<template>
	<div class="storeOverview">
		<table class="villagesTable lined">
			<thead>
				<tr>
					<th translate=""><span>Деревня</span></th>
					<th class="resource"><i class="unit_wood_small_illu" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="wood"></i></th>
					<th class="resource"><i class="unit_clay_small_illu" content="Глина" v-tippy="{placement: 'bottom'}" tooltip-translate="clay"></i></th>
					<th class="resource"><i class="unit_iron_small_illu" content="Железо" v-tippy="{placement: 'bottom'}" tooltip-translate="iron"></i></th>
					<th><i class="symbol_clock_small_flat_black duration" content="Время до того, как один из ресурсов заполнит склад" v-tippy="{placement: 'bottom'}" tooltip-translate="VillagesOverview.DurationTillFull"></i></th>
					<th class="resource"><i class="unit_crop_small_illu" content="Зерно" v-tippy="{placement: 'bottom'}" tooltip-translate="crop"></i></th>
					<th><i class="symbol_clock_small_flat_black duration" content="Время до того, как один из ресурсов заполнит склад" v-tippy="{placement: 'bottom'}" tooltip-translate="VillagesOverview.DurationTillFull"></i></th>
				</tr>
			</thead>
			<tbody>
		<!-- ngRepeat: village in overview | orderBy:'villageName' --><tr ng-repeat="village in overview | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}" class="activeVillage">
			<td>
				<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">Брест</a></div>
			</td>
			<td ng-class="{attention: village.fillPercentage[1] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 23263, total: 66000">
				&#x202D;&#x202D;35&#x202C;%&#x202C;
			</td>
			<td ng-class="{attention: village.fillPercentage[2] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 9486, total: 66000">
				&#x202D;&#x202D;14&#x202C;%&#x202C;
			</td>
			<td ng-class="{attention: village.fillPercentage[3] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 15958, total: 66000">
				&#x202D;&#x202D;24&#x202C;%&#x202C;
			</td>
			<td>
				<!-- ngIf: village.timeWhenFull.all > currentServerTime --><div countdown="1722490873" ng-if="village.timeWhenFull.all > currentServerTime">40:07:43</div><!-- end ngIf: village.timeWhenFull.all > currentServerTime -->
				<!-- ngIf: village.timeWhenFull.all <= currentServerTime -->
			</td>
			<td ng-class="{attention: village.fillPercentage[4] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 49971, total: 80000">
				&#x202D;&#x202D;62&#x202C;%&#x202C;
			</td>
			<td>
				<!-- ngIf: village.timeWhenFull.crop > currentServerTime --><div countdown="1722687424" ng-if="village.timeWhenFull.crop > currentServerTime" ng-class="{attention: village.cropProductionSpeed < 0}">94:43:34</div><!-- end ngIf: village.timeWhenFull.crop > currentServerTime -->
				<!-- ngIf: village.timeWhenFull.crop <= currentServerTime -->
			</td>
		</tr><!-- end ngRepeat: village in overview | orderBy:'villageName' --><tr ng-repeat="village in overview | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}">
			<td>
				<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">Глушица</a></div>
			</td>
			<td ng-class="{attention: village.fillPercentage[1] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 1152, total: 9600">
				&#x202D;&#x202D;12&#x202C;%&#x202C;
			</td>
			<td ng-class="{attention: village.fillPercentage[2] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 614, total: 9600">
				&#x202D;&#x202D;6&#x202C;%&#x202C;
			</td>
			<td ng-class="{attention: village.fillPercentage[3] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 1114, total: 9600">
				&#x202D;&#x202D;11&#x202C;%&#x202C;
			</td>
			<td>
				<!-- ngIf: village.timeWhenFull.all > currentServerTime --><div countdown="1722653590" ng-if="village.timeWhenFull.all > currentServerTime">85:19:40</div><!-- end ngIf: village.timeWhenFull.all > currentServerTime -->
				<!-- ngIf: village.timeWhenFull.all <= currentServerTime -->
			</td>
			<td ng-class="{attention: village.fillPercentage[4] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 6662, total: 9600">
				&#x202D;&#x202D;69&#x202C;%&#x202C;
			</td>
			<td>
				<!-- ngIf: village.timeWhenFull.crop > currentServerTime --><div countdown="1723007665" ng-if="village.timeWhenFull.crop > currentServerTime" ng-class="{attention: village.cropProductionSpeed < 0}">183:40:55</div><!-- end ngIf: village.timeWhenFull.crop > currentServerTime -->
				<!-- ngIf: village.timeWhenFull.crop <= currentServerTime -->
			</td>
		</tr><!-- end ngRepeat: village in overview | orderBy:'villageName' --><tr ng-repeat="village in overview | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}">
			<td>
				<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">Зарница</a></div>
			</td>
			<td ng-class="{attention: village.fillPercentage[1] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 6540, total: 18000">
				&#x202D;&#x202D;36&#x202C;%&#x202C;
			</td>
			<td ng-class="{attention: village.fillPercentage[2] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 5643, total: 18000">
				&#x202D;&#x202D;31&#x202C;%&#x202C;
			</td>
			<td ng-class="{attention: village.fillPercentage[3] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 5455, total: 18000">
				&#x202D;&#x202D;30&#x202C;%&#x202C;
			</td>
			<td>
				<!-- ngIf: village.timeWhenFull.all > currentServerTime --><div countdown="1722459440" ng-if="village.timeWhenFull.all > currentServerTime">31:23:50</div><!-- end ngIf: village.timeWhenFull.all > currentServerTime -->
				<!-- ngIf: village.timeWhenFull.all <= currentServerTime -->
			</td>
			<td ng-class="{attention: village.fillPercentage[4] >= 95}" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 16338, total: 18000">
				&#x202D;&#x202D;90&#x202C;%&#x202C;
			</td>
			<td>
				<!-- ngIf: village.timeWhenFull.crop > currentServerTime --><div countdown="1722453297" ng-if="village.timeWhenFull.crop > currentServerTime" ng-class="{attention: village.cropProductionSpeed < 0}">29:41:27</div><!-- end ngIf: village.timeWhenFull.crop > currentServerTime -->
				<!-- ngIf: village.timeWhenFull.crop <= currentServerTime -->
			</td>
		</tr><!-- end ngRepeat: village in overview | orderBy:'villageName' -->
				<tr ng-repeat="village in overview | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}" class="activeVillage">
					<td>
						<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">InZectorsk</a></div>
					</td>
					<td ng-class="{attention: village.fillPercentage[1] >= 95}" content="<span class='storageTooltip'><span class='values'>695/2300</span></span>" v-tippy="{placement: 'bottom'}" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 695, total: 2300">
						30%
					</td>
					<td ng-class="{attention: village.fillPercentage[2] >= 95}" content="<span class='storageTooltip'><span class='values'>1066/2300</span></span>" v-tippy="{placement: 'bottom'}" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 1066, total: 2300">
						46%
					</td>
					<td ng-class="{attention: village.fillPercentage[3] >= 95}" content="<span class='storageTooltip'><span class='values'>1647/2300</span></span>" v-tippy="{placement: 'bottom'}" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 1647, total: 2300">
						71%
					</td>
					<td>
						<div countdown="1707874042" ng-if="village.timeWhenFull.all > currentServerTime">09:38:46</div>
					</td>
					<td ng-class="{attention: village.fillPercentage[4] >= 95}" content="<span class='storageTooltip'><span class='values'>794/2300</span></span>" v-tippy="{placement: 'bottom'}" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 794, total: 1700">
						46%
					</td>
					<td>
						<div countdown="1708136607" ng-if="village.timeWhenFull.crop > currentServerTime" ng-class="{attention: village.cropProductionSpeed < 0}" class="attention">82:34:51</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
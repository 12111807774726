<template>
	<div class="buildingRallypointOverview troopsTable scrollable">
		<div class="scrollContentOuterWrapper">
			<div class="scrollContent" style="overflow-y: auto;">
				<div class="scrollContentInnerWrapper" style="width: auto;">
					<div class="filterBar">
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Incoming.attack == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Attack" clickable="filterTroops('attack', 'Incoming')">
							<i class="movement_attack_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Incoming.raid == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Raid" clickable="filterTroops('raid', 'Incoming')">
							<i class="movement_raid_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Incoming.support == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Support" clickable="filterTroops('support', 'Incoming')">
							<i class="movement_support_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Incoming.trade == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Trade" clickable="filterTroops('trade', 'Incoming')">
							<i class="movement_trade_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Incoming.healing == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Healing" clickable="filterTroops('healing', 'Incoming')">
							<i class="movement_heal_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Incoming.wounded == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Wounded" clickable="filterTroops('wounded', 'Incoming')">
							<i class="movement_wounded_small_flat_black"></i>
						</a>
					</div>
					<h4 ng-if="troopsOverview.troopsCount.Incoming == 0">
						<span>Нет прибывающих войск</span>
					</h4>
					<h4 ng-if="troopsOverview.troopsCount.Incoming > 0" translate="" data="count:1"><span>Прибывающие войска: (1)</span></h4>
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Incoming">
						<troop-details-rallypoint troop-details="troopDetails" class="movingTroops">
							<div class="troopsDetailContainer">
								<div class="troopsDetailHeader" ng-if="!showInputFields">
									<!-- ngIf: status == 'send' -->
									<!-- ngIf: isGroup -->
									<div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
										<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
										<!-- ngIf: status == 'nature' -->
										<!-- ngIf: status == 'support' -->
										<!-- ngIf: status == 'oasisSupport' -->
										<!-- ngIf: status == 'ownSupport' -->
										<!-- ngIf: status == 'awaySupport' -->
										<!-- ngIf: status == 'homeTrap' -->
										<!-- ngIf: status == 'awayTrap' -->
										<div ng-if="status == 'incoming'">
											<i class="movement_support_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_return" tooltip-placement="above"></i>
											<span ng-if="!oasis">
												<!-- ngIf: troopDetails.movement.movementType == Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT -->
												<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 -->
												<span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT &amp;&amp; ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1" data="villageId: -2983, villageName: Логово разбойников I, playerId: 1804, playerName: lexinzector"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="1804" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span> из </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': -2983}, null, true)" villageid="-2983" villagename="Логово разбойников I" nolink="" showmissinglink="true">Логово разбойников I</a></span>
											</span>
											<!-- ngIf: oasis -->
										</div>
										<!-- ngIf: status == 'outgoing' -->
									</div>
									<div class="troopsInfo incomingInfo" ng-if="status != 'send'">
										<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
										<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
											<!-- ngIf: troopDetails.movement.merchants > 0 -->
											<!-- ngIf: troopDetails.movement.merchants == 0 --><span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1708199179"><span>через </span><span countdown="1708199179" callback="">00:24:22</span><span> в </span><span i18ndt="1708199179" format="mediumTime">01:46:19</span></span><!-- end ngIf: troopDetails.movement.merchants == 0 -->
										</div>
										<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
										<div class="markerContainer" ng-if="status == 'incoming'">
											<i clickable="changeMarker(795812)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
										</div>
									</div>
								</div>
								
								<div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false">
									<table class="troopsTable gaul">
										<tbody class="troopsIconRow">
											<tr>
												<td ng-repeat="n in []|range:1:11" class="unit1">
													<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit2">
													<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit3">
													<i class="unitSmall gaul unitType3" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit4">
													<i class="unitSmall gaul unitType4" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit5">
													<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit6">
													<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit7">
													<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit8">
													<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit9">
													<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit10">
													<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="hero">
													<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
											</tr>
										</tbody>
										<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
											<!-- ngIf: troopsType == 'trapped' -->
											<tr>
												<td ng-repeat="n in [] | range:1:11" class="unit1">
													
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
														<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;64&#x202C;&#x202C;&#x202C;&#x202C;</span>
														<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
													</div>
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit2">
													
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
														<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;4&#x202C;&#x202C;&#x202C;&#x202C;</span>
														<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
													</div>
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit3">
													
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
														<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;11&#x202C;&#x202C;&#x202C;&#x202C;</span>
														<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
													</div>
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit4">
													
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
														<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;6&#x202C;&#x202C;&#x202C;&#x202C;</span>
														<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
													</div>
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit5">
													<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit6">
													<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit7">
													<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit8">
													<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit9">
													<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit10">
													<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="hero">
													<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
											</tr>
										</tbody>
										<!-- ngIf: troopData.supply -->
										<!-- ngIf: troopData.cagedAnimals -->
									</table>
								</div>

								<div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
									<!-- ngIf: showCatapults -->
									<!-- ngIf: showSpy -->
									<div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty">
										<!-- ngIf: troopDetails.movement.merchants > 1 -->
										<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
										<display-resources ng-if="troopDetails.movement.treasures > 0 &amp;&amp; showStolenGoods" resources="troopDetails.movement.resources" stolen-goods="troopDetails.movement.treasures">
											<div class="costs max3digits">
												<span ng-if="!checkStorage">
													<span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
														<i class="unit_wood_small_illu resType1"></i>
														<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;810&#x202C;&#x202C;</span>
														<!-- ngIf: ::numberUnit=='production' -->
														<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
													</span>
													<span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
														<i class="unit_clay_small_illu resType2"></i>
														<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;945&#x202C;&#x202C;</span>
														<!-- ngIf: ::numberUnit=='production' -->
														<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
													</span>
													<span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
														<i class="unit_iron_small_illu resType3"></i>
														<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;540&#x202C;&#x202C;</span>
														<!-- ngIf: ::numberUnit=='production' -->
														<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
													</span>
													<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
														<i class="unit_crop_small_illu resType4"></i>
														<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;405&#x202C;&#x202C;</span>
														<!-- ngIf: ::numberUnit=='production' -->
														<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
													</span>
												</span>
												<!-- ngIf: checkStorage -->

												<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
												<!-- ngIf: population || population == 0 -->

												<!-- ngIf: treasures || treasures == 0 -->

												<span class="resource treasureValue" ng-if="stolenGoods || stolenGoods == 0" tooltip="" tooltip-translate="Resource.StolenGoods">
													<i class="unit_stolenGoods_small_illu"></i>2
												</span>

												<!-- ngIf: time || time == 0 -->

												<!-- ngIf: enoughResSecs == -1 -->
												<!-- ngIf: enoughResSecs > 0 -->
												<!-- ngIf: capacityHint -->

												<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
											</div>
										</display-resources>
										<!-- ngIf: troopDetails.movement.treasures == 0 -->
										<span class="carryCapacity" ng-if="troopDetails.movement.merchants <= 0 &amp;&amp; !hideCapacity">
											<i class="carry unit_capacityHalf_small_flat_black" tooltip="" tooltip-translate="Report.CarryCapacityTooltip" tooltip-placement="above" tooltip-data="percent:94,used:2702,max:2870" ng-class="{
															unit_capacityEmpty_small_flat_black: totalResources == 0,
															unit_capacityHalf_small_flat_black: totalResources > 0 &amp;&amp; totalResources < troopDetails.capacity,
															unit_capacity_small_flat_black: totalResources == troopDetails.capacity }"></i>
											&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;2702&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;2870&#x202C;&#x202C;&#x202C;&#x202C;
										</span>
										<!-- ngIf: showInstantDelivery -->
									</div>
								</div>
								<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
							</div>
						</troop-details-rallypoint>
					</div>
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Incoming">
						<troop-details-rallypoint troop-details="troopDetails" class="movingTroops">
							<div class="troopsDetailContainer">
								<div class="troopsDetailHeader" ng-if="!showInputFields">
									<div class="troopsTitle" ng-if="status != 'send' && !isGroup">
										<div ng-if="status == 'incoming'">
											<i class="movement_support_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_return" tooltip-placement="above"></i>
											<span ng-if="!oasis">
												<span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1" data="villageId: -2983, villageName: Логово разбойников I, playerId: 1804, playerName: "><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="1804" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span> из </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': -2983}, null, true)" villageid="-2983" villagename="Логово разбойников I" nolink="" showmissinglink="true">Логово разбойников I</a></span>
											</span>
										</div>
									</div>
									<div class="troopsInfo incomingInfo" ng-if="status != 'send'">
										<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
											<span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1707725972"><span>через </span><span countdown="1707725972" callback="">00:19:19</span><span> в </span><span i18ndt="1707725972" format="mediumTime">14:19:32</span></span>
										</div>
										<div class="markerContainer" ng-if="status == 'incoming'">
											<i clickable="changeMarker(606532)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
											<!--<i clickable="changeMarker(606532)" class="marker movement_incoming_attack_marker_green_medium_illu"></i>
											<i clickable="changeMarker(606532)" class="marker movement_incoming_attack_marker_yellow_medium_illu"></i>
											<i clickable="changeMarker(606532)" class="marker movement_incoming_attack_marker_red_medium_illu"></i>-->
										</div>
									</div>
								</div>
								<div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false">
									<table class="troopsTable gaul">
										<tbody class="troopsIconRow">
											<tr>
												<td ng-repeat="n in []|range:1:11" class="unit1">
													<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit2">
													<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit3">
													<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit4">
													<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit5">
													<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit6">
													<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit7">
													<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit8">
													<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit9">
													<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit10">
													<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="hero">
													<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
											</tr>
										</tbody>
										<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
											<tr>
												<td ng-repeat="n in [] | range:1:11" class="unit1">
													<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;5&#x202C;&#x202C;&#x202C;&#x202C;</span>
													</div>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit2">
													<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;4&#x202C;&#x202C;&#x202C;&#x202C;</span>
													</div>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit3">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit4">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit5">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit6">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit7">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit8">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit9">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit10">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="hero">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
									<div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty">
										<display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old="">
											<div class="costs max3digits">
												<span ng-if="!checkStorage">
													<span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_1">
														<i class="unit_wood_small_illu resType1"></i>
														<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;125&#x202C;&#x202C;</span>
													</span>
													<span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_2">
														<i class="unit_clay_small_illu resType2"></i>
														<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;71&#x202C;&#x202C;</span>
													</span>
													<span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_3">
														<i class="unit_iron_small_illu resType3"></i>
														<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;106&#x202C;&#x202C;</span>
													</span>
													<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_4">
														<i class="unit_crop_small_illu resType4"></i>
														<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;53&#x202C;&#x202C;</span>
													</span>
												</span>
												<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined">
													<i class="feature_npcTrader_small_flat_black"></i>
												</div>
											</div>
										</display-resources>
										<span class="carryCapacity" ng-if="troopDetails.movement.merchants <= 0 && !hideCapacity">
											<i class="carry unit_capacity_small_flat_black" tooltip="" tooltip-translate="Report.CarryCapacityTooltip" tooltip-placement="above" tooltip-data="percent:100,used:355,max:355" ng-class="{
												unit_capacityEmpty_small_flat_black: totalResources == 0,
												unit_capacityHalf_small_flat_black: totalResources > 0 && totalResources < troopDetails.capacity,
												unit_capacity_small_flat_black: totalResources == troopDetails.capacity
											}"></i>
											&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;355&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;355&#x202C;&#x202C;&#x202C;&#x202C;
										</span>
									</div>
								</div>
							</div>
						</troop-details-rallypoint>
					</div>
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Incoming">
		<troop-details-rallypoint troop-details="troopDetails" class="movingTroops"><div class="troopsDetailContainer">
	<!-- ngIf: !showInputFields --><div class="troopsDetailHeader" ng-if="!showInputFields">
		<!-- ngIf: status == 'send' -->
		<!-- ngIf: isGroup -->
		<!-- ngIf: status != 'send' && !isGroup --><div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
			<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
			<!-- ngIf: status == 'nature' -->
			<!-- ngIf: status == 'support' -->
			<!-- ngIf: status == 'oasisSupport' -->
			<!-- ngIf: status == 'ownSupport' -->
			<!-- ngIf: status == 'awaySupport' -->
			<!-- ngIf: status == 'homeTrap' -->
			<!-- ngIf: status == 'awayTrap' -->
			<!-- ngIf: status == 'incoming' --><div ng-if="status == 'incoming'">
				<i class="movement_trade_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_incoming_merchant_return" tooltip-placement="above"></i>
				<!-- ngIf: !oasis --><span ng-if="!oasis">
					<!-- ngIf: troopDetails.movement.movementType == Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT -->
					<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 -->
					<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1 --><span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT &amp;&amp; ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1" data="villageId: 536264692, villageName: Зарница,
							   playerId: 734, playerName: "><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span> из </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264692}, null, true)" villageid="536264692" villagename="Зарница" nolink="" showmissinglink="true">Зарница</a></span><!-- end ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1 -->
				</span><!-- end ngIf: !oasis -->
				<!-- ngIf: oasis -->
			</div><!-- end ngIf: status == 'incoming' -->
			<!-- ngIf: status == 'outgoing' -->
		</div><!-- end ngIf: status != 'send' && !isGroup -->
		<!-- ngIf: status != 'send' --><div class="troopsInfo incomingInfo" ng-if="status != 'send'">
			<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
			<!-- ngIf: troopDetails.status == 'transit' --><div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
				<!-- ngIf: troopDetails.movement.merchants > 0 --><span translate="" ng-if="troopDetails.movement.merchants > 0" tooltip="" tooltip-data="timeFinish: 1723396839" tooltip-translate="RallyPoint.Troops.ArrivalTime.Trade.Tooltip" class="countdownTo" data="timeFinish: 1723396839"><span>через </span><span countdown="1723396839" callback="">00:02:08</span></span><!-- end ngIf: troopDetails.movement.merchants > 0 -->
				<!-- ngIf: troopDetails.movement.merchants == 0 -->
			</div><!-- end ngIf: troopDetails.status == 'transit' -->
			<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
			<!-- ngIf: status == 'incoming' --><div class="markerContainer" ng-if="status == 'incoming'">
				<i clickable="changeMarker(3559019)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
			</div><!-- end ngIf: status == 'incoming' -->
		</div><!-- end ngIf: status != 'send' -->
	</div><!-- end ngIf: !showInputFields -->

	<!-- ngIf: showUnits -->

	<!-- ngIf: showCatapults || showSpy || showBounty --><div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
		<!-- ngIf: showCatapults -->
		<!-- ngIf: showSpy -->
		<!-- ngIf: showBounty --><div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty">
			<!-- ngIf: troopDetails.movement.merchants > 1 --><div ng-if="troopDetails.movement.merchants > 1" class="merchantCount">
				<i class="movement_trade_small_flat_black"></i> 20
			</div><!-- end ngIf: troopDetails.movement.merchants > 1 -->
			<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
			<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
			<!-- ngIf: troopDetails.movement.treasures == 0 --><display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old="true"><div class="costs max4digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_small_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3750&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_small_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3750&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_small_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3750&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_small_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3750&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources><!-- end ngIf: troopDetails.movement.treasures == 0 -->
			<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
			<!-- ngIf: showInstantDelivery -->
		</div><!-- end ngIf: showBounty -->
	</div><!-- end ngIf: showCatapults || showSpy || showBounty -->
	<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
</div></troop-details-rallypoint>
	</div>
	<div ng-repeat="troopDetails in troopsOverview.displayTroops.Incoming">
		<troop-details-rallypoint troop-details="troopDetails" class="movingTroops"><div class="troopsDetailContainer">
	<!-- ngIf: !showInputFields --><div class="troopsDetailHeader" ng-if="!showInputFields">
		<!-- ngIf: status == 'send' -->
		<!-- ngIf: isGroup -->
		<!-- ngIf: status != 'send' && !isGroup --><div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
			<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
			<!-- ngIf: status == 'nature' -->
			<!-- ngIf: status == 'support' -->
			<!-- ngIf: status == 'oasisSupport' -->
			<!-- ngIf: status == 'ownSupport' -->
			<!-- ngIf: status == 'awaySupport' -->
			<!-- ngIf: status == 'homeTrap' -->
			<!-- ngIf: status == 'awayTrap' -->
			<!-- ngIf: status == 'incoming' --><div ng-if="status == 'incoming'">
				<i class="movement_heal_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_incoming_healing" tooltip-placement="above"></i>
				<!-- ngIf: !oasis --><span ng-if="!oasis">
					<!-- ngIf: troopDetails.movement.movementType == Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT -->
					<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 --><span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT &amp;&amp; ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1" data="villageId: 536330229, villageName: Брест,
							   playerId: 734, playerName: lexinzector" options="36"><span>Войска, исцеленные повязками</span></span><!-- end ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 -->
					<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1 -->
				</span><!-- end ngIf: !oasis -->
				<!-- ngIf: oasis -->
			</div><!-- end ngIf: status == 'incoming' -->
			<!-- ngIf: status == 'outgoing' -->
		</div><!-- end ngIf: status != 'send' && !isGroup -->
		<!-- ngIf: status != 'send' --><div class="troopsInfo incomingInfo" ng-if="status != 'send'">
			<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
			<!-- ngIf: troopDetails.status == 'transit' --><div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
				<!-- ngIf: troopDetails.movement.merchants > 0 -->
				<!-- ngIf: troopDetails.movement.merchants == 0 --><span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1723431473"><span>через </span><span countdown="1723431473" callback="">09:38:45</span><span> в </span><span i18ndt="1723431473" format="mediumTime">07:57:53</span></span><!-- end ngIf: troopDetails.movement.merchants == 0 -->
			</div><!-- end ngIf: troopDetails.status == 'transit' -->
			<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
			<!-- ngIf: status == 'incoming' --><div class="markerContainer" ng-if="status == 'incoming'">
				<i clickable="changeMarker(3528383)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
			</div><!-- end ngIf: status == 'incoming' -->
		</div><!-- end ngIf: status != 'send' -->
	</div><!-- end ngIf: !showInputFields -->

	<!-- ngIf: showUnits --><div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div><!-- end ngIf: showUnits -->

	<!-- ngIf: showCatapults || showSpy || showBounty -->
	<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
</div></troop-details-rallypoint>
	</div>
	
	<div ng-repeat="troopDetails in troopsOverview.displayTroops.Incoming">
		<troop-details-rallypoint troop-details="troopDetails" class="movingTroops"><div class="troopsDetailContainer">
	<!-- ngIf: !showInputFields --><div class="troopsDetailHeader" ng-if="!showInputFields">
		<!-- ngIf: status == 'send' -->
		<!-- ngIf: isGroup -->
		<!-- ngIf: status != 'send' && !isGroup --><div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
			<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
			<!-- ngIf: status == 'nature' -->
			<!-- ngIf: status == 'support' -->
			<!-- ngIf: status == 'oasisSupport' -->
			<!-- ngIf: status == 'ownSupport' -->
			<!-- ngIf: status == 'awaySupport' -->
			<!-- ngIf: status == 'homeTrap' -->
			<!-- ngIf: status == 'awayTrap' -->
			<!-- ngIf: status == 'incoming' --><div ng-if="status == 'incoming'">
				<i class="movement_support_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_return" tooltip-placement="above"></i>
				<!-- ngIf: !oasis --><span ng-if="!oasis">
					<!-- ngIf: troopDetails.movement.movementType == Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT -->
					<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 -->
					<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1 --><span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT &amp;&amp; ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1" data="villageId: 536330229, villageName: Брест,
							   playerId: 734, playerName: "><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span> из </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" villageid="536330229" villagename="Брест" nolink="" showmissinglink="true">Брест</a></span><!-- end ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1 -->
				</span><!-- end ngIf: !oasis -->
				<!-- ngIf: oasis -->
			</div><!-- end ngIf: status == 'incoming' -->
			<!-- ngIf: status == 'outgoing' -->
		</div><!-- end ngIf: status != 'send' && !isGroup -->
		<!-- ngIf: status != 'send' --><div class="troopsInfo incomingInfo" ng-if="status != 'send'">
			<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
			<!-- ngIf: troopDetails.status == 'transit' --><div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
				<!-- ngIf: troopDetails.movement.merchants > 0 -->
				<!-- ngIf: troopDetails.movement.merchants == 0 --><span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1723399402"><span>через </span><span countdown="1723399402" callback="">00:03:52</span><span> в </span><span i18ndt="1723399402" format="mediumTime">23:03:22</span></span><!-- end ngIf: troopDetails.movement.merchants == 0 -->
			</div><!-- end ngIf: troopDetails.status == 'transit' -->
			<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
			<!-- ngIf: status == 'incoming' --><div class="markerContainer" ng-if="status == 'incoming'">
				<i clickable="changeMarker(3560373)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
			</div><!-- end ngIf: status == 'incoming' -->
		</div><!-- end ngIf: status != 'send' -->
	</div><!-- end ngIf: !showInputFields -->

	<!-- ngIf: showUnits --><div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1 inactive" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div><!-- end ngIf: showUnits -->

	<!-- ngIf: showCatapults || showSpy || showBounty --><div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
		<!-- ngIf: showCatapults -->
		<!-- ngIf: showSpy -->
		<!-- ngIf: showBounty --><div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty">
			<!-- ngIf: troopDetails.movement.merchants > 1 -->
			<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
			<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
			<!-- ngIf: troopDetails.movement.treasures == 0 --><display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old=""><div class="costs max5digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_small_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;26220&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_small_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;21660&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_small_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;21660&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_small_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;34200&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources><!-- end ngIf: troopDetails.movement.treasures == 0 -->
			<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity --><span class="carryCapacity" ng-if="troopDetails.movement.merchants <= 0 &amp;&amp; !hideCapacity">
				<i class="carry" tooltip="" tooltip-translate="Report.CarryCapacityTooltip" tooltip-placement="above" tooltip-data="percent:∞,used:103740,max:0" ng-class="{
								unit_capacityEmpty_small_flat_black: totalResources == 0,
								unit_capacityHalf_small_flat_black: totalResources > 0 &amp;&amp; totalResources < troopDetails.capacity,
								unit_capacity_small_flat_black: totalResources == troopDetails.capacity }"></i>
			&lrm;&lrm;&#x202D;&#x202D;103k&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;
			</span><!-- end ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
			<!-- ngIf: showInstantDelivery -->
		</div><!-- end ngIf: showBounty -->
	</div><!-- end ngIf: showCatapults || showSpy || showBounty -->
	<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
</div></troop-details-rallypoint>
	</div>
	<div ng-repeat="troopDetails in troopsOverview.displayTroops.Incoming">
		<troop-details-rallypoint troop-details="troopDetails" class="movingTroops"><div class="troopsDetailContainer">
	<!-- ngIf: !showInputFields --><div class="troopsDetailHeader" ng-if="!showInputFields">
		<!-- ngIf: status == 'send' -->
		<!-- ngIf: isGroup -->
		<!-- ngIf: status != 'send' && !isGroup --><div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
			<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
			<!-- ngIf: status == 'nature' -->
			<!-- ngIf: status == 'support' -->
			<!-- ngIf: status == 'oasisSupport' -->
			<!-- ngIf: status == 'ownSupport' -->
			<!-- ngIf: status == 'awaySupport' -->
			<!-- ngIf: status == 'homeTrap' -->
			<!-- ngIf: status == 'awayTrap' -->
			<!-- ngIf: status == 'incoming' --><div ng-if="status == 'incoming'">
				<i class="movement_trade_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_incoming_merchant_return" tooltip-placement="above"></i>
				<!-- ngIf: !oasis --><span ng-if="!oasis">
					<!-- ngIf: troopDetails.movement.movementType == Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT -->
					<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 -->
					<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1 --><span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT &amp;&amp; ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1" data="villageId: 536264692, villageName: Зарница,
							   playerId: 734, playerName: "><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span> из </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264692}, null, true)" villageid="536264692" villagename="Зарница" nolink="" showmissinglink="true">Зарница</a></span><!-- end ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1 -->
				</span><!-- end ngIf: !oasis -->
				<!-- ngIf: oasis -->
			</div><!-- end ngIf: status == 'incoming' -->
			<!-- ngIf: status == 'outgoing' -->
		</div><!-- end ngIf: status != 'send' && !isGroup -->
		<!-- ngIf: status != 'send' --><div class="troopsInfo incomingInfo" ng-if="status != 'send'">
			<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
			<!-- ngIf: troopDetails.status == 'transit' --><div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
				<!-- ngIf: troopDetails.movement.merchants > 0 --><span translate="" ng-if="troopDetails.movement.merchants > 0" tooltip="" tooltip-data="timeFinish: 1723399797" tooltip-translate="RallyPoint.Troops.ArrivalTime.Trade.Tooltip" class="countdownTo" data="timeFinish: 1723399797"><span>через </span><span countdown="1723399797" callback="">00:04:28</span></span><!-- end ngIf: troopDetails.movement.merchants > 0 -->
				<!-- ngIf: troopDetails.movement.merchants == 0 -->
			</div><!-- end ngIf: troopDetails.status == 'transit' -->
			<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
			<!-- ngIf: status == 'incoming' --><div class="markerContainer" ng-if="status == 'incoming'">
				<i clickable="changeMarker(3561066)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
			</div><!-- end ngIf: status == 'incoming' -->
		</div><!-- end ngIf: status != 'send' -->
	</div><!-- end ngIf: !showInputFields -->

	<!-- ngIf: showUnits -->

	<!-- ngIf: showCatapults || showSpy || showBounty --><div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
		<!-- ngIf: showCatapults -->
		<!-- ngIf: showSpy -->
		<!-- ngIf: showBounty --><div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty">
			<!-- ngIf: troopDetails.movement.merchants > 1 --><div ng-if="troopDetails.movement.merchants > 1" class="merchantCount">
				<i class="movement_trade_small_flat_black"></i> 5
			</div><!-- end ngIf: troopDetails.movement.merchants > 1 -->
			<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
			<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
			<!-- ngIf: troopDetails.movement.treasures == 0 --><display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old="true"><div class="costs max1digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_small_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_small_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_small_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_small_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3750&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources><!-- end ngIf: troopDetails.movement.treasures == 0 -->
			<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
			<!-- ngIf: showInstantDelivery -->
		</div><!-- end ngIf: showBounty -->
	</div><!-- end ngIf: showCatapults || showSpy || showBounty -->
	<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
</div></troop-details-rallypoint>
	</div>
	
				</div>
			</div>
		</div>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems" current-page="currentPage" display-page-func="displayCurrentPage" route-named-param="cp">
			<div ng-transclude=""></div>
			<div class="tg-pagination ng-hide" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover && currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover && currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover && currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover && currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
					</li>
					<li class="nextPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover && disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover && !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black disabled"></i>
					</li>
					<li class="lastPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover && disableNext()),
							action_toEnd_tiny_flat_green: endHover && !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black disabled"></i>
					</li>
				</ul>
			</div>
		</div>
		<div class="error">xxx</div>
		<button class="sendTroops clickable" clickable="openWindow('sendTroops')">
			<div class="content">
				<span ng-transclude="" class="innerText">
					<span translate=""><span>Отправить войска</span></span>
				</span>
				<div class="animationOverlay"></div>
				<i class="checkmark action_check_small_flat_white"></i>
				<i class="checkmark action_check_small_flat_black"></i>
				<i class="checkmark action_check_small_flat_red"></i>
			</div>
		</button>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
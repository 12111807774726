<template>
	<div class="boostOffers ">
		<carousel carousel-template="tpl/shop/partials/bundle.html" carousel-items="boosts" carousel-item-primary-key="name" carousel-rows="1" carousel-cols="3" carousel-callback="selectProduct" carousel-preselect-first="false" carousel-arrow-class="greenArrow" class="col_3 rows_1 arrowsHidden">
			<div class="carousel">
				<div class="pages lastPage ng-hide firstPage" ng-show="carousel.pages.length > 1" ng-class="{firstPage: carousel.currentPage == 1, lastPage: carousel.currentPage == carousel.pages.length}">
					<div class="greenArrow arrowDirectionFrom" clickable="carousel.previousPage()"></div>
					<div class="page clickable active" ng-repeat="page in carousel.pages" clickable="carousel.goToPage(page)" ng-class="{active: page === carousel.currentPage}"></div>
					<div class="greenArrow arrowDirectionTo" clickable="carousel.nextPage()"></div>
				</div>
				<div class="itemContainer">
					<div class="items" style="width: 624px;">
						<div>
							<div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
								<div class="premiumPackage  hoverHighlight" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
									<div class="topDecoration"></div>
									<div class="packageContent large">
										<div class="packageContentBox">
											<div class="packageImageBackground">
												<i class="shop_plusAccountBg_huge_illu"></i>
											</div>
											<div class="packageLabel">
												<div class="labelText">
													<div options="plusAccount"><span>TRAVIAN Plus</span></div>
													<div ng-if="::item.duration && item.duration > 0" class="packageDuration" data="x:5"><span>5 дн.</span></div>
												</div>
											</div>
											<div class="packageItems scrollable" scrollable="">
												<div class="scrollContentOuterWrapper active">
													<div class="scrollContent" style="overflow-y: auto;">
														<div class="scrollContentInnerWrapper" style="width: 100%;">
															<div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'">
																<table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
																	<tbody>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_storagePlus" tooltip-data="x:25">
																			<td>
																				<i class="shop_feature_storagePlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:25" options="storagePlus"><strong>+25%</strong><span> к вместительности склада</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_healingPlus" tooltip-data="x:25">
																			<td>
																				<i class="shop_feature_healingPlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:25" options="healingPlus"><strong>+25 %</strong><span> к количеству мест в Целебном шатре</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_tradeRoutePlus" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_tradeRoutePlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:" options="tradeRoutePlus"><strong>+</strong><span>торговые пути</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_farmlistPlus" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_farmlistPlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:" options="farmlistPlus"><strong>+100x100</strong><span> избранных целей</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_celebrationPlus" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_celebrationPlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:1, value:" options="celebrationPlus"><strong>+1</strong><span> дополнительный слот для праздника</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_researchPlus" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_researchPlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:1, value:" options="researchPlus"><strong>+1</strong><span> дополнительный слот в кузнице</span></span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<button ng-if="item.boost && player.data[item.name + 'Time'] >= 0" class="premium packageButton" premium-feature="plusAccount" price="10" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':false}">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span ng-if="player.data[item.name + 'Time'] < $root.currentServerTime" class="truncated">
															<span>Активация</span>
														</span>
													</span>
													<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>10</span>
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</div>
									</div>
									<div class="bottomDecoration"></div>
								</div>
								<div class="packageImage shop_plusAccount_large_illu"></div>
							</div>
						</div>
						<div>
							<div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
								<div class="premiumPackage  hoverHighlight" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
									<div class="topDecoration"></div>
									<div class="packageContent large">
										<div class="packageContentBox">
											<div class="packageImageBackground">
												<i class="shop_productionBonusBg_huge_illu"></i>
											</div>
											<div class="packageLabel">
												<div class="labelText">
													<div options="productionBonus"><span>Бонус ресурсов</span></div>
													<div ng-if="::item.duration && item.duration > 0" class="packageDuration" data="x:5"><span>5 дн.</span></div>
												</div>
											</div>
											<div class="packageItems scrollable" scrollable="">
												<div class="scrollContentOuterWrapper">
													<div class="scrollContent" style="overflow-y: auto;">
														<div class="scrollContentInnerWrapper" style="width: 100%;">
															<div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'">
																<table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
																	<tbody>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_woodBonus" tooltip-data="x:25">
																			<td>
																				<i class="shop_feature_woodBonus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:25" options="woodBonus"><strong>+25 %</strong><span> к производству</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_clayBonus" tooltip-data="x:25">
																			<td>
																				<i class="shop_feature_clayBonus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:25" options="clayBonus"><strong>+25 %</strong><span> к производству</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_ironBonus" tooltip-data="x:25">
																			<td>
																				<i class="shop_feature_ironBonus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:25" options="ironBonus"><strong>+25 %</strong><span> к производству</span></span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<button ng-if="item.boost && player.data[item.name + 'Time'] >= 0" class="premium packageButton" premium-feature="productionBonus" price="20" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':false}">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span ng-if="player.data[item.name + 'Time'] < $root.currentServerTime" class="truncated">
															<span>Активация</span>
														</span>
													</span>
													<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>20</span>
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</div>
									</div>
									<div class="bottomDecoration"></div>
								</div>
								<div class="packageImage shop_productionBonus_large_illu"></div>
							</div>
						</div>
						<div>
							<div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
								<div class="premiumPackage  hoverHighlight" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
									<div class="topDecoration"></div>
									<div class="packageContent large">
										<div class="packageContentBox">
											<div class="packageImageBackground">
												<i class="shop_cropProductionBonusBg_huge_illu"></i>
											</div>
											<div class="packageLabel">
												<div class="labelText">
													<div options="cropProductionBonus"><span>Бонус зерна</span></div>
													<div ng-if="::item.duration && item.duration > 0" class="packageDuration" data="x:5"><span>5 дн.</span></div>
												</div>
											</div>
											<div class="packageItems scrollable" scrollable="">
												<div class="scrollContentOuterWrapper">
													<div class="scrollContent" style="overflow-y: auto;">
														<div class="scrollContentInnerWrapper" style="width: 100%;">
															<div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'">
																<table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
																	<tbody>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_cropBonus" tooltip-data="x:25">
																			<td>
																				<i class="shop_feature_cropBonus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:25" options="cropBonus"><strong>+25 %</strong><span> к производству</span></span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<button ng-if="item.boost && player.data[item.name + 'Time'] >= 0" class="premium packageButton" premium-feature="cropProductionBonus" price="10" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':false}">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span ng-if="player.data[item.name + 'Time'] < $root.currentServerTime" class="truncated">
															<span>Активация</span>
														</span>
													</span>
													<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>10</span>
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</div>
									</div>
									<div class="bottomDecoration"></div>
								</div>
								<div class="packageImage shop_cropProductionBonus_large_illu"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</carousel>
		<div class="wholeRoundHint" ng-if="config.balancing.TG_SPEED == 1" data="goto:gotoDeals()"><span>Бонусы продолжительностью на весь раунд представлены в разделе </span><a clickable="gotoDeals()" class="clickable">«Лучшие предложения».</a></div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="windowLoading loading" id="windowLoadingSpinner" v-if="gameModel.windowLoadingOpen">
		<div class="spin-1 spinner"></div>
	</div>
</template>

<style lang="css" scoped>
.loading{
    position:fixed;
    overflow:hidden;
}
@-webkit-keyframes loading{
    from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@-moz-keyframes loading{
    from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@-ms-keyframes loading{
    .loading from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    .loading to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@-o-keyframes loading{
    from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@keyframes loading{
    from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
.loading .spinner{
    cursor:wait;
    background-color:transparent;
    opacity:.8;
    border-radius:50px;
    animation:loading .5s infinite linear;
    -moz-animation:loading .5s infinite linear;
    -webkit-animation:loading .5s infinite linear
}
.loading .spinner.spin-1{
    border:5px solid #fff;
    border-top:5px solid transparent;
    border-left:5px solid transparent;
    width:30px;
    height:30px
}
.loading .spinner.spin-2{
    border:3px solid #fff;
    border-top:3px solid transparent;
    border-left:3px solid transparent;
    width:16px;
    height:16px
}
.windowLoading{
    background-color:rgba(0,0,0,0.5);
    width:100px;
    height:100px;
    left:50%;
    top:50%;
    margin-top:-50px;
    margin-left:-50px;
    -webkit-border-radius:16px;
    -moz-border-radius:16px;
    -ms-border-radius:16px;
    -o-border-radius:16px;
    border-radius:16px
}
.windowLoading .spinner{
    margin-top:30px;
    margin-left:30px
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
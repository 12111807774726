import axios from 'axios';
import lib from '@/lib';

export default class SilverChangeModel
{
	static TYPE_EXCHANGE_OFFICE = 1;
	static TYPE_AUCTION = 2;
	static TYPE_SELL_TO_INTERMEDIARY = 3;
	static TYPE_ADVENTURE = 4;
	static TYPE_COOP_PACKAGE = 5;
	static TYPE_QUEST = 6;
	static TYPE_STARTER_PACKAGE = 7;
	static TYPE_CARD_GAME = 8;
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		
	}
}

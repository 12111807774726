<template>
	<div class="cpOverview">
		<table class="villagesTable lined">
		<thead>
			<tr>
				<th translate=""><span>Деревня</span></th>
				<th translate=""><span>ЕК/день</span></th>
				<th translate=""><span>Праздники</span></th>
				<th translate=""><span>Войска</span></th>
				<th translate=""><span>Слоты экспансии</span></th>
			</tr>
		</thead>
		<tbody>
			<!-- ngRepeat: village in culturePointList | orderBy:'villageName' --><tr ng-repeat="village in culturePointList | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}" class="activeVillage">
				<td>
					<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">Брест</a></div>
				</td>
				<td>1123</td>
				<td>
					<!-- ngIf: celebrationData[village.villageId].count > 0 -->
					<!-- ngIf: village.celebration == 0 --><span ng-if="village.celebration == 0"><a clickable="goToTownHall(536330229)" translate="" class="clickable"><span>Ратуша</span></a></span><!-- end ngIf: village.celebration == 0 -->
					<!-- ngIf: village.celebration < 0 -->
				</td>
				<td>
					<!-- ngIf: village.units.length <= 0 --><span ng-if="village.units.length <= 0">-</span><!-- end ngIf: village.units.length <= 0 -->
					<!-- ngRepeat: unit in village.units track by $index -->
				</td>
				<td>&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</td>
			</tr><!-- end ngRepeat: village in culturePointList | orderBy:'villageName' --><tr ng-repeat="village in culturePointList | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}">
				<td>
					<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">Глушица</a></div>
				</td>
				<td>98</td>
				<td>
					<!-- ngIf: celebrationData[village.villageId].count > 0 -->
					<!-- ngIf: village.celebration == 0 -->
					<!-- ngIf: village.celebration < 0 --><span ng-if="village.celebration < 0">-</span><!-- end ngIf: village.celebration < 0 -->
				</td>
				<td>
					<!-- ngIf: village.units.length <= 0 --><span ng-if="village.units.length <= 0">-</span><!-- end ngIf: village.units.length <= 0 -->
					<!-- ngRepeat: unit in village.units track by $index -->
				</td>
				<td>&lrm;&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;</td>
			</tr><!-- end ngRepeat: village in culturePointList | orderBy:'villageName' --><tr ng-repeat="village in culturePointList | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}">
				<td>
					<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">Зарница</a></div>
				</td>
				<td>204</td>
				<td>
					<!-- ngIf: celebrationData[village.villageId].count > 0 -->
					<!-- ngIf: village.celebration == 0 --><span ng-if="village.celebration == 0"><a clickable="goToTownHall(536264692)" translate="" class="clickable"><span>Ратуша</span></a></span><!-- end ngIf: village.celebration == 0 -->
					<!-- ngIf: village.celebration < 0 -->
				</td>
				<td>
					<!-- ngIf: village.units.length <= 0 --><span ng-if="village.units.length <= 0">-</span><!-- end ngIf: village.units.length <= 0 -->
					<!-- ngRepeat: unit in village.units track by $index -->
				</td>
				<td>&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</td>
			</tr><!-- end ngRepeat: village in culturePointList | orderBy:'villageName' -->
		</tbody>
		<tfoot>
			<tr>
				<td translate=""><span>Всего</span></td>
				<td>1425</td>
				<td>
					<span>-</span>
				</td>
				<td>
					0
				</td>
				<td>&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;2&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;2&#x202C;&#x202C;&#x202C;&#x202C;</td>
			</tr>
		</tfoot>
	</table>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
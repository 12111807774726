<template>
	<div ng-controller="friendsCtrl" class="friendList">
		<h6 class="headerWithIcon arrowDirectionDown">
			<i class="chat_friend_medium_flat_black"></i>
			<span translate=""><span>Список друзей</span></span>
		</h6>
		<div class="contentWrapper scrollable" dynamic="" scrollable="">
			<div class="scrollContentOuterWrapper" style="width: 610px;">
					<div class="scrollContent" dynamic-height="true" style="width: 610px; overflow-y: hidden; max-height: 510px;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
						<div ng-if="isNoSitter" class="category">
							<table class="addFriend">
								<thead>
									<tr>
										<th colspan="2" translate=""><span>Добавить друга</span></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="searchFriend">
											<span class="autoCompleteFriend serverautocompleteContainer ng-valid" autocompletedata="player" autocompletecb="changeNewFriend" ng-model="newFriend">
												<!-- ngIf: showOwnVillages -->
												<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span><input ng-disabled="disabledInput" class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск...">
												<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 266px;">
													<i class="action_search_tiny_flat_white"></i>
													Игрок
												</label>
												<!-- ngIf: showOwnVillages -->
											</span>
										</td>
										<td class="sendRequest">
											<button clickable="sendAddRequest()" class="animate clickable">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span translate=""><span>Отправить запрос</span></span>
													</span>
													<!-- ngIf: price > 0 || forceGoldUsage -->
													<!-- ngIf: price == -1 && !forceGoldUsage -->
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</td>
									</tr>
									<!-- ngIf: addFriendError != '' -->
								</tbody>
							</table>
						</div>

						<!-- ngIf: friendlist.friendRequests.data.length > 0 && isNoSitter -->
						<!-- ngIf: friendlist.friends.data.length > 0 -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
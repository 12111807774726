import axios from 'axios';
import lib from '@/lib';

export default class PlayerModel
{
	static STATUS_IDLE = 0; // дома
	static STATUS_RETURNING = 1; // возвращается в деревню
	static STATUS_MOVING = 2; // идет в деревню (например атака) (свое название константы)
	static STATUS_UNKNOWN_2 = 3; // (иконки нет)
	static STATUS_ADVENTURE = 4; // идет в приключение (свое название константы)
	static STATUS_SUPPORTING = 5; // (иконка щита)
	static STATUS_UNKNOWN_3 = 6; // (иконка с клеткой)
	static STATUS_DEAD = 7; // герой мертв
	static STATUS_REVIVING = 8; // герой восстанавливается в деревне
	
	static TRIBE_ROMAN = 1;
	static TRIBE_TEUTON = 2
	static TRIBE_GAUL = 3;
	static TRIBE_NATAR = 4;
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.id = null;
		this.username = 'player';
		this.sex = 'male';
		this.level = 0;
		this.stars = {
			level: 1,
			stars: {
				bronze: 3,
				silver: 2,
				gold: 0,
			},
		};
		
		this.gold = 0;
		this.silver = 0;
		this.bronze = 0;
		
		this.health = 0;
		
		this.kingdomId = 0;
		this.tribeId = PlayerModel.TRIBE_GAUL;
		this.spawnedOnMap = 0;
		
		this.adventurePoints = 0;
		this.status = PlayerModel.STATUS_IDLE;
		
		this.resBonusType = 'resourcesAll';
		
		this.deletionTime = 0;
	}
}

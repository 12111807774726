import axios from 'axios';
import lib from '@/lib';

export default class HouseModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.townFoundingShow = false;
		
	}
}

<template>
	<div class="questBook">
		<div class="quests contentBox gradient adventures">
			<div class="contentBoxBody">
				<div class="selectionContainer unselectable">
					<div class="questPagination">
						<div>
							<div v-for="adventure in adventureTypes" :key="adventure.type" class="possibleQuest">
								<div class="clickableContainer clickable" @click="onSelectAdventureType(adventure.type)" :class="{'active': activeAdventureType == adventure.type}">
									<span class="questName">
										<span>{{adventure.name}}</span>
									</span>
									<div class="horizontalLine double"></div>
									<i class="adventureDuration" :class="['adventure_'+adventure.type+'_large_illu']"></i>
									<div class="selectionArrow" v-if="activeAdventureType == adventure.type"></div>
								</div>
							</div>
							<div class="currentAdventurePoints contentBox">
								<h6 class="contentBoxHeader headerColored">
									<span translate=""><span>Очки приключений</span></span>
								</h6>
								<div class="contentBoxBody">
									<div class="current">
										<span><span translate=""><span>Имеется</span></span>: </span>
										<i class="unit_adventurePoint_small_illu"></i> ×
										<span class="value">{{gameModel.player.adventurePoints}}</span>
									</div>
									<div class="next">
										<span><span translate=""><span>Следующее очко будет доступно через</span></span>: </span>
										<span class="countdown" countdown="1707759090">05:48:02</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="detailContainer fewQuests">
					<div class="questDetailsContent">
						<div class="borderWrapper">
							<div class="header">
								<img src="//iwstatic.g.bsrv.su/img/x.gif" class="gaul governor" :class="[selectedAdventureType.poster]" />
								<h6 class="headerWithArrowEndings important">
									<span class="content">{{selectedAdventureType.name}}</span>
								</h6>
							</div>
							<div><span>{{selectedAdventureType.description}}</span></div>
							<div class="horizontalLine"></div>
							<div class="buttonWrapper">
								<i class="symbol_clock_small_flat_black duration" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration"></i> {{selectedAdventureType.duration}}
								<div v-if="gameModel.player.status != 0">
									<i class="heroStatus" :class="['hero_heroStatus'+gameModel.player.status+'_small_illu']"></i>
									<span v-if="gameModel.player.status == 1" options="1" data="duration: 1708154413, villageId: 537182205, destVillageId: 537313275, destVillage: Логово разбойников I, playerId: -1, playerName: Разбойник"><span>Возвращается в деревню </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537182205}, null, true)" villageid="537182205" villagename="" nolink="" showmissinglink="true">InZectorsk</a></span>
									<span v-if="gameModel.player.status == 4" options="4" data="duration: 1707838676, villageId: 536133632, destVillageId: 536100862, destVillage: Логово разбойников II, playerId: -1, playerName: Разбойник"><span>Идет в приключение</span></span>
									<span v-if="gameModel.player.status == 8" options="8" data="duration: 1708029971, villageId: 537182205, destVillageId: 537247739, destVillage: Логово разбойников I, playerId: -1, playerName: Разбойник"><span>Герой восстанавливается в деревне </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537182205}, null, true)" villageid="537182205" villagename="" nolink="" showmissinglink="true">InZectorsk</a><span>. Восстановление займет еще </span><span countdown="1708029971" callback="">00:26:47</span><span>.</span></span>
								</div>
								<div class="horizontalLine"></div>
								<div class="adventureCosts">
									<span translate=""><span>Стоит</span></span>: <i class="unit_adventurePoint_small_illu"></i> × <span class="value">{{selectedAdventureType.amount}}</span>
								</div>
								<button :class="{'disabled': gameModel.player.status != 0 || gameModel.player.adventurePoints < selectedAdventureType.amount}" class="animate clickable" clickable="acceptQuest()" :content="gameModel.player.adventurePoints <= 0 ? 'У тебя недостаточно очков приключений' : ''" v-tippy="{placement: 'bottom'}">
									<div class="content">
										<span ng-transclude="" class="innerText">
											<span translate=""><span>Начать приключение</span></span>
										</span>
										<div class="animationOverlay"></div>
										<i class="checkmark action_check_small_flat_white"></i>
										<i class="checkmark action_check_small_flat_black"></i>
										<i class="checkmark action_check_small_flat_red"></i>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		adventureTypes: [
			{
				type: 'short',
				name: 'Короткое приключение',
				description: 'Путешественник отправляет твоего героя исследовать мир Travian, где можно найти ценные вещи. Чем выше сила героя, тем меньше здоровья он потеряет.',
				poster: 'quest991',
				duration: '00:08:00',
				amount: 1,
			},
			{
				type: 'long',
				name: 'Длинное приключение',
				description: 'Длинное приключение требует больше времени. В нем твой герой найдет в два раза больше ценных вещей, чем в коротком приключении, но оно также нанесет двойной урон его здоровью.',
				poster: 'quest992',
				duration: '01:06:14',
				amount: 2,
			},
		],
		activeAdventureType: 'short',
		tab: 'short',
	}),
	computed: {
		selectedAdventureType(){
			return this.adventureTypes.find(item => item.type === this.activeAdventureType);
		},
	},
	methods: {
		onSelectAdventureType(adventureType){
			this.activeAdventureType = adventureType;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_10.ogg');
				audio.play();
			}
		},
	},
	components: {},
}
</script>
<template>
	<div ng-if="overlayController" class="windowOverlay" id="goldFeatures" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="572"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Shop.Popup.GoldFeatures.Header" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Функции золота</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="goldFeatures scrollable" scrollable=""><div class="scrollContentOuterWrapper" style="width: 868px;">
	<div class="scrollContent" dynamic-height="false" style="width: 868px; overflow-y: hidden;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
	<!-- ngRepeat: (name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'} --><div class="feature" ng-repeat="(name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'}">
		<i class="premium_buildingMasterSlot_medium_illu"></i>
		<div class="information">
			<span class="featureName" translate="" options="BuildMaster"><span>Очередь строительства:</span></span>
			<span translate="" options="BuildMaster"><span>Открыть до трех добавочных слотов в очереди строительства на весь игровой раунд.</span></span>
		</div>
	</div><!-- end ngRepeat: (name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'} --><div class="feature" ng-repeat="(name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'}">
		<i class="premium_finishNow_medium_illu"></i>
		<div class="information">
			<span class="featureName" translate="" options="FinishNow"><span>Закончить немедленно:</span></span>
			<span translate="" options="FinishNow"><span>Моментально завершай строительство зданий в очереди, снос здания, а также текущие исследования в академии и кузнице.</span></span>
		</div>
	</div><!-- end ngRepeat: (name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'} --><div class="feature" ng-repeat="(name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'}">
		<i class="premium_exchangeOffice_medium_illu"></i>
		<div class="information">
			<span class="featureName" translate="" options="ExchangeOffice"><span>Обмен золота на серебро:</span></span>
			<span translate="" options="ExchangeOffice"><span>Позволяет тебе обменивать золото на серебро на аукционе.</span></span>
		</div>
	</div><!-- end ngRepeat: (name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'} --><div class="feature" ng-repeat="(name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'}">
		<i class="premium_traderArriveInstantly_medium_illu"></i>
		<div class="information">
			<span class="featureName" translate="" options="InstantTrader"><span>Моментальная доставка:</span></span>
			<span translate="" options="InstantTrader"><span>Моментальная доставка ресурсов торговцами на рынке.</span></span>
		</div>
	</div><!-- end ngRepeat: (name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'} --><div class="feature" ng-repeat="(name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'}">
		<i class="premium_traderSlot_medium_illu"></i>
		<div class="information">
			<span class="featureName" translate="" options="MoreTrader"><span>Дополнительный ход торговцев:</span></span>
			<span translate="" options="MoreTrader"><span>Позволяет тебе автоматически устанавливать дополнительную отправку ресурсов торговцами на рынке.</span></span>
		</div>
	</div><!-- end ngRepeat: (name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'} --><div class="feature" ng-repeat="(name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'}">
		<i class="premium_npcTrader_medium_illu"></i>
		<div class="information">
			<span class="featureName" translate="" options="NPCTrader"><span>NPC-торговец:</span></span>
			<span translate="" options="NPCTrader"><span>Используй NPC-торговца на рынке, чтобы моментально перераспределить ресурсы по своему желанию.</span></span>
		</div>
	</div><!-- end ngRepeat: (name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'} --><div class="feature" ng-repeat="(name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'}">
		<i class="premium_tributeAndTreasuresArriveInstantly_medium_illu"></i>
		<div class="information">
			<span class="featureName" translate="" options="TributeTreasures"><span>Доставка дани и сокровищ:</span></span>
			<span translate="" options="TributeTreasures"><span>Моментально доставляет дань и ресурсы в пункте сбора.</span></span>
		</div>
	</div><!-- end ngRepeat: (name, iconClass) in {'BuildMaster': 'buildingMasterSlot', 'FinishNow': 'finishNow', 'ExchangeOffice': 'exchangeOffice', 'InstantTrader': 'traderArriveInstantly', 'MoreTrader': 'traderSlot', 'NPCTrader': 'npcTrader', 'TributeTreasures': 'tributeAndTreasuresArriveInstantly'} -->
</div>
	</div>
</div>
</div></div>
	</div>
</div></div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="reportBody troopsReport" src="'tpl/report/types/'+reportType+'.html'">
		<div class="troopsScrollContainer">
			<div class="troopsDetailContainer" ng-repeat="troopDetails in troopsData">
				<div troops-details="" troop-data="troopDetails">
					<table class="troopsTable gaul">
						<tbody class="troopsIconRow">
							<tr>
								<td v-for="n in range(1, 11)" :key="n" :class="{['unit'+n]: n != TroopsModel.TYPE_HERO, 'hero': n == TroopsModel.TYPE_HERO}">
									<i class="unitSmall gaul" :class="['unitType'+n, {'hero_illu': n == TroopsModel.TYPE_HERO, 'inactive': (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (troopData['inputTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)))}]" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" v-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
							</tr>
						</tbody>
						<tbody v-show="troopData[troopsType+'Troops']" class="originalTroops" v-for="troopsType in ['original', 'input', 'lost', 'trapped']">
							<tr v-if="troopsType == 'trapped'" class="subHeader">
								<td colspan="11">
									<span><span translate=""><span>Поймано</span></span>:</span>
								</td>
							</tr>
							<tr>
								<td v-for="n in range(1, 11)" :key="n" :class="{['unit'+n]: n != TroopsModel.TYPE_HERO, 'hero': n == TroopsModel.TYPE_HERO}">
									<span v-if="troopsType == 'original' && (troopData[troopsType+'Troops'] && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0))">-</span>
									<span v-if="troopsType == 'original' && (troopData[troopsType+'Troops'] && troopData[troopsType+'Troops'][n] < 0)">?</span>
									<div v-if="troopsType != 'input' && (troopData[troopsType+'Troops'] && troopData[troopsType+'Troops'][n] >= 1)">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span v-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == TroopsModel.TYPE_HERO && troopData['heroHealthLoss'])">{{troopData[troopsType+'Troops'][n]}}</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
							</tr>
						</tbody>
						<tbody v-if="troopData.supply">
							<tr class="subHeader">
								<td colspan="11">
									<span><span translate=""><span>Содержание</span></span>:</span>
								</td>
							</tr>
							<tr>
								<td colspan="11" class="upkeep">
									<i class="unit_consumption_small_flat_black"></i> {{troopData.supply}} <span translate=""><span>в час</span></span>
								</td>
							</tr>
						</tbody>
						<tbody v-if="troopData.cagedAnimals">
							<tr class="subHeader">
								<td colspan="11">
									<span><span translate="" data="amount:38"><span>Использовано клеток: {{troopData.cagedAnimals}}.</span></span></span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!-- ngIf: header.displayType == Report.DISPLAY_TYPE_VACATION -->
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import TroopsModel from '@/models/game/TroopsModel';

export default {
	mixins: lib.mixins,
	props: {
		report: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		TroopsModel,
	}),
	computed: {
		troopData(){
			return this.report.troopData||{};
		},
	},
	methods: {
		range: lib.range,
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
	},
	components: {},
}
</script>
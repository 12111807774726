<template>
	<div ng-if="overlayController" class="windowOverlay" id="reportSingle" ng-controller="overlayController">
		<div class="darkener clickable" @click="onClose"></div>
		<div v-if="report">
			<div class="inWindowPopup singleReport" v-if="report.time">
				<div class="inWindowPopupHeader">
					<div class="navigation">
						<a class="back clickable" clickable="changeReport('prev')" ng-class="{disabled: !prevNext.prev}" play-on-click="20" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
							<i ng-class="{
								symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover && !prevNext.prev),
								symbol_arrowFrom_tiny_flat_green: fromHover && prevNext.prev,
								disabled: !prevNext.prev
							}" class="symbol_arrowFrom_tiny_flat_black"></i>
							<span translate=""><span>Новые</span></span>
						</a> |
						<a class="forward clickable" clickable="changeReport('next')" ng-class="{disabled: !prevNext.next}" play-on-click="20" on-pointer-over="toHover = true" on-pointer-out="toHover = false">
							<span translate=""><span>Старые</span></span>
							<i ng-class="{
								symbol_arrowTo_tiny_flat_black: !toHover || (toHover && !prevNext.next),
								symbol_arrowTo_tiny_flat_green: toHover && prevNext.next,
								disabled: !prevNext.next
							}" class="symbol_arrowTo_tiny_flat_black"></i>
						</a>
					</div>
					<a class="closeOverlay clickable" @click="onClose" translate=""><span>Закрыть</span></a>
				</div>
				<div class="reportCaption" v-if="report.successType && report.notificationType != ReportsModel.REPORT_FARMLIST_RAID">
					<div class="banner" :class="['result'+report.successType]">
						<span class="content">
							<i class="reportIcon reportTypes_reportIcon_base" v-if="[20, 60, 61, 62, 63, 64].includes(report.notificationType)"></i>
							<i class="reportIcon" :class="['reportTypes_reportIcon'+report.notificationType+'_base']" v-if="![18, 19, 20, 60, 61, 62, 63, 64, 115, 118, 119, 120].includes(report.notificationType)"></i>
							<i class="reportIcon reportTypes_reportIcon1_base" v-if="[124].includes(report.notificationType)"></i>
							<i class="reportIcon reportTypes_reportIcon2_base" v-if="[125].includes(report.notificationType)"></i>
							<i class="reportIcon reportTypes_reportIcon3_base" v-if="[126].includes(report.notificationType)"></i>
							<i class="reportIcon reportTypes_reportIcon4_base" v-if="[18].includes(report.notificationType)"></i>
							<i class="reportIcon reportTypes_reportIcon5_base" v-if="[19].includes(report.notificationType)"></i>
							
							<!--в случае разведки, отчет на стороне атакующего-->
							<span ng-bind="reportHeadline" v-if="[15].includes(report.notificationType)">Разведка прошла успешно</span>
							<span ng-bind="reportHeadline" v-if="[16].includes(report.notificationType)">Разведка обнаружена</span>
							<span ng-bind="reportHeadline" v-if="[17].includes(report.notificationType)">Разведка провалилась</span>
							
							<!--в случае разведки, отчет на стороне защищающегося-->
							<span ng-bind="reportHeadline" v-if="[18].includes(report.notificationType)">Разведка отражена</span>
							<span ng-bind="reportHeadline" v-if="[19].includes(report.notificationType)">Разведка обнаружена</span>
							
							<!--в случае атаки, отчет на стороне атакующего-->
							<span ng-bind="reportHeadline" v-if="[1, 124].includes(report.notificationType)">Победа</span>
							<span ng-bind="reportHeadline" v-if="[2, 125].includes(report.notificationType)">Победа</span>
							<span ng-bind="reportHeadline" v-if="[3, 126].includes(report.notificationType)">Поражение</span>
							
							<!--в случае атаки, отчет на стороне защищающегося-->
							<span ng-bind="reportHeadline" v-if="[4].includes(report.notificationType)">Победа</span>
							<span ng-bind="reportHeadline" v-if="[5].includes(report.notificationType)">Победа</span>
							<span ng-bind="reportHeadline" v-if="[6].includes(report.notificationType)">Поражение</span>
							<span ng-bind="reportHeadline" v-if="[7].includes(report.notificationType)">Поражение</span>
							
							<!--отчет о приключении-->
							<span ng-bind="reportHeadline" v-if="[21].includes(report.notificationType) && report.won">Успех</span>
							<span ng-bind="reportHeadline" v-if="[21].includes(report.notificationType) && !report.won">Неудача</span>
							
							<span ng-bind="reportHeadline" v-if="[8].includes(report.notificationType)">Подкрепление</span>
							<span ng-bind="reportHeadline" v-if="[10, 11, 12, 13, 14].includes(report.notificationType)">Товары доставлены</span>
							<span ng-bind="reportHeadline" v-if="[20].includes(report.notificationType)">Были пойманы животные</span>
							<span ng-bind="reportHeadline" v-if="[23].includes(report.notificationType)">Престиж за неделю</span>
							
							<!--
							37: 'Твои войска были освобождены!',
							22: 'Отчет о визите', // ???
							117: 'Отчет о визите', // ??? иконка с оазисом
							141: 'Перемещение прошло успешно',
							65: '', // иконка руки, дающей серебро
							66: '', // иконка смены корон
							-->
						</span>
					</div>
					<h6 v-if="report.sharedBy" class="sharedHeader headerTrapezoidal">
						<div class="content">
							<span translate=""><span>Поделился игрок:</span></span>
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="122">{{report.sharedByName}}</a>
						</div>
					</h6>
				</div>
				<div class="inWindowPopupContent">
					<div class="scrollable">
						<div class="scrollContentOuterWrapper">
							<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightContent}">
								<div class="scrollContentInnerWrapper" style="width: auto;">
									<header-prestige-report :report="report" v-if="report.reportType == 'prestigeReport'"></header-prestige-report>
									<header-report :report="report" v-if="report.reportType != 'prestigeReport'" ></header-report>
									<body-prestige-report :report="report" v-if="report.reportType == 'prestigeReport'"></body-prestige-report>
									<body-adventure-report :report="report" v-if="report.reportType == 'adventureReport'"></body-adventure-report>
									<body-trade-report :report="report" v-if="report.reportType == 'tradeReport'"></body-trade-report>
									<body-troops-report :report="report" v-if="report.reportType == 'troopsReport'"></body-troops-report>
									<body-fight-report :report="report" v-if="report.reportType == 'fightReport'"></body-fight-report>
									<body-farmlist-report :report="report" v-if="report.reportType == 'farmlistReport'"></body-farmlist-report>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import ReportsModel from '@/models/game/ReportsModel';

import HeaderReport from '@/components/pages/reports/windowOverlay/reportInfo/Header';
import HeaderPrestigeReport from '@/components/pages/reports/windowOverlay/reportInfo/HeaderPrestige';
import BodyPrestigeReport from '@/components/pages/reports/windowOverlay/reportInfo/BodyPrestige';
import BodyAdventureReport from '@/components/pages/reports/windowOverlay/reportInfo/BodyAdventure';
import BodyTradeReport from '@/components/pages/reports/windowOverlay/reportInfo/BodyTrade';
import BodyTroopsReport from '@/components/pages/reports/windowOverlay/reportInfo/BodyTroops';
import BodyFightReport from '@/components/pages/reports/windowOverlay/reportInfo/BodyFight';
import BodyFarmlistReport from '@/components/pages/reports/windowOverlay/reportInfo/BodyFarmlist';

export default {
	mixins: lib.mixins,
	props: {
		maxHeightContent: {
			type: String,
			required: true,
		},
	},
	data: () => ({
		ReportsModel,
		
		report: null,
	}),
	computed: {},
	methods: {
		range: lib.range,
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
		
		getSelectedReport(){
			this.report = this.gameModel.reports.doGetReport(this.gameModel.reports.selectedReport);
			if(!this.report){
				this.gameModel.flashNotifications.warnings.push({
					class: '',
					message: 'NotFound',
				});
			}
		},
		onOpenProfile(playerId, tab){
			
		},
		onClose(){
			this.gameModel.reports.showReportInfo = false;
			this.gameModel.reports.selectedReport = null;
		},
	},
	mounted(){
		this.getSelectedReport();
    },
	components: {
		HeaderReport,
		HeaderPrestigeReport,
		BodyPrestigeReport,
		BodyAdventureReport,
		BodyTradeReport,
		BodyTroopsReport,
		BodyFightReport,
		BodyFarmlistReport,
	},
}
</script>
<template>
	<div class="reportsList">
		<div class="reportFilter filterBar">
			<div v-for="filterGroup in filterGroups" :key="filterGroup.type" class="filterGroup">
				<a class="filter iconButton clickable" :class="{'active': filterActive.includes(filterGroup.type)}" :content="filterGroup.name" v-tippy="{placement: 'bottom'}" @click="onToggleFilter(filterGroup.type)">
					<i class="reportIcon" :class="['reportTypes_reportIcon'+filterGroup.type]"></i>
				</a>
				<div class="subFilter" v-show="filterActive.includes(filterGroup.type)" v-if="filterGroup.subfilters.length > 0">
					<a v-for="subfilter in filterGroup.subfilters" :key="subfilter" class="filter iconButton clickable" :class="{'active': filterActive.includes(subfilter)}" @click="onToggleFilter(subfilter)" :content="gameModel.reports.notificationTypes[subfilter]" v-tippy="{placement: 'bottom'}">
						<i class="reportIcon" :class="['reportTypes_reportIcon'+subfilter]"></i>
					</a>
				</div>
			</div>
		</div>
		<div>
			<div>
				<table class="allianceReports">
					<thead>
						<tr>
							<th><span translate=""><span>Тема</span></span><span ng-if="tabData.currentTab == 'Search'"> (&lt;поисковая фраза&gt;)</span></th>
							<th translate="" width="20%" style="text-align: right;"><span>Отправлено</span></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="report in reportsOnPage" :key="report.id">
							<td class="reportDescription">
								<i @click="gameModel.reports.list.splice(gameModel.reports.list.findIndex(el => el.id == report.id), 1)" class="reportIcon" :class="['reportTypes_reportIcon'+report.notificationType]" :content="gameModel.reports.notificationTypes[report.notificationType]" v-tippy="{placement: 'bottom'}" v-if="!report.sharedBy && report.displayType != 11 && report.displayType != 12"></i>
								<i class="reportIcon" :class="['reportTypes_reportIcon'+report.notificationType]" :content="gameModel.reports.notificationTypes[report.notificationType]" v-tippy="{placement: 'bottom'}" v-if="report.sharedBy && report.displayType != 11 && report.displayType != 12"></i>
								<i class="reportIcon pending" :class="['reportTypes_reportIcon'+report.notificationType]" :content="gameModel.reports.notificationTypes[report.notificationType]" v-tippy="{placement: 'bottom'}" v-if="report.displayType == 11"></i>
								<i class="reportIcon" :class="['reportTypes_reportIcon'+report.notificationType]" :content="gameModel.reports.notificationTypes[report.notificationType]+': '+report.targetsTotal+'x'" v-if="report.displayType == 12"></i>
								<span class="truncated">
									<a @click="onOpenReport(report.id)" class="clickable">
										<span :class="['reportType'+report.displayType]"><span>{{report.name}}</span></span>
									</a>
									<span class="addon" v-if="(report.displayType == 11 || report.displayType == 12) && report.targetsTotal != report.targetsFinished">
										<span>({{report.targetsFinished}} из {{report.targetsTotal}})</span>
									</span>
								</span>
								<span class="addon" v-if="report.displayType == 4 && report.capacity > 0">
									<i class="carry" :content="'Загружены добычей: '+getPercent(report.bounty, report.capacity)+'% ('+report.bounty+'/'+report.capacity+')'" v-tippy="{placement: 'bottom'}" tooltip-translate="Report.CarryCapacityTooltip" tooltip-placement="above" :class="{
										'unit_capacityEmpty_small_flat_black': report.bounty == 0,
										'unit_capacityHalf_small_flat_black': report.bounty > 0 && report.bounty < report.capacity,
										'unit_capacity_small_flat_black': report.bounty == report.capacity
									}"></i>
								</span>
							</td>
							<td style="text-align: right;"><span :i18ndt="report.time" format="short">{{formatUnixDate(report.time, 'DD.MM.YY HH:mm')}}</span></td>
						</tr>
						<tr v-if="reportsOnPage.length == 0">
							<td colspan="3">
								<span translate=""><span>Нет доступных отчетов.</span></span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<pagination :items="reports" :page-size="10" @change-page="onChangePage" style="padding: 5px 0 0 0;"></pagination>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import Pagination from '@/components/elements/Pagination';

export default {
	mixins: lib.mixins,
	data: () => ({
		pager: {},
		reportsOnPage: [],
		
		filterGroups: [
			{
				type: 'att',
				name: 'Отчеты об атаке',
				subfilters: [1, 2, 3, 124, 125, 126, 115],
			},
			{
				type: 'def',
				name: 'Отчеты о защите',
				subfilters: [4, 5, 6, 7],
			},
			{
				type: 'spy1',
				name: 'Отчеты о своей разведке',
				subfilters: [15, 16, 17],
			},
			{
				type: 'spy2',
				name: 'Обнаруженная разведка',
				subfilters: [18, 19],
			},
			{
				type: '64 .', // пробел и точка тут нужны!!! чтобы фильтр работал корректно
				name: 'Опубликованные отчеты',
				subfilters: [64, 60, 61, 62, 63],
			},
		],
		filterActive: [],
	}),
	computed: {
		filterActiveClear(){
			return this.filterActive.filter(el => typeof(el) == 'number');
		},
		reports(){
			return this.gameModel.reports.list.filter(el => this.filterActiveClear.length == 0 || this.filterActiveClear.includes(el.notificationType));
		},
	},
	methods: {
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
		getPercent: lib.getPercent,
		
		onToggleFilter(type){
			if(this.filterGroups.findIndex(el => el.type == type) !== -1){
				if(!this.filterActive.includes(type)){
					let arr = [type];
					for(let subfilter of this.filterGroups.find(el => el.type == type).subfilters){
						arr.push(subfilter);
					}
					this.filterActive = arr;
				} else {
					this.filterActive = [];
				}
			} else {
				if(this.filterActive.includes(type)){
					this.filterActive.splice(this.filterActive.indexOf(type), 1);
					
					let filterGroup = this.filterGroups.find(elg => elg.subfilters.findIndex(elf => elf == type) !== -1)
					if(filterGroup){
						let flag1 = false;
						for(let subfilter of filterGroup.subfilters){
							if(this.filterActive.includes(subfilter)){
								flag1 = true;
                                break;
							}
						}
						if(!flag1){
							this.filterActive = [];
						}
					}
					
				} else {
					this.filterActive.push(type);
				}
			}
		},
		onChangePage(pageOfItems){
			this.reportsOnPage = pageOfItems;
		},
		onOpenReport(id){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
			
			this.gameModel.reports.selectedReport = id;
			this.gameModel.reports.showReportInfo = true;
		},
	},
	components: {
		Pagination,
	},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper sendTroops" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal sendTroops">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/map')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Отправить войска</span></span>
								</h2>
							</div>
						</div>
						<div ng-controller="sendTroopsCtrl" class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<nav class="tabulation maintab">
									<a class="tab clickable" v-for="tab in sendTabs" :class="{'active': tab.id == activeTab, 'inactive': tab.id != activeTab}">
										<div class="content" @click="onToggleTab(tab.id)">
											{{tab.name}}
										</div>
										<i class="deleteTab clickable" :class="{'action_cancel_tiny_flat_black': !cancelHover, 'action_cancel_tiny_flat_red': cancelHover}" @mouseover="cancelHover = true" @mouseleave="cancelHover = false" @click="onCloseTab(tab.id)" v-if="sendTabs.length > 1"></i>
									</a>
									<a class="tab inactive clickable" @click="onAddTab()" v-if="showMoreTabs">
										<div class="content">
											<i class="symbol_plus_tiny_flat_black"></i>
										</div>
									</a>
								</nav>
								<div class="tabulationContent">
									<div ng-include="currentPage" class="send-troops" ng-controller="sendTroopsTabCtrl">
										<div class="contentBox colored choose-troops" v-if="selectedTab.page == 'choose-troops'">
											<div class="contentBoxBody">
												<div class="chooseTarget searchVillage" check-target-fct="checkTargetSend" callback="preselectAndCheckTarget" api="villageSearch" show-duration="false" show-distance="true" show-own-villages="true">
													<h6><span>Цель</span></h6>
													<div class="error success" ng-class="{success: api.result.villageReturned}">
														<span class="villageName serverautocompleteContainer ng-valid ng-dirty" disable-search="Глушица" autocompletedata="playerVillages,village,coords,emptyCoords" autocompletecb="selectVillage" ng-model="api.result.villageSearched" input-autofocus="false" show-own-villages="true">
															<ul ng-if="showOwnVillages" class="ownVillageList" ng-class="{visible: ownVillagesListVisible}">
																<li ng-repeat="village in ownVillages">
																	<a clickable="selectOwnVillage($index)" class="clickable">
																		<div class="resultRow">
																			<span class="resultName">Брест</span>
																			<span class="resultType">
																				<span class="coordinateWrapper" coordinates="" nolink="true" x="-11" y="-17">
																					<!-- ngIf: hasLink -->
																					<span ng-if="!hasLink" class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y "><span class="coordinateX">(-11</span><span class="coordinatePipe">|</span><span class="coordinateY">-17)</span></span>
																				</span>
																			</span>
																		</div>
																	</a>
																</li>
																<li ng-repeat="village in ownVillages">
																	<a clickable="selectOwnVillage($index)" class="clickable">
																		<div class="resultRow">
																			<span class="resultName">Глушица</span>
																			<span class="resultType">
																				<span class="coordinateWrapper" coordinates="" nolink="true" x="-13" y="-19">
																					<!-- ngIf: hasLink -->
																					<span ng-if="!hasLink" class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y "><span class="coordinateX">(-13</span><span class="coordinatePipe">|</span><span class="coordinateY">-19)</span></span>
																				</span>
																			</span>
																		</div>
																	</a>
																</li>
															</ul>
															<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span>
															<input ng-disabled="disabledInput" class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск..."/>
															<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 159px;" class="ownVillagesShown">
																<i class="action_search_tiny_flat_white"></i>
																Игрок, Деревня, Координаты
															</label>
															<a ng-if="showOwnVillages" class="villageQuickSelect iconButton clickable" clickable="toggleOwnVillagesList()" ng-class="{active: ownVillagesListVisible}">
																<i class="villageList_villageOverview_small_flat_black"></i>
															</a>
														</span>
													</div>
													<div ng-show="api.target.villageId > 0" class="">
														<div class="horizontalLine"></div>
														<table class="transparent">
															<tbody>
																<tr>
																	<th><span>Деревня</span></th>
																	<td>
																		<a ng-class="{'disabled': villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264691}, null, true)" village-link="" villageid="536264691" villagename="Глушица">Глушица</a>
																	</td>
																</tr>
																<tr ng-if="api.target.destPlayerId !== null">
																	<th><span>Владелец</span></th>
																	<td data-village-type="0" data-is-governor-npc="false" class="playerVillage">
																		<a ng-class="{'disabled': playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734">lexinzector</a>
																	</td>
																</tr>
																<tr ng-if="api.targetPlayer.data.kingdomId > 0">
																	<th><span>Королевство</span></th>
																	<td>
																		<a ng-class="{'disabled': kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3">MИР</a>
																	</td>
																</tr>
																<tr ng-show="showDuration && showDuration != 'false'" class="ng-hide">
																<th><span>Время</span></th>
																	<td>0</td>
																</tr>
																<tr ng-show="showDistance && showDistance != 'false'">
																<th><span>Дист.</span></th>
																	<td>1,00</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<div class="contentBox chooseMissionType">
													<h6 class="contentBoxHeader headerWithIcon arrowDirectionTo">
														<i class="movement_attack_small_flat_gray"></i>
														<span><span>Тип атаки</span></span>
													</h6>
													<div class="contentBoxBody">
														<div v-for="type in missionTypes" @click="onSelectMissionType(type.id)" class="clickableContainer" :class="['missionType'+type.id, {'active': type.state == 'selected', 'disabled': type.state == 'deactivated'}]" v-show="type.state != 'hidden'" :content="type.tooltip" v-tippy="{placement: 'bottom'}">
															<i class="missionType" :class="{
																'disabled': type.state == 'deactivated',
																'sendTroops_attack_large_illu': type.id == TroopsModel.MOVEMENT_TYPE_ATTACK,
																'sendTroops_raid_large_illu': type.id == TroopsModel.MOVEMENT_TYPE_RAID,
																'sendTroops_support_large_illu': type.id == TroopsModel.MOVEMENT_TYPE_SUPPORT,
																'sendTroops_spy_large_illu': type.id == TroopsModel.MOVEMENT_TYPE_SPY,
																'sendTroops_settle_large_illu': type.id == TroopsModel.MOVEMENT_TYPE_SETTLE,
																'sendTroops_siege_large_illu': type.id == TroopsModel.MOVEMENT_TYPE_SIEGE,
															}"></i>
														</div>
													</div>
													<div class="contentBoxBody">
														<!-- ngRepeat: type in newMissionTypes -->
													</div>
												</div>
												<div class="troopsDetailContainer chooseUnits">
													<div class="troopsDetailHeader fromHeader">
														<i class="movement_attack_small_flat_gray colorIcon"></i>
														<span><span>Выбери войска</span></span>
														<div class="troopsInfo">
															<div class="dropdownContainer" dropdown="" data="villageDropdown">
																<div class="dropdownHead openDownwards closed clickable" clickable="clickDropdown()">
																	<span class="selectedItem truncated">Зарница</span>
																	<i class="symbol_arrowDown_tiny_illu"></i>
																</div>
																<input class="dropdownInput" type="text"/>
															</div>
														</div>
													</div>
													<div troops-details="" troop-data="troopDetails" unit-input-callback="checkTroops">
														<table class="troopsTable gaul">
															<tbody class="troopsIconRow">
																<tr>
																	<td ng-repeat="n in []|range:1:11" class="unit1">
																		<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit2">
																		<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit3">
																		<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit4">
																		<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit5">
																		<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit6">
																		<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit7">
																		<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit8">
																		<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit9">
																		<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit10">
																		<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="hero">
																		<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																								clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																</tr>
															</tbody>
															<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
																<!-- ngIf: troopsType == 'trapped' -->
																<tr>
																	<td ng-repeat="n in [] | range:1:11" class="unit1">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
																			<a ng-if="troopsType == 'original' && troopData['inputTroops']" clickable="addUnit(n);" class="clickable">2</a>
																			<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
																			<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
																		</div>
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit2">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
																			<a ng-if="troopsType == 'original' && troopData['inputTroops']" clickable="addUnit(n);" class="clickable">12</a>
																			<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
																			<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
																		</div>
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit3">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit4">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit5">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit6">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit7">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit8">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit9">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit10">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="hero">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																</tr>
															</tbody>
															<tbody ng-if="troopData[troopsType+'Troops']" class="inputTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
																<!-- ngIf: troopsType == 'trapped' -->
																<tr>
																	<td ng-repeat="n in [] | range:1:11" class="unit1">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="2" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput0 ng-touched" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit2">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="12" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput1" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit3">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput2" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel" disabled="disabled"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit4">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput3" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel" disabled="disabled"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit5">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput4" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel" disabled="disabled"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit6">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput5" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel" disabled="disabled"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit7">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput6" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel" disabled="disabled"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit8">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput7" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel" disabled="disabled"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit9">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput8" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel" disabled="disabled"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit10">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput9" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel" disabled="disabled"/>
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="hero">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput10" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel" disabled="disabled"/>
																	</td>
																</tr>
															</tbody>
															<!-- ngIf: troopData.supply -->
															<!-- ngIf: troopData.cagedAnimals -->
														</table>
													</div>
												</div>
											</div>
										</div>
										<div class="contentBox colored confirm-troops" v-if="selectedTab.page == 'confirm-troops'">
											<h6 class="contentBoxHeader headerTrapezoidal">
												<div class="content">
													<span class="missionType" options="3">
														<span>{{selectedMissionType.name}}</span>
													</span>
												</div>
											</h6>
											<div class="contentBoxBody">
												<div class="participants sendTroops_headerBackground_illustration">
													<div class="movementIllustrationContainer">
														<img src="//iwstatic.g.bsrv.su/img/x.gif" :class="{
															'sendTroops_headerAttack_illustration': selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_ATTACK || selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_RAID || selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_SIEGE,
															'sendTroops_headerSupport_illustration': selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_SUPPORT,
															'sendTroops_headerSpy_illustration': selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_SPY,
															'sendTroops_headerSettle_illustration': selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_SETTLE,
														}" data-cmp-info="10"/>
													</div>
													<div class="startVillage">
														<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264692}, null, true)" village-link="" villageid="536264692" villagename="Зарница">Зарница</a>
														<br>
														<span data="playerId: 734, playerName: lexinzector"><span>из </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a></span>
													</div>
													<div class="targetVillage">
														<div v-if="selectedTab.missionType != TroopsModel.MOVEMENT_TYPE_SETTLE">
															<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" villagename="Брест">Брест</a>
															<br>
															<span data="playerId: 734, playerName: lexinzector"><span>из </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a></span>
														</div>
														<div v-if="selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_SETTLE">
															<span translate=""><span>Покинутая долина</span></span>
															<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536297461}, null, true)" village-link="" villageid="536297461" villagename="(-11|-18)">(-11|-18)</a>
														</div>
													</div>
												</div>
												<div class="headerTrapezoidal">
													<div class="content">
														<span class="arrivalDuration" data="time: 1341"><span>через 00:22:21</span></span>
														<span class="seperator"> | </span>
														<span class="arrivalTime" data="atTime: 1341"><span>в </span><span i18ndt="1341" format="mediumTime" fast-offset="true">20:58:48</span></span>
													</div>
												</div>
												<div class="troopsDetailContainer chosenUnits">
													<div class="troopsDetailHeader fromHeader">
														<i class="movement_attack_small_flat_gray colorIcon"></i>
														<span><span>Выбери войска</span></span>
													</div>
													<div troops-details="" troop-data="sendTroopsDetails">
														<table class="troopsTable gaul">
															<tbody class="troopsIconRow">
																<tr>
																	<td ng-repeat="n in []|range:1:11" class="unit1">
																		<i class="unitSmall gaul unitType1 inactive" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit2">
																		<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit3">
																		<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit4">
																		<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit5">
																		<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit6">
																		<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit7">
																		<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit8">
																		<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit9">
																		<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="unit10">
																		<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																	<td ng-repeat="n in []|range:1:11" class="hero">
																		<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																										|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																							clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
																		<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
																		<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
																	</td>
																</tr>
															</tbody>
															<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
																<!-- ngIf: troopsType == 'trapped' -->
																<tr>
																	<td ng-repeat="n in [] | range:1:11" class="unit1">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit2">
																		<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
																			<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
																			<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span>
																			<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
																		</div>
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit3">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit4">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit5">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit6">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit7">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit8">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit9">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="unit10">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																	<td ng-repeat="n in [] | range:1:11" class="hero">
																		<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
																		<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

																		<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
																		<!-- ngIf: troopsType == 'input' -->
																	</td>
																</tr>
															</tbody>
															<!-- ngIf: troopData.supply -->
															<!-- ngIf: troopData.cagedAnimals -->
														</table>
													</div>
												</div>
												<div v-if="selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_SPY" class="additionalInfo">
													<div class="dropdownContainer" dropdown="" data="spyTarget">
														<div class="dropdownHead openDownwards clickable closed" clickable="clickDropdown()">
															<span class="selectedItem truncated">
																<span>Разведать ресурсы и войска</span>
															</span>
															<i class="symbol_arrowDown_tiny_illu"></i>
														</div>
														<input class="dropdownInput" type="text"/>
													</div>
													<div scrollable="" class="dropdownBody scrollable openDownwards" style="display: block; height: 49px;">
														<div class="scrollContentOuterWrapper" style="width: 350px;">
															<div class="scrollContent" dynamic-height="false" style="display: block; width: 350px; overflow-y: hidden;">
																<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
																	<ul>
																		<!-- ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem0 truncated selected" ng-class="{selected: '0' == data.selected, disabledEntries: data.disabledEntries.indexOf('0') > -1}" clickable="selectItem(key)">
																			<span>Разведать ресурсы и войска</span>
																		</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem1 truncated" ng-class="{selected: '1' == data.selected, disabledEntries: data.disabledEntries.indexOf('1') > -1}" clickable="selectItem(key)">
																			<span>Разведать войска и оборонительные сооружения</span>
																		</li><!-- end ngRepeat: (key, option) in data.options track by $index -->
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div ng-if="numCatapults > 0 && rallyPoint.data.lvl >= 3 && isOasis == 0" class="additionalInfo">
													<div v-if="selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_ATTACK || selectedTab.missionType == TroopsModel.MOVEMENT_TYPE_SIEGE" class="catapultTargetContainer">
														<i class="unitSmall gaul unitType8" unit-icon="" data="troopDetails.tribeId, 8" tooltip="" tooltip-translate="Troop_NaN"></i>
														<span><span>цель катапульт</span></span>
														<div class="dropdownContainer" dropdown="" ng-show="rallyPoint.data.lvl >= 3" data="catapultTargets1">
															<div class="dropdownHead openDownwards clickable opened" clickable="clickDropdown()">
																<span class="selectedItem truncated">
																	<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}">Случайно</span>
																</span>
																<i class="symbol_arrowDown_tiny_illu"></i>
															</div>
															<input class="dropdownInput" type="text"/>
														</div>
														<div scrollable="" class="dropdownBody scrollable openDownwards" style="display: block; left: 672.438px; height: 200px; top: 503px;">
															<div class="scrollContentOuterWrapper active" style="width: 159px;">
																<div class="scrollContent" dynamic-height="false" style="display: block; width: 179px; overflow-y: auto;">
																	<div class="scrollContentInnerWrapper" ng-transclude="" style="width: 159px;">
																		<ul>
																			<!-- ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem0 truncated selected" ng-class="{selected: '0' == data.selected, disabledEntries: data.disabledEntries.indexOf('0') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}">Случайно</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem truncated" ng-class="{selected: '' == data.selected, disabledEntries: data.disabledEntries.indexOf('') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupLabel">Ресурсы</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem1 truncated" ng-class="{selected: '1' == data.selected, disabledEntries: data.disabledEntries.indexOf('1') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Лесопилка</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem2 truncated" ng-class="{selected: '2' == data.selected, disabledEntries: data.disabledEntries.indexOf('2') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Глиняный карьер</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem3 truncated" ng-class="{selected: '3' == data.selected, disabledEntries: data.disabledEntries.indexOf('3') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Железный рудник</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem4 truncated" ng-class="{selected: '4' == data.selected, disabledEntries: data.disabledEntries.indexOf('4') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Ферма</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem5 truncated" ng-class="{selected: '5' == data.selected, disabledEntries: data.disabledEntries.indexOf('5') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Пилорама</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem6 truncated" ng-class="{selected: '6' == data.selected, disabledEntries: data.disabledEntries.indexOf('6') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Кирпичный завод</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem7 truncated" ng-class="{selected: '7' == data.selected, disabledEntries: data.disabledEntries.indexOf('7') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Литейный завод</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem8 truncated" ng-class="{selected: '8' == data.selected, disabledEntries: data.disabledEntries.indexOf('8') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Мельница</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem9 truncated" ng-class="{selected: '9' == data.selected, disabledEntries: data.disabledEntries.indexOf('9') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Пекарня</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem truncated" ng-class="{selected: '' == data.selected, disabledEntries: data.disabledEntries.indexOf('') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupLabel">Инфраструктура</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem10 truncated" ng-class="{selected: '10' == data.selected, disabledEntries: data.disabledEntries.indexOf('10') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Склад</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem11 truncated" ng-class="{selected: '11' == data.selected, disabledEntries: data.disabledEntries.indexOf('11') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Амбар</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem15 truncated" ng-class="{selected: '15' == data.selected, disabledEntries: data.disabledEntries.indexOf('15') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Главное здание</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem17 truncated" ng-class="{selected: '17' == data.selected, disabledEntries: data.disabledEntries.indexOf('17') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Рынок</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem18 truncated" ng-class="{selected: '18' == data.selected, disabledEntries: data.disabledEntries.indexOf('18') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Посольство</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem24 truncated" ng-class="{selected: '24' == data.selected, disabledEntries: data.disabledEntries.indexOf('24') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Ратуша</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem25 truncated" ng-class="{selected: '25' == data.selected, disabledEntries: data.disabledEntries.indexOf('25') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Резиденция</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem26 truncated" ng-class="{selected: '26' == data.selected, disabledEntries: data.disabledEntries.indexOf('26') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Дворец</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem27 truncated" ng-class="{selected: '27' == data.selected, disabledEntries: data.disabledEntries.indexOf('27') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Сокровищница</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem28 truncated" ng-class="{selected: '28' == data.selected, disabledEntries: data.disabledEntries.indexOf('28') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Торговая палата</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem35 truncated" ng-class="{selected: '35' == data.selected, disabledEntries: data.disabledEntries.indexOf('35') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Пивоварня</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem truncated" ng-class="{selected: '' == data.selected, disabledEntries: data.disabledEntries.indexOf('') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupLabel">Войска</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem13 truncated" ng-class="{selected: '13' == data.selected, disabledEntries: data.disabledEntries.indexOf('13') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Кузница</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem14 truncated" ng-class="{selected: '14' == data.selected, disabledEntries: data.disabledEntries.indexOf('14') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Арена</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem16 truncated" ng-class="{selected: '16' == data.selected, disabledEntries: data.disabledEntries.indexOf('16') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Пункт сбора</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem19 truncated" ng-class="{selected: '19' == data.selected, disabledEntries: data.disabledEntries.indexOf('19') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Казарма</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem20 truncated" ng-class="{selected: '20' == data.selected, disabledEntries: data.disabledEntries.indexOf('20') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Конюшня</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem21 truncated" ng-class="{selected: '21' == data.selected, disabledEntries: data.disabledEntries.indexOf('21') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Мастерская</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem22 truncated" ng-class="{selected: '22' == data.selected, disabledEntries: data.disabledEntries.indexOf('22') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Академия</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --><li ng-repeat="(key, option) in data.options track by $index" class="dropdownOption dropDownItem46 truncated" ng-class="{selected: '46' == data.selected, disabledEntries: data.disabledEntries.indexOf('46') > -1}" clickable="selectItem(option.key)">
																				<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}" class="groupMember">Целебный шатер</span>
																			</li><!-- end ngRepeat: (key, option) in data.options track by $index --></ul></div>
																</div>
															</div>
														</div>
														<div ng-show="numCatapults >= 20 && rallyPoint.data.lvl >= 20" class="secondCatapultTarget ng-hide">
															<span><span>2-я цель</span></span>
															<div class="dropdownContainer" dropdown="" data="catapultTargets2"><div class="dropdownHead openDownwards closed clickable" clickable="clickDropdown()"><span class="selectedItem truncated">
																<span ng-class="{groupLabel: option.type=='group',groupMember: option.type=='member'}">Нет</span>
															</span><i class="symbol_arrowDown_tiny_illu"></i></div><input class="dropdownInput" type="text"></div>
														</div>
													</div>
												</div>
												<div class="additionalInfo ng-hide" ng-show="showRedeployHero()">
													<label class="clickInputLabel">
														<span><span>Прописать героя</span></span>
														<div class="clickInputWrapper">
															<input type="checkbox" ng-model="input.redeployHero" id="redeployHero" class="ng-pristine ng-untouched ng-valid"/>
															<i class="action_check_tiny_flat_black"></i>
														</div>
													</label>
												</div>
											</div>
										</div>
										<div class="error" v-if="error">
											<span>
												<span>{{error}}</span>
											</span>
										</div>
										<div ng-show="any" class="dialogWarning" keys="RallyPoint.SendTroops.CatapultRaid:false, RallyPoint.SendTroops.Warning.PendingRelocation:, RallyPoint.SendTroops.Warning.MaxMovementsPerTarget:false" content="warningMsg">
											<div class="warningHeader">
												<i class="symbol_warning_tiny_flat_white"></i>
												<span><span>Предупреждение:</span></span>
											</div>
											<div class="warningContent" :class="{'single': warnings.length == 1}">
												<ul>
													<li v-for="w in warnings" options="AttackSelfWarning">
														<span>{{w}}</span>
													</li>
												</ul>
											</div>
										</div>
										<div class="buttonContainer">
											<button class="next clickable" ng-class="{disabled: villageSearch.target == null || totalTroopCount <= 0 || error || selectedTab.missionType == null || (pendingRelocation && totalTroopCount > 0 && !onlyHero)}" clickable="checkTargetSend(1)" @click="onCheckTargetSend" v-if="selectedTab.page == 'choose-troops'">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span><span>Продолжить</span></span>
													</span>
													<!-- ngIf: price > 0 || forceGoldUsage -->
													<!-- ngIf: price == -1 && !forceGoldUsage -->
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
											<button class="sendTroops clickable" ng-class="sendTypeClass" clickable="sendTroopsConfirm()" @click="onSendTroopsConfirm" play-on-click="14" v-if="selectedTab.page == 'confirm-troops'">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span><span>Отправить</span></span>
													</span>
													<!-- ngIf: price > 0 || forceGoldUsage -->
													<!-- ngIf: price == -1 && !forceGoldUsage -->
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
											<button class="back clickable" @click="onGoBack" v-if="selectedTab.page == 'confirm-troops' && selectedTab.missionType != TroopsModel.MOVEMENT_TYPE_SETTLE">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span><span>Назад</span></span>
													</span>
													<!-- ngIf: price > 0 || forceGoldUsage -->
													<!-- ngIf: price == -1 && !forceGoldUsage -->
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
.societyFilter {
	float: right;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import TroopsModel from '@/models/game/TroopsModel';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Отправить войска | IW'});
	},
	data: () => ({
		TroopsModel,
		
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		villageBackgroundLeft: '0px',
		
		sendTabs: [],
		activeTab: null,
		
		missionTypes: [
			{
				id: TroopsModel.MOVEMENT_TYPE_SUPPORT,
				state: 'unselected',
				name: 'Подкрепление',
				tooltip: 'Подкрепление',
			},
			{
				id: TroopsModel.MOVEMENT_TYPE_ATTACK,
				state: 'unselected',
				name: 'Атака',
				tooltip: 'Атака: Обычная - Основной задачей отряда будет уничтожение противника. Битва закончится, когда войска одной из сторон будут полностью уничтожены.',
			},
			{
				id: TroopsModel.MOVEMENT_TYPE_RAID,
				state: 'unselected',
				name: 'Набег',
				tooltip: 'Атака: Набег - Основной задачей отряда будет кража ресурсов, стараясь при этом потерять как можно меньше воинов.',
			},
			{
				id: TroopsModel.MOVEMENT_TYPE_SPY,
				state: 'deactivated',
				name: 'Разведка',
				tooltip: 'Разведка. Для разведки нужно определенное войско: Следопыт!',
			},
			{
				id: TroopsModel.MOVEMENT_TYPE_SIEGE,
				state: 'deactivated',
				name: 'Осада',
				tooltip: 'Осада. Ты можешь начать осаду, только если у тебя имеется 1000 войск и минимум один таран!',
			},
			{
				id: TroopsModel.MOVEMENT_TYPE_SETTLE,
				state: 'hidden',
				name: 'Основание поселения',
				tooltip: 'Основание поселения',
			},
		],
		
		warnings: [
			'Ты действительно хочешь атаковать самого себя?',
		],
		error: 'Войска уже в этой деревне', //Деревня не найдена
	}),
	computed: {
		showMoreTabs(){
			return this.sendTabs.length < 7;
		},
		selectedTab(){
			return this.sendTabs.find(tab => tab.id == this.activeTab)||{};
        },
		selectedMissionType(){
			return this.missionTypes.find(type => type.id == this.selectedTab.missionType);
		},
	},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onAddTab(missionType = null, setActive = true){
			let id = lib.genId();
			let missionTypeInfo = this.missionTypes.find(type => type.id == missionType);
			this.sendTabs.push({
				id,
				name: missionTypeInfo ? missionTypeInfo.name : 'Выбрать миссию',
				page: missionType != TroopsModel.MOVEMENT_TYPE_SETTLE ? 'choose-troops' : 'confirm-troops',
				missionType: missionType,
			});
			if(setActive) this.activeTab = id;
		},
		onToggleTab(tab){
			this.activeTab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_8.ogg');
				audio.play();
			}
		},
		onCloseTab(tab){
			this.sendTabs.splice(this.sendTabs.findIndex(el => el.id == tab), 1);
			if(this.activeTab == tab){
				this.activeTab = this.sendTabs[this.sendTabs.length - 1].id;
			}
		},
		onSelectMissionType(type_id){
			for(let missionType of this.missionTypes){
				if(missionType.state == 'selected'){
					missionType.state = 'unselected';
				}
			}
			let selMissionType = this.missionTypes.find(el => el.id == type_id);
			if(selMissionType) selMissionType.state = 'selected';
			this.selectedTab.missionType = type_id;
			this.selectedTab.name = selMissionType ? selMissionType.name : 'Выбрать миссию';
		},
		onCheckTargetSend(){
			this.selectedTab.page = 'confirm-troops';
		},
		onGoBack(){
			this.selectedTab.page = 'choose-troops';
		},
		onSendTroopsConfirm(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_14.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		this.onAddTab();
		this.onAddTab(TroopsModel.MOVEMENT_TYPE_SETTLE, false);
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	components: {},
}
</script>
import axios from 'axios';
import lib from '@/lib';

import PlayerModel from '@/models/game/PlayerModel';
import BuildingsModel from '@/models/game/BuildingsModel';
import BuildingQueueModel from '@/models/game/BuildingQueueModel';
import TroopsModel from '@/models/game/TroopsModel';
import InventoryModel from '@/models/game/InventoryModel';
import StorageModel from '@/models/game/buildings/StorageModel';
import AuctionsModel from '@/models/game/AuctionsModel';
import QuestsModel from '@/models/game/QuestsModel';
import HelpModel from '@/models/game/HelpModel';
import ReportsModel from '@/models/game/ReportsModel';
import SocietyModel from '@/models/game/SocietyModel';
import NotificationsModel from '@/models/game/NotificationsModel';
import FlashNotificationsModel from '@/models/game/FlashNotificationsModel';
import TimedNotificationsModel from '@/models/game/TimedNotificationsModel';
import TimezonesModel from '@/models/game/TimezonesModel';
import NotepadsModel from '@/models/game/NotepadsModel';
import CardGameModel from '@/models/game/CardGameModel';
import ShopModel from '@/models/game/ShopModel';

export default class GameModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		// Инициализация
		this.quickLinksShow = true;
		this.villageListShow = true;
		this.userAreaShow = true;
		this.heroQuickInfoShow = true;
		this.questCurrentTaskShow = true;
		this.notepadsShow = true;
		this.troopsOverviewShow = true;
		this.helpNotificationsShow = true;
		this.chatFooterBarShow = true;
		this.serverTimeShow = true;
		this.contextMenuShow = false;
		
		this.tutorialArrow = {
			show: false,
			left: 949,
			top: 264,
			side: 'down', // up, down
		};
		
		this.helpNotificationOpen = true;
		this.windowLoadingOpen = false;
		this.pendingAjaxRequestOpen = false;
		this.loadingScreenOpen = false;
		this.modalWindowBlockerOpen = false;
		this.contextMenuOpen = false;
		this.notepadOpen = false;
		this.chatMenuOpen = false;
		
		this.soundOn = true;
		
		this.currentServerTime = Date.now();
		
		this.player = new PlayerModel();
		
		this.society = new SocietyModel();
		this.societies = [
			{
				id: 1,
				name: 'Царство ТС',
			},
		];
		this.settings = {
			timeTypeSwitcher: true,
		};
		this.config = {
			balancing: {
				features: {
					prestige: true,
				},
			},
		};
		
		this.unlockAdditionalLocationShow = false;
		
		this.buildingsInfo = {};
		
		this.islands = [];
		this.island = {
			id: null,
			name: 'Остров',
			main: 1,
			titleScore: 0,
			loyalty: 100,
			x: 0,
			y: 0,
			
			buildings: [],
			
			storage: new StorageModel(),
			
			cropConsumptionTroops: 0,
			cropConsumptionPopulation: 0,
		};
		
		this.SidebarMenu = {
			show: true,
			animateIgm: false,
			chat: {
				hasUnreadEntries: true,
			},
		};
		
		this.TopMenu = {
			show: true,
			
			goldPlus: false,
			goldMinus: false,
			
			silverPlus: false,
			silverMinus: false,
			
			bronzePlus: false,
			bronzeMinus: false,
		};
		
		this.StockBar = {
			show: true,
			
			woodCountPlus: false,
			woodCountMinus: false,
			
			clayCountPlus: false,
			clayCountMinus: false,
			
			rockCountPlus: false,
			rockCountMinus: false,
			
			ironCountPlus: false,
			ironCountMinus: false,
			
			cropCountPlus: false,
			cropCountMinus: false,
		};
		
		this.buildingQueue = new BuildingQueueModel();
		
		this.buildings = new BuildingsModel();
		
		this.troops = new TroopsModel();
		
		this.inventory = new InventoryModel();
		
		this.auctions = new AuctionsModel();
		
		this.questBook = new QuestsModel();
		
		this.help = new HelpModel();
		
		this.reports = new ReportsModel();
		
		this.notifications = new NotificationsModel();
		
		this.flashNotifications = new FlashNotificationsModel();
		
		this.timedNotifications = new TimedNotificationsModel();
		
		this.timezones = new TimezonesModel();
		
		this.notepads = new NotepadsModel();
		
		this.cardGame = new CardGameModel();
		
		this.shop = new ShopModel();
	}
	
	// первоначальная загрузка данных
	async loadStartInfo(){
		this.pendingAjaxRequestOpen = true;
		this.windowLoadingOpen = true;
		
		await fetch('/api/data/start-info', {
			mode: 'cors',
			credentials: 'include',
			headers: {
				Authorization: 'Bearer '+storeInstance.state.app.auth.token,
			},
		}).then(stream => stream.json()).then(data => {
			for(let [key, val] of Object.entries(data.player)){
				this.player[key] = val;
			}
			this.config = data.config;
			
			this.islands = data.islands;
			
			this.island.id = data.islandInfo.island.id || null;
			this.island.name = data.islandInfo.island.name || 'Остров';
			this.island.main = data.islandInfo.island.main || 1;
			this.island.titleScore = data.islandInfo.island.titleScore || 0;
			this.island.loyalty = data.islandInfo.island.loyalty || 100;
			this.island.x = data.islandInfo.island.x || 0;
			this.island.y = data.islandInfo.island.y || 0;
			
			this.island.buildings = data.islandInfo.buildings || [];
			
			this.buildingsInfo = data.buildingsInfo;
			
			this.island.storage.woodCountMax = data.islandInfo.resources.maxCapacity;
			this.island.storage.clayCountMax = data.islandInfo.resources.maxCapacity;
			this.island.storage.rockCountMax = data.islandInfo.resources.maxCapacity;
			this.island.storage.ironCountMax = data.islandInfo.resources.maxCapacity;
			this.island.storage.cropCountMax = data.islandInfo.resources.maxCapacity;
			
			this.island.storage.woodCount = data.islandInfo.resources.numWood;
			this.island.storage.woodCountOld = data.islandInfo.resources.numWood;
			
			this.island.storage.clayCount = data.islandInfo.resources.numClay;
			this.island.storage.clayCountOld = data.islandInfo.resources.numClay;
			
			this.island.storage.rockCount = data.islandInfo.resources.numRock;
			this.island.storage.rockCountOld = data.islandInfo.resources.numRock;
			
			this.island.storage.ironCount = data.islandInfo.resources.numIron;
			this.island.storage.ironCountOld = data.islandInfo.resources.numIron;
			
			this.island.storage.cropCount = data.islandInfo.resources.numCrop;
			this.island.storage.cropCountOld = data.islandInfo.resources.numCrop;
			
			this.island.storage.woodProduction = data.islandInfo.buildings.sawmill.numPerTurn;
			this.island.storage.clayProduction = data.islandInfo.buildings.career.numPerTurn;
			this.island.storage.rockProduction = data.islandInfo.buildings.stone.numPerTurn;
			this.island.storage.ironProduction = data.islandInfo.buildings.mine.numPerTurn;
			this.island.storage.cropProduction = data.islandInfo.buildings.farm.numPerTurn;
			
			this.island.cropConsumptionTroops = data.cropConsumptionTroops;
			this.island.cropConsumptionPopulation = data.cropConsumptionPopulation;
			
			this.pendingAjaxRequestOpen = false;
			this.windowLoadingOpen = false;
		}).catch(error => {
			console.error(error);
			this.pendingAjaxRequestOpen = false;
			this.windowLoadingOpen = false;
		});
	}
	
	
	
	// actions
	actPendingAjaxRequest()
	{
		this.pendingAjaxRequestOpen = true;
		setTimeout(() => {
			this.pendingAjaxRequestOpen = false;
		}, 1000);
	}
	
	actWoodCountPlus(count = 1)
	{
		this.island.storage.woodCount += this.island.storage.woodCount + count <= this.island.storage.woodCountMax ? count : this.island.storage.woodCountMax - this.island.storage.woodCount;
		
		this.StockBar.woodCountPlus = true;
		setTimeout(() => {
			this.StockBar.woodCountPlus = false;
			this.island.storage.woodCountOld = this.island.storage.woodCount;
		}, 1000);
	}
	actWoodCountMinus(count = 1)
	{
		this.island.storage.woodCount -= this.island.storage.woodCount >= count ? count : this.island.storage.woodCount;
		
		this.StockBar.woodCountMinus = true;
		setTimeout(() => {
			this.StockBar.woodCountMinus = false;
			this.island.storage.woodCountOld = this.island.storage.woodCount;
		}, 1000);
	}
	
	actClayCountPlus(count = 1)
	{
		this.island.storage.clayCount += this.island.storage.clayCount + count <= this.island.storage.clayCountMax ? count : this.island.storage.clayCountMax - this.island.storage.clayCount;
		
		this.StockBar.clayCountPlus = true;
		setTimeout(() => {
			this.StockBar.clayCountPlus = false;
			this.island.storage.clayCountOld = this.island.storage.clayCount;
		}, 1000);
	}
	actClayCountMinus(count = 1)
	{
		this.island.storage.clayCount -= this.island.storage.clayCount >= count ? count : this.island.storage.clayCount;
		
		this.StockBar.clayCountMinus = true;
		setTimeout(() => {
			this.StockBar.clayCountMinus = false;
			this.island.storage.clayCountOld = this.island.storage.clayCount;
		}, 1000);
	}
	
	actRockCountPlus(count = 1)
	{
		this.island.storage.rockCount += this.island.storage.rockCount + count <= this.island.storage.rockCountMax ? count : this.island.storage.rockCountMax - this.island.storage.rockCount;
		
		this.StockBar.rockCountPlus = true;
		setTimeout(() => {
			this.StockBar.rockCountPlus = false;
			this.island.storage.rockCountOld = this.island.storage.rockCount;
		}, 1000);
	}
	actRockCountMinus(count = 1)
	{
		this.island.storage.rockCount -= this.island.storage.rockCount >= count ? count : this.island.storage.rockCount;
		
		this.StockBar.rockCountMinus = true;
		setTimeout(() => {
			this.StockBar.rockCountMinus = false;
			this.island.storage.rockCountOld = this.island.storage.rockCount;
		}, 1000);
	}
	
	actIronCountPlus(count = 1)
	{
		this.island.storage.ironCount += this.island.storage.ironCount + count <= this.island.storage.ironCountMax ? count : this.island.storage.ironCountMax - this.island.storage.ironCount;
		
		this.StockBar.ironCountPlus = true;
		setTimeout(() => {
			this.StockBar.ironCountPlus = false;
			this.island.storage.ironCountOld = this.island.storage.ironCount;
		}, 1000);
	}
	actIronCountMinus(count = 1)
	{
		this.island.storage.ironCount -= this.island.storage.ironCount >= count ? count : this.island.storage.ironCount;
		
		this.StockBar.ironCountMinus = true;
		setTimeout(() => {
			this.StockBar.ironCountMinus = false;
			this.island.storage.ironCountOld = this.island.storage.ironCount;
		}, 1000);
	}
	
	actCropCountPlus(count = 1)
	{
		this.island.storage.cropCount += this.island.storage.cropCount + count <= this.island.storage.cropCountMax ? count : this.island.storage.cropCountMax - this.island.storage.cropCount;
		
		this.StockBar.cropCountPlus = true;
		setTimeout(() => {
			this.StockBar.cropCountPlus = false;
			this.island.storage.cropCountOld = this.island.storage.cropCount;
		}, 1000);
	}
	actCropCountMinus(count = 1)
	{
		this.island.storage.cropCount -= this.island.storage.cropCount >= count ? count : this.island.storage.cropCount;
		
		this.StockBar.cropCountMinus = true;
		setTimeout(() => {
			this.StockBar.cropCountMinus = false;
			this.island.storage.cropCountOld = this.island.storage.cropCount;
		}, 1000);
	}
	
	actGoldPlus(count = 1)
	{
		this.player.gold += count;
		
		this.TopMenu.goldPlus = true;
		setTimeout(() => {
			this.TopMenu.goldPlus = false;
		}, 1000);
	}
	actGoldMinus(count = 1)
	{
		this.player.gold -= count;
		
		this.TopMenu.goldMinus = true;
		setTimeout(() => {
			this.TopMenu.goldMinus = false;
		}, 1000);
	}
	
	actSilverPlus(count = 1)
	{
		this.player.silver += count;
		
		this.TopMenu.silverPlus = true;
		setTimeout(() => {
			this.TopMenu.silverPlus = false;
		}, 1000);
	}
	actSilverMinus(count = 1)
	{
		this.player.silver -= count;
		
		this.TopMenu.silverMinus = true;
		setTimeout(() => {
			this.TopMenu.silverMinus = false;
		}, 1000);
	}
	
	actBronzePlus(count = 1)
	{
		this.player.bronze += count;
		
		var that = this;
		this.TopMenu.bronzePlus = true;
		setTimeout(() => {
			this.TopMenu.bronzePlus = false;
		}, 1000);
	}
	actBronzeMinus(count = 1)
	{
		this.player.bronze -= count;
		
		this.TopMenu.bronzeMinus = true;
		setTimeout(() => {
			this.TopMenu.bronzeMinus = false;
		}, 1000);
	}
	
	actChangeAnimateIgm()
	{
		this.SidebarMenu.animateIgm = true;
		setTimeout(() => {
			this.SidebarMenu.animateIgm = false;
		}, 1000);
	}
}

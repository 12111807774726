<template>
	<div class="chatHeader">
		<div class="chat-room-member">
			<div class="chatMember clickable" ng-class="{disabled: disabled}">
				<div class="playerBox">
					<avatar-image :src="avatar" :size="62" :player-id="1" avatar-class="playerAvatar" />
				</div>
				<div class="chat-online-status chatStatus"></div>
				<div class="prestigeStars">
					<div class="innerStars">
						<div>
							<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
							<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
							<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a class="playerName playerLink truncated clickable">{{chatInfo.data.chatUser.data.name}}</a>
		<div class="membership">
			<a class="kingdomLink truncated clickable disabled">Арма-15</a>
		</div>
		<div :class="['flagHanger', chatInfo.data.chatUser.data.kingdomRole]">
			<div class="flag"></div>
			<i :class="{'community_viceking_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'viceking', 'community_duke_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'duke', 'community_governor_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'governor', 'community_king_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'king'}"></i>
		</div>
		<div class="controls">
			<i @mouseover="onMouseOver('cancelHover')" @mouseleave="onMouseLeave('cancelHover')" :class="['control', 'clickable', {'action_cancel_tiny_flat_black': !cancelHover, 'action_cancel_tiny_flat_green': cancelHover}]" @click="onCloseActiveTab(chatInfo.data.roomId)"></i>
			<i @mouseover="onMouseOver('minimizeHover')" @mouseleave="onMouseLeave('minimizeHover')" :class="['control', 'clickable',, {'window_minimize_tiny_flat_black': !minimizeHover, 'window_minimize_tiny_flat_green': minimizeHover}]" @click="onMinimizeActiveTab(chatInfo.data.roomId)"></i>
			<i @mouseover="onMouseOver('undockHover')" @mouseleave="onMouseLeave('undockHover')" :class="['control', 'clickable', {'window_undock_tiny_flat_black': !undockHover, 'window_undock_tiny_flat_green': undockHover}]"></i>
		</div>
		<div class="options">
			<a class="iconButton clickable">
				<i class="symbol_arrowDown_tiny_illu"></i>
			</a>
		</div>
	</div>
</template>

<style lang="scss">

</style>

<script>
import lib from '@/lib';

import AvatarImage from '@/components/elements/AvatarImage';

export default {
	mixins: lib.mixins,
	props: ['chatInfo'],
	data: () => ({
		cancelHover: false,
		minimizeHover: false,
		undockHover: false,
	}),
	computed: {
		avatar(){
			var avatars = [
				'angel.png',
				'angelica.png',
				'bastard.png',
				'valkiria.png',
				'demon.png',
				'demonessa.png',
				'elf.png',
				'elfiyka.png',
				'gnom.png',
				'gnomma.png',
				'goblin.png',
				'goblina.png',
				'skelet.png',
				'skeletessa.png',
				'trol.png',
				'trolessa.png',
			];
			return '//iwstatic.g.bsrv.su/resources/avatars/125/'+avatars[Math.floor(Math.random() * avatars.length)];
		},
	},
	methods: {
		onMouseLeave(el = ''){
			if(el == 'cancelHover') this.cancelHover = false;
			if(el == 'minimizeHover') this.minimizeHover = false;
			if(el == 'undockHover') this.undockHover = false;
		},
		onMouseOver(el = ''){
			if(el == 'cancelHover') this.cancelHover = true;
			if(el == 'minimizeHover') this.minimizeHover = true;
			if(el == 'undockHover') this.undockHover = true;
		},
		onCloseActiveTab(roomId){
			this.$emit('onCloseActiveTab', roomId);
		},
		onMinimizeActiveTab(roomId){
			this.$emit('onMinimizeActiveTab', roomId);
		},
	},
	components: {
		AvatarImage,
	},
}
</script>
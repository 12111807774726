<template>
	<div id="userArea">
		<a href="https://iwpanel.g.bsrv.su" id="logoutButton" class="headerButton clickable" content="Выйти" v-tippy="{placement: 'bottom-end'}">
			<i class="userArea_logout_small_flat_white"></i>
			<div class="arrow"></div>
		</a>
		<!--
		<router-link :to="{path: '/'}" v-slot="{href, route, navigate, isActive, isExactActive}" custom>
			<a :href="href" @click="navigate" id="logoutButton" class="headerButton clickable" content="Выйти" v-tippy="{placement: 'bottom-end'}">
				<i class="userArea_logout_small_flat_white"></i>
				<div class="arrow"></div>
			</a>
		</router-link>
		-->
		<router-link :to="{path: '/profile', query: {tab: 'player-profile'}}" id="userNameButton" class="headerButton clickable" content="Открыть свой профиль" v-tippy="{placement: 'bottom'}">
			<span class="text">{{authModel.userProfile.login}}</span>
			<i class="userArea_player_small_white"></i>
			<div class="arrow"></div>
		</router-link>
		<router-link :to="{path: '/profile', query: {tab: 'settings'}}" id="settingsButton" class="headerButton clickable" content="Настройки" v-tippy="{placement: 'bottom'}">
			<i class="userArea_settings_small_flat_white"></i>
			<div class="arrow"></div>
		</router-link>
		<router-link :to="{path: '/help'}" id="helpButton" class="headerButton clickable" content="Помощь" v-tippy="{placement: 'bottom'}">
			<i class="symbol_questionMark_small_flat_white"></i>
			<div class="arrow"></div>
		</router-link>
		<a id="soundButton" class="headerButton">
			<div class="headerButtonWrapper clickable" @click="onToggleSound" :content="gameModel.soundOn ? 'Отключить звук' : 'Включить звук'" v-tippy="{placement: 'bottom'}">
				<i :class="{'userArea_soundOn_small_flat_white': gameModel.soundOn, 'userArea_soundOff_small_flat_white': !gameModel.soundOn}"></i>
				<div class="arrow"></div>
			</div>
		</a>
		<a id="notepadButton" class="headerButton clickable" @click="onToggleNotepad" content="Показать/скрыть блокнот" v-tippy="{placement: 'bottom'}">
			<i class="userArea_notepad_small_flat_white"></i>
			<div class="arrow"></div>
		</a>
		<a id="forumButton" class="headerButton clickable" content="Форум Islands Wars" v-tippy="{placement: 'bottom'}">
			<i class="userArea_forum_small_flat_white"></i>
			<div class="arrow"></div>
		</a>
		<a href="https://iwpanel.g.bsrv.su/wiki" target="_blank" id="wikiButton" class="headerButton clickable" content="Wiki" v-tippy="{placement: 'bottom'}">
			<i class="userArea_wiki_small_flat_white"></i>
			<div class="arrow"></div>
		</a>
		<a class="headerButton clickable" @click="openBuilding('houseModal')" content="Modal" v-tippy="{placement: 'bottom'}">
			<i class="userArea_wiki_small_flat_white"></i>
			<div class="arrow"></div>
		</a>
	</div>
</template>

<style lang="css">
#userArea{
    position:absolute;
    top:0;
    right:15px
}
#userArea .headerButton{
    float:right;
    margin-left:3px;
    color:#252525;
    font-family:Verdana,Arial,Helvetica,sans-serif;
    font-weight:normal;
    font-size:1rem;
    line-height:16px;
    color:#fffff0;
    line-height:18px
}
#userArea .headerButton:hover:not(.disabled):before{
    content:'';
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:auto;
    height:auto;
    background-image:-owg-linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent);
    background-image:-webkit-linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent);
    background-image:-moz-linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent);
    background-image:-o-linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent);
    background-image:linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent)
}
#userArea .headerButton i{
    top:2px
}
#userArea .headerButton .text{
    position:relative
}
#userArea .headerButton#soundButton .headerButtonWrapper{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:auto;
    height:auto
}
.smallerThanWidth1350 #userArea .headerButton#forumButton .text,.smallerThanWidth1350 #userArea .headerButton#wikiButton .text{
    display:none
}
body:not(.smallerThanWidth1350) #userArea .headerButton#forumButton,body:not(.smallerThanWidth1350) #userArea .headerButton#wikiButton{
    width:auto;
    padding:0 5px
}
body:not(.smallerThanWidth1350) #userArea .headerButton#wikiButton{
    padding:0 6px
}
#userArea .headerButton#userNameButton{
    width:auto;
    padding:0 10px
}
#userArea .headerButton#userNameButton i{
    display:none
}
.smallerThanWidth1150 #userArea .headerButton#userNameButton .text{
    display:none
}
.smallerThanWidth1150 #userArea .headerButton#userNameButton i{
    display:inline-block
}
#userArea .headerButton#logoutButton{
    background-image:-owg-linear-gradient(#a60000,#dd3a37,#a60000);
    background-image:-webkit-linear-gradient(#a60000,#dd3a37,#a60000);
    background-image:-moz-linear-gradient(#a60000,#dd3a37,#a60000);
    background-image:-o-linear-gradient(#a60000,#dd3a37,#a60000);
    background-image:linear-gradient(#a60000,#dd3a37,#a60000)
}
#userArea .headerButton#logoutButton:before{
    background-image:-owg-linear-gradient(rgba(255,255,255,0.65),rgba(255,255,255,0.2));
    background-image:-webkit-linear-gradient(rgba(255,255,255,0.65),rgba(255,255,255,0.2));
    background-image:-moz-linear-gradient(rgba(255,255,255,0.65),rgba(255,255,255,0.2));
    background-image:-o-linear-gradient(rgba(255,255,255,0.65),rgba(255,255,255,0.2));
    background-image:linear-gradient(rgba(255,255,255,0.65),rgba(255,255,255,0.2))
}
#userArea .headerButton#logoutButton .arrow:after{
    border-top:5px solid #a60000
}
#userArea .headerButton:last-child{
    margin-left:0
}
</style>

<script>
import lib from '@/lib';

import QuestDirectionSelectionModal from '@/components/modals/QuestDirectionSelection';
import HouseBuildingModal from '@/components/modals/buildings/House';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {
		onToggleSound(){
			this.gameModel.soundOn = !this.gameModel.soundOn;
		},
		onToggleNotepad(){
			this.gameModel.notepads.show = !this.gameModel.notepads.show;
		},
		
		openBuilding(name){
			let modal = null;
			let component_params = {};
			switch(name){
				case 'houseModal':
					modal = HouseBuildingModal;
					break;
				/*case 'dipcentre':
					modal = QuestDirectionSelectionModal;
					component_params = {
						closeable: true,
						contentHeaderText: 'Где ты хочешь основать свою деревню?',
					};
					break;*/
			}
			if(modal != null){
				this.$vfm.show({
					component: modal,
					on: {
						cancel(close){
							close();
						},
					},
					slots: {
						contentHeaderText: 'Где ты хочешь основать свою деревню?',
						default: '',
					},
					params: {
						closeable: true,
						buttons: [
							{on: 'cancel', text: 'Закрыть', classes: 'btn-secondary'},
						],
						draggable: '.drag',
						clickToClose: true,
						width: 668,
					},
				});
			}
		},
	},
	components: {},
}
</script>
<template>
	<div ng-if="overlayController" class="windowOverlay" id="sellStolenGoods" ng-controller="overlayController">
		<div class="darkener clickable" clickable="closeOverlay()"></div>
		<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="659"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
		<div class="inWindowPopupHeader">
			<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Hero.SellTreasures" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Продать ценности</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
			<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
			<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
		</div>
		<div class="inWindowPopupContent">
			<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="sellStolenGoods">
		<div class="itemContainer">
			<span translate=""><span>Как губернатор ты можешь продавать королю краденые ценности, добытые в логовах и лагерях разбойников. В обмен ты получишь ресурсы. Эти ресурсы будут доставлены в деревню, которая открыта в момент продажи. Пожалуйста, выбери, сколько краденых ценностей ты хочешь продать:</span></span>
		</div>

		<div class="contentBoxRow">
			<div class="contentBox player">
				<div class="contentBoxBody">
					<avatar-image class="playerAvatar" scale="1" player-id="734"><!-- rerender: rerender --><div class="heroImage  male" ng-class="::class" rerender="rerender">
		<!-- ngIf: playerId == robberId -->
		<!-- ngIf: playerId == npcGovernorId -->
	<canvas width="125" height="125"></canvas></div>
	</avatar-image>
				</div>
				<div class="contentBoxFooter">
					<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" playername="lexinzector">lexinzector</a>
				</div>
			</div>
			<div class="contentBox treasure">
				<div class="contentBoxBody">
					<i class="treasureImage unit_stolenGoods_huge_illu"></i>
				</div>
				<div class="contentBoxFooter gradient double">
					<!-- ngIf: !soldResources --><span ng-if="!soldResources" class="usingTreasures">&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;125&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: !soldResources -->
					<!-- ngIf: soldResources -->
				</div>
			</div>
			<div class="contentArrow"></div>
			<div class="contentBox king">
				<div class="contentBoxBody">
					<!-- ngIf: buyer --><avatar-image ng-if="buyer" class="kingAvatar" scale="1" player-id="137"><!-- rerender: rerender --><div class="heroImage  male" ng-class="::class" rerender="rerender">
		<!-- ngIf: playerId == robberId -->
		<!-- ngIf: playerId == npcGovernorId -->
	<canvas width="125" height="125"></canvas></div>
	</avatar-image><!-- end ngIf: buyer -->
				</div>
				<div class="contentBoxFooter">
					<i class="community_duke_small_flat_black"></i>
					<!-- ngIf: buyer.data.playerId > 100 --><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="137" playername="" ng-if="buyer.data.playerId > 100">Флавий</a><!-- end ngIf: buyer.data.playerId > 100 -->
					<!-- ngIf: buyer.data.playerId < 100 -->
				</div>
			</div>
		</div>
		<div class="arrow"></div>
		<div class="contentBox treasuresChoice">
			<!-- ngIf: !soldResources --><div ng-if="!soldResources" class="contentBoxHeader headerColored">
				<slider class="treasureSlider" slider-min="1" slider-max="maxAmount" slider-data="useAmount" input-autofocus="true"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
		<div class="trackContainer">
			<div class="track">
				<!-- ngIf: stepCount && !hideSteps -->
				<div class="sliderMarker progressMarker" style="width: 0.8%;"></div>
				<!-- ngRepeat: marker in sliderMarkers track by $index -->
				<div class="hoverIndicator">
					<div class="hoverValue"></div>
					<div class="indicator"></div>
				</div>
				<div class="handle unselectable" style="left: 3.392px;"></div>
			</div>
		</div>
		<div class="inputContainer">
			<!-- ngIf: iconClass -->
			<input class="value ng-pristine ng-valid ng-touched" number="" ng-model="value" type="tel">
			<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: block;">
				<i class="action_setMax_small_flat_black"></i>
			</div>
		</div>
	</div></slider>
			</div><!-- end ngIf: !soldResources -->
			<!-- ngIf: soldResources -->
			<div class="contentBoxHeader headerTrapezoidal">
				<div class="content">
					<span class="treasureDuration">
						<i class="symbol_clock_small_flat_black"></i>
						<!-- ngIf: !soldResources --><span translate="" ng-if="!soldResources" data="duration:1697"><span>Через </span><strong>00:28:17</strong><span> ты получишь:</span></span><!-- end ngIf: !soldResources -->
						<!-- ngIf: soldResources -->
					</span>
				</div>
			</div>
			<div class="contentBoxBody">
				<div class="floatWrapper costs">
					<!-- ngRepeat: resource in ['wood', 'clay', 'iron'] --><div class="resourceBig" ng-repeat="resource in ['wood', 'clay', 'iron']">
						<div class="resourceWrapper">
							<p><i class="unit_wood_large_illu"></i></p>

							<!-- ngIf: !soldResources --><p ng-if="!soldResources" class="resourceDescription colorPositive" ng-class="{colorPositive: fittingInStorage($index+1) &amp;&amp; treasurePrice[$index+1] > 0, colorNegative: (!fittingInStorage($index+1) || treasurePrice[$index+1] < 0)}">
								<span class="resource">&#x202D;+&#x202D;1250&#x202C;&#x202C;</span>
							</p><!-- end ngIf: !soldResources -->
							<!-- ngIf: soldResources -->
						</div>
					</div><!-- end ngRepeat: resource in ['wood', 'clay', 'iron'] --><div class="resourceBig" ng-repeat="resource in ['wood', 'clay', 'iron']">
						<div class="resourceWrapper">
							<p><i class="unit_clay_large_illu"></i></p>

							<!-- ngIf: !soldResources --><p ng-if="!soldResources" class="resourceDescription colorPositive" ng-class="{colorPositive: fittingInStorage($index+1) &amp;&amp; treasurePrice[$index+1] > 0, colorNegative: (!fittingInStorage($index+1) || treasurePrice[$index+1] < 0)}">
								<span class="resource">&#x202D;+&#x202D;1650&#x202C;&#x202C;</span>
							</p><!-- end ngIf: !soldResources -->
							<!-- ngIf: soldResources -->
						</div>
					</div><!-- end ngRepeat: resource in ['wood', 'clay', 'iron'] --><div class="resourceBig" ng-repeat="resource in ['wood', 'clay', 'iron']">
						<div class="resourceWrapper">
							<p><i class="unit_iron_large_illu"></i></p>

							<!-- ngIf: !soldResources --><p ng-if="!soldResources" class="resourceDescription colorPositive" ng-class="{colorPositive: fittingInStorage($index+1) &amp;&amp; treasurePrice[$index+1] > 0, colorNegative: (!fittingInStorage($index+1) || treasurePrice[$index+1] < 0)}">
								<span class="resource">&#x202D;+&#x202D;1250&#x202C;&#x202C;</span>
							</p><!-- end ngIf: !soldResources -->
							<!-- ngIf: soldResources -->
						</div>
					</div><!-- end ngRepeat: resource in ['wood', 'clay', 'iron'] -->

					<div class="cropTooltipArea" content="Количество зерна, которое ты получаешь за продажу краденых ценностей, зависит от количества сокровищ в королевстве. Если ты продаешь их в другом королевстве, ты не получишь за них зерно." v-tippy="{placement: 'top'}" tooltip-translate="Hero.SellTreasures.CropTooltip" tooltip-show="true" tooltip-class="treasureCrop" tooltip-placement="above" tooltip-child-class="symbol_i_tiny_wrapper">
						<div class="symbol_i_tiny_wrapper">
							<i class="symbol_i_tiny_flat_white"></i>
						</div>
					</div>
					<div class="resourceBig">
						<div class="resourceWrapper">
							<!-- ngIf: village.data.belongsToKingdom != player.data.kingdomId || buyer.data.playerId < 100 -->
							<div class="cropToTreasure">
								<i class="treasureIcon unit_treasure_medium_illu"></i>
								<i class="unit_crop_large_illu"></i>
							</div>
							<!-- ngIf: !soldResources --><p ng-if="!soldResources" class="resourceDescription colorPositive" ng-class="{colorPositive: fittingInStorage(4) &amp;&amp; treasurePrice[4] > 0, colorNegative: (!fittingInStorage(4) || treasurePrice[4] < 0)}">
								<span class="resource">&#x202D;+&#x202D;7350&#x202C;&#x202C;</span>
							</p><!-- end ngIf: !soldResources -->
							<!-- ngIf: soldResources -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="buttonBar">
			<span>
				<!-- ngIf: treasurePrice && !inboundTroopId --><button ng-if="treasurePrice &amp;&amp; !inboundTroopId" class="useItemButton clickable" clickable="sellTreasures()" play-on-click="18"><div class="content">
		<span ng-transclude="" class="innerText">
					<span translate=""><span>Продать ценности</span></span>
				</span>
		<!-- ngIf: price > 0 || forceGoldUsage -->
		<!-- ngIf: price == -1 && !forceGoldUsage -->

		<div class="animationOverlay"></div>
		<i class="checkmark action_check_small_flat_white"></i>
		<i class="checkmark action_check_small_flat_black"></i>
		<i class="checkmark action_check_small_flat_red"></i>
	</div></button><!-- end ngIf: treasurePrice && !inboundTroopId -->
				<!-- ngIf: inboundTroopId -->
				<button clickable="closeOverlay()" class="cancel clickable"><div class="content">
		<span ng-transclude="" class="innerText">
					<span translate=""><span>Отмена</span></span>
				</span>
		<!-- ngIf: price > 0 || forceGoldUsage -->
		<!-- ngIf: price == -1 && !forceGoldUsage -->

		<div class="animationOverlay"></div>
		<i class="checkmark action_check_small_flat_white"></i>
		<i class="checkmark action_check_small_flat_black"></i>
		<i class="checkmark action_check_small_flat_red"></i>
	</div></button>
			</span>

		</div>
	</div>
	</div>
		</div>
	</div></div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
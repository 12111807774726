<template>
	<div class="statisticsTable playerRanking contentBox transparent">
		<h6 class="contentBoxHeader headerWithArrowEndings tableGreen">
			<div class="content" translate=""><span>Лучшие герои</span></div>
		</h6>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems[tab]" current-page="currentPage[tab]" display-page-func="displayCurrentPage" route-named-param="statsPage">
			<div ng-transclude="">
				<div class="statisticsContainer">
					<div ng-include="" src="'tpl/statistics/partials/playerInfo.html'">
						<div ng-hide="showHover" class="playerInfo top3">
							<div class="playerInfoHeader">
								<div content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></div>
								<div content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></div>
							</div>
							<div class="content rank_1" ng-repeat="player in top3[tab]" clickable="showFixedRankPlayerInfo(player.rank)">
								<div class="playerInfoRow">
									<div class="rank">
										1
									</div>
									<div class="name">
										<div class="content">
											<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="637" nolink="true">Неясыть</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3" nolink="true">Roman</a>
										</div>
									</div>
									<div class="avatar">
										<avatar-image class="avatarImage" player-id="637" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<div class="prestigeStars" ng-if="config.balancing.features.prestige">
											<div class="innerStars" playerid="637" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												</div>
												<!-- ngIf: badge -->
											</div>
										</div>
									</div>
								</div>
								<div class="pointsInfoRow Heroes">
									<!-- ngIf: tab == 'Overview' -->
									<!-- ngIf: tab == 'Attacker' -->
									<!-- ngIf: tab == 'Defender' -->
									<i ng-if="tab == 'Heroes'" class="unit_experience_small_flat_black"></i>
									<span translate="" class="pointsName" options="Heroes"><span>Опыт</span></span>
									<span class="points">66&nbsp;224</span>
								</div>
							</div>
							<div class="content rank_2" ng-repeat="player in top3[tab]" clickable="showFixedRankPlayerInfo(player.rank)">
								<div class="playerInfoRow">
									<div class="rank">
										2
									</div>
									<div class="name">
										<div class="content">
											<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="606" nolink="true">удачливый</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" kingdomid="6" nolink="true">Utopia</a>
										</div>
									</div>
									<div class="avatar">
										<avatar-image class="avatarImage" player-id="606" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<div class="prestigeStars" ng-if="config.balancing.features.prestige">
											<div class="innerStars" playerid="606" size="tiny">
												<!-- ngIf: !badge -->
												<i ng-if="badge" class="prestigeBadge prestige_silverBadge_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="pointsInfoRow Heroes">
									<!-- ngIf: tab == 'Overview' -->
									<!-- ngIf: tab == 'Attacker' -->
									<!-- ngIf: tab == 'Defender' -->
									<i ng-if="tab == 'Heroes'" class="unit_experience_small_flat_black"></i>
									<span translate="" class="pointsName" options="Heroes"><span>Опыт</span></span>
									<span class="points">45&nbsp;020</span>
								</div>
							</div>
							<div class="content rank_3" ng-repeat="player in top3[tab]" clickable="showFixedRankPlayerInfo(player.rank)">
								<div class="playerInfoRow">
									<div class="rank">
										3
									</div>
									<div class="name">
										<div class="content">
											<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="705" nolink="true">ЭЛВИС</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" kingdomid="6" nolink="true">Utopia</a>
										</div>
									</div>
									<div class="avatar">
										<avatar-image class="avatarImage" player-id="705" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<div class="prestigeStars" ng-if="config.balancing.features.prestige">
											<div class="innerStars" playerid="705" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												</div>
												<!-- ngIf: badge -->
											</div>
										</div>
									</div>
								</div>
								<div class="pointsInfoRow Heroes">
									<!-- ngIf: tab == 'Overview' -->
									<!-- ngIf: tab == 'Attacker' -->
									<!-- ngIf: tab == 'Defender' -->
									<i ng-if="tab == 'Heroes'" class="unit_experience_small_flat_black"></i>
									<span translate="" class="pointsName" options="Heroes"><span>Опыт</span></span>
									<span class="points">41&nbsp;576</span>
								</div>
							</div>
							<div class="averagePoints">
								<div class="pointsRow">
									<span translate=""><span>Ø</span></span><span class="points">5&nbsp;533</span>
								</div>
								<div class="descriptionRow" translate="" options="Heroes"><span>Средний опыт</span></div>
							</div>
						</div>
						<div ng-show="showHover" class="playerInfo playerProfile" on-pointer-over="showPlayerInfo(selectedData.rank)" on-pointer-out="hidePlayerInfo()">
							<div class="playerInfoHeader">
								<div>
									lexinzector
								</div>
							</div>
							<div class="playerProfileAvatarContainer">
								<div class="detailsWrapper">
									<div class="playerProfileAvatar kingdomRole0">
										<div class="heroLevel">
											<span translate=""><span>Ур.</span></span>
											<br>
											<span>1</span>
										</div>
										<div class="avatarLink clickable" clickable="openOverlay('playerProfileFullImage', {'playerId': 1804 });">
											<avatar-image scale="0.55" class="avatarImage" player-id="1804" size="big" avatar-class="profileImage">
												<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
													<!-- ngIf: playerId == robberId -->
													<!-- ngIf: playerId == npcGovernorId -->
													<canvas width="192" height="192"></canvas>
												</div>
											</avatar-image>
											<div class="prestigeStars" ng-if="config.balancing.features.prestige">
												<div class="innerStars" playerid="1804" size="tiny">
													<div ng-if="!badge">
														<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
														<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
														<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													</div>
													<!-- ngIf: badge -->
												</div>
											</div>
										</div>
										<span class="decoration"></span>
									</div>
								</div>
							</div>
							<div class="playerDataContainer">
								<div class="indicationArrow position_0" ng-class="{disabled: currentPage[tab] > 1 &amp;&amp; selectedData.rank < itemsPerPage}"></div>
								<div class="flagHanger governor" ng-class="['king', 'viceking', 'duke', 'governor']">
									<div class="flag"></div>
									<i class="community_governor_small_flat_black" ng-class="['community_king_small_flat_black', 'community_viceking_small_flat_black', 'community_duke_small_flat_black', 'community_governor_small_flat_black']"></i>
								</div>
								<div class="header">
									<!-- ngIf: tab == 'Overview' -->
									<!-- ngIf: tab == 'Attacker' -->
									<!-- ngIf: tab == 'Defender' -->
									<i ng-if="tab == 'Heroes'" class="unit_experience_small_flat_black"></i>
									<i content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank" class="symbol_ranking_small_flat_black"></i>
								</div>
								<div class="statsData">
									<div class="currentData">
										<span class="description" translate=""><span>Сейчас</span></span>
										<span class="points">75</span>
										<span class="rank">431.</span>
									</div>
									<div class="lastWeekData">
										<span class="description" translate=""><span>Прошлая неделя</span></span>
										<span ng-if="playerStatsLastWeek.rank >= 0" class="points">51</span>
										<!-- ngIf: playerStatsLastWeek.rank < 0 -->
										<span ng-if="playerStatsLastWeek.rank >= 0" class="rank">537.</span>
										<!-- ngIf: playerStatsLastWeek.rank < 0 -->
									</div>
								</div>
								<div class="playerData">
									<div>
										<span class="description"><span translate=""><span>Народ</span></span>:</span>
										<span class="content" translate="" options="3"><span>Галлы</span></span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Деревни</span></span>:</span>
										<span class="content">1</span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Роль</span></span>:</span>
										<span translate="" class="content" options="0"><span>Губернатор</span></span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Королевство</span></span>:</span>
										<span class="content">
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 0}, null, true)" kingdomid="0" kingdomname="-">-</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3" kingdomname="MИР">MИР</a>
										</span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Регистрация</span></span>:</span>
										<span class="content">
											<!-- ngIf: signedUp == 0 -->
											<!-- ngIf: signedUp == 1 -->
											<span ng-if="signedUp > 1" translate="" data="days: 9"><span>9 дн. назад</span></span>
										</span>
									</div>
								</div>
								<div class="buttonContainer">
									<div class="chatButton">
										<button clickable="openChat(getPrivateRoomName(selectedData.playerId))" class="clickable">
											<div class="content">
												<span ng-transclude="" class="innerText">
													<span translate=""><span>Чат</span></span>
												</span>
												<!-- ngIf: price > 0 || forceGoldUsage -->
												<!-- ngIf: price == -1 && !forceGoldUsage -->
												<div class="animationOverlay"></div>
												<i class="checkmark action_check_small_flat_white"></i>
												<i class="checkmark action_check_small_flat_black"></i>
												<i class="checkmark action_check_small_flat_red"></i>
											</div>
										</button>
									</div>
									<div class="profileButton">
										<button clickable="openWindow('profile', {'playerId': 1804, 'profileTab': 'playerProfile'}, null, true)" class="clickable">
											<div class="content">
												<span ng-transclude="" class="innerText">
													<span translate=""><span>Профиль</span></span>
												</span>
												<!-- ngIf: price > 0 || forceGoldUsage -->
												<!-- ngIf: price == -1 && !forceGoldUsage -->
												<div class="animationOverlay"></div>
												<i class="checkmark action_check_small_flat_white"></i>
												<i class="checkmark action_check_small_flat_black"></i>
												<i class="checkmark action_check_small_flat_red"></i>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<table class="gradient pagination page_1">
						<thead>
							<tr>
								<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
								<th content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></th>
								<th translate=""><span>Ур.</span></th>
							</tr>
						</thead>
						<tbody class="ranks">
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1450" player-name="" rank="460" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank461" ng-if="rank >= 0">461</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1450" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1450" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1450" playername="гороч" nolink="false">гороч</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1233" player-name="" rank="461" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank462" ng-if="rank >= 0">462</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1233" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1233" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1233" playername="BunKer" nolink="false">BunKer</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="976" player-name="" rank="462" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank463" ng-if="rank >= 0">463</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="976" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="976" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="976" playername="Тимоха" nolink="false">Тимоха</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1903" player-name="" rank="463" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank464" ng-if="rank >= 0">464</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1903" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1903" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1903" playername="Альфа" nolink="false">Альфа</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1804" player-name="" rank="464" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo" class="highlighted">
								<td class="rankColumn rank465" ng-if="rank >= 0">465</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1804" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1700" player-name="" rank="465" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank466" ng-if="rank >= 0">466</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1700" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1700" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1700" playername="ArchonAI" nolink="false">ArchonAI</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1314" player-name="" rank="466" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank467" ng-if="rank >= 0">467</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1314" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1314" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1314" playername="Алекс Блейк" nolink="false">Алекс Блейк</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1634" player-name="" rank="467" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank468" ng-if="rank >= 0">468</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1634" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1634" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1634" playername="Anger" nolink="false">Anger</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1273" player-name="" rank="468" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank469" ng-if="rank >= 0">469</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1273" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1273" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1273" playername="maxim" nolink="false">maxim</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1259" player-name="" rank="469" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank470" ng-if="rank >= 0">470</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1259" player-name="" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1259" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1259" playername="opai" nolink="false">opai</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="tg-pagination" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
					</li>
					<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(2)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="">2</a>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(...)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">...</a>
					</li>
					<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(53)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="">53</a>
					</li>
					<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black"></i>
					</li>
					<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
							action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black"></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
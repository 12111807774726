<template>
	<div ng-controller="statsWorldWonderCtrl">
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems[tab]" current-page="currentPage[tab]" display-page-func="displayCurrentPage" route-named-param="statsPage">
			<div ng-transclude="">
				<table class="worldWonder">
					<thead>
						<tr>
							<th></th>
							<th translate=""><span>Игрок</span></th>
							<th translate=""><span>Имя</span></th>
							<th translate=""><span>Королевство</span></th>
							<th translate=""><span>Уровень</span></th>
							<th translate=""><span>Бонус</span></th>
						</tr>
					</thead>
					<tbody>
						<tr ng-repeat="result in rankings.WorldWonder.worldWonder track by $index" ng-class="{highlighted: result.playerId == player.data.playerId || result.rank == searchedRank}">
							<td>1.</td>
							<td class="nameColumn"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="118" playername="ephbiu">ephbiu</a></td>
							<td class="nameColumn"><div class="truncated">VAVILON</div></td>
							<td class="nameColumn"><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">Медоеды</a></td>
							<td>0</td>
							<td>&#x202D;+&#x202D;50&#x202C;%&#x202C;</td>
						</tr>
						<tr ng-repeat="result in rankings.WorldWonder.worldWonder track by $index" ng-class="{highlighted: result.playerId == player.data.playerId || result.rank == searchedRank}">
							<td>2.</td>
							<td class="nameColumn"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="220" playername="TiS">TiS</a></td>
							<td class="nameColumn"><div class="truncated">01_Пик медоеда</div></td>
							<td class="nameColumn"><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">Медоеды</a></td>
							<td>0</td>
							<td>&#x202D;+&#x202D;33&#x202C;%&#x202C;</td>
						</tr>
						<tr ng-repeat="result in rankings.WorldWonder.worldWonder track by $index" ng-class="{highlighted: result.playerId == player.data.playerId || result.rank == searchedRank}">
							<td>3.</td>
							<td class="nameColumn"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="171" playername="Неясыть">Неясыть</a></td>
							<td class="nameColumn"><div class="truncated">Темная Владычица</div></td>
							<td class="nameColumn"><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">Медоеды</a></td>
							<td>0</td>
							<td>&#x202D;+&#x202D;25&#x202C;%&#x202C;</td>
						</tr>
						<tr ng-repeat="result in rankings.WorldWonder.worldWonder track by $index" ng-class="{highlighted: result.playerId == player.data.playerId || result.rank == searchedRank}">
							<td>4.</td>
							<td class="nameColumn"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="662" playername="Migera">Migera</a></td>
							<td class="nameColumn"><div class="truncated">Чудо-Юдо Рыба Кит</div></td>
							<td class="nameColumn"><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3">MИР</a></td>
							<td>0</td>
							<td>&#x202D;+&#x202D;20&#x202C;%&#x202C;</td>
						</tr>
						<tr ng-repeat="result in rankings.WorldWonder.worldWonder track by $index" ng-class="{highlighted: result.playerId == player.data.playerId || result.rank == searchedRank}">
							<td>5.</td>
							<td class="nameColumn"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="131" playername="Azzuro">Azzuro</a></td>
							<td class="nameColumn"><div class="truncated">&gt;Ziggurat</div></td>
							<td class="nameColumn"><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3">MИР</a></td>
							<td>0</td>
							<td>&#x202D;+&#x202D;15&#x202C;%&#x202C;</td>
						</tr>
						<tr ng-repeat="result in rankings.WorldWonder.worldWonder track by $index" ng-class="{highlighted: result.playerId == player.data.playerId || result.rank == searchedRank}">
							<td>6.</td>
							<td class="nameColumn"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1" playername="Натары">Натары</a></td>
							<td class="nameColumn"><div class="truncated">Чудо света</div></td>
							<td class="nameColumn"><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 0}, null, true)" kingdomid="0"></a></td>
							<td>0</td>
							<td>&#x202D;+&#x202D;10&#x202C;%&#x202C;</td>
						</tr>
						<tr ng-repeat="result in rankings.WorldWonder.worldWonder track by $index" ng-class="{highlighted: result.playerId == player.data.playerId || result.rank == searchedRank}">
							<td>7.</td>
							<td class="nameColumn"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1" playername="Натары">Натары</a></td>
							<td class="nameColumn"><div class="truncated">Чудо света</div></td>
							<td class="nameColumn"><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 0}, null, true)" kingdomid="0"></a></td>
							<td>0</td>
							<td>&#x202D;+&#x202D;0&#x202C;%&#x202C;</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="tg-pagination ng-hide" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<!-- ngRepeat: pageNumber in filteredPageNumbers track by $index -->
					<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black"></i>
					</li>
					<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
							action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black"></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div ng-if="overlayController" class="windowOverlay" id="heroEditor" ng-controller="overlayController">
		<div class="darkener clickable" clickable="closeOverlay()"></div>
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="659"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
		<div class="inWindowPopupHeader">
			<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Hero.ChangeAppearance" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Изменить внешность</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
			<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
			<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
		</div>
		<div class="inWindowPopupContent">
			<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="heroEditor">
		<div class="contentBox gradient double previewBox">
			<h6 class="contentBoxHeader headerWithArrowEndings glorious">
				<div class="content" translate=""><span>Герой</span></div>
			</h6>
			<div class="contentBoxBody heroImageContainer" ng-class="{noGenderSelection: !config.balancing.features.femaleHero}">
				<avatar-image avatar="editorAvatar" size="big"><!-- rerender: rerender --><div class="heroImage  male" ng-class="::class" rerender="rerender">
		<!-- ngIf: playerId == robberId -->
		<!-- ngIf: playerId == npcGovernorId -->
	<canvas width="350" height="350"></canvas></div>
	</avatar-image>
				<!-- ngIf: editorAvatar.hideHelmet -->
			</div>
			<!-- ngIf: config.balancing.features.femaleHero --><div class="contentBox gradient genderBox" ng-if="config.balancing.features.femaleHero">
				<div class="contentBoxBody">
					<div class="clickableContainer genderButton clickable" ng-class="{active: editorAvatar.gender == 'female'}" clickable="changeGender(1)" tooltip="" tooltip-translate="Hero.Female">
						<i class="attribute_genderFemale_medium_flat_black"></i>
					</div>
					<div class="clickableContainer genderButton clickable active" ng-class="{active: editorAvatar.gender == 'male'}" clickable="changeGender(0)" tooltip="" tooltip-translate="Hero.Male">
						<i class="attribute_genderMale_medium_flat_black"></i>
					</div>
				</div>
			</div><!-- end ngIf: config.balancing.features.femaleHero -->
		</div>
		<div class="contentBox gradient editHeroBox">
			<div class="contentBoxBody">
				<table class="featureSelection transparent">
					<tbody>
						<tr>
							<td>
								<a clickable="previousOption()" class="iconButton doubleBorder previous clickable">
									<i class="symbol_arrowFrom_tiny_flat_black"></i>
								</a>
							</td>
							<td>
								<div class="doubleBorder dropdownContainer" dropdown="" data="editorDropdown"><div class="dropdownHead openDownwards closed clickable" clickable="clickDropdown()"><span class="selectedItem truncated">Борода</span><i class="symbol_arrowDown_tiny_illu"></i></div><input class="dropdownInput" type="text"></div>
							</td>
							<td>
								<a clickable="nextOption()" class="iconButton doubleBorder next clickable">
									<i class="symbol_arrowTo_tiny_flat_black"></i>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- ngIf: privileges.filled --><div ng-if="privileges.filled" scrollable="" class="customizationOptions scrollable" scroll-to-class=""><div class="scrollContentOuterWrapper active" style="width: 309px;">
		<div class="scrollContent" dynamic-height="false" style="width: 329px; overflow-y: auto;">
			<div class="scrollContentInnerWrapper" ng-transclude="" style="width: 309px;">
					<table class="optionsTable transparent">
						<tbody>
							<!-- ngRepeat: n in [] | range:0:(customizationRows - 1) --><tr ng-repeat="n in [] | range:0:(customizationRows - 1)">
								<!-- ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beardNone.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beardNone" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beardNone.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard0.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard0" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard0.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard1.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard1" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard1.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard2.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard2" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard2.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) -->
							</tr><!-- end ngRepeat: n in [] | range:0:(customizationRows - 1) --><tr ng-repeat="n in [] | range:0:(customizationRows - 1)">
								<!-- ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard3.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard3" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard3.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard4.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard4" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard4.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard5.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard5" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard5.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard6.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard6" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard6.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) -->
							</tr><!-- end ngRepeat: n in [] | range:0:(customizationRows - 1) --><tr ng-repeat="n in [] | range:0:(customizationRows - 1)">
								<!-- ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard7.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard7" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard7.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox active" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard8.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard8" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard8.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard9.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard9" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard9.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard10.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard10" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard10.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) -->
							</tr><!-- end ngRepeat: n in [] | range:0:(customizationRows - 1) --><tr ng-repeat="n in [] | range:0:(customizationRows - 1)">
								<!-- ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_none" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading --><img ng-src="layout/images/heroAvatar/male/thumb/head/beard/beard11.png" ng-if="optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; !loading" file="male/thumb/head/beard/beard11" style="display: inline;" src="layout/images/heroAvatar/male/thumb/head/beard/beard11.png" data-cmp-info="10"><!-- end ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox  dummy disabled" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox  dummy disabled" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) --><td ng-repeat="m in [] | range:0:(customizationColumns - 1)">
									<!-- ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable --><div ng-if="!optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable" class="option entityBox  dummy disabled" ng-class="{dummy: optionItems[(n*customizationColumns)+m].index == undefined,
													active: optionItems[(n*customizationColumns)+m].index != undefined &amp;&amp; ((selectedOption != 'color' &amp;&amp; heroFace.data.face[selectedOption] == optionItems[(n*customizationColumns)+m].index) || (selectedOption == 'color' &amp;&amp;  heroFace.data['hairColor'] == optionItems[(n*customizationColumns)+m].index) || (heroFace.data.face[selectedOption] == undefined &amp;&amp; optionItems[(n*customizationColumns)+m].index == CUSTOMIZATION_TYPE_NONE)),
													disabled: optionItems[(n*customizationColumns)+m].index == undefined || optionItems[(n*customizationColumns)+m].locked,
													locked: optionItems[(n*customizationColumns)+m].locked}" tooltip="" tooltip-show="" tooltip-placement="above" tooltip-translate="Hero.Face.Option.Tooltip_" clickable="changeAttribute(selectedOption, optionItems[(n*customizationColumns)+m].index)">
										<!-- ngIf: optionItems[(n*customizationColumns)+m].index != undefined && !loading -->
									</div><!-- end ngIf: !optionItems[(n*customizationColumns)+m].locked || !optionItems[(n*customizationColumns)+m].purchasable -->
									<!-- ngIf: optionItems[(n*customizationColumns)+m].locked && optionItems[(n*customizationColumns)+m].purchasable -->
								</td><!-- end ngRepeat: m in [] | range:0:(customizationColumns - 1) -->
							</tr><!-- end ngRepeat: n in [] | range:0:(customizationRows - 1) -->
						</tbody>
					</table>
				</div>
		</div>
	</div>
	</div><!-- end ngIf: privileges.filled -->
			</div>
			<button class="randomButton jsHeroEditorRandomButton clickable" clickable="selectRandomFace()">
				<div class="content">
		<span ng-transclude="" class="innerText">
				<span translate=""><span>Случайно</span></span>
			</span>
		<!-- ngIf: price > 0 || forceGoldUsage -->
		<!-- ngIf: price == -1 && !forceGoldUsage -->

		<div class="animationOverlay"></div>
		<i class="checkmark action_check_small_flat_white"></i>
		<i class="checkmark action_check_small_flat_black"></i>
		<i class="checkmark action_check_small_flat_red"></i>
	</div>
	</button>
		</div>
		<div class="buttonBar">
			<button clickable="save()" class="saveButton jsHeroEditorSaveButton clickable">
				<div class="content">
		<span ng-transclude="" class="innerText">
				<span translate=""><span>Сохранить</span></span>
			</span>
		<!-- ngIf: price > 0 || forceGoldUsage -->
		<!-- ngIf: price == -1 && !forceGoldUsage -->
		<div class="animationOverlay"></div>
		<i class="checkmark action_check_small_flat_white"></i>
		<i class="checkmark action_check_small_flat_black"></i>
		<i class="checkmark action_check_small_flat_red"></i>
	</div>
	</button>
			<button ng-class="{disabled: !somethingChanged()}" clickable="resetChanges()" class="resetButton cancel clickable disabled">
				<div class="content">
		<span ng-transclude="" class="innerText">
				<span translate=""><span>Сбросить изменения</span></span>
			</span>
		<!-- ngIf: price > 0 || forceGoldUsage -->
		<!-- ngIf: price == -1 && !forceGoldUsage -->
		<div class="animationOverlay"></div>
		<i class="checkmark action_check_small_flat_white"></i>
		<i class="checkmark action_check_small_flat_black"></i>
		<i class="checkmark action_check_small_flat_red"></i>
	</div>
	</button>
		</div>
	</div>
	</div>
		</div>
	</div>
	</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
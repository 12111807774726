<template>
	<div ng-controller="statsKingdomsCtrl">
		<div tab-config-name="statsKingdomTabConfig">
			<nav class="tabulation subtab subtab unselectable" ng-if="tabData.tabs.length > 1">
				<a class="tab clickable" :class="{active: tab == 'victory-points', inactive: tab != 'victory-points'}" @click="onToggleTab('victory-points')">
					<div class="content">
						<span>Победные очки</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'population', inactive: tab != 'population'}" @click="onToggleTab('population')">
					<div class="content">
						<span>Население</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'size', inactive: tab != 'size'}" @click="onToggleTab('size')">
					<div class="content">
						<span>Площадь</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'attacker', inactive: tab != 'attacker'}" @click="onToggleTab('attacker')">
					<div class="content">
						<span>Нападение</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'defender', inactive: tab != 'defender'}" @click="onToggleTab('defender')">
					<div class="content">
						<span>Оборона</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'top10', inactive: tab != 'top10'}" @click="onToggleTab('top10')">
					<div class="content">
						<span>Топ-10</span>
					</div>
				</a>
			</nav>
			<div class="tabulationContent scrollable tabContentVictoryPoints" ng-class="'tabContent' + tabData.currentTab" scrolling-disabled="false" dynamic="" scrollable="">
				<div class="scrollContentOuterWrapper" style="width: 610px;">
					<div class="scrollContent" dynamic-height="true" style="width: 610px; overflow-y: hidden; max-height: 494px;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
							<div ng-transclude="">
								<div class="loadedTab currentTab" v-if="tab == 'victory-points'">
									<victory-points />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'population'">
									<population />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'size'">
									<size />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'attacker'">
									<attacker />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'defender'">
									<defender />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'top10'">
									<top10 />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import VictoryPoints from '@/components/pages/statistics/kingdoms/VictoryPoints';
import Population from '@/components/pages/statistics/kingdoms/Population';
import Size from '@/components/pages/statistics/kingdoms/Size';
import Attacker from '@/components/pages/statistics/kingdoms/Attacker';
import Defender from '@/components/pages/statistics/kingdoms/Defender';
import Top10 from '@/components/pages/statistics/kingdoms/Top10';

export default {
	mixins: lib.mixins,
	data: () => ({
		tab: 'victory-points',
	}),
	computed: {},
	methods: {
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_10.ogg');
				audio.play();
			}
		},
	},
	components: {
		VictoryPoints,
		Population,
		Size,
		Attacker,
		Defender,
		Top10,
	},
}
</script>
<template>
	<div ng-controller="secretSocietyDetailCtrl" class="secretSociety defaultWindow">
		<div ng-if="noSociety"><span>Ты не состоишь ни в одном тайном сообществе. В посольстве ты можешь вступить в тайное сообщество или основать свое собственное.</span></div>
		<div ng-show="!noSociety" class="dynamicTabulationContainer">
			<dynamic-tabulation watch-var="societies.data" collection-key="data.groupId" active-tab="10" class="tabulation subtab subtab dynamicTabulation">
				<div class="tabWrapper" style="opacity: 1;">
					<a class="tab clickable active" ng-repeat="tabSociety in tabs" ng-class="{active: societyId == tabSociety.data.groupId, inactive: societyId != tabSociety.data.groupId}" clickable="selectSociety(tabSociety.data.groupId);">
						<div class="content">
							<span>ЦАРСТВО ТС</span>
						</div>
					</a>
					<a class="tab showHidden clickable ng-hide" clickable="toggleDropdown()" ng-show="tabsMore.length > 0" style="left: 105px;">
						<div class="iconButton">
							<i class="symbol_arrowDown_tiny_illu"></i>
							<span class="moreTabsAmount">0</span>
							<div class="dropdownBody dynamicTabulationDropdown ng-hide" ng-show="showHiddenTabsDropdown">
								<ul>
									<!-- ngRepeat: tabSociety in tabsMore -->
								</ul>
							</div>
						</div>
					</a>
				</div>
			</dynamic-tabulation>
			<h6 class="headerWithIcon arrowDirectionDown openInvitations" ng-class="{openInvitations: invitations.data.length > 0}">
				<i class="community_secretSociety_medium_flat_black"></i>
				ЦАРСТВО ТС
				<a ng-if="isFounder" class="invitations clickable disabled" clickable="openOverlay('secretSocietyOpenInvitations', {'groupId': 30 });" ng-class="{disabled: invitations.data.length <= 0}">
					<span data="cnt: 0"><span>Открытые приглашения: 0</span></span>
					<i class="community_invitation_small_illu disabled" ng-class="{disabled: invitations.data.length <= 0}"></i>
				</a>
			</h6>
			<div class="contentBox gradient">
				<h6 class="contentBoxHeader headerWithArrowEndings glorious">
					<div class="content">
						<span><span>Основатель</span></span>
						<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="662" playername="Migera">Migera</a>
					</div>
					<i class="headerButton clickable action_edit_small_flat_black" ng-class="{action_edit_small_flat_black: !editHover, action_edit_small_flat_green: editHover}" on-pointer-over="editHover = true" on-pointer-out="editHover = false" ng-if="isFounder &amp;&amp; !isSitter" tooltip="" tooltip-translate="Society.EditDescription.Headline" clickable="openOverlay('secretSocietyEditDescription', {'groupId': 28 });"></i>
				</h6>
				<div class="contentBoxBody">
					<div class="column target">
						<i class="secretSocietyLogo secretSociety_bright_huge_illu" ng-class="{secretSociety_bright_huge_illu: society.data.attitude == 1, secretSociety_dark_huge_illu: society.data.attitude == 2}"></i>
						<div class="targetContainer">
							<div ng-if="society.data.targetType == target.village">
								<span options="White"><span>Защита деревни</span></span>
								<span options="Black"><span>Война с деревней</span></span>
								<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" villagename="Брест">Брест</a>
							</div>
							<div ng-if="society.data.targetType == target.player">
								<span options="White"><span>Защита игрока</span></span>
								<span options="Black"><span>Война с игроком</span></span>
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" playername="lexinzector">lexinzector</a>
							</div>
							<div ng-if="society.data.targetType == target.kingdom">
								<span options="White"><span>Защита королевства</span></span>
								<span options="Black"><span>Война с королевством</span></span>
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">Медоеды</a>
							</div>
						</div>
					</div>
					<div class="column">
						<div class="header">
							<span><span>Описание</span></span>
						</div>
						<div class="text scrollable" scrollable="">
							<div class="scrollContentOuterWrapper" style="width: 279px;">
								<div class="scrollContent" dynamic-height="false" style="width: 279px; overflow-y: hidden;">
									<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
										<span>
											описание...
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div pagination="" class="paginated" items-per-page="itemsPerPage" number-of-items="numberOfItems" current-page="currentPage" display-page-func="displayCurrentPage" route-named-param="cp">
				<div ng-transclude="">
					<table class="memberList">
						<thead>
							<tr>
								<th class="name" colspan="2"><span>Участники</span></th>
								<th><i class="village_village_small_flat_black" content="Деревни" v-tippy="{placement: 'bottom'}" tooltip-translate="Villages"></i></th>
								<th><i class="unit_population_small_flat_black" content="Население" v-tippy="{placement: 'bottom'}" tooltip-translate="Population"></i></th>
								<th>
									<i ng-class="{
										movement_trade_small_flat_black: society.data.attitude == 1,
										unit_capacity_small_flat_black: society.data.attitude == 2
									}" content="Отправлено ресурсов/Украдено ресурсов" v-tippy="{placement: 'bottom'}" tooltip-translate="Society.Stats.Resources1" class="movement_trade_small_flat_black"></i>
								</th>
								<th>
									<i ng-class="{
										secretSociety_troopsLost_small_flat_black: society.data.attitude == 1,
										secretSociety_troopsDefeated_small_flat_black: society.data.attitude == 2
									}" content="Потеряно войск в защите/Убито войск" v-tippy="{placement: 'bottom'}" tooltip-translate="Society.Stats.Troops1" class="secretSociety_troopsLost_small_flat_black"></i></th>
								<th>
									<i ng-if="society.data.attitude == 1" class="secretSociety_troopsProvided_small_flat_black" content="Войска сейчас" v-tippy="{placement: 'bottom'}" tooltip-translate="Society.Stats.TroopsDeployed"></i>
									<i ng-if="society.data.attitude == 2" class="unit_treasure_small_flat_black" content="Сокровищ украдено" v-tippy="{placement: 'bottom'}" tooltip-translate="Society.Stats.Treasures"></i>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr ng-repeat="member in rows" on-pointer-over="member.showOptions = true" on-pointer-out="member.showOptions = false">
								<td class="orderNr">1.</td>
								<td class="name">
									<!-- ngIf: member.isFounder -->
									<i ng-if="!member.isFounder" class="secretSociety_member_small_flat_black" content="Участник" v-tippy="{placement: 'bottom'}" tooltip-translate="Communities.SecretSociety.Member"></i>
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="172" playername="Царевич">Царевич</a>
								</td>
								<td>6</td>
								<td>4796</td>
								<td>
									<span ng-if="society.data.attitude == 1">402&nbsp;816</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">2&nbsp;874</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">7309</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td class="options">
									<i ng-show="member.showOptions &amp;&amp; isFounder &amp;&amp; user.data.playerId != member.playerId" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" on-pointer-over="leaveHover = true" on-pointer-out="leaveHover = false" clickable="openOverlay('secretSocietyKick', {'playerId': 172, 'societyId': 10 });" tooltip="" tooltip-translate="Society.Kick" class="clickable ng-hide action_leave_small_flat_black"></i>
								</td>
							</tr>
							<tr ng-repeat="member in rows" on-pointer-over="member.showOptions = true" on-pointer-out="member.showOptions = false">
								<td class="orderNr">2.</td>
								<td class="name">
									<!-- ngIf: member.isFounder -->
									<i ng-if="!member.isFounder" class="secretSociety_member_small_flat_black" content="Участник" v-tippy="{placement: 'bottom'}" tooltip-translate="Communities.SecretSociety.Member"></i>
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="245" playername="Жек@">Жек@</a>
								</td>
								<td>6</td>
								<td>4644</td>
								<td>
									<span ng-if="society.data.attitude == 1">573&nbsp;935</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">3&nbsp;918</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">796</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td class="options">
									<i ng-show="member.showOptions &amp;&amp; isFounder &amp;&amp; user.data.playerId != member.playerId" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" on-pointer-over="leaveHover = true" on-pointer-out="leaveHover = false" clickable="openOverlay('secretSocietyKick', {'playerId': 245, 'societyId': 10 });" tooltip="" tooltip-translate="Society.Kick" class="clickable ng-hide action_leave_small_flat_black"></i>
								</td>
							</tr>
							<tr ng-repeat="member in rows" on-pointer-over="member.showOptions = true" on-pointer-out="member.showOptions = false">
								<td class="orderNr">3.</td>
								<td class="name">
									<!-- ngIf: member.isFounder -->
									<i ng-if="!member.isFounder" class="secretSociety_member_small_flat_black" content="Участник" v-tippy="{placement: 'bottom'}" tooltip-translate="Communities.SecretSociety.Member"></i>
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="131" playername="Azzuro">Azzuro</a>
								</td>
								<td>8</td>
								<td>4263</td>
								<td>
									<span ng-if="society.data.attitude == 1">499&nbsp;350</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">4&nbsp;674</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">6000</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td class="options">
									<i ng-show="member.showOptions &amp;&amp; isFounder &amp;&amp; user.data.playerId != member.playerId" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" on-pointer-over="leaveHover = true" on-pointer-out="leaveHover = false" clickable="openOverlay('secretSocietyKick', {'playerId': 131, 'societyId': 10 });" tooltip="" tooltip-translate="Society.Kick" class="clickable ng-hide action_leave_small_flat_black"></i>
								</td>
							</tr>
							<tr ng-repeat="member in rows" on-pointer-over="member.showOptions = true" on-pointer-out="member.showOptions = false">
								<td class="orderNr">4.</td>
								<td class="name">
									<!-- ngIf: member.isFounder -->
									<i ng-if="!member.isFounder" class="secretSociety_member_small_flat_black" content="Участник" v-tippy="{placement: 'bottom'}" tooltip-translate="Communities.SecretSociety.Member"></i>
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="124" playername="TVG">TVG</a>
								</td>
								<td>5</td>
								<td>4057</td>
								<td>
									<span ng-if="society.data.attitude == 1">685 203</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">9 162</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">2020</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td class="options">
									<i ng-show="member.showOptions &amp;&amp; isFounder &amp;&amp; user.data.playerId != member.playerId" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" on-pointer-over="leaveHover = true" on-pointer-out="leaveHover = false" clickable="openOverlay('secretSocietyKick', {'playerId': 124, 'societyId': 10 });" tooltip="" tooltip-translate="Society.Kick" class="clickable ng-hide action_leave_small_flat_black"></i>
								</td>
							</tr>
							<tr ng-repeat="member in rows" on-pointer-over="member.showOptions = true" on-pointer-out="member.showOptions = false">
								<td class="orderNr">5.</td>
								<td class="name">
									<i ng-if="member.isFounder" class="secretSociety_leader_small_flat_black" content="Основатель" v-tippy="{placement: 'bottom'}" tooltip-translate="Communities.SecretSociety.Leader"></i>
									<!-- ngIf: !member.isFounder -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="662" playername="Migera">Migera</a>
								</td>
								<td>5</td>
								<td>3975</td>
								<td>
									<span ng-if="society.data.attitude == 1">1 109 064</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">999</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td>
									<span ng-if="society.data.attitude == 1">2549</span>
									<!-- ngIf: society.data.attitude == 2 -->
								</td>
								<td class="options">
									<i ng-show="member.showOptions &amp;&amp; isFounder &amp;&amp; user.data.playerId != member.playerId" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" on-pointer-over="leaveHover = true" on-pointer-out="leaveHover = false" clickable="openOverlay('secretSocietyKick', {'playerId': 847, 'societyId': 10 });" tooltip="" tooltip-translate="Society.Kick" class="clickable ng-hide action_leave_small_flat_black"></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="tg-pagination" ng-show="visible">
					<ul>
						<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
							<i ng-class="{
								action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
								action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
								disabled: currentPage == 1
							}" class="action_toBeginning_tiny_flat_black disabled"></i>
						</li>
						<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
							<i ng-class="{
								symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
								symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
								disabled: currentPage == 1
							}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
						</li>
						<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
						</li>
						<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(2)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}">2</a>
						</li>
						<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(...)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">...</a>
						</li>
						<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(21)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}">21</a>
						</li>
						<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
							<i ng-class="{
								symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
								symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
								disabled: disableNext()
							}" class="symbol_arrowTo_tiny_flat_black"></i>
						</li>
						<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
							<i ng-class="{
								action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
								action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
								disabled: disableNext()
							}" class="action_toEnd_tiny_flat_black"></i>
						</li>
					</ul>
				</div>
			</div>
			<div class="buttonFooter">
				<div ng-if="isFounder">
					<button ng-if="!isSitter" clickable="openOverlay('secretSocietyInvite', {'groupId': 28 });" class="clickable">
						<div class="content">
							<span ng-transclude="" class="innerText">
								<span><span>Пригласить игрока</span></span>
							</span>
							<!-- ngIf: price > 0 || forceGoldUsage -->
							<!-- ngIf: price == -1 && !forceGoldUsage -->
							<div class="animationOverlay"></div>
							<i class="checkmark action_check_small_flat_white"></i>
							<i class="checkmark action_check_small_flat_black"></i>
							<i class="checkmark action_check_small_flat_red"></i>
						</div>
					</button>
					<!-- ngIf: isSitter -->
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div id="tzSelector" class="unselectable">
		<div v-if="gameModel.timezones.selectShow" class="dropdownBody timeType scrollable openUpwards">
			<div class="scrollContentOuterWrapper">
				<div class="scrollContent">
					<div class="scrollContentInnerWrapper" style="width: auto;">
						<ul>
							<li class="dropdownOption truncated" :class="{'selected': gameModel.timezones.selected == tz.value}" @click="gameModel.timezones.doSelect(tz.value)" v-for="tz in gameModel.timezones.list">
								<strong>{{tz.value}}</strong>
								<div>{{tz.name}}</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<span class="clickable" @click="gameModel.timezones.doToggleShow()" content="Тип времени" v-tippy="{placement: 'top'}">
			<span>{{gameModel.timezones.selected}}</span>
		</span>
	</div>
</template>

<style lang="css" scoped>
#footer #tzSelector {
	position: absolute;
    right: 130px;
    bottom: -1px;
    font-size: 1.38462rem;
    line-height: 1.6em;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}
#footer #tzSelector span:hover {
    color: #fff;
}
.dropdownBody.timeType {
	margin-left: 0;
	display: block;
	width: 160px;
	left: 0;
	bottom: 29px;
}
.dropdownBody.timeType ul li {
	height: auto;
	display: block;
	white-space: normal;
	line-height: normal;
}
.dropdownBody.timeType .dropdownOption strong {
	display: block;
	text-align: left;
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
    }),
	computed: {},
	created(){},
	methods: {
		
	},
	components: {},
}
</script>
<template>
	<div>
		<div class="manual troops">
			<div class="details" ng-include="'tpl/manual/partials/unitsDetails.html'">
				<div class="contentBox">
					<div class="contentBoxBody">
						<div class="properties" :class="{zoomed: imageZoomed}">
							<div class="imageWrapper clickable" @click="imageZoomed = !imageZoomed">
								<img src="//iwstatic.g.bsrv.su/img/x.gif" size="big" :class="[{'unitBig': !imageZoomed, 'unitFull': imageZoomed}, gameModel.help.selectedGroup, 'unitType'+gameModel.help.selectedElement]" data-cmp-info="10"/>
								<i class="action_zoomIn_medium_flat_black" v-show="!imageZoomed"></i>
								<i class="action_zoomOut_medium_flat_black" v-show="imageZoomed"></i>
							</div>
							<div class="contentBox stats" v-show="!imageZoomed">
								<h6 class="contentBoxHeader headerTrapezoidal">
									<div class="content">
										<span translate=""><span>Параметры</span></span>
										<span class="basic">(<span translate=""><span>Базовые</span></span>)</span>
									</div>
								</h6>
								<div class="contentBoxBody">
									<div class="valueContainer">
										<span class="valueBox" content="Атака" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Attack">
											<i class="movement_attack_small_flat_black"></i>{{getElement().attributes.attack}}
										</span>
										<span class="valueBox" content="Защита от пехоты" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseInfantry">
											<i class="unit_defenseInfantry_small_flat_black"></i>{{getElement().attributes.defenseInfantry}}
										</span>
										<span class="valueBox" content="Защита от кавалерии" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseCavalry">
											<i class="unit_defenseCavalry_small_flat_black"></i>{{getElement().attributes.defenseCavalry}}
										</span>
									</div>
									<div class="horizontalLine"></div>
									<div class="valueContainer">
										<span class="valueBox" content="Могут переносить" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Carry">
											<i class="unit_capacity_small_flat_black"></i>{{getElement().attributes.carry}}
										</span>
										<span class="valueBox" content="Скорость" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Speed">
											<i class="unit_speed_small_flat_black"></i>{{getElement().attributes.speed}}
										</span>
										<span class="valueBox" content="Потребление" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Consumption">
											<i class="unit_consumption_small_flat_black"></i>{{getElement().attributes.consumption}}
										</span>
									</div>
									<!--
									<slot name="stats">
										<div class="valueContainer">
											<span class="valueBox" content="Атака" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Attack">
												<i class="movement_attack_small_flat_black"></i>
											</span>
											<span class="valueBox" content="Защита от пехоты" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseInfantry">
												<i class="unit_defenseInfantry_small_flat_black"></i>0
											</span>
											<span class="valueBox" content="Защита от кавалерии" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseCavalry">
												<i class="unit_defenseCavalry_small_flat_black"></i>
											</span>
										</div>
										<div class="horizontalLine"></div>
										<div class="valueContainer">
											<span class="valueBox" content="Могут переносить" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Carry">
												<i class="unit_capacity_small_flat_black"></i>0
											</span>
											<span class="valueBox" content="Скорость" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Speed">
												<i class="unit_speed_small_flat_black"></i>0
											</span>
											<span class="valueBox" content="Потребление" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Consumption">
												<i class="unit_consumption_small_flat_black"></i>0
											</span>
										</div>
									</slot>
									-->
								</div>
							</div>
						</div>
						<div class="needs" v-show="!imageZoomed">
							<div class="contentBox training">
								<h6 class="contentBoxHeader headerTrapezoidal">
									<div class="content">
										<span translate=""><span>Обучение</span></span>
										<span class="basic">(<span translate=""><span>Базовые</span></span>)</span>
									</div>
								</h6>
								<div class="contentBoxBody">
									<div class="valueContainer">
										<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1">
											<i class="unit_wood_small_illu"></i>{{getElement().trainingResources.wood}}
										</span>
										<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Глина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_2">
											<i class="unit_clay_small_illu"></i>{{getElement().trainingResources.clay}}
										</span>
										<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Железо" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_3">
											<i class="unit_iron_small_illu"></i>{{getElement().trainingResources.iron}}
										</span>
									</div>
									<div class="horizontalLine"></div>
									<div class="valueContainer">
										<span class="valueBox" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration">
											<i class="symbol_clock_small_flat_black duration"></i>{{getElement().trainingDuration}}
										</span>
									</div>
									<!--
									<slot name="training">
										<div class="valueContainer">
											<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1">
												<i class="unit_wood_small_illu"></i>0
											</span>
											<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Глина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_2">
												<i class="unit_clay_small_illu"></i>0
											</span>
											<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Железо" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_3">
												<i class="unit_iron_small_illu"></i>0
											</span>
										</div>
										<div class="horizontalLine"></div>
										<div class="valueContainer">
											<span class="valueBox" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration">
												<i class="symbol_clock_small_flat_black duration"></i>00:00:00
											</span>
										</div>
									</slot>
									-->
								</div>
							</div>
							<div class="contentBox research">
								<h6 class="contentBoxHeader headerTrapezoidal">
									<div class="content" translate=""><span>Исследование</span></div>
								</h6>
								<slot name="research">
									<div class="contentBoxBody" v-if="getElement().nr == 1 || getElement().nr == TroopsModel.TYPE_SETTLER">
										<div class="valueContainer notNecessary">
											<span translate=""><span>нет необходимости</span></span>
										</div>
										<div class="horizontalLine"></div>
										<div class="valueContainer buildingsRequired">
											<div class="valueBox" v-for="requirement in getElement().requirements">
												<a clickable="goToBuilding(requirement.buildingType)" translate="" class="clickable"><span>{{requirement.name}}</span></a>
												<span translate=""><span>Уровень</span></span> {{requirement.level}}
											</div>
											<span translate="" class="valueBox" v-if="getElement().requirements.length == 0"><span>Нет</span></span>
										</div>
									</div>
									<div class="contentBoxBody" v-if="getElement().nr != 1 && getElement().nr != TroopsModel.TYPE_SETTLER">
										<div class="valueContainer resourcesRequired">
											<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1">
												<i class="unit_wood_small_illu"></i>{{getElement().researchResources.wood}}
											</span>
											<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Глина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_2">
												<i class="unit_clay_small_illu"></i>{{getElement().researchResources.clay}}
											</span>
											<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Железо" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_3">
												<i class="unit_iron_small_illu"></i>{{getElement().researchResources.iron}}
											</span>
										</div>
										<div class="horizontalLine"></div>
										<div class="valueContainer">
											<span class="valueBox" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration">
												<i class="symbol_clock_small_flat_black duration"></i>{{getElement().researchDuration}}
											</span>
										</div>
										<div class="horizontalLine"></div>
										<div class="valueContainer buildingsRequired">
											<div class="valueBox" v-for="requirement in getElement().requirements">
												<a clickable="goToBuilding(requirement.buildingType)" translate="" class="clickable"><span>{{requirement.name}}</span></a>
												<span translate=""><span>Уровень</span></span> {{requirement.level}}
											</div>
											<span translate="" class="valueBox" v-if="getElement().requirements.length == 0"><span>Нет</span></span>
										</div>
									</div>
								</slot>
							</div>
						</div>
						<div class="contentBox description" v-show="!imageZoomed">
							<div class="contentBoxBody">
								<div class="descriptionBody scrollable" scrollable="">
									<div class="scrollContentOuterWrapper" style="width: 476px;">
										<div class="scrollContent" dynamic-height="false" style="width: 476px; overflow-y: hidden;">
											<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
												<span v-html="getElement().description"></span>
												<!--
												<slot name="description">
													<span translate="" options="21"><span>Описание...</span></span>
												</slot>
												-->
											</div>
										</div>
									</div>
								</div>
								<div class="stepsContainer">
									<div class="stepButtonsContainer">
										<a class="stepButton clickable" v-for="troop in 10" @click="onSelectElement(gameModel.help.selectedGroup, troop)" :class="{'last': troop == 10, 'active': troop == gameModel.help.selectedElement}">
											<i class="unitSmall" :class="[gameModel.help.selectedGroup, 'unitType'+troop]"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import TroopsModel from '@/models/game/TroopsModel';

export default {
	mixins: lib.mixins,
	data: () => ({
		TroopsModel,
		
		imageZoomed: false,
	}),
	computed: {},
	methods: {
		getElement(){
			return this.gameModel.help.getElement(this.gameModel.help.selectedSection, this.gameModel.help.selectedGroup, this.gameModel.help.selectedElement);
		},
		
		onSelectElement(group, element){
			this.gameModel.help.selectedGroup = group;
			this.gameModel.help.selectedElement = element;
			this.gameModel.help.selectedPage = 1;
			
			this.$router.push({query: Object.assign({}, this.$route.query, {
				group,
				element,
			})});
		},
	},
	components: {},
}
</script>
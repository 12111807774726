import axios from 'axios';
import lib from '@/lib';

export default class TimezonesModel
{
	static TIME_TYPE_UTC = 0;
	static TIME_TYPE_LOCAL = 1;
	static TIME_TYPE_GAMEWORLD = 2;
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.selectShow = false;
		this.selected = 'LOC';
		
		this.list = [
			{name: 'Универсальное время', value: 'UTC'},
			{name: 'Местное время', value: 'LOC'},
			{name: 'Время сервера', value: 'GT'},
		];
	}
	
	/**
	 * Выбор timezone
	 */
	doSelect(tz)
	{
		this.selected = tz;
		this.selectShow = false;
	}
	
	doToggleShow()
	{
		this.selectShow = !this.selectShow;
	}
}

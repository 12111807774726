<template>
	<div ng-if="overlayController" class="windowOverlay" id="useItem" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="659"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Hero.UseArtwork" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Использовать произведение искусства</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="useItem">

	<!-- ngIf: isHealingPotion -->

	<!-- ngIf: !isHealingPotion --><div ng-if="!isHealingPotion" class="itemContainer">
		<hero-item-container item="item" hide-amount="true" hide-item-states="true"><div class="entityBox item item_116 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:46443,notUsableItem:,notUsableReason:,auctionItem:false,nextUpgradeLevel:false,itemType:116" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_artwork_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
		<div class="verticalLine"></div>
		<!-- ngIf: ::isArtwork --><div ng-if="::isArtwork" class="confirmation description"><p><span translate="" data="amount:1596"><span>За этот предмет ты получишь 1596 ед. культуры.</span></span></p></div><!-- end ngIf: ::isArtwork -->
		<!-- ngIf: ::!useText && !stackable --><div ng-if="::!useText &amp;&amp; !stackable" class="confirmation description" translate=""><span>Ты действительно желаешь использовать этот предмет?</span></div><!-- end ngIf: ::!useText && !stackable -->
		<!-- ngIf: ::!useText && stackable -->
		<!-- ngIf: ::useText -->
	</div><!-- end ngIf: !isHealingPotion -->

	<!-- ngIf: stackable -->

	<div class="buttonBar">
		<!-- ngIf: !treasuresSold --><span ng-if="!treasuresSold">
			<!-- ngIf: !treasurePrice --><button ng-if="!treasurePrice" clickable="useItem()" class="useItemButton clickable"><div class="content">
	<span ng-transclude="" class="innerText">
				<!-- ngIf: ::!useButtonText --><span ng-if="::!useButtonText" data="ItemName:Произведение искусства" translate=""><span>Использовать: Произведение искусства</span></span><!-- end ngIf: ::!useButtonText -->
				<!-- ngIf: ::useButtonText -->
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: !treasurePrice -->

			<!-- ngIf: treasurePrice -->
			<button clickable="closeOverlay()" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Отмена</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		</span><!-- end ngIf: !treasuresSold -->
		<!-- ngIf: treasuresSold -->
	</div>

</div>
</div>
	</div>
</div></div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
import { createRouter, createWebHistory } from 'vue-router';

import lib from '@/lib';

import Layout from '@/layouts/Layout';
import LayoutEmpty from '@/layouts/LayoutEmpty';

import Township from '@/pages/Township';
import ShrineBuildingTownship from '@/pages/buildings/Shrine';
import HouseBuildingTownship from '@/pages/buildings/House';
import BarracksBuildingTownship from '@/pages/buildings/Barracks';
import TavernBuildingTownship from '@/pages/buildings/Tavern';
import ShopBuildingTownship from '@/pages/buildings/Shop';
import StorageBuildingTownship from '@/pages/buildings/Storage';
import ForgeBuildingTownship from '@/pages/buildings/Forge';
import PostBuildingTownship from '@/pages/buildings/Post';
import EmbassyBuildingTownship from '@/pages/buildings/Embassy';
import AcademyBuildingTownship from '@/pages/buildings/Academy';
import StablingBuildingTownship from '@/pages/buildings/Stabling';
import AreaBuildingTownship from '@/pages/buildings/Area';
import CacheBuildingTownship from '@/pages/buildings/Cache';
import ResidenceBuildingTownship from '@/pages/buildings/Residence';
import HealingBuildingTownship from '@/pages/buildings/Healing';
import TrapBuildingTownship from '@/pages/buildings/Trap';
import HiddenCacheBuildingTownship from '@/pages/buildings/HiddenCache';
import StonemasonBuildingTownship from '@/pages/buildings/Stonemason';
import WorkshopBuildingTownship from '@/pages/buildings/Workshop';
import MoatBuildingTownship from '@/pages/buildings/Moat';
import ArenaBuildingTownship from '@/pages/buildings/Arena';
import TownHallBuildingTownship from '@/pages/buildings/TownHall';
import ConstructBuildingTownship from '@/pages/buildings/Construct';

import Island from '@/pages/Island';
import FortBuildingIsland from '@/pages/buildings/Fort';
import SawmillBuildingIsland from '@/pages/buildings/Sawmill';
import CareerBuildingIsland from '@/pages/buildings/Career';
import StoneBuildingIsland from '@/pages/buildings/Stone';
import MineBuildingIsland from '@/pages/buildings/Mine';
import FarmBuildingIsland from '@/pages/buildings/Farm';
import PortBuildingIsland from '@/pages/buildings/Port';

import Reports from '@/pages/Reports';
import Hero from '@/pages/Hero';
import Shop from '@/pages/Shop';
import Profile from '@/pages/Profile';
import KingdomProfile from '@/pages/KingdomProfile';
import VillageOverview from '@/pages/VillageOverview';
import Society from '@/pages/Society';
import Statistics from '@/pages/Statistics';
import Help from '@/pages/Help';
import Igm from '@/pages/Igm';
import QuestBook from '@/pages/QuestBook';
import ProductionOverview from '@/pages/ProductionOverview';
import WelcomeScreen from '@/pages/WelcomeScreen';
import SystemMessage from '@/pages/SystemMessage';
import SystemMessage2 from '@/pages/SystemMessage2';
import ReportSingle from '@/pages/ReportSingle';
import KingdomUnionSuccess from '@/pages/KingdomUnionSuccess';
import SendTroops from '@/pages/SendTroops';
import NpcTrader from '@/pages/NpcTrader';
import BuildingAreaFarmListEntryAction2 from '@/pages/BuildingAreaFarmListEntryAction2';

import Map from '@/pages/Map';
import Map2 from '@/pages/Map2';
import Map22 from '@/pages/Map22';
import Map3 from '@/pages/Map3';
import MapAbandonedValley from '@/pages/MapAbandonedValley';
import MapCapturedOasis from '@/pages/MapCapturedOasis';
import MapDenOfRobbers from '@/pages/MapDenOfRobbers';
import MapOasis from '@/pages/MapOasis';
import MapVillage from '@/pages/MapVillage';
import MapWildLand from '@/pages/MapWildLand';
import MapWonderOfTheWorld from '@/pages/MapWonderOfTheWorld';

import Requests from '@/pages/Requests';

const routes = [
	{
		path: '/',
		redirect: '/township',
	},
	{
		path: '/township',
		component: Layout,
		children: [
			{
				path: '',
				component: Township,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/shrine',
				component: ShrineBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/house',
				component: HouseBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/barracks',
				component: BarracksBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/tavern',
				component: TavernBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/shop',
				component: ShopBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/storage',
				component: StorageBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/forge',
				component: ForgeBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/post',
				component: PostBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/embassy',
				component: EmbassyBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/academy',
				component: AcademyBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/stabling',
				component: StablingBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/area',
				component: AreaBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/cache',
				component: CacheBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/residence',
				component: ResidenceBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/healing',
				component: HealingBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/trap',
				component: TrapBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/hidden-cache',
				component: HiddenCacheBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/stonemason',
				component: StonemasonBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/workshop',
				component: WorkshopBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/moat',
				component: MoatBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/arena',
				component: ArenaBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/town-hall',
				component: TownHallBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/township/construct',
				component: ConstructBuildingTownship,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/reports',
				component: Reports,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/hero',
				component: Hero,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/shop',
				component: Shop,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/profile',
				component: Profile,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/profile/:id',
				component: Profile,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/profile-kingdom/:id',
				component: KingdomProfile,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/village-overview',
				component: VillageOverview,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/society',
				component: Society,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/statistics',
				component: Statistics,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/help',
				component: Help,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/igm',
				component: Igm,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/quest-book',
				component: QuestBook,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/production-overview',
				component: ProductionOverview,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/welcome-screen',
				component: WelcomeScreen,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/system-message',
				component: SystemMessage,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/system-message-2',
				component: SystemMessage2,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/report-single',
				component: ReportSingle,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/kingdom-union-success',
				component: KingdomUnionSuccess,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/npc-trader',
				component: NpcTrader,
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: '/island',
		component: Layout,
		children: [
			{
				path: '',
				component: Island,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/island/fort',
				component: FortBuildingIsland,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/island/sawmill',
				component: SawmillBuildingIsland,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/island/career',
				component: CareerBuildingIsland,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/island/stone',
				component: StoneBuildingIsland,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/island/mine',
				component: MineBuildingIsland,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/island/farm',
				component: FarmBuildingIsland,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/island/port',
				component: PortBuildingIsland,
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: '/map',
		component: Layout,
		children: [
			{
				path: '',
				component: Map,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/map/captured-oasis',
				component: MapCapturedOasis,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/map/abandoned-valley',
				component: MapAbandonedValley,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/map/den-of-robbers',
				component: MapDenOfRobbers,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/map/oasis',
				component: MapOasis,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/map/village',
				component: MapVillage,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/map/wild-land',
				component: MapWildLand,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/map/wonder-of-the-world',
				component: MapWonderOfTheWorld,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/send-troops',
				component: SendTroops,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/building-area-farm-list-entry-action2',
				component: BuildingAreaFarmListEntryAction2,
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: '/map22',
		component: Layout,
		children: [
			{
				path: '',
				component: Map22,
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: '/map2',
		component: Map2,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/map3',
		component: Map3,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/requests',
		component: Requests,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/login',
		component: LayoutEmpty,
		beforeEnter: (to, from, next) => {
			window.location = 'https://iwpanel.g.bsrv.su';
		},
	},
	{
		path: '/logout',
		component: LayoutEmpty,
		beforeEnter: (to, from, next) => {
			storeInstance.state.app.auth.doLogout().then(() => {
				next({path: '/'});
			}).catch((error) => {
				console.log(error);
				next();
			});
		},
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/',
	},
	{
		path: '/:pathMatch(.*)',
		redirect: '/',
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to, from, next) => {
	await storeInstance.state.app.auth.doCheckAuth().then(() => {
		if(to.matched.some(record => record.meta.requiresAuth)){
			if(!storeInstance.state.app.auth.isAuth()){
				window.location = 'https://iwpanel.g.bsrv.su';
			} else {
				/*if(to.matched.some(record => record.meta.is_admin)){
					if(storeInstance.state.app.auth.hasRole('admin')){
						next()
					} else {
						next('/')
					}
				} else {
					next()
				}*/
				next()
			}
		} else if(to.matched.some(record => record.meta.guest)){
			if(storeInstance.state.app.auth.isAuth()){
				next('/')
			} else {
				next()
			}
		} else {
			next()
		}
	});
});
/*// Не позволяет авторизованному пользователю перейти на страницу логина и перекидывает его на главную страницу
router.beforeEach((to, from, next) => {
	if (to.path == '/login' && storeInstance.state.app.auth.userProfile !== null) next({path: '/'})
  	else next()
})*/

export default router;

<template>
	<!-- ngIf: player.data.deletionTime > 0 -->
	<div class="auction sell" style="margin-top: 15px;" v-if="gameModel.player.deletionTime == 0">
		<div class="contentBox gradient">
			<h6 class="contentBoxHeader headerWithArrowEndings">
				<div class="content"><span>Инвентарь</span></div>
			</h6>
			<div class="contentBoxBody unselectable scrollable">
				<div class="scrollContentOuterWrapper">
					<div class="scrollContent" style="overflow-y: auto;">
						<div class="scrollContentInnerWrapper" style="width: auto;">
							<!-- ngIf: inventory.length == 0 -->
							<div v-if="inventory.length > 0 " class="items">
								<hero-item-container v-for="item in inventory" :key="item.id" :item="item" :hide-states="true" :hide-upgrade-container="true" ng-show="::config.balancing.heroItems[item.itemType].salable" :click-handler="() => onSelectItem(item)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="contentBox gradient">
			<h6 class="contentBoxHeader headerWithArrowEndings">
				<div class="content"><span>Продать</span></div>
			</h6>
			<div class="contentBoxBody">
				<div v-if="!currentItem" class="noSelection">
					<span>Пожалуйста, выбери предмет на продажу.</span>
				</div>
				<div v-if="currentItem">
					<hero-item-container :item="currentItem" :hide-states="true" :hide-upgrade-container="true" />
					<div class="descriptionWrapper scrollable">
						<div class="scrollContentOuterWrapper active">
							<div class="scrollContent" style="overflow-y: auto;">
								<div class="scrollContentInnerWrapper">
									<!-- ngIf: currentItem.auction -->
									<div class="itemDescription" v-if="!currentItem.auction">
										<div class="heroItemName">
											<span>{{currentItem.name}}</span>
										</div>
										<div ng-hide="instantUse && !InventoryModel.isResourceItem(currentItem.itemType) || hideInfo || !hasBonuses">
											<div class="horizontalLine"></div>
											<span ng-repeat="(i, v) in bonuses">
												<span options="14" data="x:1"><span>+1 здоровья в день</span></span><br>
											</span>
										</div>
										<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->
										<div ng-show="instantUse && (currentItem.itemType > InventoryModel.TYPE_CROP_BONUS_5 || currentItem.itemType < InventoryModel.TYPE_RESOURCE_BONUS_3) && currentItem.itemType != InventoryModel.TYPE_ARTWORK && currentItem.itemType != InventoryModel.TYPE_FIREWORKS && currentItem.itemType != InventoryModel.TYPE_SUPER_FIREWORKS && !InventoryModel.isResourceItem(currentItem.itemType)">
											<div class="horizontalLine"></div>
											<span options="112"><span>Восстанавливает здоровье твоего героя. Чем больше ты используешь мазей, тем больше единиц здоровья будет восстановлено (макс. 100%).</span></span>
										</div>
										<div v-if="currentItem.itemType == InventoryModel.TYPE_FIREWORKS || currentItem.itemType == InventoryModel.TYPE_SUPER_FIREWORKS">
											<div class="horizontalLine"></div>
											<span options="112" data="x:"><span>Восстанавливает здоровье твоего героя. Чем больше ты используешь мазей, тем больше единиц здоровья будет восстановлено (макс. 100%).</span></span>
										</div>
										<div v-if="currentItem.itemType == InventoryModel.TYPE_ARTWORK">
											<div class="horizontalLine"></div>
											<span options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
										</div>
										<div v-if="currentItem.itemType == InventoryModel.TYPE_BANDAGE_25">
											<div class="horizontalLine"></div>
											<span><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
										</div>
										<div v-if="currentItem.itemType == InventoryModel.TYPE_BANDAGE_33">
											<div class="horizontalLine"></div>
											<span><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
										</div>
										<div v-if="currentItem.itemType == InventoryModel.TYPE_CAGES">
											<div class="horizontalLine"></div>
											<span><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
										</div>
										<div v-if="currentItem.itemType == InventoryModel.TYPE_BANDAGE_25_UPGRADED">
											<hr>
											<span><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
										</div>
										<div v-if="currentItem.itemType == InventoryModel.TYPE_BANDAGE_33_UPGRADED">
											<hr>
											<span><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
										</div>
										<div v-if="currentItem.itemType == InventoryModel.TYPE_HEALING_POTION">
											<hr>
											<span><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
										</div>
										<div v-if="currentItem.itemType <= InventoryModel.TYPE_CROP_BONUS_5 && currentItem.itemType >= InventoryModel.TYPE_RESOURCE_BONUS_3">
											<div class="horizontalLine"></div>
											<span ng-repeat="bonus in bonuses" data="x:1"><span>При использовании моментально добавляет 1% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span>
										</div>
										<div ng-show="perDay.max || perDay.maxWithoutLock">
											<div class="horizontalLine"></div>
											<span ng-show="consumable"><span>Употребляемые предметы</span></span>
											<br>
											<span ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{'error': perDay.used >= perDay.max}">
												<span>Сегодня ты использовал  из максимального числа .</span>
											</span>
											<span ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{'error': perDay.used >= perDay.maxWithoutLock}">
												<span>Сегодня ты использовал  из максимального числа .</span>
											</span>
										</div>
										<div ng-if="unitName">
											<div class="horizontalLine"></div>
											<span data="x:3,name:Фаланга"><span>Фаланга: Каждый воин этого типа получает +3 к атаке и +3 к защите.</span></span>
											<span data="x:4,name:Мечник"><span>Мечник: Каждый воин этого типа получает +4 к атаке и +4 к защите.</span></span>
											<span data="x:9,name:Эдуйская конница"><span>Эдуйская конница: Каждый воин этого типа получает +9 к атаке и +9 к защите.</span></span>
										</div>
										<div v-if="currentItem.itemType == InventoryModel.TYPE_CAGES">
											<div class="horizontalLine"></div>
											<span><span>Если у твоего героя имеются клетки в слоте предмета, битва в оазисе не состоится. Вместо этого будут пойманы животные.</span></span>
										</div>
										<div class="upgradeBonus" v-if="currentItem.upgradeLevel > 0">
											<div class="horizontalLine"></div>
											<div>
												<span>
													<strong>Улучшения</strong>
													<span> (</span>
													<span class="values">{{currentItem.upgradeLevel}}/5</span>
													<span>)</span>
												</span>
												<span class="upgradeLevel">
													<i v-for="a in range(1, 5)" :class="{
														'upgrade_helmet_tiny_illu': currentItem.slot == InventoryModel.SLOT_HELMET && currentItem.upgradeLevel >= a,
														'upgrade_rightHand_tiny_illu': currentItem.slot == InventoryModel.SLOT_RIGHT_HAND && currentItem.upgradeLevel >= a,
														'upgrade_leftHand_tiny_illu': currentItem.slot == InventoryModel.SLOT_LEFT_HAND && currentItem.upgradeLevel >= a,
														'upgrade_body_tiny_illu': currentItem.slot == InventoryModel.SLOT_BODY && currentItem.upgradeLevel >= a,
														'upgrade_shoes_tiny_illu': currentItem.slot == InventoryModel.SLOT_SHOES && currentItem.upgradeLevel >= a,
														'upgrade_upgrade_tiny_flat_black': currentItem.upgradeLevel < a
													}"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- ngIf: currentItem.auction -->
					<div v-if="!currentItem.auction" class="inputFields">
						<div v-if="isNpcBuying" class="itemValue">
							<span><span>Цена:</span></span>
							<div><i class="unit_silver_small_illu"></i> {{currentItem.sellAmount}}</div>
						</div>
						<div v-if="currentItem.stackable && currentItem.amount > 1" class="amount">
							<div class="amountHeader"><span>Количество:</span></div>
							<range-slider :min="1" :max="currentItem.amount" :step="1" v-model.number="sellCount" />
						</div>
						<div class="totalValue" v-if="isNpcBuying && currentItem.stackable && currentItem.amount > 1">
							<span><span>Всего:</span></span>
							<div><i class="unit_silver_small_illu"></i> {{currentItem.sellAmount * sellCount}}</div>
						</div>
						<div v-if="!isNpcBuying" class="directAuctionDescription">
							<span translate=""><span>Идет расчет цены. Окончание через </span></span>
							<span class="ticker" countdown="">00:00:00</span>
						</div>
						<div class="sellButtonContainer" v-if="isNpcBuying">
							<button @click="onSellItem()" class="animate clickable">
								<div class="content">
									<span class="innerText">
										<span><span>Продать</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import InventoryModel from '@/models/game/InventoryModel';

import HeroItemContainer from '@/components/elements/HeroItemContainer';
import RangeSlider from '@/components/elements/RangeSlider';

export default {
	mixins: lib.mixins,
	data: () => ({
		InventoryModel,
		
		currentItem: null,
		isNpcBuying: true,
		
		sellCount: 1,
	}),
	computed: {
		inventory(){
			return this.gameModel.inventory.items;
		},
	},
	methods: {
		range: lib.range,
		
		onSelectItem(item){
            this.currentItem = item;
            this.sellCount = 1;
        },
		onSellItem(){
			// после продажи предмета продавец получает сумму больше стандартной если предмет новый и его качество превосходное и т.д. но на аукцион этот предмет выставляется по стандартной цене умноженной на "предмет не новый (предыдущих владельцев минимум 1) т.е. на 0,75 в случае если предыдущих владельцев 1. например стоимость предмета 473. продавец получает 457 * 1.5 (685.5 округляется до 686). на аукцион выставляется по 457 * 0.75 (342.75 округляется до 343)
			// у аукциона устанавливается время его окончания через 24 часа
		},
	},
	mounted(){},
	components: {
		HeroItemContainer,
		RangeSlider,
	},
}
</script>
<template>
	<div>
		
	</div>
</template>

<style lang="scss">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Requests | IW'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		loadData(){
			fetch('/api/data/startInfo', {
				mode: 'cors',
				credentials: 'include',
				headers: {
					Authorization: 'Bearer '+this.gameModel.token,
				},
			}).then(stream => stream.json()).then(data => {
				console.log(data);
			}).catch(error => {
				console.error(error);
			});
		},
	},
	created(){
		//this.gameModel.buildingQueueShow = false;
		
		//this.loadData();
	},
	components: {},
}
</script>
<template>
	<router-view/>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'IW'});
	},
	data: () => ({}),
	computed: {},
	methods: {},
	created(){		
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.authModel.doLogout();
					window.location = 'https://iwpanel.g.bsrv.su';
				}
				throw err;
			});
		});
	},
	components: {},
}
</script>

<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper reports" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal reports">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/map')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span>Логово разбойников</span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
				<div class="mapCellDetails" ng-controller="mapCellDetailsCtrl">
	<div dynamic="" scrollable="" class="scrollable"><div class="scrollContentOuterWrapper active" style="width: 610px;">
	<div class="scrollContent" dynamic-height="true" style="width: 630px; overflow-y: auto; max-height: 453px;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: 610px;">
		<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/header.html'"><div class="mapContainer npcVillageRobber">
	<!-- ngIf: mapDetails.isWonder -->
	<!-- ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'npcVillageRobber' --><div class="robberIllu" ng-if="fieldType == 'npcVillageRobber'"></div><!-- end ngIf: fieldType == 'npcVillageRobber' -->
	<!-- ngIf: cellData.menhir -->
	<!-- ngIf: fieldType != 'oasis' && fieldType != 'npcVillageRobber' && !mapDetails.isWonder && !cellData.menhir -->
	<!-- ngIf: mapDetails.isWonder -->
	<!-- ngIf: showAttack && attackingTroops.cnt == 0 -->
	<!-- ngIf: attackingTroops.cnt > 0 -->
	<!-- ngIf: mapDetails.npcInfo.type == 8 -->
	<!-- ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' -->
</div></div>
		<div class="otherContainer npcVillageRobber">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/bodyTop.html'"><div class="splitContainer npcVillageRobber">
	<!-- ngIf: fieldType == 'village' && mapDetails.wwValues -->
	<!-- ngIf: fieldType == 'village' -->
	<!-- ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' --><div ng-if="fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor'">
		<!-- ngIf: mapDetails.npcInfo.type == Village.TYPE_KINGDOM_ROBBER -->
        <!-- ngIf: mapDetails.npcInfo.type == Village.TYPE_GOVERNOR_NPC_VILLAGE -->
		<!-- ngIf: mapDetails.npcInfo.type != Village.TYPE_KINGDOM_ROBBER && mapDetails.npcInfo.type != Village.TYPE_GOVERNOR_NPC_VILLAGE --><div ng-if="mapDetails.npcInfo.type != Village.TYPE_KINGDOM_ROBBER &amp;&amp; mapDetails.npcInfo.type != Village.TYPE_GOVERNOR_NPC_VILLAGE" class="normal">
			<div class="contentBox">
				<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/npcVillageDetails.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6><span translate=""><span>Информация</span></span> <span class="coordinateWrapper" coordinates="" x="-7" y="11">
	<!-- ngIf: hasLink --><a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537247737_1708113071.1');" class="clickable">
		<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;7&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;11&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
	</a><!-- end ngIf: hasLink -->
	<!-- ngIf: !hasLink -->
</span></h6>
</div>
<div class="contentBoxBody">
	<table class="transparent">
		<tbody><tr>
			<th translate=""><span>Владелец</span></th>
			<td><div class="longTitle">
				<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="-1" playername="">Разбойник</a></div>
			</td>
		</tr>
		<tr>
			<th translate=""><span>Народ</span></th>
			<td translate="" options="2"><span>Германцы</span></td>
		</tr>
	</tbody></table>
	<div class="npcDescription" translate="" options="5"><span>Совершая набеги на логова разбойников, ты можешь добыть ресурсы и краденые ценности.</span></div>
</div></div>
			</div>
			<div class="contentBox lootContainer">
				<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/loot.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6 translate=""><span>Добыча</span></h6>
</div>
<div class="contentBoxBody">
	<div class="loot">
		<div class="horizontalLine"></div>
		<div class="verticalLine"></div>
		<!-- ngIf: player.isKing() -->
		<!-- ngIf: !player.isKing() --><div ng-if="!player.isKing()">
			<display-resources resources="selectedCharge.loot" stolen-goods="selectedCharge.treasures"><div class="costs max3digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1">
			<i class="unit_wood_small_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;467&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" content="Глина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_2">
			<i class="unit_clay_small_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;578&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" content="Железо" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_3">
			<i class="unit_iron_small_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;338&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" content="Зерно" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_4">
			<i class="unit_crop_small_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;219&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 --><span class="resource treasureValue" ng-if="stolenGoods || stolenGoods == 0" content="Краденные ценности" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource.StolenGoods">
        <i class="unit_stolenGoods_small_illu"></i>0
    </span><!-- end ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources>
		</div><!-- end ngIf: !player.isKing() -->
	</div>
</div>
</div>
			</div>
            <div class="contentBox gradient troopContainer">
                <!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/troops.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6 translate=""><span>Войска</span></h6>
</div>
<div class="contentBoxBody">
	<div troops-details="" troop-data="selectedCharge.troops"><table class="troopsTable teuton">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall teuton unitType1 inactive" unit-icon="" data="11" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall teuton unitType2 inactive" unit-icon="" data="12" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall teuton unitType3 inactive" unit-icon="" data="13" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall teuton unitType4 inactive" unit-icon="" data="14" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall teuton unitType5 inactive" unit-icon="" data="15" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall teuton unitType6 inactive" unit-icon="" data="16" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall teuton unitType7 inactive" unit-icon="" data="17" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall teuton unitType8 inactive" unit-icon="" data="18" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall teuton unitType9 inactive" unit-icon="" data="19" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall teuton unitType10 inactive" unit-icon="" data="20" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
</div>
</div>
            </div>
		</div><!-- end ngIf: mapDetails.npcInfo.type != Village.TYPE_KINGDOM_ROBBER && mapDetails.npcInfo.type != Village.TYPE_GOVERNOR_NPC_VILLAGE -->
	</div><!-- end ngIf: fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->
	<!-- ngIf: fieldType == 'unhabitable' -->
	<!-- ngIf: fieldType == 'habitable' -->
</div>
</div>
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/bodyBottom.html'"><!-- ngIf: fieldType == 'oasis' -->

<!-- ngIf: fieldType == 'village' && mapDetails.isWonder && !mapDetails.isConquered -->

<div class="tributeCropContainer">
	<div class="tributes contentBox gradient ng-hide" ng-show="showTributes">
		<div class="contentBoxBody">
			<display-resources resources="village.tributes" treasures="village.tributeTreasures" hide-zero="true"><div class="costs max1digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources>
			<div class="barView">
				<div class="actualTributes">&lrm;&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;</div>
				<div class="populationBar progressbar" progressbar="" type="green" perc="NaN" marker="NaN">
	<!-- ngIf: ::labelIcon -->
	<div class="progress">
		<!-- ngIf: steps -->
		<div class="bar positive perc green"></div>
		<div class="bar additionalBar additionalPerc"></div>
		<div class="marker">
			<div class="markerInner"></div>
		</div>
		<!-- ngIf: ::showCountdown -->
		<!-- ngIf: ::label -->
		<!-- ngIf: ::percTooltip -->
	</div>
</div>
			</div>
			<button clickable="fetchTributes()" ng-class="{disabled: !village.canFetchTributes || !village.allowTributeCollection || !village.protectionGranted || !activeTreasury || (!territoryConnected &amp;&amp; village.type != Village.TYPE_GOVERNOR_NPC_VILLAGE)}" play-on-click="16" tooltip="" tooltip-translate-switch="{'ContextMenu.button.fetchTribute.denied': true,
											   'ContextMenu.button.fetchTribute.unprotected': true,
											   'Tributes.VillageNotGeneratingInfluence': true,
											   'Tributes.VillageNotConnected': false}" class="clickable disabled"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Собрать дань</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		</div>
	</div>
	<!-- ngIf: sharedInformations.cropDetails != null -->
</div>

<p ng-show="error" class="error ng-hide">
	
</p>

<!-- ngIf: fieldType == 'village' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' --><div ng-if="fieldType == 'village' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor'">
	<!-- ngRepeat: troopDetails in troopsHere -->
</div><!-- end ngIf: fieldType == 'village' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->

<!-- ngIf: sharedInformations.stationedTroops.length > 0 --></div>
		</div>
	</div>
	</div>
</div>
<div class="scrollTrack" style="opacity: 1; display: block;">
    <div class="scrollHandle" style="top: 0px; height: 431.111px;"></div>
	<div class="trackDecoration"></div>
</div></div>
	<div class="cellActionContainer">
		<div class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '537247737_1708113071.1'); closeWindow(w.name);">
			<i class="symbol_target_small_flat_black"></i>
		</div>
		<!-- ngIf: options.length > 0 --><div class="options" ng-if="options.length > 0" more-dropdown="" more-dropdown-options="getOptions()" style="position: relative;"><a class="iconButton clickable" clickable="openMoreDropdown()"><!-- ngIf: showCount --><i class="symbol_arrowDown_tiny_illu"></i></a>
			<div class="moreDropDownBody dropdownBody" style="bottom: 0; right: 0; display: block;"><ul><!-- ngRepeat: option in options --><li ng-repeat="option in options" clickable="openWindow('markerMenu',{'cellId': 537247737}); closeMoreDropdown()" class="clickable">Отметить поле</li><!-- end ngRepeat: option in options --><li ng-repeat="option in options" clickable="openInSim(); closeMoreDropdown()" class="clickable">Рассчитать в симуляторе</li><!-- end ngRepeat: option in options --></ul></div>
		</div><!-- end ngIf: options.length > 0 -->
		<!-- ngIf: fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' --><button class="sendTroopsButton clickable" ng-if="fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor'" clickable="openWindow('sendTroops', {'x': -7, 'y': 11 })" tooltip="" tooltip-show="false" tooltip-translate="ContextMenu.NoRallyPoint" ng-class="{disabled: !hasRallyPoint}"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Отправить войска</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->
		<!-- ngIf: fieldType == 'habitable' && !cellData.menhir -->
		<!-- ngIf: cellData.menhir && cellData.menhir == player.data.playerId -->
		<!-- ngIf: cellData.menhir && cellData.owner == player.data.playerId -->
	</div>
</div>
			</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
.societyFilter {
	float: right;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Логово разбойников | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
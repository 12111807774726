<template>
	<div ng-if="overlayController" class="windowOverlay" id="unlockAdditionalLocation" ng-controller="overlayController">
		<div class="darkener clickable" clickable="closeOverlay()"></div>
		<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="659">
			<div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
				<div class="inWindowPopupHeader">
					<h4 translate="" options="Hero.BuildingGround.Header" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Дополнительная строительная площадка</span></h4>
					<h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4>
					<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
				</div>
				<div class="inWindowPopupContent">
					<div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']">
						<div class="unlockAdditionalLocation useItem">
							<div class="contentBox">
								<h6 class="contentBoxHeader headerWithArrowDown" translate=""><span>Дополнительная строительная площадка</span></h6>
								<div class="contentBoxBody">
									<div class="itemContainer">
										<img class="heroItem_building_ground_large_illu" data-cmp-info="10">
										<div class="verticalLine"></div>
										<span ng-if="unlockedGrounds < maxPremiumLocations" class="confirmation description" translate=""><span>Вы хотите добавить дополнительную строительную площадку в деревне, которая сейчас открыта?</span></span>
										<!-- ngIf: unlockedGrounds == maxPremiumLocations -->
									</div>
									<div class="horizontalLine"></div>
									<table class="buildingGrounds transparent">
										<tbody>
											<tr>
												<th colspan="2">
													<span ng-bind-html="0 | bidiRatio:unlockedGrounds:maxPremiumLocations">0/2</span>
													<!-- ngIf: unlockedGrounds == maxPremiumLocations -->
												</th>
											</tr>
											<tr>
												<td><i class="premiumLocationPreview first disabled" ng-class="{disabled: unlockedGrounds == 0}"></i></td>
												<td><i class="premiumLocationPreview second disabled" ng-class="{disabled: unlockedGrounds < 2}"></i></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="buttonBar">
								<button clickable="unlockGround()" class="useItemButton clickable" ng-class="{disabled: unlockedGrounds == maxPremiumLocations}">
									<div class="content">
										<span ng-transclude="" class="innerText">
											<span translate=""><span>Открыть</span></span>
										</span>
										<!-- ngIf: price > 0 || forceGoldUsage -->
										<!-- ngIf: price == -1 && !forceGoldUsage -->
										<div class="animationOverlay"></div>
										<i class="checkmark action_check_small_flat_white"></i>
										<i class="checkmark action_check_small_flat_black"></i>
										<i class="checkmark action_check_small_flat_red"></i>
									</div>
								</button>
								<button clickable="closeOverlay()" class="cancel clickable">
									<div class="content">
										<span ng-transclude="" class="innerText">
											<span translate=""><span>Отмена</span></span>
										</span>
										<!-- ngIf: price > 0 || forceGoldUsage -->
										<!-- ngIf: price == -1 && !forceGoldUsage -->
										<div class="animationOverlay"></div>
										<i class="checkmark action_check_small_flat_white"></i>
										<i class="checkmark action_check_small_flat_black"></i>
										<i class="checkmark action_check_small_flat_red"></i>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
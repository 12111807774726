<template>
	<div class="dailyQuests contentBox gradient" ng-class="{adventures: isAdventure}">
		<div class="contentBoxBody">
			<div class="selectionContainer unselectable">
				<div v-for="quest in quests" :key="quest.id" class="possibleQuest">
					<div class="clickableContainer clickable" @click="onSelectQuest(quest.id)" clickable="selectDailyQuest(10004)" :class="{'active': quest.id == selectedQuest}">
						<i class="dailyQuest_flat_black dailyQuestsIcon"></i>
						<span class="questName ng-scope">
							<span>{{quest.name}}</span>
						</span>
						<i v-if="quest.status == QuestsModel.STATUS_DONE" class="symbol_star_small_illu doneMarker"></i>
						<div v-if="quest.status == QuestsModel.STATUS_ACTIVATABLE" class="newMarker">
							<i class="character_exclamation_mark_tiny"></i>
						</div>
						<div class="selectionArrow" v-if="quest.id == selectedQuest"></div>
					</div>
					<div class="contentBox">
						<div class="progressbar" :class="{'completed': quest.status == QuestsModel.STATUS_DONE}" :content="quest.status != QuestsModel.STATUS_DONE && quest.tooltip ? quest.tooltip+(quest.maxValue - quest.currentValue) : ''" v-tippy="{placement: 'bottom'}">
							<div class="progress">
								<div class="bar positive perc" style="z-index: 2;" :style="{width: getPercent(quest.currentValue, quest.maxValue)+'%'}"></div>
								<div class="bar additionalBar additionalPerc"></div>
								<div class="marker">
									<div class="markerInner"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-for="el in emptyQuests" :key="el" class="possibleQuest">
					<div class="clickableContainer disabled">
						<i class="dailyQuest_flat_black dailyQuestsIcon"></i>
						<span class="questName ng-scope">
							<span>Свободно</span>
						</span>
					</div>
				</div>
				<div class="contentBox statusNote">
					<span v-if="quests.length >= 3">
						<div class="statusNoteTitle">
							<span>Все слоты заданий уже используются.</span>
						</div>
						<div class="horizontalLine"></div>
						<div class="statusNoteBody">
							<span>Поменяй задание, если ты не можешь его выполнить.</span>
						</div>
					</span>
					<span v-else>
						<div class="statusNoteTitle">
							<span>До следующего задания: </span><span countdown="1708017316" callback="">01:37:41</span>
						</div>
						<div class="horizontalLine"></div>
						<div class="statusNoteBody">
							<span>Новое ежедневное задание будет появляться каждые 12 часов, если есть свободный слот. Одновременно может быть не более 3 открытых заданий. Раз в 12 часов ты можешь поменять существующее задание.</span>
						</div>
					</span>
				</div>
			</div>
			<div class="detailContainer fewQuests" v-if="selectedQuest" ng-include="'tpl/questDetails/questDetails.html'">
				<div ng-controller="questDetailsCtrl" class="questDetailsContent">
					<div class="borderWrapper">
						<div class="header">
							<img src="//iwstatic.g.bsrv.su/img/x.gif" :class="getSelectedQuest.class" />
							<h6 class="headerWithArrowEndings important ng-scope">
								<span class="content" v-if="getSelectedQuest.status == QuestsModel.STATUS_DONE">Задание завершено</span>
								<span class="content" v-else>{{getSelectedQuest.name}}</span>
							</h6>
						</div>
						<div>
							<span>{{getSelectedQuest.description}}</span>
						</div>
						<div class="horizontalLine" v-if="getSelectedQuest.rewards.length > 0 || getSelectedQuest.isAdventure"></div>
						<div class="rewardWrapper" v-if="getSelectedQuest.rewards.length > 0">
							<h2><span>Награда</span></h2>
							<div class="rewards" v-for="reward in getSelectedQuest.rewards">
								<reward :type="reward.type" :value="reward.value" size="small" :check-storage="getSelectedQuest.status == QuestsModel.STATUS_DONE"></reward>
							</div>
							<div class="exchangeDailyQuest" v-if="getSelectedQuest.status != QuestsModel.STATUS_DONE && gameModel.questBook.showExchangeQuestButton" :content="gameModel.questBook.dailyQuestsExchanged < gameModel.questBook.maxDailyQuestsExchanged ? 'Поменяй это задание, чтобы сразу получить новое' : 'Превышен лимит пропуска ежедневных заданий. '+tooltips[1]" v-tippy="{placement: 'top'}">
								<a class="iconButton clickable" @click="onExchange()" :class="{'disabled': gameModel.questBook.dailyQuestsExchanged >= gameModel.questBook.maxDailyQuestsExchanged}">
									<i class="changeDailyQuest_flat_black"></i>
								</a>
							</div>
						</div>
						<div class="buttonWrapper" v-if="getSelectedQuest.status == QuestsModel.STATUS_DONE">
							<div class="horizontalLine"></div>
							<button @click="onCollectReward()" class="larger clickable" ng-class="{disabled: lockQuestView}" ng-if="!error">
								<div class="content">
									<span class="innerText">
										<span>Забрать награду</span>
									</span>
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import paginate from 'jw-paginate';
import QuestsModel from '@/models/game/QuestsModel';

import Reward from '@/components/elements/Reward';

export default {
	mixins: lib.mixins,
	data: () => ({
		QuestsModel,
		
		selectedQuest: null,
		
		tooltips: {
			1: `<span translate="" options="Quest.DailyQuest.ExchangeButton.Disabled"><span>Ты уже поменял задание. Следующий обмен возможен через </span><span countdown=" 1723492674" callback="">10:09:35</span></span>`,
		},
	}),
	computed: {
		quests(){
			if(this.gameModel.questBook.dailyQuests.length > 3){
				const pager = paginate(this.gameModel.questBook.dailyQuests.length, 1, 3, 99999);
				return this.gameModel.questBook.dailyQuests.slice(pager.startIndex, pager.endIndex + 1);
			}
			return this.gameModel.questBook.dailyQuests;
		},
		emptyQuests(){
			return lib.genArrayByCount(3 - this.quests.length);
		},
		getSelectedQuest(){
			return this.gameModel.questBook.dailyQuests.find((el) => el.id == this.selectedQuest);
		},
	},
	methods: {
		getPercent: lib.getPercent,
		
		onSelectQuest(id){
			this.selectedQuest = id;
			let quest = this.getSelectedQuest;
			if(quest.status == QuestsModel.STATUS_ACTIVATABLE) quest.status = QuestsModel.STATUS_PROCESSING;
		},
		onCollectReward(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_1.ogg');
				audio.play();
			}
			
			let index = this.gameModel.questBook.dailyQuests.findIndex((el) => el.id == this.selectedQuest);
			this.gameModel.questBook.dailyQuests.splice(index, 1);
			if(this.gameModel.questBook.dailyQuests[index]){
				this.selectedQuest = this.gameModel.questBook.dailyQuests[index].id;
			} else {
				if(this.gameModel.questBook.dailyQuests.length > 0){
					this.selectedQuest = this.gameModel.questBook.dailyQuests[this.gameModel.questBook.dailyQuests.length - 1].id;
				} else {
					this.selectedQuest = null;
				}
			}
			let quest = this.getSelectedQuest;
			if(quest){
				if(quest.status == QuestsModel.STATUS_ACTIVATABLE) quest.status = QuestsModel.STATUS_PROCESSING;
			}
		},
		onExchange(){
			if(this.gameModel.questBook.dailyQuestsExchanged < this.gameModel.questBook.maxDailyQuestsExchanged){
				let index = this.gameModel.questBook.dailyQuests.findIndex((el) => el.id == this.selectedQuest);
				this.gameModel.questBook.dailyQuests.splice(index, 1);
				this.gameModel.questBook.dailyQuestsExchanged++;
				if(this.gameModel.questBook.dailyQuests[index]){
					this.selectedQuest = this.gameModel.questBook.dailyQuests[index].id;
				} else {
					if(this.gameModel.questBook.dailyQuests.length > 0){
						this.selectedQuest = this.gameModel.questBook.dailyQuests[this.gameModel.questBook.dailyQuests.length - 1].id;
					} else {
						this.selectedQuest = null;
					}
				}
				let quest = this.getSelectedQuest;
				if(quest){
					if(quest.status == QuestsModel.STATUS_ACTIVATABLE) quest.status = QuestsModel.STATUS_PROCESSING;
				}
			}
		},
	},
	mounted(){
		if(this.quests.length > 0){
			this.onSelectQuest(this.quests[0].id);
		}
	},
	components: {
		Reward,
	},
}
</script>
import axios from 'axios';
import lib from '@/lib';

export default class SocietyModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.kingdomsBefore = {
			kingdomInvitationShow: false,
			kingdomInvitation: {
				expanded: false,
			},
		};
		this.kingdomsAfter = {
			kingdomLeaveShow: false,
		}
		this.secretSocietyInviteShow = false;
	}
}

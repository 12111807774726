<template>
    <div ng-if="overlayController" class="windowOverlay" id="upgradeBandages" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="577"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Hero.ItemUpgradeBandagesHeadline" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Улучшить повязки</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="useItem upgradeBandages">
	<div class="contentBox itemTopBox">
		<h6 class="contentBoxHeader headerWithArrowDown">
            <!-- ngIf: isSmallBandage --><div class="content" translate="" ng-if="isSmallBandage"><span>Улучшить малые повязки</span></div><!-- end ngIf: isSmallBandage -->
            <!-- ngIf: !isSmallBandage -->
		</h6>
		<div class="contentBoxBody">
			<div class="itemContainer">
				<hero-item-container item="healingPotions" hide-amount="true" hide-item-states="true"><div class="entityBox item item_124 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:57170,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:false,itemType:124" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_healing_potion_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
				<div class="verticalLine"></div>
                <!-- ngIf: isSmallBandage --><div class="description bandageUpgradeText" translate="" ng-if="isSmallBandage"><span>Выбери, сколько малых повязок ты хочешь улучшить. Каждое лечебное зелье можно использовать, чтобы улучшить одну повязку и на &#x202D;&#x202D;5&#x202C;%&#x202C; повысить восстановление войск.</span></div><!-- end ngIf: isSmallBandage -->
                <!-- ngIf: !isSmallBandage -->
			</div>

			<div class="horizontalLine"></div>

			<div class="calculationContainer">
				<div class="itemWrapper">
					<hero-item-container item="bandages" hide-item-states="true" hide-amount="true"><div class="entityBox item item_117 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:57590,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:false,itemType:117" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_small_bandage_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
					<span class="itemAmount" ng-bind-html="0 | bidiRatio : useAmount.value : bandages.data.amount">&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;6&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;273&#x202C;&#x202C;&#x202C;&#x202C;</span>
				</div>

				<div class="plusCircled"></div>

				<div class="itemWrapper">
					<hero-item-container item="healingPotions" hide-amount="true" hide-item-states="true"><div class="entityBox item item_124 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:57170,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:false,itemType:124" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_healing_potion_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
					<span class="itemAmount" ng-bind-html="0 | bidiRatio : useAmount.value : healingPotions.data.amount">&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;6&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;11&#x202C;&#x202C;&#x202C;&#x202C;</span>
				</div>

				<div class="equalCircled"></div>

				<div class="itemWrapper">
					<hero-item-container class="resultItem result" item="bandages" hide-amount="true" hide-item-states="true" display-next-upgrade="true"><div class="entityBox item item_117 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:57590,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:true,itemType:117" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_small_bandage_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
					<span class="itemAmount">6</span>
				</div>
			</div>
		</div>

		<div class="contentBoxFooter">
			<div class="sliderArrow"></div>
			<slider slider-min="1" slider-max="maxUseableAmount" slider-data="useAmount"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
	<div class="trackContainer">
		<div class="track">
			<!-- ngIf: stepCount && !hideSteps -->
			<div class="sliderMarker progressMarker" style="width: 54.5455%;"></div>
			<!-- ngRepeat: marker in sliderMarkers track by $index -->
			<div class="hoverIndicator">
				<div class="hoverValue"></div>
				<div class="indicator"></div>
			</div>
			<div class="handle unselectable" style="left: 210px;"></div>
		</div>
	</div>
	<div class="inputContainer">
		<!-- ngIf: iconClass -->
		<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
		<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: block;">
			<i class="action_setMax_small_flat_black"></i>
		</div>
	</div>
</div></slider>
		</div>
	</div>

	<div class="buttonBar">
		<button clickable="upgradeBandage()" class="useItemButton clickable" content="Улучшить выбранное количество малых повязок" v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{
		            'Hero.ItemUpgradeBandagesButtonTooltip': false,
		            'Hero.ItemUpgradeSmallBandagesButtonTooltip': true
                    }"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate="" data="ItemName:"><span>Объединить</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		<button clickable="cancel()" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Отмена</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
	</div>
</div>
</div>
	</div>
</div></div>
</div>

</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
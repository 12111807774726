<template>
	<div ng-controller="statsPlayerCtrl">
		<div tabulation="" tab-config-name="statsPlayerTabConfig">
			<nav class="tabulation subtab subtab unselectable" ng-if="tabData.tabs.length > 1">
				<a class="tab clickable" :class="{active: tab == 'overview', inactive: tab != 'overview'}" @click="onToggleTab('overview')">
					<div class="content">
						<span>Обзор</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'attacker', inactive: tab != 'attacker'}" @click="onToggleTab('attacker')">
					<div class="content">
						<span>Нападение</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'defender', inactive: tab != 'defender'}" @click="onToggleTab('defender')">
					<div class="content">
						<span>Оборона</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'villages', inactive: tab != 'villages'}" @click="onToggleTab('villages')">
					<div class="content">
						<span>Деревни</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'heroes', inactive: tab != 'heroes'}" @click="onToggleTab('heroes')">
					<div class="content">
						<span>Герои</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'top10', inactive: tab != 'top10'}" @click="onToggleTab('top10')">
					<div class="content">
						<span>Топ-10</span>
					</div>
				</a>
			</nav>
			<div class="tabulationContent scrollable tabContentTop10">
				<div class="scrollContentOuterWrapper active" style="width: 610px;">
					<div class="scrollContent" style="width: 630px; overflow-y: auto; max-height: 494px;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: 610px;">
							<div ng-transclude="">
								<div class="loadedTab currentTab" v-if="tab == 'overview'">
									<overview />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'attacker'">
									<attacker />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'defender'">
									<defender />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'villages'">
									<villages />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'heroes'">
									<heroes />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'top10'">
									<top10 />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import Overview from '@/components/pages/statistics/player/Overview';
import Attacker from '@/components/pages/statistics/player/Attacker';
import Defender from '@/components/pages/statistics/player/Defender';
import Villages from '@/components/pages/statistics/player/Villages';
import Heroes from '@/components/pages/statistics/player/Heroes';
import Top10 from '@/components/pages/statistics/player/Top10';

export default {
	mixins: lib.mixins,
	data: () => ({
		tab: 'overview',
	}),
	computed: {},
	methods: {
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_10.ogg');
				audio.play();
			}
		},
	},
	components: {
		Overview,
		Attacker,
		Defender,
		Villages,
		Heroes,
		Top10,
	},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Мастерская</span></span>
									<span class="level">
										(<span><span>Уровень 0</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>Осадные орудия, такие как тараны и катапульты, производятся в мастерской. Чем выше уровень мастерской, тем быстрее изготавливаются орудия.</span>
								</div>
								<img class="buildingHuge buildingType21 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_21">
								<div class="buildingStatus location38 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="buildingIndexCtrl" class="building buildingType21" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
														<div ng-include="" src="pageTemplate">
															<div class="buildingDetails trainTroops" ng-controller="trainTroopsCtrl">
																<div ng-show="isBuildingBuild()">
																	<div ng-controller="unitsTrainCtrl">
																		<div ng-include="" src="'tpl/building/partials/lists/units.html'">
																			<div class="contentBox gradient unitList">
																				<div class="contentBoxBody">
																					<carousel-unit-train :items="units" @on-active-item="(item) => activeItem = item" :cols="4" />
																					<div v-if="activeItem">
																						<table class="unitInfo transparent">
																							<tbody>
																								<tr>
																									<td>
																										<div class="unitHeader">
																											<i class="unitIcon unitMedium gaul" :class="['unitType'+activeItem.type]" unit-icon="" big="true"></i>
																											<h4 class="unitName">
																												<span><span>{{activeItem.name}}</span></span>
																												<span class="level" v-if="activeItem.unitLevel >= 0">
																													<span><span>&nbsp;Ур.</span></span> {{activeItem.unitLevel}}
																												</span>
																											</h4>
																											<div class="symbol_i_medium_wrapper">
																												<i class="symbol_i_medium_flat_white clickable" clickable="openWindow('help', {'pageId': 'Troops_' + activeItem.unitType})" content="Дополнительная информация" v-tippy="{placement: 'top'}" tooltip-translate="Units.Tooltip.UnitInfo" tooltip-placement="above"></i>
																											</div>
																										</div>
																										<div class="description"><span>{{activeItem.description}}</span></div>
																									</td>
																									<td>
																										<table class="unitAttributes transparent">
																											<tbody>
																												<tr>
																													<td>
																														<i class="unit_capacity_small_flat_black" content="Могут переносить" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CarryingCapacity.Label"></i>
																													</td>
																													<td>{{activeItem.capacity}}</td>
																												</tr>
																												<tr>
																													<td><i class="unit_speed_small_flat_black" content="Скорость" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.Speed.Label"></i></td>
																													<td>{{activeItem.speed}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_consumption_small_flat_black" content="Потребление" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CropConsumption.Label"></i>
																													</td>
																													<td>{{activeItem.cropConsumption}}</td>
																												</tr>
																											</tbody>
																										</table>
																									</td>
																									<td>
																										<table class="unitAttributes transparent" ng-if="building.data.buildingType != buildingTypes.BLACKSMITH">
																											<tbody>
																												<tr>
																													<td><i class="movement_attack_small_flat_black" content="Атака" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Attack"></i>
																													</td>
																													<td>{{activeItem.attack}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_defenseInfantry_small_flat_black" content="Защита от пехоты" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseInfantry"></i>
																													</td>
																													<td>{{activeItem.defenseInfantry}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_defenseCavalry_small_flat_black" content="Защита от кавалерии" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseCavalry"></i>
																													</td>
																													<td>{{activeItem.defenseCavalry}}</td>
																												</tr>
																											</tbody>
																										</table>
																									</td>
																								</tr>
																							</tbody>
																						</table>
																						<div class="horizontalLine"></div>
																						<display-resources v-if="activeItem" class="costsFooter" :resources="allCosts" :hide-zero="true" available="storage" :consumption="allConsumption" :time="activeItem.duration * activeItem.value" />
																						<div class="sliderRow">
																							<div class="sliderArrow"></div>
																							<range-slider v-if="activeItem.maxAvailable <= 0" :min="1" :max="1" v-model.number="activeItem.value" disabled style="width: 87%;" />
																							<range-slider v-if="activeItem.maxAvailable > 0" :min="1" :max="activeItem.maxAvailable" v-model.number="activeItem.value" style="width: 87%;" />
																							<npc-trader-button type="unitTrain" :costs="allCosts" :current-max="0" :current-limit="0" />
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		
																		<!-- unit not researched -->
																		<button :class="{'disabled': !activeItem || activeItem.disabled || activeItem.maxAvailable == 0}" class="animate footerButton clickable" clickable="startTraining(activeItem)" content="Недостаточно ресурсов" v-tippy="{placement: 'top'}" play-on-click="12">
																			<div class="content">
																				<span class="innerText">
																					<span><span>Обучить</span></span>
																				</span>
																				<!-- ngIf: price > 0 || forceGoldUsage -->
																				<!-- ngIf: price == -1 && !forceGoldUsage -->
																				<div class="animationOverlay"></div>
																				<i class="checkmark action_check_small_flat_white"></i>
																				<i class="checkmark action_check_small_flat_black"></i>
																				<i class="checkmark action_check_small_flat_red"></i>
																			</div>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import CarouselUnitTrain from '@/components/elements/CarouselUnitTrain';
import DisplayResources from '@/components/elements/DisplayResources';
import NpcTraderButton from '@/components/elements/NpcTraderButton';
import RangeSlider from '@/components/elements/RangeSlider';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Мастерская | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
		
		units: [
			{
				type: 7,
				name: 'Таран',
				description: 'Таран — это тяжелое осадное орудие в помощь пехоте и кавалерии. Их задача — пробить брешь в стенах города.',
				disabled: false,
				queue: {
					key: 0,
					count: 0,
					pause: 0,
				},
				costs: [
					750,
					370,
					220,
					0,
				],
				value: 1,
				unitLevel: 0,
				capacity: 0,
				speed: 4,
				cropConsumption: 3,
				attack: 50,
				defenseInfantry: 30,
				defenseCavalry: 105,
				duration: 1938,
				time: 1723643172,
				maxAvailable: 10,
			},
			{
				type: 8,
				name: 'Требушет',
				description: 'Требушет — замечательное орудие дальнего боя, которое уничтожает постройки и ресурсные поля. Сама по себе она, впрочем, довольно беззащитна.',
				disabled: true,
				queue: {
					key: 0,
					count: 0,
					pause: 0,
				},
				costs: [
					590,
					1200,
					400,
					0,
				],
				value: 1,
				unitLevel: 1,
				capacity: 0,
				speed: 3,
				cropConsumption: 6,
				attack: 72.3,
				defenseInfantry: 47.1,
				defenseCavalry: 11.9,
				duration: 3487,
				time: 1723643172,
				maxAvailable: 10,
			},
		],
		
		activeItem: null,
		
		tooltips: {
			1: `<div class="upgradeCostsTooltip" ng-controller="buildingUpgradeTooltipCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoTop"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' &amp;&amp; currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
	<div class="headline">
		<h3 translate="" class="building" options="34"><span>Каменотес</span></h3>
	</div>

	<div class="queuedSlidesContainer">
		<!-- ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide1 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide2 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide3 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide4 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide5 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 -->
	</div>

	<div class="content" ng-class="{boxMaxLevel: building.data.lvl >= building.data.lvlMax, boxDeconstruction: upgradeButtonClass == 'demolish'}">
		<div class="contentTopArea">
			<!-- ngIf: building.data.currentEffect.values[0] --><div class="effectDescription" ng-if="building.data.currentEffect.values[0]">
				<span translate="" options="34"><span>Повышает прочность</span></span>
				<!-- ngIf: building.data.currentEffect.values[0] --><div class="horizontalLine3d" ng-if="building.data.currentEffect.values[0]"></div><!-- end ngIf: building.data.currentEffect.values[0] -->
			</div><!-- end ngIf: building.data.currentEffect.values[0] -->
			<!-- ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' --><table class="buildingNextEffects" ng-if="building.data.lvl < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'">
				<tbody>
					<tr>
						<td>
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', enoughRes: enoughRes &amp;&amp; freeMasterSlots > 0}">
										<div class="labelLayer">
											<span class="buildingLevel">10</span>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td class="borderLeft">
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>&lrm;&#x202D;16
							</span>
						</td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect &amp;&amp; building.data.effect.length > 0 &amp;&amp; !isArray(building.data.effect[0])">
								<span translate="" options="34" data="value:200"><span>&#x202D;&#x202D;200&#x202C;%&#x202C;</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl - (currentBuildingLevel < building.data.lvlMax ? 0 : 1)] track by $index -->
						</td>
						<td class="emptySpace"></td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect &amp;&amp; building.data.effect.length > 0 &amp;&amp; !isArray(building.data.effect[0])">
								<span translate="" options="34" data="value:210"><span>&#x202D;&#x202D;210&#x202C;%&#x202C;</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl + (currentBuildingLevel < building.data.lvlMax ? 1 : 0)] track by $index -->
						</td>
						<td>
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>18
							</span>
						</td>
						<td class="borderLeft">
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
										<div class="labelLayer">
											<span class="buildingLevel">11</span>
											<!-- ngIf: currentBuildingLevel + 1 >= building.data.lvlMax -->
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
			<!-- ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
		</div>

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><span ng-if="currentBuildingLevel < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'" class="resource timeValue" tooltip="" tooltip-translate="Duration">
				<i class="symbol_clock_small_flat_black duration"></i>02:40:00
			</span><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div ng-if="currentBuildingLevel < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'" class="dropOutBox"></div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div class="resources" ng-if="currentBuildingLevel < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'">
			<display-resources available="storage" resources="building.data.nextUpgradeCosts[currentBuildingLevel]" image-size="medium"><div class="costs max4digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_medium_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;1830&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_medium_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;1535&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_medium_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;1475&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_medium_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;825&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 --><div class="enoughResTime" ng-if="enoughResSecs > 0" translate="" data="time: 1723561868"><span>Достаточно ресурсов: </span><span i18ndt="1723561868" format="short">Сегодня 20:11</span></div><!-- end ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_medium_flat_black"></i></div>
</div></display-resources>
		</div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
	</div>
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoBottom"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' &amp;&amp; currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
</div>`,
			11: `<div class="upgradeCostsTooltip" ng-controller="buildingUpgradeTooltipCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoTop"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' &amp;&amp; currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
	<div class="headline">
		<h3 translate="" class="building" options="11"><span>Амбар</span></h3>
	</div>

	<div class="queuedSlidesContainer">
		<!-- ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide1 active" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide2 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide3 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide4 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide5 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 -->
	</div>

	<div class="content" ng-class="{boxMaxLevel: building.data.lvl >= building.data.lvlMax, boxDeconstruction: upgradeButtonClass == 'demolish'}">
		<div class="contentTopArea">
			<!-- ngIf: building.data.currentEffect.values[0] --><div class="effectDescription" ng-if="building.data.currentEffect.values[0]">
				<span translate="" options="11"><span>Повышает емкость хранилища</span></span>
				<!-- ngIf: building.data.currentEffect.values[0] --><div class="horizontalLine3d" ng-if="building.data.currentEffect.values[0]"></div><!-- end ngIf: building.data.currentEffect.values[0] -->
			</div><!-- end ngIf: building.data.currentEffect.values[0] -->
			<!-- ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' --><table class="buildingNextEffects" ng-if="building.data.lvl < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'">
				<tbody>
					<tr>
						<td>
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', enoughRes: enoughRes &amp;&amp; freeMasterSlots > 0}">
										<div class="labelLayer">
											<span class="buildingLevel">13</span>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td class="borderLeft">
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>&lrm;&#x202D;16
							</span>
						</td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect &amp;&amp; building.data.effect.length > 0 &amp;&amp; !isArray(building.data.effect[0])">
								<span translate="" options="11" data="value:22000"><span>22k</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl - (currentBuildingLevel < building.data.lvlMax ? 0 : 1)] track by $index -->
						</td>
						<td class="emptySpace"></td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect &amp;&amp; building.data.effect.length > 0 &amp;&amp; !isArray(building.data.effect[0])">
								<span translate="" options="11" data="value:26000"><span>26k</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl + (currentBuildingLevel < building.data.lvlMax ? 1 : 0)] track by $index -->
						</td>
						<td>
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>18
							</span>
						</td>
						<td class="borderLeft">
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
										<div class="labelLayer">
											<span class="buildingLevel">14</span>
											<!-- ngIf: currentBuildingLevel + 1 >= building.data.lvlMax -->
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
			<!-- ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
		</div>

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><span ng-if="currentBuildingLevel < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'" class="resource timeValue" tooltip="" tooltip-translate="Duration">
				<i class="symbol_clock_small_flat_black duration"></i>05:50:00
			</span><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div ng-if="currentBuildingLevel < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'" class="dropOutBox"></div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div class="resources" ng-if="currentBuildingLevel < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'">
			<display-resources available="storage" resources="building.data.nextUpgradeCosts[currentBuildingLevel]" image-size="medium"><div class="costs max4digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_medium_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3260&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_medium_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;4075&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_medium_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;2850&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_medium_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;815&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 --><div class="enoughResTime" ng-if="enoughResSecs > 0" translate="" data="time: 1723621547"><span>Достаточно ресурсов: </span><span i18ndt="1723621547" format="short">Завтра 12:45</span></div><!-- end ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_medium_flat_black"></i></div>
</div></display-resources>
		</div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
	</div>
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoBottom"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' &amp;&amp; currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
</div>`,
			2: `<div class="finishNowTooltip" ng-controller="finishNowCtrl">
	<!-- ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) --><div ng-if="!isWorldWonder &amp;&amp; (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0)">
		<h3>
			<span translate=""><span>Моментально завершить следующие поручения</span></span><!-- ngIf: price == 0 -->
			<!-- ngIf: price > 0 || forceGoldUsage --><span class="price" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>2:</span><!-- end ngIf: price > 0 || forceGoldUsage -->
			<!-- ngIf: price == -1 && !forceGoldUsage -->
		</h3>
		<div class="horizontalLine"></div>

		<!-- ngRepeat: build in inBuilding track by $index --><div ng-repeat="build in inBuilding track by $index">
			<!-- ngIf: !finishNowQueueType -->
			<span translate="" options="22"><span>Академия</span></span>
			<span class="level" translate="" data="lvl:20"><span>Уровень 20</span></span>
		</div><!-- end ngRepeat: build in inBuilding track by $index -->
		<!-- ngRepeat: destruction in inDestruction track by $index -->
		<!-- ngRepeat: research in inResearch track by $index -->
		<!-- ngRepeat: training in inTraining track by $index -->
	</div><!-- end ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) -->
	<!-- ngIf: isWorldWonder -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && !config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && !hasInvalidBuilding && inBuilding.length == 0 && inTraining.length == 0 && inDestruction.length == 0 && inResearch.length == 0 -->
	<!-- ngIf: !isWorldWonder && premiumOptionMenu.options.instantCompletion && !freeSlots && enoughRes === false -->
</div>`,
			3: `<span translate="" options="Building.npcTrader.Tooltip.Possible"><strong>Обменять ресурсы автоматически&nbsp;<i class="icon unit_gold_small_illu"></i> 5</strong></span>`,
			4: `<span>Распределить ресурсы вручную</span>`,
		},
	}),
	computed: {
		allCosts(){
			let unitsCosts = [];
			if(this.activeItem){
				for(let [index, value] of Object.entries(this.activeItem.costs)){
					unitsCosts[index] = value * this.activeItem.value;
				}
			}
			return unitsCosts;
		},
		allConsumption(){
			if(this.activeItem){
				return this.activeItem.cropConsumption * this.activeItem.value;
			}
			return 0;
		},
	},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {
		CarouselUnitTrain,
		DisplayResources,
		NpcTraderButton,
		RangeSlider,
	},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Площадь</span></span>
									<span class="level">
										(<span><span>Уровень {{gameModel.island.buildings.area.level}}</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>Войска острова собираются на площади. Отсюда ты можешь отправить их в подкрепление, в набег или на захват других островов.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<div class="buildingStatus location27 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<nav class="tabulation  maintab unselectable">
									<a class="tab clickable" :class="{active: tab == 'overview', inactive: tab != 'overview'}" @click="onToggleTab('overview')">
										<div class="content">
											<span>Обзор</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'combat-simulator', inactive: tab != 'combat-simulator'}" @click="onToggleTab('combat-simulator')">
										<div class="content">
											<span>Симулятор сражения</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'farm-list', inactive: tab != 'farm-list'}" @click="onToggleTab('farm-list')">
										<div class="content">
											<span>Избранные цели</span>
										</div>
									</a>
								</nav>
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div class="loadedTab currentTab" v-if="tab == 'overview'">
													<overview :set-tab="subtab" />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'combat-simulator'">
													<combat-simulator />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'farm-list'">
													<farm-list />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay">
							
							<!-- редактирование войск во всех деревнях в выбранных списках целей -->
							<div ng-if="overlayController" class="windowOverlay" id="farmListEditTroops" ng-controller="overlayController" v-if="gameModel.buildings.area.farmListEditTroops">
	<div class="darkener clickable" clickable="closeOverlay()" @click="gameModel.buildings.area.farmListEditTroops = false"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="706"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="FarmList.EditTroops.Headline" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Редактировать войска</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="editUnits">
	<h6 class="headerTrapezoidal">
		<div class="content">
			
			<i class="village_village_small_flat_black"></i>
			<!-- ngIf: list -->
			<!-- ngIf: !list --><span ng-if="!list">3</span><!-- end ngIf: !list -->
		</div>
	</h6>
	<span translate=""><span>Здесь ты можешь установить количество войск, которые следует отправить в каждую из выбранных деревень.</span></span>
	<div troops-details="" troop-data="troopDetails" unit-input-callback="checkInput"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[1]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[2]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[3]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[4]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[5]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="inputTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput0" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput1" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput2" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput3" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput4" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput5" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput6" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput7" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput8" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput9" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput10" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
	<!-- ngIf: editTroopsError -->
	<div class="buttonFooter">
		<button ng-class="{disabled: noUnitSelected || !unitInputChanged}" clickable="saveTroops()" class="clickable disabled"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Сохранить</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		<button clickable="closeOverlay();" @click="gameModel.buildings.area.farmListEditTroops = false" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Отмена</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
	</div>
</div></div>
	</div>
</div></div>
</div>


<!-- редактирование войск в выбранных деревнях в открытом списке целей -->
<div ng-if="overlayController" class="windowOverlay" id="farmListEditTroops" ng-controller="overlayController" v-if="gameModel.buildings.area.farmListItemEditTroops">
	<div class="darkener clickable" clickable="closeOverlay()" @click="gameModel.buildings.area.farmListItemEditTroops = false"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="706"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="FarmList.EditTroops.Headline" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Редактировать войска</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="editUnits">
	<h6 class="headerTrapezoidal">
		<div class="content">
			Стартовый лист
			<i class="village_village_small_flat_black"></i>
			<!-- ngIf: list --><span ng-if="list">&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;2&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;10&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: list -->
			<!-- ngIf: !list -->
		</div>
	</h6>
	<span translate=""><span>Здесь ты можешь установить количество войск, которые следует отправить в каждую из выбранных деревень.</span></span>
	<div troops-details="" troop-data="troopDetails" unit-input-callback="checkInput"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[1]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[2]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[3]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[4]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[5]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="inputTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput0" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput1" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput2" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput3" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput4" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput5" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput6" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput7" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput8" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput9" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' --><input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput10" tooltip="" tooltip-translate="CombatSimulator.troopAmount" type="tel"><!-- end ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
	<!-- ngIf: editTroopsError -->
	<div class="buttonFooter">
		<button ng-class="{disabled: noUnitSelected || !unitInputChanged}" clickable="saveTroops()" class="clickable disabled"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Сохранить</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		<button clickable="closeOverlay();" @click="gameModel.buildings.area.farmListItemEditTroops = false" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Отмена</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
	</div>
</div></div>
	</div>
</div></div>
</div>



<!-- добавление новой деревни в открытый список целей -->
<div ng-if="overlayController" class="windowOverlay" id="farmListAddVillage" ng-controller="overlayController" v-if="gameModel.buildings.area.farmListAddVillage">
	<div class="darkener clickable" clickable="closeOverlay()" @click="gameModel.buildings.area.farmListAddVillage = false"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="706"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="FarmList.ListAddVillage.headline" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Добавить деревню в список целей</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="farmListAddVillage">
	<div class="searchVillage" check-target-fct="checkTarget" api="villageSearch">
	<h6 translate=""><span>Цель</span></h6>

	<div class="error success" ng-class="{success: api.result.villageReturned}">
		<span class="villageName serverautocompleteContainer ng-valid ng-dirty ng-valid-parse" disable-search="" autocompletedata="playerVillages,village,coords,emptyCoords" autocompletecb="selectVillage" ng-model="api.result.villageSearched" input-autofocus="true" show-own-villages="false">

	<!-- ngIf: showOwnVillages -->
	<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span><input ng-disabled="disabledInput" class="targetInput ng-pristine ng-valid ui-autocomplete-input ng-touched" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск...">
	<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 136px;">
		<i class="action_search_tiny_flat_white"></i>
		Игрок, Деревня, Координаты
	</label>
	<!-- ngIf: showOwnVillages -->
</span>
	</div>
	<div ng-show="api.target.villageId > 0" class="">
		<div class="horizontalLine"></div>
		<table class="transparent">
			<tbody><tr>
				<th translate=""><span>Деревня</span></th>
				<td>
					<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 535183346}, null, true)" village-link="" villageid="535183346" villagename="04.">04.</a>
				</td>
			</tr>
			<!-- ngIf: api.target.destPlayerId !== null --><tr ng-if="api.target.destPlayerId !== null">
				<th translate=""><span>Владелец</span></th>
				<td data-village-type="" data-is-governor-npc="" class="playerVillage">
					<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="553">Труррль</a>
				</td>
			</tr><!-- end ngIf: api.target.destPlayerId !== null -->
			<!-- ngIf: api.targetPlayer.data.kingdomId > 0 --><tr ng-if="api.targetPlayer.data.kingdomId > 0">
				<th translate=""><span>Королевство</span></th>
				<td>
					<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 10}, null, true)" kingdomid="10">ОоО</a>
				</td>
			</tr><!-- end ngIf: api.targetPlayer.data.kingdomId > 0 -->
			<tr ng-show="showDuration &amp;&amp; showDuration != 'false'" class="ng-hide">
			<th translate=""><span>Время</span></th>
				<td>0</td>
			</tr>
			<tr ng-show="showDistance &amp;&amp; showDistance != 'false'" class="ng-hide">
			<th translate=""><span>Дист.</span></th>
				<td></td>
			</tr>
		</tbody></table>
	</div>
</div>
</div>
<div class="buttonFooter">
	<div ng-if="error" class="error" translate="" options="Error.TargetOwnVillage"><span>Ты не можешь добавить свою собственную деревню в список.</span></div>
	<button clickable="addVillage()" ng-class="{disabled: villageId == 0 || error}" class="clickable disabled"><div class="content">
	<span ng-transclude="" class="innerText">
		<span translate=""><span>Добавить</span></span>
	</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
	<button clickable="closeOverlay();" @click="gameModel.buildings.area.farmListAddVillage = false" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
		<span translate=""><span>Отмена</span></span>
	</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
</div>
</div>
	</div>
</div></div>
</div>

				

<!-- редактирование открытого списка целей -->
<div ng-if="overlayController" class="windowOverlay" id="farmListEdit" ng-controller="overlayController" v-if="gameModel.buildings.area.farmListEdit">
	<div class="darkener clickable" clickable="closeOverlay()" @click="gameModel.buildings.area.farmListEdit = false"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="706"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="FarmList.ListEdit.headline" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Редактировать список</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="farmListEdit">
	<label for="farmListEditInput" translate=""><span>Название списка</span></label>
	<input id="farmListEditInput" type="text" maxlength="15" ng-model="input.listName" placeholder="Новое название" auto-focus="" class="ng-pristine ng-valid ng-valid-maxlength ng-touched">
</div>
<div class="buttonFooter">
	<button clickable="editList()" ng-class="{disabled: listName == ''}" class="clickable"><div class="content">
	<span ng-transclude="" class="innerText">
		<span translate=""><span>Сохранить</span></span>
	</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
	<button clickable="closeOverlay();" @click="gameModel.buildings.area.farmListEdit = false" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
		<span translate=""><span>Отмена</span></span>
	</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
</div>
<div class="error"></div>
</div>
	</div>
</div></div>
</div>
							
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import Overview from '@/components/pages/buildings/area/Overview';
import CombatSimulator from '@/components/pages/buildings/area/CombatSimulator';
import FarmList from '@/components/pages/buildings/area/FarmList';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Площадь | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		tab: 'overview',
		subtab: '',
		
		villageBackgroundLeft: '0px',
		
		tooltips: {
			1: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="21"><span>Фаланга</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType1" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>15</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>40</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>50</span>
			<span><i class="unit_capacity_small_flat_black"></i>35</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			2: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Мечник</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType2" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			3: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="24"><span>Тевтатский гром</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType4" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>90</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>25</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>40</span>
			<span><i class="unit_capacity_small_flat_black"></i>75</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			4: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="25"><span>Друид-всадник</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType5" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>45</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>115</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>55</span>
			<span><i class="unit_capacity_small_flat_black"></i>35</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			5: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="26"><span>Эдуйская конница</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType6" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>140</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>60</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>165</span>
			<span><i class="unit_capacity_small_flat_black"></i>65</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
		},
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_8.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.tab) this.tab = this.$route.query.tab;
		else this.$router.push({query: Object.assign({}, this.$route.query, {
			tab: this.tab,
		})});
		
		if(this.$route.query.subtab) this.subtab = this.$route.query.subtab;
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(!to.query.tab) this.$router.push({query: Object.assign({}, this.$route.query, {
			tab: 'overview',
		})});
		else if(to.query.tab != this.tab) this.tab = to.query.tab;
		
		if(to.query.subtab != this.subtab) this.subtab = to.query.subtab;
		
		next();
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {
		Overview,
		CombatSimulator,
		FarmList,
	},
}
</script>
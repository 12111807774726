<template>
	<div class="oasesOverview">
		<table class="villagesTable lined">
			<thead>
				<tr>
					<th><span>Деревня</span></th>
					<th colspan="2"><span>Войска</span></th>
					<th class="coordinates">
						<i class="symbol_target_small_flat_black"></i>
					</th>
					<th><span>Ранг</span></th>
					<th><span>Ресурсы</span></th>
				</tr>
			</thead>
			<tbody></tbody>
		</table>
		<div v-if="!anyOasisInUse">
			<br><span translate=""><span>Ты сейчас не используешь оазис. Если ты поставишь туда войска, ты можешь использовать его через свое посольство. Однако, ты получишь бонус от оазиса, только если ты войдешь в топ-5 в оазисе по количеству войск.</span></span>
		</div>
		<tbody v-else>
			<tr v-for="oases in oasesList" v-show="oases.usedByVillage != 0 && oases.rank > 0">
				<td>
					<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537182205}, null, true)" villageid="537182205">{{oases.villageName}}</a>
				</td>
				<td class="iconCol">
					<i class="symbol_generalTroops_small_flat_black"></i>
				</td>
				<td>0</td>
				<td class="coordinates">
					<span class="coordinateWrapper" coordinates="" x="id" y="537247738">
						<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537247738_1708245594.6');" class="clickable">
							<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">
								<span class="coordinateX">({{oases.x}}</span>
								<span class="coordinatePipe">|</span>
								<span class="coordinateY">{{oases.y}})</span>
							</span>
						</a>
					</span>
				</td>
				<td>6</td>
				<td>
					<span v-for="bonus in oases.bonuses" v-if="bonus.value > 0" class="oasisBonus"><i :class="['unit_'+bonus.resName+'_small_illu']"></i>{{bonus.value}}%</span>
				</td>
			</tr>
		</tbody>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		anyOasisInUse: true,
		oasesList: [
			{
				usedByVillage: 1,
				villageName: 'Брест',
				rank: 4,
				x: '-13',
				y: '-15',
				bonuses: [
					{
						resId: 0,
						resName: 'wood',
						value: 10,
					},
					{
						resId: 3,
						resName: 'crop',
						value: 10,
					},
				],
			},
		],
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
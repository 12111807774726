<template>
	<metainfo>
		<template v-slot:title="{content}">{{content}}</template>
	</metainfo>
	<router-view/>
	<modals-container></modals-container>
</template>

<style lang="css">

</style>

<script>
import { useMeta } from 'vue-meta';
import { ModalsContainer } from 'vue-final-modal';

export default {
	name: 'App',
	setup() {
		useMeta({
			title: '',
			htmlAttrs: {
				lang: 'en',
				amp: true,
			},
		});
	},
	mounted(){
		window["appComponent"] = this;
	},
	components: {
		ModalsContainer,
	},
};
</script>

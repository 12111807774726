<template>
	<div class="resourceBarWrapper">
		<div id="resourceBar">
			<!--
			<div class="background worldEnded" ng-class="{worldEnded: serverStatus == 'worldEnded'}"></div>
			<div ng-if="serverStatus == 'worldEnded'" clickable="openWindow('worldEnded')" class="stockContainer worldEnd clickable" ng-controller="worldEndedSummaryCtrl">
				<h2 translate=""><span>Игровой мир завершен</span></h2>
				(<span i18ndt="1603464957" format="short">Вчера 20:55</span>)
				<div class="winnerWrapper headerTrapezoidal">
					<div class="content">
						<div class="wrapper">
							<div class="wrapper2">
								<i class="endOfWorld_winnerKingdom_small_flat_green"></i>
								<span translate="" data="kingdomId:73"><span>Победители: </span><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 73}, null, true)" kingdomid="73" nolink="" showmissinglink="true">АРМА</a></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			-->
			
			<!--
			<div class="background worldEnded" ng-class="{worldEnded: gameworld.data.status == STATUS_ENDED}"></div>
			<div ng-if="gameworld.data.status == STATUS_ENDED" ng-class="{disabled:gameworld.data.data.winnerKingdomId==0}" clickable="openWindow('worldEnded')" class="stockContainer worldEnd clickable">
				<h2 translate=""><span>Игровой мир завершен</span></h2>
				(<span i18ndt="1717196870" format="short">Вчера 04:07</span>)

				<div ng-if="gameworld.data.data.winnerKingdomId > 0" class="winnerWrapper headerTrapezoidal">
					<div class="content">
						<div class="wrapper">
							<div class="wrapper2">
								<i class="endOfWorld_winnerKingdom_small_flat_green"></i>
								<span translate="" data="kingdomId:2"><span>Победители: </span><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2" nolink="" showmissinglink="true">♔Выдры♔</a></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			-->
			
			<div class="background"></div>
			<div>
				<div class="clickable" @click="$router.push({path: '/production-overview', query: {tab: 'wood'}})">
					<div class="stockContainer wood">
						<div content="<h3><span>Древесина</span></h3><div class='horizontalLine'></div><table class='transparent'><tbody><tr ng-if='village.data.production[n] > 0'><th><span>Заполнится через</span></th><td>54:25:29</td></tr></tbody></table>" v-tippy="{placement: 'bottom'}">
							<div class="progressbar">
								<div class="values">
									<span class="amount wrapper">{{gameModel.island.storage.woodCount}}</span>/<span class="capacity">{{gameModel.island.storage.woodCountMax}}</span>
								</div>
								<i class="labelIcon unit_wood_medium_illu resType1"></i>
								<i v-if="gameModel.StockBar.woodCountPlus || gameModel.StockBar.woodCountMinus" :class="['labelIcon', 'unit_wood_medium_illu', 'resType1', 'animated', {'increase': gameModel.StockBar.woodCountPlus, 'decrease': gameModel.StockBar.woodCountMinus}]"></i>
								<div class="progress">
									<div :class="['bar', 'perc', 'withTransition', {'positive': gameModel.island.storage.woodProduction >= 0 && getPercent(gameModel.island.storage.woodCount, gameModel.island.storage.woodCountMax) < 100, 'negative': gameModel.island.storage.woodProduction < 0 || getPercent(gameModel.island.storage.woodCount, gameModel.island.storage.woodCountMax) == 100}]" :style="{transition: 'none 0s ease 0s', zIndex: 2, width: getPercent(gameModel.StockBar.woodCountPlus ? gameModel.island.storage.woodCountOld : gameModel.island.storage.woodCount, gameModel.island.storage.woodCountMax)+'%'}"></div>
									<div class="bar additionalBar additionalPerc increaseBar" :style="{transition: 'none 0s ease 0s', zIndex: gameModel.StockBar.woodCountPlus ? '1' : '-1', width: getPercent(gameModel.island.storage.woodCount, gameModel.island.storage.woodCountMax)+'%'}"></div>
									<div class="marker decreaseBar" :style="{zIndex: gameModel.StockBar.woodCountMinus ? '1' : '-1', width: getPercent(gameModel.island.storage.woodCountOld, gameModel.island.storage.woodCountMax)+'%'}">
										<div class="markerInner"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="production">
							<div class="value">{{gameModel.island.storage.woodProduction > 0 ? '+' : ''}}{{gameModel.island.storage.woodProduction}}</div>
						</div>
					</div>
					<div class="divider"></div>
				</div>
				<div class="clickable" @click="$router.push({path: '/production-overview', query: {tab: 'clay'}})">
					<div class="stockContainer clay">
						<div content="<h3><span>Глина</span></h3><div class='horizontalLine'></div><table class='transparent'><tbody><tr ng-if='village.data.production[n] > 0'><th><span>Заполнится через</span></th><td>54:25:29</td></tr></tbody></table>" v-tippy="{placement: 'bottom'}">
							<div class="progressbar">
								<div class="values">
									<span class="amount wrapper">{{gameModel.island.storage.clayCount}}</span>/<span class="capacity">{{gameModel.island.storage.clayCountMax}}</span>
								</div>
								<i class="labelIcon unit_clay_medium_illu resType2"></i>
								<i v-if="gameModel.StockBar.clayCountPlus || gameModel.StockBar.clayCountMinus" :class="['labelIcon', 'unit_clay_medium_illu', 'resType2', 'animated', {'increase': gameModel.StockBar.clayCountPlus, 'decrease': gameModel.StockBar.clayCountMinus}]"></i>
								<div class="progress">
									<div :class="['bar', 'perc', 'withTransition', {'positive': gameModel.island.storage.clayProduction >= 0 && getPercent(gameModel.island.storage.clayCount, gameModel.island.storage.clayCountMax) < 100, 'negative': gameModel.island.storage.clayProduction < 0 || getPercent(gameModel.island.storage.clayCount, gameModel.island.storage.clayCountMax) == 100}]" :style="{transition: 'none 0s ease 0s', zIndex: 2, width: getPercent(gameModel.StockBar.clayCountPlus ? gameModel.island.storage.clayCountOld : gameModel.island.storage.clayCount, gameModel.island.storage.clayCountMax)+'%'}"></div>
									<div class="bar additionalBar additionalPerc increaseBar" :style="{transition: 'none 0s ease 0s', zIndex: gameModel.StockBar.clayCountPlus ? '1' : '-1', width: getPercent(gameModel.island.storage.clayCount, gameModel.island.storage.clayCountMax)+'%'}"></div>
									<div class="marker decreaseBar" :style="{zIndex: gameModel.StockBar.clayCountMinus ? '1' : '-1', width: getPercent(gameModel.island.storage.clayCountOld, gameModel.island.storage.clayCountMax)+'%'}">
										<div class="markerInner"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="production">
							<div class="value">{{gameModel.island.storage.clayProduction > 0 ? '+' : ''}}{{gameModel.island.storage.clayProduction}}</div>
						</div>
					</div>
					<div class="divider"></div>
				</div>
				<div class="clickable" @click="$router.push({path: '/production-overview', query: {tab: 'rock'}})">
					<div class="stockContainer rock">
						<div content="<h3><span>Камень</span></h3><div class='horizontalLine'></div><table class='transparent'><tbody><tr ng-if='village.data.production[n] > 0'><th><span>Заполнится через</span></th><td>54:25:29</td></tr></tbody></table>" v-tippy="{placement: 'bottom'}">
							<div class="progressbar">
								<div class="values">
									<span class="amount wrapper">{{gameModel.island.storage.rockCount}}</span>/<span class="capacity">{{gameModel.island.storage.rockCountMax}}</span>
								</div>
								<i class="labelIcon unit_rock_medium_illu resType5"></i>
								<i v-if="gameModel.StockBar.rockCountPlus || gameModel.StockBar.rockCountMinus" :class="['labelIcon', 'unit_rock_medium_illu', 'resType5', 'animated', {'increase': gameModel.StockBar.rockCountPlus, 'decrease': gameModel.StockBar.rockCountMinus}]"></i>
								<div class="progress">
									<div :class="['bar', 'perc', 'withTransition', {'positive': gameModel.island.storage.rockProduction >= 0 && getPercent(gameModel.island.storage.rockCount, gameModel.island.storage.rockCountMax) < 100, 'negative': gameModel.island.storage.rockProduction < 0 || getPercent(gameModel.island.storage.rockCount, gameModel.island.storage.rockCountMax) == 100}]" :style="{transition: 'none 0s ease 0s', zIndex: 2, width: getPercent(gameModel.StockBar.rockCountPlus ? gameModel.island.storage.rockCountOld : gameModel.island.storage.rockCount, gameModel.island.storage.rockCountMax)+'%'}"></div>
									<div class="bar additionalBar additionalPerc increaseBar" :style="{transition: 'none 0s ease 0s', zIndex: gameModel.StockBar.rockCountPlus ? '1' : '-1', width: getPercent(gameModel.island.storage.rockCount, gameModel.island.storage.rockCountMax)+'%'}"></div>
									<div class="marker decreaseBar" :style="{zIndex: gameModel.StockBar.rockCountMinus ? '1' : '-1', width: getPercent(gameModel.island.storage.rockCountOld, gameModel.island.storage.rockCountMax)+'%'}">
										<div class="markerInner"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="production">
							<div class="value">{{gameModel.island.storage.rockProduction > 0 ? '+' : ''}}{{gameModel.island.storage.rockProduction}}</div>
						</div>
					</div>
					<div class="divider"></div>
				</div>
				<div class="clickable" @click="$router.push({path: '/production-overview', query: {tab: 'iron'}})">
					<div class="stockContainer iron">
						<div content="<h3><span>Железо</span></h3><div class='horizontalLine'></div><table class='transparent'><tbody><tr ng-if='village.data.production[n] > 0'><th><span>Заполнится через</span></th><td>54:25:29</td></tr></tbody></table>" v-tippy="{placement: 'bottom'}">
							<div class="progressbar">
								<div class="values">
									<span class="amount wrapper">{{gameModel.island.storage.ironCount}}</span>/<span class="capacity">{{gameModel.island.storage.ironCountMax}}</span>
								</div>
								<i class="labelIcon unit_iron_medium_illu resType3"></i>
								<i v-if="gameModel.StockBar.ironCountPlus || gameModel.StockBar.ironCountMinus" :class="['labelIcon', 'unit_iron_medium_illu', 'resType3', 'animated', {'increase': gameModel.StockBar.ironCountPlus, 'decrease': gameModel.StockBar.ironCountMinus}]"></i>
								<div class="progress">
									<div :class="['bar', 'perc', 'withTransition', {'positive': gameModel.island.storage.ironProduction >= 0 && getPercent(gameModel.island.storage.ironCount, gameModel.island.storage.ironCountMax) < 100, 'negative': gameModel.island.storage.ironProduction < 0 || getPercent(gameModel.island.storage.ironCount, gameModel.island.storage.ironCountMax) == 100}]" :style="{transition: 'none 0s ease 0s', zIndex: 2, width: getPercent(gameModel.StockBar.ironCountPlus ? gameModel.island.storage.ironCountOld : gameModel.island.storage.ironCount, gameModel.island.storage.ironCountMax)+'%'}"></div>
									<div class="bar additionalBar additionalPerc increaseBar" :style="{transition: 'none 0s ease 0s', zIndex: gameModel.StockBar.ironCountPlus ? '1' : '-1', width: getPercent(gameModel.island.storage.ironCount, gameModel.island.storage.ironCountMax)+'%'}"></div>
									<div class="marker decreaseBar" :style="{zIndex: gameModel.StockBar.ironCountMinus ? '1' : '-1', width: getPercent(gameModel.island.storage.ironCountOld, gameModel.island.storage.ironCountMax)+'%'}">
										<div class="markerInner"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="production">
							<div class="value">{{gameModel.island.storage.ironProduction > 0 ? '+' : ''}}{{gameModel.island.storage.ironProduction}}</div>
						</div>
					</div>
					<div class="divider"></div>
				</div>
				<div class="clickable" @click="$router.push({path: '/production-overview', query: {tab: 'crop'}})">
					<div class="stockContainer crop">
						<div content="<h3><span>Зерно</span></h3><div class='horizontalLine'></div><table class='transparent'><tbody><tr ng-if='village.data.production[n] < 0'><th><span>Опустеет через</span></th><td>843:30:00</td></tr><tr ng-if='resNames[n] == \'crop\''><th><span>Всего (/ч)</span></th><td ng-class='{positiveProduction: village.data.production[n] > 0, negativeProduction: village.data.production[n] < 0}' class='negativeProduction'>+372</td></tr></tbody></table>" v-tippy="{placement: 'bottom'}">
							<div class="progressbar">
								<div class="values">
									<span class="amount wrapper">{{gameModel.island.storage.cropCount}}</span>/<span class="capacity">{{gameModel.island.storage.cropCountMax}}</span>
								</div>
								<i class="labelIcon unit_crop_medium_illu resType4"></i>
								<i v-if="gameModel.StockBar.cropCountPlus || gameModel.StockBar.cropCountMinus" :class="['labelIcon', 'unit_crop_medium_illu', 'resType4', 'animated', {'increase': gameModel.StockBar.cropCountPlus, 'decrease': gameModel.StockBar.cropCountMinus}]"></i>
								<div class="progress">
									<div :class="['bar', 'perc', 'withTransition', {'positive': gameModel.island.storage.cropProduction >= 0 && getPercent(gameModel.island.storage.cropCount, gameModel.island.storage.cropCountMax) < 100, 'negative': gameModel.island.storage.cropProduction < 0 || getPercent(gameModel.island.storage.cropCount, gameModel.island.storage.cropCountMax) == 100}]" :style="{transition: 'none 0s ease 0s', zIndex: 2, width: getPercent(gameModel.StockBar.cropCountPlus ? gameModel.island.storage.cropCountOld : gameModel.island.storage.cropCount, gameModel.island.storage.cropCountMax)+'%'}"></div>
									<div class="bar additionalBar additionalPerc increaseBar" :style="{transition: 'none 0s ease 0s', zIndex: gameModel.StockBar.cropCountPlus ? '1' : '-1', width: getPercent(gameModel.island.storage.cropCount, gameModel.island.storage.cropCountMax)+'%'}"></div>
									<div class="marker decreaseBar" :style="{zIndex: gameModel.StockBar.cropCountMinus ? '1' : '-1', width: getPercent(gameModel.island.storage.cropCountOld, gameModel.island.storage.cropCountMax)+'%'}">
										<div class="markerInner"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="production">
							<div class="value">{{gameModel.island.storage.cropProduction > 0 ? '+' : ''}}{{gameModel.island.storage.cropProduction}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">
.resourceBarWrapper{
    height:0
}
#resourceBar{
    position:absolute;
    margin-top:-11px;
    height:52px;
    /*width:528px;*/
    width:714px;
    padding:10px 40px 0 44px;
    left:50%;
    -webkit-transform:translate3d(-50%,0,0);
    -moz-transform:translate3d(-50%,0,0);
    -ms-transform:translate3d(-50%,0,0);
    -o-transform:translate3d(-50%,0,0);
    transform:translate3d(-50%,0,0);
    line-height:16px;
    text-align:center
}
#resourceBar:after{
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0
}
#resourceBar .background{
    position:absolute;
    top:0;
    left:40px;
    right:40px;
    height:50px;
    background-position:0 -99px;
    background-image:url("//iwstatic.g.bsrv.su/img/layout/main/main_stockbar.png");
    background-repeat:repeat-x
}
#resourceBar .background.worldEnded{
    background-image:url("//iwstatic.g.bsrv.su/img/layout/main/main_stockbarEndOfGameworld.png")
}
#resourceBar .background.worldEnded:before,
#resourceBar .background.worldEnded:after{
    background-image:url("//iwstatic.g.bsrv.su/img/layout/main/main_stockbarEndOfGameworld.png")
}
#resourceBar .background:before,
#resourceBar .background:after{
    content:"";
    position:absolute;
    top:0;
    height: 89px;
    width: 25px;
    background-image:url("//iwstatic.g.bsrv.su/img/layout/main/main_stockbar.png")
}
#resourceBar .background:before{
    left:-25px;
    background-position:left top
}
#resourceBar .background:after{
    right:-25px;
    background-position:right bottom
}
#resourceBar .divider{
    position:relative;
    top:3px;
    margin:0 10.5px;
    width:1px;
    height:29px;
    float:left;
    background-image:-owg-linear-gradient(#ebebeb,#c8c8c8);
    background-image:-webkit-linear-gradient(#ebebeb,#c8c8c8);
    background-image:-moz-linear-gradient(#ebebeb,#c8c8c8);
    background-image:-o-linear-gradient(#ebebeb,#c8c8c8);
    background-image:linear-gradient(#ebebeb,#c8c8c8)
}
#resourceBar .worldEnd{
    color:#fff;
    float:none;
    padding:0 50px
}
#resourceBar .worldEnd h2{
    font-size:1.53846rem;
    margin:3px;
    margin-bottom:10px;
    display:inline-block
}
#resourceBar .worldEnd .headerTrapezoidal .content{
    color:#000;
    height:42px;
    background-image:-owg-linear-gradient(#e6c77c,#fff);
    background-image:-webkit-linear-gradient(#e6c77c,#fff);
    background-image:-moz-linear-gradient(#e6c77c,#fff);
    background-image:-o-linear-gradient(#e6c77c,#fff);
    background-image:linear-gradient(#e6c77c,#fff);
    border-top:1px solid #9a7641;
    border-bottom:1px solid #9a7641
}
#resourceBar .worldEnd .headerTrapezoidal .content:before,
#resourceBar .worldEnd .headerTrapezoidal .content:after{
    width:21px;
    height:42px;
    background-image:-owg-linear-gradient(#e6c77c,#fff);
    background-image:-webkit-linear-gradient(#e6c77c,#fff);
    background-image:-moz-linear-gradient(#e6c77c,#fff);
    background-image:-o-linear-gradient(#e6c77c,#fff);
    background-image:linear-gradient(#e6c77c,#fff);
    border-top:1px solid #9a7641;
    border-bottom:1px solid #9a7641
}
#resourceBar .worldEnd .headerTrapezoidal .content:before{
    left:-10.5px;
    border-left:1px solid #9a7641
}
#resourceBar .worldEnd .headerTrapezoidal .content:after{
    right:-10.5px;
    border-right:1px solid #9a7641
}
#resourceBar .worldEnd .headerTrapezoidal .content .wrapper{
    border:1px solid #9a7641;
    background-color:#dad8d5;
    padding:3px;
    margin:3px
}
#resourceBar .worldEnd .headerTrapezoidal .content .wrapper i.winnerKingdom{
    position:relative;
    top:3px;
    background-position:-778px -48px;
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/endOfWorld.png");
    width:16px;
    height:16px
}
#resourceBar .worldEnd .headerTrapezoidal .content .wrapper .wrapper2{
    border:1px solid #fff;
    background-image:-owg-linear-gradient(#fff,#e8e5e1);
    background-image:-webkit-linear-gradient(#fff,#e8e5e1);
    background-image:-moz-linear-gradient(#fff,#e8e5e1);
    background-image:-o-linear-gradient(#fff,#e8e5e1);
    background-image:linear-gradient(#fff,#e8e5e1);
    padding:0 5px
}
.stockContainer{
    position:relative;
    margin-top:7px;
    float:left;
    min-width:125px;
    width:auto !important;
    width:125px
}
.stockContainer .production{
    position:absolute;
    left:0;
    right:0;
    margin-top:4px;
    font-size:.84615rem;
    line-height:13px
}
.stockContainer .production .value{
    display:inline-block;
    min-width:55px;
    width:auto !important;
    width:55px;
    padding:0 2px;
    border:1px solid #c8c8c8;
    border-top:1px solid #ebebeb;
    -moz-border-radius-bottomleft:10px 25px;
    -webkit-border-bottom-left-radius:10px 25px;
    border-bottom-left-radius:10px 25px;
    -moz-border-radius-bottomright:10px 25px;
    -webkit-border-bottom-right-radius:10px 25px;
    border-bottom-right-radius:10px 25px;
    background-image:-owg-linear-gradient(#fff,#c8c8c8,#c8c8c8);
    background-image:-webkit-linear-gradient(#fff,#c8c8c8,#c8c8c8);
    background-image:-moz-linear-gradient(#fff,#c8c8c8,#c8c8c8);
    background-image:-o-linear-gradient(#fff,#c8c8c8,#c8c8c8);
    background-image:linear-gradient(#fff,#c8c8c8,#c8c8c8);
    -webkit-box-shadow:0 1px 4px 0 rgba(0,0,0,0.5);
    -moz-box-shadow:0 1px 4px 0 rgba(0,0,0,0.5);
    box-shadow:0 1px 4px 0 rgba(0,0,0,0.5)
}
.stockContainer .progressbar .progress{
    height:6px;
    width:113px
}
.stockContainer .progressbar .progress .perc.full{
    background-image:-owg-linear-gradient(#ff9696,#ff9696 40%,#a60000);
    background-image:-webkit-linear-gradient(#ff9696,#ff9696 40%,#a60000);
    background-image:-moz-linear-gradient(#ff9696,#ff9696 40%,#a60000);
    background-image:-o-linear-gradient(#ff9696,#ff9696 40%,#a60000);
    background-image:linear-gradient(#ff9696,#ff9696 40%,#a60000)
}
.stockContainer .progressbar .progress .perc.full:before{
    border:1px solid #a60000
}
.resourceTooltip{
    width:220px
}
.resourceTooltip table td.positiveProduction{
    color:#5eb95e
}
.resourceTooltip table td.negativeProduction{
    color:#d40000
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {
		getPercent: lib.getPercent,
	},
	components: {},
}
</script>
<template>
	<div ng-if="overlayController" class="windowOverlay" id="secretSocietyInvitation" ng-controller="overlayController">
		<div class="darkener clickable" clickable="closeOverlay()"></div>
		<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="622">
			<div class="inWindowPopup invitationDetails">
				<div class="inWindowPopupHeader">
					<div class="navigation">
						<a class="back clickable" clickable="backToOverview()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
							<i ng-class="{
									symbol_arrowFrom_tiny_flat_black: !fromHover,
									symbol_arrowFrom_tiny_flat_green: fromHover
								}" class="symbol_arrowFrom_tiny_flat_black"></i>
							<span translate=""><span>Назад</span></span>
						</a>
					</div>
					<i class="communityIcon community_secretSociety_large_illu"></i>
					<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
				</div>
				<div class="inWindowPopupContent">
					<div class="contentBox transparent">
						<h6 class="contentBoxHeader headerColored">
							<a class="secretSocietyLink  truncated clickable" clickable="$root.openWindow('society', {'societyId': 10, 'tab': 'SecretSociety'})" societyid="10" societyname="ЦАРСТВО ТС">ЦАРСТВО ТС</a>
							<div class="sender">
								<span translate=""><span>Из:</span></span>
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="662" playername="Migera">Migera</a>
							</div>
						</h6>
						<div class="contentBoxBody">
							<div class="greeting">
								<span translate="" data="playerName: lexinzector"><span>Привет, lexinzector!</span></span><br><br>
								<span translate="" options="1, 5" data="targetId: 10, targetName: "><span>Мы приглашаем тебя присоединиться к тайному сообществу, где мы сможем защитить друг друга!</span></span>
							</div>
							
							<!-- ngRepeat: c in conditions.data -->
							
							<div class="sharedInformations" ng-if="hasSharedInformation">
								<span translate=""><span>Если ты присоединишься к нам, другие участники смогут видеть следующую информацию:</span></span>
								<ul>
									<li ng-if="society.isShared(shared.reports);" translate=""><span>Отчеты о битвах и разведке</span></li>
									<li ng-if="society.isShared(shared.nextAttacks);" translate=""><span>Следующая атака на каждую деревню</span></li>
									<li ng-if="society.isShared(shared.villageDetails);" translate=""><span>Дополнительные данные о деревнях <i>(производство зерна, войска)</i></span></li>
								</ul>
							</div>
						</div>
					</div>

					<!-- custom text -->
					<!-- ngIf: invitation.data.customText -->

					<!-- conditions -->
					<!-- ngIf: hasConditions -->
					<div class="buttonFooter">
						<div ng-if="!isSitter">
							<button clickable="acceptInvitation();" ng-class="{disabled: !allConditionsMet}" tooltip="" tooltip-translate="Error.ConditionNotMet" tooltip-hide="true" class="clickable">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Принять</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
							<button class="cancel clickable" clickable="declineInvitation();">
								<div class="content">
									<span ng-transclude="" class="innerText"><span translate=""><span>Отказаться</span></span></span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
						<!-- ngIf: isSitter -->
						<div class="error"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
<template>
	<div class="hero-item-container">
		<div class="entityBox item msAntiScroll" :class="{
			'disabled': !item.filled || item.disabled,
			'noClick': !item.filled || item.disabled || !clickHandler,
			'equipped': item.inSlot && !hideEquippedState,
			'highlight': highlighted,
			'premiumItem': item.premiumItem,
			'empty': !item.filled
		}" :content="item.tooltip" v-tippy="{placement: 'bottom'}" @click="clickHandler">
			<img class="male" :class="['heroItem_'+item.icon+'_large_illu']" src="//iwstatic.g.bsrv.su/img/x.gif" data-cmp-info="10"/>
			<div class="symbol_lock_small_wrapper" v-if="item.disabled">
				<i class="lockedState symbol_lock_small_flat_black"></i>
			</div>
			<i class="itemState" v-if="!hideStates" :class="{
				'symbol_exclamationMark_small_flat_white': !item.inSlot && item.lastChange > lastView && lastView > 0 && !viewedItems[item.id],
				'action_check_small_flat_white': item.inSlot && !hideEquippedState,
				'cardGame_prizeNormal_medium_illu': item.cardGameItem && !item.inSlot && !(item.lastChange > lastView && lastView > 0 && !viewedItems[item.id]),
				'cardGame_prizePremium_medium_illu': item.premiumItem && !item.inSlot && !(item.lastChange > lastView && lastView > 0 && !viewedItems[item.id])
			}"></i>
			<div class="amountContainer upgradedItem" v-if="item.upgradedItem && !hideAmountContainer">
				<div class="amount">
					<i class="upgrade_upgrade_tiny_flat_black"></i>
				</div>
			</div>
			<div class="amountContainer" v-if="!hideAmountContainer">
				<div class="amount" v-if="item.amount > 1">
					<div class="digit">{{item.amount}}</div>
				</div>
				<span class="upgradeLevel" v-if="(item.upgradeLevel > 0 && (
						item.slot == InventoryModel.SLOT_HELMET ||
						item.slot == InventoryModel.SLOT_BODY ||
						item.slot == InventoryModel.SLOT_LEFT_HAND ||
						item.slot == InventoryModel.SLOT_RIGHT_HAND ||
						item.slot == InventoryModel.SLOT_SHOES
					) || showNextUpgrade
				) && !hideUpgradeContainer">
					<!-- ngRepeat: a in []|range:1:5 -->
					<!-- ngIf: showNextUpgrade -->
					<i v-for="a in range(1, 5)" :class="{
						'upgrade_helmet_tiny_illu': item.slot == InventoryModel.SLOT_HELMET && item.upgradeLevel >= a,
						'upgrade_rightHand_tiny_illu': item.slot == InventoryModel.SLOT_RIGHT_HAND && item.upgradeLevel >= a,
						'upgrade_leftHand_tiny_illu': item.slot == InventoryModel.SLOT_LEFT_HAND && item.upgradeLevel >= a,
						'upgrade_body_tiny_illu': item.slot == InventoryModel.SLOT_BODY && item.upgradeLevel >= a,
						'upgrade_shoes_tiny_illu': item.slot == InventoryModel.SLOT_SHOES && item.upgradeLevel >= a,
						'upgrade_upgrade_tiny_flat_black': item.upgradeLevel < a
					}" v-if="!showNextUpgrade"></i>
				</span>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import InventoryModel from '@/models/game/InventoryModel';

export default {
	mixins: lib.mixins,
	props: {
		item: {
			type: Object,
			required: true,
		},
		lastView: {
			type: Number,
			default: 0,
		},
		viewedItems: {
			type: Object,
		},
		highlighted: {
			type: Boolean,
			default: false,
		},
		hideStates: {
			type: Boolean,
			default: false,
		},
		hideEquippedState: {
			type: Boolean,
			default: false,
		},
		hideAmountContainer: {
			type: Boolean,
			default: false,
		},
		hideUpgradeContainer: {
			type: Boolean,
			default: false,
		},
		showNextUpgrade: {
			type: Boolean,
			default: false,
		},
		clickHandler: {
			type: Function,
		},
	},
	data: () => ({
		InventoryModel,
	}),
	computed: {},
	methods: {
		range: lib.range,
	},
	components: {},
}
</script>
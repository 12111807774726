<template>
	<div>
		<table class="silverBookkeeping">
			<thead>
				<tr>
					<td class="date"><span>Дата</span></td>
					<td class="description"><span>Причина</span></td>
					<td class="amount"><span>Запись</span></td>
					<td class="balance"><span>Баланс</span></td>
				</tr>
			</thead>
			<tbody>
				<tr class="highlightRow">
					<td class="date">-</td>
					<td class="silverBlockedDescription description">
						<div v-if="silverBlockingAuctions.length == 0">
							<span>Нет задействованного на торгах серебра</span>
						</div>
						<div v-if="silverBlockingAuctions.length > 0">
							<span>Задействованное на {{silverBlockingAuctions.length}} торгах серебро</span>
						</div>
						<div @click="onToggleShowBlockedSilver()" class="clickable" v-if="silverBlockingAuctions.length > 0">
							<a :content="(!showBlockedSilver ? 'Показать' : 'Скрыть')+' информацию о задействованном серебре'" v-tippy="{placement: 'bottom'}">
								<span v-if="!showBlockedSilver">показать</span>
								<span v-if="showBlockedSilver">скрыть</span>
							</a>
						</div>
					</td>
					<td class="amount"><span v-if="blockedSilver > 0">-</span>{{blockedSilver}}</td>
					<td class="balance">{{gameModel.player.silver}}</td>
				</tr>
				<tr v-show="showBlockedSilver" v-for="auction in silverBlockingAuctions" :key="auction.id">
					<td class="silverBlockedDescription" colspan="2">
						<span class="indentedDescription">
							<i :class="['heroItem item_category_'+auction.icon+'_small_flat_black']" :content="auction.tooltip" v-tippy="{placement: 'bottom'}"></i>
							<span>&nbsp;{{auction.count}}</span>
							<span> x </span>
							<span>{{auction.name}}</span>
						</span>
					</td>
					<td class="amount">-{{auction.amount}}</td>
					<td class="balance"></td>
				</tr>
				<tr v-for="event in silverEvents" :key="event.id">
					<td class="date">
						<span :i18ndt="event.time" format="shortDate">{{formatUnixDate(event.time, 'DD.MM.YY')}}</span>
					</td>
					<td class="description">
						<div v-if="event.operationType == SilverChangeModel.TYPE_EXCHANGE_OFFICE">
							<span v-if="event.operationType == SilverChangeModel.TYPE_EXCHANGE_OFFICE && event.silver < 0"><span>Пункт обмена – покупка золота</span></span>
							<span v-if="event.operationType == SilverChangeModel.TYPE_EXCHANGE_OFFICE && event.silver > 0"><span>Пункт обмена – покупка серебра</span></span>
						</div>
						<div v-if="event.operationType == SilverChangeModel.TYPE_AUCTION || event.operationType == SilverChangeModel.TYPE_SELL_TO_INTERMEDIARY">
							<div v-if="event.silver > 0"><span>Серебро с продажи</span></div>
							<div v-if="event.silver < 0"><span>Оставшееся серебро после покупки</span></div>
							<div>
								<i class="heroItem" :class="['item_category_'+event.icon+'_small_flat_black']" :content="event.tooltip" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/hero/partials/itemTooltip.html"></i>
								<span>&nbsp;{{event.itemAmount}}</span>
								<span> x </span>
								<span>{{event.name}}</span>
							</div>
						</div>
						<div v-if="event.operationType == SilverChangeModel.TYPE_ADVENTURE">
							<span><span>Серебро с приключений</span></span>
						</div>
						<!-- ngIf: event.data.operationType == SilverChange.TYPE_COOP_PACKAGE -->
						<div v-if="event.operationType == SilverChangeModel.TYPE_QUEST">
							<span><span>Серебро за задания</span></span>
						</div>
						<!-- ngIf: event.data.operationType == SilverChange.TYPE_STARTER_PACKAGE -->
						<div v-if="event.operationType == SilverChangeModel.TYPE_CARD_GAME">
							<span><span>Серебро с карточных игр</span></span>
						</div>
					</td>
					<td class="amount"><span v-if="event.silver > 0">+</span>{{event.silver}}</td>
					<td class="balance">8894</td>
				</tr>
				<tr class="highlightRow">
					<td colspan="3" class="oldBalanceDescription">
						<span><span>Старый баланс </span></span>
						(<span i18ndt="1707556089.8" format="mediumDate">10.02.2024</span>)
					</td>
					<td class="balance">0</td>
				</tr>
			</tbody>
		</table>
		<div class="italic grey">(<span><span>Транзакции за последние 7 дней</span></span>)</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import SilverChangeModel from '@/models/game/SilverChangeModel';

export default {
	mixins: lib.mixins,
	data: () => ({
		SilverChangeModel,
		
		silverEvents: [
			{
				id: 55671,
				silver: 200,
				operationType: SilverChangeModel.TYPE_QUEST,
				itemType: null,
				itemAmount: null,
				time: 1722624270,
			},
			{
				id: 55870,
				silver: 100,
				operationType: SilverChangeModel.TYPE_QUEST,
				itemType: null,
				itemAmount: null,
				time: 1722667165,
			},
			{
				id: 56466,
				silver: 200,
				operationType: SilverChangeModel.TYPE_QUEST,
				itemType: null,
				itemAmount: null,
				time: 1722768263,
			},
			{
				id: 56911,
				silver: 1078,
				operationType: SilverChangeModel.TYPE_CARD_GAME,
				itemType: null,
				itemAmount: null,
				time: 1722837305,
			},
			{
				id: 56912,
				silver: 150,
				operationType: SilverChangeModel.TYPE_QUEST,
				itemType: null,
				itemAmount: null,
				time: 1722837384,
			},
			{
				id: 57406,
				silver: 150,
				operationType: SilverChangeModel.TYPE_QUEST,
				itemType: null,
				itemAmount: null,
				time: 1722916221,
			},
			{
				id: 57581,
				silver: 100,
				operationType: SilverChangeModel.TYPE_QUEST,
				itemType: null,
				itemAmount: null,
				time: 1722938718,
			},
			{
				id: 58202,
				silver: 150,
				operationType: SilverChangeModel.TYPE_QUEST,
				itemType: null,
				itemAmount: null,
				time: 1723035940,
			},
			{
				id: 59415,
				silver: -200,
				operationType: SilverChangeModel.TYPE_EXCHANGE_OFFICE,
				itemType: null,
				itemAmount: null,
				time: 1723215887,
			},
			{
				id: 59416,
				silver: 100,
				operationType: SilverChangeModel.TYPE_EXCHANGE_OFFICE,
				itemType: null,
				itemAmount: null,
				time: 1723215986,
			},
			{
				id: 59419,
				silver: 10,
				operationType: SilverChangeModel.TYPE_CARD_GAME,
				itemType: null,
				itemAmount: null,
				time: 1723216627,
			},
			{
				id: 59438,
				silver: -2104,
				operationType: SilverChangeModel.TYPE_AUCTION,
				itemType: 113,
				icon: 'scroll',
				name: 'Свиток',
				itemAmount: 111,
				time: 1723218047,
			},
			{
				id: 59438,
				silver: 447,
				operationType: SilverChangeModel.TYPE_AUCTION,
				itemType: 115,
				icon: 'bookOfWisdom',
				name: 'Книга мудрости',
				itemAmount: 1,
				time: 1723218048,
			},
			{
				id: 59440,
				silver: 150,
				operationType: SilverChangeModel.TYPE_QUEST,
				itemType: null,
				itemAmount: null,
				time: 1723218652,
			},
			{
				id: 59440,
				silver: 350,
				operationType: SilverChangeModel.TYPE_ADVENTURE,
				itemType: null,
				itemAmount: null,
				time: 1723218652,
			},
		],
		
		// после перебития ставки игроком таймер аукциона продлевается на 5 минут (если оставалось меньше 5 минут)
		// после выигрыша аукциона цена берется за ставку предпоследнего игрока а не ту которую поставили мы последней (при условии перебития ставки предпоследнего игрока нами)
		silverBlockingAuctions: [
			{
				id: 8474,
				itemType: 113,
				auctionitem: true,
				hideInfo: false,
				icon: 'scroll',
				name: 'Свиток',
				count: 111,
				amount: 900,
				tooltip: `<div class="itemDescription" item-description="8508" auction-item="true" hide-info="false" item-type="119">
	<div translate="" options="119" class="heroItemName"><span>Клетка</span></div>
	<div ng-hide="instantUse &amp;&amp; !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse &amp;&amp; (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) &amp;&amp; itemType != heroItem.TYPE_ARTWORK
	 		&amp;&amp; itemType != heroItem.TYPE_FIREWORKS &amp;&amp; itemType != heroItem.TYPE_SUPER_FIREWORKS &amp;&amp; !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="119">!!Hero.ItemBonusInstantUse_119!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="119" data="x:">!!Hero.ItemBonusInstantUse_119!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до &#x202D;&#x202D;25&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до &#x202D;&#x202D;33&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до &#x202D;&#x202D;30&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до &#x202D;&#x202D;38&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (&#x202D;&#x202D;5&#x202C;%&#x202C;) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до &#x202D;&#x202D;5&#x202C;%&#x202C; от войска). Так что суммарно можно восстановить &#x202D;&#x202D;33&#x202C;%&#x202C;+&#x202D;&#x202D;5&#x202C;%&#x202C;=&#x202D;&#x202D;38&#x202C;%&#x202C;.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 &amp;&amp; itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max &amp;&amp; !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max &amp;&amp; perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES --><div ng-if="itemType == heroItem.TYPE_CAGES">
		<div class="horizontalLine"></div>
		<span translate=""><span>Если у твоего героя имеются клетки в слоте предмета, битва в оазисе не состоится. Вместо этого будут пойманы животные.</span></span>
	</div><!-- end ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
			},
			{
				id: 8508,
				itemType: 119,
				auctionitem: true,
				hideInfo: false,
				icon: 'cage',
				name: 'Клетка',
				count: 5,
				amount: 25,
				tooltip: ``,
			},
		],
		showBlockedSilver: false,
	}),
	computed: {
		blockedSilver(){
			return this.silverBlockingAuctions.reduce((sum, elem) => sum + elem.amount, 0);
		},
	},
	methods: {
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
		
		onToggleShowBlockedSilver(){
			this.showBlockedSilver = !this.showBlockedSilver;
		},
	},
	components: {},
}
</script>
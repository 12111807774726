<template>
	<div id="notepads" v-if="gameModel.notepads.show">
		<div class="notepad" ng-repeat="n in gameModel.notepads.list" style="top: 37.4667%; transform: translate3d(0px, 0px, 0px); width: 256px; height: 100px; z-index: 1070; left: 51.1876%;">
			<div class="clickable">
				<div class="actions ng-hide" ng-show="over && !n.inEditor && !deleteQuestion">
					<div class="dragExtender"></div>
					<div class="action deleteNote clickable" clickable="askDelete()" content="Удалить заметку" v-tippy="{placement: 'bottom'}" tooltip-translate="Notepad.DeleteNote">
						<i class="action_delete_small_flat_black"></i>
					</div>
					<div class="action editNote clickable" clickable="startEdit()" content="Изменить" v-tippy="{placement: 'bottom'}" tooltip-translate="Notepad.EditNote">
						<i class="action_edit_small_flat_black"></i>
					</div>
					<div class="action addNote clickable" clickable="addNew()" content="Создать новую заметку" v-tippy="{placement: 'bottom'}" tooltip-translate="Notepad.AddNote">
						<i class="symbol_plus_tiny_flat_black"></i>
					</div>
				</div>
				<div class="actions active ng-hide" ng-show="n.inEditor">
					<div class="dragExtender"></div>
					<div class="action active cancelNote clickable" clickable="cancel()" content="Отмена" v-tippy="{placement: 'bottom'}">
						<i class="action_cancel_tiny_flat_white"></i>
					</div>
					<div class="action active saveNote clickable" clickable="save()" content="Сохранить" v-tippy="{placement: 'bottom'}">
						<i class="action_check_small_flat_white"></i>
					</div>
					<div class="action addNote clickable" clickable="addNew()" content="Создать новую заметку" v-tippy="{placement: 'bottom'}">
						<i class="symbol_plus_tiny_flat_black"></i>
					</div>
				</div>
				<div class="actions active ng-hide" ng-show="deleteQuestion">
					<div class="dragExtender"></div>
					<div class="action active cancelNote clickable" clickable="cancel()" @click="onNotepadClose" content="Отмена" v-tippy="{placement: 'bottom'}">
						<i class="action_cancel_tiny_flat_white"></i>
					</div>
					<div class="action active saveNote clickable" clickable="delete()" content="Удалить" v-tippy="{placement: 'bottom'}">
						<i class="action_check_small_flat_white"></i>
					</div>
					<div class="action addNote clickable" clickable="addNew()" content="Создать новую заметку" v-tippy="{placement: 'bottom'}">
						<i class="symbol_plus_tiny_flat_black"></i>
					</div>
				</div>
				<div class="header msAntiScroll" ng-class="{edit: n.inEditor}" draggable="true">
					<div class="dragExtender"></div>
					<div class="verticalLine double"></div>
					<div class="verticalLine double"></div>
					<div class="verticalLine double"></div>
					<div class="verticalLine double"></div>
					<div class="verticalLine double"></div>
					<div class="verticalLine double"></div>
				</div>
				<div class="body scrollable">
					<div class="scrollContentOuterWrapper" style="width: 250px;">
						<div class="scrollContent" style="width: auto;">
							<div class="scrollContentInnerWrapper">
								<div class="text" ng-show="!n.inEditor"></div>
								<div class="bbCodeInput ng-valid ng-hide" ng-show="n.inEditor">
									<div class="codes">
										<div class="iconButton clickable" clickable="insertCode('[b]','[/b]')" ng-class="{disabled: preview}">
											<i class="bbCode_bold_small_flat_black"></i>
										</div>
										<div class="iconButton clickable" clickable="insertCode('[i]','[/i]')" ng-class="{disabled: preview}">
											<i class="bbCode_italic_small_flat_black"></i>
										</div>
										<div class="iconButton clickable" clickable="insertCode('[u]','[/u]')" ng-class="{disabled: preview}">
											<i class="bbCode_underline_small_flat_black"></i>
										</div>
										<div class="iconButton clickable" clickable="insertCode('[s]','[/s]')" ng-class="{disabled: preview}">
											<i class="bbCode_strikeThrough_small_flat_black"></i>
										</div>
										<div class="iconButton clickable" clickable="insertCode('[h]','[/h]')" ng-class="{disabled: preview}">
											<i class="bbCode_highlight_small_flat_black"></i>
										</div>
										<div class="iconButton clickable" clickable="triggerSearch()" ng-class="{disabled: preview, active: searchOpened}">
											<i class="bbCode_addLink_small_flat_black"></i>
										</div>
										<div class="verticalLine"></div>
										<div class="iconButton clickable" clickable="triggerPreview()" ng-class="{active: preview}">
											<i class="bbCode_preview_small_flat_black"></i>
										</div>
										<div class="autocomplete ng-hide" ng-show="searchOpened">
											<span class="serverautocompleteContainer ng-valid">
												<span class="ui-helper-hidden-accessible"></span>
												<input class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" autocomplete="off" placeholder="Поиск..." />
												<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 1px;">
													<i class="action_search_tiny_flat_white"></i>
													Деревня, Игрок, Название королевства, Координаты
												</label>
											</span>
										</div>
									</div>
									<textarea ng-show="!preview" dropable="checkDrop(object)" class="ng-pristine ng-untouched ng-valid"></textarea>
									<div class="previewWrapper ng-hide" ng-show="preview"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="scrollTrack" style="display: none; opacity: 0;">
						<div class="scrollHandle" style="height: 0px; top: 0px;"></div>
						<div class="trackDecoration"></div>
					</div>
				</div>
				<div class="body deleteOverlay ng-hide" ng-show="deleteQuestion">
					<span><span>Удалить заметку полностью?</span></span>
				</div>
				<div class="footer"></div>
				<div class="resizer window_resizehandle_small_illu"></div>
				<div class="resizeHelper" style="display: none;"></div>
			</div>
		</div>
	</div>
</template>

<style lang="css">
#notepads .notepad{
    position:absolute;
    background-color:#fffbd4;
    border:1px solid #9a7641;
    -webkit-box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0;
    -moz-box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0;
    box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0
}
#notepads .notepad .actions{
    position:absolute;
    top:-2px;
    width:100%;
    height:0
}
#notepads .notepad .actions .dragExtender{
    position:absolute;
    top:-30px;
    right:0;
    width:100px;
    height:26px;
    z-index:1
}
#notepads .notepad .actions .action{
    z-index:2;
    position:relative;
    top:-26px;
    width:26px;
    height:26px;
    line-height:26px;
    background-color:#fffbd4;
    border:1px solid #bfd94d;
    float:right;
    text-align:center
}
#notepads .notepad .actions .action:hover{
    background-color:#c9e271
}
#notepads .notepad .actions .action:hover.deleteNote{
    background-color:#dd3a37
}
#notepads .notepad .actions .action.active{
    background-color:#bfd94d
}
#notepads .notepad .actions .action.active:hover{
    background-color:#c9e271
}
#notepads .notepad .actions .action.addNote{
    margin-right:10px
}
#notepads .notepad .actions .action.addNote:after{
    content:'';
    position:absolute;
    top:6px;
    left:5px;
    width:12px;
    height:12px;
    border:2px solid #463f39
}
#notepads .notepad .actions .action.cancelNote{
    background-color:#d40000
}
#notepads .notepad .actions .action.cancelNote:hover{
    background-color:#dd3a37
}
#notepads .notepad .actions .action i{
    vertical-align:middle
}
#notepads .notepad .header{
    position:relative;
    border-bottom:1px solid #9a7641;
    width:100%;
    height:10px;
    text-align:center;
    z-index:1;
    background-image:-owg-linear-gradient(#e8e5e1,#e8e5e1);
    background-image:-webkit-linear-gradient(#e8e5e1,#e8e5e1);
    background-image:-moz-linear-gradient(#e8e5e1,#e8e5e1);
    background-image:-o-linear-gradient(#e8e5e1,#e8e5e1);
    background-image:linear-gradient(#e8e5e1,#e8e5e1);
    cursor:move
}
#notepads .notepad .header.edit{
    background-image:-owg-linear-gradient(#f1ffc1,#506d00);
    background-image:-webkit-linear-gradient(#f1ffc1,#506d00);
    background-image:-moz-linear-gradient(#f1ffc1,#506d00);
    background-image:-o-linear-gradient(#f1ffc1,#506d00);
    background-image:linear-gradient(#f1ffc1,#506d00)
}
#notepads .notepad .header.edit.clicked{
    background-image:-owg-linear-gradient(#506d00,#f1ffc1);
    background-image:-webkit-linear-gradient(#506d00,#f1ffc1);
    background-image:-moz-linear-gradient(#506d00,#f1ffc1);
    background-image:-o-linear-gradient(#506d00,#f1ffc1);
    background-image:linear-gradient(#506d00,#f1ffc1)
}
#notepads .notepad .header.hovered:not(.edit){
    background-image:-owg-linear-gradient(#ebebeb,#c8c8c8);
    background-image:-webkit-linear-gradient(#ebebeb,#c8c8c8);
    background-image:-moz-linear-gradient(#ebebeb,#c8c8c8);
    background-image:-o-linear-gradient(#ebebeb,#c8c8c8);
    background-image:linear-gradient(#ebebeb,#c8c8c8);
    -webkit-box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0;
    -moz-box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0;
    box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0
}
#notepads .notepad .header.hovered:not(.edit).clicked{
    background-image:-owg-linear-gradient(#c8c8c8,#ebebeb);
    background-image:-webkit-linear-gradient(#c8c8c8,#ebebeb);
    background-image:-moz-linear-gradient(#c8c8c8,#ebebeb);
    background-image:-o-linear-gradient(#c8c8c8,#ebebeb);
    background-image:linear-gradient(#c8c8c8,#ebebeb);
    -webkit-box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0;
    -moz-box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0;
    box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0
}
#notepads .notepad .header .verticalLine{
    display:inline-block;
    position:relative;
    top:-4px;
    height:6px;
    border-left:1px solid #e6c77c;
    border-right:1px solid #e6efb8
}
#notepads .notepad .header .dragExtender{
    position:absolute;
    top:-5px;
    left:0;
    width:100%;
    height:25px
}
#notepads .notepad .body{
    position:absolute;
    top:10px;
    bottom:10px;
    left:0;
    right:6px;
    z-index:0;
    word-wrap:break-word;
    cursor:default
}
#notepads .notepad .body .text{
    padding:10px;
    white-space:pre-wrap
}
#notepads .notepad .body .bbCodeInput{
    border:0;
    width:calc(100% - -6px)
}
#notepads .notepad .body .bbCodeInput .codes{
    position:absolute;
    top:0;
    left:0;
    white-space:nowrap;
    width:calc(100% - -6px)
}
#notepads .notepad .body .bbCodeInput textarea{
    position:absolute;
    margin:0;
    top:35px;
    left:0;
    box-sizing:border-box;
    width:calc(100% - -6px);
    height:calc(100% - 46px)
}
#notepads .notepad .body .bbCodeInput .previewWrapper{
    margin:0;
    top:35px;
    padding:5px 5px
}
#notepads .notepad .body.deleteOverlay{
    z-index:1;
    background-color:rgba(0,0,0,0.6);
    right:0;
    bottom:0;
    padding:10px;
    color:#fffff0;
    text-align:center;
    overflow:auto
}
#notepads .notepad .body.deleteOverlay .horizontalLine{
    margin:10px 0;
    border-top:1px solid #463f39;
    border-bottom:1px solid #7b746e
}
#notepads .notepad .footer{
    position:absolute;
    bottom:0;
    width:100%;
    height:10px;
    border-top:1px solid #f1e9b5
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {
		onNotepadClose(){
			//this.gameModel.notepads.show = false;
		},
	},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper reportSingle" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal reportSingle">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Отчёт</span></span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
				<div class="reportWithoutOverlay" id="reportSingle" ng-controller="reportSingleCtrl">
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" class="reportHeader" src="'tpl/report/header.html'"><div class="headerBox">
	<div class="reportHeaderBg reportTypes_reportType2 success2" ng-class="{relocation3: (header.notificationType == Notifications.REPORT_RELOCATION)}"></div>
	<!-- ngIf: header.notificationType != Notifications.REPORT_RELOCATION --><div class="playerBox actionFrom" ng-if="header.notificationType != Notifications.REPORT_RELOCATION">
		<avatar-image player-id="756"><!-- rerender: rerender --><div class="heroImage  male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="125" height="125"></canvas></div>
</avatar-image>
		<div class="playerLabel">
			<div class="playerAndKingdom">
				<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="756">pavlun</a>
				<!-- ngIf: header.sourceKingdomId && header.sourceKingdomId > 0 --><span ng-if="header.sourceKingdomId && header.sourceKingdomId > 0">(Roman)</span><!-- end ngIf: header.sourceKingdomId && header.sourceKingdomId > 0 -->
			</div>
			<div class="fromVillage">
				<span translate=""><span>из</span></span>
				<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537280506}, null, true)" village-link="" villagename="Основаград" villageid="537280506">Основаград</a>
				<i class="reportIcon reportTypes_reportIcon2_flat"></i>
			</div>
			<!-- ngIf: header.sourcePlayer && config.balancing.features.prestige --><div class="prestigeStars" ng-if="header.sourcePlayer && config.balancing.features.prestige">
				<div class="innerStars" stars="header.sourcePlayer.data.stars" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
		<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
			</div><!-- end ngIf: header.sourcePlayer && config.balancing.features.prestige -->
			<!-- ngIf: header.sourcePlayer.data.playerId == player.data.playerId && config.balancing.features.prestige -->
			<!-- ngIf: header.sourcePlayer.data.playerId != player.data.playerId && config.balancing.features.prestige --><div ng-if="header.sourcePlayer.data.playerId != player.data.playerId && config.balancing.features.prestige" class="prestigeStarsTooltip" tooltip="" tooltip-translate="Prestige.Stars.Tooltip.Other"></div><!-- end ngIf: header.sourcePlayer.data.playerId != player.data.playerId && config.balancing.features.prestige -->
		</div>
		<!-- ngIf: header.ownRole == 'attacker' && header.shareMessage -->
	</div><!-- end ngIf: header.notificationType != Notifications.REPORT_RELOCATION -->
	<!-- ngIf: resultIcon --><i class="resultIcon result_attackerWonWithLosses_huge_illu" ng-if="resultIcon"></i><!-- end ngIf: resultIcon -->
	<!--
		<i class="resultIcon result_spyAttackerWonWithoutLosses_huge_illu" ng-if="resultIcon"></i>
	-->
	<!-- ngIf: header.notificationType != Notifications.REPORT_RELOCATION --><div class="playerBox actionTo" ng-if="header.notificationType != Notifications.REPORT_RELOCATION">
		<avatar-image player-id="754"><!-- rerender: rerender --><div class="heroImage  male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="125" height="125"></canvas></div>
</avatar-image>
		<!-- ngIf: header.notificationType == Notifications.REPORT_FARMLIST_RAID -->
		<!-- ngIf: header.notificationType != Notifications.REPORT_FARMLIST_RAID && header.notificationType != Notifications.REPORT_ADVENTURE && header.notificationType != Notifications.REPORT_RELOCATION --><div ng-if="header.notificationType != Notifications.REPORT_FARMLIST_RAID && header.notificationType != Notifications.REPORT_ADVENTURE && header.notificationType != Notifications.REPORT_RELOCATION" class="playerLabel">
			<div class="playerAndKingdom">
				<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="754">Rington</a>
				<!-- ngIf: header.destKingdomId && header.destKingdomId > 0 -->
			</div>
			<div class="fromVillage">
				<span translate=""><span>из</span></span>
				<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537280508}, null, true)" village-link="" villagename="Rington" villageid="537280508">Rington</a>
				<!-- ngIf: header.defenderReportType --><i ng-if="header.defenderReportType" class="defenderReportIcon reportTypes_reportIcon6_flat" ng-class="{unknown: troopsDataDefender[0].unknown}"></i><!-- end ngIf: header.defenderReportType -->
			</div>
			<!-- ngIf: header.destPlayer && config.balancing.features.prestige --><div class="prestigeStars" ng-if="header.destPlayer && config.balancing.features.prestige">
				<div class="innerStars" stars="header.destPlayer.data.stars" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
		<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
			</div><!-- end ngIf: header.destPlayer && config.balancing.features.prestige -->
			<!-- ngIf: header.destPlayer.data.playerId == player.data.playerId && config.balancing.features.prestige -->
			<!-- ngIf: header.destPlayer.data.playerId != player.data.playerId && config.balancing.features.prestige --><div ng-if="header.destPlayer.data.playerId != player.data.playerId && config.balancing.features.prestige" class="prestigeStarsTooltip" tooltip="" tooltip-translate="Prestige.Stars.Tooltip.Other"></div><!-- end ngIf: header.destPlayer.data.playerId != player.data.playerId && config.balancing.features.prestige -->
		</div><!-- end ngIf: header.notificationType != Notifications.REPORT_FARMLIST_RAID && header.notificationType != Notifications.REPORT_ADVENTURE && header.notificationType != Notifications.REPORT_RELOCATION -->
		<!-- ngIf: header.notificationType == Notifications.REPORT_ADVENTURE -->

		<!-- ngIf: header.ownRole == 'defender' && header.shareMessage -->
	</div><!-- end ngIf: header.notificationType != Notifications.REPORT_RELOCATION -->
</div>
<div class="successBar">
	<!-- ngIf: header[header.ownRole+'TroopLossSum'] --><div ng-if="header[header.ownRole+'TroopLossSum']" class="lossBar" ng-style="{width: (header[header.ownRole+'TroopLossSum']*100/header[header.ownRole+'TroopSum'])+'%'}" style="width: 68.3673%;"></div><!-- end ngIf: header[header.ownRole+'TroopLossSum'] -->
	<!-- ngIf: body.hp || body.hp == 0 -->
</div>
<h6 class="functionHeader headerTrapezoidal">
	<div class="reportDate content">
		<span i18ndt="1707914113" format="shortDate">Сегодня</span> |
		<span i18ndt="1707914113" format="mediumTime">18:35:13</span>
	</div>

	<div class="controlPanel">
		<!-- ngIf: reportShareable() -->

		<div class="iconButton favorite clickable" ng-class="{active: isFavorite}" clickable="toggleFavorite()" content="Добавить в избранное" v-tippy="{placement: 'top'}" tooltip-translate-switch="{
				'Report.Tooltip.RemoveAsFavorite': false,
				'Report.Tooltip.MarkAsFavorite': false
			 }" tooltip-placement="above">
			<i class="report_favorite_small_flat_black"></i>
		</div>
		<!-- ngIf: (reportType == 'fightReport' || reportType == 'farmlistReport') && header.sourcePlayerId == player.data.playerId && header.sourceId > 0 -->

		<!-- ngIf: reportType == 'fightReport' && !troopsDataDefender[0].unknown && !troopsDataAttacker[0].unknown
			 		&& header.notificationType != Notifications.REPORT_FARMLIST_RAID && header.notificationType != Notifications.REPORT_WORLD_PEACE && header.notificationType != Notifications.REPORT_VACATION --><div class="iconButton simulator clickable" ng-if="reportType == 'fightReport' && !troopsDataDefender[0].unknown && !troopsDataAttacker[0].unknown
			 		&& header.notificationType != Notifications.REPORT_FARMLIST_RAID && header.notificationType != Notifications.REPORT_WORLD_PEACE && header.notificationType != Notifications.REPORT_VACATION" clickable="toSimulator()" content="Рассчитать в симуляторе" v-tippy="{placement: 'top'}" tooltip-translate="Report.ToSimulator" tooltip-placement="above">
			<i class="report_simulate_small_flat_black"></i>
		</div><!-- end ngIf: reportType == 'fightReport' && !troopsDataDefender[0].unknown && !troopsDataAttacker[0].unknown
			 		&& header.notificationType != Notifications.REPORT_FARMLIST_RAID && header.notificationType != Notifications.REPORT_WORLD_PEACE && header.notificationType != Notifications.REPORT_VACATION -->
	</div>
</h6>
</div>
		<!-- ngIf: reportType --><!-- ngInclude:  --><div ng-include="" ng-if="reportType" class="reportBody fightReport" src="'tpl/report/types/'+reportType+'.html'"><div class="troopsScrollContainer">
	
			<!-- ngIf: someInfoModules --><div class="infoContainer" ng-if="someInfoModules">
		<!-- ngIf: infoModules.loot || infoModules.spy --><div ng-if="infoModules.loot || infoModules.spy" class="resourcesModule">
			<!-- ngIf: infoModules.loot --><span ng-if="infoModules.loot" class="caption" translate=""><span>Добыча</span></span><!-- end ngIf: infoModules.loot -->
			<!-- ngIf: infoModules.spy && !infoModules.spy.noSurvivors -->
			<!-- ngIf: infoModules.loot --><span ng-if="infoModules.loot" class="loot">
				<i class="carry unit_capacity_small_flat_black" ng-class="{
				   		unit_capacityEmpty_small_flat_black: infoModules.loot.allBountySum == 0,
						unit_capacityHalf_small_flat_black: infoModules.loot.allBountySum > 0 && infoModules.loot.allBountySum < infoModules.loot.capacity,
						unit_capacity_small_flat_black: infoModules.loot.allBountySum == infoModules.loot.capacity
				   }" content="Загружены добычей: 100% (6440/6440)" v-tippy="{placement: 'top'}" tooltip-translate="Report.CarryCapacityTooltip" tooltip-placement="above" tooltip-data="percent:100,used:6440,max:6440"></i>
				6440/6440
			</span>

			<!-- ngIf: (infoModules.bounty && infoModules.loot.allBountySum > 0) || infoModules.spy.resources || infoModules.treasures || infoModules.stolenGoods || infoModules.spy.treasures || infoModules.stolenGoods --><div class="bountyContainer" ng-if="(infoModules.bounty && infoModules.loot.allBountySum > 0) || infoModules.spy.resources || infoModules.treasures || infoModules.stolenGoods || infoModules.spy.treasures || infoModules.stolenGoods">
				<span class="resources" content="<div class='above'>
	<div class='tooltipContent'>
		<table class='transparent lootTooltipTable'>
			<thead>
				<tr>
					<th><span>Склад</span></th>
					<th><span>Фонд дани</span></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td><i class='unit_wood_small_illu'></i> 813</td>
					<td ng-if='n!=4'><i class='unit_wood_small_illu'></i> 959</td>
				</tr>
				<tr>
					<td><i class='unit_clay_small_illu'></i> 35</td>
					<td ng-if='n!=4'><i class='unit_clay_small_illu'></i> 1106</td>
				</tr>
				<tr>
					<td><i class='unit_iron_small_illu'></i> 1533</td>
					<td ng-if='n!=4'><i class='unit_iron_small_illu'></i> 959</td>
				</tr>
				<tr>
					<td><i class='unit_crop_small_illu'></i> 1035</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>" v-tippy="{placement: 'top'}" tooltip-url="tpl/report/partials/lootTooltip.html" tooltip-show="{&quot;1&quot;:959,&quot;2&quot;:1106,&quot;3&quot;:959}" tooltip-placement="above">
					<!-- ngRepeat: n in []|range:1:4 --><span ng-repeat="n in []|range:1:4">
						<i class="unit_wood_small_illu"></i>
						1772
					</span><!-- end ngRepeat: n in []|range:1:4 --><span ng-repeat="n in []|range:1:4">
						<i class="unit_clay_small_illu"></i>
						1141
					</span><!-- end ngRepeat: n in []|range:1:4 --><span ng-repeat="n in []|range:1:4">
						<i class="unit_iron_small_illu"></i>
						2492
					</span><!-- end ngRepeat: n in []|range:1:4 --><span ng-repeat="n in []|range:1:4">
						<i class="unit_crop_small_illu"></i>
						1035
					</span><!-- end ngRepeat: n in []|range:1:4 -->
				</span>
				<!-- ngIf: infoModules.treasures || infoModules.spy.treasures -->
				<!-- ngIf: infoModules.stolenGoods || infoModules.stolenGoods -->
			</div><!-- end ngIf: (infoModules.bounty && infoModules.loot.allBountySum > 0) || infoModules.spy.resources || infoModules.treasures || infoModules.stolenGoods || infoModules.spy.treasures || infoModules.stolenGoods -->
		</div><!-- end ngIf: infoModules.loot || infoModules.spy -->
		<!-- ngIf: infoModules.spy.defence || infoModules.damage --><div class="buildingsModule" ng-if="infoModules.spy.defence || infoModules.damage">
			<span class="caption" translate=""><span>Здания</span></span>

			<div class="buildingsContainer">
				<!-- ngRepeat: building in infoModules.spy.defence track by $index -->
				<!-- ngRepeat: building in infoModules.damage track by $index --><!-- ngIf: infoModules.damage --><div ng-if="infoModules.damage" class="buildingInfo" ng-repeat="building in infoModules.damage track by $index">
					<span class="buildingLarge buildingType33 tribeId3" content="Изгородь" v-tippy="{placement: 'bottom'}" tooltip-translate="Building_33"></span>

					<div>10
						<span class="finalLevel">10</span></div>
				</div><!-- end ngIf: infoModules.damage --><!-- end ngRepeat: building in infoModules.damage track by $index -->
			</div>
		</div><!-- end ngIf: infoModules.spy.defence || infoModules.damage -->
		<!-- ngIf: infoModules.healed -->
		<!-- ngIf: infoModules.wounded --><div class="woundedModule borderedBox" ng-if="infoModules.wounded">
			<i class="movement_wounded_small_flat_black" content="<div class='troopsTooltip below'>
	<h3>
		<span>Общее количество раненых (во всех деревнях игрока </span>
		<a class='truncated playerLink truncated clickable disabled'>pavlun</a><span>)</span>
	</h3>
	<div class='horizontalLine'></div>
	<div>
		<table class='troopsTable gaul'>
			<tbody class='troopsIconRow'>
				<tr>
					<td class='unit1'>
						<i class='unitSmall gaul unitType1 inactive'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='unit2'>
						<i class='unitSmall gaul unitType2'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='unit3'>
						<i class='unitSmall gaul unitType3 inactive'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='unit4'>
						<i class='unitSmall gaul unitType4'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='unit5'>
						<i class='unitSmall gaul unitType5 inactive'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='unit6'>
						<i class='unitSmall gaul unitType6'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='unit7'>
						<i class='unitSmall gaul unitType7 inactive'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='unit8'>
						<i class='unitSmall gaul unitType8 inactive'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='unit9'>
						<i class='unitSmall gaul unitType9 inactive'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='unit10'>
						<i class='unitSmall gaul unitType10 inactive'></i>
						<div class='horizontalLine'></div>
					</td>
					<td class='hero'>
						<i class='unitSmall hero_illu inactive'></i>
						<div class='horizontalLine'></div>
					</td>
				</tr>
			</tbody>
			<tbody class='originalTroops'>
				<tr>
					<td class='unit1'>
						<span>-</span>
					</td>
					<td class='unit2'>
						<span>73</span>
					</td>
					<td class='unit3'>
						<span>-</span>
					</td>
					<td class='unit4'>
						<span>28</span>
					</td>
					<td class='unit5'>
						<span>-</span>
					</td>
					<td class='unit6'>
						<span>4</span>
					</td>
					<td class='unit7'>
						<span>-</span>
					</td>
					<td class='unit8'>
						<span>-</span>
					</td>
					<td class='unit9'>
						<span>-</span>
					</td>
					<td class='unit10'>
						<span>-</span>
					</td>
					<td class='hero'>
						<span>-</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>" v-tippy="{placement: 'top'}" tooltip-url="tpl/report/partials/woundedTooltip.html" tooltip-class="troopsTooltip"></i>
			<span translate="" data="amount:105"><span>Следующее количество раненых отправлены в Целебный шатер: 105.</span></span>
		</div><!-- end ngIf: infoModules.wounded -->
		<!-- ngIf: infoModules.infoTexts.length > 0 || infoModules.errorTexts.length > 0 ||
											(infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0) ||
											(infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0) --><div class="infoModule" ng-if="infoModules.infoTexts.length > 0 || infoModules.errorTexts.length > 0 ||
											(infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0) ||
											(infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0)">
			<span class="caption" translate=""><span>Информация</span></span>
			<table class="infoTable transparent">
				<tbody>
					<!-- ngIf: infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0 ||
								infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0 -->
					<!-- ngRepeat: infoText in infoModules.infoTexts --><tr ng-repeat="infoText in infoModules.infoTexts">
						<td>Атакующий освободил 49 ед. своих войск, из которых выжило 38.</td>
					</tr><!-- end ngRepeat: infoText in infoModules.infoTexts --><tr ng-repeat="infoText in infoModules.infoTexts">
						<td>Защитник сумел укрыть 300 ресурсов из фонда дани в скрытой сокровищнице.</td>
					</tr><!-- end ngRepeat: infoText in infoModules.infoTexts -->
					<!-- ngRepeat: errorText in infoModules.errorTexts -->
				</tbody>
			</table>
		</div><!-- end ngIf: infoModules.infoTexts.length > 0 || infoModules.errorTexts.length > 0 ||
											(infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0) ||
											(infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0) -->
	</div><!-- end ngIf: someInfoModules -->
	<div class="infoContainer" ng-if="someInfoModules">
		<!-- ngIf: infoModules.loot || infoModules.spy --><div ng-if="infoModules.loot || infoModules.spy" class="resourcesModule">
			<!-- ngIf: infoModules.loot -->
			<!-- ngIf: infoModules.spy && !infoModules.spy.noSurvivors --><span ng-if="infoModules.spy &amp;&amp; !infoModules.spy.noSurvivors" class="caption" translate=""><span>Отчет о разведке</span></span><!-- end ngIf: infoModules.spy && !infoModules.spy.noSurvivors -->
			<!-- ngIf: infoModules.loot -->

			<!-- ngIf: (infoModules.bounty && infoModules.loot.allBountySum > 0) || infoModules.spy.resources || infoModules.treasures || infoModules.stolenGoods || infoModules.spy.treasures || infoModules.stolenGoods --><div class="bountyContainer" ng-if="(infoModules.bounty &amp;&amp; infoModules.loot.allBountySum > 0) || infoModules.spy.resources || infoModules.treasures || infoModules.stolenGoods || infoModules.spy.treasures || infoModules.stolenGoods">
				<span class="resources" tooltip="" tooltip-url="tpl/report/partials/lootTooltip.html" tooltip-show="" tooltip-placement="above">
					<!-- ngRepeat: n in []|range:1:4 --><span ng-repeat="n in []|range:1:4">
						<i class="unit_wood_small_illu"></i>
						2450
					</span><!-- end ngRepeat: n in []|range:1:4 --><span ng-repeat="n in []|range:1:4">
						<i class="unit_clay_small_illu"></i>
						2306
					</span><!-- end ngRepeat: n in []|range:1:4 --><span ng-repeat="n in []|range:1:4">
						<i class="unit_iron_small_illu"></i>
						2898
					</span><!-- end ngRepeat: n in []|range:1:4 --><span ng-repeat="n in []|range:1:4">
						<i class="unit_crop_small_illu"></i>
						1364
					</span><!-- end ngRepeat: n in []|range:1:4 -->
				</span>
				<!-- ngIf: infoModules.treasures || infoModules.spy.treasures -->
				<!-- ngIf: infoModules.stolenGoods || infoModules.stolenGoods -->
			</div><!-- end ngIf: (infoModules.bounty && infoModules.loot.allBountySum > 0) || infoModules.spy.resources || infoModules.treasures || infoModules.stolenGoods || infoModules.spy.treasures || infoModules.stolenGoods -->
		</div><!-- end ngIf: infoModules.loot || infoModules.spy -->
		<!-- ngIf: infoModules.spy.defence || infoModules.damage -->
		<!-- ngIf: infoModules.healed -->
		<!-- ngIf: infoModules.wounded -->
		<!-- ngIf: infoModules.infoTexts.length > 0 || infoModules.errorTexts.length > 0 ||
											(infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0) ||
											(infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0) --><div class="infoModule" ng-if="infoModules.infoTexts.length > 0 || infoModules.errorTexts.length > 0 ||
											(infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0) ||
											(infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0)">
			<span class="caption" translate=""><span>Информация</span></span>
			<table class="infoTable transparent">
				<tbody>
					<!-- ngIf: infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0 ||
								infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0 --><tr ng-if="infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0 ||
								infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0">
						<td>
							<!-- ngIf: infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0 --><span ng-if="infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0" class="spyInfo" content="Тайник" v-tippy="{placement: 'top'}" tooltip-translate="Report.Spy.Cranny" tooltip-placement="above">
								<i class="building_g23_small_illu"></i> 1000
							</span><!-- end ngIf: infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0 -->
							<!-- ngIf: infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0 --><span ng-if="infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0" class="spyInfo" content="Скрытая сокровищница" v-tippy="{placement: 'top'}" tooltip-translate="Report.Spy.HiddenTreasury" tooltip-placement="above">
								<i class="building_g45_small_illu"></i> 100
							</span><!-- end ngIf: infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0 -->
						</td>
					</tr><!-- end ngIf: infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0 ||
								infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0 -->
					<!-- ngRepeat: infoText in infoModules.infoTexts -->
					<!-- ngRepeat: errorText in infoModules.errorTexts -->
				</tbody>
			</table>
		</div><!-- end ngIf: infoModules.infoTexts.length > 0 || infoModules.errorTexts.length > 0 ||
											(infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0) ||
											(infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0) -->
	</div>
	<!-- ngRepeat: troopDetails in troopsDataAttacker --><div class="troopsDetailContainer" ng-repeat="troopDetails in troopsDataAttacker">
		<div class="troopsDetailHeader fromHeader">
			<i class="reportIcon reportTypes_reportIcon2_flat" content="Тип нападения: Атака" v-tippy="{placement: 'top'}" tooltip-show="body.attackType" tooltip-translate="Report.AttackTypeTooltip" tooltip-data="type:movementType_3"></i>
			<span translate=""><span>Нападение</span></span>:
			<!-- ngIf: header.sourcePlayerId != 1 --><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="756" ng-if="header.sourcePlayerId != 1">pavlun</a><!-- end ngIf: header.sourcePlayerId != 1 -->
			<!-- ngIf: header.sourcePlayerId == 1 -->
			<span translate=""><span>из</span></span>
			<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537280506}, null, true)" village-link="" villageid="537280506" villagename="Основаград">Основаград</a>
			<!-- ngIf: header.sourceId > 2 --><div ng-if="header.sourceId > 2" class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '537280506_1707924935.8'); closeWindow(w.name);">
				<i class="symbol_target_small_flat_black"></i>
			</div><!-- end ngIf: header.sourceId > 2 -->
		</div>
		<div troops-details="" troop-data="troopDetails"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1 inactive" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;301&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;114&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;15&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;10&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="lostTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;183&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;69&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;10&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;6&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="trappedTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' --><tr ng-if="troopsType == 'trapped'" class="subHeader">
			<td colspan="11">
				<span><span translate=""><span>Поймано</span></span>:</span>
			</td>
		</tr><!-- end ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;34&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;13&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
	</div>
	<div class="troopsDetailContainer" ng-repeat="troopDetails in troopsDataAttacker">
		<div class="troopsDetailHeader fromHeader">
			<i class="reportIcon reportTypes_reportIcon15_flat" content="Тип нападения: Разведка" v-tippy="{placement: 'bottom'}" tooltip-show="body.attackType" tooltip-translate="Report.AttackTypeTooltip" tooltip-data="type:movementType_6"></i>
			<span translate=""><span>Нападение</span></span>:
			<!-- ngIf: header.sourcePlayerId != 1 --><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="629" ng-if="header.sourcePlayerId != 1"></a><!-- end ngIf: header.sourcePlayerId != 1 -->
			<!-- ngIf: header.sourcePlayerId == 1 -->
			<span translate=""><span>из</span></span>
			<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': }, null, true)" village-link="" villageid="536854511" villagename="Racoon city"></a>
			<!-- ngIf: header.sourceId > 2 --><div ng-if="header.sourceId > 2" class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '536854511_1707926872.3'); closeWindow(w.name);">
				<i class="symbol_target_small_flat_black"></i>
			</div><!-- end ngIf: header.sourceId > 2 -->
		</div>
		<div troops-details="" troop-data="troopDetails"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;28&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
	</div><!-- end ngRepeat: troopDetails in troopsDataAttacker -->
	<!-- ngRepeat: troopDetails in troopsDataDefender | orderBy:['type', 'tribeId'] --><!-- ngIf: troopDetails.type == 'defender' || (!showTribeDetails[troopDetails.tribeId] && showTribeDetails[troopDetails.tribeId] !== false) ||
				troopDetails.isGroup && !showTribeDetails[troopDetails.tribeId] || !troopDetails.isGroup && showTribeDetails[troopDetails.tribeId] --><div class="troopsDetailContainer" ng-repeat="troopDetails in troopsDataDefender | orderBy:['type', 'tribeId']" ng-if="troopDetails.type == 'defender' || (!showTribeDetails[troopDetails.tribeId] && showTribeDetails[troopDetails.tribeId] !== false) ||
				troopDetails.isGroup && !showTribeDetails[troopDetails.tribeId] || !troopDetails.isGroup && showTribeDetails[troopDetails.tribeId]">
		<div class="troopsDetailHeader toHeader">
			<i class="reportIcon reportTypes_reportIcon6_flat" ng-class="{unknown: troopDetails['unknown']}"></i>
			<!-- ngIf: troopDetails.type == 'defender' --><span ng-if="troopDetails.type == 'defender'"><span translate=""><span>Оборона</span></span>:</span><!-- end ngIf: troopDetails.type == 'defender' -->
			<!-- ngIf: troopDetails.type == 'supporter' -->
			<!-- ngIf: troopDetails.playerId --><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" ng-if="troopDetails.playerId" playerid="754">Rington</a><!-- end ngIf: troopDetails.playerId -->
			<!-- ngIf: !troopDetails.playerId -->
			<!-- ngIf: troopDetails.type == 'defender' --><span ng-if="troopDetails.type == 'defender'">
				<span translate=""><span>из</span></span>
				<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537280508}, null, true)" village-link="" villageid="537280508" villagename="Rington">Rington</a>
			</span><!-- end ngIf: troopDetails.type == 'defender' -->
			<!-- ngIf: troopDetails.type == 'defender' && troopDetails.villageId > 2 --><div ng-if="troopDetails.type == 'defender' && troopDetails.villageId > 2" class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '537280508_1707924935.8'); closeWindow(w.name);">
				<i class="symbol_target_small_flat_black"></i>
			</div><!-- end ngIf: troopDetails.type == 'defender' && troopDetails.villageId > 2 -->
		</div>
		<div troops-details="" troop-data="troopDetails"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;256&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;88&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;15&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;18&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="lostTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;256&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;88&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;15&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;18&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
		<!-- ngIf: troopDetails.isGroup -->
	</div>
	<div class="troopsDetailContainer" ng-repeat="troopDetails in troopsDataSummary">
		<div class="troopsDetailHeader toHeader">
			<i class="reportIcon reportTypes_reportIcon6_flat"></i>
			<span translate=""><span>Подкрепление</span></span>:
			<span class="tribeLabel" translate="" options="3"><span>Галлы</span></span>
		</div>
		<div troops-details="" troop-data="troopDetails"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;180&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
	</div><!-- end ngIf: troopDetails.type == 'defender' || (!showTribeDetails[troopDetails.tribeId] && showTribeDetails[troopDetails.tribeId] !== false) ||
				troopDetails.isGroup && !showTribeDetails[troopDetails.tribeId] || !troopDetails.isGroup && showTribeDetails[troopDetails.tribeId] --><!-- end ngRepeat: troopDetails in troopsDataDefender | orderBy:['type', 'tribeId'] -->

	<!-- ngRepeat: troopDetails in troopsDataSummary -->
</div>
<!-- ngIf: header.displayType == Report.DISPLAY_TYPE_PEACE -->
<!-- ngIf: header.displayType == Report.DISPLAY_TYPE_VACATION -->

</div><!-- end ngIf: reportType -->
	</div>
</div>

			</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Отчёт | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
<template>
	<div ng-if="overlayController" class="windowOverlay" id="useItem" ng-controller="overlayController">
		<div class="darkener clickable" clickable="closeOverlay()"></div>
		<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="659">
			<div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
				<div class="inWindowPopupHeader">
					<h4 translate="" options="Hero.ItemEnterAmount" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Ввести количество</span></h4>
					<h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4>
					<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
				</div>
				<div class="inWindowPopupContent">
					<div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']">
						<div class="useItem">
							<!-- ngIf: isHealingPotion -->
							<div ng-if="!isHealingPotion" class="itemContainer">
								<hero-item-container item="item" hide-amount="true" hide-item-states="true">
									<div class="entityBox item item_113 noClick" ng-class="{disabled: !itemObject.filled || isDisabled(), noClick: !itemObject.filled || isDisabled() || !clickHandler, equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState, highlight: highlighted(), premiumItem: itemObject.data.premiumItem, empty: !itemObject.filled}" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:55361,notUsableItem:,notUsableReason:,auctionItem:false,nextUpgradeLevel:false,itemType:113" tooltip-show="true">
										<i class="dragMarker ng-hide" ng-show="dragObject"></i>
										<img class="heroItem_scroll_large_illu male" src="layout/images/x.gif" data-cmp-info="10" />
										<!-- ngIf: isDisabled() -->
										<!-- ngIf: !hideStates -->
										<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
											<div class="amount">
												<i class="upgrade_upgrade_tiny_flat_black"></i>
											</div>
										</div>
										<!-- ngIf: !hideAmountContainer -->
									</div>
								</hero-item-container>
								<div class="verticalLine"></div>
								<!-- ngIf: ::isArtwork -->
								<!-- ngIf: ::!useText && !stackable -->
								<div ng-if="::!useText && stackable" class="confirmation description" translate=""><span>Сколько предметов ты хочешь использовать?</span></div>
								<!-- ngIf: ::useText -->
							</div>
							<div ng-if="stackable">
								<div class="horizontalLine"></div>
								<!-- ngIf: treasurePrice -->
								<slider ng-if="stackable && !treasuresSold" class="confirmation" slider-min="1" slider-max="maxAmount" slider-data="useAmount" input-autofocus="true">
									<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
										<div class="trackContainer">
											<div class="track">
												<!-- ngIf: stepCount && !hideSteps -->
												<div class="sliderMarker progressMarker" style="width: 100%;"></div>
												<!-- ngRepeat: marker in sliderMarkers track by $index -->
												<div class="hoverIndicator" style="display: none; left: 327px;">
													<div class="hoverValue">82</div>
													<div class="indicator"></div>
												</div>
												<div class="handle unselectable" style="left: 445px;"></div>
											</div>
										</div>
										<div class="inputContainer">
											<!-- ngIf: iconClass -->
											<input class="value ng-pristine ng-valid ng-touched" number="" ng-model="value" type="tel">
											<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: block;">
												<i class="action_setMax_small_flat_black"></i>
											</div>
										</div>
									</div>
								</slider>
								<!-- ngIf: treasuresSold -->
							</div>
							<div class="buttonBar">
								<span ng-if="!treasuresSold">
									<button ng-if="!treasurePrice" clickable="useItem()" class="useItemButton clickable">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span ng-if="::!useButtonText" data="ItemName:Свиток" translate=""><span>Использовать: Свиток</span></span>
												<!-- ngIf: ::useButtonText -->
											</span>
											<!-- ngIf: price > 0 || forceGoldUsage -->
											<!-- ngIf: price == -1 && !forceGoldUsage -->
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<!-- ngIf: treasurePrice -->
									<button clickable="closeOverlay()" class="cancel clickable">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Отмена</span></span>
											</span>
											<!-- ngIf: price > 0 || forceGoldUsage -->
											<!-- ngIf: price == -1 && !forceGoldUsage -->
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
								</span>
								<!-- ngIf: treasuresSold -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
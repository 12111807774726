<template>
	<div id="sidebarMenu" class="sidebar unselectable">
		<div class="communitiesButtonWrapper" id="jsQuestButtonCommunities" content="Твой альянс" v-tippy="{placement: 'right'}">
			<div class="mainLayoutMenuButton directionDown communitiesButton clickable" @click="$router.push({path: '/society', query: {tab: 'kingdom'}})">
				<i class="sideMenu_communities_medium_illu"></i>
			</div>
		</div>
		<div class="treasureCount" id="jsQuestTreasureCount" v-if="gameModel.player.kingdomId > 0" content="<h3><span>Твой альянс</span></h3><div class='horizontalLine'></div><table class='transparent'><tbody><tr><th><span><span>Сокровища</span></span>:</th><td>&#8237;&#8237;13508&#8236;&#8236;</td></tr><tr><th><span><span>За эту неделю</span></span>:</th><td>&#8237;+&#8237;482&#8236;&#8236;</td></tr><tr v-if='gameModel.player.kingdomId > 0'><th><span><span>Победные очки</span></span>:</th><td>&#8237;&#8237;0&#8236;&#8236;</td></tr></tbody></table>" v-tippy="{placement: 'right'}">
		<div class="backgroundStart sideMenu_treasureBackgroundStart_layout"></div>
			<div class="content">
				<i class="unit_treasure_small_flat_black"></i>
				&#8237;&#8237;13508&#8236;&#8236;
			</div>
			<div class="backgroundEnd sideMenu_treasureBackgroundEnd_layout"></div>
		</div>
		<div class="mainLayoutMenuButton directionDown withArrowTip communityAttacksButton clickable" id="jsQuestButtonCommunityAttacks" content="Атаки на альянс" v-tippy="{placement: 'right'}" @click="$router.push({path: '/society', query: {tab: 'attacks'}})">
			<div class="arrowEnding"></div>
			<i class="sideMenu_communityAttacks_small_flat_black"></i>
			<div ng-if="attacksCounter != 0 && attacksCounter <= attackLimit" class="notificationCount">1</div>
		</div>
		<div class="mainLayoutMenuButton directionDown withArrowTip clickable" id="jsQuestButtonQuestbook" content="Книга заданий" v-tippy="{placement: 'right'}" @click="$router.push({path: '/quest-book'})">
			<div class="arrowEnding"></div>
			<i class="sideMenu_questBook_small_flat_black"></i>
			<div class="notificationCount" v-if="questNotificationCount > 0">{{questNotificationCount}}</div>
		</div>
		<div class="mainLayoutMenuButton directionDown withArrowTip clickable" id="jsQuestButtonStatistics" content="Статистика" v-tippy="{placement: 'right'}" @click="$router.push({path: '/statistics'})">
			<div class="arrowEnding"></div>
			<i class="sideMenu_statistics_small_flat_black"></i>
		</div>
		<div :class="['mainLayoutMenuButton', 'directionDown', 'withArrowTip', 'clickable', {'highlighted': gameModel.SidebarMenu.chat.hasUnreadEntries, 'showAnimation': gameModel.SidebarMenu.animateIgm}]" id="jsQuestButtonIgm" content="Беседы" v-tippy="{placement: 'right'}" @click="$router.push({path: '/igm'})">
			<div class="arrowEnding"></div>
			<i class="sideMenu_igm_small_flat_black"></i>
			<div class="notificationCount">1</div>
		</div>
	</div>
</template>

<style lang="css">
#sidebarMenu{
    position:absolute;
    top:70px;
    left:0;
    z-index:10;
    padding-top:43px
}
#sidebarMenu .mainLayoutMenuButton{
    margin-top:9px
}
#sidebarMenu .mainLayoutMenuButton i{
    top:2px
}
#sidebarMenu .mainLayoutMenuButton.communityAttacksButton{
    height:27px
}
#sidebarMenu .mainLayoutMenuButton.communityAttacksButton i{
    top:5px
}
#sidebarMenu .mainLayoutMenuButton.communityAttacksButton .notificationCount{
    background-color:#d40000;
    color:#fff
}
#sidebarMenu .mainLayoutMenuButton.highlighted.showAnimation{
    animation:igmAnimation 1s linear infinite;
    -webkit-animation:igmAnimation 1s linear infinite;
    -moz-animation:igmAnimation 1s linear infinite
}
#sidebarMenu .mainLayoutMenuButton .notificationCount{
    position:absolute;
    right:-18px;
    top:-4px;
    height:14px;
    width:25px;
    font-size:.76923rem;
    line-height:14px;
    text-align:center;
    font-weight:normal;
    background-color:#fff;
    color:#252525;
    -webkit-border-radius:3px 3px 3px 0;
    -moz-border-radius:3px 3px 3px 0;
    -ms-border-radius:3px 3px 3px 0;
    -o-border-radius:3px 3px 3px 0;
    border-radius:3px 3px 3px 0;
    -webkit-box-shadow:0 0 3px rgba(0,0,0,0.75);
    -moz-box-shadow:0 0 3px rgba(0,0,0,0.75);
    box-shadow:0 0 3px rgba(0,0,0,0.75);
    z-index:2
}
#sidebarMenu .communitiesButtonWrapper{
    position:absolute;
    top:0;
    left:0;
    z-index:2;
    width:50px;
    height:50px;
    border:1px solid #e6c77c;
    background-image:-owg-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:-webkit-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:-moz-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:-o-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:linear-gradient(to bottom,#e6c77c,#fff);
    -webkit-border-radius:0 50% 50% 0;
    -moz-border-radius:0 50% 50% 0;
    -ms-border-radius:0 50% 50% 0;
    -o-border-radius:0 50% 50% 0;
    border-radius:0 50% 50% 0
}
#sidebarMenu .communitiesButtonWrapper .communitiesButton{
    width:42px;
    height:42px;
    position:relative;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    border-radius:50%;
    -webkit-box-shadow:0 0 3px rgba(0,0,0,0.75);
    -moz-box-shadow:0 0 3px rgba(0,0,0,0.75);
    box-shadow:0 0 3px rgba(0,0,0,0.75);
    margin:4px 2px 0;
    z-index:2
}
#sidebarMenu .communitiesButtonWrapper .communitiesButton i{
    top:6px
}
#sidebarMenu .treasureCount{
    position:absolute;
    top:0;
    left:39px;
    height:44px;
    padding:0 17px 0 20px
}
#sidebarMenu .treasureCount:after{
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0
}
#sidebarMenu .treasureCount .backgroundStart,#sidebarMenu .treasureCount .backgroundEnd,#sidebarMenu .treasureCount .content{
    float:left;
    position:absolute;
    top:0
}
#sidebarMenu .treasureCount .backgroundStart{
    left:0
}
#sidebarMenu .treasureCount .backgroundEnd{
    right:0
}
#sidebarMenu .treasureCount .content{
    position:relative;
    z-index:2;
    background-color:rgba(241,240,238,0.7);
    -webkit-box-shadow:inset 0 2px 2px -1px rgba(255,255,255,0.75),inset 0 -2px 2px -1px rgba(255,255,255,0.75);
    -moz-box-shadow:inset 0 2px 2px -1px rgba(255,255,255,0.75),inset 0 -2px 2px -1px rgba(255,255,255,0.75);
    box-shadow:inset 0 2px 2px -1px rgba(255,255,255,0.75),inset 0 -2px 2px -1px rgba(255,255,255,0.75);
    min-width:40px;
    line-height:35px;
    padding-top:5px;
    padding-right:5px;
    font-weight:bold;
    text-align:left;
    white-space:nowrap
}
#sidebarMenu .treasureCount .content:after{
    content:'';
    position:absolute;
    bottom:-6px;
    left:0;
    right:0;
    height:6px;
    -webkit-box-shadow:inset 0 4px 3px -3px rgba(70,63,57,0.65);
    -moz-box-shadow:inset 0 4px 3px -3px rgba(70,63,57,0.65);
    box-shadow:inset 0 4px 3px -3px rgba(70,63,57,0.65)
}
#sidebarMenu .treasureCount .content i{
    margin-right:5px
}
</style>

<script>
import lib from '@/lib';

import QuestsModel from '@/models/game/QuestsModel';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {
		questNotificationCount(){
			let openQuests = this.gameModel.questBook.openQuests.filter((el) => [QuestsModel.STATUS_ACTIVATABLE, QuestsModel.STATUS_DONE].includes(el.status));
			let dailyQuests = this.gameModel.questBook.dailyQuests.filter((el) => [QuestsModel.STATUS_ACTIVATABLE, QuestsModel.STATUS_DONE].includes(el.status));
			return openQuests.length + dailyQuests.length;
		},
	},
	methods: {},
	components: {},
}
</script>
<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs">
		<div class="modalWrapper building" style="opacity: 1; align-items: center; justify-content: center; display: flex; width: 100%; height: 100%;">
			<div class="modal building" style="top: 0;">
				<div class="modalContent">
					<div class="modalDecoration"></div>
					<div class="functionPanel unselectable" v-if="closeable">
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="close">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
					</div>
					<div class="contentHeader unselectable">
						<div>
							<h2 class="msAntiScroll drag" draggable="true">
								<span class="building"><span>Главное здание</span></span>
								<span class="level">
									(<span><span>Уровень 5</span></span>)
								</span>
							</h2>
							<div class="buildingDescription">
								<span>Чем выше уровень главного здания, тем меньше времени будут строиться другие здания.</span>
							</div>
							<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
						</div>
						<span class="buildingStatusButton type_15">
							<div class="buildingStatus location27 optionMenu">
								<div class="buildingBubble clickable disabledHover">
									<div class="colorLayer notNow enoughRes">
										<div class="labelLayer">
											<span class="buildingLevel">5</span>
										</div>
									</div>
								</div>
							</div>
						</span>
					</div>
					<div class="heightTransitionContent" style="">
						<div class="contentTabulation">
							<div class="tabulationContent scrollable tabContent">
								<div class="scrollContentOuterWrapper" style="width: 610px;">
									<div class="scrollContent" style="width: 610px; overflow-y: auto; max-height: 451px;">
										<div class="scrollContentInnerWrapper" style="width: auto;">
											<div class="contentBox gradient heroReviveBox">
												<h6 class="contentBoxHeader headerWithIcon arrowDirectionDown">
													<i class="attribute_dead_medium_flat_black"></i>
													<div class="content"><span>Герой погиб.</span></div>
												</h6>
												<div class="contentBoxBody heroRevive">
													<div class="reviveText">
														<span><span>Герой восстанавливается в деревне Inzectorsk.</span></span>
													</div>
													<div class="revivalDuration">
														<i class="symbol_clock_small_flat_black duration"></i>
														<span>00:52:04</span>
													</div>
												</div>
											</div>
											<div>
												<div class="building buildingType15">
													<div>
														<div class="buildingDetails mainBuilding">
															<div class="editVillageName contentBox gradient">
																<h6 class="contentBoxHeader headerWithIcon arrowDirectionDown">
																	<i class="village_village_medium_flat_black"></i>
																	<div class="content"><span>Деревня</span></div>
																</h6>
																<div class="contentBoxBody">
																	<input type="text" maxlength="20" class="ng-pristine ng-valid ng-valid-maxlength ng-touched" />
																	<button class="animate clickable disabled disableAnimation">
																		<div class="content">
																			<span class="innerText">
																				<span><span>Сохранить</span></span>
																			</span>
																			<div class="animationOverlay"></div>
																			<i class="checkmark action_check_small_flat_white"></i>
																			<i class="checkmark action_check_small_flat_black"></i>
																			<i class="checkmark action_check_small_flat_red"></i>
																		</div>
																	</button>
																</div>
															</div>
															<div class="foundTown contentBox gradient">
																<h6 class="contentBoxHeader headerWithIcon arrowDirectionDown">
																	<i class="village_city_medium_flat_black"></i>
																	<div class="content"><span>Основать город</span></div>
																</h6>
																<div class="contentBoxBody">
																	<table class="townConditionTable transparent">
																		<tbody>
																			<tr>
																				<th>
																					<i class="unit_culturePoint_small_illu"></i>
																					<span><span>Единицы культуры</span></span>
																				</th>
																				<th>
																					<i class="unit_population_small_illu"></i>
																					<span><span>Население</span></span>
																				</th>
																			</tr>
																			<tr>
																				<td>
																					<span class="currentValue">102</span>
																					<span><span>из</span></span>
																					<span>1000</span>
																				</td>
																				<td>
																					<span class="currentValue">91</span>
																					<span><span>из</span></span>
																					<span>500</span>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																	<table class="townUpgradeTable transparent">
																		<tbody>
																			<tr>
																				<td class="doubleCol">
																					<div class="buildingDescription">
																						<span><span>Город даст следующие преимущества:</span></span>
																						<br/><br/>
																						<span>
																							<ul class="uList">
																								<li> +500 единиц культуры в день</li>
																								<li> +500 жителей</li>
																								<li>Возможность построить ров для защиты</li>
																								<li>Возможность построить большие казарму и конюшню</li>
																								<li>Повышенное одобрение 200% </li>
																								<li>Возможность улучшать поля до 12 уровня</li>
																							</ul>
																						</span>
																					</div>
																				</td>
																				<td>
																					<button class="animate clickable disabled">
																						<div class="content">
																							<span class="innerText">
																								<span><span>Основать город</span></span>
																							</span>
																							<div class="animationOverlay"></div>
																							<i class="checkmark action_check_small_flat_white"></i>
																							<i class="checkmark action_check_small_flat_black"></i>
																							<i class="checkmark action_check_small_flat_red"></i>
																						</div>
																					</button>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
															<div class="demolish contentBox gradient">
																<h6 class="contentBoxHeader headerWithIcon arrowDirectionDown">
																	<i class="action_demolish_medium_flat_black"></i>
																	<div class="content"><span>Снести здание</span></div>
																</h6>
																<div class="contentBoxBody">
																	<table class="demolishTable transparent">
																		<tbody>
																			<tr>
																				<td class="doubleCol">
																					<div class="condition">
																						<span>Доступно с уровня 10</span>
																					</div>
																				</td>
																				<td>
																					<button class="clickable disabled">
																						<div class="content">
																							<span class="innerText">
																								<span><span>Снести</span></span>
																							</span>
																							<div class="animationOverlay"></div>
																							<i class="checkmark action_check_small_flat_white"></i>
																							<i class="checkmark action_check_small_flat_black"></i>
																							<i class="checkmark action_check_small_flat_red"></i>
																						</div>
																					</button>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="scrollTrack" style="display: none; opacity: 0;">
									<div class="scrollHandle" style="top: 0px;"></div>
									<div class="trackDecoration"></div>
								</div>
							</div>
						</div>
					</div>
					<!--<div class="window-overlay"></div>-->
				</div>
			</div>
		</div>
	</vue-final-modal>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		closeable: {
			type: Boolean,
			default: true,
		},
	},
	data: () => ({
		cancelHover: false,
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
	},
	mounted(){
		this.onModalOpen();
	},
	beforeDestroy(){
		this.onModalClose();
	},
	components: {},
}
</script>
<template>
	<div ng-controller="unitsTrainCtrl">
		<div ng-include="" src="'tpl/building/partials/lists/units.html'">
			<div class="contentBox gradient unitList">
				<div class="contentBoxBody">
					<carousel-unit-train :items="units" @on-active-item="(item) => activeItem = item" :cols="4" style="padding-top: 12px;" />
					<div v-if="activeItem">
						<table class="unitInfo transparent">
							<tbody>
								<tr>
									<td>
										<div class="unitHeader">
											<i class="unitIcon unitMedium gaul" :class="['unitType'+activeItem.type]" unit-icon="" big="true"></i>
											<h4 class="unitName">
												<span><span>{{activeItem.name}}</span></span>
												<span class="level" v-if="activeItem.unitLevel >= 0">
													<span><span>&nbsp;Ур.</span></span> {{activeItem.unitLevel}}
												</span>
											</h4>
											<div class="symbol_i_medium_wrapper">
												<i class="symbol_i_medium_flat_white clickable" clickable="openWindow('help', {'pageId': 'Troops_' + activeItem.unitType})" content="Дополнительная информация" v-tippy="{placement: 'top'}" tooltip-translate="Units.Tooltip.UnitInfo" tooltip-placement="above"></i>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<display-resources v-if="activeItem" class="costsFooter" :resources="allCosts" :hide-zero="true" available="storage" :time="activeItem.duration * activeItem.value" />
						<div class="sliderRow">
							<div class="sliderArrow"></div>
							<range-slider v-if="activeItem.limit <= 0" :min="1" :max="1" v-model.number="activeItem.value" disabled style="width: 87%;" />
							<range-slider v-if="activeItem.limit > 0" :min="1" :max="activeItem.limit" v-model.number="activeItem.value" style="width: 87%;" />
							<npc-trader-button type="unitTrain" :costs="allCosts" :current-max="0" :current-limit="0" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<button class="animate footerButton clickable disabled" ng-class="{disabled: !canTrainAll}" clickable="healAll()" content="В армии нет раненых солдат / Исцелить всех раненых солдат" v-tippy="{placement: 'top'}" tooltip-translate-switch="{
			'HealingTent.Button.HealAll.Tooltip': false,
			'HealingTent.Button.HealAll.Tooltip.NoWoundedTroops': true,
			'HealingTent.Button.HealAll.Tooltip.NotEnoughResources': true
		}">
			<div class="content">
				<span class="innerText"><span><span>Исцелить всех</span></span></span>
				<!-- ngIf: price > 0 || forceGoldUsage -->
				<!-- ngIf: price == -1 && !forceGoldUsage -->
				<div class="animationOverlay"></div>
				<i class="checkmark action_check_small_flat_white"></i>
				<i class="checkmark action_check_small_flat_black"></i>
				<i class="checkmark action_check_small_flat_red"></i>
			</div>
		</button>
		<button ng-class="{disabled: allValue <= 0 || activeItem.maxAvailable == 0 || player.isOnVacation()}" class="animate footerButton clickable disabled" clickable="startTraining(activeItem)" content="У вас нет раненых солдат / ''" v-tippy="{placement: 'top'}" tooltip-translate-switch="{
			'HealingTent.Button.Heal.Tooltip.Vacation': false,
			'HealingTent.Button.Heal.Tooltip.NoWoundedTroops': true,
			'Error.NotEnoughRes': true
		}" play-on-click="12">
			<div class="content">
				<span class="innerText">
					<span><span>Исцелить</span></span>
				</span>
				<!-- ngIf: price > 0 || forceGoldUsage -->
				<!-- ngIf: price == -1 && !forceGoldUsage -->
				<div class="animationOverlay"></div>
				<i class="checkmark action_check_small_flat_white"></i>
				<i class="checkmark action_check_small_flat_black"></i>
				<i class="checkmark action_check_small_flat_red"></i>
			</div>
		</button>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import CarouselUnitTrain from '@/components/elements/CarouselUnitTrain';
import DisplayResources from '@/components/elements/DisplayResources';
import NpcTraderButton from '@/components/elements/NpcTraderButton';
import RangeSlider from '@/components/elements/RangeSlider';

export default {
	mixins: lib.mixins,
	data: () => ({
		units: [
			{
				type: 1,
				name: 'Фаланга',
				disabled: false,
				queue: {
					count: 0,
					pause: 0,
				},
				costs: [
					43,
					50,
					45,
					0,
				],
				value: 1,
				limit: 10,
				unitLevel: 7,
				duration: 294,
				time: 1723643172,
			},
			{
				type: 2,
				name: 'Мечник',
				disabled: false,
				queue: {
					count: 0,
					pause: 0,
				},
				costs: [
					48,
					30,
					70,
					0,
				],
				value: 1,
				limit: 10,
				unitLevel: 5,
				duration: 407,
				time: 1723643172,
			},
			{
				type: 3,
				name: 'Следопыт',
				disabled: false,
				queue: {
					count: 0,
					pause: 0,
				},
				costs: [
					70,
					55,
					10,
					0,
				],
				value: 1,
				limit: 10,
				unitLevel: 3,
				duration: 385,
				time: 1723643172,
			},
			{
				type: 4,
				name: 'Тевтатский гром',
				disabled: false,
				queue: {
					count: 0,
					pause: 0,
				},
				costs: [
					100,
					140,
					65,
					0,
				],
				value: 1,
				limit: 10,
				unitLevel: 5,
				duration: 701,
				time: 1723643172,
			},
			{
				type: 5,
				name: 'Друид-всадник',
				disabled: false,
				queue: {
					count: 0,
					pause: 0,
				},
				costs: [
					150,
					135,
					95,
					0,
				],
				value: 1,
				limit: 10,
				unitLevel: 6,
				duration: 724,
				time: 1723643172,
			},
			{
				type: 6,
				name: 'Эдуйская конница',
				disabled: true,
				queue: {
					count: 0,
					pause: 0,
				},
				costs: [
					150,
					190,
					220,
					0,
				],
				value: 1,
				limit: 0,
				unitLevel: 3,
				duration: 882,
				time: 1723643172,
			},
		],
		
		activeItem: null,
	}),
	computed: {
		allCosts(){
			let unitsCosts = [];
			if(this.activeItem){
				for(let [index, value] of Object.entries(this.activeItem.costs)){
					unitsCosts[index] = value * this.activeItem.value;
				}
			}
			return unitsCosts;
		},
		allConsumption(){
			if(this.activeItem){
				return this.activeItem.cropConsumption * this.activeItem.value;
			}
			return 0;
		},
	},
	methods: {},
	components: {
		CarouselUnitTrain,
		DisplayResources,
		NpcTraderButton,
		RangeSlider,
	},
}
</script>
<template>
	<div class="buildingQueue">
		<div class="centerArea unselectable">
			<div v-if="gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_DESTRUCTION].length > 0" class="destructionQueueContainer queueContainer destructionQueueDragOut">
				<div class="buildingQueueSlot entityBox noActive" @mouseleave="onHideDetails()" @mouseover="onShowDetails(BuildingQueueModel.TYPE_DESTRUCTION, 0)">
					<div class="buildingSlotImage buildingMini" :class="['tribeId'+gameModel.player.tribeId, 'level'+gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_DESTRUCTION][0].lvl, 'buildingType'+gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_DESTRUCTION][0].buildingType]">
						<div class="levelBubble">{{gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_DESTRUCTION][0].lvl}}</div>
					</div>
					<div class="progressbar" type="negative" finish-time="1674320181" duration="65">
						<div class="progress">
							<div class="bar negative perc" style="z-index: 2; width: 34.7%;"></div>
							<div class="bar additionalBar additionalPerc"></div>
							<div class="marker">
								<div class="markerInner"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="buildingQueueContainer queueContainer buildingQueueDragOut">
				<div class="constructionContainer">
					<div v-for="queueType in [BuildingQueueModel.TYPE_VILLAGE, BuildingQueueModel.TYPE_RESOURCES]" v-show="gameModel.buildingQueue.queues[queueType].length > 0 || gameModel.player.tribeId == PlayerModel.TRIBE_ROMAN || (gameModel.player.tribeId != PlayerModel.TRIBE_ROMAN && queueType == BuildingQueueModel.TYPE_VILLAGE && gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_RESOURCES].length == 0)" class="buildingQueueSlot entityBox noActive" :class="{
						'largeSlot': gameModel.player.tribeId != PlayerModel.TRIBE_ROMAN,
						'hover': gameModel.buildingQueue.activeSlot == queueType,
						'paid': gameModel.buildingQueue.queues[queueType].length > 0,
					}" @mouseleave="onHideDetails()" @mouseover="onShowDetails(queueType, 0)">
						<i v-if="gameModel.buildingQueue.queues[queueType].length == 0" class="slotIcon boxIcon builderIcon feature_buildingqueue_slot_combine_medium_flat_black" :class="['queueType'+queueType]"></i>
						<div v-if="gameModel.buildingQueue.queues[queueType].length > 0" class="progressbar" finish-time="1723625343" duration="18900">
							<div class="progress">
								<div class="bar positive perc" style="z-index: 2; width: 69.6%;"></div>
								<div class="bar additionalBar additionalPerc"></div>
								<div class="marker">
									<div class="markerInner"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="masterBuilderContainer">
					<div v-for="slotNr in range(1, BuildingQueueModel.MASTER_BUILDER_SLOTS)" class="buildingQueueSlot entityBox" :class="['slot'+slotNr, {
						'hover': (gameModel.buildingQueue.activeSlot.slotHover == BuildingQueueModel.TYPE_MASTER_BUILDER || gameModel.buildingQueue.activeSlot.detailHover == BuildingQueueModel.TYPE_MASTER_BUILDER) && (gameModel.buildingQueue.activeSlot.slot == slotNr || gameModel.buildingQueue.activeSlot.slotDetail == slotNr),
				 		'paid': gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_MASTER_BUILDER][slotNr - 1] && gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_MASTER_BUILDER][slotNr - 1].paid,
				 		'locked': gameModel.buildingQueue.availableMasterBuilderSlots < slotNr,
				 		'empty': gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_MASTER_BUILDER].length < slotNr,
				 		'noActive': (gameModel.buildingQueue.availableMasterBuilderSlots < slotNr || gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_MASTER_BUILDER].length < slotNr),
					}]" @mouseleave="onHideDetails()" @mouseover="onShowDetails(BuildingQueueModel.TYPE_MASTER_BUILDER, slotNr)">
						<div v-if="gameModel.buildingQueue.queues[BuildingQueueModel.TYPE_MASTER_BUILDER].length < slotNr && gameModel.buildingQueue.availableMasterBuilderSlots >= slotNr">
							<div class="topLeftBorder"></div>
							<div class="bottomRightBorder"></div>
							<i class="slotIcon builderIcon feature_masterbuilder_small_flat_black"></i>
						</div>
						<i v-if="gameModel.buildingQueue.availableMasterBuilderSlots < slotNr" class="slotIcon lockedState symbol_lock_small_flat_black"></i>
					</div>
				</div>
				<div v-for="queue in slotImages" drop-class="noDrop" class="buildingSlotImage buildingMini draggable" :class="['tribeId'+gameModel.player.tribeId, 'buildingType'+queue.buildingType, 'level'+queue.lvl, 'queueType'+queue.queueType, {['slot'+queue.slot]: queue.queueType == BuildingQueueModel.TYPE_MASTER_BUILDER, 'paid': queue.paid}]" draggable="true" dropable="shiftMasterBuilder(object, queue.slotNr - 1)" drag-out="buildingQueueDragOut" drag-out-distance="46" ng-on-drag-init="queue.queueType == buildingQueue.TYPE_MASTER_BUILDER ? $parent.dragClass=(queue.paid ? 'dragPaid':'dragUnpaid'): false" ng-on-drag-stop="dragOut ? cancelBuilding(609365) : false; $parent.dragClass=''" @mouseleave="onHideDetails()" @mouseover="onShowDetails(queue.queueType, queue.slot)">
					<i class="dragMarker boxIcon"></i>
					<div class="levelBubble" :class="{'enoughResources': queue.enoughResources}">{{queue.lvl}}</div>
				</div>
			</div>
			<building-queue-details />
		</div>
	</div>
</template>

<style lang="css">
.buildingQueue{
	position:fixed;
	left:0;
	bottom:82px;
	width:calc(50% - -161px);
	min-width:660px;
	text-align:center;
	z-index:1
}
.buildingQueue:hover{
	z-index:3000
}
.buildingQueue .centerArea{
	position:absolute;
	right:0;
	width:322px
}
.buildingQueue .queueContainer{
	background-color:rgba(235,235,235,0.65);
	border:1px solid #fff;
	-webkit-box-shadow:0 0 1px 1px rgba(136,136,136,0.75);
	-moz-box-shadow:0 0 1px 1px rgba(136,136,136,0.75);
	box-shadow:0 0 1px 1px rgba(136,136,136,0.75)
}
.buildingQueue .buildingQueueContainer{
	position:relative;
	margin:auto;
	height:55px;
	width:100%
}
.buildingQueue .buildingQueueContainer .constructionContainer{
	position:relative;
	height:43px;
	width:110px;
	margin:5px;
	border:1px solid #b8b2a9;
	background-color:rgba(241,240,238,0.5);
	text-align:left
}
.buildingQueue .buildingQueueContainer .constructionContainer>i{
	position:relative;
	top:6px;
	left:40px;
	filter:alpha(opacity=40);
	opacity:.4
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot{
	float:left;
	margin:-1px 4px -1px -1px
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot.largeSlot{
	border-right:0
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot.largeSlot:before{
	content:'';
	position:absolute;
	top:0;
	right:-1px;
	height:100%;
	width:1px;
	background-image:-owg-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:-webkit-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:-moz-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:-o-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px)
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot.largeSlot:after{
	content:'';
	position:absolute;
	top:0;
	right:0;
	height:100%;
	width:1px;
	background-image:-owg-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:-webkit-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:-moz-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:-o-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px)
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot.largeSlot:hover,
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot.largeSlot.hover{
	border-right:none !important
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot:not(.largeSlot){
	border-right:none !important
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot:not(.largeSlot) i.slotIcon{
	left:1px;
	top:6px;
	filter:alpha(opacity=40);
	opacity:.4
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot:not(.largeSlot):before{
	content:'';
	position:absolute;
	top:0;
	right:-1px;
	height:100%;
	width:1px;
	background-image:-owg-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:-webkit-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:-moz-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:-o-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px)
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot:not(.largeSlot):after{
	content:'';
	position:absolute;
	top:0;
	right:0;
	height:100%;
	width:1px;
	background-image:-owg-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:-webkit-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:-moz-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:-o-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px)
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot:not(.largeSlot):hover:before,
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot:not(.largeSlot).hover:before{
	background-image:-owg-linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px);
	background-image:-webkit-linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px);
	background-image:-moz-linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px);
	background-image:-o-linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px);
	background-image:linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px)
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot:not(.largeSlot):hover:after,
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot:not(.largeSlot).hover:after{
	background-image:-owg-linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px);
	background-image:-webkit-linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px);
	background-image:-moz-linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px);
	background-image:-o-linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px);
	background-image:linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px)
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot ~ .buildingQueueSlot:not(.largeSlot){
	border-right:1px solid #b8b2a9;
	border-left:none !important
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot ~ .buildingQueueSlot:not(.largeSlot):before{
	content:'';
	position:absolute;
	top:0;
	left:-1px;
	height:100%;
	width:1px;
	background-image:-owg-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:-webkit-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:-moz-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:-o-linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px);
	background-image:linear-gradient(to top,#b8b2a9 9px,transparent 9px,transparent 13px,#b8b2a9 13px,#b8b2a9 20px,transparent 20px,transparent 24px,#b8b2a9 24px,#b8b2a9 31px,transparent 31px,transparent 35px,#b8b2a9 35px)
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot ~ .buildingQueueSlot:not(.largeSlot):after{
	content:'';
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:1px;
	background-image:-owg-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:-webkit-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:-moz-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:-o-linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px);
	background-image:linear-gradient(to top,transparent 8px,#b8b2a9 8px,#b8b2a9 14px,transparent 14px,transparent 19px,#b8b2a9 19px,#b8b2a9 25px,transparent 25px,transparent 30px,#b8b2a9 30px,#b8b2a9 36px,transparent 36px)
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot ~ .buildingQueueSlot:not(.largeSlot):hover:before,
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot ~ .buildingQueueSlot:not(.largeSlot).hover:before{
	background-image:-owg-linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px);
	background-image:-webkit-linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px);
	background-image:-moz-linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px);
	background-image:-o-linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px);
	background-image:linear-gradient(to top,#9a7641 9px,transparent 9px,transparent 13px,#9a7641 13px,#9a7641 20px,transparent 20px,transparent 24px,#9a7641 24px,#9a7641 31px,transparent 31px,transparent 35px,#9a7641 35px)
}
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot ~ .buildingQueueSlot:not(.largeSlot):hover:after,
.buildingQueue .buildingQueueContainer .constructionContainer .buildingQueueSlot ~ .buildingQueueSlot:not(.largeSlot).hover:after{
	background-image:-owg-linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px);
	background-image:-webkit-linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px);
	background-image:-moz-linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px);
	background-image:-o-linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px);
	background-image:linear-gradient(to top,transparent 8px,#9a7641 8px,#9a7641 14px,transparent 14px,transparent 19px,#9a7641 19px,#9a7641 25px,transparent 25px,transparent 30px,#9a7641 30px,#9a7641 36px,transparent 36px)
}
.buildingQueue .buildingQueueContainer .masterBuilderContainer{
	position:absolute;
	top:0;
	right:0;
	margin:0 1px;
	height:100%;
	width:198px;
	padding-left:10px;
	border-left:1px solid #9a7641;
	background-image:-owg-linear-gradient(to right,#ebebeb,transparent 40%,transparent);
	background-image:-webkit-linear-gradient(to right,#ebebeb,transparent 40%,transparent);
	background-image:-moz-linear-gradient(to right,#ebebeb,transparent 40%,transparent);
	background-image:-o-linear-gradient(to right,#ebebeb,transparent 40%,transparent);
	background-image:linear-gradient(to right,#ebebeb,transparent 40%,transparent)
}
.buildingQueue .buildingQueueContainer .masterBuilderContainer:before{
	content:'';
	position:absolute;
	top:17px;
	left:-11px;
	height:21px;
	width:21px;
	background-color:#ebebeb;
	border-bottom:1px solid #9a7641;
	border-left:1px solid #9a7641;
	-webkit-transform:rotate(45deg) skew(12deg,12deg);
	-moz-transform:rotate(45deg) skew(12deg,12deg);
	-ms-transform:rotate(45deg) skew(12deg,12deg);
	-o-transform:rotate(45deg) skew(12deg,12deg);
	transform:rotate(45deg) skew(12deg,12deg)
}
.buildingQueue .buildingQueueContainer .masterBuilderContainer .buildingQueueSlot{
	float:left;
	margin:5px 2px
}
.buildingQueue .buildingQueueContainer .masterBuilderContainer .feature_masterbuilder_small_flat_black{
	filter:alpha(opacity=40);
	opacity:.4
}
.buildingQueue .buildingQueueContainer .buildingSlotImage{
	top:6px
}
.buildingQueue .destructionQueueContainer{
	position:absolute;
	bottom:0;
	left:-58px;
	height:45px;
	padding:5px
}
.buildingQueue .detailsView{
	position:absolute;
	bottom:59px;
	left:0;
	width:322px;
	z-index:3000
}
.buildingQueue .detailsView:before{
	content:'';
	position:absolute;
	bottom:-20px;
	left:0;
	height:20px;
	width:100%
}
.buildingQueue .detailsView .detailsInnerBox{
	margin:5px;
	border:1px solid #b8b2a9;
	background-color:#fff;
	text-align:left
}
.buildingQueue .detailsView .detailsInnerBox .detailsRow{
	position:relative;
	margin:0 7px;
	padding:3px 0
}
.buildingQueue .detailsView .detailsInnerBox .detailsRow.paid .costs,
.buildingQueue .detailsView .detailsInnerBox .detailsRow.paid .costs span.notEnough{
	color:#b2b2b2
}
.buildingQueue .detailsView .detailsInnerBox .detailsRow.paid .costs .enoughResTime{
	display:none
}
.buildingQueue .detailsView .detailsInnerBox .detailsRow:not(:last-child){
	border-bottom:1px solid #b8b2a9
}
.buildingQueue .detailsView .detailsInnerBox .detailsRow .detailsImageContainer{
	position:relative;
	display:inline-block;
	width:53px;
	border-right:1px solid #b8b2a9
}
.buildingQueue .detailsView .detailsInnerBox .detailsRow .detailsImageContainer .feature_masterbuilder_small_flat_black{
	filter:alpha(opacity=40);
	opacity:.4
}
.buildingQueue .detailsView .detailsInnerBox .detailsHeader{
	height:28px
}
.buildingQueue .detailsView .detailsInnerBox .detailsHeader .detailsImageContainer{
	padding-top:3px;
	text-align:center
}
.buildingQueue .detailsView .detailsInnerBox .detailsHeader .detailsImageContainer i.headerIcon{
	left:-0.5px;
	vertical-align:middle
}
.buildingQueue .detailsView .detailsInnerBox .detailsHeader .headerText{
	display:inline-block;
	line-height:28px;
	height:28px;
	padding-left:6px;
	font-weight:bold
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .subContainer{
	display:inline-block;
	position:relative;
	width:53px;
	height:74px;
	vertical-align:top
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .subContainer .feature_buildingqueue_slot_village_medium_flat_black,
.buildingQueue .detailsView .detailsInnerBox .detailsContent .subContainer .feature_buildingqueue_slot_fields_medium_flat_black,
.buildingQueue .detailsView .detailsInnerBox .detailsContent .subContainer .feature_buildingqueue_slot_combine_medium_flat_black{
	filter:alpha(opacity=40);
	opacity:.4
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsImageContainer .buildingQueueSlot{
	margin:13px 3px
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsImageContainer .buildingSlotImage{
	position:absolute;
	top:12px;
	left:3px
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsImageContainer .cancelBuilding{
	position:absolute;
	top:1px;
	right:3px;
	background-color:#fff;
	border:3px solid #fff;
	-webkit-border-radius:50%;
	-moz-border-radius:50%;
	-ms-border-radius:50%;
	-o-border-radius:50%;
	border-radius:50%;
	-webkit-box-shadow:0 0 0 1px #b8b2a9;
	-moz-box-shadow:0 0 0 1px #b8b2a9;
	box-shadow:0 0 0 1px #b8b2a9
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsImageContainer .cancelBuilding:hover:not(.disabled){
	background-color:#e8e5e1;
	border:3px solid #e8e5e1;
	-webkit-box-shadow:0 0 0 1px #888;
	-moz-box-shadow:0 0 0 1px #888;
	box-shadow:0 0 0 1px #888
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsImageContainer .queueIcon{
	margin:10px
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsImageContainer .queueIcon.builderIcon{
	margin-left:14px
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsInfo{
	width:183px;
	padding:2px 5px;
	box-sizing:border-box
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsInfo .levelText{
	white-space:nowrap
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsInfo .levelText i{
	top:-3px;
	opacity:.5
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsInfo .detailsTime{
	position:absolute;
	bottom:1px;
	left:5px
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsButtonContainer{
	width:48px;
	height:74px;
	border-left:1px solid #b8b2a9
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsButtonContainer button{
	width:38px;
	height:38px;
	margin:16px 8px;
	overflow:visible
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsButtonContainer button .content{
	padding:0
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsButtonContainer button .content .price{
	position:absolute;
	top:-12px;
	left:22px
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsButtonContainer button:not(.disabled) i.disabled{
	cursor:pointer
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .detailsButtonContainer i.voucher{
	position:absolute;
	top:15px;
	left:33px;
	pointer-events:none
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .progressbar,.buildingQueue .detailsView .detailsInnerBox .detailsContent .emptyBar{
	position:absolute;
	top:62px;
	left:0
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .progressbar .progress,.buildingQueue .detailsView .detailsInnerBox .detailsContent .emptyBar{
	width:232px;
	height:6px
}
.buildingQueue .detailsView .detailsInnerBox .detailsContent .emptyBar{
	background-color:#e8e5e1;
	border:1px solid #b8b2a9
}
.buildingQueue .detailsView .detailsInnerBox .costs .resource{
	border:0;
	min-width:62px;
	padding:4px
}
.buildingQueue .detailsView .detailsInnerBox .costs .resource i{
	margin:0
}
.buildingQueue .detailsView .detailsInnerBox .slotStatus{
	font-style:italic
}
.buildingQueue .detailsView .detailsInnerBox .slotStatus:not(:first-child){
	margin-top:2px
}
.buildingQueue .detailsView .detailsInnerBox .impediment{
	color:#d40000
}
.buildingQueue .detailsView .detailsInnerBox:before{
	content:'';
	position:absolute;
	bottom:-4px;
	left:39px;
	width:22px;
	height:10px;
	border-left:1px solid #b8b2a9;
	border-right:1px solid #b8b2a9;
	background-color:#fff
}
.buildingQueue .detailsView .detailsInnerBox:after{
	content:'';
	position:absolute;
	bottom:-11px;
	left:44px;
	width:13px;
	height:13px;
	border-bottom:1px solid #b8b2a9;
	border-left:1px solid #b8b2a9;
	-webkit-transform:rotate(315deg) skew(16deg,16deg);
	-moz-transform:rotate(315deg) skew(16deg,16deg);
	-ms-transform:rotate(315deg) skew(16deg,16deg);
	-o-transform:rotate(315deg) skew(16deg,16deg);
	transform:rotate(315deg) skew(16deg,16deg);
	background-color:#fff
}
.buildingQueue .detailsView.queueType4.slot1 .detailsInnerBox:before{
	left:136px
}
.buildingQueue .detailsView.queueType4.slot1 .detailsInnerBox:after{
	left:141px
}
.buildingQueue .detailsView.queueType4.slot2 .detailsInnerBox:before{
	left:184.5px
}
.buildingQueue .detailsView.queueType4.slot2 .detailsInnerBox:after{
	left:189.5px
}
.buildingQueue .detailsView.queueType4.slot3 .detailsInnerBox:before{
	left:233px
}
.buildingQueue .detailsView.queueType4.slot3 .detailsInnerBox:after{
	left:238px
}
.buildingQueue .detailsView.queueType4.slot4 .detailsInnerBox:before{
	left:281.5px
}
.buildingQueue .detailsView.queueType4.slot4 .detailsInnerBox:after{
	left:286.5px
}
.buildingQueue .detailsView:not(.queueType4) .detailsContent{
	height:74px
}
.buildingQueue .detailsView:not(.queueType4) .detailsContent .detailsImageContainer,
.buildingQueue .detailsView:not(.queueType4) .detailsContent .detailsInfo{
	height:54px
}
.buildingQueue .detailsView.queueType5{
	left:-58px
}
.buildingQueue .detailsView.queueType5 .detailsInnerBox:before{
	left:16px
}
.buildingQueue .detailsView.queueType5 .detailsInnerBox:after{
	left:21px
}
.buildingSlotImage{
	position:absolute;
	top:0;
	-webkit-transition:left .2s ease;
	-moz-transition:left .2s ease;
	-o-transition:left .2s ease;
	transition:left .2s ease
}
.buildingSlotImage:not(.tribeId1).queueType1,
.buildingSlotImage:not(.tribeId1).queueType2{
	left:29px
}
.buildingSlotImage:not(.tribeId1).queueType1 .levelBubble,
.buildingSlotImage:not(.tribeId1).queueType2 .levelBubble{
	margin:-5px -27px
}
.buildingSlotImage.tribeId1.queueType1{
	left:6px
}
.buildingSlotImage.tribeId1.queueType2{
	left:52px
}
.buildingSlotImage.queueType4.slot1{
	left:126px;
	transition-delay:0;
	z-index:1
}
.buildingSlotImage.queueType4.slot2{
	left:175px;
	transition-delay:.05s;
	z-index:2
}
.buildingSlotImage.queueType4.slot3{
	left:224px;
	transition-delay:.1s;
	z-index:3
}
.buildingSlotImage.queueType4.slot4{
	left:273px;
	transition-delay:.15s;
	z-index:4
}
.buildingSlotImage.queueType4.buildingQueueDropHover.dragUnpaid:not(.paid),
.buildingSlotImage.queueType4.buildingQueueDropHover.dragPaid.paid{
	background-color:#f1ffc1;
	-webkit-box-shadow:0 0 1px 1px #a5c400;
	-moz-box-shadow:0 0 1px 1px #a5c400;
	box-shadow:0 0 1px 1px #a5c400;
	border-right:1px solid #f1ffc1;
	border-bottom:1px solid #f1ffc1
}
.buildingSlotImage.queueType4:not(.paid) .levelBubble{
	border:1px solid #9a7641;
	-webkit-box-shadow:inset 0 0 0 2px #f6d81f,0 0 1px 0 #000;
	-moz-box-shadow:inset 0 0 0 2px #f6d81f,0 0 1px 0 #000;
	box-shadow:inset 0 0 0 2px #f6d81f,0 0 1px 0 #000
}
.buildingSlotImage.queueType4:not(.paid) .levelBubble.enoughResources{
	-webkit-box-shadow:inset 0 0 0 2px #a5c400,0 0 1px 0 #000;
	-moz-box-shadow:inset 0 0 0 2px #a5c400,0 0 1px 0 #000;
	box-shadow:inset 0 0 0 2px #a5c400,0 0 1px 0 #000
}
.buildingSlotImage.queueType4.dragUnpaid.paid,
.buildingSlotImage.queueType4.dragPaid:not(.paid){
	-webkit-filter:grayscale(1);
	filter:grayscale(1);
	opacity:.8
}
.buildingSlotImage.queueType4:not(.drag) i.dragMarker{
	display:none
}
.buildingSlotImage.drag .levelBubble{
	display:none
}
.buildingSlotImage .levelBubble{
	width:18px;
	height:18px;
	line-height:18px;
	margin:-5px;
	background:rgba(255,255,255,0.8);
	border:1px solid #b8b2a9;
	-webkit-border-radius:50%;
	-moz-border-radius:50%;
	-ms-border-radius:50%;
	-o-border-radius:50%;
	border-radius:50%;
	font-size:.76923rem
}
.buildingQueueSlot.entityBox{
	position:relative;
	width:43px;
	height:43px;
	background-color:rgba(255,255,255,0.6);
	border:1px solid #b8b2a9;
	text-align:center
}
.buildingQueueSlot.entityBox:hover:not(.active):not(:active):not(.drag):not(.dummy):not(.disabled):not(.premiumItem):not(.noClick),
.buildingQueueSlot.entityBox:hover:active.noActive,
.buildingQueueSlot.entityBox.hover:not(.active):not(:active):not(.drag):not(.dummy):not(.disabled):not(.premiumItem):not(.noClick),
.buildingQueueSlot.entityBox.hover:active.noActive{
	border:1px solid #b8b2a9
}
.buildingQueueSlot.entityBox.largeSlot{
	width:88px
}
.buildingQueueSlot.entityBox .progressbar{
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	z-index:1
}
.buildingQueueSlot.entityBox .progressbar .progress{
	height:6px;
	width:100%;
	margin:-1px
}
.buildingQueueSlot.entityBox.paid{
	background-image:-owg-linear-gradient(to top,#a5c400,#fff 60%);
	background-image:-webkit-linear-gradient(to top,#a5c400,#fff 60%);
	background-image:-moz-linear-gradient(to top,#a5c400,#fff 60%);
	background-image:-o-linear-gradient(to top,#a5c400,#fff 60%);
	background-image:linear-gradient(to top,#a5c400,#fff 60%)
}
.buildingQueueSlot.entityBox.paid:hover:not(.active):not(:active):not(.drag):not(.dummy):not(.disabled):not(.premiumItem):not(.noClick),
.buildingQueueSlot.entityBox.paid:hover:active.noActive,
.buildingQueueSlot.entityBox.paid.hover:not(.active):not(:active):not(.drag):not(.dummy):not(.disabled):not(.premiumItem):not(.noClick),
.buildingQueueSlot.entityBox.paid.hover:active.noActive{
	background-image:-owg-linear-gradient(to top,#bfd94d,#fff 60%);
	background-image:-webkit-linear-gradient(to top,#bfd94d,#fff 60%);
	background-image:-moz-linear-gradient(to top,#bfd94d,#fff 60%);
	background-image:-o-linear-gradient(to top,#bfd94d,#fff 60%);
	background-image:linear-gradient(to top,#bfd94d,#fff 60%)
}
.buildingQueueSlot.entityBox.dragUnpaid.paid:not(.empty),
.buildingQueueSlot.entityBox.dragPaid:not(.paid):not(.empty){
	background-image:none;
	background-color:rgba(184,178,169,0.7)
}
.buildingQueueSlot.entityBox.locked:not(:hover):not(.hover),
.buildingQueueSlot.entityBox.locked.noClick{
	background-color:rgba(218,216,213,0.5)
}
.buildingQueueSlot.entityBox.locked i{
	filter:alpha(opacity=50);
	opacity:.5
}
.buildingQueueSlot.entityBox.empty:not(.locked):not(:hover):not(.hover),
.buildingQueueSlot.entityBox.noClick:not(.locked){
	border:0;
	padding:1px
}
.buildingQueueSlot.entityBox.empty:not(.locked):not(:hover):not(.hover) .topLeftBorder:before,
.buildingQueueSlot.entityBox.noClick:not(.locked) .topLeftBorder:before{
	content:'';
	position:absolute;
	top:0;
	left:0;
	height:1px;
	width:100%;
	background-image:-owg-linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-webkit-linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-moz-linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-o-linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-image:linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-position:7px 0;
	background-size:21px 1px;
	background-repeat:repeat-x
}
.buildingQueueSlot.entityBox.empty:not(.locked):not(:hover):not(.hover) .topLeftBorder:after,
.buildingQueueSlot.entityBox.noClick:not(.locked) .topLeftBorder:after{
	content:'';
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:1px;
	background-image:-owg-linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-webkit-linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-moz-linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-o-linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-image:linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-position:0 7px;
	background-size:1px 21px;
	background-repeat:repeat-y
}
.buildingQueueSlot.entityBox.empty:not(.locked):not(:hover):not(.hover) .bottomRightBorder:before,
.buildingQueueSlot.entityBox.noClick:not(.locked) .bottomRightBorder:before{
	content:'';
	position:absolute;
	bottom:0;
	right:0;
	height:1px;
	width:100%;
	background-image:-owg-linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-webkit-linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-moz-linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-o-linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-image:linear-gradient(right,#b8b2a9 11px,transparent 11px,transparent);
	background-position:7px 0;
	background-size:21px 1px;
	background-repeat:repeat-x
}
.buildingQueueSlot.entityBox.empty:not(.locked):not(:hover):not(.hover) .bottomRightBorder:after,
.buildingQueueSlot.entityBox.noClick:not(.locked) .bottomRightBorder:after{
	content:'';
	position:absolute;
	bottom:0;
	right:0;
	height:100%;
	width:1px;
	background-image:-owg-linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-webkit-linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-moz-linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-image:-o-linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-image:linear-gradient(bottom,#b8b2a9 11px,transparent 11px,transparent);
	background-position:0 7px;
	background-size:1px 21px;
	background-repeat:repeat-y
}
.buildingQueueSlot.entityBox i.slotIcon{
	position:relative;
	top:12px;
	vertical-align:middle
}
.buildingQueueSlot.entityBox i.slotIcon.feature_buildingqueue_slot_combine_medium_flat_black{
	filter:alpha(opacity=40);
	opacity:.4;
	top:6px
}
.tooltip .cancelBuildingTooltip{
	text-align:center
}
</style>

<script>
import lib from '@/lib';

import BuildingQueueModel from '@/models/game/BuildingQueueModel';
import PlayerModel from '@/models/game/PlayerModel';

import BuildingQueueDetails from '@/components/partials/BuildingQueueDetails';

export default {
	mixins: lib.mixins,
	data: () => ({
		BuildingQueueModel,
		PlayerModel,
		
		intervalId: null,
	}),
	computed: {
		slotImages(){
			let data = [];
			for(let [type, qs] of Object.entries(this.gameModel.buildingQueue.queues)){
				if(type != BuildingQueueModel.TYPE_DESTRUCTION){
					for(let q of qs){
						data.push(q);
					}
				}
			}
			return data;
		},
	},
	methods: {
		range: lib.range,
		
		onHideDetails(){//mouseout
			this.intervalId = setTimeout(() => {
				this.gameModel.buildingQueue.activeSlot.slotHover = null;
				this.gameModel.buildingQueue.activeSlot.slot = null;
			}, 100);
		},
		onShowDetails(queueType, slot = null){//mouseover
			clearTimeout(this.intervalId);
			this.gameModel.buildingQueue.activeSlot.slotHover = queueType;
			this.gameModel.buildingQueue.activeSlot.slot = slot;
		},
	},
	components: {
		BuildingQueueDetails,
	},
}
</script>
import axios from 'axios';
import lib from '@/lib';

export default class ShopModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.allPaymentMethodsShow = false;
		this.orderHistoryShow = false;
		this.goldFeaturesShow = false;
	}
}

import axios from 'axios';
import lib from '@/lib';

export default class EmbassyModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.kingdomInvitationShow = false;
		this.kingdomLeaveShow = false;
		this.createKingdomShow = false;
		this.createSecretSocietyShow = false;
		this.openInvitationsShow = false;
		this.secretSocietyInvitationShow = false;
		this.secretSocietyInviteShow = false;
		this.secretSocietyDissolveShow = false;
	}
}

<template>
	<div class="statisticsTable playerRanking contentBox transparent">
		<h6 class="contentBoxHeader headerWithArrowEndings tableGreen">
			<div class="content" translate=""><span>Лучшие игроки</span></div>
		</h6>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems[tab]" current-page="currentPage[tab]" display-page-func="displayCurrentPage" route-named-param="statsPage">
			<div ng-transclude="">
				<div class="statisticsContainer">
					<div ng-include="" src="'tpl/statistics/partials/playerInfo.html'">
						<div ng-hide="showHover" class="playerInfo top3">
							<div class="playerInfoHeader">
								<div content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></div>
								<div content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></div>
							</div>
							<div class="content rank_1" ng-repeat="player in top3[tab]" clickable="showFixedRankPlayerInfo(player.rank)">
								<div class="playerInfoRow">
									<div class="rank">
										1
									</div>
									<div class="name">
										<div class="content">
											<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="270" nolink="true">Pinch</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2" nolink="true">♔Выдры♔</a>
										</div>
									</div>
									<div class="avatar">
										<avatar-image class="avatarImage" player-id="270" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<div class="prestigeStars" ng-if="config.balancing.features.prestige">
											<div class="innerStars" playerid="270" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												</div>
												
											</div>
										</div>
									</div>
								</div>
								<div class="pointsInfoRow Overview">
									<i ng-if="tab == 'Overview'" class="unit_population_medium_illu" content="Общее население" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.TotalPopulation"></i>
									<!-- ngIf: tab == 'Attacker' -->
									<!-- ngIf: tab == 'Defender' -->
									<!-- ngIf: tab == 'Heroes' -->
									<span translate="" class="pointsName" options="Overview"><span>Население</span></span>
									<span class="points">3&nbsp;840</span>
								</div>
							</div>
							<div class="content rank_2" ng-repeat="player in top3[tab]" clickable="showFixedRankPlayerInfo(player.rank)">
								<div class="playerInfoRow">
									<div class="rank">
										2
									</div>
									<div class="name">
										<div class="content">
											<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="514" nolink="true">Klop</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2" nolink="true">♔Выдры♔</a>
										</div>
									</div>
									<div class="avatar">
										<avatar-image class="avatarImage" player-id="514" scale="0.5" avatar-class="profileImage"><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<div class="prestigeStars" ng-if="config.balancing.features.prestige">
											<div class="innerStars" playerid="514" size="tiny">
												<i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="pointsInfoRow Overview">
									<i ng-if="tab == 'Overview'" class="unit_population_medium_illu" content="Общее население" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.TotalPopulation"></i>
									<!-- ngIf: tab == 'Attacker' -->
									<!-- ngIf: tab == 'Defender' -->
									<!-- ngIf: tab == 'Heroes' -->
									<span translate="" class="pointsName" options="Overview"><span>Население</span></span>
									<span class="points">3&nbsp;048</span>
								</div>
							</div>
							<div class="content rank_3" ng-repeat="player in top3[tab]" clickable="showFixedRankPlayerInfo(player.rank)">
								<div class="playerInfoRow">
									<div class="rank">
										3
									</div>
									<div class="name">
										<div class="content">
											<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="106" nolink="true">Брего</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2" nolink="true">♔Выдры♔</a>
										</div>
									</div>
									<div class="avatar">
										<avatar-image class="avatarImage" player-id="106" scale="0.5" avatar-class="profileImage"><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<div class="prestigeStars" ng-if="config.balancing.features.prestige">
											<div class="innerStars" playerid="106" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="pointsInfoRow Overview">
									<i ng-if="tab == 'Overview'" class="unit_population_medium_illu" content="Общее население" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.TotalPopulation"></i>
									<!-- ngIf: tab == 'Attacker' -->
									<!-- ngIf: tab == 'Defender' -->
									<!-- ngIf: tab == 'Heroes' -->
									<span translate="" class="pointsName" options="Overview"><span>Население</span></span>
									<span class="points">2&nbsp;887</span>
								</div>
							</div>

							<div class="averagePoints">
								<div class="pointsRow">
									<span translate=""><span>Ø</span></span><span class="points">590</span>
								</div>
								<div class="descriptionRow" translate="" options="Overview"><span>Среднее население</span></div>
							</div>
						</div>
						<div ng-show="showHover" class="playerInfo playerProfile" on-pointer-over="showPlayerInfo(selectedData.rank)" on-pointer-out="hidePlayerInfo()">
							<div class="playerInfoHeader">
								<div>
									lexinzector
								</div>
							</div>
							<div class="playerProfileAvatarContainer">
								<div class="detailsWrapper">
									<div class="playerProfileAvatar kingdomRole0">
										<div class="heroLevel">
											<span translate=""><span>Ур.</span></span>
											<br>
											<span>1</span>
										</div>
										<div class="avatarLink clickable" clickable="openOverlay('playerProfileFullImage', {'playerId': 1804 });">
											<avatar-image scale="0.55" class="avatarImage" player-id="1804" size="big" avatar-class="profileImage">
												<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
													<!-- ngIf: playerId == robberId -->
													<!-- ngIf: playerId == npcGovernorId -->
													<canvas width="192" height="192"></canvas>
												</div>
											</avatar-image>
											<div class="prestigeStars" ng-if="config.balancing.features.prestige">
												<div class="innerStars" playerid="1804" size="tiny">
													<div ng-if="!badge">
														<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
														<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
														<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													</div>
												</div>
											</div>
										</div>
										<span class="decoration"></span>
									</div>
								</div>
							</div>
							<div class="playerDataContainer">
								<div class="indicationArrow position_1" ng-class="{disabled: currentPage[tab] > 1 &amp;&amp; selectedData.rank < itemsPerPage}"></div>
								<div class="flagHanger governor" ng-class="['king', 'viceking', 'duke', 'governor']">
									<div class="flag"></div>
									<i class="community_governor_small_flat_black" ng-class="['community_king_small_flat_black', 'community_viceking_small_flat_black', 'community_duke_small_flat_black', 'community_governor_small_flat_black']"></i>
								</div>
								<div class="header">
									<i ng-if="tab == 'Overview'" class="unit_population_small_flat_black" content="Общее население" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.TotalPopulation"></i>
									<!-- ngIf: tab == 'Attacker' -->
									<!-- ngIf: tab == 'Defender' -->
									<!-- ngIf: tab == 'Heroes' -->
									<i content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank" class="symbol_ranking_small_flat_black"></i>
								</div>
								<div class="statsData">
									<div class="currentData">
										<span class="description" translate=""><span>Сейчас</span></span>
										<span class="points">57</span>
										<span class="rank">402.</span>
									</div>
									<div class="lastWeekData">
										<span class="description" translate=""><span>Прошлая неделя</span></span>
										<span ng-if="playerStatsLastWeek.rank >= 0" class="points">21</span>
										<!-- ngIf: playerStatsLastWeek.rank < 0 -->
										<span ng-if="playerStatsLastWeek.rank >= 0" class="rank">454.</span>
										<!-- ngIf: playerStatsLastWeek.rank < 0 -->
									</div>
								</div>
								<div class="playerData">
									<div>
										<span class="description"><span translate=""><span>Народ</span></span>:</span>
										<span class="content" translate="" options="3"><span>Галлы</span></span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Деревни</span></span>:</span>
										<span class="content">1</span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Роль</span></span>:</span>
										<span translate="" class="content" options="0"><span>Губернатор</span></span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Королевство</span></span>:</span>
										<span class="content">
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 0}, null, true)" kingdomid="0" kingdomname="-">-</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3" kingdomname="MИР">MИР</a>
										</span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Регистрация</span></span>:</span>
										<span class="content">
											<!-- ngIf: signedUp == 0 -->
											<!-- ngIf: signedUp == 1 -->
											<span ng-if="signedUp > 1" translate="" data="days: 9"><span>9 дн. назад</span></span>
										</span>
									</div>
								</div>
								<div class="buttonContainer">
									<div class="chatButton">
										<button clickable="openChat(getPrivateRoomName(selectedData.playerId))" class="clickable">
											<div class="content">
												<span ng-transclude="" class="innerText">
													<span translate=""><span>Чат</span></span>
												</span>
												<!-- ngIf: price > 0 || forceGoldUsage -->
												<!-- ngIf: price == -1 && !forceGoldUsage -->
												<div class="animationOverlay"></div>
												<i class="checkmark action_check_small_flat_white"></i>
												<i class="checkmark action_check_small_flat_black"></i>
												<i class="checkmark action_check_small_flat_red"></i>
											</div>
										</button>
									</div>
									<div class="profileButton">
										<button clickable="openWindow('profile', {'playerId': 1804, 'profileTab': 'playerProfile'}, null, true)" class="clickable">
											<div class="content">
												<span ng-transclude="" class="innerText">
													<span translate=""><span>Профиль</span></span>
												</span>
												<!-- ngIf: price > 0 || forceGoldUsage -->
												<!-- ngIf: price == -1 && !forceGoldUsage -->
												<div class="animationOverlay"></div>
												<i class="checkmark action_check_small_flat_white"></i>
												<i class="checkmark action_check_small_flat_black"></i>
												<i class="checkmark action_check_small_flat_red"></i>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<table class="gradient pagination page_1">
						<thead>
							<tr>
								<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
								<th content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></th>
								<th content="Общее население" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.TotalPopulation"><i class="unit_population_small_flat_black"></i></th>
							</tr>
						</thead>
						<tbody class="ranks">
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="614" player-name="Дыня" rank="400" points="59" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank401" ng-if="rank >= 0">401</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="614" player-name="Дыня" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="614" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="614" playername="Дыня" nolink="false">Дыня</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">59</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1804" player-name="lexinzector" rank="401" points="57" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo" class="highlighted">
								<td class="rankColumn rank402" ng-if="rank >= 0">402</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1804" player-name="lexinzector" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">57</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1070" player-name="нирчебальд" rank="402" points="57" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank403" ng-if="rank >= 0">403</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1070" player-name="нирчебальд" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1070" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1070" playername="нирчебальд" nolink="false">нирчебальд</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">57</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="877" player-name="Vanesko" rank="403" points="56" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank404" ng-if="rank >= 0">404</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="877" player-name="Vanesko" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="877" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="877" playername="Vanesko" nolink="false">Vanesko</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">56</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1030" player-name="Воля" rank="404" points="56" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank405" ng-if="rank >= 0">405</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1030" player-name="Воля" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1030" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1030" playername="Воля" nolink="false">Воля</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">56</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="955" player-name="Yammer" rank="405" points="55" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank406" ng-if="rank >= 0">406</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="955" player-name="Yammer" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="955" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="955" playername="Yammer" nolink="false">Yammer</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">55</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1706" player-name="Leo" rank="406" points="53" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank407" ng-if="rank >= 0">407</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1706" player-name="Leo" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1706" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1706" playername="Leo" nolink="false">Leo</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">53</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1802" player-name="Лёха" rank="407" points="52" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank408" ng-if="rank >= 0">408</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1802" player-name="Лёха" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1802" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1802" playername="Лёха" nolink="false">Лёха</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">52</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1029" player-name="Andrei_Gall" rank="408" points="52" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank409" ng-if="rank >= 0">409</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1029" player-name="Andrei_Gall" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1029" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1029" playername="Andrei_Gall" nolink="false">Andrei_Gall</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">52</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="379" player-name="ZEVS" rank="409" points="51" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank410" ng-if="rank >= 0">410</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="379" player-name="ZEVS" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="379" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="379" playername="ZEVS" nolink="false">ZEVS</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								
								<td class="number pointsColumn" ng-if="playerId >= 100">51</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="tg-pagination" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
					</li>
					<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(2)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="">2</a>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(...)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">...</a>
					</li>
					<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(53)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="">53</a>
					</li>
					<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black"></i>
					</li>
					<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
							action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black"></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
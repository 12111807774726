<template>
	<div class="contentBox">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content" translate=""><span>Показывать онлайн-статус</span></div>
		</div>
		<div class="contentBoxBody">
			<table>
				<tbody>
					<tr class="settingsRow">
						<td class="label" translate=""><span>Показать онлайн-статус</span></td>
						<td class="setting">
							<select style="display: block; width: 250px; height: 24px;">
								<option>Друзья и жители королевства</option>
								<option>Друзья</option>
								<option>Никому</option>
							</select>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	
	<div class="contentBox" ng-if="!isSitter">
		<div class="contentBoxHeader headerTrapezoidal">
			<div ng-if="!isDual" class="content" translate=""><span>Заместители и дуалы</span></div>
			<!-- ngIf: isDual -->
		</div>
		<div class="contentBoxBody">
			<table>
				<tbody>
					<tr class="settingsRow">
						<td ng-if="!isDual" class="label" translate=""><span>Управление замещением и дуалами</span></td>
						<!-- ngIf: isDual -->
						<td class="setting">
							<button clickable="openOverlay('manageSitterAndDuals')" class="clickable">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Управлять</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	
	<div class="contentBox" ng-if="!isSitter">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content" translate=""><span>Внешние инструменты</span></div>
		</div>
		<div class="contentBoxBody">
			<table>
				<tbody>
					<tr class="settingsRow">
						<td class="label" translate=""><span>Создать доступ для внешних инструментов</span></td>
						<td class="setting">
							<button clickable="openOverlay('getExternalLogin')" class="animate clickable">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Создать доступ</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	
	<div class="contentBox" ng-if="canDeleteAccount || player.data.deletionTime > 0 || goldDeletionTimeout">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content" translate=""><span>Удалить персонажа</span></div>
		</div>
		<div class="contentBoxBody">
			<table>
				<tbody>
					<tr class="settingsRow" ng-show="player.data.deletionTime <= 0 &amp;&amp; !goldDeletionTimeout">
						<td class="label" translate=""><span>Удалить героя</span></td>
						<td class="setting">
							<button ng-class="{disabled: player.data.deletionTime > 0}" ng-if="!player.data.kingstatus &amp;&amp; player.data.kingdomRole != Player.KINGDOM_ROLE_DUKE" class="animate clickable" clickable="openOverlay('startDeletion'); 0">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Удалить</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
							<!-- ngIf: player.data.kingstatus || player.data.kingdomRole == Player.KINGDOM_ROLE_DUKE -->
						</td>
					</tr>
					<tr class="settingsRow ng-hide" ng-show="player.data.deletionTime > 0">
						<td class="label" translate="" data="deletionTime: 0"><span>Герой будет удален через&nbsp;</span><span countdown="0" callback="">00:00:00</span><span>.</span></td>
						<td class="setting">
							<button class="cancel clickable" ng-class="{'disabled': !canAbortDeletion}" clickable="abortDeletion()" tooltip="" tooltip-translate="PlayerDeletion.Abort.NotPossible" tooltip-hide="canAbortDeletion">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Отменить удаление!</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</td>
					</tr>
					<tr class="settingsRow ng-hide" ng-show="goldDeletionTimeout">
						<td colspan="2">
							<span translate="" data="deletionTimeout: 604800"><span>Удаление героя невозможно сразу после приобретения золота. Пожалуйста, подождите </span><span i18ndt="604800" format="short">08.01.70 06:00</span><span>.</span></span>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="error ng-hide" ng-show="error"></div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="intern" ng-controller="kingdomInternCtrl">
		<div class="statistics contentBox gradient">
			<h6 class="contentBoxHeader headerWithArrowEndings glorious">
				<div class="content" translate=""><span>Статистика</span></div>
			</h6>
			<div class="contentBoxBody">
				<div class="statsBox attack contentBox colored contentBoxBody" content="Сила атаки" v-tippy="{placement: 'bottom'}" tooltip-translate="Kingdom.Intern.AttackPower">
					<i class="kingdom_totalOffense_medium_illu"></i>
					<div class="absoluteValue lastDay">2&nbsp;784&nbsp;695</div>
					<div class="rankValue" translate="" data="rank:4"><span>Ранг: 4</span></div>
				</div>
				<div class="statsBox defense contentBox colored contentBoxBody" content="Сила защиты" v-tippy="{placement: 'bottom'}" tooltip-translate="Kingdom.Intern.DefensePower">
					<i class="kingdom_totalDefense_medium_illu"></i>
					<div class="absoluteValue lastDay">2&nbsp;834&nbsp;523</div>
					<div class="rankValue" translate="" data="rank:4"><span>Ранг: 4</span></div>
				</div>
			</div>
		</div>
		
		<div ng-show="treaties.length > 0" class="diplomacy contentBox gradient">
			<h6 class="contentBoxHeader headerWithArrowEndings glorious">
				<div class="content" translate=""><span>Дипломатия</span></div>
			</h6>
			<div class="contentBoxBody scrollable" scrollable="">
				<div class="scrollContentOuterWrapper" style="width: 238px;">
					<div class="scrollContent" dynamic-height="false" style="width: 238px; overflow-y: hidden;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
							<table class="transparent">
								<tbody>
									<tr ng-repeat="i in treaties">
										<td class="colorCol"><span class="markerColor" ng-style="{&quot;background-color&quot;:&quot;#cc3333&quot;}" style="background-color: rgb(204, 51, 51);"></span></td>
										<td>
											<span options="2" data="victimId: 20, victimName: Медоеды" translate=""><span>Война с </span><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20" nolink="Медоеды" showmissinglink="true">Медоеды</a></span>
										</td>
									</tr>
									<tr ng-repeat="i in treaties">
										<td class="colorCol"><span class="markerColor" ng-style="{&quot;background-color&quot;:&quot;#00ffff&quot;}" style="background-color: rgb(0, 255, 255);"></span></td>
										<td>
											<span options="0" data="victimId: 10, victimName: ОоО" translate=""><span>ПоН с </span><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 10}, null, true)" kingdomid="10" nolink="ОоО" showmissinglink="true">ОоО</a></span>
										</td>
									</tr>
									<tr ng-repeat="i in treaties">
										<td class="colorCol"><span class="markerColor" ng-style="{&quot;background-color&quot;:&quot;#00ff00&quot;}" style="background-color: rgb(0, 255, 0);"></span></td>
										<td>
											<span options="1" data="victimId: 41, victimName: DOTA2" translate=""><span>Союз с </span><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 41}, null, true)" kingdomid="41" nolink="DOTA2" showmissinglink="true">DOTA2</a></span>
										</td>
									</tr>
									<tr ng-repeat="i in treaties">
										<td class="colorCol"><span class="markerColor" ng-style="{&quot;background-color&quot;:&quot;#00ff00&quot;}" style="background-color: rgb(0, 255, 0);"></span></td>
										<td>
											<span options="1" data="victimId: 11, victimName: S.T.I.G." translate=""><span>Союз с </span><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 11}, null, true)" kingdomid="11" nolink="S.T.I.G." showmissinglink="true">S.T.I.G.</a></span>
										</td>
									</tr>
									<tr ng-repeat="i in treaties">
										<td class="colorCol"><span class="markerColor" ng-style="{&quot;background-color&quot;:&quot;#00ffff&quot;}" style="background-color: rgb(0, 255, 255);"></span></td>
										<td>
											<span options="0" data="victimId: 35, victimName: Архангел" translate=""><span>ПоН с </span><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 35}, null, true)" kingdomid="35" nolink="Архангел" showmissinglink="true">Архангел</a></span>
										</td>
									</tr>
									<tr ng-repeat="i in treaties">
										<td class="colorCol"><span class="markerColor" ng-style="{&quot;background-color&quot;:&quot;#00ff00&quot;}" style="background-color: rgb(0, 255, 0);"></span></td>
										<td>
											<span options="1" data="victimId: 2, victimName: ♔Выдры♔" translate=""><span>Союз с </span><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2" nolink="♔Выдры♔" showmissinglink="true">♔Выдры♔</a></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="internalInfos contentBox colored diplomacyBlockShown" ng-class="{'diplomacyBlockShown': treaties.length > 0}">
			<div class="contentBoxHeader">
				<span translate=""><span>Внутренняя информация</span></span>
				<!-- ngIf: player.isKing() && !isSitter -->
			</div>
			<div class="contentBoxBody scrollable visible" scrollable="" ng-class="{'visible': descriptionVisible}">
				<div class="scrollContentOuterWrapper" style="width: 238px;">
					<div class="scrollContent" dynamic-height="false" style="width: 238px; overflow-y: hidden;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
							<div user-text-parse="kingdom.data.description.internalDescription" parse="decorations"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="newsFeed contentBox gradient">
			<h6 class="contentBoxHeader headerWithArrowEndings glorious">
				<div class="content" translate=""><span>События королевства</span></div>
			</h6>
			<div class="contentBoxBody">
				<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems" current-page="currentPage" display-page-func="displayCurrentPage" route-named-param="cp">
					<div ng-transclude="">
						<table class="columnOnly">
							<tbody>
								<tr ng-repeat="n in news">
									<td>
										<span options="20" data="agentId: 1804, agentName: , victimId: 3, victimName: " translate=""><span>Игрок </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="1804" playername="" nolink="" showmissinglink="true">lexinzector</a><span> успешно переехал и присоединился к королевству.</span></span>
									</td>
									<td>
										<span i18ndt="1707909677" format="short">Сегодня 17:21</span>
									</td>
								</tr>
								<tr ng-repeat="n in news">
									<td>
										<span options="19" data="agentId: 127, agentName: , victimId: 1804, victimName: " translate=""><span>Игрок </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="127" playername="" nolink="" showmissinglink="true">Gaius Ulius</a><span> установил каменный столб для игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="1804" playername="" nolink="" showmissinglink="true">lexinzector</a><span>.</span></span>
									</td>
									<td>
										<span i18ndt="1707907828" format="short">Сегодня 16:50</span>
									</td>
								</tr>
								<tr ng-repeat="n in news">
									<td>
										<span options="1" data="agentId: 754, agentName: Rington, victimId: 3, victimName: Roman" translate=""><span>Игрок </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="754" playername="Rington" nolink="" showmissinglink="true">Rington</a><span> покинул королевство.</span></span>
									</td>
									<td>
										<span i18ndt="1707878759" format="short">Сегодня 08:45</span>
									</td>
								</tr>
								<tr ng-repeat="n in news">
									<td>
										<span options="1" data="agentId: 1072, agentName: бомж, victimId: 3, victimName: Roman" translate=""><span>Игрок </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="1072" playername="бомж" nolink="" showmissinglink="true">бомж</a><span> покинул королевство.</span></span>
									</td>
									<td>
										<span i18ndt="1707878752" format="short">Сегодня 08:45</span>
									</td>
								</tr>
								<tr ng-repeat="n in news">
									<td>
										<span options="1" data="agentId: 1398, agentName: Dreh auf!, victimId: 3, victimName: Roman" translate=""><span>Игрок </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="1398" playername="Dreh auf!" nolink="" showmissinglink="true">Dreh auf!</a><span> покинул королевство.</span></span>
									</td>
									<td>
										<span i18ndt="1707878742" format="short">Сегодня 08:45</span>
									</td>
								</tr>
								<tr ng-repeat="n in news">
									<td>
										<span options="1" data="agentId: 1869, agentName: Rapier33, victimId: 3, victimName: Roman" translate=""><span>Игрок </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="1869" playername="Rapier33" nolink="" showmissinglink="true">Rapier33</a><span> покинул королевство.</span></span>
									</td>
									<td>
										<span i18ndt="1707878705" format="short">Сегодня 08:45</span>
									</td>
								</tr>
								<tr ng-repeat="n in news">
									<td>
										<span options="1" data="agentId: 981, agentName: Антон, victimId: 3, victimName: Roman" translate=""><span>Игрок </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="981" playername="Антон" nolink="" showmissinglink="true">Антон</a><span> покинул королевство.</span></span>
									</td>
									<td>
										<span i18ndt="1707878699" format="short">Сегодня 08:44</span>
									</td>
								</tr>
								<tr ng-repeat="n in news">
									<td>
										<span options="1" data="agentId: 1086, agentName: Mirage, victimId: 3, victimName: Roman" translate=""><span>Игрок </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="1086" playername="Mirage" nolink="" showmissinglink="true">Mirage</a><span> покинул королевство.</span></span>
									</td>
									<td>
										<span i18ndt="1707878674" format="short">Сегодня 08:44</span>
									</td>
								</tr>
								<tr ng-repeat="n in news">
									<td>
										<span options="4" data="agentId: 1163, agentName: nemo, victimId: 3, victimName: Roman" translate=""><span>Игрок </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="1163" playername="nemo" nolink="" showmissinglink="true">nemo</a><span> вступил в королевство.</span></span>
									</td>
									<td>
										<span i18ndt="1707837800" format="short">Вчера 21:23</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tg-pagination" ng-show="visible">
						<ul>
							<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
								<i ng-class="{
									action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
									action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
									disabled: currentPage == 1
								}" class="action_toBeginning_tiny_flat_black disabled"></i>
							</li>
							<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
								<i ng-class="{
									symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
									symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
									disabled: currentPage == 1
								}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
							</li>
							<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
								<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
							</li>
							<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(2)">
								<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}">2</a>
							</li>
							<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(...)">
								<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">...</a>
							</li>
							<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(24)">
								<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}">24</a>
							</li>
							<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
								<i ng-class="{
									symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
									symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
									disabled: disableNext()
								}" class="symbol_arrowTo_tiny_flat_black"></i>
							</li>
							<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
								<i ng-class="{
									action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
									action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
									disabled: disableNext()
								}" class="action_toEnd_tiny_flat_black"></i>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
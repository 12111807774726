<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper reports" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal reports">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/map')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span>Захваченный оазис</span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
				<div class="mapCellDetails" ng-controller="mapCellDetailsCtrl">
	<div dynamic="" scrollable="" class="scrollable"><div class="scrollContentOuterWrapper" style="width: 610px;">
	<div class="scrollContent" dynamic-height="true" style="width: 610px; overflow-y: hidden; max-height: 514px;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
		<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/header.html'"><div class="mapContainer  oasis">
	<!-- ngIf: mapDetails.isWonder -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisIllu type10" ng-if="fieldType == 'oasis'"></div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'npcVillageRobber' -->
	<!-- ngIf: cellData.menhir -->
	<!-- ngIf: fieldType != 'oasis' && fieldType != 'npcVillageRobber' && !mapDetails.isWonder && !cellData.menhir -->
	<!-- ngIf: mapDetails.isWonder -->
	<!-- ngIf: showAttack && attackingTroops.cnt == 0 -->
	<!-- ngIf: attackingTroops.cnt > 0 -->
	<!-- ngIf: mapDetails.npcInfo.type == 8 -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisBonusWrapper" ng-if="fieldType == 'oasis'">
		<div class="oasisBonusContainer" ng-show="mapDetails.oasisBonus[1] != 0" tooltip="" tooltip-translate="wood">
			<i class="oasisBonus unit_wood_large_illu"></i>
			<span>25%</span>
		</div>
		<div class="oasisBonusContainer ng-hide" ng-show="mapDetails.oasisBonus[2] != 0" tooltip="" tooltip-translate="clay">
			<i class="oasisBonus unit_clay_large_illu"></i>
			<span>0%</span>
		</div>
		<div class="oasisBonusContainer ng-hide" ng-show="mapDetails.oasisBonus[3] != 0" tooltip="" tooltip-translate="iron">
			<i class="oasisBonus unit_iron_large_illu"></i>
			<span>0%</span>
		</div>
		<div class="oasisBonusContainer ng-hide" ng-show="mapDetails.oasisBonus[4] != 0" tooltip="" tooltip-translate="crop">
			<i class="oasisBonus unit_crop_large_illu"></i>
			<span>0%</span>
		</div>
		<h6 class="headerTrapezoidal">
			<div class="content" translate=""><span>Бонус</span></div>
		</h6>
	</div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' --><div class="defBonus" ng-if="fieldType == 'oasis'" content="Бонус защиты: 50%" v-tippy="{placement: 'bottom'}" tooltip-translate="CellDetails.Oasis.DefBonus" tooltip-data="bonus:50">
		50<br>%
	</div><!-- end ngIf: fieldType == 'oasis' -->
</div></div>
		<div class="otherContainer  oasis">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/bodyTop.html'"><div class="splitContainer  oasis">
	<!-- ngIf: fieldType == 'village' && mapDetails.wwValues -->
	<!-- ngIf: fieldType == 'village' -->
	<!-- ngIf: fieldType == 'oasis' --><div ng-if="fieldType == 'oasis'">
		<div class="contentBox leftBox">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/oasisDetails.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6>
		<span translate=""><span>Информация</span></span>
		<span class="coordinateWrapper" coordinates="" x="-6" y="11">
	<!-- ngIf: hasLink --><a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537247738_1708112337.3');" class="clickable">
		<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y "><span class="coordinateX">(-6</span><span class="coordinatePipe">|</span><span class="coordinateY">11)</span></span>
	</a><!-- end ngIf: hasLink -->
	<!-- ngIf: !hasLink -->
</span>
	</h6>
</div>
<div class="contentBoxBody oasisDetails occupied" ng-class="{occupied: !animals}">
	<!-- ngIf: animals -->
	<!-- ngIf: !animals --><div ng-if="!animals">
		<div class="owner">
			<span><span translate=""><span>Королевство</span></span>:</span>
			<!-- ngIf: mapDetails.kingdomId <= 0 -->
			<!-- ngIf: mapDetails.kingdomId > 0 --><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="mapDetails.kingdomId > 0" kingdomid="3">Roman</a><!-- end ngIf: mapDetails.kingdomId > 0 -->
		</div>
		<!-- ngRepeat: (rank, details) in mapDetails.playersWithTroops --><div class="arrowContainer arrowDirectionTo small active" ng-repeat="(rank, details) in mapDetails.playersWithTroops" ng-class="{active: details.playerId > 0, inactive: details.playerId == 0}" content="<div class='oasisRankingTooltip'>
					<table class='oasisInfluenceTable transparent'>
						<tbody>
							<tr>
								<th><span>Деревня</span></th>
								<th class='populationDistanceRatio'>
									<i class='unit_population_small_flat_black'></i>
									<i class='unit_distance_small_flat_black'></i>
								</th>
								<th></th>
								<th><i class='unit_influence_small_flat_black'></i></th>
							</tr>
							<tr>
								<td><a class='villageLink truncated clickable disabled'>Антоновка 1</a></td>
								<td class='populationDistanceRatio'>
									<span class='amount wrapper'>405</span>
									<span class='capacity'>2.2</span>
								</td>
								<td>=</td>
								<td>184</td>
							</tr>
							<tr>
								<td><a class='villageLink truncated clickable disabled'>Антоновка 2</a></td>
								<td class='populationDistanceRatio'>
									<span class='amount wrapper'>115</span>
									<span class='capacity'>1.4</span>
								</td>
								<td>=</td>
								<td>82</td>
							</tr>
						</tbody>
					</table>
					<table class='troopsAndTotalTable transparent'>
						<tbody>
							<tr>
								<td><span>Влияние от войск:</span></td>
								<td>?</td>
							</tr>
							<tr>
								<td><span>Общее влияние:</span></td>
								<td>266<span> + ?</span></td>
							</tr>
						</tbody>
					</table>
				</div>" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/mapCellDetails/partials/oasisRankingTooltip.html" tooltip-data-request="requestInfluenceData;981">
			<span class="arrowInside">1<!-- ngIf: ::$last --></span>
			<span class="arrowOutside">
				<!-- ngIf: details.playerId > 0 --><span ng-if="details.playerId > 0"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="981" playername="">Антон</a></span><!-- end ngIf: details.playerId > 0 -->
				<!-- ngIf: details.playerId == 0 -->
				<span class="bonusPercent">25%</span>
			</span>
			<!-- ngIf: details.playerId == player.data.playerId -->
		</div><!-- end ngRepeat: (rank, details) in mapDetails.playersWithTroops --><div class="arrowContainer arrowDirectionTo small active" ng-repeat="(rank, details) in mapDetails.playersWithTroops" ng-class="{active: details.playerId > 0, inactive: details.playerId == 0}" tooltip="" tooltip-url="tpl/mapCellDetails/partials/oasisRankingTooltip.html" tooltip-data-request="requestInfluenceData;528">
			<span class="arrowInside">2<!-- ngIf: ::$last --></span>
			<span class="arrowOutside">
				<!-- ngIf: details.playerId > 0 --><span ng-if="details.playerId > 0"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="528" playername="">Olaf</a></span><!-- end ngIf: details.playerId > 0 -->
				<!-- ngIf: details.playerId == 0 -->
				<span class="bonusPercent">20%</span>
			</span>
			<!-- ngIf: details.playerId == player.data.playerId -->
		</div><!-- end ngRepeat: (rank, details) in mapDetails.playersWithTroops --><div class="arrowContainer arrowDirectionTo small active" ng-repeat="(rank, details) in mapDetails.playersWithTroops" ng-class="{active: details.playerId > 0, inactive: details.playerId == 0}" tooltip="" tooltip-url="tpl/mapCellDetails/partials/oasisRankingTooltip.html" tooltip-data-request="requestInfluenceData;115">
			<span class="arrowInside">3<!-- ngIf: ::$last --></span>
			<span class="arrowOutside">
				<!-- ngIf: details.playerId > 0 --><span ng-if="details.playerId > 0"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="115" playername="">Scepa</a></span><!-- end ngIf: details.playerId > 0 -->
				<!-- ngIf: details.playerId == 0 -->
				<span class="bonusPercent">15%</span>
			</span>
			<!-- ngIf: details.playerId == player.data.playerId -->
		</div><!-- end ngRepeat: (rank, details) in mapDetails.playersWithTroops --><div class="arrowContainer arrowDirectionTo small active" ng-repeat="(rank, details) in mapDetails.playersWithTroops" ng-class="{active: details.playerId > 0, inactive: details.playerId == 0}" content="<div class='oasisRankingTooltip'>
	<table class='oasisInfluenceTable transparent'>
		<tbody><tr>
			<th translate=''><span>Деревня</span></th>
			<th class='populationDistanceRatio'>
				<i class='unit_population_small_flat_black'></i>
				<i class='unit_distance_small_flat_black'></i>
			</th>
			<th></th>
			<th><i class='unit_influence_small_flat_black'></i></th>
		</tr>
		<!-- ngRepeat: (i, data) in ::tooltipRequestData.influenceData --><tr ng-repeat='(i, data) in ::tooltipRequestData.influenceData'>
			<td><a ng-class='{disabled: villId <= 2 || nolink}' class='villageLink truncated clickable disabled' clickable='$root.openWindow('mapCellDetails', {'cellId': 536330225}, null, true)' village-link='' villageid='536330225' villagename='' nolink='true'>КУМЕРТАУ</a></td>
			<td class='populationDistanceRatio' ng-bind-html='0|bidiRatio:data.population:data.distance:true'><span class='amount wrapper'>1286</span>/<span class='capacity'>2.8</span></td>
			<td>=</td>
			<td>459</td>
		</tr><!-- end ngRepeat: (i, data) in ::tooltipRequestData.influenceData --><tr ng-repeat='(i, data) in ::tooltipRequestData.influenceData'>
			<td><a ng-class='{disabled: villId <= 2 || nolink}' class='villageLink truncated clickable disabled' clickable='$root.openWindow('mapCellDetails', {'cellId': 536362993}, null, true)' village-link='' villageid='536362993' villagename='' nolink='true'>маячный</a></td>
			<td class='populationDistanceRatio' ng-bind-html='0|bidiRatio:data.population:data.distance:true'><span class='amount wrapper'>1061</span>/<span class='capacity'>2.2</span></td>
			<td>=</td>
			<td>482</td>
		</tr><!-- end ngRepeat: (i, data) in ::tooltipRequestData.influenceData -->
	</tbody></table>
	<table class='troopsAndTotalTable transparent'>
		<tbody><tr>
			<td translate=''><span>Влияние от войск:</span></td>
			<td><!-- ngIf: ::tooltipRequestData.troopsInfluence != '?' -->?</td>
		</tr>
		<tr>
			<td translate=''><span>Общее влияние:</span></td>
			<td>942<!-- ngIf: ::tooltipRequestData.troopsInfluence == '?' --><span ng-if='::tooltipRequestData.troopsInfluence == '?''> + ?</span><!-- end ngIf: ::tooltipRequestData.troopsInfluence == '?' --></td>
		</tr>
	</tbody></table>
</div>" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/mapCellDetails/partials/oasisRankingTooltip.html" tooltip-data-request="requestInfluenceData;700">
			<span class="arrowInside">4<!-- ngIf: ::$last --></span>
			<span class="arrowOutside">
				<!-- ngIf: details.playerId > 0 --><span ng-if="details.playerId > 0"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="700" playername="">RuleZzZ82</a></span><!-- end ngIf: details.playerId > 0 -->
				<!-- ngIf: details.playerId == 0 -->
				<span class="bonusPercent">10%</span>
			</span>
			<!-- ngIf: details.playerId == player.data.playerId -->
		</div><!-- end ngRepeat: (rank, details) in mapDetails.playersWithTroops --><div class="arrowContainer arrowDirectionTo small active" ng-repeat="(rank, details) in mapDetails.playersWithTroops" ng-class="{active: details.playerId > 0, inactive: details.playerId == 0}" content="<div class='oasisRankingTooltip'>
			<table class='oasisInfluenceTable transparent'>
				<tbody>
					<tr>
						<th><span>Деревня</span></th>
						<th class='populationDistanceRatio'>
							<i class='unit_population_small_flat_black'></i>
							<i class='unit_distance_small_flat_black'></i>
						</th>
						<th></th>
						<th><i class='unit_influence_small_flat_black'></i></th>
					</tr>
					<tr>
						<td><a class='villageLink truncated clickable disabled'>InZectorsk</a></td>
						<td class='populationDistanceRatio'>
							<span class='amount wrapper'>136</span>
							<span class='capacity'>3.6</span>
						</td>
						<td>=</td>
						<td>38</td>
					</tr>
				</tbody>
			</table>
			<table class='troopsAndTotalTable transparent'>
				<tbody>
					<tr>
						<td><span>Влияние от войск:</span></td>
						<td>+0</td>
					</tr>
					<tr>
						<td><span>Общее влияние:</span></td>
						<td>38</td>
					</tr>
				</tbody>
			</table>
		</div>" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/mapCellDetails/partials/oasisRankingTooltip.html" tooltip-data-request="requestInfluenceData;1804">
			<span class="arrowInside">5<!-- ngIf: ::$last --><span ng-if="::$last">+</span><!-- end ngIf: ::$last --></span>
			<span class="arrowOutside">
				<!-- ngIf: details.playerId > 0 --><span ng-if="details.playerId > 0"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="">lexinzector</a></span><!-- end ngIf: details.playerId > 0 -->
				<!-- ngIf: details.playerId == 0 -->
				<span class="bonusPercent">5%</span>
			</span>
			<!-- ngIf: details.playerId == player.data.playerId --><div ng-if="details.playerId == player.data.playerId" class="indicationArrow"></div><!-- end ngIf: details.playerId == player.data.playerId -->
		</div><!-- end ngRepeat: (rank, details) in mapDetails.playersWithTroops -->
	</div><!-- end ngIf: !animals -->
</div></div>
		</div>
		<div class="contentBox rightBox">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/oasisProduction.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6>
		<!-- ngIf: animals && inVillageReach -->
		<!-- ngIf: !animals || !inVillageReach --><span translate="" ng-if="!animals || !inVillageReach"><span>Производство</span></span><!-- end ngIf: !animals || !inVillageReach -->
	</h6>
</div>
<div class="contentBoxBody oasisProduction assigned" ng-class="{assigned: isInTop5}">
	<!-- ngIf: !inVillageReach -->
	<!-- ngIf: inVillageReach && animals -->
	<!-- ngIf: inVillageReach && !animals --><div ng-if="inVillageReach &amp;&amp; !animals">
		<!-- ngIf: !inOwnKingdom -->
		<!-- ngIf: inOwnKingdom && !isInTop5 -->
		<!-- ngIf: inOwnKingdom && isInTop5 --><div ng-if="inOwnKingdom &amp;&amp; isInTop5">
			<div class="villageProduction">
				<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537182205}, null, true)" village-link="" villageid="537182205" villagename="">InZectorsk</a>
				<div class="horizontalLine"></div>
				<!-- ngRepeat: (type, amount) in villageProductionBonus --><div class="resourceBonus" ng-repeat="(type, amount) in villageProductionBonus">
					<span class="bonusValue">&#x202D;&#x202D;5&#x202C;%&#x202C;</span>
					<i class="unit_wood_small_illu" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1"></i>
					<span class="productionValue" translate="" data="value: 52"><span>&nbsp;52/ч</span></span>
				</div><!-- end ngRepeat: (type, amount) in villageProductionBonus --><div class="resourceBonus" ng-repeat="(type, amount) in villageProductionBonus">
					<span class="bonusValue">&#x202D;&#x202D;5&#x202C;%&#x202C;</span>
					<i class="unit_crop_small_illu" content="Зерно" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_4"></i>
					<span class="productionValue" translate="" data="value: 90"><span>&nbsp;90/ч</span></span>
				</div><!-- end ngRepeat: (type, amount) in villageProductionBonus -->
			</div>
			<div class="troopsProduction">
				<!-- ngIf: inOwnKingdom --><div ng-if="inOwnKingdom">
					<span data="troops: 0, max: 50" translate=""><span>Войско (</span><span class="values" ng-bind-html="0 | bidiRatio:troops:max">&lrm;&lrm;0/&lrm;50</span><span>):</span></span>
					<div class="horizontalLine"></div>
					<!-- ngIf: mapDetails.ownTroops.amount > 0 -->
					<!-- ngIf: mapDetails.ownTroops.amount == 0 --><div ng-if="mapDetails.ownTroops.amount == 0">
						<span translate=""><span>Разместив здесь войска, ты можешь произвести дополнительные ресурсы</span></span>
					</div><!-- end ngIf: mapDetails.ownTroops.amount == 0 -->
				</div><!-- end ngIf: inOwnKingdom -->
				<!-- ngIf: !inOwnKingdom -->
			</div>
			<div class="productionTotal">
				<span translate=""><span>Всего:</span></span>
				<!-- ngRepeat: (type, amount) in villageProductionBonus --><div class="resourceBonus" ng-repeat="(type, amount) in villageProductionBonus">
					<i class="unit_wood_small_illu" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1"></i>
					<span class="productionValue" translate="" data="value: 4"><span>&nbsp;52/ч</span></span>
				</div><!-- end ngRepeat: (type, amount) in villageProductionBonus --><div class="resourceBonus" ng-repeat="(type, amount) in villageProductionBonus">
					<i class="unit_crop_small_illu" content="Зерно" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_4"></i>
					<span class="productionValue" translate="" data="value: 4"><span>&nbsp;90/ч</span></span>
				</div><!-- end ngRepeat: (type, amount) in villageProductionBonus -->
			</div>
		</div><!-- end ngIf: inOwnKingdom && isInTop5 -->
	</div><!-- end ngIf: inVillageReach && !animals -->
</div>
</div>
		</div>
		<!-- если меня в оазисе нет -->
		<div class="contentBox rightBox">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/oasisProduction.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6>
		<!-- ngIf: animals && inVillageReach -->
		<!-- ngIf: !animals || !inVillageReach --><span translate="" ng-if="!animals || !inVillageReach"><span>Производство</span></span><!-- end ngIf: !animals || !inVillageReach -->
	</h6>
</div>
<div class="contentBoxBody oasisProduction" ng-class="{assigned: isInTop5}">
	<!-- ngIf: !inVillageReach --><div ng-if="!inVillageReach">
		<span translate=""><span>Оазис не попадает в зону влияния ни одной деревни. Построй новую деревню в пределах досягаемости оазиса или выбери оазис, который находится ближе к твоей деревне. Твой герой, впрочем, может захватить животных в клетки, чтобы они помогали оборонять твою деревню.</span></span>
	</div><!-- end ngIf: !inVillageReach -->
	<!-- ngIf: inVillageReach && animals -->
	<!-- ngIf: inVillageReach && !animals -->
</div>
</div>
		</div>
	</div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->
	<!-- ngIf: fieldType == 'unhabitable' -->
	<!-- ngIf: fieldType == 'habitable' -->
</div>
</div>
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/bodyBottom.html'"><!-- ngIf: fieldType == 'oasis' --><div ng-if="fieldType == 'oasis'">
	<!-- ngIf: animals -->

	<!-- ngRepeat: troopDetails in troopsHere -->
</div><!-- end ngIf: fieldType == 'oasis' -->

<!-- ngIf: fieldType == 'village' && mapDetails.isWonder && !mapDetails.isConquered -->

<div class="tributeCropContainer">
	<div class="tributes contentBox gradient ng-hide" ng-show="showTributes">
		<div class="contentBoxBody">
			<display-resources resources="village.tributes" treasures="village.tributeTreasures" hide-zero="true"><div class="costs max0digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources>
			<div class="barView">
				<div class="actualTributes">&lrm;&lrm;0/&lrm;0</div>
				<div class="populationBar progressbar" progressbar="" type="green" perc="" marker="">
	<!-- ngIf: ::labelIcon -->
	<div class="progress">
		<!-- ngIf: steps -->
		<div class="bar positive perc green" style="z-index: -1; width: 0%;"></div>
		<div class="bar additionalBar additionalPerc"></div>
		<div class="marker" style="z-index: -1; width: 0%;">
			<div class="markerInner"></div>
		</div>
		<!-- ngIf: ::showCountdown -->
		<!-- ngIf: ::label -->
		<!-- ngIf: ::percTooltip -->
	</div>
</div>
			</div>
			<button clickable="fetchTributes()" ng-class="{disabled: !village.canFetchTributes || !village.allowTributeCollection || !village.protectionGranted || !activeTreasury || (!territoryConnected &amp;&amp; village.type != Village.TYPE_GOVERNOR_NPC_VILLAGE)}" play-on-click="16" tooltip="" tooltip-translate-switch="{'ContextMenu.button.fetchTribute.denied': true,
											   'ContextMenu.button.fetchTribute.unprotected': true,
											   'Tributes.VillageNotGeneratingInfluence': true,
											   'Tributes.VillageNotConnected': false}" class="clickable disabled"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Собрать дань</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		</div>
	</div>
	<!-- ngIf: sharedInformations.cropDetails != null -->
</div>

<p ng-show="error" class="error ng-hide">
	
</p>

<!-- ngIf: fieldType == 'village' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->

<!-- ngIf: sharedInformations.stationedTroops.length > 0 --></div>
		</div>
	</div>
	</div>
</div>
<div class="scrollTrack" style="opacity: 0; display: none;">
    <div class="scrollHandle" style="top: 0px; height: 356.908px;"></div>
	<div class="trackDecoration"></div>
</div></div>
	<div class="cellActionContainer">
		<div class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '537247738_1708112337.3'); closeWindow(w.name);">
			<i class="symbol_target_small_flat_black"></i>
		</div>
		<!-- ngIf: options.length > 0 --><div class="options" ng-if="options.length > 0" more-dropdown="" more-dropdown-options="getOptions()" style="position: relative;"><a class="iconButton clickable" clickable="openMoreDropdown()"><!-- ngIf: showCount --><i class="symbol_arrowDown_tiny_illu"></i></a>
			<div class="moreDropDownBody dropdownBody" style="bottom: 0; right: 0; display: block;"><ul><!-- ngRepeat: option in options --><li ng-repeat="option in options" clickable="openWindow('markerMenu',{'cellId': 537247738}); closeMoreDropdown()" class="clickable">Отметить поле</li><!-- end ngRepeat: option in options --></ul></div>
		</div><!-- end ngIf: options.length > 0 -->
		<!-- ngIf: fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' --><button class="sendTroopsButton clickable" ng-if="fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor'" clickable="openWindow('sendTroops', {'x': -6, 'y': 11 })" tooltip="" tooltip-show="false" tooltip-translate="ContextMenu.NoRallyPoint" ng-class="{disabled: !hasRallyPoint}"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Отправить войска</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->
		<!-- ngIf: fieldType == 'habitable' && !cellData.menhir -->
		<!-- ngIf: cellData.menhir && cellData.menhir == player.data.playerId -->
		<!-- ngIf: cellData.menhir && cellData.owner == player.data.playerId -->
	</div>
</div>
			</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
.societyFilter {
	float: right;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Захваченный оазис | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
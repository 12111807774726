<template>
	<div ng-include="" src="'tpl/hero/tabs/CardGame.html'" style="width: 686px;">
																			<div class="cardGame contentBox unselectable" ng-controller="cardGameCtrl">
	<div class="contentBoxBody">
		<div class="cardGameContentWrapper">
			<div class="cardGameContent">
				<div class="dialogActor">
					<div class="dialogActorPortrait">
						<img ng-src="layout/images/x.gif" class="cardGame_fortuneTeller_huge_illu" src="layout/images/x.gif" data-cmp-info="10">
					</div>
					<div class="tooltip textBubble after show">
						<div ng-show="currentState == gameStates.initial" class="tooltipContent ownText" data="playerName: lexinzector"><span>Приветствуем, lexinzector! Выбери карту.</span></div>
						<div ng-show="currentState == gameStates.startPlay" class="tooltipContent ownText ng-hide"><span>Выбери другую, если хочешь.</span></div>
						<div ng-show="currentState == gameStates.selectCard" class="tooltipContent ownText ng-hide"><span>Уверен, что хочешь эту карту?</span></div>
						<div ng-show="currentState == gameStates.play4of5selectCards || currentState == gameStates.play4of5GainCards" class="tooltipContent ownText ng-hide"><span>Выбери любые четыре карты.</span></div>
						<div ng-show="currentState == gameStates.yourWonCard || currentState == gameStates.discardedCards || currentState == gameStates.discardedCardsRemove" class="tooltipContent ownText ng-hide"><span>Ты мог бы выиграть эти карты в следующий раз.</span></div>
					</div>
				</div>
				<div class="freePlayIndicatorWrapper">
					<div class="freePlayIndicatorBackground">
						<div class="freePlayIndicator">
							<div class="freePlayIndicatorContent" tooltip="" tooltip-url="tpl/hero/partials/cardgameFreePlayTooltip.html">
								<!-- ngIf: currentState != gameStates.play4of5selectCards && cardgameRolls.data.freeRolls < 1 && midnight --><div ng-if="currentState != gameStates.play4of5selectCards && cardgameRolls.data.freeRolls < 1 && midnight" data="nextFreePlay:1707778800, callback:updateVoucher"><span>Бесплатная игра через </span><span countdown="1707778800" callback="updateVoucher">07:50:52</span></div><!-- end ngIf: currentState != gameStates.play4of5selectCards && cardgameRolls.data.freeRolls < 1 && midnight -->
								<div ng-show="currentState != gameStates.play4of5selectCards && cardgameRolls.data.freeRolls >= 1" class="freePlays ng-hide">
									<div class="freePlayAmount">×0</div>
									<span><span>бесплатные игры</span></span>
								</div>
								<div ng-show="currentState == gameStates.play4of5selectCards" class="selectCards ng-hide">
									<!-- ngRepeat: index in [1, 2, 3, 4] --><div ng-repeat="index in [1, 2, 3, 4]" class="cardSelectedIndicator cardGame_cardGame_medium_flat_black" ng-class="{cardGame_cardGame_medium_flat_black: amountChosen4of5 < index, cardGame_cardGame_medium_flat_green: amountChosen4of5 >= index}"></div><!-- end ngRepeat: index in [1, 2, 3, 4] --><div ng-repeat="index in [1, 2, 3, 4]" class="cardSelectedIndicator cardGame_cardGame_medium_flat_black" ng-class="{cardGame_cardGame_medium_flat_black: amountChosen4of5 < index, cardGame_cardGame_medium_flat_green: amountChosen4of5 >= index}"></div><!-- end ngRepeat: index in [1, 2, 3, 4] --><div ng-repeat="index in [1, 2, 3, 4]" class="cardSelectedIndicator cardGame_cardGame_medium_flat_black" ng-class="{cardGame_cardGame_medium_flat_black: amountChosen4of5 < index, cardGame_cardGame_medium_flat_green: amountChosen4of5 >= index}"></div><!-- end ngRepeat: index in [1, 2, 3, 4] --><div ng-repeat="index in [1, 2, 3, 4]" class="cardSelectedIndicator cardGame_cardGame_medium_flat_black" ng-class="{cardGame_cardGame_medium_flat_black: amountChosen4of5 < index, cardGame_cardGame_medium_flat_green: amountChosen4of5 >= index}"></div><!-- end ngRepeat: index in [1, 2, 3, 4] -->
									<div>0/4</div>
								</div>
							</div>
						</div>
						<div class="arrow"></div>
					</div>
				</div>
				<div class="cardsWrapper">
					<div class="cardHolder" ng-show="currentState != gameStates.play4of5selectCards">
						<span ng-show="currentState == gameStates.discardedCards" class="ng-hide"><span>Нажми "Продолжить", чтобы получить новые карты</span></span>
						<span ng-show="currentState <= gameStates.selectCard">
							<span ng-show="cardgameRolls.data.freeRolls > 0" class="ng-hide"><span>Играй сейчас бесплатно или</span></span>
							<span ng-show="cardgameRolls.data.freeRolls == 0" data="cost: 5"><span>Выбери одну карту за </span><i class="icon unit_gold_small_illu"></i><span>5 или</span></span>
						</span>
					</div>
					<!-- ngRepeat: index in [1, 2, 3, 4, 5] --><div ng-repeat="index in [1, 2, 3, 4, 5]" ng-class="{disabled: disablePremium,
						  dealCards: currentState <= gameStates.selectCard,
						  chosen: index == chosenCard && currentState == gameStates.yourWonCard,
						  chosen4of5: chosenCards4of5[index - 1] && currentState == gameStates.play4of5selectCards,
						  showRemainingChosen: currentState == gameStates.discardedCards && index == chosenCard,
						  showRemainingDiscarded: currentState == gameStates.discardedCards && index != chosenCard,
						  discardRemaining: currentState == gameStates.discardedCardsRemove && index != chosenCard,
						  hide: currentState == gameStates.discardedCardsRemove && (index == chosenCard || chosenCards4of5[index - 1]),
						  freePlay: iconClasses[cardResults[index].rewardTypeId] == 'prize_freePlay_huge_illu',
						  goldOrSilver: iconClasses[cardResults[index].rewardTypeId] == 'prize_gold_huge_illu' || iconClasses[cardResults[index].rewardTypeId] == 'prize_silver_huge_illu',
						  selectable: currentState == gameStates.play4of5selectCards,
						  gain4of5: currentState == gameStates.play4of5GainCards && chosenCards4of5[index - 1],
						  showAll: currentState == gameStates.play4of5GainCards}" class="card pos1 premium dealCards" premium-feature="cardgameSingle" premium-callback-param="1" on-pointer-over="cardHoverStart()" on-pointer-out="cardHoverStop()" confirm-gold-usage="true">
						<div class="hoverWrapper">
							<div class="front cardContentWrapper cardGame_cardFront_illustration clickable disabled" clickable="chooseCard4of5(index)" ng-class="{disabled: currentState != gameStates.play4of5selectCards}">
								<span class="title" options="0">!!CardGame.Card.Title_0!!</span>
								<!-- ngIf: cardResults[index].rewardAmount > 1 && cardResults[index].rewardAmount <= 99 && noAmountStarTypes.indexOf(cardResults[index].rewardTypeId) < 0 -->
								<div class="icon "></div>
								<span class="description" options="0" data="amount: 0">!!CardGame.Card.Description_0!!</span>
							</div>
							<div class="back cardGame_cardBack_illustration"></div>
						</div>
					</div><!-- end ngRepeat: index in [1, 2, 3, 4, 5] --><div ng-repeat="index in [1, 2, 3, 4, 5]" ng-class="{disabled: disablePremium,
						  dealCards: currentState <= gameStates.selectCard,
						  chosen: index == chosenCard && currentState == gameStates.yourWonCard,
						  chosen4of5: chosenCards4of5[index - 1] && currentState == gameStates.play4of5selectCards,
						  showRemainingChosen: currentState == gameStates.discardedCards && index == chosenCard,
						  showRemainingDiscarded: currentState == gameStates.discardedCards && index != chosenCard,
						  discardRemaining: currentState == gameStates.discardedCardsRemove && index != chosenCard,
						  hide: currentState == gameStates.discardedCardsRemove && (index == chosenCard || chosenCards4of5[index - 1]),
						  freePlay: iconClasses[cardResults[index].rewardTypeId] == 'prize_freePlay_huge_illu',
						  goldOrSilver: iconClasses[cardResults[index].rewardTypeId] == 'prize_gold_huge_illu' || iconClasses[cardResults[index].rewardTypeId] == 'prize_silver_huge_illu',
						  selectable: currentState == gameStates.play4of5selectCards,
						  gain4of5: currentState == gameStates.play4of5GainCards && chosenCards4of5[index - 1],
						  showAll: currentState == gameStates.play4of5GainCards}" class="card pos2 premium dealCards" premium-feature="cardgameSingle" premium-callback-param="2" on-pointer-over="cardHoverStart()" on-pointer-out="cardHoverStop()" confirm-gold-usage="true">
						<div class="hoverWrapper">
							<div class="front cardContentWrapper cardGame_cardFront_illustration clickable disabled" clickable="chooseCard4of5(index)" ng-class="{disabled: currentState != gameStates.play4of5selectCards}">
								<span class="title" options="0">!!CardGame.Card.Title_0!!</span>
								<!-- ngIf: cardResults[index].rewardAmount > 1 && cardResults[index].rewardAmount <= 99 && noAmountStarTypes.indexOf(cardResults[index].rewardTypeId) < 0 -->
								<div class="icon "></div>
								<span class="description" options="0" data="amount: 0">!!CardGame.Card.Description_0!!</span>
							</div>
							<div class="back cardGame_cardBack_illustration"></div>
						</div>
					</div><!-- end ngRepeat: index in [1, 2, 3, 4, 5] --><div ng-repeat="index in [1, 2, 3, 4, 5]" ng-class="{disabled: disablePremium,
						  dealCards: currentState <= gameStates.selectCard,
						  chosen: index == chosenCard && currentState == gameStates.yourWonCard,
						  chosen4of5: chosenCards4of5[index - 1] && currentState == gameStates.play4of5selectCards,
						  showRemainingChosen: currentState == gameStates.discardedCards && index == chosenCard,
						  showRemainingDiscarded: currentState == gameStates.discardedCards && index != chosenCard,
						  discardRemaining: currentState == gameStates.discardedCardsRemove && index != chosenCard,
						  hide: currentState == gameStates.discardedCardsRemove && (index == chosenCard || chosenCards4of5[index - 1]),
						  freePlay: iconClasses[cardResults[index].rewardTypeId] == 'prize_freePlay_huge_illu',
						  goldOrSilver: iconClasses[cardResults[index].rewardTypeId] == 'prize_gold_huge_illu' || iconClasses[cardResults[index].rewardTypeId] == 'prize_silver_huge_illu',
						  selectable: currentState == gameStates.play4of5selectCards,
						  gain4of5: currentState == gameStates.play4of5GainCards && chosenCards4of5[index - 1],
						  showAll: currentState == gameStates.play4of5GainCards}" class="card pos3 premium dealCards" premium-feature="cardgameSingle" premium-callback-param="3" on-pointer-over="cardHoverStart()" on-pointer-out="cardHoverStop()" confirm-gold-usage="true">
						<div class="hoverWrapper">
							<div class="front cardContentWrapper cardGame_cardFront_illustration clickable disabled" clickable="chooseCard4of5(index)" ng-class="{disabled: currentState != gameStates.play4of5selectCards}">
								<span class="title" options="0">!!CardGame.Card.Title_0!!</span>
								<!-- ngIf: cardResults[index].rewardAmount > 1 && cardResults[index].rewardAmount <= 99 && noAmountStarTypes.indexOf(cardResults[index].rewardTypeId) < 0 -->
								<div class="icon "></div>
								<span class="description" options="0" data="amount: 0">!!CardGame.Card.Description_0!!</span>
							</div>
							<div class="back cardGame_cardBack_illustration"></div>
						</div>
					</div><!-- end ngRepeat: index in [1, 2, 3, 4, 5] --><div ng-repeat="index in [1, 2, 3, 4, 5]" ng-class="{disabled: disablePremium,
						  dealCards: currentState <= gameStates.selectCard,
						  chosen: index == chosenCard && currentState == gameStates.yourWonCard,
						  chosen4of5: chosenCards4of5[index - 1] && currentState == gameStates.play4of5selectCards,
						  showRemainingChosen: currentState == gameStates.discardedCards && index == chosenCard,
						  showRemainingDiscarded: currentState == gameStates.discardedCards && index != chosenCard,
						  discardRemaining: currentState == gameStates.discardedCardsRemove && index != chosenCard,
						  hide: currentState == gameStates.discardedCardsRemove && (index == chosenCard || chosenCards4of5[index - 1]),
						  freePlay: iconClasses[cardResults[index].rewardTypeId] == 'prize_freePlay_huge_illu',
						  goldOrSilver: iconClasses[cardResults[index].rewardTypeId] == 'prize_gold_huge_illu' || iconClasses[cardResults[index].rewardTypeId] == 'prize_silver_huge_illu',
						  selectable: currentState == gameStates.play4of5selectCards,
						  gain4of5: currentState == gameStates.play4of5GainCards && chosenCards4of5[index - 1],
						  showAll: currentState == gameStates.play4of5GainCards}" class="card pos4 premium dealCards" premium-feature="cardgameSingle" premium-callback-param="4" on-pointer-over="cardHoverStart()" on-pointer-out="cardHoverStop()" confirm-gold-usage="true">
						<div class="hoverWrapper">
							<div class="front cardContentWrapper cardGame_cardFront_illustration clickable disabled" clickable="chooseCard4of5(index)" ng-class="{disabled: currentState != gameStates.play4of5selectCards}">
								<span class="title" options="0">!!CardGame.Card.Title_0!!</span>
								<!-- ngIf: cardResults[index].rewardAmount > 1 && cardResults[index].rewardAmount <= 99 && noAmountStarTypes.indexOf(cardResults[index].rewardTypeId) < 0 -->
								<div class="icon "></div>
								<span class="description" options="0" data="amount: 0">!!CardGame.Card.Description_0!!</span>
							</div>
							<div class="back cardGame_cardBack_illustration"></div>
						</div>
					</div><!-- end ngRepeat: index in [1, 2, 3, 4, 5] --><div ng-repeat="index in [1, 2, 3, 4, 5]" ng-class="{disabled: disablePremium,
						  dealCards: currentState <= gameStates.selectCard,
						  chosen: index == chosenCard && currentState == gameStates.yourWonCard,
						  chosen4of5: chosenCards4of5[index - 1] && currentState == gameStates.play4of5selectCards,
						  showRemainingChosen: currentState == gameStates.discardedCards && index == chosenCard,
						  showRemainingDiscarded: currentState == gameStates.discardedCards && index != chosenCard,
						  discardRemaining: currentState == gameStates.discardedCardsRemove && index != chosenCard,
						  hide: currentState == gameStates.discardedCardsRemove && (index == chosenCard || chosenCards4of5[index - 1]),
						  freePlay: iconClasses[cardResults[index].rewardTypeId] == 'prize_freePlay_huge_illu',
						  goldOrSilver: iconClasses[cardResults[index].rewardTypeId] == 'prize_gold_huge_illu' || iconClasses[cardResults[index].rewardTypeId] == 'prize_silver_huge_illu',
						  selectable: currentState == gameStates.play4of5selectCards,
						  gain4of5: currentState == gameStates.play4of5GainCards && chosenCards4of5[index - 1],
						  showAll: currentState == gameStates.play4of5GainCards}" class="card pos5 premium dealCards" premium-feature="cardgameSingle" premium-callback-param="5" on-pointer-over="cardHoverStart()" on-pointer-out="cardHoverStop()" confirm-gold-usage="true">
						<div class="hoverWrapper">
							<div class="front cardContentWrapper cardGame_cardFront_illustration clickable disabled" clickable="chooseCard4of5(index)" ng-class="{disabled: currentState != gameStates.play4of5selectCards}">
								<span class="title" options="0">!!CardGame.Card.Title_0!!</span>
								<!-- ngIf: cardResults[index].rewardAmount > 1 && cardResults[index].rewardAmount <= 99 && noAmountStarTypes.indexOf(cardResults[index].rewardTypeId) < 0 -->
								<div class="icon "></div>
								<span class="description" options="0" data="amount: 0">!!CardGame.Card.Description_0!!</span>
							</div>
							<div class="back cardGame_cardBack_illustration"></div>
						</div>
					</div><!-- end ngRepeat: index in [1, 2, 3, 4, 5] -->
				</div>
				<div class="cardGame4of5ButtonWrapper showButton" ng-class="{showButton: currentState != gameStates.play4of5selectCards}">
					<!-- ngIf: currentState != gameStates.discardedCards && currentState != gameStates.play4of5selectCards --><button class="premium cardGame4of5Button" ng-if="currentState != gameStates.discardedCards && currentState != gameStates.play4of5selectCards" ng-class="{disabled: disablePremium}" premium-feature="cardgame4of5" tooltip="" tooltip-translate="CardGame.Button.CardGame4of5.tooltip"><div class="content">
	<span ng-transclude="" class="innerText">
						<span><span>Играй в 4 из 5</span></span>
					</span>
	<!-- ngIf: price > 0 || forceGoldUsage --><span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>20</span><!-- end ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: currentState != gameStates.discardedCards && currentState != gameStates.play4of5selectCards -->
					<!-- ngIf: currentState == gameStates.discardedCards && currentState != gameStates.play4of5selectCards -->
				</div>
				<div ng-show="currentState == gameStates.yourWonCard" class="backgroundOverlay darkener ng-hide"></div>
				<div ng-show="currentState == gameStates.yourWonCard" class="backgroundOverlay clickWrapper clickable ng-hide" clickable="continue()"></div>
			</div>
		</div>
	</div>
</div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div ng-controller="sellMarketplaceCtrl" class="marketContent marketSell">
		<div class="filterWrapper contentBox">
			<div ng-include="'tpl/building/marketplace/partials/merchantsHeader.html'" class="merchantsHeader">
				<div class="headerWithIcon arrowDirectionTo contentBoxHeader">
					<i class="movement_trade_medium_flat_black"></i>
					<div class="marketplaceHeaderGroup">
						<span><span translate=""><span>Доступно</span></span>:</span>
						<div class="circle"><span>1</span></div>
					</div>
					<div class="marketplaceHeaderGroup">
						<span><span translate=""><span>В пути</span></span>:</span>
						<div class="circle"><span>0</span></div>
					</div>
					<div class="marketplaceHeaderGroup">
						<span><span translate=""><span>Торгуют</span></span>:</span>
						<div class="circle"><span>0</span></div>
					</div>
				</div>
			</div>
			<div class="createOffer contentBoxBody">
				<div class="filterBox">
					<div class="offerBox">
						<label for="marketNewOfferOfferedAmount">
							<span translate=""><span>Предложить</span></span>
							<span resource-filter="" resource-obj="filter.offer" deselectable="false" on-change="filterChanged()">
								<div class="resourceFilter filterBar">
									<a class="filter iconButton clickable" clickable="select(1);" ng-class="{active: resourceObj.type == 1, disabled: disabledEntry == 1}" tooltip="" tooltip-translate="Resource_1">
										<i class="unit_wood_small_illu"></i>
									</a>
									<a class="filter iconButton clickable active" clickable="select(2);" ng-class="{active: resourceObj.type == 2, disabled: disabledEntry == 2}" tooltip="" tooltip-translate="Resource_2">
										<i class="unit_clay_small_illu"></i>
									</a>
									<a class="filter iconButton clickable" clickable="select(3);" ng-class="{active: resourceObj.type == 3, disabled: disabledEntry == 3}" tooltip="" tooltip-translate="Resource_3">
										<i class="unit_iron_small_illu"></i>
									</a>
									<a class="filter iconButton clickable" clickable="select(4);" ng-class="{active: resourceObj.type == 4, disabled: disabledEntry == 4}" tooltip="" tooltip-translate="Resource_4">
										<i class="unit_crop_small_illu"></i>
									</a>
								</div>
							</span>
						</label>
						<input id="marketNewOfferOfferedAmount" ng-model="newOffer.offeredAmount" number="750" auto-focus="" class="ng-pristine ng-valid ng-touched" type="tel" />
					</div>
					<div class="infoBox">
						<div class="rateWrapper">
							<div class="header" translate=""><span>Пропорция</span></div>
							<div class="rate">1,00</div>
						</div>
						<div class="merchantsNeededWrapper">
							<div class="header" translate=""><span>Торговцы</span></div>
							<div class="merchantsNeeded">
								1
							</div>
						</div>
					</div>
					<div class="searchBox">
						<label for="marketNewOfferSearchedAmount">
							<span translate=""><span>Искать</span></span>
							<span resource-filter="" resource-obj="filter.search" deselectable="false" on-change="filterChanged()">
								<div class="resourceFilter filterBar">
									<a class="filter iconButton clickable active" clickable="select(1);" ng-class="{active: resourceObj.type == 1, disabled: disabledEntry == 1}" tooltip="" tooltip-translate="Resource_1">
										<i class="unit_wood_small_illu"></i>
									</a>
									<a class="filter iconButton clickable" clickable="select(2);" ng-class="{active: resourceObj.type == 2, disabled: disabledEntry == 2}" tooltip="" tooltip-translate="Resource_2">
										<i class="unit_clay_small_illu"></i>
									</a>
									<a class="filter iconButton clickable" clickable="select(3);" ng-class="{active: resourceObj.type == 3, disabled: disabledEntry == 3}" tooltip="" tooltip-translate="Resource_3">
										<i class="unit_iron_small_illu"></i>
									</a>
									<a class="filter iconButton clickable" clickable="select(4);" ng-class="{active: resourceObj.type == 4, disabled: disabledEntry == 4}" tooltip="" tooltip-translate="Resource_4">
										<i class="unit_crop_small_illu"></i>
									</a>
								</div>
							</span>
						</label>
						<input id="marketNewOfferSearchedAmount" ng-model="newOffer.searchedAmount" number="99999" class="ng-valid ng-dirty ng-valid-parse ng-touched" type="tel" />
					</div>
				</div>
				<div class="parameters">
					<div class="limitDuration wrapper">
						<input id="limitDurationValue" ng-model="newOffer.limitDurationValue" number="99" class="ng-pristine ng-untouched ng-valid" type="tel" />
						<label for="limitDurationValue">
							<span translate=""><span>Макс. длительность</span></span>
						</label>
					</div>
					<div class="allyOnly wrapper ng-hide" ng-show="player.data.kingdomId > 0 && ((currentServerTime-player.data.spawnedOnMap)/86400) >= 7">
						<div class="clickInputWrapper">
							<input type="checkbox" id="limitKingdom" ng-model="newOffer.limitKingdom" class="ng-pristine ng-untouched ng-valid" /><i class="action_check_tiny_flat_black"></i>
						</div>
						<label for="limitKingdom">
							<span translate=""><span>Только свое королевство</span></span>
						</label>
					</div>
					<button class="createOfferBtn clickable" clickable="createOffer();" ng-class="{disabled: player.isOnVacation() || newOffer.searchedAmount <= 0 || newOffer.offeredAmount <= 0}" tooltip="" tooltip-show="false" tooltip-translate="Vacation.Tooltip.FeatureDisabled">
						<div class="content">
							<span ng-transclude="" class="innerText">
								<span translate=""><span>Продать</span></span>
							</span>
							<div class="animationOverlay"></div>
							<i class="checkmark action_check_small_flat_white"></i>
							<i class="checkmark action_check_small_flat_black"></i>
							<i class="checkmark action_check_small_flat_red"></i>
						</div>
					</button>
				</div>
			</div>
		</div>
		<div class="ownOffers">
			<table class="tradeTable">
				<thead>
					<tr>
						<th><span>Дает</span></th>
						<th><span>Пропорция</span></th>
						<th><span>Ищет</span></th>
						<th><i class="movement_trade_small_flat_black" content="Торговцы" v-tippy="{placement: 'top'}" tooltip-translate="Marketplace.Merchant" tooltip-placement="above"></i></th>
						<th><span>Королевство</span></th>
						<th><i class="symbol_clock_small_flat_black" content="Время" v-tippy="{placement: 'top'}" tooltip-translate="Duration" tooltip-placement="above"></i></th>
						<th class="actions"><span>Действие</span></th>
					</tr>
				</thead>
				<tbody ng-repeat="offer in ownOffers">
					<tr>
						<td class="nowrap"><i class="unit_iron_small_illu"></i> 750</td>
						<td><span>1,00</span></td>
						<td class="nowrap"><i class="unit_wood_small_illu"></i> 750</td>
						<td>1</td>
						<td>
							<span ng-show="offer.data.limitKingdom" class="ng-hide"><span>Да</span></span>
							<span ng-show="!offer.data.limitKingdom"><span>Нет</span></span>
						</td>
						<td>
							<span ng-show="!offer.data.limitDuration">--</span>
							<span ng-show="offer.data.limitDuration" class="ng-hide"><span translate="" data="hours: 0"><span>0ч</span></span></span>
						</td>
						<td class="actions">
							<i class="deleteOffer clickable action_delete_small_flat_black" ng-class="{action_delete_small_flat_black: !deleteHover, action_delete_small_flat_red: deleteHover}" on-pointer-over="deleteHover = true" on-pointer-out="deleteHover = false" clickable="cancelOffer(28476);" content="Удалить предложение" v-tippy="{placement: 'bottom'}" tooltip-translate="Marketplace.Sell.DeleteOffer"></i>
						</td>
					</tr>
				</tbody>
				<tbody ng-repeat="offer in ownOffers">
					<tr>
						<td class="nowrap"><i class="unit_iron_small_illu"></i> 750</td>
						<td><span>1,00</span></td>
						<td class="nowrap"><i class="unit_clay_small_illu"></i> 750</td>
						<td>1</td>
						<td>
							<span ng-show="offer.data.limitKingdom" class="ng-hide"><span>Да</span></span>
							<span ng-show="!offer.data.limitKingdom"><span>Нет</span></span>
						</td>
						<td>
							<span ng-show="!offer.data.limitDuration">--</span>
							<span ng-show="offer.data.limitDuration" class="ng-hide"><span translate="" data="hours: 0"><span>0ч</span></span></span>
						</td>
						<td class="actions">
							<i class="deleteOffer clickable action_delete_small_flat_black" ng-class="{action_delete_small_flat_black: !deleteHover, action_delete_small_flat_red: deleteHover}" on-pointer-over="deleteHover = true" on-pointer-out="deleteHover = false" clickable="cancelOffer(28477);" content="Удалить предложение" v-tippy="{placement: 'bottom'}" tooltip-translate="Marketplace.Sell.DeleteOffer"></i>
						</td>
					</tr>
				</tbody>
				<tbody></tbody>
			</table>
		</div>
		<div class="error ng-hide" ng-show="sellMarketError">
			<i class="symbol_warning_tiny_flat_red"></i>
			Мало торговцев!
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="cardGame contentBox unselectable">
		<div class="contentBoxBody">
			<div class="cardGameContentWrapper">
				<div class="cardGameContent">
					<div class="dialogActor">
						<div class="dialogActorPortrait">
							<img class="cardGame_fortuneTeller_huge_illu" src="//iwstatic.g.bsrv.su/img/x.gif" data-cmp-info="10"/>
						</div>
						<div class="tooltip textBubble after show">
							<div v-if="currentState == CardGameModel.STATE_INITIAL" class="tooltipContent ownText">
								<span>Приветствую, {{gameModel.player.username}}! Выбери карту.</span>
							</div>
							<div v-if="currentState == CardGameModel.STATE_START_PLAY" class="tooltipContent ownText">
								<span>Выбери другую, если хочешь.</span>
							</div>
							<div v-if="currentState == CardGameModel.STATE_SELECT_CARD" class="tooltipContent ownText">
								<span>Уверен, что хочешь эту карту?</span>
							</div>
							<div v-if="currentState == CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS || currentState == CardGameModel.STATE_PLAY_4_OF_5_GAIN_CARDS" class="tooltipContent ownText">
								<span>Выбери любые четыре карты.</span>
							</div>
							<div v-if="currentState == CardGameModel.STATE_YOUR_WON_CARD || currentState == CardGameModel.STATE_DISCARDED_CARDS || currentState == CardGameModel.STATE_DISCARDED_CARDS_REMOVE" class="tooltipContent ownText">
								<span>Ты мог бы выиграть эти карты в следующий раз.</span>
							</div>
						</div>
					</div>
					<div class="freePlayIndicatorWrapper">
						<div class="freePlayIndicatorBackground">
							<div class="freePlayIndicator">
								<div class="freePlayIndicatorContent" :content="tooltips[1]" v-tippy="{placement: 'bottom'}">
									<div v-if="currentState != CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS && currentState != CardGameModel.STATE_PLAY_4_OF_5_GAIN_CARDS && cardgameRolls.freeRolls <= 0">
										<span>Бесплатная игра через </span>
										<span :countdown="nextFreePlay" callback="updateVoucher">{{formatUnixDate(nextFreePlay, 'HH:mm:ss')}}</span>
									</div>
									<div v-if="currentState != CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS && currentState != CardGameModel.STATE_PLAY_4_OF_5_GAIN_CARDS && cardgameRolls.freeRolls > 0" class="freePlays">
										<div class="freePlayAmount">×{{cardgameRolls.freeRolls}}</div>
										<span><span>бесплатные игры</span></span>
									</div>
									<div v-if="currentState == CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS || currentState == CardGameModel.STATE_PLAY_4_OF_5_GAIN_CARDS" class="selectCards">
										<div v-for="index in range(1, 4)" class="cardSelectedIndicator" :class="{
											'cardGame_cardGame_medium_flat_black': amountChosen4of5 < index,
											'cardGame_cardGame_medium_flat_green': amountChosen4of5 >= index
										}"></div>
										<div>{{amountChosen4of5}}/4</div>
									</div>
								</div>
							</div>
							<div class="arrow"></div>
						</div>
					</div>
					<div class="cardsWrapper">
						<div class="cardHolder" v-if="currentState != CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS">
							<span v-if="currentState == CardGameModel.STATE_DISCARDED_CARDS">
								<span>Нажми "Продолжить", чтобы получить новые карты</span>
							</span>
							<span v-if="currentState <= CardGameModel.STATE_SELECT_CARD">
								<span v-if="cardgameRolls.freeRolls > 0">
									<span>Играй сейчас бесплатно или</span>
								</span>
								<span v-if="cardgameRolls.freeRolls == 0">
									<span>Выбери одну карту за </span>
									<i class="icon unit_gold_small_illu"></i>
									<span>{{cardgameRollsCost}} или</span>
								</span>
							</span>
						</div>
						<div v-for="index in range(0, 4)" class="card" :class="['pos'+(index + 1), cardgameType, {
							'disabled': disablePremium,
							'dealCards': currentState <= CardGameModel.STATE_SELECT_CARD,
							'chosen': index == chosenCard && currentState == CardGameModel.STATE_YOUR_WON_CARD,
							'chosen4of5': chosenCards4of5[index] && currentState == CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS,
							'showRemainingChosen': currentState == CardGameModel.STATE_DISCARDED_CARDS && index == chosenCard,
							'showRemainingDiscarded': currentState == CardGameModel.STATE_DISCARDED_CARDS && index != chosenCard,
							'discardRemaining': currentState == CardGameModel.STATE_DISCARDED_CARDS_REMOVE && index != chosenCard,
							'hide': currentState == CardGameModel.STATE_DISCARDED_CARDS_REMOVE && (index == chosenCard || chosenCards4of5[index]),
							'freePlay': cardResults[index] && cardResults[index].icon == 'freePlay',
							'goldOrSilver': cardResults[index] && (cardResults[index].icon == 'gold' || cardResults[index].icon == 'silver'),
							'selectable': currentState == CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS,
							'gain4of5': currentState == CardGameModel.STATE_PLAY_4_OF_5_GAIN_CARDS && chosenCards4of5[index],
							'showAll': currentState == CardGameModel.STATE_PLAY_4_OF_5_GAIN_CARDS,
						}]" @mouseover="onCardHoverStart()" @mouseleave="onCardHoverStop()" confirm-gold-usage="true" @click="onClickCard(index)">
							<div class="hoverWrapper">
								<div class="front cardContentWrapper cardGame_cardFront_illustration clickable" :class="{
									'disabled': currentState != CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS,
								}">
									<span class="title">
										<span>{{cardResults[index] ? cardResults[index].name : 'title'}}</span>
									</span>
									<div class="amountContainer" v-if="cardResults[index].amount > 1 && cardResults[index].amount <= 99/* && noAmountStarTypes.indexOf(cardResults[index].typeId) < 0*/">
									<i class="cardGame_amountStar_large_illu"></i>
										<span>x2</span>
									</div>
									
									<div class="icon" :class="[cardResults[index].icon+'_huge_illu']" v-if="cardResults[index] && cardResults[index].icon"></div>
									<span class="description">
										<span>{{cardResults[index] ? cardResults[index].description : 'description'}}</span>
									</span>
								</div>
								<div class="back cardGame_cardBack_illustration"></div>
							</div>
						</div>
					</div>
					<div class="cardGame4of5ButtonWrapper" :class="{'showButton': currentState != CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS}">
						<button v-if="currentState != CardGameModel.STATE_DISCARDED_CARDS && currentState != CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS" class="premium cardGame4of5Button clickable" @click="onChooseCard4of5()" :class="{'disabled': disablePremium}" premium-feature="cardgame4of5" content="В режиме &quot;4 из 5&quot; все карты будут открыты и ты можешь выбрать четыре из них" v-tippy="{placement: 'top'}">
							<div class="content">
								<span class="innerText">
									<span><span>Играй в 4 из 5</span></span>
								</span>
								<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>20</span>
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
						<button v-if="currentState == CardGameModel.STATE_DISCARDED_CARDS" class="continueButton clickable" @click="onResetToStartPlay()" content="Выбрать новые карты" v-tippy="{placement: 'top'}" tooltip-translate="CardGame.Button.Continue.tooltip">
							<div class="content">
								<span class="innerText">
									<span translate=""><span>Продолжить</span></span>
								</span>
								<!-- ngIf: price > 0 || forceGoldUsage -->
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
					<div v-if="currentState == CardGameModel.STATE_YOUR_WON_CARD" class="backgroundOverlay darkener"></div>
					<div v-if="currentState == CardGameModel.STATE_YOUR_WON_CARD" class="backgroundOverlay clickWrapper clickable" @click="onContinue()"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import CardGameModel from '@/models/game/CardGameModel';

export default {
	mixins: lib.mixins,
	data: () => ({
		CardGameModel,
		
		cardHover: false,
		currentState: null,
		cardgameRolls: {
			freeRolls: 1,
		},
		cardgameType: 'free', // free, premium
		cardgameRollsCost: 5,
		nextFreePlay: 1723386346,
		disablePremium: false,
		chosenCard: null,
		chosenCards4of5: [
			false,
			false,
			false,
			false,
			false,
		],
		
		cardResults: [],
		
		tooltips: {
			1: `<div class="tooltipContent"><span translate="" data="cost: 5"><span>Ты получишь одну бесплатную карту в день. Каждая дополнительная карта стоит </span><i class="icon unit_gold_small_illu"></i><span>5</span></span>
<hr ng-show="cardgameRolls.data.freeRolls > 0 && !cardgameRolls.data.hasDailyRoll" class="ng-hide">
<span translate="" ng-show="cardgameRolls.data.freeRolls > 0 && !cardgameRolls.data.hasDailyRoll" data="nextFreePlay:1723413600" class="ng-hide"><span>Бесплатная игра через </span><span countdown="1723413600" callback="">07:38:07</span></span></div>`,
		},
	}),
	computed: {
		amountChosen4of5(){
			return this.chosenCards4of5.filter(el => el).length;
		},
	},
	methods: {
		range: lib.range,
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
		
		onCardHoverStart(){
			if(this.currentState <= CardGameModel.STATE_SELECT_CARD){
				this.cardHover = true;
				this.currentState = CardGameModel.STATE_SELECT_CARD;
			}
		},
		onCardHoverStop(){
			if(this.currentState <= CardGameModel.STATE_SELECT_CARD){
				this.cardHover = false;
				this.currentState = CardGameModel.STATE_INITIAL;
			}
		},
		
		onClickCard(index){
			if(this.currentState == CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS){
				this.onToggleCard4of5(index);
			} else if(this.currentState <= CardGameModel.STATE_SELECT_CARD){
				this.onChooseCard(index);
			}
		},
		onChooseCard(index){
			if(confirm('Вы уверены?')){
				
				for(let ind of lib.range(0, 4)){
					let reward = this.gameModel.cardGame.rewards[lib.mt_rand(0, this.gameModel.cardGame.rewards.length - 1)];
					while(this.cardResults.findIndex(el => el.typeId == reward.typeId) != -1){
						reward = this.gameModel.cardGame.rewards[lib.mt_rand(0, this.gameModel.cardGame.rewards.length - 1)];
					}
					this.cardResults[ind] = reward;
				}
				this.chosenCard = index;
				this.currentState = CardGameModel.STATE_YOUR_WON_CARD;
				this.disablePremium = true;
				if(this.cardgameType == 'free'){
					if(this.cardgameRolls.freeRolls > 0){
						this.cardgameRolls.freeRolls--;
					}
					if(this.cardgameRolls.freeRolls <= 0){
						this.cardgameType = 'premium';
					}
				} else {
					// списываем 5 золотых
				}
			}
		},
		onChooseCard4of5(){
			if(confirm('Вы уверены?')){
				for(let ind of lib.range(0, 4)){
					let reward = this.gameModel.cardGame.rewards[lib.mt_rand(0, this.gameModel.cardGame.rewards.length - 1)];
					while(this.cardResults.findIndex(el => el.typeId == reward.typeId) != -1){
						reward = this.gameModel.cardGame.rewards[lib.mt_rand(0, this.gameModel.cardGame.rewards.length - 1)];
					}
					this.cardResults[ind] = reward;
				}
				this.currentState = CardGameModel.STATE_PLAY_4_OF_5_SELECT_CARDS;
				this.disablePremium = true;
				
				// списываем 20 золотых
			}
		},
		onToggleCard4of5(index){
			if(this.amountChosen4of5 < 4){
				this.chosenCards4of5[index] = !this.chosenCards4of5[index];
			}
			if(this.amountChosen4of5 == 4){
				this.currentState = CardGameModel.STATE_PLAY_4_OF_5_GAIN_CARDS;
				
				setTimeout(() => {
					this.onResetToStartPlay();
				}, 1000);
			}
		},
		onContinue(){
			this.currentState = CardGameModel.STATE_DISCARDED_CARDS;
		},
		onResetToStartPlay(){
			this.currentState = CardGameModel.STATE_DISCARDED_CARDS_REMOVE;
			
			setTimeout(() => {
				this.currentState = CardGameModel.STATE_INITIAL;
				this.disablePremium = false;
				this.chosenCard = null;
				this.chosenCards4of5 = [
					false,
					false,
					false,
					false,
					false,
				];
				this.clearResults();
			}, 1000);
		},
		clearResults(){
			for(let index of lib.range(0, 4)){
				this.cardResults[index] = {
					typeId: 0,
					amount: 0,
					name: '',
					description: '',
					icon: '',
				};
			}
		},
	},
	created(){
		this.clearResults();
		this.currentState = CardGameModel.STATE_INITIAL;
	},
	components: {},
}
</script>
<template>
	<div class="buildingDetails">
		<div ng-include="" src="'tpl/building/partials/buildingInformationEffects.html'">
			<div class="contentBox gradient buildingEffect" ng-if="currentEffect.values[0] != undefined && (building.data.lvl > 0 || building.data.buildingType <= 4 || building.data.buildingType == 42)">
				<h6 class="contentBoxHeader headerColored">
					<span><span>Целебный шатер</span></span>
				</h6>
				<div class="contentBoxBody">
					<div class="current">
						<h6 class="headerTrapezoidal">
							<div class="content"><span>Сейчас</span></div>
						</h6>
						<div class="currentLevel"><span>Уровень 1</span></div>
						<div class="value">
							100
						</div>
						<div class="unit"><span>Количество мест</span></div>
					</div>
					<div class="nextLvl">
						<h6 class="headerTrapezoidal">
							<div class="content"><span>Следующие уровни</span></div>
						</h6>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>2</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span data="value:125"><span>Количество мест: 125</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>3</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span data="value:150"><span>Количество мест: 150</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>4</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span data="value:190"><span>Количество мест: 190</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>5</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span data="value:230"><span>Количество мест: 230</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>6</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span data="value:280"><span>Количество мест: 280</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>7</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span data="value:350"><span>Количество мест: 350</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<!--8-->
						<!--9-->
						<!--10-->
						<!--11-->
						<!--12-->
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>13</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span data="value:350"><span>Количество мест: 1200</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>14</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span options="46" data="value:1500"><span>Количество мест: 1500</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>15</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span options="46" data="value:1800"><span>Количество мест: 1800</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>16</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span options="46" data="value:2200"><span>Количество мест: 2200</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>17</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span options="46" data="value:2700"><span>Количество мест: 2700</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>18</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span options="46" data="value:3400"><span>Количество мест: 3400</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
							<tbody>
								<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
									<th>19</th>
									<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
									<td>
										<span options="46" data="value:4200"><span>Количество мест: 4200</span></span>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="maxLvl" ng-if="building.data.isMaxLvl && building.data.lvl == building.data.lvlMax">
							<span><span>Максимальный уровень</span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="buildingEffect allInVillage contentBox gradient">
			<h6 class="contentBoxHeader headerWithIcon arrowDirectionTo">
				<span><span>Целебные шатры в выбранной деревне</span></span>
			</h6>
			<div class="contentBoxBody">
				<div class="capacity">
					<h6 class="headerTrapezoidal">
						<div class="content"><span>Вместимость</span></div>
					</h6>
					<div class="value">100</div>
					<div class="unit"><span>Количество мест</span></div>
				</div>
				<div class="occupiedSeats">
					<h6 class="headerTrapezoidal">
						<div class="content"><span>Занятые места</span></div>
					</h6>
					<table class="transparent capacityTable">
						<tbody>
							<tr>
								<td>2</td>
								<td>/</td>
								<td>100</td>
							</tr>
							<tr>
								<td><span>Войска</span></td>
								<td>/</td>
								<td><span>Вместимость</span></td>
							</tr>
						</tbody>
					</table>
					<div class="cropConsumption">
						<i class="unit_consumption_small_flat_black"></i>
						<span data="value:0"><span>Потребление: 2/ч.</span></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Ратуша</span></span>
									<span class="level">
										(<span><span>Уровень 0</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>В ратуше ты можешь организовать для своих жителей великолепные праздники. Этим ты увеличишь количество единиц культуры.</span>
								</div>
								<img class="buildingHuge buildingType24 tribeId3 level1" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_34">
								<div class="buildingStatus location20 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">1</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="buildingIndexCtrl" class="building buildingType24" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
														<div ng-include="" src="pageTemplate">
															<div class="buildingDetails townHall" ng-controller="celebrationsStartCtrl">
																<div ng-show="isBuildingBuild()">
																	<div ng-include="" src="'tpl/building/partials/lists/celebrations.html'">
																		<div class="contentBox gradient celebrationsStart celebrationList">
																			<div class="contentBoxBody">
																				<carousel-celebration :items="celebrations" @on-active-item="(item) => activeItem = item" :cols="2" />
																				<div v-if="activeItem">
																					<div class="celebrationBox contentBox gradient">
																						<div class="contentBoxBody">
																							<div class="celebrationLight" :class="{
																								'running': totalCelebrationCount > 0,
																							}">
																								<span v-if="totalCelebrationCount == 0"><span>Сейчас не проводятся праздники.</span></span>
																								<span v-if="totalCelebrationCount > 0"><span>В деревне проводится праздник!</span></span>
																							</div>
																							<div class="horizontalLine"></div>
																							<div class="infoBox">
																								<span v-if="totalCelebrationCount == 0 && !plusAccount"><span>Ты можешь провести 1 праздник!</span></span>
																								<!-- ngIf: totalCelebrationCount == 0 && plusAccount -->
																								<table v-if="totalCelebrationCount > 0" class="transparent">
																									<tbody>
																										<tr>
																											<th><span>Праздник дал тебе:</span></th>
																											<td>
																												<i class="unit_culturePoint_small_illu" content="Единицы культуры" v-tippy="{placement: 'bottom'}" tooltip-translate="MainBuilding.CulturePoints"></i>
																												<span>&nbsp;{{activeItem.culturePoints}}</span>
																											</td>
																										</tr>
																										<tr>
																											<th><span>Будет длиться еще:</span></th>
																											<td countdown="1723643172">22:14:31</td>
																										</tr>
																										<!-- ngIf: plusAccount -->
																									</tbody>
																								</table>
																							</div>
																						</div>
																					</div>
																					<div class="itemInfo">
																						<table class="transparent">
																							<tbody>
																								<tr>
																									<th><span>Провести праздник:</span></th>
																									<td>
																										<i class="unit_culturePoint_small_illu" tooltip="Единицы культуры" v-tippy="{placement: 'bottom'}" tooltip-translate="MainBuilding.CulturePoints"></i>
																										<span class="reward">&nbsp;{{activeItem.culturePoints}}</span>
																										<span>&nbsp;</span>
																										<div class="symbol_i_tiny_wrapper">
																											<i class="symbol_i_tiny_flat_white" :content="activeItem.description" v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{
																												'TownHall.Celebration.Tooltip.RewardSmall': true,
																												'TownHall.Celebration.Tooltip.RewardLarge': false
																											}" tooltip-data="max:500"></i>
																										</div>
																									</td>
																								</tr>
																								<tr>
																									<th><span>Длительность:</span></th>
																									<td>{{formatSecondsDate(activeItem.duration, 'hh:mm:ss')}}</td>
																								</tr>
																								<tr>
																									<th>
																										<span><span>Бонус одобрения: </span></span>
																										<div class="symbol_i_tiny_wrapper">
																											<i class="symbol_i_tiny_flat_white" content="Торжество дает бонус при захвате деревень и городов противника, а также помогает защититься от захватов." v-tippy="{placement: 'bottom'}" tooltip-translate="TownHall.Celebration.Tooltip.LoyaltyBonus"></i>
																										</div>
																									</th>
																									<td>
																										<span ng-if="activeItem.type == celebrationCfg.celebrationTypeSmall">-</span>
																										<span ng-if="activeItem.type == celebrationCfg.celebrationTypeBig">
																											<span>5% </span>
																											<span><span>(пока идет праздник)</span></span>
																										</span>
																									</td>
																								</tr>
																							</tbody>
																						</table>
																					</div>
																					<div class="requiredBuildings">
																						<h4><span>Требования</span></h4>
																						<span class="buildingInfo" tooltip="Текущий уровень здания: 1" tooltip-translate="Tooltip.CurrentLevel" tooltip-data="number:1" v-for="(i, building) in activeItem.requiredBuildings">
																							<a clickable="openWindow('help', {'pageId': 'Buildings_' + building.buildingType})"  class="clickable">
																								<span>{{building.name}}</span>
																							</a>
																							<span><span>&nbsp;Уровень {{building.level}}</span></span>
																							<span v-if="!building.valid" class="neededLevels">&nbsp;(+9)</span>
																						</span>
																						<span v-for="requirement in activeItem.otherRequirements">
																							<span>, </span>
																							<span data="required:2,current:3" options="TownHall.Celebration.VillageRequirement">
																								<span>{{requirement.name}}: {{requirement.value}}</span>
																							</span>
																							<!-- ngIf: requirement.valid === false -->
																						</span>
																						<span v-if="activeItem.requiredBuildings.length <= 0 && activeItem.otherRequirements.length <= 0"><span>Нет</span></span>
																					</div>
																					<display-resources class="costsFooter" :resources="activeItem.costs" available="storage" />
																				</div>
																			</div>
																		</div>
																		<button :class="{
																			'disabled': celebrationQueueFull || !activeItem || activeItem.disabled || !gameModel.island.storage.hasEnoughResources(activeItem.costs),
																		}" class="animate footerButton clickable" clickable="startCelebration(activeItem)" :content="!activeItem ? 'Выбери празднование' : (!gameModel.island.storage.hasEnoughResources(activeItem.costs) ? 'Недостатчно ресурсов' : (celebrationQueueFull ? 'Другой праздник пока нельзя начать' : ''))" v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{
																			'TownHall.Celebration.QueueFull': true,
																			'Academy.NothingSelected': false,
																			'TownHall.Celebration.UpgradeTownHall': false,
																			'TownHall.Celebration.VillagesRequired': false,
																			'Error.NotEnoughRes': true
																		}">
																			<div class="content">
																				<span class="innerText">
																					<span><span>Праздновать</span></span>
																				</span>
																				<!-- ngIf: price > 0 || forceGoldUsage -->
																				<!-- ngIf: price == -1 && !forceGoldUsage -->
																				<div class="animationOverlay"></div>
																				<i class="checkmark action_check_small_flat_white"></i>
																				<i class="checkmark action_check_small_flat_black"></i>
																				<i class="checkmark action_check_small_flat_red"></i>
																			</div>
																		</button>
																		<npc-trader-button class="footerButton" type="celebration" use-npc="true" :costs="activeItem.costs" :current-max="0" :current-limit="0" v-if="activeItem" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import CarouselCelebration from '@/components/elements/CarouselCelebration';
import DisplayResources from '@/components/elements/DisplayResources';
import RangeSlider from '@/components/elements/RangeSlider';
import NpcTraderButton from '@/components/elements/NpcTraderButton';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Ратуша | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
		
		celebrations: [
			{
				type: 1, //1, 2, 3
				name: 'Праздник',
				description: 'За маленький праздник ты получишь единицы культуры, равные тому, сколько производит эта деревня ежедневно, но не больше 500.',
				isTeaHouse: false,
				disabled: false,
				culturePoints: 500,
				requiredLevel: 0,
				queue: {
					count: 1,
				},
				costs: [
					3800,
					4000,
					3030,
					9500,
				],
				duration: 86400,
				time: 1723643172,
				requiredBuildings: [],
				otherRequirements: [],
			},
			{
				type: 2, //1, 2, 3
				name: 'Торжество',
				description: 'За торжество ты получишь единицы культуры, равные тому, сколько производит вся учетная запись ежедневно, но не больше 2000.',
				isTeaHouse: false,
				disabled: true,
				culturePoints: 2000,
				requiredLevel: 10,
				queue: {
					count: 0,
				},
				costs: [
					16200,
					20250,
					17500,
					47700,
				],
				duration: 216000,
				time: 1723643172,
				requiredBuildings: [
					{
						name: 'Ратуша',
						level: 10,
						valid: false,
					},
				],
				otherRequirements: [
					{
						name: 'Мин. деревень',
						value: 2,
					},
				],
			},
		],
		
		activeItem: null,
		celebrationQueueFull: true,
		plusAccount: false,
		
		tooltips: {
			1: `<div class="upgradeCostsTooltip" ng-controller="buildingUpgradeTooltipCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoTop"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold"><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
	<div class="headline">
		<h3 class="building" options="34"><span>Каменотес</span></h3>
	</div>

	<div class="queuedSlidesContainer">
		<!-- ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide1 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide2 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide3 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide4 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide5 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 -->
	</div>

	<div class="content" ng-class="{boxMaxLevel: building.data.lvl >= building.data.lvlMax, boxDeconstruction: upgradeButtonClass == 'demolish'}">
		<div class="contentTopArea">
			<!-- ngIf: building.data.currentEffect.values[0] --><div class="effectDescription" ng-if="building.data.currentEffect.values[0]">
				<span options="34"><span>Повышает прочность</span></span>
				<!-- ngIf: building.data.currentEffect.values[0] --><div class="horizontalLine3d" ng-if="building.data.currentEffect.values[0]"></div><!-- end ngIf: building.data.currentEffect.values[0] -->
			</div><!-- end ngIf: building.data.currentEffect.values[0] -->
			<!-- ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' --><table class="buildingNextEffects" ng-if="building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish'">
				<tbody>
					<tr>
						<td>
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', enoughRes: enoughRes && freeMasterSlots > 0}">
										<div class="labelLayer">
											<span class="buildingLevel">10</span>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td class="borderLeft">
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>16
							</span>
						</td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0])">
								<span options="34" data="value:200"><span>200%</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl - (currentBuildingLevel < building.data.lvlMax ? 0 : 1)] track by $index -->
						</td>
						<td class="emptySpace"></td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0])">
								<span options="34" data="value:210"><span>210%</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl + (currentBuildingLevel < building.data.lvlMax ? 1 : 0)] track by $index -->
						</td>
						<td>
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>18
							</span>
						</td>
						<td class="borderLeft">
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
										<div class="labelLayer">
											<span class="buildingLevel">11</span>
											<!-- ngIf: currentBuildingLevel + 1 >= building.data.lvlMax -->
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
			<!-- ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
		</div>

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><span ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'" class="resource timeValue" tooltip="" tooltip-translate="Duration">
				<i class="symbol_clock_small_flat_black duration"></i>02:40:00
			</span><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'" class="dropOutBox"></div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div class="resources" ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'">
			<display-resources available="storage" resources="building.data.nextUpgradeCosts[currentBuildingLevel]" image-size="medium"><div class="costs max4digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_medium_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">1830</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_medium_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">1535</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_medium_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">1475</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_medium_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">825</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 --><div class="enoughResTime" ng-if="enoughResSecs > 0" data="time: 1723561868"><span>Достаточно ресурсов: </span><span i18ndt="1723561868" format="short">Сегодня 20:11</span></div><!-- end ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_medium_flat_black"></i></div>
</div></display-resources>
		</div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
	</div>
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoBottom"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold"><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
</div>`,
			11: `<div class="upgradeCostsTooltip" ng-controller="buildingUpgradeTooltipCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoTop"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold"><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
	<div class="headline">
		<h3 class="building" options="11"><span>Амбар</span></h3>
	</div>

	<div class="queuedSlidesContainer">
		<!-- ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide1 active" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide2 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide3 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide4 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide5 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 -->
	</div>

	<div class="content" ng-class="{boxMaxLevel: building.data.lvl >= building.data.lvlMax, boxDeconstruction: upgradeButtonClass == 'demolish'}">
		<div class="contentTopArea">
			<!-- ngIf: building.data.currentEffect.values[0] --><div class="effectDescription" ng-if="building.data.currentEffect.values[0]">
				<span options="11"><span>Повышает емкость хранилища</span></span>
				<!-- ngIf: building.data.currentEffect.values[0] --><div class="horizontalLine3d" ng-if="building.data.currentEffect.values[0]"></div><!-- end ngIf: building.data.currentEffect.values[0] -->
			</div><!-- end ngIf: building.data.currentEffect.values[0] -->
			<!-- ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' --><table class="buildingNextEffects" ng-if="building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish'">
				<tbody>
					<tr>
						<td>
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', enoughRes: enoughRes && freeMasterSlots > 0}">
										<div class="labelLayer">
											<span class="buildingLevel">13</span>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td class="borderLeft">
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>16
							</span>
						</td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0])">
								<span options="11" data="value:22000"><span>22k</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl - (currentBuildingLevel < building.data.lvlMax ? 0 : 1)] track by $index -->
						</td>
						<td class="emptySpace"></td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0])">
								<span options="11" data="value:26000"><span>26k</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl + (currentBuildingLevel < building.data.lvlMax ? 1 : 0)] track by $index -->
						</td>
						<td>
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>18
							</span>
						</td>
						<td class="borderLeft">
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
										<div class="labelLayer">
											<span class="buildingLevel">14</span>
											<!-- ngIf: currentBuildingLevel + 1 >= building.data.lvlMax -->
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
			<!-- ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
		</div>

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><span ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'" class="resource timeValue" tooltip="" tooltip-translate="Duration">
				<i class="symbol_clock_small_flat_black duration"></i>05:50:00
			</span><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'" class="dropOutBox"></div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div class="resources" ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'">
			<display-resources available="storage" resources="building.data.nextUpgradeCosts[currentBuildingLevel]" image-size="medium"><div class="costs max4digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_medium_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">3260</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_medium_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">4075</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_medium_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">2850</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_medium_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">815</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 --><div class="enoughResTime" ng-if="enoughResSecs > 0" data="time: 1723621547"><span>Достаточно ресурсов: </span><span i18ndt="1723621547" format="short">Завтра 12:45</span></div><!-- end ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_medium_flat_black"></i></div>
</div></display-resources>
		</div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
	</div>
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoBottom"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold"><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
</div>`,
			2: `<div class="finishNowTooltip" ng-controller="finishNowCtrl">
	<!-- ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) --><div ng-if="!isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0)">
		<h3>
			<span><span>Моментально завершить следующие поручения</span></span><!-- ngIf: price == 0 -->
			<!-- ngIf: price > 0 || forceGoldUsage --><span class="price" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>2:</span><!-- end ngIf: price > 0 || forceGoldUsage -->
			<!-- ngIf: price == -1 && !forceGoldUsage -->
		</h3>
		<div class="horizontalLine"></div>

		<!-- ngRepeat: build in inBuilding track by $index --><div ng-repeat="build in inBuilding track by $index">
			<!-- ngIf: !finishNowQueueType -->
			<span options="22"><span>Академия</span></span>
			<span class="level" data="lvl:20"><span>Уровень 20</span></span>
		</div><!-- end ngRepeat: build in inBuilding track by $index -->
		<!-- ngRepeat: destruction in inDestruction track by $index -->
		<!-- ngRepeat: research in inResearch track by $index -->
		<!-- ngRepeat: training in inTraining track by $index -->
	</div><!-- end ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) -->
	<!-- ngIf: isWorldWonder -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && !config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && !hasInvalidBuilding && inBuilding.length == 0 && inTraining.length == 0 && inDestruction.length == 0 && inResearch.length == 0 -->
	<!-- ngIf: !isWorldWonder && premiumOptionMenu.options.instantCompletion && !freeSlots && enoughRes === false -->
</div>`,
			3: `<span options="Building.npcTrader.Tooltip.Possible"><strong>Обменять ресурсы автоматически&nbsp;<i class="icon unit_gold_small_illu"></i> 5</strong></span>`,
			4: `<span>Распределить ресурсы вручную</span>`,
		},
	}),
	computed: {
		totalCelebrationCount(){
			return this.celebrations.reduce((total, celebration) => total + celebration.queue.count, 0);
		},
	},
	methods: {
		formatSecondsDate: lib.formatSecondsDate,
		
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {
		CarouselCelebration,
		DisplayResources,
		RangeSlider,
		NpcTraderButton,
	},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Склад</span></span>
									<span class="level">
										(<span><span>Уровень {{gameModel.island.buildings.storage.level}}</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>На складе хранятся ресурсы острова (древесина, глина, камень, железо, зерно, трава, кристаллы и др.). Повышая уровень склада, ты увеличиваешь его емкость и можешь хранить больше ресурсов.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<div class="buildingStatus location27 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="buildingIndexCtrl" class="building buildingType10" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
														<div ng-include="" src="pageTemplate">
															<div class="buildingDetails">
																<div ng-include="" src="'tpl/building/partials/buildingInformation.html'">
																	<div ng-include="" src="'tpl/building/partials/buildingInformationEffects.html'">
																		<div class="contentBox gradient buildingEffect" ng-if="currentEffect.values[0] != undefined && (building.data.lvl > 0 || building.data.buildingType <= 4 || building.data.buildingType == 42)">
																			<h6 class="contentBoxHeader headerColored">
																				<span options="10" data="lvl: , value: "><span>Емкость хранилища</span></span>
																			</h6>
																			<div class="contentBoxBody">
																				<div class="current">
																					<h6 class="headerTrapezoidal">
																						<div class="content"><span>Сейчас</span></div>
																					</h6>
																					<div class="currentLevel" data="lvl: 3"><span>Уровень 3</span></div>
																					<div class="value">
																						2300
																					</div>
																					<div class="unit" options="10"><span>Ресурсов</span></div>
																				</div>
																				<div class="nextLvl">
																					<h6 class="headerTrapezoidal">
																						<div class="content"><span>Следующие уровни</span></div>
																					</h6>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}" class="upgrading">
																								<th>4</th>
																								<td ng-if="building.data.lvlNext > getFutureLevel($index)"><span>строится</span></td>
																								<td>
																									<span options="10" data="value:3100"><span>3100 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>5</th>
																								<td>
																									<span options="10" data="value:4000"><span>4000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>6</th>
																								<td>
																									<span options="10" data="value:5000"><span>5000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>7</th>
																								<td>
																									<span options="10" data="value:6300"><span>6300 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																						<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>8</th>
																								<td>
																									<span options="10" data="value:7700"><span>7700 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>9</th>
																								<td>
																									<span options="10" data="value:9600"><span>9600 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<!--10-->
																					<!--11-->
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>12</th>
																								<td>
																									<span options="11" data="value:18000"><span>18000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>13</th>
																								<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
																								<td>
																									<span options="10" data="value:22000"><span>22000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>14</th>
																								<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
																								<td>
																									<span options="10" data="value:26000"><span>26000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>15</th>
																								<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
																								<td>
																									<span options="10" data="value:32000"><span>32000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>16</th>
																								<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
																								<td>
																									<span options="10" data="value:38000"><span>38000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>17</th>
																								<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
																								<td>
																									<span options="10" data="value:45000"><span>45000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>18</th>
																								<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
																								<td>
																									<span options="10" data="value:55000"><span>55000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>19</th>
																								<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
																								<td>
																									
																								</td>
																							</tr>
																						</tbody>
																					</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>20</th>
																								<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
																								<td>
																									<span options="10" data="value:38000"><span>80000 ресурсов</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					
																					<div class="maxLvl" ng-if="building.data.isMaxLvl && building.data.lvl == building.data.lvlMax">
																						<span><span>Максимальный уровень</span></span>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<br>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Склад | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {},
}
</script>
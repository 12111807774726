import axios from 'axios';
import lib from '@/lib';

export default class BuildingQueueModel
{
	static MASTER_BUILDER_SLOTS = 4;
	
	static TYPE_VILLAGE = 1;
	static TYPE_RESOURCES = 2;
	static TYPE_MASTER_BUILDER = 4;
	static TYPE_DESTRUCTION = 5;
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.show = true;
		
		this.activeSlot = {
			slotHover: null,
			detailHover: null,
			slot: null,
			slotDetail: null,
		};
		
		this.slotBuildHover = false;
		this.slotDestructionHover = false;
		this.slot1Hover = false;
		this.slot2Hover = false;
		this.slot3Hover = false;
		this.slot4Hover = false;
		
		this.detailBuildHover = false;
		this.detailDestructionHover = false;
		this.detail1Hover = false;
		this.detail2Hover = false;
		this.detail3Hover = false;
		this.detail4Hover = false;
		
		this.queues = {
			[BuildingQueueModel.TYPE_DESTRUCTION]: [
				{
					queueType: BuildingQueueModel.TYPE_DESTRUCTION,
					locationId: 1,
					buildingType: 8,
					buildingName: 'Лесопилка',
					lvl: 1,
					isRubble: true, // руины
					enoughResources: true,
					paid: true,
					slot: null,
					startTime: 1,
					duration: 1400,
					cost: [
						2915,
						2790,
						2915,
						1000,
					],
				},
			],
			[BuildingQueueModel.TYPE_VILLAGE]: [
				{
					queueType: BuildingQueueModel.TYPE_VILLAGE,
					locationId: 12,
					buildingType: 8,
					buildingName: 'Лесопилка',
					lvl: 1,
					isRubble: false, // руины
					enoughResources: true,
					paid: true,
					slot: null,
					startTime: 1,
					duration: 1400,
					cost: [
						2915,
						2790,
						2915,
						1000,
					],
				},
			],
			[BuildingQueueModel.TYPE_RESOURCES]: [],
			[BuildingQueueModel.TYPE_MASTER_BUILDER]: [
				{
					queueType: BuildingQueueModel.TYPE_MASTER_BUILDER,
					locationId: 10,
					buildingType: 9,
					buildingName: 'Лесопилка',
					lvl: 1,
					isRubble: false, // руины
					enoughResources: true,
					paid: false,
					slot: 1,
					startTime: 0,
					duration: 1400,
					cost: [
						2915,
						2790,
						2915,
						1000,
					],
				},
				{
					queueType: BuildingQueueModel.TYPE_MASTER_BUILDER,
					locationId: 2,
					buildingType: 10,
					buildingName: 'Лесопилка',
					lvl: 1,
					isRubble: false, // руины
					enoughResources: false,
					paid: false,
					slot: 2,
					startTime: 0,
					duration: 1400,
					cost: [
						2915,
						2790,
						2915,
						1000,
					],
				},
			],
		};
		this.availableMasterBuilderSlots = 2;
	}
	
	activeSlotSelType(){
		return this.activeSlot.slotHover || this.activeSlot.detailHover;
	}
	
	activeSlotSelSlot(){
		return this.activeSlot.slot || this.activeSlot.slotDetail;
	}
	
	activeSlotSelData(){
		if(this.activeSlotSelType()){
			return this.queues[this.activeSlotSelType()].find(el => el.slot == this.activeSlotSelSlot())||null;
		}
		return null;
	}
}

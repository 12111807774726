<template>
	<div ng-if="overlayController" class="windowOverlay" id="useItem" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="577"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Hero.ItemUsePotionsHeadline" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Использовать лечебное зелье</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="useItem">

	<!-- ngIf: isHealingPotion --><div ng-if="isHealingPotion">
		<div class="contentBox itemTopBox">
			<h6 class="contentBoxHeader headerWithArrowDown">
				<div class="content" translate=""><span>Используй лечебное зелье, чтобы улучшить малые повязки.</span></div>
			</h6>
			<div class="contentBoxBody">
				<div class="topText" translate=""><span>Пожалуйста, выбери повязки, которые ты хочешь улучшить. Улучшение добавит 5% к максимальному проценту восстановления.</span></div>
				<a class="clickableContainer clickable disabled" clickable="showUpgradeBandageDialog(smallBandagesId)" content="Улучшить малые повязки" v-tippy="{placement: 'bottom'}" tooltip-translate="Hero.TooltipUpgradeSmallBandages" ng-class="{disabled: smallBandagesAmount <= 0}">
					<span class="headline" translate="" options="117"><span>Малая повязка</span></span>
					<span class="horizontalLine double"></span>
					<img class="heroItem_small_bandage_large_illu" src="layout/images/x.gif" data-cmp-info="10">
				</a>
				<a class="clickableContainer clickable disabled" clickable="showUpgradeBandageDialog(largeBandagesId)" content="Улучшить повязки" v-tippy="{placement: 'bottom'}" tooltip-translate="Hero.TooltipUpgradeLargeBandages" ng-class="{disabled: largeBandagesAmount <= 0}">
					<span class="headline" translate="" options="118"><span>Повязка</span></span>
					<span class="horizontalLine double"></span>
					<img class="heroItem_bandage_large_illu" src="layout/images/x.gif" data-cmp-info="10">
				</a>
			</div>
		</div>

		<div class="contentBox">
			<h6 class="contentBoxHeader headerWithArrowDown">
				<div class="content" translate=""><span>Или помести зелье в слот предмета</span></div>
			</h6>
			<div class="contentBoxBody">
				<div class="itemContainer">
					<hero-item-container item="item" hide-amount="true" hide-item-states="true"><div class="entityBox item item_124 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:57170,notUsableItem:,notUsableReason:,auctionItem:false,nextUpgradeLevel:false,itemType:124" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_healing_potion_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
					<div class="verticalLine"></div>
					<div class="confirmation bandageUpgradeText" translate=""><span>Помести зелье в слот предмета героя перед битвой, чтобы восстановить до &#x202D;&#x202D;5&#x202C;%&#x202C; боевых потерь. Каждое зелье может восстановить одного воина, неиспользуемое зелье останется в инвентаре.</span></div>
				</div>
			</div>
		</div>
	</div><!-- end ngIf: isHealingPotion -->

	<!-- ngIf: !isHealingPotion -->

	<!-- ngIf: stackable --><div ng-if="stackable">
		<div class="horizontalLine"></div>
		<!-- ngIf: treasurePrice -->
		<!-- ngIf: stackable && !treasuresSold --><slider ng-if="stackable &amp;&amp; !treasuresSold" class="confirmation" slider-min="1" slider-max="maxAmount" slider-data="useAmount" input-autofocus="true"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
	<div class="trackContainer">
		<div class="track">
			<!-- ngIf: stepCount && !hideSteps -->
			<div class="sliderMarker progressMarker" style="width: 100%;"></div>
			<!-- ngRepeat: marker in sliderMarkers track by $index -->
			<div class="hoverIndicator" style="display: none; left: 205px;">
				<div class="hoverValue">5</div>
				<div class="indicator"></div>
			</div>
			<div class="handle unselectable" style="left: 445px;"></div>
		</div>
	</div>
	<div class="inputContainer">
		<!-- ngIf: iconClass -->
		<input class="value ng-pristine ng-valid ng-touched" number="" ng-model="value" type="tel">
		<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: block;">
			<i class="action_setMax_small_flat_black"></i>
		</div>
	</div>
</div></slider><!-- end ngIf: stackable && !treasuresSold -->
		<!-- ngIf: treasuresSold -->
	</div><!-- end ngIf: stackable -->

	<div class="buttonBar">
		<!-- ngIf: !treasuresSold --><span ng-if="!treasuresSold">
			<!-- ngIf: !treasurePrice --><button ng-if="!treasurePrice" clickable="useItem()" class="useItemButton clickable"><div class="content">
	<span ng-transclude="" class="innerText">
				<!-- ngIf: ::!useButtonText --><span ng-if="::!useButtonText" data="ItemName:Лечебное зелье" translate=""><span>Использовать: Лечебное зелье</span></span><!-- end ngIf: ::!useButtonText -->
				<!-- ngIf: ::useButtonText -->
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: !treasurePrice -->

			<!-- ngIf: treasurePrice -->
			<button clickable="closeOverlay()" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Отмена</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		</span><!-- end ngIf: !treasuresSold -->
		<!-- ngIf: treasuresSold -->
	</div>

</div>
</div>
	</div>
</div></div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
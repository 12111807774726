<template>
	<div class="marketplaceMerchants" ng-controller="merchantsMarketplaceCtrl">
		<div class="contentBox outbound">
			<div class="contentBoxHeader headerWithIcon arrowDirectionTo">
				<i class="movement_trade_medium_flat_black"></i>
				<span translate=""><span>Отправленные торговцы</span></span>
			</div>
			<div class="contentBoxBody">
				<div ng-repeat="troopDetails in traders.outgoing | orderBy: 'movement.timeFinish'">
					<troop-details-rallypoint troop-details="troopDetails">
						<div class="troopsDetailContainer">
							<div class="troopsDetailHeader" ng-if="!showInputFields">
								<!-- ngIf: status == 'send' -->
								<!-- ngIf: isGroup -->
								<div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
									<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
									<!-- ngIf: status == 'nature' -->
									<!-- ngIf: status == 'support' -->
									<!-- ngIf: status == 'oasisSupport' -->
									<!-- ngIf: status == 'ownSupport' -->
									<!-- ngIf: status == 'awaySupport' -->
									<!-- ngIf: status == 'homeTrap' -->
									<!-- ngIf: status == 'awayTrap' -->
									<!-- ngIf: status == 'incoming' -->
									<div ng-if="status == 'outgoing'">
										<i class="movement_trade_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_merchant" tooltip-placement="above"></i>
										<span translate="" ng-if="!settle" data="villageId: 536264691, villageName: , villageNameTarget: Глушица, playerId: 734, playerName: " options="7"><span>в </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264691}, null, true)" villageid="536264691" villagename="Глушица" nolink="" showmissinglink="true">Глушица</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a></span>
										<!-- ngIf: settle -->
									</div>
								</div>
								<div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
									<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
									<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
										<span translate="" ng-if="troopDetails.movement.merchants > 0" tooltip="" tooltip-data="timeFinish: 1721672299" tooltip-translate="RallyPoint.Troops.ArrivalTime.Trade.Tooltip" class="countdownTo" data="timeFinish: 1721672299"><span>через </span><span countdown="1721672299" callback="">00:05:42</span></span>
										<!-- ngIf: troopDetails.movement.merchants == 0 -->
									</div>
									<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
									<!-- ngIf: status == 'incoming' -->
								</div>
							</div>

							<!-- ngIf: showUnits -->

							<div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
								<!-- ngIf: showCatapults -->
								<!-- ngIf: showSpy -->
								<div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty withInstantDelivery">
									<div ng-if="troopDetails.movement.merchants > 1" class="merchantCount">
										<i class="movement_trade_small_flat_black"></i> 3
									</div>
									<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
									<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
									<display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old="">
										<div class="costs max3digits">
											<span ng-if="!checkStorage">
												<span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
													<i class="unit_wood_small_illu resType1"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
													<i class="unit_clay_small_illu resType2"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
													<i class="unit_iron_small_illu resType3"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
													<i class="unit_crop_small_illu resType4"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
											</span>
											<!-- ngIf: checkStorage -->

											<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
											<!-- ngIf: population || population == 0 -->

											<!-- ngIf: treasures || treasures == 0 -->

											<!-- ngIf: stolenGoods || stolenGoods == 0 -->

											<!-- ngIf: time || time == 0 -->

											<!-- ngIf: enoughResSecs == -1 -->
											<!-- ngIf: enoughResSecs > 0 -->
											<!-- ngIf: capacityHint -->

											<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
										</div>
									</display-resources>
									<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
									<button ng-if="showInstantDelivery" class="instantDelivery free" premium-feature="traderArriveInstantly" premium-callback-param="2474546" price="-1" tooltip="">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Забрать сейчас</span></span>
											</span>
											<!-- ngIf: price > 0 || forceGoldUsage -->
											<span class="price voucher" ng-if="price == -1 &amp;&amp; !forceGoldUsage"><i class="cardGame_prizePremium_small_illu"></i><span>1</span></span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
								</div>
							</div>
							<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
						</div>
					</troop-details-rallypoint>
				</div>
				<div ng-repeat="troopDetails in traders.outgoing | orderBy: 'movement.timeFinish'">
					<troop-details-rallypoint troop-details="troopDetails">
						<div class="troopsDetailContainer">
							<div class="troopsDetailHeader" ng-if="!showInputFields">
								<!-- ngIf: status == 'send' -->
								<!-- ngIf: isGroup -->
								<div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
									<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
									<!-- ngIf: status == 'nature' -->
									<!-- ngIf: status == 'support' -->
									<!-- ngIf: status == 'oasisSupport' -->
									<!-- ngIf: status == 'ownSupport' -->
									<!-- ngIf: status == 'awaySupport' -->
									<!-- ngIf: status == 'homeTrap' -->
									<!-- ngIf: status == 'awayTrap' -->
									<!-- ngIf: status == 'incoming' -->
									<div ng-if="status == 'outgoing'">
										<i class="movement_trade_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_merchant" tooltip-placement="above"></i>
										<span translate="" ng-if="!settle" data="villageId: 536264691, villageName: , villageNameTarget: Глушица, playerId: 734, playerName: " options="7"><span>в </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264691}, null, true)" villageid="536264691" villagename="Глушица" nolink="" showmissinglink="true">Глушица</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a></span>
										<!-- ngIf: settle -->
									</div>
								</div>
								<div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
									<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
									<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
										<span translate="" ng-if="troopDetails.movement.merchants > 0" tooltip="" tooltip-data="timeFinish: 1721672333" tooltip-translate="RallyPoint.Troops.ArrivalTime.Trade.Tooltip" class="countdownTo" data="timeFinish: 1721672333"><span>через </span><span countdown="1721672333" callback="">00:06:16</span></span>
										<!-- ngIf: troopDetails.movement.merchants == 0 -->
									</div>
									<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
									<!-- ngIf: status == 'incoming' -->
								</div>
							</div>

							<!-- ngIf: showUnits -->

							<div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
								<!-- ngIf: showCatapults -->
								<!-- ngIf: showSpy -->
								<div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty withInstantDelivery">
									<div ng-if="troopDetails.movement.merchants > 1" class="merchantCount">
										<i class="movement_trade_small_flat_black"></i> 3
									</div>
									<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
									<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
									<display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old="">
										<div class="costs max3digits">
											<span ng-if="!checkStorage">
												<span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
													<i class="unit_wood_small_illu resType1"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
													<i class="unit_clay_small_illu resType2"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
													<i class="unit_iron_small_illu resType3"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
													<i class="unit_crop_small_illu resType4"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
											</span>
											<!-- ngIf: checkStorage -->

											<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
											<!-- ngIf: population || population == 0 -->

											<!-- ngIf: treasures || treasures == 0 -->

											<!-- ngIf: stolenGoods || stolenGoods == 0 -->

											<!-- ngIf: time || time == 0 -->

											<!-- ngIf: enoughResSecs == -1 -->
											<!-- ngIf: enoughResSecs > 0 -->
											<!-- ngIf: capacityHint -->

											<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
										</div>
									</display-resources>
									<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
									<button ng-if="showInstantDelivery" class="instantDelivery free" premium-feature="traderArriveInstantly" premium-callback-param="2474550" price="-1" tooltip="">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Забрать сейчас</span></span>
											</span>
											<!-- ngIf: price > 0 || forceGoldUsage -->
											<span class="price voucher" ng-if="price == -1 &amp;&amp; !forceGoldUsage"><i class="cardGame_prizePremium_small_illu"></i><span>1</span></span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
								</div>
							</div>
							<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
						</div>
					</troop-details-rallypoint>
				</div>
				<div ng-if="traders.outgoing.length == 0" translate=""><span>Торговцев в пути не обнаружено</span></div>
			</div>
		</div>
		<div class="contentBox inbound">
			<div class="contentBoxHeader headerWithIcon arrowDirectionFrom">
				<i class="movement_trade_medium_flat_black"></i>
				<span translate=""><span>Прибывающие торговцы</span></span>
			</div>
			<div class="contentBoxBody">
				<div ng-repeat="troopDetails in traders.incoming | orderBy: 'movement.timeFinish'">
					<troop-details-rallypoint troop-details="troopDetails">
						<div class="troopsDetailContainer">
							<div class="troopsDetailHeader" ng-if="!showInputFields">
								<!-- ngIf: status == 'send' -->
								<!-- ngIf: isGroup -->
								<div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
									<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
									<!-- ngIf: status == 'nature' -->
									<!-- ngIf: status == 'support' -->
									<!-- ngIf: status == 'oasisSupport' -->
									<!-- ngIf: status == 'ownSupport' -->
									<!-- ngIf: status == 'awaySupport' -->
									<!-- ngIf: status == 'homeTrap' -->
									<!-- ngIf: status == 'awayTrap' -->
									<div ng-if="status == 'incoming'">
										<i class="movement_trade_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_incoming_merchant" tooltip-placement="above"></i>
										<span ng-if="!oasis">
											<!-- ngIf: troopDetails.movement.movementType == Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT -->
											<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 -->
											<span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT &amp;&amp; ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1" data="villageId: 536330229, villageName: Брест, playerId: 734, playerName: "><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span> из </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" villageid="536330229" villagename="Брест" nolink="" showmissinglink="true">Брест</a></span>
										</span>
										<!-- ngIf: oasis -->
									</div>
									<!-- ngIf: status == 'outgoing' -->
								</div>
								<div class="troopsInfo incomingInfo" ng-if="status != 'send'">
									<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
									<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
										<span translate="" ng-if="troopDetails.movement.merchants > 0" tooltip="" tooltip-data="timeFinish: 1721672299" tooltip-translate="RallyPoint.Troops.ArrivalTime.Trade.Tooltip" class="countdownTo" data="timeFinish: 1721672299"><span>через </span><span countdown="1721672299" callback="">00:03:55</span></span>
										<!-- ngIf: troopDetails.movement.merchants == 0 -->
									</div>
									<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
									<div class="markerContainer" ng-if="status == 'incoming'">
										<i clickable="changeMarker(2474546)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
									</div>
								</div>
							</div>

							<!-- ngIf: showUnits -->

							<div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
								<!-- ngIf: showCatapults -->
								<!-- ngIf: showSpy -->
								<div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty withInstantDelivery">
									<div ng-if="troopDetails.movement.merchants > 1" class="merchantCount">
										<i class="movement_trade_small_flat_black"></i> 3
									</div>
									<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
									<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
									<display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old="">
										<div class="costs max3digits">
											<span ng-if="!checkStorage">
												<span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
													<i class="unit_wood_small_illu resType1"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
													<i class="unit_clay_small_illu resType2"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
													<i class="unit_iron_small_illu resType3"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
													<i class="unit_crop_small_illu resType4"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
											</span>
											<!-- ngIf: checkStorage -->

											<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
											<!-- ngIf: population || population == 0 -->

											<!-- ngIf: treasures || treasures == 0 -->

											<!-- ngIf: stolenGoods || stolenGoods == 0 -->

											<!-- ngIf: time || time == 0 -->

											<!-- ngIf: enoughResSecs == -1 -->
											<!-- ngIf: enoughResSecs > 0 -->
											<!-- ngIf: capacityHint -->

											<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
										</div>
									</display-resources>
									<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
									<button ng-if="showInstantDelivery" class="instantDelivery free" premium-feature="traderArriveInstantly" premium-callback-param="2474546" price="-1" tooltip="">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Забрать сейчас</span></span>
											</span>
											<!-- ngIf: price > 0 || forceGoldUsage -->
											<span class="price voucher" ng-if="price == -1 &amp;&amp; !forceGoldUsage"><i class="cardGame_prizePremium_small_illu"></i><span>1</span></span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
								</div>
							</div>
							<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
						</div>
					</troop-details-rallypoint>
				</div>
				<div ng-repeat="troopDetails in traders.incoming | orderBy: 'movement.timeFinish'">
					<troop-details-rallypoint troop-details="troopDetails">
						<div class="troopsDetailContainer">
							<div class="troopsDetailHeader" ng-if="!showInputFields">
								<!-- ngIf: status == 'send' -->
								<!-- ngIf: isGroup -->
								<div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
									<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
									<!-- ngIf: status == 'nature' -->
									<!-- ngIf: status == 'support' -->
									<!-- ngIf: status == 'oasisSupport' -->
									<!-- ngIf: status == 'ownSupport' -->
									<!-- ngIf: status == 'awaySupport' -->
									<!-- ngIf: status == 'homeTrap' -->
									<!-- ngIf: status == 'awayTrap' -->
									<div ng-if="status == 'incoming'">
										<i class="movement_trade_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_incoming_merchant" tooltip-placement="above"></i>
										<span ng-if="!oasis">
											<!-- ngIf: troopDetails.movement.movementType == Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT -->
											<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 -->
											<span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT &amp;&amp; ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1" data="villageId: 536330229, villageName: Брест, playerId: 734, playerName: "><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span> из </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" villageid="536330229" villagename="Брест" nolink="" showmissinglink="true">Брест</a></span>
										</span>
										<!-- ngIf: oasis -->
									</div>
									<!-- ngIf: status == 'outgoing' -->
								</div>
								<div class="troopsInfo incomingInfo" ng-if="status != 'send'">
									<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
									<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
										<span translate="" ng-if="troopDetails.movement.merchants > 0" tooltip="" tooltip-data="timeFinish: 1721672333" tooltip-translate="RallyPoint.Troops.ArrivalTime.Trade.Tooltip" class="countdownTo" data="timeFinish: 1721672333"><span>через </span><span countdown="1721672333" callback="">00:04:29</span></span>
										<!-- ngIf: troopDetails.movement.merchants == 0 -->
									</div>
									<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
									<div class="markerContainer" ng-if="status == 'incoming'">
										<i clickable="changeMarker(2474550)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
									</div>
								</div>
							</div>

							<!-- ngIf: showUnits -->

							<div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
								<!-- ngIf: showCatapults -->
								<!-- ngIf: showSpy -->
								<div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty withInstantDelivery">
									<div ng-if="troopDetails.movement.merchants > 1" class="merchantCount">
										<i class="movement_trade_small_flat_black"></i> 3
									</div>
									<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
									<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
									<display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old="">
										<div class="costs max3digits">
											<span ng-if="!checkStorage">
												<span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
													<i class="unit_wood_small_illu resType1"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
													<i class="unit_clay_small_illu resType2"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
													<i class="unit_iron_small_illu resType3"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
													<i class="unit_crop_small_illu resType4"></i>
													<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
											</span>
											<!-- ngIf: checkStorage -->

											<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
											<!-- ngIf: population || population == 0 -->

											<!-- ngIf: treasures || treasures == 0 -->

											<!-- ngIf: stolenGoods || stolenGoods == 0 -->

											<!-- ngIf: time || time == 0 -->

											<!-- ngIf: enoughResSecs == -1 -->
											<!-- ngIf: enoughResSecs > 0 -->
											<!-- ngIf: capacityHint -->

											<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
										</div>
									</display-resources>
									<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
									<button ng-if="showInstantDelivery" class="instantDelivery free" premium-feature="traderArriveInstantly" premium-callback-param="2474550" price="-1" tooltip="">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Забрать сейчас</span></span>
											</span>
											<!-- ngIf: price > 0 || forceGoldUsage -->
											<span class="price voucher" ng-if="price == -1 &amp;&amp; !forceGoldUsage"><i class="cardGame_prizePremium_small_illu"></i><span>1</span></span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
								</div>
							</div>
							<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
						</div>
					</troop-details-rallypoint>
				</div>
				<div ng-if="traders.incoming.length == 0" translate=""><span>Торговцев в пути не обнаружено</span></div>
			</div>
			<div class="contentBoxHeader headerWithIcon arrowDirectionFrom">
				<i class="movement_trade_medium_flat_black"></i>
				<span translate=""><span>Возвращающиеся торговцы</span></span>
			</div>
			<div class="contentBoxBody">
				<div ng-repeat="troopDetails in traders.returns | orderBy: 'movement.timeFinish'">
					<troop-details-rallypoint troop-details="troopDetails">
						<div class="troopsDetailContainer">
							<div class="troopsDetailHeader" ng-if="!showInputFields">
								<!-- ngIf: status == 'send' -->
								<!-- ngIf: isGroup -->
								<div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
									<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
									<!-- ngIf: status == 'nature' -->
									<!-- ngIf: status == 'support' -->
									<!-- ngIf: status == 'oasisSupport' -->
									<!-- ngIf: status == 'ownSupport' -->
									<!-- ngIf: status == 'awaySupport' -->
									<!-- ngIf: status == 'homeTrap' -->
									<!-- ngIf: status == 'awayTrap' -->
									<div ng-if="status == 'incoming'">
										<i class="movement_trade_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_incoming_merchant_return" tooltip-placement="above"></i>
										<span ng-if="!oasis">
											<!-- ngIf: troopDetails.movement.movementType == Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT -->
											<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 -->
											<span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT &amp;&amp; ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1" data="villageId: 536264691, villageName: Глушица, playerId: 734, playerName: "><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span> из </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264691}, null, true)" villageid="536264691" villagename="Глушица" nolink="" showmissinglink="true">Глушица</a></span>
										</span>
										<!-- ngIf: oasis -->
									</div>
									<!-- ngIf: status == 'outgoing' -->
								</div>
								<div class="troopsInfo incomingInfo" ng-if="status != 'send'">
									<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
									<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
										<span translate="" ng-if="troopDetails.movement.merchants > 0" tooltip="" tooltip-data="timeFinish: 1721672723" tooltip-translate="RallyPoint.Troops.ArrivalTime.Trade.Tooltip" class="countdownTo" data="timeFinish: 1721672723"><span>через </span><span countdown="1721672723" callback="">00:06:21</span></span>
										<!-- ngIf: troopDetails.movement.merchants == 0 -->
									</div>
									<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
									<div class="markerContainer" ng-if="status == 'incoming'">
										<i clickable="changeMarker(2474546)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
									</div>
								</div>
							</div>

							<!-- ngIf: showUnits -->

							<div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
								<!-- ngIf: showCatapults -->
								<!-- ngIf: showSpy -->
								<div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty">
									<div ng-if="troopDetails.movement.merchants > 1" class="merchantCount">
										<i class="movement_trade_small_flat_black"></i> 3
									</div>
									<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
									<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
									<display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old="true">
										<div class="costs max3digits">
											<span ng-if="!checkStorage">
												<span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
													<i class="unit_wood_small_illu resType1"></i>
													<span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
													<i class="unit_clay_small_illu resType2"></i>
													<span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
													<i class="unit_iron_small_illu resType3"></i>
													<span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
													<i class="unit_crop_small_illu resType4"></i>
													<span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
											</span>
											<!-- ngIf: checkStorage -->

											<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
											<!-- ngIf: population || population == 0 -->

											<!-- ngIf: treasures || treasures == 0 -->

											<!-- ngIf: stolenGoods || stolenGoods == 0 -->

											<!-- ngIf: time || time == 0 -->

											<!-- ngIf: enoughResSecs == -1 -->
											<!-- ngIf: enoughResSecs > 0 -->
											<!-- ngIf: capacityHint -->

											<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
										</div>
									</display-resources>
									<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
									<!-- ngIf: showInstantDelivery -->
								</div>
							</div>
							<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
						</div>
					</troop-details-rallypoint>
				</div>
				<div ng-repeat="troopDetails in traders.returns | orderBy: 'movement.timeFinish'">
					<troop-details-rallypoint troop-details="troopDetails">
						<div class="troopsDetailContainer">
							<div class="troopsDetailHeader" ng-if="!showInputFields">
								<!-- ngIf: status == 'send' -->
								<!-- ngIf: isGroup -->
								<div class="troopsTitle" ng-if="status != 'send' &amp;&amp; !isGroup">
									<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
									<!-- ngIf: status == 'nature' -->
									<!-- ngIf: status == 'support' -->
									<!-- ngIf: status == 'oasisSupport' -->
									<!-- ngIf: status == 'ownSupport' -->
									<!-- ngIf: status == 'awaySupport' -->
									<!-- ngIf: status == 'homeTrap' -->
									<!-- ngIf: status == 'awayTrap' -->
									<div ng-if="status == 'incoming'">
										<i class="movement_trade_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_incoming_merchant_return" tooltip-placement="above"></i>
										<span ng-if="!oasis">
											<!-- ngIf: troopDetails.movement.movementType == Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT -->
											<!-- ngIf: troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT && ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) > -1 -->
											<span translate="" ng-if="troopDetails.movement.movementType != Troops.MOVEMENT_TYPE_TRIBUTE_COLLECT &amp;&amp; ['3','4','47','36'].indexOf(''+troopDetails.movement.movementType) == -1" data="villageId: 536264691, villageName: Глушица, playerId: 734, playerName: "><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span> из </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264691}, null, true)" villageid="536264691" villagename="Глушица" nolink="" showmissinglink="true">Глушица</a></span>
										</span>
										<!-- ngIf: oasis -->
									</div>
									<!-- ngIf: status == 'outgoing' -->
								</div>
								<div class="troopsInfo incomingInfo" ng-if="status != 'send'">
									<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
									<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
										<span translate="" ng-if="troopDetails.movement.merchants > 0" tooltip="" tooltip-data="timeFinish: 1721672757" tooltip-translate="RallyPoint.Troops.ArrivalTime.Trade.Tooltip" class="countdownTo" data="timeFinish: 1721672757"><span>через </span><span countdown="1721672757" callback="">00:06:55</span></span>
										<!-- ngIf: troopDetails.movement.merchants == 0 -->
									</div>
									<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
									<div class="markerContainer" ng-if="status == 'incoming'">
										<i clickable="changeMarker(2474550)" class="marker movement_incoming_attack_marker_grey_medium_illu"></i>
									</div>
								</div>
							</div>

							<!-- ngIf: showUnits -->

							<div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
								<!-- ngIf: showCatapults -->
								<!-- ngIf: showSpy -->
								<div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty">
									<div ng-if="troopDetails.movement.merchants > 1" class="merchantCount">
										<i class="movement_trade_small_flat_black"></i> 3
									</div>
									<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
									<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
									<display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old="true">
										<div class="costs max3digits">
											<span ng-if="!checkStorage">
												<span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
													<i class="unit_wood_small_illu resType1"></i>
													<span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
													<i class="unit_clay_small_illu resType2"></i>
													<span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
													<i class="unit_iron_small_illu resType3"></i>
													<span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
												<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
													<i class="unit_crop_small_illu resType4"></i>
													<span class="resourceValue strikethrough" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
													<!-- ngIf: ::numberUnit=='production' -->
													<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
												</span>
											</span>
											<!-- ngIf: checkStorage -->

											<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
											<!-- ngIf: population || population == 0 -->

											<!-- ngIf: treasures || treasures == 0 -->

											<!-- ngIf: stolenGoods || stolenGoods == 0 -->

											<!-- ngIf: time || time == 0 -->

											<!-- ngIf: enoughResSecs == -1 -->
											<!-- ngIf: enoughResSecs > 0 -->
											<!-- ngIf: capacityHint -->

											<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
										</div>
									</display-resources>
									<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
									<!-- ngIf: showInstantDelivery -->
								</div>
							</div>
							<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
						</div>
					</troop-details-rallypoint>
				</div>
				<div ng-if="traders.returns.length == 0" translate=""><span>Торговцев в пути не обнаружено</span></div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper reports" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal reports">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/map')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span>Оазис</span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
				<div class="mapCellDetails" ng-controller="mapCellDetailsCtrl">
	<div dynamic="" scrollable="" class="scrollable"><div class="scrollContentOuterWrapper active" style="width: 610px;">
	<div class="scrollContent" dynamic-height="true" style="width: 630px; overflow-y: auto; max-height: 453px;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: 610px;">
		<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/header.html'"><div class="mapContainer oasis">
	<!-- ngIf: mapDetails.isWonder -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisIllu type10" ng-if="fieldType == 'oasis'"></div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisIllu type11" ng-if="fieldType == 'oasis'"></div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisIllu type20" ng-if="fieldType == 'oasis'"></div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisIllu type21" ng-if="fieldType == 'oasis'"></div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisIllu type30" ng-if="fieldType == 'oasis'"></div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisIllu type31" ng-if="fieldType == 'oasis'"></div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisIllu type40" ng-if="fieldType == 'oasis'"></div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisIllu type41" ng-if="fieldType == 'oasis'"></div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'npcVillageRobber' -->
	<!-- ngIf: cellData.menhir -->
	<!-- ngIf: fieldType != 'oasis' && fieldType != 'npcVillageRobber' && !mapDetails.isWonder && !cellData.menhir -->
	<!-- ngIf: mapDetails.isWonder -->
	<!-- ngIf: showAttack && attackingTroops.cnt == 0 -->
	<!-- ngIf: attackingTroops.cnt > 0 -->
	<!-- ngIf: mapDetails.npcInfo.type == 8 -->
	<!-- ngIf: fieldType == 'oasis' --><div class="oasisBonusWrapper" ng-if="fieldType == 'oasis'">
		<div class="oasisBonusContainer ng-hide" ng-show="mapDetails.oasisBonus[1] != 0" tooltip="" tooltip-translate="wood">
			<i class="oasisBonus unit_wood_large_illu"></i>
			<span>&#x202D;&#x202D;0&#x202C;%&#x202C;</span>
		</div>
		<div class="oasisBonusContainer ng-hide" ng-show="mapDetails.oasisBonus[2] != 0" tooltip="" tooltip-translate="clay">
			<i class="oasisBonus unit_clay_large_illu"></i>
			<span>&#x202D;&#x202D;0&#x202C;%&#x202C;</span>
		</div>
		<div class="oasisBonusContainer" ng-show="mapDetails.oasisBonus[3] != 0" tooltip="" tooltip-translate="iron">
			<i class="oasisBonus unit_iron_large_illu"></i>
			<span>&#x202D;&#x202D;25&#x202C;%&#x202C;</span>
		</div>
		<div class="oasisBonusContainer ng-hide" ng-show="mapDetails.oasisBonus[4] != 0" tooltip="" tooltip-translate="crop">
			<i class="oasisBonus unit_crop_large_illu"></i>
			<span>&#x202D;&#x202D;0&#x202C;%&#x202C;</span>
		</div>
		<h6 class="headerTrapezoidal">
			<div class="content" translate=""><span>Бонус</span></div>
		</h6>
	</div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' --><div class="defBonus" ng-if="fieldType == 'oasis'" content="Бонус защиты: 0%" v-tippy="{placement: 'bottom'}" tooltip-translate="CellDetails.Oasis.DefBonus" tooltip-data="bonus:0">
		0<br>%
	</div><!-- end ngIf: fieldType == 'oasis' -->
</div></div>
		<div class="otherContainer oasis">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/bodyTop.html'"><div class="splitContainer oasis">
	<!-- ngIf: fieldType == 'village' && mapDetails.wwValues -->
	<!-- ngIf: fieldType == 'village' -->
	<!-- ngIf: fieldType == 'oasis' --><div ng-if="fieldType == 'oasis'">
		<div class="contentBox leftBox">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/oasisDetails.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6>
		<span translate=""><span>Информация</span></span>
		<span class="coordinateWrapper" coordinates="" x="-11" y="21">
	<!-- ngIf: hasLink --><a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537575413_1708113345.8');" class="clickable">
		<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;11&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;21&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
	</a><!-- end ngIf: hasLink -->
	<!-- ngIf: !hasLink -->
</span>
	</h6>
</div>
<div class="contentBoxBody oasisDetails" ng-class="{occupied: !animals}">
	<!-- ngIf: animals --><div ng-if="animals">
		<div class="owner" translate=""><span>Занято природой</span></div>
		<div class="horizontalLine"></div>
		<div translate=""><span>Если оазис находится в твоем королевстве, ты можешь заявить на него права, победив в нем животных.</span></div>
	</div><!-- end ngIf: animals -->
	<!-- ngIf: !animals -->
</div></div>
		</div>
		<div class="contentBox rightBox">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/oasisProduction.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6>
		<!-- ngIf: animals && inVillageReach -->
		<!-- ngIf: !animals || !inVillageReach --><span translate="" ng-if="!animals || !inVillageReach"><span>Производство</span></span><!-- end ngIf: !animals || !inVillageReach -->
	</h6>
</div>
<div class="contentBoxBody oasisProduction" ng-class="{assigned: isInTop5}">
	<!-- ngIf: !inVillageReach --><div ng-if="!inVillageReach">
		<span translate=""><span>Оазис не попадает в зону влияния ни одной деревни. Построй новую деревню в пределах досягаемости оазиса или выбери оазис, который находится ближе к твоей деревне. Твой герой, впрочем, может захватить животных в клетки, чтобы они помогали оборонять твою деревню.</span></span>
	</div><!-- end ngIf: !inVillageReach -->
	<!-- ngIf: inVillageReach && animals -->
	<!-- ngIf: inVillageReach && !animals -->
</div>
</div>
		</div>
	</div><!-- end ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->
	<!-- ngIf: fieldType == 'unhabitable' -->
	<!-- ngIf: fieldType == 'habitable' -->
</div>
</div>
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/bodyBottom.html'"><!-- ngIf: fieldType == 'oasis' --><div ng-if="fieldType == 'oasis'">
	<!-- ngIf: animals --><!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/oasisTroops.html'" ng-if="animals"><!-- ngIf: oasisTroops --><div class="contentBox troopContainer" ng-if="oasisTroops">
	<div class="villageDetails contentBoxHeader headerColored">
		<h6 translate=""><span>Войска</span></h6>
	</div>
	<div class="contentBoxBody">
		<div troops-details="" troop-data="mapDetails.troops"><table class="troopsTable nature">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall nature unitType1 inactive" unit-icon="" data="31" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[1]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall nature unitType2" unit-icon="" data="32" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[2]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall nature unitType3" unit-icon="" data="33" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[3]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall nature unitType4 inactive" unit-icon="" data="34" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[4]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall nature unitType5 inactive" unit-icon="" data="35" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[5]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall nature unitType6 inactive" unit-icon="" data="36" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[6]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall nature unitType7 inactive" unit-icon="" data="37" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[7]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall nature unitType8 inactive" unit-icon="" data="38" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[8]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall nature unitType9 inactive" unit-icon="" data="39" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[9]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall nature unitType10 inactive" unit-icon="" data="40" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[10]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" :content="tooltips[11]" v-tippy="{placement: 'top'}" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;123&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;45&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
	</div>
</div><!-- end ngIf: oasisTroops -->
</div><!-- end ngIf: animals -->

	<!-- ngRepeat: troopDetails in troopsHere -->
</div><!-- end ngIf: fieldType == 'oasis' -->

<!-- ngIf: fieldType == 'village' && mapDetails.isWonder && !mapDetails.isConquered -->

<div class="tributeCropContainer">
	<div class="tributes contentBox gradient ng-hide" ng-show="showTributes">
		<div class="contentBoxBody">
			<display-resources resources="village.tributes" treasures="village.tributeTreasures" hide-zero="true"><div class="costs max0digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources>
			<div class="barView">
				<div class="actualTributes">&lrm;&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;</div>
				<div class="populationBar progressbar" progressbar="" type="green" perc="" marker="">
	<!-- ngIf: ::labelIcon -->
	<div class="progress">
		<!-- ngIf: steps -->
		<div class="bar positive perc green" style="z-index: -1; width: 0%;"></div>
		<div class="bar additionalBar additionalPerc"></div>
		<div class="marker" style="z-index: -1; width: 0%;">
			<div class="markerInner"></div>
		</div>
		<!-- ngIf: ::showCountdown -->
		<!-- ngIf: ::label -->
		<!-- ngIf: ::percTooltip -->
	</div>
</div>
			</div>
			<button clickable="fetchTributes()" ng-class="{disabled: !village.canFetchTributes || !village.allowTributeCollection || !village.protectionGranted || !activeTreasury || (!territoryConnected &amp;&amp; village.type != Village.TYPE_GOVERNOR_NPC_VILLAGE)}" play-on-click="16" tooltip="" tooltip-translate-switch="{'ContextMenu.button.fetchTribute.denied': true,
											   'ContextMenu.button.fetchTribute.unprotected': true,
											   'Tributes.VillageNotGeneratingInfluence': true,
											   'Tributes.VillageNotConnected': false}" class="clickable disabled"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Собрать дань</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		</div>
	</div>
	<!-- ngIf: sharedInformations.cropDetails != null -->
</div>

<p ng-show="error" class="error ng-hide">
	
</p>

<!-- ngIf: fieldType == 'village' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->

<!-- ngIf: sharedInformations.stationedTroops.length > 0 --></div>
		</div>
	</div>
	</div>
</div>
<div class="scrollTrack" style="opacity: 1; display: block;">
    <div class="scrollHandle" style="top: 0px; height: 444.175px;"></div>
	<div class="trackDecoration"></div>
</div></div>
	<div class="cellActionContainer">
		<div class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '537575413_1708113345.8'); closeWindow(w.name);">
			<i class="symbol_target_small_flat_black"></i>
		</div>
		<!-- ngIf: options.length > 0 --><div class="options" ng-if="options.length > 0" more-dropdown="" more-dropdown-options="getOptions()" style="position: relative;"><a class="iconButton clickable" clickable="openMoreDropdown()"><!-- ngIf: showCount --><i class="symbol_arrowDown_tiny_illu"></i></a>
			<div class="moreDropDownBody dropdownBody" style="bottom: 0; right: 0; display: block;"><ul><!-- ngRepeat: option in options --><li ng-repeat="option in options" clickable="openWindow('markerMenu',{'cellId': 537575413}); closeMoreDropdown()" class="clickable">Отметить поле</li><!-- end ngRepeat: option in options --><li ng-repeat="option in options" clickable="openInSim(); closeMoreDropdown()" class="clickable">Рассчитать в симуляторе</li><!-- end ngRepeat: option in options --></ul></div>
		</div><!-- end ngIf: options.length > 0 -->
		
		<div class="moreDropDownBody dropdownBody" style="top: 681px; left: 797.188px; display: block;"><ul><!-- ngRepeat: option in options --><li ng-repeat="option in options" clickable="openWindow('markerMenu',{'cellId': 536100851}); closeMoreDropdown()" class="clickable">Отметить поле</li><!-- end ngRepeat: option in options --><li ng-repeat="option in options" clickable="openInSim(); closeMoreDropdown()" class="clickable">Рассчитать в симуляторе</li><!-- end ngRepeat: option in options --></ul></div>
		
		<!-- ngIf: fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' --><button class="sendTroopsButton clickable" ng-if="fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor'" clickable="openWindow('sendTroops', {'x': -11, 'y': 21 })" tooltip="" tooltip-show="false" tooltip-translate="ContextMenu.NoRallyPoint" ng-class="{disabled: !hasRallyPoint}"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Отправить войска</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->
		<!-- ngIf: fieldType == 'habitable' && !cellData.menhir -->
		<!-- ngIf: cellData.menhir && cellData.menhir == player.data.playerId -->
		<!-- ngIf: cellData.menhir && cellData.owner == player.data.playerId -->
	</div>
</div>
			</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
.societyFilter {
	float: right;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Оазис | IW'});
	},
	data: () => ({
		tooltips: {
			1: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="31"><span>Крыса</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType1" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>10</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>25</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			2: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="32"><span>Паук</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType2" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>20</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>40</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			3: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="33"><span>Змея</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType3" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>60</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>40</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>60</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			4: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="34"><span>Летучая мышь</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType4" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>80</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>66</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>50</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			5: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="35"><span>Кабан</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType5" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>50</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>70</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>33</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			6: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="36"><span>Волк</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType6" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>100</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>80</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>70</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			7: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="37"><span>Медведь</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType7" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>250</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>140</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>200</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			8: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="38"><span>Крокодил</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType8" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>450</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>380</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>240</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			9: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="39"><span>Тигр</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType9" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>200</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>170</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>250</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			10: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="40"><span>Слон</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType10" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>600</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>440</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>520</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
			11: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="hero"><span>Герой</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium hero_illu" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO -->
		<!-- ngIf: unitId == Troops.TYPE_HERO --><div class="unitAttributes" ng-if="unitId == Troops.TYPE_HERO">
			<span ng-class="{noValue : group.unitValues[unitId].attack < 0 }" class="noValue"><i class="movement_attack_small_flat_black"></i>?</span>
			<span ng-class="{noValue : group.unitValues[unitId].defence < 0 }" class="noValue"><i class="unit_defenseInfantry_small_flat_black"></i>?</span>
			<br>
			<span ng-class="{noValue : group.unitValues[unitId].defenceCavalry < 0 }" class="noValue"><i class="unit_defenseCavalry_small_flat_black"></i>?</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div><!-- end ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngIf: unit['distribution']['own'] -->
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
		},
		
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
<template>
	<div class="pendingAjaxRequest loading" v-if="gameModel.pendingAjaxRequestOpen">
		<div class="spin-2 spinner"></div>
	</div>
</template>

<style lang="css">
.loading{
    position:fixed;
    overflow:hidden;
}
@-webkit-keyframes loading{
    from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@-moz-keyframes loading{
    from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@-ms-keyframes loading{
    .loading from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    .loading to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@-o-keyframes loading{
    from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@keyframes loading{
    from{
        -webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -ms-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -ms-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
.loading .spinner{
    cursor:wait;
    background-color:transparent;
    opacity:.8;
    border-radius:50px;
    animation:loading .5s infinite linear;
    -moz-animation:loading .5s infinite linear;
    -webkit-animation:loading .5s infinite linear
}
.loading .spinner.spin-1{
    border:5px solid #fff;
    border-top:5px solid transparent;
    border-left:5px solid transparent;
    width:30px;
    height:30px
}
.loading .spinner.spin-2{
    border:3px solid #fff;
    border-top:3px solid transparent;
    border-left:3px solid transparent;
    width:16px;
    height:16px
}

.pendingAjaxRequest{
    background-color:rgba(0,0,0,0.5);
    height:32px;
    width:32px;
    bottom:30px;
    right:2px;
    z-index:100;
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    -ms-border-radius:8px;
    -o-border-radius:8px;
    border-radius:8px
}
.pendingAjaxRequest .spinner{
    margin-top:5px;
    margin-left:5px
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper igm" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal igmSystemWindow igm">
					<div class="modalContent" :style="{height: modalHeight}">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Сообщения</span></span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="igmSystemCtrl" class="igmSystem">
														<div class="firstHalf">
															<div class="background"></div>
															<div class="newThreadContainer">
																<div ng-if="!isBannedFromMessaging && isActivated" class="clickableContainer clickable" @click="newThreadShow = true" clickable="newThread()" tooltip="" tooltip-translate="Chat.StartConversation.Tooltip">
																	<span translate=""><span>Новая беседа</span></span>
																	<div class="plusSign"><i class="symbol_plus_tiny_flat_white"></i></div>
																</div>
															</div>
															<div class="history scrollable" scrollable="" ng-click="setChatInputState(0)">
																<div class="scrollContentOuterWrapper" style="width: 256px;">
																	<div class="scrollContent" dynamic-height="false" style="width: 256px; overflow-y: hidden;">
																		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
																			<ul>
																				<li ng-repeat-start="inboxEntry in filteredInbox" class="divider">
																					<div class="chat-time-divider noHoverEffect" last="" current="1707727020321">
																						<h6 class="divider" ng-if="timeTitle != ''">
																							<span class="fadeIn"></span>
																							<span translate="" class="label" options="Chat.Time_Yesterday"><span>Вчера</span></span>
																							<span class="fadeOut"></span>
																						</h6>
																					</div>
																				</li>
																				<li ng-repeat-end="" class="igmConversationEntry clickable closed selected" clickable="openConversation(inboxEntry)" chat-igm-entry="" is-other-player-deleted="isPlayerDeleted(inboxEntry.data.otherPlayer.data.playerId)" inbox-entry="inboxEntry" ng-class="{selected: selectedConversation.data.roomId == inboxEntry.data.roomId, new: inboxEntry.data.ownUnread, closed: inboxEntry.data.closed}">
																					<div class="igmAvatar">
																						<div class="avatar-image" scale="0.5" player-id="127" avatar-class="profile" ng-if="!isOtherPlayerDeleted && inboxEntry.data.playerId <= 0 && inboxEntry.data.playerId !== -1">
																							<div class="heroImage  profile male" ng-class="::class" rerender="rerender">
																								<canvas width="62" height="62"></canvas>
																							</div>
																						</div>
																						<i class="conversation_kingdom_medium_flat_black groupConversationIcon" ng-if="::inboxEntry.data.group != '' && inboxEntry.data.roomType == ChatRoom.TYPE_KINGDOM"></i>
																					</div>
																					<div class="verticalLine"></div>
																					<div class="igmInfos">
																						<div class="name roomType5 truncated" ng-if="::inboxEntry.data.group != ''">
																							<span translate="" ng-if="::inboxEntry.data.roomType == ChatRoom.TYPE_KINGDOM"><span>Королевство</span></span>
																						</div>
																						<div class="linePreview truncated" ng-if="::!short">
																							<span ng-if="::inboxEntry.data.line" user-text-parse="inboxEntry.data.line" parse="decorations;linkings;reports;coordinates"><span>Я так полагаю, нам нужна будет флудильня куда можно будет писать вообще все и отдельно для срочных уведомлений для защиты</span></span>
																						</div>
																						<div class="date" ng-if="::inboxEntry.data.timestamp && !short">
																							<span i18ndt="1704808322.88" format="veryShort">09.01. | 19:52</span>
																						</div>
																						<div class="readNotification" ng-if="inboxEntry.data.closed">
																							<i class="symbol_lock_small_flat_black"></i>
																						</div>
																					</div>
																				</li>
																				<li ng-repeat-start="inboxEntry in filteredInbox" class="divider">
																					<div class="chat-time-divider noHoverEffect" last="1707912449925" current="1707862989085"></div>
																				</li>
																				<li ng-repeat-end="" class="igmConversationEntry clickable" clickable="openConversation(inboxEntry)" chat-igm-entry="" is-other-player-deleted="isPlayerDeleted(inboxEntry.data.otherPlayer.data.playerId)" inbox-entry="inboxEntry" ng-class="{selected: selectedConversation.data.roomId == inboxEntry.data.roomId, new: inboxEntry.data.ownUnread, closed: inboxEntry.data.closed}">
																					<div class="igmAvatar">
																						<div class="avatar-image" scale="0.5" player-id="637" avatar-class="profile" ng-if="inboxEntry.data.playerId > 0 && !isOtherPlayerDeleted">
																							<div class="heroImage  profile male" ng-class="::class" rerender="rerender">
																								<canvas width="62" height="62"></canvas>
																							</div>
																						</div>
																					</div>
																					<div class="verticalLine"></div>
																					<div class="igmInfos">
																						<div class="name roomType1 truncated" ng-if="inboxEntry.data.group == '' && !isOtherPlayerDeleted">Неясыть</div>
																						<div class="linePreview truncated" ng-if="::!short">
																							<span ng-if="::inboxEntry.data.line" user-text-parse="inboxEntry.data.line" parse="decorations;linkings;reports;coordinates"><span>привет</span><br><span>к нам пойдешь?)</span></span>
																						</div>
																						<div class="date" ng-if="::inboxEntry.data.timestamp && !short">
																							<span i18ndt="1707727020.321" format="veryShort">12.02. | 14:37</span>
																						</div>
																					</div>
																				</li>
																				<li ng-repeat-start="inboxEntry in filteredInbox" class="divider">
																					<div class="chat-time-divider noHoverEffect" last="1707727020321" current="1707083918966">
																						<h6 class="divider" ng-if="timeTitle != ''">
																							<span class="fadeIn"></span>
																							<span translate="" class="label" options="Chat.Time_OlderThan7Days"><span>Старше, чем 7 дней</span></span>
																							<span class="fadeOut"></span>
																						</h6>
																					</div>
																				</li>
																				<li ng-repeat-end="" class="igmConversationEntry clickable new" clickable="openConversation(inboxEntry)" chat-igm-entry="" is-other-player-deleted="isPlayerDeleted(inboxEntry.data.otherPlayer.data.playerId)" inbox-entry="inboxEntry" ng-class="{selected: selectedConversation.data.roomId == inboxEntry.data.roomId, new: inboxEntry.data.ownUnread, closed: inboxEntry.data.closed}">
																					<div class="igmAvatar">
																						<div class="avatar-image" scale="0.5" player-id="155" avatar-class="profile" ng-if="inboxEntry.data.playerId > 0 && !isOtherPlayerDeleted">
																							<div class="heroImage  profile male" ng-class="::class" rerender="rerender">
																								<canvas width="62" height="62"></canvas>
																							</div>
																						</div>
																					</div>
																					<div class="verticalLine"></div>
																					<div class="igmInfos">
																						<div class="name roomType1 truncated" ng-if="inboxEntry.data.group == '' && !isOtherPlayerDeleted">Nol1k</div>
																						<div class="linePreview truncated" ng-if="::!short">
																							<span ng-if="::inboxEntry.data.line" user-text-parse="inboxEntry.data.line" parse="decorations;linkings;reports;coordinates"><span>Привет. С кем планируешь играть?</span></span>
																						</div>
																						<div class="date" ng-if="::inboxEntry.data.timestamp && !short">
																							<span i18ndt="1707083918.966" format="veryShort">05.02. | 03:58</span>
																						</div>
																					</div>
																				</li>
																				<li ng-repeat-start="inboxEntry in filteredInbox" class="divider">
																					<div class="chat-time-divider noHoverEffect" last="1707915352699" current="1707552882663">
																						<h6 class="divider" ng-if="timeTitle != ''">
																							<span class="fadeIn"></span>
																							<span translate="" class="label" options="Chat.Time_Last7Days"><span>Последние 7 дней</span></span>
																							<span class="fadeOut"></span>
																						</h6>
																					</div>
																				</li>
																				<li ng-repeat-end="" class="igmConversationEntry clickable" clickable="openConversation(inboxEntry)" chat-igm-entry="" is-other-player-deleted="isPlayerDeleted(inboxEntry.data.otherPlayer.data.playerId)" inbox-entry="inboxEntry" ng-class="{selected: selectedConversation.data.roomId == inboxEntry.data.roomId, new: inboxEntry.data.ownUnread, closed: inboxEntry.data.closed}">
																					<div class="igmAvatar">
																						<div class="avatar-image" scale="0.5" player-id="404" avatar-class="profile" ng-if="!isOtherPlayerDeleted && inboxEntry.data.playerId <= 0 && inboxEntry.data.playerId !== -1">
																							<div class="heroImage  profile male" ng-class="::class" rerender="rerender">
																								<canvas width="62" height="62"></canvas>
																							</div>
																						</div>
																						<i class="conversation_kingdom_medium_flat_black groupConversationIcon" ng-if="::inboxEntry.data.group != '' && inboxEntry.data.roomType == ChatRoom.TYPE_KINGDOM"></i>
																					</div>
																					<div class="verticalLine"></div>
																					<div class="igmInfos">
																						<div class="name roomType5 truncated" ng-if="::inboxEntry.data.group != ''">
																							<span translate="" ng-if="::inboxEntry.data.roomType == ChatRoom.TYPE_KINGDOM">
																								<span>Королевство</span>
																							</span>
																						</div>
																						<div class="linePreview truncated" ng-if="::!short">
																							<span ng-if="::inboxEntry.data.line" user-text-parse="inboxEntry.data.line" parse="decorations;linkings;reports;coordinates">
																								<span>ЗАПРОС ДЕФФА</span>
																							</span>
																						</div>
																						<div class="date" ng-if="::inboxEntry.data.timestamp && !short">
																							<span i18ndt="1705218665.273" format="veryShort">14.01. | 13:51</span>
																						</div>
																						<div class="readNotification" ng-if="inboxEntry.data.otherRead && !inboxEntry.data.closed">
																							<i class="action_check_tiny_flat_green" ng-if="inboxEntry.data.otherRead >= inboxEntry.data.totalParticipents" tooltip="" tooltip-translate="Chat.HasReadMessage"></i>
																						</div>
																					</div>
																				</li>
																				<li ng-repeat-start="inboxEntry in filteredInbox" class="divider">
																					<div class="chat-time-divider noHoverEffect" last="1707727020321" current="1707083918966">
																						<h6 class="divider" ng-if="timeTitle != ''">
																							<span class="fadeIn"></span>
																							<span translate="" class="label" options="Chat.Time_OlderThan7Days"><span>Сегодня</span></span>
																							<span class="fadeOut"></span>
																						</h6>
																					</div>
																				</li>
																				<li ng-repeat-end="" class="igmConversationEntry clickable" clickable="openConversation(inboxEntry)" chat-igm-entry="" is-other-player-deleted="isPlayerDeleted(inboxEntry.data.otherPlayer.data.playerId)" inbox-entry="inboxEntry" ng-class="{selected: selectedConversation.data.roomId == inboxEntry.data.roomId, new: inboxEntry.data.ownUnread, closed: inboxEntry.data.closed}">
																					<div class="igmAvatar">
																						<div class="avatar-image" scale="0.5" player-id="155" avatar-class="profile" ng-if="inboxEntry.data.playerId > 0 && !isOtherPlayerDeleted">
																							<div class="heroImage  profile male" ng-class="::class" rerender="rerender">
																								<canvas width="62" height="62"></canvas>
																							</div>
																						</div>
																					</div>
																					<div class="verticalLine"></div>
																					<div class="igmInfos">
																						<div class="name roomType1 truncated" ng-if="inboxEntry.data.group == '' && !isOtherPlayerDeleted">Nol1k</div>
																						<div class="linePreview truncated" ng-if="::!short">
																							<span ng-if="::inboxEntry.data.line" user-text-parse="inboxEntry.data.line" parse="decorations;linkings;reports;coordinates"><span>Привет. С кем планируешь играть?</span></span>
																						</div>
																						<div class="date" ng-if="::inboxEntry.data.timestamp && !short">
																							<span i18ndt="1707083918.966" format="veryShort">05.02. | 03:58</span>
																						</div>
																					</div>
																				</li>
																				<li ng-repeat-start="inboxEntry in filteredInbox" class="divider">
																					<div class="chat-time-divider noHoverEffect" last="1707727020321" current="1707083918966">
																						<h6 class="divider" ng-if="timeTitle != ''">
																							<span class="fadeIn"></span>
																							<span translate="" class="label" options="Chat.Time_OlderThan7Days"><span>Вчера</span></span>
																							<span class="fadeOut"></span>
																						</h6>
																					</div>
																				</li>
																				<li ng-repeat-end="" class="igmConversationEntry clickable" clickable="openConversation(inboxEntry)" chat-igm-entry="" is-other-player-deleted="isPlayerDeleted(inboxEntry.data.otherPlayer.data.playerId)" inbox-entry="inboxEntry" ng-class="{selected: selectedConversation.data.roomId == inboxEntry.data.roomId, new: inboxEntry.data.ownUnread, closed: inboxEntry.data.closed}">
																					<div class="igmAvatar">
																						<div class="avatar-image" scale="0.5" player-id="155" avatar-class="profile" ng-if="inboxEntry.data.playerId > 0 && !isOtherPlayerDeleted">
																							<div class="heroImage  profile male" ng-class="::class" rerender="rerender">
																								<canvas width="62" height="62"></canvas>
																							</div>
																						</div>
																					</div>
																					<div class="verticalLine"></div>
																					<div class="igmInfos">
																						<div class="name roomType1 truncated" ng-if="inboxEntry.data.group == '' && !isOtherPlayerDeleted">Nol1k</div>
																						<div class="linePreview truncated" ng-if="::!short">
																							<span ng-if="::inboxEntry.data.line" user-text-parse="inboxEntry.data.line" parse="decorations;linkings;reports;coordinates"><span>Привет. С кем планируешь играть?</span></span>
																						</div>
																						<div class="date" ng-if="::inboxEntry.data.timestamp && !short">
																							<span i18ndt="1707083918.966" format="veryShort">05.02. | 03:58</span>
																						</div>
																					</div>
																				</li>
																				<div class="divider"></div>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="threadView filled closed" ng-class="{filled: selectedConversation != null, closed: selectedConversation != null && selectedConversation.data.closed}">
															<div class="conversationHeader selected" ng-class="{selected: selectedConversation != null}">
																<div class="arrow"></div>
																
																<div class="conversationHeaderInner cantClose" ng-if="selectedConversation != null && selectedConversation.data.closed" ng-class="{cantClose: selectedConversation.data.linePlayerId !== playerId && !player.isKing()}">
																	<div class="title" translate="" data="playerName:Gaius Ulius">
																		<span>Тема была закрыта игроком Gaius Ulius.</span>
																	</div>
																</div>
																
																<!--
																<div class="conversationHeaderInner" ng-if="selectedConversation != null && !selectedConversation.data.closed">
																	<div class="title truncated" ng-if="!isPlayerDeleted(selectedConversation.data.otherPlayer.data.playerId)" translate="" data="name:Чат королевства" options="5">
																		<span>Сообщение королевству</span>
																	</div>
																	<div class="menu" ng-if="!selectedConversation.isAdminMessage()">
																		<div class="options" more-dropdown="" more-dropdown-options="getConversationOptions()">
																			<a class="iconButton clickable" @click="dropdownMoreShow = !dropdownMoreShow" clickable="openMoreDropdown()">
																				<i class="symbol_arrowDown_tiny_illu"></i>
																			</a>
																			<div class="moreDropDownBody dropdownBody" style="top: 24px; right: 15px; display: block;" v-if="dropdownMoreShow">
																				<ul>
																					<li ng-repeat="option in options" clickable="reportConversation('5.3.1704808364356'); closeMoreDropdown()" class="clickable">Пожаловаться на сообщение</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
																
																<div class="conversationHeaderInner" ng-if="selectedConversation != null && !selectedConversation.data.closed">
																	<div class="title truncated" ng-if="!isPlayerDeleted(selectedConversation.data.otherPlayer.data.playerId)" translate="" data="name:Неясыть" options="1">
																		<span>Беседа с Неясыть</span>
																	</div>
																	<div class="menu" ng-if="!selectedConversation.isAdminMessage()">
																		<div class="options" more-dropdown="" more-dropdown-options="getConversationOptions()" style="position: relative;">
																			<a class="iconButton clickable" @click="dropdownMoreShow = !dropdownMoreShow" clickable="openMoreDropdown()">
																				<i class="symbol_arrowDown_tiny_illu"></i>
																			</a>
																			<div class="moreDropDownBody dropdownBody" style="top: 24px; right: 15px; display: block;" v-if="dropdownMoreShow">
																				<ul>
																					<li ng-repeat="option in options" clickable="openWindow('profile', {'playerId': 637, 'profileTab': 'playerProfile'}); closeMoreDropdown()" class="clickable">Перейти к профилю</li>
																					<li ng-repeat="option in options" clickable="addFriend(637); closeMoreDropdown()" class="clickable">Добавить в друзья</li>
																					<li ng-repeat="option in options" clickable="deleteInboxEntry('1.1804.637'); closeMoreDropdown()" class="clickable">Скрыть разговор</li>
																					<li ng-repeat="option in options" clickable="reportConversation('1.1804.637', 637); closeMoreDropdown()" class="clickable">Пожаловаться на сообщение</li>
																					<li ng-repeat="option in options" clickable="ignorePlayer('1.1804.637',637); closeMoreDropdown()" class="clickable">Добавить в черный список</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
																-->
																
															</div>
															<div ng-include="" src="'tpl/chat/chatRoomBody.html'" ng-if="selectedConversation != null" class="chatRoomBody" ng-class="{smaller: chatInputState>0}">
																<div class="chatBody scrollable" scrollable="" scroll-down="" dropable="checkDrop(object)" ng-click="setChatInputState(0)">
																	<div class="scrollContentOuterWrapper" style="width: 422px;">
																		<div class="scrollContent" dynamic-height="false" style="width: 422px; overflow-y: hidden;">
																			<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
																				<ul>
																					<li class="divider loadOlder" ng-if="chatLines.length> 0 && !haveFirst">
																						<span><a translate="" clickable="loadOlder()" class="clickable"><span>Больше информации</span></a></span>
																					</li>
																					
																					<li ng-repeat-start="inboxLine in (orderedData = (chatLines | orderBy:'data.timestamp')) as repeatList">
																						<div class="chat-time-divider" last="" current="1707727020321" reverse="true">
																							<h6 class="divider" ng-if="timeTitle != ''">
																								<span class="fadeIn"></span>
																								<span translate="" class="label" options="Chat.Time_Last7Days"><span>Последние 7 дней</span></span>
																								<span class="fadeOut"></span>
																							</h6>
																						</div>
																					</li>
																					<li class="line otherPlayer" ng-class="{currentPlayer: player.data.playerId == inboxLine.data.playerId && !isMonologue, otherPlayer: player.data.playerId != inboxLine.data.playerId || isMonologue, samePlayerAndTime : orderedData[$index-1].data.timestamp > inboxLine.data.timestamp-60000 && orderedData[$index-1].data.playerId == inboxLine.data.playerId && (!repeatList[$index-1].data.isFirst || selectedConversation.data.isPrivateChat), new: inboxLine.data.newLine == 1, fade: inboxLine.data.newLine == 2, firstMassMail: inboxLine.data.isFirst && selectedConversation.data.isMassMail}">
																						<div class="lineHead">
																							<span class="name truncated">Неясыть</span>
																							<div class="options" ng-if="::inboxLine.data.playerId != player.data.playerId" more-dropdown="" more-dropdown-options="getPlayerOptions(637)" append-local="true">
																								<a class="iconButton clickable" clickable="openMoreDropdown()">
																									<i class="symbol_arrowDown_tiny_illu"></i>
																								</a>
																							</div>
																							<span class="date" i18ndt="1707727020.321" format="veryShort">12.02. | 14:37</span>
																						</div>
																						<div class="lineBody" user-text-parse="inboxLine.data.text" parse="decorations;linkings;reports;coordinates" room-id="">
																							<span>привет</span><br><span>к нам пойдешь?)</span>
																							<i class="reportIcon reportTypes_reportIcon2_flat colorIcon draggableReport clickable msAntiScroll" clickable="openWindow('reportSingle', {'tab': 'own','reportId': '65c40621c70dac14b40ad220', 'collection': 'own', 'reportToken': 'e881c'}, null, true)" draggable="true" content="Нажми здесь, чтобы открыть отчет, или перетяни его в другое окно чата, чтобы поделиться с другим игроком" v-tippy="{placement: 'top'}" tooltip-translate="Chat.ReportIcon.Tooltip"></i>
																							<i class="reportIcon reportTypes_reportIcon1_flat colorIcon draggableReport clickable msAntiScroll" clickable="openWindow('reportSingle', {'tab': 'own','reportId': '65c6b0cfa0db9d01240653b5', 'collection': 'own', 'reportToken': '0c11d'}, null, true)" draggable="true" tooltip="" tooltip-translate="Chat.ReportIcon.Tooltip"></i>
																							<i class="reportIcon reportTypes_reportIcon5_flat colorIcon draggableReport clickable msAntiScroll" clickable="openWindow('reportSingle', {'tab': 'own','reportId': '65c7d5d4301816a80e0f1fbf', 'collection': 'own', 'reportToken': 'a2315'}, null, true)" draggable="true" tooltip="" tooltip-translate="Chat.ReportIcon.Tooltip"></i>
																							<i class="reportIcon reportTypes_reportIcon16_flat colorIcon draggableReport clickable msAntiScroll" clickable="openWindow('reportSingle', {'tab': 'own','reportId': '65c84c0e0746c2335a006c78', 'collection': 'own', 'reportToken': 'ac750'}, null, true)" draggable="true" tooltip="" tooltip-translate="Chat.ReportIcon.Tooltip"></i>
																							<i class="reportIcon reportTypes_reportIcon3_flat colorIcon draggableReport clickable msAntiScroll" clickable="openWindow('reportSingle', {'tab': 'own','reportId': '65c88af947e8c547ea052c8d', 'collection': 'own', 'reportToken': '7f2a9'}, null, true)" draggable="true" tooltip="" tooltip-translate="Chat.ReportIcon.Tooltip"></i>
																							<i class="reportIcon reportTypes_reportIcon15_flat colorIcon draggableReport clickable msAntiScroll" clickable="openWindow('reportSingle', {'tab': 'own','reportId': '65cbeb14072a5bfa50089dda', 'collection': 'own', 'reportToken': '3124d'}, null, true)" draggable="true" tooltip="" tooltip-translate="Chat.ReportIcon.Tooltip"></i>
																						</div>
																						<div class="readNotification" ng-if="!isMonologue"></div>
																					</li>
																					<li ng-repeat-end="">
																						<!-- ngIf: ::inboxLine.data.isFirst && selectedConversation.data.isMassMail -->
																					</li>
																					<li ng-repeat-start="inboxLine in (orderedData = (chatLines | orderBy:'data.timestamp')) as repeatList">
																						<div class="chat-time-divider" last="1707760073997" current="1707782946625" reverse="true">
																							<h6 class="divider" ng-if="timeTitle != ''">
																								<span class="fadeIn"></span>
																								<span translate="" class="label" options="Chat.Time_Yesterday"><span>Вчера</span></span>
																								<span class="fadeOut"></span>
																							</h6>
																						</div>
																					</li>
																					<li class="line otherPlayer" ng-class="{currentPlayer: player.data.playerId == inboxLine.data.playerId && !isMonologue, otherPlayer: player.data.playerId != inboxLine.data.playerId || isMonologue, samePlayerAndTime : orderedData[$index-1].data.timestamp > inboxLine.data.timestamp-60000 && orderedData[$index-1].data.playerId == inboxLine.data.playerId && (!repeatList[$index-1].data.isFirst || selectedConversation.data.isPrivateChat), new: inboxLine.data.newLine == 1, fade: inboxLine.data.newLine == 2, firstMassMail: inboxLine.data.isFirst && selectedConversation.data.isMassMail}">
																						<div class="lineHead">
																							<span class="name truncated">Marabut</span>
																							<div class="options" ng-if="::inboxLine.data.playerId != player.data.playerId" more-dropdown="" more-dropdown-options="getPlayerOptions(1890)" append-local="true">
																								<a class="iconButton clickable" clickable="openMoreDropdown()">
																									<i class="symbol_arrowDown_tiny_illu"></i>
																								</a>
																							</div>
																							<span class="date" i18ndt="1707782946.625" format="veryShort">13.02. | 06:09</span>
																						</div>
																						<div class="lineBody" user-text-parse="inboxLine.data.text" parse="decorations;linkings;reports;coordinates" room-id="">
																							<span>Marabout +</span>
																						</div>
																						<div class="readNotification" ng-if="!isMonologue">
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead >= inboxLine.data.timestamp -->
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead < inboxLine.data.timestamp -->
																						</div>
																					</li>
																					<li ng-repeat-end="">
																						<!-- ngIf: ::inboxLine.data.isFirst && selectedConversation.data.isMassMail -->
																					</li>
																					<li ng-repeat-start="inboxLine in (orderedData = (chatLines | orderBy:'data.timestamp')) as repeatList">
																						<div class="chat-time-divider" last="1707782946625" current="1707802325228" reverse="true"></div>
																					</li>
																					<li class="line otherPlayer" ng-class="{currentPlayer: player.data.playerId == inboxLine.data.playerId && !isMonologue, otherPlayer: player.data.playerId != inboxLine.data.playerId || isMonologue, samePlayerAndTime : orderedData[$index-1].data.timestamp > inboxLine.data.timestamp-60000 && orderedData[$index-1].data.playerId == inboxLine.data.playerId && (!repeatList[$index-1].data.isFirst || selectedConversation.data.isPrivateChat), new: inboxLine.data.newLine == 1, fade: inboxLine.data.newLine == 2, firstMassMail: inboxLine.data.isFirst && selectedConversation.data.isMassMail}">
																						<div class="lineHead">
																							<span class="name truncated">ВАРЯГ</span>
																							<div class="options" ng-if="::inboxLine.data.playerId != player.data.playerId" more-dropdown="" more-dropdown-options="getPlayerOptions(1156)" append-local="true">
																								<a class="iconButton clickable" clickable="openMoreDropdown()">
																									<i class="symbol_arrowDown_tiny_illu"></i>
																								</a>
																							</div>
																							<span class="date" i18ndt="1707802325.228" format="veryShort">13.02. | 11:32</span>
																						</div>
																						<div class="lineBody" user-text-parse="inboxLine.data.text" parse="decorations;linkings;reports;coordinates" room-id=""><span>+</span></div>
																						<div class="readNotification" ng-if="!isMonologue">
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead >= inboxLine.data.timestamp -->
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead < inboxLine.data.timestamp -->
																						</div>
																					</li>
																					<li ng-repeat-end="">
																						<!-- ngIf: ::inboxLine.data.isFirst && selectedConversation.data.isMassMail -->
																					</li>
																					<li ng-repeat-start="inboxLine in (orderedData = (chatLines | orderBy:'data.timestamp')) as repeatList">
																						<div class="chat-time-divider" last="1707802325228" current="1707806338201" reverse="true"></div>
																					</li>
																					<li class="line otherPlayer" ng-class="{currentPlayer: player.data.playerId == inboxLine.data.playerId && !isMonologue, otherPlayer: player.data.playerId != inboxLine.data.playerId || isMonologue, samePlayerAndTime : orderedData[$index-1].data.timestamp > inboxLine.data.timestamp-60000 && orderedData[$index-1].data.playerId == inboxLine.data.playerId && (!repeatList[$index-1].data.isFirst || selectedConversation.data.isPrivateChat), new: inboxLine.data.newLine == 1, fade: inboxLine.data.newLine == 2, firstMassMail: inboxLine.data.isFirst && selectedConversation.data.isMassMail}">
																						<div class="lineHead">
																							<span class="name truncated">sinjohn</span>
																							<div class="options" ng-if="::inboxLine.data.playerId != player.data.playerId" more-dropdown="" more-dropdown-options="getPlayerOptions(701)" append-local="true">
																								<a class="iconButton clickable" clickable="openMoreDropdown()">
																									<i class="symbol_arrowDown_tiny_illu"></i>
																								</a>
																							</div>
																							<span class="date" i18ndt="1707806338.201" format="veryShort">13.02. | 12:38</span>
																						</div>
																						<div class="lineBody" user-text-parse="inboxLine.data.text" parse="decorations;linkings;reports;coordinates" room-id=""><span>+</span></div>
																						<div class="readNotification" ng-if="!isMonologue">
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead >= inboxLine.data.timestamp -->
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead < inboxLine.data.timestamp -->
																						</div>
																					</li>
																					<li ng-repeat-end="">
																						<!-- ngIf: ::inboxLine.data.isFirst && selectedConversation.data.isMassMail -->
																					</li>
																					<li ng-repeat-start="inboxLine in (orderedData = (chatLines | orderBy:'data.timestamp')) as repeatList">
																						<div class="chat-time-divider" last="1707806338201" current="1707835210155" reverse="true"></div>
																					</li>
																					<li class="line otherPlayer" ng-class="{currentPlayer: player.data.playerId == inboxLine.data.playerId && !isMonologue, otherPlayer: player.data.playerId != inboxLine.data.playerId || isMonologue, samePlayerAndTime : orderedData[$index-1].data.timestamp > inboxLine.data.timestamp-60000 && orderedData[$index-1].data.playerId == inboxLine.data.playerId && (!repeatList[$index-1].data.isFirst || selectedConversation.data.isPrivateChat), new: inboxLine.data.newLine == 1, fade: inboxLine.data.newLine == 2, firstMassMail: inboxLine.data.isFirst && selectedConversation.data.isMassMail}">
																						<div class="lineHead">
																							<span class="name truncated">Карабас</span>
																							<div class="options" ng-if="::inboxLine.data.playerId != player.data.playerId" more-dropdown="" more-dropdown-options="getPlayerOptions(204)" append-local="true">
																								<a class="iconButton clickable" clickable="openMoreDropdown()">
																									<i class="symbol_arrowDown_tiny_illu"></i>
																								</a>
																							</div>
																							<span class="date" i18ndt="1707835210.155" format="veryShort">13.02. | 20:40</span>
																						</div>
																						<div class="lineBody" user-text-parse="inboxLine.data.text" parse="decorations;linkings;reports;coordinates" room-id=""><span>+</span></div>
																						<div class="readNotification" ng-if="!isMonologue">
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead >= inboxLine.data.timestamp -->
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead < inboxLine.data.timestamp -->
																						</div>
																					</li>
																					<li ng-repeat-end="">
																						<!-- ngIf: ::inboxLine.data.isFirst && selectedConversation.data.isMassMail -->
																					</li>
																					<li ng-repeat-start="inboxLine in (orderedData = (chatLines | orderBy:'data.timestamp')) as repeatList">
																						<div class="chat-time-divider" last="1707727020321" current="1707899380213" reverse="true">
																							<h6 class="divider" ng-if="timeTitle != ''">
																								<span class="fadeIn"></span>
																								<span class="label" options="Chat.Time_Today"><span>Сегодня</span></span>
																								<span class="fadeOut"></span>
																							</h6>
																						</div>
																					</li>
																					<li class="line currentPlayer" ng-class="{currentPlayer: player.data.playerId == inboxLine.data.playerId && !isMonologue, otherPlayer: player.data.playerId != inboxLine.data.playerId || isMonologue, samePlayerAndTime : orderedData[$index-1].data.timestamp > inboxLine.data.timestamp-60000 && orderedData[$index-1].data.playerId == inboxLine.data.playerId && (!repeatList[$index-1].data.isFirst || selectedConversation.data.isPrivateChat), new: inboxLine.data.newLine == 1, fade: inboxLine.data.newLine == 2, firstMassMail: inboxLine.data.isFirst && selectedConversation.data.isMassMail}">
																						<div class="lineHead">
																							<span class="name truncated">lexinzector</span>
																							<span class="date" i18ndt="1707899380.213" format="veryShort">14:29</span>
																						</div>
																						<div class="lineBody" user-text-parse="inboxLine.data.text" parse="decorations;linkings;reports;coordinates" room-id="">
																							<span>привет. а давай</span>
																						</div>
																						<div class="readNotification" ng-if="!isMonologue">
																							<i class="action_check_tiny_flat_black gray" ng-if="selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead < inboxLine.data.timestamp" tooltip="" tooltip-translate="Chat.HasNotReadMessage"></i>
																						</div>
																					</li>
																					<li ng-repeat-end="">
																						<!-- ngIf: ::inboxLine.data.isFirst && selectedConversation.data.isMassMail -->
																					</li>
																					<li ng-repeat-start="inboxLine in (orderedData = (chatLines | orderBy:'data.timestamp')) as repeatList">
																						<div class="chat-time-divider" last="1707899380213" current="1707901927969" reverse="true"></div>
																					</li>
																					<li class="line otherPlayer fade" ng-class="{currentPlayer: player.data.playerId == inboxLine.data.playerId && !isMonologue, otherPlayer: player.data.playerId != inboxLine.data.playerId || isMonologue, samePlayerAndTime : orderedData[$index-1].data.timestamp > inboxLine.data.timestamp-60000 && orderedData[$index-1].data.playerId == inboxLine.data.playerId && (!repeatList[$index-1].data.isFirst || selectedConversation.data.isPrivateChat), new: inboxLine.data.newLine == 1, fade: inboxLine.data.newLine == 2, firstMassMail: inboxLine.data.isFirst && selectedConversation.data.isMassMail}">
																						<div class="lineHead">
																							<span class="name truncated">Неясыть</span>
																							<div class="options" ng-if="::inboxLine.data.playerId != player.data.playerId" more-dropdown="" more-dropdown-options="getPlayerOptions(637)" append-local="true">
																								<a class="iconButton clickable" clickable="openMoreDropdown()">
																									<i class="symbol_arrowDown_tiny_illu"></i>
																								</a>
																							</div>
																							<span class="date" i18ndt="1707901927.969" format="veryShort">15:12</span>
																						</div>
																						<div class="lineBody" user-text-parse="inboxLine.data.text" parse="decorations;linkings;reports;coordinates" room-id="">
																							<span>написал королю, кинет приглашение</span>
																						</div>
																						<div class="readNotification" ng-if="!isMonologue">
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead >= inboxLine.data.timestamp -->
																							<!-- ngIf: selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead < inboxLine.data.timestamp -->
																						</div>
																					</li>
																					<li ng-repeat-end="">
																						<!-- ngIf: ::inboxLine.data.isFirst && selectedConversation.data.isMassMail -->
																					</li>
																					<li ng-repeat-start="inboxLine in (orderedData = (chatLines | orderBy:'data.timestamp')) as repeatList">
																						<div class="chat-time-divider" last="1707901927969" current="1707901944868" reverse="true"></div>
																					</li>
																					<li class="line currentPlayer" ng-class="{currentPlayer: player.data.playerId == inboxLine.data.playerId && !isMonologue, otherPlayer: player.data.playerId != inboxLine.data.playerId || isMonologue, samePlayerAndTime : orderedData[$index-1].data.timestamp > inboxLine.data.timestamp-60000 && orderedData[$index-1].data.playerId == inboxLine.data.playerId && (!repeatList[$index-1].data.isFirst || selectedConversation.data.isPrivateChat), new: inboxLine.data.newLine == 1, fade: inboxLine.data.newLine == 2, firstMassMail: inboxLine.data.isFirst && selectedConversation.data.isMassMail}">
																						<div class="lineHead">
																							<span class="name truncated">lexinzector</span>
																							<span class="date" i18ndt="1707901944.868" format="veryShort">15:12</span>
																						</div>
																						<div class="lineBody" user-text-parse="inboxLine.data.text" parse="decorations;linkings;reports;coordinates" room-id="">
																							<span>гуд. жду.</span>
																						</div>
																						<div class="readNotification" ng-if="!isMonologue">
																							<i class="action_check_tiny_flat_green" ng-if="selectedConversation.data.isPrivateChat && player.data.playerId == inboxLine.data.playerId && selectedConversation.data.lastOtherRead >= inboxLine.data.timestamp" tooltip="" tooltip-translate="Chat.HasReadMessage"></i>
																						</div>
																					</li>
																					<li ng-repeat-end="">
																						<!-- ngIf: ::inboxLine.data.isFirst && selectedConversation.data.isMassMail -->
																					</li>
																					<li ng-repeat-end=""></li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
																<textarea ng-if="!isBannedFromMessaging && isActivated" row="2" class="chatInput" chat-input="" send-function="send" room-id="" dropable="checkDrop(object)" auto-expand="true" min-height="16" max-height="150"></textarea>
															</div>
															<div v-if="!writeAreaShow" @click="writeAreaShow = true" ng-if="selectedConversation != null && !selectedConversation.data.closed && !selectedConversation.isAdminMessage()" tooltip="" class="openWrite" tooltip-hide="(!isBannedFromMessaging && isActivated)" tooltip-data="timeFinish:0">
																<div ng-if="selectedConversation != null && chatInputState == 0" clickable="setChatInputState(1)" class="clickable">
																	<i class="chat_insert_message_small_flat writeMessage"></i>
																	<div class="inputWrapper">
																		<input ng-disabled="isBannedFromMessaging || !isActivated || isIgnored || isPlayerDeleted(selectedConversation.data.otherPlayer.data.playerId, true)" type="text" placeholder="Нажми здесь, чтобы ответить" ng-model="input.textToSend" class="ng-pristine ng-untouched ng-valid">
																	</div>
																</div>
															</div>
															<div v-if="writeAreaShow" class="writeArea" ng-if="selectedConversation != null && !selectedConversation.data.closed && chatInputState == 1">
																<div class="menu">
																	<i class="close action_cancel_tiny_flat_black clickable" @click="writeAreaShow = false" clickable="setChatInputState(0)" content="Закрыть" v-tippy="{placement: 'top'}" tooltip-translate="Button.Close"></i>
																</div>
																<div class="inputTextAreaWrapper bbCodeInput ng-valid" bb-code-input="" set-focus="true" ng-model="input.textToSend" auto-expand="true" min-height="16" max-height="230">
																	<div class="codes">
																		<div class="iconButton clickable" clickable="insertCode('[b]','[/b]')" :class="{'disabled': preview}" content="Жирный" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Bold">
																			<i class="bbCode_bold_small_flat_black"></i>
																		</div>
																		<div class="iconButton clickable" clickable="insertCode('[i]','[/i]')" :class="{'disabled': preview}" content="Курсив" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Italic">
																			<i class="bbCode_italic_small_flat_black"></i>
																		</div>
																		<div class="iconButton clickable" clickable="insertCode('[u]','[/u]')" :class="{'disabled': preview}" content="Подчеркивание" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Underlined">
																			<i class="bbCode_underline_small_flat_black"></i>
																		</div>
																		<div class="iconButton clickable" clickable="insertCode('[s]','[/s]')" :class="{'disabled': preview}" content="Зачеркнуто" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Strikethrough">
																			<i class="bbCode_strikeThrough_small_flat_black"></i>
																		</div>
																		<div class="iconButton clickable" clickable="insertCode('[h]','[/h]')" :class="{'disabled': preview}" content="Выделено" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Highlight">
																			<i class="bbCode_highlight_small_flat_black"></i>
																		</div>
																		<div class="iconButton clickable" @click="searchOpened = !searchOpened" clickable="triggerSearch()" :class="{'disabled': preview, 'active': searchOpened}" content="Действие" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Link">
																			<i class="bbCode_addLink_small_flat_black"></i>
																		</div>
																		<div class="verticalLine"></div>
																		<div class="iconButton clickable" @click="preview = !preview" clickable="triggerPreview()" :class="{'active': preview}" content="Просмотр" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Preview">
																			<i class="bbCode_preview_small_flat_black"></i>
																		</div>
																		<div class="autocomplete ng-hide" v-if="searchOpened">
																			<span class="serverautocompleteContainer ng-valid" autocompletedata="village,player,kingdom,coords,emptyCoords" autocompletecb="searchCallback" ng-model="searchModel">
																				<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span>
																				<input ng-disabled="disabledInput" class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск..." />
																				<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 335px;">
																					<i class="action_search_tiny_flat_white"></i>
																					Деревня, Игрок, Название королевства, Координаты
																				</label>
																			</span>
																		</div>
																	</div>
																	<textarea ng-model="localTextModel" v-if="!preview" ng-attr-auto_focus="!preview" dropable="checkDrop(object)" class="ng-pristine ng-valid ng-touched">	</textarea>
																	<div class="previewWrapper" v-if="preview">
																		<!-- ngIf: preview -->
																	</div>
																</div>
																<div class="buttonWrapper">
																	<i class="chat_insert_message_small_flat writeMessage"></i>
																	<button clickable="triggerSend()" ng-class="{disabled: input.textToSend == ''}" class="clickable disabled">
																		<div class="content">
																			<span ng-transclude="" class="innerText">
																				<span translate=""><span>Отправить сообщение</span></span>
																			</span>
																			<div class="animationOverlay"></div>
																			<i class="checkmark action_check_small_flat_white"></i>
																			<i class="checkmark action_check_small_flat_black"></i>
																			<i class="checkmark action_check_small_flat_red"></i>
																		</div>
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay">
							
							<div v-if="newThreadShow" ng-if="overlayController" class="windowOverlay" id="igmSystemNewConversation" ng-controller="overlayController">
								<div class="darkener clickable" @click="newThreadShow = false" clickable="closeOverlay()"></div>
								<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="634">
									<div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
										<div class="inWindowPopupHeader">
											<h4 translate="" options="Chat.StartConversation" ng-if="overlayConfig['inWindowPopupTitle'] != ''">
												<span>Новая беседа</span>
											</h4>
											<h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4>
											<a class="closeOverlay clickable" @click="newThreadShow = false" clickable="closeOverlay()" translate="">
												<span>Закрыть</span>
											</a>
										</div>
										<div class="inWindowPopupContent">
											<div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']">
												<div class="shareReport newIgmConversation">
													<div class="optionContainer">
														<div class="headerWithArrowDown">
															<div class="content" translate="">
																<span>Получатель</span>
															</div>
														</div>
														<div class="clickableContainer clickable active" ng-class="{active: shareWith=='player'}" clickable="setShareWith('player')">
															<i class="community_friend_medium_flat_black"></i>
															<div class="verticalLine double"></div>
															<span class="serverautocompleteContainer ng-valid" change-input="clearPlayerId()" autocompletedata="player" autocompletecb="selectSharePlayer" ng-model="shareWithPlayerName">
																<!-- ngIf: showOwnVillages -->
																<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span><input ng-disabled="disabledInput" class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск...">
																<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 100px;">
																	<i class="action_search_tiny_flat_white"></i>
																	Игрок
																</label>
																<!-- ngIf: showOwnVillages -->
															</span>
															<div class="label" translate="">
																<span>Игрок</span>
															</div>
															<div class="selectionArrow" ng-if="shareWith=='player'"></div>
														</div>
														<div class="clickableContainer clickable" ng-disabled="user.data.kingdomId == 0" ng-class="{active: shareWith=='kingdom', disabled: user.data.kingdomId == 0}" content="Ты не состоишь в королевстве или у тебя нет прав отправлять массовые рассылки" v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{'Chat.GroupDisabled.Kingdom': true }" clickable="setShareWith('kingdom')">
															<i class="community_kingdom_medium_flat_black"></i>
															<div class="verticalLine double"></div>
															<div class="label" translate="">
																<span>Королевство</span>
															</div>
														</div>
														<div class="clickableContainer clickable disabled" ng-disabled="societies.data.length == 0" ng-class="{active: shareWith=='secretSociety', disabled: societies.data.length == 0}" content="Ты не состоишь ни в одном тайном сообществе" v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{'Chat.GroupDisabled.Societies': true }" clickable="setShareWith('secretSociety')" disabled="disabled">
															<i class="community_secretSociety_medium_flat_black"></i>
															<div class="verticalLine double"></div>
															<div class="label" translate="">
																<span>Тайное сообщество</span>
															</div>
														</div>
													</div>
													<div class="commentContainer contentBox">
														<div class="contentBoxHeader headerWithArrowDown active">
															<div class="content" translate="">
																<span>Сообщение</span>
															</div>
														</div>
														<div class="contentBoxBody">
															<div class="shareMessage bbCodeInput ng-valid" bb-code-input="" ng-model="input.shareMessage">
																<div class="codes">
																	<div class="iconButton clickable" clickable="insertCode('[b]','[/b]')" :class="{'disabled': preview}" content="Жирный" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Bold">
																		<i class="bbCode_bold_small_flat_black"></i>
																	</div>
																	<div class="iconButton clickable" clickable="insertCode('[i]','[/i]')" :class="{'disabled': preview}" content="Курсив" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Italic">
																		<i class="bbCode_italic_small_flat_black"></i>
																	</div>
																	<div class="iconButton clickable" clickable="insertCode('[u]','[/u]')" :class="{'disabled': preview}" content="Подчеркивание" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Underlined">
																		<i class="bbCode_underline_small_flat_black"></i>
																	</div>
																	<div class="iconButton clickable" clickable="insertCode('[s]','[/s]')" :class="{'disabled': preview}" content="Зачеркнуто" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Strikethrough">
																		<i class="bbCode_strikeThrough_small_flat_black"></i>
																	</div>
																	<div class="iconButton clickable" clickable="insertCode('[h]','[/h]')" :class="{'disabled': preview}" content="Выделено" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Highlight">
																		<i class="bbCode_highlight_small_flat_black"></i>
																	</div>
																	<div class="iconButton clickable" @click="searchOpened = !searchOpened" clickable="triggerSearch()" :class="{'disabled': preview, 'active': searchOpened}" content="Действие" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Link">
																		<i class="bbCode_addLink_small_flat_black"></i>
																	</div>
																	<div class="verticalLine"></div>
																	<div class="iconButton clickable" @click="preview = !preview" clickable="triggerPreview()" :class="{'active': preview}" content="Просмотр" v-tippy="{placement: 'top'}" tooltip-translate="BBCode.Preview">
																		<i class="bbCode_preview_small_flat_black"></i>
																	</div>
																	<div class="autocomplete ng-hide" v-if="searchOpened">
																		<span class="serverautocompleteContainer ng-valid" autocompletedata="village,player,kingdom,coords,emptyCoords" autocompletecb="searchCallback" ng-model="searchModel">
																			<!-- ngIf: showOwnVillages -->
																			<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span><input ng-disabled="disabledInput" class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск...">
																			<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 374px;">
																				<i class="action_search_tiny_flat_white"></i>
																				Деревня, Игрок, Название королевства, Координаты
																			</label>
																			<!-- ngIf: showOwnVillages -->
																		</span>
																	</div>
																</div>
																<textarea ng-model="localTextModel" ng-show="!preview" ng-attr-auto_focus="!preview" dropable="checkDrop(object)" class="ng-pristine ng-untouched ng-valid">	</textarea>
																<div class="previewWrapper ng-hide" ng-show="preview">
																	<!-- ngIf: preview -->
																</div>
															</div>
														</div>
														<div class="buttonContainer">
															<button clickable="share();" class="share clickable disabled" ng-class="{disabled:((shareWith=='player' && (!shareWithPlayerId || shareWithPlayerId == '')) || shareWith=='' || input.shareMessage =='' || (shareWith=='secretSociety' && secretSociety == 0)) }">
																<div class="content">
																	<span ng-transclude="" class="innerText">
																		<span translate=""><span>Отправить сообщение</span></span>
																	</span>
																	<div class="animationOverlay"></div>
																	<i class="checkmark action_check_small_flat_white"></i>
																	<i class="checkmark action_check_small_flat_black"></i>
																	<i class="checkmark action_check_small_flat_red"></i>
																</div>
															</button>
															<button @click="newThreadShow = false; onWindowOverlayCancel();" clickable="closeOverlay();" class="cancel clickable">
																<div class="content">
																	<span ng-transclude="" class="innerText">
																		<span translate=""><span>Отмена</span></span>
																	</span>
																	<div class="animationOverlay"></div>
																	<i class="checkmark action_check_small_flat_white"></i>
																	<i class="checkmark action_check_small_flat_black"></i>
																	<i class="checkmark action_check_small_flat_red"></i>
																</div>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Сообщения | IW'});
	},
	data: () => ({
		modalHeight: '685px',
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
		
		dropdownMoreShow: false,
		writeAreaShow: false,
		searchOpened: false,
		preview: false,
		
		newThreadShow: false,
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onWindowOverlayCancel(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_2.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
			
			let modalHeight = window.innerHeight - 270;
			modalHeight = (modalHeight < 605 ? 605 : modalHeight);
			this.modalHeight = modalHeight + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
import axios from 'axios';
import lib from '@/lib';

export default class AreaModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.farmListEditTroops = false;
		this.farmListItemEditTroops = false;
		this.farmListAdd = false;
		this.farmListEdit = false;
		this.farmListAddVillage = false;
		
		
	}
}

<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>NPC-торговец</span></span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
				<div ng-controller="npcTraderCtrl" class="npcTraderContent">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/marketplace/tabs/NpcTrade.html'"><div ng-controller="npcMerchantCtrl" class="marketContent npcTrader contentBox">
	<div class="headerWithIcon arrowDirectionTo contentBoxHeader">
		<i class="movement_trade_medium_flat_black"></i>
		<span translate=""><span>NPC-торговец</span></span>
	</div>
	<div class="contentBoxBody npcTraderDescription">
		<p translate=""><span>NPC-торговцы позволяют перераспределять ресурсы в хранилище по твоему желанию.</span></p>

		<p translate=""><span>Для всех ресурсов ты можешь видеть текущий запас, особые бегунки для перераспределения ресурсов и конечную разницу.</span></p>
	</div>
	<table class="resourcesTable transparent contentBoxBody">
	<tbody class="sliderTable">
		<!-- ngRepeat: (resourceType, resName) in resNames --><tr ng-repeat="(resourceType, resName) in resNames">
			<td class="resCol" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1">
				<i class="unit_wood_medium_illu"></i>
				<span class="resourceAmount woodAmount">1268</span>
			</td>
			<td class="sliderCol">
				<div class="sliderArrow"></div>
				<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-lock="resourceData[resourceType]['locked']" slider-data="resourceData[resourceType]" slider-changed="sliderChanged"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="" style="pointer-events: auto;">
	<div class="trackContainer">
		<div class="track">
			<!-- ngIf: stepCount && !hideSteps -->
			<div class="sliderMarker progressMarker" style="width: 38.7025%;"></div>
			<!-- ngRepeat: marker in sliderMarkers track by $index -->
			<div class="hoverIndicator" style="display: none; left: 269px; opacity: 1;">
				<div class="hoverValue">80000</div>
				<div class="indicator"></div>
			</div>
			<div class="handle unselectable" style="left: 100.626px;"></div>
		</div>
	</div>
	<div class="inputContainer">
		<!-- ngIf: iconClass -->
		<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
		<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
			<i class="action_setMax_small_flat_black"></i>
		</div>
	</div>
</div></slider>
				<div class="lockButtonContainer clickable open" ng-class="{open: !resourceData[resourceType]['locked'], disabled: !resourceData[resourceType]['locked'] &amp;&amp; lockedResources > availableResTypes - 3}" clickable="toggleResourceLock(1)">
					<div class="lockButtonBackground"></div>
					<div class="lockSymbol"></div>
				</div>
			</td>
			<td class="diffCol">
					<span ng-class="{positive: resourceData[resourceType]['diff'] > 0, negative: resourceData[resourceType]['diff'] < 0}" class="positive">
						+29694</span>
				</td>
			</tr><!-- end ngRepeat: (resourceType, resName) in resNames --><tr ng-repeat="(resourceType, resName) in resNames">
			<td class="resCol" content="Глина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_2">
				<i class="unit_clay_medium_illu"></i>
				<span class="resourceAmount clayAmount">17070</span>
			</td>
			<td class="sliderCol">
				<div class="sliderArrow"></div>
				<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-lock="resourceData[resourceType]['locked']" slider-data="resourceData[resourceType]" slider-changed="sliderChanged"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
	<div class="trackContainer">
		<div class="track">
			<!-- ngIf: stepCount && !hideSteps -->
			<div class="sliderMarker progressMarker" style="width: 38.3338%;"></div>
			<!-- ngRepeat: marker in sliderMarkers track by $index -->
			<div class="hoverIndicator" style="display: none; left: 243px;">
				<div class="hoverValue">74400</div>
				<div class="indicator"></div>
			</div>
			<div class="handle unselectable" style="left: 99.6677px;"></div>
		</div>
	</div>
	<div class="inputContainer">
		<!-- ngIf: iconClass -->
		<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
		<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
			<i class="action_setMax_small_flat_black"></i>
		</div>
	</div>
</div></slider>
				<div class="lockButtonContainer clickable open" ng-class="{open: !resourceData[resourceType]['locked'], disabled: !resourceData[resourceType]['locked'] &amp;&amp; lockedResources > availableResTypes - 3}" clickable="toggleResourceLock(2)">
					<div class="lockButtonBackground"></div>
					<div class="lockSymbol"></div>
				</div>
			</td>
			<td class="diffCol">
					<span ng-class="{positive: resourceData[resourceType]['diff'] > 0, negative: resourceData[resourceType]['diff'] < 0}" class="positive">
						+13597</span>
				</td>
			</tr><!-- end ngRepeat: (resourceType, resName) in resNames --><tr ng-repeat="(resourceType, resName) in resNames">
			<td class="resCol" content="Железо" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_3">
				<i class="unit_iron_medium_illu"></i>
				<span class="resourceAmount ironAmount">32646</span>
			</td>
			<td class="sliderCol">
				<div class="sliderArrow"></div>
				<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-lock="resourceData[resourceType]['locked']" slider-data="resourceData[resourceType]" slider-changed="sliderChanged"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="" style="pointer-events: auto;">
	<div class="trackContainer">
		<div class="track">
			<!-- ngIf: stepCount && !hideSteps -->
			<div class="sliderMarker progressMarker" style="width: 38.2587%;"></div>
			<!-- ngRepeat: marker in sliderMarkers track by $index -->
			<div class="hoverIndicator" style="left: 208px; opacity: 1; display: none;">
				<div class="hoverValue">64000</div>
				<div class="indicator"></div>
			</div>
			<div class="handle unselectable" style="left: 99.4728px;"></div>
		</div>
	</div>
	<div class="inputContainer">
		<!-- ngIf: iconClass -->
		<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
		<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
			<i class="action_setMax_small_flat_black"></i>
		</div>
	</div>
</div></slider>
				<div class="lockButtonContainer clickable open" ng-class="{open: !resourceData[resourceType]['locked'], disabled: !resourceData[resourceType]['locked'] &amp;&amp; lockedResources > availableResTypes - 3}" clickable="toggleResourceLock(3)">
					<div class="lockButtonBackground"></div>
					<div class="lockSymbol"></div>
				</div>
			</td>
			<td class="diffCol">
					<span ng-class="{positive: resourceData[resourceType]['diff'] > 0, negative: resourceData[resourceType]['diff'] < 0}" class="negative">
						-2039</span>
				</td>
			</tr><!-- end ngRepeat: (resourceType, resName) in resNames --><tr ng-repeat="(resourceType, resName) in resNames">
			<td class="resCol" content="Зерно" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_4">
				<i class="unit_crop_medium_illu"></i>
				<span class="resourceAmount cropAmount">71209</span>
			</td>
			<td class="sliderCol">
				<div class="sliderArrow"></div>
				<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-lock="resourceData[resourceType]['locked']" slider-data="resourceData[resourceType]" slider-changed="sliderChanged"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
	<div class="trackContainer">
		<div class="track">
			<!-- ngIf: stepCount && !hideSteps -->
			<div class="sliderMarker progressMarker" style="width: 37.4462%;"></div>
			<!-- ngRepeat: marker in sliderMarkers track by $index -->
			<div class="hoverIndicator" style="display: none; left: 269px;">
				<div class="hoverValue">80000</div>
				<div class="indicator"></div>
			</div>
			<div class="handle unselectable" style="left: 97.3602px;"></div>
		</div>
	</div>
	<div class="inputContainer">
		<!-- ngIf: iconClass -->
		<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
		<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
			<i class="action_setMax_small_flat_black"></i>
		</div>
	</div>
</div></slider>
				<div class="lockButtonContainer clickable open" ng-class="{open: !resourceData[resourceType]['locked'], disabled: !resourceData[resourceType]['locked'] &amp;&amp; lockedResources > availableResTypes - 3}" clickable="toggleResourceLock(4)">
					<div class="lockButtonBackground"></div>
					<div class="lockSymbol"></div>
				</div>
			</td>
			<td class="diffCol">
					<span ng-class="{positive: resourceData[resourceType]['diff'] > 0, negative: resourceData[resourceType]['diff'] < 0}" class="negative">
						-41252</span>
				</td>
			</tr><!-- end ngRepeat: (resourceType, resName) in resNames -->
			<tr class="sumRow">
				<td class="resCol" colspan="3">
					122193
				</td>
			</tr>
		</tbody>
	</table>
	<div class="buttonBar contentBoxBody">
		<!-- ngIf: error -->


		<div ng-show="freeResources == 0" class="merchantBtn">
			<button class="premium animate" premium-feature="NPCTrader" confirm-gold-usage="true" ng-class="{disabled: totalDiff == 0 || npcTradingAvailable == 'notAvailable' || player.isOnVacation()}" tooltip="" tooltip-translate="Building.npcTrader.Tooltip.WWVillage" tooltip-hide="true"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Поменять</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage --><span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>5</span><!-- end ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		</div>
		<div ng-hide="freeResources == 0" class="merchantBtn ng-hide">
			<button clickable="distribute()" ng-class="{disabled: totalDiff == 0}" class="clickable"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Распределить оставшиеся ресурсы</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		</div>
	</div>
</div></div>
</div>
			</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Тайник | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
		
		tooltips: {
			1: `<div class="upgradeCostsTooltip" ng-controller="buildingUpgradeTooltipCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoTop"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' &amp;&amp; currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
	<div class="headline">
		<h3 translate="" class="building" options="34"><span>Каменотес</span></h3>
	</div>

	<div class="queuedSlidesContainer">
		<!-- ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide1 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide2 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide3 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide4 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide5 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 -->
	</div>

	<div class="content" ng-class="{boxMaxLevel: building.data.lvl >= building.data.lvlMax, boxDeconstruction: upgradeButtonClass == 'demolish'}">
		<div class="contentTopArea">
			<!-- ngIf: building.data.currentEffect.values[0] --><div class="effectDescription" ng-if="building.data.currentEffect.values[0]">
				<span translate="" options="34"><span>Повышает прочность</span></span>
				<!-- ngIf: building.data.currentEffect.values[0] --><div class="horizontalLine3d" ng-if="building.data.currentEffect.values[0]"></div><!-- end ngIf: building.data.currentEffect.values[0] -->
			</div><!-- end ngIf: building.data.currentEffect.values[0] -->
			<!-- ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' --><table class="buildingNextEffects" ng-if="building.data.lvl < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'">
				<tbody>
					<tr>
						<td>
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', enoughRes: enoughRes &amp;&amp; freeMasterSlots > 0}">
										<div class="labelLayer">
											<span class="buildingLevel">10</span>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td class="borderLeft">
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>&lrm;&#x202D;16
							</span>
						</td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect &amp;&amp; building.data.effect.length > 0 &amp;&amp; !isArray(building.data.effect[0])">
								<span translate="" options="34" data="value:200"><span>&#x202D;&#x202D;200&#x202C;%&#x202C;</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl - (currentBuildingLevel < building.data.lvlMax ? 0 : 1)] track by $index -->
						</td>
						<td class="emptySpace"></td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect &amp;&amp; building.data.effect.length > 0 &amp;&amp; !isArray(building.data.effect[0])">
								<span translate="" options="34" data="value:210"><span>&#x202D;&#x202D;210&#x202C;%&#x202C;</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl + (currentBuildingLevel < building.data.lvlMax ? 1 : 0)] track by $index -->
						</td>
						<td>
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>18
							</span>
						</td>
						<td class="borderLeft">
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
										<div class="labelLayer">
											<span class="buildingLevel">11</span>
											<!-- ngIf: currentBuildingLevel + 1 >= building.data.lvlMax -->
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
			<!-- ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
		</div>

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><span ng-if="currentBuildingLevel < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'" class="resource timeValue" tooltip="" tooltip-translate="Duration">
				<i class="symbol_clock_small_flat_black duration"></i>02:40:00
			</span><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div ng-if="currentBuildingLevel < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'" class="dropOutBox"></div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div class="resources" ng-if="currentBuildingLevel < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'">
			<display-resources available="storage" resources="building.data.nextUpgradeCosts[currentBuildingLevel]" image-size="medium"><div class="costs max4digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_medium_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;1830&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_medium_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;1535&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_medium_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;1475&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_medium_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;825&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 --><div class="enoughResTime" ng-if="enoughResSecs > 0" translate="" data="time: 1723561868"><span>Достаточно ресурсов: </span><span i18ndt="1723561868" format="short">Сегодня 20:11</span></div><!-- end ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_medium_flat_black"></i></div>
</div></display-resources>
		</div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
	</div>
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoBottom"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' &amp;&amp; currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
</div>`,
			2: `<div class="finishNowTooltip" ng-controller="finishNowCtrl">
	<!-- ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) --><div ng-if="!isWorldWonder &amp;&amp; (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0)">
		<h3>
			<span translate=""><span>Моментально завершить следующие поручения</span></span><!-- ngIf: price == 0 -->
			<!-- ngIf: price > 0 || forceGoldUsage --><span class="price" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>2:</span><!-- end ngIf: price > 0 || forceGoldUsage -->
			<!-- ngIf: price == -1 && !forceGoldUsage -->
		</h3>
		<div class="horizontalLine"></div>

		<!-- ngRepeat: build in inBuilding track by $index --><div ng-repeat="build in inBuilding track by $index">
			<!-- ngIf: !finishNowQueueType -->
			<span translate="" options="22"><span>Академия</span></span>
			<span class="level" translate="" data="lvl:20"><span>Уровень 20</span></span>
		</div><!-- end ngRepeat: build in inBuilding track by $index -->
		<!-- ngRepeat: destruction in inDestruction track by $index -->
		<!-- ngRepeat: research in inResearch track by $index -->
		<!-- ngRepeat: training in inTraining track by $index -->
	</div><!-- end ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) -->
	<!-- ngIf: isWorldWonder -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && !config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && !hasInvalidBuilding && inBuilding.length == 0 && inTraining.length == 0 && inDestruction.length == 0 && inResearch.length == 0 -->
	<!-- ngIf: !isWorldWonder && premiumOptionMenu.options.instantCompletion && !freeSlots && enoughRes === false -->
</div>`,
			3: `<span translate="" options="Building.npcTrader.Tooltip.Possible"><strong>Обменять ресурсы автоматически&nbsp;<i class="icon unit_gold_small_illu"></i> 5</strong></span>`,
			4: `<span>Распределить ресурсы вручную</span>`,
		},
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {},
}
</script>
<template>
	<div class="settings">
		<div>
			<nav class="tabulation subtab unselectable">
				<a class="tab clickable" @click="onToggleTab('gameplay')" :class="{active: tab == 'gameplay', inactive: tab != 'gameplay'}">
					<div class="content">
						<span>Игра</span>
					</div>
				</a>
				<a class="tab clickable" @click="onToggleTab('fx')" :class="{active: tab == 'fx', inactive: tab != 'fx'}">
					<div class="content">
						<span>Звук и графика</span>
					</div>
				</a>
				<a class="tab clickable" @click="onToggleTab('avatar')" :class="{active: tab == 'avatar', inactive: tab != 'avatar'}">
					<div class="content">
						<span>Персонаж</span>
					</div>
				</a>
			</nav>
			<div class="tabulationContent scrollable tabContentGameplay">
				<div class="scrollContentOuterWrapper active" style="width: 610px;">
					<div class="scrollContent" dynamic-height="true" style="width: 630px; overflow-y: auto; max-height: 237px;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: 610px;">
							<div ng-transclude="">
								<div class="loadedTab currentTab" v-if="tab == 'gameplay'">
									<gameplay />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'fx'">
									<fx />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'avatar'">
									<avatar />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="buttonFooter marginToScrollbar">
			<button clickable="saveSettings()" ng-class="{disabled: !somethingChanged()}" class="animate clickable disabled">
				<div class="content">
					<span ng-transclude="" class="innerText">
						<span translate=""><span>Сохранить</span></span>
					</span>
					<div class="animationOverlay"></div>
					<i class="checkmark action_check_small_flat_white"></i>
					<i class="checkmark action_check_small_flat_black"></i>
					<i class="checkmark action_check_small_flat_red"></i>
				</div>
			</button>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import Gameplay from '@/components/pages/profile/settings/Gameplay';
import Fx from '@/components/pages/profile/settings/Fx';
import Avatar from '@/components/pages/profile/settings/Avatar';

export default {
	mixins: lib.mixins,
	data: () => ({
		tab: 'gameplay',
	}),
	computed: {},
	methods: {
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_10.ogg');
				audio.play();
			}
		},
	},
	components: {
		Gameplay,
		Fx,
		Avatar,
	},
}
</script>
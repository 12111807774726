<template>
	<div class="contentBox viewContent" :class="{'stepContent': maxSteps > 1}">
        <div class="topicImage" :class="['help_image_'+getElement().icon+'_'+gameModel.help.selectedPage, 'help_image_'+getElement().icon+'_'+gameModel.help.selectedPage+'_gaul']"></div>
		<div class="contentBox transparent">
			<div class="contentBoxHeader headerWithArrowEndings viewContentText">
				<div class="content">
					<span>{{getElement().name}}</span>
				</div>
			</div>
			<div class="contentBoxBody scrollable" scrollable="">
				<div class="scrollContentOuterWrapper" style="width: 494px;">
					<div class="scrollContent" dynamic-height="false" style="width: 494px; overflow-y: hidden;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
							<span v-html="getElement().data[gameModel.help.selectedPage].text"></span>
							<div v-if="getElement().knowledgeBaseLink" class="externalHelpLinkContainer">
								<a :href="getElement().knowledgeBaseLink" target="_blank" translate=""><span>Справка по игре</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--<slot></slot>-->
		<div class="stepsContainer" v-if="maxSteps > 1">
			<div class="arrowFrom unselectable clickable" @click="onPrevStep();" @mouseleave="onMouseLeave('prev')" @mouseover="onMouseOver('prev')">
				<i :class="{'symbol_arrowFrom_small_flat_black': !prevHover, 'symbol_arrowFrom_small_flat_green': prevHover}"></i>
			</div>
			<div class="stepButtonsContainer">
				<a class="stepButton clickable" v-for="step in maxSteps" @click="onChangeStep(step)" :class="{'last': step == maxSteps, 'active': step == gameModel.help.selectedPage}">
					{{step}}
				</a>
			</div>
			<div class="arrowTo unselectable clickable" @click="onNextStep();" @mouseleave="onMouseLeave('next')" @mouseover="onMouseOver('next')">
				<i :class="{'symbol_arrowTo_small_flat_black': !nextHover, 'symbol_arrowTo_small_flat_green': nextHover}"></i>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		/*randomized: {
			type: Boolean,
			default: false,
		},*/
	},
	data: () => ({
		prevHover: false,
		nextHover: false,
	}),
	computed: {
		prevStep(){
			let step = this.gameModel.help.selectedPage;
			step--;
			if(step < 1) step = this.maxSteps;
			return step;
		},
		nextStep(){
			let step = this.gameModel.help.selectedPage;
			step++;
			if(step > this.maxSteps) step = 1;
			return step;
		},
		maxSteps(){
			if(this.gameModel.help.selectedSection && this.gameModel.help.selectedGroup && this.gameModel.help.selectedElement){
				return Object.entries(this.gameModel.help.getElement(this.gameModel.help.selectedSection, this.gameModel.help.selectedGroup, this.gameModel.help.selectedElement).content).length;
			}
			return 1;
		},
	},
	methods: {
		onMouseLeave(npc = ''){
			if(npc == 'prev'){
				this.prevHover = false;
			} else if(npc == 'next'){
				this.nextHover = false;
			}
		},
		onMouseOver(npc = ''){
			if(npc == 'prev'){
				this.prevHover = true;
			} else if(npc == 'next'){
				this.nextHover = true;
			}
		},
		
		getElement(){
			//if(!this.randomized){
				return this.gameModel.help.getElement(this.gameModel.help.selectedSection, this.gameModel.help.selectedGroup, this.gameModel.help.selectedElement);
			//}
			//return this.gameModel.help.getElement(this.gameModel.help.randSection, this.gameModel.help.randGroup, this.gameModel.help.randElement);
		},
		
		onChangeStep(step){
			this.gameModel.help.selectedPage = step;
		},
		onNextStep(){
			this.gameModel.help.selectedPage = this.nextStep;
		},
		onPrevStep(){
			this.gameModel.help.selectedPage = this.prevStep;
		},
	},
	components: {},
}
</script>
import axios from 'axios';
import lib from '@/lib';

export default class QuestsModel
{
	static STATUS_ACTIVATABLE = 'activatable';	// не просмотренный квест
	static STATUS_PROCESSING = 'processing';	// квест в процессе выполнения
	static STATUS_DONE = 'done';				// завершенный квест
	
	static REWARD_XP = 'xp';
	static REWARD_RESOURCES = 'resources';
	static REWARD_TROOPS = 'troops';
	static REWARD_SILVER = 'silver';
	static REWARD_ITEM = 'item';
	static REWARD_ONLY_TEXT = 'only-text';
	static REWARD_GOLD = 'gold';
	static REWARD_CULTURE_POINTS = 'culture-points';
	static REWARD_TREASURES = 'treasures';
	static REWARD_ADVENTURES_POINTS = 'adventures-points';
	static REWARD_BUILDING_LEVEL = 'building-level';
	static REWARD_PLUS_ACCOUNT = 'plus-account';
	static REWARD_RESOURCE_BONUS = 'resource-bonus';
	static REWARD_CROP_BONUS = 'crop-bonus';
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.showExchangeQuestButton = true;
		this.dailyQuestsExchanged = 0;
		this.maxDailyQuestsExchanged = 10;
		
		this.openQuests = [
			{
				id: 1,
				name: 'test',
				description: 'test',
				class: 'gaul governor quest503',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 2,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_TROOPS,
						value: [
							{
								type: 3,
								name: 'Разведчик',
								amount: 25,
							},
						],
					},
				],
				tooltip: 'Осталось чего-то: ',
			},
			{
				id: 502,
				name: '3000 воинов',
				description: 'Обучи дополнительные войска. Ты можешь обучать войска в казарме, конюшне или мастерской.',
				class: 'gaul governor quest502',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 3000,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [23333, 30000, 13333, 0],
					},
				],
				tooltip: 'Осталось обучить: ',
			},
			{
				id: 503,
				name: '500 воинов',
				description: 'Обучи дополнительные войска. Ты можешь обучать войска в казарме, конюшне или мастерской.',
				class: 'gaul governor quest503',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 500,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [10000, 17000, 6000, 0],
					},
				],
				tooltip: 'Осталось обучить: ',
			},
			{
				id: 5031,
				name: '3 поселенца',
				description: '...',
				class: 'gaul governor quest5031',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 3,
				rewards: [
					/*{
						type: QuestsModel.REWARD_RESOURCES,
						value: [10000, 17000, 6000, 0],
					},*/
				],
				tooltip: 'Осталось обучить: ',
			},
			// 504
			{
				id: 505,
				name: '25 воинов',
				description: 'Обучи дополнительные войска. Ты можешь обучать войска в казарме, конюшне или мастерской.',
				class: 'gaul governor quest505',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 25,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [1050, 1300, 600, 0],
					},
				],
				tooltip: 'Осталось обучить: ',
			},
			{
				id: 506,
				name: '100 воинов',
				description: 'Обучи дополнительные войска. Ты можешь обучать войска в казарме, конюшне или мастерской.',
				class: 'gaul governor quest506',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 100,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [4000, 5000, 2000, 0],
					},
				],
				tooltip: 'Осталось обучить: ',
			},
			{
				id: 507,
				name: '250 воинов',
				description: 'Обучи дополнительные войска. Ты можешь обучать войска в казарме, конюшне или мастерской.',
				class: 'gaul governor quest507',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 250,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [8000, 12000, 4000, 0],
					},
				],
				tooltip: 'Осталось обучить: ',
			},
			{
				id: 508,
				name: '1000 воинов',
				description: 'Обучи дополнительные войска. Ты можешь обучать войска в казарме, конюшне или мастерской.',
				class: 'gaul governor quest508',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1000,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [22500, 26000, 11250, 0],
					},
				],
				tooltip: 'Осталось обучить: ',
			},
			// 509
			{
				id: 510,
				name: 'Кузница 3',
				description: 'Построй и улучши кузницу до 3 уровня. Кузница позволяет улучшать войска деревни, в которой она построена.',
				class: 'gaul governor quest512',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_DONE,
				currentValue: 3,
				maxValue: 3,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [500, 700, 1000, 300],
					},
				],
				tooltip: 'Осталось построить уровней: ',
			},
			// 511
			// 512
			{
				id: 513,
				name: 'Следопыт',
				description: 'Исследуй следопытов. Следопыты позволят тебе узнать информацию о войсках и ресурсах во вражеских деревнях.',
				class: 'gaul governor quest513',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [700, 500, 300, 0],
					},
				],
				tooltip: '',
			},
			// 514
			{
				id: 515,
				name: '5 следопытов',
				description: 'Обучи 5 следопытов',
				class: 'gaul governor quest513',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [450, 200, 75, 0],
					},
				],
				tooltip: 'Осталось обучить: ',
			},
			{
				id: 516,
				name: 'Проведи разведку',
				description: 'Отправь разведку на деревню или оазис. Разведка покажет, сколько войск противника и ресурсов там укрыто.',
				class: 'gaul governor quest516',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 100,
					},
				],
				tooltip: '',
			},
			// 517
			// 518
			{
				id: 519,
				name: 'Продать ценность',
				description: 'Чтобы продать краденые ценности, сначала нажми на изображение героя в верхней правой части экрана. Отсюда ты можешь продать краденые ценности, нажав на них и выбрав "Продать". Продажа краденых ценностей увеличит количество сокровищ у твоего короля.',
				class: 'gaul governor quest519',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_PROCESSING,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 35,
					},
				],
				tooltip: '',
			},
			{
				id: 520,
				name: '10 краденых ценностей',
				description: 'Чтобы продать краденые ценности, сначала нажми на изображение героя в верхней правой части экрана. Отсюда ты можешь продать краденые ценности, нажав на них и выбрав "Продать". Продажа краденых ценностей увеличит количество сокровищ у твоего короля.',
				class: 'gaul governor quest520',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_PROCESSING,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 100,
					},
				],
				tooltip: 'Осталось продать краденых ценностей: ',
			},
			{
				id: 521,
				name: '50 краденых ценностей',
				description: 'Чтобы продать краденые ценности, сначала нажми на изображение героя в верхней правой части экрана. Отсюда ты можешь продать краденые ценности, нажав на них и выбрав "Продать". Продажа краденых ценностей увеличит количество сокровищ у твоего короля.',
				class: 'gaul governor quest521',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_PROCESSING,
				currentValue: 0,
				maxValue: 50,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 200,
					},
				],
				tooltip: 'Осталось продать краденых ценностей: ',
			},
			{
				id: 522,
				name: '100 краденых ценностей',
				description: 'Чтобы продать краденые ценности, сначала нажми на изображение героя в верхней правой части экрана. Отсюда ты можешь продать краденые ценности, нажав на них и выбрав "Продать". Продажа краденых ценностей увеличит количество сокровищ у твоего короля.',
				class: 'gaul governor quest522',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_PROCESSING,
				currentValue: 0,
				maxValue: 100,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 300,
					},
				],
				tooltip: 'Осталось продать краденых ценностей: ',
			},
			{
				id: 523,
				name: '300 краденых ценностей',
				description: 'Чтобы продать краденые ценности, сначала нажми на изображение героя в верхней правой части экрана. Отсюда ты можешь продать краденые ценности, нажав на них и выбрав "Продать". Продажа краденых ценностей увеличит количество сокровищ у твоего короля.',
				class: 'gaul governor quest523',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_PROCESSING,
				currentValue: 0,
				maxValue: 300,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 400,
					},
				],
				tooltip: 'Осталось продать краденых ценностей: ',
			},
			// 524
			// 525
			{
				id: 526,
				name: 'Тайник 5',
				description: 'Ты был атакован. Улучши тайник, чтобы защитить свои ресурсы от жадных соседей.',
				class: 'gaul governor quest526',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [150, 200, 115, 50],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			// 527
			// 528
			// 529
			// 530
			// 531
			// 532
			{
				id: 533,
				name: 'Посольство',
				description: 'Построй посольство. Посольство позволяет тебе закреплять оазисы за своей деревней для получения бонуса ресурсов, а также основывать королевства или присоединяться к существующим.',
				class: 'gaul governor quest533',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [150, 100, 120, 60],
					},
				],
				tooltip: '',
			},
			// 534
			// 535
			{
				id: 536,
				name: 'Академия',
				description: 'Построй академию. Академия позволяет тебе изучать новые типы войск.',
				class: 'gaul governor quest536',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_ITEM,
						value: {
							icon: 'heroItem_finishImmediately_large_illu male',
							name: 'Завершить сейчас',
						},
					},
				],
				tooltip: '',
			},
			{
				id: 537,
				name: '3 краденые ценности',
				description: 'Чтобы продать краденые ценности, сначала нажми на изображение героя в верхней правой части экрана. Отсюда ты можешь продать краденые ценности, нажав на них и выбрав "Продать". Продажа краденых ценностей увеличит количество сокровищ у твоего короля.',
				class: 'gaul governor quest537',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_PROCESSING,
				currentValue: 0,
				maxValue: 3,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 50,
					},
				],
				tooltip: 'Осталось продать краденых ценностей: ',
			},
			// 538
			{
				id: 539,
				name: 'Мастерская',
				description: 'Построй мастерскую. Мастерская позволяет тебе создавать тараны и катапульты.',
				class: 'gaul governor quest539',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [200, 2000, 200, 0],
					},
				],
				tooltip: '',
			},
			{
				id: 540,
				name: '10 осадных орудий',
				description: 'В мастерской ты можешь строить осадные орудия. Тараны разрушают стены, а катапульты – иные здания, если ты построил достаточное количество катапульт. Построй 10 осадных орудий.',
				class: 'gaul governor quest540',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [5000, 1000, 1000, 1000],
					},
				],
				tooltip: 'Осталось построить: 5',
			},
			// 541
			// 542
			// 543
			// 544
			// 545
			// 546
			// 547
			// 548
			// 549
			// 550
			// 551
			// 552
			// 553
			// 554
			// 555
			// 556
			// 557
			// 558
			// 559
			// 560
			{
				id: 561,
				name: 'NPC торговец',
				description: 'Если тебе нужно, чтобы имеющиеся ресурсы были перераспределены другим образом, ты можешь воспользоваться NPC торговцем на соответствующей вкладке на рынке.',
				class: 'gaul governor quest561',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [125, 150, 70, 100],
					},
				],
				tooltip: '',
			},
			// 562
			// 563
			// 564
			// 565
			// 566
			// 567
			// 568
			// 569
			// 570
			// 571
			// 572
			// 573
			// 574
			// 575
			// 576
			// 577
			// 578
			// 579
			// 580
			// 581
			// 582
			// 583
			// 584
			// 585
			// 586
			// 587
			// 588
			// 589
			// 590
			// 591
			// 592
			// 593
			// 594
			// 595
			// 596
			// 597
			// 598
			// 599
			// 600
			{
				id: 601,
				name: 'Лесопилки 1',
				description: 'Улучши все лесопилки до 1-го уровня.',
				class: 'gaul governor quest601',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [100, 250, 120, 150],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 602,
				name: 'Глиняные карьеры 1',
				description: 'Улучши все глиняные карьеры до 1-го уровня.',
				class: 'gaul governor quest602',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [150, 350, 50, 100],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 603,
				name: 'Железные рудники 1',
				description: 'Улучши все железные рудники до 1-го уровня.',
				class: 'gaul governor quest603',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [260, 210, 60, 150],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 604,
				name: 'По одному на 2',
				description: 'Улучши одну лесопилку, глиняный карьер и железный рудник до уровня 2.',
				class: 'gaul governor quest604',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 2,
				maxValue: 2,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [350, 350, 300, 300],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 6042,
				name: 'По одному на 1',
				description: 'Улучши одну лесопилку, глиняный карьер и железный рудник до уровня 1.',
				class: 'gaul governor quest604 2',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 3,
				maxValue: 3,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [150, 150, 100, 100],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 605,
				name: 'Все ресурсы до 2',
				description: 'Улучши все лесопилки, глиняные карьеры и железные рудники до уровня 2.',
				class: 'gaul governor quest605',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 4,
				maxValue: 6,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [975, 1000, 1050, 750],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 6052,
				name: 'Все ресурсы до 3',
				description: 'Улучши все лесопилки, глиняные карьеры и железные рудники до уровня 3.',
				class: 'gaul governor quest605',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 6,
				maxValue: 9,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [1200, 1100, 1000, 950],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 606,
				name: 'По одному на 5',
				description: 'Улучши одну лесопилку, глиняный карьер и железный рудник до уровня 5.',
				class: 'gaul governor quest606',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 15,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [2400, 2400, 2400, 2400],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 607,
				name: 'Все ресурсы на 5',
				description: 'Улучши все лесопилки, глиняные карьеры и железные рудники до уровня 5.',
				class: 'gaul governor quest607',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 15,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [5000, 5000, 4000, 4000],
					},
				],
				tooltip: 'Осталось возвести уровней полей: ',
			},
			// 608
			{
				id: 609,
				name: 'Все ресурсы на 7',
				description: 'Улучши все лесопилки, глиняные карьеры и железные рудники до уровня 7.',
				class: 'gaul governor quest609',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 21,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [11500, 12000, 8000, 7000],
					},
				],
				tooltip: 'Осталось возвести уровней полей: ',
			},
			{
				id: 610,
				name: 'Лесопилка 10',
				description: 'Улучши одну лесопилку до 10 уровня.',
				class: 'gaul governor quest610',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [2500, 6000, 3500, 4000],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 611,
				name: 'Глиняный карьер 10',
				description: 'Улучши один глиняный карьер до 10 уровня.',
				class: 'gaul governor quest611',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [5000, 2500, 5000, 3333],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 612,
				name: 'Железный рудник 10',
				description: 'Улучши один железный рудник до 10 уровня.',
				class: 'gaul governor quest612',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [6000, 5000, 1500, 3500],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 613,
				name: 'Все ресурсы на 3',
				description: 'Улучши все лесопилки, глиняные карьеры и железные рудники до уровня 3.',
				class: 'gaul governor quest613',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 9,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [1200, 1200, 1000, 750],
					},
				],
				tooltip: 'Осталось возвести уровней полей: ',
			},
			// 614
			// 615
			// 616
			// 617
			// 618
			// 619
			// 620
			{
				id: 621,
				name: 'Фермы 1',
				description: 'Тебе требуется зерно, чтобы содержать здания и войска. Улучши все фермы до 1 уровня.',
				class: 'gaul governor quest621',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [260, 350, 260, 70],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 622,
				name: 'Все фермы 2',
				description: 'Улучши все фермы до 2 уровня.',
				class: 'gaul governor quest622',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_DONE,
				currentValue: 2,
				maxValue: 2,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [1000, 1400, 1000, 350],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 623,
				name: 'Ферма 5',
				description: 'Улучши одну из ферм до 5 уровня.',
				class: 'gaul governor quest623',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [625, 800, 600, 200],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 624,
				name: 'Мельница',
				description: 'Построй мукомольную мельницу. Мельница даст тебе бонус к производству зерна.',
				class: 'gaul governor quest624',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [0, 0, 0, 1400],
					},
				],
				tooltip: '',
			},
			// 625
			{
				id: 626,
				name: 'Ферма 10',
				description: 'Максимальный уровень полей в деревне - 10, в городе - 12. В столице можно строить фермы любого уровня. Улучши одну ферму до 10 уровня.',
				class: 'gaul governor quest626',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [4500, 5500, 4500, 1000],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 627,
				name: 'Пекарня',
				description: 'Построй пекарню. Пекарня дает бонус к производству ферм.',
				class: 'gaul governor quest627',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [0, 0, 0, 7000],
					},
				],
				tooltip: '',
			},
			{
				id: 628,
				name: 'Склад 3',
				description: 'Улучши склад до 3 уровня, чтобы хранить больше древесины, глины и железа в этой деревне.',
				class: 'gaul governor quest628',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 3,
				maxValue: 3,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [500, 600, 360, 0],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 629,
				name: 'Все фермы 5',
				description: 'Улучши все фермы до 5 уровня.',
				class: 'gaul governor quest629',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [3000, 4000, 3000, 1000],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 630,
				name: 'Все фермы 7',
				description: 'Улучши все фермы до 7 уровня.',
				class: 'gaul governor quest630',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 7,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [5000, 6000, 5000, 1500],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 631,
				name: 'Оазис',
				description: 'Закрепи оазис королевства за одной из своих деревень. Для этого ты должен войти в пятерку самых влиятельных игроков. Размещение войск в оазисе укрепит твое влияние.',
				class: 'gaul governor quest631',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [400, 350, 250, 150],
					},
				],
				tooltip: '',
			},
			{
				id: 632,
				name: 'Кирпичный завод',
				description: 'Построй кирпичный завод. Кирпичный завод дает бонус к производству глины.',
				class: 'gaul governor quest632',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [0, 1000, 0, 0],
					},
				],
				tooltip: '',
			},
			{
				id: 633,
				name: 'Пилорама',
				description: 'Построй пилораму. Пилорама дает бонус к производству древесины.',
				class: 'gaul governor quest633',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [1000, 0, 0, 0],
					},
				],
				tooltip: '',
			},
			{
				id: 634,
				name: 'Литейный завод',
				description: 'Построй литейный завод. Литейный завод дает бонус к производству всех железных рудников.',
				class: 'gaul governor quest634',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [0, 0, 1000, 0],
					},
				],
				tooltip: '',
			},
			{
				id: 635,
				name: 'Все ресурсы на 10',
				description: 'Улучши все лесопилки, глиняные карьеры и железные рудники до уровня 10.',
				class: 'gaul governor quest635',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 30,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [30000, 30000, 20000, 25000],
					},
				],
				tooltip: 'Осталось возвести уровней полей: ',
			},
			{
				id: 636,
				name: 'Все фермы 10',
				description: 'Улучши все фермы до 10 уровня.',
				class: 'gaul governor quest636',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [25000, 30000, 25000, 7000],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 637,
				name: 'Все ресурсы на 12',
				description: 'Улучши все лесопилки, глиняные карьеры и железные рудники до уровня 12.',
				class: 'gaul governor quest637',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 36,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [66000, 66000, 33000, 44000],
					},
				],
				tooltip: 'Осталось возвести уровней полей: ',
			},
			{
				id: 638,
				name: 'Все фермы 12',
				description: 'Улучши все фермы до 12 уровня.',
				class: 'gaul governor quest638',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 12,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [33000, 35000, 30000, 10000],
					},
				],
				tooltip: 'Осталось уровней ферм: ',
			},
			{
				id: 639,
				name: 'Ресурсы 10-в-5',
				description: 'Улучши все лесопилки, глиняные карьеры и железные рудники до уровня 10 в 5 деревнях.',
				class: 'gaul governor quest639',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 258,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [100000, 100000, 100000, 0],
					},
				],
				tooltip: 'Осталось возвести уровней полей: ',
			},
			{
				id: 640,
				name: 'Фермы 10-в-5',
				description: 'Улучши все фермы до уровня 10 в 5 деревнях.',
				class: 'gaul governor quest640',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 122,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [0, 0, 0, 100000],
					},
				],
				tooltip: 'Осталось уровней ферм: ',
			},
			// 641
			// 642
			{
				id: 643,
				name: 'Все фермы 3',
				description: 'Улучши все фермы до 3 уровня.',
				class: 'gaul governor quest643',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 3,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [1200, 1500, 1200, 500],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 644,
				name: 'Склад 10',
				description: 'Улучши склад до 10 уровня, чтобы хранить больше древесины, глины и железа в этой деревне.',
				class: 'gaul governor quest644',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_BUILDING_LEVEL,
						value: {
							buildingType: 10,
							tribeId: 3,
							name: 'Склад',
							level: 12,
						},
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			// 645
			// 646
			// 647
			// 648
			// 649
			// 650
			// 651
			// 652
			// 653
			// 654
			// 655
			// 656
			// 657
			// 658
			// 659
			{
				id: 660,
				name: 'Разобрать руины',
				description: 'Разбери руины разрушенного здания в своей деревне. Нужно учесть, что время разбора руин может быть разным.',
				class: 'gaul governor quest660',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [50, 50, 100, 50],
					},
				],
				tooltip: '',
			},
			// 661
			// 662
			// 663
			// 664
			// 665
			// 666
			// 667
			// 668
			// 669
			// 670
			// 671
			// 672
			// 673
			// 674
			// 675
			// 676
			// 677
			// 678
			// 679
			// 680
			// 681
			// 682
			// 683
			// 684
			// 685
			// 686
			// 687
			// 688
			// 689
			// 690
			// 691
			// 692
			// 693
			// 694
			// 695
			// 696
			// 697
			// 698
			// 699
			// 700
			{
				id: 701,
				name: 'Построить рынок',
				description: 'Построй рынок. Он позволяет тебе торговать с другими игроками.',
				class: 'gaul governor quest701',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [50, 50, 100, 50],
					},
				],
				tooltip: '',
			},
			{
				id: 702,
				name: 'Склад',
				description: 'Построй склад. Склад позволяет хранить больше древесины, глины и железа в данной деревне.',
				class: 'gaul governor quest702',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [300, 300, 300, 0],
					},
				],
				tooltip: '',
			},
			{
				id: 703,
				name: 'Амбар',
				description: 'Построй амбар. Амбар позволяет хранить больше зерна в данной деревне.',
				class: 'gaul governor quest703',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [0, 0, 0, 500],
					},
				],
				tooltip: '',
			},
			// 704
			{
				id: 705,
				name: 'Амбар 10',
				description: 'Улучши амбар до 10 уровня. Это позволит тебе хранить больше зерна в деревне.',
				class: 'gaul governor quest705',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 10,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [500, 550, 400, 0],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			// 706
			// 707
			// 708
			// 709
			// 710
			// 711
			{
				id: 712,
				name: 'Резиденция',
				description: 'Построй резиденцию. Резиденция необходима для того, чтобы ты мог основывать новые деревни. Как вариант, ты можешь построить дворец, но на его строительство требуется гораздо больше ресурсов, поэтому неплохо будет отложить его строительство до более поздних времен и возвести дворец в другой деревне.',
				class: 'gaul governor quest712',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [5000, 3500, 3500, 4000],
					},
				],
				tooltip: '',
			},
			{
				id: 713,
				name: 'Рынок 5',
				description: 'Улучши рынок до 5 уровня. Рынок позволяет отправить ресурсы из твоей первой деревни во вторую. Это поможет быстрее отстроить вторую деревню.',
				class: 'gaul governor quest713',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [200, 200, 333, 150],
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 714,
				name: 'Резиденция 5',
				description: 'Улучши свою резиденцию (или дворец) до 5 уровня. За это ты получишь награду: здание будет немедленно повышено до 10 уровня и ты сможешь сразу приступить к обучению поселенцев.',
				class: 'gaul governor quest714',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_BUILDING_LEVEL,
						value: {
							buildingType: 25,
							tribeId: 3,
							name: 'Резиденция',
							level: 10,
						},
					},
				],
				tooltip: 'Осталось улучшить уровней: ',
			},
			{
				id: 715,
				name: 'Торговля ресурсами',
				description: 'Выстави предложение на рынке, перейдя на вкладку "Продажа".',
				class: 'gaul governor quest715',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_ITEM,
						value: {
							icon: 'heroItem_npcTrader_large_illu male',
							name: 'NPC торговец',
						},
					},
				],
				tooltip: '',
			},
			{
				id: 716,
				name: 'Отправка ресурсов',
				description: 'Отправь ресурсы в свою новую деревню. Перейди в свою первую деревню и выбери вторую в качестве пункта назначения на рынке. Как вариант, нажми на новую деревню на карте и выбери "Отправить торговцев".',
				class: 'gaul governor quest716',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_ITEM,
						value: {
							icon: 'heroItem_instantDelivery_large_illu male male',
							name: 'Моментальная доставка',
						},
					},
				],
				tooltip: '',
			},
			{
				id: 717,
				name: 'Забрать сейчас',
				description: 'Используй функцию моментальной доставки, чтобы ресурсы немедленно прибыли во вторую деревню. Не забывай, что торговцы возвращаются домой с нормальной скоростью.',
				class: 'gaul governor quest717',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [700, 1000, 1300, 300],
					},
				],
				tooltip: '',
			},
			// 718
			// 719
			// 720
			// 721
			{
				id: 722,
				name: 'Новая деревня',
				description: 'Построй новую деревню. Если тебе нравится королевство, выбери место для основания деревни внутри него. Если твоя деревня будет в другом королевстве, правящий король тех мест может прислать тебе приглашение.',
				class: 'gaul governor quest722',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [3500, 3500, 3500, 1000],
					},
				],
				tooltip: '',
			},
			// 723
			// 724
			// 725
			// 726
			// 727
			// 728
			// 729
			// 730
			{
				id: 731,
				name: 'Город или деревня',
				description: 'Реши, дать деревне привилегии города в главном здании или построить еще одну деревню.',
				class: 'gaul governor quest731',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [6000, 7500, 5000, 0],
					},
				],
				tooltip: '',
			},
			// 732
			{
				id: 733,
				name: 'Ратуша',
				description: 'Построй ратушу. Ратуша позволяет тебе проводить праздники и торжества, чтобы быстрее накопить единицы культуры для основания следующих деревень.',
				class: 'gaul governor quest733',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_CULTURE_POINTS,
						value: 800,
					},
				],
				tooltip: '',
			},
			{
				id: 734,
				name: '5 деревень',
				description: 'Построй или завоюй дополнительные деревни, чтобы у тебя было не менее 5 деревень или городов.',
				class: 'gaul governor quest734',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [9500, 7000, 8000, 0],
					},
				],
				tooltip: 'Осталось основать деревень: 2',
			},
			// 735
			// 736
			// 737
			// 738
			// 739
			// 740
			{
				id: 741,
				name: 'Праздник',
				description: 'Проведи праздник, чтобы получить дополнительные единицы культуры',
				class: 'gaul governor quest741',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_CULTURE_POINTS,
						value: 500,
					},
				],
				tooltip: '',
			},
			{
				id: 742,
				name: 'Торжество',
				description: 'Проведи торжество, чтобы получить дополнительные единицы культуры',
				class: 'gaul governor quest741',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [10000, 11000, 11000, 1500],
					},
					{
						type: QuestsModel.REWARD_CULTURE_POINTS,
						value: 2000,
					},
				],
				tooltip: '',
			},
			// 743
			// 744
			// 745
			// 746
			// 747
			// 748
			// 749
			// 750
			// 751
			// 752
			// 753
			// 754
			// 755
			// 756
			// 757
			// 758
			// 759
			// 760
			// 761
			// 762
			// 763
			// 764
			// 765
			// 766
			// 767
			// 768
			// 769
			// 770
			// 771
			// 772
			// 773
			// 774
			// 775
			// 776
			// 777
			// 778
			// 779
			// 780
			// 781
			// 782
			// 783
			// 784
			// 785
			// 786
			// 787
			// 788
			// 789
			// 790
			// 791
			// 792
			// 793
			// 794
			// 795
			// 796
			// 797
			// 798
			// 799
			// 800
			{
				id: 801,
				name: 'Производство героя',
				description: 'Ты можешь увеличить производство ресурсов героем, выбрав между всеми ресурсами равномерно или каким-то одним. В меню героя на вкладке параметров ты можешь решить, какой ресурс ты считаешь нужным активировать.',
				class: 'gaul governor quest801',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_ONLY_TEXT,
						value: '700 выбранного ресурса',
						
						// после выполнения этого задания награда сменилась на это при выборе дерева в атрибутах героя
						//type: QuestsModel.REWARD_RESOURCES,
						//value: [700, 0, 0, 0],
					},
				],
				tooltip: '',
			},
			// 802
			// 803
			// 804
			// 805
			// 806
			// 807
			// 808
			// 809
			// 810
			{
				id: 811,
				name: '5 приключений',
				description: 'Пройди 5 приключений и получи многочисленные награды. Ты всегда должен следить за здоровьем героя. Использование мази из инвентаря героя помогает восстановить здоровье.',
				class: 'gaul governor quest811',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 5,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 50,
					},
				],
				tooltip: 'Осталось приключений: ',
			},
			{
				id: 812,
				name: 'Продай предмет',
				description: 'Продай предмет на аукционе. Нажми на иконку с серебром справа вверху и выбери "Продать"',
				class: 'gaul governor quest812',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 500,
					},
				],
				tooltip: '',
			},
			// 813
			{
				id: 814,
				name: 'Лошадь героя',
				description: 'Поздравляем! Ты нашел лошадь в первом приключении. Нажми на изображение героя в правом верхнем углу экрана и дай герою лошадь, перетянув ее в подсвеченный слот.',
				class: 'gaul governor quest814',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [120, 150, 80, 140],
					},
				],
				tooltip: '',
			},
			{
				id: 815,
				name: 'Ставка',
				description: 'Сделай ставку на аукционе. Нажми на иконку с серебром справа вверху и сделай ставку на предмет по своему выбору.',
				class: 'gaul governor quest815',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_ADVENTURES_POINTS,
						value: 2,
					},
				],
				tooltip: '',
			},
			// 816
			// 817
			// 818
			// 819
			{
				id: 820,
				name: 'Полное снаряжение',
				description: 'Полностью экипируй героя предметами. Полное снаряжение включает шлем, доспехи, обувь, а также предмет в правую и левую руку. Ты можешь купить недостающие предметы на аукционе.',
				class: 'gaul governor quest820',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 6,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 150,
					},
				],
				tooltip: 'Осталось экипировать: ',
			},
			{
				id: 821,
				name: '10 приключений',
				description: 'Отправь героя в 10 приключений. Нажми на иконку приключений рядом с изображением героя в правом верхнем углу экрана. Рекомендуем выбрать короткое приключение, так как оно займет не так много времени.',
				class: 'gaul governor quest821',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 10,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 100,
					},
				],
				tooltip: 'Осталось приключений: ',
			},
			{
				id: 822,
				name: '20 приключений',
				description: 'Отправь героя в 20 приключений. Нажми на иконку приключений рядом с изображением героя в правом верхнем углу экрана. Рекомендуем выбрать короткое приключение, так как оно займет не так много времени.',
				class: 'gaul governor quest822',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 20,
				maxValue: 20,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 125,
					},
				],
				tooltip: 'Осталось приключений: ',
			},
			{
				id: 823,
				name: '50 приключений',
				description: 'Отправь героя в 50 приключений. Нажми на иконку приключений рядом с изображением героя в правом верхнем углу экрана. Рекомендуем выбрать короткое приключение, так как оно займет не так много времени.',
				class: 'gaul governor quest823',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 50,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 350,
					},
				],
				tooltip: 'Осталось приключений: ',
			},
			{
				id: 824,
				name: '100 приключений',
				description: 'Отправь героя в 100 приключений. Нажми на иконку приключений рядом с изображением героя в правом верхнем углу экрана. Рекомендуем выбрать короткое приключение, так как оно займет не так много времени.',
				class: 'gaul governor quest824',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 100,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 750,
					},
				],
				tooltip: 'Осталось приключений: ',
			},
			{
				id: 825,
				name: '33 приключения',
				description: 'Отправь героя в 33 приключения. Нажми на иконку приключений рядом с изображением героя в правом верхнем углу экрана. Рекомендуем выбрать короткое приключение, так как оно займет не так много времени.',
				class: 'gaul governor quest825',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 33,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 250,
					},
				],
				tooltip: 'Осталось приключений: ',
			},
			{
				id: 826,
				name: 'Приключение',
				description: 'Отправь героя в приключение. Нажми на иконку приключений рядом с изображением героя в правом верхнем углу экрана. Рекомендуем выбрать короткое приключение, так как оно займет не так много времени.',
				class: 'gaul governor quest826',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 25,
					},
				],
				tooltip: '',
			},
			{
				id: 827,
				name: '3 приключения',
				description: 'Отправь героя в 3 приключения. Нажми на иконку приключений рядом с изображением героя в правом верхнем углу экрана. Рекомендуем выбрать короткое приключение, так как оно займет не так много времени.',
				class: 'gaul governor quest827',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_DONE,
				currentValue: 3,
				maxValue: 3,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 75,
					},
				],
				tooltip: 'Осталось приключений: ',
			},
			// 828
			// 829
			// 830
			{
				id: 831,
				name: 'Герой 5 уровня',
				description: 'Накопи достаточно единиц опыта героя, проходя приключения и участвуя в битвах, чтобы достичь 5 уровня.',
				class: 'gaul governor quest831',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_ADVENTURES_POINTS,
						value: 3,
					},
				],
				tooltip: 'Осталось уровней героя: ',
			},
			{
				id: 832,
				name: 'Герой 10 уровня',
				description: 'Накопи достаточно единиц опыта героя, проходя приключения и участвуя в битвах, чтобы достичь 10 уровня.',
				class: 'gaul governor quest832',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_ADVENTURES_POINTS,
						value: 3,
					},
				],
				tooltip: 'Осталось уровней героя: ',
			},
			{
				id: 833,
				name: 'Герой 25 уровня',
				description: 'Накопи достаточно единиц опыта героя, проходя приключения и участвуя в битвах, чтобы достичь 25 уровня.',
				class: 'gaul governor quest833',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 25,
				rewards: [
					{
						type: QuestsModel.REWARD_ADVENTURES_POINTS,
						value: 4,
					},
				],
				tooltip: 'Осталось уровней героя: ',
			},
			{
				id: 834,
				name: 'Герой 50 уровня',
				description: 'Накопи достаточно единиц опыта героя, проходя приключения и участвуя в битвах, чтобы достичь 50 уровня.',
				class: 'gaul governor quest834',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 50,
				rewards: [
					{
						type: QuestsModel.REWARD_ADVENTURES_POINTS,
						value: 5,
					},
				],
				tooltip: 'Осталось уровней героя: ',
			},
			// 835
			// 836
			// 837
			// 838
			// 839
			// 840
			// 841
			// 842
			// 843
			// 844
			// 845
			// 846
			// 847
			// 848
			// 849
			{
				id: 850,
				name: 'Поймать животных',
				description: 'Поймай животных в диком оазисе, дав герою по меньшей мере одну клетку. Если в слоте предмета героя находится хотя бы одна клетка, битвы в оазисе не произойдет. Вместо этого будут захвачены животные.',
				class: 'gaul governor quest850',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [150, 200, 110, 90],
					},
				],
				tooltip: '',
			},
			// 851
			// 852
			// 853
			// 854
			// 855
			// 856
			// 857
			// 858
			// 859
			// 860
			// 861
			// 862
			// 863
			// 864
			// 865
			// 866
			// 867
			// 868
			// 869
			// 870
			// 871
			// 872
			// 873
			// 874
			// 875
			// 876
			// 877
			// 878
			// 879
			// 880
			// 881
			// 882
			// 883
			// 884
			// 885
			// 886
			// 887
			// 888
			// 889
			// 890
			// 891
			// 892
			// 893
			// 894
			// 895
			// 896
			// 897
			// 898
			// 899
			// 900
			// 901
			{
				id: 902,
				name: 'Обучить героя',
				description: 'Твой герой получил новый уровень. Вначале мы рекомендуем вкладывать очки опыта в личную силу героя и производство ресурсов.',
				class: 'gaul governor quest902',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [150, 150, 100, 75],
					},
				],
				tooltip: '',
			},
			{
				id: 903,
				name: 'Вылечить героя',
				description: 'У твоего героя мало здоровья. Тебе следует использовать мазь, чтобы повысить его.',
				class: 'gaul governor quest903',
				isAdventure: false,
				giver: '9',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 30,
					},
				],
				tooltip: '',
			},
			// 904
			{
				id: 905,
				name: 'Завершить сейчас',
				description: 'Моментально заверши строительство зданий в меню строительства.',
				class: 'gaul governor quest905',
				isAdventure: false,
				giver: '1',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_RESOURCES,
						value: [180, 130, 70, 30],
					},
				],
				tooltip: '',
			},
			{
				id: 906,
				name: 'Подкрепление',
				description: 'В Travian команда - это важный фактор. Внутри королевства ты можешь видеть атаки на других игроков. Атаки обозначены красным. Отправь другому игроку подкрепление.',
				class: 'gaul governor quest906',
				isAdventure: false,
				giver: '3',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_XP,
						value: 65,
					},
				],
				tooltip: '',
			},
			// 907
		];
		this.dailyQuests = [
			{
				id: 10000,
				name: 'Честная торговля',
				description: 'Совершите не менее 5 торговых сделок на рынке.',
				class: 'gaul governor quest10000',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 5,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 200,
					},
				],
				tooltip: 'Осталось сделок: ',
			},
			{
				id: 10001,
				name: 'Превзойти всех',
				description: 'Выиграйте аукцион.',
				class: 'gaul governor quest10001',
				status: QuestsModel.STATUS_DONE,
				currentValue: 1,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 150,
					},
				],
				tooltip: '',
			},
			{
				id: 10002,
				name: 'Секреты, секреты...',
				description: 'Разведайте другого игрока.',
				class: 'gaul governor quest10002',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 100,
					},
				],
				tooltip: '',
			},
			{
				id: 10003,
				name: 'Хитрый торговец',
				description: 'Продайте предмет.',
				class: 'gaul governor quest10003',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 100,
					},
				],
				tooltip: '',
			},
			{
				id: 10004,
				name: 'Главный архитектор',
				description: 'Улучшите или постройте 3 здания или ресурсных поля.',
				class: 'gaul governor quest10004',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 3,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 100,
					},
				],
				tooltip: 'Осталось зданий или полей: ',
			},
			{
				id: 10005,
				name: 'Славный военачальник',
				description: 'Успешно атакуйте, совершите набег или осаду не менее 2 целей.',
				//description: 'Успешно атакуйте, совершите набег или осаду не менее 3 целей.',
				class: 'gaul governor quest10005',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 2,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 150, // 2 цели
						//value: 200, // 3 цели
					},
				],
				tooltip: 'Осталось целей: ',
			},
			{
				id: 10006,
				name: 'Исследователь',
				description: 'Исследуйте не менее 3 воинов в академии или улучшите их в кузнице.',
				class: 'gaul governor quest10006',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 3,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 200,
					},
				],
				tooltip: 'Осталось исследовать: ',
			},
			{
				id: 10007,
				name: 'Время праздновать',
				description: 'Проведи праздник. Празднества можно устраивать в ратуше.',
				class: 'gaul governor quest10007',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 100,
					},
				],
				tooltip: '',
			},
			{
				id: 10008,
				name: 'Дикая охота',
				description: 'Захвати или победи 10 животных. Животных можно найти в оазисах на карте. Чтобы поймать их, тебе нужно атаковать оазис героем, экипированным клетками.',
				class: 'gaul governor quest10008',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 150,
					},
				],
				tooltip: 'Осталось животных: ',
			},
			{
				id: 10009,
				name: 'Рука помощи',
				description: 'Отправь подкрепление другому игроку и помоги ему отразить атаку. Атаки можно увидеть на карте, нажав на кнопку "Прибывающие атаки" в левом верхнем углу экрана.',
				class: 'gaul governor quest10009',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 200,
					},
				],
				tooltip: '',
			},
			{
				id: 10010,
				name: 'Великий воин',
				description: 'Уничтожь не менее 38 войска атакой, набегом или осадой.',
				//description: 'Уничтожь не менее 146 войска атакой, набегом или осадой.',
				class: 'gaul governor quest10010',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 38, //199
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 200,
					},
				],
				tooltip: 'Осталось уничтожить: ',
			},
			{
				id: 10011,
				name: 'Искусный фармер',
				description: 'Укради не менее 3934 ресурсов атакой, набегом или осадой.',
				//description: 'Укради не менее 4500 ресурсов атакой, набегом или осадой.',
				class: 'gaul governor quest10011',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 3934,
				//maxValue: 4500,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 150,
					},
				],
				tooltip: 'Осталось украсть: ',
			},
			{
				id: 10012,
				name: 'К оружию!',
				description: 'Обучи 26 воинов.',
				//description: 'Обучи 31 воинов.',
				//description: 'Обучи 38 воинов.',
				class: 'gaul governor quest10012',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 26,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 150,
					},
				],
				tooltip: 'Осталось обучить: ',
			},
			{
				id: 10013,
				name: 'Спрос и предложение',
				description: 'Обменяй с другими игроками не менее 443 ресурсов на рынке.',
				class: 'gaul governor quest10013',
				status: QuestsModel.STATUS_PROCESSING,
				currentValue: 0,
				maxValue: 443,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 100,
					},
				],
				tooltip: 'Осталось обменять ресурсов: ',
			},
			{
				id: 10014,
				name: 'Рост населения',
				description: 'Построй дополнительные уровни и увеличь свое население на 10.',
				class: 'gaul governor quest10014',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 10,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 150,
					},
				],
				tooltip: 'Осталось населения: ',
			},
			//10017
			{
				id: 10016,
				name: 'Ценные товары',
				description: 'Захвати краденые ценности в логовах разбойников на карте и продай их своему королю.',
				class: 'gaul governor quest10016',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 100,
					},
				],
				tooltip: '',
			},
			//10017
			{
				id: 10018,
				name: 'Оазис',
				description: 'Захвати оазис. Чтобы сделать это, нажми на вкладку "Оазисы" в своем посольстве.',
				class: 'gaul governor quest10018',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 100,
					},
				],
				tooltip: '',
			},
			{
				id: 10019,
				name: 'Друзья!',
				description: 'Отправь приглашение по e-mail другу в соответствующем меню премиум-функций.',
				class: 'gaul governor quest10019',
				status: QuestsModel.STATUS_ACTIVATABLE,
				currentValue: 0,
				maxValue: 1,
				rewards: [
					{
						type: QuestsModel.REWARD_SILVER,
						value: 100,
					},
				],
				tooltip: '',
			},
		];
	}
}

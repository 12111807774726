<template>
	<div id="servertime" class="unselectable" @click="onOpenSettings">
		<i class="symbol_clock_small_flat_black time"></i>
		<span class="clickable">{{localeDatetime}}</span>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
        // Дата хранится в виде числа. Не обязательно в state компонента. Но и в других местах тоже
        datetime: Date.now(),
    }),
	computed: {
		localeDatetime(){
            // Конвертируем число в строку. Для этого существуют специальные методы
            // toLocaleDateString() или toLocaleString() или toLocaleTimeString()
            // Итоговая строка будет зависеть от локализации системы пользователя. 
            // Для русской локали это будет "01.02.2020", 
            // для американской "2/1/2020", 
            // для немецкой — "1.2.2020"
            // Вы НЕ должны устанавливать формат даты самостоятельно
            return (new Date(this.datetime)).toLocaleTimeString();
        },
	},
	created(){
		// если нужно изменять дату в реальном времени вешаем таймер
		this.intervalId = setInterval(() => this.datetime = Date.now(), 1000);
	},
	beforeDestroy(){
		// если повесили таймер, то его нужно отключать
		if(this.intervalId) clearInterval(this.intervalId);
	},
	methods: {
		onOpenSettings(){
			this.$router.push({path: '/profile', query: {tab: 'settings', subtab: 'gameplay'}});
		},
	},
	components: {},
}
</script>
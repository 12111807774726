<template>
	<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="499">
		<div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
			<div class="inWindowPopupHeader">
				<h4 translate="" options="Society.FoundSociety" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Основать новое тайное сообщество</span></h4>
				<h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4>
				<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
			</div>
			<div class="inWindowPopupContent">
				<div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']">
					<div class="contentBox transparent attitude">
						<h6 class="contentBoxHeader headerColored" translate=""><span>Назначение</span></h6>
						<div class="contentBoxBody">
							<div class="white">
								<label class="clickInputLabel">
									<div class="clickInputWrapper"><input type="radio" ng-model="input.chosenAttitude" ng-value="1" clickable="processAttitudeChange();" class="ng-pristine ng-untouched ng-valid clickable" name="1021" value="1"><i class="symbol_dot_tiny_illu"></i></div>
									<span translate=""><span>Светлое сообщество</span></span>
								</label>
							</div>
							<div class="verticalLine"></div>
							<div class="dark">
								<label class="clickInputLabel">
									<div class="clickInputWrapper"><input type="radio" ng-model="input.chosenAttitude" ng-value="2" clickable="processAttitudeChange();" class="ng-pristine ng-untouched ng-valid clickable" name="1024" value="2"><i class="symbol_dot_tiny_illu"></i></div>
									<span translate=""><span>Темное сообщество</span></span>
								</label>
							</div>
						</div>
					</div>
					<div class="contentBox transparent name">
						<div class="contentBoxHeader headerColored" translate=""><span>Имя</span></div>
						<div class="contentBoxBody">
							<input maxlength="15" type="text" ng-model="input.name" placeholder="Имя тайного сообщества" class="ng-pristine ng-untouched ng-valid ng-valid-maxlength">
						</div>
					</div>
					<div class="contentBox transparent task" ng-class="{noTarget: targetDropdown.selected == 5}">
						<div class="contentBoxHeader headerColored" translate=""><span>Задание</span></div>
						<div class="contentBoxBody">
							<span content="Сначала выбери назначение" v-tippy="{placement: 'bottom'}" tooltip-translate="Society.Target.FirstChooseAttitude" tooltip-show="true">
								<div class="dropdownContainer" dropdown="" data="targetDropdown"><div class="dropdownHead openDownwards closed clickable disabled" clickable="clickDropdown()"><span class="selectedItem truncated empty">Выбери задачу</span><i class="symbol_arrowDown_tiny_illu"></i></div><input class="dropdownInput" type="text" disabled=""></div>
								<!--
								светлое сообщество
								<select style="display: block; height: 24px;">
									<option>Выбери задачу</option>
									<option>Защита деревни</option>
									<option>Защита игрока</option>
									<option>Защита королевства</option>
									<option>Защита друг друга</option>
								</select>
								темное соообщество
								<select style="display: block; height: 24px;">
									<option>Выбери задачу</option>
									<option>Война с деревней</option>
									<option>Война с игроком</option>
									<option>Война с королевством</option>
								</select>
								-->
							</span>
						</div>
					</div>
					
					<!--если задание защита друг друга то не показывать--><div class="contentBox transparent target" ng-if="targetDropdown.selected != 5">
						<div class="contentBoxHeader headerColored" translate=""><span>Цель</span></div>
						<div class="contentBoxBody">
							<span class="serverautocompleteContainer ng-valid" disabled-input="true" autocompletedata="" autocompletecb="" ng-model="input.targetName">
								<!-- ngIf: showOwnVillages -->
								<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span>
								<input ng-disabled="disabledInput" class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск..." disabled="disabled">
								<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 245px;">
									<i class="action_search_tiny_flat_white"></i>
								</label>
								<!-- ngIf: showOwnVillages -->
							</span>
						</div>
					</div>
					
					<!--если задание защита друг друга то не показывать--><div class="contentBox transparent requirement" ng-if="targetDropdown.selected != 5">
						<div class="contentBoxHeader headerColored">
							<span translate=""><span>Условие приема</span></span>
							<span class="optional">(<span translate=""><span>по желанию</span></span>)</span>
						</div>
						<div class="contentBoxBody">
							<span content="Сначала выбери назначение" v-tippy="{placement: 'bottom'}" tooltip-translate="Society.Target.FirstChooseAttitude" tooltip-show="true">
								<div class="dropdownContainer" dropdown="" data="conditionDropdown"><div class="dropdownHead openDownwards closed clickable disabled" clickable="clickDropdown()"><span class="selectedItem truncated empty">Требований нет</span><i class="symbol_arrowDown_tiny_illu"></i></div><input class="dropdownInput" type="text" disabled=""></div>
								<!--
								светлое сообщество
								<select style="display: block; height: 24px;">
									<option>Требований нет</option>
									<option>Отправлено ресурсов</option>
									<option>Потеряно войск в защите</option>
									<option>Войска сейчас</option>
								</select>
								темное соообщество
								<select style="display: block; height: 24px;">
									<option>Требований нет</option>
									<option>Украдено ресурсов</option>
									<option>Убито войск</option>
									<option>Сокровищ украдено</option>
								</select>
								-->
							</span>
						</div>
					</div>
					
					<!--если задание защита друг друга то не показывать--><div class="contentBox transparent value" ng-if="targetDropdown.selected != 5">
						<div class="contentBoxHeader headerColored" translate=""><span>Количество</span></div>
						<div class="contentBoxBody">
							<input ng-disabled="!conditionDropdown.selected" number="" maxlength="5" ng-model="input.conditionValue" placeholder="Сначала выбери требования" class="ng-pristine ng-untouched ng-valid ng-valid-maxlength" type="tel" disabled="disabled" placeholder11="Количество ресурсов" placeholder12="Количество войск" placeholder13="Количество войск" placeholder21="Количество ресурсов" placeholder22="Количество войск" placeholder23="Количество сокровищ">
						</div>
					</div>
					
					<div class="contentBox transparent sharedInformation">
						<div class="contentBoxHeader headerColored">
							<span translate=""><span>Общая информация</span></span>
							<span class="optional">(<span translate=""><span>по желанию</span></span>)</span>
						</div>
						<div class="contentBoxBody">
							<label class="clickInputLabel">
								<div class="clickInputWrapper"><input type="checkbox" ng-model="shared.reports" class="ng-pristine ng-untouched ng-valid"><i class="action_check_tiny_flat_black"></i></div>
								<span translate=""><span>Отчеты о битвах и разведке</span></span>
							</label>
							<label class="clickInputLabel">
								<div class="clickInputWrapper"><input type="checkbox" ng-model="shared.nextAttacks" class="ng-pristine ng-untouched ng-valid"><i class="action_check_tiny_flat_black"></i></div>
								<span translate=""><span>Следующая атака на каждую деревню</span></span>
							</label>
							<label class="clickInputLabel">
								<div class="clickInputWrapper"><input type="checkbox" ng-model="shared.villageDetails" class="ng-pristine ng-untouched ng-valid"><i class="action_check_tiny_flat_black"></i></div>
								<span translate=""><span>Дополнительные данные о деревнях</span></span>
								<span translate="" class="additional"><span>(производство зерна, войска)</span></span>
							</label>
						</div>
					</div>
					
					<div class="buttonFooter">
						<button clickable="foundNew();" ng-class="{disabled: !checkValid()}" content="Все еще недостаточно информации для основания" v-tippy="{placement: 'bottom'}" tooltip-translate="Society.Found.MissingInformation" tooltip-show="true" class="clickable disabled">
							<div class="content">
								<span ng-transclude="" class="innerText">
									<span translate=""><span>Основать</span></span>
								</span>
								<!-- ngIf: price > 0 || forceGoldUsage -->
								<!-- ngIf: price == -1 && !forceGoldUsage -->

								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
						<button clickable="closeOverlay();" class="cancel clickable">
							<div class="content">
								<span ng-transclude="" class="innerText">
									<span translate=""><span>Отмена</span></span>
								</span>
								<!-- ngIf: price > 0 || forceGoldUsage -->
								<!-- ngIf: price == -1 && !forceGoldUsage -->

								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
						<div class="error"></div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
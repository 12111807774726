<template>
	<div class="reportBody prestigeReport" src="'tpl/report/types/'+reportType+'.html'">
		<div class="prestigeReport">
			<div class="weekOverviewHeader contentBox gradient">
				<span translate="" class="prestigeGainedText1"><span>Выполнено на этой неделе</span></span>
				<i class="feature_prestige_small_flat_black"></i>
				<span class="gainedPrestigeAmount">9</span>
				<span translate="" class="prestigeGainedText2"><span>Получен престиж</span></span>
			</div>
			<div class="horizontalSeparator"></div>
			<!-- ngIf: body.medals.length > 0 -->
			<!-- ngRepeat: medal in body.medals -->
			<div class="blockHeader" ng-class="{'noTopBorder': body.medals.length > 0}">
				<span translate=""><span>Испытания</span></span>
				<span class="challengesCount" ng-bind-html="0 | bidiRatio:body.totalFulfilled:body.conditions.length:true">
					<span class="amount wrapper">13</span>/<span class="capacity">10</span>
				</span>
			</div>
			<div class="horizontalSeparator"></div>
			<div class="conditionTable">
				<table class="columnOnly">
					<tbody>
						<tr class="conditionTableRow fulfilled" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<!-- ngIf: condition.type=='ranking' && body.rankingConditions.length > 0 -->
								<div ng-if="!condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox positive">
									<i class="action_check_small_flat_white"></i>
								</div>
								<!-- ngIf: condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="1"><span>Повысить уровень героя</span></span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">
									<span class="amount wrapper">1</span>/<span class="capacity">1</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
						<tr class="conditionTableRow fulfilled" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<!-- ngIf: condition.type=='ranking' && body.rankingConditions.length > 0 -->
								<div ng-if="!condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox positive">
									<i class="action_check_small_flat_white"></i>
								</div>
								<!-- ngIf: condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="15">
									<span>Атаковать или защитить других игроков 3 раза</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">
									<span class="amount wrapper">3</span>/<span class="capacity">3</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
						<tr class="conditionTableRow fulfilled" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<!-- ngIf: condition.type=='ranking' && body.rankingConditions.length > 0 -->
								<!-- ngIf: !condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
								<div ng-if="condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0)" tooltip="" tooltip-translate="Prestige.Tooltip.AdventurePoints" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox positive">
									<i class="action_check_small_flat_white"></i>
								</div>
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="2"><span>Завершить 7 приключений</span></span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">
									<span class="amount wrapper">7</span>/<span class="capacity">7</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
						<tr class="conditionTableRow" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<!-- ngIf: condition.type=='ranking' && body.rankingConditions.length > 0 -->
								<div ng-if="!condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
									<i class="action_check_small_flat_white"></i>
								</div>
								<!-- ngIf: condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="3"><span>Завершить 7 ежедневных заданий</span></span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">
									<span class="amount wrapper">1</span>/<span class="capacity">7</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black inactive" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
						<tr class="conditionTableRow fulfilled" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<!-- ngIf: condition.type=='ranking' && body.rankingConditions.length > 0 -->
								<div ng-if="!condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox positive">
									<i class="action_check_small_flat_white"></i>
								</div>
								<!-- ngIf: condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="4"><span>Завершить 3 исследования</span></span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">
									<span class="amount wrapper">3</span>/<span class="capacity">3</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
						<tr class="conditionTableRow fulfilled" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<!-- ngIf: condition.type=='ranking' && body.rankingConditions.length > 0 -->
								<div ng-if="!condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox positive">
									<i class="action_check_small_flat_white"></i>
								</div>
								<!-- ngIf: condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="5">
									<span>Увеличить население на 100</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">
									<span class="amount wrapper">100</span>/<span class="capacity">100</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
						<tr class="conditionTableRow fulfilled" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<!-- ngIf: condition.type=='ranking' && body.rankingConditions.length > 0 -->
								<div ng-if="!condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox positive">
									<i class="action_check_small_flat_white"></i>
								</div>
								<!-- ngIf: condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="6"><span>Написать 1 сообщение любому игроку</span></span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">
									<span class="amount wrapper">1</span>/<span class="capacity">1</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
						<tr class="conditionTableRow fulfilled" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<!-- ngIf: condition.type=='ranking' && body.rankingConditions.length > 0 -->
								<div ng-if="!condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox positive">
									<i class="action_check_small_flat_white"></i>
								</div>
								<!-- ngIf: condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="7"><span>Новое поселение</span></span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">
									<span class="amount wrapper">1</span>/<span class="capacity">1</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
						<tr class="conditionTableRow fulfilled" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<!-- ngIf: condition.type=='ranking' && body.rankingConditions.length > 0 -->
								<div ng-if="!condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox positive">
									<i class="action_check_small_flat_white"></i>
								</div>
								<!-- ngIf: condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="8">
									<span>Продавайте краденные ценности или собирайте их в сокровищнице в качестве дани</span>
								</span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true"><span class="amount wrapper">10</span>/<span class="capacity">10</span></span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
						<tr class="conditionTableRow conditionTableRowLast fulfilled" ng-repeat="condition in body.conditions | orderBy:'conditionType'" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}">
							<td class="conditionTableColumn conditionTableColumnCheckbox">
								<div class="checkBox positive" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" ng-if="condition.type=='ranking' && body.rankingConditions.length > 0" tooltip-data="body.rankingConditions" tooltip="" tooltip-url="tpl/report/partials/rankingPrestigeRowTooltip.html" tooltip-placement="above">
									<i class="action_check_small_flat_white"></i>
								</div>
								<!-- ngIf: !condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
								<!-- ngIf: condition.tooltipTranslationKey && (condition.type!='ranking' || body.rankingConditions.length == 0) -->
							</td>
							<td class="conditionTableColumn conditionTableColumnName">
								<span class="conditionName" translate="" options="ranking"><span>Улучшить свой рейтинг или удержаться в топ-10</span></span>
							</td>
							<td class="conditionTableColumn conditionTableColumnAchieved">
								<span class="achievedRatio" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true"><span class="amount wrapper">1</span>/<span class="capacity">1</span></span>
							</td>
							<td class="conditionTableColumn conditionTableColumnPrestige">
								<span></span>
								<i class="feature_prestige_small_flat_black" ng-class="{'inactive': !condition.fulfilled}"></i>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		report: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
		
		onOpenProfile(playerId, tab){
			
		},
	},
	components: {},
}
</script>
<template>
	<div class="contentBox">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content" translate=""><span>Интерфейс</span></div>
		</div>
		<div class="contentBoxBody">
			<table>
				<tbody>
					<tr class="settingsRow" ng-if="languageDropdown.options.length > 1">
						<td class="label" translate=""><span>Язык:</span></td>
						<td class="setting">
							<select style="display: block; width: 100%; height: 24px;">
								<option>Русский</option>
								<option>English</option>
							</select>
						</td>
					</tr>
					<tr class="settingsRow" v-for="setting in ['enableTabNotifications', 'enableHelpNotifications', 'enableWelcomeScreen']" tooltip="" tooltip-translate="Settings.SettingTooltip_enableTabNotifications" tooltip-show="false">
						<td class="label" translate="" options="enableTabNotifications">
							<span>{{checkboxSettings[setting].name}}</span>
						</td>
						<td class="setting">
							<switch-element class="switch" name-true="Да" name-false="Нет" v-model="checkboxSettings[setting].value"/>
						</td>
					</tr>
					<tr class="settingsRow">
						<td class="label" translate="">
							<span>Подтверждение использования карточной игры, моментального завершения, NPC-торговца</span>
						</td>
						<td class="setting">
							<select style="display: block; width: 320px; height: 24px;">
								<option>Использование золота и премиум-предметов</option>
								<option>Использование золота</option>
								<option>Нет</option>
							</select>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="contentBox">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content" translate=""><span>Время</span></div>
		</div>
		<div class="contentBoxBody">
			<table>
				<tbody>
					<tr class="settingsRow">
						<td class="label" translate=""><span>Показывать переключение часового пояса</span></td>
						<td class="setting" content="Показать виджет в нижней части страницы для быстрого переключения между настройками часового пояса. Это может быть полезным, если вы регулярно координируете действия с другими игроками и хотите переключаться между временем сервера и вашим местным временем." v-tippy="{placement: 'bottom'}" tooltip-translate="Settings.TimeSettings.Tooltip.Switcher">
							<switch-element class="switch" name-true="Да" name-false="Нет" v-model="timeZoneSwitcher.value"/>
						</td>
					</tr>
					<tr class="settingsRow">
						<td class="label" translate=""><span>Тип времени</span></td>
						<td>
							<div class="option">
								<label class="clickInputLabel">
									<div class="clickInputWrapper">
										<input type="radio" ng-change="showCurrentTimeType()" v-model="current.timeType" :value="TimezonesModel.TIME_TYPE_LOCAL" class="ng-valid ng-dirty ng-valid-parse ng-touched"/>
										<i class="symbol_dot_tiny_illu"></i>
									</div>
									<span translate="" content="Все настройки времени в игре будут адаптированы, чтобы соответствовать вашему системному времени." v-tippy="{placement: 'bottom'}" tooltip-translate="Settings.TimeSettings.Tooltip.LocalTime"><span>Местное время</span></span>
								</label>
							</div>
							<div class="option">
								<label class="clickInputLabel">
									<div class="clickInputWrapper">
										<input type="radio" ng-change="showCurrentTimeType()" v-model="current.timeType" :value="TimezonesModel.TIME_TYPE_GAMEWORLD" class="ng-valid ng-dirty ng-touched"/>
										<i class="symbol_dot_tiny_illu"></i>
									</div>
									<span translate="" content="Все настройки в игре будут отображать время сервера. Рекомендуется для координации работы с другими игроками." v-tippy="{placement: 'bottom'}" tooltip-translate="Settings.TimeSettings.Tooltip.GameworldTime"><span>Время сервера</span></span>
								</label>
							</div>
							<div class="option">
								<label class="clickInputLabel">
									<div class="clickInputWrapper">
										<input type="radio" ng-change="showCurrentTimeType()" v-model="current.timeType" :value="TimezonesModel.TIME_TYPE_UTC" class="ng-valid ng-dirty ng-touched"/>
										<i class="symbol_dot_tiny_illu"></i>
									</div>
									<span translate="" content="Все настройки в игре будут отображать время согласно часовому поясу UTC (без перехода на зимний/летний режим!), который вы выберете. Измените настройки ниже, чтобы выбрать определенный часовой пояс." v-tippy="{placement: 'bottom'}" tooltip-translate="Settings.TimeSettings.Tooltip.UTC"><span>Часовой пояс UTC</span></span>
								</label>
							</div>
						</td>
					</tr>
					<tr class="settingsRow">
						<td class="label" translate="" content="Эта настройка работает, только если вы выбрали в качестве вашего типа времени 'Часовой пояс UTC'." v-tippy="{placement: 'bottom'}" tooltip-translate="Settings.TimeSettings.Tooltip.UTCTimezone">
							<span>Часовой пояс UTC</span>
						</td>
						<td>
							<select style="display: block; width: 200px; height: 24px;">
								<option>-12:00</option>
								<option>-11:00</option>
								<option>-10:00</option>
								<option>-09:30</option>
								<option>-09:00</option>
								<option>-08:00</option>
								<option>-07:00</option>
								<option>-06:00</option>
								<option>-05:00</option>
								<option>-04:00</option>
								<option>-03:30</option>
								<option>-03:00</option>
								<option>-02:00</option>
								<option>-01:00</option>
								<option>±00:00</option>
								<option>+01:00</option>
								<option>+02:00</option>
								<option>+03:00</option>
								<option>+03:30</option>
								<option>+04:00</option>
								<option>+04:30</option>
								<option>+05:00</option>
								<option>+05:30</option>
								<option>+05:45</option>
								<option>+06:00</option>
								<option>+06:30</option>
								<option>+07:00</option>
								<option>+08:00</option>
								<option>+08:30</option>
								<option>+08:45</option>
								<option>+09:00</option>
								<option>+09:30</option>
								<option>+10:00</option>
								<option>+10:30</option>
								<option>+11:00</option>
								<option>+12:00</option>
								<option>+12:45</option>
								<option>+13:00</option>
								<option>+14:00</option>
							</select>
						</td>
					</tr>
					<tr class="settingsRow">
						<td class="label" translate=""><span>Формат отображения времени</span></td>
						<td class="setting">
							<select style="display: block; width: 200px; height: 24px;">
								<option>EU (дд.мм.гг 24ч)</option>
								<option>US (мм/дд/гг 12ч)</option>
								<option>UK (дд/мм/гг 12ч)</option>
								<option>ISO (гг/мм/дд 24ч)</option>
							</select>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div ng-if="::config.balancing.vacation.vacationDays > 0" class="contentBox">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content" translate=""><span>Режим отпуска</span></div>
		</div>
		<div class="contentBoxBody">
			<table>
				<tbody>
					<tr class="settingsRow">
						<td>
							<div data="activationHours:48" translate=""><span>Активация режима отпуска займет 48 ч. Все закрепленные оазисы будут потеряны. Оазисы могут быть снова захвачены после отпуска. На время твоего отпуска другие игроки утратят возможность посылать на тебя атаки. Все подкрепления от других игроков и войска в оазисах отправлены домой. Твое производство ресурсов (дерево, глина и железо) будет увеличено на 100%% в течение того же времени, сколько длился отпуск.</span></div>
							<!-- ngIf: ::player.data.kingdomRole > 0 -->
						</td>
					</tr>
				</tbody>
			</table>
			<div class="contentBox">
				<div class="contentBoxBody">
					<div class="settingRow" data="left:14,total:14" translate=""><span>Осталось дней: </span><strong><span class="values" ng-bind-html="0 | bidiRatio:left:total">14/14</span></strong></div>
					<div ng-if="player.data.vacationState == Player.VACATION_STATE_INACTIVE &amp;&amp; config.balancing.vacation.vacationDays > player.data.usedVacationDays" class="settingRow">
						<span translate=""><span>Спланируй новый отпуск:</span></span>
						<select style="display: inline-block; width: 200px; height: 24px;">
							<option v-for="n in range(1, 14)" :key="n">{{n}} дн.</option>
						</select>
					</div>
					<div class="symbol_i_medium_wrapper clickable" clickable="openWindow('help', {'pageId': 'AccountSystem_VacationMode'})">
						<i class="symbol_i_medium_flat_white"></i>
					</div>
					<!-- ngIf: player.data.vacationState == Player.VACATION_STATE_INACTIVE && config.balancing.vacation.vacationDays <= player.data.usedVacationDays -->
					<!-- ngIf: player.data.vacationState == Player.VACATION_STATE_ACTIVATING -->
					<!-- ngIf: player.data.vacationState == Player.VACATION_STATE_ACTIVE -->
					<!-- ngIf: player.data.vacationState == Player.VACATION_STATE_BOOST -->
					<button clickable="changeVacationState()" tooltip="" tooltip-data="vacationStart:1708008251.1,vacationEnd:1708094651.1" tooltip-translate-switch="{
								'Settings.VacationMode.Button.Sitter.Tooltip': false,
								'Settings.VacationMode.Warning.Leaders': false,
								'Settings.VacationMode.Warning.WWOwner': false,
								'Settings.VacationMode.Warning.NoobProtection': false,
								'Settings.VacationMode.Button.EnterVacation.Tooltip': false,
								'Settings.VacationMode.Button.CancelActivation.Tooltip': false,
								'Settings.VacationMode.Button.AbortVacation.Tooltip': false,
								'Settings.VacationMode.Button.BoostState.Tooltip': false}" ng-class="{disabled: player.isKingdomLeader() || isWWOwner || player.data.hasNoobProtection || isSitter || (player.data.vacationState == Player.VACATION_STATE_INACTIVE &amp;&amp; !(vacationDaysDropdown.selected >= 0)) || player.data.vacationState == Player.VACATION_STATE_BOOST,
									   cancel: player.data.vacationState == Player.VACATION_STATE_ACTIVATING}" class="clickable disabled">
						<div class="content">
							<span ng-transclude="" class="innerText">
								<span translate="" ng-if="player.data.vacationState == Player.VACATION_STATE_INACTIVE || player.data.vacationState == Player.VACATION_STATE_BOOST"><span>Подтвердить</span></span>
								<!-- ngIf: player.data.vacationState == Player.VACATION_STATE_ACTIVATING -->
								<!-- ngIf: player.data.vacationState == Player.VACATION_STATE_ACTIVE -->
							</span>
							<!-- ngIf: price > 0 || forceGoldUsage -->
							<!-- ngIf: price == -1 && !forceGoldUsage -->
							<div class="animationOverlay"></div>
							<i class="checkmark action_check_small_flat_white"></i>
							<i class="checkmark action_check_small_flat_black"></i>
							<i class="checkmark action_check_small_flat_red"></i>
						</div>
					</button>
				</div>
			</div>
			<div ng-show="any" class="dialogWarning ng-hide" keys="Settings.VacationMode.Warning.Leaders:false,Settings.VacationMode.Warning.WWOwner:false,Settings.VacationMode.Warning.NoobProtection:false"><div class="warningHeader"><i class="symbol_warning_tiny_flat_white"></i><span translate=""><span>Предупреждение:</span></span></div><div class="warningContent" ng-class="{single:warnings.length == 1}"><ul><!-- ngRepeat: w in warnings track by $index --></ul></div></div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import TimezonesModel from '@/models/game/TimezonesModel';

import SwitchElement from '@/components/elements/Switch';

export default {
	mixins: lib.mixins,
	data: () => ({
		TimezonesModel,
		
		checkboxSettings: {
			enableTabNotifications: {
				name: 'Включить уведомления в браузере',
				value: true,
			},
			enableHelpNotifications: {
				name: 'Справочные уведомления',				
				value: false,
			},
			enableWelcomeScreen: {
				name: 'Показывать приветственное окно при входе',
				value: true,
			},
		},
		timeZoneSwitcher: {
			value: false,
		},
		current: {
			timeType: 1,
		},
	}),
	computed: {},
	methods: {
		range: lib.range,
	},
	components: {
		SwitchElement,
	},
}
</script>
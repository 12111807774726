<template>
	<div ng-transclude="">
		<div ng-controller="buildingIndexCtrl" class="building buildingType18" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
			<div ng-include="" src="pageTemplate">
				<div class="buildingDetails embassy" ng-controller="embassyCtrl">
					<div ng-show="isBuildingBuild()">
						<div ng-repeat="tab in tabData.loadedTabs" ng-if="tab" class="loadedTab tabCommunities activeTab currentTab" ng-class="{currentTab: tab == tabData.currentTab, hiddenTab: tab != tabData.currentTab, activeTab: tab == tabData.activeTab, hiddenDynamicHeight: tab != tabData.activeTab}" ng-include="tabData.bodyTemplatePrefix + tab + tabData.bodyTemplateSuffix">
							<div class="communities" ng-controller="embassyCommunitiesCtrl">
								<div tabulation="" tab-config-name="embassyCommunitiesTabConfig" template-url="tpl/building/embassy/subtabs/CommunitiesNavigation.html">
									<nav class="tabulation subtab subtab vertical">
										<a class="tab clickableContainer clickable" :class="{'active': tab == 'kingdom'}" @click="onToggleTab('kingdom')">
											<h4 options="Society.Headline.Kingdom">
												<span>Королевство</span>
											</h4>
											<div class="horizontalLine double"></div>
											<i class="community_kingdom_huge_illu"></i>
											<i class="selection_arrow_illu arrow" v-if="tab == 'kingdom'"></i>
										</a>
										<a class="tab clickableContainer clickable" :class="{'active': tab == 'secret-society'}" @click="onToggleTab('secret-society')">
											<h4 options="Society.Headline.SecretSociety">
												<span>Тайное сообщество</span>
											</h4>
											<div class="horizontalLine double"></div>
											<i class="community_secretSociety_huge_illu"></i>
											<i class="selection_arrow_illu arrow" v-if="tab == 'secret-society'"></i>
										</a>
									</nav>
									<div class="tabContent contentBox">
										<div ng-transclude="">
											<div v-if="tab == 'kingdom'" class="loadedTab tabKingdom activeTab currentTab">
												<div ng-controller="embassyKingdomCtrl" class="embassyKingdom">
													<h6 class="headerWithIcon arrowDirectionDown contentBoxHeader">
														<i class="community_kingdom_medium_flat_black communityIcon"></i>
														<span class="title"><span>Данные</span></span>
														<a clickable="openOverlay('kingdomInvitation');" class="invitations clickable disabled" ng-class="{disabled: invitations.data.length <= 0}">
															<span data="amount: 0"><span>Открытые приглашения: 0</span></span>
															<i class="community_invitation_small_illu disabled" ng-class="{disabled: invitations.data.length <= 0}"></i>
														</a>
													</h6>
													<div class="contentBoxBody detailsContent followingFooter" ng-class="{followingFooter: coronationStatus != Player.CORONATION_STATUS.CROWNED}">
														<!-- neither governor nor king -->
														<span ng-if="user.data.kingdomId == 0"><span>Так как ты сейчас не входишь в королевство, ты можешь основать свое собственное.</span></span>
														<!-- is governor or king -->
														<div ng-if="user.data.kingdomId > 0" class="membership contentBox gradient">
															<h6 class="contentBoxHeader headerWithArrowEndings" ng-class="{glorious: coronationStatus == Player.CORONATION_STATUS.CROWNED}">
																<div class="content">
																	<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
																</div>
																<!-- ngIf: canAbdicateAsKing() -->
																<i ng-if="player.data.kingdomRole == Player.KINGDOM_ROLE_GOVERNOR" class="headerButton jsQuestButtonCoronation clickable action_leave_small_flat_black" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" ng-on-pointer-over="leaveHover = true" ng-on-pointer-out="leaveHover = false" clickable="openOverlay('abdicateGovernor')" content="Покинуть королевство" v-tippy="{placement: 'bottom'}" tooltip-translate="Embassy.Communities.Kingdom.abdicateAsGovernor">
																</i>
															</h6>
															<div class="contentBoxBody">
																<a class="showDetails clickable" clickable="openWindow('society', {tab: 'Kingdom'})">
																	<i class="details_icon_small_green embassyDetails"></i>
																	<span><span>Показать детали</span></span>
																</a>
																<div class="horizontalLine"></div>
																<!-- ngIf: coronationStatus == Player.CORONATION_STATUS.CROWNED -->
																<!-- ngIf: coronationStatus != Player.CORONATION_STATUS.CROWNED && player.data.kingdomRole == Player.KINGDOM_ROLE_VICEKING -->
																<!-- ngIf: coronationStatus != Player.CORONATION_STATUS.CROWNED && player.data.kingdomRole == Player.KINGDOM_ROLE_DUKE -->
																<span ng-if="coronationStatus != Player.CORONATION_STATUS.CROWNED && player.data.kingdomRole == Player.KINGDOM_ROLE_GOVERNOR" data="name: Gaius Ulius"><span>Ты сейчас являешься губернатором в королевстве игрока Gaius Ulius. В обмен на защиту ты производишь дань для своего короля. В чате королевства ты можешь пообщаться со своим королем и другими губернаторами.</span></span>
																<!-- ngIf: player.data.kingdomRole == Player.KINGDOM_ROLE_DUKE -->
															</div>
														</div>
													</div>
													<div ng-if="coronationStatus == Player.CORONATION_STATUS.NONE && player.data.kingdomRole != Player.KINGDOM_ROLE_VICEKING" class="detailsFooter contentBoxFooter">
														<div class="footerContent">
															<span ng-if="player.data.kingdomRole != Player.KINGDOM_ROLE_DUKE && !player.data.hasNoobProtection"><span>Ты можешь основать свое королевство, чтобы стать королем. Будучи королем, тебе придется защищать других игроков на своей территории, чтобы ты мог получать с них дань.</span></span>
															<table class="infoBox transparent">
																<tbody>
																	<tr>
																		<td><i class="symbol_warning_tiny_flat_red"></i></td>
																		<td ng-if="!treasuryAvailable && !player.data.hasNoobProtection && player.data.kingdomRole != Player.KINGDOM_ROLE_DUKE">
																			<span><span>Чтобы основать королевство, тебе нужна скрытая сокровищница в активной деревне. Сокровищница станет активной сразу по окончании церемонии коронации.</span></span>
																			<div>
																				<span><span>Больше информации: </span></span>
																				<a clickable="openWindow('help', {'pageId': 'Buildings_' + Building.TYPE.HIDDEN_TREASURY})" options="45" class="clickable"><span>Скрытая сокровищница</span></a>
																			</div>
																		</td>
																		<td ng-if="treasuryAvailable && !player.data.hasNoobProtection && player.data.kingdomRole != Player.KINGDOM_ROLE_DUKE">
																			<span><span>По окончании церемонии коронации сокровищница в этой деревне станет активной. Тебе придется вручную активировать остальные сокровищницы после того, как ты станешь королем.</span></span>
																		</td>
																		<!-- ngIf: player.data.hasNoobProtection -->
																		<!-- ngIf: !player.data.hasNoobProtection && player.data.kingdomRole == Player.KINGDOM_ROLE_DUKE -->
																	</tr>
																</tbody>
															</table>
															<div class="horizontalLine"></div>
															<button class="jsQuestButtonCoronation clickable disabled" content="Сначала тебе нужна скрытая сокровищница!" v-tippy="{placement: 'bottom'}" clickable="openOverlay('foundKingdom')" ng-if="!isSitter && player.data.deletionTime <= 0" ng-class="{disabled: !treasuryAvailable || player.data.kingdomRole == Player.KINGDOM_ROLE_DUKE || player.data.hasNoobProtection}">
																<div class="content">
																	<span ng-transclude="" class="innerText">
																		<span><span>Основать королевство</span></span>
																	</span>
																	<!-- ngIf: price > 0 || forceGoldUsage -->
																	<!-- ngIf: price == -1 && !forceGoldUsage -->
																	<div class="animationOverlay"></div>
																	<i class="checkmark action_check_small_flat_white"></i>
																	<i class="checkmark action_check_small_flat_black"></i>
																	<i class="checkmark action_check_small_flat_red"></i>
																</div>
															</button>
															<!-- ngIf: player.data.deletionTime > 0 -->
															<!-- ngIf: isSitter -->
														</div>
													</div>
													<!-- ngIf: coronationStatus == Player.CORONATION_STATUS.IN_CEREMONY -->
												</div>
												<!-- ngIf: kingError -->
											</div>
											<div v-if="tab == 'secret-society'" class="loadedTab tabSecretSociety activeTab currentTab">
												<div class="embassySecretSociety" ng-controller="embassySecretSocietyCtrl">
													<h6 class="headerWithIcon arrowDirectionDown contentBoxHeader availableInvitation" ng-class="{availableInvitation: invitations.data.length > 0}">
														<i class="community_secretSociety_medium_flat_black communityIcon"></i>
														<span class="title"><span>Данные</span></span>
														<a clickable="openOverlay('secretSocietyInvitation');" class="invitations clickable" ng-class="{disabled: invitations.data.length <= 0}">
															<span data="amount: 1"><span>Открытые приглашения: 1</span></span>
															<i class="community_invitation_small_illu" ng-class="{disabled: invitations.data.length <= 0}"></i>
														</a>
													</h6>
													<div class="contentBoxBody detailsContent followingFooter">
														<div class="scrollWrapper scrollable" scrollable="">
															<div class="scrollContentOuterWrapper" style="width: 425px;">
																<div class="scrollContent" dynamic-height="false" style="width: 425px; overflow-y: hidden;">
																	<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
																		<div ng-if="societies.data.length == 0">
																			<span><span>Ты не входишь ни в одно тайное сообщество. Ты можешь дождаться приглашения или основать свое собственное.</span></span>
																		</div>
																		<div ng-if="ownSociety" class="membership contentBox gradient">
																			<h6 class="contentBoxHeader headerWithArrowEndings glorious">
																				<div class="content">
																					<div class="name truncated">ЗащитаБреста</div>
																					<div class="founder">
																						(<span translate=""><span>Основатель</span></span>)
																					</div>
																				</div>
																				<i class="headerButton clickable action_leave_small_flat_black" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" ng-on-pointer-over="leaveHover = true" ng-on-pointer-out="leaveHover = false" clickable="openOverlay('secretSocietyDissolve', {'societyId': 28})" content="Распустить тайное сообщество" v-tippy="{placement: 'bottom'}" tooltip-translate="Society.Close.SecretSociety" ng-if="!isSitter">
																				</i>
																			</h6>
																			<div class="contentBoxBody">
																				<a class="showDetails clickable" clickable="openWindow('society', {tab: 'SecretSociety', 'societyId': 28})">
																					<i class="details_icon_small_green embassyDetails"></i>
																					<span translate=""><span>Показать детали</span></span>
																				</a>
																			</div>
																		</div>
																		<!-- ngRepeat: society in societies.data -->
																		<!-- ngIf: society.data.groupId != ownSociety.data.groupId -->
																		<div ng-repeat="society in societies.data" ng-if="society.data.groupId != ownSociety.data.groupId" class="membership contentBox gradient">
																			<h6 class="contentBoxHeader headerWithArrowEndings">
																				<div class="content">
																					<div class="name truncated">ЦАРСТВО ТС</div>
																				</div>
																				<i class="headerButton clickable action_leave_small_flat_black" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" ng-on-pointer-over="leaveHover = true" ng-on-pointer-out="leaveHover = false" clickable="openOverlay('secretSocietyLeave', {'societyId': 10})" content="Выйти из тайного сообщества" v-tippy="{placement: 'bottom'}" tooltip-translate="Society.Leave.SecretSociety"></i>
																				<i class="headerButton clickable action_leave_small_flat_black" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" ng-on-pointer-over="leaveHover = true" ng-on-pointer-out="leaveHover = false" clickable="openOverlay('secretSocietyDissolve', {'societyId': 28})" tooltip="" tooltip-translate="Society.Close.SecretSociety" ng-if="!isSitter">
																				</i>
																			</h6>
																			<div class="contentBoxBody">
																				<a class="showDetails clickable" clickable="openWindow('society', {tab: 'SecretSociety', 'societyId': 10})">
																					<i class="details_icon_small_green embassyDetails"></i>
																					<span><span>Показать детали</span></span>
																				</a>
																			</div>
																		</div>
																		<!-- end ngIf: society.data.groupId != ownSociety.data.groupId -->
																		<!-- end ngRepeat: society in societies.data -->
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="detailsFooter contentBoxFooter">
														<div class="footerContent" ng-if="!ownSociety">
															<span><span>Тайные сообщества позволяют тебе преследовать секретные цели совместно с другими игроками. Здесь ты можешь делиться информацией и следить за прогрессом.</span></span>
															<div class="horizontalLine"></div>
															<button clickable="openOverlay('secretSocietyFound')" ng-if="!isSitter" content="Нажми здесь чтобы основать тайное сообщество" v-tippy="{placement: 'top'}" tooltip-translate="Society.Found.Found" class="clickable">
																<div class="content">
																	<span ng-transclude="" class="innerText">
																		<span><span>Основать тайное сообщество</span></span>
																	</span>
																	<!-- ngIf: price > 0 || forceGoldUsage -->
																	<!-- ngIf: price == -1 && !forceGoldUsage -->
																	<div class="animationOverlay"></div>
																	<i class="checkmark action_check_small_flat_white"></i>
																	<i class="checkmark action_check_small_flat_black"></i>
																	<i class="checkmark action_check_small_flat_red"></i>
																</div>
															</button>
															<!-- ngIf: isSitter -->
														</div>
														<div class="footerContent" ng-if="ownSociety">
															<span translate=""><span>Ты можешь основать только одно тайное сообщество. Чтобы основать второе, существующее сперва должно быть расформировано.</span></span>
															<div class="horizontalLine"></div>
															<button class="disabled" content="Ты уже основал тайное сообщество" v-tippy="{placement: 'top'}" tooltip-translate="Embassy.Communities.SecretSociety.cannotFoundSecretSocietyInfo">
																<div class="content">
																	<span ng-transclude="" class="innerText">
																		<span translate=""><span>Основать тайное сообщество</span></span>
																	</span>
																	<!-- ngIf: price > 0 || forceGoldUsage -->
																	<!-- ngIf: price == -1 && !forceGoldUsage -->
																	<div class="animationOverlay"></div>
																	<i class="checkmark action_check_small_flat_white"></i>
																	<i class="checkmark action_check_small_flat_black"></i>
																	<i class="checkmark action_check_small_flat_red"></i>
																</div>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		tab: 'kingdom',
	}),
	computed: {},
	methods: {
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_21.ogg');
				audio.play();
			}
		},
	},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper farmListAdd" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal small farmListAdd">
					<div class="modalContent">
						<div class="functionPanel">
							<a class="closeWindow clickable" @click="$router.push('/map')" play-on-click="7" ng-if="w.params.modalParams.closeable">
								<i class="closeWindow action_cancel_small_flat_black" ng-class="{action_cancel_small_flat_black: !cancelHover, action_cancel_small_flat_green: cancelHover}" on-pointer-over="cancelHover = true" on-pointer-out="cancelHover = false"></i>
							</a>
						</div>
						<div class="contentHeader">
							<h2 ng-if="!w.contentHeaderText" ng-class="::{static: w.params.modalParams.staticPosition}" class=" msAntiScroll" draggable="true">
								<span translate="" options="FarmList.ListAddVillage.headline" data=""><span>Добавить деревню в список целей</span></span>
							</h2>
							<!-- ngIf: w.contentHeaderText -->
						</div>
						<div class="farmListAdd" ng-controller="farmListAddCtrl">
							<div class="farmListWrapper scrollable" scrollable="">
								<div class="scrollContentOuterWrapper" style="width: 348px;">
									<div class="scrollContent" dynamic-height="false" style="width: 348px; overflow-y: hidden;">
										<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
											<div class="farmListInner" on-pointer-over="removePreselected()">
												<!-- ngIf: action === FarmListEntry.ACTION.TOGGLE -->
												<!-- ngIf: action !== FarmListEntry.ACTION.TOGGLE --><h6 ng-if="action !== FarmListEntry.ACTION.TOGGLE">
													<span translate="" data="targetId: 536100851, targetName: Оазис (-13|-24)"><span>Добавить </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536100851}, null, true)" villageid="536100851" villagename="Оазис (-13|-24)" nolink="" showmissinglink="true">Оазис (-13|-24)</a></span>
												</h6><!-- end ngIf: action !== FarmListEntry.ACTION.TOGGLE -->
												<!-- ngRepeat: (key, farmlist) in farmListCollection --><!-- ngIf: farmlist.data.isDefault || player.hasPlusAccount() --><div class="list clickable" ng-repeat="(key, farmlist) in farmListCollection" clickable="addVillage(325)" ng-if="farmlist.data.isDefault || player.hasPlusAccount()" ng-class="{'preselected': key === 0 &amp;&amp; preselected}">
													<!-- ngIf: villageInFarmLists.indexOf(farmlist.data.listId) > -1 --><i class="action_check_small_flat_green" ng-if="villageInFarmLists.indexOf(farmlist.data.listId) > -1"></i><!-- end ngIf: villageInFarmLists.indexOf(farmlist.data.listId) > -1 -->
													<!-- ngIf: villageInFarmLists.indexOf(farmlist.data.listId) === -1 -->
													Стартовый лист
													<span>
														<i class="village_village_small_flat_black" tooltip="" tooltip-translate="FarmList.Tooltip.villages" ng-click="$event.stopPropagation()"></i>
														&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;3&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;10&#x202C;&#x202C;&#x202C;&#x202C;
													</span>
												</div><!-- end ngIf: farmlist.data.isDefault || player.hasPlusAccount() --><!-- end ngRepeat: (key, farmlist) in farmListCollection --><div class="list clickable" ng-repeat="(key, farmlist) in farmListCollection" clickable="addVillage(325)" ng-if="farmlist.data.isDefault || player.hasPlusAccount()" ng-class="{'preselected': key === 0 &amp;&amp; preselected}">
													<!-- ngIf: villageInFarmLists.indexOf(farmlist.data.listId) > -1 -->
													<!-- ngIf: villageInFarmLists.indexOf(farmlist.data.listId) === -1 --><i class="action_check_small_flat_black" ng-if="villageInFarmLists.indexOf(farmlist.data.listId) === -1"></i><!-- end ngIf: villageInFarmLists.indexOf(farmlist.data.listId) === -1 -->
													Стартовый лист
													<span>
														<i class="village_village_small_flat_black" tooltip="" tooltip-translate="FarmList.Tooltip.villages" ng-click="$event.stopPropagation()"></i>
														&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;2&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;10&#x202C;&#x202C;&#x202C;&#x202C;
													</span>
												</div><!-- end ngIf: farmlist.data.isDefault || player.hasPlusAccount() --><!-- end ngRepeat: (key, farmlist) in farmListCollection -->
												<!-- ngIf: error -->
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Отчеты | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper kingdomUnionSuccess " style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal  kingdomUnionSuccess ">
	<div class="modalContent" ng-style="{minHeight: w.getOverlayHeightWithPadding(-10) || 'none'}">
		<div class="windowHeadlineDecoration kingdomUnion_windowHeader contentHeader">
			<h2 class=" msAntiScroll" draggable="true">
				<span translate="" options="KingdomUnionSuccess.Title" data=""><span>Объединенное королевство</span></span>
			</h2>
		</div>
		<div class="functionBar">
			<div class="functionPanel">
				<!-- ngIf: w.params.modalParams.closeable --><a ng-if="w.params.modalParams.closeable" clickable="closeWindow('kingdomUnionSuccess')" class="closeWindow clickable">
					<i class="action_cancel_small_flat_black"></i>
				</a><!-- end ngIf: w.params.modalParams.closeable -->
			</div>
		</div>
		<div class="content">
			<div ng-controller="kingdomUnionSuccessCtrl" class="kingdomUnionSuccess">
	<div class="playerInfo kingdomUnion_background">
		<!-- ngIf: kingPlayerId --><div ng-if="kingPlayerId" class="playerProfileAvatar kingdomRole1">
			<div class="headerWithArrowEndings kingdomRole kingdomRole1">
				<div class="content"><span class="truncated">Брего</span></div>
			</div>
			<div class="avatarLink clickable" clickable="openWindow('profile', {'playerId': kingPlayerId, 'profileTab': 'playerProfile'}, null, true)">
				<avatar-image scale="0.55" class="avatarImage" player-id="106" size="big" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="192" height="193"></canvas></div>
</avatar-image>
			</div>
			<span class="decoration"></span>
		</div><!-- end ngIf: kingPlayerId -->
		<!-- ngIf: vicekingPlayerId --><div ng-if="vicekingPlayerId" class="playerProfileAvatar kingdomRole3">
			<div class="headerWithArrowEndings kingdomRole kingdomRole3">
				<div class="content"><span class="truncated">Gaius Ulius</span></div>
			</div>
			<div class="avatarLink clickable" clickable="openWindow('profile', {'playerId': vicekingPlayerId, 'profileTab': 'playerProfile'}, null, true)">
				<avatar-image scale="0.55" class="avatarImage" player-id="127" size="big" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="192" height="193"></canvas></div>
</avatar-image>
			</div>
			<span class="decoration"></span>
		</div><!-- end ngIf: vicekingPlayerId -->
	</div>
	<p>
		<!-- ngIf: kingPlayerId && vicekingPlayerId --><span translate="" ng-if="kingPlayerId &amp;&amp; vicekingPlayerId" data="kingPlayerId: 106, vicekingPlayerId: 127, openKingdomChat: openChatTab(2), learnMore: openUnionsHelp()"><span>Поздравляем! Твое королевство успешно объединилось с другим, чтобы расширить границы своих владений. Твой новый король </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="106" playername="Брего" nolink="" showmissinglink="true">Брего</a><span> и вице-король </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="127" playername="Gaius Ulius" nolink="" showmissinglink="true">Gaius Ulius</a><span> соединили силы, чтобы стать еще успешнее. В качестве нового жителя королевства, не забудь посетить </span><translate clickable="openChatTab(2)" class="textLink clickable"><span>Чат королевства</span></translate><span> и </span><translate clickable="openWindow('igm', {}, null, true)" class="textLink clickable"><span>IGM</span></translate><span> для получения дальнейшей информации. Если хочешь узнать больше о слиянии королевств, загляни в нашу внутриигровую справку </span><translate clickable="openUnionsHelp()" class="textLink clickable"><span>Узнать больше</span></translate><span>.</span></span><!-- end ngIf: kingPlayerId && vicekingPlayerId -->
	</p>
</div>
		</div>
	</div>
</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Объединенное королевство | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		windowOverlayOpen: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
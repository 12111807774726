<template>
	<div class="combatSimulator" ng-controller="combatSimulatorCtrl">
		<table class="optionTable transparent">
			<tbody>
				<tr>
					<td><span>Тип:</span></td>
					<td>
						<label class="clickInputLabel">
							<div class="clickInputWrapper">
								<input type="radio" v-model="attackType" ng-value="Troops.MOVEMENT_TYPE_ATTACK" class="ng-valid ng-dirty ng-touched" value="3" />
								<i class="symbol_dot_tiny_illu"></i>
							</div>
							<span>Атака</span>
						</label>
					</td>
					<td>
						<label class="clickInputLabel">
							<div class="clickInputWrapper">
								<input type="radio" v-model="attackType" ng-value="Troops.MOVEMENT_TYPE_RAID" class="ng-valid ng-dirty ng-touched ng-valid-parse" value="4" />
								<i class="symbol_dot_tiny_illu"></i>
							</div>
							<span>Набег</span>
						</label>
					</td>
					<td>
						<label class="clickInputLabel" content="Осада<br/>Ты можешь начать осаду, только если у тебя имеется 1000 войск и минимум 1 таран." v-tippy="{placement: 'bottom'}">
							<div class="clickInputWrapper">
								<input type="radio" v-model="attackType" ng-value="Troops.MOVEMENT_TYPE_SIEGE" ng-disabled="attackerUnitCount < 1000 || !attackerDetails.inputTroops[Troops.TYPE_RAM]" class="ng-pristine ng-untouched ng-valid" value="47" disabled="disabled" />
								<i class="symbol_dot_tiny_illu"></i>
							</div>
							<span>Осада</span>
						</label>
					</td>
					<td class="gapCol"></td>
					<td><span>Детали:</span></td>
					<td width="30">
						<div class="clickInputWrapper">
							<input v-model="extendedMode" type="checkbox" class="ng-valid ng-dirty ng-valid-parse ng-touched" />
							<i class="action_check_tiny_flat_black"></i>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="troopsDetailContainer attacker">
			<div class="troopsDetailHeader fromHeader">
				<i class="movement_attack_small_flat_gray colorIcon"></i>
				<span><span>Нападение</span></span>
				<div class="troopsInfo">
					<div class="dropdownContainer" dropdown="" data="attackerDetails.tribeDropdown" open-downwards="true">
						<select style="display: block; height: 24px;">
							<option>Римляне</option>
							<option>Германцы</option>
							<option>Галлы</option>
							<option>lexinzector</option>
						</select>
					</div>
					<!--
					<div class="dropdownContainer" dropdown="" data="attackerDetails.tribeDropdown" open-downwards="true">
						<div class="dropdownHead openDownwards clickable closed" clickable="clickDropdown()">
							<span class="selectedItem truncated">Галлы</span>
							<i class="symbol_arrowDown_tiny_illu"></i>
						</div>
						<input class="dropdownInput" type="text" />
					</div>
					-->
				</div>
			</div>
			<div class="villageSettings" v-if="extendedMode">
				<div ng-repeat="(n, setting) in ['population', 'target1', 'target2', 'natarBonus']" class="populationInputContainer" ng-if="setting!='natarBonus' || attackerDetails.inputTroops[Troops.TYPE_HERO]">
					<i ng-if="setting=='population'" class="unit_population_small_flat_black" content="Население" v-tippy="{placement: 'bottom'}"></i>
					<input ng-model="attackerDetails.villageSettings[setting]" number="99999" ng-disabled="setting=='target2' && (!attackerDetails.villageSettings['target1'] || !attackerDetails.inputTroops[Troops.TYPE_CATAPULT] || attackerDetails.inputTroops[Troops.TYPE_CATAPULT] < Troops.SECOND_TARGET_CATAPULTS)" class="ng-pristine ng-untouched ng-valid" type="tel" />
				</div>
				<div ng-repeat="(n, setting) in ['population', 'target1', 'target2', 'natarBonus']" class="target1InputContainer" ng-if="setting!='natarBonus' || attackerDetails.inputTroops[Troops.TYPE_HERO]">
					<i ng-if="setting=='target1'||setting=='target2'" class="symbol_target_small_flat_black" content="Уровень первой цели катапульт" v-tippy="{placement: 'bottom'}"></i>
					<input ng-model="attackerDetails.villageSettings[setting]" number="100" ng-disabled="setting=='target2' && (!attackerDetails.villageSettings['target1'] || !attackerDetails.inputTroops[Troops.TYPE_CATAPULT] || attackerDetails.inputTroops[Troops.TYPE_CATAPULT] < Troops.SECOND_TARGET_CATAPULTS)" class="ng-pristine ng-valid ng-touched" type="tel" />
				</div>
				<div ng-repeat="(n, setting) in ['population', 'target1', 'target2', 'natarBonus']" class="target2InputContainer" ng-if="setting!='natarBonus' || attackerDetails.inputTroops[Troops.TYPE_HERO]">
					<i ng-if="setting=='target1'||setting=='target2'" class="symbol_target_small_flat_black" content="Уровень второй цели катапульт (мин. 20 катапульт)" v-tippy="{placement: 'bottom'}"></i>
					<input ng-model="attackerDetails.villageSettings[setting]" number="100" ng-disabled="setting=='target2' && (!attackerDetails.villageSettings['target1'] || !attackerDetails.inputTroops[Troops.TYPE_CATAPULT] || attackerDetails.inputTroops[Troops.TYPE_CATAPULT] < Troops.SECOND_TARGET_CATAPULTS)" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
				</div>
				<div ng-repeat="(n, setting) in ['population', 'target1', 'target2', 'natarBonus']" class="natarBonusInputContainer" ng-if="setting!='natarBonus' || attackerDetails.inputTroops[Troops.TYPE_HERO]">
					<i ng-if="setting=='natarBonus'" class="feature_natars_small_flat_black" content="Здесь можно указать бонус к атаке, который дает Рог Натар (при симуляции атаки на Натар, разбойников и животных в оазисах)." v-tippy="{placement: 'bottom'}"></i>
					<input ng-model="attackerDetails.villageSettings[setting]" number="50" ng-disabled="setting=='target2' && (!attackerDetails.villageSettings['target1'] || !attackerDetails.inputTroops[Troops.TYPE_CATAPULT] || attackerDetails.inputTroops[Troops.TYPE_CATAPULT] < Troops.SECOND_TARGET_CATAPULTS)" class="ng-pristine ng-untouched ng-valid" type="tel" />
				</div>
			</div>
			<table class="troopsTable transparent" v-if="extendedMode">
				<tbody class="levelInput">
					<tr>
						<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
							<input ng-model="attackerDetails.unitLevels[n]" number="20" hide-zero="true" type="tel" ng-disabled="!attackerDetails.inputTroops[n] || attackerDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
						</td>
						<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
							<input ng-model="attackerDetails.unitLevels[n]" number="20" hide-zero="true" type="tel" ng-disabled="!attackerDetails.inputTroops[n] || attackerDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
						</td>
						<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
							<input ng-model="attackerDetails.unitLevels[n]" number="20" hide-zero="true" type="tel" ng-disabled="!attackerDetails.inputTroops[n] || attackerDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
						</td>
						<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
							<input ng-model="attackerDetails.unitLevels[n]" number="20" hide-zero="true" type="tel" ng-disabled="!attackerDetails.inputTroops[n] || attackerDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
						</td>
						<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
							<input ng-model="attackerDetails.unitLevels[n]" number="20" hide-zero="true" type="tel" ng-disabled="!attackerDetails.inputTroops[n] || attackerDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
						</td>
						<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
							<input ng-model="attackerDetails.unitLevels[n]" number="20" hide-zero="true" type="tel" ng-disabled="!attackerDetails.inputTroops[n] || attackerDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
						</td>
						<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
							<input ng-model="attackerDetails.unitLevels[n]" number="20" hide-zero="true" type="tel" ng-disabled="!attackerDetails.inputTroops[n] || attackerDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
						</td>
						<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
							<input ng-model="attackerDetails.unitLevels[n]" number="20" hide-zero="true" type="tel" ng-disabled="!attackerDetails.inputTroops[n] || attackerDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
						</td>
						<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
							<input ng-model="attackerDetails.unitLevels[n]" number="20" hide-zero="true" type="tel" ng-disabled="!attackerDetails.inputTroops[n] || attackerDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
						</td>
						<td colspan="2"></td>
					</tr>
				</tbody>
			</table>
			<div troops-details="" troop-data="attackerDetails" ng-class="{losses: attackerDetails.lostTroops || attackerDetails.heroHealthLoss}" unit-input-callback="checkHero" unit-icon-callback="setVillageUnits">
				<table class="troopsTable gaul">
					<tbody class="troopsIconRow">
						<tr>
							<td ng-repeat="n in []|range:1:11" class="unit1">
								<i class="unitSmall gaul unitType1 clickable inactive" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html" content="<div class='stationedUnitTooltip' ng-init='troopId = getTroopId(group['tribeId'], unitId)'>
									<h3>
										<span translate='' options='21'><span>Фаланга (Ур. 1)</span></span>
									</h3>
									<div class='horizontalLine'></div>
									<div class='troopDetails'>
										<div class='troopIcon'>
											<i class='unitMedium gaul unitType1' big='true' data='group['tribeId'], unitId'></i>
										</div>
										<div class='unitAttributes' ng-if='unitId != Troops.TYPE_HERO'>
											<span><i class='movement_attack_small_flat_black'></i>15</span>
											<span><i class='unit_defenseInfantry_small_flat_black'></i>40</span>
											<br>
											<span><i class='unit_defenseCavalry_small_flat_black'></i>50</span>
											<span><i class='unit_capacity_small_flat_black'></i>35</span>
										</div>
									</div>
								</div>" v-tippy="{placement: 'top'}"></i>
								<div class="unitBonusContainer" ng-if="troopData.unitBonuses[n+(troopData.tribeId-1)*10]" tooltip="" tooltip-translate="Hero.ItemBonus_11" tooltip-child-class="increaseArrow" tooltip-data="x:3,name:Фаланга" content="Фаланга: Каждый воин этого типа получает +3 к атаке и +3 к защите." v-tippy="{placement: 'bottom'}">
									<i class="increaseArrow symbol_increaseArrow_tiny_flat_white"></i>
								</div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit2">
								<i class="unitSmall gaul unitType2 clickable inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit3">
								<i class="unitSmall gaul unitType3 clickable inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit4">
								<i class="unitSmall gaul unitType4 clickable inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit5">
								<i class="unitSmall gaul unitType5 clickable inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit6">
								<i class="unitSmall gaul unitType6 clickable inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit7">
								<i class="unitSmall gaul unitType7 clickable inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit8">
								<i class="unitSmall gaul unitType8 clickable inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit9">
								<i class="unitSmall gaul unitType9 clickable inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit10">
								<i class="unitSmall gaul unitType10 clickable inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
							</td>
							<td ng-repeat="n in []|range:1:11" class="hero">
								<i class="unitSmall hero_illu clickable inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html" content="<div class='stationedUnitTooltip' ng-init='troopId = getTroopId(group['tribeId'], unitId)'>
									<h3>
										<span translate='' options='hero'><span>Герой</span></span>
									</h3>
									<div class='horizontalLine'></div>
									<div class='troopDetails'>
										<div class='troopIcon'>
											<i class='unitMedium hero_illu' unit-icon='' big='true' data='group['tribeId'], unitId'></i>
										</div>
										<div class='unitAttributes' ng-if='unitId == Troops.TYPE_HERO'>
											<span ng-class='{noValue : group.unitValues[unitId].attack < 0 }' class='noValue'><i class='movement_attack_small_flat_black'></i>?</span>
											<span ng-class='{noValue : group.unitValues[unitId].defence < 0 }' class='noValue'><i class='unit_defenseInfantry_small_flat_black'></i>?</span>
											<br>
											<span ng-class='{noValue : group.unitValues[unitId].defenceCavalry < 0 }' class='noValue'><i class='unit_defenseCavalry_small_flat_black'></i>?</span>
											<span><i class='unit_capacity_small_flat_black'></i>0</span>
										</div>
									</div>
								</div>" v-tippy="{placement: 'top'}"></i>
							</td>
						</tr>
					</tbody>
					<tbody ng-if="troopData[troopsType+'Troops']" class="inputTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
						<tr>
							<td ng-repeat="n in [] | range:1:11" class="unit1">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput0" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit2">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput1" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit3">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput2" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit4">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput3" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit5">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput4" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit6">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput5" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit7">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput6" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit8">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput7" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit9">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput8" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit10">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput9" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
							<td ng-repeat="n in [] | range:1:11" class="hero">
								<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput10" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
							</td>
						</tr>
					</tbody>
					<tbody ng-if="troopData[troopsType+'Troops']" class="lostTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
						<tr>
							<td ng-repeat="n in [] | range:1:11" class="unit1">
								<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
									<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span>
								</div>
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit2"></td>
							<td ng-repeat="n in [] | range:1:11" class="unit3"></td>
							<td ng-repeat="n in [] | range:1:11" class="unit4"></td>
							<td ng-repeat="n in [] | range:1:11" class="unit5"></td>
							<td ng-repeat="n in [] | range:1:11" class="unit6"></td>
							<td ng-repeat="n in [] | range:1:11" class="unit7"></td>
							<td ng-repeat="n in [] | range:1:11" class="unit8"></td>
							<td ng-repeat="n in [] | range:1:11" class="unit9"></td>
							<td ng-repeat="n in [] | range:1:11" class="unit10"></td>
							<td ng-repeat="n in [] | range:1:11" class="hero"></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="heroOptionsContainer" ng-if="attackerDetails.inputTroops[Troops.TYPE_HERO]">
				<span>Герой:</span>
				<div class="unitBonusContainer">
					<i class="symbol_increaseArrow_tiny_flat_white"></i>
				</div>
				<div class="dropdownContainer" dropdown="" data="attackerDetails.equipmentDropdown" open-downwards="true" style="left: 65px;">
					<!--
					<div class="dropdownHead openDownwards clickable closed" clickable="clickDropdown()">
						<span class="selectedItem truncated">Без оружия</span><i class="symbol_arrowDown_tiny_illu"></i>
					</div>
					<input class="dropdownInput" type="text" />
					-->
					<select style="display: block; height: 24px; width: 230px;">
						<option>Без оружия</option>
						<option>Протазан фаланги</option>
						<option>Пика фаланги</option>
						<option>Копьё фаланги</option>
						<option>Короткий меч мечника</option>
						<option>Меч мечника</option>
						<option>Длинный меч мечника</option>
						<option>Короткий лук тевтатцев</option>
						<option>Лук тевтатцев</option>
						<option>Длинный лук тевтатцев</option>
						<option>Трость друида-всадника</option>
						<option>Посох друида-всадника</option>
						<option>Боевой посох друида-всадника</option>
						<option>Лёгкое копьё эдуйского всадника</option>
						<option>Копьё эдуйского всадника</option>
						<option>Тяжёлое копьё эдуйского всадника</option>
					</select>
				</div>
				<span class="heroInput" content="Верхом" v-tippy="{placement: 'bottom'}" tooltip-translate="CombatSimulator.Mounted">
					<i class="item_category_horse_small_flat_black"></i>
					<div class="clickInputWrapper">
						<input ng-model="attackerDetails.mounted" type="checkbox" class="ng-valid ng-dirty ng-valid-parse ng-touched" />
						<i class="action_check_tiny_flat_black"></i>
					</div>
				</span>
				<span class="heroInput" content="Сила" v-tippy="{placement: 'bottom'}" tooltip-translate="CombatSimulator.heroFightStrength">
					<i class="attribute_strength_small_flat_black"></i>
					<input number="99999" ng-model="attackerDetails.heroStrength" type="tel" class="ng-pristine ng-untouched ng-valid" />
				</span>
				<span class="heroInput" content="Бонус нападения" v-tippy="{placement: 'bottom'}" tooltip-translate="CombatSimulator.heroOffBonus">
					<i class="attribute_offense_small_flat_black"></i>
					<input number="9999" ng-model="attackerDetails.heroBonus" float="1" type="tel" class="ng-pristine ng-untouched ng-valid" />
				</span>
			</div>
		</div>
		<div class="separatorArrow"></div>
		<div class="troopsDetailContainer defenders">
			<div ng-repeat="troopDetails in defenderDetails">
				<div class="troopsDetailHeader toHeader">
					<i class="movement_defend_small_illu colorIcon"></i>
					<span ng-if="$first"><span>Оборона</span></span>
					<div class="troopsInfo">
						<span ng-if="$first" class="addSupport">
							<span>Добавить подкрепление</span>
							<a class="iconButton doubleBorder clickable" clickable="addSupport()">
								<i class="symbol_plus_tiny_flat_black"></i>
							</a>
						</span>
						<div class="dropdownContainer" dropdown="" data="troopDetails.tribeDropdown" open-downwards="true">
							<select style="display: block; height: 24px;">
								<option>Римляне</option>
								<option>Германцы</option>
								<option>Галлы</option>
								<option>Природа</option>
								<option>Натары</option>
								<option>lexinzector</option>
							</select>
						</div>
						<!--
						<div class="dropdownContainer" dropdown="" data="troopDetails.tribeDropdown" open-downwards="true">
							<div class="dropdownHead openDownwards clickable closed" clickable="clickDropdown()">
								<span class="selectedItem truncated">Римляне</span>
								<i class="symbol_arrowDown_tiny_illu"></i>
							</div>
							<input class="dropdownInput" type="text" />
						</div>
						-->
					</div>
				</div>
				<div class="villageSettings" ng-if="$first && extendedMode && troopDetails.tribeId != Player.TRIBE.NATURE">
					<div ng-repeat="setting in ['population', 'mason', 'wall', 'palace', 'moat']">
						<i ng-if="$first" class="unit_population_small_flat_black"  content="Население" v-tippy="{placement: 'bottom'}"></i>
						<input ng-model="troopDetails.villageSettings[setting]" number="99999" class="ng-pristine ng-untouched ng-valid" type="tel" />
					</div>
					<div ng-repeat="setting in ['population', 'mason', 'wall', 'palace', 'moat']">
						<i ng-if="!$first" ng-class="{
							building_g34_small_flat_black: setting == 'mason',
							building_g31_small_flat_black: setting == 'wall',
							building_g25_small_flat_black: setting == 'palace',
							building_g42_small_flat_black: setting == 'moat'
						}"  content="Уровень каменотеса" v-tippy="{placement: 'bottom'}" class="building_g34_small_flat_black"></i>
						<input ng-model="troopDetails.villageSettings[setting]" number="20" class="ng-pristine ng-untouched ng-valid" type="tel" />
					</div>
					<div ng-repeat="setting in ['population', 'mason', 'wall', 'palace', 'moat']">
						<i ng-if="!$first" ng-class="{
							building_g34_small_flat_black: setting == 'mason',
							building_g31_small_flat_black: setting == 'wall',
							building_g25_small_flat_black: setting == 'palace',
							building_g42_small_flat_black: setting == 'moat'
						}"  content="Уровень стены" v-tippy="{placement: 'bottom'}" class="building_g31_small_flat_black"></i>
						<input ng-model="troopDetails.villageSettings[setting]" number="20" class="ng-pristine ng-untouched ng-valid" type="tel" />
					</div>
					<div ng-repeat="setting in ['population', 'mason', 'wall', 'palace', 'moat']">
						<i ng-if="!$first" ng-class="{
							building_g34_small_flat_black: setting == 'mason',
							building_g31_small_flat_black: setting == 'wall',
							building_g25_small_flat_black: setting == 'palace',
							building_g42_small_flat_black: setting == 'moat'
						}"  content="Уровень дворца/резиденции" v-tippy="{placement: 'bottom'}" class="building_g25_small_flat_black"></i>
						<input ng-model="troopDetails.villageSettings[setting]" number="20" class="ng-pristine ng-untouched ng-valid" type="tel" />
					</div>
					<div ng-repeat="setting in ['population', 'mason', 'wall', 'palace', 'moat']">
						<i ng-if="!$first" ng-class="{
							building_g34_small_flat_black: setting == 'mason',
							building_g31_small_flat_black: setting == 'wall',
							building_g25_small_flat_black: setting == 'palace',
							building_g42_small_flat_black: setting == 'moat'
						}"  content="Уровень рва" v-tippy="{placement: 'bottom'}" class="building_g42_small_flat_black"></i>
						<input ng-model="troopDetails.villageSettings[setting]" number="20" class="ng-pristine ng-untouched ng-valid" type="tel" />
					</div>
				</div>
				<table class="troopsTable transparent" ng-if="extendedMode && troopDetails.tribeId < Player.TRIBE.NATURE">
					<tbody class="levelInput">
						<tr>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td colspan="2"></td>
						</tr>
					</tbody>
				</table>
				<div troops-details="" troop-data="troopDetails" ng-class="{losses: troopDetails.lostTroops || troopDetails.heroHealthLoss}" unit-input-callback="checkHero" unit-icon-callback="setVillageUnits">
					<table class="troopsTable roman">
						<tbody class="troopsIconRow">
							<tr>
								<td ng-repeat="n in []|range:1:11" class="unit1">
									<i class="unitSmall roman unitType1 clickable inactive" unit-icon="" data="1" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit2">
									<i class="unitSmall roman unitType2 clickable inactive" unit-icon="" data="2" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit3">
									<i class="unitSmall roman unitType3 clickable inactive" unit-icon="" data="3" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit4">
									<i class="unitSmall roman unitType4 clickable inactive" unit-icon="" data="4" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit5">
									<i class="unitSmall roman unitType5 clickable inactive" unit-icon="" data="5" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit6">
									<i class="unitSmall roman unitType6 clickable inactive" unit-icon="" data="6" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit7">
									<i class="unitSmall roman unitType7 clickable inactive" unit-icon="" data="7" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit8">
									<i class="unitSmall roman unitType8 clickable inactive" unit-icon="" data="8" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit9">
									<i class="unitSmall roman unitType9 clickable inactive" unit-icon="" data="9" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit10">
									<i class="unitSmall roman unitType10 clickable inactive" unit-icon="" data="10" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="hero">
									<i class="unitSmall hero_illu clickable inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="inputTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput0" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput1" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput2" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput3" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput4" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput5" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput6" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput7" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput8" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput9" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput10" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="lostTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span>
									</div>
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2"></td>
								<td ng-repeat="n in [] | range:1:11" class="unit3"></td>
								<td ng-repeat="n in [] | range:1:11" class="unit4"></td>
								<td ng-repeat="n in [] | range:1:11" class="unit5"></td>
								<td ng-repeat="n in [] | range:1:11" class="unit6"></td>
								<td ng-repeat="n in [] | range:1:11" class="unit7"></td>
								<td ng-repeat="n in [] | range:1:11" class="unit8"></td>
								<td ng-repeat="n in [] | range:1:11" class="unit9"></td>
								<td ng-repeat="n in [] | range:1:11" class="unit10"></td>
								<td ng-repeat="n in [] | range:1:11" class="hero"></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="heroOptionsContainer" ng-if="troopDetails.inputTroops[Troops.TYPE_HERO]">
					<span>Герой:</span>
					<div class="unitBonusContainer">
						<i class="symbol_increaseArrow_tiny_flat_white"></i>
					</div>
					<div class="dropdownContainer" dropdown="" data="troopDetails.equipmentDropdown" open-downwards="true" style="left: 65px;">
						<!--
						<div class="dropdownHead openDownwards closed clickable" clickable="clickDropdown()">
							<span class="selectedItem truncated">Без оружия</span>
							<i class="symbol_arrowDown_tiny_illu"></i>
						</div>
						<input class="dropdownInput" type="text" />
						-->
						<select style="display: block; height: 24px; width: 230px;">
							<option>Без оружия</option>
							<option>Протазан фаланги</option>
							<option>Пика фаланги</option>
							<option>Копьё фаланги</option>
							<option>Короткий меч мечника</option>
							<option>Меч мечника</option>
							<option>Длинный меч мечника</option>
							<option>Короткий лук тевтатцев</option>
							<option>Лук тевтатцев</option>
							<option>Длинный лук тевтатцев</option>
							<option>Трость друида-всадника</option>
							<option>Посох друида-всадника</option>
							<option>Боевой посох друида-всадника</option>
							<option>Лёгкое копьё эдуйского всадника</option>
							<option>Копьё эдуйского всадника</option>
							<option>Тяжёлое копьё эдуйского всадника</option>
						</select>
					</div>
					<span class="heroInput" content="Верхом" v-tippy="{placement: 'bottom'}" tooltip-translate="CombatSimulator.Mounted">
						<i class="item_category_horse_small_flat_black"></i>
						<div class="clickInputWrapper">
							<input ng-model="troopDetails.mounted" type="checkbox" class="ng-pristine ng-untouched ng-valid" />
							<i class="action_check_tiny_flat_black"></i>
						</div>
					</span>
					<span class="heroInput" content="Сила" v-tippy="{placement: 'bottom'}" tooltip-translate="CombatSimulator.heroFightStrength">
						<i class="attribute_strength_small_flat_black"></i>
						<input number="99999" ng-model="troopDetails.heroStrength" class="ng-pristine ng-untouched ng-valid" type="tel" />
					</span>
					<span class="heroInput" content="Бонус защиты" v-tippy="{placement: 'bottom'}" tooltip-translate="CombatSimulator.heroDefBonus">
						<i class="attribute_defense_small_flat_black"></i>
						<input number="9999" ng-model="troopDetails.heroBonus" float="1" class="ng-pristine ng-untouched ng-valid" type="tel" />
					</span>
				</div>
			</div>
			<div ng-repeat="troopDetails in defenderDetails">
				<div class="troopsDetailHeader toHeader">
					<i class="movement_defend_small_illu colorIcon"></i>
					<span ng-if="!$first"><span>Подкрепление</span></span>
					<div class="troopsInfo">
						<span ng-if="!$first" class="addSupport">
							<a class="iconButton doubleBorder clickable" clickable="deleteSupport($index)">
								<i class="symbol_minus_tiny_flat_black"></i>
							</a>
						</span>
						<div class="dropdownContainer" dropdown="" data="troopDetails.tribeDropdown" open-downwards="true">
							<div class="dropdownHead openDownwards closed clickable" clickable="clickDropdown()">
								<span class="selectedItem truncated">Римляне</span>
								<i class="symbol_arrowDown_tiny_illu"></i>
							</div>
							<input class="dropdownInput" type="text" />
						</div>
					</div>
				</div>
				<table class="troopsTable transparent" ng-if="extendedMode && troopDetails.tribeId < Player.TRIBE.NATURE">
					<tbody class="levelInput">
						<tr>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td ng-repeat="n in [] | range:1:9" content="Уровень войска" v-tippy="{placement: 'bottom'}">
								<input ng-model="troopDetails.unitLevels[n]" number="20" hide-zero="true" ng-disabled="!troopDetails.inputTroops[n] || troopDetails.inputTroops[n] == 0" class="ng-pristine ng-untouched ng-valid" type="tel" disabled="disabled" />
							</td>
							<td colspan="2"></td>
						</tr>
					</tbody>
				</table>
				<div troops-details="" troop-data="troopDetails" ng-class="{losses: troopDetails.lostTroops || troopDetails.heroHealthLoss}" unit-input-callback="checkHero" unit-icon-callback="setVillageUnits">
					<table class="troopsTable roman">
						<tbody class="troopsIconRow">
							<tr>
								<td ng-repeat="n in []|range:1:11" class="unit1">
									<i class="unitSmall roman unitType1 clickable inactive" unit-icon="" data="1" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit2">
									<i class="unitSmall roman unitType2 clickable inactive" unit-icon="" data="2" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit3">
									<i class="unitSmall roman unitType3 clickable inactive" unit-icon="" data="3" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit4">
									<i class="unitSmall roman unitType4 clickable inactive" unit-icon="" data="4" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit5">
									<i class="unitSmall roman unitType5 clickable inactive" unit-icon="" data="5" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit6">
									<i class="unitSmall roman unitType6 clickable inactive" unit-icon="" data="6" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit7">
									<i class="unitSmall roman unitType7 clickable inactive" unit-icon="" data="7" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit8">
									<i class="unitSmall roman unitType8 clickable inactive" unit-icon="" data="8" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit9">
									<i class="unitSmall roman unitType9 clickable inactive" unit-icon="" data="9" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit10">
									<i class="unitSmall roman unitType10 clickable inactive" unit-icon="" data="10" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
								<td ng-repeat="n in []|range:1:11" class="hero">
									<i class="unitSmall hero_illu clickable inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="unitIconCallback(troopData, n)" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="inputTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput0" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput1" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput2" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput3" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput4" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput5" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput6" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput7" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput8" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput9" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<input ng-if="troopsType == 'input'" number="" ng-model="troopData.inputTroops[n]" hide-zero="true" hide-negative="true" ng-disabled="troopData.inputTroops[n] < 0" class="unitInput10" content="Количество войска" v-tippy="{placement: 'bottom'}" type="tel" />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		
		<div class="separatorArrow" ng-if="result"></div>
		
		<div class="resultContainer contentBox gradient" ng-if="result">
			<div class="contentBoxBody">
				<table class="transparent">
					<tbody>
						<tr>
							<td>
								<span translate=""><span>Симуляция потерь:</span></span>
							</td>
							<td>
								<i class="reportIcon reportTypes_reportIcon1"></i>
								<span translate=""><span>Нападение</span></span>:
																&#x202D;&#x202D;5&#x202C;%&#x202C;
							</td>
							<td>
								<i class="reportIcon reportTypes_reportIcon4"></i>
								<span translate=""><span>Оборона</span></span>:
																&#x202D;&#x202D;100&#x202C;%&#x202C;
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!--
		<div class="resultContainer contentBox gradient" ng-if="result">
			<div class="contentBoxBody">
				<table class="transparent">
					<tbody>
						<tr>
							<td>
								<span translate=""><span>Симуляция потерь:</span></span>
							</td>
							<td>
								<i class="reportIcon reportTypes_reportIcon3"></i>
								<span translate=""><span>Нападение</span></span>:
																&#x202D;&#x202D;100&#x202C;%&#x202C;
							</td>
							<td>
								<i class="reportIcon reportTypes_reportIcon5"></i>
								<span translate=""><span>Оборона</span></span>:
																&#x202D;&#x202D;26&#x202C;%&#x202C;
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		-->
		<table class="statisticsTable transparent" ng-if="result && extendedMode">
			<tbody>
				<tr>
					<th translate=""><span>Результат:</span></th>
					<th><i class="movement_attack_small_flat_gray"></i></th>
					<th><i class="movement_defend_small_illu"></i></th>
				</tr>
				<tr>
					<th translate=""><span>Сила</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span>75</span>

							<div class="divider" ng-style="{width: ((result.totalEffective.attacker*100)/(result.totalEffective.attacker+result.totalEffective.defender))+'%'}" style="width: 88.2353%;"></div>
							<span>10</span>
						</div>
					</td>
				</tr>
				<tr class="captionRow">
					<th translate=""><span>Потери</span></th>
					<td colspan="2"></td>
				</tr>
				<tr ng-repeat="(key, statistic) in result.losses">
					<th translate="" options="resources"><span>Стоимость в ресурсах</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span ng-if="key != 'time'">0</span>
							<div class="divider" ng-style="{width: ((statistic.attacker*100)/(statistic.attacker+statistic.defender))+'%'}"></div>
							<span ng-if="key != 'time'">0</span>
						</div>
					</td>
				</tr>
				<tr ng-repeat="(key, statistic) in result.losses">
					<th translate="" options="crop"><span>Потребление</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span ng-if="key != 'time'">0</span>
							<div class="divider" ng-style="{width: ((statistic.attacker*100)/(statistic.attacker+statistic.defender))+'%'}"></div>
							<span ng-if="key != 'time'">0</span>
						</div>
					</td>
				</tr>
				<tr ng-repeat="(key, statistic) in result.losses">
					<th translate="" options="time"><span>Время обучения (ур. 20)</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span ng-if="key == 'time'">0</span>
							<div class="divider" ng-style="{width: ((statistic.attacker*100)/(statistic.attacker+statistic.defender))+'%'}"></div>
							<span ng-if="key == 'time'">0</span>
						</div>
					</td>
				</tr>
				<tr ng-repeat="(key, statistic) in result.losses">
					<th translate="" options="amount"><span>Количество</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span ng-if="key != 'time'">0</span>
							<div class="divider" ng-style="{width: ((statistic.attacker*100)/(statistic.attacker+statistic.defender))+'%'}"></div>
							<span ng-if="key != 'time'">0</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<!--
		<table class="statisticsTable transparent" ng-if="result && extendedMode">
			<tbody>
				<tr>
					<th translate=""><span>Результат:</span></th>
					<th><i class="movement_attack_small_flat_gray"></i></th>
					<th><i class="movement_defend_small_illu"></i></th>
				</tr>
				<tr>
					<th translate=""><span>Сила</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span>75</span>
							<div class="divider" ng-style="{width: ((result.totalEffective.attacker*100)/(result.totalEffective.attacker+result.totalEffective.defender))+'%'}" style="width: 24.6711%;"></div>
							<span>229</span>
						</div>
					</td>
				</tr>
				<tr class="captionRow">
					<th translate=""><span>Потери</span></th>
					<td colspan="2"></td>
				</tr>
				<tr ng-repeat="(key, statistic) in result.losses">
					<th translate="" options="resources"><span>Стоимость в ресурсах</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span ng-if="key != 'time'">1175</span>
							<div class="divider" ng-style="{width: ((statistic.attacker*100)/(statistic.attacker+statistic.defender))+'%'}" style="width: 83.9286%;"></div>
							<span ng-if="key != 'time'">225</span>
						</div>
					</td>
				</tr><tr ng-repeat="(key, statistic) in result.losses">
					<th translate="" options="crop"><span>Потребление</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span ng-if="key != 'time'">5</span>
							<div class="divider" ng-style="{width: ((statistic.attacker*100)/(statistic.attacker+statistic.defender))+'%'}" style="width: 83.3333%;"></div>
							<span ng-if="key != 'time'">1</span>
						</div>
					</td>
				</tr><tr ng-repeat="(key, statistic) in result.losses">
					<th translate="" options="time"><span>Время обучения (ур. 20)</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span ng-if="key == 'time'">00:11:45</span>
							<div class="divider" ng-style="{width: ((statistic.attacker*100)/(statistic.attacker+statistic.defender))+'%'}" style="width: 76.4642%;"></div>
							<span ng-if="key == 'time'">00:03:37</span>
						</div>
					</td>
				</tr><tr ng-repeat="(key, statistic) in result.losses">
					<th translate="" options="amount"><span>Количество</span></th>
					<td colspan="2">
						<div class="resultBar">
							<span ng-if="key != 'time'">5</span>
							<div class="divider" ng-style="{width: ((statistic.attacker*100)/(statistic.attacker+statistic.defender))+'%'}" style="width: 83.3333%;"></div>
							<span ng-if="key != 'time'">1</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		-->
		
		<button clickable="simulate()" ng-class="{disabled: !attackerUnitCount || ($parent.attackType == Troops.MOVEMENT_TYPE_SIEGE && (attackerUnitCount < 1000 || !attackerDetails.inputTroops[Troops.TYPE_RAM]))}" class="animate clickable disabled" tooltip="" tooltip-translate="RallyPoint.SendTroops.Type_Siege" tooltip-hide="true">
			<div class="content">
				<span class="innerText">
					<span>Рассчитать</span>
				</span>
				<div class="animationOverlay"></div>
				<i class="checkmark action_check_small_flat_white"></i>
				<i class="checkmark action_check_small_flat_black"></i>
				<i class="checkmark action_check_small_flat_red"></i>
			</div>
		</button>
		<button clickable="applyLosses()" ng-if="result" ng-class="{disabled: !enableApplyLosses}" class="clickable disabled">
			<div class="content">
				<span ng-transclude="" class="innerText">
					<span translate=""><span>Применить потери</span></span>
				</span>
				<div class="animationOverlay"></div>
				<i class="checkmark action_check_small_flat_white"></i>
				<i class="checkmark action_check_small_flat_black"></i>
				<i class="checkmark action_check_small_flat_red"></i>
			</div>
		</button>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		extendedMode: false,
		attackType: 3,
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div ng-if="overlayController" class="windowOverlay" id="secretSocietyInvitation" ng-controller="overlayController">
		<div class="darkener clickable" clickable="closeOverlay()"></div>
		<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="622">
			<div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
				<div class="inWindowPopupHeader">
					<h4 translate="" options="Group.OpenInvitations.Headline" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Открытые приглашения</span></h4>
					<h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4>
					<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
				</div>
				<div class="inWindowPopupContent">
					<div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']">
						<div class="invitationOverview scrollable" dynamic="" scrollable="">
							<div class="scrollContentOuterWrapper" style="width: 578px;">
								<div class="scrollContent" dynamic-height="true" style="width: 578px; overflow-y: hidden; max-height: 550px;">
									<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
										<div class="clickableContainer invitationSummary clickable" ng-repeat="invitation in invitations.data" clickable="openOverlay('secretSocietyInvitation', {invitation: invitation.data.id})">
											<i class="community_invitation_small_illu"></i>
											<div class="sender">
												<div class="header truncated">
													<span translate=""><span>Из:</span></span>&nbsp;Migera
												</div>
												<span class="date" i18ndt="1719645424" format="shortDate">29.06.24</span>
											</div>
											<div class="verticalLine double"></div>
											<div class="subject">
												<span translate="" class="header"><span>Приглашение в</span></span><br>
												ЦАРСТВО ТС
											</div>
											<i class="decorationIcon community_secretSociety_large_illu"></i>
											<div class="verticalLine double"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
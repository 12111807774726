<template>
	<div class="statisticsTable contentBox transparent">
		<h6 class="contentBoxHeader headerWithArrowEndings tableGreen">
			<div class="content" translate=""><span>Самые большие деревни</span></div>
		</h6>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems[tab]" current-page="currentPage[tab]" display-page-func="displayCurrentPage" route-named-param="statsPage">
			<div ng-transclude="">
				<table class="gradient pagination page_1">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Деревня" v-tippy="{placement: 'bottom'}" tooltip-translate="TableHeader.Village"><i class="village_village_small_flat_black"></i></th>
							<th content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></th>
							<th content="Население" v-tippy="{placement: 'bottom'}" tooltip-translate="TableHeader.Population"><i class="unit_population_small_flat_black"></i></th>
						</tr>
					</thead>
					<tbody class="ranks">
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}" class="highlighted">
							<td>831</td>
							<td village-column="" village-id="536133632" village-name="InZectorsk" population="57" village-type="1" tribe-id="3" is-active="true" class="villageColumn">
								<div class="villageContainer">
									<i class="village3_1" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536133632}, null, true)" village-link="" villageid="536133632" villagename="InZectorsk">InZectorsk</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="0" y="-23">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '536133632_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;&#x202D;0&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;-&#x202D;23&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="1804" player-name="lexinzector" kingdom-id="0">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1804" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">57</td>
						</tr>
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}">
							<td>832</td>
							<td village-column="" village-id="537313295" village-name="Новая деревня" population="56" village-type="1" tribe-id="3" is-active="false" class="villageColumn">
								<div class="villageContainer">
									<i class="village3_1_inactive" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537313295}, null, true)" village-link="" villageid="537313295" villagename="Новая деревня">Новая деревня</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="15" y="13">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537313295_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;&#x202D;15&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;13&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="1030" player-name="Воля" kingdom-id="0">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1030" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1030" playername="Воля" nolink="false">Воля</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1030" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">56</td>
						</tr>
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}">
							<td>833</td>
							<td village-column="" village-id="536297477" village-name="Valhshtam" population="56" village-type="1" tribe-id="1" is-active="false" class="villageColumn">
								<div class="villageContainer">
									<i class="village1_1_inactive" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536297477}, null, true)" village-link="" villageid="536297477" villagename="Valhshtam">Valhshtam</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="5" y="-18">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '536297477_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;&#x202D;5&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;-&#x202D;18&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="877" player-name="Vanesko" kingdom-id="0">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="877" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="877" playername="Vanesko" nolink="false">Vanesko</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="877" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">56</td>
						</tr>
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}">
							<td>834</td>
							<td village-column="" village-id="538460135" village-name="дЕревня" population="55" village-type="0" tribe-id="2" is-active="true" class="villageColumn">
								<div class="villageContainer">
									<i class="village2_1" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 538460135}, null, true)" village-link="" villageid="538460135" villagename="дЕревня">дЕревня</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="-25" y="48">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '538460135_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;25&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;48&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="1638" player-name="Astrahan" kingdom-id="3">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1638" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1638" playername="Astrahan" nolink="false">Astrahan</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1638" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">55</td>
						</tr>
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}">
							<td>835</td>
							<td village-column="" village-id="537411592" village-name="Новая деревня" population="55" village-type="1" tribe-id="3" is-active="false" class="villageColumn">
								<div class="villageContainer">
									<i class="village3_1_inactive" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537411592}, null, true)" village-link="" villageid="537411592" villagename="Новая деревня">Новая деревня</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="8" y="16">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537411592_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;&#x202D;8&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;16&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="955" player-name="Yammer" kingdom-id="0">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="955" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="955" playername="Yammer" nolink="false">Yammer</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="955" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">55</td>
						</tr>
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}">
							<td>836</td>
							<td village-column="" village-id="537214971" village-name="Антоновка 2" population="55" village-type="0" tribe-id="3" is-active="true" class="villageColumn">
								<div class="villageContainer">
									<i class="village3_1" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537214971}, null, true)" village-link="" villageid="537214971" villagename="Антоновка 2">Антоновка 2</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="-5" y="10">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537214971_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;5&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;10&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="981" player-name="Антон" kingdom-id="3">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="981" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="981" playername="Антон" nolink="false">Антон</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="981" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">55</td>
						</tr>
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}">
							<td>837</td>
							<td village-column="" village-id="537149426" village-name="Вилла м-за Карабаса" population="55" village-type="0" tribe-id="3" is-active="true" class="villageColumn">
								<div class="villageContainer">
									<i class="village3_1" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537149426}, null, true)" village-link="" villageid="537149426" villagename="Вилла м-за Карабаса">Вилла м-за Карабаса</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="-14" y="8">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537149426_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;14&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;8&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="204" player-name="Карабас" kingdom-id="3">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="204" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="204" playername="Карабас" nolink="false">Карабас</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="204" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">55</td>
						</tr>
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}">
							<td>838</td>
							<td village-column="" village-id="537477100" village-name="Карьер м-за Карабаса" population="54" village-type="0" tribe-id="3" is-active="true" class="villageColumn">
								<div class="villageContainer">
									<i class="village3_1" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537477100}, null, true)" village-link="" villageid="537477100" villagename="Карьер м-за Карабаса">Карьер м-за Карабаса</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="-20" y="18">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537477100_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;20&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;18&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="204" player-name="Карабас" kingdom-id="3">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="204" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="204" playername="Карабас" nolink="false">Карабас</a>
								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="204" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">54</td>
						</tr>
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}">
							<td>839</td>
							<td village-column="" village-id="537149446" village-name="Новая деревня" population="54" village-type="0" tribe-id="3" is-active="true" class="villageColumn">
								<div class="villageContainer">
									<i class="village3_1" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537149446}, null, true)" village-link="" villageid="537149446" villagename="Новая деревня">Новая деревня</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="6" y="8">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537149446_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;&#x202D;6&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;8&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="307" player-name="Excem" kingdom-id="2">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="307" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="307" playername="Excem" nolink="false">Excem</a>
								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<!-- ngIf: kingdomId > 0 --><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a><!-- end ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="307" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">54</td>
						</tr>
						<tr ng-repeat="result in rankings.Player[tab]" ng-class="{highlighted: result.playerId == player.data.playerId|| result.rank == searchedRank}">
							<td>840</td>
							<td village-column="" village-id="536133631" village-name="Borgold" population="53" village-type="1" tribe-id="2" is-active="false" class="villageColumn">
								<div class="villageContainer">
									<i class="village2_1_inactive" ng-class="{worldWonder: villageType == 4 || villageType == 7}"></i>
								</div>
								<div class="villageNameContainer">
									<div>
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536133631}, null, true)" village-link="" villageid="536133631" villagename="Borgold">Borgold</a>
									</div>
									<div>
										<span class="coordinateWrapper" coordinates="" x="-1" y="-23">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '536133631_1707841195.6');" class="clickable">
												<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;1&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;-&#x202D;23&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
											<!-- ngIf: !hasLink -->
										</span>
									</div>
								</div>
							</td>
							<td class="nameColumn playerColumn" player-column="" player-id="1706" player-name="Leo" kingdom-id="0">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1706" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1706" playername="Leo" nolink="false">Leo</a>
								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1706" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
										<!-- ngIf: badge -->
									</div>
								</div>
							</td>
							<td class="number">53</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="tg-pagination" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
					</li>
					<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(2)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="">2</a>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(...)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">...</a>
					</li>
					<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(95)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="">95</a>
					</li>
					<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black"></i>
					</li>
					<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
							action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black"></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
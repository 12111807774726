import axios from 'axios';
import lib from '@/lib';

let WorldMap = {};

WorldMap.Error = {
	NoSupportCanvas: new Error('NoSupportCanvas'),
};

WorldMap.EventEmitter = function() {
	this.handlers = [];
};

WorldMap.EventEmitter.prototype = {
	addEventListener : function(event, callback, context, args) {
		if (typeof this.handlers[event] == 'undefined') {
			this.handlers[event] = [];
		}
		this.handlers[event].push([context || null, callback, args || []]);
		
		return true;
	},
	
	removeListener : function(event, callback) {
		if (typeof this.handlers[event] == 'undefined') {
			return false;
		}
		for (var i=0; i<this.handlers[event].length; i++) {
			if (this.handlers[event][i][1] == callback) {
				this.handlers[event].splice(i, 1);
				return true;
			}
		}
		return false;
	},
	
	emit : function(event, local_args) {
		if (typeof this.handlers[event] == 'undefined') {
			this.handlers[event] = [];
		}
		
		local_args = local_args || [];
		
		for (var i=0; i<this.handlers[event].length; i++) {
			var context = this.handlers[event][i][0] || window;
			var callback = this.handlers[event][i][1];
			var args = this._cloneArguments(event, i);
			
			for (var j=local_args.length - 1; j > -1; j--) {
				args.unshift(local_args[j]);
			}
			// args.unshift(this);
			
			if (typeof callback == 'string') {
				context[callback].apply(context, args);
			} else {
				callback.apply(context, args);
			}
		}
	},
	
	_cloneArguments : function(event, index) {
		var result = [];
		var event_arguments = this.handlers[event][index][2];
		
		for (var i=0; i<event_arguments.length; i++) {
			result.push(event_arguments[i]);
		}
		
		return result;
	},
	
	__dummy : null,
};

WorldMap.Mouse = function(element) {
	this.element = null;
	this.pressed = false;
	this.pos  = {x : 0, y : 0};
	this.diff = {x : 0, y : 0};
	
	this._action = {};
	
	if (typeof element !== 'undefined') {
		this._watch(element);
	}
};

WorldMap.Mouse.prototype = {
	getAction : function() {
		var action = this._action;
		this._action = {};
		
		return action;
	},
	
	_watch: function(element) {
		var self = this;
		this._element = element;
		window.addEventListener('mousemove', this._move.bind(this), true);
		this._element.addEventListener('mousedown', this._down.bind(this), true);
		window.addEventListener('mouseup', this._up.bind(this), true);
		
		this._element.addEventListener('selectstart', function(e) {
			e.preventDefault();
		}, true);
	},
	
	_move: function(e) {
		var x = e.offsetX || e.layerX,
			y = e.offsetY || e.layerY;
		
		this.diff.x += Math.abs(this.pos.x - x);
		this.diff.y += Math.abs(this.pos.y - y);
		
		if (this.pressed) {
			this._addToAction('drag', this.pos.x - x, this.pos.y - y);
		} else {
			this._action.move = {x : x, y : y};
		}
		
		this.pos.x = x;
		this.pos.y = y;
	},
	
	_down: function(e) {
		this.pos = {x : e.offsetX || e.layerX, y : e.offsetY || e.layerY};
		this.diff = {x : 0, y : 0};
		this.pressed = true;
		this.click   = true;
		
		this._showMoveCursor(true);
		this._action.down = {x : this.pos.x, y : this.pos.y};
	},
	
	_up: function(e) {
		if (this.pressed) {
			this.pressed = false;
			
			this._showMoveCursor(false);
			this._action.up = {x : this.pos.x, y : this.pos.y};
			if (this.diff.x <= 1 && this.diff.y <= 1) {
				this._action.click = {x : this.pos.x, y : this.pos.y};
			}
		}
	},
	
	_addToAction : function(key, x, y) {
		if (typeof this._action[key] == 'undefined') {
			this._action[key] = {x : 0, y : 0};
		}
		
		this._action[key].x += x;
		this._action[key].y += y;
	},
	
	_showMoveCursor : function (b) {
		if (b) {
			if (lib.browser == 'mozilla') {
				//document.querySelector('.map-container').style.cursor = '-moz-grabbing !important';
				document.querySelector('.map-container').style.cursor = 'move';
			} else if (lib.browser == 'msie') {
				//document.querySelector('.map-container').style.cursor = 'url(img/cursor/closedhand.cur), move';
				document.querySelector('.map-container').style.cursor = 'move';
			} else {
				//document.querySelector('.map-container').style.cursor = 'url(img/cursor/closedhand.cur) 4 4, move';
				document.querySelector('.map-container').style.cursor = 'move';
			}
		} else {
			document.querySelector('.map-container').style.cursor = 'pointer';
		}
	},
};

WorldMap.Canvas = function(width, height, core) {
	WorldMap.EventEmitter.call(this);
	
	this.width  = width;
	this.height = height;
	this.core   = core;
	this.canvas = [];
	this.requestAnimationFrameId = null;
	
	var list = [
		{width : width, height : height},
		{width : width * 2, height : height * 2},
		{width : width * 2, height : height * 2},
		{width : width * 2, height : height * 2}
	];
	
	list.forEach(function(v) {
		var tmp = this._createCanvas(v.width, v.height);
		if (!tmp) { return; }
		
		this.canvas.push(tmp);
	}.bind(this));
	
	if (this.canvas.length != list.length) {
		throw WorldMap.Error.NoSupportCanvas;
	}
	
	document.querySelector('.map-container').appendChild(this.canvas[0].canvas);
	this._mouse = new WorldMap.Mouse(this.canvas[0].canvas);
};

WorldMap.Canvas.prototype = {
	render : function() {
		this.requestAnimationFrameId = requestAnimationFrame(this.render.bind(this));
		
		var pos = this.core._render(this.canvas[1].ctx, this.canvas[2].ctx, this._mouse);
		
		this.canvas[0].ctx.clearRect(0, 0, this.width, this.height);
		this.canvas[0].ctx.drawImage(this.canvas[1].canvas, -pos.x, -pos.y);
		this.canvas[0].ctx.drawImage(this.canvas[2].canvas, -pos.x, -pos.y);
	},
	
	destroy : function(){
		cancelAnimationFrame(this.requestAnimationFrameId);
		document.querySelector('.map-container').firstChild.remove();
	},
	
	clearBuffer : function(){
		this.canvas[1].ctx.clearRect(0, 0, this.width * 2, this.height * 2);
	},
	
	clearBuffer2 : function() {
		this.canvas[2].ctx.clearRect(0, 0, this.width * 2, this.height * 2);
	},
	
	_createCanvas : function(width, height) {
		var canvas = document.createElement('canvas');
		canvas.width  = width;
		canvas.height = height;
		
		if (!canvas.getContext('2d')) {
			document.body.innerHTML = '<div style="text-align: center;">No support 2d context.</div>';
			return false;
		}
		
		var ctx = canvas.getContext('2d');
		ctx.font = '16px monospace';
		ctx.textAlign = 'center';
		
		return {canvas : canvas, ctx : ctx};
	},
};

lib.inherit(WorldMap.Canvas, WorldMap.EventEmitter);

WorldMap.Cell = function(type, image, x, y, data, cell) {
	//this.image = image || 'img/space.jpg';
	
	//let cloudnums = ['01', '02', '03', '04'];
	//let cloudnums = ['2', '3', '4', '6'];
	//this.image = image || 'https://iwstatic.g.bsrv.su/img/map/cloud'+cloudnums[lib.mt_rand(0, cloudnums.length - 1)]+'.png';
	
	this.image = image || 'https://iwstatic.g.bsrv.su/img/map/sea_empty.png';
	
	this.type = type || 'empty';
	this.data = data || {};
	this.x = x;
	this.y = y;
	this.width  = cell.width;
	this.height = cell.height;
	
	this.pos = {
		x : x * cell.width,
		y : y * cell.height
	};
};

WorldMap.Cell.prototype = {
	draw : function(buffer, x, y) {
		var img = WorldMap.Resources.get(this.image);
		if (img) {
			buffer.drawImage(img, this.pos.x - x, this.pos.y - y, this.width, this.height);
		}
	},
	
	getPos : function() {
		return this.pos;
	},
	
	focus : function(ctx, x, y) {
		ctx.beginPath();
		
		/*ctx.ellipse(
			this.pos.x - x - this.width / 2,
			this.pos.y - y - this.height / 2,
			this.width / 2,
			this.height / 2,
			0,
			0,
			2 * Math.PI,
			false
		);*/
		
		// tl
		ctx.moveTo(this.pos.x - x - this.width, this.pos.y - y - this.height);
		ctx.lineTo(this.pos.x - x - (this.width - 10), this.pos.y - y - this.height);
		ctx.moveTo(this.pos.x - x - this.width, this.pos.y - y - this.height);
		ctx.lineTo(this.pos.x - x - this.width, this.pos.y - y - (this.height - 10));
		// tr
		ctx.moveTo(this.pos.x - x, this.pos.y - y - this.height);
		ctx.lineTo(this.pos.x - x - 10, this.pos.y - y - this.height);
		ctx.moveTo(this.pos.x - x, this.pos.y - y - this.height);
		ctx.lineTo(this.pos.x - x, this.pos.y - y - (this.height - 10));
		// bl
		ctx.moveTo(this.pos.x - x - this.width, this.pos.y - y);
		ctx.lineTo(this.pos.x - x - (this.width - 10), this.pos.y - y);
		ctx.moveTo(this.pos.x - x - this.width, this.pos.y - y);
		ctx.lineTo(this.pos.x - x - this.width, this.pos.y - y - 10);
		// br
		ctx.moveTo(this.pos.x - x, this.pos.y - y);
		ctx.lineTo(this.pos.x - x - 10, this.pos.y - y);
		ctx.moveTo(this.pos.x - x, this.pos.y - y);
		ctx.lineTo(this.pos.x - x, this.pos.y - y - 10);
		
		ctx.lineWidth = 2;
		ctx.strokeStyle = '#ff0';
		ctx.stroke();
	},
	
	select : function(ctx, x, y) {
		ctx.beginPath();
		
		/*ctx.ellipse(
			this.pos.x - x - this.width / 2,
			this.pos.y - y - this.height / 2,
			this.width / 2,
			this.height / 2,
			0,
			0,
			2 * Math.PI,
			false
		);*/
		
		// tl
		ctx.moveTo(this.pos.x - x - this.width, this.pos.y - y - this.height);
		ctx.lineTo(this.pos.x - x - (this.width - 10), this.pos.y - y - this.height);
		ctx.moveTo(this.pos.x - x - this.width, this.pos.y - y - this.height);
		ctx.lineTo(this.pos.x - x - this.width, this.pos.y - y - (this.height - 10));
		// tr
		ctx.moveTo(this.pos.x - x, this.pos.y - y - this.height);
		ctx.lineTo(this.pos.x - x - 10, this.pos.y - y - this.height);
		ctx.moveTo(this.pos.x - x, this.pos.y - y - this.height);
		ctx.lineTo(this.pos.x - x, this.pos.y - y - (this.height - 10));
		// bl
		ctx.moveTo(this.pos.x - x - this.width, this.pos.y - y);
		ctx.lineTo(this.pos.x - x - (this.width - 10), this.pos.y - y);
		ctx.moveTo(this.pos.x - x - this.width, this.pos.y - y);
		ctx.lineTo(this.pos.x - x - this.width, this.pos.y - y - 10);
		// br
		ctx.moveTo(this.pos.x - x, this.pos.y - y);
		ctx.lineTo(this.pos.x - x - 10, this.pos.y - y);
		ctx.moveTo(this.pos.x - x, this.pos.y - y);
		ctx.lineTo(this.pos.x - x, this.pos.y - y - 10);
		
		ctx.lineWidth = 2;
		ctx.strokeStyle = '#fff';
		ctx.stroke();
	},
};

WorldMap.Core = function(width, height, center_x, center_y, region_width, region_height) {
	WorldMap.EventEmitter.call(this);
	
	region_width  = region_width || 128;
	region_height = region_height || 128;
	
	this._options = {
		size : {
			width : width,
			height : height,
		},
		
		cell : {
			width : region_width,
			height : region_height,
			
			cols : Math.ceil(width / region_width) + 8, // Количество колонок
			rows : Math.ceil(height / region_height) + 8, // Количество рядов
		},
	};
	
	this._data = {};   // Тут хранится загруженная карта
	
	this.init(center_x, center_y);
};

WorldMap.Core.prototype = {
	init : function(center_x, center_y) {
		var initRunTime = new Date();
		
		try {
			this._canvas = new WorldMap.Canvas(this._options.size.width, this._options.size.height, this);
		} catch (e) {
			if (e !== WorldMap.Error.NoSupportCanvas) {
				throw e;
			}
			return;
		}
		
		this._options.focused  = null;
		this._options.selected = null;
		this._rebuild_buffer   = true; // Флаг о том, что нужно перестроить буфер
		this._rebuild_buffer2  = true; // Флаг о том, что нужно перестроить буфер 2
		
		var count = 1, _afterAll = function() {
			if (--count == 0) {
				//console && console.log('init complete over ' + (new Date() - initRunTime) + 'ms');
				
				// Запускаем рендеринг
				this._canvas.render();
			}
		}.bind(this);
		
		count++;
		if (!WorldMap.Resources.has('https://iwstatic.g.bsrv.su/img/map/sea_empty.png')) {
			WorldMap.Resources.add('https://iwstatic.g.bsrv.su/img/map/sea_empty.png');
		}
		_afterAll();
		
		this.goto(center_x, center_y, _afterAll);
	},
	
	_resize : function(width, height, callback) {
		//console && console.log('resize');
		
		this._options.size.width = width;
		this._options.size.height = height;
		this._options.cell.cols = Math.ceil(width / this._options.cell.width) + 8;
		this._options.cell.rows = Math.ceil(height / this._options.cell.height) + 8;
		
		this._canvas.destroy();
		this.init(this._options.selected.x, this._options.selected.y);
		callback && callback();
	},
	
	_destroy: function() {
		this._canvas.destroy();
	},
	
	goto : function(center_x, center_y, callback) {
		// Получаем из координат центра координаты углов нужной области
		var coords = {
			x1 : center_x - Math.ceil(this._options.cell.cols / 2),
			y1 : center_y - Math.ceil(this._options.cell.rows / 2),
		};
		
		coords.x2 = coords.x1 + this._options.cell.cols;
		coords.y2 = coords.y1 + this._options.cell.rows;
		
		this._options.selected = {
			x : center_x,
			y : center_y,
		};
		
		this._options.pos = {
			//  Пиксельные координаты верхнего левого угла канваса!
			px : {
				x : (coords.x1 + 4) * this._options.cell.width,
				y : (coords.y1 + 4) * this._options.cell.height,
			},
			
			// Смещение буффера
			offset : {
				x : this._options.cell.width  * 4,
				y : this._options.cell.height * 4,
			},
			
			// Координаты
			coord : {
				x : coords.x1,
				y : coords.y1,
			},
		};
		
		this._load_map(coords, function() {
			callback && callback();
			this.emit('select', [this._options.selected]);
		}.bind(this));
	},
	
	_get_ajax_map : async function(coords, callback) {
		//console.log(coords);
		if([coords.x1, coords.x2, coords.y1, coords.y2].includes(null)
		|| [coords.x1, coords.x2, coords.y1, coords.y2].includes(undefined)){
			callback && callback({});
			return;
		}
		await fetch('/api/mapdata?' + new URLSearchParams({
			x1: coords.x1||'',
			x2: coords.x2||'',
			y1: coords.y1||'',
			y2: coords.y2||'',
		}), {
			mode: 'cors',
			credentials: 'include',
			headers: {
				Authorization: 'Bearer '+storeInstance.state.app.auth.token,
			},
		}).then(stream => stream.json()).then(data => {
			callback && callback(data.map);
		});/*.catch(error => {
			console.error(error);
		});*/
		
		/*setTimeout(function(){
			// Генегируем ответ аякса
			var map = {};
			for(var x = Math.min(coords.x1, coords.x2); x <= Math.max(coords.x1, coords.x2); x++) {
				for(var y = Math.min(coords.y1, coords.y2); y <= Math.max(coords.y1, coords.y2); y++) {
					if (typeof map[x] == 'undefined') {
						map[x] = {};
					}
					
					if (x < -6 || y < -6
					|| x > 6 || y > 6) {
						// пустота (море, пустыня, космос, на ваше усмотрение)
						map[x][y] = {
							type: 'empty',
							//image: null,
							data: {},
						};
					} else {
						//map[x][y] = {
						//	type: 'item',
						//	image: 'https://iwstatic.g.bsrv.su/img/map/' + (((Math.abs(y) * 200 + Math.abs(x)) % 7 + 2) + '.png'),
						//	data: {},
						//};
						//map[x][y] = {
						//	type: 'item',
						//	image: 'https://iwstatic.g.bsrv.su/img/map/island1.png',
						//	data: {},
						//};
						
						//let imgs = ['v2/island1', 'v2/island1_left', 'v2/bonus1', 'v2/bonus1_left', 'cloud1'];
						let types = ['cloud', 'island1', 'island1_left', 'bonus1', 'bonus1_left'];
						map[x][y] = {
							//type: 'item',
							type: types[lib.mt_rand(0, types.length - 1)],
							//image: 'https://iwstatic.g.bsrv.su/img/map/'+imgs[lib.mt_rand(0, imgs.length - 1)]+'.png',
							data: {},
						};
					}
				}
			}
			
			callback && callback(map);
		}.bind(this), 0);*/
	},
	
	_load_map : function(coords, callback) {
		// отсеивание загруженных ячеек (хотя бы немного)
		let t_coords2 = {
			x: [],
			y: [],
		};
		for(var x = Math.min(coords.x1, coords.x2); x <= Math.max(coords.x1, coords.x2); x++) {
			if (typeof this._data[x] == 'undefined') {
				if(!t_coords2.x.includes(x)) t_coords2.x.push(x);
				//console.log(x);
				for(var y = Math.min(coords.y1, coords.y2); y <= Math.max(coords.y1, coords.y2); y++) {
					if(!t_coords2.y.includes(y)) t_coords2.y.push(y);
				}
			} else {
				for(var y = Math.min(coords.y1, coords.y2); y <= Math.max(coords.y1, coords.y2); y++) {
					if (typeof this._data[x][y] == 'undefined') {
						if(!t_coords2.x.includes(x)) t_coords2.x.push(x);
						if(!t_coords2.y.includes(y)) t_coords2.y.push(y);
						//console.log(x, y);
					}
				}
			}
		}
		let coords2 = {
			x1: t_coords2.x.length ? Math.min(...t_coords2.x) : null,
			x2: t_coords2.x.length ? Math.max(...t_coords2.x) : null,
			y1: t_coords2.y.length ? Math.min(...t_coords2.y) : null,
			y2: t_coords2.y.length ? Math.max(...t_coords2.y) : null,
		};
		console.log('coords', 'x:', coords.x1, '-', coords.x2, 'y:', coords.y1, '-', coords.y2);
		//console.log('coords2', 'x:', coords2.x1, '-', coords2.x2, 'y:', coords2.y1, '-', coords2.y2);
		
		// ToDo добавить проверку на наличие запрошенной области, чтобы не грузить повторно
		this._get_ajax_map(coords2, function(map) {
			let images_url_prefix = 'https://iwstatic.g.bsrv.su/img/map/';
			let types_images = {
				'free': 'sea_free.png',
				'cloud': 'cloud1.png',
				'island1': 'v2/island1.png',
				'island1_left': 'v2/island1_left.png',
				'bonus1': 'v2/bonus1.png',
				'bonus1_left': 'v2/bonus1_left.png',
			};
			
			let imgs = {};
			for(const [x, yy] of Object.entries(map)) {
				for(const [y, data] of Object.entries(yy)) {
					//console.log(data);
					if (typeof this._data[x] == 'undefined') {
						this._data[x] = {};
					}
					if (typeof this._data[x][y] == 'undefined') {
						let data_type = 'empty';
						let data_image = null;
						if(data){
							if(data.clouded){
								data_type = 'cloud';
							} else if (data.idIsland == null && data.idUser == null) {
								data_type = 'free';
							} else if (data.idIsland != null && data.idUser != null) {
								data_type = 'island1';
							} else if (data.idIsland != null && data.idUser == null) {
								data_type = 'island1_left';
							}
							
							data_image = images_url_prefix + types_images[data_type];
							if (!WorldMap.Resources.has(data_image)) {
								imgs[data_image] = data_image;
							}
						}
						
						this._data[x][y] = new WorldMap.Cell(
							data_type,
							data_image,
							x, y,
							data||{},
							this._options.cell
						);
						
						/*if (data.type == 'empty') {
							// Якобы космос
							//this._data[x][y] = null;
							this._data[x][y] = new WorldMap.Cell(
								'empty',
								data.image,
								x, y,
								{},
								this._options.cell
							)
						} else {
							if (!WorldMap.Resources.has(data.image)) {
								imgs[data.image] = data.image;
							}
							
							this._data[x][y] = new WorldMap.Cell(
								data.type,
								data.image,
								x, y,
								data.data,
								this._options.cell
							);
						}*/
					}
				}
			}
			
			// Загружаем картинки
			WorldMap.Resources.add(imgs, function() {
				this._rebuild_buffer = true;
			}.bind(this));
			
			this._rebuild_buffer = true;
			
			callback && callback(coords);
		}.bind(this));
	},
	
	_rebuildBuffer : function(buffer) {
		var cell = this._options.cell,
			pos = this._options.pos,
			left = pos.coord.x * cell.width + cell.width,
			top = pos.coord.y * cell.height + cell.height;
		
		// Находим отображаемый диапазон
		this._canvas.clearBuffer();
		
		for(var x = pos.coord.x; x <= pos.coord.x + cell.cols; x++) {
			for(var y = pos.coord.y; y <= pos.coord.y + cell.rows; y++) {
				if ((typeof this._data[x] != 'undefined') && (typeof this._data[x][y] != 'undefined')) {
					if (this._data[x][y] !== null) {
						this._data[x][y].draw(buffer, left, top);
					}
				}
			}
		}
	},
	
	_rebuildBuffer2 : function(buffer) {
		this._canvas.clearBuffer2();
		
		var left = this._options.pos.coord.x * this._options.cell.width,
			top = this._options.pos.coord.y * this._options.cell.height;
		
		if(this._options.selected !== null
		&& this._data[this._options.selected.x]
		&& this._data[this._options.selected.x][this._options.selected.y]) {
			this._data[this._options.selected.x][this._options.selected.y].select(buffer, left, top);
		}
		
		if(this._options.focused !== null
		&& this._data[this._options.focused.x]
		&& this._data[this._options.focused.x][this._options.focused.y]) {
			this._data[this._options.focused.x][this._options.focused.y].focus(buffer, left, top);
		}
	},
	
	_getXYByPx : function(px_x, px_y, need_4) {
		var y = Math.floor(px_y / this._options.cell.height),
			x = Math.floor(px_x / this._options.cell.width);
		
		return (need_4) ? {x : x - 4, y : y - 4} : {x : x, y : y};
	},
	
	_getXYByScreenPx : function(x, y, need_4) {
		var p = this._options.pos.px;
		return this._getXYByPx(x + this._options.cell.width + p.x, y + this._options.cell.height + p.y, need_4 || false);
	},
	
	_checkHasData : function(x, y) {
		//return typeof(this._data[x][y]) !== 'undefined' ? this._data[x][y] != null : false;
		return typeof(this._data[x]) !== 'undefined' && typeof(this._data[x][y]) !== 'undefined' && this._data[x][y].type != 'empty';
	},
	
	getCell : function(x, y) {
		return this._data[x][y] || null;
	},
	
	_checkMoveMap : function(mouse) {
		var act = mouse.getAction();
		
		if (act.click) {
			let selected = this._getXYByScreenPx(act.click.x, act.click.y);
			if (this._checkHasData(selected.x, selected.y)) {
				this._options.selected = selected;
				this.emit('select', [this._options.selected]);
				this._rebuild_buffer2 = true;
			}
		}
		
		if (act.move) {
			let focused = this._getXYByScreenPx(act.move.x, act.move.y);
			if (this._checkHasData(focused.x, focused.y)) {
				this._options.focused = focused;
			} else {
				this._options.focused = null;
			}
			this._rebuild_buffer2 = true;
			this.emit('focus', [this._options.focused]);
		}
		
		if (act.drag) {
			this._options.pos.offset.x += act.drag.x;
			this._options.pos.offset.y += act.drag.y;
			this._options.pos.px.x += act.drag.x;
			this._options.pos.px.y += act.drag.y;
			
			var p = this._options.pos,
				xx = p.offset.x,
				yy = p.offset.y,
				w = this._options.cell.width, h = this._options.cell.height;
			
			if ((xx <= w * 2) || (xx >= w * 6) || (yy <= h * 2) || (yy >= h * 6)) {
				var xy = this._getXYByPx(p.px.x, p.px.y, true);
				
				this._options.pos.coord = xy;
				this._options.pos.offset.x = w * 4 + (p.px.x - (xy.x + 4) * w);
				this._options.pos.offset.y = h * 4 + (p.px.y - (xy.y + 4) * h);
				
				var way_x = (xx <= w * 2) ? -1 : ((xx >= w * 6) ? 1 : 0),
					way_y = (yy <= h * 2) ? -1 : ((yy >= h * 6) ? 1 : 0);
				
				this._rebuild_buffer = true;
				this._checkNeedLoadMap(xy.x, xy.y, way_x, way_y);
			}
			
			this.emit('drag', []);
		}
	},
	
	_checkNeedLoadMap : function(xx, yy, way_x, way_y) {
		var x = [], y = [],
			map = this._data;
		
		if (way_x < 0) {
			x.push(xx - 20);
			x.push(xx + this._options.cell.cols);
		} else if (way_x > 0) {
			x.push(xx);
			x.push(xx + this._options.cell.cols + 20);
		} else if (way_x == 0) {
			x.push(xx - 10);
			x.push(xx + this._options.cell.cols + 10);
		}
		
		if (way_y < 0) {
			y.push(yy - 20);
			y.push(yy + this._options.cell.rows);
		} else if (way_y > 0) {
			y.push(yy);
			y.push(yy + this._options.cell.rows + 20);
		} else if (way_y == 0) {
			y.push(yy - 10);
			y.push(yy + this._options.cell.rows + 10);
		}
		
		// p.s. проверяет только рамку координат (края мин макс значений)!
		
		// проверка сверху вниз
		for (var _x = 0; _x <= 1; _x++) {
			for(var _y = y[0]; _y <= y[1]; _y++) {
				//if ((!map[x[_x]]) || (!map[x[_x]][_y])) {
				if (typeof(map[x[_x]]) == 'undefined' || typeof(map[x[_x]][_y]) == 'undefined') {
					this._load_map({x1 : x[0], x2 : x[1], y1 : y[0], y2 : y[1]});
					return;
				}
			}
		}
		
		// проверка слева направо
		for(_y = 0; _y <= 1; _y++) {
			//for (_x = x[0]; _y <= x[1]; _x++) {
			for (_x = x[0]; _x <= x[1]; _x++) {
				//if ((!map[_x]) || (!map[_x][y[_y]])) {
				if (typeof(map[_x]) == 'undefined' || typeof(map[_x][y[_y]]) == 'undefined') {
					this._load_map({x1 : x[0], x2 : x[1], y1 : y[0], y2 : y[1]});
					return;
				}
			}
		}
	},
	
	_render : function(buffer, buffer2, mouse) {
		this._checkMoveMap(mouse);
		
		if (this._rebuild_buffer) {
			// Перестраиваем буфер
			this._rebuild_buffer  = false;
			this._rebuild_buffer2 = false;
			
			this._rebuildBuffer(buffer);
			this._rebuildBuffer2(buffer2);
		} else if (this._rebuild_buffer2) {
			this._rebuild_buffer2 = false;
			this._rebuildBuffer2(buffer2);
		}
		
		return this._options.pos.offset;
	},
};

lib.inherit(WorldMap.Core, WorldMap.EventEmitter);

WorldMap.Resources = {
	_store : {},
	_loading : {},
	
	add: function(url, callback) {
		if (typeof url == 'object') {
			for(const [k, v] of Object.entries(url)) {
				this.add(v, callback || false);
			}
			return;
		}
		
		if (this._store[url]) {
			callback && callback();
			return;
		}
		
		if (!this._loading[url]) {
			var image = new Image();
			image.onload = function() {
				this._loading[url].forEach(function(v){
					v && v();
				});
				
				delete this._loading[url];
			}.bind(this);
			
			image.src = url;
			this._store[url] = image;
			this._loading[url] = [callback || false];
		} else if (callback) {
			this._loading[url].push(callback);
		}
	},
	
	get : function(url) {
		return this._store[url] || null;
	},
	
	has : function(url) {
		return !!this._store[url];
	},
};

WorldMap.Sprite = function(url, pos, size, speed, frames, dir, once) {
	this.pos = pos;
	this.size = size;
	this.speed = typeof speed === 'number' ? speed : 0;
	this.frames = frames;
	this._index = 0;
	this.url = url;
	this.dir = dir || 'horizontal';
	this.once = once;
};

WorldMap.Sprite.prototype = {
	update : function(dt) {
		this._index += this.speed*dt;
	},
	
	render : function(ctx) {
		var frame;
		
		if(this.speed > 0) {
			var max = this.frames.length;
			var idx = Math.floor(this._index);
			frame = this.frames[idx % max];
			
			if(this.once && idx >= max) {
				this.done = true;
				return;
			}
		}
		else {
			frame = 0;
		}
		
		var x = this.pos[0];
		var y = this.pos[1];
		
		if(this.dir == 'vertical') {
			y += frame * this.size[1];
		}
		else {
			x += frame * this.size[0];
		}
		
		ctx.drawImage(resources.get(this.url),
			x, y,
			this.size[0], this.size[1],
			0, 0,
			this.size[0], this.size[1]);
	},
};

export default class WorldMapModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		// Инициализация
		this.wm = WorldMap;
	}
	
	// actions
	actPendingAjaxRequest()
	{
		this.pendingAjaxRequestOpen = true;
		setTimeout(() => {
			this.pendingAjaxRequestOpen = false;
		}, 1000);
	}
}

import axios from 'axios';
import lib from '@/lib';

export default class TroopsModel
{
	// после отправки войск время для отмены 1 минута 30 секунд (кроме доставки ресурсов. там не отменить)
	
	static TRIBE_NATURE = 4; // (свое значение)
	
	static TYPE_SETTLER = 10;
	static TYPE_HERO = 11;
	
	static MOVEMENT_TYPE_ATTACK = 3;
	static MOVEMENT_TYPE_RAID = 4;
	static MOVEMENT_TYPE_SUPPORT = 5;
	static MOVEMENT_TYPE_SPY = 6;
	static MOVEMENT_TYPE_SETTLE = 10;
	static MOVEMENT_TYPE_SIEGE = 47;
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.show = true;
		
		this.movements = [
			{
				name: 'incoming_attacks',
				movementDirection: 'incoming',
				movementIcon: 'movement_attack_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 58) * 60) + 33) * 1000,
						timeFinish: ((((1 * 60) + 58) * 60) + 33) * 1000,
					},
					{
						time: ((((1 * 60) + 22) * 60) + 44) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>2 <span>Приближающиеся атаки</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th colspan="2"><span>Из:</span></th>'+
										'<td>Hersonsk</td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th><span>Через:</span></th>'+
										'<td><span>00:34:38</span></td>'+
										'<td><span>02:10:20</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th colspan="2"><span>Из:</span></th>'+
										'<td>Achtunsk</td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th><span>Через:</span></th>'+
										'<td><span>00:34:38</span></td>'+
										'<td><span>02:10:20</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'incoming_merchant',
				movementDirection: 'incoming',
				movementIcon: 'movement_trade_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span>Приближающиеся торговцы</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th>SVal</th>'+
										'<td><span>01:28:19</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Комендантский</th>'+
										'<td><span>23:31:37</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
							'<div class="costs max4digits">'+
								'<span class="resource type1">'+
									'<i class="unit_wood_small_illu resType1"></i>'+
									'<span class="resourceValue">0</span>'+
								'</span>'+
								'<span class="resource type2">'+
									'<i class="unit_clay_small_illu resType2"></i>'+
									'<span class="resourceValue">0</span>'+
								'</span>'+
								'<span class="resource type3">'+
									'<i class="unit_iron_small_illu resType3"></i>'+
									'<span class="resourceValue">300</span>'+
								'</span>'+
								'<span class="resource type4">'+
									'<i class="unit_crop_small_illu resType4"></i>'+
									'<span class="resourceValue">0</span>'+
								'</span>'+
							'</div>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'incoming_merchant',
				movementDirection: 'incoming',
				movementIcon: 'movement_trade_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span>Награда за краденые ценности</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th>Разбойник</th>'+
										'<td><span>00:13:45</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Логово разбойников</th>'+
										'<td><span>23:07:28</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
							'<div class="costs max4digits">'+
								'<span class="resource type1">'+
									'<i class="unit_wood_small_illu resType1"></i>'+
									'<span class="resourceValue">200</span>'+
								'</span>'+
								'<span class="resource type2">'+
									'<i class="unit_clay_small_illu resType2"></i>'+
									'<span class="resourceValue">150</span>'+
								'</span>'+
								'<span class="resource type3">'+
									'<i class="unit_iron_small_illu resType3"></i>'+
									'<span class="resourceValue">150</span>'+
								'</span>'+
								'<span class="resource type4">'+
									'<i class="unit_crop_small_illu resType4"></i>'+
									'<span class="resourceValue">0</span>'+
								'</span>'+
							'</div>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'return',
				movementDirection: 'incoming',
				movementIcon: 'movement_support_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>2 <span>Возвращающиеся войска</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th colspan="2"><span>Из:</span></th>'+
										'<td>Логово разбойников</td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th><span>Через:</span></th>'+
										'<td><span>00:05:37</span></td>'+
										'<td><span>20:48:37</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th colspan="2"><span>Из:</span></th>'+
										'<td>InZectorsk</td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th><span>Через:</span></th>'+
										'<td><span>00:09:42</span></td>'+
										'<td><span>20:52:42</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'return',
				movementDirection: 'incoming',
				movementIcon: 'movement_support_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span>Прибывающие подкрепления</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th>lexinzector</th>'+
										'<td><span>01:28:19</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Брест</th>'+
										'<td><span>23:31:37</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'incoming_defends',
				movementDirection: 'incoming',
				movementIcon: 'movement_defend_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'xxx'
				),
			},
			{
				name: 'incoming_heals',
				movementDirection: 'incoming',
				movementIcon: 'movement_heal_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					`<div class="troopOverviewTooltip">
						<h3>1 <span translate="" options="incoming_healing"><span>Возвращающиеся исцеленные войска</span></span></h3>
						<div ng-if="movementGroup.name == 'incoming_healing'" translate=""><span>Исцелено при помощи </span><i class="icon movement_heal_small_flat_black"></i></div>
						<span ng-if="movementGroup.length > 0">
							<span ng-repeat="idx in [0,1,2]" ng-show="$index<movementGroup.length">
								<div class="horizontalLine"></div>
								<table class="transparent">
									<tbody><tr>
										<th>lexinzector</th>
										<td><span countdown="1722762324">23:26:00</span></td>
									</tr>
									<tr class="village">
										<th>Брест</th>
										<td><span i18ndt="1722762324" format="mediumTime">14:05:24</span>
										</td>
									</tr>
								</tbody></table>
							</span>
							<span ng-repeat="idx in [0,1,2]" ng-show="$index<movementGroup.length" class="ng-hide">
								<div class="horizontalLine"></div>
								<table class="transparent">
									<tbody><tr>
										<th></th>
										<td><span countdown="">00:00:00</span></td>
									</tr>
									<tr class="village">
										<th></th>
										<td><span i18ndt="" format="mediumTime">Invalid Date</span>
										</td>
									</tr>
								</tbody></table>
							</span>
							<span ng-repeat="idx in [0,1,2]" ng-show="$index<movementGroup.length" class="ng-hide">
								<div class="horizontalLine"></div>
								<table class="transparent">
									<tbody><tr>
										<th></th>
										<td><span countdown="">00:00:00</span></td>
									</tr>
									<tr class="village">
										<th></th>
										<td><span i18ndt="" format="mediumTime">Invalid Date</span>
										</td>
									</tr>
								</tbody></table>
							</span>
						</span>
					</div>`
				),
			},
			{
				name: 'incoming_tributes',
				movementDirection: 'incoming',
				movementIcon: 'movement_tribute_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'xxx'
				),
			},
			{
				name: 'incoming_woundeds',
				movementDirection: 'incoming',
				movementIcon: 'movement_wounded_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>2 <span>Возвращающиеся раненые войска</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th>lexinzector</th>'+
										'<td><span>00:13:45</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Логово разбойников I</th>'+
										'<td><span>23:07:28</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
							'</table>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'outgoung_defends',
				movementDirection: 'outgoing',
				movementIcon: 'movement_defend_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'xxx'
				),
			},
			{
				name: 'outgoung_heals',
				movementDirection: 'outgoing',
				movementIcon: 'movement_heal_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'xxx'
				),
			},
			{
				name: 'outgoung_tributes',
				movementDirection: 'outgoing',
				movementIcon: 'movement_tribute_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'xxx'
				),
			},
			{
				name: 'outgoing_adventure',
				movementDirection: 'outgoing',
				movementIcon: 'movement_adventure_small_flat_black',
				movements: [
					{
						time: ((((1 * 48) + 0) * 60) + 21) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span>Приключение</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th></th>'+
										'<td><span>00:52:21</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Приключение</th>'+
										'<td><span>03:35:36</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'outgoing_attacks',
				movementDirection: 'outgoing',
				movementIcon: 'movement_attack_small_flat_black',
				movements: [
					{
						time: ((((1 * 41) + 0) * 60) + 0) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span>Исходящие атаки</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th>Природа</th>'+
										'<td><span>00:01:20</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Оазис (11|-24)</th>'+
										'<td><span>22:04:59</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'outgoing_attacks',
				movementDirection: 'outgoing',
				movementIcon: 'movement_raid_small_flat_black',
				movements: [
					{
						time: ((((1 * 41) + 0) * 60) + 0) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span>Исходящие атаки (набег)</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th>Природа</th>'+
										'<td><span>00:01:20</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Оазис (11|-24)</th>'+
										'<td><span>22:04:59</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'outgoing_attacks',
				movementDirection: 'outgoing',
				movementIcon: 'movement_spy_small_flat_black',
				movements: [
					{
						time: ((((1 * 13) + 0) * 60) + 47) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span>Исходящие атаки (разведка)</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th>Разбойник</th>'+
										'<td><span>00:01:20</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Логово разбойников</th>'+
										'<td><span>22:04:59</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'outgoing_attacks',
				movementDirection: 'outgoing',
				movementIcon: 'movement_support_small_flat_black',
				movements: [
					{
						time: ((((1 * 13) + 0) * 60) + 47) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span>Исходящие подкрепления</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th>lexinzector</th>'+
										'<td><span>00:01:20</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Зарница</th>'+
										'<td><span>22:04:59</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'outgoing_merchant',
				movementDirection: 'outgoing',
				movementIcon: 'movement_trade_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span>Отправленные торговцы</span></h3>'+
						'<span>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr>'+
										'<th>SVal</th>'+
										'<td><span>01:28:19</span></td>'+
									'</tr>'+
									'<tr class="village">'+
										'<th>Комендантский</th>'+
										'<td><span>23:31:37</span></td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
							'<div class="costs max4digits">'+
								'<span class="resource type1">'+
									'<i class="unit_wood_small_illu resType1"></i>'+
									'<span class="resourceValue">0</span>'+
								'</span>'+
								'<span class="resource type2">'+
									'<i class="unit_clay_small_illu resType2"></i>'+
									'<span class="resourceValue">300</span>'+
								'</span>'+
								'<span class="resource type3">'+
									'<i class="unit_iron_small_illu resType3"></i>'+
									'<span class="resourceValue">0</span>'+
								'</span>'+
								'<span class="resource type4">'+
									'<i class="unit_crop_small_illu resType4"></i>'+
									'<span class="resourceValue">0</span>'+
								'</span>'+
							'</div>'+
						'</span>'+
					'</div>'
				),
			},
			{
				name: 'outgoing_settlement',
				movementDirection: 'outgoing',
				movementIcon: 'movement_settle_small_flat_black',
				movements: [
					{
						time: ((((1 * 60) + 4) * 60) + 3) * 1000,
						timeFinish: Date.now(),
					},
				],
				tooltip: (
					'<div class="troopOverviewTooltip">'+
						'<h3>1 <span translate="" options="outgoing_settlement"><span>Основание новой деревни</span></span></h3>'+
						'<span ng-if="movementGroup.length > 0">'+
							'<span ng-repeat="idx in [0,1,2]" ng-show="$index<movementGroup.length">'+
								'<div class="horizontalLine"></div>'+
								'<table class="transparent">'+
									'<tbody>'+
										'<tr>'+
											'<th></th>'+
											'<td><span countdown="1718614234">00:25:56</span></td>'+
										'</tr>'+
										'<tr class="village">'+
											'<th>(-12|-19)</th>'+
											'<td><span i18ndt="1718614234" format="mediumTime">13:50:34</span></td>'+
										'</tr>'+
									'</tbody>'+
								'</table>'+
							'</span>'+
						'</span>'+
					'</div>'
				),
			},
		];
		
		this.stationed = [
			{
				open: false,
				name: 'Отряды',
				nation: 'gaul',
				icon: 'tribe_gaul_large_illu',
				count: 14,
				units: [
					{type: 1, count: 50, tooltip: 
`<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Дубинщик</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType1" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<div class="amount" ng-if="unitId != Troops.TYPE_HERO">162</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`}, // дубинщик
					{type: 2, count: 50, tooltip: 
`<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Копейщик</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType2" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<div class="amount" ng-if="unitId != Troops.TYPE_HERO">162</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`}, // копейщик
					{type: 3, count: 50, tooltip: 
`<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Мечник</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType3" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<div class="amount" ng-if="unitId != Troops.TYPE_HERO">162</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`}, // мечник
					{type: 4, count: 50, tooltip: 
`<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Топорщик</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType4" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<div class="amount" ng-if="unitId != Troops.TYPE_HERO">162</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`}, // топорщик
				],
			},
			{
				open: false,
				name: 'Корабли',
				nation: 'nature',
				icon: 'tribe_nature_large_illu',
				count: 14,
				units: [
					{type: 1, count: 2, tooltip: 
`<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Шлюп</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType1" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<div class="amount" ng-if="unitId != Troops.TYPE_HERO">162</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`}, // шлюп
					{type: 2, count: 3, tooltip: 
`<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Баркас</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType2" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<div class="amount" ng-if="unitId != Troops.TYPE_HERO">162</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`}, // баркас
					{type: 3, count: 1, tooltip: 
`<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Галера</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType3" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<div class="amount" ng-if="unitId != Troops.TYPE_HERO">162</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`}, // галера
					{type: 4, count: 1, tooltip: 
`<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Фрегат</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium nature unitType4" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<div class="amount" ng-if="unitId != Troops.TYPE_HERO">162</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`}, // фрегат
				],
			},
			/*{
				open: false,
				name: 'Галлы',
				nation: 'gaul',
				icon: 'tribe_gaul_large_illu',
				count: 14,
				units: [
					{type: 0, count: '', tooltip: ''},
					{type: 1, count: 6, tooltip: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="22"><span>Мечник</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium gaul unitType2" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<div class="unitAttributes" ng-if="unitId != Troops.TYPE_HERO">
			<span><i class="movement_attack_small_flat_black"></i>65</span>
			<span><i class="unit_defenseInfantry_small_flat_black"></i>35</span>
			<br>
			<span><i class="unit_defenseCavalry_small_flat_black"></i>20</span>
			<span><i class="unit_capacity_small_flat_black"></i>45</span>
		</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<div class="amount" ng-if="unitId != Troops.TYPE_HERO">162</div>
		<!-- ngIf: unitId == Troops.TYPE_HERO -->
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`},
					{type: 2, count: 7, tooltip: ''},
					{type: 3, count: '', tooltip: ''},
					{type: 4, count: '', tooltip: ''},
					{type: 5, count: '', tooltip: ''},
					{type: 6, count: '', tooltip: ''},
					{type: 7, count: '', tooltip: ''},
					{type: 8, count: '', tooltip: ''},
					{type: 9, count: '', tooltip: ''},
					{type: 10, count: '', tooltip: ''},
				],
			},
			{
				open: false,
				name: 'Римляне',
				nation: 'roman',
				icon: 'tribe_roman_large_illu',
				count: 14,
				units: [
					{type: 0, count: '', tooltip: ''},
					{type: 1, count: 6, tooltip: ''},
					{type: 2, count: 7, tooltip: ''},
					{type: 3, count: '', tooltip: ''},
					{type: 4, count: '', tooltip: ''},
					{type: 5, count: '', tooltip: ''},
					{type: 6, count: '', tooltip: ''},
					{type: 7, count: '', tooltip: ''},
					{type: 8, count: '', tooltip: ''},
					{type: 9, count: '', tooltip: ''},
					{type: 10, count: '', tooltip: ''},
				],
			},
			{
				open: false,
				name: 'Тевтонцы',
				nation: 'teuton',
				icon: 'tribe_teuton_large_illu',
				count: 14,
				units: [
					{type: 0, count: '', tooltip: ''},
					{type: 1, count: 6, tooltip: ''},
					{type: 2, count: 7, tooltip: ''},
					{type: 3, count: '', tooltip: ''},
					{type: 4, count: '', tooltip: ''},
					{type: 5, count: '', tooltip: ''},
					{type: 6, count: '', tooltip: ''},
					{type: 7, count: '', tooltip: ''},
					{type: 8, count: '', tooltip: ''},
					{type: 9, count: '', tooltip: ''},
					{type: 10, count: '', tooltip: ''},
				],
			},
			{
				open: false,
				name: 'Природа',
				nation: 'nature',
				icon: 'tribe_nature_large_illu',
				count: 14,
				units: [
					{type: 0, count: '', tooltip: ''},
					{type: 1, count: 6, tooltip: ''},
					{type: 2, count: 7, tooltip: ''},
					{type: 3, count: '', tooltip: ''},
					{type: 4, count: '', tooltip: ''},
					{type: 5, count: '', tooltip: ''},
					{type: 6, count: '', tooltip: ''},
					{type: 7, count: '', tooltip: ''},
					{type: 8, count: '', tooltip: ''},
					{type: 9, count: '', tooltip: ''},
					{type: 10, count: '', tooltip: ''},
				],
			},
			{
				open: false,
				name: 'Натары',
				nation: 'natar',
				icon: 'tribe_natar_large_illu',
				count: 14,
				units: [
					{type: 0, count: '', tooltip: ''},
					{type: 1, count: 6, tooltip: ''},
					{type: 2, count: 7, tooltip: ''},
					{type: 3, count: '', tooltip: ''},
					{type: 4, count: '', tooltip: ''},
					{type: 5, count: '', tooltip: ''},
					{type: 6, count: '', tooltip: ''},
					{type: 7, count: '', tooltip: ''},
					{type: 8, count: '', tooltip: ''},
					{type: 9, count: '', tooltip: ''},
					{type: 10, count: '', tooltip: ''},
				],
			},*/
		];
	}
}

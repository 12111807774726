<template>
	<div class="kingdom" ng-controller="kingdomCtrl">
		<div tabulation="" tab-config-name="kingdomTabConfig">
			<nav class="tabulation subtab unselectable" ng-if="tabData.tabs.length > 1">
				<a class="tab clickable" :class="{active: tab == 'profile', inactive: tab != 'profile'}" @click="onToggleTab('profile')">
					<div class="content">
						<span>Профиль</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'intern', inactive: tab != 'intern'}" @click="onToggleTab('intern')">
					<div class="content">
						<span>События</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'ranking', inactive: tab != 'ranking'}" @click="onToggleTab('ranking')">
					<div class="content">
						<span>Рейтинг</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'tributes', inactive: tab != 'tributes'}" @click="onToggleTab('tributes')">
					<div class="content">
						<span>Дань</span>
					</div>
				</a>
			</nav>
			<div class="tabulationContent scrollable tabContentTributes">
				<div class="scrollContentOuterWrapper">
					<div class="scrollContent" dynamic-height="true" style="overflow-y: auto;">
						<div class="scrollContentInnerWrapper" style="width: auto;">
							<div ng-transclude="">
								<div class="loadedTab currentTab" v-if="tab == 'profile'">
									<profile />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'intern'">
									<intern />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'ranking'">
									<ranking />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'tributes'">
									<tributes />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="error"></div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import Profile from '@/components/pages/society/kingdomIn/Profile';
import Intern from '@/components/pages/society/kingdomIn/Intern';
import Ranking from '@/components/pages/society/kingdomIn/Ranking';
import Tributes from '@/components/pages/society/kingdomIn/Tributes';

export default {
	mixins: lib.mixins,
	data: () => ({
		tab: 'profile',
	}),
	computed: {},
	methods: {
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_10.ogg');
				audio.play();
			}
		},
	},
	components: {
		Profile,
		Intern,
		Ranking,
		Tributes,
	},
}
</script>
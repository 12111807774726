<template>
	<div class="reportBody farmlistReport" src="'tpl/report/types/'+reportType+'.html'">
		<div class="troopsScrollContainer">
			<div class="infoContainer">
				<div class="resourcesModule">
					<span class="caption" translate=""><span>Общая добыча</span></span>
					<span class="loot">
						<i class="carry unit_capacityEmpty_small_flat_black unit_capacity_small_flat_black" ng-class="{
								unit_capacityEmpty_small_flat_black: lootedResSum == 0,
								unit_capacityHalf_small_flat_black: lootedResSum > 0 && lootedResSum < carryCapacity,
								unit_capacity_small_flat_black: lootedResSum == carryCapacity
						}" tooltip="" tooltip-translate="Report.CarryCapacity" tooltip-placement="above"></i>
						0/0
					</span>
					<div class="bountyContainer">
						<span class="resources">
							<span ng-repeat="n in []|range:1:4">
								<i class="unit_wood_small_illu"></i>
								0
							</span>
							<span ng-repeat="n in []|range:1:4">
								<i class="unit_clay_small_illu"></i>
								0
							</span>
							<span ng-repeat="n in []|range:1:4">
								<i class="unit_iron_small_illu"></i>
								0
							</span>
							<span ng-repeat="n in []|range:1:4">
								<i class="unit_crop_small_illu"></i>
								0
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="troopsDetailContainer">
				<div class="troopsDetailHeader fromHeader">
					<i class="reportIcon reportTypes_reportIcon_flat"></i>
					<span translate=""><span>Нападение</span></span>:
					<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" ng-if="header.sourcePlayerId != 1">lexinzector</a>
					<!-- ngIf: header.sourcePlayerId == 1 -->
					<span translate=""><span>из</span></span>
					<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264692}, null, true)" village-link="" villageid="536264692" villagename="Зарница">Зарница</a>
					<div ng-if="header.sourceId > 2" class="iconButton centerMapButton clickable" tooltip="" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '536264692_1722019894.9'); closeWindow(w.name);">
						<i class="symbol_target_small_flat_black"></i>
					</div>
				</div>
				<div troops-details="" troop-data="troopDetails">
					<table class="troopsTable gaul">
						<tbody class="troopsIconRow">
							<tr>
								<td ng-repeat="n in []|range:1:11" class="unit1">
									<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit2">
									<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit3">
									<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit4">
									<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit5">
									<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit6">
									<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit7">
									<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit8">
									<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit9">
									<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit10">
									<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="hero">
									<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<!-- ngIf: troopsType == 'trapped' -->
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">1</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
							</tr>
						</tbody>
						<!-- ngIf: troopData.supply -->
						<!-- ngIf: troopData.cagedAnimals -->
					</table>
				</div>
			</div>
			<div class="troopsDetailContainer">
				<div class="troopsDetailHeader toHeader">
					<i class="reportIcon reportTypes_reportIcon7_flat"></i>
					<span translate=""><span>Цели</span></span>:
				</div>
				<table class="farmlistReportTable">
					<thead>
						<tr>
							<th><i class="movement_attack_small_flat_black"></i></th>
							<th><i class="secretSociety_troopsProvided_small_flat_black"></i></th>
							<th><i class="secretSociety_troopsLost_small_flat_black"></i></th>
							<th translate=""><span>Деревня</span></th>
							<th translate=""><span>Прибытие</span></th>
							<th><i class="unit_capacity_small_flat_black"></i></th>
						</tr>
					</thead>
					<tbody>
						<tr ng-repeat="target in body.targets" ng-class="{highlighted: highlight == target.destVillageId}">
							<td>
								<div ng-if="target.arrivalTime < $root.currentServerTime">
									<i ng-if="target.sumDied == 0" class="reportIcon reportTypes_reportIcon1_flat"></i>
									<!-- ngIf: target.sumDied > 0 && target.sumDied < target.sumSent -->
									<!-- ngIf: target.sumDied >= target.sumSent -->
								</div>
							</td>
							<td tooltip="" tooltip-url="tpl/report/partials/farmlistSentTroops.html">1</td>
							<td>
								<!-- ngIf: target.sumDied > 0 -->
								<span ng-if="target.sumDied <= 0" tooltip="" tooltip-url="tpl/report/partials/farmlistDiedTroops.html">-</span>
							</td>
							<td>
								<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536428530}, null, true)" village-link="" villageid="536428530" villagename="">Оазис (-14|-14)</a>
							</td>
							<td ng-if="target.arrivalTime < $root.currentServerTime && target.arrivalTime > 0">
								<span i18ndt="1722010341" format="medium">Сегодня 21:12:21</span>
							</td>
							<!-- ngIf: target.arrivalTime >= $root.currentServerTime && target.arrivalTime > 0 -->
							<!-- ngIf: target.arrivalTime < 0 -->
							<!-- ngIf: target.arrivalTime == 0 -->
							<td>
								<i class="carry unit_capacityEmpty_small_flat_black" ng-if="target.arrivalTime < $root.currentServerTime && target.arrivalTime > 0" ng-class="{
									unit_capacityEmpty_small_flat_black: target.lootedResSum == 0,
									unit_capacityHalf_small_flat_black: target.lootedResSum > 0 && target.lootedResSum < target.carryCapacity,
									unit_capacity_small_flat_black: target.lootedResSum > 0 && target.lootedResSum == target.carryCapacity
								}" tooltip="" tooltip-url="tpl/report/partials/farmlistResources.html"></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		report: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		range: lib.range,
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
	},
	components: {},
}
</script>
<template>
	<div ng-show="loggedIn">
		<!--
		<div class="questCurrentTask" ng-if="activeQuest === 0">
			<div class="background">
				<div class="headerBackgroundDecoration start"></div>
				<div class="headerBackgroundDecoration end"></div>
				<div class="content">
					<div class="header">
						<div class="inner" translate=""><span>Нажми для продолжения</span></div>
					</div>
					<div class="subquests">
						<div class="subquest">
							<span class="inner" translate=""><span>Для продолжения разговора щелкни левой кнопкой мыши в любом месте экрана.</span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="questCurrentTask" ng-if="activeQuest != null">
			<div class="background">
				<div class="headerBackgroundDecoration start"></div>
				<div class="headerBackgroundDecoration end"></div>
				<div class="content">
					<div class="header">
						<div class="inner" translate="" options="1"><span>Освободи деревню</span></div>
						<div class="smallArrow"></div>
						<div class="doneMarker tutorial_circle_illustration visible" ng-class="{visible: activeQuest.data.status >= 4}">
							<div class="checkMark tutorial_check_illustration"></div>
						</div>
					</div>
					<div class="subquests" ng-if="activeQuest.data.cfg.subSteps">
						<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
							<div class="currentStep" ng-if="activeQuest.data.progress < step.progress &amp;&amp; ($index == 0 || activeQuest.data.progress >= activeQuest.data.cfg.subSteps[$index-1].progress)">
							</div>
							<span class="inner" translate="" options="1_1"><span>Атакуй занятую деревню</span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="questCurrentTask" ng-if="activeQuest != null">
			<div class="background">
				<div class="headerBackgroundDecoration start"></div>
				<div class="headerBackgroundDecoration end"></div>
				<div class="content">
					<div class="header">
						<div class="inner" translate="" options="30"><span>Защити деревню</span></div>
						<div class="smallArrow"></div>
						<div class="doneMarker tutorial_circle_illustration" ng-class="{visible: activeQuest.data.status >= 4}">
							<div class="checkMark tutorial_check_illustration"></div>
						</div>
					</div>
					<div class="subquests" ng-if="activeQuest.data.cfg.subSteps">
						<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
							<i class="action_check_medium_flat_orange" ng-if="activeQuest.data.progress >= step.progress"></i>
							<span class="inner" translate="" options="30_1_3"><span>Построй изгородь</span></span>
						</div>
						<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest inactive" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
							<i class="action_check_medium_flat_black disabled" ng-class="{disabled: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}" ng-if="activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress"></i>
							<span class="inner" translate="" options="30_2"><span>Построй казарму</span></span>
						</div>
						<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest inactive" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
							<i class="action_check_medium_flat_black disabled" ng-class="{disabled: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}" ng-if="activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress"></i>
							<span class="inner" translate="" options="30_3_3"><span>Обучи три фаланги</span></span>
						</div>
						<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest inactive" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
							<i class="action_check_medium_flat_black disabled" ng-class="{disabled: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}" ng-if="activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress"></i>
							<span class="inner" translate="" options="30_4"><span>Отрази атаку</span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="questCurrentTask" ng-if="activeQuest != null">
			<div class="background">
				<div class="headerBackgroundDecoration start"></div>
				<div class="headerBackgroundDecoration end"></div>
				<div class="content">
					<div class="header">
						<div class="inner" translate="" options="34"><span>Увековечить себя</span></div>
						<div class="smallArrow"></div>
						<div class="doneMarker tutorial_circle_illustration visible" ng-class="{visible: activeQuest.data.status >= 4}">
							<div class="checkMark tutorial_check_illustration"></div>
						</div>
					</div>
					<div class="subquests" ng-if="activeQuest.data.cfg.subSteps">
						<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
							<i class="action_check_medium_flat_orange" ng-if="activeQuest.data.progress >= step.progress"></i>
							<span class="inner" translate="" options="34_1"><span>Создай свою внешность</span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		-->
		<!--
		<div ng-include="" src="'tpl/mainlayout/questCurrentTask.html'" ng-show="loggedIn">
			<div class="questCurrentTask" ng-if="activeQuest != null">
				<div class="background">
					<div class="headerBackgroundDecoration start"></div>
					<div class="headerBackgroundDecoration end"></div>
					<div class="content">
						<div class="header">
							<div class="inner" translate="" options="302"><span>Победи разбойников</span></div>
							<div class="smallArrow"></div>
							<div class="doneMarker tutorial_circle_illustration" ng-class="{visible: activeQuest.data.status >= 4}">
								<div class="checkMark tutorial_check_illustration"></div>
							</div>
						</div>
						<div class="subquests" ng-if="activeQuest.data.cfg.subSteps">
							<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
								<div class="currentStep" ng-if="activeQuest.data.progress < step.progress &amp;&amp; ($index == 0 || activeQuest.data.progress >= activeQuest.data.cfg.subSteps[$index-1].progress)">
								</div>
								<span class="inner" translate="" options="302_1"><span>Найди разбойников на карте</span></span>
							</div>
							<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest inactive" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
								<i class="action_check_medium_flat_black disabled" ng-class="{disabled: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}" ng-if="activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress"></i>
								<span class="inner" translate="" options="302_2"><span>Отправь войска в логово разбойников</span></span>
							</div>
							<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest inactive" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
								<i class="action_check_medium_flat_black disabled" ng-class="{disabled: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}" ng-if="activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress"></i>
								<span class="inner" translate="" options="302_3"><span>Подожди возвращения войск</span></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		-->
		<!--
		<div ng-include="" src="'tpl/mainlayout/questCurrentTask.html'" ng-show="loggedIn">
			<div class="questCurrentTask" ng-if="activeQuest != null">
				<div class="background">
					<div class="headerBackgroundDecoration start"></div>
					<div class="headerBackgroundDecoration end"></div>
					<div class="content">
						<div class="header">
							<div class="inner" translate="" options="303"><span>Усиление королевства</span></div>
							<div class="smallArrow"></div>
							<div class="doneMarker tutorial_circle_illustration" ng-class="{visible: activeQuest.data.status >= 4}">
								<div class="checkMark tutorial_check_illustration"></div>
							</div>
						</div>
						<div class="subquests" ng-if="activeQuest.data.cfg.subSteps">
							<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
								<div class="currentStep" ng-if="activeQuest.data.progress < step.progress &amp;&amp; ($index == 0 || activeQuest.data.progress >= activeQuest.data.cfg.subSteps[$index-1].progress)">
								</div>
								<span class="inner" translate="" options="303_1"><span>Продай краденые ценности королю</span></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		-->
		<!--
		<div ng-include="" src="'tpl/mainlayout/questCurrentTask.html'" ng-show="loggedIn">
			<div class="questCurrentTask" ng-if="activeQuest != null" style="display: block;">
				<div class="background">
					<div class="headerBackgroundDecoration start"></div>
					<div class="headerBackgroundDecoration end"></div>
					<div class="content">
						<div class="header">
							<div class="inner" translate="" options="399"><span>Доступные задания</span></div>
							<div class="smallArrow"></div>
							<div class="doneMarker tutorial_circle_illustration" ng-class="{visible: activeQuest.data.status >= 4}">
								<div class="checkMark tutorial_check_illustration"></div>
							</div>
						</div>
						<div class="subquests" ng-if="activeQuest.data.cfg.subSteps">
							<div ng-repeat="step in activeQuest.data.cfg.subSteps" class="subquest" ng-class="{inactive: activeQuest.data.progress < activeQuest.data.cfg.subSteps[$index-1].progress}">
								<div class="currentStep" ng-if="activeQuest.data.progress < step.progress &amp;&amp; ($index == 0 || activeQuest.data.progress >= activeQuest.data.cfg.subSteps[$index-1].progress)">
								</div>
								<span class="inner" translate="" options="399_1"><span>Открой книгу заданий</span></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		-->
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
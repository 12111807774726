<template>
	<div ng-if="overlayController" class="windowOverlay" id="orderHistory" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="572"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Shop.Popup.OrderHistory.Header" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>История заказов</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="orderHistory scrollable" ng-class="{shopLoading: loading}" scrollable=""><div class="scrollContentOuterWrapper" style="width: 876px;">
	<div class="scrollContent" dynamic-height="false" style="width: 876px; overflow-y: hidden;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
	<div class="noOrders">Вы еще ничего не купили.</div>
</div>
	</div>
</div>
</div></div>
	</div>
</div></div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="mainContentWrapper" v-if="!gameModel.loadingScreenOpen">
		<header id="layoutHeader">
			<div class="ornaments"></div>
			<quick-links v-if="gameModel.quickLinksShow" />
			<village-list v-if="gameModel.villageListShow" />
			<sidebar-menu v-if="gameModel.SidebarMenu.show" />
			<top-menu v-if="gameModel.TopMenu.show" />
			<stock-bar v-if="gameModel.StockBar.show" />
			<user-area v-if="gameModel.userAreaShow" />
			<hero-quick-info v-if="gameModel.heroQuickInfoShow" />
		</header>
		<quest-current-task v-if="gameModel.questCurrentTaskShow" />
		<div class="ingameContentContainer">
			<div>
				<router-view/>
			</div>
			<building-queue v-if="gameModel.buildingQueue.show" />
		</div>
		<modals-container />
		<notepads v-if="gameModel.notepadsShow" />
		<troops-overview v-if="gameModel.troopsOverviewShow" />
		<flash-notifications v-if="gameModel.flashNotifications.show" />
		<div>
			<div id="footer">
				<timed-notifications
					v-if="gameModel.timedNotifications.show"
					:timedNotifications="gameModel.timedNotifications.list"
				/>
				<notifications v-if="gameModel.notifications.show" />
				<help-notification
					v-if="gameModel.helpNotificationsShow"
				/>
				
				<chat-footer-bar v-if="gameModel.chatFooterBarShow" />
				
				<time-type-selector v-if="gameModel.settings.timeTypeSwitcher" />
				<server-time v-if="gameModel.serverTimeShow" />
			</div>
		</div>
		<context-menu v-if="gameModel.contextMenuShow" />
	</div>
	<loading-screen />
	<window-loading />
	<pending-ajax-request />
	<div id="jqFensterModalLayout" class="jqFensterModal">
		<div class="jqFensterModalContent"></div>
	</div>
	<div :class="['tutorialArrow', gameModel.tutorialArrow.side, 'tutorial_arrow'+gameModel.tutorialArrow.side[0].toUpperCase()+gameModel.tutorialArrow.side.slice(1)+'_large_illu']" :style="{opacity: 1, left: gameModel.tutorialArrow.left+'px', top: gameModel.tutorialArrow.top+'px', zIndex: 4060}" v-if="gameModel.tutorialArrow.show"></div>
	<!--<div class="tutorialArrow up  tutorial_arrowUp_large_illu" style="opacity: 1; left: 20px; top: 183px; z-index: 4060;"></div>-->
	<!--<v-dialog />-->
</template>

<style lang="css">
.v--modal {
	background-color: inherit !important;
    border-radius: inherit !important;
    box-shadow: inherit !important;
}

#noty_layout__bottomRight{
	bottom: 80px;
    right: 15px;
	width: 400px;
}
.noty_theme__semanticui.noty_bar{
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

::-webkit-scrollbar{
	width: 6px;
}
::-webkit-scrollbar:before{
	content: '';
    position: relative;
    display: inline-block;
    /*float: left;*/
    height: 100%;
    width: 100%;
    background-color: #dad8d5
}
::-webkit-scrollbar-track{
	background: #dad8d5;
}
::-webkit-scrollbar-thumb{
	background: #a5c400;
}
::-webkit-scrollbar-thumb:hover{
	background: #a5c400;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import QuickLinks from '@/components/QuickLinks';
import VillageList from '@/components/VillageList';
import SidebarMenu from '@/components/SidebarMenu';
import TopMenu from '@/components/TopMenu';
import StockBar from '@/components/StockBar';
import UserArea from '@/components/UserArea';
import HeroQuickInfo from '@/components/HeroQuickInfo';
import QuestCurrentTask from '@/components/QuestCurrentTask';
import BuildingQueue from '@/components/BuildingQueue';
import Notepads from '@/components/Notepads';
import TroopsOverview from '@/components/TroopsOverview';
import FlashNotifications from '@/components/FlashNotifications';
import TimedNotifications from '@/components/TimedNotifications';
import Notifications from '@/components/Notifications';
import HelpNotification from '@/components/HelpNotification';
import ChatFooterBar from '@/components/chat/ChatFooterBar';
import TimeTypeSelector from '@/components/TimeTypeSelector';
import ServerTime from '@/components/ServerTime';
import ContextMenu from '@/components/ContextMenu';
import LoadingScreen from '@/components/LoadingScreen';
import WindowLoading from '@/components/WindowLoading';
import PendingAjaxRequest from '@/components/PendingAjaxRequest';

import { ModalsContainer } from 'vue-final-modal';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'IW'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		async loadData(){
			//var noty = this.$noty;
			
			this.gameModel.pendingAjaxRequestOpen = true;
			this.gameModel.windowLoadingOpen = true;
			
			this.gameModel.loadingScreenOpen = true;
			
			await this.gameModel.loadStartInfo();
			
			setTimeout(async () => {
				this.gameModel.loadingScreenOpen = false;
				
				/*noty.show("Hello world!", 'alert', {
					timeout: 6000,
					progressBar: true,
				});*/
				//noty.success("Your profile has been saved!", {});
				//noty.error("Oops, something went wrong!", {});
				//noty.warning("Please review your information.", {});
				//noty.info("New version of the app is available!", {});
			}, 4000);
		},
		
		startPendingAjaxRequest(){
			this.gameModel.actPendingAjaxRequest();
			
			//TODO: подгрузка данных для корректировки. в том числе: api/data/heck-loze-island/{idIsland}
			
			setTimeout(() => {
				this.startPendingAjaxRequest();
			}, 5000);
		},
		startChangeResources(){
			if(this.gameModel.island.storage.woodCount > 0){
				this.gameModel.actWoodCountMinus(this.gameModel.island.storage.woodProduction);
			} else {
				this.gameModel.actWoodCountPlus(this.gameModel.island.storage.woodProduction);
			}
			
			if(this.gameModel.island.storage.clayCount < this.gameModel.island.storage.clayCountMax){
				this.gameModel.actClayCountPlus(this.gameModel.island.storage.clayProduction);
			} else {
				this.gameModel.actClayCountMinus(this.gameModel.island.storage.clayProduction);
			}
			
			if(this.gameModel.island.storage.rockCount > 0){
				this.gameModel.actRockCountMinus(this.gameModel.island.storage.rockProduction);
			} else {
				this.gameModel.actRockCountPlus(this.gameModel.island.storage.rockProduction);
			}
			
			if(this.gameModel.island.storage.ironCount > 0){
				this.gameModel.actIronCountMinus(this.gameModel.island.storage.ironProduction);
			} else {
				this.gameModel.actIronCountPlus(this.gameModel.island.storage.ironProduction);
			}
			
			if(this.gameModel.island.storage.cropCount < this.gameModel.island.storage.cropCountMax){
				this.gameModel.actCropCountPlus(Math.abs(this.gameModel.island.storage.cropProduction));
			} else {
				this.gameModel.actCropCountMinus(Math.abs(this.gameModel.island.storage.cropProduction));
			}
			
			if(this.gameModel.player.gold < 2500){
				this.gameModel.actGoldPlus(100);
			} else {
				this.gameModel.actGoldMinus(100);
			}
			
			if(this.gameModel.player.silver < 2500){
				this.gameModel.actSilverPlus(100);
			} else {
				this.gameModel.actSilverMinus(100);
			}
			
			if(this.gameModel.player.bronze < 2500){
				this.gameModel.actBronzePlus(100);
			} else {
				this.gameModel.actBronzeMinus(100);
			}
			
			this.gameModel.actChangeAnimateIgm();
			
			setTimeout(() => {
				this.startChangeResources();
			}, 2000);
		},
	},
	created(){
		this.loadData();
		this.startPendingAjaxRequest();
		//this.startChangeResources();
		
		//this.gameModel.modalOpen = true;
		
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.authModel.doLogout();
					window.location = 'https://iwpanel.g.bsrv.su';
				}
				throw err;
			});
		});
	},
	/*watch: {
		dataToBeWatched(val){
			if(val){
				this.makeSmthWhenDataChanged();
			}
		}
	},*/
	components: {
		QuickLinks,
		VillageList,
		SidebarMenu,
		TopMenu,
		StockBar,
		UserArea,
		HeroQuickInfo,
		QuestCurrentTask,
		BuildingQueue,
		Notepads,
		TroopsOverview,
		FlashNotifications,
		TimedNotifications,
		Notifications,
		HelpNotification,
		ChatFooterBar,
		TimeTypeSelector,
		ServerTime,
		ContextMenu,
		LoadingScreen,
		WindowLoading,
		PendingAjaxRequest,
		
		ModalsContainer,
	},
}
</script>

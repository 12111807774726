<template>
	<div ng-include="" src="'tpl/hero/tabs/auctions/ExchangeOffice.html'" style="width: 686px;"><!-- ngIf: player.data.deletionTime > 0 -->
<!-- ngIf: player.data.deletionTime == 0 --><div class="contentBox exchangeOffice" ng-controller="exchangeOfficeCtrl" ng-if="player.data.deletionTime == 0">
	<div class="contentBoxBody">
		<div class="description"><span>В пункте обмена ты можешь обменять серебро на золото и наоборот. Введи количество, которое ты хочешь поменять, или используй бегунок, затем нажми "Обменять".</span></div>
		<div class="column silverToGold">
			<h6 class="headerWithIcon arrowDirectionTo">
				<i class="unit_silver_medium_illu"></i>
				<i class="unit_gold_medium_illu"></i>
				<span><span>Серебро на золото</span></span>
			</h6>
			<div class="columnContent">
				<div class="exchangeRate">
					<span class="unimportant">÷ 200 =</span>
					<i class="unit_gold_small_illu"></i> 1
				</div>
				<slider class="exchangeSlider" ng-class="{noSteps: exchangeSilver.getMaxAmount() > 10000}" icon-class="unit_silver_small_illu" slider-min="0" slider-max="exchangeSilver.getMaxAmount()" slider-steps="exchangeSilver.getRate()" slider-data="exchangeSilver.amount" slider-lock="exchangeSilver.getRate() > exchangeSilver.getMaxAmount()" slider-show-max-button="false"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
	<div class="trackContainer">
		<div class="track">
			<!-- ngIf: stepCount && !hideSteps --><div ng-if="stepCount && !hideSteps" class="stepContainer">
				<!-- ngRepeat: n in [] | range:0:stepCount --><div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 0%;"></div><!-- end ngRepeat: n in [] | range:0:stepCount --><div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 20%;"></div><!-- end ngRepeat: n in [] | range:0:stepCount --><div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 40%;"></div><!-- end ngRepeat: n in [] | range:0:stepCount --><div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 60%;"></div><!-- end ngRepeat: n in [] | range:0:stepCount --><div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 80%;"></div><!-- end ngRepeat: n in [] | range:0:stepCount --><div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 100%;"></div><!-- end ngRepeat: n in [] | range:0:stepCount -->
			</div><!-- end ngIf: stepCount && !hideSteps -->
			<div class="sliderMarker progressMarker" style="width: 20%;"></div>
			<!-- ngRepeat: marker in sliderMarkers track by $index -->
			<div class="hoverIndicator">
				<div class="hoverValue"></div>
				<div class="indicator"></div>
			</div>
			<div class="handle unselectable" style="left: 59px;"></div>
		</div>
	</div>
	<div class="inputContainer">
		<!-- ngIf: iconClass --><i class="unit_silver_small_illu" ng-if="iconClass"></i><!-- end ngIf: iconClass -->
		<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
		<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
			<i class="action_setMax_small_flat_black"></i>
		</div>
	</div>
</div></slider>
				<button ng-class="{disabled: exchangeSilver.result == 0 || exchangeSilver.amount.value == 0}" premium-feature="exchangeOffice" premium-callback-param="silver" price="0" class="animate exchangeSubmit free" tooltip="" tooltip-translate="ExchangeOffice.exchangeSilverToGoldNow"><div class="content">
	<span ng-transclude="" class="innerText">
				<span><span>Обменять</span></span>
				</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
			</div>
		</div>
		<div class="column goldToSilver">
			<h6 class="headerWithIcon arrowDirectionTo">
				<i class="unit_gold_medium_illu"></i>
				<i class="unit_silver_medium_illu"></i>
				<span><span>Золото на серебро</span></span>
			</h6>
			<div class="columnContent">
				<div class="exchangeRate">
					<span class="unimportant">× 100 =</span>
					<i class="unit_silver_small_illu"></i> 100
				</div>
				<slider class="exchangeSlider" icon-class="unit_gold_small_illu" slider-min="0" slider-max="exchangeGold.getMaxAmount()" slider-data="exchangeGold.amount" slider-show-max-button="false" input-autofocus="true"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
	<div class="trackContainer">
		<div class="track">
			<!-- ngIf: stepCount && !hideSteps -->
			<div class="sliderMarker progressMarker" style="width: 2.5%;"></div>
			<!-- ngRepeat: marker in sliderMarkers track by $index -->
			<div class="hoverIndicator">
				<div class="hoverValue"></div>
				<div class="indicator"></div>
			</div>
			<div class="handle unselectable" style="left: 7.35px;"></div>
		</div>
	</div>
	<div class="inputContainer">
		<!-- ngIf: iconClass --><i class="unit_gold_small_illu" ng-if="iconClass"></i><!-- end ngIf: iconClass -->
		<input class="value ng-pristine ng-valid ng-touched" number="" ng-model="value" type="tel">
		<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
			<i class="action_setMax_small_flat_black"></i>
		</div>
	</div>
</div></slider>
				<button ng-class="{disabled: exchangeGold.amount.value == 0}" class="animate exchangeSubmit premium" premium-feature="exchangeOffice" premium-callback-param="gold" tooltip="" force-gold-usage="true" price="1" tooltip-translate="ExchangeOffice.exchangeGoldToSilverNow"><div class="content">
	<span ng-transclude="" class="innerText">
				<span><span>Обменять</span></span>
				</span>
	<!-- ngIf: price > 0 || forceGoldUsage --><span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>1</span><!-- end ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
			</div>
		</div>
	</div>
	<div class="contentBoxFooter ng-hide" ng-show="exchangeSuccess.message !== ''">
		<div class="success">
			<span data="silver:0, gold:0, silver_icon:unit_silver_small_illu, gold_icon:unit_gold_small_illu" options="">
				ExchangeOffice.success_?
			</span>
		</div>
		<!-- ngIf: exchangeError -->
	</div>
</div><!-- end ngIf: player.data.deletionTime == 0 --></div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground mapBackground unselectable">
		<div id="map" class="mapContainer">
			<div class="sidebar zoomBar">
				<div class="mainLayoutMenuButton directionTo withArrowTip zoomButton zoomIn clickable disabled" content="Приблизить" v-tippy="{placement: 'right'}">
					<i class="action_zoomIn_small_flat_black"></i>
				</div>
				<div class="mainLayoutMenuButton directionTo withArrowTip zoomButton zoomOut clickable" content="Отдалить" v-tippy="{placement: 'right'}">
					<i class="action_zoomOut_small_flat_black"></i>
				</div>
				<div class="zoomLevel">
					<i class="currentZoomLevel sideMenu_zoomLevel1_small_flat_black"></i>
					<!--
					<i class="currentZoomLevel sideMenu_zoomLevel2_small_flat_black"></i>
					<i class="currentZoomLevel sideMenu_zoomLevel3_small_flat_black"></i>
					-->
				</div>
				<div :class="['mainLayoutMenuButton', 'directionTo', 'filterButton', {active: filters.showFilters}]">
					<div class="buttonWrapper clickable" @click="filters.showFilters = !filters.showFilters" content="Фильтр" v-tippy="{placement: 'right'}">
						<i class="sideMenu_filter_small_flat_black"></i>
					</div>
					<div class="filters" v-if="filters.showFilters">
						<div class="content">
							<div>
								<div class="headline"><span>Фильтр карты</span></div>
								<div class="option">
									<label class="clickInputLabel">
										<div class="clickInputWrapper">
											<input type="checkbox" class="ng-pristine ng-untouched ng-valid" />
											<i class="action_check_tiny_flat_black"></i>
										</div>
										<span><span>Границы королевств</span></span>
									</label>
								</div>
								<div class="option">
									<label class="clickInputLabel">
										<div class="clickInputWrapper">
											<input type="checkbox" class="ng-pristine ng-untouched ng-valid" />
											<i class="action_check_tiny_flat_black"></i>
										</div>
										<span><span>Свои маркеры</span></span>
									</label>
								</div>
								<div class="option">
									<label class="clickInputLabel">
										<div class="clickInputWrapper">
											<input type="checkbox" class="ng-pristine ng-untouched ng-valid" />
											<i class="action_check_tiny_flat_black"></i>
										</div>
										<span><span>Столицы</span></span>
									</label>
								</div>
								<div class="option">
									<label class="clickInputLabel">
										<div class="clickInputWrapper">
											<input type="checkbox" class="ng-pristine ng-untouched ng-valid" />
											<i class="action_check_tiny_flat_black"></i>
										</div>
										<span><span>Системные сообщения</span></span>
									</label>
								</div>
								<div class="option">
									<label class="clickInputLabel">
										<div class="clickInputWrapper">
											<input type="checkbox" class="ng-pristine ng-untouched ng-valid" />
											<i class="action_check_tiny_flat_black"></i>
										</div>
										<span><span>Сообщения игроков</span></span>
									</label>
								</div>
								<div class="option">
									<label class="clickInputLabel">
										<div class="clickInputWrapper">
											<input type="checkbox" class="ng-pristine ng-untouched ng-valid" />
											<i class="action_check_tiny_flat_black"></i>
										</div>
										<span><span>Сокровища</span></span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div :class="['mainLayoutMenuButton', 'directionTo', 'goToButton', {'active': goToLocation.show}]">
					<div class="buttonWrapper clickable" @click="goToLocation.show = !goToLocation.show" content="Перейти к местности" v-tippy="{placement: 'right'}">
						<i class="sideMenu_goTo_small_flat_black"></i>
					</div>
					<div class="filters" v-if="goToLocation.show">
						<div class="content">
							<div class="header">
								<span><span>Перейти к местности</span></span>
							</div>
							<span class="serverautocompleteContainer ng-valid">
								<span role="status" class="ui-helper-hidden-accessible"></span>
								<input class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" autocomplete="off" placeholder="Поиск..." />
								<label style="width: 101px;">
									<i class="action_search_tiny_flat_white"></i>
									Деревня, Игрок, Название королевства, Координаты
								</label>
							</span>
							<i class="centerResult clickable symbol_target_small_flat_black ng-hide" ng-class="{symbol_target_small_flat_black: !targetHover, symbol_target_small_flat_green: targetHover}" on-pointer-over="targetHover = true" on-pointer-out="targetHover = false" clickable="goToLocation.jump(true)" ng-show="goToLocation.searchObj.data.length > 0"></i>
							<div class="footer" ng-show="goToLocation.searchObj.data.length > 1">
								<span><span>Совпадений: 1 из </span></span>
								<span class="arrowButtonContainer">
									<a class="arrowButton clickable disabled" clickable="goToLocation.backward()" ng-class="{disabled: goToLocation.index <= 1}" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
										<i ng-class="{
											symbol_arrowFrom_tiny_flat_black: !fromHover || goToLocation.index <= 1,
											symbol_arrowFrom_tiny_flat_green: fromHover && goToLocation.index > 1
										}" class="symbol_arrowFrom_tiny_flat_black"></i>
									</a>
									<a class="arrowButton clickable" clickable="goToLocation.forward()" ng-class="{disabled: goToLocation.index == goToLocation.searchObj.data.length}" on-pointer-over="toHover = true" on-pointer-out="toHover = false">
										<i ng-class="{
											symbol_arrowTo_tiny_flat_black: !toHover || (goToLocation.index == goToLocation.searchObj.data.length),
											symbol_arrowTo_tiny_flat_green: toHover && (goToLocation.index != goToLocation.searchObj.data.length)
										}" class="symbol_arrowTo_tiny_flat_black"></i>
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="mainLayoutMenuButton directionTo goHomeButton" content="Перейти к открытой деревне" v-tippy="{placement: 'right'}">
					<i class="symbol_target_small_flat_black clickable"></i>
				</div>
			</div>
			<!--<div id="overlayMarkers" class="zoomLevel1" style="top: -645px; left: 1260px;">-->
			<div id="overlayMarkers" class="zoomLevel1" style="top: 469px; left: 958px;">
				<div class="clouds tutorial_mapOverlay_illustration" id="tutorialClouds" v-if="gameModel.player.spawnedOnMap == 0">
					<div class="extraCover side1 tutorial_mapOverlayStart_illustration" :class="{invisible: gameModel.islands.length > 0}"></div>
					<div class="extraCover side2 tutorial_mapOverlayEnd_illustration" :class="{invisible: gameModel.islands.length > 0}"></div>
				</div>
			</div>
			<div id="tileInformation" class="contentBox unselectable" :class="{coordsOnly: tiInfos.coordsOnly}" v-if="tiInfos.coord">
				<div class="contentBoxHeader">
					<span class="coordinates">
						<span class="coordinateWrapper" nolink="true" aligned="false">
							<span ng-if="!hasLink" class="coordinates coordinatesWrapper" ng-bind-html="coord | bidiCoordinate:x:y">
								<span class="coordinateX">({{tiInfos.coord.x}}</span>
								<span class="coordinatePipe">|</span>
								<span class="coordinateY">{{tiInfos.coord.y}})</span>
							</span>
						</span>
					</span>
					<span v-if="tiInfos.villageName" class="villageName truncated">{{tiInfos.villageName}}</span>
					<div class="additionalInfo iconValueList">
						<div v-if="tiInfos.population">
							<span><i class="unit_population_small_illu"></i></span>
						</div>
						<div v-if="tiInfos.oasisBonus">
							<span v-for="(bonus, resType) in tiInfos.oasisBonus" :key="resType">
								<i :class="['unit_'+resType+'_small_illu']"></i>{{bonus}}%
							</span>
						</div>
					</div>
					<span class="mainVillageIndicator" v-if="tiInfos.isMainVillage">
						<i class="village_main_small_flat_black"></i>
					</span>
				</div>
				<div class="contentBoxBody resources unselectable" v-if="zoomLevel != 2 && (tiInfos.resDistribution || tiInfos.robberVillageDetails || tiInfos.oasis || tiInfos.playerName)">
					<span v-if="tiInfos.oasisDetails && tiInfos.oasis.oasisStatus == 'Village.OASIS_STATUS_WILD'">
						<span class="unit" v-for="animal in tiInfos.oasisDetails.filteredTroops">
							<i class="unitSmall nature" :class="['unitType'+animal.unitType]" unit-icon="" :data="'4, '+animal.unitType"></i>{{animal.count}}&nbsp;
						</span>
					</span>
					<span v-if="tiInfos.robberVillageDetails">
						<span class="unit" v-for="troop in tiInfos.robberVillageDetails.filteredTroops">
							<i class="unitSmall gaul" :class="['unitType'+troop.unitType]" unit-icon="" :data="'3, '+troop.unitType"></i>{{troop.count}}&nbsp;
						</span>
					</span>
					<span v-if="tiInfos.resDistribution">
						<i class="unit_wood_small_illu"></i>{{tiInfos.resDistribution.wood}}
						<i class="unit_clay_small_illu"></i>{{tiInfos.resDistribution.clay}}
						<i class="unit_iron_small_illu"></i>{{tiInfos.resDistribution.iron}}
						<i class="unit_crop_small_illu"></i>{{tiInfos.resDistribution.crop}}
					</span>
					<span class="owner">
						<span v-if="tiInfos.oasis && tiInfos.oasis.oasisStatus == 'Village.OASIS_STATUS_OCCUPIED'">
							<i class="community_kingdom_small_flat_black"></i>
							<a :class="{disabled: kingdomId <= 0 || nolink}" class="disabled kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 48}, null, true)" kingdomid="48" nolink="true">{{kingdomName}}</a>
						</span>
						<span v-if="multiLanguageServer && tiInfos.country" :class="[tiInfos.country]" class="languageFlag"></span>
						<span v-if="tiInfos.playerName">
							<span class="truncated">{{tiInfos.playerName}}</span>
						</span>
					</span>
				</div>
				<div class="reportsContainer unselectable" ng-if="tiInfos.report">
					<div class="reportsDecoration unselectable">
						<!--
						<span class="inner">
							<i class="reportIcon reportTypes_reportIcon4"></i>
							<span i18ndt="1705294777" format="veryShort">15.01. | 10:59</span>
						</span>
						<span class="inner">
							<i class="reportIcon reportTypes_reportIcon19"></i>
							<span i18ndt="1708109004" format="veryShort">00:43</span>
						</span>
						-->
						<span class="inner">
							<i class="reportIcon reportTypes_reportIcon6"></i>
							<span i18ndt="1705351034" format="veryShort">16.01. | 02:37</span>
							<span ng-if="tiInfos.report && tiInfos.report.capacity > 0" class="carry">
								<i ng-class="{
									unit_capacityEmpty_small_flat_black: tiInfos.report.raidedResSum == 0,
									unit_capacityHalf_small_flat_black: tiInfos.report.raidedResSum > 0 && tiInfos.report.raidedResSum < tiInfos.report.capacity,
									unit_capacity_small_flat_black: tiInfos.report.raidedResSum == tiInfos.report.capacity
								}" class="unit_capacity_small_flat_black"></i>
								2355/2355
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="infoMovements unselectable" ng-style="tiStyle" style="visibility: visible;">
				<ul class="troopMovements" ng-if="zoomLevel != 2">
					<li class="small infoMovementType incoming unselectable incoming_attacks" ng-repeat="(type, info) in tiInfos.movements">
						<i class="movement_attack_small_flat_black"></i>
						<div class="countdown" countdown="1708165984">00:05:12</div>
						<div class="count">1</div>
						<div class="ending">
							<div class="colored"></div>
						</div>
					</li>
					<li class="small infoMovementType incoming unselectable return" ng-repeat="(type, info) in tiInfos.movements">
						<i class="movement_support_small_flat_black"></i>
						<div class="countdown" countdown="1708156183.281573">00:15:45</div>
						<div class="count">1</div>
						<div class="ending">
							<div class="colored"></div>
						</div>
					</li>
					<li class="small infoMovementType incoming unselectable return" ng-repeat="(type, info) in tiInfos.movements">
						<i class="movement_support_small_flat_black"></i>
						<div class="countdown" countdown="1708158063">00:47:05</div>
						<div class="count">1</div>
						<div class="ending">
							<div class="colored"></div>
						</div>
					</li>
				</ul>
			</div>
			<div class="infoMovements unselectable" style="visibility: hidden;">
				<ul class="troopMovements"></ul>
			</div>
			<div id="canvasBorder" class="map-container" style="position: absolute; top: 0px; left: 0px; overflow: hidden; width: 100%; height: 100%; cursor: pointer;">
				
			</div>
		</div>
	</div>
	
	
	
	<!--
	<div class="mainContentBackground mapBackground unselectable">
		<div id="map" class="mapContainer">
			<div id="canvasBorder" style="position: absolute; top: 0px; left: 0px; overflow: hidden; width: 1263px; height: 750px;">
				<div id="canvasMap" width="1515" height="886" style="position: absolute; top: -68px; left: -126px;">
					<div v-for="x in range(min, max)" class="cnt">
						<div v-for="y in range(min, max)" style="display: inline-block;" :class="[
							'bl',
							{'empty': map[x][y].idIsland == null && map[x][y].idUser == null},
							{'user': map[x][y].idIsland != null && map[x][y].idUser != null},
							{'barbarian': map[x][y].idIsland != null && map[x][y].idUser == null},
							{'selected': (x == action.x1 && y == action.y1) || (x == action.x2 && y == action.y2)},
							{'moveto': map[x][y].idIsland == null && map[x][y].idUser == null && action.moving}
						]" :title="x+' | '+y+(map[x][y].name ? ' ('+map[x][y].name+')' : '')+(map[x][y].username ? ' ('+map[x][y].username+')' : '')" @click="move(x, y)"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	-->
</template>

<style lang="css">
.cnt {
	line-height: 0;
}
.bl {
	width: 20px;
	height: 20px;
	margin: 0px;
}
.bl.empty {
	background: aqua;
}
.bl.user {
	background: blue;
}
.bl.barbarian {
	background: gray;
}
.bl.selected {
	background: #fff;
}
.bl:hover {
	opacity: 0.2;
}
.bl:hover.moveto {
	background: #fff;
	width: 16px;
	height: 16px;
	border: 2px dotted #000;
}




#map .zoomBar{
    position:absolute;
    left:0;
    top:330px;
    z-index:10
}
#map .zoomBar .mainLayoutMenuButton{
    z-index:2;
    height:25px;
    width:30px
}
#map .zoomBar .mainLayoutMenuButton:after{
    width:10px;
    height:9px;
    top:6px;
    right:-6px
}
#map .zoomBar .mainLayoutMenuButton>i{
    top:4px
}
#map .zoomBar .mainLayoutMenuButton.zoomOut{
    margin-top:4px
}
#map .zoomBar .zoomLevel{
    position:absolute;
    z-index:1;
    height:25px;
    width:51px;
    top:14px;
    border-top:1px solid #7b746e;
    border-bottom:1px solid #7b746e;
    background-color:rgba(241,240,238,0.7)
}
#map .zoomBar .zoomLevel:after{
    content:'';
    position:absolute;
    top:6px;
    right:-7px;
    width:11px;
    height:11px;
    background-image:-owg-linear-gradient(45deg,rgba(241,240,238,0.7) 50%,transparent 50%);
    background-image:-webkit-linear-gradient(45deg,rgba(241,240,238,0.7) 50%,transparent 50%);
    background-image:-moz-linear-gradient(45deg,rgba(241,240,238,0.7) 50%,transparent 50%);
    background-image:-o-linear-gradient(45deg,rgba(241,240,238,0.7) 50%,transparent 50%);
    background-image:linear-gradient(45deg,rgba(241,240,238,0.7) 50%,transparent 50%);
    border-bottom:1px solid #7b746e;
    border-left:1px solid #7b746e;
    -webkit-transform:rotate(225deg) skew(30deg,30deg);
    -moz-transform:rotate(225deg) skew(30deg,30deg);
    -ms-transform:rotate(225deg) skew(30deg,30deg);
    -o-transform:rotate(225deg) skew(30deg,30deg);
    transform:rotate(225deg) skew(30deg,30deg);
    border-width:2px
}
#map .zoomBar .zoomLevel>i{
    top:5px;
    left:33px
}
#map .zoomBar .filterButton,#map .zoomBar .goToButton{
    height:30px
}
#map .zoomBar .filterButton .buttonWrapper,#map .zoomBar .goToButton .buttonWrapper{
    line-height:30px
}
#map .zoomBar .filterButton{
    margin-top:10px
}
#map .zoomBar .goToButton{
    margin-top:4px
}
#map .zoomBar .goHomeButton{
    margin-top:4px;
    height:30px
}
#map .zoomBar .goHomeButton>i{
    top:6px
}
#map .zoomBar .filters{
    position:absolute;
    top:0;
    left:29px;
    border:1px solid #fff;
    -webkit-box-shadow:1px 0 1px rgba(0,0,0,0.7);
    -moz-box-shadow:1px 0 1px rgba(0,0,0,0.7);
    box-shadow:1px 0 1px rgba(0,0,0,0.7);
    background-color:rgba(241,240,238,0.6);
    padding:5px;
    text-align:left
}
#map .zoomBar .filters .content{
    width:150px;
    font-size:.76923rem;
    padding:5px;
    background-color:rgba(255,255,255,0.7);
    border:1px solid #b8b2a9
}
#map .zoomBar .filters .content .serverautocompleteContainer{
    display:inline-block;
    width:127px
}
#map .zoomBar .filters .content .serverautocompleteContainer input{
    width:125px
}
#map .zoomBar .filters .content .serverautocompleteContainer+i.centerResult{
    top:2px;
    margin-left:1px
}
#map .zoomBar .filters .content .serverautocompleteContainer label{
    top:22px
}
#map .zoomBar .filters .content .header{
    width:100%;
    border-bottom:1px solid #b8b2a9;
    margin-bottom:5px;
    padding-bottom:3px;
    font-weight:bold
}
#map .zoomBar .filters .content .header i{
    position:relative;
    top:auto;
    display:inline-block;
    padding-left:7px;
    padding-right:1px;
    float:right;
    border-left:1px solid #b8b2a9
}
#map .zoomBar .filters .content .footer{
    border-top:1px solid #b8b2a9;
    margin-top:8px;
    padding:4px 0
}
#map .zoomBar .filters .content .footer .arrowButtonContainer{
    float:right
}
#map .zoomBar .filters .content .footer .arrowButtonContainer .arrowButton{
    display:inline-block;
    padding:0 3px
}
#map .zoomBar .filters .content .footer .arrowButtonContainer .arrowButton i{
    vertical-align:middle
}
#map .zoomBar .filters .content .option{
    margin:1px;
    padding-right:30px;
    padding-bottom:3px;
    white-space:nowrap;
    border-top:1px solid #b8b2a9
}
#map .zoomBar .filters .content .option i{
    top:5px
}
#map .zoomBar .filters .content .option span{
    margin-top:3px;
    display:inline-block
}
#canvasMap{
    -ms-scroll-chaining:none;
    -ms-touch-action:none
}
#map #overlayMarkers{
    position:absolute;
    top:0;
    left:0;
    z-index:1
}
#map #overlayMarkers .villageName,#map #overlayMarkers .villageTreasuries{
    position:absolute;
    z-index:4;
    width:250px;
    font-size:.84615rem;
    text-align:center;
    margin:14px -125px 0 -125px
}
#map #overlayMarkers .villageName .content,#map #overlayMarkers .villageTreasuries .content{
    position:relative;
    display:inline-block
}
#map #overlayMarkers .villageName .content .inner,#map #overlayMarkers .villageTreasuries .content .inner{
    display:inline-block;
    line-height:18px;
    height:20px;
    padding:0 5px;
    box-sizing:border-box;
    background-color:rgba(235,235,235,0.85);
    border-top:1px solid #b8b2a9;
    border-bottom:1px solid #b8b2a9;
    -webkit-box-shadow:inset 0 2px 0 -1px #fff,inset 0 -2px 0 -1px #fff;
    -moz-box-shadow:inset 0 2px 0 -1px #fff,inset 0 -2px 0 -1px #fff;
    box-shadow:inset 0 2px 0 -1px #fff,inset 0 -2px 0 -1px #fff;
    min-width:90px
}
#map #overlayMarkers .villageName .content:before,#map #overlayMarkers .villageName .content:after,#map #overlayMarkers .villageTreasuries .content:before,#map #overlayMarkers .villageTreasuries .content:after{
    content:"";
    position:absolute;
    top:0;
    box-sizing:border-box;
    width:9px;
    height:19px;
    border-top:1px solid #b8b2a9
}
#map #overlayMarkers .villageName .content:before,#map #overlayMarkers .villageTreasuries .content:before{
    left:-5px;
    border-left:1px solid #b8b2a9;
    background-image:linear-gradient(to bottom right,rgba(235,235,235,0.85),rgba(235,235,235,0.85) 50%,transparent 50%,transparent);
    -webkit-transform:skewX(23deg);
    -moz-transform:skewX(23deg);
    -ms-transform:skewX(23deg);
    -o-transform:skewX(23deg);
    transform:skewX(23deg);
    -webkit-box-shadow:inset 0 2px 0 -1px #fff,inset 2px 0 0 -1px #fff;
    -moz-box-shadow:inset 0 2px 0 -1px #fff,inset 2px 0 0 -1px #fff;
    box-shadow:inset 0 2px 0 -1px #fff,inset 2px 0 0 -1px #fff
}
#map #overlayMarkers .villageName .content:after,#map #overlayMarkers .villageTreasuries .content:after{
    right:-5px;
    border-right:1px solid #b8b2a9;
    background-image:linear-gradient(to bottom left,rgba(235,235,235,0.85),rgba(235,235,235,0.85) 50%,transparent 50%,transparent);
    -webkit-transform:skewX(-23deg);
    -moz-transform:skewX(-23deg);
    -ms-transform:skewX(-23deg);
    -o-transform:skewX(-23deg);
    transform:skewX(-23deg);
    -webkit-box-shadow:inset 0 2px 0 -1px #fff,inset -2px 0 0 -1px #fff;
    -moz-box-shadow:inset 0 2px 0 -1px #fff,inset -2px 0 0 -1px #fff;
    box-shadow:inset 0 2px 0 -1px #fff,inset -2px 0 0 -1px #fff
}
#map #overlayMarkers .villageName.robber .content .inner,#map #overlayMarkers .villageTreasuries.robber .content .inner{
    color:#fff;
    background-color:rgba(70,63,57,0.85)
}
#map #overlayMarkers .villageName.robber .content:before,#map #overlayMarkers .villageTreasuries.robber .content:before{
    left:-5px;
    border-left:1px solid #b8b2a9;
    background-image:linear-gradient(to bottom right,rgba(70,63,57,0.85),rgba(70,63,57,0.85) 50%,transparent 50%,transparent);
    -webkit-transform:skewX(23deg);
    -moz-transform:skewX(23deg);
    -ms-transform:skewX(23deg);
    -o-transform:skewX(23deg);
    transform:skewX(23deg);
    -webkit-box-shadow:inset 0 2px 0 -1px #fff,inset 2px 0 0 -1px #fff;
    -moz-box-shadow:inset 0 2px 0 -1px #fff,inset 2px 0 0 -1px #fff;
    box-shadow:inset 0 2px 0 -1px #fff,inset 2px 0 0 -1px #fff
}
#map #overlayMarkers .villageName.robber .content:after,#map #overlayMarkers .villageTreasuries.robber .content:after{
    right:-5px;
    border-right:1px solid #b8b2a9;
    background-image:linear-gradient(to bottom left,rgba(70,63,57,0.85),rgba(70,63,57,0.85) 50%,transparent 50%,transparent);
    -webkit-transform:skewX(-23deg);
    -moz-transform:skewX(-23deg);
    -ms-transform:skewX(-23deg);
    -o-transform:skewX(-23deg);
    transform:skewX(-23deg);
    -webkit-box-shadow:inset 0 2px 0 -1px #fff,inset -2px 0 0 -1px #fff;
    -moz-box-shadow:inset 0 2px 0 -1px #fff,inset -2px 0 0 -1px #fff;
    box-shadow:inset 0 2px 0 -1px #fff,inset -2px 0 0 -1px #fff
}
#map #overlayMarkers .villageTreasuries .content:before{
    left:-5px;
    border-left:1px solid #fdf15f;
    background-image:linear-gradient(to bottom right,rgba(253,241,95,0.85),rgba(253,241,95,0.85) 50%,transparent 50%,transparent);
    -webkit-transform:skewX(23deg);
    -moz-transform:skewX(23deg);
    -ms-transform:skewX(23deg);
    -o-transform:skewX(23deg);
    transform:skewX(23deg)
}
#map #overlayMarkers .villageTreasuries .content:after{
    right:-5px;
    border-right:1px solid #fdf15f;
    background-image:linear-gradient(to bottom left,rgba(253,241,95,0.85),rgba(253,241,95,0.85) 50%,transparent 50%,transparent);
    -webkit-transform:skewX(-23deg);
    -moz-transform:skewX(-23deg);
    -ms-transform:skewX(-23deg);
    -o-transform:skewX(-23deg);
    transform:skewX(-23deg)
}
#map #overlayMarkers .villageTreasuries .content .inner{
    background-color:rgba(253,241,95,0.85);
    min-width:55px
}
#map #overlayMarkers .villageTreasuries .content .inner i{
    margin-right:5px;
    margin-top:1px;
    vertical-align:top
}
#map #overlayMarkers .cropMarker{
    position:absolute;
    z-index:2;
    margin-left:-12.5px;
    margin-top:-32px
}
#map #overlayMarkers .cropMarker.cropAmount1{
    background-position:-1800px -1700px;
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/map.png");
    width:25px;
    height:32px
}
#map #overlayMarkers .cropMarker.cropAmount2{
    background-position:-1864px -1668px;
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/map.png");
    width:25px;
    height:32px
}
#map #overlayMarkers .fieldMarker{
    position:absolute;
    z-index:5;
    width:150px;
    margin-left:-75px;
    margin-top:-20px
}
#map #overlayMarkers .fieldMarker .wrapper{
    position:absolute;
    z-index:1;
    background-image:-owg-linear-gradient(rgba(232,229,225,0.8),#fff);
    background-image:-webkit-linear-gradient(rgba(232,229,225,0.8),#fff);
    background-image:-moz-linear-gradient(rgba(232,229,225,0.8),#fff);
    background-image:-o-linear-gradient(rgba(232,229,225,0.8),#fff);
    background-image:linear-gradient(rgba(232,229,225,0.8),#fff);
    border:1px solid #463f39;
    -webkit-box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0;
    -moz-box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0;
    box-shadow:rgba(0,0,0,0.25) 1px 1px 5px 0;
    left:50%;
    bottom:0;
    transform:translateX(-50%)
}
#map #overlayMarkers .fieldMarker .wrapper .content{
    line-height:14px;
    font-size:11px;
    color:#5a534b;
    padding:5px;
    text-align:center;
    word-wrap:break-word
}
#map #overlayMarkers .fieldMarker .wrapper .content .personal span:last-of-type,#map #overlayMarkers .fieldMarker .wrapper .content .global span:last-of-type{
    color:#777
}
#map #overlayMarkers .fieldMarker .wrapper .content .personal .playerLink{
    display:block;
    color:#252525;
    border-bottom:1px solid #b2b2b2;
    padding-bottom:3px;
    margin-bottom:2px;
    cursor:pointer
}
#map #overlayMarkers .fieldMarker .wrapper .content .personal .playerBox{
    width:46px;
    height:40px;
    background-image:-owg-linear-gradient(to top,#fff 5px,transparent 40px);
    background-image:-webkit-linear-gradient(to top,#fff 5px,transparent 40px);
    background-image:-moz-linear-gradient(to top,#fff 5px,transparent 40px);
    background-image:-o-linear-gradient(to top,#fff 5px,transparent 40px);
    background-image:linear-gradient(to top,#fff 5px,transparent 40px);
    position:absolute;
    overflow:hidden;
    left:calc(50% - 23px);
    top:-41px
}
#map #overlayMarkers .fieldMarker .wrapper .content .personal .playerBox .heroImage{
    top:-4px;
    left:-7px
}
#map #overlayMarkers .fieldMarker .wrapper .content .personal .playerBox .heroImage.female{
    left:-9px
}
#map #overlayMarkers .fieldMarker .wrapper .content .prestigeStars{
    position:relative;
    background-color:#fff;
    border:1px solid #463f39;
    border-left:0;
    width:46px;
    height:17px;
    margin-left:33px;
    margin-top:-6px
}
#map #overlayMarkers .fieldMarker .wrapper .content .prestigeStars:before,#map #overlayMarkers .fieldMarker .wrapper .content .prestigeStars:after{
    content:"";
    z-index:0;
    border-bottom:1px solid #463f39;
    border-top:1px solid #463f39;
    position:absolute;
    background-color:#fff;
    top:-1px;
    width:17px;
    height:17px
}
#map #overlayMarkers .fieldMarker .wrapper .content .prestigeStars:before{
    border-left:1px solid #463f39;
    left:-4px;
    -webkit-transform:skewX(21deg);
    -moz-transform:skewX(21deg);
    -ms-transform:skewX(21deg);
    -o-transform:skewX(21deg);
    transform:skewX(21deg)
}
#map #overlayMarkers .fieldMarker .wrapper .content .prestigeStars:after{
    border-right:1px solid #463f39;
    right:-4px;
    -webkit-transform:skewX(-21deg);
    -moz-transform:skewX(-21deg);
    -ms-transform:skewX(-21deg);
    -o-transform:skewX(-21deg);
    transform:skewX(-21deg)
}
#map #overlayMarkers .fieldMarker .wrapper .content .prestigeStars .prestigeStar{
    margin:0;
    display:block;
    margin-top:2px;
    margin-left:2px;
    padding:0;
    float:left;
    z-index:1
}
#map #overlayMarkers .fieldMarker .wrapper .content .prestigeStars .prestigeBadge{
    z-index:2
}
#map #overlayMarkers .fieldMarker .wrapper .pagination{
    padding:3px;
    font-size:11px;
    text-align:center;
    background-color:#e9e5e2;
    border-top:1px solid #b2b2b2
}
#map #overlayMarkers .fieldMarker .wrapper .pagination i{
    vertical-align:middle
}
#map #overlayMarkers .fieldMarker .wrapper.fieldMarkerBox{
    width:122px
}
#map #overlayMarkers .fieldMarker .wrapper .closeNotification{
    display:none;
    position:absolute;
    top:-5px;
    right:-5px;
    background-color:#fff;
    border:2px solid #fff;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    border-radius:50%;
    -webkit-box-shadow:0 0 0 1px #b8b2a9;
    -moz-box-shadow:0 0 0 1px #b8b2a9;
    box-shadow:0 0 0 1px #b8b2a9
}
#map #overlayMarkers .fieldMarker .wrapper:hover .closeNotification{
    display:block
}
#map #overlayMarkers .fieldMarker .wrapper:hover .closeNotification:hover{
    background-color:#e8e5e1;
    border:2px solid #e8e5e1;
    -webkit-box-shadow:0 0 0 1px #888;
    -moz-box-shadow:0 0 0 1px #888;
    box-shadow:0 0 0 1px #888
}
#map #overlayMarkers .fieldMarker:after{
    content:'';
    position:absolute;
    z-index:2;
    top:100%;
    left:50%;
    border:1px solid;
    border-color:transparent transparent #463f39 #463f39;
    background-image:-owg-linear-gradient(45deg,#fff 55%,transparent 65%);
    background-image:-webkit-linear-gradient(45deg,#fff 55%,transparent 65%);
    background-image:-moz-linear-gradient(45deg,#fff 55%,transparent 65%);
    background-image:-o-linear-gradient(45deg,#fff 55%,transparent 65%);
    background-image:linear-gradient(45deg,#fff 55%,transparent 65%);
    width:12px;
    height:13px;
    -webkit-transform:rotate(-45deg) skew(21deg,21deg);
    -moz-transform:rotate(-45deg) skew(21deg,21deg);
    -ms-transform:rotate(-45deg) skew(21deg,21deg);
    -o-transform:rotate(-45deg) skew(21deg,21deg);
    transform:rotate(-45deg) skew(21deg,21deg);
    margin-top:-8px;
    margin-left:-7px
}
#map #overlayMarkers .fieldMarker:hover{
    z-index:6
}
#map #overlayMarkers .fieldMarker.minimizedMarker{
    z-index:4
}
#map #overlayMarkers .fieldMarker.minimizedMarker .wrapper .content{
    padding:1px;
    padding-bottom:0
}
#map #overlayMarkers .fieldMarker.minimizedMarker:after{
    width:9px;
    height:10px;
    margin-left:-5px;
    margin-top:-6px;
    border-right:0;
    border-top:0
}
#map #overlayMarkers .fieldMarker.allianceWide .wrapper{
    background-image:-owg-linear-gradient(rgba(232,229,225,0.8),#b1e4ff);
    background-image:-webkit-linear-gradient(rgba(232,229,225,0.8),#b1e4ff);
    background-image:-moz-linear-gradient(rgba(232,229,225,0.8),#b1e4ff);
    background-image:-o-linear-gradient(rgba(232,229,225,0.8),#b1e4ff);
    background-image:linear-gradient(rgba(232,229,225,0.8),#b1e4ff)
}
#map #overlayMarkers .fieldMarker.allianceWide:after{
    background-size:100% auto;
    background-image:-owg-linear-gradient(45deg,#b1e4ff 55%,transparent 65%);
    background-image:-webkit-linear-gradient(45deg,#b1e4ff 55%,transparent 65%);
    background-image:-moz-linear-gradient(45deg,#b1e4ff 55%,transparent 65%);
    background-image:-o-linear-gradient(45deg,#b1e4ff 55%,transparent 65%);
    background-image:linear-gradient(45deg,#b1e4ff 55%,transparent 65%)
}
#map #overlayMarkers .fieldMarker.personal .wrapper{
    background-image:-owg-linear-gradient(rgba(232,229,225,0.8),#a5c400);
    background-image:-webkit-linear-gradient(rgba(232,229,225,0.8),#a5c400);
    background-image:-moz-linear-gradient(rgba(232,229,225,0.8),#a5c400);
    background-image:-o-linear-gradient(rgba(232,229,225,0.8),#a5c400);
    background-image:linear-gradient(rgba(232,229,225,0.8),#a5c400)
}
#map #overlayMarkers .fieldMarker.personal:after{
    background-image:-owg-linear-gradient(45deg,#a5c400 55%,transparent 65%);
    background-image:-webkit-linear-gradient(45deg,#a5c400 55%,transparent 65%);
    background-image:-moz-linear-gradient(45deg,#a5c400 55%,transparent 65%);
    background-image:-o-linear-gradient(45deg,#a5c400 55%,transparent 65%);
    background-image:linear-gradient(45deg,#a5c400 55%,transparent 65%)
}
#map #overlayMarkers .fieldMarker.hasNavigation:not(.minimizedMarker):after{
    background-color:#e9e5e2;
    background-image:-owg-none;
    background-image:-webkit-none;
    background-image:-moz-none;
    background-image:-o-none;
    background-image:none
}
#map #overlayMarkers .movementSprite{
    position:absolute;
    z-index:2
}
#map #overlayMarkers .movementSprite i{
    position:absolute;
    height:100px;
    width:100px;
    margin-left:-50px;
    margin-top:-65px
}
#map #overlayMarkers .movementSprite i.ownType3{
    background-image:url("//iwstatic.g.bsrv.su/img/movement/outgoingAttack_static.png");
    width:100px;
    height:100px
}
#map #overlayMarkers .movementSprite i.ownType5{
    background-image:url("//iwstatic.g.bsrv.su/img/movement/outgoingSupport_static.png");
    width:100px;
    height:100px
}
#map #overlayMarkers .movementSprite i.ownAttAndDef{
    background-image:url("//iwstatic.g.bsrv.su/img/movement/outgoingSupportAndAttack_static.png");
    width:100px;
    height:100px
}
#map #overlayMarkers .movementSprite i.enemyAttack{
    background-image:url("//iwstatic.g.bsrv.su/img/movement/incomingAttack_static.png");
    width:100px;
    height:100px
}
#map #overlayMarkers .movementSprite i.robberAttack{
    background-image:url("//iwstatic.g.bsrv.su/img/movement/incomingRobberAttack_static.png");
    width:100px;
    height:100px
}
body.withAnimations #map #overlayMarkers .movementSprite i.ownType3{
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/map.png");
    background-position:-1200px -1700px;
    width:100px;
    height:100px;
    animation:outgoingAttack 2.5s step-end infinite;
    -webkit-animation:outgoingAttack 2.5s step-end infinite;
    -moz-animation:outgoingAttack 2.5s step-end infinite
}
@-webkit-keyframes outgoingAttack{
    from{
        background-position:-1200px -1700px
    }
    5%{
        background-position:-1890px -500px
    }
    10%{
        background-position:-1890px -700px
    }
    15%{
        background-position:-1890px -800px
    }
    20%{
        background-position:-1890px -900px
    }
    25%{
        background-position:-1890px -1000px
    }
    30%{
        background-position:-1890px -1100px
    }
    35%{
        background-position:-1890px -1200px
    }
    40%{
        background-position:-1890px -1300px
    }
    45%{
        background-position:-1300px -1700px
    }
    50%{
        background-position:-1400px -1700px
    }
    55%{
        background-position:-1500px -1700px
    }
    60%{
        background-position:-1600px -1700px
    }
    65%{
        background-position:-1700px -1700px
    }
    70%{
        background-position:-1890px 0
    }
    75%{
        background-position:-1890px -100px
    }
    80%{
        background-position:-1890px -200px
    }
    85%{
        background-position:-1890px -300px
    }
    90%{
        background-position:-1890px -400px
    }
    95%{
        background-position:-1890px -600px
    }
}
@-moz-keyframes outgoingAttack{
    from{
        background-position:-1200px -1700px
    }
    5%{
        background-position:-1890px -500px
    }
    10%{
        background-position:-1890px -700px
    }
    15%{
        background-position:-1890px -800px
    }
    20%{
        background-position:-1890px -900px
    }
    25%{
        background-position:-1890px -1000px
    }
    30%{
        background-position:-1890px -1100px
    }
    35%{
        background-position:-1890px -1200px
    }
    40%{
        background-position:-1890px -1300px
    }
    45%{
        background-position:-1300px -1700px
    }
    50%{
        background-position:-1400px -1700px
    }
    55%{
        background-position:-1500px -1700px
    }
    60%{
        background-position:-1600px -1700px
    }
    65%{
        background-position:-1700px -1700px
    }
    70%{
        background-position:-1890px 0
    }
    75%{
        background-position:-1890px -100px
    }
    80%{
        background-position:-1890px -200px
    }
    85%{
        background-position:-1890px -300px
    }
    90%{
        background-position:-1890px -400px
    }
    95%{
        background-position:-1890px -600px
    }
}
@-ms-keyframes outgoingAttack{
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 from{
        background-position:-1200px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 5%{
        background-position:-1890px -500px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 10%{
        background-position:-1890px -700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 15%{
        background-position:-1890px -800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 20%{
        background-position:-1890px -900px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 25%{
        background-position:-1890px -1000px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 30%{
        background-position:-1890px -1100px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 35%{
        background-position:-1890px -1200px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 40%{
        background-position:-1890px -1300px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 45%{
        background-position:-1300px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 50%{
        background-position:-1400px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 55%{
        background-position:-1500px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 60%{
        background-position:-1600px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 65%{
        background-position:-1700px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 70%{
        background-position:-1890px 0
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 75%{
        background-position:-1890px -100px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 80%{
        background-position:-1890px -200px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 85%{
        background-position:-1890px -300px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 90%{
        background-position:-1890px -400px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType3 95%{
        background-position:-1890px -600px
    }
}
@-o-keyframes outgoingAttack{
    from{
        background-position:-1200px -1700px
    }
    5%{
        background-position:-1890px -500px
    }
    10%{
        background-position:-1890px -700px
    }
    15%{
        background-position:-1890px -800px
    }
    20%{
        background-position:-1890px -900px
    }
    25%{
        background-position:-1890px -1000px
    }
    30%{
        background-position:-1890px -1100px
    }
    35%{
        background-position:-1890px -1200px
    }
    40%{
        background-position:-1890px -1300px
    }
    45%{
        background-position:-1300px -1700px
    }
    50%{
        background-position:-1400px -1700px
    }
    55%{
        background-position:-1500px -1700px
    }
    60%{
        background-position:-1600px -1700px
    }
    65%{
        background-position:-1700px -1700px
    }
    70%{
        background-position:-1890px 0
    }
    75%{
        background-position:-1890px -100px
    }
    80%{
        background-position:-1890px -200px
    }
    85%{
        background-position:-1890px -300px
    }
    90%{
        background-position:-1890px -400px
    }
    95%{
        background-position:-1890px -600px
    }
}
@keyframes outgoingAttack{
    from{
        background-position:-1200px -1700px
    }
    5%{
        background-position:-1890px -500px
    }
    10%{
        background-position:-1890px -700px
    }
    15%{
        background-position:-1890px -800px
    }
    20%{
        background-position:-1890px -900px
    }
    25%{
        background-position:-1890px -1000px
    }
    30%{
        background-position:-1890px -1100px
    }
    35%{
        background-position:-1890px -1200px
    }
    40%{
        background-position:-1890px -1300px
    }
    45%{
        background-position:-1300px -1700px
    }
    50%{
        background-position:-1400px -1700px
    }
    55%{
        background-position:-1500px -1700px
    }
    60%{
        background-position:-1600px -1700px
    }
    65%{
        background-position:-1700px -1700px
    }
    70%{
        background-position:-1890px 0
    }
    75%{
        background-position:-1890px -100px
    }
    80%{
        background-position:-1890px -200px
    }
    85%{
        background-position:-1890px -300px
    }
    90%{
        background-position:-1890px -400px
    }
    95%{
        background-position:-1890px -600px
    }
}
body.withAnimations #map #overlayMarkers .movementSprite i.ownType5{
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/map.png");
    background-position:0 -1600px;
    width:100px;
    height:100px;
    animation:outgoingSupport 2.5s step-end infinite;
    -webkit-animation:outgoingSupport 2.5s step-end infinite;
    -moz-animation:outgoingSupport 2.5s step-end infinite
}
@-webkit-keyframes outgoingSupport{
    from{
        background-position:0 -1600px
    }
    5%{
        background-position:-700px -1800px
    }
    10%{
        background-position:-900px -1800px
    }
    15%{
        background-position:-1000px -1800px
    }
    20%{
        background-position:-1100px -1800px
    }
    25%{
        background-position:-1200px -1800px
    }
    30%{
        background-position:-1300px -1800px
    }
    35%{
        background-position:-1400px -1800px
    }
    40%{
        background-position:-1500px -1800px
    }
    45%{
        background-position:-1890px -1500px
    }
    50%{
        background-position:-1890px -1600px
    }
    55%{
        background-position:-1890px -1700px
    }
    60%{
        background-position:0 -1800px
    }
    65%{
        background-position:-100px -1800px
    }
    70%{
        background-position:-200px -1800px
    }
    75%{
        background-position:-300px -1800px
    }
    80%{
        background-position:-400px -1800px
    }
    85%{
        background-position:-500px -1800px
    }
    90%{
        background-position:-600px -1800px
    }
    95%{
        background-position:-800px -1800px
    }
}
@-moz-keyframes outgoingSupport{
    from{
        background-position:0 -1600px
    }
    5%{
        background-position:-700px -1800px
    }
    10%{
        background-position:-900px -1800px
    }
    15%{
        background-position:-1000px -1800px
    }
    20%{
        background-position:-1100px -1800px
    }
    25%{
        background-position:-1200px -1800px
    }
    30%{
        background-position:-1300px -1800px
    }
    35%{
        background-position:-1400px -1800px
    }
    40%{
        background-position:-1500px -1800px
    }
    45%{
        background-position:-1890px -1500px
    }
    50%{
        background-position:-1890px -1600px
    }
    55%{
        background-position:-1890px -1700px
    }
    60%{
        background-position:0 -1800px
    }
    65%{
        background-position:-100px -1800px
    }
    70%{
        background-position:-200px -1800px
    }
    75%{
        background-position:-300px -1800px
    }
    80%{
        background-position:-400px -1800px
    }
    85%{
        background-position:-500px -1800px
    }
    90%{
        background-position:-600px -1800px
    }
    95%{
        background-position:-800px -1800px
    }
}
@-ms-keyframes outgoingSupport{
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 from{
        background-position:0 -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 5%{
        background-position:-700px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 10%{
        background-position:-900px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 15%{
        background-position:-1000px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 20%{
        background-position:-1100px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 25%{
        background-position:-1200px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 30%{
        background-position:-1300px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 35%{
        background-position:-1400px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 40%{
        background-position:-1500px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 45%{
        background-position:-1890px -1500px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 50%{
        background-position:-1890px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 55%{
        background-position:-1890px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 60%{
        background-position:0 -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 65%{
        background-position:-100px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 70%{
        background-position:-200px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 75%{
        background-position:-300px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 80%{
        background-position:-400px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 85%{
        background-position:-500px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 90%{
        background-position:-600px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownType5 95%{
        background-position:-800px -1800px
    }
}
@-o-keyframes outgoingSupport{
    from{
        background-position:0 -1600px
    }
    5%{
        background-position:-700px -1800px
    }
    10%{
        background-position:-900px -1800px
    }
    15%{
        background-position:-1000px -1800px
    }
    20%{
        background-position:-1100px -1800px
    }
    25%{
        background-position:-1200px -1800px
    }
    30%{
        background-position:-1300px -1800px
    }
    35%{
        background-position:-1400px -1800px
    }
    40%{
        background-position:-1500px -1800px
    }
    45%{
        background-position:-1890px -1500px
    }
    50%{
        background-position:-1890px -1600px
    }
    55%{
        background-position:-1890px -1700px
    }
    60%{
        background-position:0 -1800px
    }
    65%{
        background-position:-100px -1800px
    }
    70%{
        background-position:-200px -1800px
    }
    75%{
        background-position:-300px -1800px
    }
    80%{
        background-position:-400px -1800px
    }
    85%{
        background-position:-500px -1800px
    }
    90%{
        background-position:-600px -1800px
    }
    95%{
        background-position:-800px -1800px
    }
}
@keyframes outgoingSupport{
    from{
        background-position:0 -1600px
    }
    5%{
        background-position:-700px -1800px
    }
    10%{
        background-position:-900px -1800px
    }
    15%{
        background-position:-1000px -1800px
    }
    20%{
        background-position:-1100px -1800px
    }
    25%{
        background-position:-1200px -1800px
    }
    30%{
        background-position:-1300px -1800px
    }
    35%{
        background-position:-1400px -1800px
    }
    40%{
        background-position:-1500px -1800px
    }
    45%{
        background-position:-1890px -1500px
    }
    50%{
        background-position:-1890px -1600px
    }
    55%{
        background-position:-1890px -1700px
    }
    60%{
        background-position:0 -1800px
    }
    65%{
        background-position:-100px -1800px
    }
    70%{
        background-position:-200px -1800px
    }
    75%{
        background-position:-300px -1800px
    }
    80%{
        background-position:-400px -1800px
    }
    85%{
        background-position:-500px -1800px
    }
    90%{
        background-position:-600px -1800px
    }
    95%{
        background-position:-800px -1800px
    }
}
body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef{
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/map.png");
    background-position:-1600px -1800px;
    width:100px;
    height:100px;
    animation:outgoingSupportAndAttack 2.5s step-end infinite;
    -webkit-animation:outgoingSupportAndAttack 2.5s step-end infinite;
    -moz-animation:outgoingSupportAndAttack 2.5s step-end infinite
}
@-webkit-keyframes outgoingSupportAndAttack{
    from{
        background-position:-1600px -1800px
    }
    5%{
        background-position:-1990px -800px
    }
    10%{
        background-position:-1990px -1000px
    }
    15%{
        background-position:-1990px -1100px
    }
    20%{
        background-position:-1990px -1200px
    }
    25%{
        background-position:-1990px -1300px
    }
    30%{
        background-position:-1990px -1400px
    }
    35%{
        background-position:-1990px -1500px
    }
    40%{
        background-position:-1990px -1600px
    }
    45%{
        background-position:-1700px -1800px
    }
    50%{
        background-position:-1800px -1800px
    }
    55%{
        background-position:-1990px 0
    }
    60%{
        background-position:-1990px -100px
    }
    65%{
        background-position:-1990px -200px
    }
    70%{
        background-position:-1990px -300px
    }
    75%{
        background-position:-1990px -400px
    }
    80%{
        background-position:-1990px -500px
    }
    85%{
        background-position:-1990px -600px
    }
    90%{
        background-position:-1990px -700px
    }
    95%{
        background-position:-1990px -900px
    }
}
@-moz-keyframes outgoingSupportAndAttack{
    from{
        background-position:-1600px -1800px
    }
    5%{
        background-position:-1990px -800px
    }
    10%{
        background-position:-1990px -1000px
    }
    15%{
        background-position:-1990px -1100px
    }
    20%{
        background-position:-1990px -1200px
    }
    25%{
        background-position:-1990px -1300px
    }
    30%{
        background-position:-1990px -1400px
    }
    35%{
        background-position:-1990px -1500px
    }
    40%{
        background-position:-1990px -1600px
    }
    45%{
        background-position:-1700px -1800px
    }
    50%{
        background-position:-1800px -1800px
    }
    55%{
        background-position:-1990px 0
    }
    60%{
        background-position:-1990px -100px
    }
    65%{
        background-position:-1990px -200px
    }
    70%{
        background-position:-1990px -300px
    }
    75%{
        background-position:-1990px -400px
    }
    80%{
        background-position:-1990px -500px
    }
    85%{
        background-position:-1990px -600px
    }
    90%{
        background-position:-1990px -700px
    }
    95%{
        background-position:-1990px -900px
    }
}
@-ms-keyframes outgoingSupportAndAttack{
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef from{
        background-position:-1600px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 5%{
        background-position:-1990px -800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 10%{
        background-position:-1990px -1000px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 15%{
        background-position:-1990px -1100px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 20%{
        background-position:-1990px -1200px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 25%{
        background-position:-1990px -1300px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 30%{
        background-position:-1990px -1400px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 35%{
        background-position:-1990px -1500px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 40%{
        background-position:-1990px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 45%{
        background-position:-1700px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 50%{
        background-position:-1800px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 55%{
        background-position:-1990px 0
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 60%{
        background-position:-1990px -100px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 65%{
        background-position:-1990px -200px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 70%{
        background-position:-1990px -300px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 75%{
        background-position:-1990px -400px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 80%{
        background-position:-1990px -500px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 85%{
        background-position:-1990px -600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 90%{
        background-position:-1990px -700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.ownAttAndDef 95%{
        background-position:-1990px -900px
    }
}
@-o-keyframes outgoingSupportAndAttack{
    from{
        background-position:-1600px -1800px
    }
    5%{
        background-position:-1990px -800px
    }
    10%{
        background-position:-1990px -1000px
    }
    15%{
        background-position:-1990px -1100px
    }
    20%{
        background-position:-1990px -1200px
    }
    25%{
        background-position:-1990px -1300px
    }
    30%{
        background-position:-1990px -1400px
    }
    35%{
        background-position:-1990px -1500px
    }
    40%{
        background-position:-1990px -1600px
    }
    45%{
        background-position:-1700px -1800px
    }
    50%{
        background-position:-1800px -1800px
    }
    55%{
        background-position:-1990px 0
    }
    60%{
        background-position:-1990px -100px
    }
    65%{
        background-position:-1990px -200px
    }
    70%{
        background-position:-1990px -300px
    }
    75%{
        background-position:-1990px -400px
    }
    80%{
        background-position:-1990px -500px
    }
    85%{
        background-position:-1990px -600px
    }
    90%{
        background-position:-1990px -700px
    }
    95%{
        background-position:-1990px -900px
    }
}
@keyframes outgoingSupportAndAttack{
    from{
        background-position:-1600px -1800px
    }
    5%{
        background-position:-1990px -800px
    }
    10%{
        background-position:-1990px -1000px
    }
    15%{
        background-position:-1990px -1100px
    }
    20%{
        background-position:-1990px -1200px
    }
    25%{
        background-position:-1990px -1300px
    }
    30%{
        background-position:-1990px -1400px
    }
    35%{
        background-position:-1990px -1500px
    }
    40%{
        background-position:-1990px -1600px
    }
    45%{
        background-position:-1700px -1800px
    }
    50%{
        background-position:-1800px -1800px
    }
    55%{
        background-position:-1990px 0
    }
    60%{
        background-position:-1990px -100px
    }
    65%{
        background-position:-1990px -200px
    }
    70%{
        background-position:-1990px -300px
    }
    75%{
        background-position:-1990px -400px
    }
    80%{
        background-position:-1990px -500px
    }
    85%{
        background-position:-1990px -600px
    }
    90%{
        background-position:-1990px -700px
    }
    95%{
        background-position:-1990px -900px
    }
}
body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack{
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/map.png");
    background-position:-600px -1900px;
    width:100px;
    height:100px;
    animation:incomingAttack 4s step-end infinite;
    -webkit-animation:incomingAttack 4s step-end infinite;
    -moz-animation:incomingAttack 4s step-end infinite
}
@-webkit-keyframes incomingAttack{
    from{
        background-position:-600px -1900px
    }
    5%{
        background-position:-1890px -1400px
    }
    10%{
        background-position:-300px -1600px
    }
    15%{
        background-position:-400px -1600px
    }
    20%{
        background-position:-500px -1600px
    }
    25%{
        background-position:-600px -1600px
    }
    30%{
        background-position:-700px -1600px
    }
    35%{
        background-position:-800px -1600px
    }
    40%{
        background-position:-900px -1600px
    }
    45%{
        background-position:-500px -1900px
    }
    50%{
        background-position:-400px -1900px
    }
    55%{
        background-position:-300px -1900px
    }
    60%{
        background-position:-200px -1900px
    }
    65%{
        background-position:-100px -1900px
    }
    70%{
        background-position:0 -1900px
    }
    75%{
        background-position:-1990px -1800px
    }
    80%{
        background-position:-1990px -1700px
    }
    85%{
        background-position:-200px -1600px
    }
    90%{
        background-position:-100px -1600px
    }
    95%{
        background-position:-700px -1900px
    }
}
@-moz-keyframes incomingAttack{
    from{
        background-position:-600px -1900px
    }
    5%{
        background-position:-1890px -1400px
    }
    10%{
        background-position:-300px -1600px
    }
    15%{
        background-position:-400px -1600px
    }
    20%{
        background-position:-500px -1600px
    }
    25%{
        background-position:-600px -1600px
    }
    30%{
        background-position:-700px -1600px
    }
    35%{
        background-position:-800px -1600px
    }
    40%{
        background-position:-900px -1600px
    }
    45%{
        background-position:-500px -1900px
    }
    50%{
        background-position:-400px -1900px
    }
    55%{
        background-position:-300px -1900px
    }
    60%{
        background-position:-200px -1900px
    }
    65%{
        background-position:-100px -1900px
    }
    70%{
        background-position:0 -1900px
    }
    75%{
        background-position:-1990px -1800px
    }
    80%{
        background-position:-1990px -1700px
    }
    85%{
        background-position:-200px -1600px
    }
    90%{
        background-position:-100px -1600px
    }
    95%{
        background-position:-700px -1900px
    }
}
@-ms-keyframes incomingAttack{
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack from{
        background-position:-600px -1900px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 5%{
        background-position:-1890px -1400px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 10%{
        background-position:-300px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 15%{
        background-position:-400px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 20%{
        background-position:-500px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 25%{
        background-position:-600px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 30%{
        background-position:-700px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 35%{
        background-position:-800px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 40%{
        background-position:-900px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 45%{
        background-position:-500px -1900px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 50%{
        background-position:-400px -1900px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 55%{
        background-position:-300px -1900px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 60%{
        background-position:-200px -1900px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 65%{
        background-position:-100px -1900px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 70%{
        background-position:0 -1900px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 75%{
        background-position:-1990px -1800px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 80%{
        background-position:-1990px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 85%{
        background-position:-200px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 90%{
        background-position:-100px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.enemyAttack 95%{
        background-position:-700px -1900px
    }
}
@-o-keyframes incomingAttack{
    from{
        background-position:-600px -1900px
    }
    5%{
        background-position:-1890px -1400px
    }
    10%{
        background-position:-300px -1600px
    }
    15%{
        background-position:-400px -1600px
    }
    20%{
        background-position:-500px -1600px
    }
    25%{
        background-position:-600px -1600px
    }
    30%{
        background-position:-700px -1600px
    }
    35%{
        background-position:-800px -1600px
    }
    40%{
        background-position:-900px -1600px
    }
    45%{
        background-position:-500px -1900px
    }
    50%{
        background-position:-400px -1900px
    }
    55%{
        background-position:-300px -1900px
    }
    60%{
        background-position:-200px -1900px
    }
    65%{
        background-position:-100px -1900px
    }
    70%{
        background-position:0 -1900px
    }
    75%{
        background-position:-1990px -1800px
    }
    80%{
        background-position:-1990px -1700px
    }
    85%{
        background-position:-200px -1600px
    }
    90%{
        background-position:-100px -1600px
    }
    95%{
        background-position:-700px -1900px
    }
}
@keyframes incomingAttack{
    from{
        background-position:-600px -1900px
    }
    5%{
        background-position:-1890px -1400px
    }
    10%{
        background-position:-300px -1600px
    }
    15%{
        background-position:-400px -1600px
    }
    20%{
        background-position:-500px -1600px
    }
    25%{
        background-position:-600px -1600px
    }
    30%{
        background-position:-700px -1600px
    }
    35%{
        background-position:-800px -1600px
    }
    40%{
        background-position:-900px -1600px
    }
    45%{
        background-position:-500px -1900px
    }
    50%{
        background-position:-400px -1900px
    }
    55%{
        background-position:-300px -1900px
    }
    60%{
        background-position:-200px -1900px
    }
    65%{
        background-position:-100px -1900px
    }
    70%{
        background-position:0 -1900px
    }
    75%{
        background-position:-1990px -1800px
    }
    80%{
        background-position:-1990px -1700px
    }
    85%{
        background-position:-200px -1600px
    }
    90%{
        background-position:-100px -1600px
    }
    95%{
        background-position:-700px -1900px
    }
}
body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack{
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/map.png");
    background-position:-1000px -1600px;
    width:100px;
    height:100px;
    animation:incomingRobberAttack 4s step-end infinite;
    -webkit-animation:incomingRobberAttack 4s step-end infinite;
    -moz-animation:incomingRobberAttack 4s step-end infinite
}
@-webkit-keyframes incomingRobberAttack{
    from{
        background-position:-1000px -1600px
    }
    5%{
        background-position:-300px -1700px
    }
    10%{
        background-position:-500px -1700px
    }
    15%{
        background-position:-600px -1700px
    }
    20%{
        background-position:-700px -1700px
    }
    25%{
        background-position:-800px -1700px
    }
    30%{
        background-position:-900px -1700px
    }
    35%{
        background-position:-1000px -1700px
    }
    40%{
        background-position:-1100px -1700px
    }
    45%{
        background-position:-1100px -1600px
    }
    50%{
        background-position:-1200px -1600px
    }
    55%{
        background-position:-1300px -1600px
    }
    60%{
        background-position:-1400px -1600px
    }
    65%{
        background-position:-1500px -1600px
    }
    70%{
        background-position:-1600px -1600px
    }
    75%{
        background-position:-1700px -1600px
    }
    80%{
        background-position:0 -1700px
    }
    85%{
        background-position:-100px -1700px
    }
    90%{
        background-position:-200px -1700px
    }
    95%{
        background-position:-400px -1700px
    }
}
@-moz-keyframes incomingRobberAttack{
    from{
        background-position:-1000px -1600px
    }
    5%{
        background-position:-300px -1700px
    }
    10%{
        background-position:-500px -1700px
    }
    15%{
        background-position:-600px -1700px
    }
    20%{
        background-position:-700px -1700px
    }
    25%{
        background-position:-800px -1700px
    }
    30%{
        background-position:-900px -1700px
    }
    35%{
        background-position:-1000px -1700px
    }
    40%{
        background-position:-1100px -1700px
    }
    45%{
        background-position:-1100px -1600px
    }
    50%{
        background-position:-1200px -1600px
    }
    55%{
        background-position:-1300px -1600px
    }
    60%{
        background-position:-1400px -1600px
    }
    65%{
        background-position:-1500px -1600px
    }
    70%{
        background-position:-1600px -1600px
    }
    75%{
        background-position:-1700px -1600px
    }
    80%{
        background-position:0 -1700px
    }
    85%{
        background-position:-100px -1700px
    }
    90%{
        background-position:-200px -1700px
    }
    95%{
        background-position:-400px -1700px
    }
}
@-ms-keyframes incomingRobberAttack{
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack from{
        background-position:-1000px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 5%{
        background-position:-300px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 10%{
        background-position:-500px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 15%{
        background-position:-600px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 20%{
        background-position:-700px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 25%{
        background-position:-800px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 30%{
        background-position:-900px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 35%{
        background-position:-1000px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 40%{
        background-position:-1100px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 45%{
        background-position:-1100px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 50%{
        background-position:-1200px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 55%{
        background-position:-1300px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 60%{
        background-position:-1400px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 65%{
        background-position:-1500px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 70%{
        background-position:-1600px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 75%{
        background-position:-1700px -1600px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 80%{
        background-position:0 -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 85%{
        background-position:-100px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 90%{
        background-position:-200px -1700px
    }
    body.withAnimations #map #overlayMarkers .movementSprite i.robberAttack 95%{
        background-position:-400px -1700px
    }
}
@-o-keyframes incomingRobberAttack{
    from{
        background-position:-1000px -1600px
    }
    5%{
        background-position:-300px -1700px
    }
    10%{
        background-position:-500px -1700px
    }
    15%{
        background-position:-600px -1700px
    }
    20%{
        background-position:-700px -1700px
    }
    25%{
        background-position:-800px -1700px
    }
    30%{
        background-position:-900px -1700px
    }
    35%{
        background-position:-1000px -1700px
    }
    40%{
        background-position:-1100px -1700px
    }
    45%{
        background-position:-1100px -1600px
    }
    50%{
        background-position:-1200px -1600px
    }
    55%{
        background-position:-1300px -1600px
    }
    60%{
        background-position:-1400px -1600px
    }
    65%{
        background-position:-1500px -1600px
    }
    70%{
        background-position:-1600px -1600px
    }
    75%{
        background-position:-1700px -1600px
    }
    80%{
        background-position:0 -1700px
    }
    85%{
        background-position:-100px -1700px
    }
    90%{
        background-position:-200px -1700px
    }
    95%{
        background-position:-400px -1700px
    }
}
@keyframes incomingRobberAttack{
    from{
        background-position:-1000px -1600px
    }
    5%{
        background-position:-300px -1700px
    }
    10%{
        background-position:-500px -1700px
    }
    15%{
        background-position:-600px -1700px
    }
    20%{
        background-position:-700px -1700px
    }
    25%{
        background-position:-800px -1700px
    }
    30%{
        background-position:-900px -1700px
    }
    35%{
        background-position:-1000px -1700px
    }
    40%{
        background-position:-1100px -1700px
    }
    45%{
        background-position:-1100px -1600px
    }
    50%{
        background-position:-1200px -1600px
    }
    55%{
        background-position:-1300px -1600px
    }
    60%{
        background-position:-1400px -1600px
    }
    65%{
        background-position:-1500px -1600px
    }
    70%{
        background-position:-1600px -1600px
    }
    75%{
        background-position:-1700px -1600px
    }
    80%{
        background-position:0 -1700px
    }
    85%{
        background-position:-100px -1700px
    }
    90%{
        background-position:-200px -1700px
    }
    95%{
        background-position:-400px -1700px
    }
}
#map #overlayMarkers .fireworks{
    position:absolute;
    margin:-38px -64px;
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/map.png");
    background-position:-252px -1520px;
    width:126px;
    height:80px;
    animation:mapFireworks 5.6s step-end infinite;
    -webkit-animation:mapFireworks 5.6s step-end infinite;
    -moz-animation:mapFireworks 5.6s step-end infinite
}
@-webkit-keyframes mapFireworks{
    from{
        background-position:-252px -1520px
    }
    55.35714%{
        background-position:-756px -1440px
    }
    57.14286%{
        background-position:-126px -1440px
    }
    58.92857%{
        background-position:0 -1440px
    }
    60.71429%{
        background-position:-1764px -1356px
    }
    62.5%{
        background-position:-1638px -1356px
    }
    64.28571%{
        background-position:-1386px -1356px
    }
    66.07143%{
        background-position:-378px -1520px
    }
    67.85714%{
        background-position:-1008px -1356px
    }
    69.64286%{
        background-position:-126px -1520px
    }
    71.42857%{
        background-position:0 -1520px
    }
    73.21429%{
        background-position:-1764px -1440px
    }
    75%{
        background-position:-1638px -1440px
    }
    76.78571%{
        background-position:-1512px -1440px
    }
    78.57143%{
        background-position:-1386px -1440px
    }
    80.35714%{
        background-position:-1260px -1440px
    }
    82.14286%{
        background-position:-1134px -1440px
    }
    83.92857%{
        background-position:-1512px -1356px
    }
    85.71429%{
        background-position:-882px -1440px
    }
    87.5%{
        background-position:-630px -1440px
    }
    89.28571%{
        background-position:-504px -1440px
    }
    91.07143%{
        background-position:-378px -1440px
    }
    92.85714%{
        background-position:-1260px -1356px
    }
    94.64286%{
        background-position:-1134px -1356px
    }
    96.42857%{
        background-position:-1008px -1440px
    }
    98.21429%{
        background-position:-252px -1440px
    }
}
@-moz-keyframes mapFireworks{
    from{
        background-position:-252px -1520px
    }
    55.35714%{
        background-position:-756px -1440px
    }
    57.14286%{
        background-position:-126px -1440px
    }
    58.92857%{
        background-position:0 -1440px
    }
    60.71429%{
        background-position:-1764px -1356px
    }
    62.5%{
        background-position:-1638px -1356px
    }
    64.28571%{
        background-position:-1386px -1356px
    }
    66.07143%{
        background-position:-378px -1520px
    }
    67.85714%{
        background-position:-1008px -1356px
    }
    69.64286%{
        background-position:-126px -1520px
    }
    71.42857%{
        background-position:0 -1520px
    }
    73.21429%{
        background-position:-1764px -1440px
    }
    75%{
        background-position:-1638px -1440px
    }
    76.78571%{
        background-position:-1512px -1440px
    }
    78.57143%{
        background-position:-1386px -1440px
    }
    80.35714%{
        background-position:-1260px -1440px
    }
    82.14286%{
        background-position:-1134px -1440px
    }
    83.92857%{
        background-position:-1512px -1356px
    }
    85.71429%{
        background-position:-882px -1440px
    }
    87.5%{
        background-position:-630px -1440px
    }
    89.28571%{
        background-position:-504px -1440px
    }
    91.07143%{
        background-position:-378px -1440px
    }
    92.85714%{
        background-position:-1260px -1356px
    }
    94.64286%{
        background-position:-1134px -1356px
    }
    96.42857%{
        background-position:-1008px -1440px
    }
    98.21429%{
        background-position:-252px -1440px
    }
}
@-ms-keyframes mapFireworks{
    #map #overlayMarkers .fireworks from{
        background-position:-252px -1520px
    }
    #map #overlayMarkers .fireworks 55.35714%{
        background-position:-756px -1440px
    }
    #map #overlayMarkers .fireworks 57.14286%{
        background-position:-126px -1440px
    }
    #map #overlayMarkers .fireworks 58.92857%{
        background-position:0 -1440px
    }
    #map #overlayMarkers .fireworks 60.71429%{
        background-position:-1764px -1356px
    }
    #map #overlayMarkers .fireworks 62.5%{
        background-position:-1638px -1356px
    }
    #map #overlayMarkers .fireworks 64.28571%{
        background-position:-1386px -1356px
    }
    #map #overlayMarkers .fireworks 66.07143%{
        background-position:-378px -1520px
    }
    #map #overlayMarkers .fireworks 67.85714%{
        background-position:-1008px -1356px
    }
    #map #overlayMarkers .fireworks 69.64286%{
        background-position:-126px -1520px
    }
    #map #overlayMarkers .fireworks 71.42857%{
        background-position:0 -1520px
    }
    #map #overlayMarkers .fireworks 73.21429%{
        background-position:-1764px -1440px
    }
    #map #overlayMarkers .fireworks 75%{
        background-position:-1638px -1440px
    }
    #map #overlayMarkers .fireworks 76.78571%{
        background-position:-1512px -1440px
    }
    #map #overlayMarkers .fireworks 78.57143%{
        background-position:-1386px -1440px
    }
    #map #overlayMarkers .fireworks 80.35714%{
        background-position:-1260px -1440px
    }
    #map #overlayMarkers .fireworks 82.14286%{
        background-position:-1134px -1440px
    }
    #map #overlayMarkers .fireworks 83.92857%{
        background-position:-1512px -1356px
    }
    #map #overlayMarkers .fireworks 85.71429%{
        background-position:-882px -1440px
    }
    #map #overlayMarkers .fireworks 87.5%{
        background-position:-630px -1440px
    }
    #map #overlayMarkers .fireworks 89.28571%{
        background-position:-504px -1440px
    }
    #map #overlayMarkers .fireworks 91.07143%{
        background-position:-378px -1440px
    }
    #map #overlayMarkers .fireworks 92.85714%{
        background-position:-1260px -1356px
    }
    #map #overlayMarkers .fireworks 94.64286%{
        background-position:-1134px -1356px
    }
    #map #overlayMarkers .fireworks 96.42857%{
        background-position:-1008px -1440px
    }
    #map #overlayMarkers .fireworks 98.21429%{
        background-position:-252px -1440px
    }
}
@-o-keyframes mapFireworks{
    from{
        background-position:-252px -1520px
    }
    55.35714%{
        background-position:-756px -1440px
    }
    57.14286%{
        background-position:-126px -1440px
    }
    58.92857%{
        background-position:0 -1440px
    }
    60.71429%{
        background-position:-1764px -1356px
    }
    62.5%{
        background-position:-1638px -1356px
    }
    64.28571%{
        background-position:-1386px -1356px
    }
    66.07143%{
        background-position:-378px -1520px
    }
    67.85714%{
        background-position:-1008px -1356px
    }
    69.64286%{
        background-position:-126px -1520px
    }
    71.42857%{
        background-position:0 -1520px
    }
    73.21429%{
        background-position:-1764px -1440px
    }
    75%{
        background-position:-1638px -1440px
    }
    76.78571%{
        background-position:-1512px -1440px
    }
    78.57143%{
        background-position:-1386px -1440px
    }
    80.35714%{
        background-position:-1260px -1440px
    }
    82.14286%{
        background-position:-1134px -1440px
    }
    83.92857%{
        background-position:-1512px -1356px
    }
    85.71429%{
        background-position:-882px -1440px
    }
    87.5%{
        background-position:-630px -1440px
    }
    89.28571%{
        background-position:-504px -1440px
    }
    91.07143%{
        background-position:-378px -1440px
    }
    92.85714%{
        background-position:-1260px -1356px
    }
    94.64286%{
        background-position:-1134px -1356px
    }
    96.42857%{
        background-position:-1008px -1440px
    }
    98.21429%{
        background-position:-252px -1440px
    }
}
@keyframes mapFireworks{
    from{
        background-position:-252px -1520px
    }
    55.35714%{
        background-position:-756px -1440px
    }
    57.14286%{
        background-position:-126px -1440px
    }
    58.92857%{
        background-position:0 -1440px
    }
    60.71429%{
        background-position:-1764px -1356px
    }
    62.5%{
        background-position:-1638px -1356px
    }
    64.28571%{
        background-position:-1386px -1356px
    }
    66.07143%{
        background-position:-378px -1520px
    }
    67.85714%{
        background-position:-1008px -1356px
    }
    69.64286%{
        background-position:-126px -1520px
    }
    71.42857%{
        background-position:0 -1520px
    }
    73.21429%{
        background-position:-1764px -1440px
    }
    75%{
        background-position:-1638px -1440px
    }
    76.78571%{
        background-position:-1512px -1440px
    }
    78.57143%{
        background-position:-1386px -1440px
    }
    80.35714%{
        background-position:-1260px -1440px
    }
    82.14286%{
        background-position:-1134px -1440px
    }
    83.92857%{
        background-position:-1512px -1356px
    }
    85.71429%{
        background-position:-882px -1440px
    }
    87.5%{
        background-position:-630px -1440px
    }
    89.28571%{
        background-position:-504px -1440px
    }
    91.07143%{
        background-position:-378px -1440px
    }
    92.85714%{
        background-position:-1260px -1356px
    }
    94.64286%{
        background-position:-1134px -1356px
    }
    96.42857%{
        background-position:-1008px -1440px
    }
    98.21429%{
        background-position:-252px -1440px
    }
}
.Firefox #map #overlayMarkers .fireworks{
    filter:none !important
}
.noAnimations #map #overlayMarkers .fireworks{
    display:none !important
}
#map #overlayMarkers .wwMist{
    position:absolute;
    background-image:url("//iwstatic.g.bsrv.su/img/map/overlay/worldWonderMist.png");
    width:1890px;
    height:1020px;
    transform:translate(-50%,-43%)
}
#map #overlayMarkers .clouds{
    position:absolute;
    z-index:2;
    top:-1500px;
    left:-1970px;
    border:1000px solid #f7fbf9
}
#map #overlayMarkers .mainVillage{
    position:absolute;
    z-index:2;
    margin-left:25px;
    margin-top:-15px;
    width:26px;
    height:26px
}
#map #overlayMarkers .mainVillage i{
    margin:4px 5px;
    z-index:2
}
#map #overlayMarkers .mainVillage:after{
    content:"";
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:auto;
    height:auto;
    background-color:rgba(235,235,235,0.5);
    border:1px solid #b8b2a9;
    -webkit-box-shadow:inset 0 0 0 1px #fff;
    -moz-box-shadow:inset 0 0 0 1px #fff;
    box-shadow:inset 0 0 0 1px #fff;
    -webkit-border-radius:50% 50% 50% 0;
    -moz-border-radius:50% 50% 50% 0;
    -ms-border-radius:50% 50% 50% 0;
    -o-border-radius:50% 50% 50% 0;
    border-radius:50% 50% 50% 0;
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    transform:rotate(45deg)
}
#map #overlayMarkers.zoomLevel2 .movementSprite i{
    -webkit-transform:scale(0.5);
    -moz-transform:scale(0.5);
    -ms-transform:scale(0.5);
    -o-transform:scale(0.5);
    transform:scale(0.5);
    margin-top:-58px
}
#map #overlayMarkers.zoomLevel2 .fireworks{
    transform:scale(0.7)
}
#map #overlayMarkers.zoomLevel2 .wwMist{
    transform:scale(0.5);
    transform-origin:-50% -43%
}
html:not(.Edge) .withAnimations #map #overlayMarkers.zoomLevel2 .wwMist{
    animation:none;
    -webkit-animation:none;
    -moz-animation:none
}
#map #tileInformation{
    position:absolute;
    z-index:2;
    width:350px;
    height:64px;
    left:50%;
    top:100px;
    margin-left:-175px;
    border:1px solid #fff;
    -webkit-box-shadow:0 0 1px rgba(0,0,0,0.7);
    -moz-box-shadow:0 0 1px rgba(0,0,0,0.7);
    box-shadow:0 0 1px rgba(0,0,0,0.7);
    background-image:-owg-linear-gradient(#888 0,#ebebeb 12%,rgba(235,235,235,0.1) 100%);
    background-image:-webkit-linear-gradient(#888 0,#ebebeb 12%,rgba(235,235,235,0.1) 100%);
    background-image:-moz-linear-gradient(#888 0,#ebebeb 12%,rgba(235,235,235,0.1) 100%);
    background-image:-o-linear-gradient(#888 0,#ebebeb 12%,rgba(235,235,235,0.1) 100%);
    background-image:linear-gradient(#888 0,#ebebeb 12%,rgba(235,235,235,0.1) 100%);
    padding-top:25px
}
#map #tileInformation.coordsOnly{
    height:32px
}
#map #tileInformation i{
    margin:0 2px
}
#map #tileInformation .owner .languageFlag{
    position:absolute;
    left:11px;
    top:5px;
    border:1px solid #b8b2a9
}
#map #tileInformation .owner i{
    top:-2px
}
#map #tileInformation .contentBoxHeader{
    position:relative;
    border:1px solid #b8b2a9;
    background-color:rgba(255,255,255,0.7);
    margin:0 3px;
    width:auto;
    padding:5px 10px;
    line-height:16px
}
#map #tileInformation .contentBoxHeader .villageName{
    width:174px;
    text-align:center;
    position:absolute;
    left:86px
}
#map #tileInformation .contentBoxHeader .coordinates{
    font-weight:normal
}
#map #tileInformation .contentBoxHeader .mainVillageIndicator{
    float:right
}
#map #tileInformation .contentBoxHeader .additionalInfo{
    float:right
}
#map #tileInformation .contentBoxBody{
    background-color:rgba(255,255,255,0.7);
    margin:0 3px;
    width:auto;
    height:33px;
    border:1px solid #b8b2a9;
    border-top:0;
    padding:3px 3px;
    text-align:center;
    line-height:26px;
    position:relative
}
#map #tileInformation .contentBoxBody .resources{
    display:inline-block
}
#map #tileInformation .contentBoxBody .influenceBox{
    position:absolute;
    right:3px;
    top:3px
}
#map #tileInformation .reportsContainer{
    position:absolute;
    top:100%;
    margin-top:-4px;
    width:100%;
    text-align:center
}
#map #tileInformation .reportsContainer .reportsDecoration{
    display:inline-block;
    position:relative;
    line-height:26px
}
#map #tileInformation .reportsContainer .reportsDecoration .inner{
    display:inline-block;
    padding:0 5px;
    box-sizing:border-box;
    background-color:rgba(255,255,255,0.85);
    border-top:1px solid #b8b2a9;
    border-bottom:1px solid #b8b2a9
}
#map #tileInformation .reportsContainer .reportsDecoration .carry{
    margin-left:10px
}
#map #tileInformation .reportsContainer .reportsDecoration:before,#map #tileInformation .reportsContainer .reportsDecoration:after{
    content:"";
    position:absolute;
    top:0;
    box-sizing:border-box;
    border-top:1px solid #b8b2a9;
    width:12px;
    height:27px
}
#map #tileInformation .reportsContainer .reportsDecoration:before{
    left:-5px;
    border-left:1px solid #b8b2a9;
    background-image:linear-gradient(to bottom right,rgba(255,255,255,0.85),rgba(255,255,255,0.85) 50%,transparent 50%,transparent);
    -webkit-transform:skewX(23deg);
    -moz-transform:skewX(23deg);
    -ms-transform:skewX(23deg);
    -o-transform:skewX(23deg);
    transform:skewX(23deg)
}
#map #tileInformation .reportsContainer .reportsDecoration:after{
    right:-5px;
    border-right:1px solid #b8b2a9;
    background-image:linear-gradient(to bottom left,rgba(255,255,255,0.85),rgba(255,255,255,0.85) 50%,transparent 50%,transparent);
    -webkit-transform:skewX(-23deg);
    -moz-transform:skewX(-23deg);
    -ms-transform:skewX(-23deg);
    -o-transform:skewX(-23deg);
    transform:skewX(-23deg)
}
#map .infoMovements{
    position:absolute;
    top:125px;
    left:50%;
    margin-left:145px;
    z-index:1000
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

//import Zig from '@/assets/js/map/zig';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Map | IW'});
	},
	data: () => ({
		min: null,
		max: null,
		map: [],
		
		mapobj: null,
		
		action: {
			step: 0,
			moving: false,
			x1: null,
			y1: null,
			x2: null,
			y2: null,
		},
		
		
		
		tiInfos: {
			coordsOnly: false,
			coord: null,
			isMainVillage: true,
			villageName: 'Оазис', //'Покинутая долина', 'Новая деревня', 'Логово разбойников I'
			playerName: 'lexinzector',
			population: 0,
			oasis: {
				oasisStatus: 'Village.OASIS_STATUS_OCCUPIED', //Village.OASIS_STATUS_OCCUPIED, Village.OASIS_STATUS_WILD
			},
			oasisBonus: {
				wood: 25,
				clay: 25,
				/*iron: 25,
				crop: 25,*/
			},
			oasisDetails: {
				filteredTroops: [
					{
						unitType: 1,
						count: 5,
					},
					{
						unitType: 2,
						count: 5,
					},
					/*{
						unitType: 3,
						count: 6,
					},
					{
						unitType: 4,
						count: 3,
					},*/
				],
			},
			resDistribution: {
				wood: 4,
				clay: 4,
				/*iron: 4,
				crop: 6,*/
			},
			robberVillageDetails: {
				filteredTroops: [
					{
						unitType: 1,
						count: 1,
					},
					{
						unitType: 2,
						count: 1,
					},
				],
			},
			country: 'ru',
		},
		zoomLevel: 1,
		nolink: 0,
		kingdomId: 1,
		kingdomName: 'Разбой',
		multiLanguageServer: true,
		
		filters: {
			showFilters: false,
		},
		goToLocation: {
			show: false,
		},
	}),
	computed: {},
	methods: {
		arr_range(start, end){
			let arr = [];
			if(start != null && end != null){
				for(let i = start; i <= end; i++){
					arr.push(i);
				}
			}
			return arr;
		},
		async loadData(){
			await fetch('/api/mapdata', {
				mode: 'cors',
				credentials: 'include',
				headers: {
					Authorization: 'Bearer '+this.authModel.token,
				},
			}).then(stream => stream.json()).then(data => {
				this.min = data.min;
				this.max = data.max;
				this.map = data.map;
			}).catch(error => {
				console.error(error);
			});
		},
		move(x, y){
			if(this.action.step == 0){
				if(this.map[x][y].idIsland != null){
					this.action.step = 1;
					this.action.x1 = x;
					this.action.y1 = y;
					this.action.moving = true;
				}
			} else if(this.action.step == 1){
				if(this.map[x][y].idIsland == null && this.map[x][y].idUser == null){
					this.action.x2 = x;
					this.action.y2 = y;
					//if(confirm('Переместить '+this.action.x1+' | '+this.action.y1+' в '+this.action.x2+' | '+this.action.y2+'?')){
						fetch('/api/mapdata/move', {
							mode: 'cors',
							credentials: 'include',
							method: 'POST',
							body: JSON.stringify({
								x1: this.action.x1,
								y1: this.action.y1,
								x2: this.action.x2,
								y2: this.action.y2,
							}),
							headers: {
								'Content-Type': 'application/json',
								Authorization: 'Bearer '+this.authModel.token,
							},
						}).then(stream => stream.json()).then(data => {
							this.loadData();
						}).catch(error => {
							console.error(error);
						});
					//}
				}
				this.action.step = 0;
				this.action.x1 = null;
				this.action.y1 = null;
				this.action.x2 = null;
				this.action.y2 = null;
				this.action.moving = false;
			}
		},
		
	},
	created(){
		this.gameModel.buildingQueueShow = false;
	},
	async mounted(){
		this.mapobj = new this.worldmapModel.wm.Core(
			document.querySelector('.map-container').clientWidth,
			document.querySelector('.map-container').clientHeight,
			0, 0,
			80, 55
			//60, 41
			//50, 34
			//40, 28
			//30, 21
			//20, 14
		);
		await this.loadData();
		this.mapobj._load_map(this.map);
		this.mapobj.addEventListener('select', (data) => {
			//console.log(data);
			//console.log(map._data[data.x][data.y]);
			//this.tiInfos.coord.x = data.x;
			//this.tiInfos.coord.y = data.y;
		});
		this.mapobj.addEventListener('focus', (data) => {
			//console.log('focus');
			//console.log(data);
			let mapelem = this.mapobj.getCell(data.x, data.y).data;
			
			this.tiInfos.coord = data;
			this.tiInfos.villageName = mapelem.name;
			this.tiInfos.playerName = mapelem.username;
		});
		/*this.mapobj.addEventListener('drag', () => {
			console.log('drag');
		});*/
		window.addEventListener('resize', () => {
			this.mapobj._resize(
				document.querySelector('.map-container').clientWidth,
				document.querySelector('.map-container').clientHeight
			);
		});
	},
	beforeUnmount(){
		this.mapobj._destroy();
	},
	components: {},
}
</script>
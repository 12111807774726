<template>
	<div v-if="gameModel.loadingScreenOpen">
		<div id="loadingOverlay"></div>
		<div class="loadingScreen">
			<div class="centerArea">
				<div class="highlight">
					<div class="logo"></div>
				</div>
				<div class="loadingBar">
					<div class="progressBar">
						<div class="sword transparent"></div>
						<div class="sword fullVisible" :style="'width: '+getWidthByPercent(percent, 211)+'px;'"></div>
					</div>
					<div class="loadingPercent">
						<span class="loadingTextNumber">{{percent}}</span><span>%</span>
					</div>
				</div>
				<div class="loadingText">
					<div class="action backToLobby" v-if="backToLobby">НАЗАД В ЛОББИ</div>
					<div class="action loadingGame" v-else>ЗАГРУЖАЕТСЯ</div>
					<!--<div class="action loadingGame errorMessage">An error has occurred whilst loading "fenster-js.js". Please reload the page. If the problem persists, please contact our game support.</div>-->
					<div class="avatarOnGameworld">{{authModel.userProfile.login}} на ru1</div>
					<hr>
					<div class="randomText backToLobby" v-if="backToLobby">Спасибо за игру, ждем вас снова!</div>
					<div class="randomText" v-else>{{randomText}}</div>
					<div class="errorMessage"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">
#loadingOverlay{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    z-index: 1060;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 14px;
    background: url(https://iwstatic.g.bsrv.su/img/illustration/loadingScreen/loading_screen_logo.png) center no-repeat, url(//iwstatic.g.bsrv.su/resources/styles/brown/design/x-panel-body_bg.gif) repeat;
	/*background-color: #9ca55b;*/
}
.loadingScreen{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1060;
    position: fixed;
	background: url(https://iwstatic.g.bsrv.su/resources/styles/brown/design/x-panel-body_bg.gif) repeat;
	/*background-color: #9ca55b;*/
    color: #463f39;
    direction: ltr;
}
.loadingScreen .centerArea{
    width: 100%;
    height: 1000px;
    position: absolute;
    top: 50%;
    margin-top: -500px;
}
.loadingScreen .centerArea .highlight{
    width: 100%;
    max-width: 647px;
    background: -moz-radial-gradient(50% 50%, circle closest-side, #fff 0%, rgba(255,255,255,0) 100%);
    background-image: -webkit-radial-gradient(50% 50%, circle closest-side, #fff 0%, rgba(255,255,255,0) 100%);
    background: -ms-radial-gradient(50% 50%, circle closest-side, #fff 0%, rgba(255,255,255,0) 100%);
    background: radial-gradient(50% 50%, circle closest-side, #fff 0%, rgba(255,255,255,0) 100%);
    height: 629px;
    margin: 0 auto;
    top: 21px;
    position: relative;
}
.loadingScreen .centerArea .logo{
    background-image: url(https://iwstatic.g.bsrv.su/img/illustration/loadingScreen/loading_screen_logo.png);
    width: 285px;
    height: 202px;
    margin: 0 auto;
    position: relative;
    top: 188px;
}
.loadingScreen .centerArea .loadingBar{
    background-color: #ccbb8f;
    height: 58px;
    position: absolute;
    top: 50%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.33);
    margin-top: -29px;
    text-align: center;
}
.loadingScreen .centerArea .loadingBar .progressBar{
    width: 211px;
    height: 58px;
    position: relative;
    display: inline-block;
}
.loadingScreen .loadingBar .progressBar .sword.transparent{
    opacity: 0.4;
    width: 211px;
}
.loadingScreen .loadingBar .progressBar .sword{
    height: 100%;
    width: 48px;
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM8AAAAvBAMAAAC264PuAAAAMFBMVEUAAADm5ubZ19dlUET08/PNy8uqpae1sbPCv8Gclpp5Y1WCeXW+u7mddlujmpa2h2W5fRWkAAAAAXRSTlMAQObYZgAAAkhJREFUWMPV2MFr01AcB/AfvJu3dxg48NIMpaJIZpRJqgel/4CWN1BPOnwdbAi2aER7sVAJehSEXEYht4DaLq7t3BBkIAPjyZsQBIsDC/YPGEjMS7KRJ2SO8fJkn0Ogh/KlL7/3+71X+H+QBlKg0gikQKsLIAV6T1WQAfXmZQUtdkAG1KvWQQbUN6ZAAhZ0CmRA696sjGpgQU05QQPzSRtyof0d1BxCHtCSzRdDi1T234AzOQz3DkoPn6tcr1vEk1oG3WHWmFUrtDQeU0oNo+F5LRJSFAVjXN51M71ar8m1Dhdkvby3RWnVMDxv0zSTrx/QSS1VZYRMcZ3h59ZtLMwZGxJohZBJNSqKWH84c18R58RO0pEfpxVFSxnMXWgdJ+LMuhDRa/XlgpsKWqmfJ0VToKeuGgU13Rk85zi6k1iun1WKhkgPoiT9ql3Cz3z/lf81fnQ3pvFRKtY7NQwqaCV8I/j9JtgOAvZ4WzlXnrAEs9X4F30Otr8FPkuLg66UxXqksqVzL+Jf/hffv8QWz/G7lWmMhcZcHqlR1W10cVh1jq07NqsJ9o4KWKCJF2q0j74X+X3Eqk5k0DF3pzMoSrozsA3bFNoZsnpd/261pghz3U537zbXVCn9YIXG47iHR0PANFvkID5pe8yjeW4e8ZzEWsJKplI8lxg2W3YtaNxh2wbuo9GowH79e8Jmnxl69M4Q8seCbrUhfyyopkL+WFD2SfVwnr3l3SYaku5H65sdkIAFSbrDDj7Kuiw/BimQPoK9Hbb/gvLyB0UOPQQTizs9AAAAAElFTkSuQmCC) no-repeat left center;
}
.loadingScreen .loadingBar .progressBar .sword{
    height: 100%;
    width: 48px;
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM8AAAAvBAMAAAC264PuAAAAMFBMVEUAAADm5ubZ19dlUET08/PNy8uqpae1sbPCv8Gclpp5Y1WCeXW+u7mddlujmpa2h2W5fRWkAAAAAXRSTlMAQObYZgAAAkhJREFUWMPV2MFr01AcB/AfvJu3dxg48NIMpaJIZpRJqgel/4CWN1BPOnwdbAi2aER7sVAJehSEXEYht4DaLq7t3BBkIAPjyZsQBIsDC/YPGEjMS7KRJ2SO8fJkn0Ogh/KlL7/3+71X+H+QBlKg0gikQKsLIAV6T1WQAfXmZQUtdkAG1KvWQQbUN6ZAAhZ0CmRA696sjGpgQU05QQPzSRtyof0d1BxCHtCSzRdDi1T234AzOQz3DkoPn6tcr1vEk1oG3WHWmFUrtDQeU0oNo+F5LRJSFAVjXN51M71ar8m1Dhdkvby3RWnVMDxv0zSTrx/QSS1VZYRMcZ3h59ZtLMwZGxJohZBJNSqKWH84c18R58RO0pEfpxVFSxnMXWgdJ+LMuhDRa/XlgpsKWqmfJ0VToKeuGgU13Rk85zi6k1iun1WKhkgPoiT9ql3Cz3z/lf81fnQ3pvFRKtY7NQwqaCV8I/j9JtgOAvZ4WzlXnrAEs9X4F30Otr8FPkuLg66UxXqksqVzL+Jf/hffv8QWz/G7lWmMhcZcHqlR1W10cVh1jq07NqsJ9o4KWKCJF2q0j74X+X3Eqk5k0DF3pzMoSrozsA3bFNoZsnpd/261pghz3U537zbXVCn9YIXG47iHR0PANFvkID5pe8yjeW4e8ZzEWsJKplI8lxg2W3YtaNxh2wbuo9GowH79e8Jmnxl69M4Q8seCbrUhfyyopkL+WFD2SfVwnr3l3SYaku5H65sdkIAFSbrDDj7Kuiw/BimQPoK9Hbb/gvLyB0UOPQQTizs9AAAAAElFTkSuQmCC) no-repeat left center;
}
.loadingScreen .centerArea .loadingBar .loadingPercent{
    display: inline-block;
    font-size: 22px;
    left: 2px;
    bottom: 19px;
    position: relative;
    width: 50px;
    color: #f1f0ee;
}
.loadingScreen .centerArea .loadingText{
    text-align: center;
    position: relative;
    bottom: 56px;
}
.loadingScreen .centerArea .loadingText .action.backToLobby{
    /*display: none;*/
}

.loadingScreen .centerArea .loadingText .action{
    font-weight: bold;
    font-size: 22px;
}
.loadingScreen .centerArea .loadingText > div{
    height: 40px;
}
.loadingScreen .centerArea .loadingText .action{
    font-weight: bold;
    font-size: 22px;
}
.loadingScreen .centerArea .loadingText > div{
    height: 40px;
}
.loadingScreen .centerArea .loadingText .avatarOnGameworld{
    font-size: 22px;
    line-height: 0;
}
.loadingScreen .centerArea .loadingText > div{
    height: 40px;
}
.loadingScreen .centerArea .loadingText hr{
    width: 560px;
    border-top: 1px solid #7b736d;
    border-bottom: 1px solid #ABA7A4;
    margin-top: -13px;
    display: block;
}
.loadingScreen .centerArea .loadingText .randomText.backToLobby{
    /*display: none;*/
}
.loadingScreen .centerArea .loadingText .randomText{
    font-size: 18px;
    font-style: italic;
    line-height: 35px;
}
.loadingScreen .centerArea .loadingText > div{
    height: 40px;
}
.loadingScreen .centerArea .loadingText .randomText{
    font-size: 18px;
    font-style: italic;
    line-height: 35px;
}
.loadingScreen .centerArea .loadingText > div{
    height: 40px;
}
.loadingScreen .centerArea .loadingText .errorMessage{
    padding-bottom: 31px;
    line-height: 22px;
}
.loadingScreen .centerArea .loadingText > div{
    height: 40px;
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		percent: 0,
		backToLobby: false,
	}),
	computed: {
		randomText(){
			let randomTexts = [
				'Войска созываются',
				'Сокровища полируются',
				'Чудеса света возводятся',
				'Ресурсы собираются',
				'Мечи точатся',
			];
			return randomTexts[Math.floor(Math.random() * randomTexts.length)];
		},
	},
	methods: {
		getWidthByPercent(percent, maxWidth){
			percent = percent || 0;
			maxWidth = maxWidth || 0;
			return (((maxWidth / 100) * percent)||0).toFixed(1);
		},
		start(){
			this.percent++;
			if(this.percent < 99){
				let obj = this;
				setTimeout(function(){
					obj.start();
				}, 25);
			}
		},
	},
	beforeMount(){
		this.start();
	},
	components: {},
}
</script>
<template>
	<div class="chatMenu" v-if="gameModel.chatMenuOpen">
		<div class="menuItems">
			<div ng-repeat="(key, item) in menuItems" ng-if="item.visible" :class="['menuItem', 'clickable', {'selected': chatMenuActiveTab == 1}, {'unread': chatMenuUnreadTabs.indexOf(1) != -1}]" @click="onTabActive(1)" content="Чат" v-tippy="{placement: 'bottom'}">
				<i class="chat_group_small_illu"></i>
			</div>
			<div ng-repeat="(key, item) in menuItems" ng-if="item.visible" :class="['menuItem', 'clickable', {'selected': chatMenuActiveTab == 2}, {'unread': chatMenuUnreadTabs.indexOf(2) != -1}]" @click="onTabActive(2)" content="Помощь" v-tippy="{placement: 'bottom'}">
				<i class="chat_beginners_small_illu"></i>
			</div>
			<div ng-repeat="(key, item) in menuItems" ng-if="item.visible" :class="['menuItem', 'clickable', {'selected': chatMenuActiveTab == 3}, {'unread': chatMenuUnreadTabs.indexOf(3) != -1}]" @click="onTabActive(3)" content="Общий чат" v-tippy="{placement: 'bottom'}">
				<i class="chat_global_small_illu"></i>
			</div>
			<div ng-repeat="(key, item) in menuItems" ng-if="item.visible" :class="['menuItem', 'clickable', {'selected': chatMenuActiveTab == 4}, {'unread': chatMenuUnreadTabs.indexOf(4) != -1}]" @click="onTabActive(4)" content="Королевство" v-tippy="{placement: 'bottom'}">
				<i class="chat_kingdom_inactive_small_illu"></i>
			</div>
			<div ng-repeat="(key, item) in menuItems" ng-if="item.visible" :class="['menuItem', 'clickable', {'selected': chatMenuActiveTab == 5}, {'unread': chatMenuUnreadTabs.indexOf(5) != -1}]" @click="onTabActive(5)" content="Королевство" v-tippy="{placement: 'bottom'}">
				<i ng-if="!item.chatRoom.data.joined" class="chat_secret_society_inactive_small_illu"></i>
			</div>
		</div>
		<div v-if="chatMenuActiveTab == 1">
			<div class="chatRoomHeader">
				<span>
					<span>Чат</span>
					<span> | <a class="clickable">Русский</a></span>
				</span>
				<div class="options">
					<a class="iconButton clickable">
						<i class="symbol_arrowDown_tiny_illu"></i>
					</a>
					<!--
					<div class="moreDropDownBody dropdownBody" style="top: 222px; left: 260px; display: block;">
						<ul>
							<li ng-repeat="option in options" clickable="leaveChat(); closeMoreDropdown()" class="clickable">Покинуть чат</li>
						</ul>
					</div>
					-->
				</div>
			</div>
			<div class="chatWindow">
				<div class="chat-room-notifications">
					<div ng-if="text" class="roomNotification red" ng-class="{red: [ChatNotification.PLAYER_LEFT, ChatNotification.PLAYER_OFFLINE].indexOf(type) !== -1}">
						<span>Игрок Стейнар вышел из сети</span>
					</div>
				</div>
				<div class="chatBody scrollable">
					<div class="scrollContentOuterWrapper" style="width: 360px;">
						<div class="scrollContent" style="width: 363px; overflow-y: auto;">
							<div class="scrollContentInnerWrapper" style="width: auto;">
								<ul>
									<li>
										<div class="chat-time-divider">
											<h6 class="divider">
												<span class="fadeIn"></span>
												<span class="label"><span>Последние 7 дней</span></span>
												<span class="fadeOut"></span>
											</h6>
										</div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage female">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus">
													<i class="onlineStatus chat_status_afk_tiny_illu"></i>
												</div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">Rufus</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">12:24</span>
										</div>
										<div class="lineBody">
											<span>!</span>
										</div>
									</li>
									<li>
										<div class="chat-time-divider"></div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage female">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus">
													<i class="onlineStatus chat_status_afk_tiny_illu"></i>
												</div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">Zerrod</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
												<div class="moreDropDownBody dropdownBody local">
													<ul>
														<li class="clickable">Личная беседа</li>
														<li class="clickable">Перейти к профилю</li>
														<li class="clickable">Сообщить об игроке</li>
													</ul>
												</div>
											</div>
											<span class="date">12:28</span>
										</div>
										<div class="lineBody">
											<span>.!.</span>
										</div>
									</li>
									<li>
										<div class="chat-time-divider"></div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage male">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus">
													<i class="onlineStatus chat_status_online_tiny_illu"></i>
												</div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">Борк</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">14:15</span>
										</div>
										<div class="lineBody">
											<span>;?;</span>
										</div>
									</li>
									<li>
										<div class="chat-time-divider"></div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage female">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus">
													<i class="onlineStatus chat_status_afk_tiny_illu"></i>
												</div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">Zerrod</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">20:27</span>
										</div>
										<div class="lineBody">
											<span>.!.</span>
										</div>
									</li>
									<li>
										<div class="chat-time-divider"></div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage male">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus"></div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">AssKell</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">20:28</span>
										</div>
										<div class="lineBody">
											<span>фу такое показывать</span>
										</div>
									</li><li>
										<div class="chat-time-divider"></div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage female">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus">
													<i class="onlineStatus chat_status_afk_tiny_illu"></i>
												</div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">Zerrod</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">20:28</span>
										</div>
										<div class="lineBody">
											<span>З===Э</span>
										</div>
										<div class="lineBody samePlayerAndTime">
											<span>не. не так</span>
										</div>
										<div class="lineBody samePlayerAndTime">
											<span>8===э</span>
											<br/>
											<span>во</span>
										</div>
									</li>
									<li>
										<div class="chat-time-divider"></div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage male">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus"></div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">Кривбасс</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">23:21</span>
										</div>
										<div class="lineBody">
											<span>Есть кто?</span>
										</div>
									</li>
									<li>
										<div class="chat-time-divider">
											<h6 class="divider" ng-if="timeTitle != ''">
												<span class="fadeIn"></span>
												<span class="label"><span>Вчера</span></span>
												<span class="fadeOut"></span>
											</h6>
										</div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage male">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus">
													<i class="onlineStatus chat_status_online_tiny_illu"></i>
												</div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">zakovi</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">15:06</span>
										</div>
										<div class="lineBody">
											<span>что с пленными делать?</span>
										</div>
									</li>
									<li>
										<div class="chat-time-divider"></div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage female">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus"></div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">Шапокляк</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">15:09</span>
										</div>
										<div class="lineBody">
											<span>к стенке и расстреливать</span>
										</div>
									</li>
									<li>
										<div class="chat-time-divider"></div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage male">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus"></div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">BlackTaku</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">15:16</span>
										</div>
										<div class="lineBody">
											<span>принести в жертву во имя злого бога</span>
										</div>
									</li>
									<li>
										<div class="chat-time-divider"></div>
									</li>
									<li class="line otherPlayer">
										<div class="chat-room-member">
											<div class="chatMember clickable">
												<div class="playerBox">
													<div class="avatar-image playerAvatar">
														<div class="heroImage male">
															<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
														</div>
													</div>
												</div>
												<div class="chat-online-status chatStatus"></div>
											</div>
										</div>
										<div class="lineHead">
											<span class="name truncated">azaza</span>
											<div class="options">
												<a class="iconButton clickable">
													<i class="symbol_arrowDown_tiny_illu"></i>
												</a>
											</div>
											<span class="date">20:06</span>
										</div>
										<div class="lineBody">
											<span>2-й уровень предметов на ауке</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="inputWrapper">
					<i class="chat_insert_message_small_flat writeMessage"></i>
					<span role="status" class="ui-helper-hidden-accessible"></span>
					<textarea class="chatInput ui-autocomplete-input" autocomplete="off" placeholder="Нажми здесь, чтобы ответить"></textarea>
				</div>
			</div>
			<div class="chatMembers">
				<div class="prev disabled"></div>
				<div class="members" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
					<div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<i class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div :class="['names', {slideOut: chatNameHover}]" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
					<div class="container">
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">YurGenaldo</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Richam</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">RusGal</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Потрясун</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Gorr31</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Prometey</div>
							<div class="prestigeDecor"></div>
						</div>
					</div>
				</div>
				<div class="next"></div>
			</div>
		</div>
		<div v-if="chatMenuActiveTab == 2">
			<div class="chatRoomHeader">
				<span>
					<span>Помощь</span>
					<span> | <a class="clickable">Русский</a></span>
				</span>
				<div class="options">
					<a class="iconButton clickable">
						<i class="symbol_arrowDown_tiny_illu"></i>
					</a>
					<!--
					<div class="moreDropDownBody dropdownBody" style="top: 222px; left: 260px; display: block;">
						<ul>
							<li ng-repeat="option in options" clickable="leaveChat(); closeMoreDropdown()" class="clickable">Покинуть чат</li>
						</ul>
					</div>
					-->
				</div>
			</div>
			<div class="chatWindow">
				<div class="chat-room-notifications">
					<div ng-if="text" class="roomNotification" ng-class="{red: [ChatNotification.PLAYER_LEFT, ChatNotification.PLAYER_OFFLINE].indexOf(type) !== -1}">
						<span>Игрок That_Rabbit появился в сети</span>
					</div>
				</div>
				<div class="chatBody scrollable">
					<div class="scrollContentOuterWrapper" style="width: 360px;">
						<div class="scrollContent" style="width: 363px; overflow-y: auto;">
							<div class="scrollContentInnerWrapper" style="width: auto;">
								<ul></ul>
							</div>
						</div>
					</div>
				</div>
				<div class="inputWrapper">
					<i class="chat_insert_message_small_flat writeMessage"></i>
					<span role="status" class="ui-helper-hidden-accessible"></span>
					<textarea class="chatInput ui-autocomplete-input" autocomplete="off" placeholder="Нажми здесь, чтобы ответить"></textarea>
				</div>
			</div>
			<div class="chatMembers">
				<div class="prev disabled"></div>
				<div class="members" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
					<div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<i class="prestigeBadge prestige_silverBadge_tiny_illu"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<i class="prestigeBadge prestige_goldBadge_tiny_illu"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<i class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div :class="['names', {slideOut: chatNameHover}]" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
					<div class="container">
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">YurGenaldo</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Richam</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">RusGal</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Потрясун</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Prometey</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Gorr31</div>
							<div class="prestigeDecor"></div>
						</div>
					</div>
				</div>
				<div class="next"></div>
			</div>
		</div>
		<div v-if="chatMenuActiveTab == 3">
			<div class="chatRoomHeader">
				<span>
					<span>Общий чат</span>
					<span> | Международный</span>
				</span>
				<div class="options">
					<a class="iconButton clickable">
						<i class="symbol_arrowDown_tiny_illu"></i>
					</a>
					<!--
					<div class="moreDropDownBody dropdownBody" style="top: 222px; left: 260px; display: block;">
						<ul>
							<li ng-repeat="option in options" clickable="leaveChat(); closeMoreDropdown()" class="clickable">Покинуть чат</li>
						</ul>
					</div>
					-->
				</div>
			</div>
			<div class="chatWindow">
				<div class="chat-room-notifications"></div>
				<div class="chatBody scrollable">
					<div class="scrollContentOuterWrapper" style="width: 360px;">
						<div class="scrollContent" style="width: 363px; overflow-y: auto;">
							<div class="scrollContentInnerWrapper" style="width: auto;">
								<ul></ul>
							</div>
						</div>
					</div>
				</div>
				<div class="inputWrapper">
					<i class="chat_insert_message_small_flat writeMessage"></i>
					<span role="status" class="ui-helper-hidden-accessible"></span>
					<textarea class="chatInput ui-autocomplete-input" autocomplete="off" placeholder="Нажми здесь, чтобы ответить"></textarea>
				</div>
			</div>
			<div class="chatMembers">
				<div class="prev disabled"></div>
				<div class="members" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
					<div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<i class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="chat-room-member">
								<div class="chatMember clickable" @click="onOpenActiveTab">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus">
										<i class="onlineStatus chat_status_online_tiny_illu"></i>
									</div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div :class="['names', {slideOut: chatNameHover}]" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
					<div class="container">
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">YurGenaldo</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Richam</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Потрясун</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">RusGal</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Prometey</div>
							<div class="prestigeDecor"></div>
						</div>
						<div class="clickable" @click="onOpenActiveTab">
							<div class="name">Gorr31</div>
							<div class="prestigeDecor"></div>
						</div>
					</div>
				</div>
				<div class="next"></div>
			</div>
		</div>
		<div v-if="chatMenuActiveTab == 4">
			<div class="chatRoomHeader">
				<span>
					<span>Королевство</span>
					<!--<span ng-if="room.chatRoom.data.joined !== 0"> | <a class="clickable">Русский</a></span>-->
				</span>
				<!--
				<div class="options" ng-if="room.chatRoom.data.joined !== 0">
					<a class="iconButton clickable">
						<i class="symbol_arrowDown_tiny_illu"></i>
					</a>
					<div class="moreDropDownBody dropdownBody" style="top: 222px; left: 260px; display: block;">
						<ul>
							<li ng-repeat="option in options" clickable="leaveChat(); closeMoreDropdown()" class="clickable">Покинуть чат</li>
						</ul>
					</div>
				</div>
				-->
			</div>
			<div class="chatWindow">
				<!--
				<div class="notJoined" ng-if="room.chatRoom.data.joined === 0 && room.chatRoom.getRoomName() !== 0">
					<i class="no_chat_member_medium_flat_black"></i>
					<span class="text"><span>Вступи в чат, чтобы общаться с другими игроками.</span></span>
					<span class="textOnline"><span>Игроков в сети: 4</span></span>
					<button clickable="joinChat()" ng-class="{disabled: isBannedFromMessaging}" class="clickable">
						<div class="content">
							<span class="innerText">
								<span><span>Зайти в чат</span></span>
							</span>
							<div class="animationOverlay"></div>
							<i class="checkmark action_check_small_flat_white"></i>
							<i class="checkmark action_check_small_flat_black"></i>
							<i class="checkmark action_check_small_flat_red"></i>
						</div>
					</button>
				</div>
				<div class="chat-room-notifications"></div>
				<div class="inputWrapper">
					<i class="chat_insert_message_small_flat writeMessage"></i>
					<span role="status" class="ui-helper-hidden-accessible"></span>
					<textarea class="chatInput ui-autocomplete-input" autocomplete="off" placeholder="Необходимо вступить в чат" disabled="disabled"></textarea>
				</div>
				-->
				<div class="notJoined">
					<i class="no_chat_member_medium_flat_black"></i>
					<span class="text"><span>Ты сейчас не являешься жителем королевства.</span></span>
				</div>
				<div class="chat-room-notifications"></div>
				<div class="inputWrapper">
					<i class="chat_insert_message_small_flat writeMessage"></i>
					<span role="status" class="ui-helper-hidden-accessible"></span>
					<textarea class="chatInput ui-autocomplete-input" autocomplete="off" placeholder="Необходимо вступить в королевство" disabled="disabled"></textarea>
				</div>
			</div>
			<div class="chatMembers">
				<div class="members">
					<div></div>
				</div>
				<div class="names">
					<div class="container"></div>
				</div>
			</div>
		</div>
		<div v-if="chatMenuActiveTab == 5">
			<div class="chatRoomHeader">
				<span>
					<span>Тайное сообщество</span>
					<span> | <a class="clickable">BeHappy</a></span>
				</span>
				<!--
				<div class="options" ng-if="room.chatRoom.data.joined !== 0">
					<a class="iconButton clickable">
						<i class="symbol_arrowDown_tiny_illu"></i>
					</a>
					<div class="moreDropDownBody dropdownBody" style="top: 222px; left: 260px; display: block;">
						<ul>
							<li ng-repeat="option in options" clickable="leaveChat(); closeMoreDropdown()" class="clickable">Покинуть чат</li>
						</ul>
					</div>
				</div>
				-->
			</div>
			<div class="chatWindow">
				<div class="notJoined" ng-if="room.chatRoom.data.joined === 0 && room.chatRoom.getRoomName() !== 0">
					<i class="no_chat_member_medium_flat_black"></i>
					<span class="text"><span>Вступи в чат, чтобы общаться с другими игроками.</span></span>
					<span class="textOnline"><span>Игроков в сети: 4</span></span>
					<button clickable="joinChat()" ng-class="{disabled: isBannedFromMessaging}" class="clickable">
						<div class="content">
							<span class="innerText">
								<span><span>Зайти в чат</span></span>
							</span>
							<div class="animationOverlay"></div>
							<i class="checkmark action_check_small_flat_white"></i>
							<i class="checkmark action_check_small_flat_black"></i>
							<i class="checkmark action_check_small_flat_red"></i>
						</div>
					</button>
				</div>
				<div class="chat-room-notifications"></div>
				<div class="inputWrapper">
					<i class="chat_insert_message_small_flat writeMessage"></i>
					<span role="status" class="ui-helper-hidden-accessible"></span>
					<textarea class="chatInput ui-autocomplete-input" autocomplete="off" placeholder="Необходимо вступить в чат" disabled="disabled"></textarea>
				</div>
			</div>
			<div class="chatMembers">
				<div class="members">
					<div></div>
				</div>
				<div class="names">
					<div class="container"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		chatNameHover: false,
		chatMenuActiveTab: 1,
		chatMenuUnreadTabs: [1, 2],
	}),
	computed: {},
	methods: {
		onMouseLeave(){
			this.chatNameHover = false;
		},
		onMouseOver(){
			this.chatNameHover = true;
		},
		onTabActive(tabId = 1){
			this.chatMenuActiveTab = tabId;
			this.onChatScrollToEnd();
		},
		onOpenActiveTab(){
			this.$emit('onOpenActiveTab');
		},
		onChatScrollToEnd(){
			this.$emit('onChatScrollToEnd');
		},
	},
	components: {},
}
</script>
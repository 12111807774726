<!--
<avatar-image src="../file.png" size="62" player-id="1774" avatar-class="profile" v-if="chat.playerId > 0"></avatar-image>
-->

<template>
	<div class="heroImage" :class="[avatarClass, playerInfo.sex]">
		<img :src="src" :width="size" :height="size" />
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		src: {
			type: String,
			default: '',
		},
		size: {
			type: Number,
			default: 62,
		},
		playerId: {
			type: Number,
			required: true,
		},
		avatarClass: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		playerInfo: {
			sex: 'male',
		},
	}),
	computed: {},
	methods: {
		async loadPlayerInfo(){
			//this.playerId
			return {};
		},
	},
	async created(){
		await this.loadPlayerInfo();
	},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Рынок</span></span>
									<span class="level">
										(<span><span>Уровень {{gameModel.island.buildings.shop.level}}</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>На рынке можно обмениваться сырьем с другими игроками. Чем выше уровень рынка, тем больше у тебя торговцев, и тем больше ресурсов ты можешь отправить одновременно.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<div class="buildingStatus location27 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<nav class="tabulation  maintab unselectable">
									<a class="tab clickable" :class="{active: tab == 'send', inactive: tab != 'send'}" @click="onToggleTab('send')">
										<div class="content">
											<span>Отправить</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'buy', inactive: tab != 'buy'}" @click="onToggleTab('buy')">
										<div class="content">
											<span>Купить</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'sell', inactive: tab != 'sell'}" @click="onToggleTab('sell')">
										<div class="content">
											<span>Продать</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'npc-trade', inactive: tab != 'npc-trade'}" @click="onToggleTab('npc-trade')">
										<div class="content">
											<span>NPC-торговец</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'merchants', inactive: tab != 'merchants'}" @click="onToggleTab('merchants')">
										<div class="content">
											<span>Торговцы</span>
										</div>
									</a>
								</nav>
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div class="loadedTab currentTab" v-if="tab == 'send'">
													<send />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'buy'">
													<buy />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'sell'">
													<sell />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'npc-trade'">
													<npc-trade />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'merchants'">
													<merchants />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import Send from '@/components/pages/buildings/shop/Send';
import Buy from '@/components/pages/buildings/shop/Buy';
import Sell from '@/components/pages/buildings/shop/Sell';
import NpcTrade from '@/components/pages/buildings/shop/NpcTrade';
import Merchants from '@/components/pages/buildings/shop/Merchants';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Рынок | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		tab: 'send',
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_8.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.tab) this.tab = this.$route.query.tab;
		else this.$router.push({query: {tab: this.tab}});
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(!to.query.tab) this.$router.push({query: {tab: 'send'}});
		else if(to.query.tab != this.tab) this.tab = to.query.tab;
		next();
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {
		Send,
		Buy,
		Sell,
		NpcTrade,
		Merchants,
	},
}
</script>
<template>
	<div if="map.length > 0">
		<div v-for="y in arr_range(min, max)" class="cnt">
			<div v-for="x in arr_range(min, max)" style="display: inline-block;" :class="[
				'bl',
				{'empty': map[x][y].idIsland == null && map[x][y].idUser == null},
				{'user': map[x][y].idIsland != null && map[x][y].idUser != null},
				{'barbarian': map[x][y].idIsland != null && map[x][y].idUser == null},
				{'selected': (x == action.x1 && y == action.y1) || (x == action.x2 && y == action.y2)},
				{'moveto': map[x][y].idIsland == null && map[x][y].idUser == null && action.moving}
			]" :title="x+' | '+y+(map[x][y].name ? ' ('+map[x][y].name+')' : '')+(map[x][y].username ? ' ('+map[x][y].username+')' : '')" @click="move(x, y)"></div>
		</div>
	</div>
</template>

<style lang="css">
.cnt {
	line-height: 0;
}
.bl {
	width: 24px;
	height: 24px;
	margin: 0px;
}
.bl.empty {
	background: aqua;
}
.bl.user {
	background: blue;
}
.bl.barbarian {
	background: gray;
}
.bl.selected {
	background: #fff;
}
.bl:hover {
	opacity: 0.2;
}
.bl:hover.moveto {
	background: #fff;
	width: 20px;
	height: 20px;
	border: 2px dotted #000;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Map2 | IW'});
	},
	data: () => ({
		min: -35,
		max: 35,
		map: [],
		
		action: {
			step: 0,
			moving: false,
			x1: null,
			y1: null,
			x2: null,
			y2: null,
		},
	}),
	computed: {},
	methods: {
		arr_range(start, end){
			let arr = [];
			if(start != null && end != null){
				for(let i = start; i <= end; i++){
					arr.push(i);
				}
			}
			return arr;
		},
		async loadData(){
			await fetch('/api/mapdataadm?' + new URLSearchParams({
				x1: this.min,
				x2: this.max,
				y1: this.min,
				y2: this.max,
			}), {
				mode: 'cors',
				credentials: 'include',
				headers: {
					Authorization: 'Bearer '+this.authModel.token,
				},
			}).then(stream => stream.json()).then(data => {
				//this.min = data.min;
				//this.max = data.max;
				this.map = data.map;
			}).catch(error => {
				console.error(error);
			});
		},
		async move(x, y){
			if(this.action.step == 0){
				if(this.map[x][y].idIsland != null){
					this.action.step = 1;
					this.action.x1 = x;
					this.action.y1 = y;
					this.action.moving = true;
				}
			} else if(this.action.step == 1){
				if(this.map[x][y].idIsland == null && this.map[x][y].idUser == null){
					this.action.x2 = x;
					this.action.y2 = y;
					//if(confirm('Переместить '+this.action.x1+' | '+this.action.y1+' в '+this.action.x2+' | '+this.action.y2+'?')){
						await fetch('/api/mapdataadm/move', {
							mode: 'cors',
							credentials: 'include',
							method: 'POST',
							body: JSON.stringify({
								x1: this.action.x1,
								y1: this.action.y1,
								x2: this.action.x2,
								y2: this.action.y2,
							}),
							headers: {
								'Content-Type': 'application/json',
								Authorization: 'Bearer '+this.authModel.token,
							},
						}).then(stream => stream.json()).then(async (data) => {
							await this.loadData();
						}).catch(error => {
							console.error(error);
						});
					//}
				}
				this.action.step = 0;
				this.action.x1 = null;
				this.action.y1 = null;
				this.action.x2 = null;
				this.action.y2 = null;
				this.action.moving = false;
			}
		},
		
	},
	async created(){
		this.gameModel.buildingQueueShow = false;
		
		await this.loadData();
	},
	components: {},
}
</script>
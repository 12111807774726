<template>
	<div class="unselectable">
		<div id="timedNotifications">
			<a v-for="n in timedNotificationsFiltered" :class="['notification', 'clickable', {'disabled': !isClickable(n.type)}]" clickable="openNotification(82)" @click="n.onClick()" :content="n.title" v-tippy="{placement: 'top-start'}">
				<i :class="n.icon"></i>
				<div class="timeLeft">
					<vue-countdown :time="n.time" v-slot="{ days, hours, minutes, seconds }">
						<span v-if="days > 0">{{days}} дн.</span>
						<span v-else>{{hours < 10 ? 0 : ''}}{{hours}}:{{minutes < 10 ? 0 : ''}}{{minutes}}:{{seconds < 10 ? 0 : ''}}{{seconds}}</span>
					</vue-countdown>
				</div>
			</a>
			<!--
			<a ng-repeat="n in timedNotifications | orderBy:'time':true" class="notification clickable" ng-class="{disabled: !isClickable(n.type)}" clickable="openNotification(82)" tooltip="" tooltip-translate="Notification_82" tooltip-data="expireTime:1594815900" tooltip-placement="above" ng-if="n.time < gameModel.currentServerTime &amp;&amp; (n.expireTime == 0 || n.expireTime > gameModel.currentServerTime)">
				<i class="notification_systemMessage_medium_flat_black"></i>
				<div class="timeLeft" ng-if="n.expireTime > 0" countdown="1594815900" show-days-limit="172800">22:19:43</div>
			</a>
			-->
			<!--
			<a class="notification clickable disabled">
				<i class="feature_beginnersProtection_medium_flat_black"></i>
				<div class="timeLeft">6 дн.</div>
			</a>
			-->
			<!--
			<a ng-repeat="n in timedNotifications | orderBy:'time':true" class="notification clickable disabled" ng-class="{disabled: !isClickable(n.type)}" clickable="openNotification(73)" tooltip="" tooltip-translate="Notification_73" tooltip-data="expireTime:1594999091" tooltip-placement="above" ng-if="n.time < gameModel.currentServerTime &amp;&amp; (n.expireTime == 0 || n.expireTime > gameModel.currentServerTime)">
				<i class="feature_beginnersProtection_medium_flat_black"></i>
				<div class="timeLeft" ng-if="n.expireTime > 0" countdown="1594999091" show-days-limit="172800">4 дн.</div>
			</a>
			-->
		</div>
	</div>
</template>

<style lang="css">
#timedNotifications{
    position:absolute;
    left:0;
    bottom:30px;
    z-index:2;
    height:40px
}
#timedNotifications:after{
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0
}
#timedNotifications .notification{
    position:relative;
    float:left;
    width:55px;
    height:40px;
    box-sizing:border-box;
    border:1px solid #fff;
    background-color:rgba(241,240,238,0.8);
    -webkit-box-shadow:0 0 3px rgba(0,0,0,0.7);
    -moz-box-shadow:0 0 3px rgba(0,0,0,0.7);
    box-shadow:0 0 3px rgba(0,0,0,0.7);
    margin-left:7px;
    text-align:center
}
#timedNotifications .notification.activation i{
    top:7px
}
#timedNotifications .notification.challenge.halloween i{
    top:5px
}
#timedNotifications .notification.challenge.birthday i{
    top:5px;
    left:2px
}
#timedNotifications .notification.disabled{
    cursor:default
}
#timedNotifications .notification.disabled i{
    cursor:default;
    opacity:1
}
#timedNotifications .notification .timeLeft{
    background-color:rgba(241,240,238,0.65);
    font-size:.84615rem;
    color:#000;
    position:absolute;
    bottom:1px;
    font-weight:normal;
    width:100%
}
#timedNotifications .notification.clickable:not(.disabled):hover{
    background-color:rgba(255,255,255,0.9)
}
#timedNotifications .notification.clickable:not(.disabled):active{
    border:1px solid #9a7641;
    background-color:rgba(184,178,169,0.9)
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: ['timedNotifications'],
	data: () => ({
		
	}),
	computed: {
		timedNotificationsFiltered(){
			return this.timedNotifications.filter((elem) => elem.time < this.gameModel.currentServerTime && (elem.expireTime == 0 || elem.expireTime > this.gameModel.currentServerTime));
		},
	},
	methods: {
		isClickable(type = 'clickable'){
			return type != 'disabled';
		},
	},
	components: {},
}
</script>
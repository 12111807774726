import axios from 'axios';
import lib from '@/lib';

export default class NotificationsModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.show = true;
		this.countLimit = 100;
		
		this.list = [
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'movement_adventure_medium_flat_black',
					count: 1,
					title: 'Отчет о приключении',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 2,
					class: 'movement_defend_medium_flat_positive',
					count: 1,
					title: 'Победа в защите без потерь',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 3,
					class: 'movement_defend_medium_flat_uncertain',
					count: 1,
					title: 'Победа в защите с потерями',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 4,
					class: 'movement_defend_medium_flat_negative',
					count: 1,
					title: 'Поражение в защите с потерями',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 5,
					class: 'movement_defend_medium_flat_neutral',
					count: 1,
					title: 'Поражение в защите без потерь',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 6,
					class: 'unit_culturePoint_medium_flat_black',
					count: 1,
					title: 'Достаточно единиц культуры для новой деревни',
					//title: 'Достаточно ЕК для новой деревни, через 3 дня',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 7,
					class: 'feature_achievement_medium_flat_black',
					count: 1,
					title: 'Получено достижение',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 8,
					class: 'movement_attack_medium_flat_black',
					count: 1,
					title: 'xxx',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 9,
					class: 'movement_capture_medium_flat_black',
					count: 1,
					title: 'Были пойманы животные',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 10,
					class: 'movement_peace_medium_flat_black',
					count: 1,
					title: 'xxx',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 11,
					class: 'movement_support_medium_flat_black',
					count: 105,
					title: 'Подкрепление прибыло',
					// открывает отчет о подкреплении в случае одного отчета
					// в случае нескольких открывает список с фиьльтром
					// /#/page:village/villId:536330229/tab:Own/reportId:66a29c69b350f3cdba08b794/collection:own/overlayreports:reportSingle/window:reports/cp:1
				},
			},
			{
				closeHover: false,
				data: {
					//id: 12,
					class: 'movement_trade_medium_flat_black',
					count: 2,
					title: 'Торговцы доставили ресурсы',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 13,
					class: 'movement_tribute_medium_flat_black',
					count: 1,
					title: 'xxx',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 14,
					class: 'movement_attack_medium_flat_positive',
					count: 1,
					title: 'Победа в атаке без потерь',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 15,
					class: 'movement_attack_medium_flat_uncertain',
					count: 1,
					title: 'Победа в атаке с потерями',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 16,
					class: 'movement_attack_medium_flat_negative',
					count: 1,
					title: 'Поражение в атаке',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 17,
					class: 'feature_prestige_medium_flat_black',
					count: 1,
					title: 'Престиж за неделю',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 18,
					class: 'cardGame_cardGame_medium_flat_black',
					count: 1,
					title: 'Новая бесплатная игра!',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 19,
					class: 'movement_spyAttack_medium_flat_positive',
					count: 1,
					title: 'Успешная разведывательная операция без потерь',
					//title: 'Разведывательная операция прошла успешно и не была раскрыта',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 20,
					class: 'movement_spyAttack_medium_flat_uncertain',
					count: 1,
					title: 'Успешная разведывательная операция с потерями',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 21,
					class: 'movement_spyDefend_medium_flat_negative',
					count: 1,
					title: 'Разведывательную операцию отобразить не удалось',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 22,
					class: 'action_invite_medium_flat_black',
					count: 1,
					title: 'Получен запрос от короля',
					//title: 'Получен приглашение в тайное сообщество',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 23,
					class: 'notification_sharedKingdom_medium_flat_black',
					count: 1,
					title: 'Перемещение прошло успешно',
					//title: 'Отчеты, которыми поделились с моим королевством',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 24,
					class: 'feature_oasis_medium_flat_positive',
					count: 1,
					title: 'Твое королевство захватило оазис',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 25,
					class: 'feature_oasis_medium_flat_negative',
					count: 1,
					title: 'Твое королевство потеряло оазис',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 26,
					class: 'feature_oasis_medium_flat_black',
					count: 1,
					title: 'Изменился бонус оазиса, который ты используешь',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 27,
					class: 'feature_robberCamp_medium_flat_black',
					count: 1,
					title: 'Избавь королевство от разбойников',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 28,
					class: 'feature_auction_medium_flat_positive',
					count: 1,
					title: 'Ты выиграл аукцион',
					// открывает hero/auctions/bids
				},
			},
			{
				closeHover: false,
				data: {
					//id: 30,
					class: 'notification_sharedSecretSociety_medium_flat_black',
					count: 1,
					title: 'Отчеты, которыми поделились с тайным сообществом',
					// если несколько отчеты/тайные сообщества/фильтр опубликованные отчеты /#/page:village/villId:536330229/window:welcomeScreen:reports/tab:SecretSociety/reportTypes:64/lastTime:1721949662/cp:1
					// если одно то сам отчет
				},
			},
			{
				closeHover: false,
				data: {
					//id: 31,
					class: 'notification_dukeLeave_medium_flat_negative',
					count: 1,
					title: 'Герцог отрекся от власти',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 32,
					class: 'notification_dukeAccept_medium_flat_positive',
					count: 1,
					title: 'Был назначен новый герцог!',
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_changedKing_medium_flat_uncertain',
					count: 1,
					title: 'Был назначен новый король!',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 33,
					class: 'notification_itemTierAvailable_medium_flat_black',
					// welcomeScreen_newItemAvailable_small_flat_black похож на него только большой
					count: 1,
					title: 'Доступны предметы второго уровня',
					// открывается /hero tab=auctions
				},
			},
			{
				closeHover: false,
				data: {
					//id: 34,
					class: 'notification_itemTierAvailable_medium_flat_black',
					// welcomeScreen_newItemAvailable_small_flat_black похож на него только большой
					count: 1,
					title: 'Доступны предметы 3 уровня',
					// открывается /hero tab=auctions
				},
			},
			{
				closeHover: false,
				data: {
					//id: 35,
					class: 'notification_unitedKingdom_medium_flat_black',
					count: 1,
					title: 'Королевства успешно объединились',
					// открывается /kingdom-union-success
				},
			},
			{
				closeHover: false,
				data: {
					//id: 36,
					class: 'notification_healingTentFull_medium_flat_black',
					// иконка шатра есть в новой версии травиан. взять оттуда...
					count: 1,
					title: 'Целебный шатер полностью загружен',
					// открывается /buildings/healing целебный шатер
				},
			},
			{
				closeHover: false,
				data: {
					//id: 37,
					class: 'notification_unitedKingdom_medium_flat_black',
					count: 1,
					title: 'Слияние королевств теперь возможно',
					// открывается pageId:Kingdom_Unions/window:help "помощь по игре/королевство/слияние королевств"
				},
			},
			{
				closeHover: false,
				data: {
					//id: 38,
					class: 'feature_auction_medium_flat_negative',
					count: 1,
					title: 'Твоя ставку перебита',
					// открывается аукцион...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 39,
					class: 'community_kingdom_medium_flat_black',
					count: 1,
					title: 'Ты сменил королевство',
					// открывается /#/page:village/villId:536330229/tab:Kingdom/window:society/subtab:Profile/governorPage:1
				},
			},
			{
				closeHover: false,
				data: {
					//id: 40,
					class: 'farmlist_resultSingle_medium_flat_positive',
					count: 1,
					title: 'Атаки по списку целей без потерь',
					// открывается отчет /#/page:village/villId:536330229/window:welcomeScreen:reports/tab:Own/reportId:66a407ea126381854b0222a4/collection:own/overlayreports:reportSingle/cp:1
				},
			},
			{
				closeHover: false,
				data: {
					//id: 41,
					class: 'farmlist_result_medium_flat_positive',
					count: 1,
					title: 'Атаки по списку целей без потерь:',
					// открывается отчет /#/page:village/villId:536330229/window:welcomeScreen:reports/tab:Own/reportId:66a3fdf98222a72179051c75/collection:own/overlayreports:reportSingle/cp:1
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'inviteFriend_thirdVillage_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'marker_tile_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'attribute_dead_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'chat_friend_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'chat_menu_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_birthdayGameWorld_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_coopPackage_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_cropBonus_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_dynastyGameWorld_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_fireworks_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_goldPromotion_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_halloweenBoost_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_halloweenGameWorld_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_helpCenter_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_inviteGovernor_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_mayhemGameWorld_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_paymentPurchase_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_plus_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_premiumPurchase_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_relocationCooldown_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_resourceBonus_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_sharedAlliance_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_sharedPlayer_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_starvation_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_systemMessageAnnounce_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_systemMessage_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_vacation_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'report_share_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'action_check_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'action_delete_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'action_demolish_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'action_dismantle_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'action_search_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'action_zoomIn_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'action_zoomOut_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'community_player_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'community_secretSociety_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'feature_beginnersProtection_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'feature_natars_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'feature_report_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'feature_worldWonder_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'symbol_lock_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'symbol_star_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'village_city_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'village_village_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'community_alliance_medium_flat_black',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'community_alliance_medium_flat_positive',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'community_alliance_medium_flat_negative',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'notification_dukeDecline_medium_flat_negative',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'farmlist_resultSingle_medium_flat_negative',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'farmlist_result_medium_flat_negative',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'movement_spyAttack_medium_flat_negative',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'movement_spyDefend_medium_flat_positive',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
			{
				closeHover: false,
				data: {
					//id: 1,
					class: 'community_kingdom_medium_flat_positive',
					count: 1,
					title: 'xxx',
					// открывается ...
				},
			},
		];
		
		let cntNotification = 1;
		this.list.map(el => {
			el.data.id = cntNotification++;
			return el;
		});
	}
}

<template>
	<div id="chatFooterBar">
		<chat-menu
			@onOpenActiveTab="onOpenActiveTab"
			@onChatScrollToEnd="onChatScrollToEnd"
		></chat-menu>
		<a class="mainLayoutMenuButton directionDown toggleChatMenu clickable" @click="onToggleChatMenu" content="Чат" v-tippy="{placement: 'top-start'}">
			<i class="chat_menu_medium_flat_black"></i>
			<div class="unreadNotification" v-if="unreadMessages > 0">
				<span>{{unreadMessages}}</span>
			</div>
		</a>
		<div class="disconnectIndicator" v-if="!connected">
			<i class="chat_disconnected_small_illu"></i>
		</div>
		<div class="dynamic-tabulation dynamicTabulation tabWrapper" ng-class="{disconnected: disconnected}">
			<div :class="['tabWrapper', {'chatMenuOpened': gameModel.chatMenuOpen}]" style="opacity: 1;">
				<div v-for="chatInfo in tabsFilter" :class="['footerButton', 'chatButton', 'room', 'chatInbox', {open: chatInfo.data.isDocked, selected: chatInfo.data.roomId == activeTab}]">
					<div class="chatNameContainer clickable" @click="onToggleActiveTab(chatInfo.data.roomId)">
						<div class="chatName">
							<div class="chat-room-name truncated">
								<span>
									<i :class="{'community_viceking_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'viceking', 'community_duke_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'duke', 'community_governor_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'governor', 'community_king_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'king'}" alt="Онлайн-статус неизвестен" text="Kingdom.Role_0"></i>
								</span>
								{{chatInfo.data.chatUser.data.name}}
							</div>
							<div class="unreadNotification" v-if="chatInfo.data.chatInbox.data.unread > 0">
								<span>{{chatInfo.data.chatInbox.data.unread}}</span>
							</div>
							<div class="newChatWrapper" v-if="chatInfo.data.isNew">
								<div class="newChat">
									<span><span>НОВЫЙ</span></span>
								</div>
							</div>
						</div>
					</div>
					<docked-chat
						:chatInfo="chatInfo"
						@onCloseActiveTab="onCloseActiveTab"
						@onMinimizeActiveTab="onMinimizeActiveTab"
					></docked-chat>
				</div>
				<div class="footerButton chatButton showHidden room clickable" @click="onToggleHiddenTabsDropdown" style="position: relative;" v-if="tabsMore.length > 0">
					<span class="moreTabsAmount">{{tabsMore.length}}</span>
					<i class="symbol_arrowUp_tiny_illu"></i>
					<div class="dropdownBody dynamicTabulationDropdown" v-if="showHiddenTabsDropdown">
						<ul>
							<li v-for="chatInfo in tabsMoreFilter">
								<div class="chatNameContainer">
									<div class="chatName roomType clickable" @click="onToggleActiveTab(chatInfo.data.roomId)">
										<div class="chat-room-name" :room-name="chatInfo.data.chatUser.data.name" :kingdom-role="chatInfo.data.chatUser.data.kingdomRole" :online="chatInfo.data.chatUser.data.online">
											<span ng-if="online != true">
												<i :class="{'community_viceking_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'viceking', 'community_duke_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'duke', 'community_governor_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'governor', 'community_king_small_flat_black': chatInfo.data.chatUser.data.kingdomRole == 'king'}" alt="Онлайн-статус неизвестен"></i>
											</span>
											{{chatInfo.data.chatUser.data.name}}
										</div>
										<div class="unreadNotification" v-if="chatInfo.data.chatInbox.data.unread > 0">
											<span>{{chatInfo.data.chatInbox.data.unread}}</span>
										</div>
										<div class="newChatWrapper" v-if="chatInfo.data.isNew">
											<div class="newChat">
												<span><span>НОВЫЙ</span></span>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		
		<!--
		<div class="dynamic-tabulation dynamicTabulation tabWrapper">
			<div class="tabWrapper" style="opacity: 1;">
				<div class="footerButton chatButton room chatInbox">
					<div class="chatNameContainer clickable">
						<div class="chatName">
							<div class="chat-room-name truncated">
								<span>
									<i class="community_governor_small_flat_black " alt="Онлайн-статус неизвестен" text="Kingdom.Role_0"></i>
								</span>
								alistan
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footerButton chatButton showHidden room clickable" style="left: 140px;">
				<span class="moreTabsAmount">0</span>
				<i class="symbol_arrowUp_tiny_illu"></i>
				<div class="dropdownBody dynamicTabulationDropdown">
					<ul></ul>
				</div>
			</div>
		</div>
		
		<div class="dynamic-tabulation dynamicTabulation tabWrapper">
			<div class="tabWrapper" style="opacity: 1;">
				<div class="footerButton chatButton room chatInbox open selected">
					<div class="chatNameContainer clickable">
						<div class="chatName">
							<div class="chat-room-name truncated">
								<span>
									<i class="community_governor_small_flat_black " alt="Онлайн-статус неизвестен" text="Kingdom.Role_0"></i>
								</span>
								alistan
							</div>
						</div>
					</div>
					<docked-chat></docked-chat>
				</div>
			</div>
			<div class="footerButton chatButton showHidden room clickable" style="left: 356px;">
				<span class="moreTabsAmount">0</span>
				<i class="symbol_arrowUp_tiny_illu"></i>
				<div class="dropdownBody dynamicTabulationDropdown">
					<ul>
						<li>
							<div class="chatNameContainer">
								<div class="chatName roomType clickable" clickable="openChat(chatInfo.data.roomId); toggleDropdown();">
									<div class="chat-room-name truncated">
										<span>
											<i class="community_governor_small_flat_black " alt="Онлайн-статус неизвестен"></i>
										</span>
										Третий 3
									</div>
								</div>
							</div>
						</li>
						<li>
							<div class="chatNameContainer">
								<div class="chatName roomType clickable" clickable="openChat(chatInfo.data.roomId); toggleDropdown();">
									<div class="chat-room-name truncated">
										<span>
											<i class="community_governor_small_flat_black " alt="Онлайн-статус неизвестен"></i>
										</span>
										Krag
									</div>
								</div>
							</div>
						</li>
						<li>
							<div class="chatNameContainer">
								<div class="chatName roomType clickable" clickable="openChat(chatInfo.data.roomId); toggleDropdown();">
									<div class="chat-room-name truncated">
										<span>
											<i class="community_governor_small_flat_black " alt="Онлайн-статус неизвестен"></i>
										</span>
										Tosiro
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		-->
	</div>
</template>

<style lang="css">
@-webkit-keyframes unreadChatAnimation{
    0{
        top:-2px
    }
    20%{
        top:-17px
    }
    100%{
        top:-17px
    }
}
@-moz-keyframes unreadChatAnimation{
    0{
        top:-2px
    }
    20%{
        top:-17px
    }
    100%{
        top:-17px
    }
}
@-ms-keyframes unreadChatAnimation{
    0{
        top:-2px
    }
    20%{
        top:-17px
    }
    100%{
        top:-17px
    }
}
@-o-keyframes unreadChatAnimation{
    0{
        top:-2px
    }
    20%{
        top:-17px
    }
    100%{
        top:-17px
    }
}
@keyframes unreadChatAnimation{
    0{
        top:-2px
    }
    20%{
        top:-17px
    }
    100%{
        top:-17px
    }
}
@-webkit-keyframes newChatAnimation{
    0{
        filter:alpha(opacity=0);
        opacity:0;
        top:0
    }
    1%{
        filter:alpha(opacity=100);
        opacity:1;
        top:-23px
    }
    50%{
        top:-23px
    }
    100%{
        top:0
    }
}
@-moz-keyframes newChatAnimation{
    0{
        filter:alpha(opacity=0);
        opacity:0;
        top:0
    }
    1%{
        filter:alpha(opacity=100);
        opacity:1;
        top:-23px
    }
    50%{
        top:-23px
    }
    100%{
        top:0
    }
}
@-ms-keyframes newChatAnimation{
    0{
        filter:alpha(opacity=0);
        opacity:0;
        top:0
    }
    1%{
        filter:alpha(opacity=100);
        opacity:1;
        top:-23px
    }
    50%{
        top:-23px
    }
    100%{
        top:0
    }
}
@-o-keyframes newChatAnimation{
    0{
        filter:alpha(opacity=0);
        opacity:0;
        top:0
    }
    1%{
        filter:alpha(opacity=100);
        opacity:1;
        top:-23px
    }
    50%{
        top:-23px
    }
    100%{
        top:0
    }
}
@keyframes newChatAnimation{
    0{
        filter:alpha(opacity=0);
        opacity:0;
        top:0
    }
    1%{
        filter:alpha(opacity=100);
        opacity:1;
        top:-23px
    }
    50%{
        top:-23px
    }
    100%{
        top:0
    }
}
#chatFooterBar{
    position:absolute;
    bottom:0;
    right:250px;
    left:0
}
#chatFooterBar .toggleChatMenu{
    float:left;
    display:block;
    z-index:2;
    border:1px solid #fff;
    -webkit-box-shadow:inset 0 0 0 1px #7b746e;
    -moz-box-shadow:inset 0 0 0 1px #7b746e;
    box-shadow:inset 0 0 0 1px #7b746e
}
#chatFooterBar .toggleChatMenu:hover{
    background-image:-owg-linear-gradient(to bottom,#fff,#e6c77c);
    background-image:-webkit-linear-gradient(to bottom,#fff,#e6c77c);
    background-image:-moz-linear-gradient(to bottom,#fff,#e6c77c);
    background-image:-o-linear-gradient(to bottom,#fff,#e6c77c);
    background-image:linear-gradient(to bottom,#fff,#e6c77c);
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none
}
#chatFooterBar .toggleChatMenu:active{
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none;
    background-image:-owg-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:-webkit-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:-moz-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:-o-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:linear-gradient(to bottom,#e6c77c,#fff)
}
#chatFooterBar .toggleChatMenu.active{
    height:25px;
    background-image:-owg-linear-gradient(to bottom,#fff,#e6c77c);
    background-image:-webkit-linear-gradient(to bottom,#fff,#e6c77c);
    background-image:-moz-linear-gradient(to bottom,#fff,#e6c77c);
    background-image:-o-linear-gradient(to bottom,#fff,#e6c77c);
    background-image:linear-gradient(to bottom,#fff,#e6c77c);
    -webkit-box-shadow:inset 0 0 0 1px #fff;
    -moz-box-shadow:inset 0 0 0 1px #fff;
    box-shadow:inset 0 0 0 1px #fff;
    border:1px solid #7b746e
}
#chatFooterBar .toggleChatMenu.active:active{
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none;
    background-image:-owg-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:-webkit-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:-moz-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:-o-linear-gradient(to bottom,#e6c77c,#fff);
    background-image:linear-gradient(to bottom,#e6c77c,#fff)
}
#chatFooterBar .toggleChatMenu i{
    top:2px
}
#chatFooterBar .disconnectIndicator{
    background-color:#dd3a37;
    position:absolute;
    left:45px;
    bottom:0;
    height:24px;
    width:30px;
    padding:3px 7px;
    box-sizing:border-box;
    z-index:3;
    border:1px solid #d40000
}
#chatFooterBar .disconnectIndicator:after{
    content:"";
    position:absolute;
    top:50%;
    left:0;
    border-left:1px solid #d40000;
    border-bottom:1px solid #d40000;
    background-image:-owg-linear-gradient(45deg,#dd3a37 50%,transparent 50%);
    background-image:-webkit-linear-gradient(45deg,#dd3a37 50%,transparent 50%);
    background-image:-moz-linear-gradient(45deg,#dd3a37 50%,transparent 50%);
    background-image:-o-linear-gradient(45deg,#dd3a37 50%,transparent 50%);
    background-image:linear-gradient(45deg,#dd3a37 50%,transparent 50%);
    width:10px;
    height:11px;
    -webkit-transform:rotate(45deg) skew(21deg,21deg);
    -moz-transform:rotate(45deg) skew(21deg,21deg);
    -ms-transform:rotate(45deg) skew(21deg,21deg);
    -o-transform:rotate(45deg) skew(21deg,21deg);
    transform:rotate(45deg) skew(21deg,21deg);
    margin-top:-6px;
    margin-left:-6px
}
#chatFooterBar .dynamicTabulation{
    position:absolute;
    left:43px;
    right:0
}
#chatFooterBar .dynamicTabulation.disconnected{
    left:77px
}
#chatFooterBar .dynamicTabulation .tabWrapper{
    z-index:2;
    position:absolute;
    white-space:nowrap;
    left:-2px;
    right:50px
}
#chatFooterBar .dynamicTabulation .tabWrapper.chatMenuOpened{
    left:400px
}
#chatFooterBar .footerButton{
    white-space:nowrap;
    border:1px solid #ebebeb;
    background-image:-owg-linear-gradient(180deg,#f2f0ee 0,#fff 40%,#dad8d5 60%,#e6c77c 100%);
    background-image:-webkit-linear-gradient(180deg,#f2f0ee 0,#fff 40%,#dad8d5 60%,#e6c77c 100%);
    background-image:-moz-linear-gradient(180deg,#f2f0ee 0,#fff 40%,#dad8d5 60%,#e6c77c 100%);
    background-image:-o-linear-gradient(180deg,#f2f0ee 0,#fff 40%,#dad8d5 60%,#e6c77c 100%);
    background-image:linear-gradient(180deg,#f2f0ee 0,#fff 40%,#dad8d5 60%,#e6c77c 100%);
    border:1px solid #fff;
    -webkit-box-shadow:inset 0 0 0 1px #7b746e;
    -moz-box-shadow:inset 0 0 0 1px #7b746e;
    box-shadow:inset 0 0 0 1px #7b746e
}
#chatFooterBar .footerButton:hover{
    background-image:-owg-linear-gradient(180deg,#fff 0,#fff 50%,#dad8d5 100%);
    background-image:-webkit-linear-gradient(180deg,#fff 0,#fff 50%,#dad8d5 100%);
    background-image:-moz-linear-gradient(180deg,#fff 0,#fff 50%,#dad8d5 100%);
    background-image:-o-linear-gradient(180deg,#fff 0,#fff 50%,#dad8d5 100%);
    background-image:linear-gradient(180deg,#fff 0,#fff 50%,#dad8d5 100%)
}
#chatFooterBar .footerButton:active{
    background-image:-owg-none;
    background-image:-webkit-none;
    background-image:-moz-none;
    background-image:-o-none;
    background-image:none;
    background-color:#dad8d5
}
#chatFooterBar .footerButton.selected{
    background-image:-owg-none;
    background-image:-webkit-none;
    background-image:-moz-none;
    background-image:-o-none;
    background-image:none;
    background-color:#dad8d5
}
#chatFooterBar .footerButton.chatButton{
    position:relative;
    float:left;
    height:23px;
    cursor:pointer
}
#chatFooterBar .footerButton.chatButton.room{
    width:140px;
    z-index:-1
}
#chatFooterBar .footerButton.chatButton.room.open{
    width:356px
}
#chatFooterBar .footerButton.chatButton.room.chatInbox .chatName{
    float:left;
    width:100%;
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    line-height:24px;
    padding-left:3px;
    padding-right:3px;
    box-sizing:border-box
}
#chatFooterBar .footerButton.chatButton.room.chatInbox .chatName .action_edit_small_flat_black{
    position:absolute;
    right:3px;
    top:2px
}
#chatFooterBar .footerButton.chatButton.showHidden{
    min-width:30px;
    width:50px;
    left:0;
    top:0;
    position:absolute;
    z-index:3
}
#chatFooterBar .footerButton.chatButton.showHidden i{
    vertical-align:middle;
    position:relative;
    bottom:2px
}
#chatFooterBar .footerButton.chatButton.showHidden .moreTabsAmount{
    margin-top:3px;
    margin-left:4px;
    display:inline-block;
    text-align:center;
    width:21px;
    overflow:hidden;
    text-overflow:ellipsis
}
#chatFooterBar .dynamicTabulationDropdown{
    position:absolute;
    bottom:24px;
    right:0;
    display:block
}
#chatFooterBar .dynamicTabulationDropdown .newChat{
    height:22px;
    top:-2px;
    right:-8px;
    line-height:22px
}
#chatFooterBar .dynamicTabulationDropdown .newChat:before{
    height:22px
}
#chatFooterBar .connectionIndicator{
    position:absolute;
    height:12px;
    width:12px;
    left:2px;
    top:35px;
    border:1px solid #463f39;
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    -ms-border-radius:8px;
    -o-border-radius:8px;
    border-radius:8px;
    background-color:#d40000
}
#chatFooterBar .connectionIndicator.online{
    background-color:#5eb95e
}
#chatFooterBar .chatMenu{
    position:absolute;
    width:372px;
    bottom:25px;
    z-index:3;
    height:414px
}
#chatFooterBar .chatMenu .menuItems{
    width:437px;
    padding-top:2px
}
#chatFooterBar .chatMenu .menuItems .menuItem{
    display:inline-block;
    width:58px;
    text-align:center;
    position:relative;
    border:1px solid #9a7641;
    -webkit-box-shadow:0 0 0 1px #fff;
    -moz-box-shadow:0 0 0 1px #fff;
    box-shadow:0 0 0 1px #fff;
    margin-right:1px;
    height:26px;
    background-image:-owg-linear-gradient(180deg,#f2f0ee 0,white 40%,#dad8d5 60%,#e6c77c 100%);
    background-image:-webkit-linear-gradient(180deg,#f2f0ee 0,white 40%,#dad8d5 60%,#e6c77c 100%);
    background-image:-moz-linear-gradient(180deg,#f2f0ee 0,white 40%,#dad8d5 60%,#e6c77c 100%);
    background-image:-o-linear-gradient(180deg,#f2f0ee 0,white 40%,#dad8d5 60%,#e6c77c 100%);
    background-image:linear-gradient(180deg,#f2f0ee 0,white 40%,#dad8d5 60%,#e6c77c 100%)
}
#chatFooterBar .chatMenu .menuItems .menuItem:hover{
    background-image:-owg-linear-gradient(180deg,white 0,white 50%,#dad8d5 100%);
    background-image:-webkit-linear-gradient(180deg,white 0,white 50%,#dad8d5 100%);
    background-image:-moz-linear-gradient(180deg,white 0,white 50%,#dad8d5 100%);
    background-image:-o-linear-gradient(180deg,white 0,white 50%,#dad8d5 100%);
    background-image:linear-gradient(180deg,white 0,white 50%,#dad8d5 100%)
}
#chatFooterBar .chatMenu .menuItems .menuItem:active{
    background-image:-owg-linear-gradient(180deg,#e6c77c 0,#dad8d5 40%,white 60%,#f2f0ee 100%);
    background-image:-webkit-linear-gradient(180deg,#e6c77c 0,#dad8d5 40%,white 60%,#f2f0ee 100%);
    background-image:-moz-linear-gradient(180deg,#e6c77c 0,#dad8d5 40%,white 60%,#f2f0ee 100%);
    background-image:-o-linear-gradient(180deg,#e6c77c 0,#dad8d5 40%,white 60%,#f2f0ee 100%);
    background-image:linear-gradient(180deg,#e6c77c 0,#dad8d5 40%,white 60%,#f2f0ee 100%)
}
#chatFooterBar .chatMenu .menuItems .menuItem.selected{
    cursor:default;
    background:#fff
}
#chatFooterBar .chatMenu .menuItems .menuItem.selected:before{
    content:"";
    position:absolute;
    left:3px;
    top:-17px;
    box-sizing:border-box;
    width:52px;
    height:16px;
    background:#fff;
    -webkit-transform:perspective(10px) rotateX(10deg);
    -moz-transform:perspective(10px) rotateX(10deg);
    -ms-transform:perspective(10px) rotateX(10deg);
    -o-transform:perspective(10px) rotateX(10deg);
    transform:perspective(10px) rotateX(10deg);
    border:1px solid #9a7641;
    border-bottom:0;
    -webkit-box-shadow:0 -1px 0 1px #fff;
    -moz-box-shadow:0 -1px 0 1px #fff;
    box-shadow:0 -1px 0 1px #fff
}
#chatFooterBar .chatMenu .menuItems .menuItem.selected:after{
    content:"";
    position:absolute;
    width:58px;
    height:3px;
    left:-1px;
    bottom:-3px;
    background:#fff;
    border-right:1px solid #9a7641;
    border-left:1px solid #9a7641
}
#chatFooterBar .chatMenu .menuItems .menuItem.unread:not(.selected):before{
    content:"";
    position:absolute;
    left:8px;
    top:-2px;
    box-sizing:border-box;
    width:42px;
    height:16px;
    background:#c7e94f;
    -webkit-transform:perspective(10px) rotateX(10deg);
    -moz-transform:perspective(10px) rotateX(10deg);
    -ms-transform:perspective(10px) rotateX(10deg);
    -o-transform:perspective(10px) rotateX(10deg);
    transform:perspective(10px) rotateX(10deg);
    border:1px solid #9a7641;
    border-bottom:0;
    z-index:-1;
    animation:unreadChatAnimation 2s infinite linear;
    -webkit-animation:unreadChatAnimation 2s infinite linear;
    -moz-animation:unreadChatAnimation 2s infinite linear
}
#chatFooterBar .chatMenu .menuItems .menuItem i{
    margin-top:-8px
}
#chatFooterBar .chatMenu .menuItems .moreSecretSociety{
    position:relative;
    right:5px;
    width:62px;
    text-align:left
}
#chatFooterBar .chatMenu .menuItems .moreSecretSociety .dropdownBody{
    display:block;
    right:0
}
#chatFooterBar .chatMenu .menuItems .moreSecretSociety .options{
    width:100%;
    display:inline-block;
    margin-top:3px
}
#chatFooterBar .chatMenu .menuItems .moreSecretSociety .options .iconButton{
    width:100%;
    background-image:none;
    border:0;
    color:#252525;
    font-weight:normal
}
#chatFooterBar .chatMenu .menuItems .moreSecretSociety .options .iconButton i{
    margin-left:9px
}
#chatFooterBar .chatMenu .menuItems .moreSecretSociety .options .iconButton:before{
    content:'';
    position:absolute;
    top:-3px;
    left:0;
    width:50%;
    height:100%;
    padding:1px 0;
    background-image:-owg-linear-gradient(#252525,#e6c77c);
    background-image:-webkit-linear-gradient(#252525,#e6c77c);
    background-image:-moz-linear-gradient(#252525,#e6c77c);
    background-image:-o-linear-gradient(#252525,#e6c77c);
    background-image:linear-gradient(#252525,#e6c77c)
}
#chatFooterBar .chatMenu .menuItems .moreSecretSociety .options .iconButton .number{
    display:inline-block;
    position:relative;
    width:50%;
    color:#fff;
    text-align:center
}
#chatFooterBar .chatMenu .chatRoomHeader{
    background:#fff;
    border:1px solid #9a7641;
    line-height:25px;
    padding-left:6px
}
#chatFooterBar .chatMenu .chatRoomHeader .options{
    position:absolute;
    right:6px;
    top:35px
}
#chatFooterBar .chatMenu .chatRoomHeader .options .iconButton{
    width:18px;
    height:18px;
    line-height:17px
}
#chatFooterBar .chatMenu .chatRoomHeader .options .dropdownBody{
    display:block;
    right:0
}
#chatFooterBar .chatMenu .chatWindow{
    position:relative;
    height:356px;
    background-image:url("//iwstatic.g.bsrv.su/img/hud/chat/chat_pattern_small_illu.png");
    background-repeat:repeat;
    background-position:center;
    background-color:#e8e5e1;
    border:1px solid #6d6d6d;
    border-top:0
}
#chatFooterBar .chatMenu .chatWindow .notJoined{
    text-align:center;
    padding-top:30px
}
#chatFooterBar .chatMenu .chatWindow .notJoined i,#chatFooterBar .chatMenu .chatWindow .notJoined button,#chatFooterBar .chatMenu .chatWindow .notJoined span.text{
    display:block;
    margin:0 auto 20px
}
#chatFooterBar .chatMenu .chatWindow .notJoined span.text{
    width:230px
}
#chatFooterBar .chatMenu .chatWindow .notJoined span.textOnline{
    font-weight:bold;
    margin-bottom:20px;
    display:block
}
#chatFooterBar .chatMenu .chatWindow .notJoined button{
    width:240px
}
#chatFooterBar .chatMenu .chatWindow .notJoined i:not(.checkmark){
    filter:alpha(opacity=40);
    opacity:.4
}
#chatFooterBar .chatMenu .chatBody{
    position:relative;
    height:310px;
    width:357px;
    box-sizing:border-box;
    top:0;
    margin-top:0;
    border-top:0
}
#chatFooterBar .dockedChatWindow{
    position:absolute;
    bottom:23px;
    left:-2px;
    right:-2px;
    height:273px;
    border:1px solid #fff;
    -webkit-box-shadow:0 0 2px rgba(0,0,0,0.7);
    -moz-box-shadow:0 0 2px rgba(0,0,0,0.7);
    box-shadow:0 0 2px rgba(0,0,0,0.7);
    background-color:rgba(241,240,238,0.6);
    padding:4px
}
#chatFooterBar .dockedChatWindow .chatWindow{
    background-image:url("//iwstatic.g.bsrv.su/img/hud/chat/chat_pattern_small_illu.png");
    background-repeat:repeat;
    background-position:center;
    background-color:#e8e5e1
}
#chatFooterBar .dockedChatWindow .dockedChatBody{
    height:214px;
    border:1px solid #e6c77c;
    position:relative;
    top:-1px;
    left:0;
    margin:0;
    padding:0 5px
}
#chatFooterBar .dockedChatWindow .dockedChatBody .chatBody{
    position:absolute;
    margin-top:0;
    top:0;
    bottom:37px;
    min-width:331px
}
#chatFooterBar .dockedChatWindow .dockedChatBody .inputWrapper{
    position:absolute;
    bottom:-2px;
    width:100%;
    padding:0 5px;
    left:-5px
}
#chatFooterBar .dockedChatWindow .dockedChatBody .inputWrapper textarea.chatInput{
    width:270px
}
#chatFooterBar .roomNotification{
    background:#fff;
    font-weight:bold;
    text-align:center;
    padding:3px 6px;
    position:absolute;
    top:0;
    left:0;
    z-index:2;
    box-sizing:border-box;
    width:100%;
    border-bottom:1px solid #9a7641;
    border-right:1px solid #9a7641;
    filter:alpha(opacity=80);
    opacity:.8
}
#chatFooterBar .roomNotification.red{
    color:red
}
.chatControls{
    border:1px solid #e6c77c;
    height:54px;
    cursor:default
}
.chatControls .chatHeader{
    background:#fff;
    height:54px
}
.chatControls .chatHeader.draggableHeader{
    cursor:move
}
.chatControls .chatHeader .chatMember{
    position:relative;
    width:56px
}
.chatControls .chatHeader .chatMember:after{
    content:'';
    position:absolute;
    right:-1px;
    top:6px;
    width:1px;
    height:38px;
    border-right:1px solid #c0b29b
}
.chatControls .chatHeader .chatMember .playerBox{
    width:40px;
    height:34px;
    overflow:hidden;
    margin:0 auto
}
.chatControls .chatHeader .chatMember .playerBox .heroImage{
    top:-5px;
    left:-7px
}
.chatControls .chatHeader .chatMember .playerBox .heroImage.female{
    top:-8px;
    left:-9px
}
.chatControls .chatHeader .chatMember .chatStatus{
    position:absolute;
    top:0;
    right:6px
}
.chatControls .chatHeader .chatMember .prestigeStars{
    position:relative;
    border-top:1px solid #c0b29b;
    width:46px;
    height:17px;
    margin:0 auto;
    background-color:transparent
}
.chatControls .chatHeader .chatMember .prestigeStars:before,.chatControls .chatHeader .chatMember .prestigeStars:after{
    display:none
}
.chatControls .chatHeader .chatMember .prestigeStars .prestigeStar{
    display:block;
    margin:2px 0 0 0;
    padding:0;
    float:left;
    z-index:1
}
.chatControls .chatHeader .chatMember .prestigeStars .prestigeStar:first-child{
    margin-left:4px
}
.chatControls .chatHeader .controls{
    position:absolute;
    right:5px;
    top:5px
}
.chatControls .chatHeader .controls i.control{
    float:right;
    border-left:1px solid #e6c77c;
    padding:7px
}
.chatControls .chatHeader .controls:after{
    content:'';
    position:absolute;
    right:6px;
    bottom:-4px;
    width:279px;
    height:1px;
    border-bottom:1px solid #dad8d5
}
.chatControls .chatHeader .options{
    position:absolute;
    top:37px;
    right:11px
}
.chatControls .chatHeader .options .iconButton{
    width:18px;
    height:18px;
    line-height:17px
}
.chatControls .chatHeader .options .dropdownBody{
    display:block;
    right:0
}
.chatControls .chatHeader .flagHanger{
    left:auto;
    right:125px;
    top:-3px
}
.chatControls .chatHeader .playerLink.playerName{
    position:absolute;
    top:11px;
    left:70px;
    max-width:140px;
    height:20px
}
.chatControls .chatHeader .membership{
    position:absolute;
    top:38px;
    left:70px;
    max-width:250px
}
.chatControls .chatHeader .membership a{
    font-weight:normal
}
.chatMembers{
    position:absolute;
    right:-65px;
    top:31px;
    width:65px;
    height:381px;
    border:1px solid #9a7641;
    background-color:rgba(184,178,169,0.6);
    -webkit-box-shadow:1px 0 3px 0 rgba(23,39,9,0.75);
    -moz-box-shadow:1px 0 3px 0 rgba(23,39,9,0.75);
    box-shadow:1px 0 3px 0 rgba(23,39,9,0.75)
}
.chatMembers .chatMember{
    z-index:3
}
.chatMembers .chatMember .prestigeStars{
    margin-top:1px
}
.chatMembers .members{
    height:327px;
    padding-top:3px
}
.chatMembers .prev,.chatMembers .next{
    cursor:pointer;
    position:relative;
    height:25px;
    border-bottom:1px solid #9a7641;
    background-color:rgba(235,235,235,0.65)
}
.chatMembers .prev:hover:after,.chatMembers .next:hover:after{
    border-bottom-color:#a5c400
}
.chatMembers .prev:active:after,.chatMembers .next:active:after{
    border-bottom-color:#463f39
}
.chatMembers .prev.disabled:after,.chatMembers .next.disabled:after{
    border-bottom-color:rgba(70,63,57,0.3)
}
.chatMembers .next{
    border-bottom:0;
    border-top:1px solid #9a7641
}
.chatMembers .next:hover:after{
    border-top-color:#a5c400
}
.chatMembers .next:active:after{
    border-top-color:#463f39
}
.chatMembers .next.disabled:after{
    border-top-color:rgba(70,63,57,0.3)
}
.chatMembers .prev:after,.chatMembers .next:after{
    content:'';
    position:absolute;
    top:4px;
    left:calc(50% - 9px)
}
.chatMembers .prev:after{
    height:0;
    width:0;
    border-style:solid;
    border-color:transparent transparent #463f39 transparent;
    border-width:0 9px 17px 9px
}
.chatMembers .next:after{
    height:0;
    width:0;
    border-style:solid;
    border-color:#463f39 transparent transparent transparent;
    border-width:17px 9px 0 9px
}
.chatMembers .names{
    position:absolute;
    top:25px;
    left:53px;
    overflow:hidden
}
.chatMembers .names.slideOut .container{
    -webkit-transition:margin-left .15s linear;
    -moz-transition:margin-left .15s linear;
    -o-transition:margin-left .15s linear;
    transition:margin-left .15s linear;
    -webkit-transition-delay:.2s;
    -moz-transition-delay:.2s;
    -o-transition-delay:.2s;
    transition-delay:.2s;
    margin-left:13px
}
.chatMembers .names .container{
    margin-left:-146px;
    width:145px;
    border:1px solid #9a7641;
    border-left:0;
    background-color:rgba(184,178,169,0.8);
    -webkit-box-shadow:1px 0 3px 0 rgba(23,39,9,0.75);
    -moz-box-shadow:1px 0 3px 0 rgba(23,39,9,0.75);
    box-shadow:1px 0 3px 0 rgba(23,39,9,0.75);
    -webkit-transition:margin-left .1s linear;
    -moz-transition:margin-left .1s linear;
    -o-transition:margin-left .1s linear;
    transition:margin-left .1s linear;
    padding-bottom:2px
}
.chatMembers .names .container>div{
    margin-left:-14px
}
.chatMembers .names .container>div:hover .name{
    background-color:rgba(242,246,217,0.8)
}
.chatMembers .names .name{
    height:30px;
    padding:0 4px;
    padding-left:10px;
    margin-top:4px;
    margin-right:14px;
    background-color:rgba(255,255,255,0.6);
    word-wrap:break-word;
    line-height:15px
}
.chatMembers .names .prestigeDecor{
    position:relative;
    background-color:rgba(235,235,235,0.9);
    border-top:1px solid #fff;
    width:144px;
    height:17px;
    margin-left:5px;
    margin-bottom:5px;
    margin-top:1px
}
.chatMembers .names .prestigeDecor:after{
    content:"";
    z-index:0;
    border-top:1px solid #fff;
    position:absolute;
    background-color:rgba(235,235,235,0.9);
    top:-1px;
    width:17px;
    height:17px;
    right:-4px;
    -webkit-transform:skewX(-21deg);
    -moz-transform:skewX(-21deg);
    -ms-transform:skewX(-21deg);
    -o-transform:skewX(-21deg);
    transform:skewX(-21deg)
}
.chatMember{
    height:50px;
    position:relative;
    margin-bottom:4px;
    width:65px
}
.chatMember .chatStatus{
    position:absolute;
    top:0;
    right:8px
}
.chatMember .playerBox{
    width:40px;
    height:31px;
    background-image:-owg-linear-gradient(to top,#fff 5px,transparent 40px);
    background-image:-webkit-linear-gradient(to top,#fff 5px,transparent 40px);
    background-image:-moz-linear-gradient(to top,#fff 5px,transparent 40px);
    background-image:-o-linear-gradient(to top,#fff 5px,transparent 40px);
    background-image:linear-gradient(to top,#fff 5px,transparent 40px);
    overflow:hidden;
    margin:0 auto
}
.chatMember .playerBox .heroImage{
    top:-9px;
    left:-7px
}
.chatMember .playerBox .heroImage.female{
    left:-9px
}
.chatMember .prestigeStars{
    position:relative;
    background-color:rgba(235,235,235,0.9);
    border-top:1px solid #fff;
    width:46px;
    height:17px;
    margin-left:10px
}
.chatMember .prestigeStars:before,.chatMember .prestigeStars:after{
    content:"";
    z-index:0;
    border-top:1px solid #fff;
    position:absolute;
    background-color:rgba(235,235,235,0.9);
    top:-1px;
    width:17px;
    height:17px
}
.chatMember .prestigeStars:before{
    border-top:1px solid #fff;
    left:-4px;
    -webkit-transform:skewX(21deg);
    -moz-transform:skewX(21deg);
    -ms-transform:skewX(21deg);
    -o-transform:skewX(21deg);
    transform:skewX(21deg)
}
.chatMember .prestigeStars:after{
    border-top:1px solid #fff;
    right:-4px;
    -webkit-transform:skewX(-21deg);
    -moz-transform:skewX(-21deg);
    -ms-transform:skewX(-21deg);
    -o-transform:skewX(-21deg);
    transform:skewX(-21deg)
}
.chatMember .prestigeStars .prestigeStar{
    display:block;
    margin:2px 0 0 2px;
    padding:0;
    float:left;
    z-index:1
}
.chatMember .prestigeStars .prestigeBadge{
    margin:2px auto 0;
    display:block;
    z-index:2
}
#chatFooterBar .footerButton .dynamicTabulationDropdown ul{
    max-height:252px;
    overflow-y:scroll
}
#chatFooterBar .footerButton .dynamicTabulationDropdown .chatNameContainer{
    position:relative;
    height:auto
}
#chatFooterBar .newChatWrapper{
    overflow:hidden;
    position:absolute;
    top:1px;
    right:1px;
    width:100%;
    height:21px
}
#chatFooterBar .newChatWrapper .newChat{
    position:absolute;
    top:0;
    filter:alpha(opacity=100);
    opacity:1;
    right:0;
    background:#c7e94f;
    border:1px solid #fff;
    font-weight:bold;
    padding:0 9px;
    font-size:11px;
    line-height:20px;
    height:19px;
    padding-left:1px;
    border-left:0;
    z-index:2;
    animation:newChatAnimation 1s linear;
    -webkit-animation:newChatAnimation 1s linear;
    -moz-animation:newChatAnimation 1s linear
}
#chatFooterBar .newChatWrapper .newChat:before{
    position:absolute;
    content:'';
    width:21px;
    height:19px;
    -webkit-transform:skew(-45deg);
    -moz-transform:skew(-45deg);
    -ms-transform:skew(-45deg);
    -o-transform:skew(-45deg);
    transform:skew(-45deg);
    background:#c7e94f;
    bottom:-1px;
    z-index:-1;
    border:1px solid #fff;
    left:-11px;
    border-right:0
}
#chatFooterBar .unreadNotification{
    position:absolute;
    top:-27px;
    right:5px;
    background:#c7e94f;
    line-height:16px;
    padding:2px 5px;
    border:1px solid #fff;
    -webkit-box-shadow:0 0 3px rgba(0,0,0,0.7);
    -moz-box-shadow:0 0 3px rgba(0,0,0,0.7);
    box-shadow:0 0 3px rgba(0,0,0,0.7)
}
#chatFooterBar .unreadNotification:before{
    position:absolute;
    content:'';
    width:5px;
    height:5px;
    background:#c7e94f;
    bottom:-3px;
    right:-1px;
    border-right:1px solid #fff
}
#chatFooterBar .unreadNotification:after{
    position:absolute;
    content:'';
    width:16px;
    height:2px;
    -webkit-transform:rotate(45deg) skew(45deg);
    -moz-transform:rotate(45deg) skew(45deg);
    -ms-transform:rotate(45deg) skew(45deg);
    -o-transform:rotate(45deg) skew(45deg);
    transform:rotate(45deg) skew(45deg);
    background:#c7e94f;
    z-index:-1;
    bottom:-2px;
    border:1px solid #fff;
    -webkit-box-shadow:0 0 3px rgba(0,0,0,0.7);
    -moz-box-shadow:0 0 3px rgba(0,0,0,0.7);
    box-shadow:0 0 3px rgba(0,0,0,0.7);
    right:-4px
}
#chatFooterBar .unreadNotification span{
    color:#000;
    font-weight:bold
}
</style>

<script>
import lib from '@/lib';

import ChatMenu from '@/components/chat/ChatMenu';
import DockedChat from '@/components/chat/DockedChat';

export default {
	mixins: lib.mixins,
	data: () => ({
		connected: true,
		unreadMessages: 8,
		
		showHiddenTabsDropdown: false,
		
		activeTab: null,
		tabsList: [],
	}),
	computed: {
		tabs(){
			let tabs = [], isDocked = 0, isNoDocked = 0;
			this.tabsList.forEach((tab, index) => {
				if(tab.data.isDocked) isDocked++;
				else isNoDocked++;
				
				if(this.gameModel.chatMenuOpen){
					if(isNoDocked <= 3 && isDocked == 0){
						tabs.push(tab);
					} else if(isNoDocked <= 0 && isDocked == 1){
						tabs.push(tab);
					}
				} else {
					if(isNoDocked <= 6 && isDocked == 0){
						tabs.push(tab);
					} else if(isNoDocked <= 3 && isDocked == 1){
						tabs.push(tab);
					} else if(isNoDocked <= 1 && isDocked == 2){
						tabs.push(tab);
					}
				}
			});
			return tabs;
		},
		tabsFilter(){
			return this.tabs.filter((chatInfo) => chatInfo.data.chatUser.data.name);
		},
		tabsMore(){
			let tabsMore = [], isDocked = 0, isNoDocked = 0;
			this.tabsList.forEach((tab, index) => {
				if(tab.data.isDocked) isDocked++;
				else isNoDocked++;
				
				if(this.gameModel.chatMenuOpen){
					if(isNoDocked <= 3 && isDocked == 0){
					} else if(isNoDocked <= 0 && isDocked == 1){
					} else {
						tabsMore.push(tab);
					}
				} else {
					if(isNoDocked <= 6 && isDocked == 0){
					} else if(isNoDocked <= 3 && isDocked == 1){
					} else if(isNoDocked <= 1 && isDocked == 2){
					} else {
						tabsMore.push(tab);
					}
				}
			});
			return tabsMore;
		},
		tabsMoreFilter(){
			return this.tabsMore.filter((chatInfo) => chatInfo.data.chatUser.data.name);
		},
	},
	methods: {
		onOpenActiveTab(roomId = lib.genId(), chatUserName = null){
			let chatUserNames = [
				'Ahriston',
				'AhristonAhristonAhristonAhristonAhristonAhriston',
				'Кетсалькоатль',
				'LordHermes',
				'Recovery',
				'alistan',
				'ildar',
				'vistoyn',
				'lexinzector',
				'admin',
			];
			let kingdomRoles = ['governor', 'duke', 'king', 'viceking'];
			let isNew = [true, false];
			
			let isNewVal = isNew[Math.floor(Math.random() * isNew.length)];
			let tab = {
				data: {
					chatUser: {
						data: {
							name: chatUserName != null ? chatUserName : chatUserNames[Math.floor(Math.random() * chatUserNames.length)],
							kingdomId: 2,
							kingdomRole: kingdomRoles[Math.floor(Math.random() * kingdomRoles.length)],
							online: '',
						},
					},
					chatInbox: {
						data: {
							unread: isNewVal ? 1 : 0,
						},
					},
					isNew: isNewVal,
					isDocked: false,
					roomId: roomId,
					joined: true,
				},
			};
			tab.data.isDocked = true;
			this.tabsList.push(tab);
			this.activeTab = tab.data.roomId;
			
			this.onSetActiveTab(this.activeTab);
		},
		onCloseActiveTab(roomId){
			let tabs = [];
			this.tabsList.forEach((tab, tabIndex) => {
				if(tab.data.roomId != roomId){
					tabs.push(tab);
				}
			});
			this.tabsList = tabs;
			
			this.onSetActiveTab();
		},
		onMinimizeActiveTab(roomId){
			this.tabsList.forEach((tab, tabIndex) => {
				if(tab.data.roomId == roomId){
					if(tab.data.isDocked){
						tab.data.isDocked = false;
					}
				}
			});
			
			this.onSetActiveTab();
		},
		onToggleActiveTab(roomId){
			if(this.activeTab != roomId){
				// вкладка не активна
				this.activeTab = roomId;
				
				let tabs = [], isDocked = 0, isNoDocked = 0, isNoDockedPos = [], posCnt = 0, isNoDockedPosLast = 0;
				
				this.tabs.forEach((tab, tabIndex) => {
					if(tab.data.isDocked) isDocked++;
					else isNoDocked++;
				});
				this.tabsList.forEach((tab, tabIndex) => {
					posCnt++;
					if(!tab.data.isDocked){
						isNoDockedPos.push(posCnt);
					}
					
					if(tab.data.roomId != roomId){
						tabs.push(tab);
					} else {
						isNoDockedPosLast = posCnt;
						
						tab.data.isDocked = true;
						if(this.gameModel.chatMenuOpen){
							tabs.splice(0, 0, tab);
						} else {
							if(isDocked == 0){
								tabs.splice(3, 0, tab);
							} else if(isDocked == 1){
								if(isNoDocked == 1){
									if(isNoDockedPos[0] == 1){
										tabs.splice(0, 0, tab);
									} else {
										tabs.splice(1, 0, tab);
									}
								} else if(isNoDocked == 2){
									if(isNoDockedPosLast == 1
									|| isNoDockedPosLast == 2){
										tabs.splice(isNoDockedPosLast - 1, 0, tab);
									} else {
										if(isNoDockedPos.indexOf(2) != -1
										&& isNoDockedPos.indexOf(3) != -1){
											//+23
											tabs.splice(2, 0, tab);
										} else if(isNoDockedPos.indexOf(1) != -1
										&& isNoDockedPos.indexOf(3) != -1){
											//1+3
											tabs.splice(2, 0, tab);
										} else if(isNoDockedPos.indexOf(1) != -1
										&& isNoDockedPos.indexOf(2) != -1){
											//12+
											tabs.splice(2, 0, tab);
										}
									}
								} else {
									if(isNoDockedPosLast == 1
									|| isNoDockedPosLast == 2
									|| isNoDockedPosLast == 3){
										tabs.splice(isNoDockedPosLast - 1, 0, tab);
									} else {
										if(isNoDockedPos.indexOf(2) != -1
										&& isNoDockedPos.indexOf(3) != -1
										&& isNoDockedPos.indexOf(4) != -1){
											//+234
											tabs.splice(2, 0, tab);
										} else if(isNoDockedPos.indexOf(1) != -1
										&& isNoDockedPos.indexOf(3) != -1
										&& isNoDockedPos.indexOf(4) != -1){
											//1+34
											tabs.splice(2, 0, tab);
										} else if(isNoDockedPos.indexOf(1) != -1
										&& isNoDockedPos.indexOf(2) != -1
										&& isNoDockedPos.indexOf(4) != -1){
											//12+4
											tabs.splice(2, 0, tab);
										} else if(isNoDockedPos.indexOf(1) != -1
										&& isNoDockedPos.indexOf(2) != -1
										&& isNoDockedPos.indexOf(3) != -1){
											//123+
											tabs.splice(3, 0, tab);
										}
									}
								}
							} else if(isDocked == 2){
								if(isNoDocked == 0){
									tabs.splice(1, 0, tab);
								} else if(isNoDocked == 1){
									if(isNoDockedPos[0] <= 2){
										tabs.splice(2, 0, tab);
									} else {
										tabs.splice(1, 0, tab);
									}
								} else {
									tabs.splice(2, 0, tab);
								}
							}
						}
					}
				});
				
				this.tabsList = tabs;
			} else {
				// вкладка активна
				this.activeTab = null;
				
				let flagUndocked = false;
				this.tabsList.forEach((tab, tabIndex) => {
					if(tab.data.roomId == roomId){
						if(tab.data.isDocked){
							tab.data.isDocked = false;
							flagUndocked = true;
						}
					}
				});
				
				if(flagUndocked){
					this.onSetActiveTab();
				}
			}
			this.onChatUserScrollToEnd();
		},
		onToggleHiddenTabsDropdown(){
			this.showHiddenTabsDropdown = !this.showHiddenTabsDropdown;
		},
		onToggleChatMenu(){
			this.gameModel.chatMenuOpen = !this.gameModel.chatMenuOpen;
			
			this.onChatScrollToEnd();
			this.onSetActiveTab(this.activeTab);
		},
		onSetActiveTab(activeTab = null){
			let tabs = [], activeTabSearch = activeTab == null, isDocked = 0, isNoDocked = 0, isNoDockedPos = [], posCnt = 0, isNoDockedPosLast = 0;
			
			this.tabs.forEach((tab, tabIndex) => {
				if(tab.data.isDocked) isDocked++;
				else isNoDocked++;
			});
			this.tabsList.forEach((tab, tabIndex) => {
				posCnt++;
				if(!tab.data.isDocked){
					isNoDockedPos.push(posCnt);
				}
				
				if((activeTabSearch && activeTab == null && tab.data.isDocked)
				|| (!activeTabSearch && tab.data.roomId == activeTab)){
					if(activeTabSearch) activeTab = tab.data.roomId;
					isNoDockedPosLast = posCnt;
					if(this.gameModel.chatMenuOpen){
						tabs.splice(0, 0, tab);
					} else {
						if(isDocked == 0){
							tabs.splice(3, 0, tab);
						} else if(isDocked == 1){
							if(isNoDocked == 1){
								if(isNoDockedPos[0] == 1){
									tabs.splice(0, 0, tab);
								} else {
									tabs.splice(1, 0, tab);
								}
							} else if(isNoDocked == 2){
								if(isNoDockedPosLast == 1
								|| isNoDockedPosLast == 2){
									tabs.splice(isNoDockedPosLast - 1, 0, tab);
								} else {
									if(isNoDockedPos.indexOf(2) != -1
									&& isNoDockedPos.indexOf(3) != -1){
										//+23
										tabs.splice(2, 0, tab);
									} else if(isNoDockedPos.indexOf(1) != -1
									&& isNoDockedPos.indexOf(3) != -1){
										//1+3
										tabs.splice(2, 0, tab);
									} else if(isNoDockedPos.indexOf(1) != -1
									&& isNoDockedPos.indexOf(2) != -1){
										//12+
										tabs.splice(2, 0, tab);
									}
								}
							} else {
								if(isNoDockedPosLast == 1
								|| isNoDockedPosLast == 2
								|| isNoDockedPosLast == 3){
									tabs.splice(isNoDockedPosLast - 1, 0, tab);
								} else {
									if(isNoDockedPos.indexOf(2) != -1
									&& isNoDockedPos.indexOf(3) != -1
									&& isNoDockedPos.indexOf(4) != -1){
										//+234
										tabs.splice(2, 0, tab);
									} else if(isNoDockedPos.indexOf(1) != -1
									&& isNoDockedPos.indexOf(3) != -1
									&& isNoDockedPos.indexOf(4) != -1){
										//1+34
										tabs.splice(2, 0, tab);
									} else if(isNoDockedPos.indexOf(1) != -1
									&& isNoDockedPos.indexOf(2) != -1
									&& isNoDockedPos.indexOf(4) != -1){
										//12+4
										tabs.splice(2, 0, tab);
									} else if(isNoDockedPos.indexOf(1) != -1
									&& isNoDockedPos.indexOf(2) != -1
									&& isNoDockedPos.indexOf(3) != -1){
										//123+
										tabs.splice(3, 0, tab);
									}
								}
							}
						} else if(isDocked == 2){
							if(isNoDocked == 0){
								tabs.splice(1, 0, tab);
							} else if(isNoDocked == 1){
								if(isNoDockedPos[0] <= 2){
									tabs.splice(2, 0, tab);
								} else {
									tabs.splice(1, 0, tab);
								}
							} else {
								tabs.splice(2, 0, tab);
							}
						}
					}
				} else {
					tabs.push(tab);
				}
			});
			
			this.tabsList = tabs;
			this.activeTab = activeTab;
			
			this.onChatUserScrollToEnd();
		},
		
		onChatScrollToEnd(){
			if(this.gameModel.chatMenuOpen){
				setTimeout(() => {
					let container = this.$el.querySelector(".chatMenu .chatBody .scrollContentOuterWrapper .scrollContent");
					if(container){
						container.scrollTop = container.scrollHeight;
					}
				}, 100);
			}
		},
		onChatUserScrollToEnd(){
			if(this.activeTab){
				setTimeout(() => {
					let container = this.$el.querySelector(".dockedChatWindow[room='"+this.activeTab+"'] .chatBody .scrollContentOuterWrapper .scrollContent");
					if(container){
						container.scrollTop = container.scrollHeight;
					}
				}, 100);
			}
		},
	},
	beforeMount(){
		/*
		this.onOpenActiveTab(1, 'LordHermes');
		this.onOpenActiveTab(2, 'Ahriston');
		this.onOpenActiveTab(3, 'alistan');
		this.onOpenActiveTab(4, 'ildar');
		this.onOpenActiveTab(5, 'vistoyn');
		this.onOpenActiveTab(6, 'lexinzector');
		this.onOpenActiveTab(7, 'admin');
		*/
	},
	components: {
		ChatMenu,
		DockedChat,
	},
}
</script>
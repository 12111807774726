<!--
<range-slider v-model="value" />
-->

<template>
	<div class="slider">
		<div class="sliderContainer unselectable" :class="{'locked': disabled}">
			<div class="trackContainer">
				<input type="range" :min="min" :max="maxVal" :step="step" :disabled="disabled" v-model="model" />
			</div>
			<div class="inputContainer">
				<i :class="iconClass" v-if="iconClass"></i>
				<input class="value" type="number" :min="min" :max="maxVal" :step="step" @wheel="$event.target.blur()" :disabled="disabled" v-model.number="model" v-if="showInput" />
				<div class="iconButton maxButton clickable" :class="{'disabled': !maxVal || disabled}" @click="onSetMax()" v-if="showMaxButton">
					<i class="action_setMax_small_flat_black"></i>
				</div>
			</div>
		</div>
		
		<!--
		<div>
			<div class="sliderContainer unselectable" :class="{'locked': disabled}">
				<div class="trackContainer">
					<div class="track">
						<div v-if="stepCount && !hideSteps" class="stepContainer">
							<div ng-repeat="n in range(0, stepCount)" class="sliderStep" :style="{width: (Math.min((n / stepCount * 100), 100))+'%'}"></div>
						</div>
						<div class="sliderMarker progressMarker" style="width: 0%;"></div>
						<div class="hoverIndicator" style="display: none; left: 104px; opacity: 1;">
							<div class="hoverValue">2400</div>
							<div class="indicator"></div>
						</div>
						<div class="handle unselectable" style="left: 0px;"></div>
					</div>
				</div>
				<div class="inputContainer">
					<i :class="iconClass" v-if="iconClass"></i>
					<input class="value" type="number" v-model.number="model" v-if="showInput" />
					<div class="iconButton maxButton clickable" :class="{'disabled': maxVal == 0 || disabled}" @click="onSetMax()" v-if="showMaxButton">
						<i class="action_setMax_small_flat_black"></i>
					</div>
				</div>
			</div>
		</div>
		-->
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	props: {
		modelValue: {
			type: Number,
		},
		min: {
			type: Number,
		},
		max: {
			type: Number,
		},
		step: {
			type: Number,
			default: 1,
		},
		hideSteps: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		iconClass: {
			type: String,
		},
		showInput: {
			type: Boolean,
			default: true,
		},
		showMaxButton: {
			type: Boolean,
			default: true,
		},
		haha: {
			type: Number,
		},
	},
	data: () => ({
		maxVal: null,
	}),
	emits: ['update:modelValue'],
	computed: {
		model: {
			get(){
				return this.modelValue;
			},
			set(value){
				this.$emit('update:modelValue', value);
			},
		},
		stepCount(){
			return Math.abs(this.maxVal - this.min) / this.step;
		},
	},
	watch: {
		model(val, oldVal){
			let vval = parseFloat(val)||0;
			this.model -= vval % this.step;
			if(typeof(this.min) != 'undefined'){
				if(vval < this.min) this.model = this.min;
			}
			if(typeof(this.maxVal) != 'undefined'){
				if(vval > this.maxVal) this.model = this.maxVal;
			}
		},
		max(){
			this.maxVal = this.max;
		},
	},
	methods: {
		range: lib.range,
		
		onSetMax(){
			if(typeof(this.maxVal) != 'undefined' && this.maxVal > 0 && !this.disabled){
                this.model = this.maxVal;
            }
		},
	},
	created(){
		this.maxVal = this.max;
		if(typeof(this.maxVal) != 'undefined'){
			this.maxVal -= this.maxVal % this.step;
		}
	},
}
</script>
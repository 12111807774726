<template>
	<div class="statisticsTable playerRanking contentBox transparent">
		<h6 class="contentBoxHeader headerWithArrowEndings tableGreen">
			<div class="content" translate=""><span>Самые успешные обороняющиеся</span></div>
		</h6>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems[tab]" current-page="currentPage[tab]" display-page-func="displayCurrentPage" route-named-param="statsPage">
			<div ng-transclude="">
				<div class="statisticsContainer">
					<div ng-include="" src="'tpl/statistics/partials/playerInfo.html'">
						<div ng-hide="showHover" class="playerInfo top3">
							<div class="playerInfoHeader">
								<div content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></div>
								<div content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></div>
							</div>
							<div class="content rank_1" ng-repeat="player in top3[tab]" clickable="showFixedRankPlayerInfo(player.rank)">
								<div class="playerInfoRow">
									<div class="rank">
										1
									</div>
									<div class="name">
										<div class="content">
											<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="121" nolink="true">Мистер</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 0}, null, true)" kingdomid="0" nolink="true"></a>
										</div>
									</div>
									<div class="avatar">
										<avatar-image class="avatarImage" player-id="121" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<div class="prestigeStars" ng-if="config.balancing.features.prestige">
											<div class="innerStars" playerid="121" size="tiny">
												<!-- ngIf: !badge -->
												<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="pointsInfoRow Defender">
									<!-- ngIf: tab == 'Overview' -->
									<!-- ngIf: tab == 'Attacker' -->
									<i ng-if="tab == 'Defender'" class="unit_troop_medium_illu" content="Войско, уничтоженное в защите (в пересчете на потребляемое зерно)" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.Defender.Points.Tooltip"></i>
									<!-- ngIf: tab == 'Heroes' -->
									<span translate="" class="pointsName" options="Defender"><span>Уничтожено войска</span></span>
									<span class="points">4&nbsp;437</span>
								</div>
							</div>
							<div class="content rank_2" ng-repeat="player in top3[tab]" clickable="showFixedRankPlayerInfo(player.rank)">
								<div class="playerInfoRow">
									<div class="rank">
										2
									</div>
									<div class="name">
										<div class="content">
											<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="146" nolink="true">павел 215</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 0}, null, true)" kingdomid="0" nolink="true"></a>
										</div>
									</div>
									<div class="avatar">
										<avatar-image class="avatarImage" player-id="146" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<div class="prestigeStars" ng-if="config.balancing.features.prestige">
											<div class="innerStars" playerid="146" size="tiny">
												<!-- ngIf: !badge -->
												<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="pointsInfoRow Defender">
									<!-- ngIf: tab == 'Overview' -->
									<!-- ngIf: tab == 'Attacker' -->
									<i ng-if="tab == 'Defender'" class="unit_troop_medium_illu" content="Войско, уничтоженное в защите (в пересчете на потребляемое зерно)" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.Defender.Points.Tooltip"></i>
									<!-- ngIf: tab == 'Heroes' -->
									<span translate="" class="pointsName" options="Defender"><span>Уничтожено войска</span></span>
									<span class="points">4&nbsp;028</span>
								</div>
							</div>
							<div class="content rank_3" ng-repeat="player in top3[tab]" clickable="showFixedRankPlayerInfo(player.rank)">
								<div class="playerInfoRow">
									<div class="rank">
										3
									</div>
									<div class="name">
										<div class="content">
											<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="355" nolink="true">Миссис</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 0}, null, true)" kingdomid="0" nolink="true"></a>
										</div>
									</div>
									<div class="avatar">
										<avatar-image class="avatarImage" player-id="355" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<div class="prestigeStars" ng-if="config.balancing.features.prestige">
											<div class="innerStars" playerid="355" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												</div>
												<!-- ngIf: badge -->
											</div>
										</div>
									</div>
								</div>
								<div class="pointsInfoRow Defender">
									<!-- ngIf: tab == 'Overview' -->
									<!-- ngIf: tab == 'Attacker' -->
									<i ng-if="tab == 'Defender'" class="unit_troop_medium_illu" content="Войско, уничтоженное в защите (в пересчете на потребляемое зерно)" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.Defender.Points.Tooltip"></i>
									<!-- ngIf: tab == 'Heroes' -->
									<span translate="" class="pointsName" options="Defender"><span>Уничтожено войска</span></span>
									<span class="points">3&nbsp;220</span>
								</div>
							</div>
							<div class="averagePoints">
								<div class="pointsRow">
									<span translate=""><span>Ø</span></span><span class="points">185</span>
								</div>
								<div class="descriptionRow" translate="" options="Defender"><span>Средние очки защиты</span></div>
							</div>
						</div>
						<div ng-show="showHover" class="playerInfo playerProfile" on-pointer-over="showPlayerInfo(selectedData.rank)" on-pointer-out="hidePlayerInfo()">
							<div class="playerInfoHeader">
								<div>
									lexinzector
								</div>
							</div>
							<div class="playerProfileAvatarContainer">
								<div class="detailsWrapper">
									<div class="playerProfileAvatar kingdomRole0">
										<div class="heroLevel">
											<span translate=""><span>Ур.</span></span>
											<br>
											<span>1</span>
										</div>
										<div class="avatarLink clickable" clickable="openOverlay('playerProfileFullImage', {'playerId': 1804 });">
											<avatar-image scale="0.55" class="avatarImage" player-id="1804" size="big" avatar-class="profileImage">
												<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
													<!-- ngIf: playerId == robberId -->
													<!-- ngIf: playerId == npcGovernorId -->
													<canvas width="192" height="192"></canvas>
												</div>
											</avatar-image>
											<div class="prestigeStars" ng-if="config.balancing.features.prestige">
												<div class="innerStars" playerid="1804" size="tiny">
													<div ng-if="!badge">
														<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
														<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
														<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													</div>
													<!-- ngIf: badge -->
												</div>
											</div>
										</div>
										<span class="decoration"></span>
									</div>
								</div>
							</div>
							<div class="playerDataContainer">
								<div class="indicationArrow position_9" ng-class="{disabled: currentPage[tab] > 1 &amp;&amp; selectedData.rank < itemsPerPage}"></div>
								<div class="flagHanger governor" ng-class="['king', 'viceking', 'duke', 'governor']">
									<div class="flag"></div>
									<i class="community_governor_small_flat_black" ng-class="['community_king_small_flat_black', 'community_viceking_small_flat_black', 'community_duke_small_flat_black', 'community_governor_small_flat_black']"></i>
								</div>
								<div class="header">
									<!-- ngIf: tab == 'Overview' -->
									<!-- ngIf: tab == 'Attacker' -->
									<i ng-if="tab == 'Defender'" class="movement_defend_small_flat_black" content="Войско, уничтоженное в защите (в пересчете на потребляемое зарно)" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.Defender.Points.Tooltip"></i>
									<!-- ngIf: tab == 'Heroes' -->
									<i content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank" class="symbol_ranking_small_flat_black"></i>
								</div>
								<div class="statsData">
									<div class="currentData">
										<span class="description" translate=""><span>Сейчас</span></span>
										<span class="points">1</span>
										<span class="rank">340.</span>
									</div>
									<div class="lastWeekData">
										<span class="description" translate=""><span>Прошлая неделя</span></span>
										<span ng-if="playerStatsLastWeek.rank >= 0" class="points">1</span>
										<!-- ngIf: playerStatsLastWeek.rank < 0 -->
										<span ng-if="playerStatsLastWeek.rank >= 0" class="rank">333.</span>
										<!-- ngIf: playerStatsLastWeek.rank < 0 -->
									</div>
								</div>
								<div class="playerData">
									<div>
										<span class="description"><span translate=""><span>Народ</span></span>:</span>
										<span class="content" translate="" options="3"><span>Галлы</span></span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Деревни</span></span>:</span>
										<span class="content">1</span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Роль</span></span>:</span>
										<span translate="" class="content" options="0"><span>Губернатор</span></span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Королевство</span></span>:</span>
										<span class="content">
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 0}, null, true)" kingdomid="0" kingdomname="-">-</a>
											<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3" kingdomname="MИР">MИР</a>
										</span>
									</div>
									<div>
										<span class="description"><span translate=""><span>Регистрация</span></span>:</span>
										<span class="content">
											<!-- ngIf: signedUp == 0 -->
											<!-- ngIf: signedUp == 1 -->
											<span ng-if="signedUp > 1" translate="" data="days: 9"><span>9 дн. назад</span></span>
										</span>
									</div>
								</div>
								<div class="buttonContainer">
									<div class="chatButton">
										<button clickable="openChat(getPrivateRoomName(selectedData.playerId))" class="clickable">
											<div class="content">
												<span ng-transclude="" class="innerText">
													<span translate=""><span>Чат</span></span>
												</span>
												<!-- ngIf: price > 0 || forceGoldUsage -->
												<!-- ngIf: price == -1 && !forceGoldUsage -->
												<div class="animationOverlay"></div>
												<i class="checkmark action_check_small_flat_white"></i>
												<i class="checkmark action_check_small_flat_black"></i>
												<i class="checkmark action_check_small_flat_red"></i>
											</div>
										</button>
									</div>
									<div class="profileButton">
										<button clickable="openWindow('profile', {'playerId': 1804, 'profileTab': 'playerProfile'}, null, true)" class="clickable">
											<div class="content">
												<span ng-transclude="" class="innerText">
													<span translate=""><span>Профиль</span></span>
												</span>
												<!-- ngIf: price > 0 || forceGoldUsage -->
												<!-- ngIf: price == -1 && !forceGoldUsage -->
												<div class="animationOverlay"></div>
												<i class="checkmark action_check_small_flat_white"></i>
												<i class="checkmark action_check_small_flat_black"></i>
												<i class="checkmark action_check_small_flat_red"></i>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<table class="gradient pagination page_1">
						<thead>
							<tr>
								<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
								<th content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></th>
								<th content="Очки защиты" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.DefenderPoints"><i class="movement_defend_small_flat_black"></i></th>
							</tr>
						</thead>
						<tbody class="ranks">
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="924" player-name="BUNNY" rank="330" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank331" ng-if="rank >= 0">331</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="924" player-name="BUNNY" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="924" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="924" playername="BUNNY" nolink="false">BUNNY</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="861" player-name="ЛордЛекс" rank="331" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank332" ng-if="rank >= 0">332</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="861" player-name="ЛордЛекс" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="861" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="861" playername="ЛордЛекс" nolink="false">ЛордЛекс</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="798" player-name="manivrist" rank="332" points="1" kingdom-id="8" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank333" ng-if="rank >= 0">333</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="798" player-name="manivrist" chat-user="chatUser" kingdom-id="8" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="798" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="798" playername="manivrist" nolink="false">manivrist</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<!-- ngIf: kingdomId == 0 -->
										<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" ng-if="kingdomId > 0" kingdomid="8" kingdomname="фаршмелы" nolink="true">фаршмелы</a>
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="567" player-name="KausBonus" rank="333" points="1" kingdom-id="20" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank334" ng-if="rank >= 0">334</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="567" player-name="KausBonus" chat-user="chatUser" kingdom-id="20" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="567" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="567" playername="KausBonus" nolink="false">KausBonus</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<!-- ngIf: kingdomId == 0 -->
										<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" ng-if="kingdomId > 0" kingdomid="20" kingdomname="☠Outcast" nolink="true">☠Outcast</a>
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="462" player-name="Sindbad" rank="334" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank335" ng-if="rank >= 0">335</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="462" player-name="Sindbad" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="462" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="462" playername="Sindbad" nolink="false">Sindbad</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="360" player-name="Krisz" rank="335" points="1" kingdom-id="6" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank336" ng-if="rank >= 0">336</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="360" player-name="Krisz" chat-user="chatUser" kingdom-id="6" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="360" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="360" playername="Krisz" nolink="false">Krisz</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<!-- ngIf: kingdomId == 0 -->
										<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" ng-if="kingdomId > 0" kingdomid="6" kingdomname="Utopia" nolink="true">Utopia</a>
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1908" player-name="ZENTURION" rank="336" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank337" ng-if="rank >= 0">337</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1908" player-name="ZENTURION" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1908" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1908" playername="ZENTURION" nolink="false">ZENTURION</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1899" player-name="zxzxz" rank="337" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank338" ng-if="rank >= 0">338</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1899" player-name="zxzxz" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1899" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1899" playername="zxzxz" nolink="false">zxzxz</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1891" player-name="Markella" rank="338" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo">
								<td class="rankColumn rank339" ng-if="rank >= 0">339</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1891" player-name="Markella" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1891" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1891" playername="Markella" nolink="false">Markella</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
							<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in rankings.Player[tab]" ng-class="{'highlighted': rank.playerId == player.data.playerId || rank.rank == searchedRank}" player-rank="" player-id="1804" player-name="lexinzector" rank="339" points="1" kingdom-id="0" hide-prestige="true" hover-in="showPlayerInfo" hover-out="hidePlayerInfo" class="highlighted">
								<td class="rankColumn rank340" ng-if="rank >= 0">340</td>
								<!-- ngIf: !rank && rank != 0 -->
								<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1804" player-name="lexinzector" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

									<div ng-if="kingdomId >= 0" class="kingdomTag">
										<span ng-if="kingdomId == 0">-</span>
										<!-- ngIf: kingdomId > 0 -->
									</div>
									<!-- ngIf: !hidePrestige -->
								</td>
								<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
								<td class="number pointsColumn" ng-if="playerId >= 100">1</td>

								<!-- ngIf: Player.isNPC(playerId) -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="tg-pagination" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
					</li>
					<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(2)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="">2</a>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(...)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">...</a>
					</li>
					<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(53)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="">53</a>
					</li>
					<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black"></i>
					</li>
					<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
							action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black"></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Дипломатический центр</span></span>
									<span class="level">
										(<span><span>Уровень {{gameModel.island.buildings.embassy.level}}</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>Посольство - место, где собираются дипломаты. Здесь ты можешь занять оазис, а также основать тайное сообщество или свое собственное королевство.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<div class="buildingStatus location27 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<nav class="tabulation maintab unselectable">
									<a class="tab clickable" :class="{active: tab == 'communities', inactive: tab != 'communities'}" @click="onToggleTab('communities')">
										<div class="content">
											<span>Сообщество</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'oases', inactive: tab != 'oases'}" @click="onToggleTab('oases')">
										<div class="content">
											<span>Оазисы</span>
										</div>
									</a>
								</nav>
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div class="loadedTab currentTab" v-if="tab == 'communities'">
													<communities />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'oases'">
													<oases />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay">
							
							<!-- просмотр приглашения на вступление в королевство -->
							<kingdom-invitation v-if="gameModel.buildings.embassy.kingdomInvitationShow" />
							
							<!-- покинуть королевство -->
							<abdicate-governor v-if="gameModel.buildings.embassy.kingdomLeaveShow" />
							
							<!-- основать новое королевство -->
							<create-kingdom v-if="gameModel.buildings.embassy.createKingdomShow" />
							
							<!-- основать новое тайное сообщество -->
							<create-secret-society v-if="gameModel.buildings.embassy.createSecretSocietyShow" />
							
							<!-- cписок приглашений в тайное сообщество -->
							<open-invitations v-if="gameModel.buildings.embassy.openInvitationsShow" />
							
							<!-- просмотр приглашения в тайное сообщество -->
							<secret-society-invitation v-if="gameModel.buildings.embassy.secretSocietyInvitationShow" />
							
							<!-- распустить тайное сообщество (если ты владелец) -->
							<secret-society-dissolve v-if="gameModel.buildings.embassy.secretSocietyDissolveShow" />
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import Communities from '@/components/pages/buildings/embassy/Communities';
import Oases from '@/components/pages/buildings/embassy/Oases';

import KingdomInvitation from '@/components/pages/buildings/embassy/windowOverlay/KingdomInvitation';
import AbdicateGovernor from '@/components/pages/buildings/embassy/windowOverlay/AbdicateGovernor';
import CreateKingdom from '@/components/pages/buildings/embassy/windowOverlay/CreateKingdom';
import CreateSecretSociety from '@/components/pages/buildings/embassy/windowOverlay/CreateSecretSociety';
import OpenInvitations from '@/components/pages/buildings/embassy/windowOverlay/OpenInvitations';
import SecretSocietyInvitation from '@/components/pages/buildings/embassy/windowOverlay/SecretSocietyInvitation';
import SecretSocietyDissolve from '@/components/pages/buildings/embassy/windowOverlay/SecretSocietyDissolve';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Дипломатический центр | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		tab: 'communities',
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onToggleTab(tab, push = true){
			this.tab = tab;
			if(push) this.$router.push({query: {tab}});
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_8.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 112 > 0 ? maxHeightContent - 112 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.tab) this.tab = this.$route.query.tab;
		else this.$router.push({query: {tab: this.tab}});
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(!to.query.tab) this.$router.push({query: {tab: 'inventory'}});
		else if(to.query.tab != this.tab) this.onToggleTab(to.query.tab, false);
		next();
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {
		Communities,
		Oases,
		
		KingdomInvitation,
		AbdicateGovernor,
		CreateKingdom,
		CreateSecretSociety,
		OpenInvitations,
		SecretSocietyInvitation,
		SecretSocietyDissolve,
	},
}
</script>
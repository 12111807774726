import axios from 'axios';
import lib from '@/lib';

import AreaModel from '@/models/game/buildings/AreaModel';
import EmbassyModel from '@/models/game/buildings/EmbassyModel';
import HouseModel from '@/models/game/buildings/HouseModel';

export default class BuildingsModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.area = new AreaModel();
		this.embassy = new EmbassyModel();
		this.house = new HouseModel();
		
	}
}

import axios from 'axios';
import lib from '@/lib';

export default class CardGameModel
{
	static STATE_INITIAL = 1; //свое значение
	static STATE_START_PLAY = 2; //свое значение
	static STATE_SELECT_CARD = 3; //свое значение
	static STATE_PLAY_4_OF_5_SELECT_CARDS = 4; //свое значение
	static STATE_PLAY_4_OF_5_GAIN_CARDS = 5; //свое значение
	static STATE_YOUR_WON_CARD = 6; //свое значение
	static STATE_DISCARDED_CARDS = 7; //свое значение
	static STATE_DISCARDED_CARDS_REMOVE = 8; //свое значение
	 
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.rewards = [
			{
				typeId: 1,
				amount: 1,
				name: 'Дополнительное приключение',
				description: 'Добавляет одно очко приключения. Можно использовать только раз в день.',
				icon: 'prize_adventurePoint',
			},
			{
				typeId: 2,
				amount: 1,
				name: 'Строительная площадка',
				description: 'Открывает дополнительную строительную площадку (максимум 2) в активной деревне',
				icon: 'prize_buildingArea',
			},
			{
				typeId: 3,
				amount: 1,
				name: 'Малый сундук ресурсов',
				description: 'Мгновенно добавляет 3% дневного производства ресурсов в открытую деревню.',
				icon: 'prize_resourceBonusBronze',
			},
			{
				typeId: 4,
				amount: 1,
				name: 'Средний сундук ресурсов',
				description: 'Мгновенно добавляет 4% дневного производства ресурсов в открытую деревню.',
				icon: 'prize_resourceBonusSilver',
			},
			{
				typeId: 5,
				amount: 1,
				name: 'Большой сундук ресурсов',
				description: 'Мгновенно добавляет 5% дневного производства ресурсов в открытую деревню.',
				icon: 'prize_resourceBonusGold',
			},
			{
				typeId: 6,
				amount: 1,
				name: 'Малый сундук с зерном',
				description: 'Мгновенно добавляет 4% дневного производства зерна в открытую деревню.',
				icon: 'prize_cropBonusBronze',
			},
			{
				typeId: 7,
				amount: 1,
				name: 'Средний сундук с зерном',
				description: 'Мгновенно добавляет 4% дневного производства зерна в открытую деревню.',
				icon: 'prize_cropBonusSilver',
			},
			{
				typeId: 8,
				amount: 1,
				name: 'Большой сундук с зерном',
				description: 'Мгновенно добавляет 5% дневного производства зерна в открытую деревню.',
				icon: 'prize_cropBonusGold',
			},
			{
				typeId: 9,
				amount: 2,
				name: 'Завершить сейчас',
				description: 'С помощью этого предмета ты можешь использовать функцию "Завершить сейчас" без золота.',
				icon: 'prize_finishNow',
			},
			{
				typeId: 10,
				amount: 2,
				name: 'Бесплатные игры',
				description: 'Ты можешь два раза сыграть в карточную игру бесплатно.',
				icon: 'prize_freePlay',
			},
			{
				typeId: 11,
				amount: 12,//8,12
				name: 'Золотой сундук',
				description: 'Немедленно добавляет 12 золота на счет.',
				icon: 'prize_gold',
			},
			{
				typeId: 12,
				amount: 910, //1106, 1116, 976
				name: 'Серебряный сундук',
				description: 'Немедленно добавляет 910 серебра на счет.',
				icon: 'prize_silver',
			},
			{
				typeId: 13,
				amount: 18,
				name: 'Лечебное зелье',
				description: 'Добавляет в инвентарь 18 шт. лечебных зелий. Используй их для восстановления погибших войск или улучшения повязок.',
				icon: 'prize_healingPotion',
			},
			{
				typeId: 14,
				amount: 2,
				name: 'NPC-торговец',
				description: 'С помощью этого предмета ты можешь использовать функцию "NPC-торговец" без золота.',
				icon: 'prize_npcTrader',
			},
			{
				typeId: 15,
				amount: 3,
				name: 'Моментальная доставка',
				description: 'С помощью этого предмета ты можешь использовать функцию "Моментальная доставка" без золота.',
				icon: 'prize_traderArriveInstantly',
			},
			{
				typeId: 16,
				amount: 1,
				name: 'Улучшение предмета левой руки',
				description: 'Используй это, чтобы улучшить предмет левой руки. Действие улучшения зависит от типа предмета.',
				icon: 'upgrade_accessory',
			},
			{
				typeId: 17,
				amount: 1,
				name: 'Улучшение доспехов',
				description: 'Используй это, чтобы улучшить доспехи и увеличить их силы.',
				icon: 'upgrade_armor',
			},
			{
				typeId: 18,
				amount: 1,
				name: 'Улучшение шлема',
				description: 'Используй это, чтобы улучшить шлем. Действие улучшения зависит от типа шлема.',
				icon: 'upgrade_helmet',
			},
			{
				typeId: 19,
				amount: 1,
				name: 'Улучшение обуви',
				description: 'Используй это, чтобы улучшить обувь. Действие улучшения зависит от типа обуви.',
				icon: 'upgrade_shoes',
			},
			{
				typeId: 20,
				amount: 1,
				name: 'Улучшение оружия',
				description: 'Используй это, чтобы улучшить оружие и увеличить его бонус силы.',
				icon: 'upgrade_weapon',
			},
		];
	}
}

<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/island')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Ферма</span></span>
									<span class="level">
										(<span><span>Уровень {{gameModel.island.buildings.farm.level}}</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>На фермах выращивают зерно для обеспечения продовольствием населения и войска. Повысив уровни ферм, ты увеличишь производство зерна.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<div class="buildingStatus location27 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="buildingIndexCtrl" class="building buildingType4 buildingResourceProduction" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
														<div ng-include="" src="pageTemplate">
															<div class="buildingDetails" ng-controller="resourceBuildingCtrl">
																<div ng-include="" src="'tpl/building/partials/buildingInformation.html'">
																	<div ng-include="" src="'tpl/building/partials/buildingInformationEffects.html'">
																		<div class="contentBox gradient buildingEffect" ng-if="currentEffect.values[0] != undefined
																				&& (building.data.lvl > 0
																				|| building.data.buildingType <= 4
																				|| building.data.buildingType == 42)">
																			<h6 class="contentBoxHeader headerColored">
																				<span translate="" options="4" data="lvl: , value: "><span>Производство</span></span>
																			</h6>
																			<div class="contentBoxBody">
																				<div class="current">
																					<h6 class="headerTrapezoidal">
																						<div class="content" translate=""><span>Сейчас</span></div>
																					</h6>
																					<div class="currentLevel" data="lvl: 1" translate=""><span>Уровень 1</span></div>
																					<div class="value">5</div>
																					<div class="unit" options="4" translate=""><span>в час</span></div>
																				</div>
																				<div class="nextLvl">
																					<h6 class="headerTrapezoidal">
																						<div class="content" translate=""><span>Следующие уровни</span></div>
																					</h6>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>2</th>
																								<td>
																									<span translate="" options="4" data="value:9"><span>9 в час</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>3</th>
																								<td>
																									<span translate="" options="4" data="value:15"><span>15 в час</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>4</th>
																								<td>
																									<span translate="" options="4" data="value:22"><span>22 в час</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>5</th>
																								<td>
																									<span translate="" options="4" data="value:33"><span>33 в час</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>6</th>
																								<td>
																									<span translate="" options="4" data="value:50"><span>50 в час</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
																						<tbody>
																							<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
																								<th>7</th>
																								<td>
																									<span translate="" options="4" data="value:70"><span>70 в час</span></span>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					<!-- ngIf: building.data.isMaxLvl && building.data.lvl == building.data.lvlMax -->
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Ферма | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {},
}
</script>
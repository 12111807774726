<template>
	<div class="productionContainer balance">
	<div class="contentBox">
		<h6 class="contentBoxHeader headerWithIcon arrowDirectionTo">
			<i class="unit_crop_medium_illu"></i>
			<span translate=""><span>Баланс зерна</span></span>
		</h6>
		<div class="contentBoxBody">
			<div class="contentBox">
				<div class="contentBoxBody">
					<span translate=""><span>Здание и производство оазиса</span></span>
					<span class="contentBoxValue">&#x202D;&#x202D;30&#x202C;&#x202C;</span>
				</div>
				<div class="contentBoxBody">
					<span translate=""><span>Производство героя</span></span>
					<span class="contentBoxValue">&#x202D;&#x202D;20&#x202C;&#x202C;</span>
				</div>
				<div class="contentBoxBody">
					<span translate=""><span>Производство войск в оазисе</span></span>
					<span class="contentBoxValue">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
				</div>
				<div class="contentBoxBody">
					<span translate=""><span>Производство сокровищ</span></span>
					<span class="contentBoxValue">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
				</div>
				<div class="contentBoxBody">
					<span translate="" data="percentage:&#x202D;+&#x202D;25&#x202C;%&#x202C;"><span>&#x202D;+&#x202D;25&#x202C;%&#x202C; производства</span></span>
					<span class="contentBoxValue">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
				</div>
				<div class="arrowContainer arrowDirectionTo">
					<span class="arrowInside" translate=""><span>Промежуточный баланс =</span></span>
					<span class="arrowOutside">&#x202D;&#x202D;50&#x202C;&#x202C;</span>
				</div>
				<div class="contentBoxBody">
					<span translate=""><span>Население и строительство</span></span>
					<span class="contentBoxValue">&#x202D;-&#x202D;57&#x202C;&#x202C;</span>
				</div>
				<div class="subContentBox">
					<div class="expandableHead clickable" clickable="toggle('showOwnSummary')">
						<div class="icon">
							<i ng-class="showOwnSummary ? 'symbol_minus_tiny_flat_white' : 'symbol_plus_tiny_flat_white'" class="symbol_plus_tiny_flat_white"></i>
						</div>
						<div ng-class="{'item':showOwnSummary}">
							<span translate=""><span>Потребление собственных войск</span></span>
							<span class="contentBoxValue" ng-show="!showOwnSummary">&#x202D;-&#x202D;9&#x202C;&#x202C;</span>
						</div>
					</div>
					<div ng-show="showOwnSummary" class="ng-hide">
						<div class="expandableItem">
							<span translate=""><span>- в деревне</span></span>
							<span class="contentBoxValue">&#x202D;-&#x202D;9&#x202C;&#x202C;</span>
						</div>
						<div class="expandableItem">
							<span translate=""><span> - в оазисе</span></span>
							<span class="contentBoxValue">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
						</div>
						<div class="expandableItem">
							<span translate=""><span> - в пути</span></span>
							<span class="contentBoxValue">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
						</div>
						<div class="expandableItem">
							<span translate=""><span> - в ловушках</span></span>
							<span class="contentBoxValue">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
						</div>
						<div class="expandableItem">
							<span translate=""><span> - исцеление</span></span>
							<span class="contentBoxValue">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
						</div>
						<div class="expandableItem">
							<span translate=""><span> - Целебный шатер</span></span>
							<span class="contentBoxValue">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
						</div>
					</div>
				</div>
				<div class="subContentBox">
					<div class="expandableHead clickable" clickable="toggle('showOtherSummary')">
						<div class="icon">
							<i ng-class="showOtherSummary ? 'symbol_minus_tiny_flat_white' : 'symbol_plus_tiny_flat_white'" class="symbol_plus_tiny_flat_white"></i>
						</div>
						<div ng-class="{'item':showOtherSummary}">
							<span translate=""><span>Потребление чужих войск</span></span>
							<span class="contentBoxValue" ng-show="!showOtherSummary">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
						</div>
					</div>
					<div ng-show="showOtherSummary" class="ng-hide">
						<div class="expandableItem">
							<span translate=""><span>- в деревне</span></span>
							<span class="contentBoxValue">&#x202D;&#x202D;0&#x202C;&#x202C;</span>
						</div>
					</div>
				</div>
				<div class="arrowContainer arrowDirectionTo total" ng-class="{negative : interimBalanceForTroops+troops.own.sum+troops.other.sum < 0}">
					<span class="arrowInside" translate=""><span>Баланс зерна =</span></span>
					<span class="arrowOutside">&#x202D;-&#x202D;16&#x202C;&#x202C;</span>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
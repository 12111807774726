<template>
	<div class="reportHeader prestigeReportHeader" src="'tpl/report/headerPrestige.html'">
		<div class="headerBox">
			<div class="reportHeaderBg reportType23 success1"></div>
			<div class="playerBox blockLeft">
				<div class="playerLabel">
					<span class="prestigeLabel" translate=""><span>Престиж (ранг)</span></span>
					<div class="prestigeStarsContainer innerStars" :stars="report.stars.stars" size="small">
						<div ng-if="!badge">
							<i class="prestigeStar prestige_starSilver_small_illu"></i>
							<i class="prestigeStar prestige_starSilver_small_illu"></i>
							<i class="prestigeStar prestige_starBronze_small_illu"></i>
						</div>
						<i ng-if="badge" class="prestigeBadge prestige_silverBadge_tiny_illu"></i>
					</div>
				</div>
			</div>
			<div class="prestigeCup report_cup_big_illu">
				<div class="points">+{{report.currentWeekPrestige}}</div>
			</div>
			<!-- ngIf: resultIcon -->
			<div class="playerBox blockRight">
				<div class="playerLabel">
					<div class="prestigeLabel" translate=""><span>Всего</span></div>
					<i class="feature_prestige_small_flat_black"></i>
					<span>{{report.globalPrestige}}</span>
				</div>
			</div>
		</div>
		<h6 class="functionHeader headerTrapezoidal">
			<div class="reportDate content">
				<span :i18ndt="report.weekStartDate" format="shortDate">{{formatUnixDate(report.weekStartDate, 'DD.MM.YY')}}</span> -
				<span :i18ndt="report.weekEndDate" format="shortDate">{{formatUnixDate(report.weekEndDate, 'DD.MM.YY')}}</span>
			</div>
			<div class="controlPanel">
				<div class="iconButton prestigeButton clickable" @click="onOpenProfile(report.sourcePlayerId, 'prestige')" clickable="openWindow('profile', {'playerId': report.sourcePlayerId, 'profileTab': 'prestige'})">
					<i class="feature_prestige_small_flat_black"></i>
				</div>
			</div>
		</h6>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		report: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
		
		onOpenProfile(playerId, tab){
			
		},
	},
	components: {},
}
</script>
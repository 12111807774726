<template>
	<div class="contentBox audioSettings">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content" translate=""><span>Аудио</span></div>
		</div>
		<div class="contentBoxBody unselectable">
			<table>
				<tbody>
					<tr class="settingsRow">
						<td class="label" translate=""><span>Отключить все звуки</span></td>
						<td class="setting">
							<switch-element class="switch" name-true="Да" name-false="Нет" v-model="mute.all"/>
						</td>
					</tr>
					<tr class="settingsRow">
						<td class="label" translate=""><span>Громкость музыки</span></td>
						<td class="setting">
							<slider slider-min="0" slider-max="100" slider-data="music" slider-changed="onSoundChange" slider-show-max-button="false" slider-lock="mute.all" ng-class="{disabled: mute.all}">
								<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
									<div class="trackContainer">
										<div class="track">
											<!-- ngIf: stepCount && !hideSteps -->
											<div class="sliderMarker progressMarker" style="width: 0%;"></div>
											<!-- ngRepeat: marker in sliderMarkers track by $index -->
											<div class="hoverIndicator">
												<div class="hoverValue"></div>
												<div class="indicator"></div>
											</div>
											<div class="handle unselectable" style="left: 0px;"></div>
										</div>
									</div>
									<div class="inputContainer">
										<!-- ngIf: iconClass -->
										<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
										<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
											<i class="action_setMax_small_flat_black"></i>
										</div>
									</div>
								</div>
							</slider>
						</td>
					</tr>
					<tr class="settingsRow">
						<td class="label" translate=""><span>Громкость звуковых эффектов</span></td>
						<td class="setting">
							<slider slider-min="0" slider-max="100" slider-data="sound" slider-changed="onSoundChange" slider-show-max-button="false" slider-lock="mute.all" ng-class="{disabled: mute.all}">
								<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
									<div class="trackContainer">
										<div class="track">
											<!-- ngIf: stepCount && !hideSteps -->
											<div class="sliderMarker progressMarker" style="width: 0%;"></div>
											<!-- ngRepeat: marker in sliderMarkers track by $index -->
											<div class="hoverIndicator">
												<div class="hoverValue"></div>
												<div class="indicator"></div>
											</div>
											<div class="handle unselectable" style="left: 0px;"></div>
										</div>
									</div>
									<div class="inputContainer">
										<!-- ngIf: iconClass -->
										<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
										<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
											<i class="action_setMax_small_flat_black"></i>
										</div>
									</div>
								</div>
							</slider>
						</td>
					</tr>
					<tr class="settingsRow">
						<td class="label" translate=""><span>Громкость звуков интерфейса</span></td>
						<td class="setting">
							<slider slider-min="0" slider-max="100" slider-data="uiSound" slider-changed="onSoundChange" slider-show-max-button="false" slider-lock="mute.all" ng-class="{disabled: mute.all}">
								<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
									<div class="trackContainer">
										<div class="track">
											<!-- ngIf: stepCount && !hideSteps -->
											<div class="sliderMarker progressMarker" style="width: 50%;"></div>
											<!-- ngRepeat: marker in sliderMarkers track by $index -->
											<div class="hoverIndicator">
												<div class="hoverValue"></div>
												<div class="indicator"></div>
											</div>
											<div class="handle unselectable" style="left: 100px;"></div>
										</div>
									</div>
									<div class="inputContainer">
										<!-- ngIf: iconClass -->
										<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
										<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
											<i class="action_setMax_small_flat_black"></i>
										</div>
									</div>
								</div>
							</slider>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="contentBox audioSettings">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content" translate=""><span>Графика</span></div>
		</div>
		<div class="contentBoxBody">
			<table>
				<tbody>
					<tr class="settingsRow" tooltip="" tooltip-translate="Settings.SettingTooltip_disableAnimations">
						<td class="label" translate=""><span>Уменьшить анимацию</span></td>
						<td class="setting">
							<switch-element class="switch" name-true="Да" name-false="Нет" v-model="checkboxSettings.disableAnimations.value"/>
						</td>
					</tr>
					<!-- ngIf: ::config.balancing.season -->
					<!-- ngIf: ::config.balancing.season == 'winter' -->
				</tbody>
			</table>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import SwitchElement from '@/components/elements/Switch';

export default {
	mixins: lib.mixins,
	data: () => ({
		mute: {
			all: false,
		},
		checkboxSettings: {
			disableAnimations: {
				value: false,
			},
		},
	}),
	computed: {},
	methods: {},
	components: {
		SwitchElement,
	},
}
</script>
<template>
	<div class="statisticsTable contentBox transparent">
		<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
			<div class="content" translate=""><span>Крупнейшие королевства (население)</span></div>
		</h6>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems[tab]" current-page="currentPage[tab]" display-page-func="displayCurrentPage" route-named-param="statsPage">
			<div ng-transclude="">
				<table class="gradient smallRows pagination page_1">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Король" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.King"><i class="community_member_small_flat_black"></i></th>
							<th content="Королевство" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Kingdom"><i class="community_kingdom_small_flat_black"></i></th>
							<th content="Всего деревень" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.TotalVillages"><i class="village_village_small_flat_black"></i></th>
							<th content="Общее население" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.TotalPopulation"><i class="unit_population_small_flat_black"></i></th>
						</tr>
					</thead>
					<tbody class="ranks">
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>1</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="163">ингибитор</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" kingdomid="8">фаршмелы</a>
							</td>
							<td class="number">127</td>
							<td class="number">55&nbsp;878</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>2</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="106">Брего</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2">♔Выдры♔</a>
							</td>
							<td class="number">81</td>
							<td class="number">49&nbsp;513</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>3</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="155">Nol1k</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" kingdomid="6">Utopia</a>
							</td>
							<td class="number">67</td>
							<td class="number">33&nbsp;624</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>4</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="127">Gaius Ulius</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3">Roman</a>
							</td>
							<td class="number">66</td>
							<td class="number">31&nbsp;184</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>5</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="766">RuIII_Spirit</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 32}, null, true)" kingdomid="32">Квадрат</a>
							</td>
							<td class="number">53</td>
							<td class="number">18&nbsp;162</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>6</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1062">Шальная</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 44}, null, true)" kingdomid="44">Вольные</a>
							</td>
							<td class="number">15</td>
							<td class="number">8&nbsp;138</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>7</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="475">Kalika</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 29}, null, true)" kingdomid="29">StarLink</a>
							</td>
							<td class="number">12</td>
							<td class="number">5&nbsp;070</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>8</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="577">Rytux</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">☠Outcast</a>
							</td>
							<td class="number">11</td>
							<td class="number">4&nbsp;220</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>9</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="264">Rafail</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 16}, null, true)" kingdomid="16">Raf</a>
							</td>
							<td class="number">7</td>
							<td class="number">3&nbsp;068</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>10</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1403">Минус</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 57}, null, true)" kingdomid="57">♔Выдры*♔</a>
							</td>
							<td class="number">11</td>
							<td class="number">2&nbsp;279</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>11</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1073">Ганс</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 41}, null, true)" kingdomid="41">- яйца -</a>
							</td>
							<td class="number">4</td>
							<td class="number">793</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>12</td>
							<td class="nameColumn">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1733">login</a>
							</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 54}, null, true)" kingdomid="54">login</a>
							</td>
							<td class="number">1</td>
							<td class="number">121</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="tg-pagination ng-hide" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
					</li>
					<li class="nextPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black disabled"></i>
					</li>
					<li class="lastPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
							action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black disabled"></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
import axios from 'axios';
import lib from '@/lib';

export default class InventoryModel
{
	static SLOT_NOTHING = 0;
	static SLOT_HELMET = 1;
	static SLOT_RIGHT_HAND = 2;
	static SLOT_LEFT_HAND = 3;
	static SLOT_BODY = 4;
	static SLOT_SHOES = 5;
	static SLOT_HORSE = 6;
	static SLOT_BAG = 7;
	
	static TYPE_HELMET_0_0 = 1; //свой
	static TYPE_HELMET_0_1 = 2; //свой
	static TYPE_HELMET_0_2 = 3; //свой
	static TYPE_HELMET_1_0 = 4;
	static TYPE_HELMET_1_1 = 5; //свой
	static TYPE_HELMET_1_2 = 6; //свой
	static TYPE_HELMET_2_0 = 7; //свой
	static TYPE_HELMET_2_1 = 8; //свой
	static TYPE_HELMET_2_2 = 9; //свой
	static TYPE_HELMET_3_0 = 10;
	static TYPE_HELMET_3_1 = 11; //свой
	static TYPE_HELMET_3_2 = 12; //свой
	static TYPE_HELMET_4_0 = 13;
	static TYPE_HELMET_4_1 = 14; //свой
	static TYPE_HELMET_4_2 = 15; //свой
	
	static TYPE_SWORD_0_0 = null;
	static TYPE_SWORD_0_1 = null;
	static TYPE_SWORD_0_2 = null;
	static TYPE_SWORD_1_0 = null;
	static TYPE_SWORD_1_1 = null;
	static TYPE_SWORD_1_2 = null;
	static TYPE_SWORD_2_0 = null;
	static TYPE_SWORD_2_1 = null;
	static TYPE_SWORD_2_2 = null;
	static TYPE_SWORD_3_0 = null;
	static TYPE_SWORD_3_1 = null;
	static TYPE_SWORD_3_2 = null;
	
	static TYPE_SPEAR_0_0 = 31;
	static TYPE_SPEAR_0_1 = 32; //свой
	static TYPE_SPEAR_0_2 = 33; //свой
	static TYPE_SWORD_4_0 = 34; //свой
	static TYPE_SWORD_4_1 = 35;
	static TYPE_SWORD_4_2 = 36; //свой
	static TYPE_BOW_0_0 = 37;
	static TYPE_BOW_0_1 = 38; //свой
	static TYPE_BOW_0_2 = 39; //свой
	
	static TYPE_SPEAR_1_0 = 43;
	static TYPE_SPEAR_1_1 = 44; //свой
	static TYPE_SPEAR_1_2 = 45; //свой
	static TYPE_SPEAR_2_0 = 46; //свой
	static TYPE_SPEAR_2_1 = 47; //свой
	static TYPE_SPEAR_2_2 = 48; //свой
	
	static TYPE_SWORD_5_0 = null;
	static TYPE_SWORD_5_1 = null;
	static TYPE_SWORD_5_2 = null;
	
	static TYPE_STAFF_0_0 = null;
	static TYPE_STAFF_0_1 = null;
	static TYPE_STAFF_0_2 = null;
	
	static TYPE_SHIELD_0_0 = null;
	static TYPE_SHIELD_0_1 = null;
	static TYPE_SHIELD_0_2 = null;
	
	static TYPE_SACK_0_0 = null;
	static TYPE_SACK_0_1 = null;
	static TYPE_SACK_0_2 = null;
	
	static TYPE_MAP_0_0 = 61;
	static TYPE_MAP_0_1 = 62; //свой
	static TYPE_MAP_0_2 = 63; //свой
	
	static TYPE_SHIRT_0_0 = 82;
	static TYPE_SHIRT_0_1 = 83; //свой
	static TYPE_SHIRT_0_2 = 84; //свой
	static TYPE_SHIRT_1_0 = 85; //свой
	static TYPE_SHIRT_1_1 = 86; //свой
	static TYPE_SHIRT_1_2 = 87; //свой
	static TYPE_SHIRT_2_0 = 88; //свой
	static TYPE_SHIRT_2_1 = 89; //свой
	static TYPE_SHIRT_2_2 = 90; //свой
	static TYPE_SHIRT_3_0 = 91; //свой
	static TYPE_SHIRT_3_1 = 92; //свой
	static TYPE_SHIRT_3_2 = 93; //свой
	static TYPE_SHOES_0_0 = 94;
	static TYPE_SHOES_0_1 = 95; //свой
	static TYPE_SHOES_0_2 = 96; //свой
	static TYPE_SHOES_1_0 = 97; //свой
	static TYPE_SHOES_1_1 = 98; //свой
	static TYPE_SHOES_1_2 = 99; //свой
	static TYPE_SHOES_2_0 = 100; //свой
	static TYPE_SHOES_2_1 = 101; //свой
	static TYPE_SHOES_2_2 = 102; //свой
	static TYPE_HORSE_1_0 = 103;
	static TYPE_HORSE_1_1 = 104; //свой
	static TYPE_HORSE_1_2 = 105; //свой
	static TYPE_HORSE_2_0 = 106; //свой
	static TYPE_HORSE_2_1 = 107; //свой
	static TYPE_HORSE_2_2 = 108; //свой
	static TYPE_HORSE_0_0 = 109; //свой
	static TYPE_HORSE_0_1 = 110; //свой
	static TYPE_HORSE_0_2 = 111; //свой
	static TYPE_OINTMENT = 112;
	static TYPE_SCROLL = 113;
	
	static TYPE_BOOK = 115;
	static TYPE_ARTWORK = 116;
	static TYPE_SMALL_BANDAGE = 117;
	static TYPE_BANDAGE = null;
	
	static TYPE_TREASURES = 120;
	
	static TYPE_SHOES_3_0 = 121;
	static TYPE_SHOES_3_1 = 122; //свой
	static TYPE_SHOES_3_2 = 123; //свой
	static TYPE_HEALING_POTION = 124;
	
	static TYPE_UPGRADE_HELMET = null;
	static TYPE_UPGRADE_SHOES = null;
	static TYPE_UPGRADE_WEAPON = 126;
	static TYPE_UPGRADE_ACCESSORY = null;
	static TYPE_UPGRADE_ARMOR = null;
	static TYPE_WATER_BUCKET = null;
	
	static TYPE_RESOURCE_BONUS_3 = 130;
	static TYPE_RESOURCE_BONUS_4 = 131;
	static TYPE_RESOURCE_BONUS_5 = 132;
	static TYPE_CROP_BONUS_3 = 133;
	static TYPE_CROP_BONUS_4 = 134;
	static TYPE_CROP_BONUS_5 = 135;
	static TYPE_ADVENTURE_POINT = 136;
	
	static TYPE_FINISH_IMMEDIATELY = 138;
	static TYPE_NPC_TRADER = 139;
	static TYPE_INSTANT_DELIVERY = 140;
	
	static TYPE_FIREWORKS = null;
	static TYPE_SUPER_FIREWORKS = null;
	static TYPE_BANDAGE_25 = null;
	static TYPE_BANDAGE_25_UPGRADED = null;
	static TYPE_BANDAGE_33 = null;
	static TYPE_BANDAGE_33_UPGRADED = null;
	static TYPE_CAGES = null;
	
	static TYPE_RESOURCES_WOOD_S = null;
	static TYPE_RESOURCES_WOOD_M = null;
	static TYPE_RESOURCES_WOOD_L = null;
	static TYPE_RESOURCES_IRON_S = null;
	static TYPE_RESOURCES_IRON_M = null;
	static TYPE_RESOURCES_IRON_L = null;
	static TYPE_RESOURCES_CROP_S = null;
	static TYPE_RESOURCES_CROP_M = null;
	static TYPE_RESOURCES_CROP_L = null;
	static TYPE_RESOURCES_CLAY_S = null;
	static TYPE_RESOURCES_CLAY_M = null;
	static TYPE_RESOURCES_CLAY_L = null;
	static TYPE_LANCE_0_0 = null;
	static TYPE_LANCE_0_1 = null;
	static TYPE_LANCE_0_2 = null;
	static TYPE_HORN_0_0 = null;
	static TYPE_HORN_0_1 = null;
	static TYPE_HORN_0_2 = null;
	static TYPE_HAMMER_0_0 = null;
	static TYPE_HAMMER_0_1 = null;
	static TYPE_HAMMER_0_2 = null;
	static TYPE_FLAG_0_0 = null;
	static TYPE_FLAG_0_1 = null;
	static TYPE_FLAG_0_2 = null;
	static TYPE_FLAG_1_0 = null;
	static TYPE_FLAG_1_1 = null;
	static TYPE_FLAG_1_2 = null;
	static TYPE_CLUB_0_0 = null;
	static TYPE_CLUB_0_1 = null;
	static TYPE_CLUB_0_2 = null;
	static TYPE_AXE_0_0 = null;
	static TYPE_AXE_0_1 = null;
	static TYPE_AXE_0_2 = null;
	
	static TYPE_BUILDING_GROUND = null;
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.sellStolenGoodsShow = false;
		this.useResourceBonusCropShow = false;
		this.useResourceBonusCropConfirmShow = false;
		this.useResourceBonusResShow = false;
		this.useResourceBonusResConfirmShow = false;
		this.upgradeBandagesShow = false;
		this.useItemHealingPotionShow = false;
		this.useItemScrollShow = false;
		this.useItemTreasureShow = false;
		this.heroEditorShow = false;
		
		this.items = [
			{
				id: 12205,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'upgrade_armor',
				icon: 'upgrade_armor',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'upgrade_armor',
				],
			},
			{
				id: 12206,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'upgrade_accessory',
				icon: 'upgrade_accessory',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'upgrade_accessory',
				],
			},
			{
				id: 12207,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'upgrade_shoes',
				icon: 'upgrade_shoes',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'upgrade_shoes',
				],
			},
			{
				id: 12208,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'upgrade_helmet',
				icon: 'upgrade_helmet',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'upgrade_helmet',
				],
			},
			{
				id: 12209,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'upgrade_weapon',
				icon: 'upgrade_weapon',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'upgrade_weapon',
				],
			},
			{
				id: 12210,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'axe0_2',
				icon: 'axe0_2',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'axe0_2',
				],
			},
			{
				id: 12211,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_BAG,
				name: 'bandage',
				icon: 'bandage',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'bandage',
				],
			},
			{
				id: 12212,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'building_ground',
				icon: 'building_ground',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'building_ground',
				],
			},
			{
				id: 12213,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, //unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_BAG,
				name: 'Клетка',
				icon: 'cage',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'cage',
				],
			},
			{
				id: 12214,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'club0_2',
				icon: 'club0_2',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'club0_2',
				],
			},
			{
				id: 12215,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'fireworks',
				icon: 'fireworks',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'fireworks',
				],
			},
			{
				id: 12216,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_LEFT_HAND,
				name: 'flag1_2',
				icon: 'flag1_2',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'flag1_2',
				],
			},
			{
				id: 12217,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'hammer0_2',
				icon: 'hammer0_2',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'hammer0_2',
				],
			},
			{
				id: 12218,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_LEFT_HAND,
				name: 'horn0_2',
				icon: 'horn0_2',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'horn0_2',
				],
			},
			{
				id: 12219,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'lance0_2',
				icon: 'lance0_2',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'lance0_2',
				],
			},
			{
				id: 12220,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'resourcesWoodS',
				icon: 'resourcesWoodS',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'resourcesWoodS',
				],
			},
			{
				id: 12221,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_LEFT_HAND,
				name: 'shield0_0',
				icon: 'shield0_0',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'shield0_0',
				],
			},
			{
				id: 12222,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_LEFT_HAND,
				name: 'sack0_0',
				icon: 'sack0_0',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'sack0_0',
				],
			},
			{
				id: 12223,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'staff0_0',
				icon: 'staff0_0',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'staff0_0',
				],
			},
			{
				id: 12224,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 0, // unknown
				upgradedItem: false,
				disabled: true,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Ведро с водой',
				icon: 'water_bucket',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'water_bucket',
				],
			},
			{
				id: 12225,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 113,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'scroll',
				icon: 'scroll',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'scroll',
				],
			},
			{
				id: 12226,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 112,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Мазь',
				icon: 'ointment',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'ointment',
				],
			},
			{
				id: 12227,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 124,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_BAG,
				name: 'Лечебное зелье',
				icon: 'healing_potion',
				tooltip: `<div class="itemDescription" item-description="57170" auction-item="false" hide-info="false" item-type="124">
	<div translate="" options="124" class="heroItemName"><span>Лечебное зелье</span></div>
	<div ng-hide="instantUse &amp;&amp; !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse &amp;&amp; (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) &amp;&amp; itemType != heroItem.TYPE_ARTWORK
	 		&amp;&amp; itemType != heroItem.TYPE_FIREWORKS &amp;&amp; itemType != heroItem.TYPE_SUPER_FIREWORKS &amp;&amp; !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="124">!!Hero.ItemBonusInstantUse_124!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="124" data="x:">!!Hero.ItemBonusInstantUse_124!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до &#x202D;&#x202D;25&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до &#x202D;&#x202D;33&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до &#x202D;&#x202D;30&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до &#x202D;&#x202D;38&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION">
		<hr>
		<span translate=""><span>Улучшение повязок на (&#x202D;&#x202D;5&#x202C;%&#x202C;) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до &#x202D;&#x202D;5&#x202C;%&#x202C; от войска). Так что суммарно можно восстановить &#x202D;&#x202D;33&#x202C;%&#x202C;+&#x202D;&#x202D;5&#x202C;%&#x202C;=&#x202D;&#x202D;38&#x202C;%&#x202C;.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 &amp;&amp; itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max &amp;&amp; !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max &amp;&amp; perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 12,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'healing_potion',
				],
			},
			{
				id: 12228,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 138,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'finishImmediately',
				icon: 'finishImmediately',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'finishImmediately',
				],
			},
			{
				id: 12229,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 115,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'book',
				icon: 'book',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'bookOfWisdom',
					'book',
				],
			},
			{
				id: 12230,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 139,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'npcTrader',
				icon: 'npcTrader',
				tooltip: ``,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'npcTrader',
				],
			},
			{
				id: 12231,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 136,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'adventure_point',
				icon: 'adventure_point',
				tooltip: ``,
				amount: 15,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'adventure_point',
				],
			},
			{
				id: 12232,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 140,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: true,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Моментальная доставка',
				icon: 'instantDelivery',
				tooltip: `<div class="itemDescription" item-description="12232" auction-item="false" hide-info="false" item-type="140">
	<div translate="" options="140" class="heroItemName"><span>Моментальная доставка</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)">
		<div class="horizontalLine"></div>
		<span translate="" options="140"><span>С помощью каждого из этих предметов ты можешь бесплатно использовать опцию "Моментальная доставка".</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="140" data="x:"><span>С помощью каждого из этих предметов ты можешь бесплатно использовать опцию "Моментальная доставка".</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 15,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'instantDelivery',
				],
			},
			{
				id: 55361,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 113,
				upgradedItem: false,
				disabled: true,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Свиток',
				icon: 'scroll',
				tooltip: `<div class="itemDescription" item-description="55361" auction-item="false" hide-info="false" item-type="113">
	<div translate="" options="113" class="heroItemName"><span>Свиток</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="1" data="x:10"><span>+10% больше опыта для героя</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)">
		<div class="horizontalLine"></div>
		<span translate="" options="113"><span>Активируется сразу при использовании и увеличивает опыт вашего героя (10 единиц за один свиток).</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="113" data="x:"><span>Активируется сразу при использовании и увеличивает опыт вашего героя (10 единиц за один свиток).</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:10"><span>При использовании моментально добавляет 10% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:110,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}"><span>Сегодня ты использовал 0 из максимального числа 110.</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 112,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'scroll',
				],
			},
			{
				id: 47643,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 120,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Краденые ценности',
				icon: 'treasures',
				tooltip: `<div class="itemDescription" item-description="47643" auction-item="false" hide-info="false" item-type="120">
	<div translate="" options="120" class="heroItemName"><span>Краденые ценности</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)">
		<div class="horizontalLine"></div>
		<span translate="" options="120"><span>Ты можешь продать краденые ценности за ресурсы.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="120" data="x:"><span>Ты можешь продать краденые ценности за ресурсы.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 125,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'treasures',
				],
			},
			{
				id: 42334,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 135,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Сундук зерна 5%',
				icon: 'cropChest5',
				tooltip: `<div class="itemDescription" item-description="42334" auction-item="false" hide-info="false" item-type="135">
	<div translate="" options="135" class="heroItemName"><span>Сундук зерна 5%</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="25" data="x:5"><span>Используй этот предмет, чтобы немедленно добавить 5 зерна в свою деревню.</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="135">!!Hero.ItemBonusInstantUse_135!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="135" data="x:">!!Hero.ItemBonusInstantUse_135!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:5"><span>При использовании моментально добавляет 5% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:1,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}"><span>Сегодня ты использовал 0 из максимального числа 1.</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'cropChest5',
				],
			},
			{
				id: 53371,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 134,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Сундук зерна 4',
				icon: 'cropChest4',
				tooltip: `<div class="itemDescription" item-description="53371" auction-item="false" hide-info="false" item-type="134">
	<div translate="" options="134" class="heroItemName"><span>Сундук зерна 4%</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="25" data="x:4"><span>Используй этот предмет, чтобы немедленно добавить 4 зерна в свою деревню.</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="134">!!Hero.ItemBonusInstantUse_134!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="134" data="x:">!!Hero.ItemBonusInstantUse_134!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:4"><span>При использовании моментально добавляет 4% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:1,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}"><span>Сегодня ты использовал 0 из максимального числа 1.</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 2,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'cropChest4',
				],
			},
			{
				id: 54584,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 133,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Сундук зерна 3%',
				icon: 'cropChest3',
				tooltip: `<div class="itemDescription" item-description="54584" auction-item="false" hide-info="false" item-type="133">
	<div translate="" options="133" class="heroItemName"><span>Сундук зерна 3%</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="25" data="x:3"><span>Используй этот предмет, чтобы немедленно добавить 3 зерна в свою деревню.</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="133">!!Hero.ItemBonusInstantUse_133!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="133" data="x:">!!Hero.ItemBonusInstantUse_133!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:3"><span>При использовании моментально добавляет 3% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:1,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}"><span>Сегодня ты использовал 0 из максимального числа 1.</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 4,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'cropChest3',
				],
			},
			{
				id: 16720,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 132,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Сундук ресурсов 5%',
				icon: 'resourceChest5',
				tooltip: `<div class="itemDescription" item-description="16720" auction-item="false" hide-info="false" item-type="132">
	<div translate="" options="132" class="heroItemName"><span>Сундук ресурсов 5%</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="24" data="x:5">!!Hero.ItemBonus_24!!</span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="132">!!Hero.ItemBonusInstantUse_132!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="132" data="x:">!!Hero.ItemBonusInstantUse_132!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:5"><span>При использовании моментально добавляет 5% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:1,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}"><span>Сегодня ты использовал 0 из максимального числа 1.</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 7,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'resourceChest5',
				],
			},
			{
				id: 45119,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 131,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Сундук ресурсов 4%',
				icon: 'resourceChest4',
				tooltip: `<div class="itemDescription" item-description="45119" auction-item="false" hide-info="false" item-type="131">
	<div translate="" options="131" class="heroItemName"><span>Сундук ресурсов 4%</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="24" data="x:4">!!Hero.ItemBonus_24!!</span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="131">!!Hero.ItemBonusInstantUse_131!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="131" data="x:">!!Hero.ItemBonusInstantUse_131!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:4"><span>При использовании моментально добавляет 4% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:1,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}"><span>Сегодня ты использовал 0 из максимального числа 1.</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'resourceChest4',
				],
			},
			{
				id: 45120,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 131,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: true,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Сундук ресурсов 3%',
				icon: 'resourceChest3',
				tooltip: `<div class="itemDescription" item-description="45119" auction-item="false" hide-info="false" item-type="131">
	<div translate="" options="131" class="heroItemName"><span>Сундук ресурсов 3%</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="24" data="x:4">!!Hero.ItemBonus_24!!</span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="131">!!Hero.ItemBonusInstantUse_131!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="131" data="x:">!!Hero.ItemBonusInstantUse_131!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:4"><span>При использовании моментально добавляет 4% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:1,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}"><span>Сегодня ты использовал 0 из максимального числа 1.</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				count: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'resourceChest3',
				],
			},
			{
				id: 41687,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 116,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Произведение искусства',
				icon: 'artwork',
				tooltip: `<div class="itemDescription" item-description="41687" auction-item="false" hide-info="false" item-type="116">
	<div translate="" options="116" class="heroItemName"><span>Произведение искусства</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="15" data="x:2000"><span>2000 ед. культуры в день</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="x:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:2000"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp;2000 единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:2000"><span>При использовании моментально добавляет 2000% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:1,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}"><span>Сегодня ты использовал 0 из максимального числа 1.</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'artwork',
				],
			},
			{
				id: 41688,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 116,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Произведение искусства',
				icon: 'artwork',
				tooltip: `<div class="itemDescription" item-description="41687" auction-item="false" hide-info="false" item-type="116">
	<div translate="" options="116" class="heroItemName"><span>Произведение искусства</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="15" data="x:2000"><span>2000 ед. культуры в день</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="x:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:2000"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp;2000 единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:2000"><span>При использовании моментально добавляет 2000% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:1,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}"><span>Сегодня ты использовал 0 из максимального числа 1.</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'artwork',
				],
			},
			{
				id: 41689,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 116,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_NOTHING,
				name: 'Произведение искусства',
				icon: 'artwork',
				tooltip: `<div class="itemDescription" item-description="41687" auction-item="false" hide-info="false" item-type="116">
	<div translate="" options="116" class="heroItemName"><span>Произведение искусства</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="15" data="x:2000"><span>2000 ед. культуры в день</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="x:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:2000"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp;2000 единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:2000"><span>При использовании моментально добавляет 2000% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:1,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.max)}"><span>Сегодня ты использовал 0 из максимального числа 1.</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:0" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал 0 из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'artwork',
				],
			},
			{
				id: 21494,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 10,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_HELMET,
				name: 'Шлем наездника',
				icon: 'helmet3_0',
				tooltip: `<div class="itemDescription" item-description="21494" auction-item="false" hide-info="false" item-type="10">
	<div translate="" options="10" class="heroItemName"><span>Шлем наездника</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="3" data="x:10"><span>На 10% сокращает время обучения кавалерии в конюшнях и больших конюшнях, а также время лечения в целебных шатрах.</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="10">!!Hero.ItemBonusInstantUse_10!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="10" data="x:">!!Hero.ItemBonusInstantUse_10!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:10"><span>При использовании моментально добавляет 10% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 2,
				images: [
					'helmet3_0',
				],
			},
			{
				id: 10819,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 121,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_SHOES,
				name: 'Сапоги беглеца',
				icon: 'shoes3_0',
				tooltip: `<div class="itemDescription" item-description="10819" auction-item="false" hide-info="false" item-type="121">
	<div translate="" options="121" class="heroItemName"><span>Сапоги беглеца</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="23" data="x:150"><span>+150 воинов избежит входящей атаки и вернется в деревню через 180 секунд.</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="121">!!Hero.ItemBonusInstantUse_121!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="121" data="x:">!!Hero.ItemBonusInstantUse_121!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:150"><span>При использовании моментально добавляет 150% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 2,
				images: [
					'shoes3_0',
				],
			},
			{
				id: 38433,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 13,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_HELMET,
				name: 'Шлем наёмника',
				icon: 'helmet4_0',
				tooltip: `<div class="itemDescription" item-description="38433" auction-item="false" hide-info="false" item-type="13">
	<div translate="" options="13" class="heroItemName"><span>Шлем наёмника</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="2" data="x:10"><span>На 10% сокращает время обучения пехоты в казармах и больших казармах, а также время лечения в целебных шатрах.</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="13">!!Hero.ItemBonusInstantUse_13!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="13" data="x:">!!Hero.ItemBonusInstantUse_13!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:10"><span>При использовании моментально добавляет 10% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'helmet4_0',
				],
			},
			{
				id: 33728,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 43,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'Лёгкое копьё эдуйского всадника',
				icon: 'spear1_0',
				tooltip: `<div class="itemDescription" item-description="33728" auction-item="false" hide-info="false" item-type="43">
	<div translate="" options="43" class="heroItemName"><span>Лёгкое копьё эдуйского всадника</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="13" data="x:600"><span>Бонус к силе героя: +600</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="43">!!Hero.ItemBonusInstantUse_43!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="43" data="x:">!!Hero.ItemBonusInstantUse_43!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:600"><span>При использовании моментально добавляет 600% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName --><div ng-if="unitName">
		<div class="horizontalLine"></div>
		<span translate="" data="x:9,name:Эдуйская конница"><span>Эдуйская конница: Каждый воин этого типа получает +9 к атаке и +9 к защите.</span></span>
	</div><!-- end ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 2,
				images: [
					'spear1_0',
				],
			},
			{
				id: 33428,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 43,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'Копьё эдуйского всадника',
				icon: 'spear1_1',
				tooltip: `<div class="itemDescription" item-description="57911" auction-item="false" hide-info="false" item-type="44">
	<div translate="" options="44" class="heroItemName"><span>Копьё эдуйского всадника</span></div>
	<div ng-hide="instantUse &amp;&amp; !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="13" data="x:1200"><span>Бонус к силе героя: &#x202D;+&#x202D;1200&#x202C;&#x202C;</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse &amp;&amp; (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) &amp;&amp; itemType != heroItem.TYPE_ARTWORK
	 		&amp;&amp; itemType != heroItem.TYPE_FIREWORKS &amp;&amp; itemType != heroItem.TYPE_SUPER_FIREWORKS &amp;&amp; !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="44">!!Hero.ItemBonusInstantUse_44!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="44" data="x:">!!Hero.ItemBonusInstantUse_44!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до &#x202D;&#x202D;25&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до &#x202D;&#x202D;33&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до &#x202D;&#x202D;30&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до &#x202D;&#x202D;38&#x202C;%&#x202C; войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (&#x202D;&#x202D;5&#x202C;%&#x202C;) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до &#x202D;&#x202D;5&#x202C;%&#x202C; от войска). Так что суммарно можно восстановить &#x202D;&#x202D;33&#x202C;%&#x202C;+&#x202D;&#x202D;5&#x202C;%&#x202C;=&#x202D;&#x202D;38&#x202C;%&#x202C;.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 &amp;&amp; itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:1200"><span>При использовании моментально добавляет &#x202D;&#x202D;1200&#x202C;%&#x202C; дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max &amp;&amp; !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max &amp;&amp; perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName --><div ng-if="unitName">
		<div class="horizontalLine"></div>
		<span translate="" data="x:12,name:Эдуйская конница"><span>Эдуйская конница: Каждый воин этого типа получает &#x202D;+&#x202D;12&#x202C;&#x202C; к атаке и &#x202D;+&#x202D;12&#x202C;&#x202C; к защите.</span></span>
	</div><!-- end ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 2,
				images: [
					'spear1_0',
				],
			},
			{
				id: 22004,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 31,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'Протазан фаланги',
				icon: 'spear0_0',
				tooltip: `<div class="itemDescription" item-description="22004" auction-item="false" hide-info="false" item-type="31">
	<div translate="" options="31" class="heroItemName"><span>Протазан фаланги</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="13" data="x:550"><span>Бонус к силе героя: +550</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="31">!!Hero.ItemBonusInstantUse_31!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="31" data="x:">!!Hero.ItemBonusInstantUse_31!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:550"><span>При использовании моментально добавляет 550% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName --><div ng-if="unitName">
		<div class="horizontalLine"></div>
		<span translate="" data="x:3,name:Фаланга"><span>Фаланга: Каждый воин этого типа получает +3 к атаке и +3 к защите.</span></span>
	</div><!-- end ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'spear0_0',
				],
			},
			{
				id: 45122,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 35,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'Меч мечника',
				icon: 'sword4_1',
				tooltip: `<div class="itemDescription" item-description="45122" auction-item="false" hide-info="false" item-type="35">
	<div translate="" options="35" class="heroItemName"><span>Меч мечника</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="13" data="x:800"><span>Бонус к силе героя: +800</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="35">!!Hero.ItemBonusInstantUse_35!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="35" data="x:">!!Hero.ItemBonusInstantUse_35!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:800"><span>При использовании моментально добавляет 800% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName --><div ng-if="unitName">
		<div class="horizontalLine"></div>
		<span translate="" data="x:4,name:Мечник"><span>Мечник: Каждый воин этого типа получает +4 к атаке и +4 к защите.</span></span>
	</div><!-- end ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'sword4_1',
				],
			},
			{
				id: 37983,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 4,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_HELMET,
				name: 'Шлем восстановления',
				icon: 'helmet1_0',
				tooltip: `<div class="itemDescription" item-description="37983" auction-item="false" hide-info="false" item-type="4">
	<div translate="" options="4" class="heroItemName"><span>Шлем восстановления</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="14" data="x:12"><span>+12 здоровья в день</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="4">!!Hero.ItemBonusInstantUse_4!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="4" data="x:">!!Hero.ItemBonusInstantUse_4!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:12"><span>При использовании моментально добавляет 12% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 --><div class="upgradeBonus" ng-if="upgradeBonus.level > 0">
		<div class="horizontalLine"></div>
		<div>
			<span translate="" data="current:2, max:5"><strong>Улучшения</strong><span> (</span><span class="values" ng-bind-html="0 | bidiRatio:current:max">&lrm;&lrm;2/&lrm;5</span><span>)</span></span>
			<span class="upgradeLevel">
				<!-- ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_helmet_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_helmet_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 -->
			</span>
		</div>

	</div><!-- end ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'helmet1_0',
				],
			},
			{
				id: 18721,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 82,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_BODY,
				name: 'Легкие доспехи восстановления',
				icon: 'shirt0_0',
				tooltip: `<div class="itemDescription" item-description="18721" auction-item="false" hide-info="false" item-type="82">
	<div translate="" options="82" class="heroItemName"><span>Легкие доспехи восстановления</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="13" data="x:250"><span>Бонус к силе героя: +250</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="14" data="x:22"><span>+22 здоровья в день</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="82">!!Hero.ItemBonusInstantUse_82!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="82" data="x:">!!Hero.ItemBonusInstantUse_82!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:250"><span>При использовании моментально добавляет 250% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:22"><span>При использовании моментально добавляет 22% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 --><div class="upgradeBonus" ng-if="upgradeBonus.level > 0">
		<div class="horizontalLine"></div>
		<div>
			<span translate="" data="current:5, max:5"><strong>Улучшения</strong><span> (</span><span class="values" ng-bind-html="0 | bidiRatio:current:max">&lrm;&lrm;5/&lrm;5</span><span>)</span></span>
			<span class="upgradeLevel">
				<!-- ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_body_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_body_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_body_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_body_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_body_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 -->
			</span>
		</div>

	</div><!-- end ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 5,
				images: [
					'shirt0_0',
				],
			},
			{
				id: 12452,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 37,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_RIGHT_HAND,
				name: 'Короткий лук тевтатцев',
				icon: 'bow0_0',
				tooltip: `<div class="itemDescription" item-description="12452" auction-item="false" hide-info="false" item-type="37">
	<div translate="" options="37" class="heroItemName"><span>Короткий лук тевтатцев</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="13" data="x:550"><span>Бонус к силе героя: +550</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="37">!!Hero.ItemBonusInstantUse_37!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="37" data="x:">!!Hero.ItemBonusInstantUse_37!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:550"><span>При использовании моментально добавляет 550% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName --><div ng-if="unitName">
		<div class="horizontalLine"></div>
		<span translate="" data="x:6,name:Тевтатский гром"><span>Тевтатский гром: Каждый воин этого типа получает +6 к атаке и +6 к защите.</span></span>
	</div><!-- end ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 --><div class="upgradeBonus" ng-if="upgradeBonus.level > 0">
		<div class="horizontalLine"></div>
		<div>
			<span translate="" data="current:2, max:5"><strong>Улучшения</strong><span> (</span><span class="values" ng-bind-html="0 | bidiRatio:current:max">&lrm;&lrm;2/&lrm;5</span><span>)</span></span>
			<span class="upgradeLevel">
				<!-- ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_rightHand_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_rightHand_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 -->
			</span>
		</div>

	</div><!-- end ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'bow0_0',
				],
			},
			{
				id: 33861,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 61,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_LEFT_HAND,
				name: 'Малая карта',
				icon: 'map0_0',
				tooltip: `<div class="itemDescription" item-description="33861" auction-item="false" hide-info="false" item-type="61">
	<div translate="" options="61" class="heroItemName"><span>Малая карта</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="5" data="x:50"><span>Скорость возвращения домой героя и войск с ним выше на 50%.</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="61">!!Hero.ItemBonusInstantUse_61!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="61" data="x:">!!Hero.ItemBonusInstantUse_61!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:50"><span>При использовании моментально добавляет 50% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 --><div class="upgradeBonus" ng-if="upgradeBonus.level > 0">
		<div class="horizontalLine"></div>
		<div>
			<span translate="" data="current:3, max:5"><strong>Улучшения</strong><span> (</span><span class="values" ng-bind-html="0 | bidiRatio:current:max">&lrm;&lrm;3/&lrm;5</span><span>)</span></span>
			<span class="upgradeLevel">
				<!-- ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_leftHand_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_leftHand_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_leftHand_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 -->
			</span>
		</div>

	</div><!-- end ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 3,
				images: [
					'map0_0',
				],
			},
			{
				id: 10085,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 103,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_HORSE,
				name: 'Черная лошадь',
				icon: 'horse1_0',
				tooltip: `<div class="itemDescription" item-description="10085" auction-item="false" hide-info="false" item-type="103">
	<div translate="" options="103" class="heroItemName"><span>Черная лошадь</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="17" data="x:14"><span>+14 пол. в час</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="103">!!Hero.ItemBonusInstantUse_103!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="103" data="x:">!!Hero.ItemBonusInstantUse_103!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:14"><span>При использовании моментально добавляет 14% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'horse1_0',
				],
			},
			{
				id: 10086,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 110,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_HORSE,
				name: 'Гнедой жеребец',
				icon: 'horse0_1',
				tooltip: `<div class="itemDescription" item-description="10085" auction-item="false" hide-info="false" item-type="103">
	<div translate="" options="103" class="heroItemName"><span>Гнедой жеребец</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="17" data="x:14"><span>+19 пол. в час</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="103">!!Hero.ItemBonusInstantUse_103!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="103" data="x:">!!Hero.ItemBonusInstantUse_103!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:14"><span>При использовании моментально добавляет 14% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'horse0_1',
				],
			},
			{
				id: 30215,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 94,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: false,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_SHOES,
				name: 'Сапоги знания',
				icon: 'shoes0_0',
				tooltip: `<div class="itemDescription" item-description="30215" auction-item="false" hide-info="false" item-type="94">
	<div translate="" options="94" class="heroItemName"><span>Сапоги знания</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index --><span ng-repeat="(i,v) in bonuses track by $index">
			<span translate="" options="1" data="x:19"><span>+19% больше опыта для героя</span></span><br>
		</span><!-- end ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="94">!!Hero.ItemBonusInstantUse_94!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="94" data="x:">!!Hero.ItemBonusInstantUse_94!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses --><span translate="" ng-repeat="bonus in bonuses" data="x:19"><span>При использовании моментально добавляет 19% дневного производства ресурсов в текущей деревне. Два низших бонуса можно объединить до 5%, чтобы увеличить количество, которое они дают.</span></span><!-- end ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable" class="ng-hide"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 --><div class="upgradeBonus" ng-if="upgradeBonus.level > 0">
		<div class="horizontalLine"></div>
		<div>
			<span translate="" data="current:2, max:5"><strong>Улучшения</strong><span> (</span><span class="values" ng-bind-html="0 | bidiRatio:current:max">&lrm;&lrm;2/&lrm;5</span><span>)</span></span>
			<span class="upgradeLevel">
				<!-- ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_shoes_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_shoes_tiny_illu"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 --><i ng-repeat="a in []|range:1:5" class="upgrade_upgrade_tiny_flat_black"></i><!-- end ngRepeat: a in []|range:1:5 -->
			</span>
		</div>

	</div><!-- end ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 1,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'shoes0_0',
				],
			},
			{
				id: 54324,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 117,
				upgradedItem: false,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_BAG,
				name: 'Малая повязка',
				icon: 'small_bandage',
				tooltip: `<div class="itemDescription" item-description="54324" auction-item="false" hide-info="false" item-type="117">
	<div translate="" options="117" class="heroItemName"><span>Малая повязка</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="117">!!Hero.ItemBonusInstantUse_117!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="117" data="x:">!!Hero.ItemBonusInstantUse_117!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 222,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'small_bandage',
				],
			},
			{
				id: 54324,
				notUsableItem: false,
				notUsableReason: false,
				auctionItem: false,
				nextUpgradeLevel: false,
				itemType: 117,
				upgradedItem: true,
				disabled: false,
				filled: true,
				stackable: true,
				inSlot: false,
				premiumItem: false,
				cardGameItem: false,
				lastChange: 0,
				slot: InventoryModel.SLOT_BAG,
				name: 'Малая повязка',
				icon: 'small_bandage',
				tooltip: `<div class="itemDescription" item-description="54324" auction-item="false" hide-info="false" item-type="117">
	<div translate="" options="117" class="heroItemName"><span>Малая повязка</span></div>
	<div ng-hide="instantUse && !heroItem.isResourceItem(itemType) || hideInfo || !hasBonuses" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: (i,v) in bonuses track by $index -->
	</div>

	<!-- ngIf: heroItem.DISABLED_ON_VACATION.indexOf(itemType) >= 0 && $root.player.isOnVacation() -->

	<div ng-show="instantUse && (itemType > heroItem.TYPE_CROP_BONUS_5 || itemType < heroItem.TYPE_RESOURCE_BONUS_3) && itemType != heroItem.TYPE_ARTWORK
	 		&& itemType != heroItem.TYPE_FIREWORKS && itemType != heroItem.TYPE_SUPER_FIREWORKS && !heroItem.isResourceItem(itemType)" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="117">!!Hero.ItemBonusInstantUse_117!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_FIREWORKS || itemType == heroItem.TYPE_SUPER_FIREWORKS" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="117" data="x:">!!Hero.ItemBonusInstantUse_117!!</span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_ARTWORK" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" options="116" data="maxCulture:"><span>За каждое произведение искусства ты получишь дополнительные единицы культуры, количество которых вычисляется, исходя из производства единиц культуры во всех твоих деревнях. Ты не можешь получить больше&nbsp; единиц культуры за одно произведение искусства.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25">
		<div class="horizontalLine"></div>
		<span translate=""><span>Малые повязки восстанавливают до 25% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Повязки восстанавливают до 33% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_CAGES" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate=""><span>Животных в оазисах можно поймать в клетки и привести в свою деревню, где они будут помогать в защите.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_25_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Малые повязки восстанавливают до 30% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется малых повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_BANDAGE_33_UPGRADED" class="ng-hide">
		<hr>
		<span translate=""><span>Повязки восстанавливают до 38% войска, погибшего в бою. Ты можешь восстановить максимум столько войска, сколько имеется повязок.</span></span>
	</div>

	<div ng-show="itemType == heroItem.TYPE_HEALING_POTION" class="ng-hide">
		<hr>
		<span translate=""><span>Улучшение повязок на (5%) или можно использовать как есть (восстанавливает одного потерянного воина вплоть до 5% от войска). Так что суммарно можно восстановить 33%+5%=38%.</span></span>
	</div>

	<div ng-show="itemType <= heroItem.TYPE_CROP_BONUS_5 && itemType >= heroItem.TYPE_RESOURCE_BONUS_3" class="ng-hide">
		<div class="horizontalLine"></div>
		<!-- ngRepeat: bonus in bonuses -->
	</div>

	<div ng-show="perDay.max || perDay.maxWithoutLock" class="ng-hide">
		<div class="horizontalLine"></div>
		<span translate="" ng-show="consumable"><span>Употребляемые предметы</span></span>
		<br>
		<span translate="" ng-show="perDay.max && !perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.max)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
		<span translate="" ng-show="!perDay.max && perDay.maxWithoutLock" data="maxPerDay:,usedPerDay:" ng-class="{error:(perDay.used >= perDay.maxWithoutLock)}" class="ng-hide"><span>Сегодня ты использовал  из максимального числа .</span></span>
	</div>

	<!-- ngIf: unitName -->

	<!-- ngIf: itemType == heroItem.TYPE_CAGES -->

	<!-- ngIf: upgradeBonus.level > 0 -->

</div>`,
				amount: 222,
				sellAmount: 10,
				upgradeLevel: 0,
				images: [
					'small_bandage',
				],
			},
		];
	}
}

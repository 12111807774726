<template>
	<div v-if="type == QuestsModel.REWARD_XP" content="Очки опыта" v-tippy="{placement: 'bottom'}">
		<span class="rewardIcon">
			<i class="unit_experience_small_flat_black"></i>
		</span>
		<span class="rewardText">{{value}}</span>
	</div>
	<div v-if="type == QuestsModel.REWARD_RESOURCES">
		<span class="rewardIcon"></span>
		<span class="rewardText">
			<div class="display-resources" ng-if="checkStorage">
				<display-resources :check-storage="checkStorage" :resources="value"></display-resources>
			</div>
		</span>
	</div>
	<!-- ngIf: ::type == Quest.REWARD_TROOPS -->
	<div v-if="type == QuestsModel.REWARD_TROOPS"><!--сделано по своему. если найдется оригинал то заменить им-->
		<div v-for="unit in value">
			{{unit.amount}}×<i class="unitSmall gaul" :class="['unitType'+unit.type]" :content="unit.name" v-tippy="{placement: 'bottom'}"></i>
		</div>
	</div>
	
	<div v-if="type == QuestsModel.REWARD_SILVER" content="Серебро" v-tippy="{placement: 'bottom'}">
		<span class="rewardIcon">
			<i class="unit_silver_small_illu"></i>
		</span>
		<span class="rewardText">{{value}}</span>
	</div>
	<div v-if="type == QuestsModel.REWARD_ITEM" class="rewardHeroItem">
		<span class="rewardIcon">
			<i :class="value.icon"></i>
		</span>
		<span class="rewardText">
			<span>{{value.name}}</span>
		</span>
	</div>
	<div v-if="type == QuestsModel.REWARD_ONLY_TEXT">
		<span class="rewardIcon"></span>
		<span class="rewardText">
			<span>{{value.name}}</span>
		</span>
	</div>
	<div v-if="type == QuestsModel.REWARD_GOLD" content="Золото" v-tippy="{placement: 'bottom'}">
		<span class="rewardIcon">
			<i class="unit_gold_small_illu"></i>
		</span>
		<span class="rewardText">{{value}}</span>
	</div>
	<div v-if="type == QuestsModel.REWARD_CULTURE_POINTS">
		<span class="rewardIcon">
			<i class="unit_culturePoint_small_illu"></i>
		</span>
		<span class="rewardText">{{value}}</span>
	</div>
	<!-- ngIf: ::type == QuestsModel.REWARD_TREASURES -->
	<div v-if="type == QuestsModel.REWARD_ADVENTURES_POINTS">
		<span class="rewardIcon">
			<i class="unit_adventurePoint_small_illu" content="Очки приключений" v-tippy="{placement: 'bottom'}"></i>
		</span>
		<span class="rewardText">{{value}}</span>
	</div>
	<div v-if="type == QuestsModel.REWARD_BUILDING_LEVEL">
		<span class="rewardIcon">
			<img class="buildingLarge" :class="['buildingType'+value.buildingType, 'tribeId'+value.tribeId]" :content="value.name" v-tippy="{placement: 'bottom'}">
		</span>
		<span class="rewardText">
			<span>Уровень здания: {{value.level}}</span>
		</span>
	</div>
	<!-- ngIf: ::type == Quest.REWARD_PLUS_ACCOUNT -->
	<!-- ngIf: ::type == Quest.REWARD_RESOURCE_BONUS -->
	<!-- ngIf: ::type == Quest.REWARD_CROP_BONUS -->
</template>

<style lang="css" scoped>
.rewardIcon {
	margin-right: 5px;
}
</style>

<script>
import lib from '@/lib';
import QuestsModel from '@/models/game/QuestsModel';

import DisplayResources from '@/components/elements/DisplayResources';

export default {
	mixins: lib.mixins,
	props: {
		type: {
			type: String,
			required: true,
		},
		value: {
			type: [Array, Object, Number],
			required: true,
		},
		size: {
			type: String,
			default: 'small',
		},
		checkStorage: {
			type: Boolean,
			default: true,
		},
	},
	data: () => ({
		QuestsModel,
	}),
	computed: {},
	methods: {},
	components: {
		DisplayResources,
	},
}
</script>
<template>
	<div class="contentBox exchangeOffice" v-if="gameModel.player.deletionTime == 0">
		<div class="contentBoxBody">
			<div class="description"><span>В пункте обмена ты можешь обменять серебро на золото и наоборот. Введи количество, которое ты хочешь поменять, или используй бегунок, затем нажми "Обменять".</span></div>
			<div class="columns">
				<div class="column silverToGold">
					<h6 class="headerWithIcon arrowDirectionTo">
						<i class="unit_silver_medium_illu"></i>
						<i class="unit_gold_medium_illu"></i>
						<span><span>Серебро на золото</span></span>
					</h6>
					<div class="columnContent">
						<div class="exchangeRate">
							<span class="unimportant">÷ {{exchangeRates.silverToGold}} = </span>
							<i class="unit_gold_small_illu"></i>
							<span>&nbsp;{{exchangeSilver / exchangeRates.silverToGold}}</span>
						</div>
						<range-slider class="exchangeSlider" v-model.number="exchangeSilver" :min="0" :max="gameModel.player.silver" :step="exchangeRates.silverToGold" :show-max-button="false" icon-class="unit_silver_small_illu" :disabled="exchangeRates.silverToGold > gameModel.player.silver" />
						<button :class="{'disabled': exchangeSilver == 0 || exchangeSilverGold == 0}" :disabled="exchangeSilver == 0 || exchangeSilverGold == 0" @click="onExchangeSilverToGold()" class="animate exchangeSubmit free" content="Обменять серебро на золото" v-tippy="{placement: 'bottom'}" tooltip-translate="ExchangeOffice.exchangeSilverToGoldNow">
							<div class="content">
								<span class="innerText">
									<span><span>Обменять</span></span>
								</span>
								<!-- ngIf: price > 0 || forceGoldUsage -->
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
				</div>
				<div class="column goldToSilver">
					<h6 class="headerWithIcon arrowDirectionTo">
						<i class="unit_gold_medium_illu"></i>
						<i class="unit_silver_medium_illu"></i>
						<span><span>Золото на серебро</span></span>
					</h6>
					<div class="columnContent">
						<div class="exchangeRate">
							<span class="unimportant">× {{exchangeRates.goldToSilver}} = </span>
							<i class="unit_silver_small_illu"></i>
							<span>&nbsp;{{exchangeGold * exchangeRates.goldToSilver}}</span>
						</div>
						<range-slider class="exchangeSlider" v-model.number="exchangeGold" :min="0" :max="gameModel.player.gold" :step="1" :show-max-button="false" icon-class="unit_gold_small_illu" />
						<button :class="{'disabled': exchangeGold == 0 || exchangeGoldSilver == 0}" :disabled="exchangeGold == 0 || exchangeGoldSilver == 0" class="animate exchangeSubmit premium" @click="onExchangeGoldToSilver()" content="Обменять золото на серебро" v-tippy="{placement: 'bottom'}" tooltip-translate="ExchangeOffice.exchangeGoldToSilverNow">
							<div class="content">
								<span class="innerText">
									<span><span>Обменять</span></span>
								</span>
								<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>{{exchangeGold}}</span>
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="contentBoxFooter" v-if="exchangeSuccess.message">
			<div class="success">
				<span v-if="exchangeSuccess.type == 'SilverToGold'">
					<i class="icon unit_silver_small_illu"></i>
					<span>&nbsp;{{exchangeSuccess.silver}} обменяно на </span>
					<i class="icon unit_gold_small_illu"></i>
					<span>&nbsp;{{exchangeSuccess.gold}}</span>
				</span>
				<span v-if="exchangeSuccess.type == 'GoldToSilver'">
					<i class="icon unit_gold_small_illu"></i>
					<span>&nbsp;{{exchangeSuccess.gold}} обменяно на </span>
					<i class="icon unit_silver_small_illu"></i>
					<span>&nbsp;{{exchangeSuccess.silver}}</span>
				</span>
			</div>
			<!-- ngIf: exchangeError -->
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import RangeSlider from '@/components/elements/RangeSlider';

export default {
	mixins: lib.mixins,
	data: () => ({
		exchangeRates: {
			silverToGold: 200,
			goldToSilver: 100,
		},
		exchangeSilver: 0,
		exchangeGold: 0,
		exchangeSuccess: {
			type: '',
			silver: 0,
			gold: 0,
			message: '',
		},
	}),
	computed: {
		exchangeSilverGold(){
			return this.exchangeSilver / this.exchangeRates.silverToGold;
		},
		exchangeGoldSilver(){
			return this.exchangeGold * this.exchangeRates.goldToSilver;
		},
	},
	methods: {
		onExchangeSilverToGold(){
			if(confirm('Вы уверены?')){
				this.exchangeSuccess.type = 'SilverToGold';
				this.exchangeSuccess.silver = this.exchangeSilver;
				this.exchangeSuccess.gold = this.exchangeSilverGold;
				this.exchangeSuccess.message = 'success';
				
				if(this.gameModel.player.silver >= this.exchangeRates.silverToGold){
					this.exchangeSilver = this.exchangeRates.silverToGold;
				} else {
					this.exchangeSilver = 0;
				}
			}
		},
		onExchangeGoldToSilver(){
			if(confirm('Вы уверены?')){
				this.exchangeSuccess.type = 'GoldToSilver';
				this.exchangeSuccess.silver = this.exchangeGoldSilver;
				this.exchangeSuccess.gold = this.exchangeGold;
				this.exchangeSuccess.message = 'success';
				
				if(this.gameModel.player.gold >= 1){
					this.exchangeGold = 1;
				} else {
					this.exchangeGold = 0;
				}
			}
		},
	},
	mounted(){
		if(this.gameModel.player.silver >= this.exchangeRates.silverToGold){
			this.exchangeSilver = this.exchangeRates.silverToGold;
		}
		if(this.gameModel.player.gold >= 1){
			this.exchangeGold = 1;
		}
	},
	components: {
		RangeSlider,
	},
}
</script>
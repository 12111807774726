<template>
	<div class="quests contentBox gradient" ng-class="{adventures: isAdventure}">
		<div class="contentBoxBody">
			<div class="selectionContainer unselectable">
				<div class="questPagination">
					<span>
						<div v-for="quest in questsOnPage" :key="quest.id" class="possibleQuest">
							<div class="clickableContainer clickable" @click="onSelectQuest(quest.id)" clickable="selectQuest(536)" :class="{'active': quest.id == selectedQuest}">
								<i v-if="!quest.isAdventure" class="questGiverIcon" :class="['questGiver'+quest.giver]"></i>
								<span class="questName ng-scope">
									<span>{{quest.name}}</span>
								</span>
								<i v-if="quest.status == QuestsModel.STATUS_DONE" class="symbol_star_small_illu doneMarker"></i>
								<div v-if="quest.status == QuestsModel.STATUS_ACTIVATABLE" class="newMarker">
									<i class="character_exclamation_mark_tiny"></i>
								</div>
								<div class="selectionArrow" v-if="quest.id == selectedQuest"></div>
							</div>
							<div v-if="!quest.isAdventure" class="contentBox">
								<div class="progressbar" :class="{'completed': quest.status == QuestsModel.STATUS_DONE}" :content="quest.status != QuestsModel.STATUS_DONE && quest.tooltip ? quest.tooltip+(quest.maxValue - quest.currentValue) : ''" v-tippy="{placement: 'bottom'}">
									<div class="progress">
										<div class="bar positive perc" style="z-index: 2;" :style="{width: getPercent(quest.currentValue, quest.maxValue)+'%'}"></div>
										<div class="bar additionalBar additionalPerc"></div>
										<div class="marker">
											<div class="markerInner"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</span>
					<pagination :items="quests" :page-size="7" @change-page="onChangePage" style="padding: 5px 0 0 0;"></pagination>
				</div>
			</div>
			<div class="detailContainer" v-if="selectedQuest" ng-include="'tpl/questDetails/questDetails.html'">
				<div ng-controller="questDetailsCtrl" class="questDetailsContent">
					<div class="borderWrapper">
						<div class="header">
							<img src="//iwstatic.g.bsrv.su/img/x.gif" :class="getSelectedQuest.class" />
							<h6 class="headerWithArrowEndings important ng-scope">
								<span class="content" v-if="getSelectedQuest.status == QuestsModel.STATUS_DONE">Задание завершено</span>
								<span class="content" v-else>{{getSelectedQuest.name}}</span>
							</h6>
						</div>
						<div>
							<span>{{getSelectedQuest.description}}</span>
						</div>
						<div class="horizontalLine" v-if="getSelectedQuest.rewards.length > 0 || getSelectedQuest.isAdventure"></div>
						<div class="rewardWrapper" v-if="getSelectedQuest.rewards.length > 0">
							<h2><span>Награда</span></h2>
							<div class="rewards" v-for="reward in getSelectedQuest.rewards">
								<reward :type="reward.type" :value="reward.value" size="small" :check-storage="getSelectedQuest.status == QuestsModel.STATUS_DONE"></reward>
								<!--
								<div class="reward">
									<div v-if="reward.type == QuestsModel.REWARD_XP" content="Очки опыта" v-tippy="{placement: 'bottom'}">
										<span class="rewardIcon">
											<i class="unit_experience_small_flat_black"></i>
										</span>
										<span class="rewardText">{{reward.value}}</span>
									</div>
									<div v-if="reward.type == QuestsModel.REWARD_RESOURCES">
										<span class="rewardIcon"></span>
										<span class="rewardText">
											<div class="display-resources" ng-if="checkStorage">
												<display-resources :check-storage="true" :resources="reward.value"></display-resources>
											</div>
										</span>
									</div>
									<div v-if="reward.type == QuestsModel.REWARD_SILVER" content="Серебро" v-tippy="{placement: 'bottom'}">
										<span class="rewardIcon">
											<i class="unit_silver_small_illu"></i>
										</span>
										<span class="rewardText">{{reward.value}}</span>
									</div>
									<div v-if="reward.type == QuestsModel.REWARD_ITEM" class="rewardHeroItem">
										<span class="rewardIcon">
											<i :class="reward.icon"></i>
										</span>
										<span class="rewardText">
											<span>{{reward.name}}</span>
										</span>
									</div>
								</div>
								-->
							</div>
						</div>
						<div class="buttonWrapper" v-if="getSelectedQuest.status == QuestsModel.STATUS_DONE">
							<div class="horizontalLine"></div>
							<button @click="onCollectReward()" clickable="collectReward()" class="larger clickable" ng-class="{disabled: lockQuestView}" ng-if="!error">
								<div class="content">
									<span class="innerText">
										<span>Забрать награду</span>
									</span>
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import paginate from 'jw-paginate';

import QuestsModel from '@/models/game/QuestsModel';

import Pagination from '@/components/elements/Pagination';
import Reward from '@/components/elements/Reward';

export default {
	mixins: lib.mixins,
	data: () => ({
		QuestsModel,
		
		selectedQuest: null,
		
		pager: {},
		questsOnPage: [],
	}),
	computed: {
		quests(){
			return this.gameModel.questBook.openQuests;
		},
		getSelectedQuest(){
			return this.gameModel.questBook.openQuests.find((el) => el.id == this.selectedQuest);
		},
	},
	methods: {
		getPercent: lib.getPercent,
		
		onSelectQuest(id){
			this.selectedQuest = id;
			let quest = this.getSelectedQuest;
			if(quest.status == QuestsModel.STATUS_ACTIVATABLE) quest.status = QuestsModel.STATUS_PROCESSING;
		},
		onChangePage(pageOfItems){
			this.questsOnPage = pageOfItems;
		},
		onCollectReward(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_1.ogg');
				audio.play();
			}
			
			let index = this.gameModel.questBook.openQuests.findIndex((el) => el.id == this.selectedQuest);
			this.gameModel.questBook.openQuests.splice(index, 1);
			if(this.gameModel.questBook.openQuests[index]){
				this.selectedQuest = this.gameModel.questBook.openQuests[index].id;
			} else {
				if(this.gameModel.questBook.openQuests.length > 0){
					this.selectedQuest = this.gameModel.questBook.openQuests[this.gameModel.questBook.openQuests.length - 1].id;
				} else {
					this.selectedQuest = null;
				}
			}
			let quest = this.getSelectedQuest;
			if(quest){
				if(quest.status == QuestsModel.STATUS_ACTIVATABLE) quest.status = QuestsModel.STATUS_PROCESSING;
			}
		},
	},
	mounted(){
		if(this.questsOnPage.length > 0){
			this.onSelectQuest(this.questsOnPage[0].id);
		}
	},
	components: {
		Pagination,
		Reward,
	},
}
</script>
<template>
	<div class="unselectable">
		<div id="heroQuickInfo" v-if="gameModel.player != null">
			<div class="heroStats">
				<div class="stat">
					<i class="label unit_health_tiny_flat_black"></i>
					<div class="health progressbar" :content="'Здоровье: '+gameModel.player.health+'<div class=\'horizontalLine\'></div>Восстановление в секунду: '+gameModel.player.cureSpeed" v-tippy="{placement: 'left'}">
						<div class="progress">
							<div class="bar positive perc full" :style="{zIndex: 2, width: getPercent(gameModel.player.health, gameModel.player.healthMax)+'%'}"></div>
							<div class="bar additionalBar additionalPerc"></div>
							<div class="marker">
								<div class="markerInner"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="stat">
					<i class="label unit_experience_tiny_flat_black"></i>
					<div class="xp progressbar" :content="'Опыт до следующего уровня: '+gameModel.player.expAchieved+'/'+gameModel.player.expNeeded" v-tippy="{placement: 'left'}">
						<div class="progress">
							<div class="bar positive perc" :style="{zIndex: 2, width: getPercent(gameModel.player.expAchieved, gameModel.player.expNeeded)+'%'}"></div>
							<div class="bar additionalBar additionalPerc"></div>
							<div class="marker">
								<div class="markerInner"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="heroLinks">
				<router-link :to="{path: '/hero', query: {tab: 'adventures'}}" class="directLink adventureLink clickable" content="Приключение" v-tippy="{placement: 'bottom'}">
					<i :class="['movement_adventure_small_flat_black', {'disabled': gameModel.player.adventurePoints <= 0}]"></i>
				</router-link>
				<router-link :to="{path: '/hero', query: {tab: 'inventory'}}" class="framedAvatarImage clickable" content="Твой персонаж" v-tippy="{placement: 'bottom'}">
					<div class="avatar-image">
						<div class="heroImage male">
							<img src="//iwstatic.g.bsrv.su/resources/avatars/125/lexinzector.png" width="120" height="120" />
						</div>
					</div>
					<div class="prestigeStars" v-if="gameModel.config.balancing.features.prestige">
						<div class="innerStars">
							<div>
								<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
								<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
								<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
							</div>
						</div>
					</div>
				</router-link>
				<div class="prestigeStarsTooltip clickable" @click="$router.push({path: '/player', query: {tab: 'prestige'}})" v-if="gameModel.config.balancing.features.prestige" content="Сейчас у тебя 245 престижа. Для следующего уровня необходимо 300 престижа." v-tippy="{placement: 'bottom-end'}"></div>
				<router-link :to="{path: '/hero', query: {tab: 'attributes'}}" class="directLink attributesLink clickable" :content="gameModel.player.freeStats > 0 ? 'Количество очков, которые можно распределить: '+gameModel.player.freeStats : 'Параметры'" v-tippy="{placement: 'bottom-end'}">
					<div class="text"><span>Ур.</span></div>
					<div class="level">{{gameModel.player.level}}</div>
					<i :class="['levelStar', 'symbol_star_small_illu', {'levelUp': gameModel.player.freeStats > 0 || gameModel.player.levelUp}]" v-if="gameModel.player.freeStats > 0 || gameModel.player.levelUp"></i>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style lang="css">
#heroQuickInfo{
    position:absolute;
    right:5px;
    top:33px;
    z-index:3;
}
#heroQuickInfo .heroStats{
    height:23px;
}
#heroQuickInfo .heroStats .stat{
    margin-left:18px;
    width:107px;
}
#heroQuickInfo .heroStats .stat:after{
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0;
}
#heroQuickInfo .heroStats .stat:last-child{
    margin-top:3px;
}
#heroQuickInfo .heroStats .stat .label{
    float:left;
    font-size:.76923rem;
    font-weight:bold;
    line-height:10px;
}
#heroQuickInfo .heroStats .stat .progressbar{
    float:right;
}
#heroQuickInfo .heroStats .stat .progressbar .progress{
    width:88px;
}
#heroQuickInfo .heroStats .stat .progressbar.health .bar.perc{
    background-image:-owg-linear-gradient(#ffd7b4,#f67300 80%,#af5200);
    background-image:-webkit-linear-gradient(#ffd7b4,#f67300 80%,#af5200);
    background-image:-moz-linear-gradient(#ffd7b4,#f67300 80%,#af5200);
    background-image:-o-linear-gradient(#ffd7b4,#f67300 80%,#af5200);
    background-image:linear-gradient(#ffd7b4,#f67300 80%,#af5200);
}
#heroQuickInfo .heroStats .stat .progressbar.health .bar.perc:before{
    border:1px solid #a66007;
}
#heroQuickInfo .heroStats .stat .progressbar.xp .bar.perc{
    background-image:-owg-linear-gradient(#b1e4ff,#24b4fd 60%,#1271a2);
    background-image:-webkit-linear-gradient(#b1e4ff,#24b4fd 60%,#1271a2);
    background-image:-moz-linear-gradient(#b1e4ff,#24b4fd 60%,#1271a2);
    background-image:-o-linear-gradient(#b1e4ff,#24b4fd 60%,#1271a2);
    background-image:linear-gradient(#b1e4ff,#24b4fd 60%,#1271a2);
}
#heroQuickInfo .heroStats .stat .progressbar.xp .bar.perc:before{
    border:1px solid #0d648e;
}
#heroQuickInfo .heroLinks{
    position:relative;
    margin-top:13px;
    background-position:-343px 0;
    background-image:url("//iwstatic.g.bsrv.su/img/sprites/hero.png");
    width:178px;
    height:98px;
}
#heroQuickInfo .heroLinks>.prestigeStarsTooltip{
    position:absolute;
    width:69px;
    height:20px;
    right:55px;
    bottom:15px;
}
#heroQuickInfo .heroLinks .directLink{
    position:absolute;
    top:9px;
    width:24px;
    height:24px;
    background-color:#000;
    border:1px solid #e6c77c;
    -webkit-box-shadow:inset 0 0 0 1px rgba(255,255,255,0.25);
    -moz-box-shadow:inset 0 0 0 1px rgba(255,255,255,0.25);
    box-shadow:inset 0 0 0 1px rgba(255,255,255,0.25);
    background-image:-owg-linear-gradient(#fff,#e6c77c);
    background-image:-webkit-linear-gradient(#fff,#e6c77c);
    background-image:-moz-linear-gradient(#fff,#e6c77c);
    background-image:-o-linear-gradient(#fff,#e6c77c);
    background-image:linear-gradient(#fff,#e6c77c);
}
#heroQuickInfo .heroLinks .directLink:hover:not(.disabled){
    -webkit-box-shadow:inset 0 0 3px 2px rgba(255,255,255,0.75);
    -moz-box-shadow:inset 0 0 3px 2px rgba(255,255,255,0.75);
    box-shadow:inset 0 0 3px 2px rgba(255,255,255,0.75);
}
#heroQuickInfo .heroLinks .directLink:active:not(.disabled){
    background-image:-owg-linear-gradient(#e6c77c,#9a7641);
    background-image:-webkit-linear-gradient(#e6c77c,#9a7641);
    background-image:-moz-linear-gradient(#e6c77c,#9a7641);
    background-image:-o-linear-gradient(#e6c77c,#9a7641);
    background-image:linear-gradient(#e6c77c,#9a7641);
    -webkit-box-shadow:inset 0 0 3px 2px rgba(235,235,235,0.75);
    -moz-box-shadow:inset 0 0 3px 2px rgba(235,235,235,0.75);
    box-shadow:inset 0 0 3px 2px rgba(235,235,235,0.75);
}
#heroQuickInfo .heroLinks .directLink.adventureLink{
    left:19px;
}
#heroQuickInfo .heroLinks .directLink.adventureLink i{
    cursor:pointer;
    top:4px;
}
#heroQuickInfo .heroLinks .directLink.attributesLink{
    right:17px;
    color:#252525;
}
#heroQuickInfo .heroLinks .directLink.attributesLink .text{
    margin-top:1px;
    font-size:.69231rem;
    line-height:10px;
}
#heroQuickInfo .heroLinks .directLink.attributesLink .level{
    font-size:.84615rem;
    line-height:13px;
}
#heroQuickInfo .heroLinks .directLink.attributesLink i{
    position:absolute;
    top:-10px;
    right:-9px;
}
#heroQuickInfo .heroLinks .directLink.attributesLink i.levelUp{
    animation:starJump .3s;
    -webkit-animation:starJump .3s;
    -moz-animation:starJump .3s;
}
@-webkit-keyframes starJump{
    from{
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
    10%{
        opacity:1;
    }
    50%{
        -webkit-transform:scale(2);
        -moz-transform:scale(2);
        -ms-transform:scale(2);
        -o-transform:scale(2);
        transform:scale(2);
    }
    90%{
        opacity:1;
    }
    to{
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
}
@-moz-keyframes starJump{
    from{
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
    10%{
        opacity:1;
    }
    50%{
        -webkit-transform:scale(2);
        -moz-transform:scale(2);
        -ms-transform:scale(2);
        -o-transform:scale(2);
        transform:scale(2);
    }
    90%{
        opacity:1;
    }
    to{
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
}
@-ms-keyframes starJump {
    from {
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
    10%{
        opacity:1
    }
    50%{
        -webkit-transform:scale(2);
        -moz-transform:scale(2);
        -ms-transform:scale(2);
        -o-transform:scale(2);
        transform:scale(2);
    }
    90%{
        opacity:1;
    }
    to{
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
}
@-o-keyframes starJump{
    from{
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
    10%{
        opacity:1;
    }
    50%{
        -webkit-transform:scale(2);
        -moz-transform:scale(2);
        -ms-transform:scale(2);
        -o-transform:scale(2);
        transform:scale(2);
    }
    90%{
        opacity:1;
    }
    to{
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
}
@keyframes starJump{
    from{
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
    10%{
        opacity:1;
    }
    50%{
        -webkit-transform:scale(2);
        -moz-transform:scale(2);
        -ms-transform:scale(2);
        -o-transform:scale(2);
        transform:scale(2);
    }
    90%{
        opacity:1;
    }
    to{
        opacity:0;
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);
    }
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {
		getPercent: lib.getPercent,
	},
	components: {},
}
</script>
<template>
	<div id="topMenu">
		<nav id="mainNavigation">
			<router-link :to="{path: '/township'}" v-slot="{href, route, navigate, isActive, isExactActive}" custom>
				<a :href="href" @click="navigate" :class="['navi_village', 'bubbleButton', {'active': isExactActive}]" id="optimizly_mainnav_village" content="Поселок" v-tippy="{placement: 'bottom'}">
					<i id="mainNaviIconvillage" class="header_village_large_illu"></i>
				</a>
			</router-link>
			<router-link :to="{path: '/island'}" v-slot="{href, route, navigate, isActive, isExactActive}" custom>
				<a :href="href" @click="navigate" :class="['navi_resources', 'bubbleButton', {'active': isExactActive}]" id="optimizly_mainnav_village" content="Остров" v-tippy="{placement: 'bottom'}">
					<i id="mainNaviIconresources" class="header_resources_large_illu"></i>
				</a>
			</router-link>
			<router-link :to="{path: '/map'}" v-slot="{href, route, navigate, isActive, isExactActive}" custom>
				<a :href="href" @click="navigate" :class="['navi_map', 'bubbleButton', {'active': isExactActive}]" id="optimizly_mainnav_village" content="Карта" v-tippy="{placement: 'bottom'}">
					<i id="mainNaviIconmap" class="header_map_large_illu"></i>
					<i ng-class="{jumpToActiveVillage_small_flat_black: !jumpToActiveVillageHover, jumpToActiveVillage_small_flat_green: jumpToActiveVillageHover}" class="jumpToActiveVillage jumpToActiveVillage_small_flat_black" ng-if="!villageCentered && isExactActive" v-if="isExactActive"></i>
				</a>
			</router-link>
		</nav>
		<div id="subNavigation">
			<router-link :to="{path: '/island/area'}" class="troop subButton clickable" content="Войска: Потребление" v-tippy="{placement: 'bottom'}">
				<i class="unit_troop_medium_illu"></i>
				<div class="value">{{gameModel.island.cropConsumptionTroops}}</div>
			</router-link>
			<router-link :to="{path: '/production-overview', query: {tab: 'balance'}}" class="population subButton clickable" content="Население: Потребление" v-tippy="{placement: 'bottom'}">
				<i class="unit_population_medium_illu"></i>
				<div class="value">{{gameModel.island.cropConsumptionPopulation}}</div>
			</router-link>
			<a class="population subButton clickable" content="Население: Потребление" v-tippy="{placement: 'bottom'}">
				<i class="unit_population_medium_illu"></i>
				<div class="value">{{gameModel.island.cropConsumptionPopulation}}</div>
			</a>
			<router-link :to="{path: '/shop'}" class="gold subButton clickable" content="Функции золота" v-tippy="{placement: 'bottom'}">
				<i class="unit_gold_medium_illu"></i>
				<i v-if="gameModel.TopMenu.goldPlus || gameModel.TopMenu.goldMinus" :class="['animation_gold_large_illu', 'goldChanged']"></i>
				<div class="value">{{gameModel.player.gold}}</div>
			</router-link>
			<router-link :to="{path: '/hero', query: {tab: 'auctions'}}" class="silver subButton clickable" content="Аукцион" v-tippy="{placement: 'bottom'}">
				<i class="unit_silver_medium_illu"></i>
				<i v-if="gameModel.TopMenu.silverPlus || gameModel.TopMenu.silverMinus" :class="['animation_silver_large_illu', 'silverChanged']"></i>
				<div class="value">{{gameModel.player.silver}}</div>
			</router-link>
			<a class="silver subButton clickable" content="Функции бронзы" v-tippy="{placement: 'bottom'}">
				<i class="unit_bronze_medium_illu"></i>
				<i v-if="gameModel.TopMenu.bronzePlus || gameModel.TopMenu.bronzeMinus" :class="['animation_bronze_large_illu', 'bronzeChanged']"></i>
				<div class="value">{{gameModel.player.bronze}}</div>
			</a>
		</div>
	</div>
</template>

<style lang="css">
@-webkit-keyframes masterGlow{
    0{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0)
    }
    50%{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        border:1px solid #decd39
    }
    100%{
        -webkit-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        -moz-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        box-shadow:0 0 0 1px rgba(0,0,0,0.15)
    }
}
@-moz-keyframes masterGlow{
    0{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0)
    }
    50%{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        border:1px solid #decd39
    }
    100%{
        -webkit-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        -moz-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        box-shadow:0 0 0 1px rgba(0,0,0,0.15)
    }
}
@-ms-keyframes masterGlow{
    0{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0)
    }
    50%{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        border:1px solid #decd39
    }
    100%{
        -webkit-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        -moz-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        box-shadow:0 0 0 1px rgba(0,0,0,0.15)
    }
}
@-o-keyframes masterGlow{
    0{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0)
    }
    50%{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        border:1px solid #decd39
    }
    100%{
        -webkit-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        -moz-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        box-shadow:0 0 0 1px rgba(0,0,0,0.15)
    }
}
@keyframes masterGlow{
    0{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0),inset 0 -20px 40px 0 rgba(255,232,92,0)
    }
    50%{
        -webkit-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        -moz-box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        box-shadow:0 -5px 40px 0 rgba(255,232,92,0.8),inset 0 -20px 40px 0 rgba(255,232,92,0.8);
        border:1px solid #decd39
    }
    100%{
        -webkit-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        -moz-box-shadow:0 0 0 1px rgba(0,0,0,0.15);
        box-shadow:0 0 0 1px rgba(0,0,0,0.15)
    }
}
@-webkit-keyframes newConstruction{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
@-moz-keyframes newConstruction{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
@-ms-keyframes newConstruction{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
@-o-keyframes newConstruction{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
@keyframes newConstruction{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
@-webkit-keyframes uiNotification{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
@-moz-keyframes uiNotification{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
@-ms-keyframes uiNotification{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
@-o-keyframes uiNotification{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
@keyframes uiNotification{
    from{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    40%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    50%{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1)
    }
    90%{
        opacity:1;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(0.4);
        -moz-transform:scale(0.4);
        -ms-transform:scale(0.4);
        -o-transform:scale(0.4);
        transform:scale(0.4)
    }
}
#topMenu{
    height: 70px;
    /*width: 460px;*/
    /*width: 480px;*/
    width: 560px;
    position: relative;
    margin: 0 auto;
    text-align: center;
}
#topMenu #mainNavigation{
    margin:0 auto;
    position:relative;
    z-index:6;
    width:260px;
    /*width:170px;*/
}
#topMenu #mainNavigation a{
    display:inline-block
}
#topMenu #mainNavigation a:nth-child(n+2){
    margin-left:2px
}
#topMenu #mainNavigation i.jumpToActiveVillage{
    position:absolute;
    top:22px;
    left:25px;
    z-index:1
}
#topMenu #subNavigation{
    position:absolute;
    z-index:5;
    top:7px;
    width:100%
}
#topMenu #subNavigation:after{
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0
}
#topMenu #subNavigation .subButton{
    width:46px;
    height:56px;
    display:inline-block;
    position:relative
}
#topMenu #subNavigation .subButton:before{
    content:"";
    width:46px;
    height:23px;
    position:absolute;
    left:0;
    top:0;
    z-index:1;
    -moz-border-radius-topleft:24px;
    -webkit-border-top-left-radius:24px;
    border-top-left-radius:24px;
    -moz-border-radius-topright:24px;
    -webkit-border-top-right-radius:24px;
    border-top-right-radius:24px;
    background-image:-owg-radial-gradient(20px 50px,#fff,#e6c77c);
    background-image:-webkit-radial-gradient(20px 50px,#fff,#e6c77c);
    background-image:-moz-radial-gradient(20px 50px,#fff,#e6c77c);
    background-image:-o-radial-gradient(20px 50px,#fff,#e6c77c);
    background-image:radial-gradient(20px 50px,#fff,#e6c77c);
    -webkit-box-shadow:0 -1px 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow:0 -1px 0 1px rgba(0,0,0,0.15);
    box-shadow:0 -1px 0 1px rgba(0,0,0,0.15)
}
#topMenu #subNavigation .subButton:after{
    content:"";
    position:absolute;
    top:5px;
    left:5px;
    width:34px;
    height:34px;
    z-index:2;
    background-image:-owg-linear-gradient(#f1f0ee,#e6c77c);
    background-image:-webkit-linear-gradient(#f1f0ee,#e6c77c);
    background-image:-moz-linear-gradient(#f1f0ee,#e6c77c);
    background-image:-o-linear-gradient(#f1f0ee,#e6c77c);
    background-image:linear-gradient(#f1f0ee,#e6c77c);
    margin:0 auto;
    border-radius:17px;
    border:1px solid #9a7641;
    -webkit-box-shadow:0 0 5px 0 rgba(0,0,0,0.15);
    -moz-box-shadow:0 0 5px 0 rgba(0,0,0,0.15);
    box-shadow:0 0 5px 0 rgba(0,0,0,0.15)
}
#topMenu #subNavigation .subButton.masterGlow:after{
    animation:masterGlow .8s;
    -webkit-animation:masterGlow .8s;
    -moz-animation:masterGlow .8s
}
#topMenu #subNavigation .subButton i.newConstruction{
    margin:-18.5px;
    opacity:0;
    animation:newConstruction .7s;
    -webkit-animation:newConstruction .7s;
    -moz-animation:newConstruction .7s
}
#topMenu #subNavigation .subButton i.goldChanged,
#topMenu #subNavigation .subButton i.silverChanged,
#topMenu #subNavigation .subButton i.bronzeChanged{
    margin:-18.5px;
    opacity:0;
    animation:uiNotification .7s;
    -webkit-animation:uiNotification .7s;
    -moz-animation:uiNotification .7s
}
.Firefox #topMenu #subNavigation .subButton i.newConstruction,
.Firefox #topMenu #subNavigation .subButton i.goldChanged,
.Firefox #topMenu #subNavigation .subButton i.silverChanged,
.Firefox #topMenu #subNavigation .subButton i.bronzeChanged{
    margin-top:-19px
}
#topMenu #subNavigation .subButton:hover:not(.deactivated):after,
#topMenu #subNavigation .subButton.hover:not(.deactivated):after{
    border:1px solid #e6c77c;
    -webkit-box-shadow:0 0 5px 0 rgba(0,0,0,0.15),inset 0 0 12px 15px rgba(255,255,255,0.3);
    -moz-box-shadow:0 0 5px 0 rgba(0,0,0,0.15),inset 0 0 12px 15px rgba(255,255,255,0.3);
    box-shadow:0 0 5px 0 rgba(0,0,0,0.15),inset 0 0 12px 15px rgba(255,255,255,0.3)
}
#topMenu #subNavigation .subButton:active:not(.noClick):after,
#topMenu #subNavigation .subButton.active:not(.noClick):after{
    border:1px solid #9a7641;
    background-image:-owg-linear-gradient(#9a7641,#e6c77c);
    background-image:-webkit-linear-gradient(#9a7641,#e6c77c);
    background-image:-moz-linear-gradient(#9a7641,#e6c77c);
    background-image:-o-linear-gradient(#9a7641,#e6c77c);
    background-image:linear-gradient(#9a7641,#e6c77c)
}
#topMenu #subNavigation .subButton i{
    position:absolute;
    top:10px;
    left:11px;
    z-index:3
}
#topMenu #subNavigation .subButton .value{
    color:#252525;
    font-weight:normal;
    font-size:.84615rem;
    position:absolute;
    bottom:0;
    line-height:13px;
    width:100%
}
#topMenu #subNavigation .subButton:hover .value{
    color:#252525;
    font-weight:normal;
    font-size:.84615rem
}
#topMenu #subNavigation .subButton.population{
    float:left;
    margin-left:5px
}
#topMenu #subNavigation .subButton.troop{
    float:left
}
#topMenu #subNavigation .subButton.gold,
#topMenu #subNavigation .subButton.silver{
    float:right;
    margin-left:5px
}
#topMenu #subNavigation .subButton.population i,
#topMenu #subNavigation .subButton.troop i{
    left:10px
}
.bubbleButton{
    position:relative;
    display:block;
    width:75px;
    height:75px;
    box-sizing:border-box;
    background-image:-owg-linear-gradient(to bottom,#fff,#fbf2e0 10%,#e6c77c 47%,#e6c77c 53%,#fbf2e0 90%,#fff);
    background-image:-webkit-linear-gradient(to bottom,#fff,#fbf2e0 10%,#e6c77c 47%,#e6c77c 53%,#fbf2e0 90%,#fff);
    background-image:-moz-linear-gradient(to bottom,#fff,#fbf2e0 10%,#e6c77c 47%,#e6c77c 53%,#fbf2e0 90%,#fff);
    background-image:-o-linear-gradient(to bottom,#fff,#fbf2e0 10%,#e6c77c 47%,#e6c77c 53%,#fbf2e0 90%,#fff);
    background-image:linear-gradient(to bottom,#fff,#fbf2e0 10%,#e6c77c 47%,#e6c77c 53%,#fbf2e0 90%,#fff);
    border:1px solid #9a7641;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    border-radius:50%;
    cursor:pointer
}
.bubbleButton:before,.bubbleButton:after,.bubbleButton>i:first-child{
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    border-radius:50%;
    box-sizing:border-box;
    width:auto;
    height:auto
}
.bubbleButton:before,.bubbleButton:after{
    content:"";
    position:absolute;
    top:7px;
    bottom:7px;
    left:7px;
    right:7px;
    width:auto;
    height:auto
}
.bubbleButton:before{
    background-image:-owg-linear-gradient(#e6c77c,#fff);
    background-image:-webkit-linear-gradient(#e6c77c,#fff);
    background-image:-moz-linear-gradient(#e6c77c,#fff);
    background-image:-o-linear-gradient(#e6c77c,#fff);
    background-image:linear-gradient(#e6c77c,#fff)
}
.bubbleButton:after{
    border:1px solid rgba(255,255,255,0.75);
    -webkit-box-shadow:inset 0 0 4px rgba(0,0,0,0.65);
    -moz-box-shadow:inset 0 0 4px rgba(0,0,0,0.65);
    box-shadow:inset 0 0 4px rgba(0,0,0,0.65);
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    border-radius:50%
}
.MSIE8 .bubbleButton:after{
    border:1px solid white
}
.bubbleButton>i:first-child{
    position:absolute;
    top:9px;
    bottom:9px;
    left:9px;
    right:9px;
    width:auto;
    height:auto
}
.bubbleButton:hover:after{
    -webkit-box-shadow:inset 0 0 12px 15px rgba(255,255,255,0.25);
    -moz-box-shadow:inset 0 0 12px 15px rgba(255,255,255,0.25);
    box-shadow:inset 0 0 12px 15px rgba(255,255,255,0.25)
}
.bubbleButton.hovered:after{
    -webkit-box-shadow:inset 0 0 22px 35px rgba(255,255,255,0.6);
    -moz-box-shadow:inset 0 0 22px 35px rgba(255,255,255,0.6);
    box-shadow:inset 0 0 22px 35px rgba(255,255,255,0.6)
}
.bubbleButton:active{
    background-image:-owg-linear-gradient(#463f39,#463f39 3%,#e6c77c 50%,#ebebeb);
    background-image:-webkit-linear-gradient(#463f39,#463f39 3%,#e6c77c 50%,#ebebeb);
    background-image:-moz-linear-gradient(#463f39,#463f39 3%,#e6c77c 50%,#ebebeb);
    background-image:-o-linear-gradient(#463f39,#463f39 3%,#e6c77c 50%,#ebebeb);
    background-image:linear-gradient(#463f39,#463f39 3%,#e6c77c 50%,#ebebeb)
}
.bubbleButton:active:after{
    -webkit-box-shadow:inset 0 0 12px 15px rgba(0,0,0,0.25);
    -moz-box-shadow:inset 0 0 12px 15px rgba(0,0,0,0.25);
    box-shadow:inset 0 0 12px 15px rgba(0,0,0,0.25);
    border:1px solid rgba(96,96,96,0.75)
}
.MSIE8 .bubbleButton:active:after{
    border:1px solid #606060
}
.bubbleButton.active{
    background-image:-owg-linear-gradient(to bottom,#f1ffc1,#bfd94d 45%,#a5c400 75%,#bfd94d);
    background-image:-webkit-linear-gradient(to bottom,#f1ffc1,#bfd94d 45%,#a5c400 75%,#bfd94d);
    background-image:-moz-linear-gradient(to bottom,#f1ffc1,#bfd94d 45%,#a5c400 75%,#bfd94d);
    background-image:-o-linear-gradient(to bottom,#f1ffc1,#bfd94d 45%,#a5c400 75%,#bfd94d);
    background-image:linear-gradient(to bottom,#f1ffc1,#bfd94d 45%,#a5c400 75%,#bfd94d)
}
.bubbleButton.active:after{
    border:1px solid #c9e271
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
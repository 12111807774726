import '@/assets/css/ltr.css';
//import 'tippy.js/themes/light.css';
//import 'tippy.js/themes/light-border.css';
//import 'tippy.js/themes/google.css';
//import 'tippy.js/themes/translucent.css';
import '@/assets/css/tippy/themes/iw.css';
//import '@/assets/css/reset.css';
import '@/main.scss';

import { createApp } from 'vue/dist/vue.esm-bundler';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import axios, { AxiosInstance } from 'axios';
import VueAxios from 'vue-axios';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { FontAwesomeIcon } from '@/assets/plugins/font-awesome';
import Toaster from '@meforma/vue-toaster';
import { vfmPlugin } from 'vue-final-modal';
import app_mixin from '@/mixin';
import lib from '@/lib';

//import { LayoutPlugin } from 'bootstrap-vue';
//import { VBScrollspyPlugin } from 'bootstrap-vue';
//import { DropdownPlugin, TablePlugin } from 'bootstrap-vue';
//import { BModal, VBModal } from 'bootstrap-vue';
//import VueDraggableResizable from 'vue-draggable-resizable';
//import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
//import VueNoty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';
//import VueCountdownTimer from 'vuejs-countdown-timer';
import VueTippy from 'vue-tippy';
import VueCountdown from '@chenfengyuan/vue-countdown';

//import('https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.11/lodash.min.js');
//import './assets/js/lodash-4.17.11.min.js';
import _ from 'lodash';

import '@/assets/js/date.format.js';
import '@/assets/js/scripts.js';

const app = createApp(App);
const metaManager = createMetaManager();

window['appInstance'] = null;
window['appComponent'] = null;
window['storeInstance'] = null;

app.config.globalProperties.$store = store;
//app.config.productionTip = false;
//app.config.debug = true;
//app.config.devtools = true;
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(store.state.app.i18n);
app.use(metaManager);
app.use(Toaster);
app.use(vfmPlugin);
//app.use(LayoutPlugin);
//app.use(CardPlugin);
//app.use(VBScrollspyPlugin);
//app.use(DropdownPlugin);
//app.use(TablePlugin);
//app.component('b-modal', BModal);
//app.directive('b-modal', VBModal);
//app.component('vue-draggable-resizable', VueDraggableResizable);
/*app.use(VueNoty, {
	layout: 'bottomRight',
	theme: 'semanticui',
	//closeWith: ['click', 'button'],
	timeout: false,
});*/
//app.use(VueCountdownTimer);
app.use(VueTippy, {
	directive: 'tippy', // => v-tippy
	component: 'tippy', // => <tippy/>
	componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
	defaultProps: {
		placement: 'auto-end',
		allowHTML: true,
		theme: 'iw',
		maxWidth: 400,
		arrow: true,
		delay: [200, 0],
		duration: 0,
		//appendTo: 'parent',
    },
	content: (reference) => reference.getAttribute('title') || reference.getAttribute('content'),
});
app.component(VueCountdown.name, VueCountdown);
app.component('font-awesome-icon', FontAwesomeIcon);
lib.addMixin(app_mixin);
app.mount('#app');

window['lib'] = lib;
window['axios'] = axios;

window['appInstance'] = app;
window['storeInstance'] = store;
//window['routerInstance'] = router;

// Запускаем скрипт проверки авторизации
setTimeout(() => {
	//storeInstance.state.auth.SSI_step1();
}, 100);

<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Скрытый тайник</span></span>
									<span class="level">
										(<span><span>Уровень 0</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span> В случае атаки здесь будет укрыта дань, которую затем можно сохранить за собой. Ты можешь перестроить это здание в сокровищницу, если ты король, вице-король или герцог.</span>
								</div>
								<img class="buildingHuge buildingType45 tribeId3 level6" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_45">
								<div class="buildingStatus location36 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">6</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
				<div ng-controller="buildingIndexCtrl" class="building buildingType45" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
	<!-- ngInclude:  --><div ng-include="" src="pageTemplate"><div class="treasury buildingDetails" ng-controller="hiddenTreasuryCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/buildingInformation.html'"><!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/buildingInformationEffects.html'"><!-- ngIf: currentEffect.values[0] != undefined
	 		&& (building.data.lvl > 0
	 		|| building.data.buildingType <= 4
	 		|| building.data.buildingType == 42) --><div class="contentBox gradient buildingEffect" ng-if="currentEffect.values[0] != undefined
	 		&amp;&amp; (building.data.lvl > 0
	 		|| building.data.buildingType <= 4
	 		|| building.data.buildingType == 42)">
	<h6 class="contentBoxHeader headerColored">
		<span translate="" options="45" data="lvl: , value: "><span>Емкость хранилища</span></span>
	</h6>
	<div class="contentBoxBody">
		<div class="current">
			<h6 class="headerTrapezoidal">
				<div class="content" translate=""><span>Сейчас</span></div>
			</h6>
			<div class="currentLevel" data="lvl: 6" translate=""><span>Уровень 6</span></div>
			<div class="value">
				600
			</div>
			<div class="unit" options="45" translate=""><span>Ресурсов</span></div>
		</div>
		<div class="nextLvl">
			<h6 class="headerTrapezoidal">
				<div class="content" translate=""><span>Следующие уровни</span></div>
			</h6>
			<!-- ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>7</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="45" data="value:700"><span>700 ресурсов</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>8</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="45" data="value:800"><span>800 ресурсов</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>9</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="45" data="value:900"><span>900 ресурсов</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>10</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="45" data="value:1000"><span>1000 ресурсов</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>11</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="45" data="value:1250"><span>1250 ресурсов</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>12</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="45" data="value:1500"><span>1500 ресурсов</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects -->
			<!-- ngIf: building.data.isMaxLvl && building.data.lvl == building.data.lvlMax -->
		</div>
	</div>
</div><!-- end ngIf: currentEffect.values[0] != undefined
	 		&& (building.data.lvl > 0
	 		|| building.data.buildingType <= 4
	 		|| building.data.buildingType == 42) --></div></div>
	<div class="contentBox gradient">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content" translate=""><span>Хранилище</span></div>
		</div>
		<div class="contentBoxBody">
			<p translate="" options="Normal"><span>Когда на твою деревню совершают набег, дань будет укрыта в скрытой сокровищнице. Как только атака пройдет, ты можешь забрать эти ресурсы себе.</span></p>
			<div class="floatWrapper costs hiddenTreasuryStorage">
				<!-- ngRepeat: resource in ['wood', 'clay', 'iron'] --><div class="resourceBig" ng-repeat="resource in ['wood', 'clay', 'iron']">
					<p><i class="unit_wood_large_illu"></i></p>
					<p class="resourceDescription">
						<span class="resource" ng-class="{notEnough: treasuryResources[$index+1] >= currentEffect.values[0]}">&#x202D;&#x202D;0&#x202C;&#x202C;</span>/600
					</p>
				</div><!-- end ngRepeat: resource in ['wood', 'clay', 'iron'] --><div class="resourceBig" ng-repeat="resource in ['wood', 'clay', 'iron']">
					<p><i class="unit_clay_large_illu"></i></p>
					<p class="resourceDescription">
						<span class="resource" ng-class="{notEnough: treasuryResources[$index+1] >= currentEffect.values[0]}">&#x202D;&#x202D;0&#x202C;&#x202C;</span>/600
					</p>
				</div><!-- end ngRepeat: resource in ['wood', 'clay', 'iron'] --><div class="resourceBig" ng-repeat="resource in ['wood', 'clay', 'iron']">
					<p><i class="unit_iron_large_illu"></i></p>
					<p class="resourceDescription">
						<span class="resource" ng-class="{notEnough: treasuryResources[$index+1] >= currentEffect.values[0]}">&#x202D;&#x202D;0&#x202C;&#x202C;</span>/600
					</p>
				</div><!-- end ngRepeat: resource in ['wood', 'clay', 'iron'] -->
				<div class="resourceBig">
					<p class="resourceControls"></p>
					<p>
						<!-- ngIf: treasuryResources[1] + treasuryResources[2] + treasuryResources[3] < 1 --><button clickable="claimResources()" class="button disabled clickable" ng-if="treasuryResources[1] + treasuryResources[2] + treasuryResources[3] < 1" content="Заявлять права не на что" v-tippy="{placement: 'top'}" tooltip-translate="HiddenTreasury.TooltipClaimNotPossible"><div class="content">
	<span ng-transclude="" class="innerText">
							<span translate=""><span>Забрать</span></span>
						</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: treasuryResources[1] + treasuryResources[2] + treasuryResources[3] < 1 -->
						<!-- ngIf: treasuryResources[1] + treasuryResources[2] + treasuryResources[3] >= 1 -->
					</p>
				</div>
			</div>
		</div>
	</div>
	<!-- ngIf: currentPlayer.isKingdomLeader() -->
	<!-- ngIf: currentPlayer.getCoronationStatus() == Player.CORONATION_STATUS.IN_CEREMONY && transformationFinished -->
	<!-- ngIf: !currentPlayer.isKingdomLeader() --><div ng-if="!currentPlayer.isKingdomLeader()" class="transform contentBox gradient">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content">
				<span translate=""><span>Восстановить</span></span>
			</div>
		</div>
		<div class="contentBoxBody">
			<div class="floatWrapper">
				<div class="activateInfo">
					<span translate=""><span>Тебе нужно быть королем, вице-королем или герцогом, если ты хочешь использовать это здание как сокровищницу.</span></span>
				</div>
				<div class="activateControl">
					<button class="button disabled" content="Невозможно" v-tippy="{placement: 'top'}" tooltip-translate="HiddenTreasury.TransformButtonNotPossible"><div class="content">
	<span ng-transclude="" class="innerText">
						<span translate=""><span>Активация</span></span>
					</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
				</div>
			</div>
		</div>
	</div><!-- end ngIf: !currentPlayer.isKingdomLeader() -->
</div></div>
</div>
			</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Скрытый тайник | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {},
}
</script>
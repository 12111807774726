<template>
	<div ng-include="" src="'tpl/productionOverview/partials/Resource.html'">
		<div class="productionContainer">
			<div class="productionPerHour">
				<table>
					<thead>
						<tr>
							<th translate=""><span>Ресурсное поле</span></th>
							<th translate=""><span>Производство</span></th>
							<th translate=""><span>Бонус</span></th>
						</tr>
					</thead>
					<tbody>
						<tr ng-repeat="field in resourceFields[tab]">
							<td>
								<span translate="" options="1"><span>Лесопилка</span></span>
								<span data="lvl: 1" translate=""><span>Уровень 1</span></span>
							</td>
							<td class="numberCell">&#x202D;&#x202D;5&#x202C;&#x202C;</td>
							<td class="numberCell">&#x202D;&#x202D;0&#x202C;&#x202C;</td>
						</tr>
						<tr ng-repeat="field in resourceFields[tab]">
							<td>
								<span translate="" options="1"><span>Лесопилка</span></span>
								<span data="lvl: 0" translate=""><span>Уровень 0</span></span>
							</td>
							<td class="numberCell">&#x202D;&#x202D;2&#x202C;&#x202C;</td>
							<td class="numberCell">&#x202D;&#x202D;0&#x202C;&#x202C;</td>
						</tr>
						<tr ng-repeat="field in resourceFields[tab]">
							<td>
								<span translate="" options="1"><span>Лесопилка</span></span>
								<span data="lvl: 1" translate=""><span>Уровень 1</span></span>
							</td>
							<td class="numberCell">&#x202D;&#x202D;5&#x202C;&#x202C;</td>
							<td class="numberCell">&#x202D;&#x202D;0&#x202C;&#x202C;</td>
						</tr>
						<tr ng-repeat="field in resourceFields[tab]">
							<td>
								<span translate="" options="1"><span>Лесопилка</span></span>
								<span data="lvl: 1" translate=""><span>Уровень 1</span></span>
							</td>
							<td class="numberCell">&#x202D;&#x202D;5&#x202C;&#x202C;</td>
							<td class="numberCell">&#x202D;&#x202D;0&#x202C;&#x202C;</td>
						</tr>
						<tr class="sumCell">
							<td translate=""><span>Сумма</span></td>
							<td class="numberCell">&#x202D;&#x202D;17&#x202C;&#x202C;</td>
							<td class="numberCell">&#x202D;&#x202D;0&#x202C;&#x202C;</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="productionBoost contentBox">
				<h6 class="contentBoxHeader headerTrapezoidal">
					<div class="content" translate=""><span>Бонус</span></div>
				</h6>
				<div class="contentBoxBody">
					<div class="arrowContainer arrowDirectionTo" ng-repeat="bonus in buildingBoni[tab]">
						<span class="arrowInside">
							<span translate="" options="5"><span>Пилорама</span></span><br>
							<span translate="" data="lvl: 0"><span>Уровень 0</span></span>
						</span>
						<span class="arrowOutside">
							&#x202D;&#x202D;0&#x202C;%&#x202C;
						</span>
					</div>
					<div class="arrowContainer arrowDirectionTo">
						<span class="arrowInside">
							<span translate=""><span>Оазис/Оазисы</span></span><br>
							<!-- ngIf: oasis[tab].number > 0 -->
							<span translate="" ng-if="oasis[tab].number == 0"><span>нет оазиса</span></span>
						</span>
						<span class="arrowOutside">
							&#x202D;&#x202D;0&#x202C;%&#x202C;
						</span>
					</div>
					<div class="bonusSum">
						<span translate=""><span>Сумма</span></span>
						<div>&#x202D;&#x202D;0&#x202C;%&#x202C;</div>
					</div>
					<div class="indicationArrow"></div>
				</div>
			</div>
		</div>
		<div class="contentBox totalProduction">
			<div class="contentBoxBody">
				<div class="contentBox calculation transparent">
					<h6 class="contentBoxHeader headerTrapezoidal">
						<div class="content" translate=""><span>Общее производство</span></div>
					</h6>
					<div class="contentBoxBody">
						<table class="rowOnly">
							<tbody>
								<tr class="production">
									<td translate=""><span>Производство</span></td>
									<td class="numberCell">&#x202D;&#x202D;17&#x202C;&#x202C;</td>
								</tr>
								<tr class="bonus">
									<td translate=""><span>Бонус</span></td>
									<td class="numberCell">&#x202D;&#x202D;0&#x202C;&#x202C;</td>
								</tr>
								<!-- ngIf: player.isKingdomLeader() && tab == 'Crop' -->
								<tr class="heroProduction">
									<td translate=""><span>Производство героя</span></td>
									<td class="numberCell">&#x202D;&#x202D;20&#x202C;&#x202C;</td>
								</tr>
								<tr class="oasisTroopProduction">
									<td translate=""><span>Производство войск в оазисе</span></td>
									<td class="numberCell">&#x202D;&#x202D;0&#x202C;&#x202C;</td>
								</tr>
								<tr class="interimBalance">
									<td translate=""><span>Промежуточный баланс =</span></td>
									<td class="numberCell">&#x202D;&#x202D;37&#x202C;&#x202C;</td>
								</tr>
								<tr class="goldBonusProduction">
									<td data="percentage: &#x202D;+&#x202D;25&#x202C;%&#x202C;" translate=""><span>&#x202D;+&#x202D;25&#x202C;%&#x202C; производства</span></td>
									<td class="numberCell">&#x202D;&#x202D;0&#x202C;&#x202C;</td>
								</tr>
								<!-- ngIf: vacationProduction[tab] -->
								<tr class="total">
									<td class="" translate=""><span>Всего</span></td>
									<td class="numberCell">&#x202D;&#x202D;37&#x202C;&#x202C;</td>
								</tr>
							</tbody>
						</table>
						<div class="verticalLine"><div class="indicationArrow"></div></div>
					</div>
				</div>
				<div class="contentBox premiumBonus transparent">
					<h6 class="contentBoxHeader headerTrapezoidal">
						<div class="content" translate=""><span>Бонус Plus</span></div>
					</h6>
					<div class="contentBoxBody">
						<div class="title" data="bonus: &#x202D;+&#x202D;25&#x202C;%&#x202C;" translate=""><span>Без&nbsp;общего бонуса&nbsp;&#x202D;+&#x202D;25&#x202C;%&#x202C;:</span></div>
						37 <span options="1" translate=""><span>древесины в час</span></span>
						<div class="horizontalLine"></div>
						<div class="title" data="bonus: &#x202D;+&#x202D;25&#x202C;%&#x202C;" translate=""><span>С&nbsp;общим бонусом&nbsp;&#x202D;+&#x202D;25&#x202C;%&#x202C;:</span></div>
						<div>46 <span options="1" translate=""><span>древесины в час</span></span></div>

						<!-- ngIf: productionBonusTime[tab] > 0 -->
						<button ng-if="productionBonusTime[tab] == 0" class="premium" premium-feature="productionBonus">
							<div class="content">
								<span ng-transclude="" class="innerText">
									<span translate=""><span>Активация</span></span>
								</span>
								<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>20</span>
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="shopSteps transparent">
		<div ng-repeat="step in shopSteps" class="step">
			<a class="stepLink clickable done" clickable="gotoStep(step)" options="1" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}">
				<span>Выбор пакета</span>
			</a>
		</div>
		<div ng-repeat="step in shopSteps" class="step">
			<a class="stepLink clickable disabled active" clickable="gotoStep(step)" options="1" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}">
				<span>Выбор способа оплаты</span>
			</a>
		</div>
		<div ng-repeat="step in shopSteps" class="step">
			<a class="stepLink clickable disabled" clickable="gotoStep(step)" options="1" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}">
				<span>Оплата</span>
			</a>
		</div>
		<div ng-repeat="step in shopSteps" class="step">
			<a class="stepLink clickable disabled" clickable="gotoStep(step)" options="1" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}">
				<span>Покупка подтверждена</span>
			</a>
		</div>
	</div>
	<!--
	<table class="shopSteps transparent">
		<tbody>
			<tr>
				<td ng-repeat="step in shopSteps" class="step">
					<a class="stepLink clickable done" clickable="gotoStep(step)" options="1" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}">
						<span>Выбор пакета</span>
					</a>
				</td>
				<td ng-repeat="step in shopSteps" class="step">
					<a class="stepLink clickable disabled active" clickable="gotoStep(step)" options="2" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}">
						<span>Выбор способа оплаты</span>
					</a>
				</td>
				<td ng-repeat="step in shopSteps" class="step">
					<a class="stepLink clickable disabled" clickable="gotoStep(step)" options="3" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}">
						<span>Оплата</span>
					</a>
				</td>
				<td ng-repeat="step in shopSteps" class="step">
					<a class="stepLink clickable disabled" clickable="gotoStep(step)" options="4" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}">
						<span>Покупка подтверждена</span>
					</a>
				</td>
			</tr>
		</tbody>
	</table>
	-->
	<div class="goldPackages" ng-class="{shopLoading: (!goldPackages || goldPackages.length == 0) && !shopIsUnavailable}" ng-init="!goldPackages && initShop()">
	<carousel ng-hide="externalReseller" carousel-template="tpl/shop/partials/package.html" carousel-items="goldPackages" carousel-item-primary-key="productId" carousel-rows="2" carousel-cols="3" carousel-callback="selectProduct" carousel-preselect-first="false" carousel-arrow-class="greenArrow" class="col_3 rows_2 arrowsHidden"><div class="carousel" style="">
	<div class="pages ng-hide firstPage lastPage" ng-show="carousel.pages.length > 1" ng-class="{firstPage: carousel.currentPage == 1, lastPage: carousel.currentPage == carousel.pages.length}">
		<div class="greenArrow arrowDirectionFrom" clickable="carousel.previousPage()">
			<!-- ngIf: !carouselArrowClass -->
		</div>
		<!-- ngRepeat: page in carousel.pages --><div class="page clickable active" ng-repeat="page in carousel.pages" clickable="carousel.goToPage(page)" ng-class="{active: page === carousel.currentPage}"></div><!-- end ngRepeat: page in carousel.pages -->
		<div class="greenArrow arrowDirectionTo" clickable="carousel.nextPage()">
			<!-- ngIf: !carouselArrowClass -->
		</div>
	</div>
	<div class="itemContainer">
		<div class="items" style="width: 624px;"><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-class="{selected: item.selected}">
	<div class="premiumPackage  selectable" clickable="carousel.setActiveItem(item); carouselCallback(item);">
		<div class="topDecoration"></div>
		<div class="packageContent">
			<div class="packageContentBox">
				<div class="packageImageBackground"></div>
				<div class="packageLabel doubleLabel">
					<div class="labelText goldValue"><!-- ngIf: ::item.originalGold --> <div>35</div></div>
					<div class="labelText priceValue">1.69 €</div>
					<!-- ngIf: ::item.saving && !item.promotionPercentage -->
					<!-- ngIf: ::item.promotionPercentage -->
				</div>
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_bankPackage1_huge_illu"></div>
</div><!-- end ngIf: item --></div><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-class="{selected: item.selected}">
	<div class="premiumPackage  selectable" clickable="carousel.setActiveItem(item); carouselCallback(item);">
		<div class="topDecoration"></div>
		<div class="packageContent">
			<div class="packageContentBox">
				<div class="packageImageBackground"></div>
				<div class="packageLabel doubleLabel">
					<div class="labelText goldValue"><!-- ngIf: ::item.originalGold --> <div>120</div></div>
					<div class="labelText priceValue">3.99 €</div>
					<!-- ngIf: ::item.saving && !item.promotionPercentage --><div class="saving" ng-if="::item.saving && !item.promotionPercentage">+45%</div><!-- end ngIf: ::item.saving && !item.promotionPercentage -->
					<!-- ngIf: ::item.promotionPercentage -->
				</div>
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_bankPackage2_huge_illu"></div>
</div><!-- end ngIf: item --></div><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-class="{selected: item.selected}">
	<div class="premiumPackage  selectable" clickable="carousel.setActiveItem(item); carouselCallback(item);">
		<div class="topDecoration"></div>
		<div class="packageContent">
			<div class="packageContentBox">
				<div class="packageImageBackground"></div>
				<div class="packageLabel doubleLabel">
					<div class="labelText goldValue"><!-- ngIf: ::item.originalGold --> <div>300</div></div>
					<div class="labelText priceValue">7.99 €</div>
					<!-- ngIf: ::item.saving && !item.promotionPercentage --><div class="saving" ng-if="::item.saving && !item.promotionPercentage">+81%</div><!-- end ngIf: ::item.saving && !item.promotionPercentage -->
					<!-- ngIf: ::item.promotionPercentage -->
				</div>
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_bankPackage3_huge_illu"></div>
</div><!-- end ngIf: item --></div><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-class="{selected: item.selected}">
	<div class="premiumPackage tkTopSeller selectable" clickable="carousel.setActiveItem(item); carouselCallback(item);">
		<div class="topDecoration"></div>
		<div class="packageContent">
			<div class="packageContentBox">
				<div class="packageImageBackground"></div>
				<div class="packageLabel doubleLabel">
					<div class="labelText goldValue"><!-- ngIf: ::item.originalGold --> <div>700</div></div>
					<div class="labelText priceValue">14.99 €</div>
					<!-- ngIf: ::item.saving && !item.promotionPercentage --><div class="saving" ng-if="::item.saving && !item.promotionPercentage">+125%</div><!-- end ngIf: ::item.saving && !item.promotionPercentage -->
					<!-- ngIf: ::item.promotionPercentage -->
				</div>
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_bankPackage4_huge_illu"></div>
</div><!-- end ngIf: item --></div><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-class="{selected: item.selected}">
	<div class="premiumPackage  selectable" clickable="carousel.setActiveItem(item); carouselCallback(item);">
		<div class="topDecoration"></div>
		<div class="packageContent">
			<div class="packageContentBox">
				<div class="packageImageBackground"></div>
				<div class="packageLabel doubleLabel">
					<div class="labelText goldValue"><!-- ngIf: ::item.originalGold --> <div>1800</div></div>
					<div class="labelText priceValue">34.99 €</div>
					<!-- ngIf: ::item.saving && !item.promotionPercentage --><div class="saving" ng-if="::item.saving && !item.promotionPercentage">+148%</div><!-- end ngIf: ::item.saving && !item.promotionPercentage -->
					<!-- ngIf: ::item.promotionPercentage -->
				</div>
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_bankPackage5_huge_illu"></div>
</div><!-- end ngIf: item --></div><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-class="{selected: item.selected}">
	<div class="premiumPackage tkBestValue selectable" clickable="carousel.setActiveItem(item); carouselCallback(item);">
		<div class="topDecoration"></div>
		<div class="packageContent">
			<div class="packageContentBox">
				<div class="packageImageBackground"></div>
				<div class="packageLabel doubleLabel">
					<div class="labelText goldValue"><!-- ngIf: ::item.originalGold --> <div>3500</div></div>
					<div class="labelText priceValue">75.99 €</div>
					<!-- ngIf: ::item.saving && !item.promotionPercentage --><div class="saving" ng-if="::item.saving && !item.promotionPercentage">+122%</div><!-- end ngIf: ::item.saving && !item.promotionPercentage -->
					<!-- ngIf: ::item.promotionPercentage -->
				</div>
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_bankPackage6_huge_illu"></div>
</div><!-- end ngIf: item --></div></div>
	</div>
</div></carousel>
<div ng-if="externalReseller" class="externalReseller">
		<div class="column">
			<p><i class="shop_advertisement_illu"></i></p>
			<a clickable="openReseller()" class="clickable">
				<i class="shop_advertisement_RUS_illu"></i>
			</a>
		</div>
		<div class="column">
			<div class="header" options="RUS"><span>Реклама</span></div>
			<div class="description" options="RUS" data="resellerLink:openReseller()"><span>В настоящее время покупка золота на нашем сайте для вашего региона невозможна. Но вы можете купить купоны на золото в магазине нашего партнера.</span><br><span>Купоны на получение игрового золота можно приобрести в магазине </span><strong><a clickable="openReseller()" class="clickable">montybella.codes</a></strong><span> *</span></div>
			<div class="footnote" options="RUS"><span>* Нажмите, чтобы перейти в магазин вне игры</span></div>
		</div>
	</div>
	<!-- ngIf: goldPackages && !selectedProduct --><a class="goldFeatures clickable" ng-if="goldPackages && !selectedProduct" clickable="openOverlay('goldFeatures')"><span>Функции золота</span></a><!-- end ngIf: goldPackages && !selectedProduct --><!-- end ngIf: goldPackages && !selectedProduct -->
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
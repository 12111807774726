<template>
	<div ng-if="overlayController" class="windowOverlay" id="useResourceBonus" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="639"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Hero.UseResourceBonus" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Получить бонус</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="useItem useResourceItem">
	<!-- ngIf: screenState == 'UseItem' || bonusValue >= 5 || item.data.amount <= 1 -->

	<!-- ngIf: screenState == 'MergeItem' && bonusValue < 5 && item.data.amount > 1 --><div class="contentBox mergeItem" ng-if="screenState == 'MergeItem' &amp;&amp; bonusValue < 5 &amp;&amp; item.data.amount > 1">
		<div class="contentBoxHeader headerWithArrowDown">
			<span translate=""><span>Объединить бонусы ресурсов</span></span>
		</div>
		<div class="contentBoxBody">
			<div class="itemContainer">
				<hero-item-container item="item" hide-amount="true" hide-item-states="true"><div class="entityBox item item_133 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:54584,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:false,itemType:133" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_cropChest3_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
				<div class="verticalLine"></div>
				<div class="description" translate="" data="amount:3, newAmount:4"><span>Выбери, сколько именно бонусов ресурсов ты хочешь объединить. Два&nbsp;бонуса по &#x202D;&#x202D;3&#x202C;%&#x202C; будут объединены в один бонус &#x202D;&#x202D;4&#x202C;%&#x202C;.</span></div>
			</div>
			<div class="horizontalLine"></div>
			<div class="calculationContainer">
				<div class="itemWrapper">
					<hero-item-container item="item" hide-amount="true" hide-item-states="true"><div class="entityBox item item_133 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:54584,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:false,itemType:133" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_cropChest3_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
					<span class="resourceValue" ng-bind-html="(useAmount.value / 2) | bidiRatio : (useAmount.value / 2) : item.data.amount">&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;4&#x202C;&#x202C;&#x202C;&#x202C;</span>
				</div>
				<i class="plusCircled"></i>
				<div class="itemWrapper">
					<hero-item-container item="item" hide-amount="true" hide-item-states="true"><div class="entityBox item item_133 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:54584,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:false,itemType:133" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_cropChest3_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
					<span class="resourceValue" ng-bind-html="(useAmount.value / 2) | bidiRatio : (useAmount.value / 2) : item.data.amount">&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;4&#x202C;&#x202C;&#x202C;&#x202C;</span>
				</div>
				<i class="equalCircled"></i>
				<div class="itemWrapper">
					<hero-item-container item="item" hide-amount="true" hide-item-states="true" different-item-image="1" display-next-upgrade="true"><div class="entityBox item item_133 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:54584,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:true,itemType:133" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_cropChest4_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
					<span class="resourceValue">1</span>
				</div>
			</div>
		</div>
		<div class="contentBoxFooter">
			<div class="sliderArrow"></div>
			<slider slider-min="2" slider-max="item.data.amount" slider-steps="2" hide-steps="true" slider-data="useAmount"><div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="" style="pointer-events: auto; cursor: default;">
	<div class="trackContainer">
		<div class="track">
			<!-- ngIf: stepCount && !hideSteps -->
			<div class="sliderMarker progressMarker" style="width: 50%;"></div>
			<!-- ngRepeat: marker in sliderMarkers track by $index -->
			<div class="hoverIndicator" style="display: none; left: 222px; opacity: 1;">
				<div class="hoverValue">2</div>
				<div class="indicator"></div>
			</div>
			<div class="handle unselectable" style="left: 192.5px;"></div>
		</div>
	</div>
	<div class="inputContainer">
		<!-- ngIf: iconClass -->
		<input class="value ng-pristine ng-valid ng-touched" number="" ng-model="value" type="tel">
		<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: block;">
			<i class="action_setMax_small_flat_black"></i>
		</div>
	</div>
</div></slider>
		</div>
	</div><!-- end ngIf: screenState == 'MergeItem' && bonusValue < 5 && item.data.amount > 1 -->
	<div class="buttonContainer">
		<!-- ngIf: screenState == 'MergeItem' --><button class="useItemButton clickable" clickable="mergeItem()" ng-if="screenState == 'MergeItem'"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Объединить</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: screenState == 'MergeItem' -->
		<button clickable="closeOverlay()" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Отмена</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
	</div>
</div>
</div>
	</div>
</div></div>
</div>
	
	
	
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
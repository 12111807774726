<template>
	<div ng-controller="statsWorldCtrl" class="statsWorld">
		<div class="contentBox gradient">
			<div class="contentBoxHeader headerWithArrowEndings">
				<span translate="" class="content"><span>Игроки</span></span>
			</div>
			<div class="contentBoxBody">
				<table class="worldStatsTable transparent">
					<tbody>
						<tr>
							<th translate=""><span>Зарегистрированные игроки</span></th>
							<td>527</td>
						</tr>
						<tr>
							<th translate=""><span>Активные игроки</span></th>
							<td>339</td>
						</tr>
						<tr>
							<th translate=""><span>Игроков в сети</span></th>
							<td>117</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="contentBox gradient pieCharts">
			<div class="contentBoxHeader headerWithArrowEndings glorious">
				<div class="content">
					<span translate=""><span>Короли и губернаторы</span></span>
				</div>
			</div>
			<div class="contentBoxHeader headerWithArrowEndings">
				<div class="content">
					<span translate=""><span>Народы</span></span>
				</div>
			</div>
			<div class="contentBoxBody">
				<pie-chart class="pieChart kingdomChart" data="kingdomPieData" percent-in-pie="true" style="padding: 0px; position: relative;"><canvas class="flot-base" style="direction: ltr; position: absolute; left: 0px; top: 0px; width: 100px; height: 100px;" width="100" height="100"></canvas><canvas class="flot-overlay" style="direction: ltr; position: absolute; left: 0px; top: 0px; width: 100px; height: 100px;" width="100" height="100"></canvas><div class="legend"><div style="position: absolute; width: 106px; height: 123px; top: 5px; right: 5px; background-color: transparent; opacity: 0.85;"> </div><table style="position:absolute;top:5px;right:5px;;font-size:smaller;color:#545454"><tbody><tr><td class="legendColorBox"><div style="border:1px solid none;padding:1px"><div style="width:4px;height:0;border:5px solid #ffe85c;overflow:hidden"></div></div></td><td class="legendLabel"><div>Короли<br>&#x202D;&#x202D;2&#x202C;%&#x202C; (12)</div></td></tr><tr><td class="legendColorBox"><div style="border:1px solid none;padding:1px"><div style="width:4px;height:0;border:5px solid #a5c400;overflow:hidden"></div></div></td><td class="legendLabel"><div>Герцоги<br>&#x202D;&#x202D;3&#x202C;%&#x202C; (18)</div></td></tr><tr><td class="legendColorBox"><div style="border:1px solid none;padding:1px"><div style="width:4px;height:0;border:5px solid #c9e271;overflow:hidden"></div></div></td><td class="legendLabel"><div>Губернатор<br>&#x202D;&#x202D;94&#x202C;%&#x202C; (496)</div></td></tr></tbody></table></div><span class="pieLabel" id="pieLabel0" style="position: absolute; top: 12px; left: 44.5px;"><div>&#x202D;&#x202D;2&#x202C;%&#x202C;</div></span><span class="pieLabel" id="pieLabel1" style="position: absolute; top: 13px; left: 49.5px;"><div>&#x202D;&#x202D;3&#x202C;%&#x202C;</div></span><span class="pieLabel" id="pieLabel2" style="position: absolute; top: 72px; left: 34.5px;"><div>&#x202D;&#x202D;94&#x202C;%&#x202C;</div></span></pie-chart>
				<pie-chart class="pieChart tribeChart" data="tribePieData" percent-in-pie="true" style="padding: 0px; position: relative;"><canvas class="flot-base" style="direction: ltr; position: absolute; left: 0px; top: 0px; width: 100px; height: 100px;" width="100" height="100"></canvas><canvas class="flot-overlay" style="direction: ltr; position: absolute; left: 0px; top: 0px; width: 100px; height: 100px;" width="100" height="100"></canvas><div class="legend"><div style="position: absolute; width: 98px; height: 131px; top: 5px; right: 5px; background-color: transparent; opacity: 0.85;"> </div><table style="position:absolute;top:5px;right:5px;;font-size:smaller;color:#545454"><tbody><tr><td class="legendColorBox"><div style="border:1px solid none;padding:1px"><div style="width:4px;height:0;border:5px solid #c8844d;overflow:hidden"></div></div></td><td class="legendLabel"><div>Римляне<br>&#x202D;&#x202D;24&#x202C;%&#x202C; (127)</div></td></tr><tr><td class="legendColorBox"><div style="border:1px solid none;padding:1px"><div style="width:4px;height:0;border:5px solid #bc6a27;overflow:hidden"></div></div></td><td class="legendLabel"><div>Германцы<br>&#x202D;&#x202D;14&#x202C;%&#x202C; (72)</div></td></tr><tr><td class="legendColorBox"><div style="border:1px solid none;padding:1px"><div style="width:4px;height:0;border:5px solid #b05001;overflow:hidden"></div></div></td><td class="legendLabel"><div>Галлы<br>&#x202D;&#x202D;62&#x202C;%&#x202C; (328)</div></td></tr></tbody></table></div><span class="pieLabel" id="pieLabel0" style="position: absolute; top: 20px; left: 60.5px;"><div>&#x202D;&#x202D;24&#x202C;%&#x202C;</div></span><span class="pieLabel" id="pieLabel1" style="position: absolute; top: 53px; left: 67.5px;"><div>&#x202D;&#x202D;14&#x202C;%&#x202C;</div></span><span class="pieLabel" id="pieLabel2" style="position: absolute; top: 53px; left: 11.5px;"><div>&#x202D;&#x202D;62&#x202C;%&#x202C;</div></span></pie-chart>
			</div>
		</div>
		<div class="contentBox gradient">
			<div class="contentBoxHeader headerWithArrowEndings">
				<span translate="" class="content"><span>Игровой мир</span></span>
			</div>
			<div class="contentBoxBody">
				<table class="worldStatsTable transparent">
					<tbody><tr>
						<th translate=""><span>Начало игры</span></th>
						<td><span i18ndt="1704805200">09.01.2024</span></td>
					</tr>
					<tr>
						<th translate=""><span>Возраст игрового мира</span></th>
						<td>35</td>
					</tr>
					<tr>
						<th translate=""><span>Скорость</span></th>
						<td>1</td>
					</tr>
					<tr>
						<th translate=""><span>Скорость войск</span></th>
						<td>1</td>
					</tr>
				</tbody></table>
			</div>
		</div>
		<div class="contentBox gradient">
			<div class="contentBoxHeader headerWithArrowEndings">
				<span translate="" class="content"><span>Среднее количество войск на игрока</span></span>
			</div>
			<div class="contentBoxBody">
				<div ng-repeat="(tribeId, troopDetails) in troops" class="worldTroops">
					<div class="tribeCaption">
						<span><span translate="" options="1"><span>Римляне</span></span>:</span>
					</div>
					<div troops-details="" troop-data="troopDetails">
						<table class="troopsTable roman">
							<tbody class="troopsIconRow">
								<tr>
									<td ng-repeat="n in []|range:1:11" class="unit1">
										<i class="unitSmall roman unitType1" unit-icon="" data="1" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit2">
										<i class="unitSmall roman unitType2" unit-icon="" data="2" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit3">
										<i class="unitSmall roman unitType3" unit-icon="" data="3" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit4">
										<i class="unitSmall roman unitType4" unit-icon="" data="4" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit5">
										<i class="unitSmall roman unitType5" unit-icon="" data="5" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit6">
										<i class="unitSmall roman unitType6" unit-icon="" data="6" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit7">
										<i class="unitSmall roman unitType7" unit-icon="" data="7" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit8">
										<i class="unitSmall roman unitType8" unit-icon="" data="8" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit9">
										<i class="unitSmall roman unitType9 inactive" unit-icon="" data="9" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit10">
										<i class="unitSmall roman unitType10 inactive" unit-icon="" data="10" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="hero">
										<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
								</tr>
							</tbody>
							<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
								<!-- ngIf: troopsType == 'trapped' -->
								<tr>
									<td ng-repeat="n in [] | range:1:11" class="unit1">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;197&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit2">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;195&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit3">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;157&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit4">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;38&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit5">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;113&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit6">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;32&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit7">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;5&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit8">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;6&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit9">
										<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit10">
										<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="hero">
										<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
										<!-- ngIf: troopsType == 'input' -->
									</td>
								</tr>
							</tbody>
							<!-- ngIf: troopData.supply -->
							<!-- ngIf: troopData.cagedAnimals -->
						</table>
					</div>
				</div>
				<div ng-repeat="(tribeId, troopDetails) in troops" class="worldTroops">
					<div class="tribeCaption">
						<span><span translate="" options="2"><span>Германцы</span></span>:</span>
					</div>
					<div troops-details="" troop-data="troopDetails">
						<table class="troopsTable teuton">
							<tbody class="troopsIconRow">
								<tr>
									<td ng-repeat="n in []|range:1:11" class="unit1">
										<i class="unitSmall teuton unitType1" unit-icon="" data="11" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit2">
										<i class="unitSmall teuton unitType2" unit-icon="" data="12" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit3">
										<i class="unitSmall teuton unitType3" unit-icon="" data="13" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit4">
										<i class="unitSmall teuton unitType4" unit-icon="" data="14" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit5">
										<i class="unitSmall teuton unitType5" unit-icon="" data="15" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit6">
										<i class="unitSmall teuton unitType6" unit-icon="" data="16" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit7">
										<i class="unitSmall teuton unitType7" unit-icon="" data="17" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit8">
										<i class="unitSmall teuton unitType8" unit-icon="" data="18" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit9">
										<i class="unitSmall teuton unitType9 inactive" unit-icon="" data="19" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit10">
										<i class="unitSmall teuton unitType10 inactive" unit-icon="" data="20" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="hero">
										<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
								</tr>
							</tbody>
							<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
								<!-- ngIf: troopsType == 'trapped' -->
								<tr>
									<td ng-repeat="n in [] | range:1:11" class="unit1">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;578&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit2">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;269&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit3">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;110&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit4">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;117&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit5">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;48&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit6">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;60&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit7">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;10&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit8">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;3&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit9">
										<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit10">
										<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="hero">
										<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
										<!-- ngIf: troopsType == 'input' -->
									</td>
								</tr>
							</tbody>
							<!-- ngIf: troopData.supply -->
							<!-- ngIf: troopData.cagedAnimals -->
						</table>
					</div>
				</div>
				<div ng-repeat="(tribeId, troopDetails) in troops" class="worldTroops">
					<div class="tribeCaption">
						<span><span translate="" options="3"><span>Галлы</span></span>:</span>
					</div>
					<div troops-details="" troop-data="troopDetails">
						<table class="troopsTable gaul">
							<tbody class="troopsIconRow">
								<tr>
									<td ng-repeat="n in []|range:1:11" class="unit1">
										<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit2">
										<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit3">
										<i class="unitSmall gaul unitType3" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit4">
										<i class="unitSmall gaul unitType4" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit5">
										<i class="unitSmall gaul unitType5" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit6">
										<i class="unitSmall gaul unitType6" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit7">
										<i class="unitSmall gaul unitType7" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit8">
										<i class="unitSmall gaul unitType8" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit9">
										<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="unit10">
										<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
									<td ng-repeat="n in []|range:1:11" class="hero">
										<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																		|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
															  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
										<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
										<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
									</td>
								</tr>
							</tbody>
							<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
								<!-- ngIf: troopsType == 'trapped' -->
								<tr>
									<td ng-repeat="n in [] | range:1:11" class="unit1">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;491&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit2">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;150&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit3">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;33&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit4">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;79&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit5">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;41&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit6">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;21&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit7">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;3&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit8">
										<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
											<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
											<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;2&#x202C;&#x202C;&#x202C;&#x202C;</span>
											<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
										</div>
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit9">
										<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="unit10">
										<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
										<!-- ngIf: troopsType == 'input' -->
									</td>
									<td ng-repeat="n in [] | range:1:11" class="hero">
										<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
										<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

										<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
										<!-- ngIf: troopsType == 'input' -->
									</td>
								</tr>
							</tbody>
							<!-- ngIf: troopData.supply -->
							<!-- ngIf: troopData.cagedAnimals -->
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
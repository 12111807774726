<template>
	<div>
		<div class="building buildingType15">
			<div>
				<div class="buildingDetails mainBuilding">
					<div class="editVillageName contentBox gradient">
						<h6 class="contentBoxHeader headerWithIcon arrowDirectionDown">
							<i class="village_village_medium_flat_black"></i>
							<div class="content"><span>Деревня</span></div>
						</h6>
						<div class="contentBoxBody">
							<input type="text" maxlength="20" class="ng-pristine ng-valid ng-valid-maxlength ng-touched" />
							<button class="animate clickable disabled disableAnimation">
								<div class="content">
									<span class="innerText">
										<span><span>Сохранить</span></span>
									</span>
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
					</div>
					<div class="foundTown contentBox gradient">
						<h6 class="contentBoxHeader headerWithIcon arrowDirectionDown">
							<i class="village_city_medium_flat_black"></i>
							<div class="content"><span>Основать город</span></div>
						</h6>
						<div class="contentBoxBody">
							<table class="townConditionTable transparent">
								<tbody>
									<tr>
										<th>
											<i class="unit_culturePoint_small_illu"></i>
											<span><span>Единицы культуры</span></span>
										</th>
										<th>
											<i class="unit_population_small_illu"></i>
											<span><span>Население</span></span>
										</th>
									</tr>
									<tr>
										<td>
											<span class="currentValue">102</span>
											<span><span>из</span></span>
											<span>1000</span>
										</td>
										<td>
											<span class="currentValue">91</span>
											<span><span>из</span></span>
											<span>500</span>
										</td>
									</tr>
								</tbody>
							</table>
							<table class="townUpgradeTable transparent">
								<tbody>
									<tr>
										<td class="doubleCol">
											<div class="buildingDescription">
												<span><span>Город даст следующие преимущества:</span></span>
												<br/><br/>
												<span>
													<ul class="uList">
														<li> +500 единиц культуры в день</li>
														<li> +500 жителей</li>
														<li>Возможность построить ров для защиты</li>
														<li>Возможность построить большие казарму и конюшню</li>
														<li>Повышенное одобрение 200% </li>
														<li>Возможность улучшать поля до 12 уровня</li>
													</ul>
												</span>
											</div>
										</td>
										<td>
											<button class="animate clickable disabled">
												<div class="content">
													<span class="innerText">
														<span><span>Основать город</span></span>
													</span>
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="contentBoxBody">
							<table class="townConditionTable transparent town" ng-class="{town: village.isTown}">
								<tbody>
								<tr>
									<!-- ngIf: !village.isTown -->
									<td ng-if="village.isTown" rowspan="2">
										<div translate=""><span>Ты основал город. Если его население упадет ниже 1000, он снова станет деревней.</span></div>
									</td>
									<th><i class="unit_population_small_illu"></i><span translate=""><span>Население</span></span></th>
								</tr>
								<tr>
									<!-- ngIf: !village.isTown -->
									<td>
										<span ng-class="{'green': village.population >= townPreconditions.population}" class="currentValue green">1033</span>
										<!-- ngIf: !village.isTown -->
									</td>
								</tr>
								</tbody>
							</table>
							<table class="townUpgradeTable transparent">
								<tbody>
									<tr>
										<td class="doubleCol">
											<div class="buildingDescription">
												<span translate="" ng-if="village.isTown"><span>Преимущества:</span></span>
												<!-- ngIf: !village.isTown -->
												<br><br>
												<span translate="" data="bonusCulturePoints:500,bonusPopulation:500,bonusAcceptance:200"><ul class="uList"><li> &#x202D;+&#x202D;500&#x202C;&#x202C; единиц культуры в день</li> <li> &#x202D;+&#x202D;500&#x202C;&#x202C; жителей</li> <li>Возможность построить ров для защиты</li> <li>Возможность построить большие казарму и конюшню</li> <li>Повышенное одобрение &#x202D;&#x202D;200&#x202C;%&#x202C; </li> <li>Возможность улучшать поля до 12 уровня</li></ul></span>
											</div>
										</td>
										<td>
											<button ng-class="{disabled: village.isTown || !mayUpgradeToTown()}" class="animate clickable disabled" clickable="openOverlay('townFounding')">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span translate=""><span>Основать город</span></span>
													</span>
													<!-- ngIf: price > 0 || forceGoldUsage -->
													<!-- ngIf: price == -1 && !forceGoldUsage -->
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
							<!-- ngIf: errorUpgrade -->
						</div>
					</div>
					<div class="demolish contentBox gradient">
						<h6 class="contentBoxHeader headerWithIcon arrowDirectionDown">
							<i class="action_demolish_medium_flat_black"></i>
							<div class="content"><span>Снести здание</span></div>
						</h6>
						<div class="contentBoxBody">
							<table class="demolishTable transparent">
								<tbody>
									<tr>
										<td class="doubleCol">
											<div class="condition">
												<span>Доступно с уровня 10</span>
											</div>
										</td>
										<td>
											<button class="clickable disabled">
												<div class="content">
													<span class="innerText">
														<span><span>Снести</span></span>
													</span>
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</td>
									</tr>
									<tr>
										<td class="doubleCol">
											<!-- ngIf: building.data.lvl < 10 -->
											<!-- ngIf: building.data.lvl >= 10 && demolishQueue.length <= 0 -->
											<div class="dropdownContainer" dropdown="" data="dropdown" ng-if="building.data.lvl >= 10 && demolishQueue.length <= 0">
												<select style="height: 24px; display: block;">
													<option>Выбери здание</option>
													<option value="19">19. Капканщик 9</option>
													<option value="20">20. Ратуша 1</option>
													<option value="21">21. Каменотес 10</option>
													<option value="22">22. Пекарня 3</option>
													<option value="23">23. Резиденция 16</option>
													<option value="24">24. Мельница 5</option>
													<option value="25">25. Амбар 17</option>
													<option value="26">26. Склад 17</option>
													<option value="27">27. Главное здание 13</option>
													<option value="28">28. Амбар 15</option>
													<option value="29">29. Казарма 5</option>
													<option value="30">30. Академия 15</option>
													<option value="31">31. Кузница 3</option>
													<option value="32">32. Пункт сбора 5</option>
													<option value="33">33. Изгородь 12</option>
													<option value="34">34. Конюшня 16</option>
													<option value="35">35. Посольство 5</option>
													<option value="37">37. Целебный шатер 1</option>
													<option value="38">38. Мастерская 3</option>
													<option value="39">39. Рынок 5</option>
													<option value="40">40. Тайник 1</option>
												</select>
												<!--
												<div class="dropdownHead openDownwards clickable closed" clickable="clickDropdown()">
													<span class="selectedItem truncated empty">Выбери здание</span>
													<i class="symbol_arrowDown_tiny_illu"></i>
												</div>
												<input class="dropdownInput" type="text" />
												-->
											</div>
											<!-- end ngIf: building.data.lvl >= 10 && demolishQueue.length <= 0 -->
											<!-- ngIf: building.data.lvl >= 10 && demolishQueue.length > 0 -->
										</td>
										<td>
											<button ng-if="demolishQueue.length <= 0" ng-class="{disabled: building.data.lvl < 10 || dropdown.selected == null || isSitter}" content="Выбери здание" v-tippy="{placement: 'top'}" tooltip-translate-switch="{
												'Sitter.DisabledAsSitter': false,
												'MainBuilding.Demolish.Condition': false,
												'ChoseBuilding': true
											}" clickable="demolish()" class="clickable disabled">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span>Снести</span>
													</span>
													<!-- ngIf: price > 0 || forceGoldUsage -->
													<!-- ngIf: price == -1 && !forceGoldUsage -->
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
											<!-- ngIf: demolishQueue.length > 0 && !isSitter && !isRubble -->
											<!-- ngIf: demolishQueue.length > 0 && !isSitter && isRubble -->
											<!-- ngIf: demolishQueue.length > 0 && isSitter -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
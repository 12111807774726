import axios from 'axios';
import lib from '@/lib';

export default class ReportsModel
{
	static REPORT_RELOCATION = 141; // переселение в случае приглашения в дальнее королевство
	static REPORT_FARMLIST_RAID = 115;
	static REPORT_ADVENTURE = 21;
	static REPORT_WORLD_PEACE = '???';
	static REPORT_VACATION = '???';
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.showReportShare = false;
		this.showReportInfo = false;
		this.selectedReport = null;
		
		// не хватает отчетов с notificationType:
		//3: 'Поражение в атаке',
		//16: 'Разведывательная операция прошла успешно, но была раскрыта',
		//17: 'Разведка провалилась',
		//22: 'Отчет о визите', // ???
		//37: 'Твои войска были освобождены!',
		//60: '', // иконка свертка письма с текстом SO
		//61: '', // иконка свертка письма с текстом SK
		//62: '', // иконка свертка письма с текстом SA
		//65: '', // иконка руки, дающей серебро
		//66: '', // иконка смены корон
		//117: 'Отчет о визите', // ??? иконка с оазисом
		//119: 'Атаки по списку целей с потерями завершены',
		//120: 'Атаки по списку целей без выживших завершены',
		//125: 'Атаки по списку целей с потерями',
		//126: 'Атаки по списку целей без выживших',
		//141: 'Перемещение прошло успешно',
		
		this.notificationTypes = {
			// отчеты об атаке
			1: 'Победа в атаке без потерь',
			2: 'Победа в атаке с потерями',
			3: 'Поражение в атаке',
			REPORT_FARMLIST_RAID: 'Атаки по списку целей в процессе',
			118: 'Атаки по списку целей без потерь завершены',
			119: 'Атаки по списку целей с потерями завершены',
			120: 'Атаки по списку целей без выживших завершены',
			124: 'Атаки по списку целей без потерь',
			125: 'Атаки по списку целей с потерями',
			126: 'Атаки по списку целей без выживших',
			
			// отчеты о защите
			4: 'Победа в защите без потерь',
			5: 'Победа в защите с потерями',
			6: 'Поражение в защите с потерями',
			7: 'Поражение в защите без потерь',
			
			// отчеты о своей разведке
			15: 'Разведывательная операция прошла успешно и не была раскрыта',
			16: 'Разведывательная операция прошла успешно, но была раскрыта',
			17: 'Разведка провалилась',
			
			// обнаруженная разведка
			18: 'Разведывательная операция успешно отражена',
			19: 'Разведывательную операцию отразить не удалось',
			
			// опубликованные отчеты
			60: '', // иконка свертка письма с текстом SO
			61: '', // иконка свертка письма с текстом SK
			62: '', // иконка свертка письма с текстом SA
			63: 'Отчеты, которыми поделились с тайным сообществом', // иконка свертка письма с текстом SS
			64: 'Отчеты, которыми поделились с моим королевством',
			
			// различные отчеты
			REPORT_REPORT_ADVENTURE: 'Отчет о приключении',
			10: 'Торговцы доставили ресурсы',
			11: 'Торговцы доставили преимущественно древесину',
			12: 'Торговцы доставили преимущественно глину',
			13: 'Торговцы доставили преимущественно железо',
			14: 'Торговцы доставили преимущественно зерно',
			8: 'Подкрепление прибыло',
			20: 'Были пойманы животные',
			37: 'Твои войска были освобождены!',
			22: 'Отчет о визите', // ???
			117: 'Отчет о визите', // ??? иконка с оазисом
			23: 'Престиж за неделю',
			REPORT_RELOCATION: 'Перемещение прошло успешно',
			
			// неизвестные отчеты
			65: '', // иконка руки, дающей серебро
			66: '', // иконка смены корон
		};
		
		this.list = [
			
			//secretSociety
			{
				//id: 1,
				name: 'Атака 1. Дерёвня на 2_Промка',
				reportType: 'fightReport',
				notificationType: 63, // отчеты, которыми поделились с тайным сообществом
				displayType: 4,
				successType: 1, // зеленый
				sourceId: 536592383,
				sourceName: "Дерёвня",
				sourcePlayerId: 353,
				sourcePlayerName: "Мао",
				sourceKingdomId: "3",
				sourceKingdomTag: "MИР",
				sourceTribeId: "3",
				destId: 536920061,
				destType: 0,
				destName: "Промка",
				destPlayerId: 1521,
				destPlayerName: "Sand68",
				destKingdomId: "59",
				destKingdomTag: "DOKA2",
				destTribeId: "1",
				destX: "-3",
				destY: "1",
				ownRole: "attacker",
				attackType: "3",
				troopId: 1588142,
				capacity: 0,
				bounty: 0,
				otherKingdomId: "59",
				playerId: 10,
				time: 1720605510,
				attackerTroopSum: 425,
				attackerTroopLossSum: 425,
				defenderTroopSum: 1757,
				defenderTroopLossSum: 477,
				sharedBy: 353,
				sharedByName: "Мао",
				shareMessage: "",
				originalTime: 1720605248,
			},
			{
				//id: 1,
				name: 'Атака 1. Snickers на Симпампушка 3',
				reportType: 'fightReport',
				notificationType: 63, // отчеты, которыми поделились с тайным сообществом
				displayType: 4,
				successType: 1, // зеленый
				sourceId: 536428535,
				sourceName: "1. Snickers",
				sourcePlayerId: 296,
				sourcePlayerName: "snickers",
				sourceKingdomId: "10",
				sourceKingdomTag: "ОоО",
				sourceTribeId: "3",
				destId: 537018366,
				destType: 0,
				destName: "Симпампушка 3",
				destPlayerId: 294,
				destPlayerName: "Симпампушка",
				destKingdomId: "41",
				destKingdomTag: "DOTA2",
				destTribeId: "3",
				destX: "-2",
				destY: "4",
				ownRole: "attacker",
				attackType: "3",
				troopId: 1403077,
				capacity: 272700,
				bounty: 0,
				otherKingdomId: "41",
				playerId: 10,
				time: 1720369067,
				attackerTroopSum: 5027,
				attackerTroopLossSum: 0,
				defenderTroopSum: 0,
				defenderTroopLossSum: 0,
				sharedBy: 296,
				sharedByName: "snickers",
				shareMessage: "",
				originalTime: 1720368022,
			},
			{
				//id: 1,
				name: 'Атака Lobster 48 на www',
				reportType: 'fightReport',
				notificationType: 63, // отчеты, которыми поделились с тайным сообществом
				displayType: 4,
				successType: 1, // зеленый
				sourceId: 537411592,
				sourceName: "Lobster 48",
				sourcePlayerId: 410,
				sourcePlayerName: "Lobster 48",
				sourceKingdomId: "3",
				sourceKingdomTag: "MИР",
				sourceTribeId: "1",
				destId: 537444355,
				destType: 1,
				destName: "www",
				destPlayerId: 1747,
				destPlayerName: "www",
				destKingdomId: "0",
				destKingdomTag: "",
				destTribeId: "1",
				destX: "3",
				destY: "17",
				ownRole: "attacker",
				attackType: "3",
				troopId: 1356725,
				capacity: 59300,
				bounty: 1722,
				otherKingdomId: "0",
				playerId: 10,
				time: 1720285040,
				attackerTroopSum: 1237,
				attackerTroopLossSum: 2,
				defenderTroopSum: 8,
				defenderTroopLossSum: 8,
				sharedBy: 410,
				sharedByName: "Lobster 48",
				shareMessage: "",
				originalTime: 1720284506,
			},
			
			
			//kingdom
			{
				//id: 1,
				name: 'Атака Логово разбойников II на 00x',
				reportType: 'fightReport',
				notificationType: 4, // победа в защите без потерь
				displayType: 4,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: -226,
				sourceName: "Логово разбойников II",
				sourcePlayerId: -1,
				sourcePlayerName: "Разбойник",
				sourceKingdomId: "0",
				sourceKingdomTag: "",
				sourceTribeId: "1",
				destId: 537346056,
				destType: 1,
				destName: "00x",
				destPlayerId: 178,
				destPlayerName: "Behemoth",
				destKingdomId: "3",
				destKingdomTag: "MИР",
				destTribeId: "3",
				destX: "8",
				destY: "14",
				ownRole: "defender",
				attackType: "3",
				troopId: 2786643,
				capacity: 0,
				bounty: 0,
				otherKingdomId: "0",
				playerId: 3,
				time: 1722081057,
				attackerTroopSum: 142,
				attackerTroopLossSum: 142,
				defenderTroopSum: -1,
				defenderTroopLossSum: -1,
			},
			{
				//id: 1,
				name: 'Атака -=1=- на 520',
				reportType: 'fightReport',
				notificationType: 6, // поражение в защите с потерями
				displayType: 4,
				successType: 3, // красный
				sharedBy: null, // id игрока кто поделился
				sourceId: 537870347,
				sourceName: "-=1=-",
				sourcePlayerId: 438,
				sourcePlayerName: "Андрюха",
				sourceKingdomId: "20",
				sourceKingdomTag: "Медоеды",
				sourceTribeId: "3",
				destId: 537542661,
				destType: 0,
				destName: "520",
				destPlayerId: 137,
				destPlayerName: "Флавий",
				destKingdomId: "3",
				destKingdomTag: "MИР",
				destTribeId: "3",
				destX: "5",
				destY: "20",
				ownRole: "defender",
				attackType: "4",
				troopId: 2779784,
				capacity: 51675,
				bounty: 15765,
				otherKingdomId: "20",
				playerId: 3,
				time: 1722070030,
				attackerTroopSum: 919,
				attackerTroopLossSum: 123,
				defenderTroopSum: -1,
				defenderTroopLossSum: -1,
			},
			{
				//id: 1,
				name: 'Атака -=2=- на Tatu',
				reportType: 'fightReport',
				notificationType: 7, // поражение в защите без потерь
				displayType: 4,
				successType: 4, // серый
				sharedBy: null, // id игрока кто поделился
				sourceId: 537935870,
				sourceName: "-=2=-",
				sourcePlayerId: 438,
				sourcePlayerName: "Андрюха",
				sourceKingdomId: "20",
				sourceKingdomTag: "Медоеды",
				sourceTribeId: "3",
				destId: 537444357,
				destType: 0,
				destName: "Tatu",
				destPlayerId: 1491,
				destPlayerName: "Mmiliki",
				destKingdomId: "3",
				destKingdomTag: "MИР",
				destTribeId: "2",
				destX: "5",
				destY: "17",
				ownRole: "defender",
				attackType: "4",
				troopId: 2779792,
				capacity: 1875,
				bounty: 0,
				otherKingdomId: "20",
				playerId: 3,
				time: 1722069983,
				attackerTroopSum: 25,
				attackerTroopLossSum: 0,
				defenderTroopSum: -1,
				defenderTroopLossSum: -1,
			},
			{
				//id: 1,
				name: 'Атака Логово разбойников II на RG-0',
				reportType: 'fightReport',
				notificationType: 5, // победа в защите с потерями
				displayType: 4,
				successType: 2, // желтый
				sharedBy: null, // id игрока кто поделился
				sourceId: -3294,
				sourceName: "Логово разбойников II",
				sourcePlayerId: -1,
				sourcePlayerName: "Разбойник",
				sourceKingdomId: "0",
				sourceKingdomTag: "",
				sourceTribeId: "1",
				destId: 536363003,
				destType: 1,
				destName: "RG-0",
				destPlayerId: 1729,
				destPlayerName: "RusGal",
				destKingdomId: "3",
				destKingdomTag: "MИР",
				destTribeId: "3",
				destX: "-5",
				destY: "-16",
				ownRole: "defender",
				attackType: "3",
				troopId: 2754100,
				capacity: 0,
				bounty: 0,
				otherKingdomId: "0",
				playerId: 3,
				time: 1722025920,
				attackerTroopSum: 41,
				attackerTroopLossSum: 41,
				defenderTroopSum: -1,
				defenderTroopLossSum: -1,
			},
			{
				//id: 1,
				name: 'Атака Логово разбойников I на Тольятти',
				reportType: 'fightReport',
				notificationType: 64, // отчеты, которыми поделились с моим королевством //хотя в оригинале 61
				displayType: 4,
				successType: 1, // зеленый
				sourceId: -3563,
				sourceName: "Логово разбойников I",
				sourcePlayerId: -1,
				sourcePlayerName: "Разбойник",
				sourceKingdomId: "0",
				sourceKingdomTag: "",
				sourceTribeId: "1",
				destId: 537313294,
				destType: 1,
				destName: "Тольятти",
				destPlayerId: 1886,
				destPlayerName: "Атилла",
				destKingdomId: "3",
				destKingdomTag: "MИР",
				destTribeId: "3",
				destX: "14",
				destY: "13",
				ownRole: "defender",
				attackType: "3",
				troopId: 1805316,
				capacity: 0,
				bounty: 0,
				otherKingdomId: "0",
				playerId: 3,
				time: 1720868971, // shareTime
				attackerTroopSum: 0,
				attackerTroopLossSum: 0,
				defenderTroopSum: 186,
				defenderTroopLossSum: 0,
				sharedBy: 1886, // id игрока кто поделился
				sharedByName: "Атилла",
				shareMessage: "",
				originalTime: 1720868038, // report time
			},
			{
				//id: 1,
				name: 'Разведка из Colorado деревни Оазис (1|11)',
				reportType: 'fightReport',
				notificationType: 19, // разведывательную операцию отразить не удалось
				displayType: 5,
				successType: 2, // желтый
				sharedBy: null, // id игрока кто поделился
				sourceId: 537706527,
				sourceName: "Colorado",
				sourcePlayerId: 109,
				sourcePlayerName: "Dany Denver",
				sourceKingdomId: "20",
				sourceKingdomTag: "Медоеды",
				sourceTribeId: "1",
				destId: 537247745,
				destType: 41,
				destName: "Оазис (1|11)",
				destPlayerId: 0,
				destPlayerName: "",
				destKingdomId: null,
				destKingdomTag: "",
				destTribeId: "1",
				destX: "1",
				destY: "11",
				ownRole: "defender",
				attackType: "6",
				troopId: 2694478,
				capacity: 0,
				bounty: 0,
				otherKingdomId: "20",
				playerId: 3,
				time: 1721935647,
				attackerTroopSum: 500,
				attackerTroopLossSum: 28,
				defenderTroopSum: -1,
				defenderTroopLossSum: -1,
			},
			{
				//id: 1,
				name: 'Разведка из Colorado деревни Оазис (5|15)',
				reportType: 'fightReport',
				notificationType: 18, // разведывательная операция успешно отражена
				displayType: 5,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 537706527,
				sourceName: "Colorado",
				sourcePlayerId: 109,
				sourcePlayerName: "Dany Denver",
				sourceKingdomId: "20",
				sourceKingdomTag: "Медоеды",
				sourceTribeId: "1",
				destId: 537378821,
				destType: 41,
				destName: "Оазис (5|15)",
				destPlayerId: 0,
				destPlayerName: "",
				destKingdomId: null,
				destKingdomTag: "",
				destTribeId: "1",
				destX: "5",
				destY: "15",
				ownRole: "defender",
				attackType: "6",
				troopId: 2694499,
				capacity: 0,
				bounty: 0,
				otherKingdomId: "20",
				playerId: 3,
				time: 1721935099,
				attackerTroopSum: 500,
				attackerTroopLossSum: 500,
				defenderTroopSum: -1,
				defenderTroopLossSum: -1,
			},
			
			//own
			{
				//id: 1,
				name: 'Разведка из Брест деревни Друга',
				reportType: 'fightReport',
				notificationType: 15, // разведывательная операция прошла успешно и не была раскрыта
				displayType: 5,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229,
				sourceName: "Брест",
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				sourceKingdomId: "10",
				sourceKingdomTag: "ОоО",
				sourceTribeId: "3",
				destId: 536231925,
				destType: 0,
				destName: "Друга",
				destPlayerId: 929,
				destPlayerName: "Argo",
				destKingdomId: "0",
				destKingdomTag: "",
				destTribeId: "1",
				destX: "-11",
				destY: "-20",
				ownRole: "attacker",
				attackType: "6",
				troopId: 2236786,
				capacity: 0,
				bounty: 0,
				otherKingdomId: "0",
				time: 1721381083,
				attackerTroopSum: 33,
				attackerTroopLossSum: 0,
				defenderTroopSum: 0,
				defenderTroopLossSum: 0,
			},
			
			{
				//id: 1,
				name: 'Подкрепление в Друга было атаковано',
				reportType: 'fightReport',
				notificationType: 6, // поражение в защите с потерями
				displayType: 7,
				successType: 3, // красный
				sharedBy: null, // id игрока кто поделился
				sourceId: 537968661,
				sourceName: "Cromel",
				sourcePlayerId: 117,
				sourcePlayerName: "Stavred",
				sourceKingdomId: "20",
				sourceKingdomTag: "Медоеды",
				sourceTribeId: "3",
				destId: 536231925,
				destType: 0,
				destName: "Друга",
				destPlayerId: 929,
				destPlayerName: "Argo",
				destKingdomId: "0",
				destKingdomTag: "",
				destTribeId: "1",
				destX: "-11",
				destY: "-20",
				ownRole: "defender",
				attackType: "4",
				troopId: 2231433,
				capacity: 150,
				bounty: 103,
				otherKingdomId: "20",
				time: 1721382332,
				attackerTroopSum: 2,
				attackerTroopLossSum: 0,
				defenderTroopSum: 1,
				defenderTroopLossSum: 1,
			},
			
			{
				//id: 1,
				name: 'Престиж за неделю',
				reportType: 'prestigeReport',
				notificationType: 23, // престиж за неделю
				displayType: 10,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				currentWeekPrestige: 9,
				globalPrestige: 365,
				stars: {
					level: 1,
					stars: {
						bronze: 3,
						silver: 2,
						gold: 0,
					}
				},
				weekStartDate: 1720994400,
				weekEndDate: 1721599200,
				time: 1721599206,
				rankingConditions: [
					{
						id: "6013",
						avatarIdentifier: "6859401",
						conditionType: "9",
						controlValue: "217",
						achievedValue: 209,
						finalValue: 8,
						croppedValue: 1,
						threshold: 1,
						fulfilled: 1,
						type: "ranking",
					},
					{
						id: "6014",
						avatarIdentifier: "6859401",
						conditionType: "10",
						controlValue: "287",
						achievedValue: 274,
						finalValue: 13,
						croppedValue: 1,
						threshold: 1,
						fulfilled: 1,
						type: "ranking",
					},
					{
						id: "6015",
						avatarIdentifier: "6859401",
						conditionType: "11",
						controlValue: "293",
						achievedValue: 287,
						finalValue: 6,
						croppedValue: 1,
						threshold: 1,
						fulfilled: 1,
						type: "ranking",
					},
					{
						id: "6016",
						avatarIdentifier: "6859401",
						conditionType: "12",
						controlValue: "214",
						achievedValue: 190,
						finalValue: 24,
						croppedValue: 1,
						threshold: 1,
						fulfilled: 1,
						type: "ranking",
					},
					{
						id: "6017",
						avatarIdentifier: "6859401",
						conditionType: "13",
						controlValue: "255",
						achievedValue: 258,
						finalValue: -3,
						croppedValue: 0,
						threshold: 1,
						fulfilled: 0,
						type: "ranking",
					},
					{
						id: "6018",
						avatarIdentifier: "6859401",
						conditionType: "14",
						controlValue: "0",
						achievedValue: "0",
						finalValue: 0,
						croppedValue: 0,
						threshold: 1,
						fulfilled: 0,
						type: "ranking",
					}
				],
				conditions: [
					{
						id: "5988",
						avatarIdentifier: "6859401",
						conditionType: "15",
						controlValue: "0",
						achievedValue: "13",
						finalValue: 13,
						croppedValue: 3,
						threshold: 3,
						fulfilled: 1,
						type: "15",
					},
					{
						id: "6005",
						avatarIdentifier: "6859401",
						conditionType: "1",
						controlValue: "0",
						achievedValue: "1",
						finalValue: 1,
						croppedValue: 1,
						threshold: 1,
						fulfilled: 1,
						type: "1",
					},
					{
						id: "6006",
						avatarIdentifier: "6859401",
						conditionType: "8",
						controlValue: "0",
						achievedValue: "5",
						finalValue: 5,
						croppedValue: 5,
						threshold: 10,
						fulfilled: 0,
						type: "8",
					},
					{
						id: "6007",
						avatarIdentifier: "6859401",
						conditionType: "2",
						controlValue: "0",
						achievedValue: "10",
						finalValue: 10,
						croppedValue: 7,
						threshold: 7,
						fulfilled: 1,
						type: "2",
					},
					{
						id: "6008",
						avatarIdentifier: "6859401",
						conditionType: "3",
						controlValue: "0",
						achievedValue: "5",
						finalValue: 5,
						croppedValue: 5,
						threshold: 7,
						fulfilled: 0,
						type: "3",
					},
					{
						id: "6009",
						avatarIdentifier: "6859401",
						conditionType: "4",
						controlValue: "0",
						achievedValue: "17",
						finalValue: 17,
						croppedValue: 3,
						threshold: 3,
						fulfilled: 1,
						type: "4",
					},
					{
						id: "6010",
						avatarIdentifier: "6859401",
						conditionType: "5",
						controlValue: "1365",
						achievedValue: 1666,
						finalValue: 301,
						croppedValue: 100,
						threshold: 100,
						fulfilled: 1,
						type: "5",
					},
					{
						id: "6011",
						avatarIdentifier: "6859401",
						conditionType: "6",
						controlValue: "5",
						achievedValue: 5,
						finalValue: 0,
						croppedValue: 0,
						threshold: 1,
						fulfilled: 0,
						type: "6",
					},
					{
						id: "6012",
						avatarIdentifier: "6859401",
						conditionType: "7",
						controlValue: "0",
						achievedValue: "0",
						finalValue: 0,
						croppedValue: 0,
						threshold: 1,
						fulfilled: 0,
						type: "7",
					},
					{
						id: "6013",
						avatarIdentifier: "6859401",
						conditionType: "9",
						controlValue: "217",
						achievedValue: 209,
						finalValue: 8,
						croppedValue: 1,
						threshold: 1,
						fulfilled: 1,
						type: "ranking",
					}
				],
				totalFulfilled: 9,
				medals: [],
			},
			
			{
				//id: 1,
				name: 'Снабжение из Брест в Тимкинск',
				reportType: 'tradeReport',
				notificationType: 13, // торговцы доставили преимущественно железо
				displayType: 2,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229,
				sourceName: "Брест",
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				sourceKingdomId: "10",
				sourceKingdomTag: "ОоО",
				destId: 537346051,
				destName: "Тимкинск",
				destPlayerId: 194,
				destPlayerName: "Тимошка",
				destKingdomId: "3",
				destKingdomTag: "MИР",
				resources: [
					0,
					0,
					1500,
					0
				],
				time: 1721812125,
			},
			{
				//id: 1,
				name: 'Снабжение из _01 в Брест',
				reportType: 'tradeReport',
				notificationType: 11, // торговцы доставили преимущественно древесину
				displayType: 2,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 537313266,
				sourceName: "_01",
				sourcePlayerId: 288,
				sourcePlayerName: "Kimchick",
				sourceKingdomId: "41",
				sourceKingdomTag: "DOTA2",
				destId: 536330229,
				destName: "Брест",
				destPlayerId: 1,
				destPlayerName: "lexinzector",
				destKingdomId: "10",
				destKingdomTag: "ОоО",
				resources: [
					1500,
					0,
					0,
					0
				],
				time: 1721812545,
			},
			{
				//id: 1,
				name: 'Снабжение из Тимкинск в Брест',
				reportType: 'tradeReport',
				notificationType: 12, // торговцы доставили преимущественно глину
				displayType: 2,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 537346051,
				sourceName: "Тимкинск",
				sourcePlayerId: 194,
				sourcePlayerName: "Тимошка",
				sourceKingdomId: "3",
				sourceKingdomTag: "MИР",
				destId: 536330229,
				destName: "Брест",
				destPlayerId: 1,
				destPlayerName: "lexinzector",
				destKingdomId: "10",
				destKingdomTag: "ОоО",
				resources: [
					0,
					1500,
					0,
					0
				],
				time: 1721814676,
			},
			{
				//id: 1,
				name: 'Снабжение из Брест в Зарница',
				reportType: 'tradeReport',
				notificationType: 14, // торговцы доставили преимущественно зерно
				displayType: 2,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229,
				sourceName: "Брест",
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				sourceKingdomId: "3",
				sourceKingdomTag: "MИР",
				destId: 536264692,
				destName: "Зарница",
				destPlayerId: 1,
				destPlayerName: "lexinzector",
				destKingdomId: "3",
				destKingdomTag: "MИР",
				resources: [
					0,
					0,
					0,
					5250
				],
				time: 1721994923,
			},
			{
				//id: 1,
				name: 'Снабжение из Брест в Глушица',
				reportType: 'tradeReport',
				notificationType: 10, // торговцы доставили ресурсы
				displayType: 2,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229,
				sourceName: "Брест",
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				sourceKingdomId: "3",
				sourceKingdomTag: "MИР",
				destId: 536264691,
				destName: "Глушица",
				destPlayerId: 1,
				destPlayerName: "lexinzector",
				destKingdomId: "3",
				destKingdomTag: "MИР",
				resources: [
					3750,
					3750,
					3750,
					750
				],
				time: 1722003238,
			},
			
			{
				//id: 1,
				name: 'Подкрепление из Брест в Друга',
				reportType: 'troopsReport',
				notificationType: 8, // подкрепление прибыло
				displayType: 1,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229,
				sourceName: "Брест",
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				sourceKingdomId: "10",
				sourceKingdomTag: "ОоО",
				destId: 536231925,
				destType: 0,
				destName: "Друга",
				destPlayerId: 929,
				destPlayerName: "Argo",
				destKingdomId: "0",
				destKingdomTag: "",
				troopData: {
					originalTroops: {
						1: "0",
						2: "0",
						3: "0",
						4: "38",
						5: "0",
						6: "0",
						7: "0",
						8: "0",
						9: "0",
						10: "0",
						11: "0"
					},
				},
				time: 1721382154,
			},
			{
				//id: 1,
				name: 'Подкрепление из Оазис (-14|-14) в Брест',
				reportType: 'troopsReport',
				notificationType: 8, // подкрепление прибыло
				displayType: 1,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536428530,
				sourceName: "Оазис (-14|-14)",
				sourcePlayerId: 0,
				sourcePlayerName: "Природа",
				sourceKingdomId: "0",
				sourceKingdomTag: "",
				destId: 536330229,
				destType: 3,
				destName: "Брест",
				destPlayerId: 1,
				destPlayerName: "lexinzector",
				destKingdomId: "3",
				destKingdomTag: "MИР",
				troopData: {
					originalTroops: {
						1: "0",
						2: "0",
						3: "0",
						4: "38",
						5: "0",
						6: "0",
						7: "0",
						8: "0",
						9: "0",
						10: "0",
						11: "0"
					},
					supply: 38,
				},
				time: 1721932905,
			},
			
			{
				//id: 1,
				name: 'Атака Логово разбойников I на Брест',
				reportType: 'fightReport',
				notificationType: 4, // победа в защите без потерь
				displayType: 4,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: -1375,
				sourceName: "Логово разбойников I",
				sourcePlayerId: -1,
				sourcePlayerName: "Разбойник",
				sourceKingdomId: "0",
				sourceKingdomTag: "",
				sourceTribeId: "1",
				destId: 536330229,
				destType: 3,
				destName: "Брест",
				destPlayerId: 1,
				destPlayerName: "lexinzector",
				destKingdomId: "10",
				destKingdomTag: "ОоО",
				destTribeId: "3",
				destX: "-11",
				destY: "-17",
				ownRole: "defender",
				attackType: "3",
				troopId: 2690169,
				otherKingdomId: "41",
				capacity: 0,
				bounty: 0,
				time: 1721927237,
				attackerTroopSum: 0,
				attackerTroopLossSum: 0,
				defenderTroopSum: 460,
				defenderTroopLossSum: 0,
				treasures: null,
				victoryPoints: null,
				victoryPointsCap: null,
				duration: 2683,
				supply: "9807",
				tributeBounty: null,
				stolenGoods: null,
				modules: [
					{
						name: "wounded/defender",
						body: {
							playerId: 122,
							originalTroops: {
								1: 276,
								2: 0,
								3: 0,
								4: 0,
								5: 34,
								6: 368,
								7: 0,
								8: 0,
								9: 0,
								10: 0,
								11: 0
							},
							tribeId: "3",
							amount: 678
						}
					},
					{
						name: "troops/attacker",
						body: {
							tribeId: "2",
							playerId: "1130",
							playerName: "Топ4ік",
							villageId: "537182186",
							villageName: "топ4ік",
							originalTroops: {
								1: 192,
								2: 0,
								3: 5943,
								4: 0,
								5: 0,
								6: 750,
								7: 100,
								8: 187,
								9: 0,
								10: 0,
								11: 1
							},
							trappedTroops: {
								1: 1,
								2: 0,
								3: 18,
								4: 0,
								5: 0,
								6: 2,
								7: 0,
								8: 0,
								9: 0,
								10: 0,
								11: 0
							},
							lostTroops: {
								1: 191,
								2: 0,
								3: 5925,
								4: 0,
								5: 0,
								6: 748,
								7: 100,
								8: 187,
								9: 0,
								10: 0,
								11: 1
							}
						}
					},
					{
						name: "troops/defender",
						body: {
							tribeId: "3",
							playerId: "122",
							playerName: "гал",
							villageId: "537149416",
							villageName: "Новая деревня",
							originalTroops: {
								1: "0",
								2: "0",
								3: "0",
								4: "0",
								5: "0",
								6: "0",
								7: "0",
								8: "0",
								9: "0",
								10: "0",
								11: "0"
							},
							lostTroops: {
								1: 0,
								2: 0,
								3: 0,
								4: 0,
								5: 0,
								6: 0,
								7: 0,
								8: 0,
								9: 0,
								10: 0,
								11: 0
							}
						}
					},
					{
						name: "troops/supporter",
						body: {
							tribeId: "3",
							playerId: "553",
							playerName: "Труррль",
							villageId: "536428533",
							villageName: "01.",
							originalTroops: {
								1: "0",
								2: "0",
								3: "0",
								4: "0",
								5: "4870",
								6: "0",
								7: "0",
								8: "0",
								9: "0",
								10: "0",
								11: "0"
							},
							lostTroops: {
								1: 0,
								2: 0,
								3: 0,
								4: 0,
								5: 2909,
								6: 0,
								7: 0,
								8: 0,
								9: 0,
								10: 0,
								11: 0
							}
						}
					},
					{
						name: "troops/supporter",
						body: {
							tribeId: "3",
							playerId: "210",
							playerName: "СуперМегаЗло",
							villageId: "536788978",
							villageName: "2. Салуза",
							originalTroops: {
								1: "0",
								2: "0",
								3: "0",
								4: "0",
								5: "190",
								6: "0",
								7: "0",
								8: "0",
								9: "0",
								10: "0",
								11: "0"
							},
							lostTroops: {
								1: 0,
								2: 0,
								3: 0,
								4: 0,
								5: 113,
								6: 0,
								7: 0,
								8: 0,
								9: 0,
								10: 0,
								11: 0
							}
						}
					},
					{
						name: "troops/supporter",
						body: {
							tribeId: "3",
							playerId: "210",
							playerName: "СуперМегаЗло",
							villageId: "536788977",
							villageName: "Салуза Секундас",
							originalTroops: {
								1: "0",
								2: "0",
								3: "0",
								4: "0",
								5: "410",
								6: "0",
								7: "0",
								8: "0",
								9: "0",
								10: "0",
								11: "0"
							},
							lostTroops: {
								1: 0,
								2: 0,
								3: 0,
								4: 0,
								5: 245,
								6: 0,
								7: 0,
								8: 0,
								9: 0,
								10: 0,
								11: 0
							}
						}
					},
					{
						name: "troops/supporter",
						body: {
							tribeId: "3",
							playerId: "122",
							playerName: "гал",
							villageId: "537083879",
							villageName: "деревня",
							originalTroops: {
								1: "1115",
								2: "0",
								3: "0",
								4: "0",
								5: "142",
								6: "0",
								7: "0",
								8: "0",
								9: "0",
								10: "0",
								11: "0"
							},
							lostTroops: {
								1: 666,
								2: 0,
								3: 0,
								4: 0,
								5: 85,
								6: 0,
								7: 0,
								8: 0,
								9: 0,
								10: 0,
								11: 0
							}
						}
					},
					{
						name: "troops/supporter",
						body: {
							tribeId: "3",
							playerId: "122",
							playerName: "гал",
							villageId: "537083881",
							villageName: "тут ресов нет",
							originalTroops: {
								1: "40",
								2: "0",
								3: "0",
								4: "0",
								5: "0",
								6: "1542",
								7: "0",
								8: "0",
								9: "0",
								10: "0",
								11: "1"
							},
							lostTroops: {
								1: 24,
								2: 0,
								3: 0,
								4: 0,
								5: 0,
								6: 921,
								7: 0,
								8: 0,
								9: 0,
								10: 0,
								11: 0
							}
						}
					},
					{
						name: "damage",
						body: {
							unitNr: 8,
							buildingType: "4",
							buildingOriginalLevel: "10",
							buildingFinalLevel: 0,
						},
					},
					{
						name: "damage",
						body: {
							unitNr: 7,
							buildingType: "33",
							buildingOriginalLevel: "4",
							buildingFinalLevel: 0,
						},
					},
				],
			},
			{
				//id: 1,
				name: 'Атака Брест на Логово разбойников II',
				reportType: 'fightReport',
				notificationType: 1, // победа в атаке без потерь
				displayType: 4,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229,
				sourceName: "Брест",
				destId: -1376,
				destType: 5,
				destName: "Логово разбойников II",
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				sourceKingdomId: "3",
				sourceKingdomTag: "MИР",
				sourceTribeId: "3",
				destPlayerId: -1,
				destPlayerName: "Разбойник",
				destKingdomId: null,
				destKingdomTag: "",
				destTribeId: "3",
				destX: -14,
				destY: -16,
				ownRole: "attacker",
				attackType: "3",
				troopId: 2733447,
				capacity: 4950,
				bounty: 4950,
				time: 1721991886,
				attackerTroopSum: 66,
				attackerTroopLossSum: 0,
				defenderTroopSum: 0,
				defenderTroopLossSum: 0,
			},
			{
				//id: 1,
				name: 'Атака Брест на Логово разбойников I',
				reportType: 'fightReport',
				notificationType: 2, // победа в атаке с потерями
				displayType: 4,
				successType: 2, // желтый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229,
				sourceName: "Брест",
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				sourceKingdomId: "3",
				sourceKingdomTag: "MИР",
				sourceTribeId: "3",
				destId: -1375,
				destType: 5,
				destName: "Логово разбойников I",
				destPlayerId: -1,
				destPlayerName: "Разбойник",
				destKingdomId: null,
				destKingdomTag: "",
				destTribeId: "3",
				destX: -12,
				destY: -20,
				ownRole: "attacker",
				attackType: "3",
				troopId: 2733454,
				capacity: 15125,
				bounty: 7101,
				time: 1721992073,
				attackerTroopSum: 396,
				attackerTroopLossSum: 9,
				defenderTroopSum: 32,
				defenderTroopLossSum: 32,
			},
			
			{
				//id: 1,
				name: 'Атака Брест на Оазис (-14|-14)',
				reportType: 'fightReport',
				notificationType: 124, // победа в защите без потерь
				displayType: 4,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229,
				sourceName: "Брест",
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				sourceKingdomId: "3",
				sourceKingdomTag: "MИР",
				sourceTribeId: "3",
				destId: 536428530,
				destType: 30,
				destName: "Оазис (-14|-14)",
				destPlayerId: 0,
				destPlayerName: "",
				destKingdomId: null,
				destKingdomTag: "",
				destTribeId: "4",
				destX: "-14",
				destY: "-14",
				ownRole: "attacker",
				attackType: "4",
				troopId: 2761283,
				capacity: 1900,
				bounty: 0,
				time: 1722025962,
				attackerTroopSum: 40,
				attackerTroopLossSum: 0,
				defenderTroopSum: 0,
				defenderTroopLossSum: 0,
			},
			{
				//id: 1,
				name: 'Брест: "Стартовый лист" завершен',
				reportType: 'farmlistReport',
				notificationType: ReportsModel.REPORT_FARMLIST_RAID, // атаки по списку целей
				displayType: 12,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				destName: "Стартовый лист",
				destId: 325,
				targetsTotal: 1,
        		targetsFinished: 1,
				time: 1722023417,
				targetsDestId: [
					536428530,
				],
				targetsDestPlayerId: [
					0,
				],
			},
			{
				//id: 1,
				name: 'Брест: "Стартовый лист" начат',
				reportType: 'farmlistReport',
				notificationType: ReportsModel.REPORT_FARMLIST_RAID, // атаки по списку целей
				displayType: 11,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				destName: "Стартовый лист",
				destId: 325,
				targetsTotal: 1,
        		targetsFinished: 0,
				time: 1722023417,
				targetsDestId: [
					536428530,
				],
				targetsDestPlayerId: [
					0,
				],
			},
			
			{
				//id: 1,
				name: 'Были пойманы животные',
				reportType: 'troopsReport',
				notificationType: 20, // были пойманы животные
				displayType: 9,
				successType: 1, // зеленый
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229,
				sourceName: "Брест",
				sourcePlayerId: 1,
				sourcePlayerName: "lexinzector",
				sourceKingdomId: "10",
				sourceKingdomTag: "ОоО",
				sourceTribeId: "3",
				destId: 536428530,
				destType: 30,
				destName: "Оазис (-14|-14)",
				destPlayerId: 0,
				destPlayerName: "",
				destKingdomId: null,
				destKingdomTag: "",
				destTribeId: "4",
				destX: "-14",
				destY: "-14",
				ownRole: "attacker",
				attackType: "3",
				troopId: 2693159,
				capacity: 10860,
				bounty: 0,
				troopData: {
					originalTroops: {
						1: "0",
						2: "0",
						3: "0",
						4: "38",
						5: "0",
						6: "0",
						7: "0",
						8: "0",
						9: "0",
						10: "0",
						11: "0"
					},
					cagedAnimals: 38,
				},
				time: 1721930360,
			},
			
			{
				//id: 1,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					resources: {
						type: 3, // ресурсы
						subType: null,
						amount: [
							13275,
							21240,
							25665,
							21240,
						],
					},
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 57,
				hp: 23,
				time: 1721936043,
			},
			{
				//id: 2,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					items: [
						{
							type: 2, // предмет
							subType: 71,
							icon: 'heroItem_telescope0_1_large_illu male',
							name: 'Подзорная труба',
							amount: 1,
						},
					],
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 57,
				hp: 24,
				time: 1721837130,
			},
			{
				//id: 3,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					/*empty: {
						type: 0, // ничего не нашел
						subType: null,
						amount: 0,
					},*/
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 52,
				hp: 25,
				time: 1721641321,
			},
			{
				//id: 4,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					items: [
						{
							type: 14, // предмет
							subType: 117,
							icon: 'heroItem_small_bandage_large_illu male',
							name: 'Малая повязка',
							amount: 44,
						},
					],
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 52,
				hp: 25,
				time: 1721837130,
			},
			{
				//id: 5,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					troops: [
						{
							type: 5, // войска
							subType: 23,
							unitId: 1,
							name: 'Фаланга',
							amount: 24,
						},
					],
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 52,
				hp: 23,
				time: 1721372832,
			},
			{
				//id: 6,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					items: [
						{
							type: 14, // предмет
							subType: 112,
							icon: 'heroItem_ointment_large_illu male',
							name: 'Мазь',
							amount: 19,
						},
					],
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 52,
				hp: 25,
				time: 1721837130,
			},
			{
				//id: 7,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					troops: [
						{
							type: 5, // войска
							subType: 22,
							unitId: 1,
							name: 'Фаланга',
							amount: 93,
						},
					],
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 52,
				hp: 23,
				time: 1721152400,
			},
			{
				//id: 7,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					troops: [
						{
							type: 5, // войска
							subType: 22,
							unitId: 1,
							name: 'Фаланга',
							amount: 47,
						},
					],
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 47,
				hp: 23,
				time: 1721136936,
			},
			{
				//id: 7,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					items: [
						{
							type: 14, // предмет
							subType: 119,
							icon: 'heroItem_cage_large_illu male',
							name: 'Клетка',
							amount: 8,
						},
					],
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 94,
				hp: 49,
				time: 1721120478,
			},
			{
				//id: 8,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 3, // красный
				loot: [],
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: false, // погиб
				xp: 0,
        		hp: 49,
				time: 1721114299,
			},
			{
				//id: 9,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					items: [
						{
							type: 14, // предмет
							subType: 118,
							icon: 'heroItem_bandage_large_illu male',
							name: 'Повязка',
							amount: 20,
						},
						{
							type: 14, // предмет
							subType: 116,
							icon: 'heroItem_artwork_large_illu male',
							name: 'Произведение искусства',
							amount: 1,
						},
					],
					silver: {
						type: 4, // бронза
						subType: null,
						amount: 340,
					},
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 94,
				hp: 50,
				time: 1721042577,
			},
			{
				//id: 10,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					items: [
						{
							type: 14, // предмет
							subType: 117,
							icon: 'heroItem_small_bandage_large_illu male',
							name: 'Малая повязка',
							amount: 82,
						},
					],
					resources: {
						type: 3, // ресурсы
						subType: null, // 117 почемуто было
						amount: [
							18720,
							12240,
							16560,
							11520,
						],
					},
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 94,
				hp: 48,
				time: 1720975349,
			},
			{
				//id: 11,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					items: [
						{
							type: 2, // предмет
							subType: 35,
							icon: 'heroItem_sword4_1_large_illu male',
							name: 'Меч мечника',
							amount: 1,
						},
					],
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 47,
				hp: 23,
				time: 1720849011,
			},
			{
				//id: 12,
				name: 'Герой был в приключении',
				reportType: 'adventureReport',
				notificationType: ReportsModel.REPORT_ADVENTURE, // отчет о приключении
				displayType: 3,
				successType: 1, // зеленый
				loot: {
					items: [
						{
							type: 2, // предмет
							subType: 92,
							icon: 'heroItem_shirt3_1_large_illu male',
							name: 'Кольчуга',
							amount: 1,
						},
					],
				},
				sharedBy: null, // id игрока кто поделился
				sourceId: 536330229, // id острова
				sourceName: 'Брест', // столица
				sourcePlayerId: 1,
				sourcePlayerName: 'lexinzector',
				sourceKingdomId: 10,
				sourceKingdomTag: 'ОоО',
				won: true, // выжил
				xp: 47,
				hp: 26,
				time: 1720780243,
			},
			
		];
		
		let cntReport = 1;
		this.list.map(el => {
			el.id = cntReport++;
			return el;
		});
	}
	
	doGetReport(id){
		return this.list.find(el => el.id === id);
	}
}

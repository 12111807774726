<template>
	<div ng-controller="attacksCtrl" class="incomingCommunityAttacks">
		<h6 class="headerWithIcon arrowDirectionDown">
			<i class="community_kingdom_medium_flat_black"></i>
			<div class="title"><span translate=""><span>Королевство</span></span><span ng-show="total.kingdom>0"> | </span><span class="count warning" ng-show="total.kingdom>0"> × 6</span></div>
			<label class="clickInputLabel"><div class="clickInputWrapper"><input type="checkbox" ng-model="show.kingdom" checked ng-change="update();" class="ng-pristine ng-untouched ng-valid"><i class="action_check_tiny_flat_black"></i></div><span translate=""><span>Показать атаки</span></span></label>
		</h6>
		<table ng-if="show.kingdom &amp;&amp; total.kingdom>0">
			<thead>
				<tr>
					<th>
						<span translate=""><span>Игрок</span></span>
						/
						<span translate=""><span>Деревни</span></span>
					</th>
					<th></th>
					<th translate="" class="distance"><span>Дист.</span></th>
					<th translate="" class="arrival"><span>Прибытие</span></th>
				</tr>
			</thead>
			<tbody ng-repeat="player in showMembers.kingdom">
				<tr ng-show="player.playerId">
					<td colspan="4">
						<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="194" playername="">Тимошка</a>
					</td>
				</tr>
				<!-- ngRepeat: (villageId, village) in player.attacks.villages --><tr ng-repeat="(villageId, village) in player.attacks.villages" ng-show="player.playerId">
					<td class="nameColumn">
						<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537346049}, null, true)" village-link="" villageid="537346049" villagename="">уТимкин</a>
					</td>
					<td><i class="movement_attack_small_flat_red"></i><span class="count"> × 20</span></td>
					<td>
						33,2
					</td>
					<td>
						<span class="countdown" countdown="1723803918">00:03:47</span>
						<span class="date" i18ndt="1723803918" format="short">Сегодня 15:25</span>
					</td>
				</tr><!-- end ngRepeat: (villageId, village) in player.attacks.villages --><tr ng-repeat="(villageId, village) in player.attacks.villages" ng-show="player.playerId">
					<td class="nameColumn">
						<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537346051}, null, true)" village-link="" villageid="537346051" villagename="">Тимкинск</a>
					</td>
					<td><i class="movement_attack_small_flat_red"></i><span class="count"> × 23</span></td>
					<td>
						34,0
					</td>
					<td>
						<span class="countdown" countdown="1723803730">00:00:39</span>
						<span class="date" i18ndt="1723803730" format="short">Сегодня 15:22</span>
					</td>
				</tr><!-- end ngRepeat: (villageId, village) in player.attacks.villages -->
			</tbody>
			<tbody ng-repeat="player in showMembers.kingdom">
				<tr ng-show="player.playerId">
					<td colspan="4">
						<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="">lexinzector</a>
					</td>
				</tr>
				<tr ng-repeat="(villageId, village) in player.attacks.villages" ng-show="player.playerId">
					<td class="nameColumn">
						<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536133632}, null, true)" village-link="" villageid="536133632" villagename="">InZectorsk</a>
					</td>
					<td><i class="movement_attack_small_flat_red"></i><span class="count"> × 1</span></td>
					<td>
						0,0
					</td>
					<td>
						<span class="countdown" countdown="1707857848">04:51:51</span>
						<span class="date" i18ndt="1707857848" format="short">Завтра 02:57</span>
					</td>
				</tr>
			</tbody>
			<tbody ng-repeat="player in showMembers.kingdom">
				<tr ng-show="player.playerId">
					<td colspan="4">
						<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="217" playername="">Роман</a>
					</td>
				</tr>
				<tr ng-repeat="(villageId, village) in player.attacks.villages" ng-show="player.playerId">
					<td class="nameColumn">
						<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537313276}, null, true)" village-link="" villageid="537313276" villagename="">Родник</a>
					</td>
					<td><i class="movement_attack_small_flat_red"></i><span class="count"> × 1</span></td>
					<td>
						4,1
					</td>
					<td>
						<span class="countdown" countdown="1707911664">00:25:45</span>
						<span class="date" i18ndt="1707911664" format="short">Сегодня 17:54</span>
					</td>
				</tr>
			</tbody>
			<tbody ng-repeat="player in showMembers.kingdom">
				<tr ng-show="player.playerId">
					<td colspan="4">
						<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="942" playername="">OstToL</a>
					</td>
				</tr>
				<tr ng-repeat="(villageId, village) in player.attacks.villages" ng-show="player.playerId">
					<td class="nameColumn">
						<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537083891}, null, true)" village-link="" villageid="537083891" villagename="">Ямполь</a>
					</td>
					<td><i class="movement_attack_small_flat_red"></i><span class="count"> × 1</span></td>
					<td>
						10,4
					</td>
					<td>
						<span class="countdown" countdown="1707914499">01:12:47</span>
						<span class="date" i18ndt="1707914499" format="short">Сегодня 18:41</span>
					</td>
				</tr>
			</tbody>
			<tbody ng-repeat="player in showMembers.kingdom">
				<tr ng-show="player.playerId">
					<td colspan="4">
						<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1716" playername="">Патрон</a>
					</td>
				</tr>
				<tr ng-repeat="(villageId, village) in player.attacks.villages" ng-show="player.playerId">
					<td class="nameColumn">
						<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537182193}, null, true)" village-link="" villageid="537182193" villagename="">Офис</a>
					</td>
					<td><i class="movement_attack_small_flat_red"></i><span class="count"> × 1</span></td>
					<td>
						12,0
					</td>
					<td>
						<span class="countdown" countdown="1707911574">00:23:55</span>
						<span class="date" i18ndt="1707911574" format="short">Сегодня 17:52</span>
					</td>
				</tr>
			</tbody>
			<tbody>
				<tr ng-show="showMembers.kingdom[0]">
					<td colspan="4">
						<span translate=""><span>Оазисы</span></span>
					</td>
				</tr>
				<tr ng-repeat="(villageId, village) in showMembers.kingdom[0].attacks.villages" ng-show="showMembers.kingdom[0]">
					<td class="nameColumn">
						<span class="coordinates">
							<span translate=""><span>Оазис</span></span>
							<span class="coordinateWrapper" coordinates="" aligned="true" x="15" y="6">
								<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537083919_1724268047.8');" class="clickable">
									<span class="coordinates coordinatesWrapper  coordinatesAligned" ng-bind-html=" coord | bidiCoordinate:x:y "><span class="coordinateX">(15</span><span class="coordinatePipe">|</span><span class="coordinateY">6)</span></span>
								</a>
								<!-- ngIf: !hasLink -->
							</span>
						</span>
					</td>
					<td><i class="movement_attack_small_flat_red"></i><span class="count"> × 1</span></td>
					<td>
						34,7
					</td>
					<td class="arrival">
						<span class="countdown" countdown="1724255628">00:00:00</span>
						<span class="date" i18ndt="1724255628" format="short">Вчера 20:53</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
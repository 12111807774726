<template>
	<div ng-controller="npcMerchantCtrl" class="marketContent npcTrader contentBox">
		<div class="headerWithIcon arrowDirectionTo contentBoxHeader">
			<i class="movement_trade_medium_flat_black"></i>
			<span translate=""><span>NPC-торговец</span></span>
		</div>
		<div class="contentBoxBody npcTraderDescription">
			<p translate=""><span>NPC-торговцы позволяют перераспределять ресурсы в хранилище по твоему желанию.</span></p>
			<p translate=""><span>Для всех ресурсов ты можешь видеть текущий запас, особые бегунки для перераспределения ресурсов и конечную разницу.</span></p>
		</div>
		<table class="resourcesTable transparent contentBoxBody">
			<tbody class="sliderTable">
				<tr ng-repeat="(resourceType, resName) in resNames">
					<td class="resCol" tooltip="" tooltip-translate="Resource_1">
						<i class="unit_wood_medium_illu"></i>
						<span class="resourceAmount woodAmount">50</span>
					</td>
					<td class="sliderCol">
						<div class="sliderArrow"></div>
						<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-lock="resourceData[resourceType]['locked']" slider-data="resourceData[resourceType]" slider-changed="sliderChanged">
							<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="" style="pointer-events: auto; cursor: default;">
								<div class="trackContainer">
									<div class="track">
										<div class="sliderMarker progressMarker" style="width: 18.6957%;"></div>
										<div class="hoverIndicator" style="display: none; left: 78px; opacity: 1;">
											<div class="hoverValue">690</div>
											<div class="indicator"></div>
										</div>
										<div class="handle unselectable" style="left: 48.6087px;"></div>
									</div>
								</div>
								<div class="inputContainer">
									<input class="value ng-pristine ng-valid ng-touched" number="" ng-model="value" type="tel" />
									<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
										<i class="action_setMax_small_flat_black"></i>
									</div>
								</div>
							</div>
						</slider>
						<div class="lockButtonContainer clickable open" ng-class="{open: !resourceData[resourceType]['locked'], disabled: !resourceData[resourceType]['locked'] && lockedResources > availableResTypes - 3}" clickable="toggleResourceLock(1)">
							<div class="lockButtonBackground"></div>
							<div class="lockSymbol"></div>
						</div>
					</td>
					<td class="diffCol">
						<span ng-class="{positive: resourceData[resourceType]['diff'] > 0, negative: resourceData[resourceType]['diff'] < 0}" class="positive">+380</span>
					</td>
				</tr>
				<tr ng-repeat="(resourceType, resName) in resNames">
					<td class="resCol" tooltip="" tooltip-translate="Resource_2">
						<i class="unit_clay_medium_illu"></i>
						<span class="resourceAmount clayAmount">301</span>
					</td>
					<td class="sliderCol">
						<div class="sliderArrow"></div>
						<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-lock="resourceData[resourceType]['locked']" slider-data="resourceData[resourceType]" slider-changed="sliderChanged">
							<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="" style="pointer-events: auto; cursor: default;">
								<div class="trackContainer">
									<div class="track">
										<div class="sliderMarker progressMarker" style="width: 0%;"></div>
										<div class="hoverIndicator" style="display: none; left: -114px; opacity: 1;">
											<div class="hoverValue">1311</div>
											<div class="indicator"></div>
										</div>
										<div class="handle unselectable" style="left: 0px;"></div>
									</div>
								</div>
								<div class="inputContainer">
									<input class="value ng-pristine ng-valid ng-touched" number="" ng-model="value" type="tel" />
									<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
										<i class="action_setMax_small_flat_black"></i>
									</div>
								</div>
							</div>
						</slider>
						<div class="lockButtonContainer clickable open" ng-class="{open: !resourceData[resourceType]['locked'], disabled: !resourceData[resourceType]['locked'] && lockedResources > availableResTypes - 3}" clickable="toggleResourceLock(2)">
							<div class="lockButtonBackground"></div>
							<div class="lockSymbol"></div>
						</div>
					</td>
					<td class="diffCol">
						<span ng-class="{positive: resourceData[resourceType]['diff'] > 0, negative: resourceData[resourceType]['diff'] < 0}" class="negative">-301</span>
					</td>
				</tr>
				<tr ng-repeat="(resourceType, resName) in resNames">
					<td class="resCol" tooltip="" tooltip-translate="Resource_3">
						<i class="unit_iron_medium_illu"></i>
						<span class="resourceAmount ironAmount">290</span>
					</td>
					<td class="sliderCol">
						<div class="sliderArrow"></div>
						<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-lock="resourceData[resourceType]['locked']" slider-data="resourceData[resourceType]" slider-changed="sliderChanged">
							<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="" style="pointer-events: auto; cursor: default;">
								<div class="trackContainer">
									<div class="track">
										<div class="sliderMarker progressMarker" style="width: 18.4783%;"></div>
										<div class="hoverIndicator" style="display: none; left: -21px; opacity: 1;">
											<div class="hoverValue">1265</div>
											<div class="indicator"></div>
										</div>
										<div class="handle unselectable" style="left: 48.0435px;"></div>
									</div>
								</div>
								<div class="inputContainer">
									<input class="value ng-pristine ng-valid ng-touched" number="" ng-model="value" type="tel" />
									<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
										<i class="action_setMax_small_flat_black"></i>
									</div>
								</div>
							</div>
						</slider>
						<div class="lockButtonContainer clickable open" ng-class="{open: !resourceData[resourceType]['locked'], disabled: !resourceData[resourceType]['locked'] && lockedResources > availableResTypes - 3}" clickable="toggleResourceLock(3)">
							<div class="lockButtonBackground"></div>
							<div class="lockSymbol"></div>
						</div>
					</td>
					<td class="diffCol">
						<span ng-class="{positive: resourceData[resourceType]['diff'] > 0, negative: resourceData[resourceType]['diff'] < 0}" class="positive">+135</span>
					</td>
				</tr>
				<tr ng-repeat="(resourceType, resName) in resNames">
					<td class="resCol" tooltip="" tooltip-translate="Resource_4">
						<i class="unit_crop_medium_illu"></i>
						<span class="resourceAmount cropAmount">639</span>
					</td>
					<td class="sliderCol">
						<div class="sliderArrow"></div>
						<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-lock="resourceData[resourceType]['locked']" slider-data="resourceData[resourceType]" slider-changed="sliderChanged">
							<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="" style="pointer-events: auto; cursor: default;">
								<div class="trackContainer">
									<div class="track">
										<div class="sliderMarker progressMarker" style="width: 18.4783%;"></div>
										<div class="hoverIndicator" style="display: none; left: 273px; opacity: 1;">
											<div class="hoverValue">2300</div>
											<div class="indicator"></div>
										</div>
										<div class="handle unselectable" style="left: 48.0435px;"></div>
									</div>
								</div>
								<div class="inputContainer">
									<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel" />
									<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
										<i class="action_setMax_small_flat_black"></i>
									</div>
								</div>
							</div>
						</slider>
						<div class="lockButtonContainer clickable open" ng-class="{open: !resourceData[resourceType]['locked'], disabled: !resourceData[resourceType]['locked'] && lockedResources > availableResTypes - 3}" clickable="toggleResourceLock(4)">
							<div class="lockButtonBackground"></div>
							<div class="lockSymbol"></div>
						</div>
					</td>
					<td class="diffCol">
						<span ng-class="{positive: resourceData[resourceType]['diff'] > 0, negative: resourceData[resourceType]['diff'] < 0}" class="negative">-214</span>
					</td>
				</tr>
				<tr class="sumRow">
					<td class="resCol" colspan="3">1280</td>
				</tr>
			</tbody>
		</table>
		<div class="buttonBar contentBoxBody">
			<div ng-show="freeResources == 0" class="merchantBtn">
				<button class="premium animate" premium-feature="NPCTrader" confirm-gold-usage="true" ng-class="{disabled: totalDiff == 0 || npcTradingAvailable == 'notAvailable' || player.isOnVacation()}" tooltip="" tooltip-translate="Building.npcTrader.Tooltip.WWVillage" tooltip-hide="true">
					<div class="content">
						<span ng-transclude="" class="innerText">
							<span translate=""><span>Поменять</span></span>
						</span>
						<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>5</span>
						<div class="animationOverlay"></div>
						<i class="checkmark action_check_small_flat_white"></i>
						<i class="checkmark action_check_small_flat_black"></i>
						<i class="checkmark action_check_small_flat_red"></i>
					</div>
				</button>
			</div>
			<div ng-hide="freeResources == 0" class="merchantBtn ng-hide">
				<button clickable="distribute()" ng-class="{disabled: totalDiff == 0}" class="clickable">
					<div class="content">
						<span ng-transclude="" class="innerText">
							<span translate=""><span>Распределить оставшиеся ресурсы</span></span>
						</span>
						<div class="animationOverlay"></div>
						<i class="checkmark action_check_small_flat_white"></i>
						<i class="checkmark action_check_small_flat_black"></i>
						<i class="checkmark action_check_small_flat_red"></i>
					</div>
				</button>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
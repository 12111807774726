<template>
	<div class="resourcesOverview">
		<table class="villagesTable lined">
		<thead>
			<tr>
				<th translate=""><span>Деревня</span></th>
				<th class="resource"><i class="unit_wood_small_illu" tooltip="" tooltip-translate="wood"></i></th>
				<th class="resource"><i class="unit_clay_small_illu" tooltip="" tooltip-translate="clay"></i></th>
				<th class="resource"><i class="unit_iron_small_illu" tooltip="" tooltip-translate="iron"></i></th>
				<th class="resource"><i class="unit_crop_small_illu" tooltip="" tooltip-translate="crop"></i></th>
				<th translate=""><span>Торговцы</span></th>
			</tr>
		</thead>
		<tbody>
			<!-- ngRepeat: village in overview | orderBy:'villageName' --><tr ng-repeat="village in overview | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}" class="activeVillage">
				<td>
					<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">Брест</a></div>
				</td>
				<!-- ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 23272, total: 66000">
					23272
				</td><!-- end ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 9493, total: 66000">
					9493
				</td><!-- end ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 15965, total: 66000">
					15965
				</td><!-- end ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 49974, total: 80000">
					49974
				</td><!-- end ngRepeat: resource in village.resources track by $index -->
				<td>
					<!-- ngIf: village.merchants.total > 0 --><a clickable="openMarketPlace(village.villageId)" ng-if="village.merchants.total > 0" class="clickable">&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;20&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;20&#x202C;&#x202C;&#x202C;&#x202C;</a><!-- end ngIf: village.merchants.total > 0 -->
					<!-- ngIf: village.merchants.total <= 0 -->
				</td>
			</tr><!-- end ngRepeat: village in overview | orderBy:'villageName' --><tr ng-repeat="village in overview | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}">
				<td>
					<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">Глушица</a></div>
				</td>
				<!-- ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 1153, total: 9600">
					1153
				</td><!-- end ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 615, total: 9600">
					615
				</td><!-- end ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 1115, total: 9600">
					1115
				</td><!-- end ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 6662, total: 9600">
					6662
				</td><!-- end ngRepeat: resource in village.resources track by $index -->
				<td>
					<!-- ngIf: village.merchants.total > 0 --><a clickable="openMarketPlace(village.villageId)" ng-if="village.merchants.total > 0" class="clickable">&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</a><!-- end ngIf: village.merchants.total > 0 -->
					<!-- ngIf: village.merchants.total <= 0 -->
				</td>
			</tr><!-- end ngRepeat: village in overview | orderBy:'villageName' --><tr ng-repeat="village in overview | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}">
				<td>
					<div class="longTitle"><a clickable="setVillage(village.villageId)" class="truncated clickable">Зарница</a></div>
				</td>
				<!-- ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 6543, total: 18000">
					6543
				</td><!-- end ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 5646, total: 18000">
					5646
				</td><!-- end ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 5457, total: 18000">
					5457
				</td><!-- end ngRepeat: resource in village.resources track by $index --><td ng-repeat="resource in village.resources track by $index" tooltip="" tooltip-class="storageTooltip" tooltip-translate="Ratio" tooltip-data="value: 16338, total: 18000">
					16338
				</td><!-- end ngRepeat: resource in village.resources track by $index -->
				<td>
					<!-- ngIf: village.merchants.total > 0 --><a clickable="openMarketPlace(village.villageId)" ng-if="village.merchants.total > 0" class="clickable">&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;6&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;6&#x202C;&#x202C;&#x202C;&#x202C;</a><!-- end ngIf: village.merchants.total > 0 -->
					<!-- ngIf: village.merchants.total <= 0 -->
				</td>
			</tr><!-- end ngRepeat: village in overview | orderBy:'villageName' -->
		</tbody>
		<tfoot>
			<tr>
				<td translate=""><span>Всего</span></td>
				<!-- ngRepeat: resource in total.resources track by $index --><td ng-repeat="resource in total.resources track by $index">
					30967
				</td><!-- end ngRepeat: resource in total.resources track by $index --><td ng-repeat="resource in total.resources track by $index">
					15754
				</td><!-- end ngRepeat: resource in total.resources track by $index --><td ng-repeat="resource in total.resources track by $index">
					22537
				</td><!-- end ngRepeat: resource in total.resources track by $index --><td ng-repeat="resource in total.resources track by $index">
					72974
				</td><!-- end ngRepeat: resource in total.resources track by $index -->
				<td>
					&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;27&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;27&#x202C;&#x202C;&#x202C;&#x202C;
				</td>
			</tr>
		</tfoot>


	</table>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
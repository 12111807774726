<template>
	<div ng-if="overlayController" class="windowOverlay" id="abdicateGovernor" ng-controller="overlayController">
		<div class="darkener clickable" @click="gameModel.society.kingdomLeaveShow = false" clickable="closeOverlay()"></div>
		<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="749">
			<div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
				<div class="inWindowPopupHeader">
					<h4 translate="" options="Society.Kingdom.Abdicate.Governor" ng-if="overlayConfig['inWindowPopupTitle'] != ''">
						<span>Покинуть королевство</span>
					</h4>
					<h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4>
					<a class="closeOverlay clickable" @click="gameModel.society.kingdomLeaveShow = false" clickable="closeOverlay()" translate="">
						<span>Закрыть</span>
					</a>
				</div>
				<div class="inWindowPopupContent">
					<div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']">
						<div class="contentWrapper">
							<i class="decorationIcon community_kingdom_large_illu"></i>
							<div class="verticalLine double"></div>
							<div class="text">
								<span translate="" ng-if="governorPlayerId == -1">
									<span>Ты действительно желаешь покинуть свое королевство? Ты не сможешь повторно вступить в королевство, не получив нового приглашения от короля или вице-короля.</span>
								</span>
							</div>
						</div>
						<div class="buttonFooter">
							<button clickable="confirmAbdicate();" class="clickable">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate="" ng-if="governorPlayerId == -1">
											<span>Покинуть</span>
										</span>
									</span>
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
							<button @click="gameModel.society.kingdomLeaveShow = false; onWindowOverlayCancel();" clickable="closeOverlay();" class="cancel clickable">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate="">
											<span>Отмена</span>
										</span>
									</span>
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Капканщик</span></span>
									<span class="level">
										(<span><span>Уровень 0</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>Капканщик ставит хорошо спрятанные ловушки вокруг твоей деревни. Неосторожные нападающие попадают в них и больше не представляют угрозы для деревни.</span>
								</div>
								<img class="buildingHuge buildingType36 tribeId3 level10" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_36">
								<div class="buildingStatus location19 optionMenu" ng-class="{disabled: upgradeButtonClass == 'notAtAll' || upgradeButtonClass == 'maxLevel', busy: busy, queued: finished < 0, waiting: waiting, master: finished > 0 && !busy, optionMenu: upgradeButtonClass == 'notNow' || premiumOptionMenu.locationId == building.data.locationId}" ng-on-pointer-over="premiumOptionMenu.locationId != building.data.locationId && upgradeButtonClass == 'notNow' && !isRubble ? upgradeBuilding() : ''" tooltip="" tooltip-url="tpl/building/partials/upgradeCostsTooltip.html" tooltip-class="buildingTooltip" tooltip-placement="above" tooltip-offset="10" tooltip-hide="false" tooltip-hide-on-click="false">
									<div class="buildingBubble clickable disabledHover" ng-class="{disabled: upgradeButtonDisabled === true || premiumOptionMenu.locationId == building.data.locationId, disabledHover: upgradeButtonClass == 'notNow'}">
										<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', dismantle: isRubble, enoughRes: !isRubble && enoughRes && freeMasterSlots > 0}">
											<div class="labelLayer">
												<span ng-if="!isRubble" class="buildingLevel">10</span>
												<!-- ngIf: isRubble -->
												<!-- ngIf: upgradeButtonClass == 'maxLevel' -->
											</div>
										</div>
									</div>
									<!-- ngIf: premiumOptionMenu.locationId == building.data.locationId -->
									<!-- ngIf: finished > 0 -->
									<!-- ngIf: finished < 0 -->
									<!-- ngIf: masterBuilderCount > 0 -->
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="buildingIndexCtrl" class="building buildingType36" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
														<div ng-include="" src="pageTemplate">
															<div class="buildingDetails trapper" ng-controller="trapperCtrl">
																<div ng-show="isBuildingBuild()">
																	<div ng-include="" src="'tpl/building/partials/lists/traps.html'">
																		<div class="contentBox trapsBuild unitList">
																			<div class="contentBoxBody">
																				<carousel-trap :items="[traps]" :cols="1" />
																				<div class="trapsOverview contentBox gradient">
																					<h6 class="contentBoxHeader headerTrapezoidal">
																						<div class="content"><span>Информация</span></div>
																					</h6>
																					<div class="contentBoxBody">
																						<table class="transparent">
																							<tbody>
																								<tr>
																									<th><span><span>Всего ловушек</span></span></th>
																									<th>{{traps.total}}</th>
																								</tr>
																								<tr>
																									<td><span><span>Использовано</span></span></td>
																									<td>
																										<span v-if="traps.used > 0">-</span>
																										<span>{{traps.used}}</span>
																									</td>
																								</tr>
																								<tr>
																									<td><span><span>Ремонтируется/строится</span></span></td>
																									<td>
																										<span v-if="traps.queueTotal > 0">-</span>
																										<span>{{traps.queueTotal}}</span>
																									</td>
																								</tr>
																								<tr>
																									<td><span><span>Свободно</span></span></td>
																									<td>{{traps.total - traps.used}}</td>
																								</tr>
																							</tbody>
																						</table>
																					</div>
																				</div>
																				<div class="itemInfo">
																					<span>
																						<strong>Ловушки</strong>
																						<span> (</span>
																						<span class="values">
																							<span class="amount wrapper">{{traps.total + traps.queueTotal}}</span>
																							<span>/</span>
																							<span class="capacity">{{traps.maxByBuildingLevel}}</span>
																						</span>
																						<span>)</span>
																					</span>
																					<div class="horizontalLine"></div>
																					<div v-if="traps.used > 0">
																						<span>Пленников можно освободить в пункте сбора.</span>
																					</div>
																					<div v-if="traps.used == 0">
																						<span>В настоящее время пленников нет.</span>
																					</div>
																				</div>
																				<display-resources class="costsFooter" :resources="allCosts" :time="traps.duration * traps.value" available="storage" />
																				<div class="sliderRow">
																					<div class="sliderArrow"></div>
																					<range-slider v-if="maxAvailable <= 0" :min="0" :max="0" v-model.number="traps.value" disabled style="width: 87%;" />
																					<range-slider v-if="maxAvailable > 0" :min="0" :max="maxAvailable" v-model.number="traps.value" style="width: 87%;" />
																					<npc-trader-button type="unitTrain" :costs="allCosts" :current-max="0" :current-limit="0" />
																				</div>
																			</div>
																		</div>
																		<button :class="{
																			'disabled': (traps.value <= 0) || (!gameModel.island.storage.hasEnoughResources(allCosts)) || (maxAvailable <= 0),
																		}" class="animate footerButton clickable" clickable="startTraining()" :content="traps.value <= 0 ? 'Укажите количество ловушек для постройки' : (!gameModel.island.storage.hasEnoughResources(allCosts) ? 'Недостатчно ресурсов' : (maxAvailable <= 0 ? 'Максимальное количество ловушек для этого капканщика достигнуто' : ''))" v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{
																			'TrainTroops.SetAmount': false,
																			'Error.NotEnoughRes': false,
																			'Trapper.MaxDueToLevelReached': true
																		}">
																			<div class="content">
																				<span class="innerText">
																					<span><span>Построить</span></span>
																				</span>
																				<!-- ngIf: price > 0 || forceGoldUsage -->
																				<!-- ngIf: price == -1 && !forceGoldUsage -->
																				<div class="animationOverlay"></div>
																				<i class="checkmark action_check_small_flat_white"></i>
																				<i class="checkmark action_check_small_flat_black"></i>
																				<i class="checkmark action_check_small_flat_red"></i>
																			</div>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import CarouselTrap from '@/components/elements/CarouselTrap';
import DisplayResources from '@/components/elements/DisplayResources';
import RangeSlider from '@/components/elements/RangeSlider';
import NpcTraderButton from '@/components/elements/NpcTraderButton';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Капканщик | IW'});
	},
	data: () => ({
		traps: {
			name: 'Ловушка',
			maxByBuildingLevel: 154,
			total: 130,
			used: 120,
			value: 1,
			queue: {
				count: 0,
			},
			queueTotal: 0,
			сosts: [
				35,
				30,
				10,
				20,
			],
			time: 1659445649,
			duration: 487,
		},
		
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {
		allCosts(){
			let trapsCosts = [];
			for(let [index, value] of Object.entries(this.traps.сosts)){
				trapsCosts[index] = value * this.traps.value;
			}
			return trapsCosts;
		},
		maxAvailable(){
			return this.traps.maxByBuildingLevel - this.traps.total - this.traps.queueTotal;
		},
	},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.maxAvailable > 0 || gameModel.island.storage.hasEnoughResources(allCosts)){
			this.traps.value = 1;
		} else {
			this.traps.value = 0;
		}
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {
		CarouselTrap,
		DisplayResources,
		RangeSlider,
		NpcTraderButton,
	},
}
</script>
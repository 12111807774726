<template>
	<div class="embassyArea" ng-controller="embassyOasesCtrl">
	<div class="contentBox gradient oasisSlots">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content"><span>Слоты оазисов в этой деревне</span></div>
		</div>
		<div class="contentBoxBody">
			<div v-for="(oasis, index) in oases" class="arrowContainer arrowDirectionTo" :class="{
				'disabled': building.lvl < oasis.lvl,
				'locked': building.lvl < oasis.lvl,
				'active': building.lvl >= oasis.lvl,
			}" @mouseover="oasis.hover = true" @mouseleave="oasis.hover = false" tooltip="" tooltip-show="false" tooltip-translate="Embassy.Oasis.DropdownAssignOasis.Tooltip.Vacation">
				<span class="arrowInside">{{index + 1}}</span>
				<span class="arrowOutside">
					<div class="contentWrapper" v-if="oasis.used != null">
						<span><span>Оазис</span></span>
						<span class="coordinateWrapper" coordinates="" aligned="true" x="-13" y="-15">
							<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '536395763_1723738402.5');" class="clickable">
								<span class="coordinates coordinatesWrapper coordinatesAligned">
									<span class="coordinateX">({{oasis.used.x}}</span>
									<span class="coordinatePipe">|</span>
									<span class="coordinateY">{{oasis.used.y}})</span>
								</span>
							</a>
							<!-- ngIf: !hasLink -->
						</span>
						<div class="resourceBonus">
							<display-resources :resources="oasis.used.bonus" :hide-zero="true" :percent="true" />
						</div>
						<div class="delete" v-if="oasis.hover">
							<i class="delete clickable action_delete_small_flat_black" :class="{
								'action_delete_small_flat_black': !oasis.deleteHover,
								'action_delete_small_flat_red': oasis.deleteHover,
							}" @mouseover="oasis.deleteHover = true" @mouseleave="oasis.deleteHover = false" clickable="clearOasis(536395763,536330229)" content="Отказаться от оазиса" v-tippy="{placement: 'bottom'}" tooltip-translate="Embassy.ClearOasis"></i>
						</div>
					</div>
					<div v-if="oasis.used == null">
						<div v-if="building.lvl >= oasis.lvl">
							<span ng-if="!player.isOnVacation()"><span>Доступно</span></span>
							<!-- ngIf: player.isOnVacation() -->
							
							<div class="dropdownContainer">
								<div class="dropdownHead openDownwards clickable" :class="{
									'closed': !oasis.dropdownShow,
									'opened': oasis.dropdownShow,
								}" @click="oasis.dropdownShow = !oasis.dropdownShow" clickable="clickDropdown()" style="width: 279px;">
									<span class="selectedItem empty">Использовать оазис</span>
									<i class="symbol_arrowDown_tiny_illu"></i>
								</div>
								<input class="dropdownInput" type="text"/>
							</div>
							<div class="dropdownBody oasisDropdown scrollable openDownwards" v-if="oasis.dropdownShow" style="display: block; right: 0;">
								<div class="scrollContentOuterWrapper" style="width: 277px;">
									<div class="scrollContent" dynamic-height="false" style="display: block; width: 277px; overflow-y: auto; max-height: 242px;">
										<div class="scrollContentInnerWrapper" style="width: auto;">
											<ul>
												<li v-for="option in availableOases" class="dropdownOption dropDownItem0" ng-class="{selected: '0' == data.selected}" clickable="selectItem(key)" style="display: flex; justify-content: space-between;" @mouseover="option.hover = true" @mouseleave="option.hover = false">
													<span class="coordinates coordinateWrapper coordinateWrapper">
														<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '536166386_1723738514.3');" class="clickable">
															<span class="coordinates coordinatesWrapper coordinatesAligned">
																<span class="coordinateX">({{option.x}}</span>
																<span class="coordinatePipe">|</span>
																<span class="coordinateY">{{option.y}})</span>
															</span>
														</a>
														<!-- ngIf: !hasLink -->
													</span>
													<display-resources :resources="option.bonus" :hide-zero="true" :percent="true" />
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="lockedSlot" v-if="building.lvl < oasis.lvl">
							<span><span>Доступно на уровне {{oasis.lvl}}</span></span>
							<div class="symbol_lock_small_wrapper">
								<i class="symbol_lock_small_flat_black"></i>
							</div>
						</div>
					</div>
				</span>
			</div>
		</div>
	</div>
	<div class="contentBox gradient oasisInRange">
		<div class="contentBoxHeader headerTrapezoidal">
			<div class="content"><span>Оазисы в пределах досягаемости</span></div>
		</div>
		<div class="contentBoxBody">
			<table class="fixedTableHeader scrollable">
				<thead>
					<tr>
						<th class="coordinates"><span>Оазис</span></th>
						<th class="kingdom"><span>Королевство</span></th>
						<th class="rank">
							<i class="symbol_ranking_small_flat_black" content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"></i>
						</th>
						<th class="resources"><span>Бонус</span></th>
						<th class="village">
							<i class="village_village_small_flat_black" content="Захвачен игроком" v-tippy="{placement: 'bottom'}" tooltip-translate="Embassy.Oasis.UsedByVillage"></i>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="oasis in oasesInReach" :class="{'disabled': oasis.isWild}">
						<td class="coordinates">
							<span class="coordinateWrapper" coordinates="" aligned="true" x="-11" y="-16">
								<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '536362997_1723738402.5');" class="clickable">
									<span class="coordinates coordinatesWrapper coordinatesAligned">
										<span class="coordinateX">({{oasis.x}}</span>
										<span class="coordinatePipe">|</span>
										<span class="coordinateY">{{oasis.y}})</span>
									</span>
								</a>
								<!-- ngIf: !hasLink -->
							</span>
						</td>
						<td class="kingdom">
							<span v-if="oasis.kingdomId == 0">-</span>
							<span v-if="oasis.kingdomId != 0">
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="172" playername="">{{oasis.kingdomName}}</a>
							</span>
						</td>
						<td class="rank">
							<span v-if="oasis.rank == 0">-</span>
							<span v-if="oasis.rank > 0">{{oasis.rank}}</span>
						</td>
						<td class="resources">
							<display-resources :resources="oasis.bonus" :percent="true" :hide-zero="true" />
						</td>
						<td class="village">
							<span v-if="oasis.usedByVillage">
								<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 0}, null, true)" village-link="" villageid="0" villagename="">{{oasis.villageName}}</a>
							</span>
							<span v-if="!oasis.usedByVillage">
								<span v-if="!oasis.isWild"><span>Не присоединено</span></span>
								<span v-if="oasis.isWild"><span>Дикий оазис</span></span>
							</span>
						</td>
					</tr>
					<tr v-if="oasesInReach.length == 0">
						<td class="noOasesInReach">
							<span><span>Нет оазисов в пределах досягаемости.</span></span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import DisplayResources from '@/components/elements/DisplayResources';

export default {
	mixins: lib.mixins,
	data: () => ({
		oases: [
			{
				lvl: 1,
				hover: false,
				deleteHover: false,
				dropdownShow: false,
				used: {
					x: '-13',
					y: '-15',
					bonus: [5, 0, 0, 5],
				},
			},
			{
				lvl: 10,
				hover: false,
				deleteHover: false,
				dropdownShow: false,
				used: null,
			},
			{
				lvl: 20,
				hover: false,
				deleteHover: false,
				dropdownShow: false,
				used: null,
			},
		],
		oasesInReach: [
			{
				x: '-11',
				y: '-16',
				kingdomId: 1,
				kingdomName: 'Царевич',
				rank: 1,
				bonus: [0, 0, 25, 25],
				usedByVillage: null,
				villageName: null,
				isWild: false,
			},
			{
				x: '-14',
				y: '-14',
				kingdomId: 0,
				kingdomName: null,
				rank: 1,
				bonus: [0, 0, 25, 0],
				usedByVillage: null,
				villageName: null,
				isWild: true,
			},
			{
				x: '-11',
				y: '-19',
				kingdomId: 1,
				kingdomName: 'Царевич',
				rank: 2,
				bonus: [0, 0, 20, 20],
				usedByVillage: null,
				villageName: null,
				isWild: false,
			},
			{
				x: '-10',
				y: '-20',
				kingdomId: 1,
				kingdomName: 'Царевич',
				rank: 3,
				bonus: [0, 0, 15, 0],
				usedByVillage: null,
				villageName: null,
				isWild: false,
			},
			{
				x: '-15',
				y: '-20',
				kingdomId: 1,
				kingdomName: 'Царевич',
				rank: 5,
				bonus: [0, 5, 0, 5],
				usedByVillage: 1,
				villageName: 'Зарница',
				isWild: false,
			},
			{
				x: '-13',
				y: '-15',
				kingdomId: 1,
				kingdomName: 'Царевич',
				rank: 5,
				bonus: [5, 0, 0, 5],
				usedByVillage: 1,
				villageName: 'Брест',
				isWild: false,
			},
			{
				x: '-14',
				y: '-22',
				kingdomId: 1,
				kingdomName: 'Царевич',
				rank: 6,
				bonus: [0, 5, 0, 5],
				usedByVillage: 1,
				villageName: 'Глушица',
				isWild: false,
			},
			{
				x: '-9',
				y: '-21',
				kingdomId: 1,
				kingdomName: 'Царевич',
				rank: 6,
				bonus: [0, 0, 0, 10],
				usedByVillage: null,
				villageName: null,
				isWild: false,
			},
		],
		building: { // информация о текущем строении (посольство)
			lvl: 10,
		},
	}),
	computed: {
		availableOases(){
			return this.oasesInReach
				.filter(el => !el.isWild && this.oases.findIndex(el2 => el2.used && el2.used.x == el.x && el2.used.y == el.y) == -1)
				.map(el => {
					el.hover = false;
					return el;
				});
		},
	},
	methods: {},
	components: {
		DisplayResources,
	},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper kingdom" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal kingdom">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Королевство</span></span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
				<div ng-controller="kingdomPublicProfileCtrl" class="kingdom">
  	<!-- ngInclude:  --><div ng-include="" src="'tpl/society/partials/kingdom/Profile.html'"><div ng-controller="kingdomProfileCtrl" class="profile">
	<div class="innerProfile">
		<!-- ngIf: player.isKing() && !isSitter && player.data.kingdomId == kingdom.data.groupId -->
		<!-- ngIf: player.isGovernor() && player.data.kingdomId == kingdom.data.groupId -->
		<!-- ngIf: !kingdom.isUnited() --><div ng-if="!kingdom.isUnited()" class="kingProfile">
			<player-profile playerid="127" kingdomprofile="Roman"><div class="playerProfile">
	<div class="detailsWrapper">
		<div class="contentBox gradient">
			<!-- ngIf: !kingdomProfile -->
			<div class="contentBoxHeader headerWithArrowEndings kingdomRole kingdomRole kingdomRole1">
				<!-- ngIf: !kingdomProfile -->
				<!-- ngIf: kingdomProfile --><div class="content" ng-if="kingdomProfile">Roman</div><!-- end ngIf: kingdomProfile -->
			</div>
			<div class="contentBoxBody">
				<div class="playerDetails">
					<div class="contentBox transparent">
						<div class="contentBoxHeader">
							<!-- ngIf: !kingdomProfile --><!-- end ngIf: !kingdomProfile -->
							<!-- ngIf: kingdomProfile --><div ng-if="kingdomProfile">
								<i class="community_kingdom_small_flat_black"></i>
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="127" playername="Gaius Ulius">Gaius Ulius</a>
							</div><!-- end ngIf: kingdomProfile -->
							<!-- ngIf: ::playerInfo.language --><i ng-if="::playerInfo.language" class="languageFlag ru" tooltip="ru"></i><!-- end ngIf: ::playerInfo.language -->
						</div>
						<div class="contentBoxBody">
							<div>
								<span class="desc" translate=""><span>Ранг</span></span>
								<span class="data">46</span>
							</div>
							<div>
								<span class="desc" translate=""><span>Население</span></span>
								<span class="data">1614</span>
							</div>
							<div>
								<span class="desc" translate=""><span>Деревни</span></span>
								<span class="data">3</span>
							</div>
							<!-- ngIf: !kingdomProfile -->
							<div>
								<span class="desc" translate=""><span>Народ</span></span>
								<span class="data" translate="" options="1"><span>Римляне</span></span>
							</div>
						</div>
					</div>
				</div>
				<div class="playerProfileAvatar kingdomRole1">
					<div class="avatarLink clickable" clickable="kingdomProfile ? openWindow('profile', {'playerId': 127 }, null, true) : openOverlay('playerProfileFullImage', {'playerId': 127 });">
						<avatar-image scale="0.55" class="avatarImage" player-id="127" size="big" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="192" height="193"></canvas></div>
</avatar-image>
						<!-- ngIf: config.balancing.features.prestige --><div class="prestigeStars" ng-if="config.balancing.features.prestige">
							<div class="innerStars" stars="playerData.stars" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
						</div><!-- end ngIf: config.balancing.features.prestige -->
					</div>

					<span class="decoration"></span>
					<!-- ngIf: myPlayerId == playerId && config.balancing.features.prestige -->
					<!-- ngIf: myPlayerId != playerId && config.balancing.features.prestige --><div ng-if="myPlayerId != playerId &amp;&amp; config.balancing.features.prestige" class="prestigeStarsTooltip" tooltip="" tooltip-translate="Prestige.Stars.Tooltip.Other"></div><!-- end ngIf: myPlayerId != playerId && config.balancing.features.prestige -->
				</div>
				<div class="kingdomDetails">
					<div class="background profile_kingdomBackground_layout"></div>
					<div class="contentBox transparent">
						<div class="contentBoxHeader">
							<!-- ngIf: playerData.kingdomId > 0 --><span class="data" ng-if="playerData.kingdomId > 0" translate=""><span>Королевство</span></span><!-- end ngIf: playerData.kingdomId > 0 -->
							<!-- ngIf: playerData.kingdomId == 0 -->
						</div>
						<!-- ngIf: playerData.kingdomId <= 0 -->
						<!-- ngIf: playerData.kingdomId > 0 --><div class="contentBoxBody" ng-if="playerData.kingdomId > 0">
							<!-- ngIf: playerData.kingdomRole != ROLE_KING -->
							<div>
								<span class="desc" translate=""><span>Ранг</span></span>
								<!-- ngIf: kingdomStats.rank >= 0 --><span class="data" ng-if="kingdomStats.rank >= 0">4</span><!-- end ngIf: kingdomStats.rank >= 0 -->
								<!-- ngIf: kingdomStats.rank === false -->
							</div>
							<div>
								<span class="desc" translate=""><span>Победные очки</span></span>
								<!-- ngIf: kingdomStats.victoryPoints > 0 --><span class="data" ng-if="kingdomStats.victoryPoints > 0">69&nbsp;809</span><!-- end ngIf: kingdomStats.victoryPoints > 0 -->
								<!-- ngIf: kingdomStats.victoryPoints === false -->
							</div>
							<div>
								<span class="desc" translate=""><span>Сокровища</span></span>
								<!-- ngIf: kingdomStats.treasures --><span class="data" ng-if="kingdomStats.treasures">5&nbsp;530</span><!-- end ngIf: kingdomStats.treasures -->
								<!-- ngIf: !kingdomStats.treasures -->
							</div>
							<div>
								<span class="desc" translate=""><span>Срок (дни)</span></span>
								<span class="data">34</span>
							</div>
						</div><!-- end ngIf: playerData.kingdomId > 0 -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- ngIf: kingdomProfile --><!-- ngInclude:  --><div ng-include="" ng-if="kingdomProfile" src="'tpl/society/partials/kingdom/wwProgress.html'"><!-- ngIf: kingdomStats && kingdomStats.worldWonderLvl !== false --></div><!-- end ngIf: kingdomProfile -->
</div></player-profile>
			<chat-online-status class="kingStatus" chat-user="kingdom.getKing().chatUser"><!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE --></chat-online-status>
		</div><!-- end ngIf: !kingdom.isUnited() -->
		<!-- ngIf: kingdom.isUnited() -->
		<div ng-if="kingdom.isUnited()" class="unitedKingdomProfile">
			<div class="contentBox gradient">
				<div class="contentBoxHeader headerWithArrowEndings golden">
					<div class="content">фаршмелы</div>
				</div>
				<div class="contentBoxBody">
					<div class="playerProfileAvatar kingdomRole1">
						<div class="headerWithArrowEndings kingdomRole kingdomRole1">
							<div class="content"><span class="truncated">ингибитор</span></div>
						</div>
						<chat-online-status class="kingStatus" chat-user="kingdom.getKing().chatUser"><!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE --></chat-online-status>
						<div class="avatarLink clickable" clickable="openWindow('profile', {'playerId': 163 }, null, true);">
							<avatar-image scale="0.55" class="avatarImage" player-id="163" size="big" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="192" height="192"></canvas></div>
</avatar-image>
							<!-- ngIf: config.balancing.features.prestige --><div class="prestigeStars" ng-if="config.balancing.features.prestige">
								<div class="innerStars" playerid="163" size="tiny">
	<!-- ngIf: !badge -->
	<!-- ngIf: badge --><i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i><!-- end ngIf: badge -->
</div>
							</div><!-- end ngIf: config.balancing.features.prestige -->
						</div>
						<span class="decoration"></span>
					</div>
					<div class="playerProfileAvatar kingdomRole3">
						<div class="headerWithArrowEndings kingdomRole kingdomRole3">
							<div class="content"><span class="truncated">Маурелиан</span></div>
						</div>
						<chat-online-status class="kingStatus" chat-user="kingdom.getViceKing().chatUser"><!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE --></chat-online-status>
						<div class="avatarLink clickable" ng-class="{disabled: !viceKingId}" clickable="viceKingId > 0 ? openWindow('profile', {'playerId': viceKingId}, null, true) : null;">
							<avatar-image scale="0.55" class="avatarImage" player-id="548" size="big" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="192" height="193"></canvas></div>
</avatar-image>
							<!-- ngIf: config.balancing.features.prestige --><div class="prestigeStars" ng-if="config.balancing.features.prestige">
								<div class="innerStars" playerid="548" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
							</div><!-- end ngIf: config.balancing.features.prestige -->
						</div>
						<span class="decoration"></span>
					</div>
					<!-- ngIf: kingdomStats --><div ng-if="kingdomStats" class="unitedKingdomStats profile_kingdomStats_layout">
						<div class="statRow rank">
							<!-- ngIf: kingdomStats.rank >= 0 && kingdomStats.victoryPoints !== false --><div class="statValue" ng-if="kingdomStats.rank >= 0 &amp;&amp; kingdomStats.victoryPoints !== false">2.</div><!-- end ngIf: kingdomStats.rank >= 0 && kingdomStats.victoryPoints !== false -->
							<!-- ngIf: kingdomStats.rank == 0 && kingdomStats.victoryPoints === false -->
							<div translate=""><span>Ранг</span></div>
						</div>
						<div class="statRow victoryPoints">
							<!-- ngIf: kingdomStats.victoryPoints --><div class="statValue" ng-if="kingdomStats.victoryPoints">127&nbsp;099</div><!-- end ngIf: kingdomStats.victoryPoints -->
							<!-- ngIf: kingdomStats.victoryPoints === false -->
							<div translate=""><span>Победные очки</span></div>
						</div>
						<div class="statRow treasures">
							<!-- ngIf: kingdomStats.treasures --><div class="statValue" ng-if="kingdomStats.treasures">10&nbsp;679</div><!-- end ngIf: kingdomStats.treasures -->
							<!-- ngIf: !kingdomStats.treasures -->
							<div translate=""><span>Сокровища</span></div>
						</div>
						<div class="statRow age">
							<div class="statValue">36</div>
							<div translate=""><span>Срок (дни)</span></div>
						</div>
					</div><!-- end ngIf: kingdomStats -->
				</div>
			</div>
			<!-- ngInclude:  --><div ng-include="" src="'tpl/society/partials/kingdom/wwProgress.html'"><!-- ngIf: kingdomStats && kingdomStats.worldWonderLvl !== false --></div>
		</div>
		<!-- end ngIf: kingdom.isUnited() -->

		<!-- ngIf: unionAvailable || alreadyInvited -->

		<!-- ngIf: dukeSlots --><div class="contentBox dukeSlots" ng-if="dukeSlots" ng-class="{'myKingdom': myKingdom, 'unitedKingdom': kingdom.isUnited()}">
			<div class="contentBoxHeader headerWithArrowEndings purple">
				<div class="content" translate=""><span>Герцоги королевства</span></div>
			</div>
			<div class="contentBoxBody clear">
				<!-- ngRepeat: (id, slot) in dukeSlots --><div ng-repeat="(id, slot) in dukeSlots" class="dukeSlot">
					<div class="playerProfileAvatar small active kingdomRole2" clickable="dukeAction(slot)" on-pointer-over="dukeHover = true" on-pointer-out="dukeHover = false" tooltip="" tooltip-translate-switch="{'Kingdom.Dukes.Slots.Tooltip.cancelInvitation': false,
															'Kingdom.Dukes.Slots.Tooltip.PromoteDuke.Description': false }">
						<div class="headerWithArrowEndings kingdomRole kingdomRole2">
							<!-- ngIf: slot.duke --><div class="content" ng-if="slot.duke">
								<span class="truncated">Scepa</span>
							</div><!-- end ngIf: slot.duke -->
							<!-- ngIf: !slot.duke -->
						</div>
						<!-- ngIf: slot.type == 'active' --><div ng-if="slot.type == 'active'">
							<!-- ngIf: myKingdom || slot.duke.playerId === player.data.playerId -->
							<chat-online-status chat-user="slot.duke.chatUser"><!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE --></chat-online-status>
						</div><!-- end ngIf: slot.type == 'active' -->
						<!-- ngIf: slot.type == 'invitation' -->
						<!-- ngIf: slot.type == 'available' && myKingdom -->

						<div class="avatarLink">
							<avatar-image class="avatarImage" player-id="115" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="125" height="125"></canvas></div>
</avatar-image>
							<!-- ngIf: config.balancing.features.prestige --><div class="prestigeStars" ng-if="config.balancing.features.prestige">
								<div class="innerStars" playerid="115" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
							</div><!-- end ngIf: config.balancing.features.prestige -->
						</div>
						<span class="decoration"></span>
					</div>
				</div><!-- end ngRepeat: (id, slot) in dukeSlots --><div ng-repeat="(id, slot) in dukeSlots" class="dukeSlot">
					<div class="playerProfileAvatar small active kingdomRole2" clickable="dukeAction(slot)" on-pointer-over="dukeHover = true" on-pointer-out="dukeHover = false" tooltip="" tooltip-translate-switch="{'Kingdom.Dukes.Slots.Tooltip.cancelInvitation': false,
															'Kingdom.Dukes.Slots.Tooltip.PromoteDuke.Description': false }">
						<div class="headerWithArrowEndings kingdomRole kingdomRole2">
							<!-- ngIf: slot.duke --><div class="content" ng-if="slot.duke">
								<span class="truncated">Mild</span>
							</div><!-- end ngIf: slot.duke -->
							<!-- ngIf: !slot.duke -->
						</div>
						<!-- ngIf: slot.type == 'active' --><div ng-if="slot.type == 'active'">
							<!-- ngIf: myKingdom || slot.duke.playerId === player.data.playerId -->
							<chat-online-status chat-user="slot.duke.chatUser"><!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE --></chat-online-status>
						</div><!-- end ngIf: slot.type == 'active' -->
						<!-- ngIf: slot.type == 'invitation' -->
						<!-- ngIf: slot.type == 'available' && myKingdom -->

						<div class="avatarLink">
							<avatar-image class="avatarImage" player-id="314" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="125" height="125"></canvas></div>
</avatar-image>
							<!-- ngIf: config.balancing.features.prestige --><div class="prestigeStars" ng-if="config.balancing.features.prestige">
								<div class="innerStars" playerid="314" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
							</div><!-- end ngIf: config.balancing.features.prestige -->
						</div>
						<span class="decoration"></span>
					</div>
				</div><!-- end ngRepeat: (id, slot) in dukeSlots -->
			</div>
		</div><!-- end ngIf: dukeSlots -->
		<!-- ngIf: dukeSlots -->
		<div class="contentBox dukeSlots unitedKingdom" ng-if="dukeSlots" ng-class="{'myKingdom': myKingdom, 'unitedKingdom': kingdom.isUnited()}">
			<div class="contentBoxHeader headerWithArrowEndings purple">
				<div class="content" translate=""><span>Герцоги королевства</span></div>
			</div>
			<div class="contentBoxBody clear">
				<!-- ngRepeat: (id, slot) in dukeSlots --><div ng-repeat="(id, slot) in dukeSlots" class="dukeSlot">
					<div class="playerProfileAvatar small active kingdomRole2" clickable="dukeAction(slot)" on-pointer-over="dukeHover = true" on-pointer-out="dukeHover = false" tooltip="" tooltip-translate-switch="{'Kingdom.Dukes.Slots.Tooltip.cancelInvitation': false,
															'Kingdom.Dukes.Slots.Tooltip.PromoteDuke.Description': false }">
						<div class="headerWithArrowEndings kingdomRole kingdomRole2">
							<!-- ngIf: slot.duke --><div class="content" ng-if="slot.duke">
								<span class="truncated">Nakusi</span>
							</div><!-- end ngIf: slot.duke -->
							<!-- ngIf: !slot.duke -->
						</div>
						<!-- ngIf: slot.type == 'active' --><div ng-if="slot.type == 'active'">
							<!-- ngIf: myKingdom || slot.duke.playerId === player.data.playerId -->
							<chat-online-status chat-user="slot.duke.chatUser"><!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE --></chat-online-status>
						</div><!-- end ngIf: slot.type == 'active' -->
						<!-- ngIf: slot.type == 'invitation' -->
						<!-- ngIf: slot.type == 'available' && myKingdom -->

						<div class="avatarLink">
							<avatar-image class="avatarImage" player-id="186" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="125" height="125"></canvas></div>
</avatar-image>
							<!-- ngIf: config.balancing.features.prestige --><div class="prestigeStars" ng-if="config.balancing.features.prestige">
								<div class="innerStars" playerid="186" size="tiny">
	<!-- ngIf: !badge -->
	<!-- ngIf: badge --><i ng-if="badge" class="prestigeBadge prestige_silverBadge_tiny_illu"></i><!-- end ngIf: badge -->
</div>
							</div><!-- end ngIf: config.balancing.features.prestige -->
						</div>
						<span class="decoration"></span>
					</div>
				</div><!-- end ngRepeat: (id, slot) in dukeSlots --><div ng-repeat="(id, slot) in dukeSlots" class="dukeSlot">
					<div class="playerProfileAvatar small active kingdomRole2" clickable="dukeAction(slot)" on-pointer-over="dukeHover = true" on-pointer-out="dukeHover = false" tooltip="" tooltip-translate-switch="{'Kingdom.Dukes.Slots.Tooltip.cancelInvitation': false,
															'Kingdom.Dukes.Slots.Tooltip.PromoteDuke.Description': false }">
						<div class="headerWithArrowEndings kingdomRole kingdomRole2">
							<!-- ngIf: slot.duke --><div class="content" ng-if="slot.duke">
								<span class="truncated">НАМОР</span>
							</div><!-- end ngIf: slot.duke -->
							<!-- ngIf: !slot.duke -->
						</div>
						<!-- ngIf: slot.type == 'active' --><div ng-if="slot.type == 'active'">
							<!-- ngIf: myKingdom || slot.duke.playerId === player.data.playerId -->
							<chat-online-status chat-user="slot.duke.chatUser"><!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE --></chat-online-status>
						</div><!-- end ngIf: slot.type == 'active' -->
						<!-- ngIf: slot.type == 'invitation' -->
						<!-- ngIf: slot.type == 'available' && myKingdom -->

						<div class="avatarLink">
							<avatar-image class="avatarImage" player-id="141" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="125" height="125"></canvas></div>
</avatar-image>
							<!-- ngIf: config.balancing.features.prestige --><div class="prestigeStars" ng-if="config.balancing.features.prestige">
								<div class="innerStars" playerid="141" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
							</div><!-- end ngIf: config.balancing.features.prestige -->
						</div>
						<span class="decoration"></span>
					</div>
				</div><!-- end ngRepeat: (id, slot) in dukeSlots --><div ng-repeat="(id, slot) in dukeSlots" class="dukeSlot">
					<div class="playerProfileAvatar small active kingdomRole2" clickable="dukeAction(slot)" on-pointer-over="dukeHover = true" on-pointer-out="dukeHover = false" tooltip="" tooltip-translate-switch="{'Kingdom.Dukes.Slots.Tooltip.cancelInvitation': false,
															'Kingdom.Dukes.Slots.Tooltip.PromoteDuke.Description': false }">
						<div class="headerWithArrowEndings kingdomRole kingdomRole2">
							<!-- ngIf: slot.duke --><div class="content" ng-if="slot.duke">
								<span class="truncated">pavluar</span>
							</div><!-- end ngIf: slot.duke -->
							<!-- ngIf: !slot.duke -->
						</div>
						<!-- ngIf: slot.type == 'active' --><div ng-if="slot.type == 'active'">
							<!-- ngIf: myKingdom || slot.duke.playerId === player.data.playerId -->
							<chat-online-status chat-user="slot.duke.chatUser"><!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE --></chat-online-status>
						</div><!-- end ngIf: slot.type == 'active' -->
						<!-- ngIf: slot.type == 'invitation' -->
						<!-- ngIf: slot.type == 'available' && myKingdom -->

						<div class="avatarLink">
							<avatar-image class="avatarImage" player-id="579" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="125" height="125"></canvas></div>
</avatar-image>
							<!-- ngIf: config.balancing.features.prestige --><div class="prestigeStars" ng-if="config.balancing.features.prestige">
								<div class="innerStars" playerid="579" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
							</div><!-- end ngIf: config.balancing.features.prestige -->
						</div>
						<span class="decoration"></span>
					</div>
				</div><!-- end ngRepeat: (id, slot) in dukeSlots --><div ng-repeat="(id, slot) in dukeSlots" class="dukeSlot">
					<div class="playerProfileAvatar small active kingdomRole2" clickable="dukeAction(slot)" on-pointer-over="dukeHover = true" on-pointer-out="dukeHover = false" tooltip="" tooltip-translate-switch="{'Kingdom.Dukes.Slots.Tooltip.cancelInvitation': false,
															'Kingdom.Dukes.Slots.Tooltip.PromoteDuke.Description': false }">
						<div class="headerWithArrowEndings kingdomRole kingdomRole2">
							<!-- ngIf: slot.duke --><div class="content" ng-if="slot.duke">
								<span class="truncated">Igor</span>
							</div><!-- end ngIf: slot.duke -->
							<!-- ngIf: !slot.duke -->
						</div>
						<!-- ngIf: slot.type == 'active' --><div ng-if="slot.type == 'active'">
							<!-- ngIf: myKingdom || slot.duke.playerId === player.data.playerId -->
							<chat-online-status chat-user="slot.duke.chatUser"><!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE --></chat-online-status>
						</div><!-- end ngIf: slot.type == 'active' -->
						<!-- ngIf: slot.type == 'invitation' -->
						<!-- ngIf: slot.type == 'available' && myKingdom -->

						<div class="avatarLink">
							<avatar-image class="avatarImage" player-id="1895" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="125" height="125"></canvas></div>
</avatar-image>
							<!-- ngIf: config.balancing.features.prestige --><div class="prestigeStars" ng-if="config.balancing.features.prestige">
								<div class="innerStars" playerid="1895" size="tiny">
	<!-- ngIf: !badge -->
	<!-- ngIf: badge --><i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i><!-- end ngIf: badge -->
</div>
							</div><!-- end ngIf: config.balancing.features.prestige -->
						</div>
						<span class="decoration"></span>
					</div>
				</div><!-- end ngRepeat: (id, slot) in dukeSlots -->
			</div>
		</div>
		<!-- end ngIf: dukeSlots -->
		

		<div class="statisticsTable contentBox transparent kingdomGovernors">
			<div class="contentBoxHeader headerWithArrowEndings tableGreen">
				<div class="content" translate=""><span>Губернаторы</span></div>
			</div>
			<div class="contentBoxBody">
				<div class="governors">
					<table class="gradient">
						<thead>
							<tr>
								<th><i class="symbol_ranking_small_flat_black"></i></th>
								<th><i class="community_member_small_flat_black"></i></th>
								<th><i class="unit_population_small_flat_black"></i></th>
							</tr>
						</thead>
						<tbody>
							<!-- ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="637" player-name="Неясыть" rank="0" points="2319" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank1" ng-if="rank >= 0">1</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="637" player-name="Неясыть" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="637" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="637" playername="Неясыть" nolink="false">Неясыть</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="637" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">2&nbsp;319</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="225" player-name="Ile4ka88" rank="1" points="1808" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank2" ng-if="rank >= 0">2</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="225" player-name="Ile4ka88" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="225" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="225" playername="Ile4ka88" nolink="false">Ile4ka88</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="225" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;808</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="192" player-name="KING" rank="2" points="1718" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank3" ng-if="rank >= 0">3</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="192" player-name="KING" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="192" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="192" playername="KING" nolink="false">KING</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="192" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;718</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="756" player-name="pavlun" rank="3" points="1652" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank4" ng-if="rank >= 0">4</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="756" player-name="pavlun" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="756" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="756" playername="pavlun" nolink="false">pavlun</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="756" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
		<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;652</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="131" player-name="Tils" rank="4" points="1587" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank5" ng-if="rank >= 0">5</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="131" player-name="Tils" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="131" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="131" playername="Tils" nolink="false">Tils</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="131" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;587</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="528" player-name="Olaf" rank="5" points="1492" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank6" ng-if="rank >= 0">6</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="528" player-name="Olaf" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="528" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="528" playername="Olaf" nolink="false">Olaf</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="528" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;492</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="455" player-name="Олово" rank="6" points="1379" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank7" ng-if="rank >= 0">7</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="455" player-name="Олово" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="455" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="455" playername="Олово" nolink="false">Олово</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="455" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;379</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="204" player-name="Карабас" rank="7" points="1355" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank8" ng-if="rank >= 0">8</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="204" player-name="Карабас" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="204" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="204" playername="Карабас" nolink="false">Карабас</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="204" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;355</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="263" player-name="Chega" rank="8" points="1343" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank9" ng-if="rank >= 0">9</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="263" player-name="Chega" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="263" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="263" playername="Chega" nolink="false">Chega</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="263" size="tiny">
	<!-- ngIf: !badge -->
	<!-- ngIf: badge --><i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i><!-- end ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;343</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="610" player-name="ппц" rank="9" points="1262" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank10" ng-if="rank >= 0">10</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="610" player-name="ппц" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="610" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="610" playername="ппц" nolink="false">ппц</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="610" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;262</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="629" player-name="LatR" rank="10" points="1222" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank11" ng-if="rank >= 0">11</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="629" player-name="LatR" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="629" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="629" playername="LatR" nolink="false">LatR</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="629" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;222</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="662" player-name="Пригожин" rank="11" points="1002" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank12" ng-if="rank >= 0">12</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="662" player-name="Пригожин" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="662" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="662" playername="Пригожин" nolink="false">Пригожин</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="662" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">1&nbsp;002</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="749" player-name="av_p001" rank="12" points="966" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank13" ng-if="rank >= 0">13</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="749" player-name="av_p001" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="749" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="749" playername="av_p001" nolink="false">av_p001</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="749" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">966</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="391" player-name="Diego" rank="13" points="957" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank14" ng-if="rank >= 0">14</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="391" player-name="Diego" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="391" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="391" playername="Diego" nolink="false">Diego</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="391" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">957</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="125" player-name="сербез" rank="14" points="942" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank15" ng-if="rank >= 0">15</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="125" player-name="сербез" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="125" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="125" playername="сербез" nolink="false">сербез</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="125" size="tiny">
	<!-- ngIf: !badge -->
	<!-- ngIf: badge --><i ng-if="badge" class="prestigeBadge prestige_silverBadge_tiny_illu"></i><!-- end ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">942</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="170" player-name="VIX" rank="15" points="905" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank16" ng-if="rank >= 0">16</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="170" player-name="VIX" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="170" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="170" playername="VIX" nolink="false">VIX</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="170" size="tiny">
	<!-- ngIf: !badge -->
	<!-- ngIf: badge --><i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i><!-- end ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">905</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="1212" player-name="safarich" rank="16" points="825" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank17" ng-if="rank >= 0">17</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="1212" player-name="safarich" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="1212" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1212" playername="safarich" nolink="false">safarich</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="1212" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">825</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="1156" player-name="ВАРЯГ" rank="17" points="821" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank18" ng-if="rank >= 0">18</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="1156" player-name="ВАРЯГ" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="1156" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1156" playername="ВАРЯГ" nolink="false">ВАРЯГ</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="1156" size="tiny">
	<!-- ngIf: !badge -->
	<!-- ngIf: badge --><i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i><!-- end ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">821</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="731" player-name="doky45" rank="18" points="795" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank19" ng-if="rank >= 0">19</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="731" player-name="doky45" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="731" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="731" playername="doky45" nolink="false">doky45</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="731" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starGold_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">795</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors --><tr ng-repeat="governor in governors" on-pointer-over="myKingdom &amp;&amp; (showDismissGovernor = true)" on-pointer-out="myKingdom &amp;&amp; (showDismissGovernor = false)" governor-row="" player-id="668" player-name="AshDream" rank="19" points="637" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank20" ng-if="rank >= 0">20</td><!-- end ngIf: rank >= 0 -->
	<td player-column="" player-id="668" player-name="AshDream" chat-user="chatUser" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="668" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="668" playername="AshDream" nolink="false">AshDream</a>

<!-- ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="668" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td>
	<!-- ngIf: !showDismissGovernorButton || !isKing && !isViceKing --><td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing &amp;&amp; !isViceKing">637</td><!-- end ngIf: !showDismissGovernorButton || !isKing && !isViceKing -->
	<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
</tr><!-- end ngRepeat: governor in governors -->
						</tbody>
					</table>
				</div>
				<div class="contentBox description">
					<div class="contentBoxHeader">
						<span translate=""><span>Описание</span></span>
						<!-- ngIf: player.isKing() && !isSitter && player.data.kingdomId == kingdom.data.groupId -->
					</div>
					<div class="contentBoxBody scrollable" scrollable=""><div class="scrollContentOuterWrapper" style="width: 277px;">
	<div class="scrollContent" dynamic-height="false" style="width: 277px; overflow-y: hidden;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
						<div user-text-parse="kingdom.data.description.publicDescription" parse="decorations"></div>
					</div>
	</div>
</div>
<div class="scrollTrack" style="display: none; opacity: 0;">
    <div class="scrollHandle" style="top: 0px;"></div>
	<div class="trackDecoration"></div>
</div></div>
				</div>
			</div>
		</div>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems" current-page="currentPage" display-page-func="displayCurrentPage" route-named-param="governorPage"><div ng-transclude="">
		</div>
<div class="tg-pagination" ng-show="visible">
	<ul>
		<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
			<i ng-class="{
				action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
				action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
				disabled: currentPage == 1
			}" class="action_toBeginning_tiny_flat_black disabled"></i>
		</li>
		<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
			<i ng-class="{
				symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
				symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
				disabled: currentPage == 1
			}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
		</li>
		<!-- ngRepeat: pageNumber in filteredPageNumbers track by $index --><li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
			<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
		</li><!-- end ngRepeat: pageNumber in filteredPageNumbers track by $index --><li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(2)">
			<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}">2</a>
		</li><!-- end ngRepeat: pageNumber in filteredPageNumbers track by $index -->
		<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
			<i ng-class="{
				symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
				symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
				disabled: disableNext()
			}" class="symbol_arrowTo_tiny_flat_black"></i>
		</li>
		<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
			<i ng-class="{
				action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
				action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
				disabled: disableNext()
			}" class="action_toEnd_tiny_flat_black"></i>
		</li>
	</ul>
</div></div>
	</div>
</div></div>
</div>
			</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Королевство | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		windowOverlayOpen: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
import axios from 'axios';
import lib from '@/lib';

export default class StorageModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.woodCountMax = 0;
		this.woodCount = 0;
		this.woodCountOld = 0;
		this.woodProduction = 0;
		
		this.clayCountMax = 0;
		this.clayCount = 0;
		this.clayCountOld = 0;
		this.clayProduction = 0;
		
		this.rockCountMax = 0;
		this.rockCount = 0;
		this.rockCountOld = 0;
		this.rockProduction = 0;
		
		this.ironCountMax = 0;
		this.ironCount = 0;
		this.ironCountOld = 0;
		this.ironProduction = 0;
		
		this.cropCountMax = 0;
		this.cropCount = 0;
		this.cropCountOld = 0;
		this.cropProduction = 0;
		
		this.resTypes = [
			{type: 'wood', name: 'Древесина'},
			{type: 'clay', name: 'Глина'},
			{type: 'iron', name: 'Железо'},
			{type: 'crop', name: 'Зерно'},
		];
	}
	
	/**
	 * Достаточно ли ресурсов на складе
	 * 
	 * @param {array} resources массив ресурсов
	 * @returns {boolean}
	 */
	hasEnoughResources(resources){
		let enough = true;
		for(let [index, resType] of Object.entries(this.resTypes)){
			if(storeInstance.state.app.game.island.storage[resType.type+'Count'] < resources[index]){
				enough = false;
				break;
			}
		}
		return enough;
	}
	
	/**
	 * Влезает ли ресурс на склад
	 * 
	 * @param {number} resTypeIndex индекс ресурса
	 * @param {number} value значение
	 * @returns {boolean}
	 */
	hasEnoughStorage(resTypeIndex, value){
		return value <= storeInstance.state.app.game.island.storage[this.resTypes[resTypeIndex].type+'CountMax'];
	}
	
	/**
	 * Есть ли переполнение склада хотя-бы по одному ресурсу в случае его получения
	 * 
	 * @returns {boolean}
	 */
	capacityHintStorage(){
		let enough = false;
		for(let [index, resType] of Object.entries(this.resTypes)){
			if(resType.type != 'crop'){
				if(!this.hasEnoughStorage(index, storeInstance.state.app.game.island.storage[resType.type+'CountMax'])){
					enough = true;
					break;
				}
			}
		}
		return enough;
	}
	
	/**
	 * Есть ли переполнение амбара в случае его получения
	 * 
	 * @returns {boolean}
	 */
	capacityHintBarn(){
		let enough = false;
		for(let [index, resType] of Object.entries(this.resTypes)){
			if(resType.type == 'crop'){
				if(!this.hasEnoughStorage(index, storeInstance.state.app.game.island.storage[resType.type+'CountMax'])){
					enough = true;
					break;
				}
			}
		}
		return enough;
	}
}

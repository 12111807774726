/**
 * system.js
 */
if (!window.requestAnimationFrame) {
	window.requestAnimationFrame = (function() {
		return window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			window.oRequestAnimationFrame ||
			window.msRequestAnimationFrame ||
			function(callback, element) {
				window.setTimeout(callback, 1000 / 60);
			};
	})();
}

if ( !Array.prototype.forEach ) {
	Array.prototype.forEach = function(fn, scope) {
		for(var i = 0, len = this.length; i < len; ++i) {
			if (i in this) {
				fn.call(scope, this[i], i, this);
			}
		}
	};
}

if (!Function.prototype.bind) {
	Function.prototype.bind = function(context) {
		var fn = this;
		
		return function() {
			if(arguments.length !== 0) {
				return fn.apply(context, arguments);
			} else {
				return fn.call(context);
			}
		};
	};
}
<template>
	<div>
		<div class="manual buildings">
			<div class="details" ng-include="'tpl/manual/partials/buildingsDetails.html'">
				<div class="contentBox">
					<div class="contentBoxBody">
						<div class="properties" :class="{zoomed: imageZoomed}">
							<div class="imageWrapper clickable" @click="imageZoomed = !imageZoomed">
								<img class="tribeId3" :class="['buildingType'+gameModel.help.selectedElement, {'buildingBig': !imageZoomed, 'buildingFull': imageZoomed}]" src="//iwstatic.g.bsrv.su/img/x.gif" alt="" data-cmp-info="10"/>
								<i class="action_zoomIn_medium_flat_black" v-show="!imageZoomed"></i>
								<i class="action_zoomOut_medium_flat_black" v-show="imageZoomed"></i>
							</div>
						</div>
						<div class="needs" v-show="!imageZoomed">
							<div class="contentBox construction">
								<h6 class="contentBoxHeader headerTrapezoidal">
									<div class="content">
										<span>Строительство</span>
										<span class="basic">(<span translate=""><span>Уровень</span></span> 1)</span>
									</div>
								</h6>
								<div class="contentBoxBody">
									<div class="valueContainer">
										<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1">
											<i class="unit_wood_small_illu"></i>{{getElement().resources.wood}}
										</span>
										<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Глина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_2">
											<i class="unit_clay_small_illu"></i>{{getElement().resources.clay}}
										</span>
										<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Железо" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_3">
											<i class="unit_iron_small_illu"></i>{{getElement().resources.iron}}
										</span>
									</div>
									<div class="horizontalLine"></div>
									<div class="valueContainer">
										<span class="valueBox" content="Зерно" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_4">
											<i class="unit_crop_small_illu"></i>{{getElement().resources.crop}}
										</span>
										<span class="valueBox" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration">
											<i class="symbol_clock_small_flat_black duration"></i>{{getElement().duration}}
										</span>
									</div>
									<!--
									<slot name="construction">
										<div class="valueContainer">
											<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1">
												<i class="unit_wood_small_illu"></i>0
											</span>
											<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Глина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_2">
												<i class="unit_clay_small_illu"></i>0
											</span>
											<span ng-repeat="res in [] | range:1:3" class="valueBox" content="Железо" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_3">
												<i class="unit_iron_small_illu"></i>0
											</span>
										</div>
										<div class="horizontalLine"></div>
										<div class="valueContainer">
											<span class="valueBox" content="Зерно" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_4">
												<i class="unit_crop_small_illu"></i>0
											</span>
											<span class="valueBox" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration">
												<i class="symbol_clock_small_flat_black duration"></i>00:00:00
											</span>
										</div>
									</slot>
									-->
								</div>
							</div>
							<div class="contentBox requirements">
								<h6 class="contentBoxHeader headerTrapezoidal">
									<div class="content">
										<span translate=""><span>Требование</span></span>
										<span class="basic">(<span translate=""><span>Базовые</span></span>)</span>
									</div>
								</h6>
								<div class="contentBoxBody">
									<div class="valueContainer">
										<span class="valueBox" v-if="getElement().requirements.length == 0"><span>Нет</span></span>
										<div class="valueBox" v-for="requirement in getElement().requirements">
											<span v-if="requirement.type == 1" :class="{'forbiddenBuilding': requirement.op == 1}">
												<span v-if="requirement.villageType == 2" translate=""><span>{{requirement.name}}</span></span>
												<span v-if="requirement.villageType == 1" translate=""><span>{{requirement.name}}</span></span>
												<span v-if="requirement.villageType == 4" translate=""><span>{{requirement.name}}</span></span>
											</span>
											<span v-if="requirement.type == 2">
												<span v-if="requirement.op === 0" class="forbiddenBuilding">
													<a clickable="goToBuilding(requirement.buildingType)" translate="" class="clickable"><span>{{requirement.name}}</span></a>
												</span>
												<span v-if="requirement.op">
													<a clickable="goToBuilding(requirement.buildingType)" translate="" class="clickable"><span>{{requirement.name}}</span></a>
													<span translate=""><span>Уровень</span></span> {{requirement.level}}
												</span>
											</span>
										</div>
										<!--
										<slot name="requirements">
											<span class="valueBox" ng-if="!buildingData.requirements"><span>Нет</span></span>
										</slot>
										-->
									</div>
								</div>
							</div>
						</div>
						<div class="contentBox description" v-show="!imageZoomed">
							<div class="contentBoxBody scrollable">
								<div class="scrollContentOuterWrapper">
									<div class="scrollContent" style="overflow-y: auto;">
										<div class="scrollContentInnerWrapper" style="width: auto;">
											<span v-html="getElement().description"></span>
											<!--
											<slot name="description">
												<span>Описание...</span>
											</slot>
											-->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		imageZoomed: false,
	}),
	computed: {},
	methods: {
		getElement(){
			return this.gameModel.help.getElement(this.gameModel.help.selectedSection, this.gameModel.help.selectedGroup, this.gameModel.help.selectedElement);
		},
		
		onSelectElement(group, element){
			this.gameModel.help.selectedGroup = group;
			this.gameModel.help.selectedElement = element;
			this.gameModel.help.selectedPage = 1;
			
			this.$router.push({query: Object.assign({}, this.$route.query, {
				group,
				element,
			})});
		},
	},
	components: {},
}
</script>
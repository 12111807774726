<template>
	<div v-if="selType">
		<div class="detailsView queueContainer" :class="['queueType'+selType, 'slot'+selSlot, {
			'usedSlot': selData,
			'lockedSlot': availableMasterBuilderSlots < selSlot,
		}]" @mouseleave="onHideDetails()" @mouseover="onShowDetails(selType, selSlot)">
			<div class="detailsInnerBox">
				<div class="detailsHeader detailsRow">
					<div class="detailsImageContainer">
						<i class="headerIcon" :class="{
							'feature_buildingQueue_medium_illu': selType == BuildingQueueModel.TYPE_VILLAGE || selType == BuildingQueueModel.TYPE_RESOURCES,
							'feature_buildingMaster_medium_illu': selType == BuildingQueueModel.TYPE_MASTER_BUILDER,
							'action_dismantle_medium_flat_black': selType == BuildingQueueModel.TYPE_DESTRUCTION && selData.isRubble,
							'action_demolish_medium_flat_black': selType == BuildingQueueModel.TYPE_DESTRUCTION && !selData.isRubble,
						}"></i>
					</div>
					<div class="headerText">
						<span v-if="selType == BuildingQueueModel.TYPE_DESTRUCTION">Разобрать руины</span>
						<span v-if="selType == BuildingQueueModel.TYPE_VILLAGE || selType == BuildingQueueModel.TYPE_RESOURCES">Строится...</span>
						<span v-if="selType == BuildingQueueModel.TYPE_MASTER_BUILDER">Очередь</span>
					</div>
				</div>
				<div class="detailsContent detailsRow" v-for="queueType in (gameModel.player.tribeId == PlayerModel.TRIBE_ROMAN && (selType == BuildingQueueModel.TYPE_VILLAGE || selType == BuildingQueueModel.TYPE_RESOURCES) ? [BuildingQueueModel.TYPE_VILLAGE, BuildingQueueModel.TYPE_RESOURCES] : [selType])">
					<div class="detailsImageContainer subContainer">
						<div v-if="selData" :content="tooltips[4]" v-tippy="{placement: 'top'}" tooltip-url="tpl/mainlayout/partials/buildingQueueTooltip.html" class="buildingSlotImage buildingMini" :class="['buildingType'+selData.buildingType, 'tribeId'+gameModel.player.tribeId, 'level'+selData.lvl]"></div>
						<i class="cancelBuilding clickable" v-if="selData && !selData.isRubble" :class="{
							'action_cancel_tiny_flat_black': !cancelHover,
							'action_cancel_tiny_flat_red': cancelHover,
						}" @mouseover="cancelHover = true" @mouseleave="cancelHover = false" play-on-click="6" clickable="cancelBuilding(609360)" :content="selData.enoughResources && selData.paid ? tooltips[3] : 'Отменить улучшение'" v-tippy="{placement: 'top'}" tooltip-url="tpl/mainlayout/partials/buildingCancelTooltip.html"></i>
						<i v-if="gameModel.player.tribeId != PlayerModel.TRIBE_ROMAN && queues[queueType].length == 0 && (queueType == BuildingQueueModel.TYPE_VILLAGE || queueType == BuildingQueueModel.TYPE_RESOURCES)" class="queueIcon builderIcon feature_buildingqueue_slot_combine_medium_flat_black"></i>
						
						<!-- ngIf: player.data.tribeId == ROMAN && buildingQueue.data.queues[queueType].length == 0 && (queueType == BuildingQueue.TYPE_VILLAGE || queueType == BuildingQueue.TYPE_RESOURCES) -->
						 
						<i v-if="gameModel.player.tribeId == PlayerModel.TRIBE_ROMAN && queues[queueType].length == 0 && (queueType == BuildingQueueModel.TYPE_VILLAGE || queueType == BuildingQueueModel.TYPE_RESOURCES)" class="queueIcon builderIcon" :class="{
							'feature_buildingqueue_slot_fields_medium_flat_black': queueType == BuildingQueueModel.TYPE_RESOURCES,
							'feature_buildingqueue_slot_village_medium_flat_black': queueType == BuildingQueueModel.TYPE_VILLAGE,
						}"></i>
						<div v-if="queueType == BuildingQueueModel.TYPE_MASTER_BUILDER && !selData" class="buildingQueueSlot entityBox noClick" :class="{
							'empty': availableMasterBuilderSlots >= selSlot,
							'locked': availableMasterBuilderSlots < selSlot,
						}">
							<div v-if="availableMasterBuilderSlots >= selSlot">
								<div class="topLeftBorder"></div>
								<div class="bottomRightBorder"></div>
								<i class="slotIcon builderIcon feature_masterbuilder_small_flat_black"></i>
							</div>
							<i v-if="availableMasterBuilderSlots < selSlot" class="slotIcon lockedState symbol_lock_small_flat_black"></i>
						</div>
					</div>
					<div class="detailsInfo subContainer">
						<div v-if="selData">
							<span><span>{{selData.buildingName}}</span></span>
							<span v-if="queueType != BuildingQueueModel.TYPE_DESTRUCTION" class="levelText" data="current:10,
							next:11">
								<span>(10 </span>
								<i class="icon symbol_arrowTo_tiny_flat_black"></i>
								<span>&nbsp;11)</span>
							</span>
						</div>
						<div v-if="selData && selData.enoughResources && selData.startTime >= 0" class="detailsTime">
							<i class="symbol_clock_small_flat_black duration"></i>
							<!--<span countdown="1723659504">03:12:52</span>-->
							<span>&nbsp;
								<vue-countdown :time="((((1 * 60) + 15) * 60) + 9) * 1000" @end="alert('countdown ended from components/partials/BuildingQueueDetails')" v-slot="{days, hours, minutes, seconds}">
									{{hours < 10 ? 0 : ''}}{{hours}}:{{minutes < 10 ? 0 : ''}}{{minutes}}:{{seconds < 10 ? 0 : ''}}{{seconds}}
								</vue-countdown>
							</span>
							<span v-if="queueType == BuildingQueueModel.TYPE_MASTER_BUILDER && selData.duration">
								<b>+</b> {{formatSecondsDate(selData.duration, 'hh:mm:ss')}}
							</span>
						</div>
						<div v-if="queueType == BuildingQueueModel.TYPE_MASTER_BUILDER && !selData && availableMasterBuilderSlots < selSlot">
							<div class="slotStatus impediment"><span>Слот закрыт</span></div>
							<span><span>Активируй этот слот, чтобы ставить в очередь дополнительные здания</span></span>
						</div>
						<div v-if="queueType == BuildingQueueModel.TYPE_MASTER_BUILDER && availableMasterBuilderSlots >= selSlot">
							<div v-if="!selData">
								<span>Доступен слот № {{selSlot}}</span>
							</div>
							<div v-if="selData && !selData.enoughResources && !selData.paid" class="slotStatus impediment">
								<span>Недостаточно ресурсов</span>
							</div>
						</div>
						<div v-if="queueType != BuildingQueueModel.TYPE_MASTER_BUILDER && !selData">
							<div><span>Слот строительства</span></div>
							<span class="slotStatus"><span>Сейчас не используется</span></span>
						</div>
					</div>
					<div class="detailsButtonContainer subContainer" v-if="queueType == BuildingQueueModel.TYPE_MASTER_BUILDER && (selSlot - availableMasterBuilderSlots) > 1">
						<!--сперва нужно открыть предыдущий слот-->
						<button class="premium clickable disabled" content="<span><strong>Слот в очереди строительства</strong><br><span>Сперва необходимо открыть предыдущий слот.</span></span>" v-tippy="{placement: 'top'}">
							<div class="content">
								<span class="innerText">
									<i class="symbol_plus_small_flat_black"></i>
								</span>
								<!-- ngIf: price > 0 || forceGoldUsage -->
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
					<div class="detailsButtonContainer subContainer" v-else-if="queueType == BuildingQueueModel.TYPE_MASTER_BUILDER && (selSlot - availableMasterBuilderSlots) > 0">
						<!--открыть слот за 50 золотых-->
						<button clickable="openWindow('confirmGoldUsage', {'feature': PremiumFeature.FEATURE_NAME_BOOK_BUILD_MASTER_SLOT})" class="premium clickable" content="<span><strong>Слот в очереди строительства</strong> <br><span>Ты можешь открыть этот слот за 50 золота.</span></span>" v-tippy="{placement: 'top'}">
							<div class="content">
								<span class="innerText">
									<i class="symbol_plus_small_flat_black"></i>
								</span>
								<!-- ngIf: price > 0 || forceGoldUsage -->
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
					<div class="detailsButtonContainer subContainer" v-if="queueType == BuildingQueueModel.TYPE_MASTER_BUILDER && selData && !selData.enoughResources && !selData.paid">
						<button premium-feature="NPCTrader" confirm-gold-usage="true" ng-class="{disabled: activeVillage.isWWVillage() || slotDetails.npcTradingTimeBoost < 0}" class="premium" content="<strong>Обменять ресурсы автоматически <i class='icon unit_gold_small_illu'></i> 5</strong>" v-tippy="{placement: 'top'}" tooltip-translate-switch="{
							'Error.NotAvailableInWW': false,
							'Building.npcTrader.Tooltip.Possible': true,
							'Building.npcTrader.Tooltip.PossibleWithVoucher': 0,
							'Building.npcTrader.Tooltip.PossibleButNoEnoughRes': false,
							'Building.npcTrader.Tooltip.PossibleWithVoucherButNoEnoughRes': false,
							'Building.npcTrader.Tooltip.Insignificant': false
						}" tooltip-data="featurePrice:5,timePeriod:0">
							<div class="content">
								<span class="innerText">
									<i class="feature_npcTrader_small_flat_black" ng-class="{disabled: activeVillage.isWWVillage() || slotDetails.npcTradingTimeBoost}"></i>
								</span>
								<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>5</span>
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
					<div class="detailsButtonContainer subContainer" v-if="queueType == BuildingQueueModel.TYPE_MASTER_BUILDER && selData && selData.enoughResources && !selData.paid">
						<button clickable="reserveResources(609365)" ng-class="{disabled: !slotDetails.canPayNow}" content="Зарезервировать ресурсы" v-tippy="{placement: 'top'}" tooltip-translate-switch="{
							'BuildingQueue.Details.Tooltip.ReserveResources': true,
							'BuildingQueue.Details.Tooltip.ReservePrevious': false
						}" class="clickable">
							<div class="content">
								<span class="innerText">
									<i class="unit_resources_medium_illu" ng-class="{disabled: !slotDetails.canPayNow}"></i>
								</span>
								<!-- ngIf: price > 0 || forceGoldUsage -->
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
					<div class="detailsButtonContainer subContainer" v-if="queueType != BuildingQueueModel.TYPE_MASTER_BUILDER && selData">
						<!--если можно завершить сразу (осталось 5 и менее минут)-->
						<div class="detailsButtonContainer subContainer" v-if="queueType != BuildingQueueModel.TYPE_MASTER_BUILDER && selData">
							<button premium-feature="finishNow" class="free" premium-callback-param="finishNowQueueType:1" confirm-gold-usage="true" :content="tooltips[5]" v-tippy="{placement: 'top'}" tooltip-url="tpl/npcTrader/finishNowTooltip.html" ng-repeat="n in [buildingQueue.data.queues[queueType][slotDetails.slot].id]">
								<div class="content">
									<span class="innerText">
										<i class="feature_instantCompletion_small_flat_black"></i>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
						<!--если можно завершить сразу за бонус карту-->
						<div class="detailsButtonContainer subContainer" v-if="queueType != BuildingQueueModel.TYPE_MASTER_BUILDER && selData">
							<button premium-feature="finishNow" class="free" premium-callback-param="finishNowQueueType:1" confirm-gold-usage="true" :content="tooltips[5]" v-tippy="{placement: 'top'}" tooltip-url="tpl/npcTrader/finishNowTooltip.html" ng-repeat="n in [buildingQueue.data.queues[queueType][slotDetails.slot].id]">
								<div class="content">
									<span class="innerText">
										<i class="feature_instantCompletion_small_flat_black"></i>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<span class="price voucher" ng-if="price == -1 && !forceGoldUsage"><i class="cardGame_prizePremium_small_illu"></i><span>1</span></span>
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
						<!--если завершать за золото-->
						<button premium-feature="finishNow" class="premium" premium-callback-param="finishNowQueueType:5" confirm-gold-usage="true" :content="tooltips[1]" v-tippy="{placement: 'top'}" tooltip-url="tpl/npcTrader/finishNowTooltip.html" ng-repeat="n in [buildingQueue.data.queues[queueType][slotDetails.slot].id]">
							<div class="content">
								<span class="innerText">
									<i class="feature_instantCompletion_small_flat_black"></i>
								</span>
								<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>5</span>
								<!-- ngIf: price == -1 && !forceGoldUsage -->
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
					<div class="progressbar" v-if="queueType != BuildingQueueModel.TYPE_MASTER_BUILDER && selData" finish-time="1674320268" duration="57" perc-tooltip="true">
						<div class="progress">
							<div class="bar perc" :class="{
								'positive': selType != BuildingQueueModel.TYPE_DESTRUCTION,
								'negative': selType == BuildingQueueModel.TYPE_DESTRUCTION,
							}" style="z-index: 2; width: 34.7%;"></div>
							<div class="bar additionalBar additionalPerc"></div>
							<div class="marker">
								<div class="markerInner"></div>
							</div>
							<div class="tooltipArea" content="32.1%" v-tippy="{placement: 'top'}"></div>
						</div>
					</div>
				</div>
				<div class="detailsRow" v-if="selType == BuildingQueueModel.TYPE_MASTER_BUILDER && selData" :class="{
					'paid': selData.paid,
				}">
					<display-resources :resources="selData.cost" available="activeVillage.calculatedStorage" :enough-res-secs="50" />
				</div>
				<div class="detailsRow" v-if="selType == BuildingQueueModel.TYPE_DESTRUCTION && selData.isRubble">
					<display-resources :resources="selData.cost" :color-positive="true" :check-storage="true" />
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';

import BuildingQueueModel from '@/models/game/BuildingQueueModel';
import PlayerModel from '@/models/game/PlayerModel';

import DisplayResources from '@/components/elements/DisplayResources';

export default {
	mixins: lib.mixins,
	data: () => ({
		BuildingQueueModel,
		PlayerModel,
		
		cancelHover: false,
		
		tooltips: {
			1: `<div class="finishNowTooltip" ng-controller="finishNowCtrl">
	<!-- ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) --><div ng-if="!isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0)">
		<h3>
			<span><span>Моментально завершить следующие поручения</span></span><!-- ngIf: price == 0 -->
			<!-- ngIf: price > 0 || forceGoldUsage --><span class="price" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>2:</span><!-- end ngIf: price > 0 || forceGoldUsage -->
			<!-- ngIf: price == -1 && !forceGoldUsage -->
		</h3>
		<div class="horizontalLine"></div>

		<!-- ngRepeat: build in inBuilding track by $index --><div ng-repeat="build in inBuilding track by $index">
			<!-- ngIf: !finishNowQueueType -->
			<span options="22"><span>Академия</span></span>
			<span class="level" data="lvl:20"><span>Уровень 20</span></span>
		</div><!-- end ngRepeat: build in inBuilding track by $index -->
		<!-- ngRepeat: destruction in inDestruction track by $index -->
		<!-- ngRepeat: research in inResearch track by $index -->
		<!-- ngRepeat: training in inTraining track by $index -->
	</div><!-- end ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) -->
	<!-- ngIf: isWorldWonder -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && !config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && !hasInvalidBuilding && inBuilding.length == 0 && inTraining.length == 0 && inDestruction.length == 0 && inResearch.length == 0 -->
	<!-- ngIf: !isWorldWonder && premiumOptionMenu.options.instantCompletion && !freeSlots && enoughRes === false -->
</div>`,
			2: `<strong>Обменять ресурсы автоматически&nbsp;<i class="icon unit_gold_small_illu"></i> 5</strong>`,
			3: `<div class="cancelBuildingTooltip">
	<!-- ngIf: buildingQueue.data.queues[queueType][slotDetails.slot].paid --><div ng-if="buildingQueue.data.queues[queueType][slotDetails.slot].paid">
		<!-- ngIf: paidMaxLvlInQueue[slotDetails.building[queueType].data.locationId] > (queueType == BuildingQueue.TYPE_MASTER_BUILDER ? slotDetails.level : slotDetails.building[queueType].data.lvl) -->
		<div data="type:42,lvl:12"><span>Отменить: </span><translate options="Building_42"><span>Ров</span></translate><span> ур. 12.</span></div>
		<div class="horizontalLine"></div>
		<display-resources resources="slotDetails.building[queueType].data.nextUpgradeCosts[Math.max((queueType == BuildingQueue.TYPE_MASTER_BUILDER ? slotDetails.level : slotDetails.building[queueType].data.lvl), paidMaxLvlInQueue[slotDetails.building[queueType].data.locationId])]" color-positive="true" check-storage="true" signed="true"><div class="costs max5digits">
	<!-- ngIf: !checkStorage -->
	<!-- ngIf: checkStorage --><span ng-if="checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue enough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughStorage(index), enough: enoughStorage(index) && colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_small_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">+11185</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue enough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughStorage(index), enough: enoughStorage(index) && colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_small_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">+12845</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue enough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughStorage(index), enough: enoughStorage(index) && colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_small_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">+14505</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughStorage(index), enough: enoughStorage(index) && colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_small_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">+9370</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint --><div class="enoughResTime" ng-if="capacityHint">Недостаточно места в амбаре</div><!-- end ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources>
	</div><!-- end ngIf: buildingQueue.data.queues[queueType][slotDetails.slot].paid -->
	<!-- ngIf: !buildingQueue.data.queues[queueType][slotDetails.slot].paid && queueType != BuildingQueue.TYPE_DESTRUCTION -->
	<!-- ngIf: queueType == BuildingQueue.TYPE_DESTRUCTION -->
</div>`,
			4: `<div class="tooltipContent"><b><span translate="" options="3"><span>Железный рудник</span></span>
 -
<span translate="" data="lvl: "><span>Уровень </span></span></b>
<div translate="" options="2," data="time: 1723652694"><span>До завершения строительства:</span><br> <span countdown="1723652694" callback="">01:50:33</span><span> ч.</span></div></div>`,
			5: `<div class="finishNowTooltip" ng-controller="finishNowCtrl">
	<!-- ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) --><div ng-if="!isWorldWonder &amp;&amp; (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0)">
		<h3>
			<span translate=""><span>Моментально завершить следующие поручения</span></span><!-- ngIf: price == 0 --><span ng-if="price == 0">:</span><!-- end ngIf: price == 0 -->
			<!-- ngIf: price > 0 || forceGoldUsage -->
			<!-- ngIf: price == -1 && !forceGoldUsage -->
		</h3>
		<div class="horizontalLine"></div>

		<!-- ngRepeat: build in inBuilding track by $index --><div ng-repeat="build in inBuilding track by $index">
			<!-- ngIf: !finishNowQueueType -->
			<span translate="" options="17"><span>Рынок</span></span>
			<span class="level" translate="" data="lvl:2"><span>Уровень 2</span></span>
		</div><!-- end ngRepeat: build in inBuilding track by $index -->
		<!-- ngRepeat: destruction in inDestruction track by $index -->
		<!-- ngRepeat: research in inResearch track by $index -->
		<!-- ngRepeat: training in inTraining track by $index -->
	</div><!-- end ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) -->
	<!-- ngIf: isWorldWonder -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && !config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && !hasInvalidBuilding && inBuilding.length == 0 && inTraining.length == 0 && inDestruction.length == 0 && inResearch.length == 0 -->
	<!-- ngIf: !isWorldWonder && premiumOptionMenu.options.instantCompletion && !freeSlots && enoughRes === false -->
</div>`,
		},
	}),
	computed: {
		selType(){
			return this.gameModel.buildingQueue.activeSlot.slotHover || this.gameModel.buildingQueue.activeSlot.detailHover;
		},
		selSlot(){
			return this.gameModel.buildingQueue.activeSlot.slot || this.gameModel.buildingQueue.activeSlot.slotDetail;
		},
		selData(){
			if(this.selType){
				return this.queues[this.selType].find(el => el.slot == this.selSlot)||null;
			}
			return null;
		},
		queues(){
			return this.gameModel.buildingQueue.queues;
		},
		availableMasterBuilderSlots(){
			return this.gameModel.buildingQueue.availableMasterBuilderSlots;
		},
	},
	methods: {
		formatSecondsDate: lib.formatSecondsDate,
		
		onHideDetails(){//mouseleave
			this.gameModel.buildingQueue.activeSlot.detailHover = null;
			this.gameModel.buildingQueue.activeSlot.slotDetail = null;
		},
		onShowDetails(queueType, slot = null){//mouseover
			this.gameModel.buildingQueue.activeSlot.detailHover = queueType;
			this.gameModel.buildingQueue.activeSlot.slotDetail = slot;
		},
	},
	components: {
		DisplayResources,
	},
}
</script>
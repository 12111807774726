<template>
	<div class="marketContent marketSend">
		<div ng-controller="sendMarketplaceCtrl" class="contentBox">
			<div ng-include="'tpl/building/marketplace/partials/merchantsHeader.html'" class="merchantsHeader" ng-if="!editTradeRoute">
				<div class="headerWithIcon arrowDirectionTo contentBoxHeader">
					<i class="movement_trade_medium_flat_black"></i>
					<div class="marketplaceHeaderGroup">
						<span><span translate=""><span>Доступно</span></span>:</span>
						<div class="circle"><span>1</span></div>
					</div>
					<div class="marketplaceHeaderGroup">
						<span><span translate=""><span>В пути</span></span>:</span>
						<div class="circle"><span>0</span></div>
					</div>
					<div class="marketplaceHeaderGroup">
						<span><span translate=""><span>Торгуют</span></span>:</span>
						<div class="circle"><span>0</span></div>
					</div>
				</div>
			</div>
			<div class="contentBoxBody">
				<table class="resourcesTable transparent" ng-class="{editTradeRoute: editTradeRoute}">
					<tbody class="sliderTable">
						<tr ng-repeat="(resourceType, resName) in resNames">
							<td class="resCol" tooltip="" tooltip-translate="Resource_1">
								<i class="unit_wood_medium_illu"></i>
								<span class="resourceAmount woodAmount">54</span>
							</td>
							<td class="sliderCol">
								<div class="sliderArrow"></div>
								<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-data="resourceData[resourceType]" slider-changed="sliderChanged" slider-steps="merchants.data.carry" force-steps="false" slider-markers="resourceData[resourceType]['markers']">
									<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="" style="pointer-events: auto;">
										<div class="trackContainer">
											<div class="track">
												<div ng-if="stepCount && !hideSteps" class="stepContainer">
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 0%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 5%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 10%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 15%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 20%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 25%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 30%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 35%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 40%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 45%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 50%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 55%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 60%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 65%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 70%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 75%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 80%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 85%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 90%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 95%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 100%;"></div>

												</div>
												<div class="sliderMarker progressMarker" style="width: 0%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker0" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 5%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker1" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 0.36%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker2" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 5%;"></div>
												<div class="hoverIndicator" style="display: none; left: 133px; opacity: 1;">
													<div class="hoverValue">6000</div>
													<div class="indicator"></div>
												</div>
												<div class="handle unselectable" style="left: 0px;"></div>
											</div>
										</div>
										<div class="inputContainer">
											<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
											<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
												<i class="action_setMax_small_flat_black"></i>
											</div>
										</div>
									</div>
								</slider>
							</td>
							<td class="merchantCol">
								<a class="clickable" clickable="addMerchant(1)">&lrm;&#x202D;+&#x202D;750&#x202C;&#x202C;</a>
							</td>
						</tr>
						<tr ng-repeat="(resourceType, resName) in resNames">
							<td class="resCol" tooltip="" tooltip-translate="Resource_2">
								<i class="unit_clay_medium_illu"></i>
								<span class="resourceAmount clayAmount">305</span>
							</td>
							<td class="sliderCol">
								<div class="sliderArrow"></div>
								<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-data="resourceData[resourceType]" slider-changed="sliderChanged" slider-steps="merchants.data.carry" force-steps="false" slider-markers="resourceData[resourceType]['markers']">
									<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
										<div class="trackContainer">
											<div class="track">
												<div ng-if="stepCount && !hideSteps" class="stepContainer">
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 0%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 5%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 10%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 15%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 20%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 25%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 30%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 35%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 40%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 45%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 50%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 55%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 60%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 65%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 70%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 75%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 80%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 85%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 90%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 95%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 100%;"></div>
												</div>
												<div class="sliderMarker progressMarker" style="width: 0%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker0" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 5%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker1" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 2.03333%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker2" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 5%;"></div>
												<div class="hoverIndicator" style="display: none; left: 298px;">
													<div class="hoverValue">14250</div>
													<div class="indicator"></div>
												</div>
												<div class="handle unselectable" style="left: 0px;"></div>
											</div>
										</div>
										<div class="inputContainer">
											<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
											<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
												<i class="action_setMax_small_flat_black"></i>
											</div>
										</div>
									</div>
								</slider>
							</td>
							<td class="merchantCol">
								<a class="clickable" clickable="addMerchant(2)">&lrm;&#x202D;+&#x202D;750&#x202C;&#x202C;</a>
							</td>
						</tr>
						<tr ng-repeat="(resourceType, resName) in resNames">
							<td class="resCol" tooltip="" tooltip-translate="Resource_3">
								<i class="unit_iron_medium_illu"></i>
								<span class="resourceAmount ironAmount">294</span>
							</td>
							<td class="sliderCol">
								<div class="sliderArrow"></div>
								<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-data="resourceData[resourceType]" slider-changed="sliderChanged" slider-steps="merchants.data.carry" force-steps="false" slider-markers="resourceData[resourceType]['markers']">
									<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
										<div class="trackContainer">
											<div class="track">
												<div ng-if="stepCount && !hideSteps" class="stepContainer">
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 0%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 5%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 10%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 15%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 20%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 25%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 30%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 35%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 40%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 45%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 50%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 55%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 60%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 65%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 70%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 75%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 80%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 85%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 90%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 95%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 100%;"></div>
												</div>
												<div class="sliderMarker progressMarker" style="width: 0%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker0" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 5%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker1" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 1.96%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker2" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 5%;"></div>
												<div class="hoverIndicator" style="display: none; left: -71px;">
													<div class="hoverValue">12750</div>
													<div class="indicator"></div>
												</div>
												<div class="handle unselectable" style="left: 0px;"></div>
											</div>
										</div>
										<div class="inputContainer">
											<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
											<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
												<i class="action_setMax_small_flat_black"></i>
											</div>
										</div>
									</div>
								</slider>
							</td>
							<td class="merchantCol">
								<a class="clickable" clickable="addMerchant(3)">&lrm;&#x202D;+&#x202D;750&#x202C;&#x202C;</a>
							</td>
						</tr>
						<tr ng-repeat="(resourceType, resName) in resNames">
							<td class="resCol" tooltip="" tooltip-translate="Resource_4">
								<i class="unit_crop_medium_illu"></i>
								<span class="resourceAmount cropAmount">639</span>
							</td>
							<td class="sliderCol">
								<div class="sliderArrow"></div>
								<slider class="resSlider" slider-min="0" slider-max="sliderMax" slider-show-max-button="false" slider-data="resourceData[resourceType]" slider-changed="sliderChanged" slider-steps="merchants.data.carry" force-steps="false" slider-markers="resourceData[resourceType]['markers']">
									<div class="sliderContainer unselectable" ng-class="{locked: sliderLock}" touch-tooltips="">
										<div class="trackContainer">
											<div class="track">
												<div ng-if="stepCount && !hideSteps" class="stepContainer">
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 0%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 5%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 10%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 15%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 20%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 25%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 30%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 35%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 40%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 45%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 50%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 55%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 60%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 65%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 70%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 75%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 80%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 85%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 90%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 95%;"></div>
													<div ng-repeat="n in [] | range:0:stepCount" class="sliderStep" ng-style="{width: (Math.min((n/stepCount*100),100))+'%'}" style="width: 100%;"></div>
												</div>
												<div class="sliderMarker progressMarker" style="width: 0%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker0" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 5%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker1" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 4.26%;"></div>
												<div ng-repeat="marker in sliderMarkers track by $index" class="sliderMarker customMarker2" ng-style="{width: (Math.min((marker*100/sliderMax),100))+'%'}" style="width: 5%;"></div>
												<div class="hoverIndicator" style="display: none; left: 36px;">
													<div class="hoverValue">1500</div>
													<div class="indicator"></div>
												</div>
												<div class="handle unselectable" style="left: 0px;"></div>
											</div>
										</div>
										<div class="inputContainer">
											<input class="value ng-pristine ng-untouched ng-valid" number="" ng-model="value" type="tel">
											<div class="iconButton maxButton clickable" ng-class="{disabled: sliderData.maxAvailable == 0 || sliderLock}" clickable="setMax()" style="display: none;">
												<i class="action_setMax_small_flat_black"></i>
											</div>
										</div>
									</div>
								</slider>
							</td>
							<td class="merchantCol">
								<a class="clickable" clickable="addMerchant(4)">&lrm;&#x202D;+&#x202D;750&#x202C;&#x202C;</a>
							</td>
						</tr>
					</tbody>
					<tbody>
						<tr>
							<td class="requiredCol" colspan="3">
								<span class="carrySum" ng-bind-html="selectedCarrySum | bidiRatio : selectedCarrySum : (merchants.data.merchantsFree * merchants.data.carry)">&lrm;&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;4500&#x202C;&#x202C;&#x202C;&#x202C;</span>
							</td>
						</tr>
					</tbody>
				</table>
				<table class="settingsTable transparent" ng-class="{editTradeRoute: editTradeRoute}">
					<tbody>
						<tr>
							<th class="destinationCol">
								<span translate=""><span>Деревня</span></span>
								<span class="duration ng-hide" ng-show="travelDuration">0</span>
							</th>
							<th></th>
						</tr>
						<tr>
							<td>
								<span class="targetInput serverautocompleteContainer ng-valid ng-dirty ng-valid-parse" autocompletedata="village,playerVillages,coords,onlyPlayerVillage" autocompletecb="checkTarget" ng-model="targetInput" show-own-villages="true" input-autofocus="true">
									<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span>
									<input ng-disabled="disabledInput" class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск..." />
									<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 171px;">
										<i class="action_search_tiny_flat_white"></i>
										Деревня, Игрок, Координаты
									</label>
								</span>
								<div ng-show="targetPlayer !== null && targetInput !== null && targetInput != '' && !editTradeRoute" class="targetPlayer ng-hide">
									<span translate=""><span>Игрок</span></span>
									:
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="" playername=""></a>
								</div>
							</td>
							<td ng-show="traderSlots > 0" class="repetitions">
								<div class="filterBar">
									<a class="filter iconButton clickable active" ng-class="{active: $parent.recurrences == n}" ng-repeat="n in [] | range:1:traderSlots" clickable="changeRecurrence(n)">
										<span>1</span>
									</a>
									<button ng-if="!firstExtraTraderSlot" class="buyExtraSlot premium" premium-feature="traderSlot" confirm-gold-usage="force" tooltip="" tooltip-translate="Marketplace.Send.Tooltip.ExtraTraderSlot" tooltip-data="slotPrice: 50">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<i class="symbol_plus_tiny_flat_black"></i>
											</span>
											<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>50</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<button ng-if="!secondExtraTraderSlot" class="buyExtraSlot premium" premium-feature="traderSlot" confirm-gold-usage="force" tooltip="" tooltip-translate="Marketplace.Send.Tooltip.ExtraTraderSlot" tooltip-data="slotPrice: 25">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<i class="symbol_plus_tiny_flat_black"></i>
											</span>
											<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>50</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
								</div>
							</td>
							<td ng-if="!editTradeRoute && !showInstantDelivery">
								<button ng-class="{disabled: selectedCarrySum == 0 || !validTarget || player.isOnVacation() || targetVacation}" tooltip="" tooltip-show="false" tooltip-translate="Vacation.Tooltip.FeatureDisabled" clickable="sendResources();" play-on-click="13" class="clickable disabled">
									<div class="content">
										<span ng-transclude="" class="innerText">
											<span translate="" ng-if="!editTradeRoute"><span>Отправка ресурсов</span></span>
										</span>
										<div class="animationOverlay"></div>
										<i class="checkmark action_check_small_flat_white"></i>
										<i class="checkmark action_check_small_flat_black"></i>
										<i class="checkmark action_check_small_flat_red"></i>
									</div>
								</button>
							</td>
						</tr>
						<tr>
							<td colspan="3">
							</td>
						</tr>
					</tbody>
				</table>
				<table class="settingsTable transparent" ng-class="{editTradeRoute: editTradeRoute}">
					<tbody>
						<tr>
							<th class="destinationCol">
								<span translate=""><span>Деревня</span></span>
								<span class="duration" ng-show="travelDuration">00:05:35</span>
							</th>
							<!-- ngIf: plusAccount -->
							<th></th>
						</tr>
						<tr>
							<td>
								<span class="targetInput serverautocompleteContainer ng-valid ng-dirty ng-valid-parse" autocompletedata="village,playerVillages,coords,onlyPlayerVillage" autocompletecb="checkTarget" ng-model="targetInput" show-own-villages="true" input-autofocus="true">
									<ul ng-if="showOwnVillages" class="ownVillageList" ng-class="{visible: ownVillagesListVisible}">
										<li ng-repeat="village in ownVillages">
											<a clickable="selectOwnVillage($index)" class="clickable">
												<div class="resultRow">
													<span class="resultName">Зарница</span>
													<span class="resultType">
														<span class="coordinateWrapper" coordinates="" nolink="true" x="-12" y="-19">
															<!-- ngIf: hasLink -->
															<span ng-if="!hasLink" class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;12&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;-&#x202D;19&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
														</span>
													</span>
												</div>
											</a>
										</li>
									</ul>
									<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span><input ng-disabled="disabledInput" class="targetInput ng-pristine ng-untouched ng-valid ui-autocomplete-input" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск...">
									<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 171px;" class="ownVillagesShown">
										<i class="action_search_tiny_flat_white"></i>
										Деревня, Игрок, Координаты
									</label>
									<a ng-if="showOwnVillages" class="villageQuickSelect iconButton clickable" clickable="toggleOwnVillagesList()" ng-class="{active: ownVillagesListVisible}">
										<i class="villageList_villageOverview_small_flat_black"></i>
									</a>
								</span>
								<div ng-show="targetPlayer !== null &amp;&amp; targetInput !== null &amp;&amp; targetInput != '' &amp;&amp; !editTradeRoute" class="targetPlayer">
									<span translate=""><span>Игрок</span></span>
									:
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" playername="lexinzector">lexinzector</a>
								</div>
							</td>
							<td ng-show="traderSlots > 0" class="repetitions">
								<div class="filterBar">
									<a class="filter iconButton clickable active" ng-class="{active: $parent.recurrences == n}" ng-repeat="n in [] | range:1:traderSlots" clickable="changeRecurrence(n)">
										<span>1</span>
									</a>
									<button ng-if="!firstExtraTraderSlot" class="buyExtraSlot premium" premium-feature="traderSlot" confirm-gold-usage="force" tooltip="" tooltip-translate="Marketplace.Send.Tooltip.ExtraTraderSlot" tooltip-data="slotPrice: 50">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<i class="symbol_plus_tiny_flat_black"></i>
											</span>
											<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>50</span>
											<!-- ngIf: price == -1 && !forceGoldUsage -->

											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<button ng-if="!secondExtraTraderSlot" class="buyExtraSlot premium" premium-feature="traderSlot" confirm-gold-usage="force" tooltip="" tooltip-translate="Marketplace.Send.Tooltip.ExtraTraderSlot" tooltip-data="slotPrice: 25">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<i class="symbol_plus_tiny_flat_black"></i>
											</span>
											<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>50</span>
											<!-- ngIf: price == -1 && !forceGoldUsage -->
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
								</div>
							</td>
							<!-- ngIf: !editTradeRoute && !showInstantDelivery -->
							<td ng-if="showInstantDelivery">
								<button class="instantDelivery free" premium-feature="traderArriveInstantly" premium-callback-param="289308" price="-1" tooltip="">
									<div class="content">
										<span ng-transclude="" class="innerText">
											<span translate=""><span>Забрать сейчас</span></span>
										</span>
										<!-- ngIf: price > 0 || forceGoldUsage -->
										<span class="price voucher" ng-if="price == -1 &amp;&amp; !forceGoldUsage"><i class="cardGame_prizePremium_small_illu"></i><span>1</span></span>
										<div class="animationOverlay"></div>
										<i class="checkmark action_check_small_flat_white"></i>
										<i class="checkmark action_check_small_flat_black"></i>
										<i class="checkmark action_check_small_flat_red"></i>
									</div>
								</button>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<!-- ngIf: noResourceSendToOthers() -->
								<!-- ngIf: error != null && error != '' -->
								<!-- ngIf: targetVacation -->
							</td>
						</tr>
					</tbody>
				</table>
				
				
				<div ng-if="!editTradeRoute" ng-show="lastTrade !== null && lastTrade.data.movement.timeFinish > currentServerTime" class="buttonBar ng-hide">
					<div class="contentBox lastTrade">
						<div class="contentBoxBody">
							<span translate="" class="headline" data="villageName: , villageId: , playerName: , playerId: "><span>Твой торговец на пути в деревню </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': }, null, true)" villageid="" villagename="" nolink="" showmissinglink="true"></a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="0" playername="Природа" nolink="" showmissinglink="true">Природа</a><span>.</span></span>
							<display-resources resources="lastTrade.data.movement.resources">
								<div class="costs max0digits">
									<span ng-if="!checkStorage"></span>
									<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined">
										<i class="feature_npcTrader_small_flat_black"></i>
									</div>
								</div>
							</display-resources>
							<span translate="" class="timer" data="countdownTo: "><span countdown="" callback="">00:00:00</span><span>ч</span></span>
						</div>
					</div>
				</div>
				
				<div ng-if="!editTradeRoute" ng-show="lastTrade !== null &amp;&amp; lastTrade.data.movement.timeFinish > currentServerTime" class="buttonBar">
					<div class="contentBox lastTrade">
						<div class="contentBoxBody">
							<span translate="" class="headline" data="villageName: , villageId: 536264692, playerName: lexinzector, playerId: 734"><span>Твой торговец на пути в деревню </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264692}, null, true)" villageid="536264692" villagename="" nolink="" showmissinglink="true">Зарница</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a><span>.</span></span>
							<display-resources resources="lastTrade.data.movement.resources">
								<div class="costs max3digits">
									<span ng-if="!checkStorage">
										<span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
											<i class="unit_wood_small_illu resType1"></i>
											<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
											<!-- ngIf: ::numberUnit=='production' -->
											<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
										</span>
										<span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
											<i class="unit_clay_small_illu resType2"></i>
											<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
											<!-- ngIf: ::numberUnit=='production' -->
											<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
										</span>
										<span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
											<i class="unit_iron_small_illu resType3"></i>
											<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
											<!-- ngIf: ::numberUnit=='production' -->
											<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
										</span>
										<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_4">
											<i class="unit_crop_small_illu resType4"></i>
											<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
											<!-- ngIf: ::numberUnit=='production' -->
											<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
										</span>
									</span>
									<!-- ngIf: checkStorage -->

									<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
									<!-- ngIf: population || population == 0 -->

									<!-- ngIf: treasures || treasures == 0 -->

									<!-- ngIf: stolenGoods || stolenGoods == 0 -->

									<!-- ngIf: time || time == 0 -->

									<!-- ngIf: enoughResSecs == -1 -->
									<!-- ngIf: enoughResSecs > 0 -->
									<!-- ngIf: capacityHint -->

									<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
								</div>
							</display-resources>
							<span translate="" class="timer" data="countdownTo: 1718632206"><span countdown="1718632206" callback="">00:02:00</span><span>ч</span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
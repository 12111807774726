<template>
	<div id="helpNotifications" class="positionStart show" v-if="gameModel.helpNotificationOpen">
		<div class="dialogActor clickable" @click="$router.push({path: '/help', query: {section: randElement.section, group: randElement.group, element: randElement.element}}); gameModel.helpNotificationOpen = false;">
			<div class="tooltip textBubble before show">
				<div class="tooltipContent">
					<span><span>Узнай, что такое</span></span><br>
					<a><span>{{randElement.elementName}}</span></a>
				</div>
			</div>
			<div class="dialogActorPortrait">
				<img class="characters_helpNotification" src="//iwstatic.g.bsrv.su/img/x.gif" />
			</div>
		</div>
		<i :class="['closeNotification', 'clickable', {action_cancel_tiny_flat_black: !closeHover, action_cancel_tiny_flat_red: closeHover}]" @mouseover="closeHover = true" @mouseleave="closeHover = false" @click="gameModel.helpNotificationOpen = false" content="Закрыть уведомление" v-tippy="{placement: 'right'}"></i>
	</div>
</template>

<style lang="css">
#helpNotifications{
    position:absolute;
    bottom:80px;
    height:120px;
    z-index:98;
    transition-property:left,right;
    transition-duration:.4s;
    transition-timing-function:linear
}
#helpNotifications.positionStart{
    right:-500px
}
#helpNotifications.positionStart.show{
    right:0
}
#helpNotifications .dialogActor{
    top:0;
    height:100%
}
#helpNotifications .dialogActor .tooltip{
    max-width:inherit
}
#helpNotifications .dialogActor .tooltip.textBubble{
    width:inherit
}
#helpNotifications .dialogActor .dialogActorPortrait{
    position:absolute;
    right:0;
    bottom:0;
    z-index:0
}
#helpNotifications .dialogActor .textBubble{
    transition-delay:500ms;
    margin:0;
    top:auto;
    bottom:0;
    right:100px
}
#helpNotifications .dialogActor .textBubble:before{
    top:auto;
    bottom:30px
}
#helpNotifications .dialogActor .textBubble .tooltipContent{
    padding: 15px;
    float: right;
    font-size:14px;
    display:inline-block;
    line-height:18px;
    white-space:nowrap
}
#helpNotifications .closeNotification{
    display:none;
    position:absolute;
    top:-5px;
    right:-5px;
    background-color:#fff;
    border:2px solid #fff;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    border-radius:50%;
    -webkit-box-shadow:0 0 0 1px #b8b2a9;
    -moz-box-shadow:0 0 0 1px #b8b2a9;
    box-shadow:0 0 0 1px #b8b2a9
}
#helpNotifications:hover .closeNotification{
    display:block;
    right:5px;
}
#helpNotifications:hover .closeNotification:hover{
    background-color:#e8e5e1;
    border:2px solid #e8e5e1;
    -webkit-box-shadow:0 0 0 1px #888;
    -moz-box-shadow:0 0 0 1px #888;
    box-shadow:0 0 0 1px #888
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		closeHover: false,
		randElement: {},
	}),
	computed: {
		randName(){
			let names = [
				'Бич Маркет Касалтинг',
				'Бимсисельс',
				'Байровый Эль',
				'Галяяяяяя! отмена',
				'Галяяяяяя! возврат',
				'Раздуплёжь',
				'Поддатый Рок Ли',
				'Ихбиннинг',
				'Коносуба',
				
				'Йобатами',
				'Топотун',
				'Клыкан',
				'Слонопотамус',
				'Призрак Здравого Смысла',
				'Жабдуба',
				'Жубдуба',
				'Кривокопытус',
				'Волосапчатый Йобжыг',
				'Пургальё',
				'Крылатая Наковальница',
				'Двурогая Жижа',
				'Жар Курица',
				'Чупичапа',
				'Капикошка',
				'Грибок Огребок',
				'Пандус Бамбегуби',
				'Манаглот',
				'Зырк Огнеглазащий',
				'Шелубус',
				'Летающая корова',
				'Фиолетовая милка',
				'Священная корова',
				'Творческие хуспеки',
				'Груз раздумий',
				
				'Пухабойня',
				'Костяпачка',
				'Аргх',
				'Лунабаб',
				'Рогабой',
				'Хедкрабщит',
				'Черепанц',
				'Мордец',
				'Узорбой',
				'Конещит',
			];
			return names[Math.floor(Math.random() * names.length)];
		},
	},
	methods: {},
	mounted(){
		let variants = [];
		for(let section of this.gameModel.help.sections){
			for(let group of section.groups){
				for(let element of group.elements){
					variants.push({
						section: section.apiname,
						group: group.apiname,
						element: element.apiname,
						elementName: element.name,
					});
				}
			}
		}
		this.randElement = variants[lib.mt_rand(0, variants.length - 1)];
	},
	components: {},
}
</script>
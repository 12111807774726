<template>
	<div class="premiumDeals">
		<carousel carousel-template="tpl/shop/partials/bundle.html" carousel-items="deals" carousel-item-primary-key="name" carousel-rows="1" carousel-cols="3" carousel-callback="selectProduct" carousel-preselect-first="false" carousel-arrow-class="greenArrow" carousel-on-page-change="onDealsPageChange" class="col_3 rows_1">
			<div class="carousel">
				<div class="pages firstPage" ng-show="carousel.pages.length > 1" ng-class="{firstPage: carousel.currentPage == 1, lastPage: carousel.currentPage == carousel.pages.length}">
					<div class="greenArrow arrowDirectionFrom" clickable="carousel.previousPage()"></div>
					<div class="page clickable active" ng-repeat="page in carousel.pages" clickable="carousel.goToPage(page)" ng-class="{active: page === carousel.currentPage}"></div>
					<div class="page clickable" ng-repeat="page in carousel.pages" clickable="carousel.goToPage(page)" ng-class="{active: page === carousel.currentPage}"></div>
					<div class="greenArrow arrowDirectionTo" clickable="carousel.nextPage()"></div>
				</div>
				<div class="itemContainer">
					<div class="items" style="width: 624px;"><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
	<div class="premiumPackage  hoverHighlight" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
		<!-- ngIf: (item.endTime || (item.previewSeconds && item.startTime > $root.currentServerTime && item.startTime - item.previewSeconds <= $root.currentServerTime)) && (!item.limitation || item.limitation > item.booked) -->
		<!-- ngIf: item.boost && player.data[item.name + 'Time'] != 0 -->
		<div class="topDecoration"></div>
		<!-- ngIf: ::!item.boost && item.discount --><div ng-if="::!item.boost && item.discount" class="discount">-33%</div><!-- end ngIf: ::!item.boost && item.discount -->
		<div class="packageContent large">
			<div class="packageContentBox">
				<div class="packageImageBackground">
					<i class="shop_plusAccountBg_huge_illu"></i>
				</div>
				<div class="packageLabel">
					<div class="labelText">
						<div options="plusAccount"><span>TRAVIAN Plus</span></div>
						<!-- ngIf: ::item.duration && item.duration > 0 -->
						<!-- ngIf: ::item.duration && item.duration < 0 --><div ng-if="::item.duration && item.duration < 0" class="packageDuration"><span>Весь раунд</span></div><!-- end ngIf: ::item.duration && item.duration < 0 -->
					</div>
				</div>
				<div class="packageItems scrollable" scrollable=""><div class="scrollContentOuterWrapper active" style="width: 140px;">
	<div class="scrollContent" dynamic-height="false" style="width: 160px; overflow-y: auto;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: 140px;">
					<!-- ngIf: !item.features || item.features.length == 0 -->
					<!-- ngInclude:  --><div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'"><table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
	<tbody>
		<!-- ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_storagePlus" tooltip-data="x:25">
			<td>
				<i class="shop_feature_storagePlus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:, value:25" options="storagePlus"><strong>+25 %</strong><span> к вместительности склада</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_healingPlus" tooltip-data="x:25">
			<td>
				<i class="shop_feature_healingPlus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:, value:25" options="healingPlus"><strong>+25 %</strong><span> к количеству мест в Целебном шатре</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_tradeRoutePlus" tooltip-data="x:">
			<td>
				<i class="shop_feature_tradeRoutePlus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:, value:" options="tradeRoutePlus"><strong>+</strong><span>торговые пути</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_farmlistPlus" tooltip-data="x:">
			<td>
				<i class="shop_feature_farmlistPlus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:, value:" options="farmlistPlus"><strong>+100x100</strong><span> избранных целей</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_celebrationPlus" tooltip-data="x:">
			<td>
				<i class="shop_feature_celebrationPlus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:1, value:" options="celebrationPlus"><strong>+1</strong><span> дополнительный слот для праздника</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_researchPlus" tooltip-data="x:">
			<td>
				<i class="shop_feature_researchPlus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:1, value:" options="researchPlus"><strong>+1</strong><span> дополнительный слот в кузнице</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features -->
	</tbody>
</table></div>
					<!-- ngIf: ::item.paynetCode -->
				</div>
	</div>
</div>
</div>
				<!-- ngIf: item.limitation && item.price --><div ng-if="item.limitation && item.price" class="packageQuantity" data="x:1"><span>Количество: 1</span></div><!-- end ngIf: item.limitation && item.price -->
				<!-- ngIf: item.boost && player.data[item.name + 'Time'] >= $root.currentServerTime -->
				<!-- ngIf: !item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0) --><button ng-if="!item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0)" class="premium packageButton" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':false}" premium-feature="plusAccount" price="87" premium-callback-param="true"><div class="content">
	<span ng-transclude="" class="innerText">
					<span class="truncated"><span>Покупка</span></span>
				</span>
	<!-- ngIf: price > 0 || forceGoldUsage --><span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>87</span><!-- end ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: !item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0) -->
				<!-- ngIf: !item.boost && item.paynetCode && item.price && (!item.limitation || item.limitation > item.booked) -->
				<!-- ngIf: (item.limitation && item.limitation <= item.booked && item.price) || (player.data[item.name + 'Time'] && player.data[item.name + 'Time'] < 0) -->
				<!-- ngIf: item.boost && player.data[item.name + 'Time'] >= 0 -->
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_plusAccount_large_illu"></div>
</div><!-- end ngIf: item --></div><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
	<div class="premiumPackage tkTopSeller hoverHighlight" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
		<!-- ngIf: (item.endTime || (item.previewSeconds && item.startTime > $root.currentServerTime && item.startTime - item.previewSeconds <= $root.currentServerTime)) && (!item.limitation || item.limitation > item.booked) -->
		<!-- ngIf: item.boost && player.data[item.name + 'Time'] != 0 -->
		<div class="topDecoration"></div>
		<!-- ngIf: ::!item.boost && item.discount --><div ng-if="::!item.boost && item.discount" class="discount">-33%</div><!-- end ngIf: ::!item.boost && item.discount -->
		<div class="packageContent large">
			<div class="packageContentBox">
				<div class="packageImageBackground">
					<i class="shop_productionBonusBg_huge_illu"></i>
				</div>
				<div class="packageLabel">
					<div class="labelText">
						<div options="productionBonus"><span>Бонус ресурсов</span></div>
						<!-- ngIf: ::item.duration && item.duration > 0 -->
						<!-- ngIf: ::item.duration && item.duration < 0 --><div ng-if="::item.duration && item.duration < 0" class="packageDuration"><span>Весь раунд</span></div><!-- end ngIf: ::item.duration && item.duration < 0 -->
					</div>
				</div>
				<div class="packageItems scrollable" scrollable=""><div class="scrollContentOuterWrapper" style="width: 140px;">
	<div class="scrollContent" dynamic-height="false" style="width: 140px; overflow-y: hidden;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
					<!-- ngIf: !item.features || item.features.length == 0 -->
					<!-- ngInclude:  --><div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'"><table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
	<tbody>
		<!-- ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_woodBonus" tooltip-data="x:25">
			<td>
				<i class="shop_feature_woodBonus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:, value:25" options="woodBonus"><strong>+25 %</strong><span> к производству</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_clayBonus" tooltip-data="x:25">
			<td>
				<i class="shop_feature_clayBonus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:, value:25" options="clayBonus"><strong>+25 %</strong><span> к производству</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_ironBonus" tooltip-data="x:25">
			<td>
				<i class="shop_feature_ironBonus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:, value:25" options="ironBonus"><strong>+25 %</strong><span> к производству</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features -->
	</tbody>
</table></div>
					<!-- ngIf: ::item.paynetCode -->
				</div>
	</div>
</div>
</div>
				<!-- ngIf: item.limitation && item.price --><div ng-if="item.limitation && item.price" class="packageQuantity" data="x:1"><span>Количество: 1</span></div><!-- end ngIf: item.limitation && item.price -->
				<!-- ngIf: item.boost && player.data[item.name + 'Time'] >= $root.currentServerTime -->
				<!-- ngIf: !item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0) --><button ng-if="!item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0)" class="premium packageButton" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':false}" premium-feature="productionBonus" price="174" premium-callback-param="true"><div class="content">
	<span ng-transclude="" class="innerText">
					<span class="truncated"><span>Покупка</span></span>
				</span>
	<!-- ngIf: price > 0 || forceGoldUsage --><span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>174</span><!-- end ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: !item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0) -->
				<!-- ngIf: !item.boost && item.paynetCode && item.price && (!item.limitation || item.limitation > item.booked) -->
				<!-- ngIf: (item.limitation && item.limitation <= item.booked && item.price) || (player.data[item.name + 'Time'] && player.data[item.name + 'Time'] < 0) -->
				<!-- ngIf: item.boost && player.data[item.name + 'Time'] >= 0 -->
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_productionBonus_large_illu"></div>
</div><!-- end ngIf: item --></div><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
	<div class="premiumPackage  hoverHighlight" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
		<!-- ngIf: (item.endTime || (item.previewSeconds && item.startTime > $root.currentServerTime && item.startTime - item.previewSeconds <= $root.currentServerTime)) && (!item.limitation || item.limitation > item.booked) -->
		<!-- ngIf: item.boost && player.data[item.name + 'Time'] != 0 -->
		<div class="topDecoration"></div>
		<!-- ngIf: ::!item.boost && item.discount --><div ng-if="::!item.boost && item.discount" class="discount">-33%</div><!-- end ngIf: ::!item.boost && item.discount -->
		<div class="packageContent large">
			<div class="packageContentBox">
				<div class="packageImageBackground">
					<i class="shop_cropProductionBonusBg_huge_illu"></i>
				</div>
				<div class="packageLabel">
					<div class="labelText">
						<div options="cropProductionBonus"><span>Бонус зерна</span></div>
						<!-- ngIf: ::item.duration && item.duration > 0 -->
						<!-- ngIf: ::item.duration && item.duration < 0 --><div ng-if="::item.duration && item.duration < 0" class="packageDuration"><span>Весь раунд</span></div><!-- end ngIf: ::item.duration && item.duration < 0 -->
					</div>
				</div>
				<div class="packageItems scrollable" scrollable=""><div class="scrollContentOuterWrapper" style="width: 140px;">
	<div class="scrollContent" dynamic-height="false" style="width: 140px; overflow-y: hidden;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
					<!-- ngIf: !item.features || item.features.length == 0 -->
					<!-- ngInclude:  --><div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'"><table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
	<tbody>
		<!-- ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_cropBonus" tooltip-data="x:25">
			<td>
				<i class="shop_feature_cropBonus_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:, value:25" options="cropBonus"><strong>+25 %</strong><span> к производству</span></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features -->
	</tbody>
</table></div>
					<!-- ngIf: ::item.paynetCode -->
				</div>
	</div>
</div>
</div>
				<!-- ngIf: item.limitation && item.price --><div ng-if="item.limitation && item.price" class="packageQuantity" data="x:1"><span>Количество: 1</span></div><!-- end ngIf: item.limitation && item.price -->
				<!-- ngIf: item.boost && player.data[item.name + 'Time'] >= $root.currentServerTime -->
				<!-- ngIf: !item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0) --><button ng-if="!item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0)" class="premium packageButton" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':false}" premium-feature="cropProductionBonus" price="87" premium-callback-param="true"><div class="content">
	<span ng-transclude="" class="innerText">
					<span class="truncated"><span>Покупка</span></span>
				</span>
	<!-- ngIf: price > 0 || forceGoldUsage --><span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>87</span><!-- end ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: !item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0) -->
				<!-- ngIf: !item.boost && item.paynetCode && item.price && (!item.limitation || item.limitation > item.booked) -->
				<!-- ngIf: (item.limitation && item.limitation <= item.booked && item.price) || (player.data[item.name + 'Time'] && player.data[item.name + 'Time'] < 0) -->
				<!-- ngIf: item.boost && player.data[item.name + 'Time'] >= 0 -->
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_cropProductionBonus_large_illu"></div>
</div><!-- end ngIf: item --></div></div>
<div class="items" style="width: 624px;"><div><!-- ngIf: item --><div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
	<div class="premiumPackage  hoverHighlight timed" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
		<!-- ngIf: (item.endTime || (item.previewSeconds && item.startTime > $root.currentServerTime && item.startTime - item.previewSeconds <= $root.currentServerTime)) && (!item.limitation || item.limitation > item.booked) --><div ng-if="(item.endTime || (item.previewSeconds && item.startTime > $root.currentServerTime && item.startTime - item.previewSeconds <= $root.currentServerTime)) && (!item.limitation || item.limitation > item.booked)" tooltip="" tooltip-data="date:1717401468" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':true}" class="packageState unavailable" ng-class="{limited: item.endTime >= $root.currentServerTime, preview: item.previewSeconds && item.startTime > $root.currentServerTime, unavailable: item.endTime < $root.currentServerTime}">
			<!-- ngIf: item.previewSeconds && item.startTime > $root.currentServerTime -->
			<!-- ngIf: item.endTime && item.startTime <= $root.currentServerTime && item.endTime >= $root.currentServerTime -->
			<!-- ngIf: item.endTime && item.endTime < $root.currentServerTime --><span ng-if="item.endTime && item.endTime < $root.currentServerTime"><span>Недоступно</span></span><!-- end ngIf: item.endTime && item.endTime < $root.currentServerTime -->
		</div><!-- end ngIf: (item.endTime || (item.previewSeconds && item.startTime > $root.currentServerTime && item.startTime - item.previewSeconds <= $root.currentServerTime)) && (!item.limitation || item.limitation > item.booked) -->
		<!-- ngIf: item.boost && player.data[item.name + 'Time'] != 0 -->
		<div class="topDecoration"></div>
		<!-- ngIf: ::!item.boost && item.discount --><div ng-if="::!item.boost && item.discount" class="discount">-33%</div><!-- end ngIf: ::!item.boost && item.discount -->
		<div class="packageContent large">
			<div class="packageContentBox">
				<div class="packageImageBackground">
					<i class="shop_starterPackageBg_huge_illu"></i>
				</div>
				<div class="packageLabel">
					<div class="labelText">
						<div options="starterPackage"><span>Стартовый пакет</span></div>
						<!-- ngIf: ::item.duration && item.duration > 0 -->
						<!-- ngIf: ::item.duration && item.duration < 0 -->
					</div>
				</div>
				<div class="packageItems scrollable" scrollable=""><div class="scrollContentOuterWrapper active" style="width: 140px;">
	<div class="scrollContent" dynamic-height="false" style="width: 160px; overflow-y: auto;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: 140px;">
					<!-- ngIf: !item.features || item.features.length == 0 -->
					<!-- ngInclude:  --><div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'"><table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
	<tbody>
		<!-- ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="false" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_silver" tooltip-data="x:">
			<td>
				<i class="shop_feature_silver_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType --><span ng-if="::!feature.heroItemType" data="x:6000, value:" options="silver"><span>Серебро: </span><strong>6000</strong></span><!-- end ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_139" tooltip-data="x:">
			<td>
				<i class="shop_feature_item139_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType --><span ng-if="::feature.heroItemType"><strong>4x</strong> <span options="139"><span>NPC торговец</span></span></span><!-- end ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_138" tooltip-data="x:">
			<td>
				<i class="shop_feature_item138_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType --><span ng-if="::feature.heroItemType"><strong>5x</strong> <span options="138"><span>Завершить сейчас</span></span></span><!-- end ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_112" tooltip-data="x:">
			<td>
				<i class="shop_feature_item112_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType --><span ng-if="::feature.heroItemType"><strong>20x</strong> <span options="112"><span>Мазь</span></span></span><!-- end ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusBandage25" tooltip-data="x:">
			<td>
				<i class="shop_feature_item117_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType --><span ng-if="::feature.heroItemType"><strong>20x</strong> <span options="117"><span>Малая повязка</span></span></span><!-- end ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features --><tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_115" tooltip-data="x:">
			<td>
				<i class="shop_feature_item115_large_illu"></i>
			</td>
			<td>
				<!-- ngIf: ::feature.heroItemType --><span ng-if="::feature.heroItemType"><strong>1x</strong> <span options="115"><span>Книга мудрости</span></span></span><!-- end ngIf: ::feature.heroItemType -->
				<!-- ngIf: ::!feature.heroItemType -->
			</td>
		</tr><!-- end ngRepeat: feature in item.features -->
	</tbody>
</table></div>
					<!-- ngIf: ::item.paynetCode -->
				</div>
	</div>
</div>
</div>
				<!-- ngIf: item.limitation && item.price --><div ng-if="item.limitation && item.price" class="packageQuantity" data="x:1"><span>Количество: 1</span></div><!-- end ngIf: item.limitation && item.price -->
				<!-- ngIf: item.boost && player.data[item.name + 'Time'] >= $root.currentServerTime -->
				<!-- ngIf: !item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0) --><button ng-if="!item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0)" class="premium packageButton disabled" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:1717401468" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':true}" premium-feature="starterPackage" price="60" premium-callback-param="true"><div class="content">
	<span ng-transclude="" class="innerText">
					<span class="truncated"><span>Покупка</span></span>
				</span>
	<!-- ngIf: price > 0 || forceGoldUsage --><span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>60</span><!-- end ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: !item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0) -->
				<!-- ngIf: !item.boost && item.paynetCode && item.price && (!item.limitation || item.limitation > item.booked) -->
				<!-- ngIf: (item.limitation && item.limitation <= item.booked && item.price) || (player.data[item.name + 'Time'] && player.data[item.name + 'Time'] < 0) -->
				<!-- ngIf: item.boost && player.data[item.name + 'Time'] >= 0 -->
			</div>
		</div>
		<div class="bottomDecoration"></div>
	</div>
	<div class="packageImage shop_starterPackage_large_illu"></div>
</div><!-- end ngIf: item --></div><div><!-- ngIf: item --></div><div><!-- ngIf: item --></div></div>
					<div class="items" style="width: 624px;">
						<div>
							<div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
								<div class="premiumPackage  hoverHighlight timed" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
									<div ng-if="(item.endTime || (item.previewSeconds && item.startTime > $root.currentServerTime && item.startTime - item.previewSeconds <= $root.currentServerTime)) && (!item.limitation || item.limitation > item.booked)" tooltip="" tooltip-data="date:1707937200" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':true,'Shop.Deal.State.Tooltip.LimitedTime':false}" class="packageState limited preview" ng-class="{limited: item.endTime >= $root.currentServerTime, preview: item.previewSeconds && item.startTime > $root.currentServerTime, unavailable: item.endTime < $root.currentServerTime}">
										<span ng-if="item.previewSeconds && item.startTime > $root.currentServerTime"><span>Доступно в скором времени</span></span>
									</div>
									<div class="topDecoration"></div>
									<div class="packageContent large">
										<div class="packageContentBox">
											<div class="packageImageBackground">
												<i class="shop_valentinesPackageBg_huge_illu"></i>
											</div>
											<div class="packageLabel">
												<div class="labelText">
													<div options="valentinesPackage"><span>Пакет от Святого Валентина</span></div>
												</div>
											</div>
											<div class="packageItems scrollable" scrollable="">
												<div class="scrollContentOuterWrapper">
													<div class="scrollContent" style="overflow-y: auto;">
														<div class="scrollContentInnerWrapper" style="width: 100%;">
															<div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'">
																<table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
																	<tbody>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_137" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_item137_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::feature.heroItemType"><strong>1x</strong> <span options="137"><span>Строительная площадка</span></span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_138" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_item138_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::feature.heroItemType"><strong>5x</strong> <span options="138"><span>Завершить сейчас</span></span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_139" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_item139_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::feature.heroItemType"><strong>1x</strong> <span options="139"><span>NPC торговец</span></span></span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div ng-if="item.limitation && item.price" class="packageQuantity" data="x:1"><span>Количество: 1</span></div>
											<button ng-if="!item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0)" class="premium packageButton disabled" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:1707937200" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':true,'Shop.Deal.State.Tooltip.LimitedTime':false}" premium-feature="valentinesPackage" price="79" premium-callback-param="true">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span class="truncated"><span>Покупка</span></span>
													</span>
													<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>79</span>
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</div>
									</div>
									<div class="bottomDecoration"></div>
								</div>
								<div class="packageImage shop_valentinesPackage_large_illu"></div>
							</div>
						</div>
						<div>
							<div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
								<div class="premiumPackage  hoverHighlight timed" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
									<div ng-if="(item.endTime || (item.previewSeconds && item.startTime > $root.currentServerTime && item.startTime - item.previewSeconds <= $root.currentServerTime)) && (!item.limitation || item.limitation > item.booked)" tooltip="" tooltip-data="date:1707066905" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':true}" class="packageState limited" ng-class="{limited: item.endTime >= $root.currentServerTime, preview: item.previewSeconds && item.startTime > $root.currentServerTime, unavailable: item.endTime < $root.currentServerTime}">
										<span ng-if="item.endTime && item.startTime <= $root.currentServerTime && item.endTime >= $root.currentServerTime" countdown="1709658905" dhms="true">22д 04ч 43м 34с</span>
									</div>
									<div class="topDecoration"></div>
									<div ng-if="::!item.boost && item.discount" class="discount">-33%</div>
									<div class="packageContent large">
										<div class="packageContentBox">
											<div class="packageImageBackground">
												<i class="shop_starterPackageBg_huge_illu"></i>
											</div>
											<div class="packageLabel">
												<div class="labelText">
													<div options="starterPackage"><span>Стартовый пакет</span></div>
												</div>
											</div>
											<div class="packageItems scrollable" scrollable="">
												<div class="scrollContentOuterWrapper active">
													<div class="scrollContent" dynamic-height="false" style="overflow-y: auto;">
														<div class="scrollContentInnerWrapper" style="width: 100%;">
															<div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'">
																<table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
																	<tbody>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="false" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_silver" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_silver_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:6000, value:" options="silver"><span>Серебро: </span><strong>6000</strong></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_139" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_item139_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::feature.heroItemType"><strong>4x</strong> <span options="139"><span>NPC торговец</span></span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_138" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_item138_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::feature.heroItemType"><strong>5x</strong> <span options="138"><span>Завершить сейчас</span></span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_112" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_item112_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::feature.heroItemType"><strong>20x</strong> <span options="112"><span>Мазь</span></span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusBandage25" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_item117_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::feature.heroItemType"><strong>20x</strong> <span options="117"><span>Малая повязка</span></span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Hero.ItemBonusInstantUse_115" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_item115_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::feature.heroItemType"><strong>1x</strong> <span options="115"><span>Книга мудрости</span></span></span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div ng-if="item.limitation && item.price" class="packageQuantity" data="x:1"><span>Количество: 1</span></div>
											<button ng-if="!item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0)" class="premium packageButton" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:1707066905" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':false}" premium-feature="starterPackage" price="60" premium-callback-param="true">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span class="truncated"><span>Покупка</span></span>
													</span>
													<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>60</span>
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</div>
									</div>
									<div class="bottomDecoration"></div>
								</div>
								<div class="packageImage shop_starterPackage_large_illu"></div>
							</div>
						</div>
						<div>
							<div ng-if="item" class="packageContainer" ng-init="item.premiumCallback ? this[item.name] = item.premiumCallback : ''" ng-class="{selected: item.selected, paynet: item.paynetCode}">
								<div class="premiumPackage  hoverHighlight" ng-class="{timed: item.endTime, vacation: item.boost && item.vacation && player.isOnVacation()}">
									<div class="topDecoration"></div>
									<div ng-if="::!item.boost && item.discount" class="discount">-33%</div>
									<div class="packageContent large">
										<div class="packageContentBox">
											<div class="packageImageBackground">
												<i class="shop_plusAccountBg_huge_illu"></i>
											</div>
											<div class="packageLabel">
												<div class="labelText">
													<div options="plusAccount"><span>TRAVIAN Plus</span></div>
													<div ng-if="::item.duration && item.duration < 0" class="packageDuration"><span>Весь раунд</span></div>
												</div>
											</div>
											<div class="packageItems scrollable" scrollable="">
												<div class="scrollContentOuterWrapper active">
													<div class="scrollContent" style="overflow-y: auto;">
														<div class="scrollContentInnerWrapper" style="width: 100%;">
															<div ng-include="" src="'tpl/shop/partials/bundleItemsList.html'">
																<table class="itemList transparent" ng-class="{shopLoading: item.paynetCode && (!item.features || item.features.length == 0) && !item.unavailable}">
																	<tbody>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_storagePlus" tooltip-data="x:25">
																			<td>
																				<i class="shop_feature_storagePlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:25" options="storagePlus"><strong>+25%</strong><span> к вместительности склада</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_healingPlus" tooltip-data="x:25">
																			<td>
																				<i class="shop_feature_healingPlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:25" options="healingPlus"><strong>+25 %</strong><span> к количеству мест в Целебном шатре</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_tradeRoutePlus" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_tradeRoutePlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:" options="tradeRoutePlus"><strong>+</strong><span>торговые пути</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_farmlistPlus" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_farmlistPlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:, value:" options="farmlistPlus"><strong>+100x100</strong><span> избранных целей</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_celebrationPlus" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_celebrationPlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:1, value:" options="celebrationPlus"><strong>+1</strong><span> дополнительный слот для праздника</span></span>
																			</td>
																		</tr>
																		<tr ng-repeat="feature in item.features" tooltip="" tooltip-show="true" tooltip-placement="above" tooltip-url="" tooltip-class="bundleFeatureTooltip" tooltip-translate="Shop.Bundle.Feature.Tooltip_researchPlus" tooltip-data="x:">
																			<td>
																				<i class="shop_feature_researchPlus_large_illu"></i>
																			</td>
																			<td>
																				<span ng-if="::!feature.heroItemType" data="x:1, value:" options="researchPlus"><strong>+1</strong><span> дополнительный слот в кузнице</span></span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div ng-if="item.limitation && item.price" class="packageQuantity" data="x:1">
												<span>Количество: 1</span>
											</div>
											<button ng-if="!item.boost && !item.paynetCode && (!item.limitation || item.limitation > item.booked) && (!player.data[item.name + 'Time'] || player.data[item.name + 'Time'] >= 0)" class="premium packageButton" ng-class="{disabled: (item.startTime && item.startTime > $root.currentServerTime) || (item.endTime && item.endTime < $root.currentServerTime)}" tooltip="" tooltip-data="date:" tooltip-translate-switch="{'Shop.Deal.State.Tooltip.Preview':false,'Shop.Deal.State.Tooltip.LimitedTime':false}" premium-feature="plusAccount" price="153" premium-callback-param="true">
												<div class="content">
													<span ng-transclude="" class="innerText">
														<span class="truncated"><span>Покупка</span></span>
													</span>
													<span class="price gold" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>153</span>
													<div class="animationOverlay"></div>
													<i class="checkmark action_check_small_flat_white"></i>
													<i class="checkmark action_check_small_flat_black"></i>
													<i class="checkmark action_check_small_flat_red"></i>
												</div>
											</button>
										</div>
									</div>
									<div class="bottomDecoration"></div>
								</div>
								<div class="packageImage shop_plusAccount_large_illu"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</carousel>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div ng-transclude="">
		<div ng-controller="buildingIndexCtrl" class="building buildingType0 buildingResourceProduction" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}" style="padding-top: 12px;">
			<div ng-include="" src="pageTemplate">
				<div ng-controller="constructBuildingCtrl" class="constructBuilding">
					<div class="contentBox gradient">
						<div class="contentBoxBody">
							<carousel carousel-template="tpl/building/constructBuilding/buildingList.html" carousel-items="buildings" carousel-active-item="activeItem" carousel-item-primary-key="buildingType" carousel-window-height="w.maxWindowHeight" class="col_4 rows_1">
								<div class="carousel">
									<div class="pages" ng-show="carousel.pages.length > 1" ng-class="{firstPage: carousel.currentPage == 1, lastPage: carousel.currentPage == carousel.pages.length}">
										<div class="iconButton doubleBorder arrowDirectionFrom" clickable="carousel.previousPage()">
											<i ng-if="!carouselArrowClass" class="symbol_arrowFrom_tiny_flat_black"></i>
										</div>
										<div class="page clickable" ng-repeat="page in carousel.pages" clickable="carousel.goToPage(page)" ng-class="{active: page === carousel.currentPage}"></div><div class="page clickable active" ng-repeat="page in carousel.pages" clickable="carousel.goToPage(page)" ng-class="{active: page === carousel.currentPage}"></div><div class="page clickable" ng-repeat="page in carousel.pages" clickable="carousel.goToPage(page)" ng-class="{active: page === carousel.currentPage}"></div><div class="page clickable" ng-repeat="page in carousel.pages" clickable="carousel.goToPage(page)" ng-class="{active: page === carousel.currentPage}"></div><div class="page clickable" ng-repeat="page in carousel.pages" clickable="carousel.goToPage(page)" ng-class="{active: page === carousel.currentPage}"></div>
										<div class="iconButton doubleBorder arrowDirectionTo" clickable="carousel.nextPage()">
											<i ng-if="!carouselArrowClass" class="symbol_arrowTo_tiny_flat_black"></i>
										</div>
									</div>
									<div class="itemContainer">
										<div class="items" style="width: 590px;">
											<div>
												<div class="item building" ng-show="item" style="width: 145px;">
													<div class="clickableContainer clickable" clickable="carousel.setActiveItem(item)" ng-class="{active: carouselActiveItem.buildingType === item.buildingType}" play-on-click="21">
														<img class="itemImage buildingHuge buildingType25 tribeId3" src="//iwstatic.g.bsrv.su/img/x.gif" alt="" data-cmp-info="10" />
														<div class="itemHead itemDesc">
															<span translate="" options="25"><span>Резиденция</span></span>
														</div>
														<div class="horizontalLine double"></div>
														<div class="itemBody">
															<div class="symbol_lock_medium_wrapper" ng-if="!item.buildable">
																<i class="symbol_lock_medium_flat_black"></i>
															</div>
														</div>
													</div>
												</div>
												<div class="item dummy ng-hide" ng-show="!item" style="width: 145px;" v-if="false"></div>
											</div>
											<div>
												<div class="item building" ng-show="item" style="width: 145px;">
													<div class="clickableContainer clickable" clickable="carousel.setActiveItem(item)" ng-class="{active: carouselActiveItem.buildingType === item.buildingType}" play-on-click="21">
														<img class="itemImage buildingHuge buildingType34 tribeId3" src="//iwstatic.g.bsrv.su/img/x.gif" alt="" data-cmp-info="10" />
														<div class="itemHead itemDesc">
															<span translate="" options="34"><span>Каменотес</span></span>
														</div>
														<div class="horizontalLine double"></div>
														<div class="itemBody">
															<div class="symbol_lock_medium_wrapper" ng-if="!item.buildable">
																<i class="symbol_lock_medium_flat_black"></i>
															</div>
														</div>
													</div>
												</div>
												<div class="item dummy ng-hide" ng-show="!item" style="width: 145px;" v-if="false"></div>
											</div>
											<div>
												<div class="item building" ng-show="item" style="width: 145px;">
													<div class="clickableContainer clickable active" clickable="carousel.setActiveItem(item)" ng-class="{active: carouselActiveItem.buildingType === item.buildingType}" play-on-click="21">
														<img class="itemImage buildingHuge buildingType26 tribeId3" src="//iwstatic.g.bsrv.su/img/x.gif" alt="" data-cmp-info="10" />
														<div class="itemHead itemDesc">
															<span translate="" options="26"><span>Дворец</span></span>
														</div>
														<div class="horizontalLine double"></div>
														<div class="itemBody">
															<div class="symbol_lock_medium_wrapper" ng-if="!item.buildable">
																<i class="symbol_lock_medium_flat_black"></i>
															</div>
														</div>
													</div>
												</div>
												<div class="item dummy ng-hide" ng-show="!item" style="width: 145px;" v-if="false"></div>
											</div>
											<div>
												<div class="item building" ng-show="item" style="width: 145px;">
													<div class="clickableContainer clickable" clickable="carousel.setActiveItem(item)" ng-class="{active: carouselActiveItem.buildingType === item.buildingType}" play-on-click="21">
														<img class="itemImage buildingHuge buildingType8 tribeId3" src="//iwstatic.g.bsrv.su/img/x.gif" alt="" data-cmp-info="10" />
														<div class="itemHead itemDesc">
															<span translate="" options="8"><span>Мельница</span></span>
														</div>
														<div class="horizontalLine double"></div>
														<div class="itemBody">
															<div class="symbol_lock_medium_wrapper" ng-if="!item.buildable">
																<i class="symbol_lock_medium_flat_black"></i>
															</div>
														</div>
													</div>
												</div>
												<div class="item dummy ng-hide" ng-show="!item" style="width: 145px;" v-if="false"></div>
											</div>
										</div>
									</div>
								</div>
							</carousel>
							<div class="constructBuildingInfo">
								<div class="description">
									<h4 translate="" options="26"><span>Дворец</span></h4>
									<div ng-if="!activeItem.upgradeDescription" translate="" options="26" data="param:"><span>Правитель империи живет во дворце. У тебя может быть только один дворец во всех твоих деревнях. Дворец необходим, чтобы объявить деревню столицей.</span></div>
								</div>
								<div class="symbol_i_medium_wrapper">
									<i class="symbol_i_medium_flat_white clickable" clickable="openWindow('help', {'pageId': 'Buildings_' + activeItem.buildingType})" content="Дополнительная информация о здании" v-tippy="{placement: 'top'}" tooltip-translate="ConstructBuilding.ToolTip.BuildingInfo" tooltip-placement="above"></i>
								</div>
								<div class="requiredBuildings" buildings="activeItem.requiredBuildings">
									<h4 translate=""><span>Требования</span></h4>
									<span class="buildingInfo" content="Текущий уровень здания: 3" v-tippy="{placement: 'bottom'}" tooltip-translate="Tooltip.CurrentLevel" tooltip-data="number:3" ng-repeat="(i,building) in buildings">
										<a clickable="openWindow('help', {'pageId': 'Buildings_' + building.buildingType})" translate="" options="15" class="clickable"><span>Главное здание</span></a> <span translate="" data="lvl:5"><span>Уровень 5</span></span>
										<span ng-if="!building.valid" class="neededLevels">(&lrm;&#x202D;+&#x202D;2&#x202C;&#x202C;)</span>
									</span>
									<span class="buildingInfo" content="Текущий уровень здания: 0" v-tippy="{placement: 'bottom'}" tooltip-translate="Tooltip.CurrentLevel" tooltip-data="number:0" ng-repeat="(i,building) in buildings">
										<a clickable="openWindow('help', {'pageId': 'Buildings_' + building.buildingType})" translate="" options="18" class="clickable"><span>Посольство</span></a> <span translate="" data="lvl:1"><span>Уровень 1</span></span>
										<span ng-if="!building.valid" class="neededLevels">(&lrm;&#x202D;+&#x202D;1&#x202C;&#x202C;)</span>
									</span>
								</div>
							</div>
							<display-resources class="costsFooter" resources="activeItem.upgradeCosts" population="1" time="3420" available="storage">
								<div class="costs max3digits">
									<span ng-if="!checkStorage">
										<span ng-repeat="(index, type) in resTypes" class="resource woodValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" content="Древесина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_1">
											<i class="unit_wood_small_illu resType1"></i>
											<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;550&#x202C;&#x202C;</span>
										</span>
										<span ng-repeat="(index, type) in resTypes" class="resource clayValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" content="Глина" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_2">
											<i class="unit_clay_small_illu resType2"></i>
											<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;800&#x202C;&#x202C;</span>
										</span>
										<span ng-repeat="(index, type) in resTypes" class="resource ironValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" content="Железо" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_3">
											<i class="unit_iron_small_illu resType3"></i>
											<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;750&#x202C;&#x202C;</span>
										</span>
										<span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) &amp;&amp; colorPositive}" content="Зерно" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource_4">
											<i class="unit_crop_small_illu resType4"></i>
											<span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;250&#x202C;&#x202C;</span>
										</span>
									</span>
									<span class="resource populationValue" ng-if="population || population == 0" content="Население" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource.Population">
										<i class="unit_population_small_illu"></i>&lrm;&#x202D;+&#x202D;1&#x202C;&#x202C;
									</span>
									<span class="resource timeValue" ng-if="time || time == 0" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration">
										<i class="symbol_clock_small_flat_black duration"></i>00:57:00
									</span>
									<div class="enoughResTime" ng-if="enoughResSecs > 0" translate="" data="time: 1707785078"><span>Достаточно ресурсов: </span><span i18ndt="1707785078" format="short">Завтра 06:44</span></div>
									<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined">
										<i class="feature_npcTrader_small_flat_black"></i>
									</div>
								</div>
							</display-resources>
						</div>
					</div>
					<button ng-class="{disabled: !activeItem.buildable}" ng-show="(enoughRes &amp;&amp; canQueue) || !activeItem.buildable" clickable="build()" content="Требования не выполнены" v-tippy="{placement: 'top'}" tooltip-translate-switch="{'ConstructBuilding.ToolTip.DisabledDueToRequirements': true}" class="startConstruction clickable disabled">
						<div class="content">
							<span ng-transclude="" class="innerText">
								<span translate=""><span>Построить</span></span>
							</span>
							<div class="animationOverlay"></div>
							<i class="checkmark action_check_small_flat_white"></i>
							<i class="checkmark action_check_small_flat_black"></i>
							<i class="checkmark action_check_small_flat_red"></i>
						</div>
					</button>
					<button ng-class="{disabled: !masterBuilderFree}" ng-show="(!enoughRes || !canQueue) &amp;&amp; activeItem.buildable" clickable="build()" tooltip="" tooltip-translate-switch="{'ConstructBuilding.ToolTip.DisabledDueToMasterBuilderFull': false}" class="startConstruction masterBuilder clickable ng-hide">
						<div class="content">
							<span ng-transclude="" class="innerText">
								<span translate=""><span>Добавить в очередь строительства</span></span>
							</span>
							<div class="animationOverlay"></div>
							<i class="checkmark action_check_small_flat_white"></i>
							<i class="checkmark action_check_small_flat_black"></i>
							<i class="checkmark action_check_small_flat_red"></i>
						</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
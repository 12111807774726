import axios from 'axios';
import lib from '@/lib';

export default class AuthModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		// Инициализация
		this.authChecked = false;
		this.token = lib.localStorageGet('token')||'';
		//this.token = lib.getCookie('bylex_jwt')||'';
		
		this.userProfile = null;
		this.userRoles = [];
	}
	
	/**
	 * Авторизация
	 */
	async doLogin(username, password)
	{
		return await fetch('https://id.bylex.su/api/auth/login', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				username,
				password,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(async (data) => {
			//console.log(data);
			if(data.error && data.error != ''){
				console.log(data.error||'');
				this.userProfile = null;
				this.userRoles = [];
				lib.localStorageRemove('token');
				this.token = '';
				//delete axios.defaults.headers.common['Authorization'];
				this.authChecked = false;
				return {
					success: false,
					code: data.code,
					error: data.error,
				};
			}
			let token = data.token;
			//user.accessToken = token;
			lib.localStorageSet('token', token);
			this.token = token;
			//axios.defaults.headers.common['Authorization'] = token;
			this.setUserProfile(data.user_data);
			//storeInstance.state.app.setLang((this.userSettings||{}).lang||'ru');
			this.userRoles = data.user_roles||[];
			this.authChecked = true;
			return {
				success: true,
			};
		}).catch(error => {
			console.log(error);
			this.userProfile = null;
			this.userRoles = [];
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Авторизация через соцсети
	 */
	async socialLogin(provider, code)
	{
		return await fetch('https://id.bylex.su/api/social-auth/' + provider + '/callback', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				code,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(async (data) => {
			//console.log(data);
			if(data.error && data.error != ''){
				console.log(data.error||'');
				this.userProfile = null;
				this.userRoles = [];
				lib.localStorageRemove('token');
				this.token = '';
				//delete axios.defaults.headers.common['Authorization'];
				this.authChecked = false;
				return {
					success: false,
					error: data.error,
				};
			}
			let token = data.token;
			//user.accessToken = token;
			lib.localStorageSet('token', token);
			this.token = token;
			//axios.defaults.headers.common['Authorization'] = token;
			this.setUserProfile(data.user_data);
			//storeInstance.state.app.setLang((this.userSettings||{}).lang||'ru');
			this.userRoles = data.user_roles||[];
			this.authChecked = true;
			return {
				success: true,
			};
		}).catch(error => {
			console.log(error);
			this.userProfile = null;
			this.userRoles = [];
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Делает выход
	 */
	async doLogout()
	{
		return await fetch('https://id.bylex.su/api/auth/logout?' + new URLSearchParams({
			token: this.token,
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			this.userProfile = null;
			this.userRoles = [];
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return true;
		}).catch(error => {
			console.log(error);
			this.userProfile = null;
			this.userRoles = [];
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return false;
		});
	}
	
	/**
	 * Делает проверку на авторизованность
	 */
	async doCheckAuth()
	{
		if(!this.authChecked){
			return await fetch('https://id.bylex.su/api/auth/checkauth?' + new URLSearchParams({
				token: this.token,
			}), {
				mode: 'cors',
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				credentials: 'include', // include, *same-origin, omit
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}).then(stream => stream.json()).then(async (data) => {
				//console.log(data);
				//console.log('auth check done');
				if(!data.auth){
					// session expired or not exists. logout
					this.userProfile = null;
					this.userRoles = [];
					lib.localStorageRemove('token');
					this.token = '';
					this.authChecked = true;
					return true;
				}
				let token = data.token;
				//user.accessToken = token;
				lib.localStorageSet('token', token);
				this.token = token;
				//axios.defaults.headers.common['Authorization'] = token;
				this.setUserProfile(data.user_data);
				//storeInstance.state.app.setLang((this.userSettings||{}).lang||'ru');
				this.userRoles = data.user_roles||[];
				this.userKeychains = data.user_keychains||[];
				this.authChecked = true;
				return true;
			}).catch(error => {
				console.log(error);
				this.userProfile = null;
				this.userRoles = [];
				lib.localStorageRemove('token');
				this.token = '';
				//delete axios.defaults.headers.common['Authorization'];
				this.authChecked = false;
				return false;
			});
		} else {
			return true;
		}
	}
	
	isAuth()
	{
		return this.token != '' && this.userProfile != null;
	}
	
	getSname()
	{
		return this.userProfile != null ? this.userProfile.sname : '';
	}
	
	getFname()
	{
		return this.userProfile != null ? this.userProfile.fname : '';
	}
	
	getLogin()
	{
		return this.userProfile != null ? this.userProfile.login : '';
	}
	
	getUserId()
	{
		return this.userProfile != null ? this.userProfile.user_id : '';
	}
	
	getAvatarLink()
	{
		return this.userProfile != null ? this.userProfile.ava : '';
	}
	
	hasRole(role_api_name)
	{
		return !!this.userRoles.find(({api_name}) => api_name == role_api_name);
	}
	
	/**
	 * Устанавливает user profile
	 */
	setUserProfile(res)
	{
		if(res == null){
			this.userProfile = null;
		} else {
			this.userProfile = {
				user_id: res.id,
				login: res.login,
				//sname: res.sname,
				//fname: res.fname,
				//lname: res.lname,
				//gender: res.gender,
				email: res.email,
				ava: res.avatar,
			};
		}
	}
}

<template>
	<div class="statisticsTable contentBox transparent">
		<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
			<div class="content" translate=""><span>Лучшие королевства (защита)</span></div>
		</h6>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems[tab]" current-page="currentPage[tab]" display-page-func="displayCurrentPage" route-named-param="statsPage">
			<div ng-transclude="">
				<table class="gradient smallRows pagination page_1">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Королевство" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Kingdom"><i class="community_kingdom_small_flat_black"></i></th>
							<th content="Количество игроков" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.CountPlayers"><i class="community_member_small_flat_black"></i></th>
							<th content="Средние очки защиты" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Defender.AVGPoints" translate=""><span>Ø</span></th>
							<th content="Всего очков защиты" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Defender.Points"><i class="movement_defend_small_flat_black"></i></th>
						</tr>
					</thead>
					<tbody class="ranks">
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>1</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" kingdomid="8">фаршмелы</a>
							</td>
							<td class="number">71</td>
							<td class="number">268</td>
							<td class="number">19&nbsp;045</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>2</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" kingdomid="6">Utopia</a>
							</td>
							<td class="number">32</td>
							<td class="number">435</td>
							<td class="number">13&nbsp;907</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>3</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 29}, null, true)" kingdomid="29">StarLink</a>
							</td>
							<td class="number">10</td>
							<td class="number">723</td>
							<td class="number">7&nbsp;233</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>4</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2">♔Выдры♔</a>
							</td>
							<td class="number">62</td>
							<td class="number">115</td>
							<td class="number">7&nbsp;158</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>5</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3">Roman</a>
							</td>
							<td class="number">44</td>
							<td class="number">149</td>
							<td class="number">6&nbsp;575</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>6</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 32}, null, true)" kingdomid="32">Квадрат</a>
							</td>
							<td class="number">33</td>
							<td class="number">140</td>
							<td class="number">4&nbsp;636</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>7</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">☠Outcast</a>
							</td>
							<td class="number">10</td>
							<td class="number">438</td>
							<td class="number">4&nbsp;380</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>8</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 44}, null, true)" kingdomid="44">Вольные</a>
							</td>
							<td class="number">7</td>
							<td class="number">207</td>
							<td class="number">1&nbsp;452</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>9</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 41}, null, true)" kingdomid="41">- яйца -</a>
							</td>
							<td class="number">5</td>
							<td class="number">126</td>
							<td class="number">629</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>10</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 16}, null, true)" kingdomid="16">Raf</a>
							</td>
							<td class="number">4</td>
							<td class="number">55</td>
							<td class="number">218</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>11</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 54}, null, true)" kingdomid="54">login</a>
							</td>
							<td class="number">1</td>
							<td class="number">109</td>
							<td class="number">109</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="tg-pagination ng-hide" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
					</li>
					<li class="nextPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black disabled"></i>
					</li>
					<li class="lastPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
							action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black disabled"></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper society" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal society">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Сообщества</span></span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<nav class="tabulation  maintab unselectable">
									<a class="tab clickable" :class="{active: tab == 'kingdom-before', inactive: tab != 'kingdom-before'}" @click="onToggleTab('kingdom-before')">
										<div class="content">
											<span>Королевство</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'kingdom', inactive: tab != 'kingdom'}" @click="onToggleTab('kingdom')">
										<div class="content">
											<span>Королевство</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'secret-society', inactive: tab != 'secret-society'}" @click="onToggleTab('secret-society')">
										<div class="content">
											<span>Тайные сообщества</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'friends', inactive: tab != 'friends'}" @click="onToggleTab('friends')">
										<div class="content">
											<span>Друзья</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'attacks', inactive: tab != 'attacks'}" @click="onToggleTab('attacks')">
										<div class="content">
											<span>Нападения</span>
										</div>
									</a>
								</nav>
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div class="society">
													<div class="loadedTab currentTab" v-if="tab == 'kingdom-before'">
														<kingdom />
													</div>
													<div class="loadedTab currentTab" v-if="tab == 'kingdom'">
														<kingdom-in />
													</div>
													<div class="loadedTab currentTab" v-if="tab == 'secret-society'">
														<secret-society />
													</div>
													<div class="loadedTab currentTab" v-if="tab == 'friends'">
														<friends />
													</div>
													<div class="loadedTab currentTab" v-if="tab == 'attacks'">
														<attacks />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay">
							
							<!-- просмотр приглашения на вступление в королевство -->
							<kingdom-invitation v-if="gameModel.society.kingdomInvitationShow" />
							
							<!-- покинуть королевство -->
							<abdicate-governor v-if="gameModel.society.kingdomLeaveShow" />
							
							<!-- пригласить игроков в тайное сообщество -->
							<secret-society-invite v-if="gameModel.society.secretSocietyInviteShow" />
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import Kingdom from '@/components/pages/society/Kingdom';
import KingdomIn from '@/components/pages/society/KingdomIn';
import SecretSociety from '@/components/pages/society/SecretSociety';
import Friends from '@/components/pages/society/Friends';
import Attacks from '@/components/pages/society/Attacks';

import KingdomInvitation from '@/components/pages/society/windowOverlay/KingdomInvitation';
import AbdicateGovernor from '@/components/pages/society/windowOverlay/AbdicateGovernor';
import SecretSocietyInvite from '@/components/pages/society/windowOverlay/SecretSocietyInvite';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Сообщества | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		tab: 'kingdom',
		showSearch: false,
		
		villageBackgroundLeft: '0px',
		
		kingdomInvitationShow: false,
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onWindowOverlayCancel(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_2.ogg');
				audio.play();
			}
		},
		onToggleTab(tab, push = true){
			this.tab = tab;
			if(push) this.$router.push({query: {tab}});
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_8.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.tab) this.tab = this.$route.query.tab;
		else this.$router.push({query: {tab: this.tab}});
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(!to.query.tab) this.$router.push({query: {tab: 'kingdom'}});
		else if(to.query.tab != this.tab) this.onToggleTab(to.query.tab, false);
		next();
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {
		Kingdom,
		KingdomIn,
		SecretSociety,
		Friends,
		Attacks,
		
		KingdomInvitation,
		AbdicateGovernor,
		SecretSocietyInvite,
	},
}
</script>
<template>
	<div class="statisticsTable contentBox transparent kingdom victoryPoints">
		<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
			<div class="content" translate=""><span>Самые успешные королевства (победные очки)</span></div>
		</h6>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems[tab]" current-page="currentPage[tab]" display-page-func="displayCurrentPage" route-named-param="statsPage">
			<div ng-transclude="">
				<table class="gradient smallRows pagination page_{&quot;VictoryPoints&quot;:1}">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Королевство" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Kingdom"><i class="community_kingdom_small_flat_black"></i></th>
							<th colspan="2" class="combinedHeader sum" content="Сокровища (+ изменения этой недели)" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.VictoryPoints.TotalTreasures">
								<i class="unit_treasure_small_flat_black"></i>
							</th>
							<th colspan="2" class="combinedHeader sum" content="Очки победы (+ изменения этой недели)" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.VictoryPoints.TotalPoints">
								<i class="unit_victoryPoints_small_flat_black"></i>
							</th>
						</tr>
					</thead>
					<tbody class="ranks">
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>1.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2" kingdomname="♔Выдры♔">♔Выдры♔</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="106" nolink="true">Брего</a></span>
							</td>
							<td class="number sum">10&nbsp;632
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+1&nbsp;944)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								125&nbsp;365
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+17&nbsp;927)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>2.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" kingdomid="8" kingdomname="фаршмелы">фаршмелы</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="163" nolink="true">ингибитор</a></span>
							</td>
							<td class="number sum">10&nbsp;410
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+2&nbsp;189)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								116&nbsp;571
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+17&nbsp;636)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>3.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" kingdomid="6" kingdomname="Utopia">Utopia</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="155" nolink="true">Nol1k</a></span>
							</td>
							<td class="number sum">7&nbsp;353
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+306)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								102&nbsp;820
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+14&nbsp;224)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>4.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3" kingdomname="Roman">Roman</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="127" nolink="true">Gaius Ulius</a></span>
							</td>
							<td class="number sum">5&nbsp;536
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+250)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								69&nbsp;809
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+10&nbsp;587)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>5.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 32}, null, true)" kingdomid="32" kingdomname="Квадрат">Квадрат</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="766" nolink="true">RuIII_Spirit</a></span>
							</td>
							<td class="number sum">3&nbsp;064
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+327)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								25&nbsp;889
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+5&nbsp;692)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>6.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20" kingdomname="☠Outcast">☠Outcast</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="577" nolink="true">Rytux</a></span>
							</td>
							<td class="number sum">1&nbsp;250
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+6)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								18&nbsp;910
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+2&nbsp;494)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>7.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 44}, null, true)" kingdomid="44" kingdomname="Вольные">Вольные</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1062" nolink="true">Шальная</a></span>
							</td>
							<td class="number sum">1&nbsp;394
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+112)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								14&nbsp;359
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+2&nbsp;653)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>8.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 16}, null, true)" kingdomid="16" kingdomname="Raf">Raf</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="264" nolink="true">Rafail</a></span>
							</td>
							<td class="number sum">714
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+61)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								9&nbsp;086
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+1&nbsp;329)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>9.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 57}, null, true)" kingdomid="57" kingdomname="♔Выдры*♔">♔Выдры*♔</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1403" nolink="true">Минус</a></span>
							</td>
							<td class="number sum">612
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+159)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								1&nbsp;699
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+949)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>10.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 29}, null, true)" kingdomid="29" kingdomname="StarLink">StarLink</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="475" nolink="true">Kalika</a></span>
							</td>
							<td class="number sum">58
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta negative" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(-1)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								937
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+102)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>11.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 41}, null, true)" kingdomid="41" kingdomname="- яйца -">- яйца -</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1073" nolink="true">Ганс</a></span>
							</td>
							<td class="number sum">36
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(+28)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								122
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+40)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
						<tr ng-repeat="result in rankings.Kingdoms[tab]" ng-class="{highlighted: (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0) || result.rank == searchedRank}">
							<td>12.</td>
							<td class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 54}, null, true)" kingdomid="54" kingdomname="login">login</a>
								<span class="kingName"> - <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1733" nolink="true">login</a></span>
							</td>
							<td class="number sum">0
							</td>
							<td class="number sum delta">
								<span ng-if="result.treasuresCurrent != result.treasuresLatestWeek" class="number delta negative" ng-class="{negative: result.treasuresCurrent - result.treasuresLatestWeek < 0}">(-4)</span>
								<!-- ngIf: result.treasuresCurrent == result.treasuresLatestWeek -->
							</td>
							<!-- ngIf: result.bonus -->
							<!-- ngIf: result.bonus -->
							<td class="number sum victoryPoints" ng-if="!result.bonus">
								93
							</td>
							<td class="number sum delta" ng-if="!result.bonus">
								<span ng-if="result.points != result.victoryPointsLastWeek" class="number delta" ng-class="{negative: result.points - result.victoryPointsLastWeek < 0}">(+5)</span>
								<!-- ngIf: result.points == result.victoryPointsLastWeek -->
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="tg-pagination ng-hide" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover &amp;&amp; currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover &amp;&amp; currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover &amp;&amp; currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
					</li>
					<li class="nextPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover &amp;&amp; disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black disabled"></i>
					</li>
					<li class="lastPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover &amp;&amp; disableNext()),
							action_toEnd_tiny_flat_green: endHover &amp;&amp; !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black disabled"></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div ng-controller="inviteFriendCtrl" class="inviteFriend">
		<div tabulation="">
			<nav class="tabulation  maintab unselectable" ng-if="tabData.tabs.length > 1">
				<a class="tab naviTabInviteFriend clickable active" clickable="selectTab('InviteFriend')" ng-repeat="tab in tabData.tabs" id="optimizely_maintab_InviteFriend" ng-class="{active: tab == tabData.currentTab, inactive: tab != tabData.currentTab}" play-on-click="8">
					<div class="content ">
						<span ng-if="!tabData.html[tab]" options="Tab.InviteFriend"><span>Пригласить друга</span></span>
					</div>
				</a>
				<a class="tab naviTabInvitationOverview clickable inactive" clickable="selectTab('InvitationOverview')" ng-repeat="tab in tabData.tabs" id="optimizely_maintab_InvitationOverview" ng-class="{active: tab == tabData.currentTab, inactive: tab != tabData.currentTab}" play-on-click="8">
					<div class="content ">
						<span ng-if="!tabData.html[tab]" options="Tab.InvitationOverview"><span>Обзор приглашений</span></span>
					</div>
				</a>
			</nav>
			<div class="tabulationContent scrollable scrollingDisabled tabContentInviteFriend" ng-class="'tabContent' + tabData.currentTab" scrolling-disabled="true" dynamic="" scrollable="" style="">
				<div class="scrollContentOuterWrapper disableScrolling" style="">
					<div class="scrollContent disableScrolling" dynamic-height="false" style="">
						<div class="scrollContentInnerWrapper" style="width: auto;">
							<div ng-transclude="">
								<div ng-repeat="tab in tabData.loadedTabs" ng-if="tab" class="loadedTab tabInviteFriend currentTab activeTab" ng-class="{currentTab: tab == tabData.currentTab, hiddenTab: tab != tabData.currentTab, activeTab: tab == tabData.activeTab, hiddenDynamicHeight: tab != tabData.activeTab}" ng-include="tabData.bodyTemplatePrefix + tab + tabData.bodyTemplateSuffix">
									<div class="contentBox gradient referralRewards">
										<div class="contentBoxHeader">
											<span class="content"><span>Награды</span></span>
										</div>
										<div class="contentBoxBody">
											<div class="header"><span>Приглашай новых игроков в Travian Kingdoms и получай награду!</span></div>
											<div class="reward">
												<i class="rewardIcon inviteFriend_secondVillage_small_flat_black"></i>
												<span class="text" data="gold: 20"><span>Когда игрок поставит </span><strong>вторую деревню</strong><span>, ты получишь </span><i class="icon unit_gold_small_illu"></i> <strong>20</strong><span>.</span></span>
											</div>
											<div class="reward">
												<i class="rewardIcon inviteFriend_thirdVillage_medium_flat_black"></i>
												<span class="text" data="gold: 30"><span>Когда игрок поставит </span><strong>третью деревню</strong><span>, ты получишь </span><i class="icon unit_gold_small_illu"></i> <strong>30</strong><span>.</span></span>
											</div>
											<div class="reward">
												<i class="rewardIcon inviteFriend_firstPayment_small_flat_black"></i>
												<span class="text" data="gold: 50"><span>Когда игрок впервые </span><strong>приобретет золото</strong><span>, ты получишь </span><i class="icon unit_gold_small_illu"></i> <strong>50</strong><span>.</span></span>
											</div>
											<div class="reward">
												<i class="rewardIcon inviteFriend_prestigeCup_small_flat_black"></i>
												<span class="text" data="gold: 100"><span>Когда игрок наберет </span><strong>1000 престижа</strong><span>, ты получишь </span><i class="icon unit_gold_small_illu"></i> <strong>100</strong><span>. </span><br><span>(возможно в одном или нескольких раундах).</span></span>
											</div>
										</div>
									</div>
									<div style="display: flex; justify-content: space-evenly;">
										<div class="contentBox inviteBox giftBox gradient">
											<div class="contentBoxHeader goldenEnding headerWithArrowEndings">
												<div class="content"><span>Подарок</span></div>
											</div>
											<div class="contentBoxBody">
												<div class="spacer"></div>
												<i class="inviteFriend_invitationGift_large_illu"></i>
												<span><span>В качестве подарка от нас твои друзья получат </span><i class="icon unit_gold_small_illu"></i> <strong>50</strong><span> и </span><strong>Travian Plus на 7 дней</strong><span> после активации.</span><br/><br/><span>Это поможет им развиться быстрее.</span></span>
											</div>
										</div>
										<div class="contentBox inviteBox emailBox">
											<div class="contentBox gradient">
												<div class="contentBoxBody">
													<div class="header"><span>Пригласить по e-mail</span></div>
													<div class="inviteInfo"><span>Нажми на кнопку внизу, чтобы пригласить друзей по e-mail.</span></div>
												</div>
											</div>
											<div class="inviteButton">
												<button ng-click="openSendEmailOverlay()">
													<div class="content">
														<span ng-transclude="" class="innerText">
															<span><span>Пригласить по e-mail</span></span>
														</span>
														<!-- ngIf: price > 0 || forceGoldUsage -->
														<!-- ngIf: price == -1 && !forceGoldUsage -->
														<div class="animationOverlay"></div>
														<i class="checkmark action_check_small_flat_white"></i>
														<i class="checkmark action_check_small_flat_black"></i>
														<i class="checkmark action_check_small_flat_red"></i>
													</div>
												</button>
											</div>
										</div>
										<div class="contentBox inviteBox referralLinkBox">
											<div class="contentBox gradient">
												<div class="contentBoxBody">
													<div class="header"><span>Пригласить с помощью реф-ссылки</span></div>
													<div class="inviteInfo"><a href="http://www.kingdoms.com/ru/#action=register;referral=V6773605" target="_blank"><span>Реф-ссылка</span></a></div>
												</div>
											</div>
											<div class="inviteButton">
												<button ng-click="copyLink()">
													<div class="content">
														<span ng-transclude="" class="innerText">
															<span><span>Скопировать</span></span>
														</span>
														<!-- ngIf: price > 0 || forceGoldUsage -->
														<!-- ngIf: price == -1 && !forceGoldUsage -->
														<div class="animationOverlay"></div>
														<i class="checkmark action_check_small_flat_white"></i>
														<i class="checkmark action_check_small_flat_black"></i>
														<i class="checkmark action_check_small_flat_red"></i>
													</div>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div ng-repeat="tab in tabData.loadedTabs" ng-if="tab" class="loadedTab tabInvitationOverview hiddenTab hiddenDynamicHeight" ng-class="{currentTab: tab == tabData.currentTab, hiddenTab: tab != tabData.currentTab, activeTab: tab == tabData.activeTab, hiddenDynamicHeight: tab != tabData.activeTab}" ng-include="tabData.bodyTemplatePrefix + tab + tabData.bodyTemplateSuffix">
									<div class="friendInvitations">
										<div class="contentBox gradient accepted">
											<div class="contentBoxHeader">
												<span class="content"><span>Принятые приглашения</span></span> (0)
											</div>
											<div scrollable="" class="contentBoxBody scrollable">
												<div class="scrollContentOuterWrapper">
													<div class="scrollContent" style="overflow-y: auto;">
														<div class="scrollContentInnerWrapper" style="width: auto;">
															<table class="transparent ng-hide" ng-show="invitations.accepted.length > 0">
																<thead>
																	<tr>
																		<th><span>Имя персонажа</span></th>
																		<th><i class="rewardIcon inviteFriend_secondVillage_small_flat_black"></i></th>
																		<th><i class="rewardIcon inviteFriend_thirdVillage_medium_flat_black"></i></th>
																		<th><i class="rewardIcon inviteFriend_firstPayment_small_flat_black"></i></th>
																		<th><i class="inviteFriend_prestigeCup_small_flat_black"></i></th>
																		<th></th>
																	</tr>
																</thead>
																<tbody>
																	<!-- ngRepeat: invitation in invitations.accepted | orderBy:'collectibleAmount':true -->
																</tbody>
															</table>
															<p ng-show="invitations.accepted.length === 0">
																<span>В данный момент у тебя нет принятых приглашений. Приглашенные игроки должны активировать свои учетные записи, прежде чем ты сможешь получить какую-либо награду.</span>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="contentBox gradient open">
											<div class="contentBoxHeader">
												<span class="content"><span>Открытые приглашения</span></span> (1)
											</div>
											<div scrollable="" class="contentBoxBody scrollable">
												<div class="scrollContentOuterWrapper">
													<div class="scrollContent" style="overflow-y: auto;">
														<div class="scrollContentInnerWrapper" style="width: auto;">
															<table class="transparent" ng-show="invitations.open.length > 0">
																<tbody>
																	<tr ng-repeat="invitation in invitations.open">
																		<td>alexens3011@mail.ru<span class="rightBorder"></span></td>
																		<td><span i18ndt="1722592574" format="mediumDate">02.08.2024</span></td>
																	</tr>
																</tbody>
															</table>
															<p ng-show="invitations.open.length === 0">
																<span>В данный момент у тебя нет открытых приглашений.</span>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper villagesOverview" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal villagesOverview">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Обзор островов</span></span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<nav class="tabulation  maintab unselectable">
									<a class="tab clickable" :class="{active: tab == 'overview', inactive: tab != 'overview'}" @click="onToggleTab('overview')">
										<div class="content">
											<span>Обзор</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'resources', inactive: tab != 'resources'}" @click="onToggleTab('resources')">
										<div class="content">
											<span>Ресурсы</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'storage', inactive: tab != 'storage'}" @click="onToggleTab('storage')">
										<div class="content">
											<span>Склад</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'culture-point', inactive: tab != 'culture-point'}" @click="onToggleTab('culture-point')">
										<div class="content">
											<span>Единицы культуры</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'troops', inactive: tab != 'troops'}" @click="onToggleTab('troops')">
										<div class="content">
											<span>Войска</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'oases', inactive: tab != 'oases'}" @click="onToggleTab('oases')">
										<div class="content">
											<span>Оазисы</span>
										</div>
									</a>
								</nav>
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div class="loadedTab currentTab" v-if="tab == 'overview'">
													<overview />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'resources'">
													<resources />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'storage'">
													<storage />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'culture-point'">
													<culture-point />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'troops'">
													<troops />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'oases'">
													<oases />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import Overview from '@/components/pages/villageOverview/Overview';
import Resources from '@/components/pages/villageOverview/Resources';
import Storage from '@/components/pages/villageOverview/Storage';
import CulturePoint from '@/components/pages/villageOverview/CulturePoint';
import Troops from '@/components/pages/villageOverview/Troops';
import Oases from '@/components/pages/villageOverview/Oases';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Обзор островов | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		tab: 'overview',
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onToggleTab(tab, push = true){
			this.tab = tab;
			if(push) this.$router.push({query: {tab}});
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_8.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.tab) this.tab = this.$route.query.tab;
		else this.$router.push({query: {tab: this.tab}});
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(!to.query.tab) this.$router.push({query: {tab: 'overview'}});
		else if(to.query.tab != this.tab) this.onToggleTab(to.query.tab, false);
		next();
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {
		Overview,
		Resources,
		Storage,
		CulturePoint,
		Troops,
		Oases,
	},
}
</script>
<template>
	<div class="loyaltyOverview contentBox gradient">
		<div class="contentBoxBody">
			<div class="marginToScrollbar"></div>
			<div class="contentBox acceptanceOverview">
				<h6 class="contentBoxHeader headerTrapezoidal">
					<div class="content" translate=""><span>Текущее одобрение</span></div>
				</h6>
				<div class="contentBoxBody">
					<div class="acceptanceInActiveVillage">
						<span translate=""><span>Одобрение в открытой деревне:</span></span>
						<span ng-class="{high: activeVillage.data.acceptance >= 100, low: activeVillage.data.acceptance < 100}" class="high">&#x202D;&#x202D;100&#x202C;%&#x202C; (<span translate="" data="prod:6"><span>&#x202D;+&#x202D;6&#x202C;&#x202C;/ч</span></span>)</span>
					</div>
					<div>
						<div class="first">
							<p class="acceptanceDescription" translate=""><span>Резиденция и дворец защищают деревню от захвата. Если они будут уничтожены, успешные атаки предводителями, вождями или сенаторами могут понизить одобрение жителей. Если одобрение опускается до нуля, деревня примкнет к империи атакующего.</span></p>
							<p class="acceptanceDescription2" translate=""><span>Идущее торжество в деревне атакующего или обороняющегося соответственно увеличивает или уменьшает степень убедительности предводителей. Каждый уровень резиденции или дворца повышает скорость, с которой восстанавливается одобрение до &#x202D;&#x202D;100&#x202C;%&#x202C;.</span></p>
						</div>
						<div class="middle verticalLine"></div>
						<div class="second">
							<table class="acceptanceVillages fixedTableHeader scrollable" scrollable="" height="300">
								<div class="scrollContentOuterWrapper" style="width: 274px;">
									<div class="scrollContent" dynamic-height="false" style="width: 274px; overflow-y: hidden;">
										<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
											<thead>
												<tr>
													<th class="name"><i class="village_village_small_flat_black" content="Деревни" v-tippy="{placement: 'bottom'}"></i></th>
													<th class="population"><i class="unit_population_small_flat_black" content="Население" v-tippy="{placement: 'bottom'}"></i></th>
													<th class="acceptance" translate=""><span>Одобрение</span></th>
												</tr>
											</thead>
											<tbody>
												<tr ng-repeat="village in villages | orderBy:['data.acceptance', 'data.name']">
													<td class="name"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" villagename="Брест">Брест</a></td>
													<td class="population">529</td>
													<td class="acceptance high" ng-class="{high: village.data.acceptance >= 100, low: village.data.acceptance < 100}">&#x202D;&#x202D;100&#x202C;%&#x202C; (<span translate="" data="prod:10"><span>&#x202D;+&#x202D;10&#x202C;&#x202C;/ч</span></span>)</td>
												</tr>
												<tr ng-repeat="village in villages | orderBy:['data.acceptance', 'data.name']">
													<td class="name"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264692}, null, true)" village-link="" villageid="536264692" villagename="Зарница">Зарница</a></td>
													<td class="population">251</td>
													<td class="acceptance high" ng-class="{high: village.data.acceptance >= 100, low: village.data.acceptance < 100}">&#x202D;&#x202D;100&#x202C;%&#x202C; (<span translate="" data="prod:6"><span>&#x202D;+&#x202D;6&#x202C;&#x202C;/ч</span></span>)</td>
												</tr>
											</tbody>
										</div>
									</div>
								</div>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
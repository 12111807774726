<!--ратуша-->

<template>
	<div class="arrowsHidden rows_1" :class="['col_'+cols]">
		<div class="carousel">
			<div class="pages" v-if="pager.totalPages > 1" :class="{
				'firstPage': pager.currentPage == 1,
				'lastPage': pager.currentPage == pager.totalPages,
			}">
				<div class="iconButton doubleBorder arrowDirectionFrom" @click="onPreviousPage()">
					<i v-if="!arrowClass" class="symbol_arrowFrom_tiny_flat_black"></i>
				</div>
				<div class="page clickable" v-for="page in range(1, pager.totalPages)" :key="page" @click="setPage(page)" :class="{
					'active': page == pager.currentPage,
				}"></div>
				<div class="iconButton doubleBorder arrowDirectionTo" @click="onNextPage()">
					<i v-if="!arrowClass" class="symbol_arrowTo_tiny_flat_black"></i>
				</div>
			</div>
			<div class="itemContainer">
				<div class="items" style="width: 295px;">
					<div v-for="item in itemsOnPage">
						<div class="orderItem item celebration" style="width: 145px;">
							<div class="clickableContainer clickable" @click="onSelectItem(item)" :class="{
								'active': activeItem.type === item.type,
							}">
								<img src="//iwstatic.g.bsrv.su/img/x.gif" class="itemImage celebration" :class="{
									'celebration_small_illu': item.type == 1,
									'celebration_large_illu': item.type == 2,
									'celebration_brewery_illu': item.type == 3 && !item.isTeaHouse,
									'celebration_teahouse_illu': item.type == 3 && item.isTeaHouse,
								}" data-cmp-info="10"/>
								<div class="itemHead">
									<span><span>{{item.name}}</span></span>
								</div>
								<div class="horizontalLine double">
									<h6 class="headerTrapezoidal bright" v-if="!item.disabled">
										<div class="content">
											<i class="unit_culturePoint_small_flat_black" content="Единицы культуры" v-tippy="{placement: 'bottom'}" tooltip-translate="MainBuilding.CulturePoints"></i>
											<span>&nbsp;{{item.culturePoints}}</span>
										</div>
									</h6>
								</div>
								<div class="itemBody">
									<div class="symbol_lock_medium_wrapper" v-if="item.disabled">
										<i class="symbol_lock_medium_flat_black"></i>
									</div>
									<div class="lockExplain" v-if="item.disabled">
										<div class="content">
											<span options="level"><span>Требуется {{item.requiredLevel}} уровень</span></span>
										</div>
									</div>
									<div class="progressContainer" v-if="item.queue.count">
										<div class="queueAmount">{{item.queue.count}}</div>
										<div class="progressbar" finish-time="1723643172" finish-time-total="1723643172" duration="86400" show-countdown="true" :content="tooltips[1]" v-tippy="{placement: 'bottom'}" tooltip-translate="CelebrationEndTime" tooltip-data="time:1723643172">
											<!-- ngIf: ::labelIcon -->
											<div class="progress">
												<!-- ngIf: steps -->
												<div class="bar positive perc" :style="{zIndex: 2, width: getPercent(item.duration + (gameModel.currentServerTime - item.time), item.duration)+'%'}"></div>
												<div class="bar additionalBar additionalPerc"></div>
												<div class="marker">
													<div class="markerInner"></div>
												</div>
												<div ng-if="::showCountdown" class="countdown" :countdown="item.time">22:14:31</div>
												<!-- ngIf: ::label -->
												<!-- ngIf: ::percTooltip -->
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
	
</style>

<script>
import lib from '@/lib';
import paginate from 'jw-paginate';

export default {
	mixins: lib.mixins,
	props: {
		items: {
			type: Array,
			default: [],
		},
		cols: {
			type: Number,
			default: 2,
		},
		arrowClass: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onActiveItem'],
	data: () => ({
		itemsOnPage: [],
		pager: {},
		
		currentPage: 1,
		activeItem: null,
		
		tooltips: {
			1: `<span translate="" options="CelebrationEndTime"><span>Празднование завершится через </span><span countdown="1723643172" callback="">20:04:16</span></span>`,
		},
	}),
	computed: {},
	watch: {
		items: {
			handler(val, oldVal) {
				if(this.pager){
					this.setPage(this.pager.currentPage);
				} else {
					this.setPage(1);
				}
			},
			deep: true,
		},
	},
	methods: {
		range: lib.range,
		getPercent: lib.getPercent,
		
		onNextPage(){
			if(this.pager.currentPage < this.pager.totalPages){
				this.setPage(this.pager.currentPage + 1);
			} else {
				this.setPage(1);
			}
		},
		onPreviousPage(){
			if(this.pager.currentPage > 1){
				this.setPage(this.pager.currentPage - 1);
			} else {
				this.setPage(this.pager.totalPages);
			}
		},
		setPage(page){
			const { items } = this;
			const pager = paginate(items.length, page, 4, 10);
			this.itemsOnPage = items.slice(pager.startIndex, pager.endIndex + 1);
			this.pager = pager;
		},
		onSelectItem(item){
			this.activeItem = item;
			this.$emit('onActiveItem', item);
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_21.ogg');
				audio.play();
			}
		},
	},
	created(){
		this.setPage(1);
		if(this.items.length > 0){
			this.onSelectItem(this.items[0]);
		}
	},
	components: {},
}
</script>
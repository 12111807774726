<template>
	<div class="statisticsTable kingdomRanking" ng-controller="kingdomRankingCtrl">
		<div class="treasureRank contentBox transparent">
			<h6 class="contentBoxHeader headerWithArrowEndings tableGreen">
				<div class="content" translate=""><span>Рейтинг сокровищ</span></div>
			</h6>
			<table class="gradient showOwnRank" ng-class="{'inRank': ownTreasureRank &amp;&amp; ownTreasureRank.rank < 10}">
				<thead>
					<tr>
						<th colspan="3">
							<span translate=""><span>Внесенные сокровища за неделю</span></span>
						</th>
					</tr>
				</thead>
				<tbody class="ranks">
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="314" player-name="Mild" rank="0" points="119">
						<td class="rankColumn rank1" ng-if="rank >= 0">1</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="314" player-name="Mild" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="314" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="314" playername="Mild" nolink="false">Mild</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="314" size="tiny">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
									</div>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">119</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="115" player-name="Scepa" rank="1" points="109">
						<td class="rankColumn rank2" ng-if="rank >= 0">2</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="115" player-name="Scepa" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="115" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="115" playername="Scepa" nolink="false">Scepa</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="115" size="tiny">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
									</div>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">109</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="637" player-name="Неясыть" rank="2" points="63">
						<td class="rankColumn rank3" ng-if="rank >= 0">3</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="637" player-name="Неясыть" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="637" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="637" playername="Неясыть" nolink="false">Неясыть</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="637" size="tiny">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
									</div>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">63</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="170" player-name="VIX" rank="3" points="47">
						<td class="rankColumn rank4" ng-if="rank >= 0">4</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="170" player-name="VIX" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="170" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="170" playername="VIX" nolink="false">VIX</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="170" size="tiny">
									<i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">47</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="125" player-name="сербез" rank="4" points="42">
						<td class="rankColumn rank5" ng-if="rank >= 0">5</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="125" player-name="сербез" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="125" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="125" playername="сербез" nolink="false">сербез</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="125" size="tiny">
									<i ng-if="badge" class="prestigeBadge prestige_silverBadge_tiny_illu"></i>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">42</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="192" player-name="KING" rank="5" points="36">
						<td class="rankColumn rank6" ng-if="rank >= 0">6</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="192" player-name="KING" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="192" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="192" playername="KING" nolink="false">KING</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="192" size="tiny">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
									</div>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">36</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="131" player-name="Tils" rank="6" points="34">
						<td class="rankColumn rank7" ng-if="rank >= 0">7</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="131" player-name="Tils" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="131" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="131" playername="Tils" nolink="false">Tils</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="131" size="tiny">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
									</div>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">34</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="610" player-name="ппц" rank="7" points="33">
						<td class="rankColumn rank8" ng-if="rank >= 0">8</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="610" player-name="ппц" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="610" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="610" playername="ппц" nolink="false">ппц</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="610" size="tiny">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										<i class="prestigeStar prestige_starGold_tiny_illu"></i>
									</div>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">33</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="756" player-name="pavlun" rank="8" points="30">
						<td class="rankColumn rank9" ng-if="rank >= 0">9</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="756" player-name="pavlun" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="756" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="756" playername="pavlun" nolink="false">pavlun</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="756" size="tiny">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
									</div>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">30</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in treasuresRanking | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="662" player-name="Пригожин" rank="9" points="22">
						<td class="rankColumn rank10" ng-if="rank >= 0">10</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="662" player-name="Пригожин" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="662" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="662" playername="Пригожин" nolink="false">Пригожин</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="662" size="tiny">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
									</div>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">22</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
				</tbody>
				<tbody class="ownRank" ng-if="!ownTreasureRank || ownTreasureRank.rank > 9">
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" player-rank="" player-id="1804" player-name="" rank="" points="">
						<td class="rankColumn rank1" ng-if="rank >= 0">90</td>
						<!-- ngIf: !rank && rank != 0 -->
						<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
						<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="1804" player-name="" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
							<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
								<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
									<!-- ngIf: playerId == robberId -->
									<!-- ngIf: playerId == npcGovernorId -->
									<canvas width="62" height="62"></canvas>
								</div>
							</avatar-image>
							<!-- ngIf: ::accountId -->
							<!-- ngIf: chatUser -->
							<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

							<!-- ngIf: kingdomId >= 0 -->
							<div class="prestigeStars" ng-if="!hidePrestige">
								<div class="innerStars" playerid="1804" size="tiny">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
									</div>
								</div>
							</div>
						</td>
						<td class="number pointsColumn" ng-if="playerId >= 100">0</td>

						<!-- ngIf: Player.isNPC(playerId) -->
					</tr>
				</tbody>
			</table>
		</div>
		<div class="playerOfTheWeek">
			<div class="contentBox transparent">
				<h6 class="contentBoxHeader headerWithArrowEndings tableGreen">
					<div class="content" translate=""><span>Атакующий недели</span></div>
				</h6>
				<table class="gradient showOwnRank" ng-class="{'inRank': ownRanks.top10Attacker &amp;&amp; ownRanks.top10Attacker.rank < 3}">
					<thead>
						<tr>
							<th colspan="3">&nbsp;</th>
						</tr>
					</thead>
					<tbody class="ranks">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="i in [0, 1, 2]" ng-class="{'highlighted': kingdom.stats.top10Attacker[i].playerId == player.data.playerId}" player-rank="" player-id="637" player-name="Неясыть" rank="0" points="7107">
							<td class="rankColumn rank1" ng-if="rank >= 0">1</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="637" player-name="Неясыть" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="637" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="637" playername="Неясыть" nolink="false">Неясыть</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="637" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">7&nbsp;107</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="i in [0, 1, 2]" ng-class="{'highlighted': kingdom.stats.top10Attacker[i].playerId == player.data.playerId}" player-rank="" player-id="610" player-name="ппц" rank="1" points="2442">
							<td class="rankColumn rank2" ng-if="rank >= 0">2</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="610" player-name="ппц" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="610" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="610" playername="ппц" nolink="false">ппц</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="610" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">2&nbsp;442</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="i in [0, 1, 2]" ng-class="{'highlighted': kingdom.stats.top10Attacker[i].playerId == player.data.playerId}" player-rank="" player-id="127" player-name="Gaius Ulius" rank="2" points="1485">
							<td class="rankColumn rank3" ng-if="rank >= 0">3</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="127" player-name="Gaius Ulius" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="127" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="127" playername="Gaius Ulius" nolink="false">Gaius Ulius</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="127" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">1&nbsp;485</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
					<tbody class="ownRank" ng-if="!ownRanks.top10Attacker || ownRanks.top10Attacker.rank > 2">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" player-rank="" player-id="1804" player-name="" rank="" points="">
							<td class="rankColumn rank1" ng-if="rank >= 0">99</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="1804" player-name="" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1804" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">30</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
				</table>
			</div>

			<div class="contentBox transparent">
				<h6 class="contentBoxHeader headerWithArrowEndings tableGreen">
					<div class="content" translate=""><span>Обороняющийся недели</span></div>
				</h6>
				<table class="gradient showOwnRank" ng-class="{'inRank': ownRanks.top10Defender &amp;&amp; ownRanks.top10Defender.rank < 3}">
					<thead>
						<tr>
							<th colspan="3">&nbsp;</th>
						</tr>
					</thead>
					<tbody class="ranks">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="i in [0, 1, 2]" ng-class="{'highlighted': kingdom.stats.top10Defender[i].playerId == player.data.playerId}" player-rank="" player-id="314" player-name="Mild" rank="0" points="629">
							<td class="rankColumn rank1" ng-if="rank >= 0">1</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="314" player-name="Mild" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="314" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="314" playername="Mild" nolink="false">Mild</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="314" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">629</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="i in [0, 1, 2]" ng-class="{'highlighted': kingdom.stats.top10Defender[i].playerId == player.data.playerId}" player-rank="" player-id="1890" player-name="Marabut" rank="1" points="404">
							<td class="rankColumn rank2" ng-if="rank >= 0">2</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="1890" player-name="Marabut" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1890" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1890" playername="Marabut" nolink="false">Marabut</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1890" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">404</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="i in [0, 1, 2]" ng-class="{'highlighted': kingdom.stats.top10Defender[i].playerId == player.data.playerId}" player-rank="" player-id="1379" player-name="Хобот" rank="2" points="53">
							<td class="rankColumn rank3" ng-if="rank >= 0">3</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="1379" player-name="Хобот" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1379" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1379" playername="Хобот" nolink="false">Хобот</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1379" size="tiny">
										<i ng-if="badge" class="prestigeBadge prestige_silverBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">53</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
					<tbody class="ownRank" ng-if="!ownRanks.top10Defender || ownRanks.top10Defender.rank > 2">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" player-rank="" player-id="1804" player-name="" rank="" points="">
							<td class="rankColumn rank1" ng-if="rank >= 0">67</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="1804" player-name="" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1804" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">69</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
				</table>
			</div>

			<div class="contentBox transparent">
				<h6 class="contentBoxHeader headerWithArrowEndings tableGreen">
					<div class="content" translate=""><span>Взлет недели</span></div>
				</h6>
				<table class="gradient showOwnRank" ng-class="{'inRank': ownRanks.top10Climber &amp;&amp; ownRanks.top10Climber.rank < 3}">
					<thead>
						<tr>
							<th colspan="3">&nbsp;</th>
						</tr>
					</thead>
					<tbody class="ranks">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="i in [0, 1, 2]" ng-class="{'highlighted': kingdom.stats.top10Climber[i].playerId == player.data.playerId}" player-rank="" player-id="890" player-name="AnDee" rank="0" points="75">
							<td class="rankColumn rank1" ng-if="rank >= 0">1</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="890" player-name="AnDee" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="890" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="890" playername="AnDee" nolink="false">AnDee</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="890" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">75</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="i in [0, 1, 2]" ng-class="{'highlighted': kingdom.stats.top10Climber[i].playerId == player.data.playerId}" player-rank="" player-id="1906" player-name="Бык" rank="1" points="64">
							<td class="rankColumn rank2" ng-if="rank >= 0">2</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="1906" player-name="Бык" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1906" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1906" playername="Бык" nolink="false">Бык</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1906" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">64</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="i in [0, 1, 2]" ng-class="{'highlighted': kingdom.stats.top10Climber[i].playerId == player.data.playerId}" player-rank="" player-id="170" player-name="VIX" rank="2" points="52">
							<td class="rankColumn rank3" ng-if="rank >= 0">3</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="170" player-name="VIX" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="170" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="170" playername="VIX" nolink="false">VIX</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="170" size="tiny">
										<i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">52</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
					<tbody class="ownRank" ng-if="!ownRanks.top10Climber || ownRanks.top10Climber.rank > 2">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" player-rank="" player-id="1804" player-name="" rank="" points="">
							<td class="rankColumn rank1" ng-if="rank >= 0">86</td>
							<!-- ngIf: !rank && rank != 0 -->
							<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId === undefined" player-column="" player-id="1804" player-name="" chat-user="chatUser" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

								<!-- ngIf: kingdomId >= 0 -->
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1804" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							<td class="number pointsColumn" ng-if="playerId >= 100">-5</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
import axios from 'axios';
import lib from '@/lib';

import TroopsModel from '@/models/game/TroopsModel';

//import HelpContentBuildingsInfrastructure15 from '@/components/pages/help/content/buildings/infrastructure/15';
//import HelpContentBuildingsInfrastructure10 from '@/components/pages/help/content/buildings/infrastructure/10';
//import HelpContentBuildingsInfrastructure11 from '@/components/pages/help/content/buildings/infrastructure/11';
//import HelpContentBuildingsInfrastructure23 from '@/components/pages/help/content/buildings/infrastructure/23';
//import HelpContentBuildingsInfrastructure17 from '@/components/pages/help/content/buildings/infrastructure/17';
//import HelpContentBuildingsInfrastructure18 from '@/components/pages/help/content/buildings/infrastructure/18';
//import HelpContentBuildingsInfrastructure25 from '@/components/pages/help/content/buildings/infrastructure/25';
//import HelpContentBuildingsInfrastructure26 from '@/components/pages/help/content/buildings/infrastructure/26';
//import HelpContentBuildingsInfrastructure24 from '@/components/pages/help/content/buildings/infrastructure/24';
//import HelpContentBuildingsInfrastructure27 from '@/components/pages/help/content/buildings/infrastructure/27';
//import HelpContentBuildingsInfrastructure45 from '@/components/pages/help/content/buildings/infrastructure/45';
//import HelpContentBuildingsInfrastructure34 from '@/components/pages/help/content/buildings/infrastructure/34';
//import HelpContentBuildingsInfrastructure28 from '@/components/pages/help/content/buildings/infrastructure/28';
//import HelpContentBuildingsInfrastructure40 from '@/components/pages/help/content/buildings/infrastructure/40';
//import HelpContentBuildingsInfrastructure38 from '@/components/pages/help/content/buildings/infrastructure/38';
//import HelpContentBuildingsInfrastructure39 from '@/components/pages/help/content/buildings/infrastructure/39';

//import HelpContentBuildingsMilitary16 from '@/components/pages/help/content/buildings/military/16';
//import HelpContentBuildingsMilitary19 from '@/components/pages/help/content/buildings/military/19';
//import HelpContentBuildingsMilitary20 from '@/components/pages/help/content/buildings/military/20';
//import HelpContentBuildingsMilitary21 from '@/components/pages/help/content/buildings/military/21';
//import HelpContentBuildingsMilitary22 from '@/components/pages/help/content/buildings/military/22';
//import HelpContentBuildingsMilitary13 from '@/components/pages/help/content/buildings/military/13';
//import HelpContentBuildingsMilitary46 from '@/components/pages/help/content/buildings/military/46';
//import HelpContentBuildingsMilitary36 from '@/components/pages/help/content/buildings/military/36';
//import HelpContentBuildingsMilitary31 from '@/components/pages/help/content/buildings/military/31';
//import HelpContentBuildingsMilitary32 from '@/components/pages/help/content/buildings/military/32';
//import HelpContentBuildingsMilitary33 from '@/components/pages/help/content/buildings/military/33';
//import HelpContentBuildingsMilitary43 from '@/components/pages/help/content/buildings/military/43';
//import HelpContentBuildingsMilitary42 from '@/components/pages/help/content/buildings/military/42';
//import HelpContentBuildingsMilitary14 from '@/components/pages/help/content/buildings/military/14';
//import HelpContentBuildingsMilitary41 from '@/components/pages/help/content/buildings/military/41';
//import HelpContentBuildingsMilitary35 from '@/components/pages/help/content/buildings/military/35';
//import HelpContentBuildingsMilitary29 from '@/components/pages/help/content/buildings/military/29';
//import HelpContentBuildingsMilitary30 from '@/components/pages/help/content/buildings/military/30';

//import HelpContentBuildingsResources1 from '@/components/pages/help/content/buildings/resources/1';
//import HelpContentBuildingsResources2 from '@/components/pages/help/content/buildings/resources/2';
//import HelpContentBuildingsResources3 from '@/components/pages/help/content/buildings/resources/3';
//import HelpContentBuildingsResources4 from '@/components/pages/help/content/buildings/resources/4';
//import HelpContentBuildingsResources5 from '@/components/pages/help/content/buildings/resources/5';
//import HelpContentBuildingsResources6 from '@/components/pages/help/content/buildings/resources/6';
//import HelpContentBuildingsResources7 from '@/components/pages/help/content/buildings/resources/7';
//import HelpContentBuildingsResources8 from '@/components/pages/help/content/buildings/resources/8';
//import HelpContentBuildingsResources9 from '@/components/pages/help/content/buildings/resources/9';

//import HelpContentTroopsRomans1 from '@/components/pages/help/content/troops/romans/1';
//import HelpContentTroopsRomans2 from '@/components/pages/help/content/troops/romans/2';
//import HelpContentTroopsRomans3 from '@/components/pages/help/content/troops/romans/3';
//import HelpContentTroopsRomans4 from '@/components/pages/help/content/troops/romans/4';
//import HelpContentTroopsRomans5 from '@/components/pages/help/content/troops/romans/5';
//import HelpContentTroopsRomans6 from '@/components/pages/help/content/troops/romans/6';
//import HelpContentTroopsRomans7 from '@/components/pages/help/content/troops/romans/7';
//import HelpContentTroopsRomans8 from '@/components/pages/help/content/troops/romans/8';
//import HelpContentTroopsRomans9 from '@/components/pages/help/content/troops/romans/9';
//import HelpContentTroopsRomans10 from '@/components/pages/help/content/troops/romans/10';

//import HelpContentTroopsTeutons1 from '@/components/pages/help/content/troops/teutons/1';
//import HelpContentTroopsTeutons2 from '@/components/pages/help/content/troops/teutons/2';
//import HelpContentTroopsTeutons3 from '@/components/pages/help/content/troops/teutons/3';
//import HelpContentTroopsTeutons4 from '@/components/pages/help/content/troops/teutons/4';
//import HelpContentTroopsTeutons5 from '@/components/pages/help/content/troops/teutons/5';
//import HelpContentTroopsTeutons6 from '@/components/pages/help/content/troops/teutons/6';
//import HelpContentTroopsTeutons7 from '@/components/pages/help/content/troops/teutons/7';
//import HelpContentTroopsTeutons8 from '@/components/pages/help/content/troops/teutons/8';
//import HelpContentTroopsTeutons9 from '@/components/pages/help/content/troops/teutons/9';
//import HelpContentTroopsTeutons10 from '@/components/pages/help/content/troops/teutons/10';

//import HelpContentTroopsGauls1 from '@/components/pages/help/content/troops/gauls/1';
//import HelpContentTroopsGauls2 from '@/components/pages/help/content/troops/gauls/2';
//import HelpContentTroopsGauls3 from '@/components/pages/help/content/troops/gauls/3';
//import HelpContentTroopsGauls4 from '@/components/pages/help/content/troops/gauls/4';
//import HelpContentTroopsGauls5 from '@/components/pages/help/content/troops/gauls/5';
//import HelpContentTroopsGauls6 from '@/components/pages/help/content/troops/gauls/6';
//import HelpContentTroopsGauls7 from '@/components/pages/help/content/troops/gauls/7';
//import HelpContentTroopsGauls8 from '@/components/pages/help/content/troops/gauls/8';
//import HelpContentTroopsGauls9 from '@/components/pages/help/content/troops/gauls/9';
//import HelpContentTroopsGauls10 from '@/components/pages/help/content/troops/gauls/10';

//import HelpContentVillageRubble1 from '@/components/pages/help/content/village/Rubble1';
//import HelpContentVillageRubble2 from '@/components/pages/help/content/village/Rubble2';

//import HelpContentVillageProtectingYourself1 from '@/components/pages/help/content/village/ProtectingYourself1';
//import HelpContentVillageProtectingYourself2 from '@/components/pages/help/content/village/ProtectingYourself2';
//import HelpContentVillageProtectingYourself3 from '@/components/pages/help/content/village/ProtectingYourself3';

//import HelpContentVillageResearch1 from '@/components/pages/help/content/village/Research1';
//import HelpContentVillageResearch2 from '@/components/pages/help/content/village/Research2';

//import HelpContentVillageExpansionRequirements1 from '@/components/pages/help/content/village/ExpansionRequirements1';
//import HelpContentVillageExpansionRequirements2 from '@/components/pages/help/content/village/ExpansionRequirements2';
//import HelpContentVillageExpansionRequirements3 from '@/components/pages/help/content/village/ExpansionRequirements3';
//import HelpContentVillageExpansionRequirements4 from '@/components/pages/help/content/village/ExpansionRequirements4';

//import HelpContentVillageCities1 from '@/components/pages/help/content/village/Cities1';
//import HelpContentVillageCities2 from '@/components/pages/help/content/village/Cities2';

//import HelpContentVillageRelocation1 from '@/components/pages/help/content/village/Relocation1';
//import HelpContentVillageRelocation2 from '@/components/pages/help/content/village/Relocation2';
//import HelpContentVillageRelocation3 from '@/components/pages/help/content/village/Relocation3';
//import HelpContentVillageRelocation4 from '@/components/pages/help/content/village/Relocation4';

//import HelpContentRobbersRobberCamps1 from '@/components/pages/help/content/robbers/RobberCamps1';
//import HelpContentRobbersRobberCamps2 from '@/components/pages/help/content/robbers/RobberCamps2';

//import HelpContentRobbersRobberHideouts1 from '@/components/pages/help/content/robbers/RobberHideouts1';
//import HelpContentRobbersRobberHideouts2 from '@/components/pages/help/content/robbers/RobberHideouts2';
//import HelpContentVillageRobberHideouts3 from '@/components/pages/help/content/robbers/RobberHideouts3';
//import HelpContentVillageRobberHideouts4 from '@/components/pages/help/content/robbers/RobberHideouts4';

//import HelpContentRobbersStolenGoodsAndTreasures1 from '@/components/pages/help/content/robbers/StolenGoodsAndTreasures1';
//import HelpContentRobbersStolenGoodsAndTreasures2 from '@/components/pages/help/content/robbers/StolenGoodsAndTreasures2';

//import HelpContentKingdomMapAndDetailView1 from '@/components/pages/help/content/kingdom/MapAndDetailView1';
//import HelpContentKingdomMapAndDetailView2 from '@/components/pages/help/content/kingdom/MapAndDetailView2';
//import HelpContentKingdomMapAndDetailView3 from '@/components/pages/help/content/kingdom/MapAndDetailView3';

//import HelpContentKingdomTributes1 from '@/components/pages/help/content/kingdom/Tributes1';
//import HelpContentKingdomTributes2 from '@/components/pages/help/content/kingdom/Tributes2';

//import HelpContentKingdomInfluence1 from '@/components/pages/help/content/kingdom/Influence1';
//import HelpContentKingdomInfluence2 from '@/components/pages/help/content/kingdom/Influence2';

//import HelpContentKingdomInvitations1 from '@/components/pages/help/content/kingdom/Invitations1';
//import HelpContentKingdomInvitations2 from '@/components/pages/help/content/kingdom/Invitations2';
//import HelpContentKingdomInvitations3 from '@/components/pages/help/content/kingdom/Invitations3';

//import HelpContentKingdomDukes1 from '@/components/pages/help/content/kingdom/Dukes1';
//import HelpContentKingdomDukes2 from '@/components/pages/help/content/kingdom/Dukes2';

//import HelpContentKingdomUnions1 from '@/components/pages/help/content/kingdom/Unions1';
//import HelpContentKingdomUnions2 from '@/components/pages/help/content/kingdom/Unions2';
//import HelpContentKingdomUnions3 from '@/components/pages/help/content/kingdom/Unions3';
//import HelpContentKingdomUnions4 from '@/components/pages/help/content/kingdom/Unions4';

//import HelpContentHeroAdventures1 from '@/components/pages/help/content/hero/Adventures1';
//import HelpContentHeroAdventures2 from '@/components/pages/help/content/hero/Adventures2';
//import HelpContentHeroAdventures3 from '@/components/pages/help/content/hero/Adventures3';

//import HelpContentHeroConsumables1 from '@/components/pages/help/content/hero/Consumables1';
//import HelpContentHeroConsumables2 from '@/components/pages/help/content/hero/Consumables2';
//import HelpContentHeroConsumables3 from '@/components/pages/help/content/hero/Consumables3';

//import HelpContentHeroEquipment1 from '@/components/pages/help/content/hero/Equipment1';
//import HelpContentHeroEquipment2 from '@/components/pages/help/content/hero/Equipment2';

//import HelpContentHeroAuctions1 from '@/components/pages/help/content/hero/Auctions1';
//import HelpContentHeroAuctions2 from '@/components/pages/help/content/hero/Auctions2';
//import HelpContentHeroAuctions3 from '@/components/pages/help/content/hero/Auctions3';

//import HelpContentHeroHeroProduction1 from '@/components/pages/help/content/hero/HeroProduction1';
//import HelpContentHeroHeroProduction2 from '@/components/pages/help/content/hero/HeroProduction2';

//import HelpContentHeroAttributes1 from '@/components/pages/help/content/hero/Attributes1';
//import HelpContentHeroAttributes2 from '@/components/pages/help/content/hero/Attributes2';
//import HelpContentHeroAttributes3 from '@/components/pages/help/content/hero/Attributes3';
//import HelpContentHeroAttributes4 from '@/components/pages/help/content/hero/Attributes4';

//import HelpContentHeroCapturingAnimals1 from '@/components/pages/help/content/hero/CapturingAnimals1';
//import HelpContentHeroCapturingAnimals2 from '@/components/pages/help/content/hero/CapturingAnimals2';
//import HelpContentHeroCapturingAnimals3 from '@/components/pages/help/content/hero/CapturingAnimals3';
//import HelpContentHeroCapturingAnimals4 from '@/components/pages/help/content/hero/CapturingAnimals4';

//import HelpContentHeroResurrection1 from '@/components/pages/help/content/hero/Resurrection1';
//import HelpContentHeroResurrection2 from '@/components/pages/help/content/hero/Resurrection2';

//import HelpContentOasesWildOases1 from '@/components/pages/help/content/oases/WildOases1';
//import HelpContentOasesWildOases2 from '@/components/pages/help/content/oases/WildOases2';

//import HelpContentOasesAssignOasis1 from '@/components/pages/help/content/oases/AssignOasis1';
//import HelpContentOasesAssignOasis2 from '@/components/pages/help/content/oases/AssignOasis2';
//import HelpContentOasesAssignOasis3 from '@/components/pages/help/content/oases/AssignOasis3';
//import HelpContentOasesAssignOasis4 from '@/components/pages/help/content/oases/AssignOasis4';

//import HelpContentOasesTroopsInOases1 from '@/components/pages/help/content/oases/TroopsInOases1';
//import HelpContentOasesTroopsInOases2 from '@/components/pages/help/content/oases/TroopsInOases2';

//import HelpContentTradingTradingResources1 from '@/components/pages/help/content/trading/TradingResources1';
//import HelpContentTradingTradingResources2 from '@/components/pages/help/content/trading/TradingResources2';
//import HelpContentTradingTradingResources3 from '@/components/pages/help/content/trading/TradingResources3';

//import HelpContentTradingOfferingResources1 from '@/components/pages/help/content/trading/OfferingResources1';
//import HelpContentTradingOfferingResources2 from '@/components/pages/help/content/trading/OfferingResources2';
//import HelpContentTradingOfferingResources3 from '@/components/pages/help/content/trading/OfferingResources3';

//import HelpContentTradingSendingResources1 from '@/components/pages/help/content/trading/SendingResources1';
//import HelpContentTradingSendingResources2 from '@/components/pages/help/content/trading/SendingResources2';

//import HelpContentTradingTradeRoutes1 from '@/components/pages/help/content/trading/TradeRoutes1';
//import HelpContentTradingTradeRoutes2 from '@/components/pages/help/content/trading/TradeRoutes2';

//import HelpContentProgressBeginnerProtection1 from '@/components/pages/help/content/progress/BeginnerProtection1';

//import HelpContentProgressAchievements1 from '@/components/pages/help/content/progress/Achievements1';
//import HelpContentProgressAchievements2 from '@/components/pages/help/content/progress/Achievements2';

//import HelpContentProgressDailyQuests1 from '@/components/pages/help/content/progress/DailyQuests1';
//import HelpContentProgressDailyQuests2 from '@/components/pages/help/content/progress/DailyQuests2';
//import HelpContentProgressDailyQuests3 from '@/components/pages/help/content/progress/DailyQuests3';
//
//import HelpContentProgressPrestige1 from '@/components/pages/help/content/progress/Prestige1';
//import HelpContentProgressPrestige2 from '@/components/pages/help/content/progress/Prestige2';
//import HelpContentProgressPrestige3 from '@/components/pages/help/content/progress/Prestige3';

//import HelpContentMilitaryAndDiplomacyAttackTypes1 from '@/components/pages/help/content/militaryAndDiplomacy/AttackTypes1';
//import HelpContentMilitaryAndDiplomacyAttackTypes2 from '@/components/pages/help/content/militaryAndDiplomacy/AttackTypes2';
//import HelpContentMilitaryAndDiplomacyAttackTypes3 from '@/components/pages/help/content/militaryAndDiplomacy/AttackTypes3';
//import HelpContentMilitaryAndDiplomacyAttackTypes4 from '@/components/pages/help/content/militaryAndDiplomacy/AttackTypes4';

//import HelpContentMilitaryAndDiplomacySupportOtherPlayers1 from '@/components/pages/help/content/militaryAndDiplomacy/SupportOtherPlayers1';
//import HelpContentMilitaryAndDiplomacySupportOtherPlayers2 from '@/components/pages/help/content/militaryAndDiplomacy/SupportOtherPlayers2';
//import HelpContentMilitaryAndDiplomacySupportOtherPlayers3 from '@/components/pages/help/content/militaryAndDiplomacy/SupportOtherPlayers3';
//import HelpContentMilitaryAndDiplomacySupportOtherPlayers4 from '@/components/pages/help/content/militaryAndDiplomacy/SupportOtherPlayers4';

//import HelpContentMilitaryAndDiplomacyStrategicMap1 from '@/components/pages/help/content/militaryAndDiplomacy/StrategicMap1';
//import HelpContentMilitaryAndDiplomacyStrategicMap2 from '@/components/pages/help/content/militaryAndDiplomacy/StrategicMap2';

//import HelpContentMilitaryAndDiplomacySecretSocieties1 from '@/components/pages/help/content/militaryAndDiplomacy/SecretSocieties1';
//import HelpContentMilitaryAndDiplomacySecretSocieties2 from '@/components/pages/help/content/militaryAndDiplomacy/SecretSocieties2';

//import HelpContentMilitaryAndDiplomacyVictoryPoints1 from '@/components/pages/help/content/militaryAndDiplomacy/VictoryPoints1';
//import HelpContentMilitaryAndDiplomacyVictoryPoints2 from '@/components/pages/help/content/militaryAndDiplomacy/VictoryPoints2';
//import HelpContentMilitaryAndDiplomacyVictoryPoints3 from '@/components/pages/help/content/militaryAndDiplomacy/VictoryPoints3';

//import HelpContentMilitaryAndDiplomacyWonderOfTheWorld1 from '@/components/pages/help/content/militaryAndDiplomacy/WonderOfTheWorld1';
//import HelpContentMilitaryAndDiplomacyWonderOfTheWorld2 from '@/components/pages/help/content/militaryAndDiplomacy/WonderOfTheWorld2';

//import HelpContentMilitaryAndDiplomacyFarmLists1 from '@/components/pages/help/content/militaryAndDiplomacy/FarmLists1';
//import HelpContentMilitaryAndDiplomacyFarmLists2 from '@/components/pages/help/content/militaryAndDiplomacy/FarmLists2';

//import HelpContentMilitaryAndDiplomacyConquering1 from '@/components/pages/help/content/militaryAndDiplomacy/Conquering1';
//import HelpContentMilitaryAndDiplomacyConquering2 from '@/components/pages/help/content/militaryAndDiplomacy/Conquering2';

//import HelpContentPremiumCardGame1 from '@/components/pages/help/content/premium/CardGame1';
//import HelpContentPremiumCardGame2 from '@/components/pages/help/content/premium/CardGame2';
//import HelpContentPremiumCardGame3 from '@/components/pages/help/content/premium/CardGame3';

//import HelpContentPremiumRuntimeFeatures1 from '@/components/pages/help/content/premium/RuntimeFeatures1';
//import HelpContentPremiumRuntimeFeatures2 from '@/components/pages/help/content/premium/RuntimeFeatures2';

//import HelpContentPremiumInstantFeatures1 from '@/components/pages/help/content/premium/InstantFeatures1';
//import HelpContentPremiumInstantFeatures2 from '@/components/pages/help/content/premium/InstantFeatures2';
//import HelpContentPremiumInstantFeatures3 from '@/components/pages/help/content/premium/InstantFeatures3';
//import HelpContentPremiumInstantFeatures4 from '@/components/pages/help/content/premium/InstantFeatures4';

//import HelpContentPremiumInviteFriend1 from '@/components/pages/help/content/premium/InviteFriend1';
//import HelpContentPremiumInviteFriend2 from '@/components/pages/help/content/premium/InviteFriend2';
//import HelpContentPremiumInviteFriend3 from '@/components/pages/help/content/premium/InviteFriend3';

//import HelpContentPremiumMasterBuilder1 from '@/components/pages/help/content/premium/MasterBuilder1';
//import HelpContentPremiumMasterBuilder2 from '@/components/pages/help/content/premium/MasterBuilder2';

//import HelpContentAccountSystemAccountsAndAvatars1 from '@/components/pages/help/content/accountSystem/AccountsAndAvatars1';
//import HelpContentAccountSystemAccountsAndAvatars2 from '@/components/pages/help/content/accountSystem/AccountsAndAvatars2';
//import HelpContentAccountSystemAccountsAndAvatars3 from '@/components/pages/help/content/accountSystem/AccountsAndAvatars3';

//import HelpContentAccountSystemDualsAndSitters1 from '@/components/pages/help/content/accountSystem/DualsAndSitters1';
//import HelpContentAccountSystemDualsAndSitters2 from '@/components/pages/help/content/accountSystem/DualsAndSitters2';
//import HelpContentAccountSystemDualsAndSitters3 from '@/components/pages/help/content/accountSystem/DualsAndSitters3';

//import HelpContentAccountSystemGoldTransfer1 from '@/components/pages/help/content/accountSystem/GoldTransfer1';
//import HelpContentAccountSystemGoldTransfer2 from '@/components/pages/help/content/accountSystem/GoldTransfer2';
//import HelpContentAccountSystemGoldTransfer3 from '@/components/pages/help/content/accountSystem/GoldTransfer3';

//import HelpContentAccountSystemVacationMode1 from '@/components/pages/help/content/accountSystem/VacationMode1';
//import HelpContentAccountSystemVacationMode2 from '@/components/pages/help/content/accountSystem/VacationMode2';
//import HelpContentAccountSystemVacationMode3 from '@/components/pages/help/content/accountSystem/VacationMode3';

import HelpContentBuilding from '@/components/pages/help/ContentBuilding';
import HelpContentUnit from '@/components/pages/help/ContentUnit';
import HelpContentOther from '@/components/pages/help/ContentOther';

export default class HelpModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.selectedSection = '';
		this.selectedGroup = '';
		this.selectedElement = '';
		this.selectedPage = 1;
		
		this.randSection = '';
		this.randGroup = '';
		this.randElement = '';
		
		this.sections = [
			{
				apiname: 'buildings',
				type: 'building',
				name: 'Здания',
				groups: [
					{
						apiname: 'infrastructure',
						name: 'Инфраструктура',
						elements: [
							{
								apiname: '15',
								name: 'Главное здание',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure15,
								},
								resources: {
									wood: 70,
									clay: 40,
									iron: 60,
									crop: 20,
									
								},
								duration: '00:00:30',
								requirements: [],
								description: '<span>Чем выше уровень главного здания, тем меньше времени будут строиться другие здания.</span>',
							},
							{
								apiname: '10',
								name: 'Склад',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure10,
								},
								resources: {
									wood: 140,
									clay: 180,
									iron: 100,
									crop: 0,
									
								},
								duration: '00:00:35',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 1,
									},
								],
								description: '<span>На складе хранятся древесина, глина и железо. Повышая уровень склада, ты увеличиваешь его емкость и можешь хранить больше ресурсов.</span>',
							},
							{
								apiname: '11',
								name: 'Амбар',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure11,
								},
								resources: {
									wood: 80,
									clay: 100,
									iron: 70,
									crop: 20,
									
								},
								duration: '00:00:35',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 1,
									},
								],
								description: '<span>В амбаре хранится зерно.  Повышая уровень амбара, ты увеличиваешь его емкость и можешь хранить больше зерна.</span>',
							},
							{
								apiname: '23',
								name: 'Тайник',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure23,
								},
								resources: {
									wood: 40,
									clay: 50,
									iron: 30,
									crop: 10,
									
								},
								duration: '00:00:10',
								requirements: [],
								description: '<span>При набеге на твою деревню ее жители прячут в тайник часть сырья из хранилищ. Это сырье не может быть украдено нападающими.</span>',
							},
							{
								apiname: '17',
								name: 'Рынок',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure17,
								},
								resources: {
									wood: 80,
									clay: 70,
									iron: 120,
									crop: 70,
									
								},
								duration: '00:00:35',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 1,
									},
									{
										type: 2,
										op: 1,
										buildingType: 10,
										name: 'Склад',
										level: 1,
									},
								],
								description: '<span>На рынке можно обмениваться сырьем с другими игроками. Чем выше уровень рынка, тем больше у тебя торговцев, и тем больше ресурсов ты можешь отправить одновременно.</span>',
							},
							{
								apiname: '18',
								name: 'Посольство',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure18,
								},
								resources: {
									wood: 180,
									clay: 130,
									iron: 150,
									crop: 80,
									
								},
								duration: '00:00:35',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 1,
									},
								],
								description: '<span>Посольство — место, где собираются дипломаты. Здесь ты можешь занять оазис, а также основать тайное сообщество или свое собственное королевство.</span>',
							},
							{
								apiname: '25',
								name: 'Резиденция',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure25,
								},
								resources: {
									wood: 580,
									clay: 460,
									iron: 350,
									crop: 180,
									
								},
								duration: '00:22:30',
								requirements: [
									{
										type: 2,
										op: 0,
										buildingType: 26,
										name: 'Дворец',
									},
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 5,
									},
								],
								description: '<span>Резиденция — это небольшой особняк, где останавливается король во время своих визитов. Помимо выполнения других функций, резиденция защищает деревню от захвата врагами. . Резиденция готовит поселенцев для основания новых деревень и предводителей для захвата деревень.</span>',
							},
							{
								apiname: '26',
								name: 'Дворец',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure26,
								},
								resources: {
									wood: 550,
									clay: 800,
									iron: 750,
									crop: 250,
									
								},
								duration: '00:01:00',
								requirements: [
									{
										type: 2,
										op: 0,
										buildingType: 25,
										name: 'Резиденция',
									},
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 5,
									},
									{
										type: 2,
										op: 1,
										buildingType: 18,
										name: 'Посольство',
										level: 1,
									},
									{
										type: 1,
										op: 1,
										name: 'Деревня с Чудом света',
										villageType: 4,
									},
								],
								description: '<span>Правитель империи живет во дворце. У тебя может быть только один дворец во всех твоих деревнях. Дворец необходим, чтобы объявить деревню столицей.</span>',
							},
							{
								apiname: '24',
								name: 'Ратуша',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure24,
								},
								resources: {
									wood: 1250,
									clay: 1110,
									iron: 1260,
									crop: 600,
									
								},
								duration: '00:11:00',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 10,
									},
									{
										type: 2,
										op: 1,
										buildingType: 22,
										name: 'Академия',
										level: 10,
									},
								],
								description: '<span>В ратуше ты можешь организовать для своих жителей великолепные праздники. Этим ты увеличишь количество единиц культуры.</span>',
							},
							{
								apiname: '27',
								name: 'Сокровищница',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure27,
								},
								resources: {
									wood: 720,
									clay: 685,
									iron: 645,
									crop: 250,
									
								},
								duration: '00:34:00',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 27,
										name: 'Сокровищница',
										level: 20,
									},
								],
								description: '<span>Сокровища королей, вице-королей и герцогов хранятся в сокровищницах. Победные очки, ресурсы и влияние даются только за те сокровища, которые хранятся в сокровищнице. Как только игрок повысит первую сокровищницу до 20 уровня, он сможет строить дополнительные сокровищницы в той же деревне.</span>',
							},
							{
								apiname: '45',
								name: 'Скрытая сокровищница',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure45,
								},
								resources: {
									wood: 720,
									clay: 685,
									iron: 645,
									crop: 250,
									
								},
								duration: '00:34:00',
								requirements: [
									{
										type: 2,
										op: 0,
										buildingType: 27,
										name: 'Сокровищница',
									},
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 3,
									},
									{
										type: 1,
										op: 1,
										name: 'Деревня с Чудом света',
										villageType: 4,
									},
								],
								description: '<span>В случае атаки здесь будет укрыта дань, которую затем можно сохранить за собой. Ты можешь перестроить это здание в сокровищницу, если ты король, вице-король или герцог.</span>',
							},
							{
								apiname: '34',
								name: 'Каменотес',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure34,
								},
								resources: {
									wood: 155,
									clay: 130,
									iron: 125,
									crop: 70,
									
								},
								duration: '00:00:35',
								requirements: [
									{
										type: 1,
										op: 1,
										name: 'Столица',
										villageType: 1,
									},
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 5,
									},
								],
								description: '<span>Каменотес является экспертом в обработке камней. Чем выше уровень каменотеса, тем прочнее все здания в деревне.</span>',
							},
							{
								apiname: '28',
								name: 'Торговая палата',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure28,
								},
								resources: {
									wood: 1400,
									clay: 1330,
									iron: 1200,
									crop: 400,
									
								},
								duration: '00:06:05',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 17,
										name: 'Рынок',
										level: 20,
									},
									{
										type: 2,
										op: 1,
										buildingType: 20,
										name: 'Конюшня',
										level: 10,
									},
								],
								description: '<span>Торговая палата улучшает повозки твоих торговцев. Чем выше уровень торговой палаты, тем больше ресурсов способны перевозить торговцы.</span>',
							},
							{
								apiname: '40',
								name: 'Чудо света',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure40,
								},
								resources: {
									wood: 66700,
									clay: 69050,
									iron: 72200,
									crop: 13200,
									
								},
								duration: '01:00:00',
								requirements: [
									{
										type: 1,
										op: 1,
										name: 'Деревня с Чудом света',
										villageType: 4,
									},
								],
								description: '<span>Чудо света является венцом развития цивилизации. Только самые сильные и богатые империи могут построить это произведение искусства и успешно защищать его от нападений врагов.</span>',
							},
							{
								apiname: '38',
								name: 'Большой склад',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure38,
								},
								resources: {
									wood: 650,
									clay: 800,
									iron: 450,
									crop: 200,
									
								},
								duration: '00:05:25',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 10,
									},
									{
										type: 1,
										op: 1,
										name: 'Деревня с Чудом света',
										villageType: 4,
									},
								],
								description: '<span>Большой склад позволяет хранить в три раза больше древесины, глины и железа по сравнению с обычным хранилищем.</span>',
							},
							{
								apiname: '39',
								name: 'Большой амбар',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsInfrastructure39,
								},
								resources: {
									wood: 400,
									clay: 500,
									iron: 350,
									crop: 100,
									
								},
								duration: '00:05:20',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 10,
									},
									{
										type: 1,
										op: 1,
										name: 'Деревня с Чудом света',
										villageType: 4,
									},
								],
								description: '<span>Большой амбар позволяет хранить в три раза больше зерна по сравнению с обычным.</span>',
							},
						],
					},
					{
						apiname: 'military',
						name: 'Войска',
						elements: [
							{
								apiname: '16',
								name: 'Пункт сбора',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary16,
								},
								resources: {
									wood: 110,
									clay: 160,
									iron: 90,
									crop: 70,
									
								},
								duration: '00:00:35',
								requirements: [],
								description: '<span>Войска деревни собираются в пункте сбора. Отсюда ты можешь отправить их в подкрепление, в набег или на захват других деревень.</span>',
							},
							{
								apiname: '19',
								name: 'Казарма',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary19,
								},
								resources: {
									wood: 210,
									clay: 140,
									iron: 260,
									crop: 120,
									
								},
								duration: '00:00:35',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 3,
									},
									{
										type: 2,
										op: 1,
										buildingType: 16,
										name: 'Пункт сбора',
										level: 1,
									},
								],
								description: '<span>В казарме можно обучать пехотные войска. С развитием казармы уменьшается время обучения солдат.</span>',
							},
							{
								apiname: '20',
								name: 'Конюшня',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary20,
								},
								resources: {
									wood: 260,
									clay: 140,
									iron: 220,
									crop: 100,
									
								},
								duration: '00:00:40',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 13,
										name: 'Кузница',
										level: 3,
									},
									{
										type: 2,
										op: 1,
										buildingType: 22,
										name: 'Академия',
										level: 5,
									},
								],
								description: '<span>В конюшне обучаются все войска кавалерии. С развитием конюшни уменьшается время обучения солдат.</span>',
							},
							{
								apiname: '21',
								name: 'Мастерская',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary21,
								},
								resources: {
									wood: 460,
									clay: 510,
									iron: 600,
									crop: 320,
									
								},
								duration: '00:11:00',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 5,
									},
									{
										type: 2,
										op: 1,
										buildingType: 22,
										name: 'Академия',
										level: 10,
									},
								],
								description: '<span>Осадные орудия, такие как тараны и катапульты, производятся в мастерской. Чем выше уровень мастерской, тем быстрее изготавливаются орудия.',
							},
							{
								apiname: '22',
								name: 'Академия',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary22,
								},
								resources: {
									wood: 220,
									clay: 160,
									iron: 90,
									crop: 40,
									
								},
								duration: '00:00:35',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 3,
									},
									{
										type: 2,
										op: 1,
										buildingType: 19,
										name: 'Казарма',
										level: 3,
									},
								],
								description: '<span>Новые типы войск исследуются в Академии. С развитием Академии тебе становятся доступными для исследования более продвинутые и мощные войска.</span>',
							},
							{
								apiname: '13',
								name: 'Кузница',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary13,
								},
								resources: {
									wood: 180,
									clay: 250,
									iron: 500,
									crop: 160,
									
								},
								duration: '00:00:40',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 3,
									},
									{
										type: 2,
										op: 1,
										buildingType: 22,
										name: 'Академия',
										level: 1,
									},
								],
								description: '<span>В кузнице можно улучшить характеристики доспехов и оружия воинов. Чем выше уровень кузницы, тем лучше качество вооружения твоих войск.</span>',
							},
							{
								apiname: '46',
								name: 'Целебный шатер',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary46,
								},
								resources: {
									wood: 900,
									clay: 800,
									iron: 750,
									crop: 650,
									
								},
								duration: '00:11:00',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 10,
									},
									{
										type: 2,
										op: 1,
										buildingType: 22,
										name: 'Академия',
										level: 15,
									},
								],
								description: '<span>Раненых солдат (пехота и кавалерия) можно исцелить в Целебном шатре. Чем выше уровень Целебного шатра, тем больше раненых он вмещает и тем быстрее проходит лечение.</span>',
							},
							{
								apiname: '36',
								name: 'Капканщик',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary36,
								},
								resources: {
									wood: 80,
									clay: 120,
									iron: 70,
									crop: 90,
									
								},
								duration: '00:00:35',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 16,
										name: 'Пункт сбора',
										level: 1,
									},
								],
								description: '<span>Капканщик ставит хорошо спрятанные ловушки вокруг твоей деревни. Неосторожные нападающие попадают в них и больше не представляют угрозы для деревни.</span>',
							},
							{
								apiname: '31',
								name: 'Стена',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary31,
								},
								resources: {
									wood: 70,
									clay: 90,
									iron: 170,
									crop: 70,
									
								},
								duration: '00:00:35',
								requirements: [],
								description: '<span>Стена защищает твое поселение от варварских орд противников. Чем выше стена, тем выше базовая защита деревни и защитный бонус войск, находящихся в ней.</span>',
							},
							{
								apiname: '32',
								name: 'Земляной вал',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary32,
								},
								resources: {
									wood: 120,
									clay: 200,
									iron: 0,
									crop: 80,
									
								},
								duration: '00:00:35',
								requirements: [],
								description: '<span>Земляной вал защищает твое поселение от варварских орд противников. Чем выше стена, тем выше базовая защита деревни и защитный бонус войск, находящихся в ней.</span>',
							},
							{
								apiname: '33',
								name: 'Изгородь',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary33,
								},
								resources: {
									wood: 160,
									clay: 100,
									iron: 80,
									crop: 60,
									
								},
								duration: '00:00:35',
								requirements: [],
								description: '<span>Изгородь защищает твое поселение от варварских орд противников. Чем выше стена, тем выше базовая защита деревни и защитный бонус войск, находящихся в ней.</span>',
							},
							{
								apiname: '43',
								name: 'Натарская стена',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary43,
								},
								resources: {
									wood: 120,
									clay: 200,
									iron: 0,
									crop: 80,
									
								},
								duration: '00:00:35',
								requirements: [],
								description: '<span>Натарская стена может быть построена только в деревнях Натар, оберегая их от атак. Чем выше стена, тем выше базовая защита деревни и защитный бонус войск, находящихся в ней.</span>',
							},
							{
								apiname: '42',
								name: 'Ров',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary42,
								},
								resources: {
									wood: 740,
									clay: 850,
									iron: 960,
									crop: 620,
									
								},
								duration: '00:05:55',
								requirements: [
									{
										type: 1,
										op: 0,
										name: 'Город',
										villageType: 2,
									},
								],
								description: '<span>Строительство рва доступно только в городах. Ров дает бонус защиты в дополнение к стене. Тараны и катапульты не наносят ущерб рву.</span>',
							},
							{
								apiname: '14',
								name: 'Арена',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary14,
								},
								resources: {
									wood: 1750,
									clay: 2250,
									iron: 1530,
									crop: 240,
									
								},
								duration: '00:06:20',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 16,
										name: 'Пункт сбора',
										level: 15,
									},
								],
								description: '<span>На арене воины тренируют выносливость. С развитием арены увеличивается скорость передвижения твоих войск на расстояния больше 20 полей.</span>',
							},
							{
								apiname: '41',
								name: 'Водопой',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary41,
								},
								resources: {
									wood: 85,
									clay: 65,
									iron: 30,
									crop: 80,
									
								},
								duration: '00:00:35',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 16,
										name: 'Пункт сбора',
										level: 10,
									},
									{
										type: 2,
										op: 1,
										buildingType: 20,
										name: 'Конюшня',
										level: 20,
									},
								],
								description: '<span>Водопой помогает заботиться о лошадях, уменьшая потребление зерна кавалерией, сокращая время обучения и лечения.</span>',
							},
							{
								apiname: '35',
								name: 'Пивоварня',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary35,
								},
								resources: {
									wood: 1460,
									clay: 930,
									iron: 1250,
									crop: 1740,
									
								},
								duration: '00:11:30',
								requirements: [
									{
										type: 1,
										op: 0,
										name: 'Столица',
										villageType: 1,
									},
									{
										type: 2,
										op: 1,
										buildingType: 11,
										name: 'Амбар',
										level: 20,
									},
									{
										type: 2,
										op: 1,
										buildingType: 16,
										name: 'Пункт сбора',
										level: 10,
									},
								],
								description: '<span>Изысканные напитки готовятся в пивоварне. Войска атакуют лучше, но катапульты стреляют только по случайным зданиям, а вожди становятся менее убедительными.</span>',
							},
							{
								apiname: '29',
								name: 'Большая казарма',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary29,
								},
								resources: {
									wood: 630,
									clay: 420,
									iron: 780,
									crop: 360,
									
								},
								duration: '00:11:00',
								requirements: [
									{
										type: 1,
										op: 0,
										name: 'Город',
										villageType: 2,
									},
									{
										type: 2,
										op: 1,
										buildingType: 19,
										name: 'Казарма',
										level: 20,
									},
								],
								description: '<span>Большая казарма позволяет обучать дополнительные войска пехоты. Стоимость обучения войск здесь в три раза выше, чем в обычной казарме.</span>',
							},
							{
								apiname: '30',
								name: 'Большая конюшня',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsMilitary30,
								},
								resources: {
									wood: 780,
									clay: 420,
									iron: 660,
									crop: 300,
									
								},
								duration: '00:11:00',
								requirements: [
									{
										type: 1,
										op: 0,
										name: 'Город',
										villageType: 2,
									},
									{
										type: 2,
										op: 1,
										buildingType: 20,
										name: 'Конюшня',
										level: 20,
									},
								],
								description: '<span>Большая конюшня позволяет обучать дополнительные войска кавалерии. Стоимость обучения войск здесь в три раза выше, чем в обычной конюшне.</span>',
							},
						],
					},
					{
						apiname: 'resources',
						name: 'Ресурсы',
						elements: [
							{
								apiname: '1',
								name: 'Лесопилка',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsResources1,
								},
								resources: {
									wood: 40,
									clay: 100,
									iron: 50,
									crop: 60,
									
								},
								duration: '00:00:25',
								requirements: [],
								description: '<span>На лесопилке рубят деревья, чтобы произвести древесину. Чем выше уровень лесопилки, тем больше древесины на ней производится.</span>',
							},
							{
								apiname: '2',
								name: 'Глиняный карьер',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsResources2,
								},
								resources: {
									wood: 80,
									clay: 40,
									iron: 80,
									crop: 50,
									
								},
								duration: '00:00:20',
								requirements: [],
								description: '<span>Глину добывают в глиняных карьерах. Развивая глиняный карьер, ты увеличиваешь производство глины. Построй кирпичный завод, чтобы еще больше повысить производство.</span>',
							},
							{
								apiname: '3',
								name: 'Железный рудник',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsResources3,
								},
								resources: {
									wood: 100,
									clay: 80,
									iron: 30,
									crop: 60,
									
								},
								duration: '00:00:30',
								requirements: [],
								description: '<span>Шахтеры добывают ценное сырье на железных рудниках. Повысив уровень железного рудника, ты увеличишь производство железа.</span>',
							},
							{
								apiname: '4',
								name: 'Ферма',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsResources4,
								},
								resources: {
									wood: 75,
									clay: 90,
									iron: 85,
									crop: 0,
									
								},
								duration: '00:00:20',
								requirements: [],
								description: '<span>На фермах выращивают зерно для обеспечения продовольствием населения и войска. Повысив уровни ферм, ты увеличишь производство зерна.</span>',
							},
							{
								apiname: '5',
								name: 'Пилорама',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsResources5,
								},
								resources: {
									wood: 520,
									clay: 380,
									iron: 290,
									crop: 90,
									
								},
								duration: '00:08:00',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 1,
										name: 'Лесопилка',
										level: 10,
									},
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 5,
									},
								],
								description: '<span>На пилораме происходит дальнейшая обработка древесины. На максимальном уровне пилорама может увеличить производство древесины до 25%.</span>',
							},
							{
								apiname: '6',
								name: 'Кирпичный завод',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsResources6,
								},
								resources: {
									wood: 440,
									clay: 480,
									iron: 320,
									crop: 50,
									
								},
								duration: '00:08:00',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 2,
										name: 'Глиняный карьер',
										level: 10,
									},
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 5,
									},
								],
								description: '<span>Кирпичный завод производит из глины кирпичи. В зависимости от уровня завода производство глины можно повысить вплоть до 25%.</span>',
							},
							{
								apiname: '7',
								name: 'Литейный завод',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsResources7,
								},
								resources: {
									wood: 200,
									clay: 450,
									iron: 510,
									crop: 120,
									
								},
								duration: '00:08:00',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 3,
										name: 'Железный рудник',
										level: 10,
									},
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 5,
									},
								],
								description: '<span>Литейный завод переплавляет железо. Он дает прибавку к добыче железа до 25%, в зависимости от его уровня.</span>',
							},
							{
								apiname: '8',
								name: 'Мельница',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsResources8,
								},
								resources: {
									wood: 500,
									clay: 440,
									iron: 380,
									crop: 1240,
									
								},
								duration: '00:08:00',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 4,
										name: 'Ферма',
										level: 5,
									},
								],
								description: '<span>На мельнице зерно мелют в муку. В зависимости от уровня мельница дает прирост производства зерна до 25%.</span>',
							},
							{
								apiname: '9',
								name: 'Пекарня',
								content: {
									HelpContentBuilding,
									//HelpContentBuildingsResources9,
								},
								resources: {
									wood: 1200,
									clay: 1480,
									iron: 870,
									crop: 1600,
									
								},
								duration: '00:13:00',
								requirements: [
									{
										type: 2,
										op: 1,
										buildingType: 4,
										name: 'Ферма',
										level: 10,
									},
									{
										type: 2,
										op: 1,
										buildingType: 15,
										name: 'Главное здание',
										level: 5,
									},
									{
										type: 2,
										op: 1,
										buildingType: 8,
										name: 'Мельница',
										level: 5,
									},
								],
								description: '<span>В пекарне из муки пекут хлеб. Максимальный уровень пекарни и мельницы увеличат производство зерна на 50 процентов.</span>',
							},
						],
					},
				],
			},
			{
				type: 'unit',
				apiname: 'troops',
				name: 'Войска',
				groups: [
					{
						apiname: 'roman',
						name: 'Римляне',
						elements: [
							{
								apiname: '1',
								name: 'Легионер',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans1,
								},
								attributes: {
									attack: 40,
									defenseInfantry: 35,
									defenseCavalry: 50,
									carry: 50,
									speed: 6,
									consumption: 1,
								},
								trainingResources: {
									wood: 75,
									clay: 50,
									iron: 100,
								},
								trainingDuration: '00:26:40',
								researchResources: {
									wood: 0,
									clay: 0,
									iron: 0,
								},
								researchDuration: '00:00:00',
								requirements: [
									{
										buildingType: 19,
										name: 'Казарма',
										level: 1,
									},
								],
								nr: 1,
								description: '<span>Легионеры — простая и универсальная пехота римлян. Боевые единицы этого вида войск многосторонне развиты: они хорошо проявляют себя как в атаке, так и в защите.</span>',
							},
							{
								apiname: '2',
								name: 'Преторианец',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans2,
								},
								attributes: {
									attack: 30,
									defenseInfantry: 65,
									defenseCavalry: 35,
									carry: 20,
									speed: 5,
									consumption: 1,
								},
								trainingResources: {
									wood: 80,
									clay: 100,
									iron: 160,
								},
								trainingDuration: '00:29:20',
								researchResources: {
									wood: 580,
									clay: 500,
									iron: 1480,
								},
								researchDuration: '01:58:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 1,
									},
									{
										buildingType: 13,
										name: 'Кузница',
										level: 1,
									},
								],
								nr: 2,
								description: '<span>Преторианцы изначально выполняли функцию телохранителей при Римском сенате. Их длительное обучение приемам защиты заложило основы легендарной репутации.</span>',
							},
							{
								apiname: '3',
								name: 'Империанец',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans3,
								},
								attributes: {
									attack: 70,
									defenseInfantry: 40,
									defenseCavalry: 25,
									carry: 50,
									speed: 7,
									consumption: 1,
								},
								trainingResources: {
									wood: 100,
									clay: 110,
									iron: 140,
								},
								trainingDuration: '00:32:00',
								researchResources: {
									wood: 700,
									clay: 540,
									iron: 1320,
								},
								researchDuration: '02:06:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 5,
									},
									{
										buildingType: 13,
										name: 'Кузница',
										level: 1,
									},
								],
								nr: 3,
								description: '<span>Империанцы — атакующая пехота Рима. Они быстры и сильны в атаке, чем наводят ужас на защитные отряды противника. Однако обучение империанцев довольно длительно и обходится дорого.</span>',
							},
							{
								apiname: '4',
								name: 'Конный разведчик',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans4,
								},
								attributes: {
									attack: 0,
									defenseInfantry: 20,
									defenseCavalry: 10,
									carry: 0,
									speed: 16,
									consumption: 2,
								},
								trainingResources: {
									wood: 100,
									clay: 140,
									iron: 10,
								},
								trainingDuration: '00:22:40',
								researchResources: {
									wood: 700,
									clay: 660,
									iron: 280,
								},
								researchDuration: '01:38:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 5,
									},
									{
										buildingType: 20,
										name: 'Конюшня',
										level: 1,
									},
								],
								nr: 4,
								description: '<span>Конные разведчики добывают информацию о войсках противника. Они невероятно быстры. Хитростью выведывают они секреты противника о войсках, ресурсах и защитных сооружениях.</span>',
							},
							{
								apiname: '5',
								name: 'Конница императора',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans5,
								},
								attributes: {
									attack: 120,
									defenseInfantry: 65,
									defenseCavalry: 50,
									carry: 100,
									speed: 14,
									consumption: 3,
								},
								trainingResources: {
									wood: 350,
									clay: 260,
									iron: 180,
								},
								trainingDuration: '00:44:00',
								researchResources: {
									wood: 2200,
									clay: 1140,
									iron: 1640,
								},
								researchDuration: '02:42:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 5,
									},
									{
										buildingType: 20,
										name: 'Конюшня',
										level: 5,
									},
								],
								nr: 5,
								description: '<span>Конница императора — это регулярная хорошо вооруженная кавалерия Римской армии. Хотя они не самые быстрые воины, но они способны вселить ужас в сердца неподготовленных врагов.</span>',
							},
							{
								apiname: '6',
								name: 'Конница Цезаря',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans6,
								},
								attributes: {
									attack: 180,
									defenseInfantry: 80,
									defenseCavalry: 105,
									carry: 70,
									speed: 10,
									consumption: 4,
								},
								trainingResources: {
									wood: 280,
									clay: 340,
									iron: 600,
								},
								trainingDuration: '00:58:40',
								researchResources: {
									wood: 1780,
									clay: 1460,
									iron: 5000,
								},
								researchDuration: '03:26:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 5,
									},
									{
										buildingType: 20,
										name: 'Конюшня',
										level: 10,
									},
								],
								nr: 6,
								description: '<span>Конница Цезаря — тяжелая кавалерия Римлян. Хорошо вооруженные, в сверкающих доспехах, они несколько более медлительны по сравнению с конницей Императора и могут перевозить малое количество ресурсов. Содержание их также обходится дорого.</span>',
							},
							{
								apiname: '7',
								name: 'Таран',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans7,
								},
								attributes: {
									attack: 60,
									defenseInfantry: 30,
									defenseCavalry: 75,
									carry: 0,
									speed: 4,
									consumption: 3,
								},
								trainingResources: {
									wood: 700,
									clay: 180,
									iron: 400,
								},
								trainingDuration: '01:16:40',
								researchResources: {
									wood: 4300,
									clay: 820,
									iron: 3400,
								},
								researchDuration: '04:20:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 10,
									},
									{
										buildingType: 21,
										name: 'Мастерская',
										level: 1,
									},
								],
								nr: 7,
								description: '<span>Таран — это тяжелое осадное орудие в помощь пехоте и кавалерии. Их задача — пробить брешь в стенах города.</span>',
							},
							{
								apiname: '8',
								name: 'Огненная катапульта',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans8,
								},
								attributes: {
									attack: 75,
									defenseInfantry: 60,
									defenseCavalry: 10,
									carry: 0,
									speed: 3,
									consumption: 6,
								},
								trainingResources: {
									wood: 690,
									clay: 1000,
									iron: 400,
								},
								trainingDuration: '02:30:00',
								researchResources: {
									wood: 4240,
									clay: 4100,
									iron: 3400,
								},
								researchDuration: '08:00:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 15,
									},
									{
										buildingType: 21,
										name: 'Мастерская',
										level: 10,
									},
								],
								nr: 8,
								description: '<span>Огненная катапульта — замечательное орудие дальнего боя, которое уничтожает постройки и ресурсные поля. Сама по себе она, впрочем, довольно беззащитна.</span>',
							},
							{
								apiname: '9',
								name: 'Сенатор',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans9,
								},
								attributes: {
									attack: 50,
									defenseInfantry: 40,
									defenseCavalry: 30,
									carry: 0,
									speed: 4,
									consumption: 5,
								},
								trainingResources: {
									wood: 30750,
									clay: 27200,
									iron: 45000,
								},
								trainingDuration: '25:11:40',
								researchResources: {
									wood: 15880,
									clay: 13800,
									iron: 36400,
								},
								researchDuration: '06:47:55',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 20,
									},
									{
										buildingType: 16,
										name: 'Пункт сбора',
										level: 10,
									},
								],
								nr: 9,
								description: '<span>Сенатор — это лидер, избираемый народом. Сенаторы отличаются незаурядными ораторскими способностями и знают, как говорить с публикой. Он способен убедить население вражеской деревни примкнуть к твоей империи.</span>',
							},
							{
								apiname: '10',
								name: 'Поселенец',
								content: {
									HelpContentUnit,
									//HelpContentTroopsRomans10,
								},
								attributes: {
									attack: 0,
									defenseInfantry: 80,
									defenseCavalry: 80,
									carry: 3000,
									speed: 5,
									consumption: 1,
								},
								trainingResources: {
									wood: 3500,
									clay: 3000,
									iron: 4500,
								},
								trainingDuration: '07:28:20',
								researchResources: {
									wood: 0,
									clay: 0,
									iron: 0,
								},
								researchDuration: '00:00:00',
								requirements: [],
								nr: TroopsModel.TYPE_SETTLER,
								description: '<span>Поселенцы — отважные жители твоей деревни. После продолжительного обучения они уезжают исследовать чужие земли, чтобы основать деревню в твою честь.</span>',
							},
						],
					},
					{
						apiname: 'teuton',
						name: 'Германцы',
						elements: [
							{
								apiname: '1',
								name: 'Дубинщик',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons1,
								},
								attributes: {
									attack: 40,
									defenseInfantry: 20,
									defenseCavalry: 5,
									carry: 60,
									speed: 7,
									consumption: 1,
								},
								trainingResources: {
									wood: 85,
									clay: 65,
									iron: 30,
								},
								trainingDuration: '00:12:00',
								researchResources: {
									wood: 0,
									clay: 0,
									iron: 0,
								},
								researchDuration: '00:00:00',
								requirements: [],
								nr: 1,
								description: '<span>Дубинщики — самые дешевые воины в Travian. Дубинщики обучаются очень быстро и обладают умеренной атакующей способностью, но их низкие защитные качества делают их уязвимыми против атак кавалерией.</span>',
							},
							{
								apiname: '2',
								name: 'Копьеносец',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons2,
								},
								attributes: {
									attack: 10,
									defenseInfantry: 35,
									defenseCavalry: 60,
									carry: 40,
									speed: 7,
									consumption: 1,
								},
								trainingResources: {
									wood: 125,
									clay: 50,
									iron: 65,
								},
								trainingDuration: '00:18:40',
								researchResources: {
									wood: 850,
									clay: 300,
									iron: 720,
								},
								researchDuration: '01:26:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 1,
									},
									{
										buildingType: 19,
										name: 'Казарма',
										level: 3,
									},
								],
								nr: 2,
								description: '<span>Их оружие позволяет копьеносцам хорошо противостоять атакам кавалерии. В качестве атакующего войска копьеносцы, впрочем, практически бесполезны.</span>',
							},
							{
								apiname: '3',
								name: 'Топорщик',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons3,
								},
								attributes: {
									attack: 60,
									defenseInfantry: 30,
									defenseCavalry: 30,
									carry: 50,
									speed: 6,
									consumption: 1,
								},
								trainingResources: {
									wood: 80,
									clay: 65,
									iron: 130,
								},
								trainingDuration: '00:20:00',
								researchResources: {
									wood: 580,
									clay: 360,
									iron: 1240,
								},
								researchDuration: '01:30:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 3,
									},
									{
										buildingType: 13,
										name: 'Кузница',
										level: 1,
									},
								],
								nr: 3,
								description: '<span>Топорщик — самый сильный пехотинец Германцев. У топорщиков сильная атака и средняя защита. Он, однако, более медлителен и относительно дорог по сравнению с другими войсками.</span>',
							},
							{
								apiname: '4',
								name: 'Скаут',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons4,
								},
								attributes: {
									attack: 0,
									defenseInfantry: 10,
									defenseCavalry: 5,
									carry: 0,
									speed: 9,
									consumption: 1,
								},
								trainingResources: {
									wood: 140,
									clay: 80,
									iron: 30,
								},
								trainingDuration: '00:18:40',
								researchResources: {
									wood: 940,
									clay: 420,
									iron: 440,
								},
								researchDuration: '01:26:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 1,
									},
									{
										buildingType: 15,
										name: 'Главное здание',
										level: 5,
									},
								],
								nr: 4,
								description: '<span>Скаут исследует деревни ваших врагов пешком. Он осторожно выведывает их секреты по поводу ресурсов, войск и защитных сооружений.</span>',
							},
							{
								apiname: '5',
								name: 'Паладин',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons5,
								},
								attributes: {
									attack: 55,
									defenseInfantry: 100,
									defenseCavalry: 40,
									carry: 110,
									speed: 10,
									consumption: 2,
								},
								trainingResources: {
									wood: 330,
									clay: 170,
									iron: 200,
								},
								trainingDuration: '00:40:00',
								researchResources: {
									wood: 1070,
									clay: 780,
									iron: 1800,
								},
								researchDuration: '02:30:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 5,
									},
									{
										buildingType: 20,
										name: 'Конюшня',
										level: 1,
									},
								],
								nr: 5,
								description: '<span>Паладины, с их тяжелыми доспехами и сильным оружием защиты, особенно хорошо проявляют себя против пехоты. К их недостаткам можно отнести атаку и скорость.</span>',
							},
							{
								apiname: '6',
								name: 'Тевтонская конница',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons6,
								},
								attributes: {
									attack: 150,
									defenseInfantry: 50,
									defenseCavalry: 75,
									carry: 80,
									speed: 9,
									consumption: 3,
								},
								trainingResources: {
									wood: 280,
									clay: 320,
									iron: 260,
								},
								trainingDuration: '00:49:20',
								researchResources: {
									wood: 1780,
									clay: 1380,
									iron: 2280,
								},
								researchDuration: '02:58:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 15,
									},
									{
										buildingType: 20,
										name: 'Конюшня',
										level: 10,
									},
								],
								nr: 6,
								description: '<span>Тевтонские конники — могучие воины, которые буквально повергают в ужас врагов своей атакой. Их сила велика, но и обучение обходится не дешево.</span>',
							},
							{
								apiname: '7',
								name: 'Таран',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons7,
								},
								attributes: {
									attack: 65,
									defenseInfantry: 30,
									defenseCavalry: 80,
									carry: 0,
									speed: 4,
									consumption: 3,
								},
								trainingResources: {
									wood: 800,
									clay: 150,
									iron: 250,
								},
								trainingDuration: '04:00:00',
								researchResources: {
									wood: 4900,
									clay: 700,
									iron: 2200,
								},
								researchDuration: '04:00:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 10,
									},
									{
										buildingType: 21,
										name: 'Мастерская',
										level: 1,
									},
								],
								nr: 7,
								description: '<span>Таран — это тяжелое осадное орудие в помощь пехоте и кавалерии. Их задача — пробить брешь в стенах города.</span>',
							},
							{
								apiname: '8',
								name: 'Катапульта',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons8,
								},
								attributes: {
									attack: 50,
									defenseInfantry: 60,
									defenseCavalry: 10,
									carry: 0,
									speed: 3,
									consumption: 6,
								},
								trainingResources: {
									wood: 660,
									clay: 900,
									iron: 370,
								},
								trainingDuration: '02:30:00',
								researchResources: {
									wood: 4060,
									clay: 3700,
									iron: 3160,
								},
								researchDuration: '08:00:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 15,
									},
									{
										buildingType: 21,
										name: 'Мастерская',
										level: 10,
									},
								],
								nr: 8,
								description: '<span>Катапульта — замечательное орудие дальнего боя, которое уничтожает постройки и ресурсные поля. Сама по себе она, впрочем, довольно беззащитна.</span>',
							},
							{
								apiname: '9',
								name: 'Вождь',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons9,
								},
								attributes: {
									attack: 40,
									defenseInfantry: 60,
									defenseCavalry: 40,
									carry: 0,
									speed: 4,
									consumption: 4,
								},
								trainingResources: {
									wood: 35500,
									clay: 26600,
									iron: 25000,
								},
								trainingDuration: '19:35:00',
								researchResources: {
									wood: 18250,
									clay: 13500,
									iron: 20400,
								},
								researchDuration: '05:23:45',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 20,
									},
									{
										buildingType: 16,
										name: 'Пункт сбора',
										level: 5,
									},
								],
								nr: 9,
								description: '<span>Германцы выбирают вождя среди равных себе. Сила его речи настолько убедительна, что он даже может уговорить вражескую деревню сменить народ.</span>',
							},
							{
								apiname: '10',
								name: 'Поселенец',
								content: {
									HelpContentUnit,
									//HelpContentTroopsTeutons10,
								},
								attributes: {
									attack: 10,
									defenseInfantry: 80,
									defenseCavalry: 80,
									carry: 3000,
									speed: 5,
									consumption: 1,
								},
								trainingResources: {
									wood: 4000,
									clay: 3500,
									iron: 3200,
								},
								trainingDuration: '08:36:40',
								researchResources: {
									wood: 0,
									clay: 0,
									iron: 0,
								},
								researchDuration: '00:00:00',
								requirements: [],
								nr: TroopsModel.TYPE_SETTLER,
								description: '<span>Поселенцы — отважные жители твоей деревни. После продолжительного обучения они уезжают исследовать чужие земли, чтобы основать деревню в твою честь.</span>',
							},
						],
					},
					{
						apiname: 'gaul',
						name: 'Галлы',
						elements: [
							{
								apiname: '1',
								name: 'Фаланга',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls1,
								},
								attributes: {
									attack: 15,
									defenseInfantry: 40,
									defenseCavalry: 50,
									carry: 35,
									speed: 7,
									consumption: 1,
								},
								trainingResources: {
									wood: 85,
									clay: 100,
									iron: 50,
								},
								trainingDuration: '00:17:20',
								researchResources: {
									wood: 0,
									clay: 0,
									iron: 0,
								},
								researchDuration: '00:00:00',
								requirements: [],
								nr: 1,
								description: '<span>Поскольку фаланги относятся к пехоте, их обучение дешево и быстро. Атакующая сила фаланг невелика. Настоящая их сила раскрывается в защите.</span>',
							},
							{
								apiname: '2',
								name: 'Мечник',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls2,
								},
								attributes: {
									attack: 65,
									defenseInfantry: 35,
									defenseCavalry: 20,
									carry: 45,
									speed: 6,
									consumption: 1,
								},
								trainingResources: {
									wood: 95,
									clay: 60,
									iron: 140,
								},
								trainingDuration: '00:24:00',
								researchResources: {
									wood: 670,
									clay: 340,
									iron: 1320,
								},
								researchDuration: '01:42:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 3,
									},
									{
										buildingType: 13,
										name: 'Кузница',
										level: 1,
									},
								],
								nr: 2,
								description: '<span>Мечники более дороги, чем фаланги, но они уже относятся к атакующим войскам. Они довольно слабы в защите, особенно против кавалерии.</span>',
							},
							{
								apiname: '3',
								name: 'Следопыт',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls3,
								},
								attributes: {
									attack: 0,
									defenseInfantry: 20,
									defenseCavalry: 10,
									carry: 0,
									speed: 17,
									consumption: 2,
								},
								trainingResources: {
									wood: 140,
									clay: 110,
									iron: 20,
								},
								trainingDuration: '00:22:40',
								researchResources: {
									wood: 940,
									clay: 540,
									iron: 360,
								},
								researchDuration: '01:38:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 5,
									},
									{
										buildingType: 20,
										name: 'Конюшня',
										level: 1,
									},
								],
								nr: 3,
								description: '<span>Следопыты — разведывательные войска Галлов. Быстро, но осторожно, они выведывают секреты о ресурсах, войсках и защитных сооружениях врагов.</span>',
							},
							{
								apiname: '4',
								name: 'Тевтатский гром',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls4,
								},
								attributes: {
									attack: 90,
									defenseInfantry: 25,
									defenseCavalry: 40,
									carry: 75,
									speed: 19,
									consumption: 2,
								},
								trainingResources: {
									wood: 200,
									clay: 280,
									iron: 130,
								},
								trainingDuration: '00:41:20',
								researchResources: {
									wood: 1300,
									clay: 1220,
									iron: 1240,
								},
								researchDuration: '02:34:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 5,
									},
									{
										buildingType: 20,
										name: 'Конюшня',
										level: 3,
									},
								],
								nr: 4,
								description: '<span>Тевтатские громы — атакующая конница галлов — обладают исключительной скоростью и могут переносить большое количество ресурсов. В защите они довольно слабы.</span>',
							},
							{
								apiname: '5',
								name: 'Друид-всадник',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls5,
								},
								attributes: {
									attack: 45,
									defenseInfantry: 115,
									defenseCavalry: 55,
									carry: 35,
									speed: 16,
									consumption: 2,
								},
								trainingResources: {
									wood: 300,
									clay: 270,
									iron: 190,
								},
								trainingDuration: '00:42:40',
								researchResources: {
									wood: 1900,
									clay: 1180,
									iron: 1720,
								},
								researchDuration: '02:38:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 5,
									},
									{
										buildingType: 20,
										name: 'Конюшня',
										level: 5,
									},
								],
								nr: 5,
								description: '<span>Друиды-всадники - это защитная конница. Они особенно сильны против пехоты.</span>',
							},
							{
								apiname: '6',
								name: 'Эдуйская конница',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls6,
								},
								attributes: {
									attack: 140,
									defenseInfantry: 60,
									defenseCavalry: 165,
									carry: 65,
									speed: 13,
									consumption: 3,
								},
								trainingResources: {
									wood: 300,
									clay: 380,
									iron: 440,
								},
								trainingDuration: '00:52:00',
								researchResources: {
									wood: 1900,
									clay: 1620,
									iron: 3720,
								},
								researchDuration: '03:06:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 15,
									},
									{
										buildingType: 20,
										name: 'Конюшня',
										level: 10,
									},
								],
								nr: 6,
								description: '<span>Эдуйская конница хорошо проявляет себя в защите против кавалерии, а также обладает значительной атакующей силой. Однако, содержание и обучение этого войска обходится дорого.</span>',
							},
							{
								apiname: '7',
								name: 'Таран',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls7,
								},
								attributes: {
									attack: 50,
									defenseInfantry: 30,
									defenseCavalry: 105,
									carry: 0,
									speed: 4,
									consumption: 3,
								},
								trainingResources: {
									wood: 750,
									clay: 370,
									iron: 220,
								},
								trainingDuration: '01:23:20',
								researchResources: {
									wood: 4600,
									clay: 1580,
									iron: 1960,
								},
								researchDuration: '04:40:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 10,
									},
									{
										buildingType: 21,
										name: 'Мастерская',
										level: 1,
									},
								],
								nr: 7,
								description: '<span>Таран — это тяжелое осадное орудие в помощь пехоте и кавалерии. Их задача — пробить брешь в стенах города.</span>',
							},
							{
								apiname: '8',
								name: 'Требушет',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls8,
								},
								attributes: {
									attack: 70,
									defenseInfantry: 45,
									defenseCavalry: 10,
									carry: 0,
									speed: 3,
									consumption: 6,
								},
								trainingResources: {
									wood: 590,
									clay: 1200,
									iron: 400,
								},
								trainingDuration: '02:30:00',
								researchResources: {
									wood: 3640,
									clay: 4900,
									iron: 3400,
								},
								researchDuration: '08:00:00',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 15,
									},
									{
										buildingType: 21,
										name: 'Мастерская',
										level: 10,
									},
								],
								nr: 8,
								description: '<span>Требушет — замечательное орудие дальнего боя, которое уничтожает постройки и ресурсные поля. Сама по себе она, впрочем, довольно беззащитна.</span>',
							},
							{
								apiname: '9',
								name: 'Предводитель',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls9,
								},
								attributes: {
									attack: 40,
									defenseInfantry: 50,
									defenseCavalry: 50,
									carry: 0,
									speed: 5,
									consumption: 4,
								},
								trainingResources: {
									wood: 30750,
									clay: 45400,
									iron: 31000,
								},
								trainingDuration: '25:11:40',
								researchResources: {
									wood: 15880,
									clay: 22900,
									iron: 25200,
								},
								researchDuration: '06:47:55',
								requirements: [
									{
										buildingType: 22,
										name: 'Академия',
										level: 20,
									},
									{
										buildingType: 16,
										name: 'Пункт сбора',
										level: 10,
									},
								],
								nr: 9,
								description: '<span>У каждого народа есть свой опытный уважаемый предводитель, который понимает, как убедить население деревни противника оказать ему поддержку.</span>',
							},
							{
								apiname: '10',
								name: 'Поселенец',
								content: {
									HelpContentUnit,
									//HelpContentTroopsGauls10,
								},
								attributes: {
									attack: 0,
									defenseInfantry: 80,
									defenseCavalry: 80,
									carry: 3000,
									speed: 5,
									consumption: 1,
								},
								trainingResources: {
									wood: 3000,
									clay: 4000,
									iron: 3000,
								},
								trainingDuration: '06:18:20',
								researchResources: {
									wood: 0,
									clay: 0,
									iron: 0,
								},
								researchDuration: '00:00:00',
								requirements: [],
								nr: TroopsModel.TYPE_SETTLER,
								description: '<span>Поселенцы — отважные жители твоей деревни. После продолжительного обучения они уезжают исследовать чужие земли, чтобы основать деревню в твою честь.</span>',
							},
						],
					},
				],
			},
			{
				apiname: 'village',
				type: 'view',
				name: 'Деревня',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'rubble',
								name: 'Руины',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentVillageRubble1,
									//HelpContentVillageRubble2,
								},
								icon: 'Village_Rubble',
								data: [
									{
										text: '<span>Каждый раз, когда ты сносишь строение через меню в главном здании или когда оно разрушено катапультами, на площадке появляются руины, которые ты можешь разобрать. В начале игры в деревне уже имеется несколько руин, ресурсы с которых ускорят твой прогресс.</span>',
									},
									{
										text: '<span>Разбор руин занимает некоторое время, но также дает тебе некоторое количество ресурсов. Получаемые ресурсы зависят от того, насколько дорогим было разрушенное здание. Руины также являются небольшой компенсацией за уровни здания, потерянные из-за атак катапультами.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'protecting-yourself',
								name: 'Защита своих владений',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentVillageProtectingYourself1,
									//HelpContentVillageProtectingYourself2,
									//HelpContentVillageProtectingYourself3,
								},
								icon: 'Village_ProtectingYourself',
								data: [
									{
										text: '<span>В случае атаки на одну из своих деревень убедись, что ты готов к ней. В первую очередь постарайся потратить как можно больше ресурсов, чтобы атакующему ничего не досталось. Тем самым твоя деревня перестанет быть привлекательной целью для грабежей. Чтобы защитить себя от разведывательных операций, постоянно держи несколько разведчиков в своей деревне.</span>',
									},
									{
										text: '<span>Для того, чтобы противник не смог вынести даже остатки ресурсов, построй тайник, который укроет от атак определенное количество ресурсов. Если ты разовьешь тайник до максимального уровня, ты сможешь строить дополнительные тайники.</span>',
									},
									{
										text: '<span>Надень на героя сапоги беглеца, чтобы войска не погибли. Сапоги беглеца позволяют некоторому количеству войск и герою скрыться от нападения и вернуться в деревню вскоре после этого. Если атаки на твою деревню будут невыгодны, они, скорее всего, прекратятся.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'research',
								name: 'Исследование',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentVillageResearch1,
									//HelpContentVillageResearch2,
								},
								icon: 'Village_Research',
								data: [
									{
										text: '<span>В академии каждой деревни ты можешь изучить различные типы войск и тем самым улучшить свою армию. У каждого войска свои требования к изучению и стоимость.</span>',
									},
									{
										text: '<span>Чтобы сделать уже изученные войска более сильными, ты можешь улучшить их в кузнице. Улучшения кузницы действуют в равной мере и на уже имеющиеся войска. Единственное условие – улучшение должно производиться в родной деревне войск.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000083678',
							},
							{
								apiname: 'expansion-requirements',
								name: 'Расширение территории',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentVillageExpansionRequirements1,
									//HelpContentVillageExpansionRequirements2,
									//HelpContentVillageExpansionRequirements3,
									//HelpContentVillageExpansionRequirements4,
								},
								icon: 'Village_ExpansionRequirements',
								data: [
									{
										text: '<span>Для того, чтобы иметь возможность основать дополнительные деревни, тебе нужно построить дворец или резиденцию и улучшать их до тех пор, пока не откроется слот экспансии. Тогда ты сможешь обучить поселенцев.</span>',
									},
									{
										text: '<span>Кроме того, для основания или захвата деревни тебе также необходимо накопить достаточное количество единиц культуры. Твоя деревня производит единицы культуры ежедневно. Количество производимых единиц культуры зависит от того, какие здания имеются в деревне.</span>',
									},
									{
										text: '<span>Празднования в ратуше помогут тебе быстрее выработать дополнительные единицы культуры. Поначалу ты можешь проводить только маленькие праздники. Позже тебе станут доступны большие торжества, которые будут давать еще больше культуры.</span>',
									},
									{
										text: '<span>Как только все требования соблюдены, ты сможешь обучить трех поселенцев в своем дворце или резиденции и отправить их основывать поселение на свободной клетке на карте. Когда они придут на место, у тебя появится новая деревня.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'cities',
								name: 'Города',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentVillageCities1,
									//HelpContentVillageCities2,
								},
								icon: 'Village_Cities',
								data: [
									{
										text: '<span>Вместо основания дополнительной деревни ты можешь провозгласить уже существующую деревню городом в главном здании. Это также стоит слота экспансии. Для того, чтобы даровать деревне привилегии города, ее население должно быть не ниже 500. Как только деревня станет городом, там немедленно поселится еще 500 жителей.</span>',
									},
									{
										text: '<span>Население будет потреблять зерно, но за это город будет дополнительно давать 200 (в столице 500) единиц культуры в день. Кроме того, ты сможешь построить вокруг своего города ров, который усилит его защиту.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000080263',
							},
							{
								apiname: 'relocation',
								name: 'Переселение',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentVillageRelocation1,
									//HelpContentVillageRelocation2,
									//HelpContentVillageRelocation3,
									//HelpContentVillageRelocation4,
								},
								icon: 'Village_Relocation',
								data: [
									{
										text: '<span>Если вы не являетесь жителем королевства и у вас только одна деревня, то короли могут пригласить вас переехать в их земли. В этом случае ты получишь приглашение на переселение, которое можно посмотреть в посольстве на вкладке сообществ. Пока у тебя есть приглашение, кусок земли закреплен за тобой и помечен каменным столбом, так что нет нужды торопиться.</span><br><br><span>Обрати внимание, что ты должен дождаться, пока все войска не достигнут пункта назначения, только после этого ты сможешь переехать. Если кто-то атакует вашу деревню, то ты не можешь переехать, но можешь зарезервировать место на карте и деревня будет автоматически перемещена сразу после завершения атаки. Перемещение невозможно, пока общее потребление зерна (включая войска в деревнях других игроков) превышает 2000.</span><br><br><span>Позаботься о том, чтобы переселиться на землю с таким распределением ресурсных полей, которому ты можешь найти хорошее применение!</span>',
									},
									{
										text: '<span>Если кто-то атакует твою деревню, то ты не можешь переехать, но можешь зарезервировать место на карте:</span><ul class="uList"><li>деревня будет автоматически перемещена сразу после завершения атаки;</li><li>атакующие войска, которые должны атаковать деревню после перемещения, не смогут атаковать деревню;</li><li>ты не сможешь послать войска куда-либо до завершения перемещения;</li><li>ты можешь отменить резервирование до того, пока не прошло половины отведенного на резерв времени.</li></ul>',
									},
									{
										text: '<span>Условия перемещения:</span><ul class="uList"><li>все здания будут сохранены;</li><li>ресурсные поля будут потеряны, и тебе придется строить все поля заново;</li><li>в инвентарь героя будут добавлены ресурсы, примерно соответствующие ценности ресурсных полей;</li><li>все подкрепления будут отправлены домой;</li><li>все твои войска, находящиеся вне деревни, вернутся в новую деревню;</li><li>после перемещения ты не сможешь отправлять атаки в течение 12:00 ч.</li><li>Текущие логова разбойников исчезнут.</li></ul>',
									},
									{
										text: '<span>Будучи королем ты можешь выбрать свободное место на карте и разместить в выбранной точке каменный столб, после чего пригласить губернатора присоединиться к вашему королевству, переместив деревню в указанное место. Пожалуйста, имей в виду, что 9 и 15 зерновые поля недоступны для перемещения.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/ru-RU/support/solutions/articles/7000053606',
							},
						],
					},
				],
			},
			{
				apiname: 'robbers',
				type: 'view',
				name: 'Разбойники',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'robber-camps',
								name: 'Лагеря разбойников',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentRobbersRobberCamps1,
									//HelpContentRobbersRobberCamps2,
								},
								icon: 'Robbers_RobberCamps',
								data: [
									{
										text: '<span>В отличие от логова разбойников, лагеря видны всему королевству. Для того, чтобы уничтожить лагерь, его должны атаковать несколько игроков. Каждый игрок королевства может победить только одну волну в лагере. Чтобы полностью уничтожить разбойников, жителям королевства нужно сотрудничать друг с другом. Если ты откроешь информацию о лагере, ты сможешь увидеть атаки, которые на него уже отправлены.</span>',
									},
									{
										text: '<span>Только когда все волны разбойников, из которых состоит лагерь, будут уничтожены, он исчезнет с карты. Все волны, кроме последней, будут считаться уничтоженными, как только будут захвачены все краденые ценности. Выжившие войска и ресурсы будут добавлены в следующую волну. Последняя волна будет считаться уничтоженной после того, как будут захвачены все краденые ценности и ресурсы.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000083381',
							},
							{
								apiname: 'robber-hideouts',
								name: 'Логова разбойников',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentRobbersRobberHideouts1,
									//HelpContentRobbersRobberHideouts2,
									//HelpContentVillageRobberHideouts3,
									//HelpContentVillageRobberHideouts4,
								},
								icon: 'Robbers_RobberHideouts',
								data: [
									{
										text: '<span>Время от времени на карте появляются логова разбойников. Ты можешь атаковать их, чтобы добыть ресурсы и захватить краденые ценности. В отличие от лагерей разбойников, их логова скрыты и видны только тебе. Так что это только твоя задача&nbsp;– избавиться от них как можно скорей.</span>',
									},
									{
										text: '<span>Краденые ценности, захваченные у разбойников, можно продать своему королю, чтобы добавить сокровища в сокровищницу королевства. Таким образом ты поможешь усилить влияние королевства, что позволит тебе в свое время получить еще больше ресурсов.</span>',
									},
									{
										text: '<span>В информации о логове видны хранящиеся там ресурсы и краденые ценности. Только когда в логове не останется ни войск, ни ресурсов, оно исчезнет с карты. Вскоре после этого появится новое логово. Периодичность и места появления логова разбойников не фиксированы.</span>',
									},
									{
										text: '<span>Следует помнить: для того, чтобы захватить всю возможную добычу, атакующие войска должны быть в состоянии переносить большое количество ресурсов. Убедись, что ты отправляешь в атаку достаточно войска, даже если ты уже победил всех разбойников в логове.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000083381',
							},
							{
								apiname: 'stolen-goods-and-treasures',
								name: 'Краденые ценности и сокровища',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentRobbersStolenGoodsAndTreasures1,
									//HelpContentRobbersStolenGoodsAndTreasures2,
								},
								icon: 'Robbers_StolenGoodsAndTreasures',
								data: [
									{
										text: '<span>Губернаторы и герцоги отбирают краденое у разбойников, атакуя лагеря и логова разбойников. Они могут продать их королю или герцогу с максимальным влиянием на их деревню. Общее количество сокровищ влияет на количество зерна, которое игрок получает при продаже краденых ценностей.</span>',
									},
									{
										text: '<span>Чем больше сокровищ имеется в королевстве, тем больше будет открыто слотов сокровищниц. Король должен равным образом распределять эти слоты между герцогами и самим собой. Полученный слот сокровищницы позволяет игроку активировать сокровищницу в еще одной деревне, чтобы хранящиеся там сокровища оказывали влияние на территорию и обеспечивали рост королевства. Сокровища также имеют большое значение для победы в игровом раунде, так как они вырабатывают очки победы для альянса.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000083383',
							},
						],
					},
				],
			},
			{
				apiname: 'kingdom',
				type: 'view',
				name: 'Королевство',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'map-and-detail-view',
								name: 'Карта и информация',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentKingdomMapAndDetailView1,
									//HelpContentKingdomMapAndDetailView2,
									//HelpContentKingdomMapAndDetailView3,
								},
								icon: 'Kingdom_MapAndDetailView',
								data: [
									{
										text: '<span>Границы твоего королевства обозначены желтым цветом. В центре ты найдешь деревню короля (она выделена особой подложкой).</span>',
									},
									{
										text: '<span>Работая вместе и увеличивая количество сокровищ в вашем королевстве, вы сможете расширить его границы. Результаты будут отображены на карте.</span>',
									},
									{
										text: '<span>Если ты нажмешь на деревню другого игрока или любое свободное место на карте, то в центре круглого меню будет особая кнопка, открывающая детальную информацию об этом месте.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000082990',
							},
							{
								apiname: 'tributes',
								name: 'Дань',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentKingdomTributes1,
									//HelpContentKingdomTributes2,
								},
								icon: 'Kingdom_Tributes',
								data: [
									{
										text: '<span>Главным стимулом для королей и герцогов защищать своих губернаторов является дань. Они могут регулярно собирать ее с ближайших губернаторов. Дань – это ресурсы, которые хранятся в фонде дани в деревнях губернаторов, а также сокровища, которые вырабатываются при продаже ими краденых ценностей. Обрати внимание, что короли могут собрать дань только с губернаторов, являющихся жителями их королевства, а также с иностранных губернаторов, которым была дарована защита. Нейтральные губернаторы дань не платят.</span>',
									},
									{
										text: '<span>Ресурсы из фонда дани и даже сокровища могут быть украдены, если губернатор будет атакован до того, как король соберет дань. Ресурсы из фонда дани всегда крадутся в первую очередь. Только затем грабители добираются до ресурсов, хранящихся в складах губернаторов. Эти ресурсы будут вырабатываться, даже если губернатор решил не платить дань.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000083383',
							},
							{
								apiname: 'influence',
								name: 'Влияние',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentKingdomInfluence1,
									//HelpContentKingdomInfluence2,
								},
								icon: 'Kingdom_Influence',
								data: [
									{
										text: '<span>Деревни королей и герцогов, в которых имеются активные сокровищницы, распространяют влияние на округу и определяют размер королевства. Больше сокровищ означает больше влияния.</span>',
									},
									{
										text: '<span>Влияние очень важно для расширения границ, увеличения числа губернаторов и для общего роста могущества королевства. Это поможет тебе обрести еще больше сокровищ и очков победы для твоего королевства.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000081939',
							},
							{
								apiname: 'invitations',
								name: 'Приглашение губернаторов',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentKingdomInvitations1,
									//HelpContentKingdomInvitations2,
									//HelpContentKingdomInvitations3,
								},
								icon: 'Kingdom_Invitations',
								data: [
									{
										text: '<span>Если влияние королевства распространилось достаточно далеко и достигло игрока, который в данный момент не является жителем королевства, король может пригласить этого игрока в свое королевство. Приглашение отправляется через круглое меню на конкретной деревне на карте. Посмотреть активные приглашения и ответить на них можно в посольстве.</span>',
									},
									{
										text: '<span>В качестве короля ты также можешь пригласить губернаторов, находящихся за пределами сферы влияния твоего королевства, предложив им надел земли внутри границ. Чтобы это сделать, выбери поле для поселения, используй круговое меню, чтобы пригласить игрока переселиться сюда, и следуй инструкциям.</span><br><br><span>Ты можешь пригласить переехать в свое королевство только активных независимых игроков с одним поселением. Это хороший инструмент, который дает возможность пригласить новых игроков в качестве губернаторов в свое королевство, чтобы заработать дань, или быстро объединиться с друзьями в начале игрового раунда.</span>',
									},
									{
										text: '<span>Как только чудо света достигнет 50 уровня, губернаторов нельзя будет пригласить вступить в королевство. Короли не смогут повысить чужих губернаторов до роли герцогов: им придется выбирать губернатора, который уже является частью их королевства. Следует помнить, что на этом этапе нельзя будет повторно пригласить губернаторов, которые покинули или были исключены из королевства.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000081939',
							},
							{
								apiname: 'dukes',
								name: 'Герцоги',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentKingdomDukes1,
									//HelpContentKingdomDukes2,
								},
								icon: 'Kingdom_Dukes',
								data: [
									{
										text: '<span>Короли изначальных королевств могут назначить до двух герцогов себе в помощь. Только после того, как два королевства объединятся (см. Слияние королевств), получившееся королевство может назначить до четырех герцогов. Герцогов можно посмотреть в профиле королевства. Обрати внимание, что король может провозгласить герцогами не только своих губернаторов, но и выбрать их из губернаторов других королевств.</span>',
									},
									{
										text: '<span>Изначально герцоги могут активировать сокровищницу в одной деревне, что поможет дальнейшему развитию королевства. За каждые 10 000 сокровищ, которые накопит королевство, король имеет право назначить еще один слот под строительство сокровищницы себе или отдать его одному из своих герцогов. Герцоги не могут отречься от власти, пока у них имеется активная сокровищница. Когда король изгоняет герцога, королевство моментально потеряет некоторое количество победных очков, равное количеству активных сокровищ этого герцога *10.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000082523',
							},
							{
								apiname: 'unions',
								name: 'Слияния королевств',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentKingdomUnions1,
									//HelpContentKingdomUnions2,
									//HelpContentKingdomUnions3,
									//HelpContentKingdomUnions4,
								},
								icon: 'Kingdom_Unions',
								data: [
									{
										text: '<span>Когда два королевства с соприкасающейся территорией сумели продержаться в игровом мире в течение 30 дней (меньше для скоростных миров), они получают возможность объединиться друг с другом, если их короли придут к согласию. Королям следует мудро выбирать своих партнеров. Королевства, не имеющие смежных границ или просуществовавшие менее 30 дней, не могут объединиться, так что, если какое-либо королевство слишком уж увлеклось тактикой выжженной земли вокруг себя, оно может остаться без возможных партнеров. Территория считается соприкасающейся, если земли вокруг столиц королей имеют общую границу. Столицы обоих королей должны находиться каждая внутри своей территории (иметь свои собственные границы) или в них должна быть активная сокровищница; недостаточно просто находиться рядом на территории одного из королей.</span>',
									},
									{
										text: '<span>У объединения королевств имеются долгосрочные последствия. Все игроки меньшего по размеру королевства входят в состав более крупного и занимают те же позиции. Бывший король малого королевства становится вице-королем объединенного королевства. Объединенное королевство получает два дополнительных слота герцогов, которые немедленно занимаются герцогами малого королевства. Все губернаторы малого королевства становятся губернаторами объединенного. Хотя игроки и территории объединятся, любая другая собственность малого королевства будет потеряна - это прежде всего относится к очкам победы.</span>',
									},
									{
										text: '<span>Способность объединенного королевства накапливать очки победы сильно возрастает благодаря дополнительным слотам сокровищниц, но за это королям необходимо будет посвятить себя совместному управлению королевством до конца игрового раунда, приняв на себя роли короля и вице-короля. Если король отрекается от власти, он меняется местами с вице-королем. Вице-король отречься от власти не может. Никто из герцогов более не может взойти на престол. Подобно королю, вице-король может собирать дань с герцогов и губернаторов, а также назначать и убирать герцогов.</span>',
									},
									{
										text: '<span>Но больше ни один игрок не сможет взять на себя роль короля или вице-короля в этом королевстве, даже если оба правителя решат покинуть игру. Если один из правителей покидает игру, слот вице-герцога остается пустым. Королевство может объединиться лишь раз. Если оба короля покинут игру, королевство будет расформировано. Короли, решающие объединиться, должны рассматривать это как обязательство посвятить себя своей группе до конца игрового мира, и должны соглашаться на это, только если они уверены, что они могут и хотят управлять этим королевством до самого конца.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
						],
					},
				],
			},
			{
				apiname: 'hero',
				type: 'view',
				name: 'Герой',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'adventures',
								name: 'Приключения',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentHeroAdventures1,
									//HelpContentHeroAdventures2,
									//HelpContentHeroAdventures3,
								},
								icon: 'Hero_Adventures',
								data: [
									{
										text: '<span>Приключения помогают развивать героя. В них можно найти различные ценные предметы, а также ресурсы и войска.</span>',
									},
									{
										text: '<span>Для того, чтобы герой мог отправиться в приключение, нужны особые очки приключения. Они вырабатываются со временем. Дополнительные очки приключения можно выиграть в карточной игре или получить за прохождение некоторых заданий.</span>',
									},
									{
										text: '<span>Найденные в приключениях ресурсы и войска герой приносит (или приводит) с собой назад. Предметы появляются в инвентаре героя сразу по окончании приключения.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'consumables',
								name: 'Употребляемые предметы',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentHeroConsumables1,
									//HelpContentHeroConsumables2,
									//HelpContentHeroConsumables3,
								},
								icon: 'Hero_Consumables',
								data: [
									{
										text: '<span>Употребляемые предметы расходуются при использовании. Например, если ты поймал животное в клетку или применил книгу мудрости, чтобы перераспределить параметры героя, эти предметы будут изъяты из инвентаря сразу после использования.</span>',
									},
									{
										text: '<span>Повязки, как и клетки, – это употребляемый предмет, который можно поместить в слот продукта. Если ты поместишь повязки в слот продукта героя перед битвой, они будут использованы для восстановления определенного количества погибших войск.</span>',
									},
									{
										text: '<span>Некоторые предметы можно также применять прямо из инвентаря. Мази, например, можно использовать, щелкнув по ним мышью, и они немедленно восстанавливают здоровье героя.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000081996',
							},
							{
								apiname: 'equipment',
								name: 'Снаряжение',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentHeroEquipment1,
									//HelpContentHeroEquipment2,
								},
								icon: 'Hero_Equipment',
								data: [
									{
										text: '<span>Ты можешь экипировать героя различными видами снаряжения, которые дают дополнительные преимущества. Щелкни по предмету или перетяни его, чтобы надеть на героя. Всплывающая подсказка покажет, что именно дает тот или иной предмет.</span>',
									},
									{
										text: '<span>Помимо слота для лошади, есть также слоты для шлема, оружия (правая рука), особых предметов (левая рука), доспехов, обуви и употребляемых предметов (таких, как клетки или повязки).</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000082005',
							},
							{
								apiname: 'auctions',
								name: 'Аукцион',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentHeroAuctions1,
									//HelpContentHeroAuctions2,
									//HelpContentHeroAuctions3,
								},
								icon: 'Hero_Auctions',
								data: [
									{
										text: '<span>Если твой герой нашел предмет, который тебе не нужен, ты можешь продать его за фиксированную цену серебром. После этого он будет выставлен на торги для других игроков.</span>',
									},
									{
										text: '<span>Ты не можешь сам вести аукцион, поэтому ты всегда сразу получишь стоимость предмета в серебре в зависимости от его типа, качества и количества предыдущих владельцев.</span>',
									},
									{
										text: '<span>На заработанное серебро можно купить предметы, которые выставили на продажу другие игроки, или обменять его на золото в пункте обмена.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000081640',
							},
							{
								apiname: 'hero-production',
								name: 'Производство героя',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentHeroHeroProduction1,
									//HelpContentHeroHeroProduction2,
								},
								icon: 'Hero_HeroProduction',
								data: [
									{
										text: '<span>Твой герой может помочь тебе увеличить производство ресурсов. В параметрах героя ты выбираешь ресурс, который должен производить герой. Производство ресурсов в равных долях также возможно.</span>',
									},
									{
										text: '<span>Общее количество ресурсов, которое может произвести герой, зависит от его параметра "Ресурсы". Этот параметр особенно важен на раннем этапе игры, когда твоя деревня еще не производит достаточно ресурсов.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'attributes',
								name: 'Параметры',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentHeroAttributes1,
									//HelpContentHeroAttributes2,
									//HelpContentHeroAttributes3,
									//HelpContentHeroAttributes4,
								},
								icon: 'Hero_Attributes',
								data: [
									{
										text: '<span>Как только герой заработал достаточно опыта в боях и приключениях для перехода на следующий уровень, он получит 4 очка навыков, которые ты можешь распределить по своему выбору.</span>',
									},
									{
										text: '<span>Сила героя очень важна, так как она определяет его успех в боях, а также сколько здоровья он потеряет в приключении.</span>',
									},
									{
										text: '<span>Бонусы атаки и защиты важны, если герой возглавляет в битвах большие армии. Для этого он должен находиться вместе с войсками.</span>',
									},
									{
										text: '<span>Навык производства ресурсов, также как и сила, очень важен на раннем этапе игры, так как герой может значительно увеличить выработку ресурсов и ускорить развитие.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000082914',
							},
							{
								apiname: 'capturing-animals',
								name: 'Поимка животных',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentHeroCapturingAnimals1,
									//HelpContentHeroCapturingAnimals2,
									//HelpContentHeroCapturingAnimals3,
									//HelpContentHeroCapturingAnimals4,
								},
								icon: 'Hero_CapturingAnimals',
								data: [
									{
										text: '<span>Ты можешь снабдить своего героя клетками из инвентаря, чтобы он использовал их для поимки животных в ближайших диких оазисах. В одну клетку можно поймать одно животное. В несколько клеток всегда попадутся животные разного типа.</span>',
									},
									{
										text: '<span>Чтобы поймать животных, сначала найди оазис, в котором они имеются, проводя курсором мыши над оазисами на карте. Животные отображаются в информации об оазисе. Затем выбери "Отправить войска" из круглого меню.</span>',
									},
									{
										text: '<span>Когда ты атакуешь оазис героем с клетками, помещенными в слот продукта, он поймает столько животных, сколько сможет, и вернется домой. В этом случае герой не вступает в бой с животными.</span>',
									},
									{
										text: '<span>Пойманные животные вернутся в деревню с героем. Они будут служить в качестве дополнительных войск защиты, при этом не потребляя зерно. Животных нельзя использовать в атаке.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000083361',
							},
							{
								apiname: 'resurrection',
								name: 'Восстановление',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentHeroResurrection1,
									//HelpContentHeroResurrection2,
								},
								icon: 'Hero_Resurrection',
								data: [
									{
										text: '<span>Погибшего героя можно восстановить на вкладке параметров. Восстановление можно запустить за ресурсы или использовать ведро из инвентаря. Стоимость восстановления за ресурсы снижается каждый день, пока герой мертв. Максимальное снижение равно 80%. Когда герой погибает в первый раз, максимальная скидка на восстановление применяется сразу.</span>',
									},
									{
										text: '<span>Для того, чтобы герой реже погибал, ты должен следить за шкалой здоровья героя и, возможно, использовать мазь перед началом приключений или перед отправкой героя в поход. Позднее ты сможешь найти снаряжение для героя, которое снижает получаемый урон в бою или повышает скорость восстановления здоровья героя.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
						],
					},
				],
			},
			{
				apiname: 'oases',
				type: 'view',
				name: 'Оазисы',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'wild-oases',
								name: 'Дикий оазис',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentOasesWildOases1,
									//HelpContentOasesWildOases2,
								},
								icon: 'Oases_WildOases',
								data: [
									{
										text: '<span>Первоначально все оазисы находятся в диком состоянии, то есть в них живут животные. Ты можешь расчистить оазис от животных, атаковав его с войсками или поймав их в клетки. Если ты атакуешь дикий оазис, ты также сможешь украсть небольшое количество ресурсов.</span>',
									},
									{
										text: '<span>Расчищенный оазис, который находится неподалеку от одной из твоих деревень, можно закрепить в посольстве деревни, чтобы получить дополнительный бонус к производству ресурсов.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000082954',
							},
							{
								apiname: 'assign-oasis',
								name: 'Оазис',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentOasesAssignOasis1,
									//HelpContentOasesAssignOasis2,
									//HelpContentOasesAssignOasis3,
									//HelpContentOasesAssignOasis4,
								},
								icon: 'Oases_AssignOasis',
								data: [
									{
										text: '<span>Для того, чтобы герой реже погибал, ты должен следить за шкалой здоровья героя и, возможно, использовать мазь перед началом приключений или перед отправкой героя в поход. Позднее ты сможешь найти снаряжение для героя, которое снижает получаемый урон в бою или повышает скорость восстановления здоровья героя.</span>',
									},
									{
										text: '<span>Оазисы можно завоевать и присоединить к королевству успешной атакой. Оазисы, вплотную примыкающие к границам королевства, можно присоединить, как только все защитники там были уничтожены.</span>',
									},
									{
										text: '<span>Влияние на оазис определяется окружающими деревнями и войсками, размещенными в этом оазисе, которые также могут производить добавочные ресурсы.</span>',
									},
									{
										text: '<span>Более подробную информацию о бонусах и влиянии на оазис ты можешь найти на карте, открыв окно информации об оазисе.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000082954',
							},
							{
								apiname: 'troops-in-oases',
								name: 'Войска в оазисах',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentOasesTroopsInOases1,
									//HelpContentOasesTroopsInOases2,
								},
								icon: 'Oases_TroopsInOases',
								data: [
									{
										text: '<span>Войска, размещенные в оазисах внутри твоего королевства, не только защищают эти оазисы, но также повышают бонус ресурсов в закрепленном оазисе.</span>',
									},
									{
										text: '<span>Оазисы можно завоевать и присоединить к королевству успешной атакой. Оазисы, вплотную примыкающие к границам королевства, можно присоединить, как только все защитники там были уничтожены.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000082954',
							},
						],
					},
				],
			},
			{
				apiname: 'trading',
				type: 'view',
				name: 'Торговля',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'trading-resources',
								name: 'Торговля ресурсами',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentTradingTradingResources1,
									//HelpContentTradingTradingResources2,
									//HelpContentTradingTradingResources3,
								},
								icon: 'Trading_TradingResources',
								data: [
									{
										text: '<span>На вкладке "Купить" на рынке ты можешь найти обзор текущих торговых предложений от других игроков.</span>',
									},
									{
										text: '<span>Фильтры над списком предложений могут помочь тебе найти то, что ты ищешь. Если ни одно предложение тебе не подходит, ты можешь создать свое собственное на вкладке "Продать".</span>',
									},
									{
										text: '<span>Если ты хочешь принять предложение, нажми на кнопку справа. Торговцы автоматически начнут свой путь с ресурсами с обеих деревень.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/ru-RU/support/solutions/articles/7000052606',
							},
							{
								apiname: 'offering-resources',
								name: 'Продажа ресурсов',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentTradingOfferingResources1,
									//HelpContentTradingOfferingResources2,
									//HelpContentTradingOfferingResources3,
								},
								icon: 'Trading_OfferingResources',
								data: [
									{
										text: '<span>На рынке ты можешь торговать ресурсами с другими игроками, если у тебя слишком много, или наоборот, слишком мало какого-то ресурса.</span>',
									},
									{
										text: '<span>На вкладке "Продать" ты можешь выставить предложение на рынке, определив количество и тип ресурсов, которые ты хочешь предложить и получить взамен.</span>',
									},
									{
										text: '<span>Как только ты нажмешь на кнопку "Продать", предложение будет выставлено на рынок. Если другой игрок его примет, то зарезервированные торговцы автоматически начнут доставку ресурсов.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/ru-RU/support/solutions/articles/7000052606',
							},
							{
								apiname: 'sending-resources',
								name: 'Отправка ресурсов',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentTradingSendingResources1,
									//HelpContentTradingSendingResources2,
								},
								icon: 'Trading_SendingResources',
								data: [
									{
										text: '<span>Ты можешь отправить ресурсы в свою новую деревню в соответствующем меню на рынке, или выбрав опцию "Отправить торговцев" в информации о деревне, куда ты хочешь их послать.</span>',
									},
									{
										text: '<span>Используй эту функцию, чтобы ускорить развитие своей новой деревни, а также восполнить недостаток ресурсов.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/ru-RU/support/solutions/articles/7000052606',
							},
							{
								apiname: 'trade-routes',
								name: 'Торговые пути',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentTradingTradeRoutes1,
									//HelpContentTradingTradeRoutes2,
								},
								icon: 'Trading_TradeRoutes',
								data: [
									{
										text: '<span>На вкладке "Торговые пути" на рынке ты можешь назначить автоматическую отправку ресурсов для поддержания своих деревень. Для использования этой функции требуется активировать Travian Plus. Нажми на кнопку "Новый путь" и укажи, как часто и как много ресурсов каждого типа ты хочешь отправлять каждый день.</span>',
									},
									{
										text: '<span> В верхней части окна ты можешь указать, сколько именно ресурсов необходимо доставить в деревню. В нижней части окна ты найдешь график отправки, который позволит тебе определить, насколько часто и в какое время торговцы должны начать свой путь.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/ru-RU/support/solutions/articles/7000052606',
							},
						],
					},
				],
			},
			{
				apiname: 'progress',
				type: 'view',
				name: 'Прогресс',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'beginner-protection',
								name: 'Защита новичка',
								content: {
									HelpContentOther,
									//HelpContentProgressBeginnerProtection1,
								},
								icon: 'Progress_BeginnerProtection',
								data: [
									{
										text: '<span>В первые 7 дней новые игроки находятся под защитой новичка. В это время они не могут отправлять ресурсы другим игрокам и атаковать их. Игроков также нельзя атаковать. При этом игроки могут отправлять подкрепления. Защита новичка автоматически завершается, как только население игрока превысит 200 жителей.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'achievements',
								name: 'Достижения',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentProgressAchievements1,
									//HelpContentProgressAchievements2,
								},
								icon: 'Progress_Achievements',
								data: [
									{
										text: '<span>За прохождение определенных этапов игры ты получишь достижения. Обзор достижений, которые ты уже собрал и которые все еще доступны, находится в лобби.</span>',
									},
									{
										text: '<span>Получение достижений –&nbsp;это еще один способ заработать очки престижа помимо выполнения еженедельных заданий на престиж. Каждый уровень достижения даст тебе определенное количество очков престижа.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'daily-quests',
								name: 'Ежедневные задания',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentProgressDailyQuests1,
									//HelpContentProgressDailyQuests2,
									//HelpContentProgressDailyQuests3,
								},
								icon: 'Progress_DailyQuests',
								data: [
									{
										text: '<span>В дополнение к общим заданиям ты также получишь маленькие ежедневные задания каждые 12 часов. Ты можешь их найти на второй вкладке в книге заданий.</span>',
									},
									{
										text: '<span>Ты можешь иметь не более трех активных заданий одновременно. Если к моменту, когда завершится обратный отсчет, все слоты дневных заданий будут заполнены, ты пропустишь очередное задание.</span>',
									},
									{
										text: '<span>Каждые 12 часов ты можешь поменять ежедневное задание, чтобы немедленно получить новое.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'prestige',
								name: 'Престиж',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentProgressPrestige1,
									//HelpContentProgressPrestige2,
									//HelpContentProgressPrestige3,
								},
								icon: 'Progress_Prestige',
								data: [
									{
										text: '<span>Престиж – это показатель твоего опыта игры, который останется с тобой на протяжении всех игровых раундов, в которых ты примешь участие. Ты можешь заработать престиж каждую неделю, выполняя определенные задания или попав в топ-10 недельного рейтинга. Обзор престижа можно открыть, щелкнув по имени персонажа в верхнем правом углу экрана.</span>',
									},
									{
										text: '<span>После окончания игрового раунда ты получишь бонус к общему количеству недельного престижа в зависимости от места в отдельных рейтингах. Высокий рейтинг твоего королевства по очкам победы даст тебе дополнительный бонус (если ты был в составе этого королевства минимум 21 дней к моменту окончания игрового раунда).</span>',
									},
									{
										text: '<span>После прохождения определенного рубежа уровень твоего престижа повысится, что выразится в получении звезды под твоим персонажем. Когда ты соберешь три звезды одного типа и достигнешь нового уровня, ты получишь звезду нового типа.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
						],
					},
				],
			},
			{
				apiname: 'military-and-diplomacy',
				type: 'view',
				name: 'Войска и дипломатия',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'attack-types',
								name: 'Типы нападений',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentMilitaryAndDiplomacyAttackTypes1,
									//HelpContentMilitaryAndDiplomacyAttackTypes2,
									//HelpContentMilitaryAndDiplomacyAttackTypes3,
									//HelpContentMilitaryAndDiplomacyAttackTypes4,
								},
								icon: 'MilitaryAndDiplomacy_AttackTypes',
								data: [
									{
										text: '<span>Атакуя NPC-персонажа или другого игрока, ты можешь выбрать различные типы нападений.</span>',
									},
									{
										text: '<span>Основная цель набега - это кража ресурсов. Битва не будет длиться до полного уничтожения одной из сторон.</span>',
									},
									{
										text: '<span>Если же ты желаешь полностью уничтожить противника, следует выбирать атаку, а не набег. С другой стороны, в этом случае ты также рискуешь потерять все свои войска, если ты проиграешь бой.</span>',
									},
									{
										text: '<span>Для осады требуется отряд по меньшей мере в 1000 воинов и хотя бы один таран. Передвижение в два раза медленнее, но сила на 33 % больше.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000083384',
							},
							{
								apiname: 'support-other-players',
								name: 'Подкрепление',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentMilitaryAndDiplomacySupportOtherPlayers1,
									//HelpContentMilitaryAndDiplomacySupportOtherPlayers2,
									//HelpContentMilitaryAndDiplomacySupportOtherPlayers3,
									//HelpContentMilitaryAndDiplomacySupportOtherPlayers4,
								},
								icon: 'MilitaryAndDiplomacy_SupportOtherPlayers',
								data: [
									{
										text: '<span>Атаки на игроков твоего королевства видны на карте (красные стрелки). Посмотреть список входящих атак можно, нажав на символ скрещенных мечей в левой части экрана.</span>',
									},
									{
										text: '<span>Ты можешь поддержать союзных игроков в бою. Нажми на деревню и выбери "Отправить войска". Подкрепление будет выбрано автоматически.</span>',
									},
									{
										text: '<span>Войска в подкреплении потребляют зерно той деревни, где они располагаются. Всегда следи за поставками зерна в эту деревню.</span>',
									},
									{
										text: '<span>Подкрепления можно отозвать назад из обзора деревни на карте или через пункт сбора игрока, которому они принадлежат. Игрок, в чьей деревне находятся подкрепления, также может отправить их назад через пункт сбора.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000083384',
							},
							{
								apiname: 'strategic-map',
								name: 'Стратегическая',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentMilitaryAndDiplomacyStrategicMap1,
									//HelpContentMilitaryAndDiplomacyStrategicMap2,
								},
								icon: 'MilitaryAndDiplomacy_StrategicMap',
								data: [
									{
										text: '<span>Стратегическая карта дает представление о королевствах и их отношениях. Твое собственное королевство отображается синим цветом, королевства, с которыми заключен пакт о ненападении, будут отмечены голубым, а союзные - зеленым цветом.</span>',
									},
									{
										text: '<span>Королевства, с которыми у вас война, обозначены красным, а серые области означают нейтральных игроков. Используй стратегическую карту, чтобы оценить соотношение сил во время войны.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'secret-societies',
								name: 'Тайные сообщества',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentMilitaryAndDiplomacySecretSocieties1,
									//HelpContentMilitaryAndDiplomacySecretSocieties2,
								},
								icon: 'MilitaryAndDiplomacy_SecretSocieties',
								data: [
									{
										text: '<span>Тайные сообщества - это группы игроков, которые собираются вместе, чтобы достичь определенной цели. Ты можешь увидеть активные приглашения в посольстве или даже создать свое собственное сообщество.</span>',
									},
									{
										text: '<span>Сообщества делятся на светлые, созданные для защиты, и темные, главной целью которых являются атаки и набеги.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000083382',
							},
							{
								apiname: 'victory-points',
								name: 'Победные очки',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentMilitaryAndDiplomacyVictoryPoints1,
									//HelpContentMilitaryAndDiplomacyVictoryPoints2,
									//HelpContentMilitaryAndDiplomacyVictoryPoints3,
								},
								icon: 'MilitaryAndDiplomacy_VictoryPoints',
								data: [
									{
										text: '<span>В конце игрового раунда королевство с максимальным количеством победных очков будет объявлено победителем.</span>',
									},
									{
										text: '<span>Каждое сокровище дает одно победное очко в сутки. Победные очки могут быть украдены наряду с сокровищами при атаке с героем на королей и герцогов королевств, располагающиеся выше в рейтинге.</span>',
									},
									{
										text: '<span>Если у твоего королевства в конце раунда имеется деревня с Чудом света, оно получит дополнительный бонус в процентах к победным очкам.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'wonder-of-the-world',
								name: 'Чудо света',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentMilitaryAndDiplomacyWonderOfTheWorld1,
									//HelpContentMilitaryAndDiplomacyWonderOfTheWorld2,
								},
								icon: 'MilitaryAndDiplomacy_WonderOfTheWorld',
								data: [
									{
										text: '<span>Через 111 дней игры на карте открываются Чудеса света. Это означает, что теперь деревню с Чудом света можно захватить и построить Чудо 100 уровня. Игроки королевства, которому принадлежит Чудо света, должны работать вместе над его постройкой и защитой.</span>',
									},
									{
										text: '<span>Когда первое Чудо света будет достроено до 100 уровня, игровой раунд завершается. В зависимости от ранга Чуда света королевства, которые им владеют, получают дополнительный бонус к очкам победы. Затем определяется победитель игрового раунда.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000080454',
							},
							{
								apiname: 'farm-lists',
								name: 'Избранные цели',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentMilitaryAndDiplomacyFarmLists1,
									//HelpContentMilitaryAndDiplomacyFarmLists2,
								},
								icon: 'MilitaryAndDiplomacy_FarmLists',
								data: [
									{
										text: '<span>Если ты регулярно атакуешь определенные деревни, ты можешь использовать список целей (фарм-лист) для автоматической отправки войск. Вкладку со списком целей ты можешь найти в меню пункта сбора. Добавь деревни, которые ты хочешь регулярно атаковать, в список целей. Для каждой цели ты можешь указать войска, которые ты хочешь туда отправить.</span>',
									},
									{
										text: '<span>Изначально твой список целей может содержать не больше 10 деревень. Создание дополнительных списков целей – это функция Travian Plus, которую ты можешь подключить в меню премиум-функций. Открыть премиум-функции можно, нажав на золотую монету в верхнем меню.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'conquering',
								name: 'Захват',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentMilitaryAndDiplomacyConquering1,
									//HelpContentMilitaryAndDiplomacyConquering2,
								},
								icon: 'MilitaryAndDiplomacy_Conquering',
								data: [
									{
										text: '<span>Ты можешь захватывать деревни других игроков. Если тебе сопутствует успех, то ты захватишь все здания и ресурсные поля в деревне. Для того, чтобы завоевать деревню, тебе нужна резиденция или дворец с открытым слотом экспансии и достаточно единиц культуры для развития.</span>',
									},
									{
										text: '<span>В зависимости от твоего народа, ты можешь обучить сенатора, предводителя или вождя, который будет говорить с населением враждебной деревни и понижать его одобрение по отношению к предыдущему владельцу. Тебе необходимо включить этого воина в волну атаки, чтобы понизить одобрение деревни. Кроме того, резиденция или дворец захватываемой деревни должны быть уничтожены. Любые войска, которые находятся не дома в момент захвата деревни, мгновенно исчезнут.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
						],
					},
				],
			},
			{
				apiname: 'premium',
				type: 'view',
				name: 'Премиум',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'card-game',
								name: 'Карточная игра',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentPremiumCardGame1,
									//HelpContentPremiumCardGame2,
									//HelpContentPremiumCardGame3,
								},
								icon: 'Premium_CardGame',
								data: [
									{
										text: '<span>Ты можешь сыграть в карточную игру, чтобы получить ценные призы. Вытяни одну карту вслепую или выбери 4 лучших из 5 открытых карт.</span>',
									},
									{
										text: '<span>Ты получишь одну бесплатную игру в день. Обязательно используй игру, чтобы ты мог получить еще одну на следующий день. Дополнительные карты можно всегда получить за золото.</span>',
									},
									{
										text: '<span>Возможные призы включают в себя бонусы ресурсов, улучшения для снаряжения героя и предметы, которые позволят тебе бесплатно использовать премиум-функции.</span>',
									},
								],
								knowledgeBaseLink: null,
							},
							{
								apiname: 'runtime-features',
								name: 'Временные функции',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentPremiumRuntimeFeatures1,
									//HelpContentPremiumRuntimeFeatures2,
								},
								icon: 'Premium_RuntimeFeatures',
								data: [
									{
										text: '<span>Премиум-функции с фиксированным временем, такие как Travian Plus, позволяют тебе более эффективно играть в Travian. Бонусы к производству ресурсов и зерна помогают активнее развиваться в игре.</span>',
									},
									{
										text: '<span>На начальном этапе игрового раунда доступен стартовый пакет, который включает в себя премиум-функции и некоторые ценные предметы для героя.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000078806',
							},
							{
								apiname: 'instant-features',
								name: 'Моментальные функции',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentPremiumInstantFeatures1,
									//HelpContentPremiumInstantFeatures2,
									//HelpContentPremiumInstantFeatures3,
									//HelpContentPremiumInstantFeatures4,
								},
								icon: 'Premium_InstantFeatures',
								data: [
									{
										text: '<span>В игре есть достаточно большое количество моментальных премиум функций. Например, можно позволить торговцам, путешествующим между твоими деревнями, моментально прибыть в пункт назначения.</span>',
									},
									{
										text: '<span>Ты можешь немедленно завершить строительство зданий (за исключением резиденции, дворца и Чудес света), а также идущие исследования в академии и улучшения в кузнице. Стоимость зависит от оставшегося времени. Если до окончания осталось не более 5 минут, поручение будет завершено за золото бесплатно. Если оставшееся время более 5 минут, но менее 2 часов, это будет стоить 1 единицу золота. Если осталось более двух часов, стоимость завершения равна 2 ед. золота.</span>',
									},
									{
										text: '<span>На рынке ты можешь найти NPC-торговца. Он поможет тебе перераспределить ресурсы свободно в любом соотношении. Например, ты можешь использовать эту функцию, чтобы не допустить перелива ресурсов на складе или чтобы скорее улучшить здание.</span>',
									},
									{
										text: '<span>В карточной игре ты можешь выиграть карты, которые попадут в твой инвентарь в качестве премиум-предметов. Это употребляемые предметы, которые дадут тебе возможность использовать определенную премиум-функцию бесплатно, если у тебя есть хотя бы один предмет нужного типа в инвентаре героя.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000078806',
							},
							{
								apiname: 'invite-friend',
								name: 'Пригласить друга',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentPremiumInviteFriend1,
									//HelpContentPremiumInviteFriend2,
									//HelpContentPremiumInviteFriend3,
								},
								icon: 'Premium_InviteAFriend',
								data: [
									{
										text: '<span>Ты можешь пригласить друзей присоединиться к твоему игровому миру с помощью автоматического письма или отправив им свою персональную реф-ссылку. Эту функцию можно найти на соответствующей вкладке в премиум-меню.</span>',
									},
									{
										text: '<span>Если твой друг активировал учетную запись по твоему приглашению, ты получишь золото в качестве награды, когда он пройдет определенные этапы игры: например, когда им будет основана вторая и третья деревня, когда он приобретет золото или наберет 1000 престижа.</span>',
									},
									{
										text: '<span>Для того, чтобы дать твоим друзьям дополнительный стимул начать игру, они начнут ее с 50 золота и бесплатным активированным Travian Plus на неделю.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000084557',
							},
							{
								apiname: 'master-builder',
								name: 'Очередь',
								content: {
									HelpContentOther,
									HelpContentOther,
									//HelpContentPremiumMasterBuilder1,
									//HelpContentPremiumMasterBuilder2,
								},
								icon: 'Premium_MasterBuilder',
								data: [
									{
										text: '<span>Если хочешь иметь больше мест в очереди строительства, ты можешь открыть дополнительные слоты за золото. Наведи курсор на закрытый слот, чтобы узнать больше.</span>',
									},
									{
										text: '<span>Слоты строительства позволяют закладывать в очередь дополнительные здания для дальнейшего строительства. Так ты можешь заранее планировать постройку, даже если сейчас у тебя недостаточно ресурсов.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000084036',
							},
						],
					},
				],
			},
			{
				apiname: 'account-system',
				type: 'view',
				name: 'Учетная запись',
				groups: [
					{
						apiname: '1',
						name: '',
						elements: [
							{
								apiname: 'accounts-and-avatars',
								name: 'Учетная запись и персонажи',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentAccountSystemAccountsAndAvatars1,
									//HelpContentAccountSystemAccountsAndAvatars2,
									//HelpContentAccountSystemAccountsAndAvatars3,
								},
								icon: 'AccountSystem_AccountAndAvatars',
								data: [
									{
										text: '<span>В Travian Kingdoms у тебя есть единая учетная запись. Ты можешь создать ее только один раз и использовать во всех игровых мирах, в которых ты хочешь играть. Сделать это можно в лобби.</span>',
									},
									{
										text: '<span>Ты можешь иметь только одну учетную запись, но при этом создать несколько персонажей, по одному в каждом игровом мире. Персонаж - это воплощение твоей учетной записи в игровом мире.</span>',
									},
									{
										text: '<span>Инициировать удаление персонажа можно в меню настроек в игровом мире. Удаление длится три дня. При этом, конечно, центральная учетная запись будет сохранена.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/ru-RU/support/solutions/articles/7000070093',
							},
							{
								apiname: 'duals-and-sitters',
								name: 'Дуалы и заместители',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentAccountSystemDualsAndSitters1,
									//HelpContentAccountSystemDualsAndSitters2,
									//HelpContentAccountSystemDualsAndSitters3,
								},
								icon: 'AccountSystem_DualsAndSitters',
								data: [
									{
										text: '<span>В лобби ты имеешь возможность добавить к созданным тобой персонажам дуалов и заместителей. Это другие игроки, которые помогут тебе управлять твоими персонажами. Чтобы выйти в лобби, нажми на кнопку выхода в верхнем правом углу экрана.</span>',
									},
									{
										text: '<span>Дуалы могут повысить общее время онлайна персонажа и обладают такими же правами, что и владелец. Дуал не может играть своим отдельным персонажем в том же игровом мире.</span>',
									},
									{
										text: '<span>Заместителями, с другой стороны, могут быть игроки из одного и того же игрового мира. Они заботятся о твоем персонаже, когда у тебя нет времени на игру. У них ограниченные права.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/en/support/solutions/articles/7000081450',
							},
							{
								apiname: 'gold-transfer',
								name: 'Перевод золота',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentAccountSystemGoldTransfer1,
									//HelpContentAccountSystemGoldTransfer2,
									//HelpContentAccountSystemGoldTransfer3,
								},
								icon: 'AccountSystem_GoldTransfer',
								data: [
									{
										text: '<span>Остатки золота, которое ты приобрел в игровом мире, можно перевести через лобби в другие игровые миры.</span>',
									},
									{
										text: '<span>После удаления персонажа или окончания игрового мира оставшееся золото (но не более того количества, которое было приобретено) будет автоматически перенесено на учетную запись в лобби.</span>',
									},
									{
										text: '<span>После того, как ты нажмешь кнопку "Перевод золота", ты можешь решить, добавить его в игровой мир, где у тебя уже есть персонаж или начать новый.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/ru-RU/support/solutions/articles/7000053898',
							},
							{
								apiname: 'vacation-mode',
								name: 'Режим отпуска',
								content: {
									HelpContentOther,
									HelpContentOther,
									HelpContentOther,
									//HelpContentAccountSystemVacationMode1,
									//HelpContentAccountSystemVacationMode2,
									//HelpContentAccountSystemVacationMode3,
								},
								icon: 'AccountSystem_VacationMode',
								data: [
									{
										text: '<span>Режим отпуска может быть активирован губернаторами. Короли, вице-короли, герцоги или владельцы Чудес света не могут активировать режим отпуска.</span><br><span>Ты можешь активировать режим отпуска для своего персонажа в настройках. Активация режима займет 48 часов (24 для скоростных миров). Находясь в отпуске, нельзя отправлять атаки или осады на других игроков, в деревни Натар, оазисы или на Чудеса света. Но другие игроки могут атаковать твои деревни, если атака прибудет до начала отпуска.</span><br><span>Пока активен режим отпуска (включая фазу активации) нельзя использовать каменный столб для перемещения деревни.</span>',
									},
									{
										text: '<span>Во время отпуска премиум-функции с мгновенным эффектом отключены («Завершить сейчас», NPC-торговец и мгновенная доставка). Производство ресурсов (за исключением зерна) будет остановлено, а обучение войск поставлено на паузу. Войска будут продолжать потреблять пищу во время отпуска и голодать, если производство зерна будет отрицательным.</span><br><span>Ты не сможешь отправлять войска другим игрокам, а они, в свою очередь, не смогут отправлять атаки, набеги, осады и подкрепления в твои деревни. Все подкрепления от других игроков отправлены домой Торговля на рынке также отключена, за исключением использования торговых путей для продажи краденых ценностей.</span>',
									},
									{
										text: '<span>Когда отпуск закончится (или ты прервешь его), скорость производства ресурсов и обучения войск будут удвоены на то же самое время, сколько длился отпуск, чтобы ты смог нагнать других игроков. Если при активации отпуска у тебя были включены бонусы на ресурсы, они будут продлены на соответствующее количество дней.</span><br><span>Ты можешь взять отпуск максимум на 14 дней в каждом игровом мире (7 дней на скоростном) и распределить эти дни по своему усмотрению. Однако, обрати внимание, что ты не можешь повторно активировать режим отпуска, пока скорость производства ресурсов и обучения войск удвоены за предыдущий отпуск.</span>',
									},
								],
								knowledgeBaseLink: 'https://support.kingdoms.com/ru-RU/support/solutions/articles/7000057823',
							},
						],
					},
				],
			},
		];
	}
	
	getContentComponents()
	{
		let components = [];
		for(let section of this.sections){
			for(let group of section.groups){
				for(let element of group.elements){
					for(let [name, comp] of Object.entries(element.content)){
						components[name] = comp;
					}
				}
			}
		}
		return components;
	}
	
	getSection(section){
		if(!section) return {};
		return this.sections.find((el) => el.apiname == section);
	}
	
	getGroup(section, group){
		if(!section || !group) return {};
		return this.getSection(section).groups.find((el) => el.apiname == group);
	}
	
	getElement(section, group, element){
		if(!section || !group || !element) return {};
		return this.getGroup(section, group).elements.find((el) => el.apiname == element);
	}
}

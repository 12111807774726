<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Конюшня</span></span>
									<span class="level">
										(<span><span>Уровень {{gameModel.island.buildings.stabling.level}}</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>В конюшне обучаются все войска кавалерии. С развитием конюшни уменьшается время обучения солдат.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<div class="buildingStatus location27 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="buildingIndexCtrl" class="building buildingType20" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
														<div ng-include="" src="pageTemplate">
															<div class="buildingDetails trainTroops" ng-controller="trainTroopsCtrl">
																<div ng-show="isBuildingBuild()">
																	<div ng-controller="unitsTrainCtrl">
																		<div ng-include="" src="'tpl/building/partials/lists/units.html'">
																			<div class="contentBox gradient unitList">
																				<div class="contentBoxBody">
																					<carousel-unit-train :items="units" @on-active-item="(item) => activeItem = item" :cols="4" />
																					<div v-if="activeItem">
																						<table class="unitInfo transparent">
																							<tbody>
																								<tr>
																									<td>
																										<div class="unitHeader">
																											<i class="unitIcon unitMedium gaul" :class="['unitType'+activeItem.type]" unit-icon="" big="true"></i>
																											<h4 class="unitName">
																												<span><span>{{activeItem.name}}</span></span>
																												<span class="level" v-if="activeItem.unitLevel >= 0">
																													<span><span>&nbsp;Ур.</span></span> {{activeItem.unitLevel}}
																												</span>
																											</h4>
																											<div class="symbol_i_medium_wrapper">
																												<i class="symbol_i_medium_flat_white clickable" clickable="openWindow('help', {'pageId': 'Troops_' + activeItem.unitType})" content="Дополнительная информация" v-tippy="{placement: 'top'}" tooltip-translate="Units.Tooltip.UnitInfo" tooltip-placement="above"></i>
																											</div>
																										</div>
																										<div class="description"><span>{{activeItem.description}}</span></div>
																									</td>
																									<td>
																										<table class="unitAttributes transparent">
																											<tbody>
																												<tr>
																													<td>
																														<i class="unit_capacity_small_flat_black" content="Могут переносить" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CarryingCapacity.Label"></i>
																													</td>
																													<td>{{activeItem.capacity}}</td>
																												</tr>
																												<tr>
																													<td><i class="unit_speed_small_flat_black" content="Скорость" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.Speed.Label"></i></td>
																													<td>{{activeItem.speed}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_consumption_small_flat_black" content="Потребление" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CropConsumption.Label"></i>
																													</td>
																													<td>{{activeItem.cropConsumption}}</td>
																												</tr>
																											</tbody>
																										</table>
																									</td>
																									<td>
																										<table class="unitAttributes transparent" ng-if="building.data.buildingType != buildingTypes.BLACKSMITH">
																											<tbody>
																												<tr>
																													<td><i class="movement_attack_small_flat_black" content="Атака" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Attack"></i>
																													</td>
																													<td>{{activeItem.attack}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_defenseInfantry_small_flat_black" content="Защита от пехоты" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseInfantry"></i>
																													</td>
																													<td>{{activeItem.defenseInfantry}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_defenseCavalry_small_flat_black" content="Защита от кавалерии" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseCavalry"></i>
																													</td>
																													<td>{{activeItem.defenseCavalry}}</td>
																												</tr>
																											</tbody>
																										</table>
																									</td>
																								</tr>
																							</tbody>
																						</table>
																						<div class="horizontalLine"></div>
																						<display-resources v-if="activeItem" class="costsFooter" :resources="allCosts" :hide-zero="true" available="storage" :consumption="allConsumption" :time="activeItem.duration * activeItem.value" />
																						<div class="sliderRow">
																							<div class="sliderArrow"></div>
																							<range-slider v-if="activeItem.maxAvailable <= 0" :min="1" :max="1" v-model.number="activeItem.value" disabled style="width: 87%;" />
																							<range-slider v-if="activeItem.maxAvailable > 0" :min="1" :max="activeItem.maxAvailable" v-model.number="activeItem.value" style="width: 87%;" />
																							<npc-trader-button type="unitTrain" :costs="allCosts" :current-max="0" :current-limit="0" />
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		
																		<!-- unit not researched -->
																		<button :class="{'disabled': !activeItem || activeItem.disabled || activeItem.maxAvailable == 0}" class="animate footerButton clickable" clickable="startTraining(activeItem)" content="Недостаточно ресурсов" v-tippy="{placement: 'top'}" play-on-click="12">
																			<div class="content">
																				<span class="innerText">
																					<span><span>Обучить</span></span>
																				</span>
																				<!-- ngIf: price > 0 || forceGoldUsage -->
																				<!-- ngIf: price == -1 && !forceGoldUsage -->
																				<div class="animationOverlay"></div>
																				<i class="checkmark action_check_small_flat_white"></i>
																				<i class="checkmark action_check_small_flat_black"></i>
																				<i class="checkmark action_check_small_flat_red"></i>
																			</div>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import CarouselUnitTrain from '@/components/elements/CarouselUnitTrain';
import DisplayResources from '@/components/elements/DisplayResources';
import NpcTraderButton from '@/components/elements/NpcTraderButton';
import RangeSlider from '@/components/elements/RangeSlider';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Конюшня | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
		
		units: [
			{
				type: 3,
				name: 'Следопыт',
				description: 'Следопыты — разведывательные войска Галлов. Быстро, но осторожно, они выведывают секреты о ресурсах, войсках и защитных сооружениях врагов.',
				disabled: false,
				queue: {
					key: 0,
					count: 0,
					pause: 0,
				},
				costs: [
					140,
					110,
					20,
					0,
				],
				value: 1,
				unitLevel: 3,
				capacity: 2,
				speed: 17,
				cropConsumption: 2,
				attack: 1.8,
				defenseInfantry: 22.2,
				defenseCavalry: 12,
				duration: 527,
				time: 1723643172,
				maxAvailable: 10,
			},
			{
				type: 4,
				name: 'Тевтатский гром',
				description: 'Тевтатские громы — атакующая конница галлов — обладают исключительной скоростью и могут переносить большое количество ресурсов. В защите они довольно слабы.',
				disabled: false,
				queue: {
					key: 0,
					count: 0,
					pause: 0,
				},
				costs: [
					200,
					280,
					130,
					0,
				],
				value: 1,
				unitLevel: 5,
				capacity: 75,
				speed: 19,
				cropConsumption: 2,
				attack: 96.2,
				defenseInfantry: 28.9,
				defenseCavalry: 44.5,
				duration: 961,
				time: 1723643172,
				maxAvailable: 10,
			},
			{
				type: 5,
				name: 'Друид-всадник',
				description: 'Друиды-всадники - это защитная конница. Они особенно сильны против пехоты.',
				disabled: false,
				queue: {
					key: 0,
					count: 0,
					pause: 0,
				},
				costs: [
					300,
					270,
					190,
					0,
				],
				value: 1,
				unitLevel: 6,
				capacity: 35,
				speed: 13,
				cropConsumption: 2,
				attack: 50.6,
				defenseInfantry: 123.6,
				defenseCavalry: 61,
				duration: 992,
				time: 1723643172,
				maxAvailable: 10,
			},
			{
				type: 6,
				name: 'Эдуйская конница',
				description: 'Эдуйская конница хорошо проявляет себя в защите против кавалерии, а также обладает значительной атакующей силой. Однако, содержание и обучение этого войска обходится дорого.',
				disabled: true,
				queue: {
					key: 0,
					count: 0,
					pause: 0,
				},
				costs: [
					300,
					380,
					440,
					0,
				],
				value: 1,
				unitLevel: 4,
				capacity: 65,
				speed: 13,
				cropConsumption: 3,
				attack: 145.7,
				defenseInfantry: 64,
				defenseCavalry: 171.2,
				duration: 1209,
				time: 1723643172,
				maxAvailable: 10,
			},
		],
		
		activeItem: null,
	}),
	computed: {
		allCosts(){
			let unitsCosts = [];
			if(this.activeItem){
				for(let [index, value] of Object.entries(this.activeItem.costs)){
					unitsCosts[index] = value * this.activeItem.value;
				}
			}
			return unitsCosts;
		},
		allConsumption(){
			if(this.activeItem){
				return this.activeItem.cropConsumption * this.activeItem.value;
			}
			return 0;
		},
	},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {
		CarouselUnitTrain,
		DisplayResources,
		NpcTraderButton,
		RangeSlider,
	},
}
</script>
<template>
	<div ng-if="overlayController" class="windowOverlay" id="townFounding" ng-controller="overlayController">
		<div class="darkener clickable" clickable="closeOverlay()"></div>
		<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="622" style="max-height: none;">
			<div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
				<div class="inWindowPopupHeader">
					<h4 translate="" options="MainBuilding.TownFounding" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Основать город</span></h4>
					<h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4>
					<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
				</div>
				<div class="inWindowPopupContent">
					<div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']">
						<div>
							<p class="townFoundingConfirmationDescription">
								<i class="symbol_warning_tiny_flat_red"></i>
								<span translate=""><span>Это действие необратимо! Ты не сможешь вернуть город в состояние деревни. </span><br><span>Желаешь продолжить?</span></span>
							</p>
						</div>
						<div class="buttonFooter">
							<button clickable="upgradeToTown();" class="clickable">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Принять</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->

									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
							<button clickable="closeOverlay();" class="cancel clickable">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Отмена</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->

									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
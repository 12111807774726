<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Академия</span></span>
									<span class="level">
										(<span><span>Уровень {{gameModel.island.buildings.academy.level}}</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>Новые типы войск исследуются в Академии. С развитием Академии тебе становятся доступными для исследования более продвинутые и мощные войска.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<div class="buildingStatus location30 disabled busy" ng-class="{disabled: upgradeButtonClass == 'notAtAll' || upgradeButtonClass == 'maxLevel', busy: busy, queued: finished < 0, waiting: waiting, master: finished > 0 &amp;&amp; !busy, optionMenu: upgradeButtonClass == 'notNow' || premiumOptionMenu.locationId == building.data.locationId}" ng-on-pointer-over="premiumOptionMenu.locationId != building.data.locationId &amp;&amp; upgradeButtonClass == 'notNow' &amp;&amp; !isRubble ? upgradeBuilding() : ''" :content="tooltips[1]" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/building/partials/upgradeCostsTooltip.html" tooltip-class="buildingTooltip" tooltip-placement="above" tooltip-offset="10" tooltip-hide="false" tooltip-hide-on-click="false">
									<div class="buildingBubble clickable disabled" ng-class="{disabled: upgradeButtonDisabled === true || premiumOptionMenu.locationId == building.data.locationId, disabledHover: upgradeButtonClass == 'notNow'}">
										<div class="colorLayer notAtAll disabled" ng-class="{disabled: upgradeButtonClass == 'notAtAll', dismantle: isRubble, enoughRes: !isRubble &amp;&amp; enoughRes &amp;&amp; freeMasterSlots > 0}">
											<div class="labelLayer">
												<!-- ngIf: !isRubble --><span ng-if="!isRubble" class="buildingLevel">19</span><!-- end ngIf: !isRubble -->
												<!-- ngIf: isRubble -->
												<!-- ngIf: upgradeButtonClass == 'maxLevel' -->
											</div>
										</div>
									</div>
									<!-- ngIf: premiumOptionMenu.locationId == building.data.locationId -->
									<div class="progressbar clickable" progressbar="" ng-if="finished > 0" clickable="openBuilding()" finish-time="1723594274" show-countdown="true" duration="35700">
										<!-- ngIf: ::labelIcon -->
										<div class="progress">
											<!-- ngIf: steps -->
											<div class="bar positive perc " style="z-index: 2; width: 8%;"></div>
											<div class="bar additionalBar additionalPerc"></div>
											<div class="marker">
												<div class="markerInner"></div>
											</div>
											<div ng-if="::showCountdown" class="countdown" countdown="1723594274">09:07:16</div>
											<!-- ngIf: ::label -->
											<!-- ngIf: ::percTooltip -->
										</div>
									</div>
									<!-- ngIf: finished < 0 -->
									<!-- ngIf: masterBuilderCount > 0 -->
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="buildingIndexCtrl" class="building buildingType22" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
														<div ng-include="" src="pageTemplate">
															<div class="buildingDetails academy" ng-controller="academyCtrl">
																<div ng-show="isBuildingBuild()">
																	<div ng-controller="unitsResearchCtrl">
																		<div ng-include="" src="'tpl/building/partials/lists/units.html'">
																			<div class="contentBox gradient unitList">
																				<div class="contentBoxBody">
																					<carousel-unit-research :items="units" @on-active-item="(item) => activeItem = item" :cols="4" />
																					<div v-if="activeItem">
																						<table class="unitInfo transparent">
																							<tbody>
																								<tr>
																									<td>
																										<div class="unitHeader">
																											<i class="unitIcon unitMedium gaul" :class="['unitType'+activeItem.type]" unit-icon="" big="true"></i>
																											<h4 class="unitName">
																												<span><span>{{activeItem.name}}</span></span>
																											</h4>
																											<div class="symbol_i_medium_wrapper">
																												<i class="symbol_i_medium_flat_white clickable" clickable="openWindow('help', {'pageId': 'Troops_' + activeItem.unitType})" content="Дополнительная информация" v-tippy="{placement: 'top'}" tooltip-translate="Units.Tooltip.UnitInfo" tooltip-placement="above"></i>
																											</div>
																										</div>
																										<div class="description"><span>{{activeItem.description}}</span></div>
																									</td>
																									<td>
																										<table class="unitAttributes transparent">
																											<tbody>
																												<tr>
																													<td>
																														<i class="unit_capacity_small_flat_black" content="Могут переносить" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CarryingCapacity.Label"></i>
																													</td>
																													<td>{{activeItem.capacity}}</td>
																												</tr>
																												<tr>
																													<td><i class="unit_speed_small_flat_black" content="Скорость" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.Speed.Label"></i></td>
																													<td>{{activeItem.speed}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_consumption_small_flat_black" content="Потребление" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CropConsumption.Label"></i>
																													</td>
																													<td>{{activeItem.cropConsumption}}</td>
																												</tr>
																											</tbody>
																										</table>
																									</td>
																									<td ng-if="building.data.buildingType != buildingTypes.HEALING_TENT">
																										<table class="unitAttributes transparent" ng-if="building.data.buildingType != buildingTypes.BLACKSMITH">
																											<tbody>
																												<tr>
																													<td><i class="movement_attack_small_flat_black" content="Атака" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Attack"></i>
																													</td>
																													<td>{{activeItem.attack}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_defenseInfantry_small_flat_black" content="Защита от пехоты" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseInfantry"></i>
																													</td>
																													<td>{{activeItem.defenceInfantry}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_defenseCavalry_small_flat_black" content="Защита от кавалерии" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseCavalry"></i>
																													</td>
																													<td>{{activeItem.defenceCavalry}}</td>
																												</tr>
																											</tbody>
																										</table>
																									</td>
																								</tr>
																							</tbody>
																						</table>
																						<div class="requiredBuildings" v-if="activeItem.required.length > 0">
																							<h4><span>Требования</span></h4>
																							<span class="buildingInfo" content="Текущий уровень здания: 1" v-tippy="{placement: 'bottom'}" tooltip-translate="Tooltip.CurrentLevel" v-for="(i, building) in activeItem.required">
																								<a clickable="openWindow('help', {'pageId': 'Buildings_' + building.buildingType})" class="clickable">
																									<span>{{building.name}}</span>
																								</a>
																								<span><span>&nbsp;Уровень {{building.level}}</span></span>
																								<span v-if="!building.valid" class="neededLevels">(+4)</span>
																							</span>
																						</div>
																						<div class="horizontalLine" v-if="!activeItem.required || activeItem.required.length == 0"></div>
																						<display-resources v-if="activeItem" class="costsFooter" :resources="activeItem.costs" :hide-zero="true" available="storage" :consumption="0" :time="activeItem.duration" />
																					</div>
																					<span v-if="!activeItem" class="allResearched">
																						<span>В этой деревне исследованы все виды войск!</span>
																					</span>
																				</div>
																			</div>
																		</div>
																		<button v-class="{'disabled': researchQueueFull || !activeItem || !activeItem.canResearch || !gameModel.island.storage.hasEnoughResources(activeItem.costs)}" class="animate footerButton clickable" clickable="research(activeItem)" content="Для этого исследования выполнены не все требования" v-tippy="{placement: 'top'}" tooltip-translate-switch="{
																			'Academy.NoAdditionalResearch': false,
																			'Academy.NothingSelected': false,
																			'Academy.RequirementsNotFullfilled': true,
																			'Error.NotEnoughRes': true
																		}">
																			<div class="content">
																				<span class="innerText">
																					<span><span>Исследование</span></span>
																				</span>
																				<div class="animationOverlay"></div>
																				<i class="checkmark action_check_small_flat_white"></i>
																				<i class="checkmark action_check_small_flat_black"></i>
																				<i class="checkmark action_check_small_flat_red"></i>
																			</div>
																		</button>
																		<npc-trader-button class="footerButton" type="unitResearch" :costs="activeItem.costs" v-if="activeItem" />
																		<div class="iconButton finishNow free disabled" premium-feature="finishNow" premium-callback-param="finishNowBuildingType:22" confirm-gold-usage="true" content="Нечего завершать" v-tippy="{placement: 'top'}" tooltip-url="tpl/npcTrader/finishNowTooltip.html">
																			<i class="feature_instantCompletion_small_flat_black"></i>
																		</div>
																		<div class="iconButton finishNow premium" premium-feature="finishNow" premium-callback-param="finishNowBuildingType:22" confirm-gold-usage="true" content="<div class='finishNowTooltip'>
																			<div>
																				<h3>
																					<span>Моментально завершить следующие поручения</span>
																					<span class='price'><i class='unit_gold_small_illu'></i>1:</span>
																				</h3>
																				<div class='horizontalLine'></div>
																				<div>
																					<span>Следопыт</span>
																				</div>
																			</div>
																		</div>" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/npcTrader/finishNowTooltip.html">
																			<i class="feature_instantCompletion_small_flat_black"></i>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import CarouselUnitResearch from '@/components/elements/CarouselUnitResearch';
import DisplayResources from '@/components/elements/DisplayResources';
import NpcTraderButton from '@/components/elements/NpcTraderButton';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Академия | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
		
		units: [
			{
				type: 1,
				name: 'Фаланга',
				description: 'xxx',
				disabled: false,
				canResearch: true,
				queue: true,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 86400,
				time: 1723643172,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 2,
				name: 'Мечник',
				description: 'xxx',
				disabled: false,
				canResearch: true,
				queue: false,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 86400,
				time: 1723643172,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 3,
				name: 'Следопыт',
				description: 'Следопыты — разведывательные войска Галлов. Быстро, но осторожно, они выведывают секреты о ресурсах, войсках и защитных сооружениях врагов.',
				disabled: false,
				canResearch: true,
				queue: false,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 86400,
				time: 1723643172,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 4,
				name: 'Тевтатский гром',
				description: 'xxx',
				disabled: false,
				canResearch: true,
				queue: false,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 86400,
				time: 1723643172,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 5,
				name: 'Друид-всадник',
				description: 'xxx',
				disabled: false,
				canResearch: false,
				queue: false,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 86400,
				time: 1723643172,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 6,
				name: 'Эдуйская конница',
				description: 'xxx',
				disabled: false,
				canResearch: false,
				queue: false,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 86400,
				time: 1723643172,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 9,
				name: 'Предводитель',
				description: 'xxx',
				disabled: false,
				canResearch: false,
				queue: false,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 86400,
				time: 1723643172,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
		],
		
		activeItem: null,
		researchQueueFull: true,
		
		tooltips: {
			1: `<div class="upgradeCostsTooltip" ng-controller="buildingUpgradeTooltipCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoTop"><div class="upgradeInfo notAtAll maxLevel" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
	<div class="headline">
		<h3 class="building" options="22"><span>Академия</span></h3>
	</div>

	<div class="queuedSlidesContainer">
		<!-- ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide1 active" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide2 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide3 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide4 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide5 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 -->
	</div>

	<div class="content" ng-class="{boxMaxLevel: building.data.lvl >= building.data.lvlMax, boxDeconstruction: upgradeButtonClass == 'demolish'}">
		<div class="contentTopArea">
			<!-- ngIf: building.data.currentEffect.values[0] -->
			<!-- ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' --><table class="buildingNextEffects" ng-if="building.data.lvl < building.data.lvlMax &amp;&amp; upgradeButtonClass != 'demolish'">
				<tbody>
					<tr>
						<td>
							<div class="buildingStatus">
								<div class="buildingBubble disabled" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notAtAll disabled" ng-class="{disabled: upgradeButtonClass == 'notAtAll', enoughRes: enoughRes &amp;&amp; freeMasterSlots > 0}">
										<div class="labelLayer">
											<span class="buildingLevel">19</span>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td class="borderLeft">
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>&lrm;&#x202D;58
							</span>
						</td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl - (currentBuildingLevel < building.data.lvlMax ? 0 : 1)] track by $index -->
						</td>
						<td class="emptySpace"></td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl + (currentBuildingLevel < building.data.lvlMax ? 1 : 0)] track by $index -->
						</td>
						<td>
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>62
							</span>
						</td>
						<td class="borderLeft">
							<div class="buildingStatus">
								<div class="buildingBubble disabled" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer maxLevel disabled" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
										<div class="labelLayer">
											<span class="buildingLevel">20</span>
											<!-- ngIf: currentBuildingLevel + 1 >= building.data.lvlMax --><i ng-if="currentBuildingLevel + 1 >= building.data.lvlMax" class="symbol_star_tiny_illu ng-scope"></i><!-- end ngIf: currentBuildingLevel + 1 >= building.data.lvlMax -->
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
			<!-- ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
		</div>

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' --><div class="description maxLevel" ng-if="currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish'" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
			<!-- ngIf: upgradeButtonClass != 'demolish' && building.data.lvl >= building.data.lvlMax -->
			<!-- ngIf: upgradeButtonClass != 'demolish' && building.data.lvl < building.data.lvlMax --><span ng-if="upgradeButtonClass != 'demolish' &amp;&amp; building.data.lvl < building.data.lvlMax"><span>Максимальный уровень здания уже строится.</span></span><!-- end ngIf: upgradeButtonClass != 'demolish' && building.data.lvl < building.data.lvlMax -->
			<!-- ngIf: upgradeButtonClass == 'demolish' -->
		</div><!-- end ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
	</div>
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoBottom"><div class="upgradeInfo notAtAll maxLevel" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
</div>`,
		},
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {
		CarouselUnitResearch,
		DisplayResources,
		NpcTraderButton,
	},
}
</script>
<!--
<switch name-false="No" name-true="Yes" v-model="value" />
-->

<template>
	<label class="clickInputLabel">
		<span class="switchLabel" v-if="nameFalse != ''" :class="{'selected': !model}">{{nameFalse}}</span>
		<div class="clickInputWrapper switchBox" :class="{'switchedOn': model}">
			<div class="switchButtonWrapper">
				<input type="checkbox" class="switch" :disabled="disabled" v-model="model" />
				<i :class="{'action_check_tiny_flat_black': model, 'action_cancel_tiny_flat_black': !model}"></i>
			</div>
		</div>
		<span class="switchLabel" v-if="nameTrue != ''" :class="{'selected': model}">{{nameTrue}}</span>
	</label>
</template>

<style lang="css" scoped>
	
</style>

<script >
export default {
	props: {
		modelValue: {
			type: Boolean,
		},
		nameFalse: {
			type: String,
			default: '',
		},
		nameTrue: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
		},
	},
	emits: ['update:modelValue'],
	computed: {
		model: {
			get(){
				return this.modelValue;
			},
			set(value){
				this.$emit('update:modelValue', value);
			},
		},
	},
}
</script>
<template>
	<div ng-if="overlayController" class="windowOverlay" id="useResourceBonus" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="639"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Hero.UseResourceBonus" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Получить бонус</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="useItem useResourceItem">
	<!-- ngIf: screenState == 'UseItem' || bonusValue >= 5 || item.data.amount <= 1 --><div class="contentBox useItem" ng-if="screenState == 'UseItem' || bonusValue >= 5 || item.data.amount <= 1">
		<div class="contentBoxHeader headerWithArrowDown">
				<span translate=""><span>Использовать бонус ресурсов</span></span>
		</div>
		<div class="contentBoxBody">
			<div class="itemContainer">
				<hero-item-container item="item" hide-amount="true" hide-item-states="true"><div class="entityBox item item_130 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:58545,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:false,itemType:130" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_resourceChest3_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
				<div class="verticalLine"></div>
				<div translate="" class="description" data="amount:3, currentVillageName:Брест"><span>Когда ты используешь этот бонус, ты получишь &#x202D;&#x202D;3&#x202C;%&#x202C; дневного производства ресурсов. Ресурсы будут немедленно доставлены в деревню, которая сейчас открыта (Брест). Бонус можно получить один раз каждые 24 часа.</span></div>
			</div>


			<div class="horizontalLine"></div>
			<h6 class="headerTrapezoidal">
				<span translate="" class="content"><span>Ты получишь:</span></span>
			</h6>
			<div class="rewardContainer">
				<div class="selectedItemContainer">
					<hero-item-container class="selectedItem" hide-amount="true" hide-item-states="true" item="item"><div class="entityBox item item_130 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
	 				noClick: !itemObject.filled || isDisabled() || !clickHandler,
				 	equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
				 	highlight: highlighted(),
				 	premiumItem: itemObject.data.premiumItem,
				 	empty: !itemObject.filled
				 }" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:58545,notUsableItem:false,notUsableReason:false,auctionItem:false,nextUpgradeLevel:false,itemType:130" tooltip-show="true">

	<i class="dragMarker ng-hide" ng-show="dragObject"></i>
	<img class="heroItem_resourceChest3_large_illu male" src="layout/images/x.gif" data-cmp-info="10">

	<!-- ngIf: isDisabled() -->
	<!-- ngIf: !hideStates -->

	<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
		<div class="amount">
			<i class="upgrade_upgrade_tiny_flat_black"></i>
		</div>
	</div>
	<!-- ngIf: !hideAmountContainer -->

</div></hero-item-container>
					<!-- ngIf: bonusValue < 5 && item.data.amount > 1 --><i class="arrowDown" ng-if="bonusValue < 5 &amp;&amp; item.data.amount > 1"></i><!-- end ngIf: bonusValue < 5 && item.data.amount > 1 -->
				</div>
				<i class="equalCircled"></i>
				<display-resources resources="resources" hide-zero="true" signed="true" image-size="large" check-storage="true"><div class="costs max4digits">
	<!-- ngIf: !checkStorage -->
	<!-- ngIf: checkStorage --><span ng-if="checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughStorage(index), enough: enoughStorage(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_large_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;+&#x202D;1650&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughStorage(index), enough: enoughStorage(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_large_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;+&#x202D;1765&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughStorage(index), enough: enoughStorage(index) &amp;&amp; colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_large_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;+&#x202D;1434&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_large_flat_black"></i></div>
</div></display-resources>
			</div>
			<div class="horizontalLine"></div>
			<div class="buttonWrapper">
				<!-- ngIf: bonusValue < 5 && item.data.amount > 1 --><button clickable="changeScreenStateToMerge()" class="changeViewButton clickable" ng-if="bonusValue < 5 &amp;&amp; item.data.amount > 1"><div class="content">
	<span ng-transclude="" class="innerText">
					<span translate="" data="amount:4"><span>Повысить до &#x202D;&#x202D;4&#x202C;%&#x202C;</span></span>
				</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: bonusValue < 5 && item.data.amount > 1 -->
				<!-- ngIf: bonusValue < 5 && item.data.amount > 1 --><span class="separator" ng-if="bonusValue < 5 &amp;&amp; item.data.amount > 1"><span translate="" class="text"><span>или</span></span> <i class="arrowDirectionTo"></i></span><!-- end ngIf: bonusValue < 5 && item.data.amount > 1 -->
				<button class="useItemButton clickable" clickable="useItem()" ng-class="{disabled: item.data.usedPerDay >= item.data.maxPerDayWithoutLock}" tooltip="" tooltip-translate="Hero.UseResourceBonus.UseItem.Button.UseBonus.Tooltip" tooltip-data="time: 1723759201" tooltip-hide="true"><div class="content">
	<span ng-transclude="" class="innerText">
					<span translate=""><span>Применить бонус</span></span>
				</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
			</div>
		</div>
	</div><!-- end ngIf: screenState == 'UseItem' || bonusValue >= 5 || item.data.amount <= 1 -->

	<!-- ngIf: screenState == 'MergeItem' && bonusValue < 5 && item.data.amount > 1 -->
	<div class="buttonContainer">
		<!-- ngIf: screenState == 'MergeItem' -->
		<button clickable="closeOverlay()" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Отмена</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
	</div>
</div>
</div>
	</div>
</div></div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
<template>
	<div class="reportHeader" src="'tpl/report/header.html'">
		<div class="headerBox">
			<div class="reportHeaderBg" :class="['reportTypes_reportType'+report.notificationType, 'success'+report.successType, {'relocation3': (report.notificationType == ReportsModel.REPORT_RELOCATION)}]"></div>
			<div class="playerBox actionFrom" v-if="report.notificationType != ReportsModel.REPORT_RELOCATION">
				<avatar-image :player-id="report.sourcePlayerId"></avatar-image>
				<div class="playerLabel">
					<div class="playerAndKingdom">
						<a ng-class="{'disabled': playerId <= 2 || nolink || deleted}" class="playerLink truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted">{{report.sourcePlayerName}}</a>
						<span v-if="report.sourceKingdomId && report.sourceKingdomId > 0">({{report.sourceKingdomTag}})</span>
					</div>
					<div class="fromVillage">
						<span translate=""><span>из</span></span>
						<a ng-class="{'disabled': villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)">{{report.sourceName}}</a>
						<i class="reportIcon" :class="['reportTypes_reportIcon'+report.notificationType+'_flat']"></i>
						<!--
						<i class="reportIcon reportTypes_reportIcon21_failed_flat"></i>
						<i class="reportIcon reportTypes_reportIcon1_flat"></i>
						<i class="reportIcon reportTypes_reportIcon2_flat"></i>
						<i class="reportIcon reportTypes_reportIcon3_flat"></i>
						<i class="reportIcon reportTypes_reportIcon8_flat"></i>
						<i class="reportIcon reportTypes_reportIcon12_flat"></i>
						<i class="reportIcon reportTypes_reportIcon15_flat"></i>
						<i class="reportIcon reportTypes_reportIcon16_flat"></i>
						<i class="reportIcon reportTypes_reportIcon17_flat"></i>
						<i class="reportIcon reportTypes_reportIcon20_flat"></i>
						<i class="reportIcon reportTypes_reportIcon115_flat"></i>
						-->
					</div>
					<div class="prestigeStars" v-if="report.sourcePlayer && gameModel.config.balancing.features.prestige">
						<div class="innerStars" stars="report.sourcePlayer.data.stars" size="tiny">
							<div ng-if="!badge">
								<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
								<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
								<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
							</div>
							<i ng-if="badge" class="prestigeBadge prestige_silverBadge_tiny_illu"></i>
						</div>
					</div>
					<div class="prestigeStarsTooltip clickable" tooltip="" tooltip-translate-switch="{
						'Prestige.Stars.Tooltip.Own': true,
						'Prestige.Stars.Tooltip.Own.Max': false,
					}" v-if="report.sourcePlayerId == gameModel.player.id && gameModel.config.balancing.features.prestige" clickable="openWindow('profile', {'playerId': gameModel.player.id, 'profileTab': 'prestige'})" tooltip-data="prestige:372,nextLevelPrestige:400"></div>
					<div v-if="report.sourcePlayerId != gameModel.player.id && gameModel.config.balancing.features.prestige" class="prestigeStarsTooltip" tooltip="" tooltip-translate="Prestige.Stars.Tooltip.Other"></div>
				</div>
				<!-- ngIf: header.ownRole == 'attacker' && header.shareMessage -->
			</div>
			<i class="resultIcon" :class="{
				'result_capturedAnimals_huge_illu': [20].includes(report.notificationType),
				'result_adventureWon_huge_illu': [21].includes(report.notificationType) && report.won,
				'result_adventureLost_huge_illu': [21].includes(report.notificationType) && !report.won,
				'result_attackerWonWithoutLosses_huge_illu': [1].includes(report.notificationType),
				'result_attackerWonWithLosses_huge_illu': [2].includes(report.notificationType),
				'result_attackerLost_huge_illu': [3].includes(report.notificationType),
				'result_defenderWonWithoutLosses_huge_illu': [4].includes(report.notificationType),
				'result_defenderWonWithLosses_huge_illu': [5].includes(report.notificationType),
				'result_defenderLostWithoutLosses_huge_illu': [7].includes(report.notificationType),
				'result_defenderLostWithLosses_huge_illu': [6].includes(report.notificationType),
				'result_spyAttackerWonWithoutLosses_huge_illu': [15].includes(report.notificationType),
				'result_spyAttackerWonWithLosses_huge_illu': [16].includes(report.notificationType),
				'result_spyAttackerLost_huge_illu': [17].includes(report.notificationType),
				'result_spyDefenderWon_huge_illu': [18].includes(report.notificationType),
				'result_spyDefenderLost_huge_illu': [19].includes(report.notificationType),
				'result_support_huge_illu': [8].includes(report.notificationType),
				'result_trade_huge_illu': [10, 11, 12, 13, 14].includes(report.notificationType),
			}" ng-if="resultIcon"></i>
			<div class="playerBox actionTo" v-if="report.notificationType != ReportsModel.REPORT_RELOCATION">
				<avatar-image :player-id="report.destPlayerId" v-if="report.notificationType != ReportsModel.REPORT_ADVENTURE"></avatar-image>
				<div v-if="report.notificationType == ReportsModel.REPORT_FARMLIST_RAID" class="playerLabel">
					<span translate=""><span>Избранные цели</span></span>
					<div class="fromVillage">
						<a clickable="openWindow('building', {'location': 32, 'tab': 'FarmList', 'listId': 325 });" class="clickable">Стартовый лист</a>
					</div>
				</div>
				<div v-if="report.notificationType != ReportsModel.REPORT_FARMLIST_RAID && report.notificationType != ReportsModel.REPORT_ADVENTURE && report.notificationType != ReportsModel.REPORT_RELOCATION" class="playerLabel">
					<div class="playerAndKingdom">
						<a ng-class="{'disabled': playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="Игрок удален" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted">{{report.destPlayerName}}</a>
						<span v-if="report.destKingdomId && report.destKingdomId > 0">({{report.destKingdomTag}})</span>
					</div>
					<div class="fromVillage">
						<span translate=""><span>из</span></span>
						<a ng-class="{'disabled': villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537083881}, null, true)">{{report.destName}}</a>
						<i ng-if="report.defenderReportType" class="defenderReportIcon reportTypes_reportIcon4_flat" ng-class="{unknown: troopsDataDefender[0].unknown}"></i>
						<i ng-if="report.defenderReportType" class="defenderReportIcon reportTypes_reportIcon5_flat" ng-class="{unknown: troopsDataDefender[0].unknown}"></i>
						<i ng-if="report.defenderReportType" class="defenderReportIcon reportTypes_reportIcon7_flat" ng-class="{unknown: troopsDataDefender[0].unknown}"></i>
						<i ng-if="report.defenderReportType" class="defenderReportIcon reportTypes_reportIcon6_flat" ng-class="{unknown: troopsDataDefender[0].unknown}"></i>
					</div>
					<div class="prestigeStars" v-if="report.destPlayer && gameModel.config.balancing.features.prestige">
						<div class="innerStars" stars="report.destPlayer.data.stars" size="tiny">
							<div ng-if="!badge">
								<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
								<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
								<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
							</div>
							<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
						</div>
					</div>
					<div class="prestigeStarsTooltip clickable" tooltip="" tooltip-translate-switch="{
						'Prestige.Stars.Tooltip.Own': true,
						'Prestige.Stars.Tooltip.Own.Max': false,
					}" v-if="report.destPlayerId == gameModel.player.id && gameModel.config.balancing.features.prestige" clickable="openWindow('profile', {'playerId': gameModel.player.id, 'profileTab': 'prestige'})" tooltip-data="prestige:372,nextLevelPrestige:400"></div>
					<div v-if="report.destPlayerId != gameModel.player.id && gameModel.config.balancing.features.prestige" class="prestigeStarsTooltip" tooltip="" tooltip-translate="Prestige.Stars.Tooltip.Other"></div>
				</div>
				<div v-if="report.notificationType == ReportsModel.REPORT_ADVENTURE" class="playerLabel">
					<div class="adventureLabel" translate=""><span>Приключение</span></div>
				</div>
				<!-- ngIf: header.ownRole == 'defender' && header.shareMessage -->
			</div>
		</div>
		<div class="successBar">
			<div v-if="report[report.ownRole+'TroopLossSum']" class="lossBar" :style="{width: (report[report.ownRole+'TroopLossSum'] * 100 / report[report.ownRole+'TroopSum'])+'%'}"></div>
			<div v-if="report.reportType == 'adventureReport' && (report.hp || report.hp == 0)" class="lossBar" :style="{width: (report.won ? report.hp : 100)+'%'}"></div>
		</div>
		<h6 class="functionHeader headerTrapezoidal">
			<div class="reportDate content">
				<span :i18ndt="report.time" format="shortDate">{{formatUnixDate(report.time, 'DD.MM.YY')}}</span> |
				<span :i18ndt="report.time" format="mediumTime">{{formatUnixDate(report.time, 'HH:mm:ss')}}</span>
			</div>
			<div class="controlPanel">
				<div class="iconButton shareReport clickable" ng-if="reportShareable()" @click="onShare" clickable="openOverlay('reportShare', {'reportToken': reportToken})" content="Поделиться" v-tippy="{placement: 'top'}">
					<i class="report_share_small_flat_black"></i>
				</div>
				<div class="iconButton favorite clickable" :class="{'active': isFavorite}" @click="onToggleFavorite" :content="isFavorite ? 'Убрать из избранных' : 'Отметить избранным'" v-tippy="{placement: 'top'}">
					<i class="report_favorite_small_flat_black"></i>
				</div>
				<div class="iconButton attackAgain clickable" v-if="(report.reportType == 'fightReport' || report.reportType == 'farmlistReport') && report.sourcePlayerId == gameModel.player.id && report.sourceId > 0" @click="onAttackAgain" content="Повторить атаку" v-tippy="{placement: 'top'}">
					<i class="movement_attack_small_flat_black"></i>
				</div>
				<div class="iconButton simulator clickable" ng-if="report.reportType == 'fightReport' && !troopsDataDefender[0].unknown && !troopsDataAttacker[0].unknown && report.notificationType != ReportsModel.REPORT_FARMLIST_RAID && report.notificationType != ReportsModel.REPORT_WORLD_PEACE && report.notificationType != ReportsModel.REPORT_VACATION" @click="onToSimulator" content="Рассчитать в симуляторе" v-tippy="{placement: 'top'}">
					<i class="report_simulate_small_flat_black"></i>
				</div>
			</div>
		</h6>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import ReportsModel from '@/models/game/ReportsModel';

import AvatarImage from '@/components/elements/AvatarImage';

export default {
	mixins: lib.mixins,
	props: {
		report: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		ReportsModel,
		
		isFavorite: false,
	}),
	computed: {},
	methods: {
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
		
		onOpenProfile(playerId, tab){
			
		},
		onShare(){
			this.gameModel.reports.showReportShare = true;
		},
		onToggleFavorite(){
			this.isFavorite = !this.isFavorite;
		},
		onAttackAgain(){
			
		},
		onToSimulator(){
			
		},
	},
	components: {
		AvatarImage,
	},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Алтарь</span></span>
									<span class="level">
										(<span><span>Уровень {{gameModel.island.buildings.shrine.level}}</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>Чем выше уровень главного здания, тем меньше времени будут строиться другие здания.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<!--
								<div class="buildingStatus location27 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
								-->
								<div class="buildingStatus location25 disabled" ng-class="{disabled: upgradeButtonClass == 'notAtAll' || upgradeButtonClass == 'maxLevel',
	 			busy: busy, queued: finished < 0, waiting: waiting, master: finished > 0 &amp;&amp; !busy,
	 			optionMenu: upgradeButtonClass == 'notNow' || premiumOptionMenu.locationId == building.data.locationId}" on-pointer-over="premiumOptionMenu.locationId != building.data.locationId &amp;&amp; upgradeButtonClass == 'notNow' &amp;&amp; !isRubble ? upgradeBuilding() : ''" :content="tooltips[1]" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/building/partials/upgradeCostsTooltip.html" tooltip-class="buildingTooltip" tooltip-placement="above" tooltip-offset="10" tooltip-hide="false" tooltip-hide-on-click="false">
	<div class="buildingBubble clickable disabled" ng-class="{disabled: upgradeButtonDisabled === true || premiumOptionMenu.locationId == building.data.locationId, disabledHover: upgradeButtonClass == 'notNow'}">
		<div class="colorLayer maxLevel" ng-class="{disabled: upgradeButtonClass == 'notAtAll', dismantle: isRubble, enoughRes: !isRubble &amp;&amp; enoughRes &amp;&amp; freeMasterSlots > 0}">
			<div class="labelLayer">
				<!-- ngIf: !isRubble --><span ng-if="!isRubble" class="buildingLevel">20</span><!-- end ngIf: !isRubble -->
				<!-- ngIf: isRubble -->
				<!-- ngIf: upgradeButtonClass == 'maxLevel' --><i ng-if="upgradeButtonClass == 'maxLevel'" class="symbol_star_tiny_illu"></i><!-- end ngIf: upgradeButtonClass == 'maxLevel' -->
			</div>
		</div>
	</div>
	<!-- ngIf: premiumOptionMenu.locationId == building.data.locationId -->

	<!-- ngIf: finished > 0 -->

	<!-- ngIf: finished < 0 -->
	<!-- ngIf: masterBuilderCount > 0 -->
</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<nav class="tabulation  maintab unselectable">
									<a class="tab clickable" :class="{active: tab == 'tab1', inactive: tab != 'tab1'}" @click="onToggleTab('tab1')">
										<div class="content">
											<span>Выздоровление</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'tab2', inactive: tab != 'tab2'}" @click="onToggleTab('tab2')">
										<div class="content">
											<span>Обмен медалей</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'tab3', inactive: tab != 'tab3'}" @click="onToggleTab('tab3')">
										<div class="content">
											<span>Арена</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'tab4', inactive: tab != 'tab4'}" @click="onToggleTab('tab4')">
										<div class="content">
											<span>Склонность</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'tab5', inactive: tab != 'tab5'}" @click="onToggleTab('tab5')">
										<div class="content">
											<span>Апгрейд</span>
										</div>
									</a>
								</nav>
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div class="loadedTab currentTab" v-if="tab == 'tab1'">
													tab1
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'tab2'">
													tab2
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'tab3'">
													tab3
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'tab4'">
													tab4
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'tab5'">
													tab5
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

//import Tab1 from '@/components/pages/shrine/Tab1';
//import Tab2 from '@/components/pages/shrine/Tab2';
//import Tab3 from '@/components/pages/shrine/Tab3';
//import Tab4 from '@/components/pages/shrine/Tab4';
//import Tab5 from '@/components/pages/shrine/Tab5';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Алтарь | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		tab: 'tab1',
		
		villageBackgroundLeft: '0px',
		
		tooltips: {
			1: `<div class="upgradeCostsTooltip" ng-controller="buildingUpgradeTooltipCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoTop"><div class="upgradeInfo maxLevel" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
	<div class="headline">
		<h3 translate="" class="building" options="11"><span>Амбар</span></h3>
	</div>

	<div class="queuedSlidesContainer">
		<!-- ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide1 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide2 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide3 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide4 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide5 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 -->
	</div>

	<div class="content boxMaxLevel" ng-class="{boxMaxLevel: building.data.lvl >= building.data.lvlMax, boxDeconstruction: upgradeButtonClass == 'demolish'}">
		<div class="contentTopArea">
			<!-- ngIf: building.data.currentEffect.values[0] --><div class="effectDescription" ng-if="building.data.currentEffect.values[0]">
				<span translate="" options="11"><span>Повышает емкость хранилища</span></span>
				<!-- ngIf: building.data.currentEffect.values[0] --><div class="horizontalLine3d" ng-if="building.data.currentEffect.values[0]"></div><!-- end ngIf: building.data.currentEffect.values[0] -->
			</div><!-- end ngIf: building.data.currentEffect.values[0] -->
			<!-- ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
			<!-- ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' --><table class="buildingNextEffects" ng-if="building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish'">
				<tbody>
					<tr><td>
						<!-- ngIf: building.data.currentEffect.values[0] --><span class="effectNumber" ng-if="building.data.currentEffect.values[0]">
							<span translate="" options="11" data="value: 80000"><span>80k</span></span>
						</span><!-- end ngIf: building.data.currentEffect.values[0] -->
					</td>
					<td>
						<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
							<i class="unit_population_small_illu"></i>&lrm;&#x202D;30
						</span>
					</td>
					<td class="borderLeft">
						<div class="buildingStatus">
							<div class="buildingBubble">
								<div class="colorLayer maxLevel">
									<div class="labelLayer">
										<span class="buildingLevel">20</span>
										<!-- ngIf: upgradeButtonClass == 'maxLevel' || building.data.lvl >= building.data.lvlMax --><i ng-if="upgradeButtonClass == 'maxLevel' || building.data.lvl >= building.data.lvlMax" class="symbol_star_tiny_illu ng-scope"></i><!-- end ngIf: upgradeButtonClass == 'maxLevel' || building.data.lvl >= building.data.lvlMax -->
									</div>
								</div>
							</div>
						</div>
					</td>
				</tr></tbody>
			</table><!-- end ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
		</div>

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' --><div class="description maxLevel" ng-if="currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish'" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
			<!-- ngIf: upgradeButtonClass != 'demolish' && building.data.lvl >= building.data.lvlMax --><span ng-if="upgradeButtonClass != 'demolish' &amp;&amp; building.data.lvl >= building.data.lvlMax" translate=""><span>Здание уже максимального уровня.</span></span><!-- end ngIf: upgradeButtonClass != 'demolish' && building.data.lvl >= building.data.lvlMax -->
			<!-- ngIf: upgradeButtonClass != 'demolish' && building.data.lvl < building.data.lvlMax -->
			<!-- ngIf: upgradeButtonClass == 'demolish' -->
		</div><!-- end ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
	</div>
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoBottom"><div class="upgradeInfo maxLevel" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
</div>`,
		},
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_8.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.tab) this.tab = this.$route.query.tab;
		else this.$router.push({query: {tab: this.tab}});
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(!to.query.tab) this.$router.push({query: {tab: 'tab1'}});
		else if(to.query.tab != this.tab) this.tab = to.query.tab;
		next();
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {},
}
</script>
<template>
	<div class="kingdom" ng-controller="kingdomCtrl">
		<div tabulation="" tab-config-name="kingdomTabConfig">
			<!-- ngIf: tabData.tabs.length > 1 -->
			<div class="tabulationContent scrollable tabContentIndependentGovernor" ng-class="'tabContent' + tabData.currentTab" scrolling-disabled="false" dynamic="" scrollable="">
				<div class="scrollContentOuterWrapper" style="width: 610px;">
					<div class="scrollContent" dynamic-height="true" style="width: 610px; overflow-y: hidden; max-height: 541px;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
							<div ng-transclude="">
								<div ng-repeat="tab in tabData.loadedTabs" ng-if="tab" class="loadedTab tabIndependentGovernor activeTab currentTab" ng-class="{currentTab: tab == tabData.currentTab, hiddenTab: tab != tabData.currentTab, activeTab: tab == tabData.activeTab, hiddenDynamicHeight: tab != tabData.activeTab}" ng-include="tabData.bodyTemplatePrefix + tab + tabData.bodyTemplateSuffix">
									<div ng-controller="kingdomIndependentGovernorCtrl" class="noKingdom">
										<!-- ngIf: invitations.data.length > 0 -->
										
										<div class="contentBox invitations" ng-if="invitations.data.length > 0">
											<h6 class="contentBoxHeader headerWithArrowEndings glorious">
												<div class="content" translate=""><span>Приглашения в королевство</span></div>
											</h6>
											<div class="contentBoxBody">
												<div class="invitationOverview">
													<kingdom-invitation-list>
														<div class="clickableContainer invitationSummary clickable" ng-repeat="invitation in invitations.data" @click="gameModel.pagesParams.society.kingdomInvitationShow = true" clickable="openOverlay('kingdomInvitation', {invitation: invitation.data.id})">
															<i class="community_invitation_small_illu"></i>
															<div class="sender">
																<div class="header truncated">
																	<span translate=""><span>От:</span></span>
																	&nbsp;Gaius Ulius
																</div>
																<br>
																<span class="date" i18ndt="1707907828" format="shortDate">Сегодня</span>
															</div>
															<div class="verticalLine double"></div>
															<!-- ngIf: invitation.data.invitedAs==Player.KINGDOM_ROLE_GOVERNOR && !invitation.isRelocationInvitation() -->
															<div class="subject" ng-if="invitation.data.invitedAs==Player.KINGDOM_ROLE_GOVERNOR &amp;&amp; invitation.isRelocationInvitation()">
																<span translate="" ng-if="!invitation.data.reservationTime" class="header"><span>Приглашение переселиться</span></span>
																<!-- ngIf: invitation.data.reservationTime -->
															</div>
															<!-- ngIf: invitation.data.invitedAs==Player.KINGDOM_ROLE_DUKE -->
															<!-- ngIf: invitation.data.invitedAs==Player.KINGDOM_ROLE_KING || invitation.data.invitedAs==Player.KINGDOM_ROLE_VICEKING -->
															<i ng-if="::invitation.data.invitedAs==Player.KINGDOM_ROLE_GOVERNOR || invitation.data.invitedAs==Player.KINGDOM_ROLE_DUKE" class="decorationIcon community_kingdom_large_illu"></i>
															<!-- ngIf: ::invitation.data.invitedAs==Player.KINGDOM_ROLE_KING || invitation.data.invitedAs==Player.KINGDOM_ROLE_VICEKING -->
															<div class="verticalLine double"></div>
														</div>
													</kingdom-invitation-list>
												</div>
											</div>
										</div>
										
										<div class="contentBox kingdomExplanation">
											<h6 class="contentBoxHeader headerWithArrowEndings glorious">
												<div class="content" translate=""><span>Зачем вступать в королевство?</span></div>
											</h6>
											<div class="contentBoxBody">
												<i class="community_kingdom_huge_illu"></i>
												<p class="description" translate=""><span>В качестве жителя королевства ты можешь вместе с другими игроками накапливать сокровища, расширять территорию и зарабатывать очки победы. Когда завершится игровой мир, королевство с самым большим количеством очков победы будет объявлено победителем. Сильный дух - вот залог успеха.</span></p>
											</div>
										</div>
										
										<div class="contentBox nearestKings">
											<h6 class="contentBoxHeader headerWithArrowEndings glorious">
												<div class="content" translate=""><span>Окружающие королевства</span></div>
											</h6>
											<div class="contentBoxBody">
												<span translate=""><span>Здесь ты можешь найти список ближайших королевств. Напиши какому-нибудь королю и попроси приглашение. Другие королевства можно найти на карте мира.</span></span>
											</div>
											<table class="nearestKings">
												<thead>
													<tr>
														<th colspan="2">
															<span translate=""><span>Король</span></span>
														</th>
														<th>
															<i class="unit_distance_small_flat_black" content="Дист." v-tippy="{placement: 'top'}" tooltip-translate="Kingdom.NearKings.Distance"></i>
														</th>
														<th>
															<i class="community_governor_small_flat_black" content="Губернатор" v-tippy="{placement: 'top'}" tooltip-translate="Kingdom.NearKings.Governor"></i>
														</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													<tr ng-repeat="king in nearestKings">
														<td class="kingAvatar">
															<avatar-image class="avatarImage" player-id="1733" scale="0.5" avatar-class="profileImage">
																<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
																	<!-- ngIf: playerId == robberId -->
																	<!-- ngIf: playerId == npcGovernorId -->
																	<canvas width="62" height="62"></canvas>
																</div>
															</avatar-image>
														</td>
														<td class="kingInfo">
															<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1733">login</a>
															<br>
															<div class="kingColumnPopulation">
																<i class="unit_population_small_flat_black"></i>
																<span>121</span>
															</div>
														</td>
														<td class="kingDistance">5,0</td>
														<td class="kingGovernor">0</td>
														<td class="kingContact">
															<button class="contactKing clickable" content="Открыть чат" v-tippy="{placement: 'top'}" tooltip-translate="Chat.Open" clickable="openChat(getPrivateRoomName(king.playerId))">
																<div class="content">
																	<span ng-transclude="" class="innerText">
																		<span translate=""><span>Написать сообщение</span></span>
																	</span>
																	<!-- ngIf: price > 0 || forceGoldUsage -->
																	<!-- ngIf: price == -1 && !forceGoldUsage -->
																	<div class="animationOverlay"></div>
																	<i class="checkmark action_check_small_flat_white"></i>
																	<i class="checkmark action_check_small_flat_black"></i>
																	<i class="checkmark action_check_small_flat_red"></i>
																</div>
															</button>
															<div class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '536264707_1707840335.6'); closeWindow(w.name);">
																<i class="symbol_target_small_flat_black"></i>
															</div>
														</td>
													</tr>
													<tr ng-repeat="king in nearestKings">
														<td class="kingAvatar">
															<avatar-image class="avatarImage" player-id="163" scale="0.5" avatar-class="profileImage">
																<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
																	<!-- ngIf: playerId == robberId -->
																	<!-- ngIf: playerId == npcGovernorId -->
																	<canvas width="62" height="62"></canvas>
																</div>
															</avatar-image>
														</td>
														<td class="kingInfo">
															<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="163">ингибитор</a>
															<br>
															<div class="kingColumnPopulation">
																<i class="unit_population_small_flat_black"></i>
																<span>1500</span>
															</div>
														</td>
														<td class="kingDistance">7,2</td>
														<td class="kingGovernor">65</td>
														<td class="kingContact">
															<button class="contactKing clickable" content="Открыть чат" v-tippy="{placement: 'top'}" tooltip-translate="Chat.Open" clickable="openChat(getPrivateRoomName(king.playerId))">
																<div class="content">
																	<span ng-transclude="" class="innerText">
																		<span translate=""><span>Написать сообщение</span></span>
																	</span>
																	<!-- ngIf: price > 0 || forceGoldUsage -->
																	<!-- ngIf: price == -1 && !forceGoldUsage -->
																	<div class="animationOverlay"></div>
																	<i class="checkmark action_check_small_flat_white"></i>
																	<i class="checkmark action_check_small_flat_black"></i>
																	<i class="checkmark action_check_small_flat_red"></i>
																</div>
															</button>
															<div class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '536264698_1707840335.6'); closeWindow(w.name);">
																<i class="symbol_target_small_flat_black"></i>
															</div>
														</td>
													</tr>
													<tr ng-repeat="king in nearestKings">
														<td class="kingAvatar">
															<avatar-image class="avatarImage" player-id="155" scale="0.5" avatar-class="profileImage">
																<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
																	<!-- ngIf: playerId == robberId -->
																	<!-- ngIf: playerId == npcGovernorId -->
																	<canvas width="62" height="62"></canvas>
																</div>
															</avatar-image>
														</td>
														<td class="kingInfo">
															<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="155">Nol1k</a>
															<br>
															<div class="kingColumnPopulation">
																<i class="unit_population_small_flat_black"></i>
																<span>2002</span>
															</div>
														</td>
														<td class="kingDistance">16,4</td>
														<td class="kingGovernor">29</td>
														<td class="kingContact">
															<button class="contactKing clickable" content="Открыть чат" v-tippy="{placement: 'top'}" tooltip-translate="Chat.Open" clickable="openChat(getPrivateRoomName(king.playerId))">
																<div class="content">
																	<span ng-transclude="" class="innerText">
																		<span translate=""><span>Написать сообщение</span></span>
																	</span>
																	<!-- ngIf: price > 0 || forceGoldUsage -->
																	<!-- ngIf: price == -1 && !forceGoldUsage -->
																	<div class="animationOverlay"></div>
																	<i class="checkmark action_check_small_flat_white"></i>
																	<i class="checkmark action_check_small_flat_black"></i>
																	<i class="checkmark action_check_small_flat_red"></i>
																</div>
															</button>
															<div class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '536461325_1707840335.6'); closeWindow(w.name);">
																<i class="symbol_target_small_flat_black"></i>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="error"></div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div ng-controller="buyMarketplaceCtrl" class="marketContent marketBuy">
		<div class="filterWrapper contentBox">
			<div ng-include="'tpl/building/marketplace/partials/merchantsHeader.html'" class="merchantsHeader">
				<div class="headerWithIcon arrowDirectionTo contentBoxHeader">
					<i class="movement_trade_medium_flat_black"></i>
					<div class="marketplaceHeaderGroup">
						<span><span translate=""><span>Доступно</span></span>:</span>
						<div class="circle"><span>1</span></div>
					</div>
					<div class="marketplaceHeaderGroup">
						<span><span translate=""><span>В пути</span></span>:</span>
						<div class="circle"><span>0</span></div>
					</div>
					<div class="marketplaceHeaderGroup">
						<span><span translate=""><span>Торгуют</span></span>:</span>
						<div class="circle"><span>0</span></div>
					</div>
				</div>
			</div>
			<div class="contentBoxBody marketBuyFilter">
				<div class="filterBox searchFilter">
					<div translate=""><span>Искать</span></div>
					<div>
						<span resource-filter="" deselectable="true" resource-obj="filter.search" on-change="filterChanged('search');">
							<div class="resourceFilter filterBar">
								<a class="filter iconButton clickable" clickable="select(1);" ng-class="{active: resourceObj.type == 1, disabled: disabledEntry == 1}" tooltip="" tooltip-translate="Resource_1">
									<i class="unit_wood_small_illu"></i>
								</a>
								<a class="filter iconButton clickable" clickable="select(2);" ng-class="{active: resourceObj.type == 2, disabled: disabledEntry == 2}" tooltip="" tooltip-translate="Resource_2">
									<i class="unit_clay_small_illu"></i>
								</a>
								<a class="filter iconButton clickable" clickable="select(3);" ng-class="{active: resourceObj.type == 3, disabled: disabledEntry == 3}" tooltip="" tooltip-translate="Resource_3">
									<i class="unit_iron_small_illu"></i>
								</a>
								<a class="filter iconButton clickable" clickable="select(4);" ng-class="{active: resourceObj.type == 4, disabled: disabledEntry == 4}" tooltip="" tooltip-translate="Resource_4">
									<i class="unit_crop_small_illu"></i>
								</a>
							</div>
						</span>
					</div>
				</div>
				<div class="filterBox ratioFilter">
					<div translate=""><span>Пропорция</span></div>
					<div class="filterBar">
						<a class="filter iconButton oneToOne clickable active" clickable="selectRate(1);" ng-class="{active: filter.rate == 1 }" content="Показать только предложения 1:1 или лучше" v-tippy="{placement: 'bottom'}" tooltip-translate="Marketplace.Buy.11filter">1:1</a>
						<a ng-if="hasMerchantRight" class="filter iconButton oneToX clickable" clickable="selectRate(0);" ng-class="{active: filter.rate == 0 }" content="Показать все предложения" v-tippy="{placement: 'bottom'}" tooltip-translate="Marketplace.Buy.1xfilter">1:x</a>
					</div>
				</div>
				<div class="filterBox offerFilter">
					<div translate=""><span>Предложить</span></div>
					<div>
						<span resource-filter="" deselectable="true" resource-obj="filter.offer" on-change="filterChanged('offer');">
							<div class="resourceFilter filterBar">
								<a class="filter iconButton clickable" clickable="select(1);" ng-class="{active: resourceObj.type == 1, disabled: disabledEntry == 1}" tooltip="" tooltip-translate="Resource_1">
									<i class="unit_wood_small_illu"></i>
								</a>
								<a class="filter iconButton clickable" clickable="select(2);" ng-class="{active: resourceObj.type == 2, disabled: disabledEntry == 2}" tooltip="" tooltip-translate="Resource_2">
									<i class="unit_clay_small_illu"></i>
								</a>
								<a class="filter iconButton clickable" clickable="select(3);" ng-class="{active: resourceObj.type == 3, disabled: disabledEntry == 3}" tooltip="" tooltip-translate="Resource_3">
									<i class="unit_iron_small_illu"></i>
								</a>
								<a class="filter iconButton clickable" clickable="select(4);" ng-class="{active: resourceObj.type == 4, disabled: disabledEntry == 4}" tooltip="" tooltip-translate="Resource_4">
									<i class="unit_crop_small_illu"></i>
								</a>
							</div>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="marketOffers">
			<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems" current-page="filter.page" display-page-func="displayCurrentPage" route-named-param="cp">
				<div ng-transclude="">
					<table class="tradeTable">
						<thead>
							<tr>
								<th><span>Дает</span></th>
								<th><span>Пропорция</span></th>
								<th><span>Ищет</span></th>
								<th><span>Продавец</span></th>
								<th><i class="movement_trade_small_flat_black" content="Торговцы" v-tippy="{placement: 'top'}" tooltip-translate="Marketplace.Merchant" tooltip-placement="above"></i></th>
								<th><i class="symbol_clock_small_flat_black" content="Время" v-tippy="{placement: 'top'}" tooltip-translate="Duration" tooltip-placement="above"></i></th>
								<th><span>Действие</span></th>
							</tr>
						</thead>
						<tbody ng-repeat="offer in marketOffers">
							<tr>
								<td class="nowrap"><i class="unit_clay_small_illu"></i> 1500</td>
								<td class="buyRateData"><span>2,0</span></td>
								<td class="nowrap"><i class="unit_crop_small_illu"></i> 3000</td>
								<td class="nameColumn">
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1001" playername="yo-ho-ho">yo-ho-ho</a>
								</td>
								<td>4</td>
								<td>01:14:26</td>
								<td ng-class="{buttonContainer: !offer.inactive}" class="">
									<button ng-show="!offer.inactive" clickable="acceptOffer(52962);" ng-class="{disabled: player.isOnVacation() || (offer.searchedAmount > stock[offer.searchedResource] && merchants.data.merchantsFree > 0) || ((offer.searchedAmount > merchants.data.maxCapacity && !(offer.searchedAmount > stock[offer.searchedResource])) || merchants.data.merchantsFree == 0)}" tooltip="Мало торговцев!/Мало ресурсов!" tooltip-translate-switch="{
										'Vacation.Tooltip.FeatureDisabled': false,
										'Error.NotEnoughResources': false,
										'Error.NotEnoughMerchants': false
									}" class="clickable ng-hide">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Принять</span></span>
											</span>
											<!-- ngIf: price > 0 || forceGoldUsage -->
											<!-- ngIf: price == -1 && !forceGoldUsage -->
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<div ng-show="offer.inactive" class="marketIconContainer">
										<span class="icon ">
											<i class="action_check_small_flat_green" content="Предложение принято" tooltip-translate="Marketplace.Buy.AcceptedOfferSuccess"></i>
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody ng-repeat="offer in marketOffers">
							<tr>
								<td class="nowrap"><i class="unit_crop_small_illu"></i> 1500</td>
								<td class="buyRateData"><span>1,0</span></td>
								<td class="nowrap"><i class="unit_clay_small_illu"></i> 1500</td>
								<td class="nameColumn">
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="147" playername="Vikihgs">Vikihgs</a>
								</td>
								<td>2</td>
								<td>00:19:02</td>
								<td ng-class="{buttonContainer: !offer.inactive}" class="buttonContainer">
									<button ng-show="!offer.inactive" clickable="acceptOffer(16263);" ng-class="{disabled: player.isOnVacation() || (offer.searchedAmount > stock[offer.searchedResource] && merchants.data.merchantsFree > 0) ||
										((offer.searchedAmount > merchants.data.maxCapacity && !(offer.searchedAmount > stock[offer.searchedResource])) || merchants.data.merchantsFree == 0)}" content="Мало торговцев" v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{
										'Vacation.Tooltip.FeatureDisabled': false,
										'Error.NotEnoughResources': true,
										'Error.NotEnoughMerchants': false
									}" class="clickable disabled">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Принять</span></span>
											</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<div ng-show="offer.inactive" class="marketIconContainer ng-hide">
										<span class="icon ">
											<i class="action_check_small_flat_green" tooltip="" tooltip-translate="Marketplace.Buy.AcceptedOfferSuccess"></i>
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody ng-repeat="offer in marketOffers">
							<tr>
								<td class="nowrap"><i class="unit_crop_small_illu"></i> 1500</td>
								<td class="buyRateData"><span>1,0</span></td>
								<td class="nowrap"><i class="unit_clay_small_illu"></i> 1500</td>
								<td class="nameColumn">
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="147" playername="Vikihgs">Vikihgs</a>
								</td>
								<td>2</td>
								<td>00:19:02</td>
								<td ng-class="{buttonContainer: !offer.inactive}" class="buttonContainer">
									<button ng-show="!offer.inactive" clickable="acceptOffer(18497);" ng-class="{disabled: player.isOnVacation() || (offer.searchedAmount > stock[offer.searchedResource] && merchants.data.merchantsFree > 0) ||
										((offer.searchedAmount > merchants.data.maxCapacity && !(offer.searchedAmount > stock[offer.searchedResource])) || merchants.data.merchantsFree == 0)}" tooltip="" tooltip-translate-switch="{
										'Vacation.Tooltip.FeatureDisabled': false,
										'Error.NotEnoughResources': true,
										'Error.NotEnoughMerchants': false
									}" class="clickable disabled">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Принять</span></span>
											</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<div ng-show="offer.inactive" class="marketIconContainer ng-hide">
										<span class="icon ">
											<i class="action_check_small_flat_green" tooltip="" tooltip-translate="Marketplace.Buy.AcceptedOfferSuccess"></i>
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody ng-repeat="offer in marketOffers">
							<tr>
								<td class="nowrap"><i class="unit_crop_small_illu"></i> 750</td>
								<td class="buyRateData"><span>1,0</span></td>
								<td class="nowrap"><i class="unit_clay_small_illu"></i> 750</td>
								<td class="nameColumn">
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1697" playername="karat">karat</a>
								</td>
								<td>1</td>
								<td>00:30:25</td>
								<td ng-class="{buttonContainer: !offer.inactive}" class="buttonContainer">
									<button ng-show="!offer.inactive" clickable="acceptOffer(18731);" ng-class="{disabled: player.isOnVacation() || (offer.searchedAmount > stock[offer.searchedResource] && merchants.data.merchantsFree > 0) ||
										((offer.searchedAmount > merchants.data.maxCapacity && !(offer.searchedAmount > stock[offer.searchedResource])) || merchants.data.merchantsFree == 0)}" tooltip="" tooltip-translate-switch="{
										'Vacation.Tooltip.FeatureDisabled': false,
										'Error.NotEnoughResources': true,
										'Error.NotEnoughMerchants': false
									}" class="clickable disabled">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Принять</span></span>
											</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<div ng-show="offer.inactive" class="marketIconContainer ng-hide">
										<span class="icon ">
											<i class="action_check_small_flat_green" tooltip="" tooltip-translate="Marketplace.Buy.AcceptedOfferSuccess"></i>
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody ng-repeat="offer in marketOffers">
							<tr>
								<td class="nowrap"><i class="unit_crop_small_illu"></i> 750</td>
								<td class="buyRateData"><span>1,0</span></td>
								<td class="nowrap"><i class="unit_wood_small_illu"></i> 750</td>
								<td class="nameColumn">
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="185" playername="Bastion">Bastion</a>
								</td>
								<td>1</td>
								<td>00:32:36</td>
								<td ng-class="{buttonContainer: !offer.inactive}" class="buttonContainer">
									<button ng-show="!offer.inactive" clickable="acceptOffer(18379);" ng-class="{disabled: player.isOnVacation() || (offer.searchedAmount > stock[offer.searchedResource] && merchants.data.merchantsFree > 0) ||
										((offer.searchedAmount > merchants.data.maxCapacity && !(offer.searchedAmount > stock[offer.searchedResource])) || merchants.data.merchantsFree == 0)}" tooltip="" tooltip-translate-switch="{
										'Vacation.Tooltip.FeatureDisabled': false,
										'Error.NotEnoughResources': true,
										'Error.NotEnoughMerchants': false
									}" class="clickable disabled">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Принять</span></span>
											</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<div ng-show="offer.inactive" class="marketIconContainer ng-hide">
										<span class="icon ">
											<i class="action_check_small_flat_green" tooltip="" tooltip-translate="Marketplace.Buy.AcceptedOfferSuccess"></i>
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody ng-repeat="offer in marketOffers">
							<tr>
								<td class="nowrap"><i class="unit_iron_small_illu"></i> 750</td>
								<td class="buyRateData"><span>1,0</span></td>
								<td class="nowrap"><i class="unit_wood_small_illu"></i> 750</td>
								<td class="nameColumn">
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="984" playername="Kas">Kas</a>
								</td>
								<td>1</td>
								<td>00:37:10</td>
								<td ng-class="{buttonContainer: !offer.inactive}" class="buttonContainer">
									<button ng-show="!offer.inactive" clickable="acceptOffer(18488);" ng-class="{disabled: player.isOnVacation() || (offer.searchedAmount > stock[offer.searchedResource] && merchants.data.merchantsFree > 0) ||
										((offer.searchedAmount > merchants.data.maxCapacity && !(offer.searchedAmount > stock[offer.searchedResource])) || merchants.data.merchantsFree == 0)}" tooltip="" tooltip-translate-switch="{
										'Vacation.Tooltip.FeatureDisabled': false,
										'Error.NotEnoughResources': true,
										'Error.NotEnoughMerchants': false
									}" class="clickable disabled">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Принять</span></span>
											</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<div ng-show="offer.inactive" class="marketIconContainer ng-hide">
										<span class="icon ">
											<i class="action_check_small_flat_green" tooltip="" tooltip-translate="Marketplace.Buy.AcceptedOfferSuccess"></i>
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody ng-repeat="offer in marketOffers">
							<tr>
								<td class="nowrap"><i class="unit_crop_small_illu"></i> 750</td>
								<td class="buyRateData"><span>1,0</span></td>
								<td class="nowrap"><i class="unit_clay_small_illu"></i> 750</td>
								<td class="nameColumn">
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="185" playername="Bastion">Bastion</a>
								</td>
								<td>1</td>
								<td>00:38:49</td>
								<td ng-class="{buttonContainer: !offer.inactive}" class="buttonContainer">
									<button ng-show="!offer.inactive" clickable="acceptOffer(18871);" ng-class="{disabled: player.isOnVacation() || (offer.searchedAmount > stock[offer.searchedResource] && merchants.data.merchantsFree > 0) ||
										((offer.searchedAmount > merchants.data.maxCapacity && !(offer.searchedAmount > stock[offer.searchedResource])) || merchants.data.merchantsFree == 0)}" tooltip="" tooltip-translate-switch="{
										'Vacation.Tooltip.FeatureDisabled': false,
										'Error.NotEnoughResources': true,
										'Error.NotEnoughMerchants': false
									}" class="clickable disabled">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Принять</span></span>
											</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<div ng-show="offer.inactive" class="marketIconContainer ng-hide">
										<span class="icon ">
											<i class="action_check_small_flat_green" tooltip="" tooltip-translate="Marketplace.Buy.AcceptedOfferSuccess"></i>
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody ng-repeat="offer in marketOffers">
							<tr>
								<td class="nowrap"><i class="unit_crop_small_illu"></i> 750</td>
								<td class="buyRateData"><span>1,0</span></td>
								<td class="nowrap"><i class="unit_wood_small_illu"></i> 750</td>
								<td class="nameColumn">
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="185" playername="Bastion">Bastion</a>
								</td>
								<td>1</td>
								<td>00:38:49</td>
								<td ng-class="{buttonContainer: !offer.inactive}" class="buttonContainer">
									<button ng-show="!offer.inactive" clickable="acceptOffer(18383);" ng-class="{disabled: player.isOnVacation() || (offer.searchedAmount > stock[offer.searchedResource] && merchants.data.merchantsFree > 0) ||
										((offer.searchedAmount > merchants.data.maxCapacity && !(offer.searchedAmount > stock[offer.searchedResource])) || merchants.data.merchantsFree == 0)}" tooltip="" tooltip-translate-switch="{
										'Vacation.Tooltip.FeatureDisabled': false,
										'Error.NotEnoughResources': true,
										'Error.NotEnoughMerchants': false
									}" class="clickable disabled">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Принять</span></span>
											</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<div ng-show="offer.inactive" class="marketIconContainer ng-hide">
										<span class="icon ">
											<i class="action_check_small_flat_green" tooltip="" tooltip-translate="Marketplace.Buy.AcceptedOfferSuccess"></i>
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody ng-repeat="offer in marketOffers">
							<tr>
								<td class="nowrap"><i class="unit_crop_small_illu"></i> 750</td>
								<td class="buyRateData"><span>1,0</span></td>
								<td class="nowrap"><i class="unit_wood_small_illu"></i> 750</td>
								<td class="nameColumn">
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="185" playername="Bastion">Bastion</a>
								</td>
								<td>1</td>
								<td>00:38:49</td>
								<td ng-class="{buttonContainer: !offer.inactive}" class="buttonContainer">
									<button ng-show="!offer.inactive" clickable="acceptOffer(18387);" ng-class="{disabled: player.isOnVacation() || (offer.searchedAmount > stock[offer.searchedResource] && merchants.data.merchantsFree > 0) ||
										((offer.searchedAmount > merchants.data.maxCapacity && !(offer.searchedAmount > stock[offer.searchedResource])) || merchants.data.merchantsFree == 0)}" tooltip="" tooltip-translate-switch="{
										'Vacation.Tooltip.FeatureDisabled': false,
										'Error.NotEnoughResources': true,
										'Error.NotEnoughMerchants': false
									}" class="clickable disabled">
										<div class="content">
											<span ng-transclude="" class="innerText">
												<span translate=""><span>Принять</span></span>
											</span>
											<div class="animationOverlay"></div>
											<i class="checkmark action_check_small_flat_white"></i>
											<i class="checkmark action_check_small_flat_black"></i>
											<i class="checkmark action_check_small_flat_red"></i>
										</div>
									</button>
									<div ng-show="offer.inactive" class="marketIconContainer ng-hide">
										<span class="icon ">
											<i class="action_check_small_flat_green" tooltip="" tooltip-translate="Marketplace.Buy.AcceptedOfferSuccess"></i>
										</span>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody>
							<tr ng-if="marketOffers.length == 0">
								<td colspan="7" translate="" class="info"><span>В данный момент предложения отсутствуют.</span></td>
							</tr>
						</tbody>
					</table>
					<div class="acceptedOffer ng-hide" ng-show="acceptedOffer.offerId">
						<span translate="" data="playerId:,playerName:"><span>Предложение от игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="0" playername="Природа" nolink="" showmissinglink="true">Природа</a><span> было принято.</span></span>
					</div>
					<div class="error ng-hide" ng-show="error">
						<i class="symbol_warning_tiny_flat_red"></i> 
						Мало торговцев!
					</div>
				</div>
				<div class="tg-pagination" ng-show="visible">
					<ul>
						<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
							<i ng-class="{
								action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover && currentPage == 1),
								action_toBeginning_tiny_flat_green: beginningHover && currentPage != 1,
								disabled: currentPage == 1
							}" class="action_toBeginning_tiny_flat_black disabled"></i>
						</li>
						<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
							<i ng-class="{
								symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover && currentPage == 1),
								symbol_arrowFrom_tiny_flat_green: fromHover && currentPage != 1,
								disabled: currentPage == 1
							}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
						</li>
						<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
						</li>
						<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(2)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}">2</a>
						</li>
						<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(...)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">...</a>
						</li>
						<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(10)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}">10</a>
						</li>
						<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
							<i ng-class="{
								symbol_arrowTo_tiny_flat_black: !toHover || (toHover && disableNext()),
								symbol_arrowTo_tiny_flat_green: toHover && !disableNext(),
								disabled: disableNext()
							}" class="symbol_arrowTo_tiny_flat_black"></i>
						</li>
						<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
							<i ng-class="{
								action_toEnd_tiny_flat_black: !endHover || (endHover && disableNext()),
								action_toEnd_tiny_flat_green: endHover && !disableNext(),
								disabled: disableNext()
							}" class="action_toEnd_tiny_flat_black"></i>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="heroInventory">
		<div class="contentBox previewBox gradient double" @mouseover="showSlots = true" @mouseleave="showSlots = false">
			<div class="contentBoxHeader headerWithArrowEndings glorious">
				<div class="content" translate=""><span>Герой</span></div>
			</div>
			<div class="contentBoxBody" v-if="heroEquipment">
				<div class="bodyItems">
					<div v-for="slot in range(1, 7)" class="bodyItem male" :class="['slot'+slot, {'clickable': heroEquipment[slot], 'highlight': slot == highlightedSlot && slot != InventoryModel.SLOT_NOTHING}]" v-show="showSlots || slot == highlightedSlot || slot == InventoryModel.SLOT_BAG">
						<div class="innerWrapper">
							<div class="topLeftBorder"></div>
							<div class="bottomRightBorder"></div>
							<span v-if="heroEquipment[slot]">
								<hero-item-container :item="heroEquipment[slot]" :click-handler="() => onDisequip(slot)" :hide-equipped-state="true" :highlighted="slot == highlightedSlot && slot != InventoryModel.SLOT_NOTHING" @mouseover="highlightedSlot = slot" @mouseleave="highlightedSlot = null" />
							</span>
						</div>
					</div>
				</div>
				<div class="heroImage">
					<img class="heroBody" file="male/body/330x422/base0" style="display: block;" src="//iwstatic.g.bsrv.su/img/heroAvatar/male/body/330x422/base0.png" data-cmp-info="10"/>
					<div class="faceOffset male">
						<div class="avatar-image" player-id="1804" hide-hair="heroEquipment[InventoryModel.SLOT_HELMET] && !heroFace.hideHelmet" hide-ears="heroEquipment[InventoryModel.SLOT_HELMET] && !heroFace.hideHelmet" no-shoulders="true" avatar="heroFace">
							<div class="heroImage  male" ng-class="::class" rerender="rerender">
								<!-- ngIf: playerId == robberId -->
								<!-- ngIf: playerId == npcGovernorId -->
								<canvas width="125" height="125"></canvas>
							</div>
						</div>
					</div>
					<span v-for="slot in range(1, 7)" v-show="(heroEquipment[slot] || slot == InventoryModel.SLOT_RIGHT_HAND || slot == InventoryModel.SLOT_LEFT_HAND) && !(heroFace.hideHelmet && slot == InventoryModel.SLOT_HELMET)">
						<img src="//iwstatic.g.bsrv.su/img/heroAvatar/male/body/330x422/horse1_0.png" v-if="(heroEquipment[slot] && heroEquipment[slot].images && heroEquipment[slot].images[0]) && slot != InventoryModel.SLOT_BAG" :class="{'slotBag': heroEquipment[slot].stackable}" style="z-index: 0; display: block;" data-cmp-info="10"/>
						<img v-if="!heroEquipment[slot] && slot == InventoryModel.SLOT_RIGHT_HAND" class="heroRightHand" style="display: block;" src="//iwstatic.g.bsrv.su/img/heroAvatar/male/body/330x422/arm_right.png" data-cmp-info="10"/>
						<img v-if="!heroEquipment[slot] && slot == InventoryModel.SLOT_LEFT_HAND" class="heroLeftHand" src="//iwstatic.g.bsrv.su/img/heroAvatar/male/body/330x422/arm_left.png" style="display: block;" data-cmp-info="10"/>
						<!--
						<img ng-if="heroEquipment[slot].images[0] && slot != HeroItem.SLOT_BAG" ng-style="{zIndex: heroEquipment[slot].data.zIndex}" ng-class="{slotBag: heroEquipment[slot].data.stackable}" style="z-index: 9; display: block;" src="layout/images/heroAvatar/male/body/330x422/helmet1_0.png" data-cmp-info="10">
						<img ng-if="heroEquipment[slot].data.images[0] && slot != HeroItem.SLOT_BAG" ng-style="{zIndex: heroEquipment[slot].data.zIndex}" ng-class="{slotBag: heroEquipment[slot].data.stackable}" style="z-index: 8; display: block;" src="layout/images/heroAvatar/male/body/330x422/bow0_0.png" data-cmp-info="10">
						<img ng-if="heroEquipment[slot].data.images[0] && slot != HeroItem.SLOT_BAG" ng-style="{zIndex: heroEquipment[slot].data.zIndex}" ng-class="{slotBag: heroEquipment[slot].data.stackable}" style="z-index: 1; display: block;" src="layout/images/heroAvatar/male/body/330x422/map0_0.png" data-cmp-info="10">
						<img ng-if="heroEquipment[slot].data.images[0] && slot != HeroItem.SLOT_BAG" ng-style="{zIndex: heroEquipment[slot].data.zIndex}" ng-class="{slotBag: heroEquipment[slot].data.stackable}" style="z-index: 6; display: block;" src="layout/images/heroAvatar/male/body/330x422/shirt0_0.png" data-cmp-info="10">
						<img ng-if="heroEquipment[slot].data.images[0] && slot != HeroItem.SLOT_BAG" ng-style="{zIndex: heroEquipment[slot].data.zIndex}" ng-class="{slotBag: heroEquipment[slot].data.stackable}" style="z-index: 3; display: block;" src="layout/images/heroAvatar/male/body/330x422/shoes0_0.png" data-cmp-info="10">
						<img ng-if="heroEquipment[slot].data.images[0] && slot != HeroItem.SLOT_BAG" ng-style="{zIndex: heroEquipment[slot].data.zIndex}" ng-class="{slotBag: heroEquipment[slot].data.stackable}" style="z-index: 0; display: block;" src="layout/images/heroAvatar/male/body/330x422/horse1_0.png" data-cmp-info="10">
						-->
					</span>
				</div>
			</div>
			<div class="ground"></div>
		</div>
		<div class="contentBox inventoryBox">
			<div class="contentBoxHeader">
				<div class="content">
					<span><span>Инвентарь</span></span>
					<a class="filter iconButton clickable" v-for="type in filterTypes" :key="type.value" :class="{'active': activeFilter == type.value}" @click="onToggleItemFilter(type.value)" :content="type.name" v-tippy="{placement: 'bottom'}">
						<i class="filterItem heroItem" :class="['item_category_'+type.value+'_small_flat_black']"></i>
					</a>
				</div>
			</div>
			<div class="contentBoxBody inventory scrollable">
				<div class="scrollContentOuterWrapper">
					<div class="scrollContent" style="overflow-y: auto;">
						<div class="scrollContentInnerWrapper" style="width: auto;">
							<hero-item-container v-for="item in inventory" :key="item.id" :item="item" :click-handler="() => onEquip(item)" :highlighted="(item.slot == highlightedSlot || (item.itemType == InventoryModel.TYPE_OINTMENT && highlightedSlot == InventoryModel.SLOT_BAG)) && item.slot != InventoryModel.SLOT_NOTHING" @mouseover="highlightedSlot = item.slot" @mouseleave="highlightedSlot = null" :hide-equipped-state="true" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import InventoryModel from '@/models/game/InventoryModel';

import HeroItemContainer from '@/components/elements/HeroItemContainer';

export default {
	mixins: lib.mixins,
	data: () => ({
		InventoryModel,
		
		filterTypes: [
			{
				value: 'cardGame',
				name: 'Только призы карточной игры',
			},
			{
				value: 'consumable',
				name: 'Только употребляемые предметы',
			},
			{
				value: 'equipment',
				name: 'Только снаряжение',
			},
		],
		activeFilter: null,
		heroFace: {
			hideHelmet: true,
		},
		heroEquipment: {},
		highlightedSlot: null,
		showSlots: false,
	}),
	computed: {
		inventory(){
			if(this.activeFilter == 'cardGame'){
				return this.gameModel.inventory.items.filter((item) => item.cardGameItem || item.premiumItem);
			} else if(this.activeFilter == 'consumable'){
				return this.gameModel.inventory.items.filter((item) => !item.slot && !item.cardGameItem && !item.premiumItem);
			} else if(this.activeFilter == 'equipment'){
				return this.gameModel.inventory.items.filter((item) => item.slot && !item.cardGameItem && !item.premiumItem);
			} else {
				return this.gameModel.inventory.items;
			}
		},
	},
	methods: {
		range: lib.range,
		
		onToggleItemFilter(type){
			if(type != this.activeFilter){
				this.activeFilter = type;
			} else {
				this.activeFilter = null;
			}
		},
		
		onEquip(item){
			if(item.slot != InventoryModel.SLOT_NOTHING){
				this.heroEquipment[item.slot] = item;
			}
		},
		onDisequip(slot){
			this.heroEquipment[slot] = null;
		},
	},
	mounted(){
		let item_helmet = this.gameModel.inventory.items.find((item) => item.slot == InventoryModel.SLOT_HELMET);
		if(item_helmet) this.heroEquipment[InventoryModel.SLOT_HELMET] = item_helmet;
		
		let item_right_hand = this.gameModel.inventory.items.find((item) => item.slot == InventoryModel.SLOT_RIGHT_HAND);
		if(item_right_hand) this.heroEquipment[InventoryModel.SLOT_RIGHT_HAND] = item_right_hand;
		
		let item_left_hand = this.gameModel.inventory.items.find((item) => item.slot == InventoryModel.SLOT_LEFT_HAND);
		if(item_left_hand) this.heroEquipment[InventoryModel.SLOT_LEFT_HAND] = item_left_hand;
		
		let item_body = this.gameModel.inventory.items.find((item) => item.slot == InventoryModel.SLOT_BODY);
		if(item_body) this.heroEquipment[InventoryModel.SLOT_BODY] = item_body;
		
		let item_shoes = this.gameModel.inventory.items.find((item) => item.slot == InventoryModel.SLOT_SHOES);
		if(item_shoes) this.heroEquipment[InventoryModel.SLOT_SHOES] = item_shoes;
		
		let item_horse = this.gameModel.inventory.items.find((item) => item.slot == InventoryModel.SLOT_HORSE);
		if(item_horse) this.heroEquipment[InventoryModel.SLOT_HORSE] = item_horse;
		
		let item_bag = this.gameModel.inventory.items.find((item) => item.slot == InventoryModel.SLOT_BAG);
		if(item_bag) this.heroEquipment[InventoryModel.SLOT_BAG] = item_bag;
	},
	components: {
		HeroItemContainer,
	},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper help" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal help">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Помощь по игре</span></span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="helpMenuCtrl" class="helpMenu">
														<div class="navigation contentBox gradient">
															<div class="navigationWrapper" :class="{'level1': gameModel.help.selectedSection, 'level0': !gameModel.help.selectedSection}">
																<div class="topLevelNav">
																	<h6 class="headerWithIcon arrowDirectionTo">
																		<i class="symbol_questionMark_small_flat_black"></i>
																		<span>Быстрая справка</span>
																	</h6>
																	<div class="navContent scrollable">
																		<div class="scrollContentOuterWrapper">
																			<div class="scrollContent" style="overflow-y: auto;">
																				<div class="scrollContentInnerWrapper" style="width: auto;">
																					<div class="entries">
																						<div v-for="section in gameModel.help.sections" :key="section.apiname">
																							<div class="clickableContainer clickable" @click="onSelectSection(section.apiname)">
																								<span>{{section.name}}</span>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="subLevelNav" v-if="gameModel.help.selectedSection">
																	<div class="header subLevelHeader">
																		<div class="clickableContainer clickable" @click="onBackToMain">
																			<i class="symbol_arrowFrom_small_flat_black"></i>
																		</div>
																		<span>{{gameModel.help.getSection(gameModel.help.selectedSection).name}}</span>
																	</div>
																	<div class="navContent scrollable">
																		<div class="scrollContentOuterWrapper active">
																			<div class="scrollContent" style="overflow-y: auto;">
																				<div class="scrollContentInnerWrapper" style="width: auto;">
																					<div class="entries">
																						<div v-for="group in gameModel.help.getSection(gameModel.help.selectedSection).groups">
																							<div class="header groupHeader" v-if="group.name">
																								<span>{{group.name}}</span>
																							</div>
																							<div v-for="element in gameModel.help.getGroup(gameModel.help.selectedSection, group.apiname).elements" :key="element.apiname">
																								<div class="entry truncated">
																									<div class="entryButton clickable" @click="onSelectElement(gameModel.help.selectedSection, group.apiname, element.apiname)" :class="{'active': gameModel.help.selectedElement == element.apiname && gameModel.help.selectedGroup == group.apiname}">
																										<span>{{element.name}}</span>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="contentBox helpContent gradient" v-if="!gameModel.help.selectedElement">
															<div class="contentBoxHeader headerWithArrowDown">
																<span>Случайная тема</span>: <span class="pageTitle">{{gameModel.help.getElement(gameModel.help.randSection, gameModel.help.randGroup, gameModel.help.randElement).name}}</span>
															</div>
															<div class="contentBoxBody indexContentBody">
																<div class="indexView">
																	<!--<component :is="randomizedComponent" :randomized="true"></component>-->
																	
																	<div class="topicImage" :class="['help_image_'+gameModel.help.getElement(gameModel.help.randSection, gameModel.help.randGroup, gameModel.help.randElement).icon+'_1', 'help_image_'+gameModel.help.getElement(gameModel.help.randSection, gameModel.help.randGroup, gameModel.help.randElement).icon+'_1_gaul']"></div>
																	<div class="truncated indexContent">
																		<div class="contentBox transparent">
																			<div class="contentBoxHeader headerWithArrowEndings">
																				<div class="content">
																					<span>{{gameModel.help.getElement(gameModel.help.randSection, gameModel.help.randGroup, gameModel.help.randElement).name}}</span>
																				</div>
																			</div>
																			<div class="contentBoxBody">
																				<span v-if="gameModel.help.randElement" v-html="truncate(gameModel.help.getElement(gameModel.help.randSection, gameModel.help.randGroup, gameModel.help.randElement).data[0].text, 300)"></span>
																				<a @click="onSelectElement(gameModel.help.randSection, gameModel.help.randGroup, gameModel.help.randElement)" class="clickable"><span translate=""><span>узнать больше...</span></span></a>
																			</div>
																		</div>
																	</div>
																	<div class="contentBox externalHelp">
																		<div class="contentBoxHeader headerTrapezoidal">
																			<div class="content">
																				<span>Дополнительная помощь</span>
																			</div>
																		</div>
																		<div class="contentBoxBody">
																			<a class="clickableContainer clickable" clickable="openDiscord();">
																				<span>Discord</span>
																			</a>
																			<a class="clickableContainer clickable" clickable="openHelpCenter();" ng-class="{disabled: isSitter || isDual}" tooltip="">
																				<span>Help Center</span>
																			</a>
																		</div>
																	</div>
																	<div class="legalLinks">
																		<a clickable="openOverlay('gameRulesOverlay')" translate="" class="clickable"><span>Правила игры</span></a> |
																		<a clickable="openOverlay('termsAndConditionsOverlay')" translate="" class="clickable"><span>Общие условия</span></a> |
																		<a clickable="openOverlay('privacyOverlay')" translate="" class="clickable"><span>Положение о защите данных</span></a> |
																		<a clickable="openOverlay('imprintOverlay')" translate="" class="clickable"><span>Контакты</span></a>
																	</div>
																	
																</div>
															</div>
														</div>
														<div class="contentBox helpContent gradient" v-else>
															<h6 class="contentBoxHeader headerWithArrowEndings" v-if="['building', 'unit'].includes(gameModel.help.getSection(gameModel.help.selectedSection).type)">
																<div class="content">
																	<span>{{gameModel.help.getElement(gameModel.help.selectedSection, gameModel.help.selectedGroup, gameModel.help.selectedElement).name}}</span>
																</div>
															</h6>
															<div class="contentBoxBody viewContentBody" :class="{'unitOrBuilding': ['building', 'unit'].includes(gameModel.help.getSection(gameModel.help.selectedSection).type)}">
																<component :is="selectedComponent"></component>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
//import { defineAsyncComponent } from 'vue';

import HelpModel from '@/models/game/HelpModel';

let helpModel = new HelpModel();
let components = helpModel.getContentComponents();

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Помощь по игре | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {
		selectedComponent(){
			let elem = this.gameModel.help.getElement(this.gameModel.help.selectedSection, this.gameModel.help.selectedGroup, this.gameModel.help.selectedElement);
			if(elem){
				if(Object.keys(elem.content).length > 0){
					if(Object.keys(elem.content)[this.gameModel.help.selectedPage - 1]){
						return Object.keys(elem.content)[this.gameModel.help.selectedPage - 1];
					}
				}
			}
			return null;
		},
		/*randomizedComponent(){
			if(!this.gameModel.help.randSection || !this.gameModel.help.randGroup || !this.gameModel.help.randElement) this.onRandomComponent();
			let elem = this.gameModel.help.getElement(this.gameModel.help.randSection, this.gameModel.help.randGroup, this.gameModel.help.randElement);
			if(elem){
				if(Object.keys(elem.content).length > 0){
					if(Object.keys(elem.content)[0]){
						return Object.keys(elem.content)[0];
					}
				}
			}
			return null;
		},*/
	},
	watch: {
		'gameModel.help.selectedSection'(val, oldVal){
			if(val == '') this.onRandomComponent();
		},
	},
	methods: {
		truncate: lib.truncate,
		
		onRandomComponent(){
			this.gameModel.help.randSection = '';
			this.gameModel.help.randGroup = '';
			this.gameModel.help.randElement = '';
			let hSecitions = [];
			for(let section of this.gameModel.help.sections){
				if(!['building', 'unit'].includes(section.type)){
					hSecitions.push(section.apiname);
				}
			}
			this.gameModel.help.randSection = hSecitions[lib.mt_rand(0, hSecitions.length - 1)];
			let hGroups = [];
			for(let group of this.gameModel.help.getSection(this.gameModel.help.randSection).groups){
				hGroups.push(group.apiname);
			}
			this.gameModel.help.randGroup = hGroups[lib.mt_rand(0, hGroups.length - 1)];
			let hElements = [];
			for(let element of this.gameModel.help.getGroup(this.gameModel.help.randSection, this.gameModel.help.randGroup).elements){
				hElements.push(element.apiname);
			}
			this.gameModel.help.randElement = hElements[lib.mt_rand(0, hElements.length - 1)];
		},
		
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onSelectSection(section){
			this.gameModel.help.selectedSection = section;
			this.gameModel.help.selectedGroup = '';
			this.gameModel.help.selectedElement = '';
			this.gameModel.help.selectedPage = 1;
			
			this.$router.push({query: Object.assign({}, this.$route.query, {
				section,
				group: '',
				element: '',
			})});
		},
		onSelectElement(section, group, element){
			this.gameModel.help.selectedSection = section;
			this.gameModel.help.selectedGroup = group;
			this.gameModel.help.selectedElement = element;
			this.gameModel.help.selectedPage = 1;
			
			this.$router.push({query: Object.assign({}, this.$route.query, {
				section,
				group,
				element,
			})});
		},
		onSelectPage(page){
			this.gameModel.help.selectedPage = page;
		},
		onBackToMain(){
			this.gameModel.help.selectedSection = '';
			this.gameModel.help.selectedGroup = '';
			this.gameModel.help.selectedElement = '';
			this.gameModel.help.selectedPage = 1;
			
			this.$router.push({query: Object.assign({}, this.$route.query, {
				section: '',
				group: '',
				element: '',
			})});
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 20 > 0 ? maxHeightContent - 20 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		this.onRandomComponent();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.section) this.gameModel.help.selectedSection = this.$route.query.section;
		if(this.$route.query.group) this.gameModel.help.selectedGroup = this.$route.query.group;
		if(this.$route.query.element) this.gameModel.help.selectedElement = this.$route.query.element;
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(to.query.section != this.gameModel.help.selectedSection) this.gameModel.help.selectedSection = to.query.section;
		if(to.query.group != this.gameModel.help.selectedGroup) this.gameModel.help.selectedGroup = to.query.group;
		if(to.query.element != this.gameModel.help.selectedElement) this.gameModel.help.selectedElement = to.query.element;
		next();
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components,
}
</script>
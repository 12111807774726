<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper reports" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal reports">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/map')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span>Санкт-Петербург</span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
				<div class="mapCellDetails" ng-controller="mapCellDetailsCtrl">
	<div dynamic="" scrollable="" class="scrollable"><div class="scrollContentOuterWrapper" style="width: 610px;">
	<div class="scrollContent" dynamic-height="true" style="width: 610px; overflow-y: hidden; max-height: 453px;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
		<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/header.html'"><div class="mapContainer village">
	<!-- ngIf: mapDetails.isWonder -->
	<!-- ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'npcVillageRobber' -->
	<!-- ngIf: cellData.menhir -->
	<!-- ngIf: fieldType != 'oasis' && fieldType != 'npcVillageRobber' && !mapDetails.isWonder && !cellData.menhir --><div class="wrapper" ng-if="fieldType != 'oasis' &amp;&amp; fieldType != 'npcVillageRobber' &amp;&amp; !mapDetails.isWonder &amp;&amp; !cellData.menhir">
		<div class="background"></div>
		<div class="foreground resDistribution resources3339"></div>
	</div><!-- end ngIf: fieldType != 'oasis' && fieldType != 'npcVillageRobber' && !mapDetails.isWonder && !cellData.menhir -->
	<!-- ngIf: mapDetails.isWonder -->
	<!-- ngIf: showAttack && attackingTroops.cnt == 0 -->
	<!-- ngIf: attackingTroops.cnt > 0 -->
	<!-- ngIf: mapDetails.npcInfo.type == 8 -->
	<!-- ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'oasis' -->
</div></div>
		<div class="otherContainer village">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/bodyTop.html'"><div class="splitContainer village">
	<!-- ngIf: fieldType == 'village' && mapDetails.wwValues -->
	<!-- ngIf: fieldType == 'village' --><div ng-if="fieldType == 'village'">
		<div class="contentBox">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/villageDetails.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6><span translate=""><span>Информация</span></span> <span class="coordinateWrapper" coordinates="" x="-8" y="16">
	<!-- ngIf: hasLink --><a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537411576_1708113691.9');" class="clickable">
		<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;8&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;16&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
	</a><!-- end ngIf: hasLink -->
	<!-- ngIf: !hasLink -->
</span></h6>
	<span class="mainVillageIndicator"><!-- ngIf: village.isMainVillage --></span>
</div>
<table class="transparent contentBoxBody">
	<tbody><!-- ngIf: mapDetails.tribe != PlayerModel.TRIBE.NATAR --><tr ng-if="mapDetails.tribe != PlayerModel.TRIBE.NATAR">
		<th translate=""><span>Владелец</span></th>
		<td><div class="longTitle">
			<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="662" playername="Пригожин">Пригожин</a></div>
		</td>
	</tr><!-- end ngIf: mapDetails.tribe != PlayerModel.TRIBE.NATAR -->
	<!-- ngIf: mapDetails.tribe != PlayerModel.TRIBE.NATAR --><tr ng-if="mapDetails.tribe != PlayerModel.TRIBE.NATAR">
		<th translate=""><span>Королевство</span></th>
		<td>
			<div class="longTitle">
				<!-- ngIf: mapDetails.kingdomId != 0 --><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="mapDetails.kingdomId != 0" kingdomid="3">Roman</a><!-- end ngIf: mapDetails.kingdomId != 0 -->
				<!-- ngIf: mapDetails.kingdomId == 0 -->
			</div>
		</td>
	</tr><!-- end ngIf: mapDetails.tribe != PlayerModel.TRIBE.NATAR -->
	<tr>
		<th translate=""><span>Народ</span></th>
		<td translate="" options="3"><span>Галлы</span></td>
	</tr>
	<tr>
		<th translate=""><span>Население</span></th>
		<td>358</td>
	</tr>
	<!-- ngIf: mapDetails.treasures > 0 -->
	<tr ng-if="mapDetails.treasures > 0">
		<th translate=""><span>Сокровища</span></th>
		<td>3867</td>
	</tr>
</tbody></table>
</div>
		</div>
		<div class="contentBox">
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/landDistribution.html'"><div class="villageDetails contentBoxHeader headerColored">
	<h6 translate=""><span>Распределение</span></h6>
</div>
<div class="contentBoxBody">
	<div class="landDistributionContainer">
		<i class="landDistribution unit_wood_large_illu"></i>
		<span>3</span>
	</div><div class="landDistributionContainer">
		<i class="landDistribution unit_clay_large_illu"></i>
		<span>3</span>
	</div><div class="landDistributionContainer">
		<i class="landDistribution unit_iron_large_illu"></i>
		<span>3</span>
	</div><div class="landDistributionContainer">
		<i class="landDistribution unit_crop_large_illu"></i>
		<span>9</span>
	</div>
</div>

</div>
		</div>
	</div><!-- end ngIf: fieldType == 'village' -->
	<!-- ngIf: fieldType == 'oasis' -->
	<!-- ngIf: fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->
	<!-- ngIf: fieldType == 'unhabitable' -->
	<!-- ngIf: fieldType == 'habitable' -->
</div>
</div>
			<!-- ngInclude:  --><div ng-include="" src="'tpl/mapCellDetails/partials/bodyBottom.html'"><!-- ngIf: fieldType == 'oasis' -->

<!-- ngIf: fieldType == 'village' && mapDetails.isWonder && !mapDetails.isConquered -->

<div class="tributeCropContainer">
	<div class="tributes contentBox gradient ng-hide" ng-show="showTributes">
		<div class="contentBoxBody">
			<display-resources resources="village.tributes" treasures="village.tributeTreasures" hide-zero="true"><div class="costs max1digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources>
			<div class="barView">
				<div class="actualTributes">&lrm;&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;0&#x202C;&#x202C;</div>
				<div class="populationBar progressbar" progressbar="" type="green" perc="NaN" marker="NaN">
	<!-- ngIf: ::labelIcon -->
	<div class="progress">
		<!-- ngIf: steps -->
		<div class="bar positive perc green"></div>
		<div class="bar additionalBar additionalPerc"></div>
		<div class="marker">
			<div class="markerInner"></div>
		</div>
		<!-- ngIf: ::showCountdown -->
		<!-- ngIf: ::label -->
		<!-- ngIf: ::percTooltip -->
	</div>
</div>
			</div>
			<button clickable="fetchTributes()" ng-class="{disabled: !village.canFetchTributes || !village.allowTributeCollection || !village.protectionGranted || !activeTreasury || (!territoryConnected &amp;&amp; village.type != Village.TYPE_GOVERNOR_NPC_VILLAGE)}" play-on-click="16" tooltip="" tooltip-translate-switch="{'ContextMenu.button.fetchTribute.denied': true,
											   'ContextMenu.button.fetchTribute.unprotected': true,
											   'Tributes.VillageNotGeneratingInfluence': true,
											   'Tributes.VillageNotConnected': false}" class="clickable disabled"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Собрать дань</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
		</div>
	</div>
	<!-- ngIf: sharedInformations.cropDetails != null -->
</div>

<p ng-show="error" class="error ng-hide">
	
</p>

<!-- ngIf: fieldType == 'village' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' --><div ng-if="fieldType == 'village' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor'">
	<!-- ngRepeat: troopDetails in troopsHere -->
</div><!-- end ngIf: fieldType == 'village' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->

<!-- ngIf: sharedInformations.stationedTroops.length > 0 --></div>
		</div>
	</div>
	</div>
</div>
<div class="scrollTrack" style="display: none; opacity: 0;">
    <div class="scrollHandle" style="top: 0px;"></div>
	<div class="trackDecoration"></div>
</div></div>
	<div class="cellActionContainer">
		<div class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '537411576_1708113691.9'); closeWindow(w.name);">
			<i class="symbol_target_small_flat_black"></i>
		</div>
		<!-- ngIf: options.length > 0 --><div class="options" ng-if="options.length > 0" more-dropdown="" more-dropdown-options="getOptions()" style="position: relative;"><a class="iconButton clickable" clickable="openMoreDropdown()"><!-- ngIf: showCount --><i class="symbol_arrowDown_tiny_illu"></i></a>
			<div class="moreDropDownBody dropdownBody" style="bottom: 0; right: 0; display: block;"><ul><!-- ngRepeat: option in options --><li ng-repeat="option in options" clickable="openWindow('markerMenu',{'cellId': 537411576}); closeMoreDropdown()" class="clickable">Отметить поле</li><!-- end ngRepeat: option in options --><li ng-repeat="option in options" clickable="openWindow('reports',{'tab': 'Search', 'villageId': 537411576}); closeMoreDropdown()" class="clickable">Отчеты</li><!-- end ngRepeat: option in options --><li ng-repeat="option in options" clickable="openWindow('building',{'location': 35, 'tab': 'Send', 'destId': 537411576, destPlayerId: 662}); closeMoreDropdown()" class="clickable">Послать торговцев</li><!-- end ngRepeat: option in options --></ul></div>
		</div><!-- end ngIf: options.length > 0 -->
		<!-- ngIf: fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' --><button class="sendTroopsButton clickable" ng-if="fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor'" clickable="openWindow('sendTroops', {'x': -8, 'y': 16 })" tooltip="" tooltip-show="false" tooltip-translate="ContextMenu.NoRallyPoint" ng-class="{disabled: !hasRallyPoint}"><div class="content">
	<span ng-transclude="" class="innerText">
			<span translate=""><span>Отправить войска</span></span>
		</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: fieldType == 'village' || fieldType == 'oasis' || fieldType == 'npcVillageRobber' || fieldType == 'npcVillageGovernor' -->
		<!-- ngIf: fieldType == 'habitable' && !cellData.menhir -->
		<!-- ngIf: cellData.menhir && cellData.menhir == player.data.playerId -->
		<!-- ngIf: cellData.menhir && cellData.owner == player.data.playerId -->
	</div>
</div>
			</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
.societyFilter {
	float: right;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Деревня | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
<template>
	<div id="flashNotifications">
		<div v-for="(noty, index) in gameModel.flashNotifications.list" class="notification" :class="[noty.type]">
			<!-- ngIf: noty.count > 1 -->
			<span v-if="noty.count == 1" @click="onCloseNotification(index)" class="clickable">{{noty.message}}</span>
		</div>
		<div v-for="(chat, index) in gameModel.flashNotifications.chat" class="notification chatNotification fadeIn fullOpacity">
			<span on-pointer-over="chatNotificationOver(chat)" on-pointer-out="chatNotificationOut(chat)" @click="onCloseChatNotification(index)" clickable="openConversation(chat.roomId); closeChatNotification(chat);" class="clickable">
				<div class="firstLine">
					<div class="avatar">
						<avatar-image :src="chat.playerAvatar" :size="56" :player-id="chat.playerId" avatar-class="profile" v-if="chat.playerId > 0" />
						<i class="kingdomRole community_governor_small_flat_black" v-if="chat.kingdomRole == kingdomRole.governor"></i>
						<i class="kingdomRole community_duke_small_flat_black" v-if="chat.kingdomRole == kingdomRole.duke"></i>
						<i class="kingdomRole community_king_small_flat_black" v-if="chat.kingdomRole == kingdomRole.king"></i>
					</div>
					<div class="verticalLine"></div>
					<div class="playerInfos">
						<div>{{chat.playerUsername}}</div>
						<div>
							<a :class="{disabled: chat.kingdomId <= 0 || nolink}" class="kingdomName kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': chat.kingdomId}, null, true)">{{chat.kingdomName}}</a>
						</div>
					</div>
				</div>
				<div class="horizontalLine"></div>
				<div>
					<div class="message truncated">{{chat.message}}</div>
					<i class="symbol_arrowTo_tiny_illu messageEndIcon"></i>
				</div>
			</span>
		</div>
	</div>
	<div v-for="(warning, index) in gameModel.flashNotifications.warnings" class="notificationWarning fullScreenOverlay" :class="warning.class">
		<table class="transparent">
			<tbody>
				<tr>
					<td>
						<div class="notification error">
							<div>
								<div class="errorTitle">
									<i class="symbol_warning_large_flat_red"></i>
									<span>Возникла ошибка.</span>
									<i class="closeWarning clickable action_cancel_small_flat_black" ng-class="{action_cancel_small_flat_black: !closeHover, action_cancel_small_flat_green: closeHover}" on-pointer-over="closeHover = true" on-pointer-out="closeHover = false" @click="onCloseWarning(index)" clickable="close(warning);"></i>
								</div>
								<div class="errorDescription">
									<p>{{warning.message}}</p>
									<p><span>Пожалуйста, перегрузи страницу. Если проблема сохранится, свяжись с нашей службой поддержки.</span></p>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<style lang="scss">
@-webkit-keyframes notificationfade{
    from{
        opacity:0
    }
    to{
        opacity:1
    }
}
@-moz-keyframes notificationfade{
    from{
        opacity:0
    }
    to{
        opacity:1
    }
}
@-ms-keyframes notificationfade{
    from{
        opacity:0
    }
    to{
        opacity:1
    }
}
@-o-keyframes notificationfade{
    from{
        opacity:0
    }
    to{
        opacity:1
    }
}
@keyframes notificationfade{
    from{
        opacity:0
    }
    to{
        opacity:1
    }
}
#flashNotifications{
    position:fixed;
    right:5px;
    bottom:75px;
    z-index:99;
    text-align:center;
    width:300px
}
#flashNotifications .notification{
    position:relative;
    display:inline-block;
    width:300px;
    padding:3px;
    text-align:center;
    box-sizing:border-box;
    color:#06f;
    border:1px solid #fff;
    background-color:rgba(241,240,238,0.6);
    margin-bottom:2px;
    animation:notificationfade .4s linear 0 1;
    -webkit-animation:notificationfade .4s linear 0 1;
    -moz-animation:notificationfade .4s linear 0 1
}
#flashNotifications .notification.fadeOut{
    opacity:0;
    -webkit-transition:opacity 1s linear;
    -moz-transition:opacity 1s linear;
    -o-transition:opacity 1s linear;
    transition:opacity 1s linear
}
#flashNotifications .notification span{
    font-size:1rem;
    line-height:21px;
    display:inline-block;
    box-sizing:border-box;
    width:100%;
    border:1px solid #fff;
    -webkit-box-shadow:0 0 1px rgba(0,0,0,0.75);
    -moz-box-shadow:0 0 1px rgba(0,0,0,0.75);
    box-shadow:0 0 1px rgba(0,0,0,0.75);
    background-color:rgba(241,240,238,0.87)
}
#flashNotifications .notification.warning,#flashNotifications .notification.error,#flashNotifications .notification.debug{
    color:red
}
#flashNotifications .notification.chatNotification.fadeIn{
    opacity:.9
}
#flashNotifications .notification.chatNotification.fadeIn.fullOpacity{
    opacity:1
}
#flashNotifications .notification.chatNotification .horizontalLine{
    padding-bottom:1px
}
#flashNotifications .notification.chatNotification .message{
    max-height:44px;
    text-align:left;
    display:block;
    margin-right:5px;
    margin-left:5px;
    min-height:22px;
    float:left;
    max-width:250px
}
#flashNotifications .notification.chatNotification i.messageEndIcon{
    float:left;
    top:4px;
    margin-left:3px
}
#flashNotifications .notification.chatNotification .firstLine{
    height:40px
}
#flashNotifications .notification.chatNotification .firstLine .avatar{
    float:left;
    left:6px;
    width:40px;
    overflow:hidden;
    height:40px;
    top:3px
}
#flashNotifications .notification.chatNotification .firstLine .avatar .heroImage{
    top:-10px;
    left:-7px
}
#flashNotifications .notification.chatNotification .firstLine .avatar i.kingdomRole{
    top:24px;
    float:left
}
#flashNotifications .notification.chatNotification .firstLine .verticalLine{
    float:left;
    height:40px;
    margin-right:6px;
    margin-left:6px
}
#flashNotifications .notification.chatNotification .firstLine .playerInfos{
    font-weight:bold;
    float:left
}
#flashNotifications .notification.chatNotification .firstLine .playerInfos .kingdomName{
    font-weight:normal;
    float:left
}
</style>

<script>
import lib from '@/lib';

import {kingdomRole} from '@/models/game/Constants';
import AvatarImage from '@/components/elements/AvatarImage';

export default {
	mixins: lib.mixins,
	data: () => ({
		kingdomRole,
		
		nolink: true,
	}),
	computed: {},
	methods: {
		onCloseNotification(index){
			this.gameModel.flashNotifications.doCloseNotification(index);
		},
		onCloseWarning(index){
			this.gameModel.flashNotifications.doCloseWarning(index);
		},
		onCloseChatNotification(index){
			this.gameModel.flashNotifications.doCloseChatNotification(index);
		},
	},
	components: {
		AvatarImage,
	},
}
</script>
<template>
	<div class="unselectable">
		<div id="villageList" :class="{'ongoingAttack': ongoingAttack}" ng-show="authModel.isAuth">
			<div class="currentVillageName dropdown">
				<a class="navigation previous clickable">
					<div class="button">
						<i class="symbol_arrowFrom_tiny_flat_black"></i>
					</div>
				</a>
				<div class="dropdownContainer">
					<div class="dropdownHead openDownwards clickable" :class="{'opened': villagesDropdownOpen, 'closed': !villagesDropdownOpen}" @click="villagesDropdownOpen = !villagesDropdownOpen">
						<span class="selectedItem truncated">
							<div class="villageEntry" :class="{'attack': selIsland.underAttack}">{{selIsland.name}}</div>
							<!--
							<i class="movement_attack_small_flat_red"></i>
							<div class="acceptance" v-if="selIsland.loyalty < 100">
								<span><span>Лояльность</span></span>: {{selIsland.loyalty}}
							</div>
							-->
						</span>
						<i class="symbol_arrowDown_tiny_illu"></i>
					</div>
					<input class="dropdownInput" type="text" />
				</div>
				<a class="navigation next clickable">
					<div class="button">
						<i class="symbol_arrowTo_tiny_flat_black"></i>
					</div>
				</a>
				<router-link :to="{path: '/village-overview'}" id="villageOverview" class="iconButton clickable" content="Обзор островов" v-tippy="{placement: 'bottom'}">
					<i class="villageList_villageOverview_small_flat_black"></i>
				</router-link>
			</div>
		</div>
	</div>
	<div class="dropdownBody villageListDropDown scrollable openDownwards" style="display: block; left: 48px; max-height: 250px; top: 60px;" v-if="villagesDropdownOpen">
		<div class="scrollContentOuterWrapper" style="width: 144px;">
			<div class="scrollContent" style="display: block; width: 144px; overflow-y: auto;">
				<div class="scrollContentInnerWrapper" style="width: auto;">
					<ul>
						<li v-for="island in gameModel.islands" :key="island.id" class="dropdownOption dropDownItem536231946 truncated" :class="{'selected': island.id == selIslandId}" style="text-align: left;">
							<div class="villageEntry" :class="{'attack': island.underAttack}">{{island.name}}</div>
							<i v-if="island.underAttack" class="movement_attack_small_flat_red"></i>
							<div v-if="island.loyalty < 100" class="acceptance">
								Лояльность: {{island.loyalty}}
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		villagesDropdownOpen: false,
	}),
	computed: {
		ongoingAttack(){
			return !!this.gameModel.islands.find((island) => island.underAttack);
		},
		selIsland(){
			return this.gameModel.islands.find((island) => island.id == this.gameModel.island.id) || {};
		},
	},
	methods: {},
	created(){},
	components: {},
}
</script>
<template>
	<div>
		<div @click="useNpcTrader()" :content="type == 'heroRevive' || type == 'celebration' ? 'Открой NPC-торговца и перераспредели ресурсы' : currentMax+' с NPC-торговцем'" v-tippy="{placement: 'top'}" tooltip-translate="Units.Tooltip.MaxWithTrader" class="iconButton premium npcTrade">
			<i class="feature_npcTrader_small_flat_black"></i>
		</div>
	</div>
</template>

<style lang="css" scoped>
	
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		type: {
			type: String,
			default: '', //unitTrain, unitResearch, unitUpgrade, celebration, heroRevive, celebration
		},
		costs: {
			type: [Array, Object],
			required: true,
			default: [0, 0, 0, 0],
		},
		currentMax: {
			type: Number,
			default: 0,
		},
		currentLimit: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		useNpcTrader(){
			
		},
	},
}
</script>
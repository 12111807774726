<template>
	<div id="quickLinks" class="headerButton">
		<div class="buildingQueueSlots">
			<div class="units slotWrapper">
				<div v-for="item in unitBuilding" clickable="gotoBuilding(item.buildingId)" :class="['slotContainer', 'clickable', {disabled: item.state == 'disabled'}]" :content="item.title" v-tippy="{placement: 'bottom-start'}">
					<div class="slot">
						<i :class="{[item.icon+'_small_flat_white']: item.state == 'normal' || item.state == 'disabled', [item.icon+'_small_flat_green']: item.state == 'active', 'disabled': item.state == 'disabled'}"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="arrow"></div>
	</div>
</template>

<style lang="css">
#quickLinks.headerButton{
    position:absolute;
    left:21px;
    top:0;
    width:auto;
}
#quickLinks.headerButton .slotWrapper:after{
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0;
}
#quickLinks.headerButton .slotWrapper .slotContainer{
    width:47px;
    height:20px;
    float:left;
}
#quickLinks.headerButton .slotWrapper .slotContainer:hover:not(.disabled){
    background-image:-owg-linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent);
    background-image:-webkit-linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent);
    background-image:-moz-linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent);
    background-image:-o-linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent);
    background-image:linear-gradient(to bottom,#7b746e,#7b746e 40%,transparent);
}
#quickLinks.headerButton .slotWrapper .slotContainer .slot{
    position:relative;
}
#quickLinks.headerButton .slotWrapper .slotContainer .slot i{
    height:16px;
    display:inherit;
    padding:0 16px 1px;
    top:1px;
}
#quickLinks.headerButton .slotWrapper .slotContainer:not(:last-child){
    border-right:1px solid #7b746e;
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		unitBuilding: [
			{
				show: true,
				buildingId: 25,
				state: 'normal',
				icon: 'building_g17',
				title: '<div class="queueEntryTooltip" v-if="item.state == \'normal\'"><h3 class="queueEntryTitle"><span>Рынок</span></h3><div class="horizontalLine"></div><div class="subinfo" v-if="item.state != \'disabled\'"><span><span>Доступно торговцев: </span><span class="values">2/4</span></span><br><span><span>Занято продажей: </span><span class="values">0/4</span></span><br><span><span>Торговцы в пути: </span><span class="values">2/4</span></span></div></div>',
			},
			{
				show: true,
				buildingId: 29,
				state: 'active',
				icon: 'building_g19',
				title: '<div class="queueEntryTooltip" v-if="item.state == \'active\'"><div class="tableWrapper" ng-repeat="itemData in item.data" ng-show="itemData.queue.length > 0"><table class="transparent"><tbody><tr class="unitQueueTitle"><th class="queueEntryTitle"><h3 class="queueEntryTitle"><span>Казарма</span></h3></th><th class="time"><span><span>08:09:04</span><span>ч</span></span></th></tr></tbody></table><div class="horizontalLine"></div><table class="transparent"><tbody><tr ng-repeat="entry in itemData.queue"><td class="subinfo"><span>18x</span> <span><span>Мечник</span></span></td><td class="subinfo time"><span><span>04:59:19</span><span>ч</span></span></td></tr><tr ng-repeat="entry in itemData.queue"><td class="subinfo"><span>15x</span> <span><span>Фаланга</span></span></td><td class="subinfo time"><span><span>08:09:04</span><span>ч</span></span></td></tr></tbody></table></div></div>',
			},
			{
				show: true,
				buildingId: 31,
				state: 'normal',
				icon: 'building_g20',
				/*title: '<div class="queueEntryTooltip" v-if="item.state == \'normal\'"><h3 class="queueEntryTitle"><span>Конюшня</span></h3><div class="horizontalLine"></div><div class="subinfo"><span>Нет войск в обучении.</span></div></div>',*/
				title: (
					'<div class="queueEntryTooltip">'+
						'<div class="tableWrapper" ng-repeat="itemData in item.data" ng-show="itemData.queue.length > 0">'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr class="unitQueueTitle">'+
										'<th class="queueEntryTitle">'+
											'<h3 class="queueEntryTitle"><span>Конюшня</span></h3>'+
										'</th>'+
										'<th class="time">'+
											'<span><span countdown="1708247736" callback="">00:07:26</span><span>ч</span></span>'+
										'</th>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr ng-repeat="entry in itemData.queue">'+
										'<td class="subinfo">'+
											'<span>1x</span> <span><span>Тевтатский гром</span></span>'+
										'</td>'+
										'<td class="subinfo time">'+
											'<span><span countdown="1708247736" callback="">00:07:26</span><span>ч</span></span>'+
										'</td>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
						'</div>'+
						'<div class="tableWrapper ng-hide" ng-repeat="itemData in item.data" ng-show="itemData.queue.length > 0">'+
							'<table class="transparent">'+
								'<tbody>'+
									'<tr class="unitQueueTitle">'+
										'<th class="queueEntryTitle">'+
											'<h3 class="queueEntryTitle"><span>Большая конюшня</span></h3>'+
										'</th>'+
										'<th class="time">'+
											'<span><span countdown="" callback="">00:00:00</span><span>ч</span></span>'+
										'</th>'+
									'</tr>'+
								'</tbody>'+
							'</table>'+
							'<div class="horizontalLine"></div>'+
							'<table class="transparent">'+
								'<tbody></tbody>'+
							'</table>'+
						'</div>'+
					'</div>'
				),
			},
			{
				show: true,
				buildingId: 0,
				state: 'disabled',
				icon: 'building_g21',
				title: '<div class="queueEntryTooltip" v-if="item.state == \'disabled\'"><h3><span>Кузница</span></h3><div class="horizontalLine"></div><div class="subinfo"><span>Здание еще не построено!</span></div></div>',
				//title: '<div class="queueEntryTooltip" v-if="item.state == \'disabled\'"><h3><span>Кузница</span></h3><div class="horizontalLine"></div><div class="subinfo"><span>Нет войск в обучении!</span></div></div>',
			},
			{
				show: true,
				buildingId: 0,
				state: 'disabled',
				icon: 'building_g46',
				title: '<div class="queueEntryTooltip" v-if="item.state == \'disabled\'"><h3><span>Алтарь</span></h3><div class="horizontalLine"></div><div class="subinfo"><span>Здание еще не построено!</span></div></div>',
				//title: '<div class="queueEntryTooltip" ng-if="item.state == \'normal\'"><h3 class="queueEntryTitle"><span>Целебный шатер</span></h3><div class="horizontalLine"></div><div class="subinfo"><span>В настоящий момент никто не находится на лечении.</span></div></div>',
			},
		],
	}),
	computed: {
		unitBuildingShow(){
			return this.unitBuilding.filter((elem) => elem.show);
		},
	},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="reportBody adventureReport" src="'tpl/report/types/'+reportType+'.html'">
		<div class="adventureReport">
			<div class="contentBox colored heroInfo">
				<div class="contentBoxHeader">
					<div class="content"><span>Герой</span></div>
				</div>
				<div class="contentBoxBody">
					<div v-if="report.xp" content="Опыт" v-tippy="{placement: 'bottom'}" tooltip-translate="Hero.Experience">
						<i class="unit_experience_small_flat_black"></i> +{{report.xp}}
					</div>
					<div v-if="report.hp" class="heroDamage" content="Здоровье" v-tippy="{placement: 'bottom'}" tooltip-translate="Hero.Health">
						<i class="unit_health_small_flat_black"></i> -{{report.hp}}
					</div>
					<div v-if="report.won && Object.entries(report.loot).length == 0" class="infoText">
						<span>Не найдено ничего ценного</span>
					</div>
					<div v-if="!report.won" class="infoText errorText">
						<span>Герой не выжил в приключении.</span>
					</div>
				</div>
			</div>
			<div v-if="Object.entries(report.loot).length > 0" class="contentBox colored adventureBounty">
				<div class="contentBoxHeader">
					<div class="content"><span>Добыча</span></div>
				</div>
				<div class="contentBoxBody">
					<div class="bountyModule" v-if="report.loot.resources">
						<display-resources :resources="report.loot.resources.amount"></display-resources>
					</div>
					<div class="bountyModule" v-if="report.loot.silver">
						<span content="Серебро" v-tippy="{placement: 'bottom'}" tooltip-translate="Silver"><i class="unit_silver_small_illu"></i> {{report.loot.silver.amount}}</span>
					</div>
					<div class="bountyModule troopsBounty" v-if="report.loot.troops">
						<div v-for="troop in report.loot.troops">
							{{troop.amount}}×<i class="unitSmall gaul" :class="['unitType'+troop.unitId]" :content="troop.name" v-tippy="{placement: 'bottom'}"></i>
						</div>
					</div>
					<div class="bountyModule itemBounty" v-if="report.loot.items">
						<div class="itemContainer" v-for="item in report.loot.items">
							<div class="itemImage" :content="item.name" v-tippy="{placement: 'bottom'}">
								<div v-if="item.amount > 1">{{item.amount}}×</div>
								<img src="//iwstatic.g.bsrv.su/img/x.gif" class="male" :class="item.icon" data-cmp-info="10"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import DisplayResources from '@/components/elements//DisplayResources';

export default {
	mixins: lib.mixins,
	props: {
		report: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
	},
	components: {
		DisplayResources,
	},
}
</script>
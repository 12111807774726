<template>
	<div class="farmList" ng-controller="farmListCtrl">
		<div ng-if="hasNoobProtection">
			<span>Ты все еще находишься под защитой новичка и не можешь использовать списки целей.</span>
		</div>
		<div ng-if="!hasNoobProtection">
			<div class="listOverview" ng-if="currentListIndex < 0" v-if="page == 'overview'">
				<div class="contentBox gradient">
					<h6 class="contentBoxHeader headerTrapezoidal marginToScrollbar">
						<div class="content">
							<span translate=""><span>Обзор</span></span>
						</div>
						<div class="newFarmList">
							<span translate=""><span>Новый список целей</span></span>
							<a class="iconButton doubleBorder clickable disabled" ng-class="{disabled: !player.hasPlusAccount() || farmListCollection.data.length >= farmListLimit}" tooltip="" tooltip-show="false" tooltip-translate="FarmList.Tooltip.listLimitReached" clickable="openOverlay('farmListCreation');">
								<i class="symbol_plus_tiny_flat_black"></i>
							</a>
						</div>
					</h6>
					<div class="contentBoxBody scrollable" dynamic="" scrollable="">
						<div class="scrollContentOuterWrapper" style="width: 588px;">
							<div class="scrollContent" dynamic-height="true" style="width: 588px; overflow-y: hidden; max-height: 150px;">
								<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
									<table class="farmListsOverviewTable transparent">
										<tbody ng-repeat="farmlist in farmListCollection.data">
											<tr class="farmListEntry" ng-class="{disabled: !farmlist.data.isDefault && !player.hasPlusAccount()}">
												<td class="selector">
													<div class="clickInputWrapper">
														<input type="checkbox" ng-model="farmlist.checked" ng-change="adjustTroopsInfo();" ng-disabled="farmlist.data.entryIds.length == 0 || (!farmlist.data.isDefault && !player.hasPlusAccount())" class="ng-pristine ng-untouched ng-valid" disabled="disabled" />
														<i class="action_check_tiny_flat_black"></i>
													</div>
												</td>
												<td clickable="(farmlist.data.isDefault || player.hasPlusAccount()) ? openList(628,0) : null" @click="page = 'detail'" class="clickable">
													<div class="listName truncated">Стартовый лист</div>
													<div class="villageCounter">
														<i class="village_village_small_flat_black" content="Деревень в списке" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.Tooltip.villages"></i>
														<span ng-class="{error: farmlist.data.entryIds.length == 0}" class="error">
															0/10
														</span>
													</div>
													<div class="attackCounter">
														<i class="movement_attack_small_flat_gray" ng-if="!farmlist.data.underAttack" content="Атак сейчас нет" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.NoAttack"></i>
														<i class="reportIcon reportTypes_reportIcon115_pending" ng-if="farmlist.data.underAttack && farmlist.data.underAttack < farmlist.data.villageIds.length" content="На некоторые деревни из списка уже отправлены атаки." v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.SomeAttacks"></i>
														<i class="reportIcon reportTypes_reportIcon115_pending" ng-if="farmlist.data.underAttack &amp;&amp; farmlist.data.underAttack == farmlist.data.villageIds.length" content="В данный момент атаки идут на все деревни из списка" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.AllUnderAttack"></i> 
														<span ng-if="farmlist.data.underAttack">2</span>
													</div>
													<div class="listInfo">
														<span ng-if="farmlist.data.isDefault || player.hasPlusAccount()">
															<span ng-if="farmlist.data.troopsAmount > 0 && farmlist.data.lastSent">
																<span translate=""><span>Последний:</span></span>
																<span i18ndt="1722010811" full="true" format="shortDate">Сегодня</span> |
																<span i18ndt="1722010811" full="true" format="mediumTime">21:20:11</span>
															</span>
															<span ng-if="farmlist.data.troopsAmount == 0" class="error" translate=""><span>Войска не назначены</span></span>
														</span>
														<!-- ngIf: !farmlist.data.isDefault && !player.hasPlusAccount() -->
													</div>
												</td>
												<td></td>
											</tr>
											<tr ng-if="$first">
												<td colspan="3">
													<div class="horizontalLine"></div>
												</td>
											</tr>
											<tr ng-if="$first && $last" class="infoRow" ng-class="{plus: player.hasPlusAccount()}">
												<td colspan="3">
													<span ng-if="!player.hasPlusAccount()" translate=""><span>Ты можешь создать дополнительные&nbsp;списки избранных целей с Travian Plus.</span></span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="contentBoxFooter marginToScrollbar">
						<div ng-include="'tpl/building/rallypoint/tabs/partials/FarmListTroopBar.html'">
							<div class="troopBar">
								<div class="troops">
									<div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType1 notUsed" unit-icon="" data="tribeId,info.id" content="Фаланга" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_21" ng-class="{notUsed: info.needed == 0}"></i>
										<div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div>
									</div>
									<div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType2 notUsed" unit-icon="" data="tribeId,info.id" content="Мечник" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_22" ng-class="{notUsed: info.needed == 0}"></i>
										<div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div>
									</div>
									<div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType4 notUsed" unit-icon="" data="tribeId,info.id" content="Тефтатский гром" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_24" ng-class="{notUsed: info.needed == 0}"></i>
										<div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div>
									</div>
									<div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType5 notUsed" unit-icon="" data="tribeId,info.id" content="Друид-всадник" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_25" ng-class="{notUsed: info.needed == 0}"></i>
										<div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div>
									</div>
									<div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType6 notUsed" unit-icon="" data="tribeId,info.id" content="Эдуйская конница" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_26" ng-class="{notUsed: info.needed == 0}"></i>
										<div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div>
									</div>
									<span class="description" translate=""><span>Всего войск</span></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="listDetail" ng-if="currentListIndex >= 0" v-if="page == 'detail'">
				<div class="contentBox gradient">
					<h6 class="contentBoxHeader headerTrapezoidal marginToScrollbar">
						<div class="back clickable" clickable="goBackToOverview()" @click="page = 'overview'" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
							<i ng-class="{
								symbol_arrowFrom_tiny_flat_black: !fromHover,
								symbol_arrowFrom_tiny_flat_green: fromHover
							}" class="symbol_arrowFrom_tiny_flat_black"></i>
							<span translate=""><span>Назад</span></span>
						</div>
						<div class="content">
							<div class="nameWrapper">
								<span class="truncated">Стартовый лист</span>
								<i class="village_village_small_flat_black" content="Деревень в списке" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.Tooltip.villages"></i>
								3/10
							</div>
							<i class="headerButton clickable action_edit_small_flat_black" ng-class="{action_edit_small_flat_black: !editHover, action_edit_small_flat_green: editHover}" on-pointer-over="editHover = true" on-pointer-out="editHover = false" @click="gameModel.buildings.area.farmListEdit = true" clickable="openOverlay('farmListEdit', {'listId': 325 });" content="Редактировать список" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.Tooltip.editList"></i>
						</div>
						<div class="newFarmList">
							<span translate=""><span>Добавить деревню</span></span>
							<a class="iconButton doubleBorder clickable" ng-class="{disabled: farmListCollection.data[currentListIndex].data.entryIds.length >= farmListCollection.data[currentListIndex].data.maxEntriesCount}" @click="gameModel.buildings.area.farmListAddVillage = true" clickable="openOverlay('farmListAddVillage', {'listId': 325 });" content="Добавить деревню" v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{
											'FarmList.Tooltip.villageLimitReached': false,
											'FarmList.Tooltip.addVillage': true
									}">
								<i class="symbol_plus_tiny_flat_black"></i>
							</a>
						</div>
					</h6>
					<div class="contentBoxBody">
						<table class="farmListDetailsTable lined fixedTableHeader scrollable" dynamic="" scrollable="">
							<div class="scrollContentOuterWrapper" style="width: 588px;">
								<div class="scrollContent" dynamic-height="true" style="width: 588px; overflow-y: hidden; max-height: 551px;">
									<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
										<thead>
											<tr ng-class="{reverseSort: reverseSort}" class="marginToScrollbar">
												<th class="selector">
													<div class="clickInputWrapper"><input type="checkbox" ng-change="checkAllChange();" ng-model="checkAll.checked" ng-disabled="currentFarmListEntries.data.length == 0" class="ng-pristine ng-untouched ng-valid"><i class="action_check_tiny_flat_black"></i></div></th>
												<th class="combat clickable" content="Текущие атаки" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.Tooltip.CurrentAttacks" ng-class="{sort: sort=='underAttack.count'}" clickable="sortBy('underAttack.count')">
													<i class="movement_attack_small_flat_gray"></i>
												</th>
												<th class="name clickable" ng-class="{sort: sort=='villageName'}" clickable="sortBy('villageName')">
													<span translate=""><span>Деревня</span></span>
												</th>
												<th class="population clickable" content="Население" v-tippy="{placement: 'bottom'}" tooltip-translate="Population" ng-class="{sort: sort=='population'}" clickable="sortBy('population')">
													<i class="unit_population_small_flat_black"></i>
												</th>
												<th class="distance clickable sort" content="Дист." v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.Tooltip.distance" ng-class="{sort: sort=='distance'}" clickable="sortBy('distance')">
													<i class="unit_distance_small_flat_black"></i>
												</th>
												<th content="Войска" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.Tooltip.AssignedTroops" ng-class="{sort: sort=='troopsAmount'}" clickable="sortBy('troopsAmount')" class="clickable">
													<i class="symbol_generalTroops_small_flat_black"></i>
												</th>
												<th content="Потери в последнем набеге" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.Tooltip.LastRaidSuccess" ng-class="{sort: sort=='lastReport.notificationType'}" clickable="sortBy('lastReport.notificationType')" class="clickable">
													<i class="movement_attack_small_flat_gray"></i>
												</th>
												<th class="carryCol clickable" content="Добыча в последнем набеге" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.Tooltip.carryStatus" ng-class="{sort: sort=='lastReport.raidedResSum'}" clickable="sortBy('lastReport.raidedResSum')">
													<i class="unit_capacity_small_flat_black"></i>
												</th>
												<th class="lastReport clickable" ng-class="{sort: sort=='lastReport.time'}" clickable="sortBy('lastReport.time')">
													<span translate=""><span>Последняя атака</span></span>
												</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<!-- ngRepeat: farm in currentFarmListEntries.data --><tr ng-repeat="farm in currentFarmListEntries.data">
												<td class="selector" tooltip="" tooltip-data="protectionEnd:0,maxPerTarget:50,maxTotal:2000" tooltip-translate-switch="{
														'FarmList.Tooltip.NoobProtection':false,
														'FarmList.Tooltip.NightPeace':false,
														'FarmList.Tooltip.TargetOnVacation':false,
														'Error.PlayerRelocated':false,
														'FarmList.Tooltip.TargetInRelocationProtection':false,
														'Error.MaxMovementsPerTargetReached':false,
														'Error.MaxMovementsReached':false}">
													<div class="clickInputWrapper"><input type="checkbox" ng-model="farm.checked" ng-change="adjustTroopsInfo()" ng-disabled="entryAttackInfo[farm.data.entryId].noobProtection > 0 || entryAttackInfo[farm.data.entryId].nightPeaceStatus > 0
																		|| entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_IS_ON_VACATION || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_PLAYER_WILL_BE_ON_VACATION || entryAttackInfo[farm.data.entryId].relocation
																		|| movementCounts[farm.data.villageId] >= config.balancing.movementLimitations.maxPerTarget || (!farm.checked &amp;&amp; (movementCounts.sum + checkedCount) >= config.balancing.movementLimitations.maxTotal)" class="ng-pristine ng-untouched ng-valid"><i class="action_check_tiny_flat_black"></i></div>
												</td>
												<td class="combat">
													<!-- ngIf: !farm.data.underAttack --><i class="movement_attack_small_flat_gray" ng-if="!farm.data.underAttack" content="Атак сейчас нет" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.NoAttack"></i><!-- end ngIf: !farm.data.underAttack -->
													<!-- ngIf: farm.data.underAttack -->
												</td>
												<td class="name">
													<div class="villageNameContainer">
														<!-- ngIf: farm.data.isAlly || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_WILL_BE_ON_VACATION || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_IS_ON_VACATION -->
														<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536100851}, null, true)" village-link="" villagename="Оазис (-13|-24)" villageid="536100851">Оазис (-13|-24)</a>
													</div>
												</td>
												<td class="population">
													<!-- ngIf: !farm.data.isOasis -->
												</td>
												<td class="distance">
													<span>5</span>
												</td>
												<td ng-class="{error: farm.data.troopsAmount == 0}" tooltip="" tooltip-show="0" tooltip-url="tpl/building/rallypoint/tabs/partials/AssignedTroopsTooltip.html" class="error">
													-
												</td>
												<td>
													<!-- ngIf: farm.data.lastReport.notificationType -->
												</td>
												<td class="carryCol">
													<!-- ngIf: farm.data.lastReport.capacity > 0 -->
												</td>
												<td class="lastReport">
													<!-- ngIf: farm.data.lastReport.time > 0 -->
												</td>
												<td>
													<div class="options">
														<div class="optionContainer clickable" clickable="openWindow('farmListAdd', {'targetId': 536100851, 'entryId': 18142, 'entryAction': 2 })" on-pointer-over="copyHover = true" on-pointer-out="copyHover = false" content="Скопировать деревню в другой список" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.CopyToOtherList">
															<i class="copy farmlist_copy_small_flat_black" ng-class="{farmlist_copy_small_flat_black: !copyHover, farmlist_copy_small_flat_green: copyHover}"></i>
														</div>
														<div class="optionContainer clickable" clickable="deleteEntry(18142)" on-pointer-over="deleteHover = true" on-pointer-out="deleteHover = false" content="Удалить" v-tippy="{placement: 'bottom'}" tooltip-translate="Button.Delete">
															<i ng-class="{action_delete_small_flat_black: !deleteHover, action_delete_small_flat_red: deleteHover}" class="action_delete_small_flat_black"></i>
														</div>
													</div>
												</td>
											</tr><!-- end ngRepeat: farm in currentFarmListEntries.data --><tr ng-repeat="farm in currentFarmListEntries.data">
												<td class="selector" tooltip="" tooltip-data="protectionEnd:0,maxPerTarget:50,maxTotal:2000" tooltip-translate-switch="{
														'FarmList.Tooltip.NoobProtection':false,
														'FarmList.Tooltip.NightPeace':false,
														'FarmList.Tooltip.TargetOnVacation':false,
														'Error.PlayerRelocated':false,
														'FarmList.Tooltip.TargetInRelocationProtection':false,
														'Error.MaxMovementsPerTargetReached':false,
														'Error.MaxMovementsReached':false}">
													<div class="clickInputWrapper"><input type="checkbox" ng-model="farm.checked" ng-change="adjustTroopsInfo()" ng-disabled="entryAttackInfo[farm.data.entryId].noobProtection > 0 || entryAttackInfo[farm.data.entryId].nightPeaceStatus > 0
																		|| entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_IS_ON_VACATION || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_PLAYER_WILL_BE_ON_VACATION || entryAttackInfo[farm.data.entryId].relocation
																		|| movementCounts[farm.data.villageId] >= config.balancing.movementLimitations.maxPerTarget || (!farm.checked &amp;&amp; (movementCounts.sum + checkedCount) >= config.balancing.movementLimitations.maxTotal)" class="ng-pristine ng-untouched ng-valid"><i class="action_check_tiny_flat_black"></i></div>
												</td>
												<td class="combat">
													<!-- ngIf: !farm.data.underAttack -->
													<!-- ngIf: farm.data.underAttack --><i class="reportIcon reportTypes_reportIcon3_flat" ng-if="farm.data.underAttack" :content="tooltips[2]" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.UnderAttack" tooltip-data="count:1,next:1722009835"></i><!-- end ngIf: farm.data.underAttack -->
												</td>
												<td class="name">
													<div class="villageNameContainer">
														<!-- ngIf: farm.data.isAlly || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_WILL_BE_ON_VACATION || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_IS_ON_VACATION -->
														<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536428530}, null, true)" village-link="" villagename="Оазис (-14|-14)" villageid="536428530">Оазис (-14|-14)</a>
													</div>
												</td>
												<td class="population">
													<!-- ngIf: !farm.data.isOasis -->
												</td>
												<td class="distance">
													<span>5</span>
												</td>
												<td ng-class="{error: farm.data.troopsAmount == 0}" tooltip="" tooltip-show="0" tooltip-url="tpl/building/rallypoint/tabs/partials/AssignedTroopsTooltip.html" class="error">
													-
												</td>
												<td>
													<!-- ngIf: farm.data.lastReport.notificationType -->
												</td>
												<td class="carryCol">
													<!-- ngIf: farm.data.lastReport.capacity > 0 -->
												</td>
												<td class="lastReport">
													<!-- ngIf: farm.data.lastReport.time > 0 -->
												</td>
												<td>
													<div class="options">
														<div class="optionContainer clickable" clickable="openWindow('farmListAdd', {'targetId': 536428530, 'entryId': 18137, 'entryAction': 2 })" on-pointer-over="copyHover = true" on-pointer-out="copyHover = false" content="Скопировать деревню в другой список" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.CopyToOtherList">
															<i class="copy farmlist_copy_small_flat_black" ng-class="{farmlist_copy_small_flat_black: !copyHover, farmlist_copy_small_flat_green: copyHover}"></i>
														</div>
														<div class="optionContainer clickable" clickable="deleteEntry(18137)" on-pointer-over="deleteHover = true" on-pointer-out="deleteHover = false" content="Удалить" v-tippy="{placement: 'bottom'}" tooltip-translate="Button.Delete">
															<i ng-class="{action_delete_small_flat_black: !deleteHover, action_delete_small_flat_red: deleteHover}" class="action_delete_small_flat_black"></i>
														</div>
													</div>
												</td>
											</tr><!-- end ngRepeat: farm in currentFarmListEntries.data --><tr ng-repeat="farm in currentFarmListEntries.data">
												<td class="selector" tooltip="" tooltip-data="protectionEnd:0,maxPerTarget:50,maxTotal:2000" tooltip-translate-switch="{
														'FarmList.Tooltip.NoobProtection':false,
														'FarmList.Tooltip.NightPeace':false,
														'FarmList.Tooltip.TargetOnVacation':false,
														'Error.PlayerRelocated':false,
														'FarmList.Tooltip.TargetInRelocationProtection':false,
														'Error.MaxMovementsPerTargetReached':false,
														'Error.MaxMovementsReached':false}">
													<div class="clickInputWrapper"><input type="checkbox" ng-model="farm.checked" ng-change="adjustTroopsInfo()" ng-disabled="entryAttackInfo[farm.data.entryId].noobProtection > 0 || entryAttackInfo[farm.data.entryId].nightPeaceStatus > 0
																		|| entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_IS_ON_VACATION || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_PLAYER_WILL_BE_ON_VACATION || entryAttackInfo[farm.data.entryId].relocation
																		|| movementCounts[farm.data.villageId] >= config.balancing.movementLimitations.maxPerTarget || (!farm.checked &amp;&amp; (movementCounts.sum + checkedCount) >= config.balancing.movementLimitations.maxTotal)" class="ng-pristine ng-untouched ng-valid"><i class="action_check_tiny_flat_black"></i></div>
												</td>
												<td class="combat">
													<!-- ngIf: !farm.data.underAttack --><i class="movement_attack_small_flat_gray" ng-if="!farm.data.underAttack" content="Атак сейчас нет" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.NoAttack"></i><!-- end ngIf: !farm.data.underAttack -->
													<!-- ngIf: farm.data.underAttack -->
												</td>
												<td class="name">
													<div class="villageNameContainer">
														<!-- ngIf: farm.data.isAlly || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_WILL_BE_ON_VACATION || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_IS_ON_VACATION --><i ng-if="farm.data.isAlly || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_WILL_BE_ON_VACATION || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_IS_ON_VACATION" class="symbol_warning_tiny_flat_red" content="Осторожно, игрок является союзником." v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{
															'FarmList.Tooltip.VacationWarning': false,
															'FarmList.Tooltip.Vacation': false,
															'FarmList.Tooltip.FriendWarning': true}">
														</i><!-- end ngIf: farm.data.isAlly || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_WILL_BE_ON_VACATION || entryAttackInfo[farm.data.entryId].vacation == FarmList.INFO_TARGET_IS_ON_VACATION -->
														<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536395770}, null, true)" village-link="" villagename="Halak" villageid="536395770">Halak</a>
													</div>
												</td>
												<td class="population">
													<!-- ngIf: !farm.data.isOasis --><span ng-if="!farm.data.isOasis">1055</span><!-- end ngIf: !farm.data.isOasis -->
												</td>
												<td class="distance">
													<span>7</span>
												</td>
												<td ng-class="{error: farm.data.troopsAmount == 0}" :content="tooltips[1]" v-tippy="{placement: 'bottom'}" tooltip-show="0" tooltip-url="tpl/building/rallypoint/tabs/partials/AssignedTroopsTooltip.html" class="error">
													1
												</td>
												<td>
													<!-- ngIf: farm.data.lastReport.notificationType -->
												</td>
												<td class="carryCol">
													<!-- ngIf: farm.data.lastReport.capacity > 0 -->
												</td>
												<td class="lastReport">
													<!-- ngIf: farm.data.lastReport.time > 0 -->
												</td>
												<td>
													<div class="options">
														<div class="optionContainer clickable" clickable="openWindow('farmListAdd', {'targetId': 536395770, 'entryId': 18077, 'entryAction': 2 })" on-pointer-over="copyHover = true" on-pointer-out="copyHover = false" content="Скопировать деревню в другой список" v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.CopyToOtherList">
															<i class="copy farmlist_copy_small_flat_black" ng-class="{farmlist_copy_small_flat_black: !copyHover, farmlist_copy_small_flat_green: copyHover}"></i>
														</div>
														<div class="optionContainer clickable" clickable="deleteEntry(18077)" on-pointer-over="deleteHover = true" on-pointer-out="deleteHover = false" content="Удалить" v-tippy="{placement: 'bottom'}" tooltip-translate="Button.Delete">
															<i ng-class="{action_delete_small_flat_black: !deleteHover, action_delete_small_flat_red: deleteHover}" class="action_delete_small_flat_black"></i>
														</div>
													</div>
												</td>
											</tr><!-- end ngRepeat: farm in currentFarmListEntries.data -->
										</tbody>
									</div>
								</div>
							</div>
						</table>
					</div>
					<div class="contentBoxFooter marginToScrollbar">
						<div ng-include="'tpl/building/rallypoint/tabs/partials/FarmListTroopBar.html'"><div class="troopBar">
								<div class="troops">
									<!-- ngRepeat: info in troopInfo --><div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType1 notUsed" unit-icon="" data="tribeId,info.id" content="Фаланга" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_21" ng-class="{notUsed: info.needed == 0}"></i>
										<!-- ngIf: info.needed == 0 --><div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div><!-- end ngIf: info.needed == 0 -->
										<div class="troop" ng-if="info.needed > 0" ng-class="{enoughTroops: info.enoughTroops}">
											3
										</div>
									</div><!-- end ngRepeat: info in troopInfo --><div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType2 notUsed" unit-icon="" data="tribeId,info.id" content="Мечник" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_22" ng-class="{notUsed: info.needed == 0}"></i>
										<!-- ngIf: info.needed == 0 --><div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div><!-- end ngIf: info.needed == 0 -->
										<div class="troop" ng-if="info.needed > 0" ng-class="{enoughTroops: info.enoughTroops}">
											3
										</div>
									</div><!-- end ngRepeat: info in troopInfo --><div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType4 notUsed" unit-icon="" data="tribeId,info.id" content="Тевтатский гром" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_24" ng-class="{notUsed: info.needed == 0}"></i>
										<!-- ngIf: info.needed == 0 --><div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div><!-- end ngIf: info.needed == 0 -->
										<div class="troop" ng-if="info.needed > 0" ng-class="{enoughTroops: info.enoughTroops}">
											3
										</div>
									</div><!-- end ngRepeat: info in troopInfo --><div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType5 notUsed" unit-icon="" data="tribeId,info.id" content="Друид-всадник" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_25" ng-class="{notUsed: info.needed == 0}"></i>
										<!-- ngIf: info.needed == 0 --><div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div><!-- end ngIf: info.needed == 0 -->
										<div class="troop" ng-if="info.needed > 0" ng-class="{enoughTroops: info.enoughTroops}">
											3
										</div>
									</div><!-- end ngRepeat: info in troopInfo --><div class="troopInfo" ng-repeat="info in troopInfo">
										<i class="unitSmall gaul unitType6 notUsed" unit-icon="" data="tribeId,info.id" content="Эдуйская конница" v-tippy="{placement: 'bottom'}" tooltip-translate="Troop_26" ng-class="{notUsed: info.needed == 0}"></i>
										<!-- ngIf: info.needed == 0 --><div class="troop enoughTroops" ng-if="info.needed == 0">
											-
										</div><!-- end ngIf: info.needed == 0 -->
										<div class="troop" ng-if="info.needed > 0" ng-class="{enoughTroops: info.enoughTroops}">
											3
										</div>
									</div><!-- end ngRepeat: info in troopInfo -->
									<span class="description" translate=""><span>Всего войск</span></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div ng-show="any" class="dialogWarning ng-hide" keys="RallyPoint.SendTroops.Warning.PendingRelocation:false">
				<div class="warningHeader">
					<i class="symbol_warning_tiny_flat_white"></i>
					<span translate=""><span>Предупреждение:</span></span>
				</div>
				<div class="warningContent" ng-class="{single:warnings.length == 1}">
					<ul></ul>
				</div>
			</div>
			<div class="marginToScrollbar">
				<button @click="gameModel.buildings.area.farmListEditTroops = true" clickable="openOverlay('farmListEditTroops', {'listId': 0, 'entryIds': [] });" ng-class="{disabled: selectedEntries.length == 0}" class="animate clickable disabled" content="Список не выбран/нет подсказки" v-tippy="{placement: 'bottom'}" tooltip-translate-switch="{
					'FarmList.Tooltip.noListSelected': true,
					'FarmList.Tooltip.noVillageSelected': false
				}" tooltip-show="true">
					<div class="content">
						<span ng-transclude="" class="innerText">
							<span translate=""><span>Редактировать войска</span></span>
						</span>
						<div class="animationOverlay"></div>
						<i class="checkmark action_check_small_flat_white"></i>
						<i class="checkmark action_check_small_flat_black"></i>
						<i class="checkmark action_check_small_flat_red"></i>
					</div>
				</button>
				<button clickable="startRaid()" class="animate startRaid clickable disabled" ng-class="{disabled: selectedEntries.length == 0 || troopsAmountNeeded == 0 || player.isOnVacation() || attackableTargets == 0 || pendingRelocation}" content="Список не выбран/нет подсказки/Не все деревни можно атаковать" v-tippy="{placement: 'bottom'}" tooltip-show="true" tooltip-translate-switch="{
					'FarmList.Tooltip.PlayerOnVacation': false,
					'FarmList.Tooltip.noListSelected': true,
					'FarmList.Tooltip.noVillageSelected': false,
					'FarmList.Notice.notEnoughTroops': false
				}">
					<div class="content">
						<span ng-transclude="" class="innerText">
							<span translate=""><span>Начать набег</span></span>
						</span>
						<div class="animationOverlay"></div>
						<i class="checkmark action_check_small_flat_white"></i>
						<i class="checkmark action_check_small_flat_black"></i>
						<i class="checkmark action_check_small_flat_red"></i>
					</div>
				</button>
				<i class="troopsWarning symbol_warning_tiny_flat_red" ng-if="troopsAmountNeeded > 0 &amp;&amp; !enoughTroops" content="Недостаточно войска для атаки всех деревень. Деревни будут атакованы в том порядке, в котором они располагаются в списке целей." v-tippy="{placement: 'bottom'}" tooltip-translate="FarmList.Tooltip.TroopsWarning"></i>
				<!-- ngIf: currentListIndex < 0 && someTargetInNoobProtection -->
				<!-- ngIf: currentListIndex < 0 && nightPeaceEffect > 0 -->
				<!-- ngIf: currentListIndex < 0 && someTargetOnVacation -->
				<!-- ngIf: player.isOnVacation() -->
				<!-- ngIf: currentListIndex < 0 && someTargetInRelocationProtection -->
				<!-- ngIf: relocationProtection > 1 -->
				<!-- ngIf: currentListIndex < 0 && someTargetWithMaxMovements -->
				<!-- ngIf: currentListIndex < 0 && movementCounts.sum + attackableTargets >= config.balancing.movementLimitations.maxTotal -->
				<!-- ngIf: error -->
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		page: 'overview',
		
		tooltips: {
			1: `<div class="assignedTroopsTooltip">
	<table class="transparent">
		<tbody><tr>
			<!-- ngRepeat: n in [] | range:1:6 --><!-- ngIf: n != spyId && farm.data.units[n] > 0 --><td ng-repeat="n in [] | range:1:6" ng-if="n != spyId &amp;&amp; farm.data.units[n] > 0">
				<i class="unitSmall gaul unitType1" unit-icon="" data="n+(player.data.tribeId-1)*10"></i>
				1
			</td><!-- end ngIf: n != spyId && farm.data.units[n] > 0 --><!-- end ngRepeat: n in [] | range:1:6 --><!-- ngIf: n != spyId && farm.data.units[n] > 0 --><!-- end ngRepeat: n in [] | range:1:6 --><!-- ngIf: n != spyId && farm.data.units[n] > 0 --><!-- end ngRepeat: n in [] | range:1:6 --><!-- ngIf: n != spyId && farm.data.units[n] > 0 --><!-- end ngRepeat: n in [] | range:1:6 --><!-- ngIf: n != spyId && farm.data.units[n] > 0 --><!-- end ngRepeat: n in [] | range:1:6 --><!-- ngIf: n != spyId && farm.data.units[n] > 0 --><!-- end ngRepeat: n in [] | range:1:6 -->
		</tr>
	</tbody></table>
</div>`,
			2: `<span translate="" options="FarmList.UnderAttack"><span>Исходящих атак: 1 | </span><span countdown="1722010341" callback="">00:45:59</span></span>`,
		},
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="tabCulturePoints contentBox hiddenTab">
		<div class="contentBoxBody">
			<div class="rowContainer">
				<div class="first">
					<div class="contentBox usedSlots gradient">
						<h6 class="contentBoxHeader headerTrapezoidal">
							<div class="content" translate=""><span>Занятые слоты культуры</span></div>
						</h6>
						<table class="transparent contentBoxBody">
							<thead>
								<tr>
									<th></th>
									<th translate=""><span>Количество</span></th>
									<th translate=""><span>Занято</span></th>
								</tr>
							</thead>
							<tbody>
								<tr class="dividerLine active" ng-class="{active: expansionSlots.numberOfVillages > 0}">
									<td translate=""><span>Деревни</span></td>
									<td>2</td>
									<td>2</td>
								</tr>
								<tr ng-class="{active: expansionSlots.numberOfTowns > 0}">
								<td translate=""><span>Города</span></td>
									<td>0</td>
									<td>0</td>
								</tr>
								<tr>
									<td colspan="3" class="placeholder"></td>
								</tr>
								<tr class="dividerLine active" ng-class="{active: expansionSlots.numberOfVillages + expansionSlots.numberOfTowns > 0}">
									<td translate=""><span>Всего</span></td>
									<td></td>
									<td>2</td>
								</tr>
							</tbody>
						</table>
					</div>
					
					<div class="culturePerDay contentBox gradient">
						<h6 class="contentBoxHeader headerTrapezoidal">
							<div class="content" translate=""><span>Культура за день</span></div>
						</h6>
						<div class="contentBoxBody">
							<div class="symbol_i_tiny_wrapper">
								<i class="symbol_i_tiny_flat_white" content="Чем выше уровень зданий твоей деревни, тем больше культуры в ней производится." v-tippy="{placement: 'right'}" tooltip-translate="ExpansionSlots.CulturePerDay.Description" tooltip-placement="after"></i>
							</div>
							<table class="transparent">
								<tbody>
									<tr ng-class="{active: cpProduction.activeVillage > 0}" class="active">
										<td translate=""><span>Выбранная деревня</span></td>
										<td>137</td>
									</tr>
									<tr class="dividerLine active" ng-class="{active: cpProduction.otherVillages > 0}">
										<td translate=""><span>Остальные деревни</span></td>
										<td>457</td>
									</tr>
									<tr ng-class="{active: cpProduction.hero > 0}">
										<td translate=""><span>Герой</span></td>
										<td>0</td>
									</tr>
									<tr class="dividerLine active" ng-class="{active: cpProduction.sum > 0}">
										<td translate=""><span>Общее производство</span></td>
										<td>594</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				
				<div class="second">
					<div class="slotOverview contentBox gradient">
						<h6 class="contentBoxHeader headerTrapezoidal">
							<div class="content" translate=""><span>Следующие слоты культуры</span></div>
						</h6>
						<div class="contentBoxBody">
							<div class="cultureSlot arrowContainer arrowDirectionTo active" ng-class="{active: slot.required <= 0, disabled: slot.required > 0}" ng-repeat="slot in expansionSlots.slots">
								<span class="arrowInside">3</span>
								<span class="arrowOutside" ng-if="slot.required <= 0" content="Свободный слот" v-tippy="{placement: 'bottom'}" tooltip-translate="ExpansionSlots.ExpansionList.FreeSlot">5000</span>

								<!-- ngIf: slot.required > 0 && slot.showDetails -->
								<!-- ngIf: slot.required > 0 && !slot.showDetails -->
							</div>
							<div class="cultureSlot arrowContainer arrowDirectionTo active" ng-class="{active: slot.required <= 0, disabled: slot.required > 0}" ng-repeat="slot in expansionSlots.slots">
								<span class="arrowInside">4</span>
								<span class="arrowOutside" ng-if="slot.required <= 0" content="Свободный слот" v-tippy="{placement: 'bottom'}" tooltip-translate="ExpansionSlots.ExpansionList.FreeSlot">10000</span>

								<!-- ngIf: slot.required > 0 && slot.showDetails -->
								<!-- ngIf: slot.required > 0 && !slot.showDetails -->
							</div>
							<div class="cultureSlot arrowContainer arrowDirectionTo disabled" ng-class="{active: slot.required <= 0, disabled: slot.required > 0}" ng-repeat="slot in expansionSlots.slots">
								<span class="arrowInside">5</span>
								<!-- ngIf: slot.required <= 0 -->

								<div class="arrowOutside deactivated withProgress" ng-if="slot.required > 0 &amp;&amp; slot.showDetails" content="Достаточно единиц культуры: 24.07.24 03:13" v-tippy="{placement: 'bottom'}" tooltip-translate="ExpansionSlots.ExpansionList.AvailableIn" tooltip-data="time:1721772787">
									<span>&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;16212&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;20000&#x202C;&#x202C;&#x202C;&#x202C;</span>
									<!-- ngIf: expansionSlots.timeNeeded - currentServerTime <= 172800 -->
									<span class="countdown" ng-if="expansionSlots.timeNeeded - currentServerTime > 172800" i18ndt="1721772787" relative="from">через 6 дней</span>
									<div class="progressbar" progressbar="" perc="81">
										<!-- ngIf: ::labelIcon -->
										<div class="progress">
											<!-- ngIf: steps -->
											<div class="bar positive perc " style="z-index: 2; width: 81%;"></div>
											<div class="bar additionalBar additionalPerc"></div>
											<div class="marker">
												<div class="markerInner"></div>
											</div>
											<!-- ngIf: ::showCountdown -->
											<!-- ngIf: ::label -->
											<!-- ngIf: ::percTooltip -->
										</div>
									</div>
								</div>
								<!-- ngIf: slot.required > 0 && !slot.showDetails -->
							</div>
							<div class="cultureSlot arrowContainer arrowDirectionTo disabled" ng-class="{active: slot.required <= 0, disabled: slot.required > 0}" ng-repeat="slot in expansionSlots.slots">
								<span class="arrowInside">6</span>
								<!-- ngIf: slot.required <= 0 -->

								<!-- ngIf: slot.required > 0 && slot.showDetails -->
								<div class="arrowOutside deactivated" ng-if="slot.required > 0 &amp;&amp; !slot.showDetails " content="Слот пока недоступен" v-tippy="{placement: 'bottom'}" tooltip-translate="ExpansionSlots.ExpansionList.NotAvailable">
									<span>&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;16212&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;40000&#x202C;&#x202C;&#x202C;&#x202C;</span>
								</div>
							</div>
							<div class="cultureSlot arrowContainer arrowDirectionTo disabled" ng-class="{active: slot.required <= 0, disabled: slot.required > 0}" ng-repeat="slot in expansionSlots.slots">
								<span class="arrowInside">7</span>
								<!-- ngIf: slot.required <= 0 -->

								<!-- ngIf: slot.required > 0 && slot.showDetails -->
								<div class="arrowOutside deactivated" ng-if="slot.required > 0 &amp;&amp; !slot.showDetails " content="Слот пока недоступен" v-tippy="{placement: 'bottom'}" tooltip-translate="ExpansionSlots.ExpansionList.NotAvailable">
									<span>&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;16212&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;&#x202D;&#x202D;70000&#x202C;&#x202C;&#x202C;&#x202C;</span>
								</div>
							</div>
							<div class="cultureSlot arrowContainer arrowDirectionTo disabled" ng-class="{active: slot.required <= 0, disabled: slot.required > 0}" ng-repeat="slot in expansionSlots.slots">
								<span class="arrowInside">8</span>
								<!-- ngIf: slot.required <= 0 -->

								<!-- ngIf: slot.required > 0 && slot.showDetails -->
								<div class="arrowOutside deactivated" ng-if="slot.required > 0 &amp;&amp; !slot.showDetails " content="Слот пока недоступен" v-tippy="{placement: 'bottom'}" tooltip-translate="ExpansionSlots.ExpansionList.NotAvailable">
									<span>&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;16212&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;110k&#x202C;&#x202C;</span>
								</div>
							</div>
							<div class="cultureSlot arrowContainer arrowDirectionTo disabled" ng-class="{active: slot.required <= 0, disabled: slot.required > 0}" ng-repeat="slot in expansionSlots.slots">
								<span class="arrowInside">9</span>
								<!-- ngIf: slot.required <= 0 -->

								<!-- ngIf: slot.required > 0 && slot.showDetails -->
								<div class="arrowOutside deactivated" ng-if="slot.required > 0 &amp;&amp; !slot.showDetails " content="Слот пока недоступен" v-tippy="{placement: 'bottom'}" tooltip-translate="ExpansionSlots.ExpansionList.NotAvailable">
									<span>&lrm;&lrm;&#x202D;&#x202D;&#x202D;&#x202D;16212&#x202C;&#x202C;&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;150k&#x202C;&#x202C;</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
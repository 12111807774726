<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Целебный шатер</span></span>
									<span class="level">
										(<span><span>Уровень 0</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>Раненых солдат (пехота и кавалерия) можно исцелить в Целебном шатре. Чем выше уровень Целебного шатра, тем больше раненых он вмещает и тем быстрее проходит лечение.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<div class="buildingStatus location27 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<nav class="tabulation  maintab unselectable">
									<a class="tab clickable" :class="{active: tab == 'healing', inactive: tab != 'healing'}" @click="onToggleTab('healing')">
										<div class="content">
											<span>Лечение</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'capacity', inactive: tab != 'capacity'}" @click="onToggleTab('capacity')">
										<div class="content">
											<span>Вместимость</span>
										</div>
									</a>
								</nav>
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-include="" src="pageTemplate">
													<div class="buildingDetails trainTroops healingTent" ng-controller="healingTentCtrl">
														<div ng-show="isBuildingBuild()">
															<div class="loadedTab currentTab" v-if="tab == 'healing'">
																<healing />
															</div>
															<div class="loadedTab currentTab" v-if="tab == 'capacity'">
																<capacity />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import Healing from '@/components/pages/buildings/healing/Healing';
import Capacity from '@/components/pages/buildings/healing/Capacity';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Целебный шатер | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		tab: 'healing',
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_8.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.tab) this.tab = this.$route.query.tab;
		else this.$router.push({query: {tab: this.tab}});
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(!to.query.tab) this.$router.push({query: {tab: 'healing'}});
		else if(to.query.tab != this.tab) this.tab = to.query.tab;
		next();
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {
		Healing,
		Capacity,
	},
}
</script>
import axios from 'axios';
import lib from '@/lib';

export default class TimedNotificationsModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.show = true;
		
		this.list = [
			{
				type: 'disabled',
				icon: 'notification_systemMessage_medium_flat_black',
				time: ((((0 * 60) + 19) * 60) + 43) * 1000,
				expireTime: 0,
				title: 'Отключение сервера в 16:00 (GMT +06:00)<br/>Отключение сервера для заливки обновлений.<br/>Избегайте массовых боев и штурмов.',
				onClick: () => {},
			},
			{
				type: 'clickable',
				icon: 'notification_systemMessage_medium_flat_black',
				time: ((((22 * 60) + 19) * 60) + 43) * 1000,
				expireTime: 0,
				title: 'Важное системное сообщение',
				onClick: () => {
					this.$router.push({path: '/system-message'});
				},
			},
			{
				type: 'clickable',
				icon: 'notification_systemMessageAnnounce_medium_flat_black',
				time: ((((22 * 60) + 19) * 60) + 43) * 1000,
				expireTime: 0,
				title: 'Объявление',
				onClick: () => {
					this.$router.push({path: '/system-message-2'});
				},
			},
			{
				type: 'disabled',
				icon: 'notification_relocationCooldown_medium_flat_black',
				time: ((((((0 * 24) + 12) * 60) + 0) * 60) + 0) * 1000,
				expireTime: 0,
				title: 'Ты только что переехал и не можешь атаковать других игроков до 11:54:23',
				onClick: () => {},
			},
			{
				type: 'disabled',
				icon: 'feature_beginnersProtection_medium_flat_black',
				time: ((((((6 * 24) + 1) * 60) + 0) * 60) + 0) * 1000,
				expireTime: 0,
				title: 'Ты будешь находиться под защитой еще 119:15:41.<br/>Внимание: Защита также спадет, как только ты достигнешь населения 200!',
				onClick: () => {},
			},
			{
				type: 'disabled',
				icon: 'feature_natars_medium_flat_black',
				time: ((((((6 * 24) + 1) * 60) + 0) * 60) + 0) * 1000,
				expireTime: 0,
				title: 'Чудеса света появятся через 67:10:35',
				onClick: () => {},
			},
			{
				type: 'clickable',
				icon: 'notification_goldPromotion_medium_flat_black',
				time: ((((((6 * 24) + 1) * 60) + 0) * 60) + 0) * 1000,
				expireTime: 0,
				title: 'Распродажа (+30%): 1д 08ч 57м 30с',
				onClick: () => {
					this.$router.push({path: '/shop'});
				},
			},
			/*<a ng-repeat="n in notifications.data | orderBy:'data.time':true" class="notification clickable" ng-class="{disabled: !isClickable(n.data.type)}" clickable="openNotification(104)" tooltip="" tooltip-translate="Notification_104" tooltip-data="expireTime:0,data:" tooltip-placement="above" ng-if="n.data.time < currentServerTime && (n.data.expireTime == 0 || n.data.expireTime > currentServerTime)">
				<i class="feature_worldWonder_medium_flat_black"></i>
				<!-- ngIf: n.data.expireTime > 0 -->
			</a>*/
			{
				type: 'clickable',
				icon: 'feature_worldWonder_medium_flat_black',
				time: 0 * 1000,
				expireTime: 0,
				title: 'Игровой раунд завершен',
				onClick: () => {
					this.$router.push({path: '/shop'});
				},
			},
		];
	}
}

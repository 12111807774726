<template>
	<div class="reportBody fightReport" src="'tpl/report/types/'+reportType+'.html'">
		<div class="troopsScrollContainer">
			<div class="infoContainer" ng-if="someInfoModules">
				<div ng-if="infoModules.loot || infoModules.spy" class="resourcesModule">
					<span ng-if="infoModules.loot" class="caption" translate=""><span>Добыча</span></span>
					<!-- ngIf: infoModules.spy && !infoModules.spy.noSurvivors -->
					<span ng-if="infoModules.loot" class="loot">
						<i class="carry unit_capacityHalf_small_flat_black" ng-class="{
							unit_capacityEmpty_small_flat_black: infoModules.loot.allBountySum == 0,
							unit_capacityHalf_small_flat_black: infoModules.loot.allBountySum > 0 &amp;&amp; infoModules.loot.allBountySum < infoModules.loot.capacity,
							unit_capacity_small_flat_black: infoModules.loot.allBountySum == infoModules.loot.capacity
						}" :content="tooltips[3]" v-tippy="{placement: 'top'}" tooltip-translate="Report.CarryCapacityTooltip"></i>
						24761/44340
					</span>
					<div class="bountyContainer" ng-if="(infoModules.bounty &amp;&amp; infoModules.loot.allBountySum > 0) || infoModules.spy.resources || infoModules.treasures || infoModules.stolenGoods || infoModules.spy.treasures || infoModules.stolenGoods">
						<span class="resources" tooltip="" tooltip-url="tpl/report/partials/lootTooltip.html" tooltip-show="" tooltip-placement="above">
							<span ng-repeat="n in []|range:1:4">
								<i class="unit_wood_small_illu"></i>
								7284
							</span>
							<span ng-repeat="n in []|range:1:4">
								<i class="unit_clay_small_illu"></i>
								6515
							</span>
							<span ng-repeat="n in []|range:1:4">
								<i class="unit_iron_small_illu"></i>
								3731
							</span>
							<span ng-repeat="n in []|range:1:4">
								<i class="unit_crop_small_illu"></i>
								7225
							</span>
						</span>
						<!-- ngIf: infoModules.treasures || infoModules.spy.treasures -->
						<span class="stolenGoods" ng-if="infoModules.stolenGoods" content="Краденые ценности" v-tippy="{placement: 'top'}" tooltip-translate="Resource.StolenGoods" tooltip-placement="above">
							<i class="unit_stolenGoods_small_illu"></i>
							6
						</span>
					</div>
				</div>
				<div class="buildingsModule" ng-if="infoModules.spy.defence || infoModules.damage">
					<span class="caption" translate=""><span>Здания</span></span>
					<div class="buildingsContainer">
						<!-- ngRepeat: building in infoModules.spy.defence track by $index -->
						<div ng-if="infoModules.damage" class="buildingInfo" ng-repeat="building in infoModules.damage track by $index">
							<span class="buildingLarge buildingType4 tribeId3" tooltip="" tooltip-translate="Building_4"></span>
							<div>12 <span class="finalLevel">12</span></div>
						</div>
					</div>
				</div>
				<div class="healedModule borderedBox" ng-if="infoModules.healed">
					<i class="movement_heal_small_flat_black" :content="tooltips[1]" v-tippy="{placement: 'top'}"  tooltip-url="tpl/report/partials/healTooltip.html" tooltip-class="troopsTooltip"></i>
					<span translate="" data="amount:9"><span>Исцелено воинов: 9</span></span>
				</div>
				<div class="woundedModule borderedBox" ng-if="infoModules.wounded">
					<i class="movement_wounded_small_flat_black" :content="tooltips[2]" v-tippy="{placement: 'top'}" tooltip-url="tpl/report/partials/woundedTooltip.html" tooltip-class="troopsTooltip"></i>
					<span translate="" data="amount:32"><span>Следующее количество раненых отправлены в Целебный шатер: 32.</span></span>
				</div>
				<div class="infoModule" ng-if="infoModules.infoTexts.length > 0 || infoModules.errorTexts.length > 0 || (infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0) || (infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0)">
					<span class="caption" translate=""><span>Информация</span></span>
					<table class="infoTable transparent">
						<tbody>
							<tr ng-if="infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0 ||
										infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0">
								<td>
									<span ng-if="infoModules.spy.hiddenByAllCrannies || infoModules.spy.hiddenByAllCrannies == 0" class="spyInfo" content="Тайник" v-tippy="{placement: 'top'}" tooltip-translate="Report.Spy.Cranny" tooltip-placement="above">
										<i class="building_g23_small_illu"></i> 0
									</span>
									<span ng-if="infoModules.spy.hiddenByTreasury || infoModules.spy.hiddenByTreasury == 0" class="spyInfo" content="Скрытая сокровищница" v-tippy="{placement: 'top'}" tooltip-translate="Report.Spy.HiddenTreasury" tooltip-placement="above">
										<i class="building_g45_small_illu"></i> 0
									</span>
								</td>
							</tr>
							<tr ng-repeat="infoText in infoModules.infoTexts">
								<td>Информация о цели недоступна.</td>
							</tr>
							<!-- ngRepeat: errorText in infoModules.errorTexts -->
						</tbody>
					</table>
				</div>
			</div>
			<div class="troopsDetailContainer" ng-repeat="troopDetails in troopsDataAttacker">
				<div class="troopsDetailHeader fromHeader">
					<i class="reportIcon" :class="['reportTypes_reportIcon'+report.notificationType+'_flat']" content="Тип нападения: Атака/Разведка" v-tippy="{placement: 'bottom'}" tooltip-show="body.attackType" tooltip-translate="Report.AttackTypeTooltip" tooltip-data="type:movementType_3"></i>
					<span translate=""><span>Нападение</span></span>:
					<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1521" ng-if="header.sourcePlayerId != 1">Sand68</a>
					<!-- ngIf: header.sourcePlayerId == 1 -->
					<span translate=""><span>из</span></span>
					<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537149422}, null, true)" village-link="" villageid="537149422" villagename="1_Тьма">1_Тьма</a>
					<div ng-if="header.sourceId > 2" class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '537149422_1721988878.2'); closeWindow(w.name);">
						<i class="symbol_target_small_flat_black"></i>
					</div>
				</div>
				<div troops-details="" troop-data="troopDetails">
					<table class="troopsTable roman">
						<tbody class="troopsIconRow">
							<tr>
								<td ng-repeat="n in []|range:1:11" class="unit1">
									<i class="unitSmall roman unitType1" unit-icon="" data="1" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit2">
									<i class="unitSmall roman unitType2" unit-icon="" data="2" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit3">
									<i class="unitSmall roman unitType3" unit-icon="" data="3" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit4">
									<i class="unitSmall roman unitType4" unit-icon="" data="4" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit5">
									<i class="unitSmall roman unitType5" unit-icon="" data="5" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit6">
									<i class="unitSmall roman unitType6" unit-icon="" data="6" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit7">
									<i class="unitSmall roman unitType7" unit-icon="" data="7" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit8">
									<i class="unitSmall roman unitType8" unit-icon="" data="8" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit9">
									<i class="unitSmall roman unitType9" unit-icon="" data="9" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit10">
									<i class="unitSmall roman unitType10" unit-icon="" data="10" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="hero">
									<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<span ng-if="troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0">?</span>

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">373</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">31</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">1</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="lostTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<!-- ngIf: troopsType == 'trapped' -->
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">373</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">31</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">1</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
							</tr>
						</tbody>
						<tbody>
							<tr ng-if="troopsType == 'trapped'" class="subHeader">
								<td colspan="11">
									<span><span translate=""><span>Поймано</span></span>:</span>
								</td>
							</tr>
							<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">16</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">23</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">10</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">15</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
						</tbody>
						<tbody ng-if="troopData.supply">
							<tr class="subHeader">
								<td colspan="11">
									<span><span translate=""><span>Содержание</span></span>:</span>
								</td>
							</tr>
							<tr>
								<td colspan="11" class="upkeep">
									<i class="unit_consumption_small_flat_black"></i> 1 <span translate=""><span>в час</span></span>
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData.cagedAnimals">
							<tr class="subHeader">
								<td colspan="11">
									<span><span translate="" data="amount:38"><span>Использовано клеток: 38.</span></span></span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="troopsDetailContainer" ng-repeat="troopDetails in troopsDataDefender | orderBy:['type', 'tribeId']" ng-if="troopDetails.type == 'defender' || (!showTribeDetails[troopDetails.tribeId] && showTribeDetails[troopDetails.tribeId] !== false) ||
						troopDetails.isGroup && !showTribeDetails[troopDetails.tribeId] || !troopDetails.isGroup && showTribeDetails[troopDetails.tribeId]">
				<div class="troopsDetailHeader toHeader">
					<i class="reportIcon reportTypes_reportIcon5_flat" ng-class="{unknown: troopDetails['unknown']}"></i>
					<span ng-if="troopDetails.type == 'defender'"><span translate=""><span>Оборона</span></span>:</span>
					<!-- ngIf: troopDetails.type == 'supporter' -->
					<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" ng-if="troopDetails.playerId" playerid="122">гал</a>
					<!-- ngIf: !troopDetails.playerId -->
					<span ng-if="troopDetails.type == 'defender'">
						<span translate=""><span>из</span></span>
						<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537083881}, null, true)" village-link="" villageid="537083881" villagename="тут ресов нет">тут ресов нет</a>
					</span>
					<div ng-if="troopDetails.type == 'defender' && troopDetails.villageId > 2" class="iconButton centerMapButton clickable" content="Центрировать" v-tippy="{placement: 'top'}" tooltip-translate="CellDetails.CenterMap" clickable="openPage('map', 'centerId', '537083881_1721988878.2'); closeWindow(w.name);">
						<i class="symbol_target_small_flat_black"></i>
					</div>
				</div>
				<div troops-details="" troop-data="troopDetails">
					<table class="troopsTable gaul">
						<tbody class="troopsIconRow">
							<tr>
								<td ng-repeat="n in []|range:1:11" class="unit1">
									<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit2">
									<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit3">
									<i class="unitSmall gaul unitType3" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit4">
									<i class="unitSmall gaul unitType4" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit5">
									<i class="unitSmall gaul unitType5" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit6">
									<i class="unitSmall gaul unitType6" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit7">
									<i class="unitSmall gaul unitType7" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit8">
									<i class="unitSmall gaul unitType8" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit9">
									<i class="unitSmall gaul unitType9" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit10">
									<i class="unitSmall gaul unitType10" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="hero">
									<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<!-- ngIf: troopsType == 'trapped' -->
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">28</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">5</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">15</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">459</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">2</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">1</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="lostTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<!-- ngIf: troopsType == 'trapped' -->
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">2</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">1</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">31</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">1</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
							</tr>
						</tbody>
						<!-- ngIf: troopData.supply -->
						<!-- ngIf: troopData.cagedAnimals -->
					</table>
				</div>
				<!-- ngIf: troopDetails.isGroup -->
			</div>
			<div class="troopsDetailContainer" ng-repeat="troopDetails in troopsDataDefender | orderBy:['type', 'tribeId']" ng-if="troopDetails.type == 'defender' || (!showTribeDetails[troopDetails.tribeId] && showTribeDetails[troopDetails.tribeId] !== false) ||
						troopDetails.isGroup && !showTribeDetails[troopDetails.tribeId] || !troopDetails.isGroup && showTribeDetails[troopDetails.tribeId]">
				<div class="troopsDetailHeader toHeader">
					<i class="reportIcon reportTypes_reportIcon5_flat" ng-class="{unknown: troopDetails['unknown']}"></i>
					<!-- ngIf: troopDetails.type == 'defender' -->
					<span ng-if="troopDetails.type == 'supporter'"><span translate=""><span>Подкрепление</span></span>:</span>
					<!-- ngIf: troopDetails.playerId -->
					<span class="tribeLabel" ng-if="!troopDetails.playerId" translate="" options="1"><span>Римляне</span></span>
					<!-- ngIf: troopDetails.type == 'defender' -->
					<!-- ngIf: troopDetails.type == 'defender' && troopDetails.villageId > 2 -->
				</div>
				<div troops-details="" troop-data="troopDetails">
					<table class="troopsTable roman">
						<tbody class="troopsIconRow">
							<tr>
								<td ng-repeat="n in []|range:1:11" class="unit1">
									<i class="unitSmall roman unitType1 inactive" unit-icon="" data="1" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit2">
									<i class="unitSmall roman unitType2" unit-icon="" data="2" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit3">
									<i class="unitSmall roman unitType3 inactive" unit-icon="" data="3" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit4">
									<i class="unitSmall roman unitType4 inactive" unit-icon="" data="4" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit5">
									<i class="unitSmall roman unitType5 inactive" unit-icon="" data="5" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit6">
									<i class="unitSmall roman unitType6 inactive" unit-icon="" data="6" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit7">
									<i class="unitSmall roman unitType7 inactive" unit-icon="" data="7" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit8">
									<i class="unitSmall roman unitType8 inactive" unit-icon="" data="8" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit9">
									<i class="unitSmall roman unitType9 inactive" unit-icon="" data="9" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit10">
									<i class="unitSmall roman unitType10 inactive" unit-icon="" data="10" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="hero">
									<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<!-- ngIf: troopsType == 'trapped' -->
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">466</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="lostTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<!-- ngIf: troopsType == 'trapped' -->
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">32</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
							</tr>
						</tbody>
						<!-- ngIf: troopData.supply -->
						<!-- ngIf: troopData.cagedAnimals -->
					</table>
				</div>
				<div class="troopActions" ng-if="troopDetails.isGroup">
					<a class="centered clickable" clickable="showTribeDetails[troopDetails.tribeId] = true" translate=""><span>Показать детали</span></a>
				</div>
			</div>
			<div class="troopsDetailContainer" ng-repeat="troopDetails in troopsDataDefender | orderBy:['type', 'tribeId']" ng-if="troopDetails.type == 'defender' || (!showTribeDetails[troopDetails.tribeId] && showTribeDetails[troopDetails.tribeId] !== false) ||
						troopDetails.isGroup && !showTribeDetails[troopDetails.tribeId] || !troopDetails.isGroup && showTribeDetails[troopDetails.tribeId]">
				<div class="troopsDetailHeader toHeader">
					<i class="reportIcon reportTypes_reportIcon5_flat" ng-class="{unknown: troopDetails['unknown']}"></i>
					<!-- ngIf: troopDetails.type == 'defender' -->
					<span ng-if="troopDetails.type == 'supporter'"><span translate=""><span>Подкрепление</span></span>:</span>
					<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" ng-if="troopDetails.playerId" playerid="122">гал</a>
					<!-- ngIf: !troopDetails.playerId -->
					<!-- ngIf: troopDetails.type == 'defender' -->
					<!-- ngIf: troopDetails.type == 'defender' && troopDetails.villageId > 2 -->
				</div>
				<div troops-details="" troop-data="troopDetails">
					<table class="troopsTable gaul">
						<tbody class="troopsIconRow">
							<tr>
								<td ng-repeat="n in []|range:1:11" class="unit1">
									<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit2">
									<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit3">
									<i class="unitSmall gaul unitType3" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit4">
									<i class="unitSmall gaul unitType4" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit5">
									<i class="unitSmall gaul unitType5" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit6">
									<i class="unitSmall gaul unitType6" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit7">
									<i class="unitSmall gaul unitType7" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit8">
									<i class="unitSmall gaul unitType8" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit9">
									<i class="unitSmall gaul unitType9" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="unit10">
									<i class="unitSmall gaul unitType10" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
								<td ng-repeat="n in []|range:1:11" class="hero">
									<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																	|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
														clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
									<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
									<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
								</td>
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<!-- ngIf: troopsType == 'trapped' -->
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">645</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">23</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">43</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td><!-- end ngRepeat: n in [] | range:1:11 -->
							</tr>
						</tbody>
						<tbody ng-if="troopData[troopsType+'Troops']" class="lostTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
							<!-- ngIf: troopsType == 'trapped' -->
							<tr>
								<td ng-repeat="n in [] | range:1:11" class="unit1">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">43</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit2">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit3">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">2</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit4">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit5">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
										<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
										<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">3</span>
										<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
									</div>
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit6">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit7">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit8">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit9">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="unit10">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td>
								<td ng-repeat="n in [] | range:1:11" class="hero">
									<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
									<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

									<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
									<!-- ngIf: troopsType == 'input' -->
								</td><!-- end ngRepeat: n in [] | range:1:11 -->
							</tr>
						</tbody>
						<!-- ngIf: troopData.supply -->
						<!-- ngIf: troopData.cagedAnimals -->
					</table>
				</div>
				<!-- ngIf: troopDetails.isGroup -->
			</div>
			<div class="troopsDetailContainer" ng-repeat="troopDetails in troopsDataDefender | orderBy:['type', 'tribeId']" ng-if="troopDetails.type == 'defender' || (!showTribeDetails[troopDetails.tribeId] &amp;&amp; showTribeDetails[troopDetails.tribeId] !== false) ||
				troopDetails.isGroup &amp;&amp; !showTribeDetails[troopDetails.tribeId] || !troopDetails.isGroup &amp;&amp; showTribeDetails[troopDetails.tribeId]">
		<div class="troopsDetailHeader toHeader">
			<i class="reportIcon reportTypes_reportIcon5_flat" ng-class="{unknown: troopDetails['unknown']}"></i>
			<!-- ngIf: troopDetails.type == 'defender' -->
			<!-- ngIf: troopDetails.type == 'supporter' --><span ng-if="troopDetails.type == 'supporter'"><span translate=""><span>Подкрепление</span></span>:</span><!-- end ngIf: troopDetails.type == 'supporter' -->
			<!-- ngIf: troopDetails.playerId --><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" ng-if="troopDetails.playerId" playerid="0">Природа</a><!-- end ngIf: troopDetails.playerId -->
			<!-- ngIf: !troopDetails.playerId -->
			<!-- ngIf: troopDetails.type == 'defender' -->
			<!-- ngIf: troopDetails.type == 'defender' && troopDetails.villageId > 2 -->
		</div>
		<div troops-details="" troop-data="troopDetails"><table class="troopsTable nature">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall nature unitType1" unit-icon="" data="31" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall nature unitType2" unit-icon="" data="32" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall nature unitType3" unit-icon="" data="33" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall nature unitType4" unit-icon="" data="34" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall nature unitType5" unit-icon="" data="35" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall nature unitType6" unit-icon="" data="36" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall nature unitType7" unit-icon="" data="37" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall nature unitType8" unit-icon="" data="38" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall nature unitType9" unit-icon="" data="39" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall nature unitType10" unit-icon="" data="40" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">17</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">16</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="lostTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">1</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">1</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
		<!-- ngIf: troopDetails.isGroup -->
	</div>
			<!-- ngRepeat: troopDetails in troopsDataSummary -->
		</div>
		<!-- ngIf: header.displayType == Report.DISPLAY_TYPE_PEACE -->
		<!-- ngIf: header.displayType == Report.DISPLAY_TYPE_VACATION -->
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		report: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		tooltips: {
			1: `<div class="tooltipContent"><h3 translate=""><span>Войска, исцеленные повязками</span></h3>
<div class="horizontalLine"></div>
<div troops-details="" troop-data="infoModules.healed"><table class="troopsTable roman">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall roman unitType1" unit-icon="" data="1" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall roman unitType2" unit-icon="" data="2" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall roman unitType3 inactive" unit-icon="" data="3" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall roman unitType4 inactive" unit-icon="" data="4" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall roman unitType5 inactive" unit-icon="" data="5" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall roman unitType6" unit-icon="" data="6" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall roman unitType7 inactive" unit-icon="" data="7" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall roman unitType8 inactive" unit-icon="" data="8" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall roman unitType9 inactive" unit-icon="" data="9" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall roman unitType10 inactive" unit-icon="" data="10" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">6</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">1</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">2</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div>
</div>`,
			2: `<div class="tooltipContent"><h3 data="id:228" translate=""><span>Общее количество раненых (во всех деревнях игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="228" playername="" nolink="true" showmissinglink="true">сталкер</a><span>)</span></h3>
<div class="horizontalLine"></div>
<div troops-details="" troop-data="infoModules.wounded"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">3</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">1</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div></div>`,
			3: `<span translate="" options="Report.CarryCapacityTooltip"><span>Загружены добычей: 67% (</span><span class="values" ng-bind-html="0 | bidiRatio:used:max">9452/14135</span><span>)</span></span>`,
		},
	}),
	computed: {},
	methods: {
		range: lib.range,
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
	},
	components: {},
}
</script>
<template>
	<div class="playerProfile">
		<div class="player-profile">
			<div class="playerProfile">
				<div class="detailsWrapper">
					<div class="contentBox gradient">
						<div class="flagHanger governor">
							<div class="flag"></div>
							<i class="community_governor_small_flat_black"></i>
						</div>
						<div class="contentBoxHeader headerWithArrowEndings kingdomRole kingdomRole0">
							<div class="content">{{player.username}}</div>
						</div>
						<div class="contentBoxBody">
							<div class="playerDetails">
								<div class="contentBox transparent">
									<div class="contentBoxHeader">
										<div><span>Губернатор</span></div>
										<i ng-if="::playerInfo.language" class="languageFlag ru" content="ru" v-tippy="{placement: 'bottom'}"></i>
									</div>
									<div class="contentBoxBody">
										<div>
											<span class="desc" translate=""><span>Ранг</span></span>
											<span class="data">418</span>
										</div>
										<div>
											<span class="desc" translate=""><span>Население</span></span>
											<span class="data">45</span>
										</div>
										<div>
											<span class="desc" translate=""><span>Деревни</span></span>
											<span class="data">1</span>
										</div>
										<span class="desc" translate=""><span>Королевство</span></span>
										<span class="data">
											<span ng-if="playerData.kingdomId == 0">-</span>
											<router-link :to="{path: '/profile-kingdom/1'}" ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="playerData.kingdomId > 0" kingdomid="3">Roman</router-link>
										</span>
									</div>
									<div>
										<span class="desc" translate=""><span>Народ</span></span>
										<span class="data" translate="" options="3"><span>Галлы</span></span>
									</div>
								</div>
							</div>
						</div>
						<div class="playerProfileAvatar kingdomRole0">
							<div class="avatarLink clickable" clickable="kingdomProfile ? openWindow('profile', {'playerId': 1804 }, null, true) : openOverlay('playerProfileFullImage', {'playerId': 1804 });">
								<div scale="0.55" class="avatar-image avatarImage" player-id="1804" size="big" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<img src="//iwstatic.g.bsrv.su/resources/avatars/125/lexinzector.png" width="192" height="192" />
									</div>
								</div>
								<div class="prestigeStars" ng-if="config.balancing.features.prestige">
									<div class="innerStars" stars="playerData.stars" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</div>
							<span class="decoration"></span>
							<div class="prestigeStarsTooltip clickable" content="Уровень престижа показывает опыт игрока. Престиж можно заработать за выполнение еженедельных заданий и достижений." v-tippy="{placement: 'bottom'}" ng-if="myPlayerId == playerId &amp;&amp; config.balancing.features.prestige" clickable="openWindow('profile', {'playerId': playerId, 'profileTab': 'prestige'})" tooltip-data="prestige:247,nextLevelPrestige:300"></div>
						</div>
						<div class="kingdomDetails">
							<div class="background profile_kingdomBackground_layout"></div>
							<div class="contentBox transparent">
								<div class="contentBoxHeader">
									<span class="data" ng-if="playerData.kingdomId > 0" translate=""><span>Королевство</span></span>
									<span class="data" ng-if="playerData.kingdomId == 0" translate=""><span>Без королевства</span></span>
								</div>
								<div class="contentBoxBody empty" ng-if="playerData.kingdomId <= 0"></div>
								<div class="contentBoxBody" ng-if="playerData.kingdomId > 0">
									<div ng-if="playerData.kingdomRole != ROLE_KING">
										<span class="desc" translate=""><span>Король</span></span>
										<span class="data"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="127" playername="">Gaius Ulius</a></span>
									</div>
									<div>
										<span class="desc" translate=""><span>Ранг</span></span>
										<span class="data" ng-if="kingdomStats.rank >= 0">4</span>
									</div>
									<div>
										<span class="desc" translate=""><span>Победные очки</span></span>
										<span class="data" ng-if="kingdomStats.victoryPoints > 0">69&nbsp;809</span>
									</div>
									<div>
										<span class="desc" translate=""><span>Сокровища</span></span>
										<span class="data" ng-if="kingdomStats.treasures">5&nbsp;530</span>
									</div>
									<div>
										<span class="desc" translate=""><span>Срок (дни)</span></span>
										<span class="data">34</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="infoWrapper">
		<div class="playerVillages">
			<table class="fixedTableHeader scrollable" scrollable="">
				<div class="scrollContentOuterWrapper" style="width: 298px;">
					<div class="scrollContent" dynamic-height="false" style="width: 298px; overflow-y: hidden;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
							<thead>
								<tr>
									<th class="name">
										<i content="Деревни" v-tippy="{placement: 'bottom'}" tooltip-translate="Villages" class="village_village_small_flat_black"></i> (4)
									</th>
									<th class="coordinates"><i content="Координаты" v-tippy="{placement: 'bottom'}" class="symbol_target_small_flat_black"></i></th>
									<th class="population">
										<i content="Население" v-tippy="{placement: 'bottom'}" tooltip-translate="Population" class="unit_population_small_flat_black"></i></th>
								</tr>
							</thead>
							<tbody>
								<tr ng-repeat="village in playerData.villages | orderBy:'population':true">
									<td class="name">
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536133632}, null, true)" village-link="" villageid="536133632" villagename="InZectorsk">InZectorsk</a>
										<i ng-if="village.isMainVillage" class="village_main_small_flat_black" content="Столица" v-tippy="{placement: 'bottom'}" tooltip-translate="Village.main"></i>
									</td>
									<td class="coordinates">
										<span class="coordinateWrapper" coordinates="" aligned="true" x="0" y="-23">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '536133632_1707751942.9');" class="clickable">
												<span class="coordinates coordinatesWrapper  coordinatesAligned" ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;&#x202D;0&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;-&#x202D;23&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
										</span>
									</td>
									<td class="population">45</td>
								</tr>
								<tr ng-repeat="village in playerData.villages | orderBy:'population':true">
									<td class="name">
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536952816}, null, true)" village-link="" villageid="536952816" villagename="Зенда">Зенда</a>
									</td>
									<td class="coordinates">
										<span class="coordinateWrapper" coordinates="" aligned="true" x="-16" y="2">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '536952816_1707821760.4');" class="clickable">
												<span class="coordinates coordinatesWrapper  coordinatesAligned" ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;16&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;2&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
										</span>
									</td>
									<td class="population">437</td>
								</tr>
								<tr ng-repeat="village in playerData.villages | orderBy:'population':true">
									<td class="name">
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537018349}, null, true)" village-link="" villageid="537018349" villagename="Дарра">Дарра</a>
									</td>
									<td class="coordinates">
										<span class="coordinateWrapper" coordinates="" aligned="true" x="-19" y="4">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537018349_1707821772.2');" class="clickable">
												<span class="coordinates coordinatesWrapper  coordinatesAligned" ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;19&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;4&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
										</span>
									</td>
									<td class="population">373</td>
								</tr>
								<tr ng-repeat="village in playerData.villages | orderBy:'population':true">
									<td class="name">
										<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537968610}, null, true)" village-link="" villageid="537968610" villagename="Уккарон">Уккарон</a>
									</td>
									<td class="coordinates">
										<span class="coordinateWrapper" coordinates="" aligned="true" x="-30" y="33">
											<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537968610_1707821781.2');" class="clickable">
												<span class="coordinates coordinatesWrapper  coordinatesAligned" ng-bind-html=" coord | bidiCoordinate:x:y ">&#x202D;<span class="coordinateX">&#x202D;(&#x202C;&#x202D;-&#x202D;30&#x202C;&#x202C;</span><span class="coordinatePipe">|</span><span class="coordinateY">&#x202D;&#x202D;33&#x202C;&#x202C;&#x202D;)&#x202C;</span>&#x202C;</span>
											</a>
										</span>
									</td>
									<td class="population">156</td>
								</tr>
							</tbody>
						</div>
					</div>
				</div>
			</table>
		</div>
		<div class="contentBox gradient playerDescription">
			<div class="contentBoxHeader headerColored">
				<span translate=""><span>Описание</span></span>
				<i class="headerButton clickable action_edit_small_flat_black" ng-show="canEditDescription" ng-class="{action_edit_small_flat_black: !editHover, action_edit_small_flat_green: editHover}" on-pointer-over="editHover = true" on-pointer-out="editHover = false" clickable="openOverlay('playerProfileEditDescription');" content="Редактировать описание" v-tippy="{placement: 'bottom'}" tooltip-translate="Player.EditDescription"></i>
			</div>
			<div class="contentBoxBody scrollable" scrollable="">
				<div class="scrollContentOuterWrapper" style="width: 280px;">
					<div class="scrollContent" dynamic-height="false" style="width: 280px; overflow-y: hidden;">
						<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
							<div user-text-parse="playerProfile.description" parse="decorations;medals;achievements" class="desc">
								<span>Война закончена лишь тогда, когда закончатся солдаты.</span><br>
								<br>
								<span>==========================</span><br>
								<i class="achievementFrame achievements_frame5_large_illu" tooltip="" tooltip-url="tpl/profile/playerProfile/achievementTooltip.html" tooltip-data="achievement-data:{&quot;id&quot;:10191738,&quot;playerId&quot;:637,&quot;type&quot;:27,&quot;level&quot;:5,&quot;special&quot;:false,&quot;extraData&quot;:null,&quot;langKeySuffix&quot;:&quot;&quot;}" player-id="637" id="10191738"><i class="achievementIcon achievements_type27_medium_illu"></i></i>
								<i class="achievementFrame achievements_frame5_large_illu" tooltip="" tooltip-url="tpl/profile/playerProfile/achievementTooltip.html" tooltip-data="achievement-data:{&quot;id&quot;:10191722,&quot;playerId&quot;:637,&quot;type&quot;:11,&quot;level&quot;:5,&quot;special&quot;:false,&quot;extraData&quot;:null,&quot;langKeySuffix&quot;:&quot;&quot;}" player-id="637" id="10191722"><i class="achievementIcon achievements_type11_medium_illu"></i></i>
								<i class="achievementFrame achievements_frameMystery_large_illu" tooltip="" tooltip-url="tpl/profile/playerProfile/achievementTooltip.html" tooltip-data="achievement-data:{&quot;id&quot;:11618943,&quot;playerId&quot;:637,&quot;type&quot;:58,&quot;level&quot;:1,&quot;special&quot;:true,&quot;extraData&quot;:null,&quot;langKeySuffix&quot;:&quot;&quot;}" player-id="637" id="11618943"><i class="achievementIcon achievements_type58_medium_illu"></i></i>
								<i class="achievementFrame achievements_frameMystery_large_illu" content="Победа! (Аэро на RU2X3)" v-tippy="{placement: 'top'}" tooltip-url="tpl/profile/playerProfile/achievementTooltip.html" tooltip-data="achievement-data:{&quot;id&quot;:11621818,&quot;playerId&quot;:637,&quot;type&quot;:62,&quot;level&quot;:1,&quot;special&quot;:true,&quot;extraData&quot;:{&quot;serverName&quot;:&quot;RU2X3&quot;,&quot;kingdomTag&quot;:&quot;Аэро&quot;,&quot;serverEndTime&quot;:&quot;1501101535&quot;},&quot;langKeySuffix&quot;:&quot;_hasKingdom&quot;}" player-id="637" id="11621818"><i class="achievementIcon achievements_type62_medium_illu"></i></i><br>
								<span>==========================</span><br>
								<br>
								<i class="medalImage medal_climberRank1_large_illu" ng-class="{lowRank:rank>3}" content="Взлет недели<br>Неделя 2<br>Ранг 1" v-tippy="{placement: 'top'}" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:1,rank:1,week:2" type="1" rank="1" week="2"><span class="rankNumber">1</span></i>
								<i class="medalImage medal_robberRank1_large_illu" ng-class="{lowRank:rank>3}" content="Грабитель недели<br>Неделя 2<br>Ранг 1" v-tippy="{placement: 'top'}" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:4,rank:1,week:2" type="4" rank="1" week="2"><span class="rankNumber">1</span></i>
								<i class="medalImage medal_attackerRank1_large_illu" ng-class="{lowRank:rank>3}" content="Атакующий недели<br>Неделя 3<br>Ранг 1" v-tippy="{placement: 'top'}" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:2,rank:1,week:3" type="2" rank="1" week="3"><span class="rankNumber">1</span></i>
								<i class="medalImage medal_climberRank2_large_illu" ng-class="{lowRank:rank>3}" tooltip="" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:2,rank:1,week:3" type="1" rank="2" week="3"><span class="rankNumber">2</span></i>
								<i class="medalImage medal_robberRank2_large_illu" ng-class="{lowRank:rank>3}" tooltip="" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:4,rank:1,week:3" type="4" rank="2" week="3"><span class="rankNumber">2</span></i>
								<i class="medalImage medal_attackerRank2_large_illu" ng-class="{lowRank:rank>3}" tooltip="" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:2,rank:2,week:4" type="2" rank="2" week="4"><span class="rankNumber">2</span></i>
								<i class="medalImage medal_climberRank3_large_illu" ng-class="{lowRank:rank>3}" tooltip="" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:2,rank:1,week:3" type="1" rank="3" week="3"><span class="rankNumber">3</span></i>
								<i class="medalImage medal_robberRank3_large_illu" ng-class="{lowRank:rank>3}" tooltip="" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:4,rank:1,week:3" type="4" rank="3" week="3"><span class="rankNumber">3</span></i>
								<i class="medalImage medal_attackerRank3_large_illu" ng-class="{lowRank:rank>3}" tooltip="" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:2,rank:2,week:4" type="2" rank="3" week="4"><span class="rankNumber">3</span></i>
								<i class="medalImage medal_climberRank4_large_illu" ng-class="{lowRank:rank>3}" tooltip="" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:2,rank:1,week:3" type="1" rank="4" week="3"><span class="rankNumber">4</span></i>
								<i class="medalImage medal_robberRank4_large_illu" ng-class="{lowRank:rank>3}" tooltip="" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:4,rank:1,week:3" type="4" rank="4" week="3"><span class="rankNumber">4</span></i>
								<i class="medalImage medal_attackerRank4_large_illu" ng-class="{lowRank:rank>3}" tooltip="" tooltip-url="tpl/profile/playerProfile/medalTooltip.html" tooltip-data="type:2,rank:2,week:4" type="2" rank="4" week="4"><span class="rankNumber">4</span></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="horizontalLine" v-if="player.id != 0 && player.id != gameModel.player.id"></div>
	<div class="contentWrapper" v-if="player.id != 0 && player.id != gameModel.player.id">
		<div class="openChat">
			<button clickable="openChat(getPrivateRoomName(playerData.playerId))" class="clickable">
				<div class="content">
					<span ng-transclude="" class="innerText">
						<span translate=""><span>Открыть чат</span></span>
					</span>
					<div class="animationOverlay"></div>
					<i class="checkmark action_check_small_flat_white"></i>
					<i class="checkmark action_check_small_flat_black"></i>
					<i class="checkmark action_check_small_flat_red"></i>
				</div>
			</button>
		</div>
		<div class="iconButton beAFriend clickable" ng-class="{disabled: !userAddable(playerData.playerId)}" content="Добавить в друзья" v-tippy="{placement: 'top'}" clickable="addFriend(playerData.playerId, playerData.name)">
			<i class="community_playerAdd_small_flat_black"></i>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		player: {
			type: Object,
			default: {},
		},
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
import axios from 'axios';
import lib from '@/lib';

export default class AuctionsModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.confirmSellingShow = false;
	}
}

<template>
	<div id="contextMenu" v-if="gameModel.contextMenuOpen">
		<div class="backgroundRing"></div>
	</div>
	
	<!-- деревня / лагерь разбойников / оазис / захваченный оазис / дикая местность -->
	<div id="contextMenu" v-if="gameModel.contextMenuOpen" style="top: 552px; left: 1160px; display: block;" class="expanded">
		<div class="backgroundRing"></div>
		<div ng-repeat="item in menuItems" class="item pos4 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable disabled" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" content="Отчеты" v-tippy="{placement: 'left'}" tooltip-class="mapContextMenu pos4" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.reports" tooltip-url="" tooltip-placement="before">
				<i ng-class="item.iconClass" class="feature_report_medium_illu"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos3 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" content="Отметить поле" v-tippy="{placement: 'bottom'}" tooltip-class="mapContextMenu pos3" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.marker" tooltip-url="" tooltip-placement="below">
				<i ng-class="item.iconClass" class="feature_marker_medium_illu"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos1 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" content="Отправить войска" v-tippy="{placement: 'top'}" tooltip-class="mapContextMenu pos1" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.sendTroops" tooltip-url="" tooltip-placement="above">
				<i ng-class="item.iconClass" class="feature_sendTroops_medium_illu"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos2 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" content="Послать торговцев" v-tippy="{placement: 'right'}" tooltip-class="mapContextMenu pos2" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.sendTraders" tooltip-url="" tooltip-placement="after">
				<i ng-class="item.iconClass" class="movement_trade_medium_illu"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos0 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable ng-hide" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" content="Данные" v-tippy="{placement: 'bottom'}" tooltip-class="mapContextMenu pos0" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.details" tooltip-url="" tooltip-placement="after">
				<i ng-class="item.iconClass" class="radialMenu_cellDetails_layout"></i>
			</div>
			<div ng-if="item.class == 'pos0'" class="middleButton clickable" clickable="item.callback()" on-pointer-over="item.hover = true" on-pointer-out="item.hover = false" ng-class="{hover: item.hover}" tooltip="" tooltip-class="mapContextMenu pos0" tooltip-translate="ContextMenu.button.details">
				<i class="radialMenu_cellDetails_layout" ng-class="{radialMenu_goToVillage_normal_layout: item.type == 'radialMenu_goToVillage_hover_layout' && !item.hover}"></i>
				<i ng-show="item.type == 'radialMenu_goToVillage_hover_layout'" class="radialMenu_goToVillage_arrow_layout ng-hide"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos6 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable ng-hide" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" tooltip="" tooltip-class="mapContextMenu pos6" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.addToFarmList" tooltip-url="" tooltip-placement="before">
				<i ng-class="item.iconClass" class="feature_farmlist_small_flat_black"></i>
			</div>
			<div ng-if="item.class == 'pos6'" class="roundButton farmListRoundButton clickable activeRoundButton" tooltip="" tooltip-translate="ContextMenu.button.farmList.noobProtection" tooltip-show="false" ng-class="{disabled: $eval(item.disabled), inFarmList: item.params.inFarmList, activeRoundButton: activeState}" clickable="openFarmListDialog(true)" on-pointer-over="openFarmListDialog()" on-pointer-out="closeFarmListDialog()">
				<i ng-class="item.iconClass" ng-click="$event.stopPropagation()" class="feature_farmlist_small_flat_black"></i>
				<div class="hoverArea" on-pointer-over="activeState = true; triggerPreselect()" on-pointer-out="activeState = false" ng-click="$event.stopPropagation()"></div>
				<div ng-if="showAddToFarmList" class="farmListContext">
					<div ng-include="" src="'tpl/farmListAdd/farmListAdd.html'">
						<div class="farmListAdd" ng-controller="farmListAddCtrl">
							<div class="farmListWrapper scrollable">
								<div class="scrollContentOuterWrapper">
									<div class="scrollContent" style="overflow-y: auto;">
										<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
											<div class="farmListInner" on-pointer-over="removePreselected()">
												<h6 ng-if="action === FarmListEntry.ACTION.TOGGLE">
													<span translate=""><span>Добавить деревню в список целей</span></span>
												</h6>
												<div class="list clickable preselected" ng-repeat="(key, farmlist) in farmListCollection" clickable="addVillage(628)" ng-if="farmlist.data.isDefault || player.hasPlusAccount()" ng-class="{'preselected': key === 0 && preselected}">
													<i class="action_check_small_flat_black" ng-if="villageInFarmLists.indexOf(farmlist.data.listId) === -1"></i>
													Стартовый лист
													<span>
														<i class="village_village_small_flat_black" tooltip="" tooltip-translate="FarmList.Tooltip.villages" ng-click="$event.stopPropagation()"></i>
														0/10
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<!-- покинутая долина -->
	<div id="contextMenu" v-if="gameModel.contextMenuOpen" style="top: 637px; left: 1295px; display: block;" class="expanded">
		<div class="backgroundRing"></div>
		<div ng-repeat="item in menuItems" class="item pos4 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable disabled" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" tooltip="" tooltip-class="mapContextMenu pos4" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.reports" tooltip-url="" tooltip-placement="before">
				<i ng-class="item.iconClass" class="feature_report_medium_illu"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos3 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" tooltip="" tooltip-class="mapContextMenu pos3" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.marker" tooltip-url="" tooltip-placement="below">
				<i ng-class="item.iconClass" class="feature_marker_medium_illu"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos0 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable ng-hide" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" tooltip="" tooltip-class="mapContextMenu pos0" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.details" tooltip-url="" tooltip-placement="after">
				<i ng-class="item.iconClass" class="radialMenu_cellDetails_layout"></i>
			</div>
			<div ng-if="item.class == 'pos0'" class="middleButton clickable" clickable="item.callback()" on-pointer-over="item.hover = true" on-pointer-out="item.hover = false" ng-class="{hover: item.hover}" tooltip="" tooltip-class="mapContextMenu pos0" tooltip-translate="ContextMenu.button.details">
				<i class="radialMenu_cellDetails_layout" ng-class="{radialMenu_goToVillage_normal_layout: item.type == 'radialMenu_goToVillage_hover_layout' && !item.hover}"></i>
				<i ng-show="item.type == 'radialMenu_goToVillage_hover_layout'" class="radialMenu_goToVillage_arrow_layout ng-hide"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos1 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable disabled" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" tooltip="" tooltip-class="mapContextMenu pos1" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.foundVillage" tooltip-url="" tooltip-placement="above">
				<i ng-class="item.iconClass" class="movement_settle_medium_illu disabled"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos2 transition">
			<div ng-show="item.class != 'pos0' && item.class != 'pos6'" class="roundButton clickable disabled" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" tooltip="" tooltip-class="mapContextMenu pos2" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.sendTraders" tooltip-url="" tooltip-placement="after">
				<i ng-class="item.iconClass" class="movement_trade_medium_illu disabled"></i>
			</div>
		</div>
	</div>
	
	<!-- покинутая долина с возможностью поселения -->
	<div id="contextMenu" v-if="gameModel.contextMenuOpen" style="top: 591px; left: 696px; display: block;" class="expanded">
		<div class="backgroundRing"></div>
		<div ng-repeat="item in menuItems" class="item pos4 transition">
			<div ng-show="item.class != 'pos0' &amp;&amp; item.class != 'pos6'" class="roundButton clickable disabled" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" v-tippy="{placement: 'left'}" content="Отчеты" tooltip-class="mapContextMenu pos4" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.reports" tooltip-url="" tooltip-placement="before">
				<i ng-class="item.iconClass" class="feature_report_medium_illu"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos3 transition">
			<div ng-show="item.class != 'pos0' &amp;&amp; item.class != 'pos6'" class="roundButton clickable" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" v-tippy="{placement: 'bottom'}" content="Отметить поле" tooltip-class="mapContextMenu pos3" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.marker" tooltip-url="" tooltip-placement="below">
				<i ng-class="item.iconClass" class="feature_marker_medium_illu"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos0 transition">
			<div ng-show="item.class != 'pos0' &amp;&amp; item.class != 'pos6'" class="roundButton clickable ng-hide" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" v-tippy="{placement: 'bottom'}" content="Данные" tooltip-class="mapContextMenu pos0" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.details" tooltip-url="" tooltip-placement="after">
				<i ng-class="item.iconClass" class="radialMenu_cellDetails_layout"></i>
			</div>
			<div ng-if="item.class == 'pos0'" class="middleButton clickable" clickable="item.callback()" on-pointer-over="item.hover = true" on-pointer-out="item.hover = false" ng-class="{hover: item.hover}" tooltip="" tooltip-class="mapContextMenu pos0" tooltip-translate="ContextMenu.button.details">
				<i class="radialMenu_cellDetails_layout" ng-class="{radialMenu_goToVillage_normal_layout: item.type == 'radialMenu_goToVillage_hover_layout' &amp;&amp; !item.hover}"></i>
				<i ng-show="item.type == 'radialMenu_goToVillage_hover_layout'" class="radialMenu_goToVillage_arrow_layout ng-hide"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos1 transition">
			<div ng-show="item.class != 'pos0' &amp;&amp; item.class != 'pos6'" class="roundButton clickable" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" v-tippy="{placement: 'top'}" content="Основание – Для основания деревни нужны 3 поселенца. Их можно обучить во дворце или резиденции." tooltip-class="mapContextMenu pos1" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.foundVillage" tooltip-url="" tooltip-placement="above">
				<i ng-class="item.iconClass" class="movement_settle_medium_illu"></i>
			</div>
		</div>
		<div ng-repeat="item in menuItems" class="item pos2 transition">
			<div ng-show="item.class != 'pos0' &amp;&amp; item.class != 'pos6'" class="roundButton clickable disabled" ng-class="{disabled: $eval(item.disabled)}" clickable="item.callback()" v-tippy="{placement: 'right'}" content="Послать торговцев" tooltip-class="mapContextMenu pos2" tooltip-data="disableKingdomChangesWithWWLevel: " tooltip-translate="ContextMenu.button.sendTraders" tooltip-url="" tooltip-placement="after">
				<i ng-class="item.iconClass" class="movement_trade_medium_illu disabled"></i>
			</div>
		</div>
	</div>
</template>

<style lang="css">
#contextMenu{
    position:absolute;
    z-index:1000;
    text-align:center;
    -webkit-transform:scale(0);
    -moz-transform:scale(0);
    -ms-transform:scale(0);
    -o-transform:scale(0);
    transform:scale(0);
    -webkit-transition:none;
    -moz-transition:none;
    -o-transition:none;
    transition:none;
    -webkit-transform-origin:0 0;
    -moz-transform-origin:0 0;
    -ms-transform-origin:0 0;
    -o-transform-origin:0 0;
    transform-origin:0 0
}
#contextMenu.expanded{
    -webkit-transform:scale(1);
    -moz-transform:scale(1);
    -ms-transform:scale(1);
    -o-transform:scale(1);
    transform:scale(1);
    -webkit-transition:-webkit-transform .3s linear;
    -moz-transition:-moz-transform .3s linear;
    -o-transition:-o-transform .3s linear;
    transition:transform .3s linear
}
#contextMenu .backgroundRing{
    position:relative;
    width:150px;
    height:150px;
    margin:-75px;
    background-image:-owg-radial-gradient(transparent 0,transparent 47%,rgba(154,147,137,0.8) 48%,rgba(154,147,137,0.8) 55%,#fff 56%,rgba(241,240,238,0.7) 57%,rgba(241,240,238,0.7) 64%,#fff 65%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:-webkit-radial-gradient(transparent 0,transparent 47%,rgba(154,147,137,0.8) 48%,rgba(154,147,137,0.8) 55%,#fff 56%,rgba(241,240,238,0.7) 57%,rgba(241,240,238,0.7) 64%,#fff 65%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:-moz-radial-gradient(transparent 0,transparent 47%,rgba(154,147,137,0.8) 48%,rgba(154,147,137,0.8) 55%,#fff 56%,rgba(241,240,238,0.7) 57%,rgba(241,240,238,0.7) 64%,#fff 65%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:-o-radial-gradient(transparent 0,transparent 47%,rgba(154,147,137,0.8) 48%,rgba(154,147,137,0.8) 55%,#fff 56%,rgba(241,240,238,0.7) 57%,rgba(241,240,238,0.7) 64%,#fff 65%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:radial-gradient(transparent 0,transparent 47%,rgba(154,147,137,0.8) 48%,rgba(154,147,137,0.8) 55%,#fff 56%,rgba(241,240,238,0.7) 57%,rgba(241,240,238,0.7) 64%,#fff 65%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    border-radius:50%
}
#contextMenu .item{
    position:absolute;
    width:52px;
    height:52px;
    top:-26px;
    left:-26px;
    z-index:2
}
#contextMenu .item.pos0{
    z-index:1
}
#contextMenu .item.pos1{
    top:-86px
}
#contextMenu .item.pos2{
    left:34px
}
#contextMenu .item.pos3{
    top:34px
}
#contextMenu .item.pos4{
    left:-86px
}
#contextMenu .item.pos5{
    left:30.875px;
    top:-62.875px
}
#contextMenu .item.pos6{
    left:-62.875px;
    top:-62.875px;
    z-index:3
}
#contextMenu .item.pos7{
    left:30.875px;
    top:30.875px
}
#contextMenu .item.pos5,
#contextMenu .item.pos6,
#contextMenu .item.pos7{
    width:32px;
    height:32px
}
#contextMenu .item.pos5 .roundButton:after,
#contextMenu .item.pos6 .roundButton:after,
#contextMenu .item.pos7 .roundButton:after{
    top:5px;
    left:5px;
    width:20px;
    height:20px
}
#contextMenu .item.pos5 i,
#contextMenu .item.pos6 i,
#contextMenu .item.pos7 i{
    position:absolute;
    top:8px;
    left:8px
}
#contextMenu .item i{
    position:absolute;
    top:11px;
    left:11px;
    z-index:3
}
#contextMenu .item .roundButton{
    width:100%;
    height:100%;
    display:inline-block;
    position:relative
}
#contextMenu .item .roundButton:before{
    content:"";
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    z-index:1;
    background-image:-owg-radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:-webkit-radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:-moz-radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:-o-radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent)
}
#contextMenu .item .roundButton:after{
    content:"";
    position:absolute;
    top:7px;
    left:7px;
    width:36px;
    height:36px;
    z-index:2;
    background-image:-owg-linear-gradient(#f1f0ee,#e6c77c);
    background-image:-webkit-linear-gradient(#f1f0ee,#e6c77c);
    background-image:-moz-linear-gradient(#f1f0ee,#e6c77c);
    background-image:-o-linear-gradient(#f1f0ee,#e6c77c);
    background-image:linear-gradient(#f1f0ee,#e6c77c);
    margin:0 auto;
    border-radius:50%;
    border:1px solid #9a7641;
    -webkit-box-shadow:0 0 5px 0 rgba(0,0,0,0.15);
    -moz-box-shadow:0 0 5px 0 rgba(0,0,0,0.15);
    box-shadow:0 0 5px 0 rgba(0,0,0,0.15)
}
#contextMenu .item .roundButton:hover:not(:active):not(.disabled):after{
    border:1px solid #e8e5e1;
    -webkit-box-shadow:0 0 5px 0 rgba(0,0,0,0.15),inset 0 0 12px 15px rgba(255,255,255,0.3);
    -moz-box-shadow:0 0 5px 0 rgba(0,0,0,0.15),inset 0 0 12px 15px rgba(255,255,255,0.3);
    box-shadow:0 0 5px 0 rgba(0,0,0,0.15),inset 0 0 12px 15px rgba(255,255,255,0.3)
}
#contextMenu .item .roundButton:active:after,
#contextMenu .item .roundButton.active:after{
    border:1px solid #9a7641;
    background-image:-owg-linear-gradient(#7b746e,#e8e5e1);
    background-image:-webkit-linear-gradient(#7b746e,#e8e5e1);
    background-image:-moz-linear-gradient(#7b746e,#e8e5e1);
    background-image:-o-linear-gradient(#7b746e,#e8e5e1);
    background-image:linear-gradient(#7b746e,#e8e5e1)
}
#contextMenu .item .roundButton.disabled{
    cursor:not-allowed
}
#contextMenu .item .roundButton.disabled i{
    filter:alpha(opacity=40);
    opacity:.4
}
#contextMenu .item .roundButton.disabled:before{
    background-image:-owg-radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:-webkit-radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:-moz-radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:-o-radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent);
    background-image:radial-gradient(rgba(241,240,238,0.7) 0,rgba(241,240,238,0.7) 64%,rgba(37,37,37,0.3) 66%,transparent 71%,transparent)
}
#contextMenu .item .roundButton.disabled:after{
    background-image:-owg-linear-gradient(#f1f0ee,#f1f0ee);
    background-image:-webkit-linear-gradient(#f1f0ee,#f1f0ee);
    background-image:-moz-linear-gradient(#f1f0ee,#f1f0ee);
    background-image:-o-linear-gradient(#f1f0ee,#f1f0ee);
    background-image:linear-gradient(#f1f0ee,#f1f0ee)
}
#contextMenu .item .middleButton{
    position:absolute;
    top:-12px;
    left:-12px;
    width:75px;
    height:75px
}
#contextMenu .item .middleButton .radialMenu_goToVillage_normal_layout,
#contextMenu .item .middleButton .radialMenu_goToVillage_hover_layout{
    top:37px;
    left:2px
}
#contextMenu .item .middleButton .radialMenu_goToVillage_arrow_layout{
    top:35px;
    left:22px
}
@-webkit-keyframes detailsAni{
    from{
        -webkit-transform:scale3d(1,1,1) rotate(0.02deg);
        -moz-transform:scale3d(1,1,1) rotate(0.02deg);
        -ms-transform:scale3d(1,1,1) rotate(0.02deg);
        -o-transform:scale3d(1,1,1) rotate(0.02deg);
        transform:scale3d(1,1,1) rotate(0.02deg);
        opacity:1
    }
    to{
        -webkit-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -moz-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -ms-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -o-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        opacity:.7
    }
}
@-moz-keyframes detailsAni{
    from{
        -webkit-transform:scale3d(1,1,1) rotate(0.02deg);
        -moz-transform:scale3d(1,1,1) rotate(0.02deg);
        -ms-transform:scale3d(1,1,1) rotate(0.02deg);
        -o-transform:scale3d(1,1,1) rotate(0.02deg);
        transform:scale3d(1,1,1) rotate(0.02deg);
        opacity:1
    }
    to{
        -webkit-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -moz-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -ms-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -o-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        opacity:.7
    }
}
@-ms-keyframes detailsAni{
    #contextMenu .item .middleButton from{
        -webkit-transform:scale3d(1,1,1) rotate(0.02deg);
        -moz-transform:scale3d(1,1,1) rotate(0.02deg);
        -ms-transform:scale3d(1,1,1) rotate(0.02deg);
        -o-transform:scale3d(1,1,1) rotate(0.02deg);
        transform:scale3d(1,1,1) rotate(0.02deg);
        opacity:1
    }
    #contextMenu .item .middleButton to{
        -webkit-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -moz-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -ms-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -o-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        opacity:.7
    }
}
@-o-keyframes detailsAni{
    from{
        -webkit-transform:scale3d(1,1,1) rotate(0.02deg);
        -moz-transform:scale3d(1,1,1) rotate(0.02deg);
        -ms-transform:scale3d(1,1,1) rotate(0.02deg);
        -o-transform:scale3d(1,1,1) rotate(0.02deg);
        transform:scale3d(1,1,1) rotate(0.02deg);
        opacity:1
    }
    to{
        -webkit-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -moz-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -ms-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -o-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        opacity:.7
    }
}
@keyframes detailsAni{
    from{
        -webkit-transform:scale3d(1,1,1) rotate(0.02deg);
        -moz-transform:scale3d(1,1,1) rotate(0.02deg);
        -ms-transform:scale3d(1,1,1) rotate(0.02deg);
        -o-transform:scale3d(1,1,1) rotate(0.02deg);
        transform:scale3d(1,1,1) rotate(0.02deg);
        opacity:1
    }
    to{
        -webkit-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -moz-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -ms-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        -o-transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        transform:scale3d(0.7,0.7,1) rotate(0.02deg);
        opacity:.7
    }
}
@-webkit-keyframes goToVillageAni{
    from{
        top:35px
    }
    to{
        top:19px
    }
}
@-moz-keyframes goToVillageAni{
    from{
        top:35px
    }
    to{
        top:19px
    }
}
@-ms-keyframes goToVillageAni{
    #contextMenu .item .middleButton from{
        top:35px
    }
    #contextMenu .item .middleButton to{
        top:19px
    }
}
@-o-keyframes goToVillageAni{
    from{
        top:35px
    }
    to{
        top:19px
    }
}
@keyframes goToVillageAni{
    from{
        top:35px
    }
    to{
        top:19px
    }
}
#contextMenu .item .middleButton .radialMenu_cellDetails_layout{
    -webkit-transform-origin:50% 50% 50%;
    -moz-transform-origin:50% 50% 50%;
    -ms-transform-origin:50% 50% 50%;
    -o-transform-origin:50% 50% 50%;
    transform-origin:50% 50% 50%;
    -webkit-animation-play-state:paused;
    -moz-animation-play-state:paused;
    -ms-animation-play-state:paused;
    -o-animation-play-state:paused;
    animation-play-state:paused
}
body.withAnimations #contextMenu .item .middleButton .radialMenu_cellDetails_layout{
    animation:detailsAni 1.3s infinite linear alternate;
    -webkit-animation:detailsAni 1.3s infinite linear alternate;
    -moz-animation:detailsAni 1.3s infinite linear alternate
}
#contextMenu .item .middleButton .radialMenu_goToVillage_arrow_layout{
    -webkit-animation-play-state:paused;
    -moz-animation-play-state:paused;
    -ms-animation-play-state:paused;
    -o-animation-play-state:paused;
    animation-play-state:paused
}
body.withAnimations #contextMenu .item .middleButton .radialMenu_goToVillage_arrow_layout{
    animation:goToVillageAni 1.3s infinite ease-in-out alternate;
    -webkit-animation:goToVillageAni 1.3s infinite ease-in-out alternate;
    -moz-animation:goToVillageAni 1.3s infinite ease-in-out alternate
}
#contextMenu .item .middleButton:hover i,
#contextMenu .item .middleButton.hover i{
    -webkit-animation-play-state:running;
    -moz-animation-play-state:running;
    -ms-animation-play-state:running;
    -o-animation-play-state:running;
    animation-play-state:running
}
#contextMenu .item .farmListRoundButton .hoverArea{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:3
}
#contextMenu .item .farmListRoundButton:active:after{
    border:1px solid #e8e5e1;
    background-image:-owg-linear-gradient(#f1f0ee,#e6c77c);
    background-image:-webkit-linear-gradient(#f1f0ee,#e6c77c);
    background-image:-moz-linear-gradient(#f1f0ee,#e6c77c);
    background-image:-o-linear-gradient(#f1f0ee,#e6c77c);
    background-image:linear-gradient(#f1f0ee,#e6c77c);
    -webkit-box-shadow:0 0 5px 0 rgba(0,0,0,0.15),inset 0 0 12px 15px rgba(255,255,255,0.3);
    -moz-box-shadow:0 0 5px 0 rgba(0,0,0,0.15),inset 0 0 12px 15px rgba(255,255,255,0.3);
    box-shadow:0 0 5px 0 rgba(0,0,0,0.15),inset 0 0 12px 15px rgba(255,255,255,0.3)
}
#contextMenu .item .farmListRoundButton.activeRoundButton:active:after{
    border:1px solid #9a7641;
    background-image:-owg-linear-gradient(#7b746e,#e8e5e1);
    background-image:-webkit-linear-gradient(#7b746e,#e8e5e1);
    background-image:-moz-linear-gradient(#7b746e,#e8e5e1);
    background-image:-o-linear-gradient(#7b746e,#e8e5e1);
    background-image:linear-gradient(#7b746e,#e8e5e1)
}
#contextMenu .item .farmListRoundButton.inFarmList:after{
    background-image:-owg-linear-gradient(#7da100,#7da100);
    background-image:-webkit-linear-gradient(#7da100,#7da100);
    background-image:-moz-linear-gradient(#7da100,#7da100);
    background-image:-o-linear-gradient(#7da100,#7da100);
    background-image:linear-gradient(#7da100,#7da100)
}
#contextMenu .item .farmListRoundButton.inFarmList.activeRoundButton:active:after{
    border:1px solid #9a7641;
    background-image:-owg-linear-gradient(#506d00,#7da100);
    background-image:-webkit-linear-gradient(#506d00,#7da100);
    background-image:-moz-linear-gradient(#506d00,#7da100);
    background-image:-o-linear-gradient(#506d00,#7da100);
    background-image:linear-gradient(#506d00,#7da100)
}
#contextMenu .item .farmListRoundButton.right .farmListContext{
    right:0;
    left:39px
}
#contextMenu .item .farmListRoundButton.right .farmListContext .farmListWrapper:before{
    right:0;
    left:-8px
}
#contextMenu .item .farmListRoundButton.right .farmListContext .farmListWrapper:after{
    right:0;
    left:-14px;
    border-bottom:1px solid #e6c77c;
    border-left:1px solid #e6c77c;
    -webkit-transform:rotate(45deg) skew(16deg,16deg);
    -moz-transform:rotate(45deg) skew(16deg,16deg);
    -ms-transform:rotate(45deg) skew(16deg,16deg);
    -o-transform:rotate(45deg) skew(16deg,16deg);
    transform:rotate(45deg) skew(16deg,16deg)
}
#contextMenu .farmListContext{
    position:absolute;
    right:39px;
    top:-39px;
    width:294px;
    min-height:84px;
    max-height:300px;
    -webkit-box-shadow:0 0 2px rgba(0,0,0,0.7);
    -moz-box-shadow:0 0 2px rgba(0,0,0,0.7);
    box-shadow:0 0 2px rgba(0,0,0,0.7);
    background-color:rgba(241,240,238,0.6);
    padding:6px
}
#contextMenu .farmListContext .farmListWrapper{
    min-height:82px
}
#contextMenu .farmListContext .farmListWrapper:before{
    content:'';
    position:absolute;
    top:38px;
    right:-8px;
    width:8px;
    height:18px;
    border-top:1px solid #e6c77c;
    border-bottom:1px solid #e6c77c;
    background-color:#fff
}
#contextMenu .farmListContext .farmListWrapper:after{
    content:'';
    position:absolute;
    top:42px;
    right:-14px;
    width:10px;
    height:10px;
    border-bottom:1px solid #e6c77c;
    border-left:1px solid #e6c77c;
    -webkit-transform:rotate(225deg) skew(16deg,16deg);
    -moz-transform:rotate(225deg) skew(16deg,16deg);
    -ms-transform:rotate(225deg) skew(16deg,16deg);
    -o-transform:rotate(225deg) skew(16deg,16deg);
    transform:rotate(225deg) skew(16deg,16deg);
    background-color:#fff
}
#contextMenu .farmListContext .farmListWrapper .farmListInner i{
    position:relative;
    top:auto;
    left:auto
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="unselectable">
		<div class="notificationsContainer">
			<div id="reportStream">
				<a class="mainLayoutMenuButton directionFrom withArrowTip reportButton clickable" @click="onNotificationAdd()" content="Отчеты" v-tippy="{placement: 'top-end'}">
					<i class="feature_report_medium_flat_black"></i>
					<div class="arrow"></div>
				</a>
				<a v-if="gameModel.notifications.list.length > 0" class="mainLayoutMenuButton directionFrom withArrowTip clearNotifications clickable" @click="onNotificationCloseAll()" content="Удалить все уведомления" v-tippy="{placement: 'top-end'}">
					<i class="action_cancel_tiny_flat_black"></i>
					<div class="arrowEnding"></div>
				</a>
				<div v-for="n in gameModel.notifications.list" class="notification" :content="n.data.title" v-tippy="{placement: 'top-end'}">
					<a class="clickable" @click="onModalOpen(); onNotificationClose(n.data.id);">
						<i :class="n.data.class"></i>
						<div v-if="n.data.count > 1 && n.data.count < gameModel.notifications.countLimit" class="notificationCount">{{n.data.count}}</div>
						<div v-else-if="n.data.count >= gameModel.notifications.countLimit" class="notificationCount">99+</div>
					</a>
					<i :class="['closeNotification', 'clickable', {'action_cancel_tiny_flat_black': !n.closeHover, 'action_cancel_tiny_flat_red': n.closeHover}]" @mouseover="onMouseOver(n)" @mouseleave="onMouseLeave(n)" @click="onNotificationClose(n.data.id)" content="Удалить уведомление" v-tippy="{placement: 'top-end'}"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {
		
	},
	methods: {
		onMouseLeave(el = null){
			if(el) el.closeHover = false;
		},
		onMouseOver(el = null){
			if(el) el.closeHover = true;
		},
		onNotificationAdd(id = lib.genId()){
			let classes = [
				'movement_adventure_medium_flat_black',
				'movement_defend_medium_flat_positive',
				'movement_defend_medium_flat_uncertain',
				'unit_culturePoint_medium_flat_black',
				'feature_achievement_medium_flat_black',
				'movement_attack_medium_flat_black',
				'movement_capture_medium_flat_black',
				'movement_peace_medium_flat_black',
				'movement_support_medium_flat_black',
				'movement_trade_medium_flat_black',
				'movement_tribute_medium_flat_black',
				'movement_attack_medium_flat_positive',
				'movement_attack_medium_flat_uncertain',
				'movement_attack_medium_flat_negative',
				'feature_prestige_medium_flat_black',
				'cardGame_cardGame_medium_flat_black',
				'movement_spyAttack_medium_flat_positive',
				'movement_spyAttack_medium_flat_uncertain',
				'movement_spyDefend_medium_flat_negative',
				'action_invite_medium_flat_black',
				'notification_sharedKingdom_medium_flat_black',
				'feature_oasis_medium_flat_positive',
				'feature_oasis_medium_flat_negative',
				'feature_oasis_medium_flat_black',
				'feature_robberCamp_medium_flat_black',
				'feature_auction_medium_flat_positive',
				'feature_auction_medium_flat_negative',
				'notification_sharedSecretSociety_medium_flat_black',
				'notification_dukeLeave_medium_flat_negative',
				'notification_dukeAccept_medium_flat_positive',
				'notification_unitedKingdom_medium_flat_black',
			];
			let counts = [1,2,10,100];
			
			this.gameModel.notifications.list.push({
				closeHover: false,
				data: {
					id,
					class: classes[Math.floor(Math.random() * classes.length)],
					count: counts[Math.floor(Math.random() * counts.length)],
					title: 'xxx',
				},
			});
			
			this.$router.push('/reports');
		},
		onNotificationClose(id){
			let notifications = [];
			this.gameModel.notifications.list.forEach(function(notification, index){
				if(notification.data.id != id){
					notifications.push(notification);
				}
			});
			this.gameModel.notifications.list = notifications;
		},
		onNotificationCloseAll(){
			this.gameModel.notifications.list = [];
		},
		onModalOpen(){
			if(this.gameModel.soundOn){
				let audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
			this.gameModel.modalOpen = true;
		}
	},
	components: {},
}
</script>
<template>
	<div class="auction buy" v-if="gameModel.player.deletionTime == 0">
		<div class="filterBar">
			<div class="item-filter" func="filter">
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot1">
					<i class="filterItem heroItem item_category_helmet_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot4">
					<i class="filterItem heroItem item_category_body_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot3">
					<i class="filterItem heroItem item_category_leftHand_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot2">
					<i class="filterItem heroItem item_category_rightHand_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot5">
					<i class="filterItem heroItem item_category_shoes_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot6">
					<i class="filterItem heroItem item_category_horse_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_112">
					<i class="filterItem heroItem item_category_ointment_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_113">
					<i class="filterItem heroItem item_category_scroll_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_114">
					<i class="filterItem heroItem item_category_bucket_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_115">
					<i class="filterItem heroItem item_category_bookOfWisdom_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_116">
					<i class="filterItem heroItem item_category_artwork_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_117">
					<i class="filterItem heroItem item_category_smallBandage_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_118">
					<i class="filterItem heroItem item_category_bandage_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_119">
					<i class="filterItem heroItem item_category_cage_small_flat_black"></i>
				</a>
			</div>
		</div>
		<div>
			<div>
				<table>
					<thead>
						<tr>
							<th colspan="2"><span>Описание</span></th>
							<th>
								<i class="symbol_clock_small_flat_black duration" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration"></i>
							</th>
							<th>
								<i class="feature_auction_small_flat_black" content="Ставки" v-tippy="{placement: 'bottom'}" tooltip-translate="Auction.Bids"></i>
							</th>
							<th colspan="2"><span>Цена</span></th>
							<th class="action" colspan="2"><span>Ставка</span></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="auction in auctionsOnPage" :key="auction.id" :class="{'error': auction.error}" ng-show="auction.timeEnd > gameModel.currentServerTime">
							<td class="item">
								<i class="heroItem clickable" :class="['item_category_'+auction.images[0]+'_small_flat_black']" :content="auction.tooltip" v-tippy="{placement: 'bottom'}" clickable="filter(auction.itemTypeId,true)"></i>
							</td>
							<td>
								<span><span>{{auction.name}}</span></span>
								<span v-if="auction.stackable"> ({{auction.amount}}x)</span>
							</td>
							<td>
								<div :countdown="auction.timeEnd">{{formatUnixDate(auction.timeEnd, 'HH:mm:ss')}}</div>
							</td>
							<td :content="auction.bids > 0 ? 'Игрок с высшей ставкой: '+auction.highestBidderName : 'Ставок пока нет'" v-tippy="{placement: 'bottom'}">
								{{auction.bids}}
							</td>
							<td class="price" :content="auction.amount > 1 ? 'Цена за единицу: '+(auction.price / auction.amount).toFixed(2) : ''" v-tippy="{placement: 'bottom'}" tooltip-translate="Auction.PriceProItem">
								{{auction.price}} <i class="unit_silver_small_illu"></i>
							</td>
							<td>
								<i :class="{
									'feature_auction_small_flat_black disabled': auction.highestBidderPlayerId == 0,
									'feature_auction_small_flat_positive': auction.highestBidderPlayerId == gameModel.player.id,
									'feature_auction_small_flat_negative': hasBidById[auction.id] && auction.highestBidderPlayerId != gameModel.player.id,
									'feature_auction_small_flat_black': !hasBidById[auction.id] && auction.highestBidderPlayerId != gameModel.player.id
								}"></i>
							</td>
							<td class="priceInputCol">
								<input type="number" class="priceInput" @wheel="$event.target.blur()" v-model.number="auction.priceInput" :disabled="auction.highestBidderPlayerId != gameModel.player.id && gameModel.player.silver < auction.price" :class="{
									'highestBidder': auction.highestBidderPlayerId == gameModel.player.id,
									'outbid': hasBidById[auction.id] && auction.highestBidderPlayerId != gameModel.player.id
								}"/>
							</td>
							<td class="bidButtonCol">
								<button v-if="auction.highestBidderPlayerId == gameModel.player.id" clickable="placeBid(8474)" class="animate clickable" play-on-click="19" :class="{'disabled': auction.highestBidderPlayerId != gameModel.player.id && gameModel.player.silver < auction.price}" :content="gameModel.player.silver < auction.price ? 'Недостаточно серебра' : ''" v-tippy="{placement: 'bottom'}">
									<div class="content">
										<span class="innerText">
											<span><span>Изменить</span></span>
										</span>
										<!-- ngIf: price > 0 || forceGoldUsage -->
										<!-- ngIf: price == -1 && !forceGoldUsage -->
										<div class="animationOverlay"></div>
										<i class="checkmark action_check_small_flat_white"></i>
										<i class="checkmark action_check_small_flat_black"></i>
										<i class="checkmark action_check_small_flat_red"></i>
									</div>
								</button>
								<button v-if="auction.highestBidderPlayerId != gameModel.player.id" clickable="placeBid(3022)" play-on-click="19" :class="{'disabled': auction.highestBidderPlayerId != gameModel.player.id && gameModel.player.silver < auction.price}" :content="gameModel.player.silver < auction.price ? 'Недостаточно серебра' : ''" v-tippy="{placement: 'bottom'}" class="clickable">
									<div class="content">
										<span class="innerText">
											<span><span>Предложить</span></span>
										</span>
										<div class="animationOverlay"></div>
										<i class="checkmark action_check_small_flat_white"></i>
										<i class="checkmark action_check_small_flat_black"></i>
										<i class="checkmark action_check_small_flat_red"></i>
									</div>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<pagination :items="auctions" :page-size="10" @change-page="onChangePage" style="padding: 5px 0 0 0;"></pagination>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import Pagination from '@/components/elements/Pagination';

export default {
	mixins: lib.mixins,
	data: () => ({
		hasBidById: {
			8488: 1,
			8489: 1,
		},
		
		auctions: [
			{
				id: 8488,
				tribeId: 0,
				itemTypeId: 119,
				strength: 0,
				bonuses: [],
				amount: 18, //шт
				status: 1,
				timeStart: 1723142860,
				timeEnd: 1723229368,
				price: 1201, //цена (предпоследняя ставка)
				priceInput: 1300, //моя ставка
				bids: 7, //ставок
				highestBid: 1350,
				highestBidderPlayerId: 371,
				highestBidderName: "Лом",
				slot: 7,
				name: 'Клетка',
				images: [
					"cage",
				],
				stackable: true,
				error: false,
				tooltip: ``,
			},
			{
				id: 8489,
				tribeId: 0,
				itemTypeId: 119,
				strength: 0,
				bonuses: [],
				amount: 18, //шт
				status: 1,
				timeStart: 1723142860,
				timeEnd: 1723229368,
				price: 1201, //цена (предпоследняя ставка)
				priceInput: 1300, //моя ставка
				bids: 7, //ставок
				highestBid: 1300,
				highestBidderPlayerId: 1,
				highestBidderName: "lexinzector",
				slot: 7,
				name: 'Клетка',
				images: [
					"cage",
				],
				stackable: true,
				error: false,
				tooltip: ``,
			},
			{
				id: 8490,
				tribeId: 0,
				itemTypeId: 118,
				strength: 0,
				bonuses: {
					21: 0,
				},
				amount: 1,
				status: 1,
				timeStart: 1723142298,
				timeEnd: 1723228698,
				price: 27,
				priceInput: '',
				bids: 5,
				highestBid: 0,
				highestBidderPlayerId: 371,
				highestBidderName: "Лом",
				slot: 7,
				name: 'bandage',
				images: [
					"bandage",
				],
				stackable: true,
				error: false,
				tooltip: ``,
			},
			{
				id: 8491,
				tribeId: 0,
				itemTypeId: 116,
				strength: 2000,
				bonuses: {
					15: 2000,
				},
				amount: 1,
				status: 1,
				timeStart: 1723142630,
				timeEnd: 1723229030,
				price: 7276,
				priceInput: '',
				bids: 4,
				highestBid: 0,
				highestBidderPlayerId: 171,
				highestBidderName: "Неясыть",
				slot: -1,
				name: 'artwork',
				images: [
					"artwork",
				],
				stackable: false,
				error: false,
				tooltip: ``,
			},
			{
				id: 8492,
				tribeId: 0,
				itemTypeId: 119,
				strength: 0,
				bonuses: [],
				amount: 18,
				status: 1,
				timeStart: 1723142860,
				timeEnd: 1723229260,
				price: 1080,
				priceInput: '',
				bids: 4,
				highestBid: 0,
				highestBidderPlayerId: 220,
				highestBidderName: "TiS",
				slot: 7,
				name: 'cage',
				images: [
					"cage",
				],
				stackable: true,
				error: false,
				tooltip: ``,
			},
			{
				id: 8493,
				tribeId: 0,
				itemTypeId: 98,
				strength: 60,
				bonuses: {
					8: 60,
				},
				amount: 1,
				status: 1,
				timeStart: 1723143648,
				timeEnd: 1723230048,
				price: 2829,
				priceInput: '',
				bids: 0,
				highestBid: 0,
				highestBidderPlayerId: 0,
				highestBidderName: "Природа",
				slot: 5,
				name: 'shoes1_1',
				images: [
					"shoes1_1",
				],
				stackable: false,
				error: false,
				tooltip: ``,
			},
			{
				id: 8494,
				tribeId: 0,
				itemTypeId: 103,
				strength: 14,
				bonuses: {
					17: 14,
				},
				amount: 1,
				status: 1,
				timeStart: 1723145012,
				timeEnd: 1723231412,
				price: 1,
				priceInput: '',
				bids: 0,
				highestBid: 0,
				highestBidderPlayerId: 0,
				highestBidderName: "Природа",
				slot: 6,
				name: 'horse1_0',
				images: [
					"horse1_0",
				],
				stackable: false,
				error: false,
				tooltip: ``,
			},
			{
				id: 8495,
				tribeId: 0,
				itemTypeId: 103,
				strength: 14,
				bonuses: {
					17: 14,
				},
				amount: 1,
				status: 1,
				timeStart: 1723145015,
				timeEnd: 1723231415,
				price: 1,
				priceInput: '',
				bids: 0,
				highestBid: 0,
				highestBidderPlayerId: 0,
				highestBidderName: "Природа",
				slot: 6,
				name: 'horse1_0',
				images: [
					"horse1_0",
				],
				stackable: false,
				error: false,
				tooltip: ``,
			},
			{
				id: 8496,
				tribeId: 0,
				itemTypeId: 117,
				strength: 25,
				bonuses: {
					21: 25,
				},
				amount: 52,
				status: 1,
				timeStart: 1723145191,
				timeEnd: 1723231591,
				price: 970,
				priceInput: '',
				bids: 2,
				highestBid: 0,
				highestBidderPlayerId: 161,
				highestBidderName: "kapik",
				slot: 7,
				name: 'small_bandage',
				images: [
					"small_bandage",
				],
				stackable: true,
				error: false,
				tooltip: ``,
			},
			{
				id: 8497,
				tribeId: 0,
				itemTypeId: 119,
				strength: 0,
				bonuses: [],
				amount: 9,
				status: 1,
				timeStart: 1723145492,
				timeEnd: 1723231892,
				price: 500,
				priceInput: '',
				bids: 3,
				highestBid: 0,
				highestBidderPlayerId: 704,
				highestBidderName: "Крикс",
				slot: 7,
				name: 'cage',
				images: [
					"cage",
				],
				stackable: true,
				error: false,
				tooltip: ``,
			},
			{
				id: 8498,
				tribeId: 0,
				itemTypeId: 115,
				strength: 1,
				bonuses: {
					20: 1,
				},
				amount: 1,
				status: 1,
				timeStart: 1723145966,
				timeEnd: 1723232366,
				price: 295,
				priceInput: '',
				bids: 1,
				highestBid: 0,
				highestBidderPlayerId: 161,
				highestBidderName: "kapik",
				slot: -1,
				name: 'book',
				images: [
					"bookOfWisdom",
					"book",
				],
				stackable: false,
				error: false,
				tooltip: ``,
			},
			{
				id: 8499,
				tribeId: 0,
				itemTypeId: 122,
				strength: 1000,
				bonuses: {
					23: 1000,
				},
				amount: 1,
				status: 1,
				timeStart: 1723146069,
				timeEnd: 1723232469,
				price: 16,
				priceInput: '',
				bids: 0,
				highestBid: 0,
				highestBidderPlayerId: 0,
				highestBidderName: "Природа",
				slot: 5,
				name: 'shoes3_1',
				images: [
					"shoes3_1",
				],
				stackable: false,
				error: false,
				tooltip: ``,
			},
		],
		
		pager: {},
		auctionsOnPage: [],
	}),
	computed: {},
	methods: {
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
		
		onChangePage(pageOfItems){
			this.auctionsOnPage = pageOfItems;
		},
	},
	components: {
		Pagination,
	},
}
</script>
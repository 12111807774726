import axios from 'axios';
import lib from '@/lib';

import {kingdomRole} from '@/models/game/Constants';

export default class FlashNotificationsModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.show = true;
		
		this.list = [
			/*{
				type: 'information',
				message: 'Началось обучение войск',
				count: 1,
			},
			{
				type: 'warning',
				message: 'На ashan напали',
				count: 1,
			},
			{
				type: 'error',
				message: 'error...',
				count: 1,
			},
			{
				type: 'debug',
				message: 'debug...',
				count: 1,
			},*/
		];
		
		this.warnings = [
			/*{
				class: '',
				message: 'InvalidRequest',
			},
			{
				class: '',
				message: 'NotFound',
			},*/
		];
		
		this.chat = [
			/*{
				playerId: 1,
				playerUsername: 'lexinzector',
				playerAvatar: '//iwstatic.g.bsrv.su/resources/avatars/125/elf.png',
				kingdomId: 3,
				kingdomName: 'Roman',
				kingdomRole: kingdomRole.governor, //governor, duke, king
				roomId: 1, // в чате
				message: '[report:65d06d38cf462010f80c8ff40001c541e] Успел забрать',
			},*/
		];
	}
	
	/**
	 * Закрыть уведомление
	 */
	doCloseNotification(index)
	{
		this.list.splice(index, 1);
	}
	doCloseWarning(index)
	{
		this.warnings.splice(index, 1);
	}
	doCloseChatNotification(index)
	{
		this.chat.splice(index, 1);
	}
}

import axios from 'axios';
import lib from '@/lib';

export default class NotepadsModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.show = false;
		
		this.list = [];
	}
}

<template>
	<div ng-controller="kingdomProfileCtrl" class="profile">
		<div class="innerProfile">
			<!-- ngIf: player.isKing() && !isSitter && player.data.kingdomId == kingdom.data.groupId -->
			<div class="leaveKingdom" ng-if="player.isGovernor() && player.data.kingdomId == kingdom.data.groupId">
				<i class="headerButton clickable action_leave_small_flat_black" ng-class="{action_leave_small_flat_black: !leaveHover, action_leave_small_flat_red: leaveHover}" on-pointer-over="leaveHover = true" on-pointer-out="leaveHover = false" @click="gameModel.pagesParams.society.kingdomLeaveShow = true" clickable="openOverlay('abdicateGovernor')" content="Покинуть королевство" v-tippy="{placement: 'bottom'}" tooltip-translate="Embassy.Communities.Kingdom.abdicateAsGovernor">
				</i>
			</div>
			<div ng-if="!kingdom.isUnited()" class="kingProfile">
				<player-profile playerid="127" kingdomprofile="Roman">
					<div class="playerProfile">
						<div class="detailsWrapper">
							<div class="contentBox gradient">
								<!-- ngIf: !kingdomProfile -->
								<div class="contentBoxHeader headerWithArrowEndings kingdomRole kingdomRole kingdomRole1">
									<!-- ngIf: !kingdomProfile -->
									<div class="content" ng-if="kingdomProfile">Roman</div>
								</div>
								<div class="contentBoxBody">
									<div class="playerDetails">
										<div class="contentBox transparent">
											<div class="contentBoxHeader">
												<!-- ngIf: !kingdomProfile --><!-- end ngIf: !kingdomProfile -->
												<div ng-if="kingdomProfile">
													<i class="community_kingdom_small_flat_black"></i>
													<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="127" playername="Gaius Ulius">Gaius Ulius</a>
												</div>
												<i ng-if="::playerInfo.language" class="languageFlag ru" content="ru" v-tippy="{placement: 'bottom'}"></i>
											</div>
											<div class="contentBoxBody">
												<div>
													<span class="desc" translate=""><span>Ранг</span></span>
													<span class="data">48</span>
												</div>
												<div>
													<span class="desc" translate=""><span>Население</span></span>
													<span class="data">1644</span>
												</div>
												<div>
													<span class="desc" translate=""><span>Деревни</span></span>
													<span class="data">3</span>
												</div>
												<!-- ngIf: !kingdomProfile -->
												<div>
													<span class="desc" translate=""><span>Народ</span></span>
													<span class="data" translate="" options="1"><span>Римляне</span></span>
												</div>
											</div>
										</div>
									</div>
									<div class="playerProfileAvatar kingdomRole1">
										<div class="avatarLink clickable" clickable="kingdomProfile ? openWindow('profile', {'playerId': 127 }, null, true) : openOverlay('playerProfileFullImage', {'playerId': 127 });">
											<avatar-image scale="0.55" class="avatarImage" player-id="127" size="big" avatar-class="profileImage">
												<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
													<!-- ngIf: playerId == robberId -->
													<!-- ngIf: playerId == npcGovernorId -->
													<canvas width="192" height="193"></canvas>
												</div>
											</avatar-image>
											<div class="prestigeStars" ng-if="config.balancing.features.prestige">
												<div class="innerStars" stars="playerData.stars" size="tiny">
													<div ng-if="!badge">
														<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
														<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
														<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													</div>
												</div>
											</div>
										</div>

										<span class="decoration"></span>
										<!-- ngIf: myPlayerId == playerId && config.balancing.features.prestige -->
										<div ng-if="myPlayerId != playerId && config.balancing.features.prestige" class="prestigeStarsTooltip" tooltip="" tooltip-translate="Prestige.Stars.Tooltip.Other"></div>
									</div>
									<div class="kingdomDetails">
										<div class="background profile_kingdomBackground_layout"></div>
										<div class="contentBox transparent">
											<div class="contentBoxHeader">
												<span class="data" ng-if="playerData.kingdomId > 0" translate=""><span>Королевство</span></span>
												<!-- ngIf: playerData.kingdomId == 0 -->
											</div>
											<!-- ngIf: playerData.kingdomId <= 0 -->
											<div class="contentBoxBody" ng-if="playerData.kingdomId > 0">
												<!-- ngIf: playerData.kingdomRole != ROLE_KING -->
												<div>
													<span class="desc" translate=""><span>Ранг</span></span>
													<span class="data" ng-if="kingdomStats.rank >= 0">4</span>
													<!-- ngIf: kingdomStats.rank === false -->
												</div>
												<div>
													<span class="desc" translate=""><span>Победные очки</span></span>
													<span class="data" ng-if="kingdomStats.victoryPoints > 0">75&nbsp;355</span>
													<!-- ngIf: kingdomStats.victoryPoints === false -->
												</div>
												<div>
													<span class="desc" translate=""><span>Сокровища</span></span>
													<span class="data" ng-if="kingdomStats.treasures">5&nbsp;626</span>
													<!-- ngIf: !kingdomStats.treasures -->
												</div>
												<div>
													<span class="desc" translate=""><span>Срок (дни)</span></span>
													<span class="data">35</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div ng-include="" ng-if="kingdomProfile" src="'tpl/society/partials/kingdom/wwProgress.html'">
							<!-- ngIf: kingdomStats && kingdomStats.worldWonderLvl !== false -->
						</div>
					</div>
				</player-profile>
				<chat-online-status class="kingStatus" chat-user="kingdom.getKing().chatUser">
					<!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE -->
				</chat-online-status>
			</div>
			<!-- ngIf: kingdom.isUnited() -->

			<div ng-if="unionAvailable || alreadyInvited" class="contentBox unionContainer">
				<div class="contentBoxBody" ng-if="player.data.kingdomId == kingdom.data.groupId">
					<span translate=""><span>Объединиться с другим королевством</span></span>
					<a clickable="openWindow('help', {'pageId': 'Kingdom_Unions'});" translate="" class="clickable"><span>Узнать больше</span></a>
					<!-- ngIf: player.isKing() -->
				</div>
				<!-- ngIf: player.isKing() && player.data.kingdomId != kingdom.data.groupId -->
			</div>

			<div class="contentBox dukeSlots" ng-if="dukeSlots" ng-class="{'myKingdom': myKingdom, 'unitedKingdom': kingdom.isUnited()}">
				<div class="contentBoxHeader headerWithArrowEndings purple">
					<div class="content" translate=""><span>Герцоги королевства</span></div>
				</div>
				<div class="contentBoxBody clear">
					<div ng-repeat="(id, slot) in dukeSlots" class="dukeSlot">
						<div class="playerProfileAvatar small active kingdomRole2" clickable="dukeAction(slot)" on-pointer-over="dukeHover = true" on-pointer-out="dukeHover = false" tooltip="" tooltip-translate-switch="{'Kingdom.Dukes.Slots.Tooltip.cancelInvitation': false,
																'Kingdom.Dukes.Slots.Tooltip.PromoteDuke.Description': false }">
							<div class="headerWithArrowEndings kingdomRole kingdomRole2">
								<div class="content" ng-if="slot.duke">
									<span class="truncated">Scepa</span>
								</div>
								<!-- ngIf: !slot.duke -->
							</div>
							<div ng-if="slot.type == 'active'">
								<!-- ngIf: myKingdom || slot.duke.playerId === player.data.playerId -->
								<chat-online-status chat-user="slot.duke.chatUser">
									<!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE -->
								</chat-online-status>
							</div>
							<!-- ngIf: slot.type == 'invitation' -->
							<!-- ngIf: slot.type == 'available' && myKingdom -->

							<div class="avatarLink">
								<avatar-image class="avatarImage" player-id="115" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="125" height="125"></canvas>
									</div>
								</avatar-image>
								<div class="prestigeStars" ng-if="config.balancing.features.prestige">
									<div class="innerStars" playerid="115" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</div>
							<span class="decoration"></span>
						</div>
					</div>
					<div ng-repeat="(id, slot) in dukeSlots" class="dukeSlot">
						<div class="playerProfileAvatar small active kingdomRole2" clickable="dukeAction(slot)" on-pointer-over="dukeHover = true" on-pointer-out="dukeHover = false" tooltip="" tooltip-translate-switch="{'Kingdom.Dukes.Slots.Tooltip.cancelInvitation': false,
																'Kingdom.Dukes.Slots.Tooltip.PromoteDuke.Description': false }">
							<div class="headerWithArrowEndings kingdomRole kingdomRole2">
								<div class="content" ng-if="slot.duke">
									<span class="truncated">Mild</span>
								</div>
								<!-- ngIf: !slot.duke -->
							</div>
							<div ng-if="slot.type == 'active'">
								<!-- ngIf: myKingdom || slot.duke.playerId === player.data.playerId -->
								<chat-online-status chat-user="slot.duke.chatUser">
									<!-- ngIf: chatUser && chatUser.data.status !== ChatUser.STATUS_NOT_AVAILABLE -->
								</chat-online-status>
							</div>
							<!-- ngIf: slot.type == 'invitation' -->
							<!-- ngIf: slot.type == 'available' && myKingdom -->

							<div class="avatarLink">
								<avatar-image class="avatarImage" player-id="314" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="125" height="125"></canvas>
									</div>
								</avatar-image>
								<div class="prestigeStars" ng-if="config.balancing.features.prestige">
									<div class="innerStars" playerid="314" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</div>
							<span class="decoration"></span>
						</div>
					</div>
				</div>
			</div>

			<div class="statisticsTable contentBox transparent kingdomGovernors">
				<div class="contentBoxHeader headerWithArrowEndings tableGreen">
					<div class="content" translate=""><span>Губернаторы</span></div>
				</div>
				<div class="contentBoxBody">
					<div class="governors">
						<table class="gradient">
							<thead>
								<tr>
									<th><i class="symbol_ranking_small_flat_black"></i></th>
									<th><i class="community_member_small_flat_black"></i></th>
									<th><i class="unit_population_small_flat_black"></i></th>
								</tr>
							</thead>
							<tbody>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="637" player-name="Неясыть" rank="0" points="2391" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank1" ng-if="rank >= 0">1</td>
									<td player-column="" player-id="637" player-name="Неясыть" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="637" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="637" playername="Неясыть" nolink="false">Неясыть</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="637" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">2&nbsp;391</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="225" player-name="Ile4ka88" rank="1" points="1883" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank2" ng-if="rank >= 0">2</td>
									<td player-column="" player-id="225" player-name="Ile4ka88" chat-user="chatUser" class="playerColumn">
									<avatar-image ng-if="::!accountId" class="avatarImage" player-id="225" scale="0.5" avatar-class="profileImage">
										<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
											<!-- ngIf: playerId == robberId -->
											<!-- ngIf: playerId == npcGovernorId -->
											<canvas width="62" height="62"></canvas>
										</div>
									</avatar-image>
									<!-- ngIf: ::accountId -->
									<!-- ngIf: chatUser -->
									<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="225" playername="Ile4ka88" nolink="false">Ile4ka88</a>

									<!-- ngIf: kingdomId >= 0 -->
									<div class="prestigeStars" ng-if="!hidePrestige">
										<div class="innerStars" playerid="225" size="tiny">
											<div ng-if="!badge">
												<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											</div>
										</div>
									</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;883</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="192" player-name="KING" rank="2" points="1739" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank3" ng-if="rank >= 0">3</td>
									<td player-column="" player-id="192" player-name="KING" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="192" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="192" playername="KING" nolink="false">KING</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="192" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;739</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="756" player-name="pavlun" rank="3" points="1682" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank4" ng-if="rank >= 0">4</td>
									<td player-column="" player-id="756" player-name="pavlun" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="756" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="756" playername="pavlun" nolink="false">pavlun</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="756" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
													<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;682</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="131" player-name="Tils" rank="4" points="1610" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank5" ng-if="rank >= 0">5</td>
									<td player-column="" player-id="131" player-name="Tils" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="131" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="131" playername="Tils" nolink="false">Tils</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="131" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;610</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="528" player-name="Olaf" rank="5" points="1509" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank6" ng-if="rank >= 0">6</td>
									<td player-column="" player-id="528" player-name="Olaf" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="528" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="528" playername="Olaf" nolink="false">Olaf</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="528" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;509</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="204" player-name="Карабас" rank="6" points="1437" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank7" ng-if="rank >= 0">7</td>
									<td player-column="" player-id="204" player-name="Карабас" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="204" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="204" playername="Карабас" nolink="false">Карабас</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="204" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;437</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="170" player-name="VIX" rank="7" points="1432" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank8" ng-if="rank >= 0">8</td>
									<td player-column="" player-id="170" player-name="VIX" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="170" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="170" playername="VIX" nolink="false">VIX</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="170" size="tiny">
												<i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;432</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="455" player-name="Олово" rank="8" points="1407" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank9" ng-if="rank >= 0">9</td>
									<td player-column="" player-id="455" player-name="Олово" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="455" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="455" playername="Олово" nolink="false">Олово</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="455" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;407</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="263" player-name="Chega" rank="9" points="1360" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank10" ng-if="rank >= 0">10</td>
									<td player-column="" player-id="263" player-name="Chega" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="263" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="263" playername="Chega" nolink="false">Chega</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="263" size="tiny">
												<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;360</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="610" player-name="ппц" rank="10" points="1311" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank11" ng-if="rank >= 0">11</td>
									<td player-column="" player-id="610" player-name="ппц" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="610" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="610" playername="ппц" nolink="false">ппц</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="610" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;311</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="629" player-name="LatR" rank="11" points="1251" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank12" ng-if="rank >= 0">12</td>
									<td player-column="" player-id="629" player-name="LatR" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="629" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="629" playername="LatR" nolink="false">LatR</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="629" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;251</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="890" player-name="AnDee" rank="12" points="1176" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank13" ng-if="rank >= 0">13</td>
									<td player-column="" player-id="890" player-name="AnDee" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="890" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="890" playername="AnDee" nolink="false">AnDee</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="890" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;176</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="662" player-name="Пригожин" rank="13" points="1023" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank14" ng-if="rank >= 0">14</td>
									<td player-column="" player-id="662" player-name="Пригожин" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="662" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="662" playername="Пригожин" nolink="false">Пригожин</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="662" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;023</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="749" player-name="av_p001" rank="14" points="1000" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank15" ng-if="rank >= 0">15</td>
									<td player-column="" player-id="749" player-name="av_p001" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="749" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="749" playername="av_p001" nolink="false">av_p001</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="749" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">1&nbsp;000</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="391" player-name="Diego" rank="15" points="991" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank16" ng-if="rank >= 0">16</td>
									<td player-column="" player-id="391" player-name="Diego" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="391" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="391" playername="Diego" nolink="false">Diego</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="391" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
													<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">991</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="125" player-name="сербез" rank="16" points="974" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank17" ng-if="rank >= 0">17</td>
									<td player-column="" player-id="125" player-name="сербез" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="125" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="125" playername="сербез" nolink="false">сербез</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="125" size="tiny">
												<i ng-if="badge" class="prestigeBadge prestige_silverBadge_tiny_illu"></i>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">974</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="1212" player-name="safarich" rank="17" points="873" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank18" ng-if="rank >= 0">18</td>
									<td player-column="" player-id="1212" player-name="safarich" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1212" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1212" playername="safarich" nolink="false">safarich</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="1212" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">873</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="1156" player-name="ВАРЯГ" rank="18" points="853" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank19" ng-if="rank >= 0">19</td>
									<td player-column="" player-id="1156" player-name="ВАРЯГ" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1156" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1156" playername="ВАРЯГ" nolink="false">ВАРЯГ</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="1156" size="tiny">
												<i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">853</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
								<tr ng-repeat="governor in governors" on-pointer-over="myKingdom && (showDismissGovernor = true)" on-pointer-out="myKingdom && (showDismissGovernor = false)" governor-row="" player-id="731" player-name="doky45" rank="19" points="825" chat-user="governor.chatUser" show-dismiss-governor-button="showDismissGovernor" callback="::openGovernorDismissOverlay">
									<td class="rankColumn rank20" ng-if="rank >= 0">20</td>
									<td player-column="" player-id="731" player-name="doky45" chat-user="chatUser" class="playerColumn">
										<avatar-image ng-if="::!accountId" class="avatarImage" player-id="731" scale="0.5" avatar-class="profileImage">
											<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
												<!-- ngIf: playerId == robberId -->
												<!-- ngIf: playerId == npcGovernorId -->
												<canvas width="62" height="62"></canvas>
											</div>
										</avatar-image>
										<!-- ngIf: ::accountId -->
										<!-- ngIf: chatUser -->
										<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="731" playername="doky45" nolink="false">doky45</a>

										<!-- ngIf: kingdomId >= 0 -->
										<div class="prestigeStars" ng-if="!hidePrestige">
											<div class="innerStars" playerid="731" size="tiny">
												<div ng-if="!badge">
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starGold_tiny_illu"></i>
													<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
												</div>
											</div>
										</div>
									</td>
									<td class="pointsColumn number" ng-if="!showDismissGovernorButton || !isKing && !isViceKing">825</td>
									<!-- ngIf: showDismissGovernorButton && (isKing || isViceKing) -->
								</tr>
							</tbody>
						</table>
					</div>
					<div class="contentBox description">
						<div class="contentBoxHeader">
							<span translate=""><span>Описание</span></span>
							<!-- ngIf: player.isKing() && !isSitter && player.data.kingdomId == kingdom.data.groupId -->
						</div>
						<div class="contentBoxBody scrollable" scrollable="">
							<div class="scrollContentOuterWrapper" style="width: 277px;">
								<div class="scrollContent" dynamic-height="false" style="width: 277px; overflow-y: hidden;">
									<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
										<div user-text-parse="kingdom.data.description.publicDescription" parse="decorations"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems" current-page="currentPage" display-page-func="displayCurrentPage" route-named-param="governorPage">
				<div ng-transclude=""></div>
				<div class="tg-pagination" ng-show="visible">
					<ul>
						<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
							<i ng-class="{
								action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover && currentPage == 1),
								action_toBeginning_tiny_flat_green: beginningHover && currentPage != 1,
								disabled: currentPage == 1
							}" class="action_toBeginning_tiny_flat_black disabled"></i>
						</li>
						<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
							<i ng-class="{
								symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover && currentPage == 1),
								symbol_arrowFrom_tiny_flat_green: fromHover && currentPage != 1,
								disabled: currentPage == 1
							}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
						</li>
						<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
						</li>
						<li class="number clickable" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(2)">
							<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}">2</a>
						</li>
						<li class="nextPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
							<i ng-class="{
								symbol_arrowTo_tiny_flat_black: !toHover || (toHover && disableNext()),
								symbol_arrowTo_tiny_flat_green: toHover && !disableNext(),
								disabled: disableNext()
							}" class="symbol_arrowTo_tiny_flat_black"></i>
						</li>
						<li class="lastPage clickable" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
							<i ng-class="{
								action_toEnd_tiny_flat_black: !endHover || (endHover && disableNext()),
								action_toEnd_tiny_flat_green: endHover && !disableNext(),
								disabled: disableNext()
							}" class="action_toEnd_tiny_flat_black"></i>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="dockedChatWindow" :room="chatInfo.data.roomId" v-if="chatInfo.data.isDocked">
		<div class="chatWindow">
			<div>
				<div class="chatControls">
					<chat-room-header
						:chatInfo="chatInfo"
						@onCloseActiveTab="onCloseActiveTab"
						@onMinimizeActiveTab="onMinimizeActiveTab"
					></chat-room-header>
				</div>
				<div class="dockedChatBody">
					<div class="chat-window-body">
						<div class="chatBody scrollable">
							<div class="scrollContentOuterWrapper" style="width: 338px;">
								<div class="scrollContent" style="width: 342px; overflow-y: auto;">
									<div class="scrollContentInnerWrapper" style="width: auto;">
										<ul>
											<li>
												<div class="chat-time-divider">
													<h6 class="divider">
														<span class="fadeIn"></span>
														<span class="label"><span>Последние 7 дней</span></span>
														<span class="fadeOut"></span>
													</h6>
												</div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage female">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus">
															<i class="onlineStatus chat_status_afk_tiny_illu"></i>
														</div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">Rufus</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">12:24</span>
												</div>
												<div class="lineBody">
													<span>!</span>
												</div>
											</li>
											<li>
												<div class="chat-time-divider"></div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage female">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus">
															<i class="onlineStatus chat_status_afk_tiny_illu"></i>
														</div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">Zerrod</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
														<div class="moreDropDownBody dropdownBody local">
															<ul>
																<li class="clickable">Личная беседа</li>
																<li class="clickable">Перейти к профилю</li>
																<li class="clickable">Сообщить об игроке</li>
															</ul>
														</div>
													</div>
													<span class="date">12:28</span>
												</div>
												<div class="lineBody">
													<span>.!.</span>
												</div>
											</li>
											<li>
												<div class="chat-time-divider"></div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage male">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus">
															<i class="onlineStatus chat_status_online_tiny_illu"></i>
														</div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">Борк</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">14:15</span>
												</div>
												<div class="lineBody">
													<span>;?;</span>
												</div>
											</li>
											<li>
												<div class="chat-time-divider"></div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage female">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus">
															<i class="onlineStatus chat_status_afk_tiny_illu"></i>
														</div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">Zerrod</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">20:27</span>
												</div>
												<div class="lineBody">
													<span>.!.</span>
												</div>
											</li>
											<li>
												<div class="chat-time-divider"></div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage male">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus"></div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">AssKell</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">20:28</span>
												</div>
												<div class="lineBody">
													<span>фу такое показывать</span>
												</div>
											</li><li>
												<div class="chat-time-divider"></div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage female">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus">
															<i class="onlineStatus chat_status_afk_tiny_illu"></i>
														</div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">Zerrod</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">20:28</span>
												</div>
												<div class="lineBody">
													<span>З===Э</span>
												</div>
												<div class="lineBody samePlayerAndTime">
													<span>не. не так</span>
												</div>
												<div class="lineBody samePlayerAndTime">
													<span>8===э</span>
													<br/>
													<span>во</span>
												</div>
											</li>
											<li>
												<div class="chat-time-divider"></div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage male">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus"></div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">Кривбасс</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">23:21</span>
												</div>
												<div class="lineBody">
													<span>Есть кто?</span>
												</div>
											</li>
											<li>
												<div class="chat-time-divider">
													<h6 class="divider" ng-if="timeTitle != ''">
														<span class="fadeIn"></span>
														<span class="label"><span>Вчера</span></span>
														<span class="fadeOut"></span>
													</h6>
												</div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage male">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus">
															<i class="onlineStatus chat_status_online_tiny_illu"></i>
														</div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">zakovi</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">15:06</span>
												</div>
												<div class="lineBody">
													<span>что с пленными делать?</span>
												</div>
											</li>
											<li>
												<div class="chat-time-divider"></div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage female">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus"></div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">Шапокляк</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">15:09</span>
												</div>
												<div class="lineBody">
													<span>к стенке и расстреливать</span>
												</div>
											</li>
											<li>
												<div class="chat-time-divider"></div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage male">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus"></div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">BlackTaku</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">15:16</span>
												</div>
												<div class="lineBody">
													<span>принести в жертву во имя злого бога</span>
												</div>
											</li>
											<li>
												<div class="chat-time-divider"></div>
											</li>
											<li class="line otherPlayer">
												<div class="chat-room-member">
													<div class="chatMember clickable">
														<div class="playerBox">
															<div class="avatar-image playerAvatar">
																<div class="heroImage male">
																	<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
																</div>
															</div>
														</div>
														<div class="chat-online-status chatStatus"></div>
													</div>
												</div>
												<div class="lineHead">
													<span class="name truncated">azaza</span>
													<div class="options">
														<a class="iconButton clickable">
															<i class="symbol_arrowDown_tiny_illu"></i>
														</a>
													</div>
													<span class="date">20:06</span>
												</div>
												<div class="lineBody">
													<span>2-й уровень предметов на ауке</span>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="askForDesktopNotification" ng-if="displayDesktopNotificationInfo">
							<div class="confirmQuestion" translate="">
								<span>Включить </span><strong>уведомление на рабочей панели</strong><span>, когда приходит новое сообщение?</span>
							</div>
							<button class="cancel clickable" clickable="cancelDisplayDesktopNotification()">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Отмена</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->

									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
							<button class="acceptButton clickable" clickable="acceptDisplayDesktopNotification()">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Принять</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->

									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
						<div class="inputWrapper">
							<i class="chat_insert_message_small_flat writeMessage"></i>
							<span role="status" class="ui-helper-hidden-accessible"></span>
							<textarea class="chatInput ui-autocomplete-input" autocomplete="off" placeholder="Нажми здесь, чтобы ответить"></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--
	<div class="dockedChatWindow">
		<div class="chatWindow">
			<div>
				<div class="chatControls">
					<div src="'tpl/chat/partials/chatRoomHeader.html'">
						<div class="chatHeader">
							<div class="chat-room-member">
								<div class="chatMember clickable">
									<div class="playerBox">
										<div class="avatar-image playerAvatar">
											<div class="heroImage male">
												<img src="//iwstatic.g.bsrv.su/resources/avatars/125/demon.png" width="56" height="56" />
											</div>
										</div>
									</div>
									<div class="chat-online-status chatStatus"></div>
									<div class="prestigeStars">
										<div class="innerStars">
											<div>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
												<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							<a class="playerName playerLink truncated clickable">alistan</a>
							<div class="membership">
								<a class="kingdomLink truncated clickable disabled">Арма-15</a>
							</div>
							<div class="flagHanger governor">
								<div class="flag"></div>
								<i class="community_governor_small_flat_black"></i>
							</div>
							<div class="controls">
								<i class="control clickable action_cancel_tiny_flat_black" on-pointer-over="cancelHover = true" on-pointer-out="cancelHover = false" ng-class="{action_cancel_tiny_flat_black: !cancelHover, action_cancel_tiny_flat_green: cancelHover}"></i>
								<i class="control clickable window_minimize_tiny_flat_black" on-pointer-over="minimizeHover = true" on-pointer-out="minimizeHover = false" ng-class="{window_minimize_tiny_flat_black: !minimizeHover, window_minimize_tiny_flat_green: minimizeHover}"></i>
								<i class="control clickable window_undock_tiny_flat_black" on-pointer-over="undockHover = true" on-pointer-out="undockHover = false" ng-class="{window_undock_tiny_flat_black: !undockHover, window_undock_tiny_flat_green: undockHover}"></i>
							</div>
							<div class="options">
								<a class="iconButton clickable">
									<i class="symbol_arrowDown_tiny_illu"></i>
								</a>
								<div class="moreDropDownBody dropdownBody" style="top: 357px; left: 176px; display: block;">
									<ul>
										<li clickable="openWindow('profile', {'playerId': 3047, 'profileTab': 'playerProfile'}); closeMoreDropdown()" class="clickable">Перейти к профилю</li>
										<li clickable="reportConversation('1.5140.3047', 3047); closeMoreDropdown()" class="clickable">Пожаловаться на сообщение</li>
										<li clickable="ignorePlayer('1.5140.3047',3047); closeMoreDropdown()" class="clickable">Добавить в черный список</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="dockedChatBody">
					<div class="chat-window-body">
						<div class="chatBody scrollable">
							<div class="scrollContentOuterWrapper" style="width: 331px;">
								<div class="scrollContent" style="width: 331px; overflow-y: auto;">
									<div class="scrollContentInnerWrapper" style="width: auto;">
										<ul>
											<li>
												<div class="chat-time-divider">
													<h6 class="divider">
														<span class="fadeIn"></span>
														<span class="label"><span>Последние 7 дней</span></span>
														<span class="fadeOut"></span>
													</h6>
												</div>
											</li>
											<li>
												<div class="chat-time-divider">
													<h6 class="divider">
														<span class="fadeIn"></span>
														<span class="label"><span>Вчера</span></span>
														<span class="fadeOut"></span>
													</h6>
												</div>
											</li>
											<li>
												<div class="chat-time-divider">
													<h6 class="divider">
														<span class="fadeIn"></span>
														<span class="label"><span>Сегодня</span></span>
														<span class="fadeOut"></span>
													</h6>
												</div>
											</li>
											<li class="line currentPlayer new">
												<div class="lineHead">
													<span class="date"><span>прямо сейчас</span></span>
												</div>
												<div class="lineBody"><span>test message...</span></div>
											</li>
											<li class="line otherPlayer new">
												<div class="lineHead">
													<span class="date"><span>прямо сейчас</span></span>
												</div>
												<div class="lineBody"><span>test message...</span></div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="askForDesktopNotification">
							<div class="confirmQuestion">
								<span>Включить </span><strong>уведомление на рабочей панели</strong><span>, когда приходит новое сообщение?</span>
							</div>
							<button class="cancel clickable">
								<div class="content">
									<span class="innerText">
										<span><span>Отмена</span></span>
									</span>
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
							<button class="acceptButton clickable">
								<div class="content">
									<span class="innerText">
										<span><span>Принять</span></span>
									</span>
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
						<div class="inputWrapper">
							<i class="chat_insert_message_small_flat writeMessage"></i>
							<span role="status" class="ui-helper-hidden-accessible"></span><textarea class="chatInput ui-autocomplete-input" autocomplete="off" placeholder="Нажми здесь, чтобы ответить" style="height: 30px; overflow: hidden;"></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	-->
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';

import ChatRoomHeader from '@/components/partials/ChatRoomHeader';

export default {
	mixins: lib.mixins,
	props: ['chatInfo'],
	data: () => ({
		
	}),
	computed: {},
	methods: {
		onCloseActiveTab(roomId){
			this.$emit('onCloseActiveTab', roomId);
		},
		onMinimizeActiveTab(roomId){
			this.$emit('onMinimizeActiveTab', roomId);
		},
	},
	components: {
		ChatRoomHeader,
	},
}
</script>
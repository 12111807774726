<template>
	<div class="reportBody tradeReport" src="'tpl/report/types/'+reportType+'.html'">
		<div class="contentBox  gradient tradeReport">
			<div class="contentBoxHeader">
				<div class="content" translate=""><span>Ресурсы</span></div>
			</div>
			<div class="contentBoxBody">
				<display-resources :resources="report.resources"></display-resources>
				<div class="horizontalLine"></div>
				<div class="tradeDuration" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.RunTime">
					<i class="symbol_clock_small_flat_black duration"></i> 00:07:04
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import DisplayResources from '@/components/elements//DisplayResources';

export default {
	mixins: lib.mixins,
	props: {
		report: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
	},
	components: {
		DisplayResources,
	},
}
</script>
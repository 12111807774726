<template>
	<div class="prestigeTab">
		<div class="leftCol">
			<div class="currentWeekEstimation">
				<div class="contentBox">
					<div class="contentBoxHeader">
						<span translate=""><span>Престиж за неделю</span></span>
						<div class="estimatedPrestigeThisWeek">
							<div class="withArrowTip"></div>
							<span class="weekPrestigeAmount">+8</span>
							<i class="feature_prestige_small_flat_black"></i>
							<div class="arrow"></div>
						</div>
					</div>
					<div class="contentBoxBody">
						<div translate="" class="timeLeft" data="days:7"><span>Осталось дней: 7</span></div>
						<div class="horizontalLine"></div>
						<div translate="" class="prestigeEstimationExplanation"><span>Показывает количество очков, набранных за эту неделю.</span><br><span>Престиж начисляется в конце недели.</span></div>
					</div>
				</div>
			</div>
			<div class="clear"></div>
			<div class="currentPrestigeLevel">
				<div class="contentBox">
					<div class="contentBoxHeader">
						<span translate=""><span>Престиж</span></span>
						<div class="headerWithArrowEndings goldenEnding">
							<div class="content">
								<div class="innerStars" stars="stars" size="small">
									<div ng-if="!badge">
										<i class="prestigeStar prestige_starSilver_small_illu"></i>
										<i class="prestigeStar prestige_starBronze_small_illu"></i>
										<i class="prestigeStar prestige_starBronze_small_illu"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="spacing"></div>
					<div class="contentBoxBody">
						<div class="prestigeCategoryRow">
							<div class="prestigeCategoryName" translate=""><span>В этом игровом мире</span></div>
							<div class="prestigeCategoryIcon">
								<i class="feature_prestige_small_flat_black"></i>
							</div>
							<div class="prestigeCategoryAmount">2</div>
						</div>
						<div class="horizontalLine"></div>
						<div class="prestigeCategoryRow">
							<div class="prestigeCategoryName" translate=""><span>Всего</span></div>
							<div class="prestigeCategoryIcon">
								<i class="feature_prestige_small_flat_black"></i>
							</div>
							<div class="prestigeCategoryAmount">247</div>
						</div>
						<div class="horizontalLine"></div>
						<div class="prestigeCategoryRow">
							<div class="prestigeCategoryName" ng-if="nextLevelGlobalPrestige != 0" translate=""><span>След. уровень</span></div>
							<div class="prestigeCategoryIcon">
								<i class="feature_prestige_small_flat_black inactive"></i>
							</div>
							<div class="prestigeCategoryAmount">300</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rightCol currentConditions scrollable" scrollable="">
			<div class="scrollContentOuterWrapper active" style="width: 385px;">
				<div class="scrollContent" dynamic-height="false" style="width: 405px; overflow-y: auto;">
					<div class="scrollContentInnerWrapper" ng-transclude="" style="width: 385px;">
						<div class="contentBox">
							<div class="contentBoxHeader centeredTitle headerColored">
								<span translate=""><span>Испытания</span></span>
							</div>
							<div class="conditionTable">
								<table class="columnOnly">
									<tbody>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span ng-if="!condition.tooltipTranslationKey &amp;&amp; (condition.type!='ranking' || rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Повысить уровень героя</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">0</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;1&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span ng-if="!condition.tooltipTranslationKey &amp;&amp; (condition.type!='ranking' || rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Атаковать или защитить других игроков 3 раза</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">&#x202D;&#x202D;1&#x202C;&#x202C;</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;3&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span ng-if="condition.tooltipTranslationKey &amp;&amp; (condition.type!='ranking' || rankingConditions.length == 0)" tooltip="" tooltip-translate="Prestige.Tooltip.AdventurePoints" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Завершить 7 приключений</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">0</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;7&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span ng-if="!condition.tooltipTranslationKey &amp;&amp; (condition.type!='ranking' || rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Завершить 7 ежедневных заданий</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">0</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;7&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span ng-if="!condition.tooltipTranslationKey &amp;&amp; (condition.type!='ranking' || rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Завершить 3 исследования</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">0</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;3&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span ng-if="!condition.tooltipTranslationKey &amp;&amp; (condition.type!='ranking' || rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Увеличить население на 100</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">&#x202D;&#x202D;24&#x202C;&#x202C;</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;100&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span ng-if="!condition.tooltipTranslationKey &amp;&amp; (condition.type!='ranking' || rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Написать 1 сообщение любому игроку</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">0</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;1&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span ng-if="condition.tooltipTranslationKey &amp;&amp; (condition.type!='ranking' || rankingConditions.length == 0)" tooltip="" tooltip-translate="Prestige.Tooltip.AcquireVillage" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Новое поселение</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">0</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;1&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span ng-if="!condition.tooltipTranslationKey &amp;&amp; (condition.type!='ranking' || rankingConditions.length == 0)" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" class="checkBox negative">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Продавайте краденные ценности или собирайте их в сокровищнице в качестве дани</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">0</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;10&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
										<tr class="conditionTableRow conditionTableRowLast fulfilled" ng-class="{'conditionTableRowLast':$last, 'fulfilled': condition.fulfilled}" ng-repeat="condition in conditions | orderBy:'conditionType'">
											<td class="conditionTableColumn conditionTableColumnCheckbox">
												<span class="checkBox positive" ng-class="{'positive': condition.fulfilled, 'negative': !condition.fulfilled}" ng-if="condition.type=='ranking'" tooltip="" tooltip-data="rankings" tooltip-url="tpl/profile/partials/rankingPrestigeTooltip.html" tooltip-placement="above">
													<i class="action_check_small_flat_white"></i>
												</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Улучшить свой рейтинг или удержаться в топ-10</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved" ng-bind-html="0 | bidiRatio:condition.croppedValue:condition.threshold:true">&lrm;&lrm;&#x202D;&#x202D;<span class="amount wrapper">&#x202D;&#x202D;1&#x202C;&#x202C;</span>&#x202C;&#x202C;/&lrm;&#x202D;&#x202D;<span class="capacity">&#x202D;&#x202D;1&#x202C;&#x202C;</span>&#x202C;&#x202C;</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="spacing"></div>
							<div class="clear"></div>
							<div class="contentBoxHeader centeredTitle headerColored">
								<span translate=""><span>Топ-10 недели</span></span>
								<span class="prestigeRankingHeaderRank">
									<i class="symbol_ranking_small_flat_black" content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Rank"></i>
								</span>
								<span class="prestigeRankingHeaderPrestige">
									<i class="feature_prestige_small_flat_black" content="Престиж" v-tippy="{placement: 'bottom'}" tooltip-translate="Prestige.Top10.Header.Tooltip"></i>
								</span>
							</div>
							<div class="conditionTable">
								<table class="columnOnly">
									<tbody>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last}" ng-repeat="ranking in top10rankings">
											<td class="conditionTableColumn conditionTableColumnIcon">
												<i class="movement_attack_small_flat_black"></i>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Атакующий недели</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved rank">
												<span>-</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnPrestige"> </td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last}" ng-repeat="ranking in top10rankings">
											<td class="conditionTableColumn conditionTableColumnIcon">
												<i class="movement_defend_small_flat_black"></i>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Обороняющийся недели</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved rank">
												<span>-</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnPrestige"></td>
										</tr>
										<tr class="conditionTableRow" ng-class="{'conditionTableRowLast':$last}" ng-repeat="ranking in top10rankings">
											<td class="conditionTableColumn conditionTableColumnIcon">
												<i class="ranking_climber_small_flat_black"></i>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Взлет недели</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved rank">
												<span>4</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnPrestige">
												<i class="feature_prestige_small_flat_black" ng-if="ranking.rank <= 10"></i>
												<span ng-if="ranking.rank <= 10">7</span>
											</td>
										</tr>
										<tr class="conditionTableRow conditionTableRowLast" ng-class="{'conditionTableRowLast':$last}" ng-repeat="ranking in top10rankings">
											<td class="conditionTableColumn conditionTableColumnIcon">
												<i class="unit_capacity_small_flat_black"></i>
											</td>
											<td class="conditionTableColumn conditionTableColumnName">
												<span class="conditionName">Грабитель недели</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnAchieved rank">
												<span>205</span>
											</td>
											<td class="conditionTableColumn conditionTableColumnPrestige">
												<span ng-if="ranking.rank > 10">-</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="clear"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear"></div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
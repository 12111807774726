<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper hero" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal hero">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div>
							<div class="contentHeader unselectable">
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>{{gameModel.player.username}} (Уровень: {{gameModel.player.level}})</span></span>
								</h2>
							</div>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<nav class="tabulation maintab unselectable">
									<a class="tab clickable" :class="{active: tab == 'inventory', inactive: tab != 'inventory'}" @click="onToggleTab('inventory')">
										<div class="content">
											<span>Инвентарь</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'attributes', inactive: tab != 'attributes'}" @click="onToggleTab('attributes')">
										<div class="content">
											<span>Параметры</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'auctions', inactive: tab != 'auctions'}" @click="onToggleTab('auctions')">
										<div class="content">
											<span>Аукцион</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'adventures', inactive: tab != 'adventures'}" @click="onToggleTab('adventures')">
										<div class="content">
											<span>Приключение</span>
										</div>
									</a>
									<a class="tab clickable" :class="{active: tab == 'card-game', inactive: tab != 'card-game'}" @click="onToggleTab('card-game')">
										<div class="content">
											<span>Карточная игра</span>
										</div>
									</a>
								</nav>
								<div class="tabulationContent scrollable">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div class="loadedTab currentTab" v-if="tab == 'inventory'">
													<inventory />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'attributes'">
													<attributes />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'auctions'">
													<auctions />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'adventures'">
													<adventures />
												</div>
												<div class="loadedTab currentTab" v-if="tab == 'card-game'">
													<card-game />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay">
							
							<!-- дополнительная строительная площадка -->
							<unlock-additional-location v-if="gameModel.unlockAdditionalLocationShow" />
							
							<!-- продать краденые ценности -->
							<sell-stolen-goods v-if="gameModel.inventory.sellStolenGoodsShow" />
							
							<!-- получить бонус по зерну -->
							<use-resource-bonus-crop v-if="gameModel.inventory.useResourceBonusCropShow" />
							
							<!-- слияние бонусов по зерну -->
							<use-resource-bonus-crop-confirm v-if="gameModel.inventory.useResourceBonusCropConfirmShow" />
							
							<!-- получить бонус по ресурсам кроме зерна -->
							<use-resource-bonus-res v-if="gameModel.inventory.useResourceBonusResShow" />
							
							<!-- слияние бонусов по ресурсам кроме зерна -->
							<use-resource-bonus-crop-confirm v-if="gameModel.inventory.useResourceBonusResConfirmShow" />
							
							<!-- обновление повязок -->
							<upgrade-bandages v-if="gameModel.inventory.upgradeBandagesShow" />
							
							<!-- использовать лечебное зелье -->
							<use-item-healing-potion v-if="gameModel.inventory.useItemHealingPotionShow" />
							
							<!-- сколько предметов ты хочешь использовать / использовать свиток -->
							<use-item-scroll v-if="gameModel.inventory.useItemScrollShow" />
							
							<!-- использовать произведение искусства -->
							<use-item-treasure v-if="gameModel.inventory.useItemTreasureShow" />
							
							<!-- изменить внешность -->
							<hero-editor v-if="gameModel.inventory.heroEditorShow" />

							<!-- выставление предмета на аукцион -->
							<confirm-selling v-if="gameModel.auctions.confirmSellingShow" />
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import Inventory from '@/components/pages/hero/Inventory';
import Attributes from '@/components/pages/hero/Attributes';
import Auctions from '@/components/pages/hero/Auctions';
import Adventures from '@/components/pages/hero/Adventures';
import CardGame from '@/components/pages/hero/CardGame';

import UnlockAdditionalLocation from '@/components/pages/hero/windowOverlay/UnlockAdditionalLocation';
import SellStolenGoods from '@/components/pages/hero/windowOverlay/SellStolenGoods';
import UseResourceBonusCrop from '@/components/pages/hero/windowOverlay/UseResourceBonusCrop';
import UseResourceBonusCropConfirm from '@/components/pages/hero/windowOverlay/UseResourceBonusCropConfirm';
import UseResourceBonusRes from '@/components/pages/hero/windowOverlay/UseResourceBonusRes';
import UseResourceBonusResConfirm from '@/components/pages/hero/windowOverlay/UseResourceBonusResConfirm';
import UpgradeBandages from '@/components/pages/hero/windowOverlay/UpgradeBandages';
import UseItemHealingPotion from '@/components/pages/hero/windowOverlay/UseItemHealingPotion';
import UseItemScroll from '@/components/pages/hero/windowOverlay/UseItemScroll';
import UseItemTreasure from '@/components/pages/hero/windowOverlay/UseItemTreasure';
import HeroEditor from '@/components/pages/hero/windowOverlay/HeroEditor';
import ConfirmSelling from '@/components/pages/hero/windowOverlay/ConfirmSelling';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Твой персонаж | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		tab: 'inventory',
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onToggleTab(tab, push = true){
			this.tab = tab;
			if(push) this.$router.push({query: {tab}});
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_8.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.tab) this.tab = this.$route.query.tab;
		else this.$router.push({query: {tab: this.tab}});
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(!to.query.tab) this.$router.push({query: {tab: 'inventory'}});
		else if(to.query.tab != this.tab) this.onToggleTab(to.query.tab, false);
		next();
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {
		Inventory,
		Attributes,
		Auctions,
		Adventures,
		CardGame,
		
		UnlockAdditionalLocation,
		SellStolenGoods,
		UseResourceBonusCrop,
		UseResourceBonusCropConfirm,
		UseResourceBonusRes,
		UseResourceBonusResConfirm,
		UpgradeBandages,
		UseItemHealingPotion,
		UseItemScroll,
		UseItemTreasure,
		HeroEditor,
        ConfirmSelling,
	},
}
</script>
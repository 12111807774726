<template>
	<div ng-if="overlayController" class="windowOverlay" id="allPaymentMethods" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="572"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Shop.Popup.AllPaymentMethods.Header" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Все способы оплаты</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><div class="allPaymentMethods scrollable" scrollable=""><div class="scrollContentOuterWrapper" style="width: 868px;">
	<div class="scrollContent" dynamic-height="false" style="width: 868px; overflow-y: hidden;">
		<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
	<!-- ngRepeat: paymentMethod in paymentMethods --><div class="paymentMethod" ng-repeat="paymentMethod in paymentMethods">
		<img ng-src="https://tgp-psp.traviangames.com/img/qiwi_cc.png" src="https://tgp-psp.traviangames.com/img/qiwi_cc.png" data-cmp-info="10">
		<div class="providerName">Credit cards Russia</div>
	</div><!-- end ngRepeat: paymentMethod in paymentMethods --><div class="paymentMethod" ng-repeat="paymentMethod in paymentMethods">
		<img ng-src="https://tgp-psp.traviangames.com/img/paypal.png" src="https://tgp-psp.traviangames.com/img/paypal.png" data-cmp-info="10">
		<div class="providerName">PayPal</div>
	</div><!-- end ngRepeat: paymentMethod in paymentMethods --><div class="paymentMethod" ng-repeat="paymentMethod in paymentMethods">
		<img ng-src="https://tgp-psp.traviangames.com/img/adyen/adyen_cc.png" src="https://tgp-psp.traviangames.com/img/adyen/adyen_cc.png" data-cmp-info="10">
		<div class="providerName">Debit and credit cards</div>
	</div><!-- end ngRepeat: paymentMethod in paymentMethods -->
</div>
	</div>
</div>
</div></div>
	</div>
</div></div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="troopsInVillages">
		<div class="troopsDetailContainer" ng-repeat="village in troopsInVillage">
		<div class="troopsDetailHeader">
			<span translate=""><span>Всего войск в</span></span> <a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" villagename="Брест">Брест</a>
			<div class="troopsInfo" tooltip="" tooltip-translate="Resource.CropConsumption">
				<span><i class="unit_consumption_small_flat_black"></i> 726 <span translate=""><span>в час</span></span></span>
			</div>
		</div>
		<!-- ngRepeat: troops in village.troops --><div troops-details="" ng-repeat="troops in village.troops" troop-data="troops"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;184&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;135&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;33&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;52&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;102&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;5&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div><!-- end ngRepeat: troops in village.troops --><div troops-details="" ng-repeat="troops in village.troops" troop-data="troops"><table class="troopsTable nature">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall nature unitType1 inactive" unit-icon="" data="31" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall nature unitType2 inactive" unit-icon="" data="32" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall nature unitType3 inactive" unit-icon="" data="33" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall nature unitType4" unit-icon="" data="34" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall nature unitType5 inactive" unit-icon="" data="35" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall nature unitType6 inactive" unit-icon="" data="36" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall nature unitType7 inactive" unit-icon="" data="37" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall nature unitType8 inactive" unit-icon="" data="38" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall nature unitType9 inactive" unit-icon="" data="39" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall nature unitType10 inactive" unit-icon="" data="40" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;38&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div><!-- end ngRepeat: troops in village.troops -->
	</div>
		<div class="troopsDetailContainer" ng-repeat="village in troopsInVillage">
			<div class="troopsDetailHeader">
				<span translate=""><span>Всего войск в</span></span> <a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536133632}, null, true)" village-link="" villageid="536133632" villagename="InZectorsk">InZectorsk</a>
				<div class="troopsInfo" content="Потребление" v-tippy="{placement: 'bottom'}">
					<span><i class="unit_consumption_small_flat_black"></i> 9 <span translate=""><span>в час</span></span></span>
				</div>
			</div>
			<div troops-details="" ng-repeat="troops in village.troops" troop-data="troops">
				<table class="troopsTable gaul">
					<tbody class="troopsIconRow">
						<tr>
							<td ng-repeat="n in []|range:1:11" class="unit1">
								<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit2">
								<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit3">
								<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit4">
								<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit5">
								<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit6">
								<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit7">
								<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit8">
								<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit9">
								<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="unit10">
								<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
							<td ng-repeat="n in []|range:1:11" class="hero">
								<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] &amp;&amp; !troopData['originalTroops'][n])
																|| (!troopData['originalTroops'] &amp;&amp; (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
													  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
								<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
								<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
							</td>
						</tr>
					</tbody>
					<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
						<!-- ngIf: troopsType == 'trapped' -->
						<tr>
							<td ng-repeat="n in [] | range:1:11" class="unit1">
								<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
									<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
									<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;5&#x202C;&#x202C;&#x202C;&#x202C;</span>
									<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
								</div>
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit2">
								<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<div ng-if="troopsType != 'input' &amp;&amp; troopData[troopsType+'Troops'][n] >= 1">
									<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
									<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) &amp;&amp; !(troopsType == 'lost' &amp;&amp; n == Troops.TYPE_HERO &amp;&amp; troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;4&#x202C;&#x202C;&#x202C;&#x202C;</span>
									<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
								</div>
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit3">
								<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit4">
								<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit5">
								<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit6">
								<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit7">
								<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit8">
								<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit9">
								<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="unit10">
								<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
								<!-- ngIf: troopsType == 'input' -->
							</td>
							<td ng-repeat="n in [] | range:1:11" class="hero">
								<span ng-if="troopsType == 'original' &amp;&amp; (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
								<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

								<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
								<!-- ngIf: troopsType == 'input' -->
							</td>
						</tr>
					</tbody>
					<!-- ngIf: troopData.supply -->
					<!-- ngIf: troopData.cagedAnimals -->
				</table>
			</div><!-- end ngRepeat: troops in village.troops -->
		</div><!-- end ngRepeat: village in troopsInVillage -->
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
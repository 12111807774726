<template>
    <div ng-if="overlayController" class="windowOverlay" id="secretSocietyInvite" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="572"><div class="inWindowPopup secretSocietyInvite">
	<div class="inWindowPopupHeader">
		<h4 translate="" options="Society.Invite.Headline"><span>Пригласить игрока</span></h4>
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent" ng-controller="secretSocietyInviteCtrl">
		<div class="inBox">
			<div class="contentBox transparent">
				<h6 class="contentBoxHeader headerColored" translate=""><span>Имя</span></h6>

				<div class="contentBoxBody">
					<!-- ngRepeat: (nr, player) in selectedPlayer track by $index --><div ng-repeat="(nr, player) in selectedPlayer track by $index">
						<span class="invitePlayer_0 serverautocompleteContainer serverautocompleteContainer ng-valid" autocompletedata="player" ng-model="dummyModel" autocompletecb="selectInvitePlayer.0" auto-focus-after-select=".secretSocietyInvite .invitePlayer_1 input" input-autofocus="true">

	<!-- ngIf: showOwnVillages -->
	<span role="status" aria-live="polite" class="ui-helper-hidden-accessible"></span><input ng-disabled="disabledInput" class="targetInput ng-pristine ng-valid ui-autocomplete-input ng-touched" type="text" ng-model="modelInput" ng-focus="onFocus();hideOwnVillagesList();" autocomplete="off" placeholder="Поиск...">
	<label ng-class="{ownVillagesShown: showOwnVillages}" style="width: 254px;">
		<i class="action_search_tiny_flat_white"></i>
		Игрок
	</label>
	<!-- ngIf: showOwnVillages -->
</span>
						<!-- ngIf: nr == selectedPlayer.length-1 && nr < maxInvite-1 --><span ng-if="nr == selectedPlayer.length-1 &amp;&amp; nr < maxInvite-1">
							<div class="iconButton clickable" clickable="addNewPlayer()" tooltip="" tooltip-translate="Group.Invite.AddAnotherPlayer">
								<i class="symbol_plus_tiny_flat_black"></i>
							</div>
						</span><!-- end ngIf: nr == selectedPlayer.length-1 && nr < maxInvite-1 -->
					</div><!-- end ngRepeat: (nr, player) in selectedPlayer track by $index -->
				</div>
			</div>
		</div>
		<div class="inBox">
			<div class="contentBox transparent">
				<h6 class="contentBoxHeader headerColored" translate=""><span>Сообщение (по желанию)</span></h6>

				<div class="contentBoxBody">
					<textarea rows="10" maxlength="2000" ng-model="customText" class="ng-pristine ng-untouched ng-valid ng-valid-maxlength"></textarea>
				</div>
			</div>
		</div>

		<div class="buttonFooter">
			<button ng-class="{disabled: !atLeastOnePlayer}" clickable="inviteToSociety();" class="clickable disabled"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Пригласить</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
			<button clickable="closeOverlay();" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Отмена</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
			<div class="error"></div>
		</div>
	</div>
</div></div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
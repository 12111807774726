<template>
	<div class="mainOverview">
	<table class="villagesTable lined">
		<thead>
			<tr>
				<th><span>Деревня</span></th>
				<th><span>Прибывающие</span></th>
				<th><span>Исходящие</span></th>
				<th><span>Строительство</span></th>
				<th><span>Войска</span></th>
			</tr>
		</thead>
		<tbody>
			<tr ng-repeat="village in overview | orderBy:'villageName'" ng-class="{activeVillage:activeVillageId == village.villageId}" class="activeVillage">
				<td>
					<a clickable="setVillage(village.villageId)" class="truncated clickable">InZectorsk</a>
				</td>
				<td>
					<i class="movement_attack_small_flat_black" ng-if="village.attacks.underAttack > 0" content="Прибыв. атаки: 1x" v-tippy="{placement: 'bottom'}" tooltip-translate="VillagesOverview.Tooltip.IncomingAttacks" tooltip-data="count:1"></i>
					<div ng-show="village.attacks.underAttack <= 0" class="ng-hide">-</div>
				</td>
				<td>
					<i class="movement_attack_small_flat_black" ng-if="village.attacks.attacking > 0" content="Собств. атаки: 2x" v-tippy="{placement: 'bottom'}" tooltip-translate="VillagesOverview.Tooltip.OutgoingAttacks" tooltip-data="count:2"></i>
					<div ng-show="village.attacks.attacking <= 0">-</div>
				</td>
				<td>
					<i ng-class="{feature_buildingQueue_small_illu: building.queueType != BuildingQueueModel.TYPE_MASTER_BUILDER, feature_buildingMaster_small_illu: building.queueType == BuildingQueueModel.TYPE_MASTER_BUILDER}" ng-repeat="building in village.buildingQueue | orderBy:['queueType!=4', '-queuePosition', '-finished']" content="<div class='constructionTooltip'>
						<span>Главное здание</span>
						<span class='finishTime'><span countdown='1708238988' callback=''>00:00:00</span><span>ч</span></span>
					</div>" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/villagesOverview/partials/constructionTooltip.html" clickable="openBuilding(building.locationId, village.villageId)" class="clickable feature_buildingMaster_small_illu"></i>
					<i ng-class="{feature_buildingQueue_small_illu: building.queueType != BuildingQueueModel.TYPE_MASTER_BUILDER, feature_buildingMaster_small_illu: building.queueType == BuildingQueueModel.TYPE_MASTER_BUILDER}" ng-repeat="building in village.buildingQueue | orderBy:['queueType!=4', '-queuePosition', '-finished']" content="<div class='constructionTooltip'>
						<span>Амбар</span>
						<span class='finishTime'><span countdown='1708249181' callback=''>00:52:47</span><span>ч</span></span>
					</div>" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/villagesOverview/partials/constructionTooltip.html" clickable="openBuilding(building.locationId, village.villageId)" class="clickable feature_buildingQueue_small_illu"></i>
					<div ng-if="village.buildingQueue.length <= 0">-</div>
				</td>
				<td class="troopsProduction">
					<i clickable="gotoBuilding(29)" ng-repeat="item in unitBuilding" content="<div class='queueEntryTooltip'>
						<div class='tableWrapper'>
							<table class='transparent'>
								<tbody>
									<tr class='unitQueueTitle'>
										<th class='queueEntryTitle'>
											<h3 class='queueEntryTitle'><span>Казарма</span></h3>
										</th>
										<th class='time'>
											<span><span countdown='1708246941' callback=''>00:08:22</span><span>ч</span></span>
										</th>
									</tr>
								</tbody>
							</table>
							<div class='horizontalLine'></div>
							<table class='transparent'>
								<tbody>
									<tr ng-repeat='entry in itemData.queue'>
										<td class='subinfo'>
											<span>1x</span> <span>Фаланга</span>
										</td>
										<td class='subinfo time'>
											<span><span countdown='1708246941' callback=''>00:08:22</span><span>ч</span></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class='tableWrapper ng-hide'>
							<table class='transparent'>
								<tbody>
									<tr class='unitQueueTitle'>
										<th class='queueEntryTitle'>
											<h3 class='queueEntryTitle'><span>Большая казарма</span></h3>
										</th>
										<th class='time'>
											<span><span countdown='' callback=''>00:00:00</span><span>ч</span></span>
										</th>
									</tr>
								</tbody>
							</table>
							<div class='horizontalLine'></div>
							<table class='transparent'>
								<tbody></tbody>
							</table>
						</div>
					</div>" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/mainlayout/partials/unitQueueTooltip.html" on-pointer-over="g19Hover = true" on-pointer-out="gHover = false" ng-if="item.state == 'active'" ng-class="{building_g19_small_flat_black: !gHover, building_g_small_flat_green: gHover}" village-id="537182205" show-active="" class="clickable building_g19_small_flat_black"></i>
					<i clickable="gotoBuilding(39)" ng-repeat="item in unitBuilding" content="<div class='queueEntryTooltip'>
						<div class='tableWrapper'>
							<table class='transparent'>
								<tbody>
									<tr class='unitQueueTitle'>
										<th class='queueEntryTitle'>
											<h3 class='queueEntryTitle'><span>Конюшня</span></h3>
										</th>
										<th class='time'>
											<span><span countdown='1708247736' callback=''>00:22:07</span><span>ч</span></span>
										</th>
									</tr>
								</tbody>
							</table>
							<div class='horizontalLine'></div>
							<table class='transparent'>
								<tbody>
									<tr ng-repeat='entry in itemData.queue'>
										<td class='subinfo'>
											<span>1x</span> <span><span>Тевтатский гром</span></span>
										</td>
										<td class='subinfo time'>
											<span><span countdown='1708247736' callback=''>00:22:07</span><span>ч</span></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class='tableWrapper ng-hide'>
							<table class='transparent'>
								<tbody>
									<tr class='unitQueueTitle'>
										<th class='queueEntryTitle'>
											<h3 class='queueEntryTitle'><span>Большая конюшня</span></h3>
										</th>
										<th class='time'>
											<span><span countdown='' callback=''>00:00:00</span><span>ч</span></span>
										</th>
									</tr>
								</tbody>
							</table>
							<div class='horizontalLine'></div>
							<table class='transparent'>
								<tbody></tbody>
							</table>
						</div>
					</div>" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/mainlayout/partials/unitQueueTooltip.html" on-pointer-over="g20Hover = true" on-pointer-out="gHover = false" ng-if="item.state == 'active'" ng-class="{building_g20_small_flat_black: !gHover, building_g_small_flat_green: gHover}" village-id="537182205" show-active="" class="clickable building_g20_small_flat_black"></i>
					<i ng-if="village.smithyQueue" clickable="openBuildingByType(13, village.villageId)" content="Кузница" v-tippy="{placement: 'bottom'}" tooltip-translate="Building_13" class="building_g13_small_flat_black clickable"></i>
					<i ng-if="village.academyQueue" clickable="openBuildingByType(22, village.villageId)" content="Академия" v-tippy="{placement: 'bottom'}" tooltip-translate="Building_22" class="building_g22_small_flat_black clickable"></i>
				</td>
			</tr>
		</tbody>
	</table>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import BuildingQueueModel from '@/models/game/BuildingQueueModel';

export default {
	mixins: lib.mixins,
	data: () => ({
		BuildingQueueModel,
		
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
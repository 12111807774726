<template>
	<div class="tg-pagination" v-if="pager.pages && pager.pages.length">
		<ul>
			<li class="firstPage clickable" :class="{'disabled': pager.currentPage == 1}" @mouseover="beginningHover = true" @mouseleave="beginningHover = false" @click="setPage(1)">
				<i :class="{
					'action_toBeginning_tiny_flat_black': !beginningHover || (beginningHover && pager.currentPage == 1),
					'action_toBeginning_tiny_flat_green': beginningHover && pager.currentPage != 1,
					'disabled': pager.currentPage == 1
				}"></i>
			</li>
			<li class="prevPage clickable" :class="{'disabled': pager.currentPage == 1}" @mouseover="fromHover = true" @mouseleave="fromHover = false" @click="setPage(pager.currentPage - 1)">
				<i :class="{
					'symbol_arrowFrom_tiny_flat_black': !fromHover || (fromHover && pager.currentPage == 1),
					'symbol_arrowFrom_tiny_flat_green': fromHover && pager.currentPage != 1,
					'disabled': pager.currentPage == 1
				}"></i>
			</li>
			<li class="number clickable" :class="{'disabled': pager.currentPage == 1}" @click="setPage(1)">
				<a :class="{'disabled': pager.currentPage == 1}">1</a>
			</li>
			<li class="number clickable disabled" v-if="pager.currentPage - alfa > 2">
				<a class="disabled">...</a>
			</li>
			<li class="number clickable" :class="{'disabled': pager.currentPage == page}" @click="setPage(page)" v-show="a2 >= a1" v-for="page in range(a1, a2)" :key="page">
				<a :class="{'disabled': pager.currentPage == page}">{{page}}</a>
			</li>
			<li class="number clickable disabled" v-if="pager.currentPage + alfa < pager.totalPages - 1">
				<a class="disabled">...</a>
			</li>
			<li class="number clickable" :class="{'disabled': pager.currentPage == pager.totalPages}" @click="setPage(pager.totalPages)" v-if="pager.totalPages > 1">
				<a :class="{'disabled': pager.currentPage == pager.totalPages}">{{pager.totalPages}}</a>
			</li>
			<!--
			<li class="number clickable" v-for="page in pager.pages" :key="page" :class="{'disabled': pager.currentPage === page}" @click="setPage(page)">
				<a :class="{'disabled': pager.currentPage === page}">{{page}}</a>
			</li>
			-->
			<li class="nextPage clickable" :class="{'disabled': pager.currentPage == pager.totalPages}" @mouseover="toHover = true" @mouseleave="toHover = false" @click="setPage(pager.currentPage + 1)">
				<i :class="{
					'symbol_arrowTo_tiny_flat_black': !toHover || (toHover && pager.currentPage == pager.totalPages),
					'symbol_arrowTo_tiny_flat_green': toHover && pager.currentPage != pager.totalPages,
					'disabled': pager.currentPage == pager.totalPages
				}"></i>
			</li>
			<li class="lastPage clickable" :class="{'disabled': pager.currentPage == pager.totalPages}" @mouseover="endHover = true" @mouseleave="endHover = false" @click="setPage(pager.totalPages)">
				<i :class="{
					action_toEnd_tiny_flat_black: !endHover || (endHover && pager.currentPage == pager.totalPages),
					action_toEnd_tiny_flat_green: endHover && pager.currentPage != pager.totalPages,
					disabled: pager.currentPage == pager.totalPages
				}"></i>
			</li>
		</ul>
	</div>
</template>

<style lang="css" scoped>
.tg-pagination {
	text-align: center;
	padding: 5px 0px 0px;
}
</style>

<script>
import lib from '@/lib';
import paginate from 'jw-paginate';

export default {
	props: {
		items: {
			type: Array,
			required: true,
		},
		initialPage: {
			type: Number,
			default: 1,
		},
		pageSize: {
			type: Number,
			default: 10,
		},
		maxPages: {
			type: Number,
			default: 99999,
		},
	},
	emits: ['changePage'],
	data: () => ({
		beginningHover: false,
		fromHover: false,
		toHover: false,
		endHover: false,
		
		pager: {},
		
		alfa: 1,
	}),
	computed: {
		a1(){
			return Math.max(this.pager.currentPage - this.alfa, 2);
		},
		a2(){
			return Math.min(this.pager.currentPage + this.alfa, this.pager.totalPages - 1);
		},
	},
	watch: {
		items: {
			handler(val, oldVal) {
				if(this.pager){
					this.setPage(this.pager.currentPage);
				} else {
					this.setPage(this.initialPage);
				}
			},
			deep: true,
		},
	},
	created(){
		// set to initial page
		this.setPage(this.initialPage);
	},
	methods: {
		range: lib.range,
		
		setPage(page){
			const { items, pageSize, maxPages } = this;
			// get new pager object for specified page
			const pager = paginate(items.length, page, pageSize, maxPages);
			// get new page of items from items array
			const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
			// update pager
			this.pager = pager;
			// emit change page event to parent component
			this.$emit('changePage', pageOfItems);
		},
	}
}
</script>
<template>
	<div ng-if="overlayController" class="windowOverlay" id="confirmSelling" ng-controller="overlayController">
		<div class="darkener clickable" clickable="closeOverlay()"></div>
		<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="499">
			<div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
				<div class="inWindowPopupHeader">
					<h4 translate="" options="Auction.Sell.Confirmation.Header" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Ты действительно желаешь продать этот предмет?</span></h4>
					<h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4>
					<a class="closeOverlay clickable" clickable="closeOverlay()" @click="gameModel.auctions.confirmSellingShow = false"><span>Закрыть</span></a>
				</div>
				<div class="inWindowPopupContent">
					<div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']">
						<!-- ngIf: isNpcBuying === false -->
						<div class="auctionConfirmSelling" ng-if="isNpcBuying">
							<hero-item-container class="heroItemContainer" item="item" hide-amount="true" hide-item-states="true">
								<div class="entityBox item item_115 noClick" ng-class="{ 	disabled: !itemObject.filled || isDisabled(),
									noClick: !itemObject.filled || isDisabled() || !clickHandler,
									equipped: itemObject.data.inSlot > 0 &amp;&amp; !hideEquippedState,
									highlight: highlighted(),
									premiumItem: itemObject.data.premiumItem,
									empty: !itemObject.filled
									}" tooltip="" tooltip-url="tpl/hero/partials/itemTooltip.html" tooltip-data="id:33081,notUsableItem:,notUsableReason:,auctionItem:false,nextUpgradeLevel:false,itemType:115" tooltip-show="true">
									<i class="dragMarker ng-hide" ng-show="dragObject"></i>
									<img class="heroItem_book_large_illu male" src="layout/images/x.gif" data-cmp-info="10"/>
									<!-- ngIf: isDisabled() -->
									<!-- ngIf: !hideStates -->
									<div class="amountContainer upgradedItem ng-hide" ng-show="itemObject.data.upgradedItem &amp;&amp; !hideAmountContainer">
										<div class="amount">
											<i class="upgrade_upgrade_tiny_flat_black"></i>
										</div>
									</div>
									<!-- ngIf: !hideAmountContainer -->
								</div>
							</hero-item-container>
							<div class="priceCalculation">
								<div class="heroItemName" translate="" options="115"><span>Книга мудрости</span></div>
								<div class="avgMarketPrice">
									<span translate=""><span>Средняя цена на рынке:</span></span>
									<span class="priceAmount">448</span>
								</div>
								<div ng-if="tier > 0">
									<span translate="" data="multiplier:0.5" options="50"><span>* 0.5 (Качество: очень низкое)</span></span>
									<span translate="" data="multiplier:1" options="100"><span>* 1 (Качество: среднее)</span></span>
									<span translate="" data="multiplier:1.25" options="125"><span>* 1.25 (Качество: хорошее)</span></span>
									<span translate="" data="multiplier:1.5" options="150"><span>* 1.5 (Качество: превосходное)</span></span>
									<span class="priceAmount">692</span>
								</div>
								<div>
									<span ng-if="owners == 0" translate="" data="multiplier:1"><span>* 1 (Возраст: этот предмет новый)</span></span>
									<span ng-if="owners > 0" translate="" data="multiplier:0.75, owners: 1"><span>* 0.75 (Возраст: предыдущие владельцы - 1).</span></span>
									<span class="priceAmount">448</span>
								</div>
								<div ng-if="amount > 1">
									<span translate="" data="multiplier: 99"><span>* 99 (количество)</span></span>
									<span class="priceAmount">3564</span>
								</div>
								<div class="totalPrice">
									<span translate=""><span>Цена продажи:</span></span>
									<span class="priceAmount"><i class="unit_silver_small_illu"></i> 448</span>
								</div>
							</div>
						</div>
						<!-- ngIf: equipped -->
						<div class="buttonFooter">
							<button clickable="sellItemAction()" play-on-click="17" class="clickable">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Ок</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
							<button class="cancel clickable" clickable="closeOverlay()">
								<div class="content">
									<span ng-transclude="" class="innerText">
										<span translate=""><span>Отмена</span></span>
									</span>
									<!-- ngIf: price > 0 || forceGoldUsage -->
									<!-- ngIf: price == -1 && !forceGoldUsage -->
									<div class="animationOverlay"></div>
									<i class="checkmark action_check_small_flat_white"></i>
									<i class="checkmark action_check_small_flat_black"></i>
									<i class="checkmark action_check_small_flat_red"></i>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
<template>
    <div ng-if="overlayController" class="windowOverlay" id="foundKingdom" ng-controller="overlayController">
	<div class="darkener clickable" clickable="closeOverlay()"></div>
	<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="572"><div class="inWindowPopup" ng-class="{warning: overlayConfig['isAWarning']}">
	<div class="inWindowPopupHeader">
		<!-- ngIf: overlayConfig['inWindowPopupTitle'] != '' --><h4 translate="" options="Embassy.Communities.Kingdom.FoundKingdom.Title" ng-if="overlayConfig['inWindowPopupTitle'] != ''"><span>Основать королевство</span></h4><!-- end ngIf: overlayConfig['inWindowPopupTitle'] != '' -->
		<!-- ngIf: overlayConfig['inWindowPopupTitleText'] != '' --><h4 ng-if="overlayConfig['inWindowPopupTitleText'] != ''"></h4><!-- end ngIf: overlayConfig['inWindowPopupTitleText'] != '' -->
		<a class="closeOverlay clickable" clickable="closeOverlay()" translate=""><span>Закрыть</span></a>
	</div>
	<div class="inWindowPopupContent">
		<!-- ngInclude:  --><div ng-include="" src="overlayConfig['inWindowPopupContentTemplate']"><p translate=""><span>Внимание! Ты действительно хочешь взять на себя обязанности короля?</span><br><span>Имей в виду, что, будучи королем, ты не можешь активировать режим отпуска. Но тебе могут помочь заместитель или дуал.</span></p>
<!-- ngIf: player.hasScheduledVacation(currentServerTime + player.data.coronationDuration) -->
<div class="contentBox transparent tag">
	<h6 class="contentBoxHeader headerColored" translate=""><strong>Дай имя своему королевству!</strong><span> (макс. 8 символов)</span></h6>

	<div class="contentBoxBody">
		<input type="text" maxlength="8" ng-model="form.tag" placeholder="Название королевства" class="ng-pristine ng-untouched ng-valid ng-valid-maxlength">
	</div>
</div>
<!-- ngIf: amountStolenGoods > 0 --><div ng-if="amountStolenGoods > 0">
	<p><span translate="" data="amount:128"><span>Количество краденых ценностей, которое у тебя имеется: 128. Они будут автоматически преобразованы в сокровища. Ты не получишь за них ресурсы.</span></span></p>
</div><!-- end ngIf: amountStolenGoods > 0 -->
<!-- ngIf: coronationDuration > 0 -->
<!-- ngIf: currentPlayer.data.hasNoobProtection -->
<!-- ngIf: foundKingError -->
<div class="buttonFooter">
	<button clickable="confirmBecomeKing();" class="clickable"><div class="content">
	<span ng-transclude="" class="innerText">
		<span translate=""><span>Основать королевство</span></span>
	</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
	<button clickable="closeOverlay();" class="cancel clickable"><div class="content">
	<span ng-transclude="" class="innerText">
		<span translate=""><span>Отмена</span></span>
	</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button>
</div></div>
	</div>
</div></div>
</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){},
	components: {},
}
</script>
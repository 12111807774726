<template>
	<div ng-if="overlayController" class="windowOverlay" id="kingdomInvitation" ng-controller="overlayController">
		<div class="darkener clickable" @click="gameModel.buildings.embassy.kingdomInvitationShow = false" clickable="closeOverlay()"></div>
		<div ng-include="" src="overlayConfig['inWindowPopupTemplate']" dynamic-height="true" dynamic-max-height="634">
			<div class="inWindowPopup invitationDetails">
				<div class="inWindowPopupHeader">
					<div ng-if="!confirmationStep">
						<i ng-if="::invitation.data.invitedAs==Player.KINGDOM_ROLE_GOVERNOR || invitation.data.invitedAs==Player.KINGDOM_ROLE_DUKE" class="communityIcon community_kingdom_large_illu"></i>
					</div>
					<a class="closeOverlay clickable" @click="gameModel.buildings.embassy.kingdomInvitationShow = false" clickable="closeOverlay()">
						<span>Закрыть</span>
					</a>
				</div>
				<div class="inWindowPopupContent">
					<div ng-if="!confirmationStep" class="contentBox transparent invitationBody">
						<h6 class="contentBoxHeader headerColored">
							<span translate="" ng-if="::invitation.data.invitedAs==Player.KINGDOM_ROLE_GOVERNOR && invitation.data.cellId">
								<span>Переселиться в королевство</span>
							</span>
							<div class="sender">
								<span translate=""><span>От:</span></span>
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="127" playername="Gaius Ulius">Gaius Ulius</a>
							</div>
						</h6>
						<div class="contentBoxBody">
							<!-- ngIf: ::invitation.isGovernorInvitation() -->
							<!-- ngIf: ::invitation.isDukeInvitation() -->
							<!-- ngIf: ::invitation.isUnionInvitation(Player.KINGDOM_ROLE_KING) -->
							<!-- ngIf: ::invitation.isUnionInvitation(Player.KINGDOM_ROLE_VICEKING) -->
							<!-- ngIf: ::invitation.isRelocationInvitation() -->
							<span ng-if="::invitation.isRelocationInvitation()" translate="" data="playerRole:1,playerId:127,playerName:Gaius Ulius,kingdomId:3,kingdomName:Roman"><translate options="Kingdom.Role_1"><span>Король</span></translate> <a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="127" playername="Gaius Ulius" nolink="" showmissinglink="true">Gaius Ulius</a><span> предлагает тебе </span><strong>надел земли</strong><span> в своем королевстве </span><a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3" nolink="Roman" showmissinglink="true">Roman</a><span>:</span></span>
							<!-- end ngIf: ::invitation.isRelocationInvitation() -->
							<!-- ngIf: ::invitation.isDukeInvitation() && player.hasScheduledVacation() -->
						</div>
						<!-- ngIf: ::invitation.isRelocationInvitation() -->
						<div ng-if="::invitation.isRelocationInvitation()" class="relocationInfo">
							<div ng-if="relocationCellData">
								<div data="cellId:537182205" translate="">
									<span class="coordinateWrapper" x="id" y="537182205">
										<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537182205_1707909594.2');" class="clickable">
											<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">
												<span class="coordinateX">(-3</span>
												<span class="coordinatePipe">|</span>
												<span class="coordinateY">9)</span>
											</span>
										</a>
									</span>
									<span> ― это хорошо сбалансированный надел земли, со всеми ресурсами, какие необходимы для развития деревни.</span>
								</div>
								<div class="contentBox fertility">
									<div class="contentBoxHeader headerColored">
										<span class="coordinateWrapper" coordinates="" x="id" y="537182205" nolink="true" aligned="false">
											<span ng-if="!hasLink" class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">
												<span class="coordinateX">(-3</span>
												<span class="coordinatePipe">|</span>
												<span class="coordinateY">9)</span>
											</span>
										</span>
										<span class="content" translate=""><span>Покинутая долина</span></span>
									</div>
									<div class="contentBoxBody">
										<span ng-if="relocationCellData.resType" ng-repeat="res in resNames" class="resourceType"><i class="unit_wood_small_illu"></i>4</span>
										<span ng-if="relocationCellData.resType" ng-repeat="res in resNames" class="resourceType"><i class="unit_clay_small_illu"></i>4</span>
										<span ng-if="relocationCellData.resType" ng-repeat="res in resNames" class="resourceType"><i class="unit_iron_small_illu"></i>4</span>
										<span ng-if="relocationCellData.resType" ng-repeat="res in resNames" class="resourceType"><i class="unit_crop_small_illu"></i>6</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div ng-if="::invitation.isRelocationInvitation()">
						<div class="expandableContent" expandable-content="" ng-if="!confirmationStep || incomingAttack" header-text="Embassy.Communities.Popup.Kingdom.Invitations.Relocation.Info.Expand">
							<div class="expandButton clickable" @click="gameModel.buildings.embassy.kingdomsBefore.kingdomInvitation.expanded = !gameModel.buildings.embassy.kingdomsBefore.kingdomInvitation.expanded" :class="{'opened': gameModel.buildings.embassy.kingdomsBefore.kingdomInvitation.expanded, 'closed': !gameModel.buildings.embassy.kingdomsBefore.kingdomInvitation.expanded}" clickable="expandMe()">
								<span ng-bind-html="myTitle">Раскрой список, чтобы посмотреть <strong>условия перемещения</strong></span>
							</div>
							<div class="collapser" :class="{'collapsed': !gameModel.buildings.buildings.embassy.kingdomsBefore.kingdomInvitation.expanded}">
								<div class="collapserContent" ng-transclude="">
									<ul>
										<div ng-if="!confirmationStep">
											<li>
												<span data="kingdomId:3,kingdomName:Roman" translate="">
													<span>После перемещения ты станешь губернатором королевства </span>
													<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="truncated kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3" nolink="Roman" showmissinglink="true">Roman</a>
												</span>
											</li>
											<li>
												<span data="cellId:537182205" translate="">
													<span>Деревня будет перемещена по следующим координатам: </span>
													<span class="coordinateWrapper" x="id" y="537182205">
														<a ng-if="hasLink" clickable="$root.openPage('map', 'centerId', '537182205_1707909594.2');" class="clickable">
															<span class="coordinates coordinatesWrapper " ng-bind-html=" coord | bidiCoordinate:x:y ">
																<span class="coordinateX">(-3</span>
																<span class="coordinatePipe">|</span>
																<span class="coordinateY">9)</span>
															</span>
														</a>
													</span>
												</span>
											</li>
											<li><span translate=""><span>Все здания в деревне будут сохранены</span></span></li>
											<li><span translate=""><span>Все ресурсные поля будут потеряны и тебе придется их восстановить</span></span></li>
											<li><span translate=""><span>В инвентарь героя будут добавлены ресурсы, примерно соответствующие ценности ресурсных полей</span></span></li>
											<li><span translate=""><span>Убедись, что у тебя достаточно зерна, чтобы войска не голодали</span></span></li>
											<li><span data="minCityPopulation:1000" translate=""><span>Если ты перемещаешь город, то убедись, что население города не упадет ниже 1000</span></span></li>
											<li><span translate=""><span>Все подкрепления будут отправлены домой</span></span></li>
											<li><span translate=""><span>Все твои войска, находящиеся вне деревни, вернутся в новую деревню</span></span></li>
											<li><span translate=""><span>После перемещения ты не сможешь отправлять атаки в течение 12:00 ч.</span></span></li>
											<li><span translate=""><span>Текущие логова разбойников исчезнут</span></span></li>
										</div>
									</ul>
								</div>
							</div>
						</div>
						<div ng-show="any" class="dialogWarning ng-hide" keys="Embassy.Communities.Popup.Kingdom.Invitations.Relocation.Warning.Troops:false, Embassy.Communities.Popup.Kingdom.Invitations.Relocation.Warning.TroopSupply:false:limit:2000:current:5">
							<div class="warningHeader">
								<i class="symbol_warning_tiny_flat_white"></i>
								<span translate=""><span>Предупреждение:</span></span>
							</div>
							<div class="warningContent" ng-class="{single:warnings.length == 1}">
								<ul></ul>
							</div>
						</div>
					</div>
					<div class="buttonFooter">
						<div class="error"></div>
						<button ng-if="((!player.isKing() && (invitation.data.invitedAs==Player.KINGDOM_ROLE_GOVERNOR || invitation.data.invitedAs==Player.KINGDOM_ROLE_DUKE)) || (player.isKing() && (invitation.data.invitedAs==Player.KINGDOM_ROLE_KING || invitation.data.invitedAs==Player.KINGDOM_ROLE_VICEKING))) && !invitation.data.reservationTime" ng-class="{disabled: pendingRelocation || disallowedTroopMovements || totalTroopSupply > config.balancing.KingdomConfig.relocationTroopSupplyLimit || !player.finishedTutorial()}" clickable="acceptInvitation();" tooltip="" tooltip-placement="above" class="clickable">
							<div class="content">
								<span ng-transclude="" class="innerText">
									<span ng-if="!confirmationStep" translate="">
										<span>Принять</span>
									</span>
								</span>
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
						<button ng-if="!confirmationStep && !invitation.data.reservationTime" class="cancel clickable" clickable="declineInvitation()">
							<div class="content">
								<span ng-transclude="" class="innerText">
									<span translate="">
										<span>Отказаться</span>
									</span>
								</span>
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
<template>
	<div class="top10Ranking">
		<div ng-controller="statsPlayerTop10Ctrl">
			<div class="statisticsTable contentBox transparent top10quarter" ng-repeat="top10 in top10Lists">
				<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
					<div class="content">Атакующий недели</div>
				</h6>
				<table class="gradient showOwnRank inRank" ng-class="{'inRank': top10.ownData[0].rank < 10}">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></th>
							<th ng-if="top10.pointsTitle == 'offPoints'" content="Очки атаки" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.AttackerPoints"><i class="movement_attack_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'defPoints' -->
							<!-- ngIf: top10.pointsTitle == 'ranks' -->
							<!-- ngIf: top10.pointsTitle == 'resources' -->
						</tr>
					</thead>
					<tbody class="ranks">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="637" player-name="Неясыть" rank="0" points="5562" kingdom-id="3">
							<td class="rankColumn rank1" ng-if="rank >= 0">1</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="637" player-name="Неясыть" chat-user="chatUser" kingdom-id="3" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="637" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="637" playername="Неясыть" nolink="false">Неясыть</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="637" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">5&nbsp;562</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="106" player-name="Брего" rank="1" points="3124" kingdom-id="2">
							<td class="rankColumn rank2" ng-if="rank >= 0">2</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="106" player-name="Брего" chat-user="chatUser" kingdom-id="2" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="106" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="106" playername="Брего" nolink="false">Брего</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="106" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">3&nbsp;124</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="294" player-name="любопытный гусь" rank="2" points="2717" kingdom-id="6">
							<td class="rankColumn rank3" ng-if="rank >= 0">3</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="294" player-name="любопытный гусь" chat-user="chatUser" kingdom-id="6" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="294" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="294" playername="любопытный гусь" nolink="false">любопытный гусь</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" ng-if="kingdomId > 0" kingdomid="6" kingdomname="Utopia" nolink="true">Utopia</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="294" size="tiny">
										<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">2&nbsp;717</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="155" player-name="Nol1k" rank="3" points="2335" kingdom-id="6">
							<td class="rankColumn rank4" ng-if="rank >= 0">4</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="155" player-name="Nol1k" chat-user="chatUser" kingdom-id="6" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="155" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="155" playername="Nol1k" nolink="false">Nol1k</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" ng-if="kingdomId > 0" kingdomid="6" kingdomname="Utopia" nolink="true">Utopia</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="155" size="tiny">
									<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">2&nbsp;335</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="705" player-name="ЭЛВИС" rank="4" points="2249" kingdom-id="6">
							<td class="rankColumn rank5" ng-if="rank >= 0">5</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="705" player-name="ЭЛВИС" chat-user="chatUser" kingdom-id="6" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="705" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="705" playername="ЭЛВИС" nolink="false">ЭЛВИС</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" ng-if="kingdomId > 0" kingdomid="6" kingdomname="Utopia" nolink="true">Utopia</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="705" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">2&nbsp;249</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="371" player-name="derg" rank="5" points="2119" kingdom-id="2">
							<td class="rankColumn rank6" ng-if="rank >= 0">6</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="371" player-name="derg" chat-user="chatUser" kingdom-id="2" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="371" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="371" playername="derg" nolink="false">derg</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="371" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">2&nbsp;119</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="610" player-name="ппц" rank="6" points="1936" kingdom-id="3">
							<td class="rankColumn rank7" ng-if="rank >= 0">7</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="610" player-name="ппц" chat-user="chatUser" kingdom-id="3" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="610" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="610" playername="ппц" nolink="false">ппц</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="610" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">1&nbsp;936</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="268" player-name="kaa" rank="7" points="1807" kingdom-id="2">
							<td class="rankColumn rank8" ng-if="rank >= 0">8</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="268" player-name="kaa" chat-user="chatUser" kingdom-id="2" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="268" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="268" playername="kaa" nolink="false">kaa</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="268" size="tiny">
									<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">1&nbsp;807</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="284" player-name="Ixus3k" rank="8" points="1791" kingdom-id="2">
							<td class="rankColumn rank9" ng-if="rank >= 0">9</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="284" player-name="Ixus3k" chat-user="chatUser" kingdom-id="2" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="284" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="284" playername="Ixus3k" nolink="false">Ixus3k</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="284" size="tiny">
									<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">1&nbsp;791</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="514" player-name="Klop" rank="9" points="1788" kingdom-id="2">
							<td class="rankColumn rank10" ng-if="rank >= 0">10</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="514" player-name="Klop" chat-user="chatUser" kingdom-id="2" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="514" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="514" playername="Klop" nolink="false">Klop</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="514" size="tiny">
									<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">1&nbsp;788</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
					<tbody class="ownRank" ng-if="top10.ownData[0].rank > 9 || top10.ownData[0].rank == '?'">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" player-rank="" player-id="734" player-name="lexinzector" rank="230" points="66" kingdom-id="3">
		<!-- ngIf: rank >= 0 --><td class="rankColumn rank231" ng-if="rank >= 0">231</td><!-- end ngIf: rank >= 0 -->
		<!-- ngIf: !rank && rank != 0 -->
		<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined --><td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="734" player-name="lexinzector" chat-user="chatUser" kingdom-id="3" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="734" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
		<!-- ngIf: playerId == robberId -->
		<!-- ngIf: playerId == npcGovernorId -->
	<canvas width="62" height="62"></canvas></div>
	</avatar-image><!-- end ngIf: ::!accountId -->
	<!-- ngIf: ::accountId -->
	<!-- ngIf: chatUser -->
	<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" playername="lexinzector" nolink="false">lexinzector</a>

	<!-- ngIf: kingdomId >= 0 --><div ng-if="kingdomId >= 0" class="kingdomTag">
		<span ng-if="kingdomId == 0">-</span>
	<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="MИР" nolink="true">MИР</a>
	</div><!-- end ngIf: kingdomId >= 0 -->
	<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
		<div class="innerStars" playerid="734" size="tiny">
		<!-- ngIf: !badge --><div ng-if="!badge">
			<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
			<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
			<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
		</div><!-- end ngIf: !badge -->
		<!-- ngIf: badge -->
	</div>
	</div><!-- end ngIf: !hidePrestige --></td><!-- end ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
		<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
		<!-- ngIf: playerId >= 100 --><td class="number pointsColumn" ng-if="playerId >= 100">66</td><!-- end ngIf: playerId >= 100 -->

		<!-- ngIf: Player.isNPC(playerId) -->
	</tr>
					</tbody>
				</table>
			</div>
			<div class="statisticsTable contentBox transparent top10quarter" ng-repeat="top10 in top10Lists">
				<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
					<div class="content">Взлет недели</div>
				</h6>
				<table class="gradient showOwnRank inRank" ng-class="{'inRank': top10.ownData[0].rank < 10}">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'offPoints' -->
							<!-- ngIf: top10.pointsTitle == 'defPoints' -->
							<th ng-if="top10.pointsTitle == 'ranks'" content="Повышение в рейтинге" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Top10.RanksImproved"><i class="symbol_rankUp_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'resources' -->
						</tr>
					</thead>
					<tbody class="ranks">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1911" player-name="Stavred" rank="0" points="152" kingdom-id="57">
							<td class="rankColumn rank1" ng-if="rank >= 0">1</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1911" player-name="Stavred" chat-user="chatUser" kingdom-id="57" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1911" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1911" playername="Stavred" nolink="false">Stavred</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 57}, null, true)" ng-if="kingdomId > 0" kingdomid="57" kingdomname="♔Выдры*♔" nolink="true">♔Выдры*♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1911" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">152</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1915" player-name="Mitiay" rank="1" points="99" kingdom-id="0">
							<td class="rankColumn rank2" ng-if="rank >= 0">2</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1915" player-name="Mitiay" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1915" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1915" playername="Mitiay" nolink="false">Mitiay</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1915" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">99</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1458" player-name="mustdie" rank="2" points="76" kingdom-id="2">
							<td class="rankColumn rank3" ng-if="rank >= 0">3</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1458" player-name="mustdie" chat-user="chatUser" kingdom-id="2" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1458" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1458" playername="mustdie" nolink="false">mustdie</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1458" size="tiny">
									<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">76</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1806" player-name="NeCesar" rank="3" points="73" kingdom-id="0">
							<td class="rankColumn rank4" ng-if="rank >= 0">4</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1806" player-name="NeCesar" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1806" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1806" playername="NeCesar" nolink="false">NeCesar</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1806" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">73</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1241" player-name="Star797" rank="4" points="71" kingdom-id="8">
							<td class="rankColumn rank5" ng-if="rank >= 0">5</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1241" player-name="Star797" chat-user="chatUser" kingdom-id="8" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1241" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1241" playername="Star797" nolink="false">Star797</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" ng-if="kingdomId > 0" kingdomid="8" kingdomname="фаршмелы" nolink="true">фаршмелы</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1241" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">71</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1354" player-name="Пенсионный Фонд" rank="5" points="70" kingdom-id="32">
							<td class="rankColumn rank6" ng-if="rank >= 0">6</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1354" player-name="Пенсионный Фонд" chat-user="chatUser" kingdom-id="32" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1354" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1354" playername="Пенсионный Фонд" nolink="false">Пенсионный Фонд</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 32}, null, true)" ng-if="kingdomId > 0" kingdomid="32" kingdomname="Квадрат" nolink="true">Квадрат</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1354" size="tiny">
									<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">70</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="563" player-name="Пума" rank="6" points="68" kingdom-id="8">
							<td class="rankColumn rank7" ng-if="rank >= 0">7</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="563" player-name="Пума" chat-user="chatUser" kingdom-id="8" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="563" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="563" playername="Пума" nolink="false">Пума</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" ng-if="kingdomId > 0" kingdomid="8" kingdomname="фаршмелы" nolink="true">фаршмелы</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="563" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">68</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="207" player-name="Берендей" rank="7" points="64" kingdom-id="8">
							<td class="rankColumn rank8" ng-if="rank >= 0">8</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="207" player-name="Берендей" chat-user="chatUser" kingdom-id="8" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="207" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="207" playername="Берендей" nolink="false">Берендей</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" ng-if="kingdomId > 0" kingdomid="8" kingdomname="фаршмелы" nolink="true">фаршмелы</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="207" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">64</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="851" player-name="MadCat" rank="8" points="62" kingdom-id="2">
							<td class="rankColumn rank9" ng-if="rank >= 0">9</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="851" player-name="MadCat" chat-user="chatUser" kingdom-id="2" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="851" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="851" playername="MadCat" nolink="false">MadCat</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="851" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">62</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1804" player-name="lexinzector" rank="9" points="52" kingdom-id="0" class="highlighted">
							<td class="rankColumn rank10" ng-if="rank >= 0">10</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1804" player-name="lexinzector" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1804" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">52</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
					<tbody class="ownRank" ng-if="top10.ownData[0].rank > 9 || top10.ownData[0].rank == '?'">
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" player-rank="" player-id="734" player-name="lexinzector" rank="221" points="-5" kingdom-id="3">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank222" ng-if="rank >= 0">222</td><!-- end ngIf: rank >= 0 -->
	<!-- ngIf: !rank && rank != 0 -->
	<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined --><td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="734" player-name="lexinzector" chat-user="chatUser" kingdom-id="3" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="734" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" playername="lexinzector" nolink="false">lexinzector</a>

<!-- ngIf: kingdomId >= 0 --><div ng-if="kingdomId >= 0" class="kingdomTag">
	<span ng-if="kingdomId == 0">-</span>
	<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="MИР" nolink="true">MИР</a>
</div><!-- end ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="734" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td><!-- end ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
	<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
	<!-- ngIf: playerId >= 100 --><td class="number pointsColumn" ng-if="playerId >= 100">-5</td><!-- end ngIf: playerId >= 100 -->

	<!-- ngIf: Player.isNPC(playerId) -->
</tr>
				</tbody>
				</table>
			</div>
			<div class="statisticsTable contentBox transparent top10quarter" ng-repeat="top10 in top10Lists">
				<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
					<div class="content">Обороняющийся недели</div>
				</h6>
				<table class="gradient showOwnRank inRank" ng-class="{'inRank': top10.ownData[0].rank < 10}">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'offPoints' -->
							<th ng-if="top10.pointsTitle == 'defPoints'" content="Очки защиты" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.DefenderPoints"><i class="movement_defend_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'ranks' -->
							<!-- ngIf: top10.pointsTitle == 'resources' -->
						</tr>
					</thead>
					<tbody class="ranks">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="227" player-name="Злой Дядько" rank="0" points="797" kingdom-id="8">
							<td class="rankColumn rank1" ng-if="rank >= 0">1</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="227" player-name="Злой Дядько" chat-user="chatUser" kingdom-id="8" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="227" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="227" playername="Злой Дядько" nolink="false">Злой Дядько</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" ng-if="kingdomId > 0" kingdomid="8" kingdomname="фаршмелы" nolink="true">фаршмелы</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="227" size="tiny">
									<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">797</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="314" player-name="Mild" rank="1" points="629" kingdom-id="3">
							<td class="rankColumn rank2" ng-if="rank >= 0">2</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="314" player-name="Mild" chat-user="chatUser" kingdom-id="3" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="314" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="314" playername="Mild" nolink="false">Mild</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="314" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">629</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="984" player-name="Kas" rank="2" points="592" kingdom-id="0">
							<td class="rankColumn rank3" ng-if="rank >= 0">3</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="984" player-name="Kas" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="984" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="984" playername="Kas" nolink="false">Kas</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="984" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">592</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1890" player-name="Marabut" rank="3" points="406" kingdom-id="3">
							<td class="rankColumn rank4" ng-if="rank >= 0">4</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1890" player-name="Marabut" chat-user="chatUser" kingdom-id="3" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1890" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1890" playername="Marabut" nolink="false">Marabut</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1890" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">406</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="176" player-name="Twism" rank="4" points="357" kingdom-id="8">
							<td class="rankColumn rank5" ng-if="rank >= 0">5</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="176" player-name="Twism" chat-user="chatUser" kingdom-id="8" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="176" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="176" playername="Twism" nolink="false">Twism</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" ng-if="kingdomId > 0" kingdomid="8" kingdomname="фаршмелы" nolink="true">фаршмелы</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="176" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">357</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="121" player-name="Мистер" rank="5" points="327" kingdom-id="0">
							<td class="rankColumn rank6" ng-if="rank >= 0">6</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="121" player-name="Мистер" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="121" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="121" playername="Мистер" nolink="false">Мистер</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="121" size="tiny">
									<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_bronzeBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">327</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1843" player-name="TpouKa" rank="6" points="319" kingdom-id="0">
							<td class="rankColumn rank7" ng-if="rank >= 0">7</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1843" player-name="TpouKa" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1843" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1843" playername="TpouKa" nolink="false">TpouKa</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1843" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">319</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1399" player-name="PsychozeF20" rank="7" points="315" kingdom-id="20">
							<td class="rankColumn rank8" ng-if="rank >= 0">8</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1399" player-name="PsychozeF20" chat-user="chatUser" kingdom-id="20" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1399" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1399" playername="PsychozeF20" nolink="false">PsychozeF20</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" ng-if="kingdomId > 0" kingdomid="20" kingdomname="☠Outcast" nolink="true">☠Outcast</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1399" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">315</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1209" player-name="Сугутор" rank="8" points="275" kingdom-id="0">
							<td class="rankColumn rank9" ng-if="rank >= 0">9</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1209" player-name="Сугутор" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1209" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1209" playername="Сугутор" nolink="false">Сугутор</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1209" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">275</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1235" player-name="jabodan303" rank="9" points="240" kingdom-id="0">
							<td class="rankColumn rank10" ng-if="rank >= 0">10</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1235" player-name="jabodan303" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1235" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1235" playername="jabodan303" nolink="false">jabodan303</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<!-- ngIf: kingdomId > 0 -->
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1235" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starEmpty_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">240</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
					<tbody class="ownRank" ng-if="top10.ownData[0].rank > 9 || top10.ownData[0].rank == '?'">
					<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" player-rank="" player-id="734" player-name="lexinzector" rank="132" points="69" kingdom-id="3">
	<!-- ngIf: rank >= 0 --><td class="rankColumn rank133" ng-if="rank >= 0">133</td><!-- end ngIf: rank >= 0 -->
	<!-- ngIf: !rank && rank != 0 -->
	<!-- ngIf: !Player.isNPC(playerId) && kingdomId !== undefined --><td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="734" player-name="lexinzector" chat-user="chatUser" kingdom-id="3" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn"><!-- ngIf: ::!accountId --><avatar-image ng-if="::!accountId" class="avatarImage" player-id="734" scale="0.5" avatar-class="profileImage"><!-- rerender: rerender --><div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
	<!-- ngIf: playerId == robberId -->
	<!-- ngIf: playerId == npcGovernorId -->
<canvas width="62" height="62"></canvas></div>
</avatar-image><!-- end ngIf: ::!accountId -->
<!-- ngIf: ::accountId -->
<!-- ngIf: chatUser -->
<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" playername="lexinzector" nolink="false">lexinzector</a>

<!-- ngIf: kingdomId >= 0 --><div ng-if="kingdomId >= 0" class="kingdomTag">
	<span ng-if="kingdomId == 0">-</span>
	<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="MИР" nolink="true">MИР</a>
</div><!-- end ngIf: kingdomId >= 0 -->
<!-- ngIf: !hidePrestige --><div class="prestigeStars" ng-if="!hidePrestige">
	<div class="innerStars" playerid="734" size="tiny">
	<!-- ngIf: !badge --><div ng-if="!badge">
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
		<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
	</div><!-- end ngIf: !badge -->
	<!-- ngIf: badge -->
</div>
</div><!-- end ngIf: !hidePrestige --></td><!-- end ngIf: !Player.isNPC(playerId) && kingdomId !== undefined -->
	<!-- ngIf: !Player.isNPC(playerId) && kingdomId === undefined -->
	<!-- ngIf: playerId >= 100 --><td class="number pointsColumn" ng-if="playerId >= 100">69</td><!-- end ngIf: playerId >= 100 -->

	<!-- ngIf: Player.isNPC(playerId) -->
</tr>
				</tbody>
				</table>
			</div>
			<div class="statisticsTable contentBox transparent top10quarter" ng-repeat="top10 in top10Lists">
				<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
					<div class="content">Грабитель недели</div>
				</h6>
				<table class="gradient showOwnRank" ng-class="{'inRank': top10.ownData[0].rank < 10}">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Игрок" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Player"><i class="community_member_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'offPoints' -->
							<!-- ngIf: top10.pointsTitle == 'defPoints' -->
							<!-- ngIf: top10.pointsTitle == 'ranks' -->
							<th ng-if="top10.pointsTitle == 'resources'" content="Награблено ресурсов" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Top10.ResourcesRaided"><i class="symbol_resources_small_flat_black"></i></th>
						</tr>
					</thead>
					<tbody class="ranks">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="637" player-name="Неясыть" rank="0" points="1096377" kingdom-id="3">
							<td class="rankColumn rank1" ng-if="rank >= 0">1</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="637" player-name="Неясыть" chat-user="chatUser" kingdom-id="3" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="637" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="637" playername="Неясыть" nolink="false">Неясыть</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="637" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">1&nbsp;096&nbsp;377</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1403" player-name="Минус" rank="1" points="500072" kingdom-id="57">
							<td class="rankColumn rank2" ng-if="rank >= 0">2</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1403" player-name="Минус" chat-user="chatUser" kingdom-id="57" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1403" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1403" playername="Минус" nolink="false">Минус</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 57}, null, true)" ng-if="kingdomId > 0" kingdomid="57" kingdomname="♔Выдры*♔" nolink="true">♔Выдры*♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1403" size="tiny">
										<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_silverBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">500&nbsp;072</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="441" player-name="Neron" rank="2" points="443526" kingdom-id="6">
							<td class="rankColumn rank3" ng-if="rank >= 0">3</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="441" player-name="Neron" chat-user="chatUser" kingdom-id="6" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="441" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="441" playername="Neron" nolink="false">Neron</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" ng-if="kingdomId > 0" kingdomid="6" kingdomname="Utopia" nolink="true">Utopia</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="441" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">443&nbsp;526</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="106" player-name="Брего" rank="3" points="341109" kingdom-id="2">
							<td class="rankColumn rank4" ng-if="rank >= 0">4</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="106" player-name="Брего" chat-user="chatUser" kingdom-id="2" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="106" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="106" playername="Брего" nolink="false">Брего</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="106" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">341&nbsp;109</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="472" player-name="Roxa" rank="4" points="295901" kingdom-id="6">
							<td class="rankColumn rank5" ng-if="rank >= 0">5</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="472" player-name="Roxa" chat-user="chatUser" kingdom-id="6" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="472" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="472" playername="Roxa" nolink="false">Roxa</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" ng-if="kingdomId > 0" kingdomid="6" kingdomname="Utopia" nolink="true">Utopia</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="472" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">295&nbsp;901</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="514" player-name="Klop" rank="5" points="284004" kingdom-id="2">
							<td class="rankColumn rank6" ng-if="rank >= 0">6</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="514" player-name="Klop" chat-user="chatUser" kingdom-id="2" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="514" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="514" playername="Klop" nolink="false">Klop</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" ng-if="kingdomId > 0" kingdomid="2" kingdomname="♔Выдры♔" nolink="true">♔Выдры♔</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="514" size="tiny">
										<!-- ngIf: !badge -->
										<i ng-if="badge" class="prestigeBadge prestige_goldBadge_tiny_illu"></i>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">284&nbsp;004</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1232" player-name="alexandr" rank="6" points="251752" kingdom-id="8">
							<td class="rankColumn rank7" ng-if="rank >= 0">7</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1232" player-name="alexandr" chat-user="chatUser" kingdom-id="8" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1232" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1232" playername="alexandr" nolink="false">alexandr</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" ng-if="kingdomId > 0" kingdomid="8" kingdomname="фаршмелы" nolink="true">фаршмелы</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1232" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">251&nbsp;752</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="610" player-name="ппц" rank="7" points="241314" kingdom-id="3">
							<td class="rankColumn rank8" ng-if="rank >= 0">8</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="610" player-name="ппц" chat-user="chatUser" kingdom-id="3" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="610" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage female" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="610" playername="ппц" nolink="false">ппц</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="Roman" nolink="true">Roman</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="610" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
											<i class="prestigeStar prestige_starGold_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">241&nbsp;314</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="1642" player-name="stakan" rank="8" points="199532" kingdom-id="8">
							<td class="rankColumn rank9" ng-if="rank >= 0">9</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1642" player-name="stakan" chat-user="chatUser" kingdom-id="8" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1642" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1642" playername="stakan" nolink="false">stakan</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" ng-if="kingdomId > 0" kingdomid="8" kingdomname="фаршмелы" nolink="true">фаршмелы</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1642" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">199&nbsp;532</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" ng-repeat="rank in top10.data | limitTo:10" ng-class="{'highlighted': rank.playerId == player.data.playerId}" player-rank="" player-id="149" player-name="SeregaDD" rank="9" points="198844" kingdom-id="8">
							<td class="rankColumn rank10" ng-if="rank >= 0">10</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="149" player-name="SeregaDD" chat-user="chatUser" kingdom-id="8" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="149" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="149" playername="SeregaDD" nolink="false">SeregaDD</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<!-- ngIf: kingdomId == 0 -->
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" ng-if="kingdomId > 0" kingdomid="8" kingdomname="фаршмелы" nolink="true">фаршмелы</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="149" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">198&nbsp;844</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
					<tbody class="ownRank" ng-if="top10.ownData[0].rank > 9 || top10.ownData[0].rank == '?'">
						<tr on-pointer-over="hoverIn(rank)" on-pointer-out="hoverOut()" player-rank="" player-id="1804" player-name="lexinzector" rank="245" points="1418" kingdom-id="0">
							<td class="rankColumn rank246" ng-if="rank >= 0">246</td>
							<!-- ngIf: !rank && rank != 0 -->
							<td ng-if="!Player.isNPC(playerId) &amp;&amp; kingdomId !== undefined" player-column="" player-id="1804" player-name="lexinzector" chat-user="chatUser" kingdom-id="0" kingdom-name="" account-id="" hide-prestige="hidePrestige" class="playerColumn">
								<avatar-image ng-if="::!accountId" class="avatarImage" player-id="1804" scale="0.5" avatar-class="profileImage">
									<div class="heroImage  profileImage male" ng-class="::class" rerender="rerender">
										<!-- ngIf: playerId == robberId -->
										<!-- ngIf: playerId == npcGovernorId -->
										<canvas width="62" height="62"></canvas>
									</div>
								</avatar-image>
								<!-- ngIf: ::accountId -->
								<!-- ngIf: chatUser -->
								<a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="1804" playername="lexinzector" nolink="false">lexinzector</a>

								<div ng-if="kingdomId >= 0" class="kingdomTag">
									<span ng-if="kingdomId == 0">-</span>
									<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable disabled" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" ng-if="kingdomId > 0" kingdomid="3" kingdomname="MИР" nolink="true">MИР</a>
								</div>
								<div class="prestigeStars" ng-if="!hidePrestige">
									<div class="innerStars" playerid="1804" size="tiny">
										<div ng-if="!badge">
											<i class="prestigeStar prestige_starSilver_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
											<i class="prestigeStar prestige_starBronze_tiny_illu"></i>
										</div>
									</div>
								</div>
							</td>
							
							<td class="number pointsColumn" ng-if="playerId >= 100">1&nbsp;418</td>

							<!-- ngIf: Player.isNPC(playerId) -->
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
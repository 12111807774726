<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Кузница</span></span>
									<span class="level">
										(<span><span>Уровень {{gameModel.island.buildings.forge.level}}</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>В кузнице можно улучшить характеристики доспехов и оружия воинов. Чем выше уровень кузницы, тем лучше качество вооружения твоих войск.</span>
								</div>
								<img class="buildingHuge buildingType15 tribeId3 level5" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_15">
								<div class="buildingStatus location27 optionMenu">
									<div class="buildingBubble clickable disabledHover">
										<div class="colorLayer notNow enoughRes">
											<div class="labelLayer">
												<span class="buildingLevel">5</span>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
													<div ng-controller="buildingIndexCtrl" class="building buildingType13" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
														<div ng-include="" src="pageTemplate">
															<div class="buildingDetails blacksmith" ng-controller="blacksmithCtrl">
																<div ng-show="isBuildingBuild()">
																	<div ng-controller="unitsResearchCtrl">
																		<div ng-include="" src="'tpl/building/partials/lists/units.html'">
																			<div class="contentBox gradient unitList">
																				<div class="contentBoxBody">
																					<carousel-unit-upgrade  :items="units" @on-active-item="(item) => activeItem = item" :cols="4" />
																					<div v-if="activeItem">
																						<table class="unitInfo transparent">
																							<tbody>
																								<tr>
																									<td>
																										<div class="unitHeader">
																											<i class="unitIcon unitMedium gaul" :class="['unitType'+activeItem.type]" unit-icon="" big="true"></i>
																											<h4 class="unitName">
																												<span><span>{{activeItem.name}}</span></span>
																												<span class="level" v-if="activeItem.unitLevel >= 0">
																													<span><span>&nbsp;Ур.</span></span> {{activeItem.unitLevel}}
																													<span class="levelText">/{{activeItem.maxAvailableLevel}}</span>
																												</span>
																											</h4>
																											<div class="symbol_i_medium_wrapper">
																												<i class="symbol_i_medium_flat_white clickable" clickable="openWindow('help', {'pageId': 'Troops_' + activeItem.unitType})" content="Дополнительная информация" v-tippy="{placement: 'top'}" tooltip-translate="Units.Tooltip.UnitInfo" tooltip-placement="above"></i>
																											</div>
																										</div>
																										<div class="description"><span>{{activeItem.description}}</span></div>
																									</td>
																									<td>
																										<table class="unitAttributes transparent">
																											<tbody>
																												<tr>
																													<td>
																														<i class="unit_capacity_small_flat_black" content="Могут переносить" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CarryingCapacity.Label"></i>
																													</td>
																													<td>{{activeItem.capacity}}</td>
																												</tr>
																												<tr>
																													<td><i class="unit_speed_small_flat_black" content="Скорость" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.Speed.Label"></i></td>
																													<td>{{activeItem.speed}}</td>
																												</tr>
																												<tr>
																													<td>
																														<i class="unit_consumption_small_flat_black" content="Потребление" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CropConsumption.Label"></i>
																													</td>
																													<td>{{activeItem.cropConsumption}}</td>
																												</tr>
																											</tbody>
																										</table>
																									</td>
																									<td>
																										<table class="unitAttributes transparent">
																											<tbody>
																												<tr content="Атака" v-tippy="{placement: 'bottom'}" tooltip-placement="above" tooltip-url="tpl/building/partials/lists/items/unitUpgradeTooltip.html" tooltip-data="type:attack">
																													<td><i class="movement_attack_small_flat_black"></i></td>
																													<td>{{activeItem.attack}}</td>
																												</tr>
																												<tr content="Защита от пехоты" v-tippy="{placement: 'bottom'}" tooltip-placement="above" tooltip-url="tpl/building/partials/lists/items/unitUpgradeTooltip.html" tooltip-data="type:defence">
																													<td><i class="unit_defenseInfantry_small_flat_black"></i></td>
																													<td>{{activeItem.defenceInfantry}}</td>
																												</tr>
																												<tr content="Защита от кавалерии" v-tippy="{placement: 'bottom'}" tooltip-placement="above" tooltip-url="tpl/building/partials/lists/items/unitUpgradeTooltip.html" tooltip-data="type:defenceCavalry">
																													<td><i class="unit_defenseCavalry_small_flat_black"></i></td>
																													<td>{{activeItem.defenceCavalry}}</td>
																												</tr>
																											</tbody>
																										</table>
																									</td>
																								</tr>
																							</tbody>
																						</table>
																						<div class="requiredBuildings" v-if="activeItem.required.length > 0">
																							<h4><span>Требования</span></h4>
																							<span class="buildingInfo" tooltip="" tooltip-translate="Tooltip.CurrentLevel" v-for="(i, building) in activeItem.required">
																								<a clickable="openWindow('help', {'pageId': 'Buildings_' + building.buildingType})" class="clickable">
																									<span>{{building.name}}</span>
																								</a>
																								<span><span>&nbsp;Уровень {{building.level}}</span></span>
																								<span v-if="!building.valid" class="neededLevels">(+4)</span>
																							</span>
																							<!-- ngRepeat: requirement in otherRequirements -->
																							<!-- ngIf: !buildings.length && !otherRequirements -->
																						</div>
																						<div class="horizontalLine" v-if="!activeItem.required || activeItem.required.length == 0"></div>
																						<display-resources v-if="activeItem" class="costsFooter" :resources="activeItem.costs" :hide-zero="true" available="storage" :consumption="0" :time="activeItem.duration" />
																					</div>
																				</div>
																			</div>
																		</div>
																		<!-- queue full -->
																		<button :class="{'disabled': upgradeQueueFull || !activeItem || !activeItem.canUpgrade || !gameModel.island.storage.hasEnoughResources(activeItem.costs)}" class="animate footerButton clickable" clickable="research(activeItem)" content="Недостаточно ресурсов / Кузница полностью загружена работой в данный момент" v-tippy="{placement: 'top'}">
																			<div class="content">
																				<span ng-transclude="" class="innerText">
																					<span><span>Улучшить</span></span>
																				</span>
																				<!-- ngIf: price > 0 || forceGoldUsage -->
																				<!-- ngIf: price == -1 && !forceGoldUsage -->
																				<div class="animationOverlay"></div>
																				<i class="checkmark action_check_small_flat_white"></i>
																				<i class="checkmark action_check_small_flat_black"></i>
																				<i class="checkmark action_check_small_flat_red"></i>
																			</div>
																		</button>
																		<npc-trader-button class="footerButton" type="unitUpgrade" :costs="activeItem.costs" v-if="activeItem" />
																		<div class="iconButton finishNow free disabled" premium-feature="finishNow" premium-callback-param="finishNowBuildingType:13" confirm-gold-usage="true" content="Нечего завершать" v-tippy="{placement: 'top'}" tooltip-url="tpl/npcTrader/finishNowTooltip.html">
																			<i class="feature_instantCompletion_small_flat_black"></i>
																		</div>
																		<div class="iconButton finishNow premium" premium-feature="finishNow" premium-callback-param="finishNowBuildingType:13" confirm-gold-usage="true" content="<div class='finishNowTooltip'>
																			<div>
																				<h3>
																					<span>Моментально завершить следующие поручения</span>
																					<span class='price'><i class='unit_gold_small_illu'></i>2:</span>
																				</h3>
																				<div class='horizontalLine'></div>
																				<div>
																					<span>Следопыт</span>
																					<span class='level'><span>Уровень 1</span></span>
																				</div>
																			</div>
																		</div>" v-tippy="{placement: 'top'}" tooltip-url="tpl/npcTrader/finishNowTooltip.html">
																			<i class="feature_instantCompletion_small_flat_black"></i>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import CarouselUnitUpgrade from '@/components/elements/CarouselUnitUpgrade';
import DisplayResources from '@/components/elements/DisplayResources';
import NpcTraderButton from '@/components/elements/NpcTraderButton';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Кузница | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
		
		units: [
			{
				type: 1,
				name: 'Фаланга',
				description: 'xxx',
				disabled: false,
				canUpgrade: true,
				queue: 1,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 36701,
				time: 1723643172,
				unitLevel: 3,
				maxAvailableLevel: 10,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 2,
				name: 'Мечник',
				description: 'xxx',
				disabled: false,
				canUpgrade: true,
				queue: 0,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 36587,
				time: 1723643172,
				unitLevel: 3,
				maxAvailableLevel: 10,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 3,
				name: 'Следопыт',
				description: 'Следопыты — разведывательные войска Галлов. Быстро, но осторожно, они выведывают секреты о ресурсах, войсках и защитных сооружениях врагов.',
				disabled: false,
				canUpgrade: true,
				queue: 0,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 34051,
				time: 1723643172,
				unitLevel: 3,
				maxAvailableLevel: 10,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 4,
				name: 'Тевтатский гром',
				description: 'xxx',
				disabled: false,
				canUpgrade: true,
				queue: 0,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 45021,
				time: 1723643172,
				unitLevel: 3,
				maxAvailableLevel: 10,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 5,
				name: 'Друид-всадник',
				description: 'xxx',
				disabled: false,
				canUpgrade: true,
				queue: 0,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 47505,
				time: 1723643172,
				unitLevel: 3,
				maxAvailableLevel: 10,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 6,
				name: 'Эдуйская конница',
				description: 'xxx',
				disabled: false,
				canUpgrade: true,
				queue: 0,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 48902,
				time: 1723643172,
				unitLevel: 3,
				maxAvailableLevel: 10,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 7,
				name: 'Таран',
				description: 'xxx',
				disabled: false,
				canUpgrade: false,
				queue: 0,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 63548,
				time: 1723643172,
				unitLevel: 3,
				maxAvailableLevel: 10,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 8,
				name: 'Требушет',
				description: 'xxx',
				disabled: false,
				canUpgrade: false,
				queue: 0,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 70635,
				time: 1723643172,
				unitLevel: 3,
				maxAvailableLevel: 10,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
			{
				type: 9,
				name: 'Предводитель',
				description: 'xxx',
				disabled: false,
				canUpgrade: false,
				queue: 0,
				costs: [
					940,
					540,
					360,
					0,
				],
				capacity: 0,
				speed: 17,
				cropConsumption: 2,
				attack: 0,
				defenseInfantry: 20,
				defenseCavalry: 10,
				consumption: 0,
				duration: 86400,
				time: 1723643172,
				unitLevel: 3,
				maxAvailableLevel: 10,
				required: [
					{
						name: 'Академия',
						level: 5,
					},
					{
						name: 'Конюшня',
						level: 1,
					},
				],
			},
		],
		
		activeItem: null,
		upgradeQueueFull: true,
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.sow = true;
	},
	components: {
		CarouselUnitUpgrade,
		DisplayResources,
		NpcTraderButton,
	},
}
</script>
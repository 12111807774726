<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper building" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal building">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable">
							<div>
								<h2 class="msAntiScroll drag" draggable="true">
									<span class="building"><span>Каменотес</span></span>
									<span class="level">
										(<span><span>Уровень 0</span></span>)
									</span>
								</h2>
								<div class="buildingDescription">
									<span>Каменотес является экспертом в обработке камней. Чем выше уровень каменотеса, тем прочнее все здания в деревне.</span>
								</div>
								<img class="buildingHuge buildingType34 tribeId3 level10" src="//iwstatic.g.bsrv.su/img/x.gif" />
							</div>
							<span class="buildingStatusButton type_34">
								<div class="buildingStatus location21 master optionMenu" ng-class="{disabled: upgradeButtonClass == 'notAtAll' || upgradeButtonClass == 'maxLevel', busy: busy, queued: finished < 0, waiting: waiting, master: finished > 0 && !busy, optionMenu: upgradeButtonClass == 'notNow' || premiumOptionMenu.locationId == building.data.locationId}" on-pointer-over="premiumOptionMenu.locationId != building.data.locationId && upgradeButtonClass == 'notNow' && !isRubble ? upgradeBuilding() : ''" :content="tooltips[1]" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/building/partials/upgradeCostsTooltip.html" tooltip-class="buildingTooltip" tooltip-placement="above" tooltip-offset="10" tooltip-hide="false" tooltip-hide-on-click="false">
									<div class="buildingBubble clickable disabledHover" ng-class="{disabled: upgradeButtonDisabled === true || premiumOptionMenu.locationId == building.data.locationId, disabledHover: upgradeButtonClass == 'notNow'}">
										<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', dismantle: isRubble, enoughRes: !isRubble && enoughRes && freeMasterSlots > 0}">
											<div class="labelLayer">
												<div class="labelLayer">
													<span ng-if="!isRubble" class="buildingLevel">20</span>
													<!-- ngIf: isRubble -->
													<i ng-if="upgradeButtonClass == 'maxLevel'" class="symbol_star_tiny_illu"></i>
												</div>
											</div>
										</div>
									</div>
									<div class="premiumOptionMenu upgradeMenu_background_layout" ng-if="premiumOptionMenu.locationId == building.data.locationId">
										<div class="buildingBubble clickable" clickable="upgradeBuilding()" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
											<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', enoughRes: enoughRes && freeMasterSlots > 0}">
												<!-- ngIf: !premiumOptionMenu.options.masterBuilder || canReserveResources -->
												<i class="feature_buildingMaster_medium_illu" ng-if="premiumOptionMenu.options.masterBuilder && !canReserveResources"></i>
												<i class="symbol_plus_tiny_flat_black" ng-if="upgradeButtonClass == 'notNow' && !canReserveResources"></i>
											</div>
										</div>
										<div class="premiumBubble instantCompletion premium optional" ng-class="{optional: !enoughRes || freeSlots}" premium-feature="finishNow" premium-callback-param="finishNowQueueType:1" confirm-gold-usage="true" :content="tooltips[2]" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/npcTrader/finishNowTooltip.html" on-pointer-over="$root.$broadcast('hideTooltip');">
											<i class="feature_instantCompletion_small_flat_black"></i>
										</div>
										<!-- ngIf: premiumOptionMenu.options.onlyQueue -->
										<!-- ngIf: !premiumOptionMenu.options.onlyQueue && vouchers.data.hasVouchers.NPCTrader == 1 -->
										
										
										<div ng-if="!premiumOptionMenu.options.onlyQueue && vouchers.data.hasVouchers.NPCTrader != 1" class="premiumBubble premium npcTrader disabled" ng-class="{disabled: !premiumOptionMenu.options.npcTrader || enoughRes, optional: !enoughAfterNPCTrade}" premium-feature="NPCTrader" confirm-gold-usage="true" on-pointer-over="premiumOptionMenu.options.manualTrader = (premiumOptionMenu.options.npcTrader && !enoughRes); $root.$broadcast('hideTooltip');" tooltip="Это приведет лишь к незначительному изменению" tooltip-translate="Building.npcTrader.Tooltip.Insignificant" tooltip-data="featurePrice:5, timePeriod:">
											<i class="feature_npcTrader_small_flat_black"></i>
										</div>
										
										<div ng-if="!premiumOptionMenu.options.onlyQueue && vouchers.data.hasVouchers.NPCTrader != 1" class="premiumBubble premium npcTrader" ng-class="{disabled: !premiumOptionMenu.options.npcTrader || enoughRes, optional: !enoughAfterNPCTrade}" premium-feature="NPCTrader" confirm-gold-usage="true" on-pointer-over="premiumOptionMenu.options.manualTrader = (premiumOptionMenu.options.npcTrader && !enoughRes); $root.$broadcast('hideTooltip');" :content="tooltips[3]" v-tippy="{placement: 'bottom'}" tooltip-translate="Building.npcTrader.Tooltip.Possible" tooltip-data="featurePrice:5, timePeriod:">
											<i class="feature_npcTrader_small_flat_black"></i>
										</div>
										
										
										<div class="premiumBubble premium small optional manualTrader clickable" ng-if="premiumOptionMenu.options.manualTrader" clickable="callNPCTrader()" :content="tooltips[4]" v-tippy="{placement: 'bottom'}" tooltip-translate="Building.npcTrader.Tooltip.Manual" on-pointer-over="$root.$broadcast('hideTooltip');">
											<i class="feature_npcTraderManual_tiny_flat_black"></i>
										</div>
									</div>
									<div class="progressbar clickable" progressbar="" ng-if="finished > 0" clickable="openBuilding()" finish-time="1723585786" show-countdown="true" duration="">
										<!-- ngIf: ::labelIcon -->
										<div class="progress">
											<!-- ngIf: steps -->
											<div class="bar positive perc " style="z-index: -1; width: 0%;"></div>
											<div class="bar additionalBar additionalPerc"></div>
											<div class="marker">
												<div class="markerInner"></div>
											</div>
											<div ng-if="::showCountdown" class="countdown" countdown="1723585786">06:57:06</div>
											<!-- ngIf: ::label -->
											<!-- ngIf: ::percTooltip -->
										</div>
									</div>
									<div class="progressbar clickable" progressbar="" ng-if="finished < 0" clickable="openBuilding()" finish-time="-1" label="1." duration="">
										<!-- ngIf: ::labelIcon -->
										<div class="progress">
											<!-- ngIf: steps -->
											<div class="bar positive perc " style="z-index: 2; width: 100%;"></div>
											<div class="bar additionalBar additionalPerc"></div>
											<div class="marker">
												<div class="markerInner"></div>
											</div>
											<!-- ngIf: ::showCountdown -->
											<div ng-if="::label" class="label" ng-bind-html="label">1.</div>
											<!-- ngIf: ::percTooltip -->
										</div>
									</div>
									<!-- ngIf: masterBuilderCount > 0 -->
								</div>
							</span>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div ng-transclude="">
				<div ng-controller="buildingIndexCtrl" class="building buildingType34" ng-class="{buildingResourceProduction: building.data.buildingType <= 4}">
	<!-- ngInclude:  --><div ng-include="" src="pageTemplate"><div class="buildingDetails">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/buildingInformation.html'"><!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/buildingInformationEffects.html'"><!-- ngIf: currentEffect.values[0] != undefined
	 		&& (building.data.lvl > 0
	 		|| building.data.buildingType <= 4
	 		|| building.data.buildingType == 42) --><div class="contentBox gradient buildingEffect" ng-if="currentEffect.values[0] != undefined
	 		&& (building.data.lvl > 0
	 		|| building.data.buildingType <= 4
	 		|| building.data.buildingType == 42)">
	<h6 class="contentBoxHeader headerColored">
		<span translate="" options="34" data="lvl: , value: "><span>Прочность</span></span>
	</h6>
	<div class="contentBoxBody">
		<div class="current">
			<h6 class="headerTrapezoidal">
				<div class="content" translate=""><span>Сейчас</span></div>
			</h6>
			<div class="currentLevel" data="lvl: 10" translate=""><span>Уровень 10</span></div>
			<div class="value">
				200
			</div>
			<div class="unit" options="34" translate=""><span>%</span></div>
		</div>
		<div class="nextLvl">
			<h6 class="headerTrapezoidal">
				<div class="content" translate=""><span>Следующие уровни</span></div>
			</h6>
			<!-- ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>11</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="34" data="value:210"><span>210 %</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>12</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="34" data="value:220"><span>220 %</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>13</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="34" data="value:230"><span>230 %</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>14</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="34" data="value:240"><span>240 %</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>15</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="34" data="value:250"><span>250 %</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects --><table ng-repeat="effect in nextEffects" ng-class="{}" class="transparent">
				<tbody>
					<tr ng-class="{upgrading: building.data.lvlNext > getFutureLevel($index)}">
						<th>16</th>
						<!-- ngIf: building.data.lvlNext > getFutureLevel($index) -->
						<td>
							<span translate="" options="34" data="value:260"><span>260 %</span></span>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngRepeat: effect in nextEffects -->
			<!-- ngIf: building.data.isMaxLvl && building.data.lvl == building.data.lvlMax -->
		</div>
	</div>
</div><!-- end ngIf: currentEffect.values[0] != undefined
	 		&& (building.data.lvl > 0
	 		|| building.data.buildingType <= 4
	 		|| building.data.buildingType == 42) --></div></div>
	<br>
	<!-- ngIf: building.data.lvl == 0 && building.data.lvlNext > 1 -->
</div></div>
</div>
			</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Каменотес | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
		
		tooltips: {
			1: `<div class="upgradeCostsTooltip" ng-controller="buildingUpgradeTooltipCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoTop"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
	<div class="headline">
		<h3 translate="" class="building" options="34"><span>Каменотес</span></h3>
	</div>

	<div class="queuedSlidesContainer">
		<!-- ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide1 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide2 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide3 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide4 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide5 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 -->
	</div>

	<div class="content" ng-class="{boxMaxLevel: building.data.lvl >= building.data.lvlMax, boxDeconstruction: upgradeButtonClass == 'demolish'}">
		<div class="contentTopArea">
			<!-- ngIf: building.data.currentEffect.values[0] --><div class="effectDescription" ng-if="building.data.currentEffect.values[0]">
				<span translate="" options="34"><span>Повышает прочность</span></span>
				<!-- ngIf: building.data.currentEffect.values[0] --><div class="horizontalLine3d" ng-if="building.data.currentEffect.values[0]"></div><!-- end ngIf: building.data.currentEffect.values[0] -->
			</div><!-- end ngIf: building.data.currentEffect.values[0] -->
			<!-- ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' --><table class="buildingNextEffects" ng-if="building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish'">
				<tbody>
					<tr>
						<td>
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', enoughRes: enoughRes && freeMasterSlots > 0}">
										<div class="labelLayer">
											<span class="buildingLevel">10</span>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td class="borderLeft">
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>&lrm;&#x202D;16
							</span>
						</td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0])">
								<span translate="" options="34" data="value:200"><span>&#x202D;&#x202D;200&#x202C;%&#x202C;</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl - (currentBuildingLevel < building.data.lvlMax ? 0 : 1)] track by $index -->
						</td>
						<td class="emptySpace"></td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0])">
								<span translate="" options="34" data="value:210"><span>&#x202D;&#x202D;210&#x202C;%&#x202C;</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl + (currentBuildingLevel < building.data.lvlMax ? 1 : 0)] track by $index -->
						</td>
						<td>
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>18
							</span>
						</td>
						<td class="borderLeft">
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
										<div class="labelLayer">
											<span class="buildingLevel">11</span>
											<!-- ngIf: currentBuildingLevel + 1 >= building.data.lvlMax -->
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
			<!-- ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
		</div>

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><span ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'" class="resource timeValue" tooltip="" tooltip-translate="Duration">
				<i class="symbol_clock_small_flat_black duration"></i>02:40:00
			</span><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'" class="dropOutBox"></div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div class="resources" ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'">
			<display-resources available="storage" resources="building.data.nextUpgradeCosts[currentBuildingLevel]" image-size="medium"><div class="costs max4digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_medium_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;1830&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_medium_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;1535&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_medium_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;1475&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_medium_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;825&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 --><div class="enoughResTime" ng-if="enoughResSecs > 0" translate="" data="time: 1723561868"><span>Достаточно ресурсов: </span><span i18ndt="1723561868" format="short">Сегодня 20:11</span></div><!-- end ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_medium_flat_black"></i></div>
</div></display-resources>
		</div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
	</div>
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoBottom"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
</div>`,
			11: `<div class="upgradeCostsTooltip" ng-controller="buildingUpgradeTooltipCtrl">
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoTop"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
	<div class="headline">
		<h3 translate="" class="building" options="11"><span>Амбар</span></h3>
	</div>

	<div class="queuedSlidesContainer">
		<!-- ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide1 active" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide2 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide3 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide4 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 --><div ng-repeat="numItem in [] | range:1:5" class="queuedBuildingSlide slide5 animate" ng-class="{active: numItemsInQueue >= numItem, animate: originalSlides < numItem}"></div><!-- end ngRepeat: numItem in [] | range:1:5 -->
	</div>

	<div class="content" ng-class="{boxMaxLevel: building.data.lvl >= building.data.lvlMax, boxDeconstruction: upgradeButtonClass == 'demolish'}">
		<div class="contentTopArea">
			<!-- ngIf: building.data.currentEffect.values[0] --><div class="effectDescription" ng-if="building.data.currentEffect.values[0]">
				<span translate="" options="11"><span>Повышает емкость хранилища</span></span>
				<!-- ngIf: building.data.currentEffect.values[0] --><div class="horizontalLine3d" ng-if="building.data.currentEffect.values[0]"></div><!-- end ngIf: building.data.currentEffect.values[0] -->
			</div><!-- end ngIf: building.data.currentEffect.values[0] -->
			<!-- ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' --><table class="buildingNextEffects" ng-if="building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish'">
				<tbody>
					<tr>
						<td>
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll', enoughRes: enoughRes && freeMasterSlots > 0}">
										<div class="labelLayer">
											<span class="buildingLevel">13</span>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td class="borderLeft">
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>&lrm;&#x202D;16
							</span>
						</td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0])">
								<span translate="" options="11" data="value:22000"><span>22k</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl - (currentBuildingLevel < building.data.lvlMax ? 0 : 1)] track by $index -->
						</td>
						<td class="emptySpace"></td>
						<td class="effectColumn">
							<!-- ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) --><span class="effectNumber" ng-if="building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0])">
								<span translate="" options="11" data="value:26000"><span>26k</span></span>
							</span><!-- end ngIf: building.data.effect && building.data.effect.length > 0 && !isArray(building.data.effect[0]) -->
							<!-- ngRepeat: effectValue in building.data.effect[currentBuildingLevel - building.data.lvl + (currentBuildingLevel < building.data.lvlMax ? 1 : 0)] track by $index -->
						</td>
						<td>
							<span class="resource populationValue" tooltip="" tooltip-translate="Resource.Population">
								<i class="unit_population_small_illu"></i>18
							</span>
						</td>
						<td class="borderLeft">
							<div class="buildingStatus">
								<div class="buildingBubble" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
									<div class="colorLayer notNow" ng-class="{disabled: upgradeButtonClass == 'notAtAll'}">
										<div class="labelLayer">
											<span class="buildingLevel">14</span>
											<!-- ngIf: currentBuildingLevel + 1 >= building.data.lvlMax -->
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table><!-- end ngIf: building.data.lvl < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
			<!-- ngIf: building.data.lvl >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
		</div>

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><span ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'" class="resource timeValue" tooltip="" tooltip-translate="Duration">
				<i class="symbol_clock_small_flat_black duration"></i>05:50:00
			</span><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'" class="dropOutBox"></div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->

		<!-- ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' --><div class="resources" ng-if="currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish'">
			<display-resources available="storage" resources="building.data.nextUpgradeCosts[currentBuildingLevel]" image-size="medium"><div class="costs max4digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_medium_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3260&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue notEnough" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_medium_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;4075&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_medium_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;2850&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_medium_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;815&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 --><div class="enoughResTime" ng-if="enoughResSecs > 0" translate="" data="time: 1723621547"><span>Достаточно ресурсов: </span><span i18ndt="1723621547" format="short">Завтра 12:45</span></div><!-- end ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_medium_flat_black"></i></div>
</div></display-resources>
		</div><!-- end ngIf: currentBuildingLevel < building.data.lvlMax && upgradeButtonClass != 'demolish' -->
		<!-- ngIf: currentBuildingLevel >= building.data.lvlMax || upgradeButtonClass == 'demolish' -->
	</div>
	<!-- ngInclude:  --><div ng-include="" src="'tpl/building/partials/upgradeActionInfo.html'" class="upgradeInfoBottom"><div class="upgradeInfo notNow" ng-class="{maxLevel: currentBuildingLevel >= building.data.lvlMax}">
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'possible' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: !premiumOptionMenu.options.masterBuilder && upgradeButtonClass == 'notAtAll' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'useMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax --><div class="actionText" ng-if="premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax">
		<span class="actionTextContent costsGold" translate=""><span>Открыть слот в очереди строительства</span></span>
	</div><!-- end ngIf: premiumOptionMenu.options.masterBuilder == 'buyMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
	<!-- ngIf: premiumOptionMenu.options.masterBuilder == 'disabledMasterBuilder' && currentBuildingLevel < building.data.lvlMax -->
</div></div>
</div>`,
			2: `<div class="finishNowTooltip" ng-controller="finishNowCtrl">
	<!-- ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) --><div ng-if="!isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0)">
		<h3>
			<span translate=""><span>Моментально завершить следующие поручения</span></span><!-- ngIf: price == 0 -->
			<!-- ngIf: price > 0 || forceGoldUsage --><span class="price" ng-if="price > 0 || forceGoldUsage"><i class="unit_gold_small_illu"></i>2:</span><!-- end ngIf: price > 0 || forceGoldUsage -->
			<!-- ngIf: price == -1 && !forceGoldUsage -->
		</h3>
		<div class="horizontalLine"></div>

		<!-- ngRepeat: build in inBuilding track by $index --><div ng-repeat="build in inBuilding track by $index">
			<!-- ngIf: !finishNowQueueType -->
			<span translate="" options="22"><span>Академия</span></span>
			<span class="level" translate="" data="lvl:20"><span>Уровень 20</span></span>
		</div><!-- end ngRepeat: build in inBuilding track by $index -->
		<!-- ngRepeat: destruction in inDestruction track by $index -->
		<!-- ngRepeat: research in inResearch track by $index -->
		<!-- ngRepeat: training in inTraining track by $index -->
	</div><!-- end ngIf: !isWorldWonder && (inBuilding.length>0 || inTraining.length>0 || inDestruction.length>0 || inResearch.length>0) -->
	<!-- ngIf: isWorldWonder -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && !config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && hasInvalidBuilding && config.balancing.specialRules.cropDiet -->
	<!-- ngIf: !isWorldWonder && !hasInvalidBuilding && inBuilding.length == 0 && inTraining.length == 0 && inDestruction.length == 0 && inResearch.length == 0 -->
	<!-- ngIf: !isWorldWonder && premiumOptionMenu.options.instantCompletion && !freeSlots && enoughRes === false -->
</div>`,
			3: `<span translate="" options="Building.npcTrader.Tooltip.Possible"><strong>Обменять ресурсы автоматически&nbsp;<i class="icon unit_gold_small_illu"></i> 5</strong></span>`,
			4: `<span>Распределить ресурсы вручную</span>`,
		},
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueue.show = true;
	},
	components: {},
}
</script>
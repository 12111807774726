<template>
	<div>
		<div class="costs max4digits">
			<span v-if="!checkStorage">
				<span v-for="(type, index) in resTypes" :key="index" v-show="hideZero ? resources[index] > 0 : resourceVisibility[index]" class="resource" :class="[type.type+'Value']" :content="type.name" v-tippy="{placement: 'bottom'}">
					<i :class="['unit_'+type.type+'_small_illu', 'resType'+(index + 1)]"></i>
					<span class="resourceValue" v-if="!onlyProductionPerHour" :class="{'strikethrough': showAsOldResources}">&nbsp;{{resources[index]}}<span v-if="percent">%</span></span>
					<!-- ngIf: ::numberUnit=='production' -->
					<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
				</span>
			</span>
			<span v-if="checkStorage">
				<span v-for="(type, index) in resTypes" :key="index" v-show="hideZero ? resources[index] > 0 : resourceVisibility[index]" class="resource" :class="[{'notEnough': !enoughStorage(index, resources[index]), 'enough': enoughStorage(index, resources[index]) && colorPositive}, type.type+'Value']" :content="type.name" v-tippy="{placement: 'bottom'}">
					<i :class="['unit_'+type.type+'_small_illu', 'resType'+(index + 1)]"></i>
					<span class="resourceValue" v-if="!onlyProductionPerHour" :class="{'strikethrough': showAsOldResources}">&nbsp;{{resources[index]}}<span v-if="percent">%</span></span>
					<!-- ngIf: ::numberUnit=='production' -->
					<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
				</span>
			</span>
			<span class="resource consumptionValue" v-if="(consumption || consumption == 0)" content="Потребление" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource.CropConsumption">
				<i class="unit_consumption_small_flat_black"></i>+{{consumption}}
			</span>
			<!-- ngIf: population || population == 0 -->
			<!-- ngIf: treasures || treasures == 0 -->
			<span class="resource treasureValue" v-if="stolenGoods || stolenGoods == 0" content="Краденые ценности" v-tippy="{placement: 'bottom'}" tooltip-translate="Resource.StolenGoods">
				<i class="unit_stolenGoods_small_illu"></i>
				<span>&nbsp;{{stolenGoods}}</span>
			</span>
			<span class="resource timeValue" v-if="time || time == 0" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration">
				<i class="symbol_clock_small_flat_black duration"></i>
				<span>&nbsp;{{formatSecondsDate(time, 'hh:mm:ss')}}</span>
			</span>
			<!-- ngIf: enoughResSecs == -1 -->
			<div class="enoughResTime" v-if="enoughResSecs > 0">
				<span>Достаточно ресурсов: </span>
				<span :i18ndt="enoughResSecs" format="short">{{formatDiffUnixDate(gameModel.currentServerTime, gameModel.currentServerTime + enoughResSecs, 'DD.MM.YY HH:mm:ss')}}</span>
			</div>
			<div class="enoughResTime" v-if="checkStorage && (capacityHintStorage || capacityHintBarn)">
				<div v-if="capacityHintStorage">Недостаточно места на складе</div>
				<div v-if="capacityHintBarn">Недостаточно места в амбаре</div>
			</div>
			<div class="iconButton premium npcTrade" v-if="showNpc">
				<i class="feature_npcTrader_small_flat_black"></i>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
	
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	props: {
		resources: {
			type: [Array, Object],
			required: true,
			default: [0, 0, 0, 0],
		},
		consumption: {
			type: Number,
		},
		stolenGoods: {
			type: Number,
		},
		enoughResSecs: {
			type: Number,
			default: 0,
		},
		time: {
			type: Number,
		},
		checkStorage: {
			type: Boolean,
			default: false,
		},
		colorPositive: {
			type: Boolean,
			default: false,
		},
		hideZero: {
			type: Boolean,
			default: false,
		},
		percent: {
			type: Boolean,
			default: false,
		},
		onlyProductionPerHour: {
			type: Boolean,
			default: false,
		},
		showAsOldResources: {
			type: Boolean,
			default: false,
		},
		resourceVisibility: {
			type: Array,
			default: [
				true,
				true,
				true,
				true,
			],
		},
		showNpc: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		resTypes: [
			{type: 'wood', name: 'Древесина'},
			{type: 'clay', name: 'Глина'},
			{type: 'iron', name: 'Железо'},
			{type: 'crop', name: 'Зерно'},
		],
	}),
	computed: {
		// есть ли переполнение склада хотя-бы по одному ресурсу в случае его получения
		capacityHintStorage(){
			let enough = false;
			for(let [index, resType] of Object.entries(this.resTypes)){
				if(resType.type != 'crop'){
					if(!this.enoughStorage(index, this.resources[index])){
						enough = true;
						break;
					}
				}
			}
			return enough;
		},
		// есть ли переполнение амбара в случае его получения
		capacityHintBarn(){
			let enough = false;
			for(let [index, resType] of Object.entries(this.resTypes)){
				if(resType.type == 'crop'){
					if(!this.enoughStorage(index, this.resources[index])){
						enough = true;
						break;
					}
				}
			}
			return enough;
		},
	},
	methods: {
		formatSecondsDate: lib.formatSecondsDate,
		formatDiffUnixDate: lib.formatDiffUnixDate,
		
		// влезает ли на склад
		enoughStorage(resTypeIndex, value){
			return value <= this.gameModel.island.storage[this.resTypes[resTypeIndex].type+'CountMax'];
		},
	},
}
</script>
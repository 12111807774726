<template>
	<div ng-controller="auctionsCtrl">
		<div tabulation="" tab-config-name="auctionsTabs" ng-if="!isSitter">
			<nav class="tabulation subtab unselectable">
				<a class="tab clickable" @click="onToggleTab('buy')" :class="{active: tab == 'buy', inactive: tab != 'buy'}">
					<div class="content ">
						<span>Купить</span>
					</div>
				</a>
				<a class="tab clickable" @click="onToggleTab('sell')" :class="{active: tab == 'sell', inactive: tab != 'sell'}">
					<div class="content ">
						<span>Продать</span>
					</div>
				</a>
				<a class="tab clickable" @click="onToggleTab('bids')" :class="{active: tab == 'bids', inactive: tab != 'bids'}">
					<div class="content ">
						<span>Ставки</span>
					</div>
				</a>
				<a class="tab clickable" @click="onToggleTab('silver')" :class="{active: tab == 'silver', inactive: tab != 'silver'}">
					<div class="content ">
						<span>Учёт серебра</span>
					</div>
				</a>
				<a class="tab clickable" @click="onToggleTab('exchange-office')" :class="{active: tab == 'exchange-office', inactive: tab != 'exchange-office'}">
					<div class="content ">
						<span>Пункт обмена</span>
					</div>
				</a>
			</nav>
			<div class="tabulationContent scrollable scrollingDisabled">
				<div class="scrollContentOuterWrapper disableScrolling">
					<div class="scrollContent disableScrolling" style="overflow-y: auto;">
						<div class="scrollContentInnerWrapper" style="width: auto;">
							<div ng-transclude="">
								<div class="loadedTab currentTab" v-if="tab == 'buy'">
									<buy />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'sell'">
									<sell />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'bids'">
									<bids />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'silver'">
									<silver />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'exchange-office'">
									<exchange-office />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import Buy from '@/components/pages/hero/auctions/Buy';
import Sell from '@/components/pages/hero/auctions/Sell';
import Bids from '@/components/pages/hero/auctions/Bids';
import Silver from '@/components/pages/hero/auctions/Silver';
import ExchangeOffice from '@/components/pages/hero/auctions/ExchangeOffice';

export default {
	mixins: lib.mixins,
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		
		tab: 'buy',
	}),
	computed: {},
	methods: {
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_10.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		window.removeEventListener('resize', this.onResize); 
	},
	components: {
		Buy,
		Sell,
		Bids,
		Silver,
		ExchangeOffice,
	},
}
</script>
<template>
	<div class="ownTroops">
		<table class="villagesTable lined">
			<thead class="troopsIconRow">
				<tr>
					<th translate=""><span>Деревня</span></th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType1" unit-icon="" data="21" content="Фаланга" v-tippy="{placement: 'top'}" tooltip-translate="Troop_21" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType2" unit-icon="" data="22" tooltip="" tooltip-translate="Troop_22" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType3" unit-icon="" data="23" tooltip="" tooltip-translate="Troop_23" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType4" unit-icon="" data="24" tooltip="" tooltip-translate="Troop_24" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType5" unit-icon="" data="25" tooltip="" tooltip-translate="Troop_25" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType6" unit-icon="" data="26" tooltip="" tooltip-translate="Troop_26" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType7" unit-icon="" data="27" tooltip="" tooltip-translate="Troop_27" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType8" unit-icon="" data="28" tooltip="" tooltip-translate="Troop_28" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType9" unit-icon="" data="29" tooltip="" tooltip-translate="Troop_29" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall gaul unitType10" unit-icon="" data="30" tooltip="" tooltip-translate="Troop_30" tooltip-placement="above"></i>
					</th>
					<th ng-repeat="n in []|range:1:11">
						<i class="unitSmall hero_illu" unit-icon="" data="hero" content="Герой" v-tippy="{placement: 'top'}" tooltip-translate="Troop_hero" tooltip-placement="above"></i>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr ng-repeat="village in ownTroops | orderBy:'villageName'">
					<td>
						<a clickable="setVillage(village.villageId)" class="truncated clickable">InZectorsk</a>
					</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">5</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">4</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">0</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">0</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">0</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">0</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">0</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">0</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">0</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">0</td>
					<td ng-repeat="(troopId, troop) in village.troops track by $index">1</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<td translate=""><span>Всего</span></td>
					<td ng-repeat="troop in totalTroops track by $index">5</td>
					<td ng-repeat="troop in totalTroops track by $index">4</td>
					<td ng-repeat="troop in totalTroops track by $index">0</td>
					<td ng-repeat="troop in totalTroops track by $index">0</td>
					<td ng-repeat="troop in totalTroops track by $index">0</td>
					<td ng-repeat="troop in totalTroops track by $index">0</td>
					<td ng-repeat="troop in totalTroops track by $index">0</td>
					<td ng-repeat="troop in totalTroops track by $index">0</td>
					<td ng-repeat="troop in totalTroops track by $index">0</td>
					<td ng-repeat="troop in totalTroops track by $index">0</td>
					<td ng-repeat="troop in totalTroops track by $index">1</td>
				</tr>
			</tfoot>
		</table>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
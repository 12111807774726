<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper welcomeScreen" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal welcomeScreen">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable" ng-if="!w.contentHeaderTemplate">
							<h2 class=" msAntiScroll drag" draggable="true">
								<span><span>С возвращением, </span><br><strong>lexinzector</strong></span>
							</h2>
						</div>
						<div class="heightTransitionContent" :style="{maxHeight: maxHeightContent}">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent">
									<div class="scrollContentOuterWrapper">
										<div class="scrollContent" style="overflow-y: auto;" :style="{maxHeight: maxHeightInnerContent}">
											<div class="scrollContentInnerWrapper" style="width: auto;">
												<div>
													<div ng-controller="welcomeScreenCtrl">
														<div class="prestigeInfo">
															<div class="progressbar" progressbar="" perc="47">
																<div class="progress">
																	<div class="bar positive perc " style="z-index: 2; width: 47%;"></div>
																	<div class="bar additionalBar additionalPerc"></div>
																	<div class="marker">
																		<div class="markerInner"></div>
																	</div>
																</div>
															</div>
															<h6 class="headerTrapezoidal">
																<div class="content clickable" content="Сейчас у тебя 247 престижа. Для следующего уровня необходимо 300 престижа." v-tippy="{placement: 'bottom'}" tooltip-data="prestige:247,nextLevelPrestige:300" tooltip-class="welcomeScreen" @click="$router.push({path: '/profile', query: {tab: 'prestige'}})" clickable="openWindow('profile', {'profileTab': 'prestige', 'playerId': player.data.playerId})">
																	<span class="rankLeft" data="rank:4"><span>Ранг: 4</span></span>
																	<div class="innerStars" ng-if="player.filled" playerid="1804" size="small">
																		<div ng-if="!badge">
																			<i class="prestigeStar prestige_starSilver_small_illu"></i>
																			<i class="prestigeStar prestige_starBronze_small_illu"></i>
																			<i class="prestigeStar prestige_starBronze_small_illu"></i>
																		</div>
																	</div>
																	<span class="rankRight" ng-if="!maxPrestige" data="rank:5"><span>Ранг: 5</span></span>
																</div>
															</h6>
														</div>
														<div class="activities container">
															<div class="containerHeader">
																<span><span>Текущие задания</span></span>
															</div>
															<div class="activitiesButtonContainer">
																<div class="clickableContainer activitiesButton adventureButton clickable" @click="$router.push({path: '/hero', query: {tab: 'adventures'}})" clickable="openWindow('hero', {'herotab': 'Adventures'})" content="Очки приключений" v-tippy="{placement: 'bottom'}" tooltip-translate="WelcomeScreen.Tooltip.Adventure" tooltip-data="adventurePoints:33">
																	<i class="welcomescreen_adventure_big_illu" ng-class="{disabled: AdventurePoints == 0}"></i>
																	<div class="horizontalLine double"></div>
																	<span class="activitiesAmount">33</span>
																</div>
																<div class="clickableContainer activitiesButton robberButton clickable" @click="$router.push({path: '/map'})" clickable="openPage('map')" content="Логова и лагеря разбойников: 2" v-tippy="{placement: 'bottom'}" tooltip-translate="WelcomeScreen.Tooltip.Robbers" tooltip-data="robberCamps:2">
																	<i class="welcomescreen_robbers_big_illu" ng-class="{disabled: RobberCampsAmount == 0}"></i>
																	<div class="horizontalLine double"></div>
																	<span class="activitiesAmount">2</span>
																</div>
																<div class="clickableContainer activitiesButton cardGameButton clickable" @click="$router.push({path: '/hero', query: {tab: 'card-game'}})" clickable="openWindow('hero', {'herotab': 'CardGame'})" content="Бесплатные карточные игры: 0" v-tippy="{placement: 'bottom'}" tooltip-translate="WelcomeScreen.Tooltip.Cardgame" tooltip-data="cardgameRolls:0">
																	<i class="welcomescreen_cardgame_big_illu disabled" ng-class="{disabled: cardGameRollsSum == 0}"></i>
																	<div class="horizontalLine double"></div>
																	<span class="activitiesAmount">0</span>
																</div>
																<div class="clickableContainer activitiesButton last prestigeButton clickable" @click="$router.push({path: '/profile', query: {tab: 'prestige'}})" clickable="openWindow('profile', {'profileTab': 'prestige', 'playerId': player.data.playerId})" content="Выполнено недельных заданий: 2/10" v-tippy="{placement: 'bottom'}" tooltip-translate="WelcomeScreen.Tooltip.Prestige" tooltip-data="completedPrestigeTasks:2,totalPrestigeTasks:10">
																	<i class="welcomescreen_prestige_big_illu"></i>
																	<div class="horizontalLine double"></div>
																	<span class="activitiesAmount prestigeTasks">2 / 10</span>
																</div>
															</div>
														</div>
														<div class="activityStream container">
															<div class="containerHeader">
																<span><span>Пока тебя не было...</span></span>
															</div>
															<div class="activityStreamContainer containerBody scrollable" :style="{maxHeight: maxHeightInnerContent2}">
																<div class="scrollContentOuterWrapper">
																	<div class="scrollContent" style="overflow-y: auto;">
																		<div class="scrollContentInnerWrapper" style="width: auto;">
																			<div v-for="stream in activityStream" :key="stream.idActivity" class="activity">
																				<div class="activityWrapper">
																					<div class="activityStreamActivity" :class="{'open': stream.showSubStream}" @click="onToggleSubStreams(stream)">
																						<i :class="stream.icon"></i>
																						<div class="activityText">
																							<span>
																								<span v-if="stream.amount > 0">{{stream.name}}: {{stream.amount}}</span>
																								<span v-else>{{stream.name}}</span>
																							</span>
																						</div>
																					</div>
																					<div v-if="stream.showSubStream">
																						<div v-for="subStream in stream.subStreams" :key="subStream.idSubStream" class="activityStreamActivity subActivity" ng-click="clickHandler(this, true)">
																							<span>
																								<span>{{subStream.name}} ур. {{subStream.amount}} ({{subStream.villageName}})</span>
																							</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="kingdomStatus container">
															<div class="containerHeader">
																<span><span>Статус королевства</span></span>
															</div>
															<div class="kingdomStatusButtonContainer containerBody">
																<div class="clickableContainer kingdomStatusButton clickable" @click="$router.push({path: '/society', query: {tab: 'territory', subtab: 'territory'}})" clickable="openWindow('society', {'subtab': 'Territory'})">
																	<i class="welcomescreen_kingdom_big_illu"></i>
																	<div class="horizontalLine"></div>
																	<div class="headerTrapezoidal">
																		<span class="content"><span>Ранг: -</span></span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
.modalWrapper.welcomeScreen {
	margin-top: 0;
	margin-left: 0;
}
.modalWrapper .modal .modalContent .modalDecoration {
	left: -10px;
	right: -10px;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Отчеты | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		maxHeightInnerContent2: '170px',
		cancelHover: false,
		
		villageBackgroundLeft: '0px',
		
		activityStream: [
			{
				idActivity: 4,
				idKingdom: 1,
				idPlayer: 1,
				icon: 'welcomescreen_buildingUpgrade_small_flat_black',
				name: 'Построено уровней зданий',
				amount: 1,
				timestamp: 1707853247,
				showSubStream: false,
				subStreams: [
					{
						idSubStream: 1,
						idActivity: 4,
						idVillage: 1,
						idLocation: 34,
						villageName: 'InZectorsk',
						name: 'Академия',
						amount: 5,
						timestamp: 1707853247,
					},
				],
			},
			{
				idActivity: 19,
				idKingdom: 1,
				idPlayer: 1,
				icon: 'welcomeScreen_newItemAvailable_small_flat_black',
				name: 'Доступны предметы 3 уровня!',
				amount: 0,
				timestamp: 1723809600,
				showSubStream: false,
				subStreams: [],
			},
			{
				idActivity: 18,
				idKingdom: 1,
				idPlayer: 1,
				icon: 'welcomeScreen_newItemAvailable_small_flat_black',
				name: 'Доступны предметы 2 уровня!',
				amount: 0,
				timestamp: 1723809600,
				showSubStream: false,
				subStreams: [],
			},
			{
				idActivity: 6,
				idKingdom: 1,
				idPlayer: 1,
				icon: 'building_g19_small_flat_black',
				name: 'Обучено воинов',
				amount: 2,
				timestamp: 1723809600,
				showSubStream: false,
				subStreams: [
				{
						idSubStream: 1,
						idActivity: 6,
						idVillage: 1,
						idLocation: 34,
						idUnit: 'Мечник',
						villageName: 'Брест',
						name: '1 Мечник: (Брест)',
						timestamp: 1707853247,
						amount: 1,
					},
				],
			},
			{
				idActivity: 13,
				idKingdom: 1,
				idPlayer: 1,
				icon: 'unit_treasure_small_flat_black',
				name: 'Твое королевство завоевало в битвах сокровища (16) и очки победы (0)!',
				amount: 0,
				treasures: 16,
				victoryPoints: 0,
				timestamp: 1723809600,
				showSubStream: false,
				subStreams: [],
			},
			{
				idActivity: 15,
				idKingdom: 1,
				idPlayer: 1,
				icon: 'welcomeScreen_worldWonder_small_flat_black',
				name: 'Появились Чудеса Света!',
				amount: 0,
				treasures: 16,
				victoryPoints: 0,
				timestamp: 1723809600,
				showSubStream: false,
				subStreams: [],
				// открывает страницу помощи по игре - войска и дипломатия - чудо света
			},
			{
				idActivity: 15,
				idKingdom: 1,
				idPlayer: 1,
				icon: 'welcomeScreen_worldWonder_small_flat_black',
				name: 'Игрок Медоеды, захватил Чудо света у игрока Натары!',
				amount: 0,
				treasures: 16,
				victoryPoints: 0,
				timestamp: 1723809600,
				showSubStream: false,
				subStreams: [],
				// открывает страницу статистика - чудо света
			},
		],
	}),
	computed: {},
	methods: {
		onToggleSubStreams(stream){
			if(stream.subStreams.length > 0){
				stream.showSubStream = !stream.showSubStream;
			}
		},
		
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 0 > 0 ? maxHeightContent - 0 : 0) + 'px';
			this.maxHeightInnerContent2 = (maxHeightContent - 380 > 0 ? maxHeightContent - 380 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {},
}
</script>
<template>
	<div class="mainContentBackground villageBackground unselectable">
		<div class="sea viewBackground" :style="{top: '0px', left: villageBackgroundLeft, right: 'auto', height: '1440px', transform: 'perspective(1000px) scale3d(1, 1, 1)', transformOrigin: '50% 50%', filter: 'blur(0px)', perspective: '1000px'}">
			<div class="modalWrapper shop" style="opacity: 1; top: 400px; align-items: flex-start; justify-content: center; display: flex; width: 100%; height: 100%;">
				<div class="modal specialHeader goldenHeader shop">
					<div class="modalContent">
						<div class="modalDecoration"></div>
						<div class="functionPanel unselectable">
							<a class="closeWindow clickable" @click="$router.push('/township')">
								<div class="decoration" content="Закрыть" v-tippy="{placement: 'bottom'}">
									<i class="action_cancel_tiny_flat_black"></i>
								</div>
							</a>
						</div>
						<div class="contentHeader unselectable" ng-if="!w.contentHeaderTemplate">
							<h2 class=" msAntiScroll">
								<span>{{tabName}}</span>
							</h2>
						</div>
						<div class="heightTransitionContent" style="margin: 0; padding: 0;">
							<div class="contentTabulation">
								<div class="tabulationContent scrollable tabContent" style="margin-top: 0;">
									<div class="scrollContentOuterWrapper active">
										<div class="scrollContent" style="overflow-y: auto;">
											<div class="scrollContentInnerWrapper" ng-transclude="" style="width: auto;">
												<div ng-transclude="">
													<div class="shop shopTabDeals " ng-class="[{shopIsUnavailable: shopIsUnavailable}, 'shopTabDeals', 'shopTabBoosts', 'shopTabGold', 'shopTabCards', 'shopTabInvite', 'shopTabExchange', 'productSelection', 'paymentMethodSelection', 'payment', 'purchaseConfirmation', 'purchaseCompleted']" ng-controller="shopCtrl">
														<div class="shopHeader">
															<div ng-if="promotionUntil > currentServerTime" class="promotionBanner shop_promotionBanner_huge_illu">
																<div class="promotionBannerText">
																	<div class="promotionHeadline"><span>Продажа</span></div>
																	<div countdown="1723759199" dhms="true">
																		<!--0д 12ч 13м 45с-->
																		<vue-countdown :time="((((12 * 60) + 13) * 60) + 45) * 1000" v-slot="{ days, hours, minutes, seconds }">
																			{{days}}д {{(hours < 10 ? 0 : '')+hours}}ч {{(minutes < 10 ? 0 : '')+minutes}}м {{(seconds < 10 ? 0 : '')+seconds}}с
																		</vue-countdown>
																	</div>
																</div>
															</div>
															<!--
															<table v-if="tab == 'gold'" class="shopSteps transparent">
																<tbody>
																	<tr>
																		<td ng-repeat="step in shopSteps" class="step">
																			<a class="stepLink clickable done" clickable="gotoStep(step)" options="1" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}"><span>Выбор пакета</span></a>
																		</td>
																		<td ng-repeat="step in shopSteps" class="step">
																			<a class="stepLink clickable disabled active" clickable="gotoStep(step)" options="2" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}"><span>Выбор способа оплаты</span></a>
																		</td>
																		<td ng-repeat="step in shopSteps" class="step">
																			<a class="stepLink clickable disabled" clickable="gotoStep(step)" options="3" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}"><span>Оплата</span></a>
																		</td>
																		<td ng-repeat="step in shopSteps" class="step">
																			<a class="stepLink clickable disabled" clickable="gotoStep(step)" options="4" ng-class="{active: shopStepOrder[step] == shopStepOrder[currentStep], done: shopStepOrder[step] < shopStepOrder[currentStep], disabled: shopStepOrder[step] >= shopStepOrder[currentStep] || shopStepOrder[currentStep] >= shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]}"><span>Покупка подтверждена</span></a>
																		</td>
																	</tr>
																</tbody>
															</table>
															-->
														</div>
														<div class="shopContent" ng-class="{noTransitions: noTransitions}">
															<div tabulation="" tab-config-name="shopTabConfig" template-url="tpl/shop/tabs/ShopTabs.html" style="display: flex;">
																<nav class="tabulation  vertical" ng-if="tabData.tabs.length > 1">
																	<a class="tab clickableContainer clickable" :class="{active: tab == 'deals'}" @click="onToggleTab('deals')">
																		<i class="tabIcon shop_tabDeals_large_illu"></i>
																		<h4 options="Deals"><span>Лучшие предложения</span></h4>
																		<i class="selection_arrow_illu arrow" v-if="tab == 'deals'"></i>
																	</a>
																	<a class="tab clickableContainer clickable" :class="{active: tab == 'boosts'}" @click="onToggleTab('boosts')">
																		<i class="tabIcon shop_tabBoosts_large_illu"></i>
																		<h4 options="Boosts"><span>Ускорители</span></h4>
																		<i class="selection_arrow_illu arrow ng-hide" v-if="tab == 'boosts'"></i>
																	</a>
																	<a class="tab clickableContainer clickable" :class="{active: tab == 'gold'}" @click="onToggleTab('gold')">
																		<i class="tabIcon shop_tabGold_large_illu"></i>
																		<h4 options="Gold"><span>Купить золото</span></h4>
																		<i class="selection_arrow_illu arrow ng-hide" v-if="tab == 'gold'"></i>
																	</a>
																	<a class="tab clickableContainer clickable" :class="{active: tab == 'cards'}" @click="onToggleTab('cards')">
																		<i class="tabIcon shop_tabCards_large_illu"></i>
																		<h4 options="Cards"><span>Карточная игра</span></h4>
																		<i class="selection_arrow_illu arrow ng-hide" v-if="tab == 'cards'"></i>
																	</a>
																	<a class="tab clickableContainer clickable" :class="{active: tab == 'invite'}" @click="onToggleTab('invite')">
																		<i class="tabIcon shop_tabInvite_large_illu"></i>
																		<h4 options="Invite"><span>Пригласить друга</span></h4>
																		<i class="selection_arrow_illu arrow ng-hide" v-if="tab == 'invite'"></i>
																	</a>
																	<a class="tab clickableContainer clickable" :class="{active: tab == 'exchange'}" @click="onToggleTab('exchange')">
																		<i class="tabIcon shop_tabExchange_large_illu"></i>
																		<h4 options="Exchange"><span>Пункт обмена</span></h4>
																		<i class="selection_arrow_illu arrow ng-hide" v-if="tab == 'exchange'"></i>
																	</a>
																</nav>
																<div class="tabContent" style="width: 100%;">
																	<div class="currentTab activeTab" v-if="tab == 'deals'">
																		<deals />
																	</div>
																	<div class="currentTab activeTab" v-if="tab == 'boosts'">
																		<boosts />
																	</div>
																	<div class="currentTab activeTab" v-if="tab == 'gold'">
																		<gold />
																	</div>
																	<div class="currentTab activeTab" v-if="tab == 'cards'">
																		<cards />
																	</div>
																	<div class="currentTab activeTab" v-if="tab == 'invite'">
																		<invite />
																	</div>
																	<div class="currentTab activeTab" v-if="tab == 'exchange'">
																		<exchange />
																	</div>
																</div>
															</div>
															<div ng-if="selectedProduct || purchasedProduct || shopIsUnavailable" class="shopRoom  tkTopSeller">
																<i class="desk shop_desk_illu"></i>
																<i class="merchant shop_merchant_illu"></i>
																<div ng-if="selectedProduct || purchasedProduct.features" class="premiumPackage billingInfo">
																	<div class="topDecoration"></div>
																	<div class="packageContent medium">
																		<div class="packageContentBox">
																			<div class="packageLabel">
																				<div ng-if="selectedProduct" class="labelText">Мешок с золотом</div>
																				<!-- ngIf: purchasedProduct -->
																			</div>
																			<div ng-if="selectedProduct" class="packageItems"></div>
																			<!-- ngIf: purchasedProduct.features -->
																		</div>
																	</div>
																	<div class="bottomDecoration"></div>
																</div>
																<!-- ngIf: purchasedProduct -->
																<!-- ngIf: purchasedProduct -->
																<div ng-if="selectedProduct.gold || purchasedProduct.gold" class="packageGoldValue">700<br><span><span>Золото</span></span></div>
																<!-- ngIf: shopIsUnavailable -->
															</div>
															<div ng-if="currentStep == SHOP_STEP.PAYMENT || currentStep == SHOP_STEP.PURCHASE_CONFIRMATION" class="stepInfo" options="payment">
																<strong>Все почти готово...</strong><br><br><span>Открыта страница платежной системы.</span><br><br><span>Пожалуйста, продолжите покупку в отдельно открытом окне.</span>
															</div>
														</div>
														<div class="shopFooter" v-if="tab == 'deals' || tab == 'boosts'">
															<div class="shopCheckout">
																<div id="paynetCheckout"></div>
																<div id="paynetVoucher"></div>
															</div>
															<div ng-if="!shopIsUnavailable" class="shopLinks">
																<a class="redeemVoucher clickable" clickable="gotoStep(SHOP_STEP.VOUCHER_REDEMPTION)"><span>Активировать купон</span></a>
																<a class="orderHistory clickable" clickable="openOverlay('orderHistory')"><span>История заказов</span></a>
															</div>
														</div>
														<div class="shopFooter" v-if="tab == 'gold'">
															<div class="shopCountryDropdown dropdownContainer" dropdown="" ng-if="shopCountryDropdownData.options && !selectedProduct" data="shopCountryDropdownData">
																<div class="dropdownHead closed clickable openUpwards" clickable="clickDropdown()">
																	<span class="selectedItem truncated"><i class="languageFlag ru"></i>Russian Federation</span>
																	<i class="symbol_arrowDown_tiny_illu"></i>
																</div>
																<input class="dropdownInput" type="text"/>
																<!--
																<select style="display: block;">
																	<option>Kazakhstan</option>
																	<option>Russian Federation</option>
																</select>
																-->
															</div>
															<button class="backButton clickable" ng-if="selectedProduct || purchasedProduct" clickable="gotoStep(SHOP_STEP.SELECT_PRODUCT)">
																<div class="content">
																	<span ng-transclude="" class="innerText">
																		<span ng-if="shopStepOrder[currentStep] < shopStepOrder[SHOP_STEP.PURCHASE_CONFIRMATION]">
																			<span>Назад</span>
																		</span>
																	</span>
																	<div class="animationOverlay"></div>
																	<i class="checkmark action_check_small_flat_white"></i>
																	<i class="checkmark action_check_small_flat_black"></i>
																	<i class="checkmark action_check_small_flat_red"></i>
																</div>
															</button>
															<div class="shopCheckout">
																<div id="shop-layout">
																	<div class="shop-components row mb-4">
																		<div class="providerArrow from disabled"></div>
																		<div id="shop-methods">
																			<h5 class="methods-title">Способы оплаты</h5>
																			<div title="" class="payment-method active" style="margin-left: 0px;">
																				<img alt="" src="//iwstatic.g.bsrv.su/img/paymentMethods/qiwi_cc.png" class="methods-icon" data-cmp-info="10" />
																				<div class="methods-name">
																					<p>Credit cards Russia</p>
																				</div>
																			</div>
																			<div title="" class="payment-method">
																				<img alt="" src="//iwstatic.g.bsrv.su/img/paymentMethods/paypal.png" class="methods-icon" data-cmp-info="10" />
																				<div class="methods-name">
																					<p>PayPal</p>
																				</div>
																			</div>
																			<div title="" class="payment-method">
																				<img alt="" src="//iwstatic.g.bsrv.su/img/paymentMethods/adyen_cc.png" class="methods-icon" data-cmp-info="10" />
																				<div class="methods-name">
																					<p>Debit and credit cards</p>
																				</div>
																			</div>
																			<div title="" class="payment-method">
																				<img alt="" src="//iwstatic.g.bsrv.su/img/paymentMethods/adyen_paysafecard.png" class="methods-icon" data-cmp-info="10"/>
																				<div class="methods-name">
																					<p>paysafecard</p>
																				</div>
																			</div>
																			<div class="dummy payment-method"></div>
																			<div class="dummy payment-method"></div>
																			<div class="dummy payment-method"></div>
																		</div>
																		<div class="providerArrow to disabled"></div>
																		<div id="cart-layout" class="cart-component col-md-3">
																			<h5 class="shop-cart-title">Корзина</h5>
																			<div class="empty-cart">Корзина пуста</div>
																			<div id="shop-cart" class="mb-3">
																				<div id="cart-product-974826e4-c300-4c6d-b667-d83a1a67f218" product-id="974826e4-c300-4c6d-b667-d83a1a67f218" product-tags="" class="cart-product">
																					<div class="product">
																						<div class="product-name"><span>Горсть золотых монет</span></div>
																						<div class="product-price">1,69&nbsp;€</div>
																						<div class="product-btn">
																							<button class="product-remove">Убрать товар</button>
																						</div>
																					</div>
																				</div>
																				<div class="cart-promotional-products"></div>
																				<div class="cart-summary">
																					<div class="cart-total"><span>СТОИМОСТЬ ЗАКАЗА:</span>1,69&nbsp;€</div>
																					<div class="cart-shipping"><span>СТОИМОСТЬ ДОСТАВКИ:</span>0,00&nbsp;€</div>
																					<div class="cart-vat"><span>НДС 21%:</span>0,35&nbsp;€</div>
																					<div class="cart-total-vat"><span>ВСЕГО К ОПЛАТЕ:</span>2,04&nbsp;€*</div>
																				</div>
																			</div>
																			<div class="shop-cart-payment-method mb-3">
																				<div class="method-name">
																					<span class="method-label">Способ оплаты:</span>
																					<span class="active-method">Credit cards Russia</span>
																					<span class="active-method">PayPal</span>
																				</div>
																				<div class="transaction-duration">
																					<span>Время обработки заказа: </span>незамедлительно
																				</div>
																			</div>
																			<div class="legal-remark">* Все цены окончательные.</div>
																		</div>
																	</div>
																	<div class="withdrawal">Я принимаю <a target="_blank" href="//iwstatic.g.bsrv.su/files/terms-ru.pdf">Общие положения,</a> <a target="_blank" href="//iwstatic.g.bsrv.su/files/terms-en.pdf">Политику конфиденциальности</a> и прочитал(а) <a target="_blank" href="//iwstatic.g.bsrv.su/files/terms-ru.pdf#row">информацию о праве на отзыв.</a></div>
																	<button class="product-pay btn">Оплатить</button>
																</div>
																<div id="common-components">
																	<div id="shop-modal" class="modal-mask">
																		<div class="modal-wrapper">
																			<div class="modal-container">
																				<div class="modal-header">Ошибка при активации купона<button class="modal-close-button">x</button></div>
																				<div class="modal-body">Произошла ошибка при активации купона. Пожалуйста, введите корректный код купона. (Код: 4001)</div>
																				<div class="modal-footer"><button class="modal-default-button">OK</button></div>
																			</div>
																		</div>
																	</div>
																</div>
																<div id="shop-vouchers">
																	<div id="voucher-fields">
																		<label for="voucher-field-input">Активировать купон:</label>
																		<input id="voucher-field-input" maxlength="36" placeholder="Введите код купона"/>
																		<button id="voucher-field-reset" class="btn">X</button>
																		<button id="voucher-field-button" class="btn">Активировать</button>
																	</div>
																</div>
																<div id="paynetVoucher"></div>
															</div>
															<div ng-if="currentStep == SHOP_STEP.PAYMENT" class="paymentHelp" data="reopenPayment:openPaymentWindow()">
																<span>Если окно покупки не открылось, то нажмите на </span>
																<a clickable="openPaymentWindow()" class="clickable">эту ссылку.</a>
															</div>
															<!-- ngIf: currentStep == SHOP_STEP.PURCHASE_COMPLETED -->
															<div ng-if="!shopIsUnavailable" class="shopLinks">
																<a class="redeemVoucher clickable" clickable="gotoStep(SHOP_STEP.VOUCHER_REDEMPTION)">
																	<span>Активировать купон</span>
																</a>
																<a ng-if="goldPackages && goldPackages.length > 0" class="allPaymentMethods clickable" clickable="openOverlay('allPaymentMethods')">
																	<span>Все способы оплаты</span>
																</a>
																<a class="orderHistory clickable" clickable="openOverlay('orderHistory')"><span>История заказов</span></a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="window-overlay">
							
							<!-- все способы оплаты -->
							<all-payment-methods v-if="gameModel.shop.allPaymentMethodsShow" />
							
							<!-- история заказов -->
							<order-history v-if="gameModel.shop.orderHistoryShow" />
							
							<!-- функции золота -->
							<gold-features v-if="gameModel.shop.goldFeaturesShow" />
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import Deals from '@/components/pages/shop/Deals';
import Boosts from '@/components/pages/shop/Boosts';
import Gold from '@/components/pages/shop/Gold';
import Cards from '@/components/pages/shop/Cards';
import Invite from '@/components/pages/shop/Invite';
import Exchange from '@/components/pages/shop/Exchange';

import AllPaymentMethods from '@/components/pages/shop/windowOverlay/AllPaymentMethods';
import OrderHistory from '@/components/pages/shop/windowOverlay/OrderHistory';
import GoldFeatures from '@/components/pages/shop/windowOverlay/GoldFeatures';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Функции золота | IW'});
	},
	data: () => ({
		maxHeightContent: '550px',
		maxHeightInnerContent: '530px',
		cancelHover: false,
		
		tab: 'deals',
		tabName: 'Лучшие предложения',
		
		villageBackgroundLeft: '0px',
	}),
	computed: {},
	methods: {
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
		onToggleTab(tab, push = true){
			this.tab = tab;
			if(push) this.$router.push({query: {tab}});
			this.onSetTabName();
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_21.ogg');
				audio.play();
			}
		},
		onSetTabName(){
			switch(this.tab){
				case 'deals':
					this.tabName = 'Лучшие предложения';
					break;
				case 'boosts':
					this.tabName = 'Ускорители';
					break;
				case 'gold':
					this.tabName = 'Купить золото';
					break;
				case 'cards':
					this.tabName = 'Карточная игра';
					break;
				case 'invite':
					this.tabName = 'Пригласить друга';
					break;
				case 'exchange':
					this.tabName = 'Пункт обмена';
					break;
			}
		},
		
		onResize(){
			//let leftpos = (window.innerWidth / 2) - (1920 / 2) - 70;
			let leftpos = (window.innerWidth / 2) - (1920 / 2);
			//console.log(window.innerWidth, leftpos);
			this.villageBackgroundLeft = leftpos+'px';
			
			let maxHeightContent = window.innerHeight - 325;
			maxHeightContent = (maxHeightContent < 550 ? 550 : maxHeightContent);
			this.maxHeightContent = maxHeightContent + 'px';
			this.maxHeightInnerContent = (maxHeightContent - 57 > 0 ? maxHeightContent - 57 : 0) + 'px';
		},
	},
	mounted(){
		this.onModalOpen();
		
		window.addEventListener('resize', this.onResize);
		this.onResize();
		
		if(this.$route.query.tab){
			this.tab = this.$route.query.tab;
			this.onSetTabName();
		}
		else this.$router.push({query: {tab: this.tab}});
	},
	unmounted(){
		this.onModalClose();
		
		window.removeEventListener('resize', this.onResize); 
	},
	beforeRouteUpdate(to, from, next){
		if(!to.query.tab) this.$router.push({query: {tab: 'deals'}});
		else if(to.query.tab != this.tab){
			this.onToggleTab(to.query.tab, false);
			this.onSetTabName();
		}
		next();
	},
	created(){
		this.gameModel.buildingQueueShow = true;
	},
	components: {
		Deals,
		Boosts,
		Gold,
		Cards,
		Invite,
		Exchange,
		
		AllPaymentMethods,
		OrderHistory,
		GoldFeatures,
	},
}
</script>
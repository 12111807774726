<template>
	<div class="tabExpansions hiddenTab">
		<div>
			<div ng-controller="unitsTrainCtrl">
				<div ng-include="" src="'tpl/building/partials/lists/units.html'">
					<div class="contentBox gradient unitList">
						<div class="contentBoxBody">
							<carousel-unit-expansion :items="units" @on-active-item="(item) => activeItem = item" :cols="2" />
							<div v-if="activeItem">
								<table class="unitInfo transparent">
									<tbody>
										<tr>
											<td>
												<div class="unitHeader">
													<i class="unitIcon unitMedium gaul unitType10" unit-icon="" big="true" data="30"></i>
													<h4 class="unitName">
														<span options="30"><span>Поселенец</span></span>
														<!-- ngIf: activeItem.unitLevel >= 0 &&
															!(building.data.buildingType == buildingTypes.PALACE || building.data.buildingType == buildingTypes.RESIDENCE) -->
														<span>
															<span v-if="activeItem.existing">({{activeItem.existing}})</span>
															<span class="notEnoughSlots" v-if="usedControlPoint >= availableControlPoints || (activeItem.type == 10 && (availableControlPoints - usedControlPoint) < 3)">
																<span>(<span><span>Недостаточно слотов экспансии</span></span>)</span>
															</span>
														</span>
													</h4>
													<div class="symbol_i_medium_wrapper">
														<i class="symbol_i_medium_flat_white clickable" clickable="openWindow('help', {'pageId': 'Troops_' + activeItem.unitType})" content="Дополнительная информация" v-tippy="{placement: 'top'}" tooltip-translate="Units.Tooltip.UnitInfo" tooltip-placement="above"></i>
													</div>
												</div>
												<div class="description"><span>{{activeItem.description}}</span></div>
											</td>
											<td>
												<table class="unitAttributes transparent">
													<tbody>
														<tr>
															<td>
																<i class="unit_capacity_small_flat_black" content="Могут переносить" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CarryingCapacity.Label"></i>
															</td>
															<td>{{activeItem.capacity}}</td>
														</tr>
														<tr>
															<td><i class="unit_speed_small_flat_black" content="Скорость" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.Speed.Label"></i></td>
															<td>{{activeItem.speed}}</td>
														</tr>
														<tr>
															<td>
																<i class="unit_consumption_small_flat_black" content="Потребление" v-tippy="{placement: 'bottom'}" tooltip-translate="Troops.CropConsumption.Label"></i>
															</td>
															<td>{{activeItem.cropConsumption}}</td>
														</tr>
													</tbody>
												</table>
											</td>
											<td ng-if="building.data.buildingType != buildingTypes.HEALING_TENT">
												<table class="unitAttributes transparent" ng-if="building.data.buildingType != buildingTypes.BLACKSMITH">
													<tbody>
														<tr>
															<td><i class="movement_attack_small_flat_black" content="Атака" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.Attack"></i>
															</td>
															<td>{{activeItem.attack}}</td>
														</tr>
														<tr>
															<td>
																<i class="unit_defenseInfantry_small_flat_black" content="Защита от пехоты" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseInfantry"></i>
															</td>
															<td>{{activeItem.defenseInfantry}}</td>
														</tr>
														<tr>
															<td>
																<i class="unit_defenseCavalry_small_flat_black" content="Защита от кавалерии" v-tippy="{placement: 'bottom'}" tooltip-translate="TroopAttribute.DefenseCavalry"></i>
															</td>
															<td>{{activeItem.defenseCavalry}}</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
								<div class="horizontalLine"></div>
								<display-resources v-if="activeItem" class="costsFooter" :resources="allCosts || activeItem.costs" :hide-zero="true" available="storage" :consumption="allConsumption" :time="activeItem.duration * activeItem.value" />
								<div class="sliderRow">
									<div class="sliderArrow"></div>
									<range-slider v-if="activeItem.existing <= 0" :min="1" :max="1" v-model.number="activeItem.value" disabled style="width: 87%;" />
									<range-slider v-if="activeItem.existing > 0" :min="1" :max="activeItem.existing" v-model.number="activeItem.value" style="width: 87%;" />
									<npc-trader-button type="unitTrain" :costs="allCosts" :current-max="0" :current-limit="0" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<button :class="{'disabled': !activeItem || activeItem.disabled || activeItem.value <= 0 || !gameModel.island.storage.hasEnoughResources(activeItem.costs) || (usedControlPoint >= availableControlPoints || (activeItem.type == 10 && (availableControlPoints - usedControlPoint) < 3))}" class="animate footerButton clickable" clickable="startTraining(activeItem)" tooltip="" tooltip-translate-switch="{
					'ExpansionSlots.ExpansionList.NotEnoughSlotsAvailable': true,
					'TrainTroops.NotResearched': true,
					'TrainTroops.SetAmount': false,
					'Error.NotEnoughRes': true
				}">
					<div class="content">
						<span class="innerText">
							<span><span>Обучить</span></span>
						</span>
						<!-- ngIf: price > 0 || forceGoldUsage -->
						<!-- ngIf: price == -1 && !forceGoldUsage -->
						<div class="animationOverlay"></div>
						<i class="checkmark action_check_small_flat_white"></i>
						<i class="checkmark action_check_small_flat_black"></i>
						<i class="checkmark action_check_small_flat_red"></i>
					</div>
				</button>
			</div>
		</div>
		<div class="expansionSlots contentBox gradient">
			<h6 class="contentBoxHeader headerTrapezoidal">
				<div class="content"><span>Слоты экспансии</span></div>
			</h6>
			<div class="expansionList contentBoxBody">
				<div ng-repeat="slot in expensionSlots" class="expansionSlot arrowContainer arrowDirectionTo active" ng-class="{'active': slot.state == 'active' || slot.state == 'occupied', 'locked disabled': slot.state == 'locked'}">
					<span class="arrowInside">1</span>
					<!-- ngIf: slot.state == 'locked' -->
					<!-- ngIf: slot.state == 'active' -->
					<div class="slotVillage arrowOutside fullCentered" ng-if="slot.state == 'occupied'">
						<a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264692}, null, true)" village-link="" villageid="536264692" villagename="Зарница">Зарница</a>
					</div>
				</div>
				<div ng-repeat="slot in expensionSlots" class="expansionSlot arrowContainer arrowDirectionTo active" ng-class="{'active': slot.state == 'active' || slot.state == 'occupied', 'locked disabled': slot.state == 'locked'}">
					<span class="arrowInside">1</span>
					<!-- ngIf: slot.state == 'locked' -->
					<div class="slotUnlocks arrowOutside fullCentered" ng-if="slot.state == 'active'">
						<span ng-if="slot.subState == 'free'">активно</span>
						<span ng-if="slot.subState == 'icons'">
							<i class="unitIcon unitSmall gaul unitType10 available" unit-icon="" ng-class="icon.className" data="icon.tribeId, icon.unitId" ng-repeat="icon in slot.subStateIcons"></i>
							<i class="unitIcon unitSmall gaul unitType10 available" unit-icon="" ng-class="icon.className" data="icon.tribeId, icon.unitId" ng-repeat="icon in slot.subStateIcons"></i>
							<i class="unitIcon unitSmall gaul unitType10 needed" unit-icon="" ng-class="icon.className" data="icon.tribeId, icon.unitId" ng-repeat="icon in slot.subStateIcons"></i>
						</span>
					</div>
					<!-- ngIf: slot.state == 'occupied' -->
				</div>
				<div ng-repeat="slot in expensionSlots" class="expansionSlot arrowContainer arrowDirectionTo locked disabled" ng-class="{'active': slot.state == 'active' || slot.state == 'occupied', 'locked disabled': slot.state == 'locked'}">
					<span class="arrowInside">2</span>
					<div class="slotUnlocks arrowOutside fullCentered" ng-if="slot.state == 'locked'">
						<div class="symbol_lock_small_wrapper">
							<i class="symbol_lock_small_flat_black"></i>
						</div>
						<span data="lvl:20"><span>доступно с уровня 20</span></span>
					</div>
					<!-- ngIf: slot.state == 'active' -->
					<!-- ngIf: slot.state == 'occupied' -->
				</div>
				<div class="expansionSlot arrowContainer arrowDirectionTo disabled" ng-if="isResidence">
					<span class="arrowInside">3</span>
					<div class="arrowOutside fullCentered"><span>только во дворце</span></div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import CarouselUnitExpansion from '@/components/elements/CarouselUnitExpansion';
import DisplayResources from '@/components/elements/DisplayResources';
import NpcTraderButton from '@/components/elements/NpcTraderButton';
import RangeSlider from '@/components/elements/RangeSlider';

export default {
	mixins: lib.mixins,
	data: () => ({
		units: [
			{
				type: 10,
				name: 'Поселенец',
				description: 'Поселенцы — отважные жители твоей деревни. После продолжительного обучения они уезжают исследовать чужие земли, чтобы основать деревню в твою честь.',
				disabled: false,
				queue: {
					count: 1,
					pause: 0,
				},
				costs: [
					940,
					540,
					360,
					0,
				],
				value: 1,
				capacity: 3000,
				speed: 5,
				cropConsumption: 1,
				attack: 0,
				defenseInfantry: 80,
				defenseCavalry: 80,
				duration: 4674,
				time: 1723643172,
				existing: 0,
			},
			{
				type: 9,
				name: 'Предводитель',
				description: 'У каждого народа есть свой опытный уважаемый предводитель, который понимает, как убедить население деревни противника оказать ему поддержку.',
				disabled: true,
				queue: {
					count: 0,
					pause: 0,
				},
				costs: [
					30750,
					45400,
					31000,
					0,
				],
				value: 1,
				capacity: 0,
				speed: 5,
				cropConsumption: 4,
				attack: 40,
				defenseInfantry: 50,
				defenseCavalry: 50,
				duration: 18675,
				time: 1723643172,
				existing: 0,
			},
		],
		
		availableControlPoints: 3,
		usedControlPoint: 3,
		
		activeItem: null,
	}),
	computed: {
		allCosts(){
			let unitsCosts = [];
			if(this.activeItem){
				for(let [index, value] of Object.entries(this.activeItem.costs)){
					unitsCosts[index] = value * this.activeItem.value;
				}
			}
			return unitsCosts;
		},
		allConsumption(){
			if(this.activeItem){
				return this.activeItem.cropConsumption * this.activeItem.value;
			}
			return 0;
		},
	},
	methods: {},
	components: {
		CarouselUnitExpansion,
		DisplayResources,
		NpcTraderButton,
		RangeSlider,
	},
}
</script>
<template>
	<div class="modalWrapper questDirectionSelection" style="opacity: 1; position: inherit;">
		<div class="modal small questDirectionSelection">
			<div class="modalContent">
				<div class="functionPanel unselectable" v-if="closeable">
					<a @click="$emit('close')" @mouseover="cancelHover = true" @mouseleave="cancelHover = false" class="clickable">
						<i :class="['closeWindow', {action_cancel_small_flat_black: !cancelHover, action_cancel_small_flat_green: cancelHover}]"></i>
					</a>
				</div>
				<div class="contentHeader unselectable" v-if="contentHeaderText">
					<h2 :class="['msAntiScroll', {drag: draggable}]" :draggable="draggable">
						<span>{{contentHeaderText}}</span>
					</h2>
				</div>
				<div class="questDirectionSelection">
					<div class="map tutorial_mapSelect_illustration">
						<div v-for="(elem, key) in directions" :class="['direction', elem.key]" @mouseover="elem.hover = true" @mouseleave="elem.hover = false" @click="changeDirection(key)">
							<div :class="['sign', {highlight: elem.hover}]" v-if="elem.key == 'random'">
								<i class="symbol_questionMark_small_flat_white"></i>
							</div>
							<div v-else>
								<div class="selection">
									<div class="marker" v-if="elem.clicked || !showAll"></div>
								</div>
								<div class="label" v-if="(elem.clicked || direction == elem.key) && showAll"><span>{{elem.label}}</span></div>
								<div v-if="showAll" :class="['sign', {highlight: elem.hover}]"><span>{{elem.label[0]}}</span></div>
							</div>
						</div>
					</div>
					<div class="mapPlacement">
						<h6 class="contentBoxHeader headerWithArrowEndings">
							<div class="content">
								<span>Решение</span>
							</div>
						</h6>
						<div class="directionChoice">
							<div class="option random">
								<label class="clickInputLabel">
									<div class="clickInputWrapper">
										<input type="radio" name="cardinalDirection" @click="clearDirection(true)" v-model.number="showAll" value="0"><i class="symbol_dot_tiny_illu"></i>
									</div>
									<span>Случайно</span>
								</label>
							</div>
							<div class="option ownChoice">
								<label class="clickInputLabel">
									<div class="clickInputWrapper">
										<input type="radio" name="cardinalDirection" @click="clearDirection()" v-model.number="showAll" value="1"><i class="symbol_dot_tiny_illu"></i>
									</div>
									<span>Выбрать часть света</span>
								</label>
							</div>
						</div>
						<div class="horizontalLine"></div>
						<div class="cardinalText" v-if="!showAll">
							<strong><span>Ты начнешь игру в области с наибольшим количеством свободных клеток.</span></strong>
							<p>
								<span>Когда ты поставишь дополнительные деревни, ты сможешь свободно выбрать местоположение.</span><br>
								<span>Расположение не влияет ни на что. Так что если ты не слишком уверен — не волнуйся, здесь не может быть неправильного выбора.</span>
							</p>
						</div>
						<div class="cardinalText" v-if="showAll">
							<strong><span>Выбери место, где ты хочешь начать игру.</span></strong>
							<p><span>Нажми на карту, чтобы выбрать место, где ты хочешь основать свою первую деревню. Ты можешь основывать остальные деревни в любом месте на карте.</span></p>
						</div>
					</div>
					<div class="buttonFooter">
						<button clickable="acceptDirection();" ng-class="['clickable', {disabled: direction == null}]">
							<div class="content">
								<span class="innerText">
									<span>Основать деревню</span>
								</span>
								<div class="animationOverlay"></div>
								<i class="checkmark action_check_small_flat_white"></i>
								<i class="checkmark action_check_small_flat_black"></i>
								<i class="checkmark action_check_small_flat_red"></i>
							</div>
						</button>
					</div>
					<div class="error" v-if="chooseDirectionError">
						<i class="symbol_warning_tiny_flat_red"></i><span>{{chooseDirectionError}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	
	props: {
		namespace: [Array, Object],
		closeable: {
			type: Boolean,
			default: true,
		},
		draggable: {
			type: Boolean,
			default: true,
		},
		contentHeaderText: [String, Number],
	},
	data: () => ({
		cancelHover: false,
		
		showAll: 1,
		directions: [
			{
				key: 'north',
				label: 'Север',
				clicked: false,
				hover: false,
			},
			{
				key: 'east',
				label: 'Восток',
				clicked: false,
				hover: false,
			},
			{
				key: 'west',
				label: 'Запад',
				clicked: false,
				hover: false,
			},
			{
				key: 'south',
				label: 'Юг',
				clicked: false,
				hover: false,
			},
			{
				key: 'random',
				label: 'Случайный выбор',
				clicked: false,
				hover: false,
			},
		],
		direction: '',
		
		chooseDirectionError: '',
	}),
	computed: {},
	methods: {
		changeDirection(key = null){
			if(this.showAll){
				if(this.directions[key].key == 'random'){
					do {
						key = lib.mt_rand(0, this.directions.length - 1);
					} while(key == this.direction);
				}
				for(let elem of this.directions){
					elem.clicked = false;
				}
				this.directions[key].clicked = true;
				this.direction = this.directions[key].key;
			} else {
				for(let elem of this.directions){
					elem.clicked = false;
				}
				this.direction = 'random';
			}
		},
		clearDirection(random = false){
			for(let elem of this.directions){
				elem.clicked = false;
			}
			this.direction = random ? 'random' : '';
		},
		
		onModalOpen(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_20.ogg');
				audio.play();
			}
		},
		onModalClose(){
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_7.ogg');
				audio.play();
			}
		},
	},
	mounted(){
		this.onModalOpen();
	},
	beforeDestroy(){
		this.onModalClose();
	},
	components: {},
}
</script>
<template>
	<div class="buildingRallypointOverview troopsTable scrollable">
		<div class="scrollContentOuterWrapper">
			<div class="scrollContent" style="overflow-y: auto;">
				<div class="scrollContentInnerWrapper" style="width: auto;">
					<div class="filterBar">
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Outgoing.attack == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Attack" clickable="filterTroops('attack', 'Outgoing')">
							<i class="movement_attack_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Outgoing.raid == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Raid" clickable="filterTroops('raid', 'Outgoing')">
							<i class="movement_raid_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Outgoing.spy == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Spy" clickable="filterTroops('spy', 'Outgoing')">
							<i class="movement_spy_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Outgoing.support == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Support" clickable="filterTroops('support', 'Outgoing')">
							<i class="movement_support_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Outgoing.adventure == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Adventure" clickable="filterTroops('adventure', 'Outgoing')">
							<i class="movement_adventure_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Outgoing.settle == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Settle" clickable="filterTroops('settle', 'Outgoing')">
							<i class="movement_settle_small_flat_black"></i>
						</a>
						<a class="filter iconButton clickable" ng-class="{active: troopsFilter.Outgoing.trade == 1}" tooltip="" tooltip-translate="RallyPoint.Overview.Trade" clickable="filterTroops('trade', 'Outgoing')">
							<i class="movement_trade_small_flat_black"></i>
						</a>
					</div>
					<h4 ng-if="troopsOverview.troopsCount.Outgoing == 0">
						<span>Нет исходящих войск</span>
					</h4>
					<h4 ng-if="troopsOverview.troopsCount.Outgoing > 0" translate="" data="count:1"><span>Исходящие войска: (4)</span></h4>
					
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Outgoing">
						<troop-details-rallypoint troop-details="troopDetails" abort="abortTroopMovement(troopId);" class="movingTroops">
							<div class="troopsDetailContainer">
								<div class="troopsDetailHeader" ng-if="!showInputFields">
									<!-- ngIf: status == 'send' -->
									<!-- ngIf: isGroup -->
									<div class="troopsTitle" ng-if="status != 'send' && !isGroup">
										<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
										<!-- ngIf: status == 'nature' -->
										<!-- ngIf: status == 'support' -->
										<!-- ngIf: status == 'oasisSupport' -->
										<!-- ngIf: status == 'ownSupport' -->
										<!-- ngIf: status == 'awaySupport' -->
										<!-- ngIf: status == 'homeTrap' -->
										<!-- ngIf: status == 'awayTrap' -->
										<!-- ngIf: status == 'incoming' -->
										<div ng-if="status == 'outgoing'">
											<i class="movement_attack_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_attacks" tooltip-placement="above"></i>
											<span translate="" ng-if="!settle" data="villageId: -2983, villageName: , villageNameTarget: Логово разбойников I, playerId: -1, playerName: " options="3"><span>Атака на </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': -2983}, null, true)" villageid="-2983" villagename="" nolink="" showmissinglink="true">Логово разбойников I</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="-1" playername="Разбойник" nolink="" showmissinglink="true">Разбойник</a></span>
											<!-- ngIf: settle -->
										</div>
									</div>
									<div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
										<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
										<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
											<!-- ngIf: troopDetails.movement.merchants > 0 -->
											<span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1708174777"><span>через </span><span countdown="1708174777" callback="">00:18:37</span><span> в </span><span i18ndt="1708174777" format="mediumTime">18:59:37</span></span>
										</div>
										<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
										<!-- ngIf: status == 'incoming' -->
									</div>
								</div>
								
								<div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false">
									<table class="troopsTable gaul">
										<tbody class="troopsIconRow">
											<tr>
												<td ng-repeat="n in []|range:1:11" class="unit1">
													<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit2">
													<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit3">
													<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit4">
													<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit5">
													<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit6">
													<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit7">
													<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit8">
													<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit9">
													<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit10">
													<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="hero">
													<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
											</tr>
										</tbody>
										<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
											<!-- ngIf: troopsType == 'trapped' -->
											<tr>
												<td ng-repeat="n in [] | range:1:11" class="unit1">
													
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
														<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;15&#x202C;&#x202C;&#x202C;&#x202C;</span>
														<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
													</div>
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit2">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit3">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit4">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit5">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit6">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit7">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit8">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit9">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit10">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="hero">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
											</tr>
										</tbody>
										<!-- ngIf: troopData.supply -->
										<!-- ngIf: troopData.cagedAnimals -->
									</table>
								</div>
								
								<!-- ngIf: showCatapults || showSpy || showBounty -->
								<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
							</div>
						</troop-details-rallypoint>
					</div>
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Outgoing">
		<troop-details-rallypoint troop-details="troopDetails" abort="abortTroopMovement(troopId);" class="movingTroops"><div class="troopsDetailContainer">
	<!-- ngIf: !showInputFields --><div class="troopsDetailHeader" ng-if="!showInputFields">
		<!-- ngIf: status == 'send' -->
		<!-- ngIf: isGroup -->
		<!-- ngIf: status != 'send' && !isGroup --><div class="troopsTitle" ng-if="status != 'send' && !isGroup">
			<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
			<!-- ngIf: status == 'nature' -->
			<!-- ngIf: status == 'support' -->
			<!-- ngIf: status == 'oasisSupport' -->
			<!-- ngIf: status == 'ownSupport' -->
			<!-- ngIf: status == 'awaySupport' -->
			<!-- ngIf: status == 'homeTrap' -->
			<!-- ngIf: status == 'awayTrap' -->
			<!-- ngIf: status == 'incoming' -->
			<!-- ngIf: status == 'outgoing' --><div ng-if="status == 'outgoing'">
				<i class="movement_settle_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_settlement" tooltip-placement="above"></i>
				<!-- ngIf: !settle -->
				<!-- ngIf: settle --><span translate="" ng-if="settle" data="villageId: 536297461, villageName: Покинутая долина (-11|-18)" options="10"><span>на </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536297461}, null, true)" villageid="536297461" villagename="Покинутая долина (-11|-18)" nolink="" showmissinglink="true">Покинутая долина (-11|-18)</a></span><!-- end ngIf: settle -->
			</div><!-- end ngIf: status == 'outgoing' -->
		</div><!-- end ngIf: status != 'send' && !isGroup -->
		<!-- ngIf: status != 'send' --><div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
			<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
			<!-- ngIf: troopDetails.status == 'transit' --><div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
				<!-- ngIf: troopDetails.movement.merchants > 0 -->
				<!-- ngIf: troopDetails.movement.merchants == 0 --><span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1725010190"><span>через </span><span countdown="1725010190" callback="">00:11:22</span><span> в </span><span i18ndt="1725010190" format="mediumTime">14:29:50</span></span><!-- end ngIf: troopDetails.movement.merchants == 0 -->
			</div><!-- end ngIf: troopDetails.status == 'transit' -->
			<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
			<!-- ngIf: status == 'incoming' -->
		</div><!-- end ngIf: status != 'send' -->
	</div><!-- end ngIf: !showInputFields -->

	<!-- ngIf: showUnits --><div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1 inactive" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;3&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div><!-- end ngIf: showUnits -->

	<!-- ngIf: showCatapults || showSpy || showBounty -->
	<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure --><div class="troopActions" ng-if="isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure">
		<!-- ngIf: isGroup -->
		<!-- ngIf: showAction && !isGroup -->
		<!-- ngIf: (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure --><div ng-if="(showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure">
			<!-- ngIf: !isAdventure --><span translate="" data="time: 1725009560" ng-if="!isAdventure"><span>Время до отмены: </span><span countdown="1725009560" callback="">00:00:52</span></span><!-- end ngIf: !isAdventure -->
			<button class="abortTroopMovementButton cancel clickable" clickable="abortMovement();" play-on-click="15">Отмена</button>
		</div><!-- end ngIf: (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
	</div><!-- end ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
</div></troop-details-rallypoint>
	</div>
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Outgoing">
		<troop-details-rallypoint troop-details="troopDetails" abort="abortTroopMovement(troopId);" class="movingTroops"><div class="troopsDetailContainer">
	<!-- ngIf: !showInputFields --><div class="troopsDetailHeader" ng-if="!showInputFields">
		<!-- ngIf: status == 'send' -->
		<!-- ngIf: isGroup -->
		<!-- ngIf: status != 'send' && !isGroup --><div class="troopsTitle" ng-if="status != 'send' && !isGroup">
			<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
			<!-- ngIf: status == 'nature' -->
			<!-- ngIf: status == 'support' -->
			<!-- ngIf: status == 'oasisSupport' -->
			<!-- ngIf: status == 'ownSupport' -->
			<!-- ngIf: status == 'awaySupport' -->
			<!-- ngIf: status == 'homeTrap' -->
			<!-- ngIf: status == 'awayTrap' -->
			<!-- ngIf: status == 'incoming' -->
			<!-- ngIf: status == 'outgoing' --><div ng-if="status == 'outgoing'">
				<i class="movement_spy_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_attacks" tooltip-placement="above"></i>
				<!-- ngIf: !settle --><span translate="" ng-if="!settle" data="villageId: 536297463, villageName: ,
							   villageNameTarget: Москва Сити,
							   playerId: 901, playerName: " options="6"><span>на </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536297463}, null, true)" villageid="536297463" villagename="" nolink="" showmissinglink="true">Москва Сити</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="901" playername="Anyron" nolink="" showmissinglink="true">Anyron</a></span><!-- end ngIf: !settle -->
				<!-- ngIf: settle -->
			</div><!-- end ngIf: status == 'outgoing' -->
		</div><!-- end ngIf: status != 'send' && !isGroup -->
		<!-- ngIf: status != 'send' --><div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
			<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
			<!-- ngIf: troopDetails.status == 'transit' --><div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
				<!-- ngIf: troopDetails.movement.merchants > 0 -->
				<!-- ngIf: troopDetails.movement.merchants == 0 --><span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1723801258"><span>через </span><span countdown="1723801258" callback="">00:00:00</span><span> в </span><span i18ndt="1723801258" format="mediumTime">14:40:58</span></span><!-- end ngIf: troopDetails.movement.merchants == 0 -->
			</div><!-- end ngIf: troopDetails.status == 'transit' -->
			<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
			<!-- ngIf: status == 'incoming' -->
		</div><!-- end ngIf: status != 'send' -->
	</div><!-- end ngIf: !showInputFields -->

	<!-- ngIf: showUnits --><div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1 inactive" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;10&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div><!-- end ngIf: showUnits -->

	<!-- ngIf: showCatapults || showSpy || showBounty --><div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
		<!-- ngIf: showCatapults -->
		<!-- ngIf: showSpy --><div ng-if="showSpy">
			<span options="1" translate=""><span>Разведать ресурсы и войска</span></span>
		</div><!-- end ngIf: showSpy -->
		<!-- ngIf: showBounty -->
	</div><!-- end ngIf: showCatapults || showSpy || showBounty -->
	<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
</div></troop-details-rallypoint>
	</div>
	<div ng-repeat="troopDetails in troopsOverview.displayTroops.Outgoing">
		<troop-details-rallypoint troop-details="troopDetails" abort="abortTroopMovement(troopId);" class="movingTroops"><div class="troopsDetailContainer">
	<!-- ngIf: !showInputFields --><div class="troopsDetailHeader" ng-if="!showInputFields">
		<!-- ngIf: status == 'send' -->
		<!-- ngIf: isGroup -->
		<!-- ngIf: status != 'send' && !isGroup --><div class="troopsTitle" ng-if="status != 'send' && !isGroup">
			<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
			<!-- ngIf: status == 'nature' -->
			<!-- ngIf: status == 'support' -->
			<!-- ngIf: status == 'oasisSupport' -->
			<!-- ngIf: status == 'ownSupport' -->
			<!-- ngIf: status == 'awaySupport' -->
			<!-- ngIf: status == 'homeTrap' -->
			<!-- ngIf: status == 'awayTrap' -->
			<!-- ngIf: status == 'incoming' -->
			<!-- ngIf: status == 'outgoing' --><div ng-if="status == 'outgoing'">
				<i class="movement_spy_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_attacks" tooltip-placement="above"></i>
				<!-- ngIf: !settle --><span translate="" ng-if="!settle" data="villageId: 536297463, villageName: ,
							   villageNameTarget: Москва Сити,
							   playerId: 901, playerName: " options="6"><span>на </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536297463}, null, true)" villageid="536297463" villagename="" nolink="" showmissinglink="true">Москва Сити</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="901" playername="Anyron" nolink="" showmissinglink="true">Anyron</a></span><!-- end ngIf: !settle -->
				<!-- ngIf: settle -->
			</div><!-- end ngIf: status == 'outgoing' -->
		</div><!-- end ngIf: status != 'send' && !isGroup -->
		<!-- ngIf: status != 'send' --><div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
			<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
			<!-- ngIf: troopDetails.status == 'transit' --><div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
				<!-- ngIf: troopDetails.movement.merchants > 0 -->
				<!-- ngIf: troopDetails.movement.merchants == 0 --><span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1723801269"><span>через </span><span countdown="1723801269" callback="">00:00:02</span><span> в </span><span i18ndt="1723801269" format="mediumTime">14:41:09</span></span><!-- end ngIf: troopDetails.movement.merchants == 0 -->
			</div><!-- end ngIf: troopDetails.status == 'transit' -->
			<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
			<!-- ngIf: status == 'incoming' -->
		</div><!-- end ngIf: status != 'send' -->
	</div><!-- end ngIf: !showInputFields -->

	<!-- ngIf: showUnits --><div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1 inactive" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;10&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div><!-- end ngIf: showUnits -->

	<!-- ngIf: showCatapults || showSpy || showBounty --><div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
		<!-- ngIf: showCatapults -->
		<!-- ngIf: showSpy --><div ng-if="showSpy">
			<span options="2" translate=""><span>Разведать войска и оборонительные сооружения</span></span>
		</div><!-- end ngIf: showSpy -->
		<!-- ngIf: showBounty -->
	</div><!-- end ngIf: showCatapults || showSpy || showBounty -->
	<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
</div></troop-details-rallypoint>
	</div>
	<div ng-repeat="troopDetails in troopsOverview.displayTroops.Outgoing">
		<troop-details-rallypoint troop-details="troopDetails" abort="abortTroopMovement(troopId);" class="movingTroops"><div class="troopsDetailContainer">
	<!-- ngIf: !showInputFields --><div class="troopsDetailHeader" ng-if="!showInputFields">
		<!-- ngIf: status == 'send' -->
		<!-- ngIf: isGroup -->
		<!-- ngIf: status != 'send' && !isGroup --><div class="troopsTitle" ng-if="status != 'send' && !isGroup">
			<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
			<!-- ngIf: status == 'nature' -->
			<!-- ngIf: status == 'support' -->
			<!-- ngIf: status == 'oasisSupport' -->
			<!-- ngIf: status == 'ownSupport' -->
			<!-- ngIf: status == 'awaySupport' -->
			<!-- ngIf: status == 'homeTrap' -->
			<!-- ngIf: status == 'awayTrap' -->
			<!-- ngIf: status == 'incoming' -->
			<!-- ngIf: status == 'outgoing' --><div ng-if="status == 'outgoing'">
				<i class="movement_attack_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_attacks" tooltip-placement="above"></i>
				<!-- ngIf: !settle --><span translate="" ng-if="!settle" data="villageId: -1375, villageName: ,
							   villageNameTarget: Логово разбойников I,
							   playerId: -1, playerName: " options="47"><span>Осада на </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': -1375}, null, true)" villageid="-1375" villagename="" nolink="" showmissinglink="true">Логово разбойников I</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="-1" playername="Разбойник" nolink="" showmissinglink="true">Разбойник</a></span><!-- end ngIf: !settle -->
				<!-- ngIf: settle -->
			</div><!-- end ngIf: status == 'outgoing' -->
		</div><!-- end ngIf: status != 'send' && !isGroup -->
		<!-- ngIf: status != 'send' --><div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
			<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
			<!-- ngIf: troopDetails.status == 'transit' --><div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
				<!-- ngIf: troopDetails.movement.merchants > 0 -->
				<!-- ngIf: troopDetails.movement.merchants == 0 --><span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1723806489"><span>через </span><span countdown="1723806489" callback="">01:26:57</span><span> в </span><span i18ndt="1723806489" format="mediumTime">16:08:09</span></span><!-- end ngIf: troopDetails.movement.merchants == 0 -->
			</div><!-- end ngIf: troopDetails.status == 'transit' -->
			<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
			<!-- ngIf: status == 'incoming' -->
		</div><!-- end ngIf: status != 'send' -->
	</div><!-- end ngIf: !showInputFields -->

	<!-- ngIf: showUnits --><div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false"><table class="troopsTable gaul">
	<tbody class="troopsIconRow">
		<tr>
			<!-- ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit1">
				<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit2">
				<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit3">
				<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit4">
				<i class="unitSmall gaul unitType4" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit5">
				<i class="unitSmall gaul unitType5" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit6">
				<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit7">
				<i class="unitSmall gaul unitType7" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit8">
				<i class="unitSmall gaul unitType8" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit9">
				<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="unit10">
				<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 --><td ng-repeat="n in []|range:1:11" class="hero">
				<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
								   				|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
								   	  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
				<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
				<!-- ngIf: troopData['originalTroops'] || !troopData['inputTroops'] --><div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div><!-- end ngIf: troopData['originalTroops'] || !troopData['inputTroops'] -->
			</td><!-- end ngRepeat: n in []|range:1:11 -->
		</tr>
	</tbody>
	<!-- ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
		<!-- ngIf: troopsType == 'trapped' -->
		<tr>
			<!-- ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit1">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;984&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit2">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;211&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit3">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit4">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;47&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit5">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;155&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit6">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit7">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;7&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit8">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;3&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit9">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="unit10">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) --><span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span><!-- end ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 --><td ng-repeat="n in [] | range:1:11" class="hero">
				<!-- ngIf: troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0) -->
				<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

				<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 --><div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
					<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
					<!-- ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) --><span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span><!-- end ngIf: (troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss']) -->
					<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
				</div><!-- end ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
				<!-- ngIf: troopsType == 'input' -->
			</td><!-- end ngRepeat: n in [] | range:1:11 -->
		</tr>
	</tbody><!-- end ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] --><!-- ngIf: troopData[troopsType+'Troops'] --><!-- end ngRepeat: troopsType in ['original', 'input', 'lost', 'trapped'] -->
	<!-- ngIf: troopData.supply -->
	<!-- ngIf: troopData.cagedAnimals -->
</table></div><!-- end ngIf: showUnits -->

	<!-- ngIf: showCatapults || showSpy || showBounty --><div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
		<!-- ngIf: showCatapults --><div ng-if="showCatapults">
			<!-- ngIf: troopDetails.movement.catapultTarget2 > 0 -->
			<!-- ngIf: troopDetails.movement.catapultTarget2 == 0 --><span ng-if="troopDetails.movement.catapultTarget2 == 0">
				<span translate=""><span>Цель:</span></span>
				<span options="99" translate=""><span>Случайно</span></span>
			</span><!-- end ngIf: troopDetails.movement.catapultTarget2 == 0 -->
		</div><!-- end ngIf: showCatapults -->
		<!-- ngIf: showSpy -->
		<!-- ngIf: showBounty -->
	</div><!-- end ngIf: showCatapults || showSpy || showBounty -->
	<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
</div></troop-details-rallypoint>
	</div>
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Outgoing">
						<troop-details-rallypoint troop-details="troopDetails" abort="abortTroopMovement(troopId);" class="movingTroops">
							<div class="troopsDetailContainer">
								<div class="troopsDetailHeader" ng-if="!showInputFields">
									<!-- ngIf: status == 'send' -->
									<!-- ngIf: isGroup -->
									<div class="troopsTitle" ng-if="status != 'send' && !isGroup">
										<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
										<!-- ngIf: status == 'nature' -->
										<!-- ngIf: status == 'support' -->
										<!-- ngIf: status == 'oasisSupport' -->
										<!-- ngIf: status == 'ownSupport' -->
										<!-- ngIf: status == 'awaySupport' -->
										<!-- ngIf: status == 'homeTrap' -->
										<!-- ngIf: status == 'awayTrap' -->
										<!-- ngIf: status == 'incoming' -->
										<div ng-if="status == 'outgoing'">
											<i class="movement_attack_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_attacks" tooltip-placement="above"></i>
											<span translate="" ng-if="!settle" data="villageId: -2984, villageName: , villageNameTarget: Логово разбойников II, playerId: -1, playerName: " options="3"><span>Атака на </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': -2984}, null, true)" villageid="-2984" villagename="" nolink="" showmissinglink="true">Логово разбойников II</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="-1" playername="Разбойник" nolink="" showmissinglink="true">Разбойник</a></span>
											<!-- ngIf: settle -->
										</div>
									</div>
									<div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
										<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
										<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
											<!-- ngIf: troopDetails.movement.merchants > 0 -->
											<span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1708175169"><span>через </span><span countdown="1708175169" callback="">00:24:56</span><span> в </span><span i18ndt="1708175169" format="mediumTime">19:06:09</span></span>
										</div>
										<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
										<!-- ngIf: status == 'incoming' -->
									</div>
								</div>
								
								<div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false">
									<table class="troopsTable gaul">
										<tbody class="troopsIconRow">
											<tr>
												<td ng-repeat="n in []|range:1:11" class="unit1">
													<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit2">
													<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit3">
													<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit4">
													<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit5">
													<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit6">
													<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit7">
													<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit8">
													<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit9">
													<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit10">
													<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="hero">
													<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
											</tr>
										</tbody>
										<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
											<!-- ngIf: troopsType == 'trapped' -->
											<tr>
												<td ng-repeat="n in [] | range:1:11" class="unit1">
													
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
														<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;24&#x202C;&#x202C;&#x202C;&#x202C;</span>
														<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
													</div>
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit2">
													
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
														<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;4&#x202C;&#x202C;&#x202C;&#x202C;</span>
														<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
													</div>
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit3">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit4">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit5">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit6">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit7">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit8">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit9">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit10">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="hero">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
											</tr>
										</tbody>
										<!-- ngIf: troopData.supply -->
										<!-- ngIf: troopData.cagedAnimals -->
									</table>
								</div>
								
								<!-- ngIf: showCatapults || showSpy || showBounty -->
								<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
							</div>
						</troop-details-rallypoint>
					</div>
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Outgoing">
						<troop-details-rallypoint troop-details="troopDetails" abort="abortTroopMovement(troopId);" class="movingTroops">
							<div class="troopsDetailContainer">
								<div class="troopsDetailHeader" ng-if="!showInputFields">
									<!-- ngIf: status == 'send' -->
									<!-- ngIf: isGroup -->
									<div class="troopsTitle" ng-if="status != 'send' && !isGroup">
										<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
										<!-- ngIf: status == 'nature' -->
										<!-- ngIf: status == 'support' -->
										<!-- ngIf: status == 'oasisSupport' -->
										<!-- ngIf: status == 'ownSupport' -->
										<!-- ngIf: status == 'awaySupport' -->
										<!-- ngIf: status == 'homeTrap' -->
										<!-- ngIf: status == 'awayTrap' -->
										<!-- ngIf: status == 'incoming' -->
										<div ng-if="status == 'outgoing'">
											<i class="movement_adventure_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_adventure" tooltip-placement="above"></i>
											<span translate="" ng-if="!settle" data="villageId: 0, villageName: , villageNameTarget: Приключение, playerId: 1804, playerName: " options="20"><span>Приключение</span></span>
											<!-- ngIf: settle -->
										</div>
									</div>
									<div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
										<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
										<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
											<!-- ngIf: troopDetails.movement.merchants > 0 -->
											<span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1708176002"><span>через </span><span countdown="1708176002" callback="">00:38:33</span><span> в </span><span i18ndt="1708176002" format="mediumTime">19:20:02</span></span>
										</div>
										<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
										<!-- ngIf: status == 'incoming' -->
									</div>
								</div>
								
								<div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false">
									<table class="troopsTable gaul">
										<tbody class="troopsIconRow">
											<tr>
												<td ng-repeat="n in []|range:1:11" class="unit1">
													<i class="unitSmall gaul unitType1 inactive" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit2">
													<i class="unitSmall gaul unitType2 inactive" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit3">
													<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit4">
													<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit5">
													<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit6">
													<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit7">
													<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit8">
													<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit9">
													<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit10">
													<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="hero">
													<i class="unitSmall hero_illu" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n])
																					|| (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)),
																		  clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<!-- ngIf: troopData.unitBonuses[n+(troopData.tribeId-1)*10] -->
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
											</tr>
										</tbody>
										<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
											<!-- ngIf: troopsType == 'trapped' -->
											<tr>
												<td ng-repeat="n in [] | range:1:11" class="unit1">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit2">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit3">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit4">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit5">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit6">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit7">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit8">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit9">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit10">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<!-- ngIf: troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1 -->
													<!-- ngIf: troopsType == 'input' -->
												</td>
												<td ng-repeat="n in [] | range:1:11" class="hero">
													
													<!-- ngIf: troopsType == 'original' && troopData[troopsType+'Troops'][n] < 0 -->

													<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
														<!-- ngIf: troopsType == 'original' && troopData['inputTroops'] -->
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;1&#x202C;&#x202C;&#x202C;&#x202C;</span>
														<!-- ngIf: troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'] -->
													</div>
													<!-- ngIf: troopsType == 'input' -->
												</td><!-- end ngRepeat: n in [] | range:1:11 -->
											</tr>
										</tbody>
										<!-- ngIf: troopData.supply -->
										<!-- ngIf: troopData.cagedAnimals -->
									</table>
								</div>
								
								<!-- ngIf: showCatapults || showSpy || showBounty -->
								<div class="troopActions" ng-if="isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure">
									<!-- ngIf: isGroup -->
									<!-- ngIf: showAction && !isGroup -->
									<div ng-if="(showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure">
										<!-- ngIf: !isAdventure -->
										<button class="abortTroopMovementButton cancel clickable" clickable="abortMovement();" play-on-click="15">
											<div class="content">
												<span ng-transclude="" class="innerText">
													<span translate=""><span>Отмена</span></span>
												</span>
												<!-- ngIf: price > 0 || forceGoldUsage -->
												<!-- ngIf: price == -1 && !forceGoldUsage -->
												<div class="animationOverlay"></div>
												<i class="checkmark action_check_small_flat_white"></i>
												<i class="checkmark action_check_small_flat_black"></i>
												<i class="checkmark action_check_small_flat_red"></i>
											</div>
										</button>
									</div>
								</div>
							</div>
						</troop-details-rallypoint>
					</div>
					
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Outgoing">
						<troop-details-rallypoint troop-details="troopDetails" abort="abortTroopMovement(troopId);" class="movingTroops">
							<div class="troopsDetailContainer">
								<div class="troopsDetailHeader" ng-if="!showInputFields">
									<div class="troopsTitle" ng-if="status != 'send' && !isGroup">
										<div ng-if="status == 'outgoing'">
											<i class="movement_attack_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_attacks" tooltip-placement="above"></i>
											<span translate="" ng-if="!settle" data="villageId: -2983, villageName: , villageNameTarget: Логово разбойников I, playerId: -1, playerName: " options="3"><span>Атака на </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': -2983}, null, true)" villageid="-2983" villagename="" nolink="" showmissinglink="true">Логово разбойников I</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="-1" playername="Разбойник" nolink="" showmissinglink="true">Разбойник</a></span>
										</div>
									</div>
									<div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
										<div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
											<span translate="" ng-if="troopDetails.movement.merchants == 0" class="countdownTo" data="timeFinish: 1707724772"><span>через </span><span countdown="1707724772" callback="">00:18:59</span><span> в </span><span i18ndt="1707724772" format="mediumTime">13:59:32</span></span>
										</div>
									</div>
								</div>
								<div troops-details="" ng-if="showUnits" troop-data="troopDetails" render-lazy="false">
									<table class="troopsTable gaul">
										<tbody class="troopsIconRow">
											<tr>
												<td ng-repeat="n in []|range:1:11" class="unit1">
													<i class="unitSmall gaul unitType1" unit-icon="" data="21" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit2">
													<i class="unitSmall gaul unitType2" unit-icon="" data="22" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit3">
													<i class="unitSmall gaul unitType3 inactive" unit-icon="" data="23" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit4">
													<i class="unitSmall gaul unitType4 inactive" unit-icon="" data="24" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit5">
													<i class="unitSmall gaul unitType5 inactive" unit-icon="" data="25" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit6">
													<i class="unitSmall gaul unitType6 inactive" unit-icon="" data="26" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit7">
													<i class="unitSmall gaul unitType7 inactive" unit-icon="" data="27" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit8">
													<i class="unitSmall gaul unitType8 inactive" unit-icon="" data="28" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit9">
													<i class="unitSmall gaul unitType9 inactive" unit-icon="" data="29" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="unit10">
													<i class="unitSmall gaul unitType10 inactive" unit-icon="" data="30" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
												<td ng-repeat="n in []|range:1:11" class="hero">
													<i class="unitSmall hero_illu inactive" unit-icon="" data="hero" ng-init="group=troopData; unitId=n" clickable="" ng-class="{inactive: (troopData['originalTroops'] && !troopData['originalTroops'][n]) || (!troopData['originalTroops'] && (!troopData['inputTroops'][n] || troopData['inputTroops'][n] <= 0)), clickable: unitIconCallback}" tooltip="" tooltip-placement="above" tooltip-url="tpl/mainlayout/partials/troopTypeTooltip.html"></i>
													<div class="horizontalLine" ng-if="troopData['originalTroops'] || !troopData['inputTroops']"></div>
												</td>
											</tr>
										</tbody>
										<tbody ng-if="troopData[troopsType+'Troops']" class="originalTroops" ng-repeat="troopsType in ['original', 'input', 'lost', 'trapped']">
											<tr>
												<td ng-repeat="n in [] | range:1:11" class="unit1">
													<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;5&#x202C;&#x202C;&#x202C;&#x202C;</span>
													</div>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit2">
													<div ng-if="troopsType != 'input' && troopData[troopsType+'Troops'][n] >= 1">
														<span ng-if="(troopsType != 'original' || !troopData['inputTroops']) && !(troopsType == 'lost' && n == Troops.TYPE_HERO && troopData['heroHealthLoss'])">&#x202D;&#x202D;&#x202D;&#x202D;4&#x202C;&#x202C;&#x202C;&#x202C;</span>
													</div>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit3">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit4">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit5">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit6">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit7">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit8">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit9">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="unit10">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
												<td ng-repeat="n in [] | range:1:11" class="hero">
													<span ng-if="troopsType == 'original' && (!troopData[troopsType+'Troops'][n] || troopData[troopsType+'Troops'][n] == 0)">-</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="troopActions" ng-if="isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure">
									<div ng-if="(showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure">
										<span translate="" data="time: 1707723662" ng-if="!isAdventure"><span>Время до отмены: </span><span countdown="1707723662" callback="">00:00:29</span></span>
										<button class="abortTroopMovementButton cancel clickable" clickable="abortMovement();" play-on-click="15">
											<div class="content">
												<span ng-transclude="" class="innerText">
													<span translate=""><span>Отмена</span></span>
												</span>
												<div class="animationOverlay"></div>
												<i class="checkmark action_check_small_flat_white"></i>
												<i class="checkmark action_check_small_flat_black"></i>
												<i class="checkmark action_check_small_flat_red"></i>
											</div>
										</button>
									</div>
								</div>
							</div>
						</troop-details-rallypoint>
					</div>
					<div ng-repeat="troopDetails in troopsOverview.displayTroops.Outgoing">
		<troop-details-rallypoint troop-details="troopDetails" abort="abortTroopMovement(troopId);" class="movingTroops"><div class="troopsDetailContainer">
	<!-- ngIf: !showInputFields --><div class="troopsDetailHeader" ng-if="!showInputFields">
		<!-- ngIf: status == 'send' -->
		<!-- ngIf: isGroup -->
		<!-- ngIf: status != 'send' && !isGroup --><div class="troopsTitle" ng-if="status != 'send' && !isGroup">
			<!-- ngIf: status == 'home' && troopDetails.tribeId != tribes.NATURE -->
			<!-- ngIf: status == 'nature' -->
			<!-- ngIf: status == 'support' -->
			<!-- ngIf: status == 'oasisSupport' -->
			<!-- ngIf: status == 'ownSupport' -->
			<!-- ngIf: status == 'awaySupport' -->
			<!-- ngIf: status == 'homeTrap' -->
			<!-- ngIf: status == 'awayTrap' -->
			<!-- ngIf: status == 'incoming' -->
			<!-- ngIf: status == 'outgoing' --><div ng-if="status == 'outgoing'">
				<i class="movement_trade_small_flat_black" tooltip="" tooltip-translate="TroopMovementInfo_outgoing_merchant" tooltip-placement="above"></i>
				<!-- ngIf: !settle --><span translate="" ng-if="!settle" data="villageId: 536264691, villageName: ,
							   villageNameTarget: Глушица,
							   playerId: 734, playerName: " options="7"><span>в </span><a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536264691}, null, true)" villageid="536264691" villagename="Глушица" nolink="" showmissinglink="true">Глушица</a><span> игрока </span><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="truncated playerLink  truncated clickable" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" playerid="734" playername="lexinzector" nolink="" showmissinglink="true">lexinzector</a></span><!-- end ngIf: !settle -->
				<!-- ngIf: settle -->
			</div><!-- end ngIf: status == 'outgoing' -->
		</div><!-- end ngIf: status != 'send' && !isGroup -->
		<!-- ngIf: status != 'send' --><div class="troopsInfo outgoingInfo" ng-if="status != 'send'">
			<!-- ngIf: troopDetails.movement.recurrencesTotal > 1 -->
			<!-- ngIf: troopDetails.status == 'transit' --><div ng-if="troopDetails.status == 'transit'" class="countdownContainer">
				<!-- ngIf: troopDetails.movement.merchants > 0 --><span translate="" ng-if="troopDetails.movement.merchants > 0" tooltip="" tooltip-data="timeFinish: 1723401036" tooltip-translate="RallyPoint.Troops.ArrivalTime.Trade.Tooltip" class="countdownTo" data="timeFinish: 1723401036"><span>через </span><span countdown="1723401036" callback="">00:06:35</span></span><!-- end ngIf: troopDetails.movement.merchants > 0 -->
				<!-- ngIf: troopDetails.movement.merchants == 0 -->
			</div><!-- end ngIf: troopDetails.status == 'transit' -->
			<!-- ngIf: (troopDetails.status != 'transit' && troopDetails.status != 'trap' && troopDetails.status != 'send' && troopDetails.tribeId != tribes.NATURE) || (troopDetails.status == 'trap' && troopDetails.playerId == $root.player.data.playerId) -->
			<!-- ngIf: status == 'incoming' -->
		</div><!-- end ngIf: status != 'send' -->
	</div><!-- end ngIf: !showInputFields -->

	<!-- ngIf: showUnits -->

	<!-- ngIf: showCatapults || showSpy || showBounty --><div class="additionalTroopInfos" ng-if="showCatapults || showSpy || showBounty">
		<!-- ngIf: showCatapults -->
		<!-- ngIf: showSpy -->
		<!-- ngIf: showBounty --><div ng-if="showBounty" ng-class="{withInstantDelivery: showInstantDelivery}" class="bounty withInstantDelivery">
			<!-- ngIf: troopDetails.movement.merchants > 1 --><div ng-if="troopDetails.movement.merchants > 1" class="merchantCount">
				<i class="movement_trade_small_flat_black"></i> 12
			</div><!-- end ngIf: troopDetails.movement.merchants > 1 -->
			<!-- ngIf: troopDetails.movement.treasures > 0 && !showStolenGoods -->
			<!-- ngIf: troopDetails.movement.treasures > 0 && showStolenGoods -->
			<!-- ngIf: troopDetails.movement.treasures == 0 --><display-resources ng-if="troopDetails.movement.treasures == 0" resources="troopDetails.movement.resources" old=""><div class="costs max4digits">
	<!-- ngIf: !checkStorage --><span ng-if="!checkStorage">
		<!-- ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource woodValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_1">
			<i class="unit_wood_small_illu resType1"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3000&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource clayValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_2">
			<i class="unit_clay_small_illu resType2"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3000&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource ironValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_3">
			<i class="unit_iron_small_illu resType3"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;3000&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes --><!-- ngIf: resourceVisibility[index] --><span ng-repeat="(index, type) in resTypes" class="resource cropValue" ng-if="resourceVisibility[index]" ng-class="{notEnough: !enoughResources(index), enough: enoughResources(index) && colorPositive}" tooltip="" tooltip-translate="Resource_4">
			<i class="unit_crop_small_illu resType4"></i>
			<!-- ngIf: !onlyProductionPerHour --><span class="resourceValue" ng-if="!onlyProductionPerHour" ng-class="::{strikethrough: showAsOldResources}">&#x202D;&#x202D;0&#x202C;&#x202C;</span><!-- end ngIf: !onlyProductionPerHour -->
			<!-- ngIf: ::numberUnit=='production' -->
			<!-- ngIf: productionPerHour[index] || productionPerHour[index] == 0 -->
		</span><!-- end ngIf: resourceVisibility[index] --><!-- end ngRepeat: (index, type) in resTypes -->
	</span><!-- end ngIf: !checkStorage -->
	<!-- ngIf: checkStorage -->

	<!-- ngIf: (consumption || consumption == 0) && consumption != 'false' -->
	<!-- ngIf: population || population == 0 -->

	<!-- ngIf: treasures || treasures == 0 -->

	<!-- ngIf: stolenGoods || stolenGoods == 0 -->

	<!-- ngIf: time || time == 0 -->

	<!-- ngIf: enoughResSecs == -1 -->
	<!-- ngIf: enoughResSecs > 0 -->
	<!-- ngIf: capacityHint -->

	<div class="iconButton premium npcTrade ng-hide" ng-show="showNpc != undefined"><i class="feature_npcTrader_small_flat_black"></i></div>
</div></display-resources><!-- end ngIf: troopDetails.movement.treasures == 0 -->
			<!-- ngIf: troopDetails.movement.merchants <= 0 && !hideCapacity -->
			<!-- ngIf: showInstantDelivery --><button ng-if="showInstantDelivery" class="instantDelivery free" premium-feature="traderArriveInstantly" premium-callback-param="3562163" price="-1" tooltip=""><div class="content">
	<span ng-transclude="" class="innerText">
				<span translate=""><span>Забрать сейчас</span></span>
			</span>
	<!-- ngIf: price > 0 || forceGoldUsage -->
	<!-- ngIf: price == -1 && !forceGoldUsage --><span class="price voucher" ng-if="price == -1 && !forceGoldUsage"><i class="cardGame_prizePremium_small_illu"></i><span>1</span></span><!-- end ngIf: price == -1 && !forceGoldUsage -->

	<div class="animationOverlay"></div>
	<i class="checkmark action_check_small_flat_white"></i>
	<i class="checkmark action_check_small_flat_black"></i>
	<i class="checkmark action_check_small_flat_red"></i>
</div></button><!-- end ngIf: showInstantDelivery -->
		</div><!-- end ngIf: showBounty -->
	</div><!-- end ngIf: showCatapults || showSpy || showBounty -->
	<!-- ngIf: isGroup || (showAction && !isGroup) || (showAbort && $root.currentServerTime <= abortButtonShowTime) || isAdventure -->
</div></troop-details-rallypoint>
	</div>
					
					
					
				</div>
			</div>
		</div>
		<div pagination="" items-per-page="itemsPerPage" number-of-items="numberOfItems" current-page="currentPage" display-page-func="displayCurrentPage" route-named-param="cp">
			<div ng-transclude=""></div>
			<div class="tg-pagination ng-hide" ng-show="visible">
				<ul>
					<li class="firstPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="firstPage()" on-pointer-over="beginningHover = true" on-pointer-out="beginningHover = false">
						<i ng-class="{
							action_toBeginning_tiny_flat_black: !beginningHover || (beginningHover && currentPage == 1),
							action_toBeginning_tiny_flat_green: beginningHover && currentPage != 1,
							disabled: currentPage == 1
						}" class="action_toBeginning_tiny_flat_black disabled"></i>
					</li>
					<li class="prevPage clickable disabled" ng-class="{disabled: currentPage == 1}" clickable="prevPage()" on-pointer-over="fromHover = true" on-pointer-out="fromHover = false">
						<i ng-class="{
							symbol_arrowFrom_tiny_flat_black: !fromHover || (fromHover && currentPage == 1),
							symbol_arrowFrom_tiny_flat_green: fromHover && currentPage != 1,
							disabled: currentPage == 1
						}" class="symbol_arrowFrom_tiny_flat_black disabled"></i>
					</li>
					<li class="number clickable disabled" ng-repeat="pageNumber in filteredPageNumbers track by $index" ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" clickable="setPage(1)">
						<a ng-class="{disabled: (currentPage == pageNumber || pageNumber == '...')}" class="disabled">1</a>
					</li>
					<li class="nextPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="toHover = true" on-pointer-out="toHover = false" clickable="nextPage()">
						<i ng-class="{
							symbol_arrowTo_tiny_flat_black: !toHover || (toHover && disableNext()),
							symbol_arrowTo_tiny_flat_green: toHover && !disableNext(),
							disabled: disableNext()
						}" class="symbol_arrowTo_tiny_flat_black disabled"></i>
					</li>
					<li class="lastPage clickable disabled" ng-class="{disabled: disableNext()}" on-pointer-over="endHover = true" on-pointer-out="endHover = false" clickable="lastPage()">
						<i ng-class="{
							action_toEnd_tiny_flat_black: !endHover || (endHover && disableNext()),
							action_toEnd_tiny_flat_green: endHover && !disableNext(),
							disabled: disableNext()
						}" class="action_toEnd_tiny_flat_black disabled"></i>
					</li>
				</ul>
			</div>
		</div>
		<div class="error">xxx</div>
		<button class="sendTroops clickable" clickable="openWindow('sendTroops')">
			<div class="content">
				<span ng-transclude="" class="innerText">
					<span translate=""><span>Отправить войска</span></span>
				</span>
				<div class="animationOverlay"></div>
				<i class="checkmark action_check_small_flat_white"></i>
				<i class="checkmark action_check_small_flat_black"></i>
				<i class="checkmark action_check_small_flat_red"></i>
			</div>
		</button>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
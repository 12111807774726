<template>
	<div ng-controller="villagesOverviewTroopsCtrl">
		<div tab-config-name="villageOverviewTabConfig">
			<nav class="tabulation subtab subtab unselectable" ng-if="tabData.tabs.length > 1">
				<a class="tab clickable" :class="{active: tab == 'own-troops', inactive: tab != 'own-troops'}" @click="onToggleTab('own-troops')">
					<div class="content">
						<span>Собственные войска</span>
					</div>
				</a>
				<a class="tab clickable" :class="{active: tab == 'troops-in-villages', inactive: tab != 'troops-in-villages'}" @click="onToggleTab('troops-in-villages')">
					<div class="content">
						<span>Войска в деревнях</span>
					</div>
				</a>
			</nav>
			<div class="tabulationContent scrollable tabContentOwnTroops">
				<div class="scrollContentOuterWrapper" style="width: 610px;">
					<div class="scrollContent" style="width: 610px; overflow-y: hidden; max-height: 494px;">
						<div class="scrollContentInnerWrapper" style="width: auto;">
							<div>
								<div class="loadedTab currentTab" v-if="tab == 'own-troops'">
									<own-troops />
								</div>
								<div class="loadedTab currentTab" v-if="tab == 'troops-in-villages'">
									<troops-in-villages />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import OwnTroops from '@/components/pages/villageOverview/troops/OwnTroops';
import TroopsInVillages from '@/components/pages/villageOverview/troops/TroopsInVillages';

export default {
	mixins: lib.mixins,
	data: () => ({
		tab: 'own-troops',
	}),
	computed: {},
	methods: {
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_10.ogg');
				audio.play();
			}
		},
	},
	components: {
		OwnTroops,
		TroopsInVillages,
	},
}
</script>
<template>
	<div class="heroProperties" ng-controller="attributesCtrl">
		<div class="scrollable">
			<div class="scrollContentOuterWrapper active">
				<div class="scrollContent" style="overflow-y: auto;">
					<div class="scrollContentInnerWrapper" style="width: auto;">
						<div class="contentBox gradient heroReviveBox" v-if="gameModel.player.status == PlayerModel.STATUS_DEAD || gameModel.player.status == PlayerModel.STATUS_REVIVING">
							<div class="contentBoxBody heroRevive">
								<div class="reviveText">
									<span v-if="gameModel.player.status == PlayerModel.STATUS_DEAD">
										<span>Оживить героя в деревне InZectorsk в качестве родной</span>
									</span>
									<span v-if="gameModel.player.status == PlayerModel.STATUS_REVIVING">
										<span>Герой восстанавливается в деревне Inzectorsk</span>
									</span>
								</div>
								<div class="reviveCosts" v-if="gameModel.player.status == PlayerModel.STATUS_DEAD">
									<span>
										<display-resources :resources="[
											144,
											259,
											174,
											0
										]" :enough-res-secs="0" />
										<i class="symbol_clock_small_flat_black duration" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration"></i>&nbsp;00:43:00
									</span>
									<div class="reviveButtonContainer">
										<button clickable="revive()" ng-class="{disabled: !isRevivable()}" class="clickable disabled">
											<div class="content">
												<span class="innerText">
													<span><span>Оживить</span></span>
												</span>
												<!-- ngIf: price > 0 || forceGoldUsage -->
												<!-- ngIf: price == -1 && !forceGoldUsage -->
												<div class="animationOverlay"></div>
												<i class="checkmark action_check_small_flat_white"></i>
												<i class="checkmark action_check_small_flat_black"></i>
												<i class="checkmark action_check_small_flat_red"></i>
											</div>
										</button>
										<npc-trader-button class="npcTraderHeroRevive" type="heroRevive" :costs="{
											wood: 144,
											clay: 259,
											iron: 174,
											crop: 0,
										}" />
									</div>
								</div>
								<div class="revivalDuration" v-if="gameModel.player.status == PlayerModel.STATUS_REVIVING">
									<i class="symbol_clock_small_flat_black duration"></i>
									<span>&nbsp;00:52:04</span>
								</div>
								<!-- ngIf: reviveError -->
							</div>
						</div>
						<table class="transparent heroTable">
							<tbody>
								<tr>
									<td>
										<div class="contentBox gradient heroInfo">
											<h6 class="contentBoxHeader headerWithArrowEndings glorious">
												<div class="content"><span>Герой</span></div>
											</h6>
											<div class="contentBoxBody">
												<div class="contentBox gradient double heroStats">
													<h6 class="contentBoxHeader headerWithIcon arrowDirectionDown">
														<i class="heroLevel">
															<div><span>Ур.</span></div>
															<div>{{gameModel.player.level}}</div>
														</i>
														<i class="headerButton clickable" ng-if="isOwner" :class="{
															'action_edit_small_flat_black': !editHover,
															'action_edit_small_flat_green': editHover
														}" content="Изменить внешность" v-tippy="{placement: 'bottom'}" tooltip-translate="Hero.Attributes.Tooltip.EditHero" clickable="openOverlay('heroEditor')" @mouseover="editHover = true" @mouseleave="editHover = false"></i>
													</h6>
													<div class="contentBoxBody heroImageContainer">
														<div class="avatar-image" scale="0.66" player-id="1804" size="big" dead="false">
															<div class="heroImage male" ng-class="::class" rerender="rerender">
																<img src="//iwstatic.g.bsrv.su/resources/avatars/125/lexinzector.png" width="231" height="231" />
															</div>
														</div>
														<div v-if="gameModel.player.health > 0" class="currentLocation" :class="{
															'atHome': gameModel.player.status == PlayerModel.STATUS_IDLE,
															'moving': gameModel.player.status > PlayerModel.STATUS_IDLE && gameModel.player.status < PlayerModel.STATUS_SUPPORTING,
															'return': gameModel.player.status == PlayerModel.STATUS_RETURNING,
														}">
															<span v-if="gameModel.player.status == PlayerModel.STATUS_IDLE"><span>Дома!</span></span>
															<div v-if="gameModel.player.status == PlayerModel.STATUS_RETURNING" class="heroStatusContainer">
																<div><i class="heroStatus hero_heroStatus1_small_illu"></i></div>
																<div class="statusText" options="1" data="duration: 1708154413, villageId: 537182205, homeVillage: InZectorsk, destVillageId: 537313275, destVillage: Логово разбойников I, playerId: -1, playerName: Разбойник">
																	<span>Возвращается в деревню </span>
																	<a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 537182205}, null, true)" villageid="537182205" villagename="InZectorsk" nolink="" showmissinglink="true">InZectorsk</a>
																</div>
															</div>
															<div v-if="gameModel.player.status == PlayerModel.STATUS_ADVENTURE" class="heroStatusContainer">
																<div><i class="heroStatus hero_heroStatus4_small_illu"></i></div>
																<div class="statusText" options="4" data="duration: 1707838676, villageId: 536133632, homeVillage: InZectorsk, destVillageId: 536100862, destVillage: Логово разбойников II, playerId: -1, playerName: Разбойник">
																	<span>Идет в приключение</span>
																</div>
															</div>
															<div v-if="gameModel.player.status != PlayerModel.STATUS_IDLE" class="heroStatusContainer">
																<div><i class="heroStatus hero_heroStatus4_small_illu"></i></div>
																<div class="statusText" options="4" data="duration: 1707838676, villageId: 536133632, homeVillage: InZectorsk, destVillageId: 536100862, destVillage: Логово разбойников II, playerId: -1, playerName: Разбойник">
																	<span>gameModel.player.status != PlayerModel.STATUS_IDLE</span>
																</div>
															</div>
														</div>
													</div>
													<div class="statsContainer contentBox gradient">
														<div class="contentBoxBody">
															<div class="progressbarContainer healthBar">
																<span class="frontLabel">
																	<i class="unit_health_small_flat_black"></i>
																</span>
																<div class="health progressbar" :content="tooltips[1]" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/hero/partials/healthTooltip.html">
																	<!-- ngIf: ::labelIcon -->
																	<div class="progress">
																		<!-- ngIf: steps -->
																		<div class="bar positive perc" :style="{zIndex: 2, width: getPercent(gameModel.player.health, gameModel.player.healthMax)+'%'}"></div>
																		<div class="bar additionalBar additionalPerc"></div>
																		<div class="marker">
																			<div class="markerInner"></div>
																		</div>
																		<!-- ngIf: ::showCountdown -->
																		<div class="label">{{gameModel.player.health}} / {{gameModel.player.healthMax}}</div>
																		<!-- ngIf: ::percTooltip -->
																	</div>
																</div>
															</div>
															<div class="progressbarContainer xpBar">
																<span class="frontLabel">
																	<i class="unit_experience_small_flat_black"></i>
																</span>
																<div class="xp progressbar" :content="tooltips[2]" v-tippy="{placement: 'bottom'}" tooltip-url="tpl/hero/partials/xpTooltip.html">
																	<!-- ngIf: ::labelIcon -->
																	<div class="progress">
																		<!-- ngIf: steps -->
																		<div class="bar positive perc " :style="{zIndex: 2, width: getPercent(gameModel.player.expAchieved, gameModel.player.expNeeded)+'%'}"></div>
																		<div class="bar additionalBar additionalPerc"></div>
																		<div class="marker">
																			<div class="markerInner"></div>
																		</div>
																		<!-- ngIf: ::showCountdown -->
																		<div class="label">{{gameModel.player.expAchieved}} / {{gameModel.player.expNeeded}}</div>
																		<!-- ngIf: ::percTooltip -->
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="contentBox gradient heroLocation">
													<div class="contentBoxBody">
														<div>
															<i class="village_village_small_flat_black"></i>
															<span data="villageId: 536133632, homeVillage: InZectorsk">
																<span>&nbsp;Родная деревня: </span>
																<a ng-class="{disabled: villId <= 2 || nolink}" class="truncated villageLink truncated clickable" clickable="$root.openWindow('mapCellDetails', {'cellId': 536133632}, null, true)" villageid="536133632" villagename="InZectorsk" nolink="" showmissinglink="true">InZectorsk</a>
															</span>
														</div>
														<div>
															<i class="unit_speed_small_flat_black" content="Скорость героя" v-tippy="{placement: 'bottom'}" tooltip-translate="Hero.Attributes.Tooltip.HeroSpeed"></i>
															<span data="speed: 7"><span>&nbsp;7 пол./час</span></span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="contentBox gradient heroAttributes">
											<h6 class="contentBoxHeader headerWithArrowEndings">
												<div class="content"><span>Параметры</span></div>
												<div class="freePoints">
													<span content="Свободные очки" v-tippy="{placement: 'bottom'}" tooltip-translate="Hero.Attributes.FreePoints">{{gameModel.player.freeStats}}</span>
													<i class="symbol_star_small_illu" v-if="gameModel.player.freeStats" content="Свободные очки" v-tippy="{placement: 'bottom'}" tooltip-translate="Hero.Attributes.FreePoints"></i>
												</div>
											</h6>
											<div class="attributesList">
												<div class="attribute" v-for="attribute in attributes" :class="{'disabled': disabledAttributes[attribute.type]}">
													<i class="attributeImage fightStrengthPoints" :class="{
															'attribute_defense_large_illu': attribute.type == 'defBonusPoints',
															'attribute_offense_large_illu': attribute.type =='attBonusPoints',
															'attribute_resources_large_illu': attribute.type == 'resBonusPoints',
															'attribute_strength_large_illu': attribute.type == 'fightStrengthPoints'
														 }" :content="attribute.tooltip" v-tippy="{placement: 'bottom'}">
													</i>
													<div class="attributeData">
														<span class="attributeName" options="fightStrengthPoints"><span>{{attribute.name}}</span></span>
														<span class="attributeValue" v-if="attribute.type == 'attBonusPoints' || attribute.type == 'defBonusPoints'">0.0%</span>
														<span class="attributeValue" v-if="attribute.type == 'fightStrengthPoints'">320</span>
														<span class="attributeValue" v-if="attribute.type  == 'resBonusPoints'">76</span>
														<div class="attributeBar">
															<div class="numberAdjuster" number-model="changes[attribure.type]">
																<div class="decr iconButton doubleBorder" :class="{'disabled': changes[attribute.type] <= min}">
																	<i class="symbol_minus_tiny_flat_black"></i>
																</div>
																<span class="numberAdjusterContent">
																	<div class="progressbar" progressbar="" perc="0" additional-perc="0">
																		<!-- ngIf: ::labelIcon -->
																		<div class="progress">
																			<!-- ngIf: steps -->
																			<div class="bar positive perc " style="z-index: 2; width: 0%;"></div>
																			<div class="bar additionalBar additionalPerc" style="z-index: 1; width: 0%;"></div>
																			<div class="marker">
																				<div class="markerInner"></div>
																			</div>
																			<!-- ngIf: ::showCountdown -->
																			<div ng-if="::label" class="label" ng-bind-html="label">&lrm;<span class="amount wrapper">0</span>/<span class="capacity">100</span></div>
																			<!-- ngIf: ::percTooltip -->
																		</div>
																	</div>
																</span>
																<div class="incr iconButton doubleBorder" :class="{'disabled': changes[attribute.type] >= max}">
																	<i class="symbol_plus_tiny_flat_black"></i>
																</div>
															</div>
														</div>
													</div>
													<p class="attributeDisabled" v-if="disabledAttributes[attribute.type]" data="level: 10"><span>Доступно на уровне 10</span></p>
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="contentBox gradient resourceProductionBox" v-if="gameModel.player.status < PlayerModel.STATUS_DEAD">
							<div class="contentBoxBody">
								<div class="headline"><span>Производство героя</span></div>
								<div class="resourceOptions">
									<a v-for="type in resTypes" :key="type" class="resourceType clickableContainer clickable" :class="{'active': changes.resBonusType == type}" @click="onChangeResourceType(type)">
										<i :class="['unit_'+type+'_small_illu', {'unit_resourcesAndCrop_small_illu': type == 'resourcesAll'}]" :content="resTypesNames[type]" v-tippy="{placement: 'top'}"></i>
										<div class="horizontalLine double"></div>
										<span v-if="type == 'resourcesAll'">20</span>
										<span v-if="type != 'resourcesAll'">80</span>
										<i class="resourceSelected action_check_small_flat_white" v-if="changes.resBonusType == type"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="saveChanges">
			<button @click="onSaveChanges()" :class="{'disabled': saveBtnDisabled}" class="animate clickable">
				<div class="content">
					<span class="innerText">
						<span><span>Сохранить</span></span>
					</span>
					<div class="animationOverlay"></div>
					<i class="checkmark action_check_small_flat_white"></i>
					<i class="checkmark action_check_small_flat_black"></i>
					<i class="checkmark action_check_small_flat_red"></i>
				</div>
			</button>
		</div>
		<div class="clear"></div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import PlayerModel from '@/models/game/PlayerModel';

import DisplayResources from '@/components/elements//DisplayResources';
import NpcTraderButton from '@/components/elements//NpcTraderButton';

export default {
	mixins: lib.mixins,
	data: () => ({
		PlayerModel,
		
		editHover: false,
		
		attributes: [
			{
				type: 'attBonusPoints',
				name: 'Сила',
				tooltip: `<div class="tooltipContent" data="val1:720, val2:800, val3:400, val4:0, val5:360, val6:1440, disabled:false"><h3><span>Сила</span></h3>
<div class="horizontalLine"></div>
<span><span>Сила включает в себя как атакующие, так и защитные навыки героя. Чем выше это значение, тем меньше повреждений получит герой в приключениях.</span></span>
<div class="horizontalLine"></div>
<span data="hero:720, equipment:800"><span>Сила: личная сила - 720, от снаряжения - 800</span></span></div>`,
			},
			{
				type: 'defBonusPoints',
				name: 'Бонус нападения',
				tooltip: `<div class="tooltipContent" data="val1:0.8, val2:800, val3:0, val4:0, val5:360, val6:1440, disabled:false"><h3><span>Бонус нападения</span></h3>
<div class="horizontalLine"></div>
<span><span>Повышает силу атаки всех единиц войск, участвующих в сражении вместе с героем.</span></span>
<!-- ngIf: disabled == 'true' -->
<!-- ngIf: disabled == 'true' -->
<div class="horizontalLine"></div>
<span data="offBonus:0"><span>Бонус атаки 0%</span></span></div>`,
			},
			{
				type: 'fightStrengthPoints',
				name: 'Бонус защиты',
				tooltip: `<div class="tooltipContent" data="val1:3.8000000000000003, val2:800, val3:3, val4:0, val5:360, val6:1440, disabled:false"><h3><span>Бонус защиты</span></h3>
<div class="horizontalLine"></div>
<span><span>Повышает параметр защиты всех единиц войск, участвующих с героем в обороне.</span></span>
<!-- ngIf: disabled == 'true' -->
<!-- ngIf: disabled == 'true' -->
<div class="horizontalLine"></div>
<span data="defBonus:3"><span>Бонус защиты: 3%</span></span></div>`,
			},
			{
				type: 'resBonusPoints',
				name: 'Ресурсы',
				tooltip: `<div class="tooltipContent" data="val1:76, val2:800, val3:72, val4:0, val5:360, val6:1440, disabled:false"><h3><span>Ресурсы</span></h3>
<div class="horizontalLine"></div>
<span><span>Повышает производство ресурсов той деревни, в которой прописан герой.</span></span><br>
<div class="horizontalLine"></div>
<span><span>Рост производства:</span></span><br>
<!-- ngIf: val4 == 0 --><span ng-if="val4 == 0"><i class="unit_resources_small_illu"></i> 360 <span><span>Все ресурсы</span></span></span><!-- end ngIf: val4 == 0 -->
<!-- ngIf: val4 == 1 -->
<!-- ngIf: val4 == 2 -->
<!-- ngIf: val4 == 3 --></div>`,
			},
		],
		disabledAttributes: [
			'defBonusPoints',
			'fightStrengthPoints',
		],
		changes: {
			attBonusPoints: 0,
			defBonusPoints: 0,
			fightStrengthPoints: 0,
			resBonusPoints: 0,
			resBonusType: 'resourcesAll',
		},
		min: 0,
		
		resTypes: [
			'resourcesAll',
			'wood',
			'clay',
			'iron',
			'crop',
		],
		resTypesNames: {
			resourcesAll: 'Все ресурсы',
			wood: 'Древесина',
			clay: 'Глина',
			iron: 'Железо',
			crop: 'Зерно',
		},
		
		tooltips: {
			1: `<div class="tooltipContent"><h3><span>Здоровье</span></h3>
<div class="horizontalLine"></div>
<span data="heroRegen:15"><span>Герой восстанавливает 15% здоровья в день.</span></span>
<!-- ngIf: hero.itemsHealthRegen != 0 --><div class="horizontalLine" ng-if="hero.itemsHealthRegen != 0"></div><!-- end ngIf: hero.itemsHealthRegen != 0 -->
<!-- ngIf: hero.itemsHealthRegen != 0 --><span ng-if="hero.itemsHealthRegen != 0">
	<span data="itemsRegen:34"><span> +34% в день от снаряжения</span></span>
</span><!-- end ngIf: hero.itemsHealthRegen != 0 --></div>`,
			2: `<div class="tooltipContent" data="bonusXp:19, xpPerc:98, xpToNextLevel:19, nextLevel:24"><h3><span>Опыт</span></h3>
<div class="horizontalLine"></div>
<span data="perc:98"><span>Опыт текущего уровня: 98%</span></span><br>
<span data="xpToNextLevel:19,nextLevel:24"><span>Герою все еще требуется 19 опыта для достижения уровня 24.</span></span>
<!-- ngIf: bonusXp != '' --><span ng-if="bonusXp != ''">
	<div class="horizontalLine"></div>
	<span data="bonusXp:19"><span>Бонус: 19%</span></span>
</span><!-- end ngIf: bonusXp != '' --></div>`,
		},
	}),
	computed: {
		max(){
			let val = this.gameModel.player.freeStats;
			for(let attribute of this.attributes){
				val -= this.changes[attribute.type]||0;
            }
			if(val > 100) val = 100;
			if(val < 0) val = 0;
			return val;
		},
		saveBtnDisabled(){
			if(this.changes.resBonusType != this.gameModel.player.resBonusType) return false;
			return true;
		},
	},
	methods: {
		getPercent: lib.getPercent,
		
		onChangeResourceType(type){
			this.changes.resBonusType = type;
		},
		onSaveChanges(){
			this.gameModel.player.resBonusType = this.changes.resBonusType;
		},
	},
	mounted(){
		this.changes.resBonusType = this.gameModel.player.resBonusType;
		
		//debug
		this.gameModel.player.freeStats = 10;
	},
	components: {
		DisplayResources,
		NpcTraderButton,
	},
}
</script>
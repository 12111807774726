<template>
	<div class="top10Ranking">
		<div ng-controller="statsKingdomTop10Ctrl">
			<div class="statisticsTable contentBox transparent top10quarter" ng-repeat="top10 in top10Lists">
				<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
					<div class="content">Атакующий недели</div>
				</h6>
				<table class="gradient showOwnRank smallRows inRank" ng-class="{'inRank': top10.ownData[0].rank < 10}">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Королевство" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Kingdom"><i class="community_kingdom_small_flat_black"></i></th>
							<th ng-if="top10.pointsTitle == 'offPoints'" content="Очки атаки" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.AttackerPoints"><i class="movement_attack_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'defPoints' -->
							<!-- ngIf: top10.pointsTitle == 'ranks' -->
							<!-- ngIf: top10.pointsTitle == 'resources' -->
						</tr>
					</thead>
					<tbody class="ranks">
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>1</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2">♔Выдры♔</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">32292</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>2</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" kingdomid="6">Utopia</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">24156</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>3</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" kingdomid="8">фаршмелы</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">19496</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>4</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3">Roman</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">18032</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>5</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 32}, null, true)" kingdomid="32">Квадрат</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">6985</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>6</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 44}, null, true)" kingdomid="44">Вольные</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">3211</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>7</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 29}, null, true)" kingdomid="29">StarLink</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">2534</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>8</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 57}, null, true)" kingdomid="57">♔Выдры*♔</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1883</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>9</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">☠Outcast</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1582</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>10</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 16}, null, true)" kingdomid="16">Raf</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1058</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
					</tbody>
					<!-- ngIf: top10.ownData[0].rank > 9 || top10.ownData[0].rank == '?' -->
				</table>
			</div>
			<div class="statisticsTable contentBox transparent top10quarter" ng-repeat="top10 in top10Lists">
				<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
					<div class="content">Взлет недели</div>
				</h6>
				<table class="gradient showOwnRank smallRows inRank" ng-class="{'inRank': top10.ownData[0].rank < 10}">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Королевство" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Kingdom"><i class="community_kingdom_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'offPoints' -->
							<!-- ngIf: top10.pointsTitle == 'defPoints' -->
							<th ng-if="top10.pointsTitle == 'ranks'" content="Повышение в рейтинге" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Top10.RanksImproved"><i class="symbol_rankUp_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'resources' -->
						</tr>
					</thead>
					<tbody class="ranks">
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>1</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" kingdomid="8">фаршмелы</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>2</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 57}, null, true)" kingdomid="57">♔Выдры*♔</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>3</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 54}, null, true)" kingdomid="54">login</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>4</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 44}, null, true)" kingdomid="44">Вольные</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>5</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 41}, null, true)" kingdomid="41">- яйца -</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>6</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 29}, null, true)" kingdomid="29">StarLink</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>7</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">☠Outcast</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>8</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 16}, null, true)" kingdomid="16">Raf</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>9</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" kingdomid="6">Utopia</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">0</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>10</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 32}, null, true)" kingdomid="32">Квадрат</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">0</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
					</tbody>
					<!-- ngIf: top10.ownData[0].rank > 9 || top10.ownData[0].rank == '?' -->
				</table>
			</div>
			<div class="statisticsTable contentBox transparent top10quarter" ng-repeat="top10 in top10Lists">
				<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
					<div class="content">Обороняющийся недели</div>
				</h6>
				<table class="gradient showOwnRank smallRows inRank" ng-class="{'inRank': top10.ownData[0].rank < 10}">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Королевство" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Kingdom"><i class="community_kingdom_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'offPoints' -->
							<th ng-if="top10.pointsTitle == 'defPoints'" content="Очки защиты" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.DefenderPoints"><i class="movement_defend_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'ranks' -->
							<!-- ngIf: top10.pointsTitle == 'resources' -->
						</tr>
					</thead>
					<tbody class="ranks">
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>1</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" kingdomid="8">фаршмелы</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1912</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>2</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3">Roman</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">1163</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>3</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2">♔Выдры♔</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">466</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>4</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" kingdomid="6">Utopia</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">380</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>5</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">☠Outcast</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">363</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>6</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 44}, null, true)" kingdomid="44">Вольные</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">180</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>7</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 29}, null, true)" kingdomid="29">StarLink</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">164</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>8</td>
						<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 32}, null, true)" kingdomid="32">Квадрат</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">119</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>9</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 54}, null, true)" kingdomid="54">login</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">76</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>10</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 41}, null, true)" kingdomid="41">- яйца -</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">49</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
					</tbody>
					<!-- ngIf: top10.ownData[0].rank > 9 || top10.ownData[0].rank == '?' -->
				</table>
			</div>
			<div class="statisticsTable contentBox transparent top10quarter" ng-repeat="top10 in top10Lists">
				<h6 class="contentBoxHeader headerWithArrowEndings tableYellow">
					<div class="content">Грабитель недели</div>
				</h6>
				<table class="gradient showOwnRank smallRows inRank" ng-class="{'inRank': top10.ownData[0].rank < 10}">
					<thead>
						<tr>
							<th content="Ранг" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Rank"><i class="symbol_ranking_small_flat_black"></i></th>
							<th content="Королевство" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Kingdom"><i class="community_kingdom_small_flat_black"></i></th>
							<!-- ngIf: top10.pointsTitle == 'offPoints' -->
							<!-- ngIf: top10.pointsTitle == 'defPoints' -->
							<!-- ngIf: top10.pointsTitle == 'ranks' -->
							<th ng-if="top10.pointsTitle == 'resources'" content="Награблено ресурсов" v-tippy="{placement: 'bottom'}" tooltip-translate="Statistics.TableHeader.Tooltip.Top10.ResourcesRaided"><i class="symbol_resources_small_flat_black"></i></th>
						</tr>
					</thead>
					<tbody class="ranks">
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>1</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 2}, null, true)" kingdomid="2">♔Выдры♔</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">3711419</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>2</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 8}, null, true)" kingdomid="8">фаршмелы</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">2856232</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>3</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 3}, null, true)" kingdomid="3">Roman</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">2767351</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>4</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 6}, null, true)" kingdomid="6">Utopia</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">2632104</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>5</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 32}, null, true)" kingdomid="32">Квадрат</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">825394</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>6</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 57}, null, true)" kingdomid="57">♔Выдры*♔</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">612258</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>7</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 44}, null, true)" kingdomid="44">Вольные</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">420624</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>8</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 20}, null, true)" kingdomid="20">☠Outcast</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">121019</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>9</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 16}, null, true)" kingdomid="16">Raf</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">75232</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
						<tr ng-repeat="result in top10.data" ng-class="{'highlighted': (result.kingdomId == player.data.kingdomId &amp;&amp; player.data.kingdomId > 0)}">
							<td>10</td>
							<td ng-if="result.kingdomId > 0" class="nameColumn">
								<a ng-class="{disabled: kingdomId <= 0 || nolink}" class="kingdomLink truncated clickable" clickable="$root.openWindow('kingdom', {'kingdomId': 54}, null, true)" kingdomid="54">login</a>
							</td>
							<td ng-if="result.kingdomId > 0" class="number">-8081</td>
							<!-- ngIf: result.kingdomId == 0 -->
						</tr>
					</tbody>
					<!-- ngIf: top10.ownData[0].rank > 9 || top10.ownData[0].rank == '?' -->
				</table>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>
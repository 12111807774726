<template>
	<div class="auction bids" v-if="gameModel.player.deletionTime == 0">
		<div class="filterBar">
			<div class="item-filter" func="filter">
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot1">
					<i class="filterItem heroItem item_category_helmet_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot4">
					<i class="filterItem heroItem item_category_body_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot3">
					<i class="filterItem heroItem item_category_leftHand_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot2">
					<i class="filterItem heroItem item_category_rightHand_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot5">
					<i class="filterItem heroItem item_category_shoes_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_Slot6">
					<i class="filterItem heroItem item_category_horse_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_112">
					<i class="filterItem heroItem item_category_ointment_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_113">
					<i class="filterItem heroItem item_category_scroll_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_114">
					<i class="filterItem heroItem item_category_bucket_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_115">
					<i class="filterItem heroItem item_category_bookOfWisdom_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_116">
					<i class="filterItem heroItem item_category_artwork_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_117">
					<i class="filterItem heroItem item_category_smallBandage_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_118">
					<i class="filterItem heroItem item_category_bandage_small_flat_black"></i>
				</a>
				<a class="filter iconButton clickable" ng-class="{active:type==showItemsType}" clickable="itemFilter(type)" ng-repeat="type in types" tooltip="" tooltip-translate="Hero.Item_119">
					<i class="filterItem heroItem item_category_cage_small_flat_black"></i>
				</a>
			</div>
		</div>
		<div>
			<div>
				<table>
					<thead>
						<tr>
							<th colspan="2"><span>Описание</span></th>
							<th>
								<i class="symbol_clock_small_flat_black duration" content="Время" v-tippy="{placement: 'bottom'}" tooltip-translate="Duration"></i>
							</th>
							<th>
								<i class="feature_auction_small_flat_black" content="Ставки" v-tippy="{placement: 'bottom'}" tooltip-translate="Auction.Bids"></i>
							</th>
							<th colspan="2"><span>Цена</span></th>
							<th class="action" colspan="2"><span>Ставка</span></th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="auctions.length == 0">
							<td class="noAuctions" colspan="8"><span>Нет доступных торгов</span></td>
						</tr>
						<tr v-for="auction in auctionsOnPage" :key="auction.id" :class="{'error': auction.error, 'finished': auction.finished}">
							<td class="item">
								<i class="heroItem clickable" :class="['item_category_'+auction.images[0]+'_small_flat_black']" :content="auction.tooltip" v-tippy="{placement: 'bottom'}" clickable="filter(auction.itemTypeId,true)"></i>
							</td>
							<td>
								<span><span>{{auction.name}}</span></span>
								<span v-if="auction.stackable"> ({{auction.amount}}x)</span>
							</td>
							<td>
								<div v-if="!auction.finished" :countdown="auction.timeEnd">{{formatUnixDate(auction.timeEnd, 'HH:mm:ss')}}</div>
								<div v-if="auction.finished" class="inactive"><span>Окончено</span></div>
							</td>
							<td :content="'Игрок с высшей ставкой: '+auction.highestBidderName" v-tippy="{placement: 'bottom'}">
								{{auction.bids}}
							</td>
							<td class="price" :content="auction.amount > 1 ? 'Цена за единицу: '+(auction.price / auction.amount).toFixed(2) : ''" v-tippy="{placement: 'bottom'}" tooltip-translate="Auction.PriceProItem">
								{{auction.price}} <i class="unit_silver_small_illu"></i>
							</td>
							<td>
								<i :class="{
									'feature_auction_small_flat_black disabled': auction.highestBidderPlayerId == 0,
									'feature_auction_small_flat_positive': auction.highestBidderPlayerId == gameModel.player.id,
									'feature_auction_small_flat_negative': auction.highestBidderPlayerId != gameModel.player.id
								}"></i>
							</td>
							<td class="priceInputCol">
								<input type="number" class="priceInput" @wheel="$event.target.blur()" v-model.number="auction.priceInput" :disabled="auction.finished" :class="{
									'highestBidder': !auction.finished && auction.highestBidderPlayerId == gameModel.player.id,
									'outbid': !auction.finished && auction.highestBidderPlayerId != gameModel.player.id
								}"/>
							</td>
							<td class="bidButtonCol">
								<button v-if="!auction.finished" clickable="placeBid(8474)" play-on-click="19" class="clickable">
									<div class="content">
										<span ng-transclude="" class="innerText">
											<span><span>Изменить</span></span>
										</span>
										<!-- ngIf: price > 0 || forceGoldUsage -->
										<!-- ngIf: price == -1 && !forceGoldUsage -->
										<div class="animationOverlay"></div>
										<i class="checkmark action_check_small_flat_white"></i>
										<i class="checkmark action_check_small_flat_black"></i>
										<i class="checkmark action_check_small_flat_red"></i>
									</div>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<pagination :items="auctions" :page-size="10" @change-page="onChangePage" style="padding: 5px 0 0 0;"></pagination>
		</div>
		<!-- ngIf: error -->
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import Pagination from '@/components/elements/Pagination';

export default {
	mixins: lib.mixins,
	data: () => ({
		ownBids: [
			{
				id: 38453,
				playerId: 734,
				auctionId: 8474,
				maxBid: 2201,
				time: 1723217988,
				deleted: 0,
			},
			{
				id: 38471,
				playerId: 734,
				auctionId: 8492,
				maxBid: 1300,
				time: 1723229068,
				deleted: 0,
			},
			{
				id: 38419,
				playerId: 734,
				auctionId: 8508,
				maxBid: 25,
				time: 1723216709,
				deleted: 0,
			},
		],
		
		auctions: [
			{
				id: 5519,
				tribeId: 0,
				itemTypeId: 113,
				strength: 10,
				bonuses: {
					1: 10,
				},
				amount: 45,
				status: 2, //1: в процессе, 2: окончено
				timeStart: 1720556005,
				timeEnd: 1720642405,
				finished: false,
				error: false,
				price: 991,
				priceInput: 991,
				bids: 2,
				highestBid: 995,
				highestBidderPlayerId: 410,
				highestBidderName: "Lobster 48",
				slot: -1,
				name: 'Клетка',
				images: [
					"scroll",
				],
				stackable: true,
				tooltip: ``,
			},
			{
				id: 8474,
				tribeId: 0,
				itemTypeId: 113,
				strength: 10,
				bonuses: {
					1: 10,
				},
				amount: 111,
				status: 2,
				timeStart: 1723131251,
				timeEnd: 1723218047,
				finished: false,
				error: false,
				price: 2104,
				priceInput: 2201,
				bids: 26,
				highestBid: 2201,
				highestBidderPlayerId: 1,
				highestBidderName: "lexinzector",
				slot: -1,
				name: 'Клетка',
				images: [
					"scroll",
				],
				stackable: true,
				tooltip: ``,
			},
			{
				id: 8492,
				tribeId: 0,
				itemTypeId: 119,
				strength: 0,
				bonuses: [],
				amount: 18,
				status: 2,
				timeStart: 1723142860,
				timeEnd: 1723229368,
				finished: true,
				error: false,
				price: 1201,
				priceInput: 1300,
				bids: 7,
				highestBid: 1300,
				highestBidderPlayerId: 1,
				highestBidderName: "lexinzector",
				slot: 7,
				name: 'Клетка',
				images: [
					"cage",
				],
				stackable: true,
				tooltip: ``,
			},
			{
				id: 8508,
				tribeId: 0,
				itemTypeId: 119,
				strength: 0,
				bonuses: [],
				amount: 1,
				status: 1,
				timeStart: 1723150051,
				timeEnd: 1723236451,
				finished: true,
				error: false,
				price: 21,
				priceInput: 25,
				bids: 2,
				highestBid: 25,
				highestBidderPlayerId: 1,
				highestBidderName: "lexinzector",
				slot: 7,
				name: 'Свиток',
				images: [
					"cage",
				],
				stackable: true,
				tooltip: ``,
    		},
			{
				id: 8509,
				tribeId: 0,
				itemTypeId: 119,
				strength: 0,
				bonuses: [],
				amount: 1,
				status: 1,
				timeStart: 1723150051,
				timeEnd: 1723236451,
				finished: true,
				error: false,
				price: 21,
				priceInput: 22,
				bids: 2,
				highestBid: 26,
				highestBidderPlayerId: 410,
				highestBidderName: "Lobster 48",
				slot: 7,
				name: 'Свиток',
				images: [
					"cage",
				],
				stackable: true,
				tooltip: ``,
    		},
		],
		
		pager: {},
		auctionsOnPage: [],
	}),
	computed: {},
	methods: {
		formatDate: lib.formatDate,
		formatUnixDate: lib.formatUnixDate,
		
		onChangePage(pageOfItems){
			this.auctionsOnPage = pageOfItems;
		},
	},
	components: {
		Pagination,
	},
}
</script>
<template>
	<nav class="tabulation subtab unselectable" ng-if="tabData.tabs.length> 1">
		<a class="tab clickable" :class="{active: tab == 'local', inactive: tab != 'local'}" @click="tab = 'local'">
			<div class="content">
				<span>Местные войска</span>
			</div>
		</a>
		<a class="tab clickable" :class="{active: tab == 'deployed', inactive: tab != 'deployed'}" @click="tab = 'deployed'">
			<div class="content">
				<span>В подкреплении</span>
			</div>
		</a>
		<a class="tab clickable" :class="{active: tab == 'incoming', inactive: tab != 'incoming'}" @click="tab = 'incoming'">
			<div class="content">
				<span>Прибывающие</span>
			</div>
		</a>
		<a class="tab clickable" :class="{active: tab == 'outgoing', inactive: tab != 'outgoing'}" @click="tab = 'outgoing'">
			<div class="content">
				<span>Исходящие</span>
			</div>
		</a>
	</nav>
	<div class="tabulationContent scrollable scrollingDisabled tabContentLocal">
		<div class="scrollContentOuterWrapper disableScrolling">
			<div class="scrollContent disableScrolling">
				<div class="scrollContentInnerWrapper" style="width: 100%;">
					<div class="loadedTab currentTab" v-if="tab == 'local'">
						<local />
					</div>
					<div class="loadedTab currentTab" v-if="tab == 'deployed'">
						<deployed />
					</div>
					<div class="loadedTab currentTab" v-if="tab == 'incoming'">
						<incoming />
					</div>
					<div class="loadedTab currentTab" v-if="tab == 'outgoing'">
						<outgoing />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import Local from '@/components/pages/buildings/area/overview/Local';
import Deployed from '@/components/pages/buildings/area/overview/Deployed';
import Incoming from '@/components/pages/buildings/area/overview/Incoming';
import Outgoing from '@/components/pages/buildings/area/overview/Outgoing';

export default {
	mixins: lib.mixins,
	props: {
		setTab: {
			type: [Object, String],
		},
	},
	data: () => ({
		tab: 'local',
	}),
	computed: {},
	watch: {
		setTab(val, oldVal){
			if(val != oldVal){
				if(!val) this.onToggleTab('local');
				else this.onToggleTab(val);
			}
		},
	},
	methods: {
		onToggleTab(tab){
			this.tab = tab;
			
			if(this.gameModel.soundOn){
				var audio = new Audio('//iwstatic.g.bsrv.su/audio/sounds/T5UIS_10.ogg');
				audio.play();
			}
		},
	},
	mounted(){},
	components: {
		Local,
		Deployed,
		Incoming,
		Outgoing,
	},
}
</script>
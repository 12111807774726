<template>
	<div id="troopsOverview" class="troopsOverview scrollable">
		<div class="scrollContentOuterWrapper" style="width: 153px;">
			<div class="scrollContent" style="width: 153px; overflow-y: auto;">
				<div class="scrollContentInnerWrapper" style="width: auto;">
					<div id="troopMovements">
						<ul class="troopMovements">
							<li v-for="movementGroup in gameModel.troops.movements" :class="[movementGroup.name, movementGroup.movementDirection, {alertBlink: movementGroup.name == 'incoming_attacks' && movementGroup.movements[0].timeFinish < currentServerTime + 60}]" @click="$router.push({path: '/island/area', query: {tab: 'overview', subtab: movementGroup.movementDirection}})" clickable="openRallypoint(movementGroup.movementDirection)" :content="movementGroup.tooltip" v-tippy="{placement: 'left'}">
								<i :class="movementGroup.movementIcon"></i>
								<div class="countdown">
									<vue-countdown :time="movementGroup.movements[0].time" v-slot="{days, hours, minutes, seconds}">
										{{hours < 10 ? 0 : ''}}{{hours}}:{{minutes < 10 ? 0 : ''}}{{minutes}}:{{seconds < 10 ? 0 : ''}}{{seconds}}
									</vue-countdown>
								</div>
								<div class="count">{{movementGroup.movements.length}}</div>
								<div class="ending">
									<div class="colored"></div>
								</div>
							</li>
						</ul>
					</div>
					<div id="troopsStationed">
						<ul class="troopsStationed">
							<ul v-for="group in gameModel.troops.stationed">
								<li class="tribe clickable" :class="{active: group.show}" @click="group.show = !group.show" :content="group.name" v-tippy="{placement: 'left'}">
									<i class="tribe" :class="[group.icon]"></i>
									<div class="count">{{group.count}}</div>
								</li>
								<li class="unit clickable" v-for="unit in group.units" v-if="group.show" :content="unit.tooltip" v-tippy="{placement: 'left'}">
									<i class="unitIcon unitSmall" :class="[group.nation, 'unitType'+unit.type]"></i>
									<div class="count">{{unit.count}}</div>
								</li>
								<!--
								<li class="unit clickable" v-if="group.show" :content="heroTooltip" v-tippy="{placement: 'left'}">
									<i class="unitIcon unitSmall hero_illu"></i>
									<div class="count">1</div>
								</li>
								-->
							</ul>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">
@-webkit-keyframes troopMovementBlink{
    from{
        background-color:#fff
    }
    50%{
        background-color:red
    }
    100%{
        background-color:#fff
    }
}
@-moz-keyframes troopMovementBlink{
    from{
        background-color:#fff
    }
    50%{
        background-color:red
    }
    100%{
        background-color:#fff
    }
}
@-ms-keyframes troopMovementBlink{
    from{
        background-color:#fff
    }
    50%{
        background-color:red
    }
    100%{
        background-color:#fff
    }
}
@-o-keyframes troopMovementBlink{
    from{
        background-color:#fff
    }
    50%{
        background-color:red
    }
    100%{
        background-color:#fff
    }
}
@keyframes troopMovementBlink{
    from{
        background-color:#fff
    }
    50%{
        background-color:red
    }
    100%{
        background-color:#fff
    }
}
#dummyElementThatPreventsOlderBrowsersToBreakBecauseOfKeyframesMixin5{
    width:0
}
.troopMovements{
    padding-top:1px
}
.troopMovements li{
    height:22px;
    line-height:22px
}
.troopMovements li .ending .colored{
    border-top:11px solid transparent;
    border-bottom:11px solid transparent
}
.troopMovements li .ending .colored:before{
    top:-11px;
    border-bottom:11px solid transparent
}
.troopMovements li .ending .colored:after{
    bottom:-11px;
    border-top:11px solid transparent
}
.troopMovements li.outgoing .ending{
    height:22px
}
.troopMovements li.outgoing .ending:before{
    border-bottom:11px solid transparent
}
.troopMovements li.outgoing .ending:after{
    border-top:11px solid transparent
}
.troopMovements li.incoming .ending{
    border-top:11px solid transparent;
    border-bottom:11px solid transparent
}
.troopMovements li.incoming .ending .colored{
    top:-11px
}
.troopMovements li.small{
    height:22px;
    line-height:22px
}
.troopMovements li.small .ending .colored{
    border-top:11px solid transparent;
    border-bottom:11px solid transparent
}
.troopMovements li.small .ending .colored:before{
    top:-11px;
    border-bottom:11px solid transparent
}
.troopMovements li.small .ending .colored:after{
    bottom:-11px;
    border-top:11px solid transparent
}
.troopMovements li.small.outgoing .ending{
    height:22px
}
.troopMovements li.small.outgoing .ending:before{
    border-bottom:11px solid transparent
}
.troopMovements li.small.outgoing .ending:after{
    border-top:11px solid transparent
}
.troopMovements li.small.incoming .ending{
    border-top:11px solid transparent;
    border-bottom:11px solid transparent
}
.troopMovements li.small.incoming .ending .colored{
    top:-11px
}
.troopMovements li{
    position:relative;
    border-top:1px solid #fff;
    border-bottom:1px solid #fff;
    -webkit-box-shadow:1px 0 1px rgba(0,0,0,0.7);
    -moz-box-shadow:1px 0 1px rgba(0,0,0,0.7);
    box-shadow:1px 0 1px rgba(0,0,0,0.7);
    background-image:-owg-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    background-image:-webkit-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    background-image:-moz-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    background-image:-o-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    background-image:linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    width:143px;
    padding-left:5px;
    margin-bottom:3px;
    margin-left:6px
}
.troopMovements li:after{
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0
}
.troopMovements li.alertBlink{
    background-image:-owg-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.4));
    background-image:-webkit-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.4));
    background-image:-moz-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.4));
    background-image:-o-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.4));
    background-image:linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.4));
    animation:troopMovementBlink 2s infinite linear;
    -webkit-animation:troopMovementBlink 2s infinite linear;
    -moz-animation:troopMovementBlink 2s infinite linear
}
.troopMovements li.incoming+.outgoing{
    margin-top:10px
}
.troopMovements li:last-child{
    margin-bottom:0
}
.troopMovements li:hover{
    background-image:none;
    background-color:#f1f0ee
}
.troopMovements li i{
    float:left;
    position:relative;
    top:3px;
    margin-left:5px
}
.troopMovements li i:after{
    content:"";
    position:absolute;
    left:-25px
}
.troopMovements li .countdown{
    float:left;
    width:65px;
    text-align:right;
    margin-right:7px
}
.troopMovements li .count{
    float:left;
    text-align:left;
    border-left:1px solid #9a7641;
    padding-left:7px
}
.troopMovements li .ending{
    position:absolute;
    left:-6px
}
.troopMovements li .ending .colored{
    position:absolute;
    z-index:2
}
.troopMovements li .ending .colored:before,
.troopMovements li .ending .colored:after{
    content:"";
    position:absolute
}
.troopMovements li.outgoing .ending{
    top:-1px;
    width:6px;
    border-top:1px solid #fff;
    border-bottom:1px solid #fff
}
.troopMovements li.outgoing .ending:before,
.troopMovements li.outgoing .ending:after{
    content:"";
    position:absolute;
    left:0;
    border-right:6px solid #fff
}
.troopMovements li.outgoing .ending:before{
    top:0
}
.troopMovements li.outgoing .ending:after{
    bottom:0
}
.troopMovements li.outgoing .ending .colored{
    left:8px;
    top:0
}
.troopMovements li.outgoing .ending .colored:before,
.troopMovements li.outgoing .ending .colored:after{
    left:-12px
}
.troopMovements li.incoming .ending{
    top:0;
    border-right:6px solid #fff
}
.troopMovements li.incoming .ending .colored{
    left:2px
}
.troopMovements li.incoming .ending .colored:before,
.troopMovements li.incoming .ending .colored:after{
    left:6px
}
.troopMovements li.incoming_attacks .countdown,
.troopMovements li.oasis_incoming_attacks .countdown{
    color:red
}
.troopMovements li.incoming .ending .colored{
    border-right:6px solid #3da311
}
.troopMovements li.incoming .ending .colored:before,
.troopMovements li.incoming .ending .colored:after{
    border-left:6px solid #3da311
}
.troopMovements li.incoming.incoming_attacks .ending .colored{
    border-right:6px solid red
}
.troopMovements li.incoming.incoming_attacks .ending .colored:before,
.troopMovements li.incoming.incoming_attacks .ending .colored:after{
    border-left:6px solid red
}
.troopMovements li.outgoing .ending .colored{
    border-left:6px solid #f90
}
.troopMovements li.outgoing .ending .colored:before,
.troopMovements li.outgoing .ending .colored:after{
    border-right:6px solid #f90
}
.troopsOverview.scrollable{
    position:absolute;
    top:175px;
    bottom:100px;
    right:0;
    z-index:2;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.troopsOverview.scrollable .scrollContentOuterWrapper{
    height:100%
}
.troopsOverview.scrollable ul{
    margin:0
}
.troopsOverview.scrollable ul:after{
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0
}
.troopsOverview.scrollable ul li{
    position:relative;
    height:22px;
    line-height:22px
}
.troopsOverview.scrollable ul li:after{
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0
}
.troopsOverview.scrollable .troopsStationed{
    margin-top:10px;
    margin-left:29px
}
.troopsOverview.scrollable .troopsStationed li{
    width:122px;
    border:1px solid #fff;
    -webkit-box-shadow:0 0 1px rgba(0,0,0,0.7);
    -moz-box-shadow:0 0 1px rgba(0,0,0,0.7);
    box-shadow:0 0 1px rgba(0,0,0,0.7)
}
.troopsOverview.scrollable .troopsStationed li i{
    float:left;
    margin-left:10px
}
.troopsOverview.scrollable .troopsStationed li i.tribe{
    float:none;
    margin:0;
    position:absolute;
    bottom:0;
    left:0
}
.troopsOverview.scrollable .troopsStationed li .count{
    float:left;
    margin-left:5px;
    margin-right:35px;
    width:50px;
    text-align:right
}
.troopsOverview.scrollable .troopsStationed li.tribe{
    background-image:-owg-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    background-image:-webkit-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    background-image:-moz-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    background-image:-o-linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    background-image:linear-gradient(to right,#f1f0ee,rgba(241,240,238,0.8));
    height:26px;
    line-height:26px;
    margin-top:20px
}
.troopsOverview.scrollable .troopsStationed li.tribe .count{
    font-weight:bold;
    float:right;
    margin-right:16px;
    width:auto
}
.troopsOverview.scrollable .troopsStationed li.tribe.active{
    background-image:none;
    background-color:rgba(255,255,255,0.95);
    cursor:default
}
.troopsOverview.scrollable .troopsStationed li.unit{
    right:-20px;
    background-color:rgba(241,240,238,0.7);
    margin-top:5px
}
.troopsOverview.scrollable .troopsStationed li.unit i{
    margin-top:1px
}
.troopsOverview.scrollable .troopsStationed li.tribe:hover:not(.active),
.troopsOverview.scrollable .troopsStationed li.unit:hover:not(.active){
    background-image:none;
    background-color:rgba(255,255,255,0.9)
}
.troopsOverview.scrollable .troopsStationed li.tribe:active:not(.active),
.troopsOverview.scrollable .troopsStationed li.unit:active:not(.active){
    border:1px solid #9a7641;
    background-image:none;
    background-color:rgba(184,178,169,0.9)
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		currentServerTime: ((((1 * 60) + 58) * 60) + 33) * 1000,
		
		heroTooltip: `<div class="stationedUnitTooltip" ng-init="troopId = getTroopId(group['tribeId'], unitId)">
	<h3>
		<span translate="" options="hero"><span>Герой</span></span>
		<!-- ngIf: group.unitLevels[unitId] -->
	</h3>
	<div class="horizontalLine"></div>
	<div class="troopDetails">
		<div class="troopIcon">
			<i class="unitMedium hero_illu" unit-icon="" big="true" data="group['tribeId'], unitId"></i>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO -->
		<div class="unitAttributes" ng-if="unitId == Troops.TYPE_HERO">
			<span ng-class="{noValue : group.unitValues[unitId].attack < 0 }"><i class="movement_attack_small_flat_black"></i>1470</span>
			<span ng-class="{noValue : group.unitValues[unitId].defence < 0 }"><i class="unit_defenseInfantry_small_flat_black"></i>1470</span>
			<br>
			<span ng-class="{noValue : group.unitValues[unitId].defenceCavalry < 0 }"><i class="unit_defenseCavalry_small_flat_black"></i>1470</span>
			<span><i class="unit_capacity_small_flat_black"></i>0</span>
		</div>
	</div>
	<div class="homeTroops troopDetails" ng-if="unit['distribution']['own']">
		<div class="horizontalLine"></div>
		<div class="playerNameVillageNameWrapper">
			<div class="playerName"><a ng-class="{disabled: playerId <= 2 || nolink || deleted}" class="playerLink  truncated clickable disabled" clickable="openPlayerWindow()" tooltip="" tooltip-show="false" tooltip-translate="Player.AlreadyDeleted" player-link="" playerid="734" nolink="true" playername="">lexinzector</a></div>
			<div class="villageName"><a ng-class="{disabled: villId <= 2 || nolink}" class="villageLink truncated clickable disabled" clickable="$root.openWindow('mapCellDetails', {'cellId': 536330229}, null, true)" village-link="" villageid="536330229" nolink="true" villagename="">Брест</a></div>
		</div>
		<!-- ngIf: unitId != Troops.TYPE_HERO -->
		<div class="heroLevel" ng-if="unitId == Troops.TYPE_HERO">
			<span translate=""><span>Ур.</span></span>
			<span class="level">18</span>
		</div>
	</div>
	<!-- ngRepeat: support in unit['distribution']['support'] track by $index -->
	<!-- ngIf: unit['distribution']['others']['sum'] > 0 -->
</div>`,
	}),
	computed: {},
	mounted(){},
	methods: {},
	components: {},
}
</script>